import { defineMessages } from '@transcend-io/internationalization';

export const rangeInputMessages = defineMessages(
  'ad-core-components.RangeInput.rangeInput',
  {
    low: {
      defaultMessage: 'Low',
    },
    medium: {
      defaultMessage: 'Med',
    },
    high: {
      defaultMessage: 'High',
    },
  },
);
