import { statusDescriptionMessages, statusTitleMessages } from './messages';
import type { RequestStatusConfig } from './types';

/**
 * ## Manual Approval after Deletion
 * The request has completed deletion, but is pending approval before user is notified about deletion being completed.
 *
 * In this stage, the organization can customize the final deletion approval template.
 */
export const SECONDARY_APPROVING_STATUS: RequestStatusConfig = {
  color: 'primary',
  icon: 'exclamation-triangle',
  isActionItem: true,
  secondaryOnly: true,
  messages: {
    description: statusDescriptionMessages.SECONDARY_APPROVING,
    title: statusTitleMessages.SECONDARY_APPROVING,
  },
  transitions: [
    /** View catagories of request results */
    'SECONDARY_COMPLETED',
  ],
};
