import { defineMessages } from '@transcend-io/internationalization';

export const countUpMessages = defineMessages(
  'ad-core-components.CountUp.countUp',
  {
    startedAt: {
      defaultMessage: 'Started at: {date}',
    },
    endedAt: {
      defaultMessage: 'Ended at: {date}',
    },
  },
);
