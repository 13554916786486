/**
 * The status of the certificate
 *
 * See https://docs.aws.amazon.com/acm/latest/APIReference/API_CertificateDetail.html
 */
export enum CertificateStatus {
  /** The certificate is pending validation */
  PendingValidation = 'PENDING_VALIDATION',
  /** The certificate has been issued */
  Issued = 'ISSUED',
  /** The certificate is inactive */
  Inactive = 'INACTIVE',
  /** The certificate has expired */
  Expired = 'EXPIRED',
  /** Validation for the certificate timed out */
  ValidationTimedOut = 'VALIDATION_TIMED_OUT',
  /** The certificate has been revoked */
  Revoked = 'REVOKED',
  /** The certificate failed or something */
  Failed = 'FAILED',
}
