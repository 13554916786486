import { defineMessages } from '@transcend-io/internationalization';

export const filterManagerHookMessages = defineMessages(
  'admin-dashboard.hooks.filterManager.filterManagerHook',
  {
    teamsPlaceholder: {
      defaultMessage: 'Select teams',
    },
    apiKeysPlaceholder: {
      defaultMessage: 'Select API keys',
    },
    contractsPlaceholder: {
      defaultMessage: 'Select contracts',
    },
    codePackagesPlaceholder: {
      defaultMessage: 'Select packages',
    },
    catalogsPlaceholder: {
      defaultMessage: 'Select integrations',
    },
    repositoriesPlaceholder: {
      defaultMessage: 'Select repositories',
    },
    softwareDevelopmentKitsPlaceholder: {
      defaultMessage: 'Select SDKs',
    },
    promptsPlaceholder: {
      defaultMessage: 'Select prompts',
    },
    promptGroupsPlaceholder: {
      defaultMessage: 'Select prompt groups',
    },
    largeLanguageModelsPlaceholder: {
      defaultMessage: 'Select models',
    },
    contractScansPlaceholder: {
      defaultMessage: 'Select contract scans',
    },
    agentsPlaceholder: {
      defaultMessage: 'Select agents',
    },
    agentFilesPlaceholder: {
      defaultMessage: 'Select files',
    },
    agentFunctionsPlaceholder: {
      defaultMessage: 'Select functions',
    },
    applicationsPlaceholder: {
      defaultMessage: 'Select applications',
    },
    pathfinderPoliciesPlaceholder: {
      defaultMessage: 'Select policies',
    },
    pathfindersPlaceholder: {
      defaultMessage: 'Select pathfinders',
    },
    applicationUsersPlaceholder: {
      defaultMessage: 'Select users',
    },
  },
);
