import { FilterConfigItem } from '@main/ad-core-components';
import {
  SelectedLargeLanguageModel,
  SelectLargeLanguageModels,
} from '@main/admin-dashboard/src/Prompts/components/SelectLargeLanguageModels';
import { buildUseLazyQuery } from '@main/core-ui';
import { endpoints } from '@main/prompt-types';
import { ID } from '@main/schema-utils';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { MessageDescriptor } from 'react-intl';

import { filterManagerHookMessages } from './messages';

/**
 * Filters definition with property for largeLanguageModel values specified
 */
type LargeLanguageModelEnrichedFilters = Record<string, unknown> &
  Record<
    /**
     * LargeLanguageModel values that are currently filtering the table
     */
    string,
    SelectedLargeLanguageModel[] | undefined
  >;

const useLazyLargeLanguageModels = buildUseLazyQuery(
  endpoints.largeLanguageModels,
);

export const useLargeLanguageModelFilters = <
  T extends Record<string, unknown>,
  E extends LargeLanguageModelEnrichedFilters,
>({
  largeLanguageModelIdsFilterKey = 'largeLanguageModelIds',
  filters,
  setFilters,
  label,
  enrichedLargeLanguageModelKey = 'largeLanguageModels',
}: {
  /** The property name of the filter for largeLanguageModel ids */
  largeLanguageModelIdsFilterKey?: keyof T;
  /** The currently applied filters */
  filters?: T;
  /** Callback when the filters are changed */
  setFilters: (filters: T) => void;
  /** Label for the filter menu */
  label: string | MessageDescriptor;
  /** The key to use if more than one useLargeLanguageModelFilters is used in a FilterManager */
  enrichedLargeLanguageModelKey?: keyof E;
}): {
  /**  The largeLanguageModel keys enabled on this table */
  selectedLargeLanguageModels: SelectedLargeLanguageModel[];
  /** The filter configuration to be passed to the filter manager */
  largeLanguageModelFiltersConfig: FilterConfigItem<E>;
  /** Callback for when the filter is cleared in the filter manager */
  clearLargeLanguageModelFilters: (key: Extract<keyof E, string>) => void;
} => {
  const getSelectedLargeLanguageModels = useLazyLargeLanguageModels();
  const [selectedLargeLanguageModels, setSelectedLargeLanguageModels] =
    useState<SelectedLargeLanguageModel[]>([]);
  const [fetchingLargeLanguageModels, setFetchingLargeLanguageModels] =
    useState(false);

  // Populate initial complex filter values
  useEffect(() => {
    if (filters) {
      const largeLanguageModelIds = (filters[largeLanguageModelIdsFilterKey] ??
        []) as ID<'largeLanguageModel'>[];

      const filtersAndEnrichedFiltersMatch =
        largeLanguageModelIds.sort().join() ===
        selectedLargeLanguageModels
          .map(({ id }) => id)
          .sort()
          .join();

      if (
        largeLanguageModelIds.length === 0 &&
        selectedLargeLanguageModels.length > 0
      ) {
        setSelectedLargeLanguageModels([]);
      } else if (
        !filtersAndEnrichedFiltersMatch &&
        !fetchingLargeLanguageModels
      ) {
        // Prevent over-firing this setter while data is still being fetched
        setFetchingLargeLanguageModels(true);
        getSelectedLargeLanguageModels({
          filterBy: { ids: largeLanguageModelIds },
        })
          .then(({ data }) => {
            setSelectedLargeLanguageModels(data.nodes);
          })
          .finally(() => {
            setFetchingLargeLanguageModels(false);
          });
      }
    }
  }, [
    filters,
    fetchingLargeLanguageModels,
    getSelectedLargeLanguageModels,
    selectedLargeLanguageModels.length,
    largeLanguageModelIdsFilterKey,
    selectedLargeLanguageModels,
  ]);

  const largeLanguageModelFiltersConfig = useMemo(
    () =>
      ({
        filterKey: enrichedLargeLanguageModelKey,
        label,
        pillOptions: {
          label: ({
            filterValues: {
              [enrichedLargeLanguageModelKey]: largeLanguageModels = [],
            },
            index = 0,
          }) => {
            const model = largeLanguageModels[index];
            return `${model?.client}: ${model?.name}`;
          },
        },
        filter: (
          <SelectLargeLanguageModels
            isMulti
            placeholderDescriptor={
              filterManagerHookMessages.largeLanguageModelsPlaceholder
            }
            menuPosition="absolute"
            value={selectedLargeLanguageModels}
            showOutline={false}
            onChange={(largeLanguageModels) => {
              setSelectedLargeLanguageModels([...largeLanguageModels]);
              setFilters({
                ...filters,
                [largeLanguageModelIdsFilterKey]: largeLanguageModels.map(
                  ({ id }) => id,
                ),
              } as T);
            }}
          />
        ),
      }) as FilterConfigItem<LargeLanguageModelEnrichedFilters>,
    [
      enrichedLargeLanguageModelKey,
      label,
      selectedLargeLanguageModels,
      filters,
      largeLanguageModelIdsFilterKey,
    ],
  );

  const clearLargeLanguageModelFilters = useCallback(
    (key) => {
      if (key === enrichedLargeLanguageModelKey) {
        setFilters({
          ...filters,
          [largeLanguageModelIdsFilterKey]: [],
        } as T);
        setSelectedLargeLanguageModels([]);
      }
    },
    [enrichedLargeLanguageModelKey, filters, largeLanguageModelIdsFilterKey],
  );

  return {
    selectedLargeLanguageModels,
    largeLanguageModelFiltersConfig,
    clearLargeLanguageModelFilters,
  };
};
