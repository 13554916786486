import { view } from '@main/utils';

/**
 * Helper to figure out if a given string uses non-standard protocol
 *
 * @param input - Input string, from user or telemetry data
 * @returns true or false
 */
export function usesNonStandardProtocol(input: string): boolean {
  return !['https:', 'http:'].includes(
    new view.URL(input, 'https://-').protocol,
  );
}
