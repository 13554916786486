import { defineMessages } from '@transcend-io/internationalization';

export const newCodePackageFormMessages = defineMessages(
  'admin-dashboard.CodeScanning.components.NewCodePackageForm.newCodePackageForm',
  {
    nameLabel: {
      defaultMessage: 'Package name',
    },
    descriptionLabel: {
      defaultMessage: 'Package description',
    },
    typeLabel: {
      defaultMessage: 'Package type',
    },
    repositoryLabel: {
      defaultMessage: 'Repository name',
    },
    relativePathLabel: {
      defaultMessage: 'Relative path to package within repository',
    },
    submitButton: {
      defaultMessage: 'Create Package',
    },
    submitSuccess: {
      defaultMessage: 'New package created!',
    },
  },
);
