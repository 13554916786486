import {
  AssessmentFormStatus,
  AssessmentFormTemplateStatus,
} from '@transcend-io/privacy-types';

import { DarkColorOption, IconType } from '@main/core-ui';

export const DATE_FORMAT = 'L [at] h:mma';

export const ASSESSMENT_FORM_ASSIGNEE_VIEW_QUERY_NAME =
  'AssessmentFormAssigneeView';

export const ASSESSMENT_FORM_TOKEN_VIEW_QUERY_NAME = 'AssessmentFormTokenView';

export const ASSESSMENT_FORM_STATUS_COLOR_NAMES: {
  [status in AssessmentFormStatus]: keyof DarkColorOption;
} = {
  [AssessmentFormStatus.Draft]: 'pink1',
  [AssessmentFormStatus.Shared]: 'blue1',
  [AssessmentFormStatus.InProgress]: 'lime1',
  [AssessmentFormStatus.InReview]: 'purple1',
  [AssessmentFormStatus.ChangesRequested]: 'orange1',
  [AssessmentFormStatus.Rejected]: 'red1',
  [AssessmentFormStatus.Approved]: 'mint1',
};

export const ASSESSMENT_FORM_STATUS_ICON_TYPES: {
  [status in AssessmentFormStatus]: IconType;
} = {
  [AssessmentFormStatus.Draft]: IconType.Draft,
  [AssessmentFormStatus.Shared]: IconType.Submitted,
  [AssessmentFormStatus.InProgress]: IconType.Pencil,
  [AssessmentFormStatus.InReview]: IconType.InReview,
  [AssessmentFormStatus.ChangesRequested]: IconType.ChangesRequested,
  [AssessmentFormStatus.Rejected]: IconType.Rejected,
  [AssessmentFormStatus.Approved]: IconType.Approved,
};

export const ASSESSMENT_FORM_TEMPLATE_STATUS_COLOR_NAMES: {
  [status in AssessmentFormTemplateStatus]: keyof DarkColorOption;
} = {
  [AssessmentFormTemplateStatus.Draft]: 'pink1',
  [AssessmentFormTemplateStatus.Published]: 'mint1',
};

export const ASSESSMENT_FORM_TEMPLATE_STATUS_ICON_TYPES: {
  [status in AssessmentFormTemplateStatus]: IconType;
} = {
  [AssessmentFormTemplateStatus.Draft]: IconType.Draft,
  [AssessmentFormTemplateStatus.Published]: IconType.Submitted,
};
