import { Modal, NA } from '@main/ad-core-components';
import { A, FlexColumn, StyleUtils } from '@main/core-ui';
import { Sdk } from '@main/datamap-types';
import React from 'react';
import { useIntl } from 'react-intl';

import { codePackageTypeMessages } from '../SelectCodePackageType/messages';
import { previewSdkMetadataMessages } from './messages';

/** Props for the `InlineSdkModal` component */
export interface InlineSdkModalProps {
  /** If the modal is visible */
  visible: boolean;
  /** Set the value of the modal to be visible or not */
  setVisible: (vis: boolean) => void;
  /** The sdk metadata to display */
  sdk: Sdk;
}

export const InlineSdkModal: React.FC<InlineSdkModalProps> = ({
  visible,
  setVisible,
  sdk,
}) => {
  const { formatMessage } = useIntl();

  return (
    <Modal
      show={visible}
      onHide={() => setVisible(false)}
      header={previewSdkMetadataMessages.title}
      width="700px"
    >
      <FlexColumn style={{ gap: StyleUtils.Spacing.md }}>
        <h3>{formatMessage(previewSdkMetadataMessages.integration)}</h3>
        {sdk.catalog.title}
        <h3>{formatMessage(previewSdkMetadataMessages.name)}</h3>
        {sdk.name}
        <h3>{formatMessage(previewSdkMetadataMessages.type)}</h3>
        {formatMessage(codePackageTypeMessages[sdk.codePackageType])}
        <h3>{formatMessage(previewSdkMetadataMessages.description)}</h3>
        {sdk.description}
        <h3>{formatMessage(previewSdkMetadataMessages.repositoryUrl)}</h3>
        {sdk.repositoryUrl ? (
          <A href={sdk.repositoryUrl}>{sdk.repositoryUrl}</A>
        ) : (
          <NA />
        )}
        <h3>{formatMessage(previewSdkMetadataMessages.documentationLinks)}</h3>
        {sdk.documentationLinks && sdk.documentationLinks.length > 0 ? (
          <ul>
            {sdk.documentationLinks.map((link) => (
              <li key={link}>
                <A href={link}>{link}</A>
              </li>
            ))}
          </ul>
        ) : (
          <NA />
        )}
      </FlexColumn>
    </Modal>
  );
};
