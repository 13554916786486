/* eslint-disable max-lines */
import {
  IdentifierType,
  QueueStatus,
  RequestDataSiloStatus,
  RequestEnricherStatus,
  RequestStatus,
} from '@transcend-io/privacy-types';
import { apply } from '@transcend-io/type-utils';

import {
  DataSiloConnectionState,
  FormItemInput,
  FormType,
  LookupProcessStatus,
  PluginQueueStatus,
} from '@main/datamap-types';
import { CODE_INTEGRATION_NAMES } from '@main/sombra-types';
import {
  adjacencyToMatrix,
  FormFormat,
  ONE_DAY,
  ONE_HOUR,
  ONE_MINUTE,
  ONE_MONTH,
} from '@main/utils';

import { REQUEST_STATUSES } from './helpers/statuses';
import { formItemMessages } from './messages';
import {
  ActivityCode,
  ProfileDataPointStatus,
  ProfileDataPointStatusSuccess,
  RequestDataSiloStatusSuccess,
  RequestEnricherStatusSuccess,
  RequestFileStatus,
  RequestFileStatusSuccess,
  VisualRequestDataSiloStatus,
} from './schema/enums';

/**
 * Maximum amount of additional time to request (in days)
 */
export const MAX_ADDITIONAL_TIME = 60;

/**
 * Default amount of additional time to request (in days)
 */
export const DEFAULT_ADDITIONAL_TIME = 14;

/**
 * Only the first 100 RequestIdentifiers for a request will be
 * shown in the bulk processing interface
 */
export const MAX_REQUEST_IDENTIFIERS_BULK_PROCESSING = 100;

/**
 * Request status transitions FSM
 */
type RequestStatusTransitions = {
  [status in RequestStatus]: RequestStatus[];
};

/**
 * A graph that represents the directed finite state machine transitions of RequestStatus.
 *
 * Each key is a RequestStatus and the value is a list of RequestStatus's that can be transitioned to.
 *
 * @see https://user-images.githubusercontent.com/10264973/53323673-aae51a80-3893-11e9-88c4-cfe572623ee5.png
 */
export const REQUEST_STATUS_FSM: RequestStatusTransitions = apply(
  REQUEST_STATUSES,
  ({ transitions }) => transitions,
);

/**
 * Matrix representation of FSM for request status transition
 */
export const REQUEST_STATUS_FSM_MATRIX = adjacencyToMatrix(REQUEST_STATUS_FSM);

/**
 * Freeform details
 */
export const DETAILS_FORM_ITEM: FormItemInput = {
  type: FormType.String,
  multiline: 3,
  label: formItemMessages.details,
  name: 'details',
};

/**
 * The valid json schema form item formats
 */
export const FORM_FORMATS: { [key in FormFormat]: string } = {
  [FormFormat.Date]: 'date',
  [FormFormat.DateTime]: 'dateTime',
  [FormFormat.Email]: 'email',
  [FormFormat.Password]: 'password',
  [FormFormat.PhoneNumber]: 'phone-number',
  [FormFormat.Time]: 'time',
};

/**
 * Mapping from RequestStatus -> ActivityCode
 *
 * This is used to enforce that an activity code is created for each request status
 */
export const REQUEST_STATUS_TO_ACTIVITY_CODE: {
  [status in RequestStatus]: ActivityCode;
} = {
  [RequestStatus.RequestMade]: 'REQUEST_REQUEST_MADE',
  [RequestStatus.FailedVerification]: 'REQUEST_FAILED_VERIFICATION',
  [RequestStatus.Enriching]: 'REQUEST_ENRICHING',
  [RequestStatus.OnHold]: 'REQUEST_ON_HOLD',
  [RequestStatus.Waiting]: 'REQUEST_WAITING',
  [RequestStatus.Compiling]: 'REQUEST_COMPILING',
  [RequestStatus.Approving]: 'REQUEST_APPROVING',
  [RequestStatus.Delayed]: 'REQUEST_DELAYED',
  [RequestStatus.Completed]: 'REQUEST_COMPLETED',
  [RequestStatus.Downloadable]: 'REQUEST_DOWNLOADABLE',
  [RequestStatus.ViewCategories]: 'REQUEST_VIEW_CATEGORIES',
  [RequestStatus.Canceled]: 'REQUEST_CANCELED',
  [RequestStatus.Secondary]: 'REQUEST_SECONDARY',
  [RequestStatus.SecondaryCompleted]: 'REQUEST_SECONDARY_COMPLETED',
  [RequestStatus.SecondaryApproving]: 'REQUEST_SECONDARY_APPROVING',
  [RequestStatus.Revoked]: 'REQUEST_REVOKED',
};

/** Constant for prompt a person integration name string */
export const PROMPT_A_PERSON_INTEGRATION_NAME = 'promptAPerson';

export const CLOUD_INTEGRATION_NAMES = [
  'amazonWebServices',
  'microsoftAzure',
  'googleCloud',
];

/** Constant for integration types that are not fully automated */
export const NON_AUTOMATED_INTEGRATIONS = [
  'skip',
  PROMPT_A_PERSON_INTEGRATION_NAME,
];

/** Constant for integration types that do not go against the integration count in contract */
export const NON_PAID_INTEGRATIONS = [
  ...CODE_INTEGRATION_NAMES,
  ...NON_AUTOMATED_INTEGRATIONS,
];

/** Integrations go against count in these states */
export const PAID_INTEGRATION_CONNECTION_STATES = [
  DataSiloConnectionState.Connected,
  DataSiloConnectionState.Expired,
  DataSiloConnectionState.PermissionsUpdated,
  DataSiloConnectionState.Indexing,
];

/**
 * The default error message when an unknown error occurs
 */
export const DEFAULT_ERROR_MESSAGE =
  'An error occurred. The request will be retried in some time. If this persists, please reach out for assistance.';

/**
 * Set scheduledAt times for the appropriate daemon states.
 */
export const DAEMON_QUEUE_SCHEDULED_AT_DELTAS: Record<QueueStatus, number> = {
  [QueueStatus.Queued]: 0,
  [QueueStatus.Resolved]: 0,
  [QueueStatus.Skipped]: 0,
  [QueueStatus.RemoteProcessing]: ONE_DAY / 2,
  [QueueStatus.Waiting]: ONE_DAY,
  [QueueStatus.Error]: ONE_DAY,
  [QueueStatus.ActionRequired]: 7 * ONE_DAY,
};

export const REQUEST_DATA_SILO_SCHEDULED_AT_DELTAS: Record<
  RequestDataSiloStatus,
  number
> = {
  ...DAEMON_QUEUE_SCHEDULED_AT_DELTAS,
  [RequestDataSiloStatus.SkippedDueToException]: 0,
};

export const PROFILE_DATA_POINT_SCHEDULED_AT_DELTAS: {
  [status in Exclude<ProfileDataPointStatus, 'MANUAL'>]: number;
} = {
  ...DAEMON_QUEUE_SCHEDULED_AT_DELTAS,
  [ProfileDataPointStatus.Finished]: 0,
  [ProfileDataPointStatus.Polling]: 6 * ONE_HOUR,
  [ProfileDataPointStatus.WaitingOnDeletionDependencies]: ONE_DAY,
  [ProfileDataPointStatus.SkippedDueToException]: 0,
  [ProfileDataPointStatus.WaitingOnOtherRequests]: ONE_DAY,
  // cast is necessary here because we removed the hardcoded Manual delta
  // in favor of a dynamic one on the DataSilos table
};

export const REQUEST_FILE_SCHEDULED_AT_DELTAS: Record<
  Exclude<RequestFileStatus, 'MANUAL'>,
  number
> = {
  ...DAEMON_QUEUE_SCHEDULED_AT_DELTAS,
  [RequestFileStatus.EncryptedCopy]: 0,
  [RequestFileStatus.Polling]: 6 * ONE_HOUR,
};

export const LOOKUP_PROCESS_SCHEDULED_AT_DELTAS: Record<
  LookupProcessStatus,
  number
> = {
  ...DAEMON_QUEUE_SCHEDULED_AT_DELTAS,
  [LookupProcessStatus.Inactive]: 0,
  [LookupProcessStatus.ActionRequired]: 0,
  [LookupProcessStatus.RemoteProcessing]: ONE_HOUR,
  [LookupProcessStatus.Waiting]: ONE_MINUTE * 5,
  [LookupProcessStatus.Error]: ONE_HOUR * 5,
};

/**
 * How often we want the Lookup Process Plugin entry to wake up
 * and search for LPs to be scheduled.
 */
export const LOOKUP_PROCESS_PLUGIN_SCHEDULED_AT_DELTA = 5 * ONE_MINUTE;

/** The time delta to use for when a Create Lookup Process plugin executes successfully. */
export const CREATE_LOOKUP_PROCESS_SUCCESS_SCHEDULED_AT_DELTA = ONE_DAY;

/** The time delta to use for when a Create Lookup Process plugin fails to execute. */
export const CREATE_LOOKUP_PROCESS_ERROR_SCHEDULED_AT_DELTA = 6 * ONE_HOUR;

export const REQUEST_ENRICHER_SCHEDULED_AT_DELTAS: Record<
  RequestEnricherStatus,
  number
> = {
  ...DAEMON_QUEUE_SCHEDULED_AT_DELTAS,
  [RequestEnricherStatus.RemoteProcessing]: ONE_HOUR * 3,
  [RequestEnricherStatus.Waiting]: ONE_DAY,
  [RequestEnricherStatus.Error]: ONE_HOUR * 5,
  [RequestEnricherStatus.WaitingOnDependencies]: ONE_HOUR * 12,
  [RequestEnricherStatus.Polling]: ONE_MINUTE * 30,
};

export const PLUGIN_SCHEDULED_AT_DELTAS: Record<PluginQueueStatus, number> = {
  ...DAEMON_QUEUE_SCHEDULED_AT_DELTAS,
  [PluginQueueStatus.RemoteProcessing]: ONE_HOUR,
  [PluginQueueStatus.Error]: ONE_HOUR,
  [PluginQueueStatus.Skipped]: ONE_MONTH,
};

export const CONTENT_CLASSIFICATION_SCHEDULED_AT_DELTAS: Record<
  PluginQueueStatus,
  number
> = {
  ...DAEMON_QUEUE_SCHEDULED_AT_DELTAS,
  [PluginQueueStatus.RemoteProcessing]: ONE_MINUTE * 15,
  [PluginQueueStatus.Error]: ONE_MINUTE * 15,
  [PluginQueueStatus.Skipped]: ONE_MONTH,
};

export const UNSTRUCTURED_DATA_SAMPLING_SCHEDULED_AT_DELTAS: Record<
  PluginQueueStatus,
  number
> = {
  ...DAEMON_QUEUE_SCHEDULED_AT_DELTAS,
  [PluginQueueStatus.RemoteProcessing]: ONE_HOUR,
  [PluginQueueStatus.Error]: ONE_HOUR,
  [PluginQueueStatus.Skipped]: ONE_MONTH,
};

/**
 * Schedule models to run after this many milliseconds, once over the error threshold.
 */
export const OVER_ERROR_THRESHOLD_SCHEDULED_AT_DELTA = 15 * ONE_DAY;

/**
 * Error message prefix for when a model goes over the error threshold.
 */
export const OVER_ERROR_THRESHOLD_ERROR_MESSAGE_PREFIX =
  'Queue model is over the error threshold';

/**
 * These statuses cannot be scheduled by the daemon
 * and thus should not do things like show the scheduledAt
 * tooltip.
 */
export const NO_SCHEDULED_AT_STATUSES = [
  ...Object.values(RequestDataSiloStatusSuccess),
  ...Object.values(ProfileDataPointStatusSuccess),
  ...Object.values(RequestFileStatusSuccess),
  VisualRequestDataSiloStatus.DataSiloDisconnected,
  VisualRequestDataSiloStatus.RequestPending,
  VisualRequestDataSiloStatus.RequestCanceled,
];

/**
 * The maximum number of files that can be bulk approved
 */
export const MAXIMUM_AVC_DOWNLOAD_NUM = 3000;

/**
 * Request enricher statuses that are not successful
 */
export const NON_SUCCESS_REQUEST_ENRICHER_STATUSES = Object.values(
  RequestEnricherStatus,
).filter(
  (status) =>
    !Object.values(RequestEnricherStatusSuccess).includes(
      status as RequestEnricherStatusSuccess,
    ),
);

/**
 * Request data silo statuses that are not successful
 */
export const NON_SUCCESS_REQUEST_DATA_SILO_STATUSES = Object.values(
  RequestDataSiloStatus,
).filter(
  (status) =>
    !Object.values(RequestDataSiloStatusSuccess).includes(
      status as RequestDataSiloStatusSuccess,
    ),
);

/**
 * Profile datapoint statuses that are not successful
 */
export const NON_SUCCESS_PROFILE_DATAPOINT_STATUSES = Object.values(
  ProfileDataPointStatus,
).filter(
  (status) =>
    !Object.values(ProfileDataPointStatusSuccess).includes(
      status as ProfileDataPointStatusSuccess,
    ),
);

/**
 * Request file statuses that are not successful
 */
export const NON_SUCCESS_REQUEST_FILE_STATUSES = Object.values(
  RequestFileStatus,
).filter(
  (status) =>
    !Object.values(RequestFileStatusSuccess).includes(
      status as RequestFileStatusSuccess,
    ),
);

/**
 * Filter out all identifiers except coreIdentifier
 */
export const NON_CORE_IDENTIFIER_IDENTIFIER_TYPES = Object.values(
  IdentifierType,
).filter((v) => v !== IdentifierType.CoreIdentifier);
/* eslint-enable max-lines */
