import {
  extractFormItemWrapperProps,
  FormItemWrapper,
  IFormFieldProps,
  IFormItemWrapperProps,
} from '@main/core-ui';
import React, { useState } from 'react';
import { Controller } from 'react-hook-form';

import {
  IReactSelectCreatableOption,
  ReactSelectCreatable,
  ReactSelectCreatableProps,
} from './ReactSelectCreatable';

export interface IFormReactSelectCreatableProps
  extends IFormFieldProps<string[]> {
  /** Callback for handling validation of input */
  onValidateNewInput?: (inputValue: string) => boolean;
}

export const createOption = (label: string): IReactSelectCreatableOption => ({
  label,
  value: label,
});

export const FormReactSelectCreatableRaw: React.FC<
  IFormReactSelectCreatableProps &
    Omit<
      ReactSelectCreatableProps,
      | 'value'
      | 'components'
      | 'inputValue'
      | 'isClearable'
      | 'isMulti'
      | 'menuIsOpen'
      | 'onChange'
      | 'onInputChange'
      | 'onKeyDown'
      | 'onBlur'
    >
> = ({
  name,
  defaultValue,
  onValidateNewInput,
  ...reactSelectCreatableProps
}) => {
  const [inputValue, setInputValue] = useState('');

  return (
    <Controller
      name={name}
      defaultValue={defaultValue}
      render={({ field }) => (
        <ReactSelectCreatable
          {...reactSelectCreatableProps}
          components={{
            DropdownIndicator: null,
          }}
          inputValue={inputValue}
          isClearable
          isMulti
          menuIsOpen={false}
          onChange={(options: IReactSelectCreatableOption[]) =>
            field.onChange(options?.map((option) => option.value))
          }
          onInputChange={(inputValue: string) => setInputValue(inputValue)}
          onKeyDown={(event: React.KeyboardEvent<HTMLElement>) => {
            if (!inputValue) return;
            switch (event.key) {
              case 'Enter':
              case 'Tab':
              case ',':
                if (!onValidateNewInput || onValidateNewInput(inputValue)) {
                  field.onChange([...(field.value ?? []), inputValue]);
                  setInputValue('');
                }
                event.preventDefault();
                break;
              default:
              // no op
            }
          }}
          onBlur={() => {
            if (inputValue) {
              if (!onValidateNewInput || onValidateNewInput(inputValue)) {
                field.onChange([...(field.value ?? []), inputValue]);
                setInputValue('');
              }
            }
            field.onBlur();
          }}
          value={
            field.value && Array.isArray(field.value)
              ? field.value.map((value: string) => createOption(value))
              : []
          }
        />
      )}
    />
  );
};

// @deprecated - Use ReactCreatableSelect
export const FormReactSelectCreatable: React.FC<
  IFormReactSelectCreatableProps &
    Omit<
      ReactSelectCreatableProps,
      | 'value'
      | 'components'
      | 'inputValue'
      | 'isClearable'
      | 'isMulti'
      | 'menuIsOpen'
      | 'onChange'
      | 'onInputChange'
      | 'onKeyDown'
      | 'onBlur'
    > &
    Omit<IFormItemWrapperProps, 'errors' | 'errorDisplay'>
> = (props) => {
  const { passthroughProps, wrapperProps } = extractFormItemWrapperProps(props);
  return (
    <FormItemWrapper {...wrapperProps}>
      <FormReactSelectCreatableRaw {...passthroughProps} />
    </FormItemWrapper>
  );
};
