import { defineMessages } from '@transcend-io/internationalization';

export const emailDomainMessages = defineMessages(
  'admin-dashboard.Infrastructure.EmailDomains.emailDomain',
  {
    header: {
      defaultMessage: 'Email Domains',
    },
    description: {
      defaultMessage:
        'Some vendors don’t support managing customer data directly via an API, ' +
        'so Transcend needs to send emails to their privacy team automatically ' +
        'for each request. Enable this feature to allow us to send and receive ' +
        'these emails on behalf of your company to ensure the broadest compatibility.',
    },
    nameFormItem: {
      defaultMessage: 'Domain Name',
    },
    saveCustomDomain: {
      defaultMessage: 'Save and Verify',
    },
    tokenTableTypeColumn: {
      defaultMessage: 'Type',
    },
    tokenTableNameColumn: {
      defaultMessage: 'Name',
    },
    tokenTableValueColumn: {
      defaultMessage: 'Value',
    },
    domainVerificationTitle: {
      defaultMessage: 'Domain Verification',
    },
    dkimVerificationTitle: {
      defaultMessage: 'DKIM Verification',
    },
    receiveVerificationTitle: {
      defaultMessage: 'Receive Verification',
    },
    verificationTokenDescription: {
      defaultMessage:
        'Add the DNS Records listed below to the DNS settings for the domain.',
    },
    verificationSuccess: {
      defaultMessage: 'Verified',
    },
    verificationPending: {
      defaultMessage: 'Pending - Waiting for DNS Records',
    },
    verificationFailed: {
      defaultMessage: 'Failed - Click to restart verification',
    },
    domainPlaceholder: {
      defaultMessage: 'privacy.google.com',
    },
    validatorMustBeSubdomain: {
      defaultMessage: 'Must be subdomain',
    },
    domainWarning: {
      defaultMessage:
        'This will route all emails at this domain to Transcend, so it is important ' +
        'that this is an unused subdomain, and not your primary domain.',
    },
    deleteDomain: {
      defaultMessage:
        'Are you sure you want to delete this domain? DNS records will need to be ' +
        're-added if you change your mind later and this will invalidate all Integrations' +
        ' that require an email Domain until a new domain is validated.',
    },
    customDomain: {
      defaultMessage: 'Custom Domain',
    },
    customDomainDescription: {
      defaultMessage:
        'Use a domain that is different than the Privacy Center domain',
    },
    emailBody: {
      defaultMessage:
        'Hi there, We are using a vendor Transcend (https://transcend.io/) to ' +
        'automate our data privacy requests to erase/opt out/or fetch personal ' +
        'data related to an individual in our systems.\n\n' +
        'Some vendors don’t support managing customer data directly via an API, ' +
        'so Transcend needs to send emails to their privacy team automatically ' +
        'for each request. We need to set the following DNS records to allow ' +
        'Transcend to send and receive these emails on behalf of us to ensure ' +
        'the broadest compatibility.',
    },
    emailSubject: {
      defaultMessage: 'DNS Records needed to automate data privacy requests',
    },
    emailConclusion: {
      defaultMessage:
        'Please let me know once these DNS records have been set. If you have ' +
        'additional questions, you can email Transcend directly at ' +
        '"support@transcend.io".',
    },
    sendEmailInstructions: {
      defaultMessage: 'Share Instructions',
    },
    customAlias: {
      defaultMessage: 'Custom Email Alias for Vendors',
    },
    customAliasDescription: {
      defaultMessage:
        'Transcend supports setting a custom email alias (formatted email name) ' +
        'to make emails more recognizable. Transcend uses the organization name ' +
        'by default. Setting an alias will affect all emails sent to 3rd party ' +
        'vendors, including Automated Vendor Coordination emails.',
    },
    exampleAlias: {
      defaultMessage: 'Example From address:',
    },
    domainDescription: {
      defaultMessage:
        'Domain verification records are used to verify that you own the specified ' +
        'domain by verifying that you can change settings in the DNS configuration. DNS ' +
        'records can take up to 24h to become visible as they propagate up the DNS chain. These ' +
        'records are publicly visible and can be checked in your terminal or on a DNS ' +
        'record viewer website like https://toolbox.googleapps.com/apps/dig.',
    },
    dkimDescription: {
      defaultMessage:
        'DKIM verification records are used to verify that the server sending an email ' +
        'has permission from the domain owner to send email on their behalf in order to ' +
        'prevent from addresses from being spoofed. This is necessary to prevent Transcend ' +
        'emails from being flagged as spam. DNS records can take up to 24h to become visible ' +
        'as they propagate up the DNS chain. These records are publicly visible and can be ' +
        'checked in your terminal or on a DNS record viewer website like ' +
        'https://toolbox.googleapps.com/apps/dig.',
    },
    mxDescription: {
      defaultMessage:
        'MX records are used by email servers to identify where to send incoming mail. ' +
        'As a result, these settings can direct mail to any server, so be careful when ' +
        'selecting and configuring a domain. DNS records can take up to 24h to become ' +
        'visible as they propagate up the DNS chain. Please consider:',
    },
    mxBulletAllMail: {
      defaultMessage:
        'Configuring a subdomain with a MX record will route ALL mail sent to that ' +
        'subdomain to Transcend. Therefore, it is important that a subdomain is chosen ' +
        'that is not already in use, otherwise ALL emails for that subdomain will be ' +
        'routed to Transcend.',
    },
    mxBulletExtraInput: {
      defaultMessage:
        'Some DNS registrars split the MX record value into two text fields, the priority and ' +
        'value. In this case, enter the number shown below in the priority field and the ' +
        'text in the value field',
    },
    confirmRestartVerification: {
      defaultMessage:
        'This will restart the entire verification process and generate new DNS records ' +
        'to set. Are you sure you want to restart?',
    },
    failedToVerify: {
      defaultMessage:
        'These records failed to be verified. This means that the DNS records were not ' +
        'visible or correct after 5 days of checking. It is important that the values ' +
        'listed below are copied exactly, otherwise the verification will fail. DNS ' +
        'records can also take up to 24h to become visible, so please ensure that there ' +
        'is enough time for the DNS to become visible before the testing period ends. ' +
        'DNS records are publicly visible and can be checked in your terminal or on a ' +
        'DNS record viewer website like https://toolbox.googleapps.com/apps/dig.',
    },
    emptyOrDefaultValue: {
      defaultMessage: 'Empty or existing value',
    },
    emptyValueTooltip: {
      defaultMessage:
        'If the DNS record exists, leave it as the current value, otherwise leave it empty',
    },
  },
);
