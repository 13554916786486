import { createSelector } from 'reselect';

import { BACKEND_API_URLS } from '../../args';
import { AppState } from './slice';

export const getAppView = (state: any): AppState => state.AdminDashboardAuth;

/**
 * The currently logged in user
 */
export const selectUser = createSelector(getAppView, ({ user }) => user);

/**
 * An indicator that the data subject is not able to authenticate to sombra
 */
export const selectNoSombraAuth = createSelector(
  getAppView,
  ({ noSombraAuth }) => noSombraAuth,
);

/**
 * The unwrapped session secret that the admin dashboard can present to sombra
 */
export const selectSombraSessionSecret = createSelector(
  getAppView,
  ({ sombraSessionSecret }) => sombraSessionSecret,
);

/**
 * The selected backend URL.
 */
export const selectBackendUrl = createSelector(
  getAppView,
  ({ selectedBackend }) => selectedBackend?.url,
);

/**
 * The selected backend URL or the default API url if none is selected
 */
export const selectSelectedBackendOrDefault = createSelector(
  selectBackendUrl,
  (backendUrl) => backendUrl || BACKEND_API_URLS[0],
);

/**
 *  The logged in user enforced to be non-null
 */
export const selectRequiredUser = createSelector(selectUser, (user) => {
  if (user) {
    return user;
  }
  throw new Error('Missing user in selectors');
});

/**
 * Indicates if there is currently a logged in user
 */
export const selectUserLoggedIn = createSelector(selectUser, (user) => !!user);

/**
 * Indicates if the currently logged in user is an admin of the organization
 */
export const selectIsAdmin = createSelector(
  selectUser,
  (user) => !!user?.isAdmin,
);

/**
 * The profile picture url
 */
export const selectProfilePicture = createSelector(
  selectUser,
  (user) => user?.profilePicture,
);

/**
 * The set of employee authentication methods enabled for the organization
 */
export const selectEmployeeAuthenticationMethods = createSelector(
  selectUser,
  (user) => user?.organization?.sombra?.employeeAuthenticationMethods,
);

/**
 * The uuid of the currently logged in user
 */
export const selectUserId = createSelector(selectUser, (user) => user?.id);

/**
 * The core organization information attached to the user
 */
export const selectOrganization = createSelector(
  selectUser,
  (user) => user?.organization,
);

/**
 * The core organization enforced to be non-null
 */
export const selectRequiredOrganization = createSelector(
  selectOrganization,
  (organization) => {
    if (organization) {
      return organization;
    }
    throw new Error('Missing organization in selectors');
  },
);

/**
 * The sombra instance
 */
export const selectSombra = createSelector(
  selectRequiredOrganization,
  (organization) => organization.sombra,
);

/**
 * Indicates if the user is attached to an organization
 */
export const selectHasOrganization = createSelector(
  selectOrganization,
  (organization) => !!organization,
);

/**
 * The name of the active organization
 */
export const selectOrganizationName = createSelector(
  selectOrganization,
  (organization) => organization?.name,
);

/**
 * The logo for the active organization
 */
export const selectOrganizationLogoSrc = createSelector(
  selectOrganization,
  (organization) => organization?.logo?.src,
);

/**
 * The favicon for the active organization
 */
export const selectOrganizationFaviconSrc = createSelector(
  selectOrganization,
  (organization) => organization?.favicon?.src,
);

/**
 * The redirect location when the Transcend logo is clicked
 */
export const selectHomePath = createSelector(
  selectOrganization,
  (organization) => organization?.deployedPrivacyCenterUrl || '/',
);

/**
 * The organization's tier
 */
export const selectTier = createSelector(
  selectRequiredOrganization,
  (organization) => organization.tier,
);

/**
 * Get the preferences for the user and the organization
 */
export const selectPreferences = createSelector(
  getAppView,
  ({ organizationPreference, userPreference }) => ({
    organizationPreference,
    userPreference,
  }),
);

/**
 * The public and private keys for Sombra communication
 * used when logging in to sombra via SSO. These dh encryption
 * keys are only needed temporarily when sombra redirects, they are then
 * useless after that
 */
export const selectDhEncryptionKeysForSsoLogin = createSelector(
  getAppView,
  ({ dhEncryptionKeysForSsoLogin }) => dhEncryptionKeysForSsoLogin,
);

/**
 * The sombra public key to use when using the unauthenticated
 * AVC flow. This key is ignored when a user is authenticated
 */
export const selectSombraPublicKeyEcdhForAvc = createSelector(
  getAppView,
  ({ sombraPublicKeyEcdhForAvc }) => sombraPublicKeyEcdhForAvc,
);

/**
 * The sombra ECDH public key. When the user is authenticated
 * via a session, the public key of that account is always returned.
 *
 * For the unauthenticated bulk respond (AVC) view - the `organization`
 * will be undefined, and instead the sombraPublicKey must be explicitly set
 * in the redux store
 */
export const selectSombraPublicKeyECDH = createSelector(
  selectSombraPublicKeyEcdhForAvc,
  selectOrganization,
  (sombraPublicKeyEcdhForAvc, organization) => {
    if (organization) {
      return organization.sombra.publicKeyECDH!;
    }
    return sombraPublicKeyEcdhForAvc || '';
  },
);
