import { ConfigurablePurpose } from '@transcend-io/airgap.js-types';
import {
  CspOption,
  UnknownRequestPolicy as SimpleUnknownRequestPolicy,
} from '@transcend-io/privacy-types';
import { invert, Optionalize } from '@transcend-io/type-utils';

import { UnknownRequestPolicy } from '@main/ag-types';

import type { ConsentManagerUI } from './schema';
import {
  DateRange,
  DefaultConsentOption,
  TranscendConsentManagerScript,
  TranscendConsentManagerUI,
} from './schema/enums';

/**
 * Relative URL of Transcend Modules
 */
export const TranscendScriptLocation = {
  [TranscendConsentManagerScript.ui]: 'ui.js',
  [TranscendConsentManagerScript.tcfUi]: 'tcf/ui.js',
  [TranscendConsentManagerScript.gpp]: 'gpp.js',
} as const;

export const DEFAULT_UIS: Optionalize<ConsentManagerUI, 'id'>[] = [
  {
    name: TranscendConsentManagerUI.ui,
    scriptLocation: TranscendScriptLocation.ui,
  },
  {
    name: TranscendConsentManagerUI.tcfUi,
    scriptLocation: TranscendScriptLocation.tcfUi,
  },
];

// This must be kept in sync with the 'OCCURRENCES_SYNC_PERIOD_IN_HOURS' value
// in backend-services/airgap-telemetry/infra/src/index.ts
export const OCCURRENCES_SYNC_PERIOD_IN_HOURS = 4;

/**
 * Mapping of the CSPPolicyDirectives used by airgap.js API to the simpler options
 * that we expose in the Consent Manager configuration dashboard.
 */
export const AIRGAP_CSP_TO_SIMPLE_CSP_OPTION = {
  strict: CspOption.Strict,
  'allow-subdomains': CspOption.On,
  'allow-known-hosts allow-subdomains': CspOption.Lax,
  off: CspOption.Off,
} as const;

/**
 * Mapping of the CSP options that we expose in the Consent Manager dashboard
 * to those used by airgap.js API
 */
export const SIMPLE_CSP_OPTION_TO_AIRGAP_CSP = invert(
  AIRGAP_CSP_TO_SIMPLE_CSP_OPTION,
);

/**
 * Mapping of the UnknownRequestPolicy options that airgap.js core uses to the options
 * that we expose in the Consent Manager configuration dashboard/GraphQL API
 */
export const AIRGAP_UNKNOWN_REQUEST_POLICY_TO_SIMPLE_POLICY: {
  [k in UnknownRequestPolicy]: SimpleUnknownRequestPolicy;
} = {
  allow: SimpleUnknownRequestPolicy.Allow,
  block: SimpleUnknownRequestPolicy.Block,
  'require-full-consent': SimpleUnknownRequestPolicy.RequireFullConsent,
};

/**
 * Mapping of the UnknownRequestPolicy options that we expose in the Consent Manager configuration dashboard/GraphQL API
 * to the options that airgap.js core uses
 */
export const SIMPLE_POLICY_TO_AIRGAP_UNKNOWN_REQUEST_POLICY = invert(
  AIRGAP_UNKNOWN_REQUEST_POLICY_TO_SIMPLE_POLICY,
);

/**
 * Mapping of the DefaultConsentOption used by airgap.js API to the simpler options
 * that we expose in the Consent Manager configuration dashboard.
 */
export const AIRGAP_DEFAULT_CONSENT_TO_SIMPLE_DEFAULT_CONSENT = {
  off: DefaultConsentOption.OptIn,
  Auto: DefaultConsentOption.OptOut,
} as const;

/**
 * Mapping of the default consent options that we expose in the Consent Manager dashboard
 * to those used by airgap.js API
 */
export const SIMPLE_DEFAULT_CONSENT_TO_AIRGAP_DEFAULT_CONSENT = invert(
  AIRGAP_DEFAULT_CONSENT_TO_SIMPLE_DEFAULT_CONSENT,
);

/**
 * Regular expressions for known junk that sometimes appears in cookie names
 */
export const KNOWN_COOKIE_JUNK_MATCHERS = [
  /^[0-9]+\_@@@\_/, // e.g. 1629193174_@@@__dd_s or 2_@@@_mglts
  /^ST\*[0-9]{7,11}\*/, // e.g. ST*36432051*_fbp
];

/**
 * Mapping of DateRange to acceptable Amazon Timestream bin interval.
 */
export const DATE_RANGE_TO_BIN_INTERVAL: {
  [K in DateRange]: string;
} = {
  [DateRange.SevenDays]: '1d',
  [DateRange.ThirtyDays]: '1d',
  [DateRange.OneYear]: '30d',
};
export const BIN_INTERVAL_TO_DATE_RANGE = invert(
  DATE_RANGE_TO_BIN_INTERVAL,
  false,
);

/**
 * Mapping of bin intervals to a duration in integer form.
 */
export const BIN_INTERVAL_TO_DURATION: {
  [K in (typeof DATE_RANGE_TO_BIN_INTERVAL)[keyof typeof DATE_RANGE_TO_BIN_INTERVAL]]: number;
} = {
  [DATE_RANGE_TO_BIN_INTERVAL[DateRange.SevenDays]]: 1,
  [DATE_RANGE_TO_BIN_INTERVAL[DateRange.ThirtyDays]]: 1,
  [DATE_RANGE_TO_BIN_INTERVAL[DateRange.OneYear]]: 30,
};

const YESTERDAY = new Date();
YESTERDAY.setDate(YESTERDAY.getDate() - 1 || 1);

// Map a DateRange to binInterval/start/end analytics inputs
export const DATE_RANGE_TO_ANALYTICS_INPUT: {
  [K in DateRange]: {
    /** Bin interval */
    binInterval: string;
    /** Start date epoch ms */
    start: number;
    /** End date epoch ms */
    end: number;
  };
} = {
  [DateRange.SevenDays]: {
    binInterval: DATE_RANGE_TO_BIN_INTERVAL[DateRange.SevenDays],
    start: new Date().setUTCDate(YESTERDAY.getUTCDate() - 6),
    end: YESTERDAY.getTime(),
  },
  [DateRange.ThirtyDays]: {
    binInterval: DATE_RANGE_TO_BIN_INTERVAL[DateRange.ThirtyDays],
    start: new Date().setUTCDate(YESTERDAY.getUTCDate() - 29),
    end: YESTERDAY.getTime(),
  },
  [DateRange.OneYear]: {
    binInterval: DATE_RANGE_TO_BIN_INTERVAL[DateRange.OneYear],
    start: new Date().setUTCDate(YESTERDAY.getUTCDate() - 364),
    end: YESTERDAY.getTime(),
  },
};

/**
 * The default theme settings for a new bundle.
 */
export const DEFAULT_CONSENT_MANAGER_THEME = {
  primaryColor: '#3333FF',
  fontColor: '#010101',
  prompt: 0,
};

/**
 *  the minimum recommended airgap version, used for creating "please upgrade
 *  consent manager" action item
 *  TODO: https://transcend.height.app/T-25408 - remove when new changelog is merged
 */
export const MINIMUM_AIRGAP_RECOMMENDED_VERSION = '7.24.0';

export const DEFAULT_TCF_AIRGAP_PURPOSE_MAP = {
  'Store and/or access information on a device': {
    tcfPurposeId: 1,
    trackingTypes: [ConfigurablePurpose.Functional],
  },
  'Select basic ads': {
    tcfPurposeId: 2,
    trackingTypes: [ConfigurablePurpose.Advertising],
  },
  'Create a personalised ads profile': {
    tcfPurposeId: 3,
    trackingTypes: [
      ConfigurablePurpose.Advertising,
      ConfigurablePurpose.Analytics,
    ],
  },
  'Use profiles to select personalised advertising': {
    tcfPurposeId: 4,
    trackingTypes: [ConfigurablePurpose.Advertising],
  },
  'Create profiles to personalise content': {
    tcfPurposeId: 5,
    trackingTypes: [ConfigurablePurpose.Analytics],
  },
  'Use profiles to select personalised content': {
    tcfPurposeId: 6,
    trackingTypes: [ConfigurablePurpose.Analytics],
  },
  'Measure advertising performance': {
    tcfPurposeId: 7,
    trackingTypes: [ConfigurablePurpose.Advertising],
  },
  'Measure content performance': {
    tcfPurposeId: 8,
    trackingTypes: [ConfigurablePurpose.Analytics],
  },
  'Understand audiences through statistics or combinations of data from different sources':
    {
      tcfPurposeId: 9,
      trackingTypes: [ConfigurablePurpose.Analytics],
    },
  'Develop and improve services': {
    tcfPurposeId: 10,
    trackingTypes: [ConfigurablePurpose.Analytics],
  },
  'Use limited data to select content': {
    tcfPurposeId: 11,
    trackingTypes: [ConfigurablePurpose.Functional],
  },
};

/** Purpose Intl message prefix */
export const PURPOSE_PREFIX = 'purpose.trackingType';

/**
 * Helper function to get the intl message id for a preference topic
 *
 * @param trackingType - the tracking type of the purpose
 * @param topicSlug - the slug of the preference topic
 * @param type - the type of message (title or description)
 * @returns the intl message id
 */
export const getPreferenceTopicMessageId = (
  trackingType: string,
  topicSlug: string,
  type: 'title' | 'description',
): string =>
  `${PURPOSE_PREFIX}.${trackingType}.preferenceTopic.${topicSlug}.${type}`;

/**
 * Helper function to get the intl message id for a preference option
 *
 * @param slug - the slug of the preference option
 * @param type - the type of message (title or description)
 * @returns the intl message id
 */
export const getPreferenceOptionMessageId = (
  slug: string,
  type: 'title' | 'description',
): string => `preferenceOption.${slug}.${type}`;

/**
 * Helper function to get the intl message id for a purpose
 *
 * @param trackingType - the tracking type of the purpose
 * @param type - the type of message (title or description)
 * @returns the intl message id
 */
export const getPurposeMessageId = (
  trackingType: string,
  type: 'title' | 'description',
): string => `${PURPOSE_PREFIX}.${trackingType}.${type}`;

/**
 * The name of the custom field to use for double opt-in requests
 * TODO: https://transcend.height.app/T-40163 - remove this when native solution exists
 */
export const DOUBLE_OPT_IN_CUSTOM_FIELD_NAME = 'Double Opt-In';

/**
 * The custom field value to use to trigger double opt-in requests
 * TODO: https://transcend.height.app/T-40163 - remove this when native solution exists
 */
export const DOUBLE_OPT_IN_CUSTOM_FIELD_VALUE = 'true';
