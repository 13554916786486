import { makeEnum } from '@transcend-io/type-utils';

import { AwsRegion } from '@main/utils';

import { AuditorGQLRegion } from '../../constants';

/** AWS Region enum values */
type AuditorRegionI = keyof typeof AwsRegion;

/** Auditor run geographic region GQL enum */
export const AuditorGQLRegionEnum = makeEnum<
  Partial<{ [k in AuditorRegionI]: string }>,
  string
>(AuditorGQLRegion);
