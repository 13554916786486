import { FlexRowCenterBoth, Icon, StyleUtils } from '@main/core-ui';
import { DefinedMessage } from '@transcend-io/internationalization';
import React, { MouseEventHandler, useState } from 'react';
import { useIntl } from 'react-intl';

import { Button } from '../Button';
import { Modal } from '../Modal';
import { confirmDeleteModalMessages } from './messages';

interface ConfirmDeleteModalProps {
  /** The button component that when clicked displays the modal */
  button?: JSX.Element;
  /** The message for both the button to open the modal, and the button to confirm deletion within the modal */
  buttonMessage: DefinedMessage;
  /** The text beneath the title that will prompt the user to delete the items or cancel */
  promptMessage: DefinedMessage;
  /** onClick handler for delete button */
  handleDelete: MouseEventHandler<HTMLButtonElement>;
  /** Number of items that will be deleted */
  count?: number;
  /** Whether the delete mutation is currently running */
  loading?: boolean;
  /** Whether the modal should be visible */
  modalVisible?: boolean;
  /** Whether the modal should be visible */
  setModalVisible?: (b: boolean) => void;
}

export const ConfirmDeleteModal: React.FC<ConfirmDeleteModalProps> = ({
  button,
  buttonMessage,
  promptMessage,
  handleDelete,
  count = 1,
  loading,
  modalVisible,
  setModalVisible,
}) => {
  const [visible, setVisible] = useState(false);
  const { formatMessage } = useIntl();

  return (
    <>
      {button ?? (
        <Button
          variant="danger"
          onClick={() => setVisible(true)}
          icon={<Icon type="trash" />}
          loading={loading ?? false}
        >
          {formatMessage(buttonMessage, { count })}
        </Button>
      )}
      <Modal
        centerHeader
        show={modalVisible ?? visible}
        onHide={() =>
          setModalVisible ? setModalVisible(false) : setVisible(false)
        }
        header={confirmDeleteModalMessages.confirm}
      >
        <div
          style={{ textAlign: 'center', marginBottom: StyleUtils.Spacing.xl }}
        >
          <p>{formatMessage(promptMessage, { count })}</p>
        </div>
        <FlexRowCenterBoth style={{ gap: StyleUtils.Spacing.md }}>
          <Button
            variant="outline-secondary"
            onClick={() =>
              setModalVisible ? setModalVisible(false) : setVisible(false)
            }
          >
            {formatMessage(confirmDeleteModalMessages.cancelButton)}
          </Button>

          <Button variant="danger" onClick={handleDelete}>
            {formatMessage(buttonMessage, { count })}
          </Button>
        </FlexRowCenterBoth>
      </Modal>
    </>
  );
};
