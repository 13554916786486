import { endpoints as accessControlEndpoints } from '@main/access-control-types';
import {
  AUDIT_EVENT_CODES_BASE_GROUPS,
  AuditEventBaseModelCode,
  AuditEventCode,
  GROUPED_AUDIT_EVENT_CODES,
} from '@main/audit-types';
import { AuditEventFiltersInput } from '@main/audit-types/src/schema';
import { buildUseLazyQuery } from '@main/core-ui';
import pickBy from 'lodash/pickBy';
import uniq from 'lodash/uniq';
import xor from 'lodash/xor';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';

import { useDateRangeFilters } from '../../DatePicker/useDateRangeFilters';
import {
  FilterConfigItem,
  FilterManagerProps,
  FiltersConfig,
} from '../../FilterManager';
import { CheckboxInputGroup, RadioInputGroup } from '../../MultipleChoice';
import { OrganizationIcon } from '../../OrganizationIcon';
import { SelectedUser, SelectUser } from '../../SelectUser';
import { AuditTrailEnrichedFilters } from '../types';
import {
  auditEventCodeFilterLabelMessages,
  auditTrailFilterMessages,
} from './messages';

const useLazyUsers = buildUseLazyQuery(accessControlEndpoints.users);

export interface AdditionalFilterOptions {
  /** Property/value pairs for any additional items we are including in the Filter Manager */
  filterValues?: AuditTrailEnrichedFilters;
  /** Any additional items to include in the Filter Manager */
  filterConfigItems?: FilterConfigItem<AuditTrailEnrichedFilters>[];
  /** Clear callbacks for any additional filters */
  clearFilters?: Array<
    (
      key: keyof AuditEventFiltersInput,
      setFilters: (filters: AuditEventFiltersInput) => void,
      filters?: AuditEventFiltersInput,
    ) => void
  >;
}

/** Hook that returns default props for audit trail filters */
type BuildAuditTrailFilters = (
  filterParams: AdditionalFilterOptions & {
    /** Codes permitted for this audit trail */
    allowedCodes: AuditEventCode[];
    /** The currently applied filters */
    filters?: AuditEventFiltersInput;
    /** Callback when the filters are changed */
    setFilters: (filters: AuditEventFiltersInput) => void;
  },
) => FilterManagerProps<AuditTrailEnrichedFilters>;

export const useAuditTrailFilters: BuildAuditTrailFilters = ({
  allowedCodes,
  filters,
  setFilters,
  filterValues,
  filterConfigItems,
  clearFilters,
}) => {
  const { formatMessage } = useIntl();
  const [selectedCodes, setSelectedCodes] = useState<AuditEventBaseModelCode[]>(
    [],
  );

  const groupedAuditCodes = uniq(
    allowedCodes.flatMap((code) => AUDIT_EVENT_CODES_BASE_GROUPS[code]),
  );

  const [selectedActorUsers, setSelectedActorUsers] = useState<SelectedUser[]>(
    [],
  );
  const [selectedIsAutomated, setSelectedIsAutomated] =
    useState<AuditTrailEnrichedFilters['isAutomated']>();

  const getSelectedActorUsers = useLazyUsers();

  const {
    dateRange: createdAt,
    dateRangeFiltersConfig: createdAtFiltersConfig,
    clearDateRangeFilters: clearCreatedAtFilters,
  } = useDateRangeFilters({
    dateRangeStartFilterKey: 'createdAtBefore',
    dateRangeEndFilterKey: 'createdAtAfter',
    label: auditTrailFilterMessages.createdAt,
    filters,
    setFilters,
    enrichedDateRangeKey: 'createdAt',
  });

  const enrichedFilters: AuditTrailEnrichedFilters = useMemo(
    () =>
      pickBy(
        {
          ...filters,
          actorUsers: selectedActorUsers,
          codes: selectedCodes,
          isAutomated: selectedIsAutomated,
          ...filterValues,
          createdAt,
        },
        (v) => (Array.isArray(v) ? v.length > 0 : v !== undefined),
      ),
    [
      filters,
      createdAt,
      filterValues,
      selectedCodes,
      selectedActorUsers,
      selectedIsAutomated,
    ],
  );

  // Populate initial complex filter values
  useEffect(() => {
    if (filters) {
      if (filters.actorUserIds && filters.actorUserIds.length > 0) {
        getSelectedActorUsers({ filterBy: { ids: filters.actorUserIds } }).then(
          ({ data }) => {
            setSelectedActorUsers(data.nodes);
          },
        );
      }

      if (filters.codes && filters.codes.length > 0) {
        // only set the codes as "selected codes" if they are different than the defaults for this audit trail
        if (xor(filters.codes, allowedCodes).length > 0) {
          setSelectedCodes([
            // use Set to get only unique values
            ...new Set(
              filters.codes.flatMap(
                (code) => AUDIT_EVENT_CODES_BASE_GROUPS[code],
              ),
            ),
          ]);
        }
      }

      if (typeof filters.isAutomated !== 'undefined') {
        setSelectedIsAutomated(filters.isAutomated ? 'true' : 'false');
      }
    }
  }, []);

  const filtersConfig: FiltersConfig<AuditTrailEnrichedFilters> = useMemo(
    () => [
      createdAtFiltersConfig as FilterConfigItem<AuditTrailEnrichedFilters>,
      {
        filterKey: 'actorUsers',
        label: auditTrailFilterMessages.actorUsersLabel,
        pillOptions: {
          style: {
            display: 'flex',
            alignItems: 'center',
          },
          label: ({ filterValues: { actorUsers = [] }, index = 0 }) => (
            <>
              <OrganizationIcon
                organizationIconSrc={actorUsers[index]?.profilePicture}
                size={18}
                style={{
                  borderRadius: '10em',
                  fontSize: '12px',
                  marginRight: '3px',
                }}
              />
              {actorUsers[index]?.name}
            </>
          ),
        },
        filter: (
          <SelectUser
            isMulti
            menuPosition="absolute"
            value={selectedActorUsers}
            showOutline={false}
            onChange={(actorUsers) => {
              setSelectedActorUsers(actorUsers);
              setFilters({
                ...filters,
                actorUserIds: actorUsers
                  .filter(({ id }) => id)
                  .map(({ id }) => id),
              });
            }}
          />
        ),
      },
      {
        filterKey: 'codes',
        label: auditTrailFilterMessages.codesLabel,
        pillOptions: {
          label: ({ filterValues: { codes }, index = 0 }) =>
            codes &&
            formatMessage(auditEventCodeFilterLabelMessages[codes[index]]),
        },
        filter: (
          <CheckboxInputGroup
            hideLegend
            title={auditTrailFilterMessages.codesLabel}
            onChange={(selectedCodes) => {
              setSelectedCodes(selectedCodes);
              setFilters({
                ...filters,
                codes: selectedCodes.flatMap(
                  (code) => GROUPED_AUDIT_EVENT_CODES[code],
                ),
              });
            }}
            options={groupedAuditCodes.map((code) => ({
              value: code,
              label: auditEventCodeFilterLabelMessages[code],
            }))}
          />
        ),
      },
      {
        filterKey: 'isAutomated',
        label: auditTrailFilterMessages.isAutomatedLabel,
        pillOptions: {
          label: ({ filterValues: { isAutomated } }) =>
            typeof isAutomated !== 'undefined' &&
            formatMessage(
              auditTrailFilterMessages[
                isAutomated === 'true'
                  ? 'isAutomatedTrueLabel'
                  : 'isAutomatedFalseLabel'
              ],
            ),
        },
        filter: (
          <RadioInputGroup
            value={selectedIsAutomated}
            onChange={(isAutomated) => {
              setSelectedIsAutomated(isAutomated);
              setFilters({
                ...filters,
                isAutomated:
                  typeof isAutomated === 'undefined'
                    ? isAutomated
                    : isAutomated === 'true',
              });
            }}
            hideLegend
            title={auditTrailFilterMessages.isAutomatedLabel}
            options={[
              {
                value: 'true',
                label: auditTrailFilterMessages.isAutomatedTrueLabel,
              },
              {
                value: 'false',
                label: auditTrailFilterMessages.isAutomatedFalseLabel,
              },
            ]}
          />
        ),
      },
      ...(filterConfigItems || []),
    ],
    [
      filters,
      createdAtFiltersConfig,
      setFilters,
      filterConfigItems,
      formatMessage,
      groupedAuditCodes,
      selectedActorUsers,
      selectedIsAutomated,
    ],
  );

  const clearFilter = useCallback(
    (key) => {
      (clearFilters || []).forEach((clearFilter) =>
        clearFilter(key, setFilters, filters),
      );
      clearCreatedAtFilters(key);
      if (key === 'actorUsers') {
        setSelectedActorUsers([]);
        setFilters({
          ...filters,
          actorUserIds: undefined,
        });
      } else if (key === 'codes') {
        setSelectedCodes([]);
        setFilters({
          ...filters,
          codes: [],
        });
      } else if (key === 'isAutomated') {
        setSelectedIsAutomated(undefined);
        setFilters({
          ...filters,
          isAutomated: undefined,
        });
      }
    },
    [clearFilters, filters],
  );

  return {
    filtersValues: enrichedFilters,
    filtersConfig,
    clearFilter,
  };
};
