import { defineMessages } from '@transcend-io/internationalization';

export const requestAssessmentFromInventoryMessages = defineMessages(
  'admin-dashboard.DataMap.components.RequestAssessmentFromInventory.requestAssessmentFromInventory',
  {
    buttonLabel: {
      defaultMessage: 'Request Assessment',
    },
    titleLabel: {
      defaultMessage: 'Assessment Title',
    },
    sectionTitle: {
      defaultMessage: 'Data Inventory Fields',
    },
    chooseColumnsLabel: {
      defaultMessage: 'Assessment Questions',
    },
    chooseColumnsDescription: {
      defaultMessage:
        'Choose columns to use as the questions in the assessment',
    },
    success: {
      defaultMessage:
        '{count, plural, one {Assessment} other {Assessments}} requested successfully!',
    },
    columnConfigError: {
      defaultMessage:
        'An error occurred creating an assessment with these columns. Please reach out to support.',
    },
    assignUsersLabel: {
      defaultMessage: 'Assignees for "{rowTitle}" Assessment',
    },
    sendInvitesButtonLabel: {
      defaultMessage: 'Send Invites',
    },
    createWithoutAssigningButtonLabel: {
      defaultMessage: 'Create Assessments without Assigning',
    },
    progress: {
      defaultMessage: 'Step {currentStep} of {totalSteps}',
    },
    viewAssessments: {
      defaultMessage: 'View Assessments',
    },
    returnToDataInventory: {
      defaultMessage: 'Return to Data Inventory',
    },
  },
);
