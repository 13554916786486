import { ColorPalette } from '@main/theme';
import React from 'react';

import { FlexRow } from '../StyledWrappers';
import { LeftLabelTagContainer } from './wrappers';

export interface LeftLabelTagProps {
  /** The main color of the tag, used for background or border */
  color: keyof ColorPalette;
  /** The tag variant, either filled or outline */
  variant: 'filled' | 'outline';
  /** Main label of the tag (left) */
  label: string | JSX.Element;
  /** the icon to appear at the leftmost point in the tag  */
  icon?: JSX.Element;
  /** Additional styles */
  style: React.CSSProperties;
}

export const LeftLabelTag: React.FC<LeftLabelTagProps> = ({
  color,
  variant,
  label,
  icon,
  style,
}): JSX.Element => (
  <LeftLabelTagContainer
    color={color}
    variant={variant}
    style={{
      ...style,
    }}
  >
    {icon && (
      <FlexRow as="span" style={{ marginRight: 7 }}>
        {icon}
      </FlexRow>
    )}
    {label}
  </LeftLabelTagContainer>
);
