import { defineMessages } from '@transcend-io/internationalization';

export const uploadImageMessages = defineMessages(
  'ad-core-components.UploadImage.uploadImage',
  {
    dropzonePlaceholder: {
      defaultMessage: 'Drop image here (optional)',
    },
    uploadImage: {
      defaultMessage: 'Upload Image',
    },
    changeImage: {
      defaultMessage: 'Change Image',
    },
    removeImage: {
      defaultMessage: 'Remove Image',
    },
    previewAlt: {
      defaultMessage: 'Preview of your uploaded image',
    },
    success: {
      defaultMessage: '{file} uploaded successfully',
    },
    error: {
      defaultMessage: '{file} upload failed',
    },
  },
);
