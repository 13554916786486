import { InlineUrlInput } from '@main/ad-core-components';
import {
  AssessmentAttributeStatus,
  AssessmentSecondaryType,
} from '@main/attribute-types';
import { Badge, Logo, StyleUtils } from '@main/core-ui';
import React, { useEffect, useRef } from 'react';
import { useIntl } from 'react-intl';
import { OptionsType } from 'react-select';

import { AssessmentFormBadge } from '../../../Assessments/components/AssessmentBadge';
import { AttributeKeyPreview, getAttributeValueColorName } from './constants';
import {
  assessmentAttributeStatusMessages,
  selectAttributeMessages,
} from './messages';
import {
  isCustomOption,
  SelectAttributeDefaultOption,
  SelectAttributeOption,
} from './types';

interface SelectAttributePillDisplayProps {
  /** The attribute key associated with these options */
  attributeKey?: AttributeKeyPreview;
  /** the option to display */
  option: SelectAttributeOption;
  /** is the pill in the dropdown menu or is it a value */
  isInMenu: boolean;
  /** Callback to fire when the input loses focus */
  onBlur: (event?: React.FocusEvent) => void;
  /** Helper to fill out all option info when creating a new option */
  getNewOptionData: (
    inputValue: string,
    optionLabel?: React.ReactNode,
  ) => SelectAttributeDefaultOption;

  /** Reference to React Select component */
  // selectRef: React.RefObject<Select>;
  /** The selected attribute options */
  values: SelectAttributeOption[];
  /** Function to set updated values */
  onChange: (
    selected: SelectAttributeOption | OptionsType<SelectAttributeOption> | null,
  ) => void;
}

export const SelectAttributePillDisplay: React.FC<
  SelectAttributePillDisplayProps
> = ({
  attributeKey,
  isInMenu,
  getNewOptionData,
  option,
  onBlur,
  onChange,
  values,
}) => {
  const { formatMessage } = useIntl();
  const urlInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (!isInMenu && option.id === 'url') {
      urlInputRef?.current?.focus();
    }
  }, [isInMenu, option.id]);

  if (!isCustomOption(option) && option.assessmentForm) {
    return (
      <AssessmentFormBadge
        id={option.assessmentForm!.id}
        isLinked={false}
        status={option.assessmentForm!.status}
      >
        {option.name}
      </AssessmentFormBadge>
    );
  }

  if (
    !isInMenu &&
    ((!isCustomOption(option) &&
      option.assessmentSecondaryType === AssessmentSecondaryType.Url) ||
      option.id === 'url')
  ) {
    return (
      // Safe to disable because we are preventing propagation, not actually providing an interaction.
      // eslint-disable-next-line jsx-a11y/no-static-element-interactions
      <span
        // Stops react-select menu from opening when clicking buttons or pressing space with the InlineUrlInput
        onMouseDown={(e) => e.stopPropagation()}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <InlineUrlInput
          style={{
            height: 30,
          }}
          ref={urlInputRef}
          value={option.id === 'url' ? '' : values[0]?.name}
          showPlaceholder
          placeholder={formatMessage(
            selectAttributeMessages.assessmentUrlPlaceholder,
            {
              templateTitle: attributeKey?.assessmentFormTemplate?.title || '',
            },
          )}
          onChange={(e: any) => {
            onChange({
              ...getNewOptionData(e.target.value),
              type: 'default',
              assessmentSecondaryType: AssessmentSecondaryType.Url,
              canDelete: true,
              color: 'white',
            });
          }}
          // don't save an empty string
          onBlur={(e) => (values[0]?.name ? onBlur(e) : undefined)}
        />
      </span>
    );
  }

  return (
    <Badge
      ignoreAccessibilityAndUseLightText={
        (!!option?.color && option.color.slice(-1) === '1') ||
        option.name === AssessmentAttributeStatus.Needed
      }
      color={getAttributeValueColorName(option)}
      style={
        isCustomOption(option)
          ? {
              display: 'flex',
              alignItems: 'center',
              gap: StyleUtils.Spacing.xs,
            }
          : undefined
      }
    >
      {isCustomOption(option) &&
        (typeof option.logo === 'string' ? (
          <Logo logo={option.logo} height="20px" />
        ) : (
          option.logo
        ))}
      {'assessmentSecondaryType' in option &&
      option.assessmentSecondaryType === AssessmentSecondaryType.Status
        ? formatMessage(
            assessmentAttributeStatusMessages[
              option.name as AssessmentAttributeStatus
            ],
            {
              templateTitle: attributeKey?.assessmentFormTemplate?.title || '',
            },
          )
        : option.name}
    </Badge>
  );
};
