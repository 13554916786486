import { statusDescriptionMessages, statusTitleMessages } from './messages';
import type { RequestStatusConfig } from './types';

/**
 * ## Identity Verification Failed
 * We attempted to verify some of the request identifiers, and at least one of them failed to be verified.
 *
 * When a request comes in, we attempt to verify all identifiers that the organization has not attested to. (Currently this is only email)
 * We will send out each identifier verification (i.e. send email), and if the identifier is not verified within a time interval, it is
 * invalidated and the entire request is killed.
 *
 * In the future, the organization will also have the option of also deleting or hashing the identifiers/profile ids we store to query data.
 *
 * ### ON CHANGE TO
 *  - Any files stored for the request are deleted.
 */
export const FAILED_VERIFICATION_STATUS: RequestStatusConfig = {
  color: 'warning',
  icon: 'user-exclamation',
  isClosed: true,
  isError: true,
  canArchive: true,
  messages: {
    description: statusDescriptionMessages.FAILED_VERIFICATION,
    title: statusTitleMessages.FAILED_VERIFICATION,
  },
  transitions: [],
};
