import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { logger } from '../logger';
import { addQueryParams } from '../utils/location';
import { useQueryParams } from './useQueryParams';

/**
 * Grab the current page from a query param, parsing to int
 *
 * @param rawFromQueryParam - The page query param or undefined if not existing
 * @returns The page parsed as int
 */
export function getPageFromQueryParam(
  rawFromQueryParam: string | undefined,
): number {
  // attempt to parse the query parameter or default to page 1
  return parseInt(rawFromQueryParam || '1', 10);
}

export interface PaginationOptions {
  /** The name of the query parameter to use to determine current page */
  queryParam?: string;
  /**
   * The name of the page size query parameter to use to determine current page
   * Set to empty string to disable query param caching
   */
  pageSizeQueryParam?: string;
  /** Additional callback on change */
  onChange?: () => void;
  /** List of dependencies that should cause the page to be reset to 1 (usually when the query params have changed) */
  resetPageOnChange?: any[];
  /** The page size, used in table options */
  pageSize?: number;
}

const useIsMount = (): boolean => {
  const isMountRef = useRef(true);
  useEffect(() => {
    isMountRef.current = false;
  }, []);
  return isMountRef.current;
};

/**
 * Return type for usePagination hook
 */
export interface UsePaginationResult {
  /** The index of the current page */
  currentPage: number;
  /** Set the current page */
  setCurrentPage: Dispatch<SetStateAction<number>>;
  /**  Clamp the max page number, based on total results and page size */
  useMaxPage: (max?: number) => void;
  /** The page size */
  pageSize: number;
  /** Setter for page size */
  setPageSize: (pageSize: number) => void;
  /** Options for controlled pagination */
  tableOptions: {
    /** Enable manual pagination */
    manualPagination: true;
    /** Control pagination state */
    useControlledState: (state: any) => any;
  }; // tablePaginationOptions
}

/**
 * Hook to handle pagination.
 *
 * This hook is also capable of storing the current page in a query parameter,
 * allow for backwards/forward navigation to a paginated view.
 * i.e. /data-map?page=4
 *
 * This component does NOT make any network requests directly. It's just
 * a helper to manage pagination state & syncing it with any pagination query parameters.
 *
 * @param options - pagination options
 * @returns the current page, a function to handle page changes, and a function to set the max page
 */
export function usePagination(
  options: PaginationOptions = {},
): UsePaginationResult {
  const {
    onChange,
    queryParam,
    pageSizeQueryParam = 'pageSize',
    resetPageOnChange = [],
    pageSize: initialPageSize = 10,
  } = options;
  const location = useLocation();
  const navigate = useNavigate();
  const isMount = useIsMount();
  const [maxPageNumber, setMaxPageNumber] = useState<number>();

  // We have the option of letting the page persist to the query parameter
  const queryParams = useQueryParams();
  const rawFromQueryParam = !queryParam ? undefined : queryParams[queryParam];
  const rawQueryParamPageSize =
    pageSizeQueryParam && pageSizeQueryParam in (queryParams ?? {})
      ? queryParams[pageSizeQueryParam]
      : undefined;
  const queryParamPageSize =
    rawQueryParamPageSize !== undefined
      ? parseInt(rawQueryParamPageSize, 10)
      : undefined;

  // attempt to parse the query parameter
  const queryParamValue = getPageFromQueryParam(rawFromQueryParam);

  // The current page
  const [currentPage, setCurrentPageRaw] = useState(
    queryParam ? queryParamValue || 1 : 1,
  );
  const [pageSize, setPageSizeRaw] = useState(
    queryParamPageSize || initialPageSize,
  );

  const setPageSize = useCallback(
    (pageSize: number) => {
      setPageSizeRaw(pageSize);

      if (pageSizeQueryParam) {
        const updated = addQueryParams(location, {
          [pageSizeQueryParam]: pageSize.toString(),
        });
        navigate(updated);
      }
    },
    [location, navigate, pageSizeQueryParam],
  );

  // need to return a setter function because usePagination and useQuery depend
  // on each other's results, so this is the only way to get the results back
  // from useQuery
  const useMaxPage = (max?: number): void => {
    useEffect(() => {
      if (!max) {
        return;
      }
      // round the max page up and clamp to >1
      const roundedPage = Math.max(Math.ceil(max), 1);
      if (maxPageNumber !== roundedPage) {
        setMaxPageNumber(roundedPage);
      }
    }, [max]);
  };

  // clamp the page number to the acceptable range
  const clampPageNumber = (
    page: ((prevState: number) => number) | number,
    currentValue: number,
  ): number => {
    // page number is at least 1
    const clampedLow = Math.max(
      1,
      typeof page === 'function' ? page(currentValue) : page,
    );
    // page number is at most maxPageNumber (if set)
    return maxPageNumber ? Math.min(clampedLow, maxPageNumber) : clampedLow;
  };

  const setCurrentPage: Dispatch<SetStateAction<number>> = (page) =>
    setCurrentPageRaw((currentValue) => clampPageNumber(page, currentValue));

  // Function to set the local state and query param (if using) to a given page
  const setPageAndQueryParam: Dispatch<SetStateAction<number>> = (page) => {
    const clampedPage = clampPageNumber(page, currentPage);
    setCurrentPage(clampedPage);
    if (
      queryParam &&
      ((!rawFromQueryParam && clampedPage !== 1) ||
        queryParamValue !== clampedPage)
    ) {
      const updated = addQueryParams(location, {
        [queryParam]: clampedPage.toString(),
      });
      navigate(updated);
    }
    if (onChange) {
      onChange();
    }
  };

  useEffect(() => {
    if (!isMount) {
      logger.info(`Resetting query param: ${queryParam}`);
      setPageAndQueryParam(1);
    }
  }, resetPageOnChange);

  // Sync with the query param
  useEffect(() => {
    setPageSizeRaw(queryParamPageSize || initialPageSize);

    // No sync needed when relying on local state
    if (!queryParam) {
      return;
    }

    // if this is true, the query parameter has changed and we should update the query
    if (queryParamValue !== currentPage) {
      setCurrentPage(queryParamValue || 1);
    }
  }, [queryParamValue, queryParamPageSize, initialPageSize]);

  useEffect(() => setPageAndQueryParam(currentPage), [maxPageNumber]);

  // Table pagination options
  const tableOptions = {
    manualPagination: true as const,
    useControlledState: (state: any) =>
      useMemo(
        () => ({
          ...state,
          pageSize,
          pageIndex: currentPage - 1,
        }),
        [state, currentPage],
      ),
  };

  return {
    currentPage,
    setCurrentPage,
    useMaxPage,
    pageSize,
    setPageSize,
    tableOptions,
  };
}
