import { A, FormatMessageArgs } from '@main/core-ui';
import { DefinedMessages } from '@transcend-io/internationalization';
import React, { useMemo } from 'react';
import { Column } from 'react-table';

import { ColumnHeaderWithTooltip } from './ColumnHeaderWithTooltip';

export const getFrozenCellsLeftPosition = <T extends object>(
  columns: Column<T>[],
): number[] =>
  columns.reduce<number[]>((distanceFromLeft, col, i) => {
    const prevCol = i === 0 ? null : columns[i - 1];
    return [
      ...distanceFromLeft,
      !prevCol
        ? 0
        : distanceFromLeft[i - 1] +
          (typeof prevCol.minWidth === 'number' && prevCol.minWidth !== 0
            ? (prevCol.minWidth as number)
            : typeof prevCol.width === 'number'
              ? (prevCol.width as number)
              : 0),
    ];
  }, []);

export const buildColumnHeadersFromMessages =
  <T extends object>(
    messages: DefinedMessages,
    tooltips?: DefinedMessages,
    tooltipInfoArgs?: Record<string, FormatMessageArgs>,
  ) =>
  ({
    column,
  }: {
    /** The column definition */
    column: Column<T>;
  }): JSX.Element | null => {
    const { id } = column;
    if (!id || !(id in messages)) {
      return null;
    }

    const infoArgs = useMemo(() => {
      if (tooltipInfoArgs?.[id]) {
        return Object.entries(tooltipInfoArgs[id]).reduce(
          (acc, [linkText, node]) => {
            if (typeof node === 'string' && node.startsWith('http')) {
              acc[linkText] = (
                <A href={node} key={`${id}-${linkText}`}>
                  {linkText}
                </A>
              );
            } else {
              acc[linkText] = node;
            }
            return acc;
          },
          {} as Record<string, React.ReactNode>,
        );
      }
      return undefined;
    }, [id]);

    return (
      <ColumnHeaderWithTooltip
        message={messages[id]}
        info={tooltips ? tooltips[id] : undefined}
        disableResizing={column.disableResizing}
        infoArgs={infoArgs}
      />
    );
  };
