import { FlexRow, Icon, Popover } from '@main/core-ui';
import React from 'react';
import { useIntl } from 'react-intl';
import { useTheme } from 'styled-components';

import { Button } from '../Button';
import { documentationReferencesMessages } from './messages';
import { DocumentationReferencesWrapper } from './wrappers';

/**
 * Props for DetailsList
 */
export interface DocumentationReferencesProps {
  /** The documentation URLS to display */
  references: string[];
}

const ICON_SIZE = 16; // px

/**
 * Display references to documentation
 */
export const DocumentationReferences: React.FC<
  DocumentationReferencesProps
> = ({ references }) => {
  const { formatMessage } = useIntl();
  const theme = useTheme();
  return (
    <DocumentationReferencesWrapper>
      <FlexRow>
        {references.map((reference, i) => (
          <Popover
            key={reference}
            contents={formatMessage(
              documentationReferencesMessages.tooltipWithNumber,
              {
                index: references.length > 1 ? `${i + 1}` : undefined,
              },
            )}
            trigger={['hover', 'focus']}
            placement="top"
          >
            <Button
              variant="link"
              key={reference}
              href={reference}
              target="_blank"
              style={{
                padding: 0,
                border: 'none',
                // 3/24 is the [SVG rect padding / viewbox size] in the SVG
                transform: `translateY(${(3 / 24) * ICON_SIZE}px)`,
              }}
            >
              <Icon
                type="book"
                color={theme.colors.transcend}
                size={ICON_SIZE}
              />
            </Button>
          </Popover>
        ))}
      </FlexRow>
    </DocumentationReferencesWrapper>
  );
};
