import debounce from 'lodash/debounce';
import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';

import { Input, InputProps, TextArea, TextAreaProps } from './Input';

export interface DebouncedInputPropsBase {
  /** How long to debounce */
  debounceTime: number;
  /** the initial value of the input */
  initialValue?: string;
}

export interface DebouncedInputProps
  extends Omit<InputProps, 'value'>,
    DebouncedInputPropsBase {}

export interface DebouncedTextAreaProps
  extends Omit<TextAreaProps, 'value'>,
    DebouncedInputPropsBase {}

const useDebouncedInputProps = <
  T extends HTMLInputElement | HTMLTextAreaElement,
>({
  initialValue,
  onChange,
  debounceTime,
}: DebouncedInputPropsBase & {
  /** on change handler */
  onChange?: (event: React.ChangeEvent<T>) => void;
}): {
  /** controlled value */
  value: string;
  /** the wrapped onChange to pass along */
  rawOnChange: (event: React.ChangeEvent<T>) => void;
} => {
  // lock to avoid extra refreshes
  const [lockValue, setLockValue] = useState(initialValue ?? '');
  const [value, setValue] = useState(initialValue ?? '');

  useEffect(() => {
    const initialValueOrDefault = initialValue ?? '';
    if (initialValueOrDefault !== lockValue) {
      setValue(initialValueOrDefault);
      setLockValue(initialValueOrDefault);
    }
  }, [initialValue, lockValue]);

  const debouncedOnChange = useCallback(
    debounce(
      (event: ChangeEvent<T>) => {
        onChange?.(event);
        // update the lock to avoid delayed onchange overwriting new text
        setLockValue(event.target.value);
      },
      Math.max(1, debounceTime ?? 500),
    ),
    [debounceTime, setValue, onChange],
  );

  return {
    value,
    rawOnChange: (event: ChangeEvent<T>): void => {
      setValue(event.target.value);
      if (debounceTime > 0) {
        debouncedOnChange(event);
      } else {
        onChange?.(event);
      }
    },
  };
};

/**
 * Input component that is debounced by a certain time and controls state internally
 */
export const DebouncedInput = React.forwardRef<
  HTMLInputElement,
  DebouncedInputProps
>(({ debounceTime, initialValue, onChange, ...inputProps }, ref) => {
  const { value, rawOnChange } = useDebouncedInputProps<HTMLInputElement>({
    initialValue,
    onChange,
    debounceTime,
  });
  return (
    <Input {...inputProps} value={value} onChange={rawOnChange} ref={ref} />
  );
});

/**
 * Input component that is debounced by a certain time and controls state internally
 */
export const DebouncedTextArea = React.forwardRef<
  HTMLTextAreaElement,
  DebouncedTextAreaProps
>(({ debounceTime, initialValue, onChange, ...inputProps }, ref) => {
  const { value, rawOnChange } = useDebouncedInputProps<HTMLTextAreaElement>({
    initialValue,
    onChange,
    debounceTime,
  });
  return (
    <TextArea {...inputProps} value={value} onChange={rawOnChange} ref={ref} />
  );
});
