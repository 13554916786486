import { defineMessages } from '@transcend-io/internationalization';

export const selectDataPointLevelMessages = defineMessages(
  'admin-dashboard.DataMap.components.SelectDataPointLevelFilter.selectDataPointLevel',
  {
    placeholder: {
      defaultMessage: 'Select Level/Schema',
    },
  },
);
