import {
  PaginatedSelect,
  PaginatedSelectProps,
} from '@main/ad-core-components';
import { buildUseInfiniteScroll, Icon } from '@main/core-ui';
import {
  AgentFunctionOrderField,
  AgentFunctionPreview,
  endpoints,
} from '@main/prompt-types';
import { createNewId, OrderDirection } from '@main/schema-utils';
import React, { useState } from 'react';
import { MenuPosition } from 'react-select';

import { selectAgentFunctionsMessages } from './messages';

const NODES = {
  id: null,
  name: null,
} as const;

/**
 * Selected prompt
 */
export type SelectedAgentFunction = Pick<
  AgentFunctionPreview,
  keyof typeof NODES
> & {
  /** Whether prompt is newly created prompt or existing prompt */
  isNew?: boolean;
};

const useSelectAgentFunctions = buildUseInfiniteScroll(
  endpoints.agentFunctions,
  'SelectAgentFunctions',
  {
    nodes: NODES,
  },
);

export interface SelectAgentFunctionProps
  extends Omit<
    PaginatedSelectProps<SelectedAgentFunction, true>,
    'isQueryLoading' | 'queryError' | 'fetchMore' | 'onEndsTyping' | 'onChange'
  > {
  /** On change handler */
  onChange?: (agentFunctions: SelectedAgentFunction[]) => void;
  /** Show option to create prompt */
  allowCreate?: boolean;
  /** How to position the menu (see docs for React Select) */
  menuPosition?: MenuPosition;
}

/**
 * Select a specific prompt
 */
export const SelectAgentFunctions: React.FC<SelectAgentFunctionProps> = ({
  allowCreate = false,
  placeholderDescriptor = selectAgentFunctionsMessages.placeholder,
  showOutline = true,
  onChange,
  menuPosition = 'fixed',
  ...paginatedSelectProps
}) => {
  const [searchText, setSearchText] = useState('');
  const { data, loading, error, fetchMore } = useSelectAgentFunctions({
    variables: {
      filterBy: {
        text: searchText,
      },
      orderBy: [
        { field: AgentFunctionOrderField.Name, direction: OrderDirection.ASC },
      ],
    },
    fetchPolicy: 'cache-and-network',
  });

  return (
    <PaginatedSelect
      id="select-agent-functions"
      showOutline={showOutline}
      placeholderDescriptor={placeholderDescriptor}
      options={data?.nodes ?? []}
      onChange={onChange as any}
      isQueryLoading={loading}
      isCreatable={allowCreate}
      isMulti
      menuPosition={menuPosition}
      getOptionValue={({ id }: SelectedAgentFunction) => id}
      getOptionLabel={({ name }: SelectedAgentFunction) => name}
      queryError={error}
      getOptionLogo={({ isNew }: SelectedAgentFunction) =>
        isNew ? <Icon type="circle-add" /> : ''
      }
      getNewOptionData={(inputValue): SelectedAgentFunction => ({
        isNew: true as const,
        name: inputValue,
        id: createNewId<'agentFunction'>(),
      })}
      fetchMore={fetchMore}
      onEndsTyping={setSearchText}
      {...paginatedSelectProps}
    />
  );
};
