import { AirgapIssue, AirgapIssueSeverity } from '../airgapChangelog';

/**
 * Compares the sort precedence of two issue severities in descending order
 * ex: [AirgapIssueSeverity.High, AirgapIssueSeverity.Moderate]
 *
 * @param a - first severity to compare
 * @param b - second severity to compare
 * @returns the sort order of the two severity arguments
 */
export function compareSeverity(
  a: AirgapIssueSeverity,
  b: AirgapIssueSeverity,
): 0 | 1 | -1 {
  return a === AirgapIssueSeverity.High
    ? b === AirgapIssueSeverity.High
      ? 0
      : -1
    : 1;
}

/**
 * Compares the sort precedence of two issues based on severity in descending order
 * ex: [{ severity: AirgapIssueSeverity.High }, { severity: AirgapIssueSeverity.Moderate }]
 *
 * @param a - first issue to compare
 * @param b - second issue to compare
 * @returns the sort order of the two issue arguments
 */
export function compareIssueSeverity(
  a: AirgapIssue,
  b: AirgapIssue,
): 0 | 1 | -1 {
  return compareSeverity(a.severity, b.severity);
}
