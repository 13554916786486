/**
 * The fields that an LLM can be ordered on
 */
export enum LargeLanguageModelOrderField {
  /** The name of the LLM */
  Name = 'name',
  /** The client of the LLM */
  Client = 'client',
  /** Hosting Method */
  IsTranscendHosted = 'isTranscendHosted',
  /** The age of the LLM */
  CreatedAt = 'createdAt',
  /** The last updated time */
  UpdatedAt = 'updatedAt',
}
