import {
  FormItemWrapper,
  IFormInputValidatedProps,
  IFormItemWrapperProps,
  multipleValidators,
} from '@main/core-ui';
import { DataSiloFiltersInput } from '@main/datamap-types';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';

import { selectIntegrationsMessages } from './messages';
import { SelectIntegrations } from './SelectIntegrations';

export interface IFormInputSelectProps extends IFormInputValidatedProps {
  /** Filter the data silos */
  filterBy?: Omit<DataSiloFiltersInput, 'text'>;
  /** When true, allow for multiple values */
  isMulti?: boolean;
}

export const FormSelectIntegrations: React.FC<
  IFormInputSelectProps & Omit<IFormItemWrapperProps, 'errors'>
> = ({
  name,
  label,
  rules,
  info,
  errorDisplay,
  isMulti = true,
  required,
  filterBy = {},
}) => {
  const { formatMessage } = useIntl();
  const {
    control,
    formState: { errors },
  } = useFormContext();
  return (
    <FormItemWrapper
      name={name}
      label={label || formatMessage(selectIntegrationsMessages.title)}
      info={info}
      errors={errors}
      errorDisplay={errorDisplay}
      required={required}
    >
      <Controller
        name={name}
        control={control}
        rules={rules ? { validate: multipleValidators(rules) } : undefined}
        render={({ field: { ref: {} = {}, onChange, value } }) => (
          <SelectIntegrations
            filterBy={filterBy}
            onChange={isMulti ? onChange : ([dataSilo]) => onChange(dataSilo)}
            value={value}
            isMulti={isMulti}
          />
        )}
      />
    </FormItemWrapper>
  );
};
