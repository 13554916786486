import { mkInput, mkType, SchemaToType } from '@main/schema-utils';

/** Details about a consent manager UI */
export const ConsentManagerUI = mkType({
  name: 'ConsentManagerUI',
  comment: 'A consent manager UI',
  fields: {
    id: {
      comment: 'The id of the consent manager UI',
      type: 'id',
      modelName: 'consentManagerUi',
    },
    name: {
      comment: 'The slug for this UI script',
      type: 'string',
    },
    scriptLocation: {
      comment: 'location for UI script',
      type: 'string',
    },
  },
});

/** Override type */
export type ConsentManagerUI = SchemaToType<typeof ConsentManagerUI>;

export const CreateConsentManagerUIInput = mkInput({
  name: 'CreateConsentManagerUIInput',
  comment:
    'Input used to create a new record for a custom UI module for Airgap',
  fields: {
    name: {
      comment: 'The slug for this UI script',
      type: 'string',
    },
    scriptLocation: {
      comment: 'location for UI script',
      type: 'string',
    },
  },
});

/** Override type */
export type CreateConsentManagerUIInput = SchemaToType<
  typeof CreateConsentManagerUIInput
>;
