import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ID } from '@main/schema-utils';

/**
 *localized router state
 */
export interface ActionItemState {
  /** The list of action item collection IDs that are collapsed */
  collapsedActionItemCollections: ID<'actionItemCollection'>[];
}

export const actionItemSlice = createSlice({
  name: 'ActionItem',
  initialState: {
    collapsedActionItemCollections: [],
  } as ActionItemState,
  reducers: {
    showActionItemCollection: (
      state,
      { payload }: PayloadAction<ID<'actionItemCollection'>>,
    ) => ({
      ...state,
      collapsedActionItemCollections:
        state.collapsedActionItemCollections.filter((id) => id !== payload),
    }),
    hideActionItemCollection: (
      state,
      { payload }: PayloadAction<ID<'actionItemCollection'>>,
    ) => ({
      ...state,
      collapsedActionItemCollections: [
        ...state.collapsedActionItemCollections,
        payload,
      ],
    }),
  },
});

export const { showActionItemCollection, hideActionItemCollection } =
  actionItemSlice.actions;
