import { createLoadable } from '@main/core-ui';

// Load the component dynamically
export const AgentLoadable = createLoadable({
  loader: () => import('./Agent'),
});

export const NewAgentLoadable = createLoadable({
  loader: () => import('./NewAgent'),
});
