import pickBy from 'lodash/pickBy';
import { generatePath } from 'react-router-dom';

import { logger } from '@main/core-ui';

export const safeJoinPath = (
  path: string,
  params: Record<string, string | undefined> = {},
  filters: object | undefined = undefined,
): string | undefined => {
  try {
    // generate the path
    const generatedPath = generatePath(path, params);
    const cleanedFilters = pickBy(filters, (filter) => filter !== undefined);
    // apply filters, if defined
    return cleanedFilters && Object.keys(cleanedFilters).length > 0
      ? `${generatedPath}?filters=${encodeURIComponent(
          JSON.stringify(cleanedFilters),
        )}`
      : generatedPath;
  } catch (e) {
    // If missing params, then we return an empty link. We don't break the page.
    logger.warn(
      `Failed to generate action item link for ${path} with params ${JSON.stringify(
        params,
      )}`,
    );
    return undefined;
  }
};
