import React from 'react';
import { DayPicker, DayPickerProps } from 'react-day-picker';

import { Button } from '../Button';
import { useCalendarLocale } from './useCalendarLocale';
import {
  StyledDay,
  StyledDayButton,
  StyledMonthCaption,
  StyledMonths,
  StyledNav,
  StyledRoot,
} from './wrappers';

/**
 * Interface for Day Picker
 */
type DayPickerCoreProps = DayPickerProps & {
  /**
   * Force a specific locale for the calendar. Otherwise uses react-intl `useIntl().locale`
   */
  forceLocale?: string;
};

export const DayPickerCore: React.FC<DayPickerCoreProps> = ({
  forceLocale,
  ...dayPickerProps
}) => {
  // Localization
  const calendarLocaleData = useCalendarLocale({ forceLocale });

  return (
    <DayPicker
      {...dayPickerProps}
      locale={calendarLocaleData}
      components={{
        Root: StyledRoot,
        Nav: StyledNav,
        MonthCaption: StyledMonthCaption,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        NextMonthButton: ({ ref, ...buttonProps }) => (
          <Button
            {...buttonProps}
            iconOnly
            icon="arrow-right"
            variant="secondary"
          />
        ),
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        PreviousMonthButton: ({ ref, ...buttonProps }) => (
          <Button
            {...buttonProps}
            iconOnly
            icon="arrow-left"
            variant="secondary"
          />
        ),
        Months: StyledMonths,
        Day: StyledDay,
        DayButton: StyledDayButton,
      }}
    />
  );
};
