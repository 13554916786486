import { defineMessages } from '@transcend-io/internationalization';

export const commonButtonMessages = defineMessages(
  'ad-core-components.Button.commonButton',
  {
    add: {
      defaultMessage: 'Add',
    },
    assign: {
      defaultMessage: 'Assign',
    },
    cancel: {
      defaultMessage: 'Cancel',
    },
    clear: {
      defaultMessage: 'Clear',
    },
    close: {
      defaultMessage: 'Close',
    },
    confirm: {
      defaultMessage: 'Confirm',
    },
    create: {
      defaultMessage: 'Create',
    },
    delete: {
      defaultMessage: 'Delete',
    },
    dismiss: {
      defaultMessage: 'Dismiss',
    },
    status: {
      defaultMessage: 'Status',
    },
    done: {
      defaultMessage: 'Done',
    },
    next: {
      defaultMessage: 'Next',
    },
    back: {
      defaultMessage: 'Back',
    },
    edit: {
      defaultMessage: 'Edit',
    },
    filter: {
      defaultMessage: 'Filter',
    },
    ok: {
      defaultMessage: 'OK',
    },
    reset: {
      defaultMessage: 'Reset',
    },
    save: {
      defaultMessage: 'Save',
    },
    submit: {
      defaultMessage: 'Submit',
    },
    update: {
      defaultMessage: 'Update',
    },
    exportCsv: {
      defaultMessage: 'Export CSV',
    },
    discard: {
      defaultMessage: 'Discard',
    },
    hide: {
      defaultMessage: 'Hide',
    },
    retry: {
      defaultMessage: 'Retry',
    },
    show: {
      defaultMessage: 'Show',
    },
    exportToCsv: {
      defaultMessage: 'Export to CSV',
    },
  },
);

export const buttonWithDropdownMessages = defineMessages(
  'ad-core-components.Button.buttonWithDropdown',
  {
    moreOptions: {
      defaultMessage: 'More options',
    },
  },
);
