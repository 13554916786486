import { rgba, transparentize } from 'polished';
import { FixedSizeList } from 'react-window';
import styled, { css } from 'styled-components';

import { FlexRowCenterBoth, FlexRowCenterVertical } from '@main/core-ui';

import { Button } from '../Button';

export const TableActionWrapper = styled(FlexRowCenterVertical)`
  position: sticky;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 2;

  transition: opacity 0.3s ease-in-out;

  background: linear-gradient(
    90deg,
    transparent,
    ${({ theme }) => theme.colors.white} 2em
  );
  padding: 0.5em 0.5em 0.5em 2.5em;
  gap: 0.5em;
  max-width: 300px;
  // stick the buttons to the right side of the table
  margin-left: auto;

  flex-wrap: wrap;

  &:focus-within {
    z-index: 3;
  }
`;

const tableStyles = css`
  width: 100%;
  font-family: neue-haas-grotesk-display, sans-serif;
  background-color: ${({ theme }) => theme.colors.white};

  tr {
    border-bottom: 1px solid ${({ theme }) => theme.colors.gray3};
    min-width: 100%;
  }

  th {
    padding: 14px 5px 14px 5px;
    color: ${({ theme }) => theme.colors.transcendNavy2};
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 1px;
    word-break: break-word;
    cursor: auto;

    div {
      color: ${({ theme }) => theme.colors.transcendNavy2};
      text-transform: uppercase;
      font-size: 12px;
      font-weight: 600;
    }
  }

  td {
    color: ${({ theme }) => theme.colors.transcendNavy};
    font-size: 14px;
  }

  tbody tr {
    cursor: auto;
    transition: background 0.2s;

    ${TableActionWrapper} {
      opacity: 0;
    }

    &:hover,
    &:focus-within {
      &,
      td {
        background-color: ${({ theme }) => theme.colors.gray1};
      }

      ${TableActionWrapper} {
        opacity: 1;
      }
    }
  }
`;

export const TableCellOverflowWrapper = styled.div`
  height: 100%;
  padding: 5px;
  word-break: break-word;
  overflow-wrap: anywhere;
  overflow-x: auto;
`;

export const StyledInfiniteScrollTable = styled.table`
  ${tableStyles}
`;

export const TableScrollContainer = styled.div<{
  /** Whether the table has rows or is empty */
  tableIsEmpty: boolean;
}>`
  overflow: auto;
  ${({ tableIsEmpty }) => (!tableIsEmpty ? 'height: 100%;' : '')}
  max-width: 100%;
  position: relative;

  // Prevent Chrome "go back a page" gesture when scrolling horizontally and you hit the edge of the table
  overscroll-behavior-x: contain;
`;

export const StyledTable = styled.table`
  ${tableStyles}
  border-spacing: 0;
  border-collapse: collapse;
  position: relative;

  // html tables (display:table) don't respect max-width unless this is set
  // https://stackoverflow.com/questions/12232858/css-table-and-max-width-in-chrome-not-working
  table-layout: fixed;

  thead {
    position: sticky;
    top: 0;
    /** So table body contents don't scroll over and block the headers */
    z-index: 5;

    tr {
      background-color: ${({ theme }) => theme.colors.white};
    }
  }

  tr {
    &.row-clickable {
      cursor: pointer;
    }
  }
`;

export const TablePaginationWrapper = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const StyledColumnResizeButton = styled(Button)`
  /** To prevent tiny cut-off on the top when focused */
  margin-top: 4px;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  border: none;
  padding: 3px;
  opacity: 0;
  transition: opacity 0.2s;
  ${'' /* prevents from scrolling while dragging on touch devices */}
  touch-action: none;

  &:hover,
  &:focus {
    background: none;
    opacity: 1;
  }
`;

export const StyledFixedSizeList = styled(FixedSizeList)`
  max-height: calc(100vh - 410px);
`;

export const StyledLoadingSpinnerWrapper = styled(FlexRowCenterBoth)`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 4;
  background-color: ${({ theme }) => transparentize(0.3, theme.colors.white)};
`;

const scrollShadowWidth = 7;

export const FreezableCell = styled.td<{
  /** Whether this cell is frozen and should have frozen styling applied */
  frozen: boolean;
  /**
   * Distance the freezable cell should be from the left
   * (e.g., if there are other frozen cells further to the left)
   */
  left?: number;
  /** a background color to override the default */
  backgroundColor?: string;
  /** Whether the table is scrolled from the left */
  isScrolledFromLeft: boolean;
  /** Whether the cell is the rightmost frozen cell */
  isRightmostFrozenCell: boolean;
}>`
  ${({
    frozen,
    left,
    backgroundColor,
    theme,
    isScrolledFromLeft,
    isRightmostFrozenCell,
  }) =>
    frozen
      ? `
          position: sticky;
          left: ${left || 0}px;
          background-color: ${backgroundColor ?? theme.colors.white};
          z-index: 10;

          &::after {
            content: '';
            position: absolute;
            top: 0;
            right: -${scrollShadowWidth}px;
            bottom: 0;
            opacity: 0;
            background: linear-gradient(to right, ${rgba(
              theme.colors.black,
              0.03,
            )}, transparent);
            ${
              isScrolledFromLeft && isRightmostFrozenCell
                ? `
                  width: ${scrollShadowWidth}px;
                  opacity: 1;
                  `
                : ''
            }
          }
        `
      : ''}
`;

export const TableCellCenterVertical = styled(FlexRowCenterVertical)`
  // Need to expand to full height to center cell contents after using useBlockLayout
  height: 100%;
`;

export const TableCellCenterBoth = styled(FlexRowCenterBoth)`
  // Need to expand to full height to center cell contents after using useBlockLayout
  height: 100%;
`;
