import {
  RequestDataSiloStatus,
  RequestStatus,
} from '@transcend-io/privacy-types';

import {
  DataSiloBulkPreview,
  DataSiloConnectionState,
} from '@main/datamap-types';
import { mkInput, mkOrder, mkType, SchemaToType } from '@main/schema-utils';

import {
  ProfileDataPointStatusSuccess,
  RequestDataSiloOrderField,
  RequestDataSiloSortAssociation,
  UpdateRequestDataSiloStatus,
  VisualRequestDataSiloStatus,
} from './enums';
import { Profile } from './profile';
import { RequestDetails } from './request';

export const RequestDataSiloInput = mkInput({
  name: 'RequestDataSiloInput',
  comment: 'Input for finding a request data silo',
  fields: {
    dataSiloId: {
      comment: 'The ID of the data silo in reference',
      modelName: 'dataSilo',
      type: 'id',
    },
    requestId: {
      comment: 'The ID of the request in reference',
      modelName: 'request',
      type: 'id',
    },
  },
});

/** Override type */
export type RequestDataSiloInput = SchemaToType<typeof RequestDataSiloInput>;

export const UpdateRequestDataSiloStatusInput = mkInput({
  name: 'UpdateRequestDataSiloStatusInput',
  comment: 'SR configuration settings for an organization',
  fields: {
    id: {
      comment: 'The ID of the request data silo to update',
      modelName: 'RequestDataSilo',
      type: 'id',
    },
    status: {
      comment: 'The desired status to update to',
      type: { UpdateRequestDataSiloStatus },
    },
  },
});

/** Override type */
export type UpdateRequestDataSiloStatusInput = SchemaToType<
  typeof UpdateRequestDataSiloStatusInput
>;

export const UpdateRequestDataSiloStatusesInput = mkInput({
  name: 'UpdateRequestDataSiloStatusesInput',
  comment: 'SR configuration settings for an organization',
  fields: {
    requestId: {
      comment: 'The ID of the request to update',
      modelName: 'request',
      type: 'id',
    },
    dataSiloIds: {
      comment: 'The IDs of the data silos to update',
      list: true,
      type: 'id',
      modelName: 'dataSilo',
    },
    status: {
      comment: 'The desired status to update to',
      type: { UpdateRequestDataSiloStatus },
    },
  },
});

/** Override type */
export type UpdateRequestDataSiloStatusesInput = SchemaToType<
  typeof UpdateRequestDataSiloStatusesInput
>;

export const RequestDataSiloFiltersInput = mkInput({
  name: 'RequestDataSiloFiltersInput',
  comment: 'Input for finding a request data silo',
  fields: {
    dataSiloId: {
      comment: 'The ID of the data silo in reference',
      modelName: 'dataSilo',
      optional: true,
      type: 'id',
    },
    requestId: {
      comment: 'The ID of the request in reference',
      modelName: 'request',
      optional: true,
      type: 'id',
    },
    status: {
      comment: 'The status of the request data silo',
      list: true,
      optional: true,
      type: { RequestDataSiloStatus },
    },
    requestStatus: {
      comment: 'The status of the request to filter on',
      list: true,
      optional: true,
      type: { RequestStatus },
    },
    text: {
      comment: 'The text to filter the data silos by',
      optional: true,
      type: 'string',
    },
    isDeleted: {
      comment: 'Filter for data silos that are deleted',
      optional: true,
      type: 'boolean',
    },
    connectionState: {
      comment: 'Filter by the connection state',
      list: true,
      optional: true,
      type: { DataSiloConnectionState },
    },
  },
});

/** Override type */
export type RequestDataSiloFiltersInput = SchemaToType<
  typeof RequestDataSiloFiltersInput
>;

export const RequestDataSilo = mkType({
  name: 'RequestDataSilo',
  comment: 'The status of looking up a DSR in a data silo',
  fields: {
    id: {
      comment: 'The unique ID of the request data silo lookup',
      modelName: 'RequestDataSilo',
      type: 'id',
    },
    dataSiloId: {
      comment: 'The ID of the data silo in reference',
      modelName: 'dataSilo',
      type: 'id',
    },
    requestId: {
      comment: 'The ID of the request in reference',
      modelName: 'request',
      type: 'id',
    },
    link: {
      comment: 'Link to the request data silo on the admin dashboard',
      type: 'string',
    },
    details: {
      comment: 'Notes specific to this RequestDataSilo',
      type: 'string',
    },
    request: {
      comment: 'The exposed details of the request',
      type: RequestDetails,
    },
    status: {
      comment: 'The current status of the request/data silo combo',
      type: { VisualRequestDataSiloStatus },
    },
    canEdit: {
      comment: 'Indicates if the request data silo is in an editable state',
      type: 'boolean',
    },
    canChangeStatus: {
      comment:
        'Whether or not the current user can change the status of the request data silo',
      type: 'boolean',
    },
    dataSilo: {
      comment: 'The related data silo information',
      type: DataSiloBulkPreview,
    },
    // TODO: https://transcend.height.app/T-6902 - paginate
    profiles: {
      comment: 'The profiles associated with a request data silo',
      list: true,
      type: Profile,
    },
    profileCount: {
      comment: 'The number of profiles being processed',
      type: 'int',
    },
    requestFileCount: {
      comment: 'The number of request files that have been found',
      type: 'int',
    },
    error: {
      comment: 'The error message if there is one',
      optional: true,
      type: 'string',
    },
    scheduledAt: {
      comment: 'When the next action is scheduled',
      type: 'Date',
    },
  },
});

/** Override type */
export type RequestDataSilo = SchemaToType<typeof RequestDataSilo>;

/**
 * Order for a requestDataSilos query
 */
export const RequestDataSiloOrder = mkOrder(
  RequestDataSilo.name,
  RequestDataSiloOrderField,
  RequestDataSiloSortAssociation,
);

/** Override type */
export type RequestDataSiloOrder = SchemaToType<typeof RequestDataSiloOrder>;

export const MarkBulkRequestsAsCompletedInput = mkInput({
  name: 'MarkBulkRequestsAsCompletedInput',
  comment: 'Input for marking a data silo as completed',
  fields: {
    token: {
      comment: 'The token to validate',
      type: 'string',
      optional: true,
    },
    RequestDataSiloIds: {
      comment:
        'The request data silos to mark as complete instead of the ones directly attached to the token',
      type: 'string',
      list: true,
      // TODO: https://transcend.height.app/T-6975 - make RDS ids optional again
    },
    status: {
      comment: 'Mark the requests with the following statuses',
      type: { ProfileDataPointStatusSuccess },
      optional: true,
    },
  },
});

/** Override type */
export type MarkBulkRequestsAsCompletedInput = SchemaToType<
  typeof MarkBulkRequestsAsCompletedInput
>;

export const RequestDataSiloDataPointInput = mkInput({
  name: 'RequestDataSiloDataPointInput',
  comment: "Input for finding a request data silo's data points",
  fields: {
    text: {
      comment: 'Filter by datapoint name',
      type: 'string',
      optional: true,
    },
    profileId: {
      comment: 'The ID of the profile being viewed',
      modelName: 'profile',
      type: 'id',
      optional: true,
    },
  },
});

/** Override type */
export type RequestDataSiloDataPointInput = SchemaToType<
  typeof RequestDataSiloDataPointInput
>;
