import React from 'react';
import { MessageDescriptor } from 'react-intl';

import { yesOrNoMessages } from './messages';
import {
  MultipleChoice,
  MultipleChoiceOption,
  MultipleChoiceProps,
} from './MultipleChoice';

/**
 * props for BooleanRadioInputGroup wrapper around MultipleChoice
 */
export interface BooleanRadioInputGroupProps
  extends Omit<
    MultipleChoiceProps<
      'true' | 'false',
      Array<MultipleChoiceOption<'true' | 'false'>>
    >,
    'value' | 'onChange' | 'options' | 'type'
  > {
  /** current checked value of group */
  value?: boolean;
  /** callback for when a choice is selected */
  onChange?: (value: boolean | undefined) => void;
  /** labels for options */
  labels?: {
    /** label for when choice is true */
    true?: MessageDescriptor;
    /** label for when choice is false */
    false?: MessageDescriptor;
  };
}

export const BooleanRadioInputGroup: React.FC<BooleanRadioInputGroupProps> = ({
  onChange,
  value,
  labels,
  ...props
}) => {
  const options = [
    {
      label: labels?.true ?? yesOrNoMessages.yes,
      value: 'true',
    },
    {
      label: labels?.false ?? yesOrNoMessages.no,
      value: 'false',
    },
  ];
  return (
    <MultipleChoice
      {...props}
      options={options}
      type="radio"
      onChange={(values) =>
        onChange?.(values.length === 0 ? undefined : values[0] === 'true')
      }
      value={value === undefined ? [] : value ? ['true'] : ['false']}
    />
  );
};

export const TrueRadioInputGroup: React.FC<BooleanRadioInputGroupProps> = ({
  onChange,
  value,
  labels,
  ...props
}) => {
  const options = [
    {
      label: labels?.true ?? yesOrNoMessages.yes,
      value: 'true',
    },
  ];
  return (
    <MultipleChoice
      {...props}
      options={options}
      type="radio"
      onChange={(values) =>
        onChange?.(values.length === 0 ? undefined : values[0] === 'true')
      }
      value={value === undefined ? [] : value ? ['true'] : ['false']}
    />
  );
};
