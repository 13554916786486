import type { RequestStatus } from '@transcend-io/privacy-types';
import { createEnum } from '@transcend-io/type-utils';

/**
 * The statuses that are action items and require attention
 */
export const REQUEST_STATUSES_PROCESSING: RequestStatus[] = [
  'COMPILING',
  'SECONDARY',
];

/**
 * The statuses that are action items
 */
export const REQUEST_STATUS_PROCESSING = createEnum(
  REQUEST_STATUSES_PROCESSING,
);

/**
 * A request is actively being processed
 *
 * @param status - The status of the request to check
 * @returns True if the status is a step that is actively processing
 */
export function isProcessing(status: RequestStatus): boolean {
  return status in REQUEST_STATUS_PROCESSING;
}
