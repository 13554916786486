import styled from 'styled-components';

import {
  CONTENT_PADDING,
  TOTAL_HEIGHT_PADDING_AND_BREADCRUMB,
} from '../constants';
import { TableWrapper } from '../wrappers';

export const AuditTrailBoldWrapper = styled.span`
  font-weight: 600;
`;

export const AuditTrailDataViewWrapper = styled.div`
  // full-height, minus the top and bottom page padding,
  // and the height and margin (same as content padding) of the breadcrumbs
  height: calc(100vh - ${TOTAL_HEIGHT_PADDING_AND_BREADCRUMB}px);
  display: flex;
  flex-direction: column;

  ${TableWrapper} {
    margin-right: -${CONTENT_PADDING}px;
  }
`;

export const AuditTrailStateDiffWrapper = styled.div`
  display: flex;
  flex-direction: column;
  border-width: 0;
  flex-grow: 1;
  gap: 0.25em;
`;

export const AuditTrailStateDiffRowContentWrapper = styled.div`
  color: ${({ theme }) => theme.colors.transcendNavy};
  Ò
  flex-grow: 1;
  font-family: 'Menlo', monospace;
  display: flex;
  word-break: break-word;
`;

export const AuditTrailStateDiffRowWrapper = styled.div`
  color: ${({ theme }) => theme.colors.transcendNavy};
  flex-wrap: wrap;
  display: flex;
  max-width: 100%;
`;
