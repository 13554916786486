import type { RequestStatus } from '@transcend-io/privacy-types';
import { RequestAction } from '@transcend-io/privacy-types';
import { createEnum, filterEnum } from '@transcend-io/type-utils';

import { hasSecondaryAction } from '@main/datamap-types';

import { REQUEST_STATUSES } from './statuses';

/**
 * The statuses where primary only can use
 */
export const REQUEST_STATUSES_PRIMARY = filterEnum(
  REQUEST_STATUSES,
  (status) => !status.secondaryOnly,
);

/**
 * The statuses where secondary only can use
 */
export const REQUEST_STATUSES_SECONDARY = filterEnum(
  REQUEST_STATUSES,
  (status) => !status.primaryOnly,
);

/**
 * The statuses where primary only can use, keyed by status
 */
export const REQUEST_STATUS_PRIMARY = createEnum(REQUEST_STATUSES_PRIMARY);

/**
 * The statuses where secondary only can use, keyed by status
 */
export const REQUEST_STATUS_SECONDARY = createEnum(REQUEST_STATUSES_SECONDARY);

/**
 * Ensure that a request of type secondary action does not go to primary only states, and vice versa
 *
 * @param type - The type of action to verify against
 * @param status - The status to check if that action type is allowed to enter
 * @returns True if it is ok for the request of that type to enter that status
 */
export function validateStatusBySecondary(
  type: RequestAction,
  status: RequestStatus,
): boolean {
  // Check if the type has a secondary and primary action
  const isSecondary = hasSecondaryAction(type);
  return isSecondary
    ? status in REQUEST_STATUS_SECONDARY
    : status in REQUEST_STATUS_PRIMARY;
}
