import { TeamPreview, UserPreview } from '@main/access-control-types';
import { AgentPreview, PromptPreview } from '@main/attribute-types';
import { DataSubCategoryPreview } from '@main/datamap-types';
import { mkInput, mkOrder, mkType, SchemaToType } from '@main/schema-utils';

import { ApplicationOrderField } from './enums';
import { PathfinderPreview } from './pathfinder';

export const ApplicationPreview = mkType({
  name: 'ApplicationPreview',
  comment: 'A preview of an application instance',
  fields: {
    id: {
      comment: 'The id of the application',
      modelName: 'application',
      type: 'id',
    },
    name: {
      comment: 'The name of the application',
      type: 'string',
    },
    description: {
      comment: 'The description of the application',
      type: 'string',
    },
    createdAt: {
      comment: 'The date the application was created',
      type: 'Date',
    },
    updatedAt: {
      comment: 'The date the application was last updated',
      type: 'Date',
    },
    lastSeenAt: {
      comment: 'The date the application was last seen reporting a prompt run',
      type: 'Date',
      optional: true,
    },
  },
});

/** Override type */
export type ApplicationPreview = SchemaToType<typeof ApplicationPreview>;

export const Application = mkType({
  name: 'Application',
  comment:
    'A service or application that executes some code - typically tracked for the purposes of AI governance',
  fields: {
    ...ApplicationPreview.fields,
    prompts: {
      comment: 'List of prompts that the application is calling',
      type: PromptPreview,
      list: true,
    },
    owners: {
      comment:
        'The list of individual users who are responsible for managing this application',
      list: true,
      type: UserPreview,
    },
    teams: {
      comment:
        'The list of teams who are responsible for managing this application',
      list: true,
      type: TeamPreview,
    },
    pathfinders: {
      comment:
        'The list of pathfinder instances that the application has access to',
      list: true,
      type: () => PathfinderPreview,
    },
    agents: {
      comment: 'The list of agents that this application has access to',
      list: true,
      type: AgentPreview,
    },
    promptRunDataSubCategories: {
      comment:
        'Data sub categories that were detected in the prompt runs for this application',
      type: DataSubCategoryPreview,
      list: true,
    },
    applicationUserCount: {
      comment: 'The number of users detected in the application',
      type: 'int',
    },
    promptRunCount: {
      comment: 'The number of prompt runs that this application has ran',
      type: 'int',
    },
    // TODO: https://transcend.height.app/T-31595 - add attributes
    // attributeValues: {
    //   comment: 'The attribute values used to label this assessment',
    //   type: AttributeValue,
    //   list: true,
    // },
  },
});

/** Override type */
export type Application = SchemaToType<typeof Application>;

export const ApplicationFiltersInput = mkInput({
  name: 'ApplicationFiltersInput',
  comment: 'Inputs for filtering a list of applications',
  fields: {
    ids: {
      comment: 'The ids of the applications',
      type: 'id',
      modelName: 'application',
      optional: true,
      list: true,
    },
    ownerIds: {
      comment: 'The ids of the owners that are mapped to this application',
      type: 'id',
      modelName: 'user',
      optional: true,
      list: true,
    },
    teamIds: {
      comment: 'The ids of the teams that are mapped to this application',
      type: 'id',
      modelName: 'team',
      optional: true,
      list: true,
    },
    promptIds: {
      comment: 'The ids of the prompts that this application has called',
      type: 'id',
      modelName: 'prompt',
      optional: true,
      list: true,
    },
    pathfinderIds: {
      comment: 'The ids of the pathfinders to filter down applications for',
      type: 'id',
      modelName: 'pathfinder',
      optional: true,
      list: true,
    },
    agentIds: {
      comment: 'The ids of the agents to filter down applications for',
      type: 'id',
      modelName: 'agent',
      optional: true,
      list: true,
    },
    dataSubCategoryIds: {
      comment: 'The ids of the data sub categories detected in prompt runs',
      type: 'id',
      modelName: 'dataSubCategory',
      optional: true,
      list: true,
    },
    appUserIds: {
      comment: 'The ids of the application users to filter applications for',
      type: 'id',
      modelName: 'appUser',
      optional: true,
      list: true,
    },
    text: {
      comment: 'Filter by text (name, description of the application)',
      optional: true,
      type: 'string',
    },
  },
});

/** Override type */
export type ApplicationFiltersInput = SchemaToType<
  typeof ApplicationFiltersInput
>;

export const DeleteApplicationsInput = mkInput({
  name: 'DeleteApplicationsInput',
  comment: 'Input for deleting a group of applications',
  fields: {
    ids: {
      comment: 'The id of the applications to delete',
      type: 'id',
      modelName: 'application',
      list: true,
    },
  },
});

/** Override type */
export type DeleteApplicationsInput = SchemaToType<
  typeof DeleteApplicationsInput
>;

export const CreateApplicationInput = mkInput({
  name: 'CreateApplicationInput',
  comment: 'Input for creating a application',
  fields: {
    name: {
      comment: 'The name of the application',
      type: 'string',
    },
    description: {
      comment: 'The description of the application',
      type: 'string',
      optional: true,
    },
    ownerIds: {
      comment: 'The IDs of owners that maintain the application',
      modelName: 'user',
      type: 'id',
      list: true,
      optional: true,
    },
    ownerEmails: {
      comment: 'The emails of owners that maintain the application',
      type: 'string',
      list: true,
      optional: true,
    },
    teamIds: {
      comment: 'The IDs of owners that maintain the application',
      modelName: 'team',
      type: 'id',
      list: true,
      optional: true,
    },
    teamNames: {
      comment: 'The names of team that maintain the application',
      type: 'string',
      list: true,
      optional: true,
    },
    pathfinderIds: {
      comment:
        'The IDs of pathfinder instances that the application has access to',
      modelName: 'pathfinder',
      type: 'id',
      list: true,
      optional: true,
    },
    pathfinderNames: {
      comment:
        'The names of the pathfinder instances that the application has access to',
      type: 'string',
      list: true,
      optional: true,
    },
    promptIds: {
      comment: 'The IDs of prompts that the application is calling',
      modelName: 'prompt',
      type: 'id',
      list: true,
      optional: true,
    },
    promptTitles: {
      comment: 'The titles of prompts that the application is calling',
      type: 'string',
      list: true,
      optional: true,
    },
    agentIds: {
      comment:
        'The IDs of agents that the application has been seen interacting with',
      modelName: 'agent',
      type: 'id',
      list: true,
      optional: true,
    },
    agentNames: {
      comment:
        'The names of agents that the application has been seen interacting with',
      type: 'string',
      list: true,
      optional: true,
    },
    agentRemoteIds: {
      comment:
        'The remote IDs of agents that the application has been seen interacting with',
      type: 'string',
      list: true,
      optional: true,
    },
  },
});

/** Override type */
export type CreateApplicationInput = SchemaToType<
  typeof CreateApplicationInput
>;

export const UpdateApplicationInput = mkInput({
  name: 'UpdateApplicationInput',
  comment: 'Input for updating a application',
  fields: {
    id: {
      comment: 'The id of the application',
      modelName: 'application',
      type: 'id',
    },
    name: {
      comment: 'The new name of the application',
      type: 'string',
      optional: true,
    },
    description: CreateApplicationInput.fields.description,
    teamIds: CreateApplicationInput.fields.teamIds,
    teamNames: CreateApplicationInput.fields.teamNames,
    ownerIds: CreateApplicationInput.fields.ownerIds,
    ownerEmails: CreateApplicationInput.fields.ownerEmails,
    pathfinderIds: CreateApplicationInput.fields.pathfinderIds,
    pathfinderNames: CreateApplicationInput.fields.pathfinderNames,
    promptIds: CreateApplicationInput.fields.promptIds,
    promptTitles: CreateApplicationInput.fields.promptTitles,
    agentIds: CreateApplicationInput.fields.agentIds,
    agentNames: CreateApplicationInput.fields.agentNames,
    agentRemoteIds: CreateApplicationInput.fields.agentRemoteIds,
  },
});

/** Override type */
export type UpdateApplicationInput = SchemaToType<
  typeof UpdateApplicationInput
>;

export const UpdateApplicationsInput = mkInput({
  name: 'UpdateApplicationsInput',
  comment: 'Input for bulk updating one or more applications',
  fields: {
    applications: {
      comment:
        'List of applications with the properties that should be updated for each',
      type: UpdateApplicationInput,
      list: true,
    },
  },
});

/** Override type */
export type UpdateApplicationsInput = SchemaToType<
  typeof UpdateApplicationsInput
>;

/** Order for a applications query */
export const ApplicationOrder = mkOrder(
  Application.name,
  ApplicationOrderField,
);
