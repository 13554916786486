import React from 'react';
import { useIntl } from 'react-intl';

import { naMessages } from './messages';

export { naMessages };

/**
 * Display N/A text
 */
export const NA: React.FC = () => {
  const { formatMessage } = useIntl();
  return <>{formatMessage(naMessages.header)}</>;
};
