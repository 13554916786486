import { LegendOrdinal } from '@visx/legend';
import { animated } from 'react-spring';
import styled from 'styled-components';

export const ChartSVGLine = styled(animated.svg)`
  position: absolute;
  bottom: 0;
  left: 0;
`;

export const StyledHoverLegend = styled(LegendOrdinal)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 4px;
  gap: 16px;
  font-size: 12px;
  color: ${({ theme }) => theme.colors.transcendNavy2};
`;
