import { createTheme } from '@main/theme';

import { AdminDashboardGlobalStyles } from './global-styles';

/**
 * The color theme definition for the app
 */
export const ADMIN_DASHBOARD_THEME = createTheme({
  name: 'admin-dashboard',
  colors: {
    bgAccent: '#f7f7f7',
    primary: '#3333ff',
    secondary: '#787A80',
    textOnBg: '#222222',
    textLightOnBg: '#222222',
    error: '#DD3515',
  },
  GlobalStyles: AdminDashboardGlobalStyles,
});
