import mergeWith from 'lodash/mergeWith';
import uniq from 'lodash/uniq';
import sanitizeHtmlLib from 'sanitize-html';

import { SANITIZE_OPTIONS_FOR_EXTENSION } from './constants';
import { RichEditorExtension } from './enums';

export const getSanitizeOptionsFromExtensions = (
  extensions: RichEditorExtension[],
): sanitizeHtmlLib.IOptions => {
  const options: sanitizeHtmlLib.IOptions = {};

  extensions.forEach((extension) => {
    mergeWith(
      options,
      SANITIZE_OPTIONS_FOR_EXTENSION[extension],
      (objValue, srcValue) => {
        if (Array.isArray(objValue) && Array.isArray(srcValue)) {
          return uniq([...objValue, ...srcValue]);
        }
        // eslint-disable-next-line consistent-return, no-useless-return
        return;
      },
    );
  });

  return options;
};
