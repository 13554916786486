import { FilterConfigItem } from '@main/ad-core-components';
import {
  SelectedSoftwareDevelopmentKit,
  SelectSoftwareDevelopmentKits,
} from '@main/admin-dashboard/src/CodeScanning/components/SelectSoftwareDevelopmentKits';
import { endpoints } from '@main/code-scanning-types';
import { buildUseLazyQuery } from '@main/core-ui';
import { ID } from '@main/schema-utils';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { MessageDescriptor } from 'react-intl';

import { filterManagerHookMessages } from './messages';

/**
 * Filters definition with property for softwareDevelopmentKit values specified
 */
type SoftwareDevelopmentKitEnrichedFilters = Record<string, unknown> &
  Record<
    /**
     * SoftwareDevelopmentKit values that are currently filtering the table
     */
    string,
    SelectedSoftwareDevelopmentKit[] | undefined
  >;

const useLazySoftwareDevelopmentKits = buildUseLazyQuery(
  endpoints.softwareDevelopmentKits,
);

export const useSoftwareDevelopmentKitFilters = <
  T extends Record<string, unknown>,
  E extends SoftwareDevelopmentKitEnrichedFilters,
>({
  softwareDevelopmentKitIdsFilterKey = 'softwareDevelopmentKitIds',
  filters,
  setFilters,
  label,
  enrichedSoftwareDevelopmentKitKey = 'softwareDevelopmentKits',
}: {
  /** The property name of the filter for softwareDevelopmentKit ids */
  softwareDevelopmentKitIdsFilterKey?: keyof T;
  /** The currently applied filters */
  filters?: T;
  /** Callback when the filters are changed */
  setFilters: (filters: T) => void;
  /** Label for the filter menu */
  label: string | MessageDescriptor;
  /** The key to use if more than one useSoftwareDevelopmentKitFilters is used in a FilterManager */
  enrichedSoftwareDevelopmentKitKey?: keyof E;
}): {
  /**  The softwareDevelopmentKit keys enabled on this table */
  selectedSoftwareDevelopmentKits: SelectedSoftwareDevelopmentKit[];
  /** The filter configuration to be passed to the filter manager */
  softwareDevelopmentKitFiltersConfig: FilterConfigItem<E>;
  /** Callback for when the filter is cleared in the filter manager */
  clearSoftwareDevelopmentKitFilters: (key: Extract<keyof E, string>) => void;
} => {
  const getSelectedSoftwareDevelopmentKits = useLazySoftwareDevelopmentKits();
  const [selectedSoftwareDevelopmentKits, setSelectedSoftwareDevelopmentKits] =
    useState<SelectedSoftwareDevelopmentKit[]>([]);
  const [fetchingSoftwareDevelopmentKits, setFetchingSoftwareDevelopmentKits] =
    useState(false);

  // Populate initial complex filter values
  useEffect(() => {
    if (filters) {
      const softwareDevelopmentKitIds = (filters[
        softwareDevelopmentKitIdsFilterKey
      ] ?? []) as ID<'softwareDevelopmentKit'>[];

      const filtersAndEnrichedFiltersMatch =
        softwareDevelopmentKitIds.sort().join() ===
        selectedSoftwareDevelopmentKits
          .map(({ id }) => id)
          .sort()
          .join();

      if (
        softwareDevelopmentKitIds.length === 0 &&
        selectedSoftwareDevelopmentKits.length > 0
      ) {
        setSelectedSoftwareDevelopmentKits([]);
      } else if (
        !filtersAndEnrichedFiltersMatch &&
        !fetchingSoftwareDevelopmentKits
      ) {
        // Prevent over-firing this setter while data is still being fetched
        setFetchingSoftwareDevelopmentKits(true);
        getSelectedSoftwareDevelopmentKits({
          filterBy: { ids: softwareDevelopmentKitIds },
        })
          .then(({ data }) => {
            setSelectedSoftwareDevelopmentKits(data.nodes);
          })
          .finally(() => {
            setFetchingSoftwareDevelopmentKits(false);
          });
      }
    }
  }, [
    filters,
    fetchingSoftwareDevelopmentKits,
    getSelectedSoftwareDevelopmentKits,
    selectedSoftwareDevelopmentKits.length,
    softwareDevelopmentKitIdsFilterKey,
    selectedSoftwareDevelopmentKits,
  ]);

  const softwareDevelopmentKitFiltersConfig = useMemo(
    () =>
      ({
        filterKey: enrichedSoftwareDevelopmentKitKey,
        label,
        pillOptions: {
          label: ({
            filterValues: {
              [enrichedSoftwareDevelopmentKitKey]: softwareDevelopmentKits = [],
            },
            index = 0,
          }) => softwareDevelopmentKits[index]?.name,
        },
        filter: (
          <SelectSoftwareDevelopmentKits
            isMulti
            placeholderDescriptor={
              filterManagerHookMessages.softwareDevelopmentKitsPlaceholder
            }
            menuPosition="absolute"
            value={selectedSoftwareDevelopmentKits}
            showOutline={false}
            onChange={(softwareDevelopmentKits) => {
              setSelectedSoftwareDevelopmentKits([...softwareDevelopmentKits]);
              setFilters({
                ...filters,
                [softwareDevelopmentKitIdsFilterKey]:
                  softwareDevelopmentKits.map(({ id }) => id),
              } as T);
            }}
          />
        ),
      }) as FilterConfigItem<SoftwareDevelopmentKitEnrichedFilters>,
    [
      enrichedSoftwareDevelopmentKitKey,
      label,
      selectedSoftwareDevelopmentKits,
      filters,
      softwareDevelopmentKitIdsFilterKey,
    ],
  );

  const clearSoftwareDevelopmentKitFilters = useCallback(
    (key) => {
      if (key === enrichedSoftwareDevelopmentKitKey) {
        setFilters({
          ...filters,
          [softwareDevelopmentKitIdsFilterKey]: [],
        } as T);
        setSelectedSoftwareDevelopmentKits([]);
      }
    },
    [
      enrichedSoftwareDevelopmentKitKey,
      filters,
      softwareDevelopmentKitIdsFilterKey,
    ],
  );

  return {
    selectedSoftwareDevelopmentKits,
    softwareDevelopmentKitFiltersConfig,
    clearSoftwareDevelopmentKitFilters,
  };
};
