import { mkMutation, mkQuery } from '@main/schema-utils';

import {
  AnalyticsInput,
  AnalyticsResult,
  DataSiloCountsByCountry,
  DataSiloCountsByCountryInput,
  SavingsConfigurationPreview,
  UpdateSavingsConfigurationInput,
} from './schema';

export const analyticsData = mkQuery({
  name: 'analyticsData',
  comment: 'Fetch data from an analytics source',
  params: {
    input: AnalyticsInput,
  },
  response: AnalyticsResult,
});

export const dataSiloCountsByCountry = mkQuery({
  name: 'dataSiloCountsByCountry',
  comment:
    'fetches all country codes and a total count of all of their data silos',
  params: {
    input: DataSiloCountsByCountryInput,
  },
  response: DataSiloCountsByCountry,
});

export const savingsConfiguration = mkQuery({
  name: 'savingsConfiguration',
  comment: 'Fetch the savings configuration information for the org',
  params: {},
  response: SavingsConfigurationPreview,
});

export const updateSavingsConfiguration = mkMutation({
  name: 'updateSavingsConfiguration',
  comment: 'Updates the savings configuration with the specified values',
  params: { input: UpdateSavingsConfigurationInput },
  response: { updatedConfiguration: SavingsConfigurationPreview },
});
