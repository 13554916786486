import { makeEnum } from '@transcend-io/type-utils';

export const CODE_SCANNING_PATH_PREFIX = '/code-scanning';

export const AdminDashboardCodeScanningPath = makeEnum({
  Repositories: `${CODE_SCANNING_PATH_PREFIX}/repositories`,
  SoftwareDevelopmentKits: `${CODE_SCANNING_PATH_PREFIX}/sdks`,
  PullRequests: `${CODE_SCANNING_PATH_PREFIX}/pull-requests`,
  CodePackages: `${CODE_SCANNING_PATH_PREFIX}/code-packages`,
});

/** Override type */
export type AdminDashboardCodeScanningPath =
  (typeof AdminDashboardCodeScanningPath)[keyof typeof AdminDashboardCodeScanningPath];
