import * as t from 'io-ts';

import { dbModelId } from '@main/schema-utils';

import { AuditEventCode } from '../schema/enums';
import { mkAuditEventCodec } from '../types';

export const AuditEventDataCategory = mkAuditEventCodec(
  AuditEventCode.DataCategory,
  t.type({
    /** ID of the data category */
    dataCategoryId: dbModelId('dataCategory'),
  }),
);

/** Override types. */
export type AuditEventDataCategory = t.TypeOf<typeof AuditEventDataCategory>;
