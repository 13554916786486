import { makeEnum } from '@transcend-io/type-utils';

/**
 * The statuses that indicate a successful completion
 */
export const RequestEnricherStatusSuccess = makeEnum({
  /** Successfully resolved */
  Resolved: 'RESOLVED',
  /** Skipped processing because already completed */
  Skipped: 'SKIPPED',
});

/**
 * Overload type
 */
export type RequestEnricherStatusSuccess =
  (typeof RequestEnricherStatusSuccess)[keyof typeof RequestEnricherStatusSuccess];

/**
 * Fields that a list of RequestEnrichers can be ordered by (in ASC or DESC order)
 */
export enum RequestEnricherOrderField {
  /** Order in direction of RequestEnricher creation (submission time) */
  CreatedAt = 'createdAt',
  /** Order in direction of last modification of the RequestEnricher */
  UpdatedAt = 'updatedAt',
  /** Enricher title */
  Title = 'title', // enricher
}

/**
 * Associated models that can be sorted on
 */
export enum RequestEnricherSortAssociation {
  /** Sort on enricher title */
  Enricher = 'enricher',
}
