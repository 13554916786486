import {
  FlexColumn,
  FlexRowCenterBoth,
  Icon,
  LoadingSpinner,
  StyleUtils,
} from '@main/core-ui';
import React from 'react';
import { useIntl } from 'react-intl';
import { useTheme } from 'styled-components';

import { reviewChangesAndPublishMessages } from './messages';

interface PreparePublishStepsProps {
  /** are we currently saving the draft state? */
  saveDraftLoading: boolean;
  /** is the draft state data loading */
  savedStateLoading: boolean;
  /** is the live state data loading */
  liveStateLoading: boolean;
}

/**
 * PreparePublishSteps
 */
export const PreparePublishSteps: React.FC<PreparePublishStepsProps> = ({
  savedStateLoading,
  liveStateLoading,
  saveDraftLoading,
}) => {
  const { formatMessage } = useIntl();
  const theme = useTheme();

  return (
    <FlexColumn
      style={{
        gap: StyleUtils.Spacing.sm,
      }}
    >
      <div>
        {formatMessage(
          reviewChangesAndPublishMessages.preparingPublishDescription,
        )}
      </div>
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: '30px 1fr',
          gap: StyleUtils.Spacing.sm,
          alignItems: 'center',
        }}
      >
        <FlexRowCenterBoth style={{ height: 28 }}>
          {saveDraftLoading ? (
            <LoadingSpinner small />
          ) : (
            <Icon type="checkmark" color={theme.colors.mint1} size="2em" />
          )}
        </FlexRowCenterBoth>
        <div style={{ fontWeight: 600 }}>
          {formatMessage(reviewChangesAndPublishMessages.savingDraftLoading)}
        </div>
        <FlexRowCenterBoth style={{ height: 28 }}>
          {saveDraftLoading ? (
            <Icon type="clock" size="1.6em" />
          ) : savedStateLoading ? (
            <LoadingSpinner small />
          ) : (
            <Icon type="checkmark" color={theme.colors.mint1} size="2em" />
          )}
        </FlexRowCenterBoth>
        <div style={{ fontWeight: 600 }}>
          {formatMessage(reviewChangesAndPublishMessages.savedStateLoading)}
        </div>
        <FlexRowCenterBoth style={{ height: 28 }}>
          {saveDraftLoading ? (
            <Icon type="clock" size="1.6em" />
          ) : liveStateLoading ? (
            <LoadingSpinner small />
          ) : (
            <Icon type="checkmark" color={theme.colors.mint1} size="2em" />
          )}
        </FlexRowCenterBoth>
        <div style={{ fontWeight: 600 }}>
          {formatMessage(reviewChangesAndPublishMessages.publishedStateLoading)}
        </div>
      </div>
    </FlexColumn>
  );
};
