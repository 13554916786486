/**
 * Fields that an assetFile can be ordered by
 */
export enum AssetFileOrderField {
  /** The time the asset was created */
  CreatedAt = 'createdAt',
  /** The time the asset was updated */
  UpdatedAt = 'updatedAt',
  /** The size of the file in bytes */
  Size = 'size',
}
