import * as t from 'io-ts';

import { dbModelId } from '@main/schema-utils';

import { AuditEventCode } from '../schema/enums';
import { mkAuditEventCodec } from '../types';

export const AuditEventAssessmentSectionComment = mkAuditEventCodec(
  AuditEventCode.AssessmentSectionComment,
  t.intersection([
    t.type({
      /** ID of the assessment section comment */
      assessmentSectionCommentId: dbModelId('assessmentSectionComment'),
    }),
    t.partial({
      /** ID of the assessment section that owns the comment */
      assessmentSectionId: dbModelId('assessmentSection'),
      /** ID of the assessment form that owns the comment */
      assessmentFormId: dbModelId('assessmentForm'),
    }),
  ]),
);

/** Override types. */
export type AuditEventAssessmentSectionComment = t.TypeOf<
  typeof AuditEventAssessmentSectionComment
>;
