/**
 * The fields that a contract can be ordered on
 */
export enum ContractOrderField {
  /** The name of the contract */
  Name = 'name',
  /** The age of the contract */
  CreatedAt = 'createdAt',
  /** The last updated time */
  UpdatedAt = 'updatedAt',
}
