import { InlineSelectTeams, SelectedTeam } from '@main/ad-core-components';
import { buildUseMutation } from '@main/core-ui';
import { endpoints } from '@main/datamap-types';
import type { ID } from '@main/schema-utils';
import React from 'react';

const useUpdateDataPointLevel = buildUseMutation(
  endpoints.updateDataPointLevel,
);

/** Props for the `InlineSelectDataPointLevelTeams` component */
interface InlineSelectDataPointLevelTeamsProps {
  /** The ID of the parent data point level for this cell */
  id: ID<'dataPointLevel'>;
  /** The value for the cell */
  value: SelectedTeam[];
}

export const InlineSelectDataPointLevelTeams: React.FC<
  InlineSelectDataPointLevelTeamsProps
> = ({ id, value }) => {
  const [updateDataPointLevel, { loading }] = useUpdateDataPointLevel({});

  return (
    <InlineSelectTeams
      value={value}
      loading={loading}
      onUpdate={(teamIds) =>
        updateDataPointLevel({
          variables: {
            input: {
              id,
              teamIds,
            },
          },
        }).then(({ data, errors }) => ({
          data: data?.dataPointHierarchy?.teams,
          errors,
        }))
      }
    />
  );
};
