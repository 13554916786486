import { endpoints } from '@main/access-control-types';
import { TextArea, useInlineInput } from '@main/ad-core-components';
import { buildUseMutation } from '@main/core-ui';
import type { ID } from '@main/schema-utils';
import isEqual from 'lodash/isEqual';
import React from 'react';
import { useIntl } from 'react-intl';

import { actionItemsTableMessages } from '../messages';
import { RowTableContext } from '../types';

const useUpdateActionItems = buildUseMutation(endpoints.updateActionItems);

interface InlineActionItemNotesProps {
  /** ids of the action items for this cell */
  ids: ID<'actionItem'>[];
  /** The value for the cell */
  values: string[];
  /** Queries to refetch on success */
  refetchQueries?: string[];
  /** The table context */
  context: RowTableContext;
  /** are there multiple different values for this field */
  hasMultipleValues?: boolean;
}

export const InlineActionItemNotes: React.FC<InlineActionItemNotesProps> = ({
  ids,
  values: initialValues,
  refetchQueries = ['GlobalActionItems', 'GlobalActionItemsSubTable'],
  context,
  hasMultipleValues,
}) => {
  const { formatMessage } = useIntl();
  const [updateActionItems, { loading }] = useUpdateActionItems(
    refetchQueries
      ? {
          refetchQueries,
          awaitRefetchQueries: true,
        }
      : {},
  );
  const { value, setValue, onBlur } = useInlineInput<string>({
    // If there are multiple different notes, show nothing as the default
    initialValue: hasMultipleValues ? '' : initialValues[0],
    optionsComparator: (value, previousValue) => isEqual(value, previousValue),
    onUpdate: (notes) =>
      updateActionItems({
        variables: {
          input: {
            notes,
            ...(context.count > 1
              ? {
                  queryFilters: context.filters,
                  rollupFilters: [context.rollupFilters],
                }
              : {
                  ids,
                }),
          },
        },
      }).then(({ data, errors }) => ({
        data: notes,
        errors,
        success: data?.success,
      })),
  });

  return (
    <TextArea
      onBlur={() => onBlur()}
      value={value}
      onChange={(event) => {
        setValue(event.target.value);
      }}
      disabled={loading}
      placeholder={formatMessage(
        hasMultipleValues
          ? actionItemsTableMessages.multipleNotes
          : actionItemsTableMessages.notes,
      )}
    />
  );
};
