import { PurposeSubCategory } from '@main/datamap-types';

export const USE_PROCESSING_PURPOSE_FILTER_FIELDS = {
  id: null,
  isDefault: null,
  purpose: null,
  name: null,
};

/** A preview of the PurposeSubCategory type used by the useProcessingPurposeFilter hook */
export type UseProcessingPurposeFilterPreview = Pick<
  PurposeSubCategory,
  keyof typeof USE_PROCESSING_PURPOSE_FILTER_FIELDS
>;
