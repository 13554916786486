import styled from 'styled-components';

import { TabBar } from '@main/core-ui';

export const StyledTabBar = styled(TabBar)`
  margin-bottom: 1em;

  .nav-link {
    font-size: 14px;
  }
  .nav-item {
    // flex-basis is arbitrary since the items also have
    // flex-grow. It just keeps the items the same width.
    flex: 1 1 20px;
    text-align: center;
    margin: 0;
  }
`;

export const Divider = styled.div`
  align-self: stretch;
  margin: 0 10px;
  border-left: 1px solid ${({ theme }) => theme.colors.transcendNavy4};
`;

export const ColumnConfigSection = styled.div<{
  /** Whether the personal/org tabs are shown */
  showTabs: boolean;
}>`
  overflow: scroll;
  height: inherit;
  padding: ${({ showTabs }) => `${showTabs ? '0' : '16px'} 18px 16px`};
`;
