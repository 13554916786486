import { ItemsWithOverflow, RegionTitle } from '@main/core-ui';
import {
  IsoCountryCode,
  IsoCountrySubdivisionCode,
} from '@transcend-io/privacy-types';
import React from 'react';

export const RegionsReadOnly: React.FC<{
  /** List of regions to render */
  regions: string[];
  /** Number of items to display before putting the rest into an overflow container */
  overflowLimit?: number;
}> = ({ regions, overflowLimit = 2 }) => (
  <ItemsWithOverflow
    items={regions}
    limit={overflowLimit}
    renderElement={(region) => (
      <RegionTitle
        key={region}
        country={region.slice(0, 2) as IsoCountryCode}
        countrySubDivision={
          region.length > 2 ? (region as IsoCountrySubdivisionCode) : undefined
        }
      />
    )}
  />
);
