import styled from 'styled-components';

/**
 * Upload wrapper props
 */
export interface WrapperProps {
  /** whether the button is active or not */
  active: boolean;
}

export const ViewToggleContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 4px;
  border: 1px ${({ theme }) => theme.colors.gray4} solid;
  border-radius: 6px;
`;

export const ViewToggleButton = styled.button<WrapperProps>`
  height: 28px;
  width: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px
    ${({ theme, active }) =>
      active ? theme.colors.transcendNavy4 : 'transparent'}
    solid;
  border-radius: 6px;
  background: ${({ active }) =>
    active ? ({ theme }) => theme.colors.gray3 : 'none'};
`;
