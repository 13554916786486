import styled from 'styled-components';

import { hexToRgb } from '@main/theme-types';

import { Card } from '../Card';
import { StyledToolbar } from './Toolbar';

interface RichEditorOptions {
  /** shrink down the editor as much as possible */
  compact?: boolean;
}

export const StyledEditor = styled.div<RichEditorOptions>`
  /* Editor styles */

  .ProseMirror {
    margin: 0;
    ${({ compact }) => (compact ? '' : 'min-height: 200px;')}
    padding: ${({ compact }) => (compact ? '5px 0px' : '1em')};
    line-height: 1.5;
    max-width: 1000px;
    font-size: ${({ compact }) => (compact ? 14 : 16)}px;
    text-rendering: optimizeLegibility;

    h1,
    h2,
    h3 {
      margin-top: 1em;
      font-weight: 600;
    }

    li p {
      margin: 0.3em;
    }

    ol {
      list-style-type: decimal;
    }
    ol ol {
      list-style-type: lower-latin;
    }
    ol ol ol {
      list-style-type: lower-roman;
    }
    ol ol ol ol {
      list-style-type: upper-latin;
    }
    ol ol ol ol ol {
      list-style-type: upper-roman;
    }

    img {
      max-width: 100%;
      height: auto;
      margin-bottom: 1em;
    }
  }

  .ProseMirror[contenteditable='true'] {
    .image.ProseMirror-selectednode {
      img {
        box-shadow: 0 0 0 0.25rem
          ${({ theme }) => {
            const { r, g, b } = hexToRgb(theme.colors.transcend);
            return `rgba(${r}, ${g}, ${b}, .25)`;
          }};
      }
    }
  }

  .ProseMirror > *:first-child {
    margin-top: 0;
  }

  .ProseMirror-focused {
    outline: none;
  }

  /* Tables */
  .ProseMirror table {
    border-collapse: collapse;
    margin: 0;
    overflow: hidden;
    table-layout: fixed;
    width: 100%;
  }
  .ProseMirror table td,
  .ProseMirror table th {
    border: 2px solid #ced4da;
    box-sizing: border-box;
    min-width: 1em;
    padding: 3px 5px;
    position: relative;
    vertical-align: top;
  }
  .ProseMirror table td > *,
  .ProseMirror table th > * {
    margin-bottom: 0;
  }
  .ProseMirror table th {
    background-color: #f1f3f5;
    font-weight: bold;
    text-align: left;
  }
  .ProseMirror table .selectedCell:after {
    background: rgba(200, 200, 255, 0.4);
    content: '';
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    pointer-events: none;
    position: absolute;
    z-index: 2;
  }
  .ProseMirror table .column-resize-handle {
    background-color: #adf;
    bottom: -2px;
    position: absolute;
    right: -2px;
    pointer-events: none;
    top: 0;
    width: 4px;
  }
  .ProseMirror table p {
    margin: 0;
  }
  .tableWrapper {
    margin: 0;
    overflow-x: auto;
  }
  .resize-cursor {
    cursor: ew-resize;
    cursor: col-resize;
  }

  ${({ compact }) =>
    compact
      ? `
  .ProseMirror tr {
    display: flex;
  }
  .ProseMirror table td {
    border: none;
    padding: .5em 0;
  }
  `
      : ''}
`;

export const StyledEditorCardWrapper = styled(Card)<RichEditorOptions>`
  padding: 0;
  overflow-y: scroll;

  ${StyledEditor} {
    ${({ compact }) => (compact ? 'padding: 5px 10px;' : '')};
  }

  ${StyledToolbar} {
    // offset the outer buttons' padding so they line up with the content
    ${({ compact }) => (compact ? 'margin: 0 -5px;' : '')};
  }
`;
