import {
  ApiKeyOrderField,
  ApiKeyPreview,
  endpoints,
} from '@main/access-control-types';
import {
  PaginatedSelect,
  PaginatedSelectProps,
} from '@main/ad-core-components';
import { buildUseInfiniteScroll } from '@main/core-ui';
import { OrderDirection } from '@main/schema-utils';
import React, { useState } from 'react';
import { OptionsType } from 'react-select';

import { apiKeysMessages } from './messages';

const NODES = {
  id: null,
  title: null,
} as const;

/**
 * Selected API key
 */
export type SelectedApiKey = Pick<ApiKeyPreview, keyof typeof NODES>;

const useSelectApiKeys = buildUseInfiniteScroll(
  endpoints.apiKeys,
  'SelectApiKeys',
  {
    nodes: NODES,
  },
);

/**
 * Props for SelectApiKeys
 */
export type SelectApiKeyProps = Omit<
  PaginatedSelectProps<SelectedApiKey, true>,
  'isQueryLoading' | 'queryError' | 'fetchMore' | 'onEndsTyping'
> & {
  /** On change handler */
  onChange?: (teams: OptionsType<SelectedApiKey>) => void;
};

/**
 * Select a specific API key
 */
export const SelectApiKeys: React.FC<SelectApiKeyProps> = ({
  placeholderDescriptor = apiKeysMessages.placeholder,
  showOutline = true,
  ...paginatedSelectProps
}) => {
  const [searchText, setSearchText] = useState('');
  const { data, loading, error, fetchMore } = useSelectApiKeys({
    variables: {
      filterBy: {
        text: searchText,
      },
      orderBy: [
        { field: ApiKeyOrderField.Title, direction: OrderDirection.ASC },
      ],
    },
    fetchPolicy: 'cache-and-network',
  });

  return (
    <PaginatedSelect
      id="select-API keys"
      showOutline={showOutline}
      placeholderDescriptor={placeholderDescriptor}
      options={data?.nodes ?? []}
      isQueryLoading={loading}
      isMulti
      getOptionValue={({ id }: SelectedApiKey) => id}
      getOptionLabel={({ title }: SelectedApiKey) => title}
      queryError={error}
      fetchMore={fetchMore}
      onEndsTyping={setSearchText}
      {...paginatedSelectProps}
    />
  );
};
