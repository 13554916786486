import { ApiKeyPreview, UserPreview } from '@main/access-control-types';
import { mkInput, mkOrder, mkType, SchemaToType } from '@main/schema-utils';

import { ApplicationPreview } from './application';
import { ApplicationUserPreview } from './appUser';
import { PromptThreadOrderField } from './enums';
import { PathfinderPreview } from './pathfinder';
import { ReportPromptRunInput } from './promptRun';
import { PromptThreadPreview } from './promptThreadPreview';

export const PromptThread = mkType({
  name: 'PromptThread',
  comment:
    'A thread that was contains context that a large language model will run against',
  fields: {
    ...PromptThreadPreview.fields,
    promptRunCount: {
      comment: 'The number of runs against this thread',
      type: 'int',
    },
    messageCount: {
      comment: 'The number of messages in the thread',
      type: 'int',
    },
    user: {
      comment: 'The employee user that started the thread.',
      type: UserPreview,
      optional: true,
    },
    apiKey: {
      comment: 'The API key that reported the thread.',
      type: ApiKeyPreview,
      optional: true,
    },
    application: {
      comment: 'The application that reported the thread.',
      type: ApplicationPreview,
      optional: true,
    },
    pathfinder: {
      comment: 'The pathfinder that reported the thread.',
      type: PathfinderPreview,
      optional: true,
    },
    applicationUser: {
      comment: 'The application user that started the thread.',
      type: ApplicationUserPreview,
      optional: true,
    },
  },
});

/** Override type */
export type PromptThread = SchemaToType<typeof PromptThread>;

export const PromptThreadFiltersInput = mkInput({
  name: 'PromptThreadFiltersInput',
  comment: 'Inputs for filtering prompt threads',
  fields: {
    ids: {
      comment: 'The ids of the prompt threads',
      type: 'id',
      modelName: 'promptThread',
      optional: true,
      list: true,
    },
    creatorIds: {
      comment: 'The ids of the users who created a prompt thread',
      type: 'id',
      modelName: 'user',
      optional: true,
      list: true,
    },
    apiKeyIds: {
      comment: 'The ids of the API keys who created a prompt thread',
      type: 'id',
      modelName: 'apiKey',
      optional: true,
      list: true,
    },
    applicationUsersIds: {
      comment: 'The ids of the application users who created a prompt thread',
      type: 'id',
      modelName: 'appUser',
      optional: true,
      list: true,
    },
    pathfinderIds: {
      comment: 'The ids of the pathfinders who created a prompt thread',
      type: 'id',
      modelName: 'pathfinder',
      optional: true,
      list: true,
    },
    applicationIds: {
      comment: 'The ids of the applications that reported a prompt thread',
      type: 'id',
      modelName: 'application',
      optional: true,
      list: true,
    },
    text: {
      comment: 'Filter by fuzzy text (threadId of the prompt thread)',
      optional: true,
      type: 'string',
    },
    threadIds: {
      comment: 'Filter by exact thread ids',
      optional: true,
      type: 'string',
      list: true,
    },
    slackMessageTs: {
      comment: 'The list of slack message timestamps to filter by',
      optional: true,
      type: 'string',
      list: true,
    },
  },
});

/** Override type */
export type PromptThreadFiltersInput = SchemaToType<
  typeof PromptThreadFiltersInput
>;

export const DeletePromptThreadsInput = mkInput({
  name: 'DeletePromptThreadsInput',
  comment: 'Input for deleting a group of prompt threads',
  fields: {
    ids: {
      comment: 'The id of the prompt threads to delete',
      type: 'id',
      modelName: 'promptThread',
      list: true,
    },
  },
});

/** Override type */
export type DeletePromptThreadsInput = SchemaToType<
  typeof DeletePromptThreadsInput
>;

export const CreatePromptThreadInput = mkInput({
  name: 'CreatePromptThreadInput',
  comment: 'Input for creating a prompt thread',
  fields: {
    threadId: PromptThread.fields.threadId,
    slackMessageTs: PromptThread.fields.slackMessageTs,
    slackTeamId: PromptThread.fields.slackTeamId,
    slackChannelId: PromptThread.fields.slackChannelId,
    slackChannelName: PromptThread.fields.slackChannelName,
    applicationId: ReportPromptRunInput.fields.applicationId,
    applicationName: ReportPromptRunInput.fields.applicationName,
    runByEmployeeEmail: ReportPromptRunInput.fields.runByEmployeeEmail,
    applicationUserCoreIdentifier:
      ReportPromptRunInput.fields.applicationUserCoreIdentifier,
    applicationUserName: ReportPromptRunInput.fields.applicationUserName,
    pathfinderId: ReportPromptRunInput.fields.pathfinderId,
    pathfinderName: ReportPromptRunInput.fields.pathfinderName,
  },
});

/** Override type */
export type CreatePromptThreadInput = SchemaToType<
  typeof CreatePromptThreadInput
>;

export const UpdatePromptThreadInput = mkInput({
  name: 'UpdatePromptThreadInput',
  comment: 'Input for updating a Prompt thread',
  fields: {
    id: {
      comment:
        'The id of the prompt thread. Either this or threadId must be provided.',
      modelName: 'promptThread',
      type: 'id',
      optional: true,
    },
    threadId: {
      comment:
        'The ID of the thread in the remote system. Either this or id must be provided.',
      type: 'string',
      optional: true,
    },
    slackMessageTs: PromptThread.fields.slackMessageTs,
    slackTeamId: PromptThread.fields.slackTeamId,
    slackChannelId: PromptThread.fields.slackChannelId,
    slackChannelName: PromptThread.fields.slackChannelName,
  },
});

/** Override type */
export type UpdatePromptThreadInput = SchemaToType<
  typeof UpdatePromptThreadInput
>;

export const UpdatePromptThreadsInput = mkInput({
  name: 'UpdatePromptThreadsInput',
  comment: 'Input for bulk updating one or more prompt threads',
  fields: {
    promptThreads: {
      comment:
        'List of prompt threads with the properties that should be updated for each',
      type: UpdatePromptThreadInput,
      list: true,
    },
  },
});

/** Override type */
export type UpdatePromptThreadsInput = SchemaToType<
  typeof UpdatePromptThreadsInput
>;

/** Order for a promptThreads query */
export const PromptThreadOrder = mkOrder(
  PromptThread.name,
  PromptThreadOrderField,
);
