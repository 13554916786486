import { DefinedMessage } from '@main/internationalization';
import { mkInput, mkType, SchemaToType } from '@main/schema-utils';

export const PreferenceOptionValue = mkType({
  name: 'PreferenceOptionValue',
  comment: 'The option value for a preference',
  fields: {
    id: {
      comment: 'The id of the preference option',
      type: 'id',
      modelName: 'preferenceOptionValue',
    },
    title: {
      comment: 'The title of the preference option',
      type: DefinedMessage,
    },
    slug: {
      comment: 'The slug of the preference option',
      type: 'string',
    },
  },
});

/** Override type */
export type PreferenceOptionValue = SchemaToType<typeof PreferenceOptionValue>;

export const CreateOrUpdatePreferenceOptionValueInput = mkInput({
  name: 'CreateOrUpdatePreferenceOptionValueInput',
  comment: 'Input for creating or updating a single preference option value',
  fields: {
    id: {
      comment: 'The id of the preference option',
      type: 'id',
      modelName: 'preferenceOptionValue',
      optional: true,
    },
    title: {
      comment: 'The title of the preference option',
      type: 'string',
    },
    slug: {
      comment: 'The slug of the preference option',
      type: 'string',
      optional: true,
    },
  },
});

/** Override type */
export type CreateOrUpdatePreferenceOptionValueInput = SchemaToType<
  typeof CreateOrUpdatePreferenceOptionValueInput
>;

export const PreferenceOptionValueFilterInput = mkInput({
  name: 'PreferenceOptionValueFilterInput',
  comment: 'Filter input for preference option values',
  fields: {
    text: {
      type: 'string',
      comment: 'Filter by name',
      optional: true,
    },
    preferenceTopicIds: {
      type: 'id',
      comment: 'Filter by preference topic id',
      modelName: 'preferenceTopic',
      list: true,
      optional: true,
    },
  },
});

/** Override type */
export type PreferenceOptionValueFilterInput = SchemaToType<
  typeof PreferenceOptionValueFilterInput
>;

export const CreateOrUpdatePreferenceOptionValuesInput = mkInput({
  name: 'CreateOrUpdatePreferenceOptionValuesInput',
  comment: 'Input for creating or updating multiple preference option values',
  fields: {
    preferenceOptionValues: {
      type: CreateOrUpdatePreferenceOptionValueInput,
      list: true,
      comment: 'The preference option values to create or update',
    },
  },
});

/** Override type */
export type CreateOrUpdatePreferenceOptionValuesInput = SchemaToType<
  typeof CreateOrUpdatePreferenceOptionValuesInput
>;

export const DeletePreferenceOptionValuesInput = mkInput({
  name: 'DeletePreferenceOptionValuesInput',
  comment: 'Input for deleting multiple preference option values',
  fields: {
    ids: {
      type: 'id',
      modelName: 'preferenceOptionValue',
      comment: 'IDs of the preference option values to delete',
      list: true,
    },
  },
});

/** Override type */
export type DeletePreferenceOptionValuesInput = SchemaToType<
  typeof DeletePreferenceOptionValuesInput
>;

export const PrivacyCenterPreferenceOptionValue = mkType({
  name: 'PrivacyCenterPreferenceOptionValue',
  comment: 'The option value for a preference option on the privacy center',
  fields: {
    title: PreferenceOptionValue.fields.title,
    slug: PreferenceOptionValue.fields.slug,
  },
});

/** Override type */
export type PrivacyCenterPreferenceOptionValue = SchemaToType<
  typeof PrivacyCenterPreferenceOptionValue
>;

export const EnrichedPreferenceOption = mkType({
  name: 'EnrichedPreferenceOption',
  comment: 'Represents a preference option with all metadata enriched',
  fields: {
    id: {
      // this is not a uuid but JSON stringified object of codec `PreferenceOptionIdLookup`
      comment: 'A unique ID for the preference option change',
      type: 'string',
    },
    name: {
      comment: 'The unique name of the preference option slug',
      type: 'string',
    },
    preferenceOption: {
      comment:
        'The preference option metadata. If not provided, this is deleted',
      type: PreferenceOptionValue,
      optional: true,
    },
  },
});

/** Override type */
export type EnrichedPreferenceOption = SchemaToType<
  typeof EnrichedPreferenceOption
>;
