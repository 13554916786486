import type { Location } from 'react-router-dom';

import { message } from '@main/core-ui';
import { ImmutableUrl } from '@main/immutable-url';

import {
  ADMIN_DASHBOARD_URL,
  BACKEND_API_URLS,
  PRIVACY_POLICY_URL,
} from '../../args';

/**
 * When allowing for open redirects in a query param, only the
 * following URLs are allowed to be redirected to
 *
 * @see https://github.com/transcend-io/main/issues/10832
 */
const REMOTE_URL_REDIRECT_ALLOWLIST = [
  ...BACKEND_API_URLS.map((url) => new ImmutableUrl(url)),
  ADMIN_DASHBOARD_URL,
  PRIVACY_POLICY_URL,
];

/**
 * Parse the login the redirect URL
 *
 * @param redirect - The raw redirect query parameter
 * @returns The location object or string path
 */
export function parseRedirectTo(
  redirect: string | undefined,
): Location | string {
  // The redirect could be a relative path string or a location object
  let redirectTo: string | Location | undefined;
  try {
    redirectTo = redirect ? JSON.parse(redirect) : undefined;
  } catch (err) {
    redirectTo = redirect;
  }

  // ensure the url is in the allow list
  if (
    typeof redirectTo === 'string' &&
    ImmutableUrl.isCompleteUrl(redirectTo)
  ) {
    const incomingOrigin = new ImmutableUrl(redirectTo).origin;
    if (
      !REMOTE_URL_REDIRECT_ALLOWLIST.some(
        (allowed) => allowed.origin === incomingOrigin,
      )
    ) {
      const errMessage = `Attempted redirect to an unverified URL`;
      message.error(errMessage);
      throw new Error(errMessage);
    }
  }

  // Default is the base path (which will redirect to a default page)
  return (
    redirectTo || {
      key: '/',
      pathname: '/',
      search: '',
      state: '',
      hash: '',
    }
  );
}
