/* eslint-disable max-lines */
import {
  ConsentTrackerSource,
  ConsentTrackerStatus,
  DataFlowScope,
} from '@transcend-io/privacy-types';

import { TeamPreview, UserPreview } from '@main/access-control-types';
import { AttributeInput, AttributeValue } from '@main/attribute-types';
import { Catalog } from '@main/datamap-types';
import { mkInput, mkOrder, mkType, SchemaToType } from '@main/schema-utils';

import { DataFlowOrderField } from './enums';
import { TrackingPurpose } from './purpose';

export const DataFlowServicesFiltersInput = mkInput({
  name: 'DataFlowServicesFiltersInput',
  comment: 'Filters that can be applied when searching up data flow services',
  fields: {
    trackingTypes: {
      comment: 'Filter for services that have data flows of a certain rule',
      type: 'string',
      list: true,
      optional: true,
    },
    purposes: {
      comment: 'Filter for services that have data flows of a certain purpose',
      optional: true,
      type: 'id',
      modelName: 'purpose',
      list: true,
    },
    text: {
      comment: 'Filter data flows by text',
      type: 'string',
      optional: true,
    },
    isJunk: {
      comment: 'Filter for data flows marked as "junk"',
      type: 'boolean',
      optional: true,
    },
    status: {
      comment: "Filter for a data flow's status",
      type: { ConsentTrackerStatus },
      optional: true,
    },
    showZeroActivity: {
      comment:
        'Whether or not to show data flows with zero activity in the past 3 days. Defaults to false.',
      type: 'boolean',
      optional: true,
    },
  },
});

/** Override type */
export type DataFlowServicesFiltersInput = SchemaToType<
  typeof DataFlowServicesFiltersInput
>;

export const DataFlowService = mkType({
  name: 'DataFlowService',
  comment: 'The services (SaaS tools) that have configured data flows',
  fields: {
    service: {
      comment: 'The SaaS tool associated with these data flows',
      type: Catalog,
      optional: true,
    },
    types: {
      comment: 'The types of data flows configured for this service',
      type: { DataFlowScope },
      list: true,
    },
    trackingTypes: {
      comment: 'The tracking types that apply to data flows for this service',
      type: 'string',
      list: true,
    },
    purposes: {
      comment: 'A list of tracking purposes associated with this data flow',
      type: TrackingPurpose,
      list: true,
    },
    count: {
      comment: 'The number of data flows configured for this service',
      type: 'int',
    },
    isJunk: {
      comment: 'Whether this grouping contains only junk data flows',
      type: 'boolean',
    },
  },
});

/** Override type */
export type DataFlowService = SchemaToType<typeof DataFlowService>;

export const DataFlowsFiltersInput = mkInput({
  name: 'DataFlowsFiltersInput',
  comment: 'Filters that can be applied when querying for data flows',
  fields: {
    service: {
      comment:
        // eslint-disable-next-line max-len
        'Filter for data flows that are associated with a particular integration (or empty string for unassociated data flows)',
      type: 'string',
      optional: true,
    },
    text: DataFlowServicesFiltersInput.fields.text,
    trackingTypes: DataFlowServicesFiltersInput.fields.trackingTypes,
    purposes: DataFlowServicesFiltersInput.fields.purposes,
    isJunk: DataFlowServicesFiltersInput.fields.isJunk,
    showZeroActivity: DataFlowServicesFiltersInput.fields.showZeroActivity,
    ownerIds: {
      comment: 'Filter by the assigned owner IDs',
      optional: true,
      type: 'id',
      modelName: 'user',
      list: true,
    },
    teamIds: {
      comment: 'Filter by the assigned team IDs',
      optional: true,
      type: 'id',
      modelName: 'team',
      list: true,
    },
    attributeValueIds: {
      comment: 'Filter by the attribute values used to label the data flows',
      type: 'id',
      modelName: 'attributeValue',
      list: true,
      optional: true,
    },
    source: {
      comment: 'Filter for data flows by their source',
      type: { ConsentTrackerSource },
      optional: true,
    },
    status: DataFlowServicesFiltersInput.fields.status,
    type: {
      comment: 'Filter for a data flow based on type',
      type: { DataFlowScope },
      optional: true,
    },
    discoveredBy: {
      comment: 'The list of plugins that found this data silo',
      list: true,
      type: 'id',
      modelName: 'dataSilo',
      optional: true,
    },
    createdAt: {
      comment: 'Filter for data flows by when they were created',
      type: 'string',
      optional: true,
    },
    lastDiscoveredAt: {
      comment: 'Filter for data flows by when they were last encountered',
      type: 'string',
      optional: true,
    },
    encounters: {
      comment: 'Filter for data flows by the places they have been encountered',
      optional: true,
      type: 'string',
    },
    minOccurrences: {
      comment:
        'Filter for data flows with a minimum number of times the data flow has been seen',
      optional: true,
      type: 'int',
    },
    matchesUrl: {
      comment: 'Filter for data flows that match a specific url',
      optional: true,
      type: 'string',
    },
  },
});

/** Override type */
export type DataFlowsFiltersInput = SchemaToType<typeof DataFlowsFiltersInput>;

export const DataFlow = mkType({
  name: 'DataFlow',
  comment: 'An Airgap data flow config',
  fields: {
    id: {
      comment: 'The ID of this data flow',
      type: 'id',
      modelName: 'airgapDataFlow',
    },
    description: {
      comment: 'The description for this data flow',
      type: 'string',
      optional: true,
    },
    value: {
      comment: 'The value of this data flow',
      type: 'string',
    },
    type: {
      comment: 'The type of this data flow',
      type: { DataFlowScope },
    },
    trackingType: {
      comment: 'The tracking types that apply to this data flow',
      type: 'string',
      list: true,
    },
    purposes: {
      comment: 'A list of tracking purposes associated with this data flow',
      type: TrackingPurpose,
      list: true,
    },
    frequency: {
      comment: 'The relative frequency of this data flow',
      type: 'float',
    },
    service: {
      comment: 'The SaaS tool associated with these data flows',
      type: Catalog,
      optional: true,
    },
    isJunk: {
      comment: 'Whether Airgap should ignore this data flow',
      type: 'boolean',
    },
    source: {
      comment: 'The source of this data flow, (scanned or manually entered)',
      type: { ConsentTrackerSource },
    },
    status: {
      comment: 'The status of this data flow',
      type: { ConsentTrackerStatus },
    },
    createdAt: {
      comment: 'The time the request was made',
      type: 'Date',
    },
    updatedAt: {
      comment: 'The time the request was last updated',
      type: 'Date',
    },
    encounters: {
      comment: 'Places this data flow was encountered',
      type: 'string',
      list: true,
      optional: true,
    },
    lastDiscoveredAt: {
      comment: 'The last time this particular data flow was encountered',
      type: 'Date',
      optional: true,
    },
    attributeValues: {
      comment: 'The attribute values used to label this data flow',
      type: AttributeValue,
      list: true,
    },
    owners: {
      comment:
        'The list of individual users who are responsible for managing this data flow',
      list: true,
      type: UserPreview,
    },
    teams: {
      comment:
        'The list of teams who are responsible for managing this data flow',
      list: true,
      type: TeamPreview,
    },
    pendingTelemetryMetadataSync: {
      comment:
        'Whether or not this data flow has a pending telemetry metadata sync',
      type: 'boolean',
    },
    occurrences: {
      comment: 'The number of times this data flow has been seen',
      type: 'float',
    },
  },
});

/** Override type */
export type DataFlow = SchemaToType<typeof DataFlow>;

export const DataFlowInput = mkInput({
  name: 'DataFlowInput',
  comment: 'Input for a new data flow',
  fields: {
    value: {
      comment: 'The value for the data flow',
      type: 'string',
    },
    type: {
      comment: 'The type of this data flow',
      type: { DataFlowScope },
    },
    trackingType: {
      comment: 'The tracking types that apply to this data flow',
      type: 'string',
      list: true,
      optional: true,
    },
    purposeIds: {
      comment: 'The purposes that apply to this data flow',
      modelName: 'purpose',
      type: 'id',
      list: true,
      optional: true,
    },
    description: {
      comment: 'The description for this data flow',
      type: 'string',
      optional: true,
    },
    service: {
      comment: 'The name of the SaaS tool associated with this data flow',
      type: 'string',
      optional: true,
    },
    isJunk: {
      comment: 'Whether Airgap should ignore this data flow',
      type: 'boolean',
      optional: true,
    },
    status: {
      comment: 'The status of this data flow',
      type: { ConsentTrackerStatus },
      optional: true,
    },
    attributes: {
      comment: 'The attribute values used to label this data flow',
      type: AttributeInput,
      list: true,
      optional: true,
    },
  },
});

/** Override type */
export type DataFlowInput = SchemaToType<typeof DataFlowInput>;

export const CreateDataFlowsInput = mkInput({
  name: 'CreateDataFlowInput',
  comment: 'Input to create new data flow entries',
  fields: {
    airgapBundleId: {
      comment: 'The airgap bundle to create the data flows for',
      type: 'id',
      modelName: 'airgapBundle',
    },
    dataFlows: {
      comment: 'The data flow entries to create',
      type: DataFlowInput,
      list: true,
    },
    dropMatchingDataFlowsInTriage: {
      comment:
        'Whether or not to drop matching data flows in triage if it is covered by any of the data flows being added. ' +
        'Defaults to false.',
      type: 'boolean',
      optional: true,
    },
    classifyService: {
      comment: 'Invoke service classifier if integration unknown',
      type: 'boolean',
      optional: true,
    },
  },
});

/** Override type */
export type CreateDataFlowsInput = SchemaToType<typeof CreateDataFlowsInput>;

export const UpdateDataFlowInput = mkInput({
  name: 'UpdateDataFlowInput',
  comment: 'Input for updating a data flow',
  fields: {
    id: {
      comment: 'The id of the data flow',
      type: 'id',
      modelName: 'airgapDataFlow',
    },
    value: {
      ...DataFlowInput.fields.value,
      optional: true,
    },
    type: {
      ...DataFlowInput.fields.type,
      optional: true,
    },
    trackingType: {
      ...DataFlowInput.fields.trackingType,
      optional: true,
    },
    purposeIds: {
      ...DataFlowInput.fields.purposeIds,
      optional: true,
    },
    description: DataFlowInput.fields.description,
    ownerIds: {
      comment:
        'The unique ids of the users to assign as owners of this data flow',
      modelName: 'user',
      type: 'id',
      optional: true,
      list: true,
    },
    teamIds: {
      comment:
        'The ids of the teams that should be responsible for this data flow',
      type: 'id',
      modelName: 'team',
      list: true,
      optional: true,
    },
    service: {
      ...DataFlowInput.fields.service,
      comment:
        'The name of the SaaS tool associated with this data flow, if undefined will clear value',
    },
    isJunk: DataFlowInput.fields.isJunk,
    status: DataFlowInput.fields.status,
    dropMatchingDataFlowsInTriage: {
      comment:
        'Only relevant for LIVE data flows - ' +
        'Whether or not to drop matching data flows in triage if it is covered by the data flow being updated. ' +
        'Defaults to false.',
      type: 'boolean',
      optional: true,
    },
    attributes: {
      comment: 'The attribute values used to label this data flow',
      type: AttributeInput,
      list: true,
      optional: true,
    },
  },
});

/** Override type */
export type UpdateDataFlowInput = SchemaToType<typeof UpdateDataFlowInput>;

export const UpdateDataFlowsInput = mkInput({
  name: 'UpdateDataFlowsInput',
  comment: 'Input for bulk updating one or more data flows',
  fields: {
    airgapBundleId: {
      comment: 'The airgap bundle to update the data flows for',
      type: 'id',
      modelName: 'airgapBundle',
    },
    dataFlows: {
      comment:
        'List of data flows with the properties that should be updated for each',
      type: UpdateDataFlowInput,
      list: true,
    },
    classifyService: {
      comment: 'Invoke service classifier if integration unknown',
      type: 'boolean',
      optional: true,
    },
  },
});

/** Override type */
export type UpdateDataFlowsInput = SchemaToType<typeof UpdateDataFlowsInput>;

/*
 * Order for a dataFlows query
 */
export const DataFlowOrder = mkOrder(DataFlow.name, DataFlowOrderField);

/** Override type */
export type DataFlowOrder = SchemaToType<typeof DataFlowOrder>;

export const DeleteDataFlowsInput = mkInput({
  name: 'DeleteDataFlowsInput',
  comment: 'Input to delete data flows',
  fields: {
    airgapBundleId: {
      comment: 'The airgap bundle to delete the data flows for',
      type: 'id',
      modelName: 'airgapBundle',
    },
    ids: {
      comment: 'Delete data flows with ID in this list',
      type: 'id',
      modelName: 'airgapDataFlow',
      list: true,
      optional: true,
    },
    status: {
      comment: 'Delete data flows with this status',
      type: { ConsentTrackerStatus },
      optional: true,
    },
  },
});

/** Override type */
export type DeleteDataFlowsInput = SchemaToType<typeof DeleteDataFlowsInput>;

export const ClassifyDataFlowsInput = mkInput({
  name: 'ClassifyDataFlowsInput',
  comment: 'Input to classify for data flows to be created',
  fields: {
    dataFlows: {
      comment: 'The data flow entries to create',
      type: DataFlowInput,
      list: true,
    },
  },
});

/** Override type */
export type ClassifyDataFlowsInput = SchemaToType<
  typeof ClassifyDataFlowsInput
>;

export const DataFlowSuggestion = mkType({
  name: 'DataFlowSuggestion',
  comment:
    'Suggestions for certain data flow fields based on the user inputted value',
  fields: {
    value: {
      comment: 'The value of this data flow',
      type: 'string',
    },
    type: {
      comment: 'The type of this data flow',
      type: { DataFlowScope },
    },
    service: {
      ...DataFlow.fields.service,
      comment: 'The suggested service associated for this data flow',
    },
    trackingType: {
      comment: 'The suggested tracking purposes associated for this data flow',
      type: 'string',
      list: true,
    },
    description: {
      ...DataFlow.fields.description,
      comment: 'The suggested description for this data flow',
    },
  },
});

/** Override type */
export type DataFlowSuggestion = SchemaToType<typeof DataFlowSuggestion>;
/* eslint-enable max-lines */
