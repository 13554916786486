import styled, { keyframes } from 'styled-components';

import { RadarIconProps } from './types';

const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

export const RadarIconContainer = styled.svg<RadarIconProps>`
  animation: ${spin} 2s linear infinite;
  animation-play-state: ${({ isAnimated }) =>
    isAnimated ? 'running' : 'paused'};
  position: relative;
  height: 1.1em;
  width: 1.1em;
  vertical-align: middle;
`;
