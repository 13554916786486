import * as t from 'io-ts';

import { dbModelId } from '@main/schema-utils';

import { AuditEventCode } from '../schema/enums';
import {
  mkAuditEventCodec,
  mkAuditEventJoinCodec,
  mkAuditJoinStateCodec,
} from '../types';

const baseFields = {
  /** ID of the identifier */
  identifierId: dbModelId('identifier'),
};

export const AuditEventIdentifier = mkAuditEventCodec(
  AuditEventCode.Identifier,
  t.type({
    /** ID of the identifier */
    identifierId: dbModelId('identifier'),
  }),
);

/** Override types. */
export type AuditEventIdentifier = t.TypeOf<typeof AuditEventIdentifier>;

export const AuditEventIdentifierSubject = mkAuditEventJoinCodec(
  AuditEventCode.IdentifierSubject,
  mkAuditJoinStateCodec('subject'),
  t.type(baseFields),
);
/** Override types. */
export type AuditEventIdentifierSubject = t.TypeOf<typeof AuditEventIdentifier>;

export const AuditEventIdentifierAction = mkAuditEventJoinCodec(
  AuditEventCode.IdentifierAction,
  mkAuditJoinStateCodec('action'),
  t.type(baseFields),
);
/** Override types. */
export type AuditEventIdentifierAction = t.TypeOf<typeof AuditEventIdentifier>;

export const AuditEventIdentifierDataSilo = mkAuditEventJoinCodec(
  AuditEventCode.IdentifierDataSilo,
  mkAuditJoinStateCodec('dataSilo'),
  t.type({
    ...baseFields,
    /** ID of the data silo */
    dataSiloId: dbModelId('dataSilo'),
  }),
);
/** Override types. */
export type AuditEventIdentifierDataSilo = t.TypeOf<
  typeof AuditEventIdentifier
>;
