import { Catalog } from '@main/datamap-types';

export const CATALOG_SELECTION_NODES = {
  id: null,
  title: null,
  description: null,
  integrationName: null,
  logo: null,
  logoSquare: null,
  alreadyConnected: null,
} as const;

/**
 * the type of the selected catalog
 */
export type SelectedCatalog = Pick<
  Catalog,
  keyof typeof CATALOG_SELECTION_NODES
>;

export interface UpdatedSiloGuessParams {
  /** the updated catalog selection */
  updatedCatalog?: SelectedCatalog;
  /** the updated custom title */
  updatedTitle?: string;
}
