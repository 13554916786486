import { FlexRowCenterVertical, Icon, StyleUtils } from '@main/core-ui';
import { IndexResult } from '@main/utils';
import React from 'react';
import { useIntl } from 'react-intl';
import { useTheme } from 'styled-components';

import { DefaultFilterSearch } from './DefaultFilterSearch';
import { FilterManagerProps } from './FilterManager';
import { FilterPill } from './FilterPill';
import { FilterPillValues } from './FilterPillValues';
import { filterManagerMessages } from './messages';
import { FilterConfigItem } from './types';

export const FilterPillsSection = <T extends Record<string, unknown>>({
  defaultSearchKey,
  filtersValues,
  filtersConfigLookup,
  placeholder,
  clearFilter,
  setActiveMenu,
  onChange,
  setMenuIsOpen,
}: Omit<FilterManagerProps<T>, 'children' | 'filtersConfig'> & {
  /** Function to set the menu to open/closed */
  setMenuIsOpen: (isOpen: boolean) => void;
  /** Callback to fire when the user clicks on a pill in the menu */
  setActiveMenu: (filterKey: Extract<keyof T, string> | '') => void;
  /** hash table for filters config array  */
  filtersConfigLookup: IndexResult<
    Extract<keyof T, string>,
    FilterConfigItem<T>
  >;
}): JSX.Element => {
  const valuesKeys = Object.keys(filtersValues).filter(
    (key) => filtersConfigLookup[key] && filtersValues[key] !== undefined,
    // The type is only inferred as string, but we need to be more specific
  ) as Extract<keyof T, string>[];

  const { formatMessage } = useIntl();
  const theme = useTheme();

  const isEmpty = valuesKeys.length === 0;
  const hasSearchFilterValue =
    defaultSearchKey && filtersValues[defaultSearchKey];

  return (
    <FlexRowCenterVertical
      style={{
        gap: '0.3rem',
      }}
    >
      {isEmpty && !hasSearchFilterValue && <Icon type="search" />}

      {/* Filtered values */}
      {valuesKeys.map((filterKey, index) => {
        const { label } = filtersConfigLookup[filterKey];
        const values = filtersValues[filterKey];
        const formattedLabel =
          typeof label === 'string' ? label : formatMessage(label);

        return (
          <div
            key={index}
            style={{
              ...StyleUtils.Flex.Row.CenterVertical,
              gap: '0.25rem',
              color: theme.colors.transcendNavy3,
              padding: isEmpty && !hasSearchFilterValue ? '0.2rem' : 0,
            }}
            onClick={() => {
              setActiveMenu(filterKey);
            }}
          >
            <FilterPill
              label={formattedLabel}
              onDismiss={(e) => {
                clearFilter(filterKey);
                e.stopPropagation();
              }}
            >
              {Array.isArray(values) ? (
                values.map((_, index) => (
                  <FilterPillValues
                    key={index}
                    label={formattedLabel}
                    filterValues={filtersValues}
                    filterItem={filtersConfigLookup[filterKey]}
                    index={index}
                  />
                ))
              ) : (
                <FilterPillValues
                  filterItem={filtersConfigLookup[filterKey]}
                  label={formattedLabel}
                  filterValues={filtersValues}
                />
              )}
            </FilterPill>
            {valuesKeys.length > (hasSearchFilterValue ? 0 : 1) &&
              (index !== valuesKeys.length - 1 || hasSearchFilterValue) && (
                <span>{formatMessage(filterManagerMessages.and)}</span>
              )}
          </div>
        );
      })}

      {defaultSearchKey && onChange ? (
        <DefaultFilterSearch
          clearFilter={clearFilter}
          defaultSearchKey={defaultSearchKey}
          value={
            typeof filtersValues[defaultSearchKey] === 'string'
              ? (filtersValues[defaultSearchKey] as string)
              : ''
          }
          setMenuIsOpen={setMenuIsOpen}
          onChange={onChange}
          placeholder={isEmpty ? placeholder : undefined}
        />
      ) : (
        <span>{placeholder && isEmpty ? formatMessage(placeholder) : ''}</span>
      )}
    </FlexRowCenterVertical>
  );
};
