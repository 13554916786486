import { Validators } from '@main/core-ui';
import { FormAutoComplete } from '@main/utils';
import React from 'react';
import { useIntl } from 'react-intl';

import { FORM_AUTO_COMPLETES } from './constants';
import { FormInput } from './FormInput';
import { inputMessages } from './messages';

export const FormPasswordInput: React.FC<
  React.ComponentProps<typeof FormInput>
> = (props) => {
  const { formatMessage } = useIntl();
  return (
    <FormInput
      type="password"
      rules={[Validators.REQUIRED]}
      label={formatMessage(inputMessages.password)}
      autoComplete={FORM_AUTO_COMPLETES[FormAutoComplete.CurrentPassword]}
      required
      {...props}
    />
  );
};
