import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { TableId } from '@main/access-control-types';
import { ID } from '@main/schema-utils';

import { selectPreferences } from '../../Auth/App/selectors';

export const useIsPersonalTab = (
  tableId: TableId,
  assessmentGroupId?: ID<'assessmentGroup'>,
): [boolean, Dispatch<SetStateAction<boolean | undefined>>] => {
  const { userPreference, organizationPreference } =
    useSelector(selectPreferences);

  // Keep it in state so it can be updated locally faster than it can via API
  const [isPersonalTab, setIsPersonalTab] = useState<boolean | undefined>();

  useEffect(() => {
    if (typeof isPersonalTab === 'undefined') {
      const tableConfiguration = assessmentGroupId
        ? userPreference?.tables?.RISK_INTELLIGENCE_ASSESSMENTS?.find(
            (t) => t.assessmentGroupId === assessmentGroupId,
          )
        : userPreference?.tables?.[tableId];
      setIsPersonalTab(!!tableConfiguration?.isPreferred);
    }
  }, [
    isPersonalTab,
    tableId,
    assessmentGroupId,
    organizationPreference,
    userPreference,
  ]);

  return [isPersonalTab || false, setIsPersonalTab];
};
