import { ApiKeyPreview, UserPreview } from '@main/access-control-types';
import { mkInput, mkOrder, mkType, SchemaToType } from '@main/schema-utils';

import { ContractOrderField } from './enums';

export const ContractPreview = mkType({
  name: 'ContractPreview',
  comment: 'A preview of a contract',
  fields: {
    id: {
      comment: 'The id of the contract',
      modelName: 'contract',
      type: 'id',
    },
    name: {
      comment: 'The title of the contract',
      type: 'string',
    },
    description: {
      comment: 'The description of the contract',
      type: 'string',
    },
    createdAt: {
      comment: 'The date the contract was created',
      type: 'Date',
    },
    updatedAt: {
      comment: 'The date the contract was last updated',
      type: 'Date',
    },
  },
});

/** Override type */
export type ContractPreview = SchemaToType<typeof ContractPreview>;

export const Contract = mkType({
  name: 'Contract',
  comment: 'A legal contract',
  fields: {
    ...ContractPreview.fields,
    content: {
      comment: 'The content of the contract',
      type: 'string',
    },
    uploader: {
      comment: 'The user who uploaded this contract',
      type: UserPreview,
      optional: true,
    },
    apiKey: {
      comment: 'The API key who uploaded this contract',
      type: ApiKeyPreview,
      optional: true,
    },
    lastEditor: {
      comment: 'The user who last edited the contract',
      type: UserPreview,
      optional: true,
    },
    contractScanCount: {
      comment: 'The number of times this contract has been scanned',
      type: 'int',
    },
    // TODO: https://transcend.height.app/T-31593 - add attributes
    // attributeValues: {
    //   comment: 'The attribute values used to label this assessment',
    //   type: AttributeValue,
    //   list: true,
    // },
  },
});

/** Override type */
export type Contract = SchemaToType<typeof Contract>;

export const ContractFiltersInput = mkInput({
  name: 'ContractFiltersInput',
  comment: 'Inputs for filtering a list of contracts',
  fields: {
    ids: {
      comment: 'The ids of the contracts',
      type: 'id',
      modelName: 'contract',
      optional: true,
      list: true,
    },
    uploaderIds: {
      comment: 'The ids of the users that uploaded the contracts',
      type: 'id',
      modelName: 'user',
      optional: true,
      list: true,
    },
    lastEditorIds: {
      comment: 'The ids of the users that edited the contracts',
      type: 'id',
      modelName: 'user',
      optional: true,
      list: true,
    },
    apiKeyIds: {
      comment: 'The ids of the API keys that uploaded the contracts',
      type: 'id',
      modelName: 'apiKey',
      optional: true,
      list: true,
    },
    text: {
      comment: 'Filter by text (name, description, content of the contract)',
      optional: true,
      type: 'string',
    },
    name: {
      comment: 'Fuzzy search by name by name of contact',
      optional: true,
      type: 'string',
    },
    content: {
      comment: 'Fuzzy search file contents',
      optional: true,
      type: 'string',
    },
  },
});

/** Override type */
export type ContractFiltersInput = SchemaToType<typeof ContractFiltersInput>;

export const DeleteContractsInput = mkInput({
  name: 'DeleteContractsInput',
  comment: 'Input for deleting a group of contracts',
  fields: {
    ids: {
      comment: 'The id of the contracts to delete',
      type: 'id',
      modelName: 'contract',
      list: true,
    },
  },
});

/** Override type */
export type DeleteContractsInput = SchemaToType<typeof DeleteContractsInput>;

export const CreateContractInput = mkInput({
  name: 'CreateContractInput',
  comment: 'Input for creating a contract',
  fields: {
    name: {
      comment: 'The name of the contract',
      type: 'string',
    },
    description: {
      comment: 'The description of the contract',
      type: 'string',
      optional: true,
    },
    content: {
      comment: 'The content of the contract',
      type: 'string',
    },
  },
});

/** Override type */
export type CreateContractInput = SchemaToType<typeof CreateContractInput>;

export const UpdateContractInput = mkInput({
  name: 'UpdateContractInput',
  comment: 'Input for updating a contract',
  fields: {
    id: {
      comment: 'The id of the contract',
      modelName: 'contract',
      type: 'id',
    },
    name: {
      comment: 'The new name of the contract',
      type: 'string',
      optional: true,
    },
    description: {
      comment: 'The new description of the contract',
      type: 'string',
      optional: true,
    },
    content: {
      comment: 'The content of the contract',
      type: 'string',
      optional: true,
    },
  },
});

/** Override type */
export type UpdateContractInput = SchemaToType<typeof UpdateContractInput>;

export const UpdateContractsInput = mkInput({
  name: 'UpdateContractsInput',
  comment: 'Input for bulk updating one or more contracts',
  fields: {
    contracts: {
      comment:
        'List of contracts with the properties that should be updated for each',
      type: UpdateContractInput,
      list: true,
    },
  },
});

/** Override type */
export type UpdateContractsInput = SchemaToType<typeof UpdateContractsInput>;

/** Order for a contracts query */
export const ContractOrder = mkOrder(Contract.name, ContractOrderField);
