import styled from 'styled-components';

export const ToolbarWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin: 1em 0;
  width: 100%;
  gap: 1em;
`;

export const RightWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  gap: 8px;
`;
