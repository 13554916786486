import {
  FlexRowCenterVertical,
  GenericMessageDescriptor,
  Icon,
  Tooltip,
  useFormatMessageGeneric,
  useQueryParam,
} from '@main/core-ui';
import React from 'react';
import { useTheme } from 'styled-components';

import { Button } from '../Button';
import { CircleLogoDisplay, StyledStepsList } from './wrappers';

export interface WizardStep {
  /** The id of the step */
  id: string;
  /** The title of the step */
  title: GenericMessageDescriptor;
  /** The description (shown on hover, if present) */
  description?: GenericMessageDescriptor;
}

export interface WizardStepsProps {
  /** The steps to display */
  steps: WizardStep[];
  /** The id of the active step */
  activeStepId?: string;
  /** Ids of completed steps */
  completedSteps?: string[];
}

export const WizardSteps: React.FC<WizardStepsProps> = ({
  steps,
  activeStepId,
  completedSteps = [],
}) => {
  const { formatMessageGeneric } = useFormatMessageGeneric();
  const { persistQueryParam } = useQueryParam({
    name: 'step',
  });
  const theme = useTheme();

  return (
    <StyledStepsList>
      {steps.map((step, index) => {
        const isCompleted = completedSteps.includes(step.id);
        const isActive = step.id === activeStepId;

        return (
          <li key={step.id} className={isActive ? 'active' : undefined}>
            <Tooltip title={step.description}>
              <FlexRowCenterVertical>
                <CircleLogoDisplay isComplete={isCompleted}>
                  {isCompleted ? (
                    <Icon type="checkmark" color={theme.colors.white} />
                  ) : (
                    <span
                      style={isActive ? { color: theme.colors.transcend2 } : {}}
                    >
                      {index + 1}
                    </span>
                  )}
                </CircleLogoDisplay>
                <Button
                  onClick={() => {
                    persistQueryParam(step.id);
                  }}
                  noPadding
                  variant="link"
                  style={{
                    color: isActive ? theme.colors.transcend2 : 'inherit',
                    // hardcoded to match the check/circle icon
                    height: '24px',
                  }}
                >
                  {formatMessageGeneric(step.title)}
                </Button>
              </FlexRowCenterVertical>
            </Tooltip>
          </li>
        );
      })}
    </StyledStepsList>
  );
};
