import * as t from 'io-ts';

import { dbModelId } from '@main/schema-utils';

import { AuditEventCode } from '../schema/enums';
import {
  mkAuditEventCodec,
  mkAuditEventJoinCodec,
  mkAuditJoinStateCodec,
} from '../types';

export const AuditEventRiskCategory = mkAuditEventCodec(
  AuditEventCode.RiskCategory,
  t.intersection([
    t.type({
      /** ID of the risk category */
      riskCategoryId: dbModelId('riskCategory'),
    }),
    t.partial({
      /** ID of the risk framework to which the risk category belongs */
      riskFrameworkId: dbModelId('riskFramework'),
    }),
  ]),
);

/** Override types. */
export type AuditEventRiskCategory = t.TypeOf<typeof AuditEventRiskCategory>;

export const AuditEventRiskCategoryTeam = mkAuditEventJoinCodec(
  AuditEventCode.RiskCategoryTeam,
  mkAuditJoinStateCodec('team'),
  t.type({
    /** ID of the risk category */
    riskCategoryId: dbModelId('riskCategory'),
  }),
);

/** Override types. */
export type AuditEventRiskCategoryTeam = t.TypeOf<
  typeof AuditEventRiskCategoryTeam
>;

export const AuditEventRiskCategoryOwner = mkAuditEventJoinCodec(
  AuditEventCode.RiskCategoryOwner,
  mkAuditJoinStateCodec('user'),
  t.type({
    /** ID of the risk category */
    riskCategoryId: dbModelId('riskCategory'),
  }),
);

/** Override types. */
export type AuditEventRiskCategoryOwner = t.TypeOf<
  typeof AuditEventRiskCategoryOwner
>;
