import { makeEnum } from '@transcend-io/type-utils';

export const AuditorRunStatus = makeEnum({
  /** This run is queued to be run */
  Queued: 'Queued',
  /** The auditor lamba was triggered and is in progress */
  Processing: 'Processing',
  /** This run was skipped or cancelled */
  Skipped: 'Skipped',
  /** Error state */
  Error: 'Error',
  /** The run is complete and processed */
  Complete: 'Complete',
});

/** Override type */
export type AuditorRunStatus =
  (typeof AuditorRunStatus)[keyof typeof AuditorRunStatus];
