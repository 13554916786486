import * as t from 'io-ts';

import { dbModelId } from '@main/schema-utils';

import { AuditEventCode } from '../schema/enums';
import {
  mkAuditEventCodec,
  mkAuditEventJoinCodec,
  mkAuditJoinStateCodec,
} from '../types';

export const AuditEventPrivacyCenter = mkAuditEventCodec(
  AuditEventCode.PrivacyCenter,
  t.type({
    /** ID of the privacy center */
    privacyCenterId: dbModelId('privacyCenter'),
  }),
);

/** Override types. */
export type AuditEventPrivacyCenter = t.TypeOf<typeof AuditEventPrivacyCenter>;

export const AuditEventEmailSenderAddress = mkAuditEventCodec(
  AuditEventCode.EmailSenderAddress,
  t.type({
    /** ID of the email sender address */
    emailSenderAddressId: dbModelId('emailSenderAddress'),
  }),
);

/** Override types. */
export type AuditEventEmailSenderAddress = t.TypeOf<
  typeof AuditEventEmailSenderAddress
>;

export const AuditEventPrivacyCenterEmailSenderAddress = mkAuditEventJoinCodec(
  AuditEventCode.PrivacyCenterEmailSenderAddress,
  mkAuditJoinStateCodec('privacyCenter'),
  t.type({
    /** ID of the privacy center */
    privacyCenterId: dbModelId('privacyCenter'),
    /** ID of the email sender address */
    emailSenderAddressId: dbModelId('emailSenderAddress'),
  }),
);

/** Override types. */
export type AuditEventPrivacyCenterEmailSenderAddress = t.TypeOf<
  typeof AuditEventPrivacyCenterEmailSenderAddress
>;

export const AuditEventEmailSenderAddressAttributeValue = mkAuditEventJoinCodec(
  AuditEventCode.EmailSenderAddressAttributeValue,
  mkAuditJoinStateCodec('emailSenderAddress'),
  t.type({
    /** ID of the attribute value */
    attributeValueId: dbModelId('attributeValue'),
  }),
);

/** Override types. */
export type AuditEventEmailSenderAddressAttributeValue = t.TypeOf<
  typeof AuditEventEmailSenderAddressAttributeValue
>;
