import { QueryResult } from '@apollo/client';

import { AssessmentFormTemplate, endpoints } from '@main/datamap-types';
import { ResponseToType } from '@main/schema-utils';

export const transformRawAssessmentFormTemplates = (
  data: QueryResult<
    ResponseToType<typeof endpoints.assessmentFormTemplates.response>
  >['data'],
): QueryResult<{
  /** The total count of the templates */
  totalCount?: number;
  /** The transformed assessment form templates */
  nodes: AssessmentFormTemplate[];
}>['data'] => ({
  ...data,
  nodes: (data?.nodes || []).map(
    (template) =>
      ({
        ...template,
        sections: (template?.sections || []).map((section) => ({
          ...section,
          questions: (section.questions || []).map(
            ({ displayLogic, riskLogic, ...question }) => ({
              ...question,
              ...(displayLogic
                ? { displayLogic: JSON.parse(displayLogic) }
                : {}),
              ...(riskLogic
                ? { riskLogic: riskLogic.map((rl) => JSON.parse(rl)) }
                : {}),
              // form templates have no selected answers or previous submissions
              // and we'd have to parse them if we wanted to pretend they did
              selectedAnswers: [],
              previousSubmissions: [],
            }),
          ),
        })),
      }) as AssessmentFormTemplate,
  ),
});
