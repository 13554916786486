/**
 * Environment variables for the frontend build
 */

import { getEnvDefault } from '@main/core-ui';
import { ImmutableUrl } from '@main/immutable-url';
import { Environment } from '@main/utils';

/**
 * The url of the backend server
 */
export const BACKEND_API_URLS = getEnvDefault(
  'BACKEND_API_URLS',
  'https://api.transcend.io,https://api.us.transcend.io',
  'https://yo.com:4001',
).split(',');

/**
 * The url of this app
 */
export const ADMIN_DASHBOARD_URL = new ImmutableUrl(
  getEnvDefault(
    'ADMIN_DASHBOARD_URL',
    'https://app.transcend.io',
    'https://yo.com:3000',
  ),
);

/**
 * The NODE_ENV
 */
export const NODE_ENV = getEnvDefault(
  'NODE_ENV',
  '___NODE_ENV___',
  'production',
) as Environment;

/**
 * The name of the datadog service
 */
export const DATADOG_SERVICE = getEnvDefault(
  'DATADOG_SERVICE',
  'admin-dashboard',
  'admin-dashboard',
);

/**
 * The name of the datadog container when logging
 */
export const DATADOG_CONTAINER_NAME = getEnvDefault(
  'DATADOG_CONTAINER_NAME',
  '___DATADOG_CONTAINER_NAME___',
  'admin-dashboard',
);

/**
 * Client token used for sending RUM metrics to datadog
 *
 * @see https://app.datadoghq.com/rum/application/76f3ba0c-2019-46a1-88a7-0ef9474062bd/manage
 */
export const DATADOG_RUM_CLIENT_TOKEN = getEnvDefault(
  'DATADOG_RUM_CLIENT_TOKEN',
  'pub5eaa5ec5927b726bfb6e14130155cfa8',
  '',
);

/**
 * Application ID used for sending RUM metrics to datadog
 *
 * @see https://app.datadoghq.com/rum/application/76f3ba0c-2019-46a1-88a7-0ef9474062bd/manage
 */
export const DATADOG_RUM_APPLICATION_ID = getEnvDefault(
  'DATADOG_RUM_APPLICATION_ID',
  '76f3ba0c-2019-46a1-88a7-0ef9474062bd',
  '76f3ba0c-2019-46a1-88a7-0ef9474062bd',
);

/**
 * Frequency at which to send logs/traces defaults to 100
 * 100 means send all errors
 */
export const DATADOG_SAMPLE_RATE = parseInt(
  getEnvDefault('DATADOG_SAMPLE_RATE', '___DATADOG_SAMPLE_RATE___', '100'),
  10,
);

/**
 * The Intercom version to use
 */
export const INTERCOM_VERSION = getEnvDefault(
  'INTERCOM_VERSION',
  'f7yqnv3x',
  'bxjk2bt8', // staging environment
);

/**
 * The Amplitude API key (this is a public write key for frontend use)
 *
 * @see https://amplitude.com/docs/apis/authentication
 * @see https://app.amplitude.com/data/transcend-io/Admin%20Dashboard%20v2%20(Production)/sources/name/admin_dashboard
 * @see https://app.amplitude.com/data/transcend-io/Admin%20Dashboard%20v2%20(Development%20%2F%20Staging)/sources/name/admin_dashboard
 */
export const AMPLITUDE_API_KEY = getEnvDefault(
  'AMPLITUDE_API_KEY',
  'd17bb76d0237fe7c75e32ecb46b885c9',
  'a538e02ada4a0b7f03bf5db7ae2807b7', // dev / staging
);

/**
 * Link to our own privacy policy
 */
export const PRIVACY_POLICY_URL = new ImmutableUrl(
  getEnvDefault(
    'PRIVACY_POLICY_URL',
    '___PRIVACY_POLICY_URL_ENV___',
    'https://privacy.transcend.io/policies?name=#privacy-policy',
  ),
);

/**
 *  Link to our subscription terms
 */
export const SUBSCRIPTION_TERMS_URL = new ImmutableUrl(
  getEnvDefault(
    'SUBSCRIPTION_TERMS_URL',
    '___SUBSCRIPTION_TERMS_URL_ENV___',
    'https://transcend.io/legal/subscription-terms/',
  ),
);

/**
 * The support contact for transcen
 */
export const SUPPORT_CONTACT = getEnvDefault(
  'SUPPORT_CONTACT',
  '___SUPPORT_CONTACT___',
  'support@transcend.io',
);

/**
 * The support contact for transcend
 */
export const SALES_CONTACT = getEnvDefault(
  'SALES_CONTACT',
  '___SALES_CONTACT___',
  'sales@transcend.io',
);

/**
 * The url of the splash page
 */
export const SPLASH_URL = getEnvDefault(
  'SPLASH_URL',
  'https://transcend.io',
  '/login',
);

/**
 * The title of the site
 */
export const TITLE = getEnvDefault('TITLE', '___TITLE___', 'Transcend');
