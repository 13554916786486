import { defineMessages } from '@transcend-io/internationalization';
import { ConfigurableColorPaletteColor } from '@transcend-io/privacy-types';

/**
 * Color Messages
 */
export const colorMessages = defineMessages<ConfigurableColorPaletteColor>(
  'admin-dashboard.PrivacyCenter.ColorPalette.color',
  {
    primary: {
      defaultMessage: 'Primary color',
    },
    textOnPrimary: {
      defaultMessage: 'Text on primary',
    },
    secondary: {
      defaultMessage: 'Secondary color',
    },
    sidebarNavBg: {
      defaultMessage: 'Sidebar color',
    },
    textOnSidebar: {
      defaultMessage: 'Text on side menu',
    },
    accentOnSidebar: {
      defaultMessage: 'Accent color on side menu',
    },
    heroBg: {
      defaultMessage: 'Hero color',
    },
    textOnHero: {
      defaultMessage: 'Text on hero',
    },
    widgetBg: {
      defaultMessage: 'Widget color',
    },
    textOnAboutTranscend: {
      defaultMessage: 'Text on "About Transcend" widget',
    },
    tableOutline: {
      defaultMessage: 'Table Header & Outline',
    },
    textOnBg: {
      defaultMessage: 'Main paragraph text',
    },
    textLightOnBg: {
      defaultMessage: 'Lighten text',
    },
    bgAccent: {
      defaultMessage: 'Background accent',
    },
    error: {
      defaultMessage: 'Error',
    },
    highlight: {
      defaultMessage: 'Highlighted text',
    },
  },
);

export const colorPaletteMessages = defineMessages(
  'admin-dashboard.PrivacyCenter.ColorPalette.colorPalette',
  {
    header: {
      defaultMessage: 'Colors & Styles',
    },
    expand: {
      defaultMessage: 'Show more',
    },
    hide: {
      defaultMessage: 'Show less',
    },
    colors: {
      defaultMessage: 'Colors',
    },
    styles: {
      defaultMessage: 'Custom CSS Styles',
    },
    fonts: {
      defaultMessage: 'Fonts',
    },
    warning: {
      defaultMessage:
        'There is a noticeable difference between the styling properties you entered and what has been detected. Please double-check that your input is correct!', // eslint-disable-line max-len
    },
  },
);
