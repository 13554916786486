import {
  FlexRowCenterVertical,
  Icon,
  Popover,
  StyleUtils,
  TRANSCEND_BOT_URL,
} from '@main/core-ui';
import React, { ReactNode, useState } from 'react';
import { useIntl } from 'react-intl';

import { Card } from '../Card';
import { OrganizationIcon } from '../OrganizationIcon';
import { auditTrailMessages } from './messages';

interface AuditTrailListItemLayoutProps {
  /** the event date */
  createdAt: Date;
  /** the title of the actor responsible for this event */
  actorTitle: string;
  /** the profile picture of the actor responsible for this event */
  actorProfilePictureUrl?: string;
  /** the displayed title for the event */
  eventTitle: ReactNode;
  /** the details of the event */
  details: ReactNode;
  /** does the event have more details */
  hasMoreDetails: boolean;
  /** the api key id */
  apiKeyId?: string;
}

/**
 * common layout for a single audit trail list item
 */
export const AuditTrailListItemLayout: React.FC<
  AuditTrailListItemLayoutProps
> = ({
  createdAt,
  actorTitle,
  actorProfilePictureUrl,
  eventTitle,
  hasMoreDetails,
  details,
  apiKeyId,
}) => {
  const [hideDetails, setHideDetails] = useState(true);
  const { formatMessage } = useIntl();

  return (
    <Card
      style={{ ...StyleUtils.Flex.Column.base, gap: '1em' }}
      padding="1em 1.5em"
      onClick={hasMoreDetails ? () => setHideDetails(!hideDetails) : undefined}
    >
      <FlexRowCenterVertical style={{ gap: '2em' }}>
        <Popover
          contents={
            <span>
              {apiKeyId
                ? formatMessage(auditTrailMessages.apiKey, {
                    title: actorTitle,
                  })
                : actorTitle}
            </span>
          }
          trigger={['focus', 'hover']}
        >
          {/* div is necessary here to act as a focus/hover target for the popover */}
          <FlexRowCenterVertical>
            {apiKeyId ? (
              <Icon type="key" size={24} />
            ) : (
              <OrganizationIcon
                organizationIconSrc={
                  actorProfilePictureUrl || TRANSCEND_BOT_URL
                }
                organizationName={actorTitle}
                circle
              />
            )}
          </FlexRowCenterVertical>
        </Popover>
        <div style={{ flexGrow: 1 }}>{eventTitle}</div>
        <div style={{ whiteSpace: 'nowrap' }}>{createdAt.toLocaleString()}</div>
        <FlexRowCenterVertical
          style={!hasMoreDetails ? { visibility: 'hidden' } : {}}
        >
          <Icon type={hideDetails ? 'caret-down' : 'caret-up'} />
        </FlexRowCenterVertical>
      </FlexRowCenterVertical>
      {!hideDetails && details}
    </Card>
  );
};
