import React from 'react';
import { useTheme } from 'styled-components';

/**
 * Short Text icon
 */
export const ShortText: React.FC = (props) => {
  const theme = useTheme();
  return (
    <svg
      width="48px"
      height="18px"
      viewBox="0 0 48 18"
      version="1.1"
      {...props}
    >
      <defs />
      <g
        id="Symbols"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Button/dashboardsquare"
          transform="translate(-40.000000, -43.000000)"
          fill={theme.colors.primary}
        >
          <g
            id="inputicon/short-text"
            transform="translate(40.000000, 28.000000)"
          >
            <path
              d="M0,15 L30,15 L30,21 L0,21 L0,15 Z M36,15 L48,15 L48,21 L36,21 L36,15 Z M0,27 L48,27 L48,33 L0,33 L0,27 Z"
              id="Shape"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};
