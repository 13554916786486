import { isNodeSelection } from '@tiptap/core';
import React, { useState } from 'react';

import { AddImageModal, AddImageModalProps } from '../AddImageModal';
import { TipTapNodeType } from './enums';
import { toolbarMessages } from './messages';
import { ToolbarButton } from './ToolbarButton';
import { ToolbarButtonGroupProps } from './types';

export const ImageButtonGroup: React.FC<
  ToolbarButtonGroupProps &
    Pick<AddImageModalProps, 'uploadOptions' | 'transformImageSrc'>
> = ({ editor, uploadOptions, transformImageSrc }) => {
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <ToolbarButton
        title={toolbarMessages.image}
        onClick={() => setShowModal(true)}
        icon="image"
        disabled={false}
      />

      {showModal && (
        <AddImageModal
          editor={editor}
          uploadOptions={uploadOptions}
          transformImageSrc={transformImageSrc}
          onHide={() => setShowModal(false)}
          onSubmit={({ url, alt }) => {
            const { selection } = editor.state;

            if (url) {
              editor.chain().focus().setImage({ src: url, alt }).run();
            } else if (
              isNodeSelection(selection) &&
              selection.node.type.name === TipTapNodeType.Image
            ) {
              // Delete the selected image if the user has cleared the url
              editor.commands.deleteSelection();
            }

            // close modal
            setShowModal(false);
          }}
          show
        />
      )}
    </>
  );
};
