import { endpoints, UserPreview } from '@main/access-control-types';
import {
  buildUseMutation,
  FlexRowCenterHorizontal,
  formatErrorMessage,
  FormItemWrapper,
  message,
} from '@main/core-ui';
import type { ID } from '@main/schema-utils';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import { Button } from '../Button';
import { SelectedUser, SelectUser } from '../SelectUser';
import { selectDataSiloOwnerMessages } from './messages';

export interface SelectDataSiloOwnerProps {
  /** The logged in user's id, used for self-assignment */
  currentUserId: ID<'user'>;
  /** The currently selected existing users */
  existingOwners: UserPreview[];
  /** The callback to fire when the modal is completed */
  onComplete: (userIds: ID<'user'>[]) => void;
  /** When true, invite the user on select change instead of on button click */
  inviteOnChange?: boolean;
}

const useAddUser = buildUseMutation(endpoints.addUser);

/**
 * The SelectDataSiloOwner component
 */
export function SelectDataSiloOwner({
  currentUserId,
  onComplete,
  existingOwners,
  inviteOnChange,
}: SelectDataSiloOwnerProps): JSX.Element {
  const [selected, setSelected] = useState<SelectedUser[]>(
    existingOwners.map((user) => ({
      ...user,
      isNew: false,
    })),
  );
  const { formatMessage } = useIntl();
  const [addUser, { error: addError, loading: addLoading }] = useAddUser();

  useEffect(() => {
    if (!addLoading && addError) {
      message.error(formatErrorMessage(addError.message));
    }
  }, [addError, addLoading]);

  const handleAssignAndInvite = (assignedUsers: SelectedUser[]): void => {
    // Clear assignees
    if (assignedUsers.length === 0) {
      onComplete([]);
      return;
    }

    // If all selected users already exist, fire onComplete and return
    if (!assignedUsers.some((user) => user.isNew)) {
      onComplete(assignedUsers.map((user) => user.id));
      return;
    }

    const existingUsers = assignedUsers
      .filter((user) => !user.isNew)
      .map((user) => user.id);

    Promise.all(
      assignedUsers
        .filter((user) => user.isNew)
        .map((user) =>
          addUser({
            variables: {
              input: {
                name: user.name,
                email: user.email,
                sendInviteEmail: true,
              },
            },
          }),
        ),
    )
      .then((users) => {
        const newUserIds = users
          .map(({ data }) => data?.user?.id)
          .filter((id) => !!id) as ID<'user'>[];
        const allUserIds = existingUsers.concat(newUserIds);
        onComplete(allUserIds);
      })
      .catch((error) => message.error(formatErrorMessage(error.message)));
  };

  return (
    <>
      <FormItemWrapper
        name="user"
        label={selectDataSiloOwnerMessages.label}
        info={selectDataSiloOwnerMessages.info}
      >
        <SelectUser
          closeMenuOnSelect
          backspaceRemovesValue
          allowInvite
          isMulti
          value={selected}
          onChange={(value) =>
            inviteOnChange ? handleAssignAndInvite(value) : setSelected(value)
          }
        />
      </FormItemWrapper>
      {!inviteOnChange && (
        <FlexRowCenterHorizontal style={{ gap: '1rem' }}>
          {!selected && (
            <Button
              variant="secondary"
              onClick={() => onComplete([currentUserId])}
            >
              {formatMessage(selectDataSiloOwnerMessages.assignSelfButton)}
            </Button>
          )}
          <Button
            disabled={addLoading}
            onClick={() => handleAssignAndInvite(selected)}
          >
            {formatMessage(
              selected && selected.some((user) => user.isNew)
                ? selectDataSiloOwnerMessages.inviteAndAssignButton
                : selectDataSiloOwnerMessages.assignButton,
            )}
          </Button>
        </FlexRowCenterHorizontal>
      )}
    </>
  );
}
