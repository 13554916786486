import {
  extractFormItemWrapperProps,
  FormItemWrapper,
  IFormFieldProps,
  IFormItemWrapperProps,
} from '@main/core-ui';
import React from 'react';
import { Controller } from 'react-hook-form';

import { CheckboxInput, CheckboxInputProps } from './CheckboxInput';
import { InlineFormCheckbox } from './wrappers';

/**
 * Checkbox props
 */
export type IFormCheckboxProps = IFormFieldProps<boolean>;

/**
 * FormCheckbox
 */
export const FormCheckboxRaw: React.FC<
  IFormCheckboxProps & CheckboxInputProps
> = ({ name, defaultValue, ...props }) => (
  <Controller
    name={name}
    defaultValue={defaultValue}
    render={({ field }) => (
      <CheckboxInput
        {...props}
        {...field}
        // TS doesn't like a null value here, but idk where that null value is even coming from
        key={props.key ?? undefined}
        defaultChecked={defaultValue}
        onChange={() => field.onChange(!field.value)}
      />
    )}
  />
);

/**
 * FormCheckbox
 */
export const FormCheckbox: React.FC<
  IFormCheckboxProps &
    Omit<
      CheckboxInputProps,
      'value' | 'defaultChecked' | 'checked' | 'onChange'
    > &
    Omit<IFormItemWrapperProps, 'errors' | 'errorDisplay'> & {
      /** whether to display the checkbox with the label to the side of the checkbox (inline) or above it */
      inline?: boolean;
    }
> = (props) => {
  const FormCheckboxWrapper = props.inline
    ? InlineFormCheckbox
    : FormItemWrapper;
  const { passthroughProps, wrapperProps } = extractFormItemWrapperProps(props);

  return (
    <FormCheckboxWrapper {...wrapperProps} isFullWidth={false}>
      <FormCheckboxRaw {...passthroughProps} />
    </FormCheckboxWrapper>
  );
};
