import { FlexColumn, StyleUtils } from '@main/core-ui';
import orderBy from 'lodash/orderBy';
import React from 'react';

import { BarChartTooltipData } from './types';
import {
  StyledVisxTooltipColorCircle,
  VisxTooltipGridWrapper,
  VisxTooltipKeyWrapper,
  VisxTooltipValueWrapper,
} from './wrappers';

export const BarChartTooltip: React.FC<{
  /** tooltip data */
  tooltipData: BarChartTooltipData;
}> = ({ tooltipData }) => {
  const domain = tooltipData.legendItems ?? [];
  const sortedDomain =
    domain.length > 2
      ? orderBy(
          domain,
          ['numberValue', 'value', 'key'],
          ['desc', 'desc', 'asc'],
        )
      : domain;
  return (
    <FlexColumn style={{ gap: StyleUtils.Spacing.md }}>
      {tooltipData.title && (
        <div style={{ fontWeight: 600 }}>{tooltipData.title}</div>
      )}
      {tooltipData.subtitle && (
        <div style={{ fontWeight: 600 }}>{tooltipData.subtitle}</div>
      )}
      <VisxTooltipGridWrapper>
        {sortedDomain.map(({ key, value, hexColor }) => (
          <React.Fragment key={key}>
            {hexColor ? (
              <StyledVisxTooltipColorCircle
                isOutline={tooltipData.noFillSeriesNames?.includes(key)}
                color={hexColor}
              />
            ) : (
              <div />
            )}
            <VisxTooltipKeyWrapper
              style={{
                fontWeight: key === tooltipData.hoveredKey ? 600 : undefined,
              }}
            >
              {key}
            </VisxTooltipKeyWrapper>
            <VisxTooltipValueWrapper
              style={{
                fontWeight: key === tooltipData.hoveredKey ? 600 : undefined,
              }}
            >
              {value}
            </VisxTooltipValueWrapper>
          </React.Fragment>
        ))}
      </VisxTooltipGridWrapper>
      <VisxTooltipGridWrapper
        style={{ gridTemplateColumns: '1fr min-content' }}
      >
        {tooltipData.extraMetadata?.map(({ key, value }) => (
          <React.Fragment key={key}>
            <VisxTooltipKeyWrapper>{key}:</VisxTooltipKeyWrapper>
            <VisxTooltipValueWrapper>{value}</VisxTooltipValueWrapper>
          </React.Fragment>
        ))}
      </VisxTooltipGridWrapper>
    </FlexColumn>
  );
};
