import { useMemo } from 'react';

import { ActionItemCode } from '@transcend-io/privacy-types';

import { GlobalActionItem } from '@main/access-control-types';
import { SelectedUser } from '@main/ad-core-components';

import { ACTION_ITEM_FRONTEND_MAPPING } from '../constants';
import { ActionItemRow } from '../types';

/**
 * remaps the action item query result to table rows, and compiles an id and action link for each row
 *
 * @param nodes - the nodes from the query response
 * @param user - the current user
 * @param isExpandedTable - is the table a sub table?
 * @returns the remapped rows
 */
export function useRemapActionItemNodesToRows(
  nodes: GlobalActionItem[] | undefined,
  user: SelectedUser,
  isExpandedTable: boolean,
): ActionItemRow[] {
  const rows = useMemo(
    () =>
      nodes?.map((row) => {
        const actionLink = ACTION_ITEM_FRONTEND_MAPPING[
          row.type
        ].getActionLink?.(row, user);
        return {
          ...row,
          actionLink,
          // need to generate an id for the row to ensure expand works correctly
          id:
            row.type === ActionItemCode.Onboarding
              ? row.ids[0]
              : [
                  row.type,
                  ...(isExpandedTable ? row.ids : [false]),
                  row.resolved,
                  ...row.users.map(({ id }) => id),
                  ...row.teams.map(({ id }) => id),
                ].join(','),
        };
      }) ?? [],
    [nodes, isExpandedTable, user],
  );
  return rows;
}
