import { Icon } from '@main/core-ui';
import React from 'react';

import { DragHandleWrapper } from './wrappers';

/**
 * Props for the drag handle
 */
type DragHandleProps = Parameters<typeof DragHandleWrapper>[0];

export const DragHandle: React.FC<DragHandleProps> = (props) => (
  <DragHandleWrapper {...props}>
    <Icon size="1.1em" type="dots-vertical" />
    <Icon size="1.1em" type="dots-vertical" />
  </DragHandleWrapper>
);
