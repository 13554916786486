import { ErrorText, Skeleton } from '@main/core-ui';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';

import { Button } from '../Button';
import { uploadCsvMessages } from './messages';
import { RowWithUploadStatus } from './types';

export interface SubmitPendingRowsProps<T extends {}> {
  /** Callback to handle uploading of pending rows */
  onSubmit: (
    validPendingRows: RowWithUploadStatus<T>[],
  ) => Promise<void> | void;
  /** if we have an error */
  error?: Error;
  /** loading any additional data needed for upload */
  isLoading?: boolean;
  /** valid pending rows to be uploaded */
  validPendingRows: RowWithUploadStatus<T>[];
  /** callback function for after the data is uploaded successfully */
  onSuccess?: () => void;
}

/**
 * Button that submits the pending rows for upload that are valid
 */
export function SubmitPendingRows<T extends {}>({
  onSubmit,
  error,
  isLoading,
  validPendingRows,
  onSuccess,
}: SubmitPendingRowsProps<T>): JSX.Element {
  const { formatMessage } = useIntl();
  const [isUploading, setIsUploading] = useState(false);

  if (isLoading) {
    return <Skeleton />;
  }

  if (error) {
    return <ErrorText error={error} />;
  }

  return (
    <Button
      variant="primary"
      disabled={isUploading}
      size="sm"
      onClick={async () => {
        setIsUploading(true);
        try {
          await onSubmit(validPendingRows);
          onSuccess?.();
        } finally {
          setIsUploading(false);
        }
      }}
      id="submit-csv-rows"
    >
      {formatMessage(uploadCsvMessages.upload, {
        count: validPendingRows.length,
      })}
    </Button>
  );
}
