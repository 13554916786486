/* eslint-disable max-lines */
import { Purpose } from '@transcend-io/airgap.js-types';
import { applyEnum } from '@transcend-io/type-utils';

import { UserPreview } from '@main/access-control-types';
import { AttributeValue } from '@main/attribute-types';
import { SelectableCatalog } from '@main/datamap-types';
import { mkInput, mkType, SchemaToType } from '@main/schema-utils';

import {
  AuditorGQLRegionEnum,
  AuditorRunStatus,
  ColorScheme,
  FindingClass,
  FindingSeverity,
  SameSite,
} from './enums';

export const AuditorRunFiltersInput = mkInput({
  name: 'AuditorRunFiltersInput',
  comment: 'The Web Auditor Run filters',
  fields: {
    scheduleId: {
      comment: 'Filter by a particular schedule',
      type: 'id',
      modelName: 'auditorSchedule',
      optional: true,
    },
    urlScanned: {
      comment: 'The URL that was scanned',
      type: 'string',
      optional: true,
    },
    status: {
      comment: 'Filter by the status of the run',
      type: { AuditorRunStatus },
      optional: true,
    },
    text: {
      comment: 'Filter by text',
      type: 'string',
      optional: true,
    },
  },
});

/** Override type */
export type AuditorRunFiltersInput = SchemaToType<
  typeof AuditorRunFiltersInput
>;

export const AuditorRunDataFlowsFiltersInput = mkInput({
  name: 'AuditorRunDataFlowsFiltersInput',
  comment: 'The Web Auditor Run Data Flows filters',
  fields: {
    runId: {
      comment: 'Filter by a particular run',
      type: 'id',
      modelName: 'auditorRun',
      optional: false,
    },
    id: {
      comment: 'Filter by a data flow id ',
      type: 'id',
      modelName: 'auditorRunDataFlow',
      optional: true,
    },
    requestEndpoint: {
      comment: 'The URL that was requested',
      type: 'string',
      optional: true,
    },
    method: {
      comment: 'Filter by the request method',
      type: 'string',
      optional: true,
    },
    resourceType: {
      comment: 'Filter by the request resource type',
      type: 'string',
      optional: true,
    },
    text: {
      comment: 'Filter by text',
      type: 'string',
      optional: true,
    },
    regulatedByAirgap: {
      comment:
        'Filter by whether the network request can be regulated by Airgap',
      type: 'boolean',
      optional: true,
    },
    purposeGuesses: {
      comment: 'Filter by the guesses for the purpose of the SaaS tool',
      type: { Purpose },
      optional: true,
      list: true,
    },
  },
});

/** Override type */
export type AuditorRunDataFlowsFiltersInput = SchemaToType<
  typeof AuditorRunDataFlowsFiltersInput
>;

export const AuditorRunCookiesFiltersInput = mkInput({
  name: 'AuditorRunCookiesFiltersInput',
  comment: 'The Web Auditor Run Cookies filters',
  fields: {
    runId: {
      comment: 'Filter by a particular run',
      type: 'id',
      modelName: 'auditorRun',
      optional: false,
    },
    id: {
      comment: 'Filter by a cookie id ',
      type: 'id',
      modelName: 'auditorRunCookie',
      optional: true,
    },
    text: {
      comment: 'Filter by text',
      type: 'string',
      optional: true,
    },
    regulatedByAirgap: {
      comment: 'Filter by whether the cookie can be regulated by Airgap',
      type: 'boolean',
      optional: true,
    },
    purposeGuesses: {
      comment: 'Filter by the guesses for the purpose of the SaaS tool',
      type: { Purpose },
      optional: true,
      list: true,
    },
  },
});

/** Override type */
export type AuditorRunCookiesFiltersInput = SchemaToType<
  typeof AuditorRunCookiesFiltersInput
>;

export const AuditorFindingFiltersInput = mkInput({
  name: 'AuditorFindingFiltersInput',
  comment: 'The Web Auditor Findings filters',
  fields: {
    runId: {
      comment: 'Filter by a particular run',
      type: 'id',
      modelName: 'auditorRun',
      optional: false,
    },
    text: {
      comment: 'Filter by text',
      type: 'string',
      optional: true,
    },
    findingClass: {
      comment: 'Filter by finding class',
      type: { FindingClass },
      optional: true,
      list: true,
    },
    cookieId: {
      comment: 'Filter by a cookie id ',
      type: 'id',
      modelName: 'auditorRunCookie',
      optional: true,
    },
    dataFlowId: {
      comment: 'Filter by a data flow id ',
      type: 'id',
      modelName: 'auditorRunDataFlow',
      optional: true,
    },
    severity: {
      comment: 'Filter by severity',
      type: { FindingSeverity },
      optional: true,
      list: true,
    },
    passed: {
      comment:
        'Filter passing or failing tests. If not specified, defaults to filtering for failing tests.',
      type: 'boolean',
      optional: true,
    },
  },
});

/** Override type */
export type AuditorFindingFiltersInput = SchemaToType<
  typeof AuditorFindingFiltersInput
>;

export const AuditorRunResults = mkType({
  name: 'AuditorRunResults',
  comment: 'A single run of auditor with the results',
  fields: {
    id: {
      comment: 'The ID of this run',
      type: 'id',
      modelName: 'auditorRun',
    },
    organizationId: {
      comment: 'The ID of the organization that ran this auditor run',
      type: 'id',
      modelName: 'organization',
    },
    auditorScheduleId: {
      comment:
        'The ID of the auditor schedule this run was triggered by, if it was triggered by a schedule',
      type: 'id',
      modelName: 'auditorSchedule',
      optional: true,
    },
    user: {
      comment: 'The user that triggered the run, if any',
      type: UserPreview,
      optional: true,
    },
    apiKeyId: {
      comment: 'The ID of the API key',
      type: 'id',
      modelName: 'apiKey',
      optional: true,
    },
    urlScanned: {
      comment: 'The URL scanned in this run',
      type: 'string',
    },
    colorScheme: {
      comment: 'The color scheme used for the browser',
      type: { ColorScheme },
    },
    deviceType: {
      comment: 'The type of the device used for the run',
      type: 'string',
    },
    harFileKey: {
      comment: 'The HAR file key',
      type: 'string',
      optional: true,
    },
    requestsFileKey: {
      comment:
        'The key of the file containing the requests made during the run',
      type: 'string',
      optional: true,
    },
    cookiesFileKey: {
      comment:
        'The key of the file containing the cookies stored during the run',
      type: 'string',
      optional: true,
    },
    screenshotFileKey: {
      comment: 'The key of the screenshot taken when the page loaded',
      type: 'string',
      optional: true,
    },
    pdfFileKey: {
      comment: 'A PDF containing the results of the run',
      type: 'string',
      optional: true,
    },
    videoFileKey: {
      comment: 'The key to a video recording of the browser running the audit',
      type: 'string',
      optional: true,
    },
    status: {
      comment: 'The status of the run',
      type: { AuditorRunStatus },
    },
    errorMessage: {
      comment:
        'If the run was a failure, this is the message to display to the user on why it failed',
      type: 'string',
      optional: true,
    },
    failedStep: {
      comment: 'The user behavior simulation step that failed',
      type: 'int',
      optional: true,
    },
    createdAt: {
      comment: 'When the run was completed',
      type: 'Date',
    },
    startedAt: {
      comment: 'When the run was started',
      type: 'Date',
      optional: true,
    },
    completedAt: {
      comment: 'When the run was completed',
      type: 'Date',
      optional: true,
    },
    browserSteps: {
      comment: 'The steps the browser took during the run',
      type: 'string',
    },
    failedFindingsCount: {
      comment: 'The number of findings for this run',
      type: 'int',
      optional: true,
    },
    useGPC: {
      comment: 'Whether to use the GPC signal for the run',
      type: 'boolean',
    },
    region: {
      comment: 'What region to run the audit in',
      type: { AuditorGQLRegionEnum },
    },
    attributeValues: {
      comment: 'The attribute values used to label this assessment',
      type: AttributeValue,
      list: true,
    },
  },
});

/** Override type */
export type AuditorRunResults = SchemaToType<typeof AuditorRunResults>;

export const AuditorRunDataFlow = mkType({
  name: 'AuditorRunDataFlow',
  comment: 'A single run request made during a Web Auditor run',
  fields: {
    id: {
      comment: 'The ID of this run',
      type: 'id',
      modelName: 'auditorRunDataFlow',
    },
    requestEndpoint: {
      comment: 'The URL requested',
      type: 'string',
    },
    resourceType: {
      comment: 'The resource type of the result',
      type: 'string',
    },
    method: {
      comment: 'The method of the request',
      type: 'string',
    },
    isNavigationRequest: {
      comment: 'If the request was a navigation to a new page',
      type: 'boolean',
    },
    headers: {
      comment: 'The headers of the request',
      type: 'string',
    },
    postData: {
      comment: 'The post data of the request',
      type: 'string',
      optional: true,
    },
    failedFindingsCount: {
      comment: 'The number of findings for this run',
      type: 'int',
      optional: true,
    },
    guessCatalog: {
      comment: 'The catalog of the service guess associated with this cookie',
      type: SelectableCatalog,
      optional: true,
    },
    purposeGuesses: {
      comment: 'The guess for the purpose of the SaaS tool',
      type: { Purpose },
      optional: true,
      list: true,
    },
    regulatedByAirgap: {
      comment: 'Filter by whether the cookie can be regulated by Airgap',
      type: 'boolean',
      optional: true,
    },
  },
});

/** Override type */
export type AuditorRunDataFlow = SchemaToType<typeof AuditorRunDataFlow>;

export const AuditorRunCookie = mkType({
  name: 'AuditorRunCookies',
  comment: 'A single cookie object made during a Web Auditor run',
  fields: {
    id: {
      comment: 'The ID of this auditor cookie',
      type: 'id',
      modelName: 'auditorRunCookie',
    },
    name: {
      comment: 'The name of the cookie',
      type: 'string',
      optional: true,
    },
    value: {
      comment: 'The value of the cookie',
      type: 'string',
      optional: true,
    },
    domain: {
      comment: 'The domain of the cookie',
      type: 'string',
      optional: true,
    },
    path: {
      comment: 'The path of the cookie',
      type: 'string',
      optional: true,
    },
    expiresDuration: {
      comment: 'The amount of time until the cookie expires',
      type: 'string',
      optional: true,
    },
    httpOnly: {
      comment: 'If the cookie is HttpOnly',
      type: 'boolean',
      optional: true,
    },
    secure: {
      comment: 'If the cookie is Secure',
      type: 'boolean',
      optional: true,
    },
    sameSite: {
      comment: 'The same-site policy of the cookie',
      type: { SameSite },
      optional: false,
    },
    failedFindingsCount: {
      comment: 'The number of findings for this run',
      type: 'int',
      optional: true,
    },
    guessCatalog: {
      comment: 'The catalog of the service guess associated with this cookie',
      type: SelectableCatalog,
      optional: true,
    },
    purposeGuesses: {
      comment: 'The guess for the purpose of the SaaS tool',
      type: { Purpose },
      optional: true,
      list: true,
    },
    regulatedByAirgap: {
      comment: 'If the cookie is regulated by Airgap',
      type: 'boolean',
      optional: true,
    },
  },
});

/** Override type */
export type AuditorRunCookie = SchemaToType<typeof AuditorRunCookie>;

export const AuditorFinding = mkType({
  name: 'AuditorFinding',
  comment: 'A single finding found during a Web Auditor run',
  fields: {
    id: {
      comment: 'The ID of this finding',
      type: 'id',
      modelName: 'auditorFinding',
    },
    message: {
      comment: 'The message of the finding',
      type: 'string',
      optional: true,
    },
    class: {
      comment: 'The class of the finding',
      type: { FindingClass },
    },
    passed: {
      comment: 'Was the test a pass or a fail',
      type: 'boolean',
    },
    severity: {
      comment: 'The severity of the finding',
      type: { FindingSeverity },
    },
    auditorRunDataFlowId: {
      comment: 'The id of the data flow that created this finding',
      type: 'id',
      modelName: 'auditorRunDataFlow',
      optional: true,
    },
    auditorRunCookieId: {
      comment: 'The id of the cookie that created this finding',
      type: 'id',
      modelName: 'auditorRunCookie',
      optional: true,
    },
    auditorRunDataFlows: {
      comment: 'The associated data flows that created this finding',
      type: AuditorRunDataFlow,
      list: true,
      optional: true,
    },
    auditorRunCookies: {
      comment: 'The associated cookies that created this finding',
      type: AuditorRunCookie,
      list: true,
      optional: true,
    },
  },
});

/** Override type */
export type AuditorFinding = SchemaToType<typeof AuditorFinding>;

export const AuditorRunInput = mkInput({
  name: 'AuditorRunInput',
  comment: 'Input for running a single auditor run',
  fields: {
    urlToScan: {
      comment: 'The URL to scan',
      type: 'string',
    },
    colorScheme: {
      comment: 'The color scheme to use for the browser',
      type: { ColorScheme },
    },
    deviceType: {
      comment: 'The type of the device to use for the run',
      type: 'string',
    },
    browserSteps: {
      comment: 'The steps the browser should take during the run',
      type: 'string',
    },
    useGPC: {
      comment: 'Whether to use the GPC signal for the run',
      type: 'boolean',
    },
    region: {
      comment: 'What region to run the audit in',
      type: { AuditorGQLRegionEnum },
      optional: true,
    },
  },
});

/** Override type */
export type AuditorRunInput = SchemaToType<typeof AuditorRunInput>;

export const AuditorRunResponse = mkType({
  name: 'AuditorRunResponse',
  comment: 'Output from running a single auditor run',
  fields: {
    runId: {
      type: 'id',
      comment: 'The ID of the auditor run',
      modelName: 'auditorRun',
    },
  },
});

/** Override type */
export type AuditorRunResponse = SchemaToType<typeof AuditorRunResponse>;

export const AuditorSeverityCountsResults = mkType({
  name: 'AuditorSeverityCountsResults',
  comment: 'The counts of each severity for a given run',
  fields: applyEnum(FindingSeverity, (_, severityName) => ({
    comment: `The number of ${severityName} severity findings`,
    type: 'int',
  })),
});

/** Override type */
export type AuditorSeverityCountsResults = SchemaToType<
  typeof AuditorSeverityCountsResults
>;

export const AuditorFindingsByClassResults = mkType({
  name: 'AuditorFindingsByClassResults',
  comment: 'The counts of each finding for a given finding type',
  fields: applyEnum(FindingClass, (_, findingClassName) => ({
    comment: `The number of ${findingClassName} findings`,
    type: 'int',
  })),
});

/** Override type */
export type AuditorFindingsByClassResults = SchemaToType<
  typeof AuditorFindingsByClassResults
>;

export const AuditorDataFlowsByAirgapRegulationResults = mkType({
  name: 'AuditorDataFlowsByAirgapRegulationResults',
  comment: 'The counts of data flows regulated by airgap for a given run',
  fields: {
    regulated: {
      comment: 'The number of data flows regulated by airgap',
      type: 'int',
    },
    unregulated: {
      comment: 'The number of data flows not regulated by airgap',
      type: 'int',
    },
  },
});

/** Override type */
export type AuditorDataFlowsByAirgapRegulationResults = SchemaToType<
  typeof AuditorDataFlowsByAirgapRegulationResults
>;

export const AuditorCookiesByAirgapRegulationResults = mkType({
  name: 'AuditorCookiesByAirgapRegulationResults',
  comment: 'The counts of cookies regulated by airgap for a given run',
  fields: {
    regulated: {
      comment: 'The number of cookies regulated by airgap',
      type: 'int',
    },
    unregulated: {
      comment: 'The number of cookies not regulated by airgap',
      type: 'int',
    },
  },
});

/** Override type */
export type AuditorCookiesByAirgapRegulationResults = SchemaToType<
  typeof AuditorCookiesByAirgapRegulationResults
>;

export const AuditorFileInput = mkInput({
  name: 'AuditorFileInput',
  comment: 'Input for retrieving a temporary link to download an auditor file',
  fields: {
    auditorRunId: {
      comment: 'ID of the auditor run',
      modelName: 'auditorRun',
      type: 'id',
    },
    fileKey: {
      comment: 'The key of the file to download',
      type: 'string',
    },
  },
});

/** Override type */
export type AuditorFileInput = SchemaToType<typeof AuditorFileInput>;

export const AuditorSchedule = mkType({
  name: 'AuditorSchedule',
  comment:
    'A schedule for running the Web Auditor repeatedly over time on the same URL',
  fields: {
    id: {
      comment: 'The ID of this schedule',
      type: 'id',
      modelName: 'auditorSchedule',
    },
    organizationId: {
      comment: 'The ID of the organization that owns this schedule',
      type: 'id',
      modelName: 'organization',
    },
    user: {
      comment: 'The user that created this schedule',
      type: UserPreview,
      optional: true,
    },
    apiKeyId: {
      comment: 'The API Key that created this schedule',
      type: 'id',
      modelName: 'apiKey',
      optional: true,
    },
    urlToScan: {
      comment: 'The URL to scan in jobs created by this schedule',
      type: 'string',
    },
    colorScheme: {
      comment: 'The color scheme used for the browser',
      type: { ColorScheme },
    },
    deviceType: {
      comment: 'The type of the device used for the run',
      type: 'string',
    },
    browserSteps: {
      comment: 'The steps the browser should take during the run',
      type: 'string',
    },
    createdAt: {
      comment: 'When the run was completed',
      type: 'Date',
    },
    scheduleStartAt: {
      comment: 'When the first scan was scheduled to run',
      type: 'Date',
    },
    scheduledAt: {
      comment: 'When the next scan is scheduled to run',
      type: 'Date',
    },
    scheduleFrequency: {
      comment:
        'How frequently the scan should be run. Needs to be a string because ' +
        'the number can be larger than the MAX_INT',
      type: 'string',
    },
    useGPC: {
      comment: 'Whether to use the GPC signal for the run',
      type: 'boolean',
    },
    region: {
      comment: 'What region to run the audit in',
      type: { AuditorGQLRegionEnum },
      optional: true,
    },
    attributeValues: {
      comment: 'The attribute values used to label this assessment',
      type: AttributeValue,
      list: true,
    },
  },
});

/** Override type */
export type AuditorSchedule = SchemaToType<typeof AuditorSchedule>;

export const AuditorSchedulesFiltersInput = mkInput({
  name: 'AuditorSchedulesFiltersInput',
  comment: 'The Web Auditor Schedules filters',
  fields: {
    id: {
      comment: 'Filter by a particular schedule ID',
      type: 'id',
      modelName: 'auditorSchedule',
      optional: true,
    },
    urlToScan: {
      comment: 'The URL that is scanned by this schedule',
      type: 'string',
      optional: true,
    },
    text: {
      comment: 'Filter by text',
      type: 'string',
      optional: true,
    },
  },
});

/** Override type */
export type AuditorSchedulesFiltersInput = SchemaToType<
  typeof AuditorSchedulesFiltersInput
>;

export const AuditorScheduleInput = mkInput({
  name: 'AuditorScheduleInput',
  comment: 'Input for creating an auditor schedule',
  fields: {
    urlToScan: {
      comment: 'The URL to scan',
      type: 'string',
    },
    colorScheme: {
      comment: 'The color scheme to use for the browser',
      type: { ColorScheme },
    },
    deviceType: {
      comment: 'The type of the device to use for the run',
      type: 'string',
    },
    browserSteps: {
      comment: 'The steps the browser should take during the run',
      type: 'string',
    },
    scheduleStartAt: {
      comment: 'When the first scan was scheduled to run',
      type: 'Date',
    },
    scheduleFrequency: {
      comment:
        'How frequently the scan should be run. Needs to be a string because ' +
        'the number can be larger than the MAX_INT',
      type: 'string',
    },
    useGPC: {
      comment: 'Whether to use the GPC signal for the run',
      type: 'boolean',
    },
    region: {
      comment: 'What region to run the audit in',
      type: { AuditorGQLRegionEnum },
      optional: true,
    },
  },
});

/** Override type */
export type AuditorScheduleInput = SchemaToType<typeof AuditorScheduleInput>;

export const DeleteAuditorSchedulesInput = mkInput({
  name: 'DeleteAuditorSchedulesInput',
  comment: 'Input for deleting a group of Web Auditor schedules',
  fields: {
    ids: {
      comment: 'The id of the assessments to delete',
      type: 'id',
      modelName: 'auditorSchedule',
      list: true,
    },
  },
});

/** Override type */
export type DeleteAuditorSchedulesInput = SchemaToType<
  typeof DeleteAuditorSchedulesInput
>;

export const UpdateAuditorScheduleInput = mkInput({
  name: 'UpdateAuditorScheduleInput',
  comment: 'Input for deleting a group of Web Auditor schedules',
  fields: {
    id: {
      comment: 'The id of the assessments to delete',
      type: 'id',
      modelName: 'auditorSchedule',
    },
    useGPC: {
      comment: 'Whether or not to use GPC for the scheduled runs',
      type: 'boolean',
      optional: true,
    },
    region: {
      comment: 'What region to run the audit in',
      type: { AuditorGQLRegionEnum },
      optional: true,
    },
  },
});

/** Override type */
export type UpdateAuditorScheduleInput = SchemaToType<
  typeof UpdateAuditorScheduleInput
>;

export const AuditorFindingClassCountsFiltersInput = mkInput({
  name: 'AuditorFindingClassCountsFiltersInput',
  comment: 'The Web Auditor Findings Count filters',
  fields: {
    passed: {
      comment:
        'Filter passing or failing tests. If not specified, defaults to filtering for failing tests.',
      type: 'boolean',
      optional: true,
    },
  },
});

/** Override type */
export type AuditorFindingClassCountsFiltersInput = SchemaToType<
  typeof AuditorFindingClassCountsFiltersInput
>;

/* eslint-enable max-lines */
