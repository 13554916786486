import { ConditionalContainer } from '@main/ad-core-components';
import { A } from '@main/core-ui';
import { AdminDashboardPromptsPath } from '@main/prompt-types';
import { ID } from '@main/schema-utils';
import React from 'react';
import { useIntl } from 'react-intl';
import { generatePath } from 'react-router-dom';

import { StyledBadge } from '../../Assessments/components/AssessmentBadge/wrappers';
import { promptBadgeMessages } from '../../Prompts/components/PromptBadge/messages';

export const CodePackagePromptRunCountPreview: React.FC<{
  /** Model ID */
  id: ID<'codePackage'>;
  /** Value of code package run count */
  value: number;
}> = ({ id, value }) => {
  const { formatMessage } = useIntl();
  return (
    <ConditionalContainer
      condition={value > 0}
      container={(children) => (
        <A
          href={`${generatePath(
            AdminDashboardPromptsPath.PromptRuns,
          )}?${new URLSearchParams({
            filters: JSON.stringify({ codePackageIds: [id] }),
          })}`}
        >
          {children}
        </A>
      )}
    >
      <StyledBadge color="gray1" isLinked={value > 0}>
        {formatMessage(promptBadgeMessages.promptRunCount, {
          count: value,
        })}
      </StyledBadge>
    </ConditionalContainer>
  );
};
