import { IntlMessageType } from '@main/internationalization';
import { mkInput, SchemaToType } from '@main/schema-utils';

export const TranslatedMessagesFilterInput = mkInput({
  name: 'TranslatedMessagesFilterInput',
  comment: 'Input for filtering privacy center translation messages',
  fields: {
    type: {
      comment: 'Filter by type of message',
      optional: true,
      list: true,
      type: { IntlMessageType },
    },
  },
});

/** Override type */
export type TranslatedMessagesFilterInput = SchemaToType<
  typeof TranslatedMessagesFilterInput
>;
