import * as t from 'io-ts';

import { dbModelId } from '@main/schema-utils';

import { AuditEventCode } from '../schema/enums';
import {
  mkAuditEventCodec,
  mkAuditEventJoinCodec,
  mkAuditJoinStateCodec,
} from '../types';

export const AuditEventAirgapBundle = mkAuditEventCodec(
  AuditEventCode.AirgapBundle,
  t.type({
    /** ID of the airgap bundle */
    airgapBundleId: dbModelId('airgapBundle'),
  }),
);

/** Override types. */
export type AuditEventAirgapBundle = t.TypeOf<typeof AuditEventAirgapBundle>;

export const AuditEventAirgapBundleVersion = mkAuditEventJoinCodec(
  AuditEventCode.AirgapBundleVersion,
  mkAuditJoinStateCodec('airgapBundleVersion'),
  t.type({
    /** ID of the airgap bundle */
    airgapBundleId: dbModelId('airgapBundle'),
  }),
);

/** Override types. */
export type AuditEventAirgapBundleVersion = t.TypeOf<
  typeof AuditEventAirgapBundleVersion
>;

export const AuditEventAirgapBundleDeployment = mkAuditEventCodec(
  AuditEventCode.AirgapBundleDeployment,
  t.type({
    /** ID of the airgap bundle */
    airgapBundleId: dbModelId('airgapBundle'),
  }),
);

/** Override types. */
export type AuditEventAirgapBundleDeployment = t.TypeOf<
  typeof AuditEventAirgapBundleDeployment
>;

// uses a custom codec for state because TCF stacks' ids are numbers, not uuids
export const AuditEventAirgapBundleTcfStack = mkAuditEventJoinCodec(
  AuditEventCode.AirgapBundleTcfStack,
  t.union([
    t.undefined,
    t.intersection([t.type({ id: t.number, title: t.string }), t.partial({})]),
  ]),
  t.type({
    /** ID of the airgap bundle */
    airgapBundleId: dbModelId('airgapBundle'),
  }),
);

/** Override types. */
export type AuditEventAirgapBundleTcfStack = t.TypeOf<
  typeof AuditEventAirgapBundleTcfStack
>;
