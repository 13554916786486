import { UserPreview } from '@main/access-control-types';
import { mkInput, mkType, SchemaToType } from '@main/schema-utils';

import { AuditEvent } from '../auditEvent';
import { AuditEventCode } from './enums';

export const AuditEventPreviewRaw = mkType({
  name: 'AuditEventPreviewRaw',
  comment: 'An event recorded in the audit event log',
  fields: {
    id: {
      comment: 'The id of the event',
      modelName: 'configAuditEvent',
      type: 'id',
    },
    createdAt: {
      comment: 'The time the event occurred',
      type: 'Date',
    },
    actorUser: {
      comment: 'The user responsible for triggering the event',
      optional: true,
      type: UserPreview,
    },
    actorApiKeyId: {
      comment: 'The apiKey responsible for triggering the event',
      modelName: 'apiKey',
      type: 'id',
      optional: true,
    },
    code: {
      comment: 'The type of audit event',
      type: { AuditEventCode },
    },
    beforeState: {
      comment:
        'The original state of the data before a mutation, as stringified JSON',
      type: 'string',
      optional: true,
    },
    afterState: {
      comment:
        'The new state of the data after a mutation, as stringified JSON',
      type: 'string',
      optional: true,
    },
    additionalContext: {
      comment: 'Additional information about the event, as stringified JSON',
      type: 'string',
      optional: true,
    },
    dataSiloId: {
      comment: 'The data silo the event pertains to, if applicable',
      modelName: 'dataSilo',
      type: 'id',
      optional: true,
    },
    actionItemId: {
      comment: 'The action item the event pertains to, if applicable',
      modelName: 'actionItem',
      type: 'id',
      optional: true,
    },
    dataSubCategoryId: {
      comment: 'The data subcategory the event pertains to, if applicable',
      modelName: 'dataSubCategory',
      type: 'id',
      optional: true,
    },
    processingPurposeSubCategoryId: {
      comment:
        'The processing purpose subcategory the event pertains to, if applicable',
      modelName: 'processingPurposeSubCategory',
      type: 'id',
      optional: true,
    },
    subDataPointId: {
      comment: 'The sub-datapoint the event pertains to, if applicable',
      modelName: 'subDataPoint',
      type: 'id',
      optional: true,
    },
    transactionUuid: {
      comment: 'The unique UUID of the transaction where this was added',
      type: 'string',
    },
  },
});

/** Override type */
export type AuditEventPreviewRaw = SchemaToType<typeof AuditEventPreviewRaw>;

/**
 * The parsed version of the GQL audit event, with parsed state & context
 * instead of stringified JSON
 */
export type AuditEventPreview = AuditEvent &
  Pick<AuditEventPreviewRaw, 'id' | 'createdAt' | 'actorUser'>;

export const AuditEventFiltersInput = mkInput({
  name: 'AuditEventFiltersInput',
  comment: 'Inputs for filtering a list of audit events',
  fields: {
    codes: {
      comment: 'The types of audit events',
      type: { AuditEventCode },
      optional: true,
      list: true,
    },
    actorUserIds: {
      comment: 'The ids of the users responsible for triggering the events',
      type: 'id',
      modelName: 'user',
      optional: true,
      list: true,
    },
    isAutomated: {
      comment: 'Whether the event was triggered by a bot',
      type: 'boolean',
      optional: true,
    },
    dataSiloIds: {
      comment: 'Filter for events with a specific data silo ID',
      modelName: 'dataSilo',
      type: 'id',
      optional: true,
      list: true,
    },
    createdAtBefore: {
      comment: 'Filter for events created before or on this date',
      type: 'Date',
      optional: true,
    },
    createdAtAfter: {
      comment: 'Filter for events created after this date',
      type: 'Date',
      optional: true,
    },
  },
});

/** Override type */
export type AuditEventFiltersInput = SchemaToType<
  typeof AuditEventFiltersInput
>;
