import { lighten } from 'polished';
import {
  Day,
  DayButton,
  MonthCaption,
  Months,
  Nav,
  Root,
} from 'react-day-picker';
import styled from 'styled-components';

export const StyledRoot = styled(Root)`
  --rdp-accent-color: ${({ theme }) => theme.colors.transcend};
  --rdp-day-height: 2.25rem;
  --rdp-day-width: 2.25rem;
  --rdp-range_middle-background-color: ${({ theme }) =>
    lighten(0.13, theme.colors.transcendNavy4)};
  --rdp-accent-background-color: ${({ theme }) =>
    lighten(0.1, theme.colors.transcendNavy4)};
`;

export const StyledMonths = styled(Months)`
  flex-wrap: nowrap;
`;

export const StyledDayButton = styled(DayButton)`
  border-radius: 6px;
  transition-duration: 150ms;
  transition-property: background-color, color;
  transition-timing-function: ease-in-out;
  font-size: 14px;
`;

export const StyledDay = styled(Day)`
  &.rdp-selected:not(.rdp-range_middle) ${StyledDayButton} {
    background-color: ${({ theme }) => theme.colors.transcend};
    color: ${({ theme }) => theme.colors.white};
    border: none;
  }

  :not(&.rdp-selected):not(&.rdp-range_middle) ${StyledDayButton} {
    &:hover {
      background-color: ${({ theme }) =>
        lighten(0.13, theme.colors.transcendNavy4)};
      color: ${({ theme }) => theme.colors.black};
    }
  }
`;

export const StyledNav = styled(Nav)`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

export const StyledMonthCaption = styled(MonthCaption)`
  display: flex;
  justify-content: center;
  color: ${({ theme }) => theme.colors.black};
`;
