import { ASSET_BASE_URL } from '@main/utils';

import { getEnvDefault } from './utils/getEnvDefault';

/**
 * Client ID for the LaunchDarkly Feature Flag Service
 *
 * This Client ID is not sensitive, as it cannot change or add
 * any flag values, and is limited to a single environment.
 *
 * Defaults to the `test` env ClientId.
 *
 * @see https://docs.launchdarkly.com/docs/client-side-and-server-side#section-client-side-id
 */
export const LAUNCH_DARKLY_CLIENT_ID = getEnvDefault(
  'LAUNCH_DARKLY_CLIENT_ID',
  '61411694a789b122e5e040a0',
  '61411694a789b122e5e0409f',
);

/**
 * The url of the transcend bot image
 */
export const TRANSCEND_BOT_URL = getEnvDefault(
  'TRANSCEND_BOT_URL',
  '___TRANSCEND_BOT_URL___',
  `${ASSET_BASE_URL}/transcend-bot.png`,
);

/**
 * Client token used for sending logs to datadog
 *
 * @see https://app.datadoghq.com/account/settings?_ga=2.82007574.1417940979.1611096205-1882334611.1582654153#api
 */
export const DATADOG_LOGS_CLIENT_TOKEN = getEnvDefault(
  'DATADOG_LOGS_CLIENT_TOKEN',
  'pub3d78172bd0aa0e487b6dd5c78725ae17',
  '',
);

/**
 * Frequency at which to send logs/traces defaults to 100
 * 100 means send all errors
 */
export const DATADOG_SAMPLE_RATE = parseInt(
  getEnvDefault('DATADOG_SAMPLE_RATE', '___DATADOG_SAMPLE_RATE___', '100'),
  10,
);

/**
 * The deploy env where either the admin-dashboard or the privacy-center runs
 */
export const DEPLOY_ENV = getEnvDefault(
  'DEPLOY_ENV',
  'prod',
  'local',
);

/**
 * The sha of the deployed code
 */
export const BUILD_VERSION = getEnvDefault(
  'BUILD_VERSION',
  'db18acda22044e233b028136129802d891e9e373',
  '',
);

/**
 * The node environment
 */
export const NODE_ENV = getEnvDefault(
  'NODE_ENV',
  '___NODE_ENV___',
  'development',
);

/**
 * Link for requesting a new integration
 */
export const NEW_INTEGRATION_LINK =
  'https://transcend.height.app/?taskForm=Customer-Integration-Request-T_NtRSg0IgGU';
