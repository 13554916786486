import * as t from 'io-ts';

import { dbModelId } from '@main/schema-utils';

import { AuditEventCode } from '../schema/enums';
import {
  mkAuditEventCodec,
  mkAuditEventJoinCodec,
  mkAuditJoinStateCodec,
} from '../types';

export const AuditEventDataSilo = mkAuditEventCodec(
  AuditEventCode.DataSilo,
  t.type({
    /** ID of the data silo */
    dataSiloId: dbModelId('dataSilo'),
  }),
);

/** Override types. */
export type AuditEventDataSilo = t.TypeOf<typeof AuditEventDataSilo>;

export const AuditEventDataSiloApiKey = mkAuditEventJoinCodec(
  AuditEventCode.DataSiloApiKey,
  mkAuditJoinStateCodec('apiKey'),
  t.type({
    /** ID of the data silo */
    dataSiloId: dbModelId('dataSilo'),
  }),
);

export const AuditEventDataSiloVendor = mkAuditEventJoinCodec(
  AuditEventCode.DataSiloVendor,
  mkAuditJoinStateCodec('vendor'),
  t.type({
    /** ID of the data silo */
    dataSiloId: dbModelId('dataSilo'),
  }),
);

/** Override types. */
export type AuditEventDataSiloVendor = t.TypeOf<
  typeof AuditEventDataSiloVendor
>;

export const AuditEventDataSiloBusinessEntity = mkAuditEventJoinCodec(
  AuditEventCode.DataSiloBusinessEntity,
  mkAuditJoinStateCodec('businessEntity'),
  t.type({
    /** ID of the data silo */
    dataSiloId: dbModelId('dataSilo'),
  }),
);

/** Override types. */
export type AuditEventDataSiloBusinessEntity = t.TypeOf<
  typeof AuditEventDataSiloBusinessEntity
>;
/** Override types. */
export type AuditEventDataSiloApiKey = t.TypeOf<
  typeof AuditEventDataSiloApiKey
>;

export const AuditEventDataSiloOwner = mkAuditEventJoinCodec(
  AuditEventCode.DataSiloOwner,
  mkAuditJoinStateCodec('user'),
  t.type({
    /** ID of the data silo */
    dataSiloId: dbModelId('dataSilo'),
  }),
);

/** Override types. */
export type AuditEventDataSiloOwner = t.TypeOf<typeof AuditEventDataSiloOwner>;

export const AuditEventDataSiloTeam = mkAuditEventJoinCodec(
  AuditEventCode.DataSiloTeam,
  mkAuditJoinStateCodec('team'),
  t.type({
    /** ID of the data silo */
    dataSiloId: dbModelId('dataSilo'),
  }),
);

/** Override types. */
export type AuditEventDataSiloTeam = t.TypeOf<typeof AuditEventDataSiloTeam>;

export const AuditEventDataSiloDataSilo = mkAuditEventJoinCodec(
  AuditEventCode.DataSiloDataSilo,
  mkAuditJoinStateCodec('dataSilo'),
  t.type({
    /** ID of the data silo */
    dataSiloId: dbModelId('dataSilo'),
  }),
);

/** Override types. */
export type AuditEventDataSiloDataSilo = t.TypeOf<
  typeof AuditEventDataSiloTeam
>;

export const AuditEventDataSiloSubjectDenies = mkAuditEventJoinCodec(
  AuditEventCode.DataSiloSubjectDenies,
  mkAuditJoinStateCodec('subject'),
  t.type({
    /** ID of the data silo */
    dataSiloId: dbModelId('dataSilo'),
  }),
);

/** Override types. */
export type AuditEventDataSiloSubjectDenies = t.TypeOf<
  typeof AuditEventDataSiloSubjectDenies
>;

export const AuditEventDataSiloAttribute = mkAuditEventJoinCodec(
  AuditEventCode.DataSiloAttribute,
  mkAuditJoinStateCodec(
    'attributeValue',
    t.type({
      /** the display title for the attribute key */
      attributeKeyTitle: t.string,
    }),
  ),
  t.type({
    /** ID of the data silo */
    dataSiloId: dbModelId('dataSilo'),
  }),
);

/** Override types. */
export type AuditEventDataSiloAttribute = t.TypeOf<
  typeof AuditEventDataSiloAttribute
>;

export const AuditEventDataSiloDependentDataSilo = mkAuditEventJoinCodec(
  AuditEventCode.DataSiloDependentDataSilo,
  mkAuditJoinStateCodec('dataSilo'),
  t.type({
    /** ID of the data silo */
    dataSiloId: dbModelId('dataSilo'),
  }),
);

/** Override types. */
export type AuditEventDataSiloDependentDataSilo = t.TypeOf<
  typeof AuditEventDataSiloDependentDataSilo
>;

export const AuditEventDataSiloDataSubCategory = mkAuditEventJoinCodec(
  AuditEventCode.DataSiloDataSubCategory,
  mkAuditJoinStateCodec('dataSubCategory', t.type({ category: t.string })),
  t.type({
    /** ID of the data silo */
    dataSiloId: dbModelId('dataSilo'),
  }),
);

/** Override types. */
export type AuditEventDataSiloDataSubCategory = t.TypeOf<
  typeof AuditEventDataSiloDataSubCategory
>;

export const AuditEventDataSiloProcessingPurposeSubCategory =
  mkAuditEventJoinCodec(
    AuditEventCode.DataSiloProcessingPurposeSubCategory,
    mkAuditJoinStateCodec(
      'processingPurposeSubCategory',
      t.type({ purpose: t.string }),
    ),
    t.type({
      /** ID of the data silo */
      dataSiloId: dbModelId('dataSilo'),
    }),
  );

/** Override types. */
export type AuditEventDataSiloProcessingPurposeSubCategory = t.TypeOf<
  typeof AuditEventDataSiloProcessingPurposeSubCategory
>;

export const AuditEventDataSiloSaaSCategory = mkAuditEventJoinCodec(
  AuditEventCode.DataSiloSaaSCategory,
  mkAuditJoinStateCodec('saaSCategory'),
  t.type({
    /** ID of the data silo */
    dataSiloId: dbModelId('dataSilo'),
  }),
);

/** Override types. */
export type AuditEventDataSiloSaaSCategory = t.TypeOf<
  typeof AuditEventDataSiloSaaSCategory
>;
