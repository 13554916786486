/* eslint-disable max-lines */
import { UserPrivacySignalEnum } from '@main/ag-types';
import { mkInput, mkOrder, mkType, SchemaToType } from '@main/schema-utils';

import {
  DefaultConsentOption,
  PreferenceStoreAuthLevel,
  PurposeOrderField,
} from './enums';
import { PreferenceTopicPreview } from './preferenceTopicPreview';
import { TCFPurpose } from './tcfVendor';

/** Details about a tracking purpose */
export const TrackingPurpose = mkType({
  name: 'TrackingPurpose',
  comment: 'A tracking purpose',
  fields: {
    id: {
      comment: 'The id of the tracking purpose',
      type: 'id',
      modelName: 'purpose',
    },
    name: {
      comment: 'The display name of this tracking purpose',
      type: 'string',
    },
    description: {
      comment: 'Tracking purpose description (used in Consent Manager UI) ',
      type: 'string',
    },
    defaultConsent: {
      comment: 'Whether this purpose is default consented by the user.',
      type: {
        DefaultConsentOption,
      },
    },
    trackingType: {
      comment: 'The tracking purpose type associated with the site.',
      type: 'string',
    },
    configurable: {
      comment: 'Whether this purpose should be configurable by the user ',
      type: 'boolean',
    },
    essential: {
      comment:
        'Is tracking purpose "essential" - i.e. consent is mandatory / equivalent to "essential" permission',
      type: 'boolean',
    },
    showInConsentManager: {
      comment:
        'Whether this purpose should be shown in the (data-subject facing) Consent Manager UI',
      type: 'boolean',
    },
    isActive: {
      comment:
        'Whether this purpose should be shown in the Consent Management dashboard',
      type: 'boolean',
    },
    optOutSignals: {
      comment:
        'Privacy signals that should opt the user out of this tracking purpose',
      type: { UserPrivacySignalEnum },
      list: true,
    },
    deletedAt: {
      comment: 'When this purpose was soft deleted',
      optional: true,
      type: 'Date',
    },
    authLevel: {
      type: { PreferenceStoreAuthLevel },
      comment:
        'The authentication level require by user to update the purpose in the preference store',
    },
    showInPrivacyCenter: {
      comment:
        'Whether this purpose should be shown in the communication preferences page of the privacy center',
      type: 'boolean',
    },
    title: {
      comment:
        'The title of the tracking purpose that appears in Consent Management and Privacy Center UIs',
      type: 'string',
      optional: true,
    },
    preferenceTopics: {
      comment: 'The preferences associated with this purpose',
      type: PreferenceTopicPreview,
      list: true,
    },
  },
});

/** Override type */
export type TrackingPurpose = SchemaToType<typeof TrackingPurpose>;

export const TrackingPurposePreview = mkType({
  name: 'TrackingPurposePreview',
  comment: 'Preview of purpose with subset of fields',
  fields: {
    id: TrackingPurpose.fields.id,
    name: TrackingPurpose.fields.name,
    trackingType: TrackingPurpose.fields.trackingType,
    isActive: TrackingPurpose.fields.isActive,
  },
});

/** Override type */
export type TrackingPurposePreview = SchemaToType<
  typeof TrackingPurposePreview
>;

export const TCFPurposeTrackingPurposes = mkType({
  name: 'TCFPurposeTrackingPurposes',
  comment: 'Airgap Purposes inferred from a TCF Purpose',
  fields: {
    id: {
      comment: "The id of the organization's TCF purpose",
      type: 'id',
      modelName: 'orgTcfPurpose',
    },
    isApplicable: {
      comment:
        'Whether or not this TCF purpose is applicable for the organization',
      type: 'boolean',
    },
    allowLegitimateInterest: {
      comment:
        'Whether or not this TCF purpose is allowed to be processed based on legitimate interest basis',
      type: 'boolean',
    },
    tcfPurpose: {
      comment: 'TCF Purpose',
      type: TCFPurpose,
    },
    trackingPurposes: {
      comment: 'Airgap Purpose',
      type: TrackingPurpose,
      list: true,
    },
    vendorsDeclaring: {
      comment: 'Number of vendors that have declared this purpose',
      type: 'int',
    },
  },
});

/** Override type */
export type TCFPurposeTrackingPurposes = SchemaToType<
  typeof TCFPurposeTrackingPurposes
>;

export const OrganizationTCFSpecialPurpose = mkType({
  name: 'OrganizationTCFSpecialPurposes',
  comment: 'TCF Special Purposes applicable for an organization',
  fields: {
    ...TCFPurpose.fields,
    isApplicable: {
      comment:
        'Whether or not this TCF purpose is applicable for the organization',
      type: 'boolean',
    },
    vendorsDeclaring: {
      comment: 'Number of vendors that have declared this special purpose',
      type: 'int',
    },
  },
});

/** Override type */
export type OrganizationTCFSpecialPurpose = SchemaToType<
  typeof OrganizationTCFSpecialPurpose
>;

export const UpdateOrgTCFPurposesInput = mkInput({
  name: 'UpdateOrgTCFPurposesInput',
  comment:
    'Input used to update the mapping from TCF Purposes to Airgap tracking purposes',
  fields: {
    tcfPurposeId: {
      comment: 'The TCF Purpose',
      type: 'id',
      modelName: 'tcfPurpose',
    },
    trackingPurposeIds: {
      comment: 'The Airgap tracking purposes associated with this TCF purpose',
      type: 'id',
      modelName: 'purpose',
      list: true,
      optional: true,
    },
    isApplicable: {
      comment:
        'Whether or not this TCF purpose is applicable for the organization',
      type: 'boolean',
      optional: true,
    },
    allowLegitimateInterest: {
      comment:
        'Should vendors be allowed to process on the basis of Legitimate Interest if they declare that basis. ' +
        "If set to no, vendors may still request consent for the purpose if they've declared their legal basis is flexible.",
      type: 'boolean',
      optional: true,
    },
  },
});

/** Override type */
export type UpdateOrgTCFPurposesInput = SchemaToType<
  typeof UpdateOrgTCFPurposesInput
>;

export const TrackingPurposeInput = mkInput({
  name: 'TrackingPurposeInput',
  comment: 'Input for creating or updating a tracking purpose',
  fields: {
    includeDeleted: {
      comment: 'Include soft deleted purposes in the response',
      type: 'boolean',
      optional: true,
    },
  },
});

/** Override type */
export type TrackingPurposeInput = SchemaToType<typeof TrackingPurposeInput>;

export const TrackingPurposeFiltersInput = mkInput({
  name: 'TrackingPurposeFiltersInput',
  comment: 'Filter purposes',
  fields: {
    ids: {
      comment: 'Filter by IDs',
      type: 'id',
      modelName: 'purpose',
      list: true,
      optional: true,
    },
    text: {
      comment: 'Filter by text',
      optional: true,
      type: 'string',
    },
    names: {
      comment: 'Filter by names',
      optional: true,
      type: 'string',
      list: true,
    },
    trackingTypes: {
      comment: 'Filter by tracking type',
      optional: true,
      type: 'string',
      list: true,
    },
    essential: {
      comment: 'Filter for purposes cannot be opted out by the end-user',
      optional: true,
      type: 'boolean',
    },
    optOutSignals: {
      comment:
        'Filter by privacy signals that should opt the user out of this tracking purpose',
      optional: true,
      type: { UserPrivacySignalEnum },
      list: true,
    },
    isDeleted: {
      comment: 'Filter by for purpose that are soft deleted',
      optional: true,
      type: 'boolean',
    },
    isActive: {
      comment: 'Filter for purpose that is enabled or disabled',
      optional: true,
      type: 'boolean',
    },
    authLevels: {
      type: { PreferenceStoreAuthLevel },
      comment: 'Filter by auth level',
      optional: true,
      list: true,
    },
  },
});

/** Override type */
export type TrackingPurposeFiltersInput = SchemaToType<
  typeof TrackingPurposeFiltersInput
>;

export const CreatePurposeInput = mkInput({
  name: 'CreatePurposeInput',
  comment: 'Input used to create a new consent purpose for an organization',
  fields: {
    name: {
      comment: 'The display name of this tracking purpose',
      type: 'string',
    },
    description: {
      comment: 'Tracking purpose description (used in Consent Manager UI) ',
      type: 'string',
    },
    trackingType: {
      comment: 'The tracking purpose type associated with the site.',
      type: 'string',
    },
    optOutSignals: {
      comment:
        'Privacy signals that should opt the user out of this tracking purpose',
      type: { UserPrivacySignalEnum },
      list: true,
      optional: true,
    },
    showInConsentManager: {
      comment: 'Whether this purpose should be shown in the Consent Manager UI',
      type: 'boolean',
      optional: true,
    },
    configurable: {
      comment: 'Whether this purpose should be configurable by the user ',
      type: 'boolean',
      optional: true,
    },
    isActive: {
      comment: 'Whether the purpose is active for an organization',
      type: 'boolean',
      optional: true,
    },
    authLevel: {
      type: { PreferenceStoreAuthLevel },
      optional: true,
      comment:
        'The authentication level require by user to update the marketing purpose',
    },
    showInPrivacyCenter: {
      comment:
        'Whether this purpose should be shown in the communication preferences page of the privacy center',
      type: 'boolean',
      optional: true,
    },
  },
});

/** Override type */
export type CreatePurposeInput = SchemaToType<typeof CreatePurposeInput>;

export const UpdatePurposeInput = mkInput({
  name: 'UpdatePurposeInput',
  comment:
    'Input used to update an existing consent purpose for an organization',
  fields: {
    id: {
      comment: 'The id of the purpose',
      type: 'id',
      modelName: 'purpose',
    },
    name: {
      comment: 'The name of this purpose',
      type: 'string',
      optional: true,
    },
    description: {
      comment: 'Tracking purpose description (used in Consent Manager UI) ',
      type: 'string',
      optional: true,
    },
    optOutSignals: {
      comment:
        'Privacy signals that should opt the user out of this tracking purpose',
      type: { UserPrivacySignalEnum },
      list: true,
      optional: true,
    },
    title: {
      comment:
        'The display name of the purpose that appears in Consent Management and Privacy Center UIs',
      type: 'string',
      optional: true,
    },
    showInConsentManager: {
      comment: 'Whether this purpose should be shown in the Consent Manager UI',
      type: 'boolean',
      optional: true,
    },
    configurable: {
      comment: 'Whether this purpose should be configurable by the user ',
      type: 'boolean',
      optional: true,
    },
    isActive: {
      comment: 'Whether the purpose is active for an organization',
      type: 'boolean',
      optional: true,
    },
    authLevel: {
      type: { PreferenceStoreAuthLevel },
      comment:
        'The authentication level require by user to update the marketing purpose',
      optional: true,
    },
    showInPrivacyCenter: {
      comment:
        'Whether this purpose should be shown in the communication preferences page of the privacy center',
      type: 'boolean',
      optional: true,
    },
  },
});

/** Override type */
export type UpdatePurposeInput = SchemaToType<typeof UpdatePurposeInput>;

export const DeletePurposesInput = mkInput({
  name: 'DeletePurposesInput',
  comment: 'Input used to delete existing consent purposes for an organization',
  fields: {
    ids: {
      comment: 'The ids of the purposes',
      type: 'id',
      modelName: 'purpose',
      list: true,
    },
  },
});

/** Override type */
export type DeletePurposesInput = SchemaToType<typeof DeletePurposesInput>;

export const TogglePurposeActiveInput = mkInput({
  name: 'TogglePurposeActiveInput',
  comment: 'Input used to enable/disable a list of purposes via purpose id',
  fields: {
    ids: {
      comment: 'The ids of the purposes',
      type: 'id',
      modelName: 'purpose',
      list: true,
    },
    isActive: {
      ...CreatePurposeInput.fields.isActive,
      optional: true,
    },
  },
});

/** Override type */
export type TogglePurposeActiveInput = SchemaToType<
  typeof TogglePurposeActiveInput
>;

export const OrgTcfPurposeFilterInput = mkInput({
  name: 'OrgTcfPurposeFilterInput',
  comment: 'Inputs to filter the Org TCF Purposes endpoint by',
  fields: {
    text: {
      comment:
        'Filters the purpose by whether the substring is present in the name',
      type: 'string',
      optional: true,
    },
    tcfPurposeIds: {
      comment: 'Filters the purposes by the TCF Purpose IDs',
      type: 'id',
      modelName: 'tcfPurpose',
      list: true,
      optional: true,
    },
  },
});

/** Override type */
export type OrgTcfPurposeFilterInput = SchemaToType<
  typeof OrgTcfPurposeFilterInput
>;

/**
 * Order for a purpose query
 */
export const PurposeOrder = mkOrder('Purpose', PurposeOrderField);
/** Override type */
export type PurposeOrder = SchemaToType<typeof PurposeOrder>;

/* eslint-enable max-lines */
