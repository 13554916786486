/**
 * Analytics data sources
 */
export enum AnalyticsDataSource {
  SilosByTeam = 'SILOS_BY_TEAM',
  SubDataPointsByProcessingPurposes = 'SUB_DATA_POINTS_BY_PROCESSING_PURPOSES',
  SubDataPointsByClassificationStatus = 'SUB_DATA_POINTS_BY_CLASSIFICATION_STATUS',
  SubDataPointsByClassificationProcessingStatus = 'SUB_DATA_POINTS_BY_CLASSIFICATION_PROCESSING_STATUS',
  SubDataPointsByDataSubCategories = 'SUB_DATA_POINTS_BY_DATA_SUB_CATEGORIES',
  SilosByHasPersonalData = 'SILOS_BY_HAS_PERSONAL_DATA',
  SilosByRecommendedForPrivacy = 'SILOS_BY_RECOMMENDED_FOR_PRIVACY',
  SilosByRecommendedForConsent = 'SILOS_BY_RECOMMENDED_FOR_CONSENT',
  SilosBySaaSCategory = 'SILOS_BY_SAAS_CATEGORY',
  SilosBySiloDiscoRecommendationStatus = 'SILOS_BY_SILO_DISCO_RECOMMENDATION_STATUS',
  SilosByCreatedAtAndDiscoStatus = 'SILOS_BY_CREATED_AT_AND_DISCO_STATUS',
  SiloRequestVolume = 'SILO_REQUEST_VOLUME',
  ConsentHttpRequestsByPurpose = 'CONSENT_HTTP_REQUESTS_BY_PURPOSE',
  RequestsByStatus = 'REQUESTS_BY_STATUS',
  RequestsByType = 'REQUESTS_BY_TYPE',
  RequestsBySubject = 'REQUESTS_BY_SUBJECT',
  RequestFulfillmentsOverTime = 'REQUEST_FULFILLMENTS_OVER_TIME',
  RequestFulfillmentByType = 'REQUEST_FULFILLMENT_BY_TYPE',
  RequestVolumeOverTime = 'REQUEST_VOLUME_OVER_TIME',
  PrivacyRisks = 'PRIVACY_RISKS',
  HistoricalRequestsByStatus = 'HISTORICAL_REQUESTS_BY_STATUS',
  VisitorChoices = 'VISITOR_CHOICES',
  ConsentSignals = 'CONSENT_SIGNALS',
  ConsentSessionsByRegime = 'CONSENT_SESSIONS_BY_REGIME',
  ConsentChangesTimeseries = 'CONSENT_CHANGES_TIMESERIES',
  PrivacySignalTimeseries = 'PRIVACY_SIGNAL_TIMESERIES',
  AuditorRequestsByPurpose = 'AUDITOR_REQUESTS_BY_PURPOSE',
  AuditorCookiesByPurpose = 'AUDITOR_COOKIES_BY_PURPOSE',
  AuditorRequestsRegulatedByAirgap = 'AUDITOR_REQUESTS_REGULATED_BY_AIRGAP',
  AuditorCookiesRegulatedByAirgap = 'AUDITOR_COOKIES_REGULATED_BY_AIRGAP',
  AuditorFindingsBySeverity = 'AUDITOR_FINDINGS_BY_SEVERITY',
  GlobalConsentStatusesByPurpose = 'GLOBAL_CONSENT_STATUSES_BY_PURPOSE',
}

/**
 * Analytics data grouping
 */
export enum AnalyticsGroupBy {
  AttributeValue = 'ATTRIBUTE_VALUE',
  RequestType = 'REQUEST_TYPE',
  Locale = 'LOCALE',
}

/**
 * the base cache key used for ensuring no cache key conflicts between uses
 */
export enum BaseCacheKey {
  Analytics = 'ANALYTICS',
  GeoAnalytics = 'GEO_ANALYTICS',
  AirgapTimeseriesAnalytics = 'AIRGAP_TIMESERIES_ANALYTICS',
  AirgapAggregateAnalytics = 'AIRGAP_AGGREGATE_ANALYTICS',
  DataSiloActivity = 'DATA_SILO_ACTIVITY',
  AirgapVersions = 'AIRGAP_VERSION',
}
