import { defineMessages } from '@transcend-io/internationalization';

import { Controllership } from '@main/datamap-types';

export const controllershipMessages = defineMessages<Controllership>(
  'admin-dashboard.DataMap.components.SelectControllerships.controllership',
  {
    [Controllership.Controller]: {
      defaultMessage: 'Controller',
    },
    [Controllership.JointController]: {
      defaultMessage: 'Joint Controller',
    },
    [Controllership.Processor]: {
      defaultMessage: 'Processor',
    },
  },
);
