/* eslint-disable max-lines */
import {
  LargeLanguageModelClient,
  PromptRunProductArea,
  QueueStatus,
} from '@transcend-io/privacy-types';

import { ApiKeyPreview, UserPreview } from '@main/access-control-types';
import {
  AgentPreview,
  AttributeValue,
  PromptGroupPreview,
  PromptPreview,
} from '@main/attribute-types';
import {
  CodePackagePreview,
  RepositoryPreview,
} from '@main/code-scanning-types';
import {
  ContractPreview,
  ContractScanPreview,
} from '@main/contract-scanning-types';
import { DataSubCategoryPreview } from '@main/datamap-types';
import { mkInput, mkOrder, mkType, SchemaToType } from '@main/schema-utils';

import { ApplicationPreview } from './application';
import { ApplicationUserPreview } from './appUser';
import { PromptRunOrderField } from './enums';
import { LargeLanguageModelPreview } from './largeLanguageModel';
import { PathfinderPreview } from './pathfinder';
import { PromptRunMessage, PromptRunMessageInput } from './promptRunMessage';
import { PromptThreadPreview } from './promptThreadPreview';

export const PromptRunVariableInput = mkInput({
  name: 'PromptRunVariableInput',
  comment:
    'When templating prompts, the set of data that is necessary to fill in said template',
  fields: {
    name: {
      comment: 'Name of the variable',
      type: 'string',
    },
    data: {
      comment: 'JSON stringified data to be used for that variable',
      type: 'string',
    },
  },
});

/** Override type */
export type PromptRunVariableInput = SchemaToType<
  typeof PromptRunVariableInput
>;

export const PromptRun = mkType({
  name: 'PromptRun',
  comment: 'An instance of running a prompt',
  fields: {
    id: {
      comment: 'The id of the prompt run',
      modelName: 'promptRun',
      type: 'id',
    },
    name: {
      comment:
        'The name of the prompt run (specified in API or derived from the file input)',
      type: 'string',
    },
    link: {
      comment: 'The link to view the prompt run in the admin dashboard',
      type: 'string',
    },
    status: {
      comment: 'The status of the prompt run',
      type: { QueueStatus },
    },
    productArea: {
      comment: 'The product area that the prompt run is a part of',
      type: { PromptRunProductArea },
    },
    createdAt: {
      comment: 'The date the prompt run was created',
      type: 'Date',
    },
    updatedAt: {
      comment: 'The date the prompt run was last updated',
      type: 'Date',
    },
    temperature: {
      comment:
        'Amount of randomness injected into the response. ' +
        'Defaults to 1. Ranges from 0 to 1. ' +
        'Use temp closer to 0 for analytical / multiple choice, and closer to 1 for creative and generative tasks.',
      type: 'float',
    },
    topP: {
      comment:
        'An alternative to sampling with temperature, called nucleus sampling, where the ' +
        'model considers the results of the tokens with top_p probability mass. So 0.1 ' +
        'means only the tokens comprising the top 10% probability mass are considered. ' +
        'We generally recommend altering this or `temperature` but not both.',
      type: 'float',
    },
    maxTokensToSample: {
      comment: 'The maximum number of tokens to sample in the response',
      type: 'int',
      optional: true,
    },
    duration: {
      comment:
        'The number of ms that the prompt run took to execute within the LLM',
      type: 'int',
      optional: true,
    },
    inputTokenCount: {
      comment: 'The number of tokens used in the prompt input',
      type: 'int',
      optional: true,
    },
    responseTokenCount: {
      comment: 'The number of tokens used in the response',
      type: 'int',
      optional: true,
    },
    inputTokenCost: {
      comment: 'The cost of input tokens',
      type: 'float',
      optional: true,
    },
    responseTokenCost: {
      comment: 'The cost of response tokens',
      type: 'float',
      optional: true,
    },
    totalCost: {
      comment: 'The total cost of the prompt run',
      type: 'float',
      optional: true,
    },
    error: {
      comment: 'The error message if status is error',
      type: 'string',
      optional: true,
    },
    promptRunMessages: {
      comment: 'The messages in the prompt run',
      type: PromptRunMessage,
      list: true,
    },
    prompt: {
      comment: 'The prompt that executed the prompt run',
      type: PromptPreview,
      optional: true,
    },
    promptGroup: {
      comment:
        'The prompt group that the prompt run was a being executed within',
      type: PromptGroupPreview,
      optional: true,
    },
    agent: {
      comment: 'The agent that the prompt run was associated to',
      type: AgentPreview,
      optional: true,
    },
    application: {
      comment: 'The application that the prompt run was executed within',
      type: ApplicationPreview,
      optional: true,
    },
    pathfinder: {
      comment: 'The pathfinder that reported the prompt',
      type: PathfinderPreview,
      optional: true,
    },
    applicationUser: {
      comment: 'The application user that reported the prompt',
      type: ApplicationUserPreview,
      optional: true,
    },
    codePackage: {
      comment: 'The code package that the prompt was reported for',
      type: CodePackagePreview,
      optional: true,
    },
    repository: {
      comment: 'The repository that the prompt was reported for',
      type: RepositoryPreview,
      optional: true,
    },
    promptThread: {
      comment: 'The thread that the prompt was found within',
      type: PromptThreadPreview,
      optional: true,
    },
    user: {
      comment: 'The user that the prompt run was executed by',
      type: UserPreview,
      optional: true,
    },
    apiKey: {
      comment: 'The API key that executed or reported the prompt run',
      type: ApiKeyPreview,
      optional: true,
    },
    contractScan: {
      comment: 'The contract scan that the prompt run was related to',
      type: ContractScanPreview,
      optional: true,
    },
    contract: {
      comment: 'The contract that the prompt run was run again',
      type: ContractPreview,
      optional: true,
    },
    largeLanguageModel: {
      comment: 'The large language model that executed the prompt',
      type: LargeLanguageModelPreview,
    },
    attributeValues: {
      comment: 'The attribute values used to label this assessment',
      type: AttributeValue,
      list: true,
    },
    dataSubCategories: {
      comment: 'Data sub categories that were detected in the prompt runs',
      type: DataSubCategoryPreview,
      list: true,
    },
  },
});

/** Override type */
export type PromptRun = SchemaToType<typeof PromptRun>;

/**
 * Order for a promptRuns query
 */
export const PromptRunOrder = mkOrder(PromptRun.name, PromptRunOrderField);

/** Override type */
export type PromptRunOrder = SchemaToType<typeof PromptRunOrder>;

export const PromptRunFiltersInput = mkInput({
  name: 'PromptRunFiltersInput',
  comment: 'Inputs for filtering prompt runs',
  fields: {
    ids: {
      comment: 'The ids of the prompt runs',
      type: 'id',
      modelName: 'promptRun',
      optional: true,
      list: true,
    },
    promptIds: {
      comment: 'The ids of the prompts',
      type: 'id',
      modelName: 'prompt',
      optional: true,
      list: true,
    },
    promptGroupIds: {
      comment: 'The ids of the prompt groups',
      type: 'id',
      modelName: 'promptGroup',
      optional: true,
      list: true,
    },
    largeLanguageModelIds: {
      comment: 'The ids of the large language models',
      type: 'id',
      modelName: 'largeLanguageModel',
      optional: true,
      list: true,
    },
    userIds: {
      comment: 'The ids of the users who recorded the prompt run',
      type: 'id',
      modelName: 'user',
      optional: true,
      list: true,
    },
    contractScanIds: {
      comment: 'The ids of the contract scans that the prompt was run for',
      type: 'id',
      modelName: 'contractScan',
      optional: true,
      list: true,
    },
    pathfinderIds: {
      comment: 'The ids of the pathfinders that reported the run',
      type: 'id',
      modelName: 'pathfinder',
      optional: true,
      list: true,
    },
    applicationIds: {
      comment: 'The ids of the applications that reported the run',
      type: 'id',
      modelName: 'application',
      optional: true,
      list: true,
    },
    appUserIds: {
      comment: 'The ids of the application users that reported the run',
      type: 'id',
      modelName: 'appUser',
      optional: true,
      list: true,
    },
    dataSubCategoryIds: {
      comment: 'Filter by specific subcategory of personal data',
      type: 'id',
      modelName: 'dataSubCategory',
      optional: true,
      list: true,
    },
    apiKeyIds: {
      comment: 'The ids of the api keys that recorded for the prompt run',
      type: 'id',
      modelName: 'apiKey',
      optional: true,
      list: true,
    },
    teamIds: {
      comment:
        'The ids of the teams that reported the prompt run (looked up by users on team)',
      type: 'id',
      modelName: 'team',
      optional: true,
      list: true,
    },
    codePackageIds: {
      comment: 'Filter by specific code packages',
      type: 'id',
      modelName: 'codePackage',
      optional: true,
      list: true,
    },
    repositoryIds: {
      comment: 'Filter by specific repositories',
      type: 'id',
      modelName: 'repository',
      optional: true,
      list: true,
    },
    contractIds: {
      comment: 'Filter by specific contracts',
      type: 'id',
      modelName: 'contract',
      optional: true,
      list: true,
    },
    statuses: {
      comment: 'The statuses of the prompt runs',
      type: { QueueStatus },
      optional: true,
      list: true,
    },
    productAreas: {
      comment: 'The product areas of the prompt runs',
      type: { PromptRunProductArea },
      optional: true,
      list: true,
    },
    text: {
      comment: 'Filter by text (title of the prompt)',
      optional: true,
      type: 'string',
    },
  },
});

/** Override type */
export type PromptRunFiltersInput = SchemaToType<typeof PromptRunFiltersInput>;

export const ExecutePromptRunInput = mkInput({
  name: 'ExecutePromptRunInput',
  comment: 'Input for executing a prompt',
  fields: {
    name: {
      comment:
        'The name of the prompt run (specified in API or derived from the file input)',
      type: 'string',
    },
    // TODO: https://transcend.height.app/T-30473 - encrypt this
    content: {
      comment: 'The content to scan',
      type: 'string',
    },
    // TODO: https://transcend.height.app/T-30669 - determine how to gate this
    productArea: {
      comment: 'The product area that the prompt run is a part of',
      type: { PromptRunProductArea },
    },
    temperature: {
      comment:
        'Amount of randomness injected into the response. ' +
        'Defaults to 1. Ranges from 0 to 1. ' +
        'Use temp closer to 0 for analytical / multiple choice, and closer to 1 for creative and generative tasks.',
      type: 'float',
      optional: true,
    },
    topP: {
      comment:
        'An alternative to sampling with temperature, called nucleus sampling, where the ' +
        'model considers the results of the tokens with top_p probability mass. So 0.1 ' +
        'means only the tokens comprising the top 10% probability mass are considered. ' +
        'We generally recommend altering this or `temperature` but not both.',
      type: 'float',
      optional: true,
    },
    maxTokensToSample: {
      comment: 'The maximum number of tokens to sample in the response',
      type: 'int',
      optional: true,
    },
    promptId: {
      comment: 'The id of the prompt being executed',
      type: 'id',
      modelName: 'prompt',
    },
    promptGroupId: {
      comment: 'The id of the prompt group being executed',
      type: 'id',
      modelName: 'promptGroup',
      optional: true,
    },
    variables: {
      comment:
        'The additional variables to template into the prompt. JSON data is stringified.',
      type: PromptRunVariableInput,
      list: true,
      optional: true,
    },
    largeLanguageModelId: {
      comment: 'The id of the large language model being executed',
      type: 'id',
      modelName: 'largeLanguageModel',
      optional: true,
    },
  },
});

/** Override type */
export type ExecutePromptRunInput = SchemaToType<typeof ExecutePromptRunInput>;

export const ReportPromptRunInput = mkInput({
  name: 'ReportPromptRunInput',
  comment:
    'Input for recording the execution of a prompt that is run on premise',
  fields: {
    name: {
      comment:
        'The name of the prompt run (specified in API or derived from the file input)',
      type: 'string',
    },
    error: {
      comment: 'Error message to report when an error occurs',
      type: 'string',
      optional: true,
    },
    // TODO: https://transcend.height.app/T-30669 - determine how to gate this
    productArea: {
      comment: 'The product area that the prompt run is a part of',
      type: { PromptRunProductArea },
    },
    status: {
      comment: 'The status of the prompt run',
      type: { QueueStatus },
      optional: true,
    },
    runByEmployeeEmail: {
      comment: 'The email address of employee that ran the prompt',
      type: 'string',
      optional: true,
    },
    duration: {
      comment: 'The number of milliseconds it took to run the message',
      type: 'int',
      optional: true,
    },
    temperature: {
      comment:
        'Amount of randomness injected into the response. ' +
        'Defaults to 1. Ranges from 0 to 1. ' +
        'Use temp closer to 0 for analytical / multiple choice, and closer to 1 for creative and generative tasks.',
      type: 'float',
      optional: true,
    },
    topP: {
      comment:
        'An alternative to sampling with temperature, called nucleus sampling, where the ' +
        'model considers the results of the tokens with top_p probability mass. So 0.1 ' +
        'means only the tokens comprising the top 10% probability mass are considered. ' +
        'We generally recommend altering this or `temperature` but not both.',
      type: 'float',
      optional: true,
    },
    maxTokensToSample: {
      comment: 'The maximum number of tokens to sample in the response',
      type: 'int',
      optional: true,
    },
    promptId: {
      comment: 'The id of the prompt being executed',
      type: 'id',
      modelName: 'prompt',
      optional: true,
    },
    promptTitle: {
      comment: 'The title of the prompt being executed',
      type: 'string',
      optional: true,
    },
    promptGroupId: {
      comment: 'The id of the prompt group being executed',
      type: 'id',
      modelName: 'promptGroup',
      optional: true,
    },
    promptGroupTitle: {
      comment: 'The title of the prompt group being executed',
      type: 'string',
      optional: true,
    },
    applicationId: {
      comment: 'The id of the application calling the LLM',
      type: 'id',
      modelName: 'application',
      optional: true,
    },
    applicationName: {
      comment: 'The name of the application calling the LLM',
      type: 'string',
      optional: true,
    },
    applicationUserCoreIdentifier: {
      comment:
        'The core identifier of the application user interacting with the model',
      type: 'string',
      optional: true,
    },
    promptThreadId: {
      comment:
        'The Transcend UUID of the prompt thread that this run is a part of',
      type: 'id',
      modelName: 'promptThread',
      optional: true,
    },
    threadId: {
      comment:
        'The promptThread.threadId ID that the prompt run is a part of. ' +
        'This may be the thread ID for the remote system such as OpenAI.',
      type: 'string',
      optional: true,
    },
    slackMessageTs: {
      comment:
        'The ts of the slack message if this run is in response to a slack message',
      type: 'string',
      optional: true,
    },
    slackTeamId: {
      comment:
        'The ID of the slack team if the run is in response to a slack message',
      type: 'string',
      optional: true,
    },
    slackChannelId: {
      comment:
        'The ID of the slack channel if the run is in response to a slack message',
      type: 'string',
      optional: true,
    },
    slackChannelName: {
      comment:
        'The name of the slack channel if the thread is in response to a slack message',
      type: 'string',
      optional: true,
    },
    applicationUserName: {
      comment: 'Name of the application user being reported on',
      type: 'string',
      optional: true,
    },
    agentId: {
      comment: 'The ID of the agent that reported the prompt run',
      type: 'id',
      modelName: 'agent',
      optional: true,
    },
    agentName: {
      comment: 'The name of the agent that reported the prompt run',
      type: 'string',
      optional: true,
    },
    agentRemoteId: {
      comment: 'The remote ID of the agent - agent.agentId',
      type: 'string',
      optional: true,
    },
    pathfinderId: {
      comment: 'The id of the pathfinder calling the LLM',
      type: 'id',
      modelName: 'pathfinder',
      optional: true,
    },
    pathfinderName: {
      comment: 'The name of the pathfinder calling the LLM',
      type: 'string',
      optional: true,
    },
    codePackageId: {
      comment: 'The id of the code package being reported on',
      type: 'id',
      modelName: 'codePackage',
      optional: true,
    },
    codePackageName: {
      comment: 'The name of the code package being reported on',
      type: 'string',
      optional: true,
    },
    repositoryId: {
      comment: 'The id of the repository being reported on',
      type: 'id',
      modelName: 'repository',
      optional: true,
    },
    repositoryName: {
      comment: 'The name of the repository being reported on',
      type: 'string',
      optional: true,
    },
    largeLanguageModelId: {
      comment: 'The id of the large language model being executed',
      type: 'id',
      modelName: 'largeLanguageModel',
      optional: true,
    },
    largeLanguageModelName: {
      comment: 'The name of the large language model being executed',
      type: 'string',
      optional: true,
    },
    largeLanguageModelClient: {
      comment: 'The client of the large language model being executed',
      type: { LargeLanguageModelClient },
      optional: true,
    },
    promptRunMessages: {
      comment: 'The messages in the prompt run',
      type: PromptRunMessageInput,
      list: true,
    },
  },
});

/** Override type */
export type ReportPromptRunInput = SchemaToType<typeof ReportPromptRunInput>;

export const AddMessagesToPromptRunInput = mkInput({
  name: 'AddMessagesToPromptRunInput',
  comment: 'Input for adding messages to an existing prompt run',
  fields: {
    id: {
      comment:
        'The ID of the prompt run being reported on. Either this or "name" must be provided, but not both.',
      type: 'id',
      modelName: 'promptRun',
      optional: true,
    },
    name: {
      comment:
        'The name of the prompt run (specified in API or derived from the file input). ' +
        'Either this or "id" must be provided, but not both.',
      type: 'string',
      optional: true,
    },
    error: {
      comment: 'Error message to report when an error occurs',
      type: 'string',
      optional: true,
    },
    status: {
      comment: 'The status of the prompt run',
      type: { QueueStatus },
      optional: true,
    },
    duration: {
      comment: 'The number of milliseconds it took to run the message',
      type: 'int',
      optional: true,
    },
    promptRunMessages: {
      comment: 'The messages in the prompt run',
      type: PromptRunMessageInput,
      list: true,
      optional: true,
    },
  },
});

/** Override type */
export type AddMessagesToPromptRunInput = SchemaToType<
  typeof AddMessagesToPromptRunInput
>;

/* eslint-enable max-lines */
