import { defineMessages } from '@transcend-io/internationalization';

export const assessmentsPageTitleMessages = defineMessages(
  'admin-dashboard.Assessments.assessmentsPageTitle',
  {
    assessments: {
      defaultMessage: 'Assessments',
    },
    assessmentForms: {
      defaultMessage: 'Assessments',
    },
    assessmentForm: {
      defaultMessage: 'Assessment',
    },
    newAssessmentFormTemplate: {
      defaultMessage: 'New Assessment Template',
    },
    assessmentFormTemplates: {
      defaultMessage: 'Templates',
    },
    riskFrameworks: {
      defaultMessage: 'Risk Frameworks',
    },
    riskCategories: {
      defaultMessage: 'Risk Categories',
    },
    assessmentFormTemplate: {
      defaultMessage: 'Template',
    },
    assessmentEmailSets: {
      defaultMessage: 'Email Sets',
    },
    templates: {
      defaultMessage: 'Email Templates',
    },
    respondents: {
      defaultMessage: 'Responses',
    },
    comments: {
      defaultMessage: 'Comments',
    },
  },
);
