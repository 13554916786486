/* eslint-disable max-lines */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import _get from 'lodash/get';
import _set from 'lodash/set';

import { LanguageKey } from '@transcend-io/internationalization';
import {
  ConfigurableColorPaletteColor,
  CustomizableComponent,
  CustomizableText,
} from '@transcend-io/privacy-types';

import {
  DataApplication,
  DataCategory,
  DataCategoryMethod,
  DataCategoryMethodMenu,
  DataCollection,
  RegionsList,
} from '@main/datamap-types';
import {
  createDefinedMessage,
  createLocalizedMessage,
} from '@main/internationalization';
import type {
  AssetFile,
  FontBasicInput,
  PrivacyCenter,
  PrivacyCenterAssetName,
  RequestsProcessedDisclosureStatOverrideInput,
  RequestsProcessedDisclosureStatsSettings,
} from '@main/pc-types';
import type { ID } from '@main/schema-utils';

import type { DataPracticeNode } from './types';

/**
 * The path from the privacyCenter.dataPractices to a specific dataCategory
 */
export type DataCategoryIndex = [
  // data category method index
  number,
  'children',
  // data category index
  number,
];

/**
 * When creating a new data category, there is no final index
 */
type CreateDataCategoryIndex = [
  // data category method index
  number,
  'children',
];

/**
 * The path from the privacyCenter.dataPractices to a specific dataCollection
 */
export type DataCollectionIndex = [
  // data category method index
  number,
  'children',
  // data category index
  number,
  'children',
  // data collection index
  number,
];

/**
 * When creating a new data collection, no final index is needed
 */
type CreateDataCollectionIndex = [
  // data category method index
  number,
  'children',
  // data category index
  number,
  'children',
];

/**
 * The PrivacyCenter reducer state
 */
export interface PrivacyCenterState {
  /** The privacy center that exists in the database and is deployed. */
  deployedPrivacyCenter?: PrivacyCenter;
  /** The privacy center that is stored in the cache, possibly being edited */
  privacyCenter?: PrivacyCenter;
}

// TODO: https://transcend.height.app/T-5506 - break down store into sub-stores
export const privacyCenterSlice = createSlice({
  name: 'PrivacyCenter',
  initialState: {} as PrivacyCenterState,
  reducers: {
    // Update privacy center in store
    setPrivacyCenter: (state, { payload }: PayloadAction<PrivacyCenter>) => ({
      ...state,
      privacyCenter: payload,
      deployedPrivacyCenter: payload,
    }),
    // Reset the privacy center to the deployed state
    resetPrivacyCenter: (state) => ({
      ...state,
      privacyCenter: state.deployedPrivacyCenter,
    }),

    // ////// //
    // Assets //
    // ////// //

    // Update asset
    changeAsset: (
      state,
      {
        payload: { asset, assetName },
      }: PayloadAction<{
        /** The modified asset */
        asset: AssetFile | undefined;
        /** The name of the asset that was modified */
        assetName: PrivacyCenterAssetName;
      }>,
    ) => {
      if (state.privacyCenter) {
        state.privacyCenter.assets[assetName] = asset;
      }
    },
    // Update asset's alt text
    changeAssetAlt: (
      state,
      {
        payload: { alt, assetName },
      }: PayloadAction<{
        /** The alt text */
        alt: string;
        /** The name of the asset that was modified */
        assetName: PrivacyCenterAssetName;
      }>,
    ) => {
      if (state.privacyCenter) {
        const assetFile = state.privacyCenter.assets[assetName];
        if (assetFile && assetFile.alt) {
          assetFile.alt.defaultMessage = alt;
        } else if (assetFile) {
          assetFile.alt = createLocalizedMessage(alt, true);
        }
      }
    },

    // ///// //
    // Theme //
    // ///// //

    // Update a color
    changeColor: (
      state,
      {
        payload: { color, colorName },
      }: PayloadAction<{
        /** The value of the color */
        color: string;
        /** The name of the color in the palette */
        colorName: ConfigurableColorPaletteColor;
      }>,
    ) => {
      if (state.privacyCenter) {
        state.privacyCenter.partialTheme.colors[colorName] = color;
        state.privacyCenter.theme.colors[colorName] = color;
      }
    },

    // Update a font
    changeTextStyle: (
      state,
      {
        payload: { textType, textStyle },
      }: PayloadAction<{
        /** The type of text */
        textStyle: string;
        /** The name of the text type */
        textType: CustomizableText;
      }>,
    ) => {
      if (state.privacyCenter) {
        state.privacyCenter.theme.textStyles[textType].name = textStyle;

        if (!state.privacyCenter.partialTheme.textStyles) {
          state.privacyCenter.partialTheme.textStyles = {};
        }
        if (!state.privacyCenter.partialTheme.textStyles![textType]) {
          state.privacyCenter.partialTheme.textStyles![textType] =
            state.privacyCenter.theme.textStyles[textType];
        }
        state.privacyCenter.partialTheme.textStyles![textType]!.name =
          textStyle;
      }
    },
    changeTextStyleLink: (
      state,
      {
        payload: { textType, link },
      }: PayloadAction<{
        /** The link of text */
        link: string;
        /** The name of the text type */
        textType: CustomizableText;
      }>,
    ) => {
      if (state.privacyCenter) {
        state.privacyCenter.theme.textStyles[textType].url = link;

        if (!state.privacyCenter.partialTheme.textStyles) {
          state.privacyCenter.partialTheme.textStyles = {};
        }
        if (!state.privacyCenter.partialTheme.textStyles![textType]) {
          state.privacyCenter.partialTheme.textStyles![textType] =
            state.privacyCenter.theme.textStyles[textType];
        }
        state.privacyCenter.partialTheme.textStyles![textType]!.url = link;
      }
    },
    addTextStyleAsset: (
      state,
      {
        payload: { textType, font },
      }: PayloadAction<{
        /** The asset to add */
        font: FontBasicInput;
        /** The name of the text type */
        textType: CustomizableText;
      }>,
    ) => {
      if (state.privacyCenter) {
        if (!state.privacyCenter.theme.textStyles[textType].assets) {
          state.privacyCenter.theme.textStyles[textType].assets = [];
        }
        if (!state.privacyCenter.partialTheme.textStyles) {
          state.privacyCenter.partialTheme.textStyles = {};
        }
        if (!state.privacyCenter.partialTheme.textStyles![textType]) {
          state.privacyCenter.partialTheme.textStyles![textType] =
            state.privacyCenter.theme.textStyles[textType];
        }
        if (!state.privacyCenter.partialTheme.textStyles![textType]!.assets) {
          state.privacyCenter.partialTheme.textStyles![textType]!.assets = [];
        }
        state.privacyCenter.theme.textStyles[textType].assets!.push(font);
        state.privacyCenter.partialTheme.textStyles![textType]!.assets!.push(
          font,
        );
      }
    },
    removeTextStyleAsset: (
      state,
      {
        payload: { textType, url },
      }: PayloadAction<{
        /** The asset URL to remote */
        url: string;
        /** The name of the text type */
        textType: CustomizableText;
      }>,
    ) => {
      if (state.privacyCenter) {
        if (state.privacyCenter.theme.textStyles[textType].assets) {
          state.privacyCenter.theme.textStyles[textType].assets =
            state.privacyCenter.theme.textStyles[textType].assets!.filter(
              (asset) => asset.url !== url,
            );
        }
        if (state.privacyCenter.partialTheme.textStyles?.[textType]?.assets) {
          state.privacyCenter.partialTheme!.textStyles![textType]!.assets =
            state.privacyCenter.partialTheme!.textStyles![
              textType
            ]!.assets!.filter((asset) => asset.url !== url);
        }
      }
    },

    // Update a component style
    changeComponentStyle: (
      state,
      {
        payload: { componentCss, componentType },
      }: PayloadAction<{
        /** The new style for the button */
        componentCss: string;
        /** The type of component */
        componentType: CustomizableComponent;
      }>,
    ) => {
      if (state.privacyCenter) {
        if (!state.privacyCenter.partialTheme.componentStyles) {
          state.privacyCenter.partialTheme.componentStyles = {};
        }

        state.privacyCenter.partialTheme!.componentStyles[componentType] =
          componentCss;
        state.privacyCenter.theme.componentStyles[componentType] = componentCss;
      }
    },

    // /////// //
    // General //
    // /////// //

    // Update home url
    changeHome: (state, { payload }: PayloadAction<string>) => {
      if (state.privacyCenter) {
        state.privacyCenter.home.defaultMessage = payload;
      }
    },
    // Emails
    changeEmailSettings: (
      state,
      { payload }: PayloadAction<Partial<PrivacyCenter>>,
    ) => {
      if (state.privacyCenter) {
        state.privacyCenter = Object.assign(state.privacyCenter, payload);
      }
    },
    changeCustomSubdomain: (state, { payload }: PayloadAction<string>) => {
      if (state.privacyCenter) {
        state.privacyCenter.customSubdomain = payload;
      }
    },
    // Update locales
    changeLocales: (state, { payload }: PayloadAction<LanguageKey[]>) => {
      if (state.privacyCenter) {
        state.privacyCenter.locales = payload;
      }
    },
    // Update defaultLocale
    changeDefaultLocale: (state, { payload }: PayloadAction<LanguageKey>) => {
      if (state.privacyCenter) {
        state.privacyCenter.defaultLocale = payload;
      }
    },
    // Update preferBrowserDefaultLocale
    changePreferBrowserDefaultLocale: (
      state,
      { payload }: PayloadAction<boolean>,
    ) => {
      if (state.privacyCenter) {
        state.privacyCenter.preferBrowserDefaultLocale = payload;
      }
    },
    // Update hideDataPractices
    changeHideDataPractices: (state, { payload }: PayloadAction<boolean>) => {
      if (state.privacyCenter) {
        state.privacyCenter.hideDataPractices = payload;
      }
    },
    // Update showTrackingTechnologies
    changeShowTrackingTechnologies: (
      state,
      { payload }: PayloadAction<boolean>,
    ) => {
      if (state.privacyCenter) {
        state.privacyCenter.showTrackingTechnologies = payload;
      }
    },
    // Update showManageYourPrivacy
    changeShowManageYourPrivacy: (
      state,
      { payload }: PayloadAction<boolean>,
    ) => {
      if (state.privacyCenter) {
        state.privacyCenter.showManageYourPrivacy = payload;
      }
    },
    // Update showSaleOfInfo
    changeShowSaleOfInfo: (state, { payload }: PayloadAction<boolean>) => {
      if (state.privacyCenter) {
        state.privacyCenter.showSaleOfInfo = payload;
      }
    },
    // Update unauthenticatedDoNotSellRegions
    changeUnauthenticatedDoNotSellRegions: (
      state,
      { payload }: PayloadAction<RegionsList>,
    ) => {
      if (state.privacyCenter) {
        state.privacyCenter.unauthenticatedDoNotSellRegions = payload;
      }
    },
    // Update showPrivacyRequestButton
    changeShowPrivacyRequestButton: (
      state,
      { payload }: PayloadAction<boolean>,
    ) => {
      if (state.privacyCenter) {
        state.privacyCenter.showPrivacyRequestButton = payload;
      }
    },
    // Update showPolicies
    changeShowPolicies: (state, { payload }: PayloadAction<boolean>) => {
      if (state.privacyCenter) {
        state.privacyCenter.showPolicies = payload;
      }
    },
    // Update isDisabled
    changeShowPrivacyCenter: (state, { payload }: PayloadAction<boolean>) => {
      if (state.privacyCenter) {
        state.privacyCenter.isDisabled = !payload;
      }
    },
    // Update showCookies
    changeShowCookies: (state, { payload }: PayloadAction<boolean>) => {
      if (state.privacyCenter) {
        state.privacyCenter.showCookies = payload;
      }
    },
    // Update showConsentManager
    changeShowConsentManager: (state, { payload }: PayloadAction<boolean>) => {
      if (state.privacyCenter) {
        state.privacyCenter.showConsentManager = payload;
      }
    },
    // Update showDataFlows
    changeShowDataFlows: (state, { payload }: PayloadAction<boolean>) => {
      if (state.privacyCenter) {
        state.privacyCenter.showDataFlows = payload;
      }
    },
    // Update transformAccessReportJsonToCsv
    changeTransformAccessReportJsonToCsv: (
      state,
      { payload }: PayloadAction<boolean>,
    ) => {
      if (state.privacyCenter) {
        state.privacyCenter.transformAccessReportJsonToCsv = payload;
      }
    },

    // Update allowPartitionChange
    changeAllowPartitionChange: (
      state,
      { payload }: PayloadAction<boolean>,
    ) => {
      if (state.privacyCenter) {
        state.privacyCenter.allowPartitionChange = payload;
      }
    },
    // Update showPartition
    changeShowPartition: (state, { payload }: PayloadAction<boolean>) => {
      if (state.privacyCenter) {
        state.privacyCenter.showPartition = payload;
      }
    },
    // Update showMarketingPreferences
    changeShowMarketingPreferences: (
      state,
      { payload }: PayloadAction<boolean>,
    ) => {
      if (state.privacyCenter) {
        state.privacyCenter.showMarketingPreferences = payload;
      }
    },

    // ////////////// //
    // Data practices //
    // ////////////// //

    // Update the order of data practices
    reorderDataPractices: (
      state,
      { payload }: PayloadAction<DataPracticeNode[]>,
    ) => {
      if (state.privacyCenter) {
        state.privacyCenter.dataPractices = payload
          .map((dataCategoryMethodMenu) => ({
            ...dataCategoryMethodMenu,
            children: dataCategoryMethodMenu.children
              ?.map((dataCategory) => ({
                ...dataCategory,
                children: dataCategory.children?.filter(
                  (dataCollection) => !dataCollection.isCreateButton,
                ),
              }))
              .filter((dataCategory) => !dataCategory.isCreateButton),
          }))
          .filter(
            (dataCategoryMethodMenu) => !dataCategoryMethodMenu.isCreateButton,
          )
          .filter(
            (dataCategoryMethodMenu) =>
              dataCategoryMethodMenu.id !== DataCategoryMethod.Unassigned ||
              (dataCategoryMethodMenu?.children?.[0]?.children?.length &&
                dataCategoryMethodMenu?.children?.[0]?.children?.length > 0),
          ) as DataCategoryMethodMenu[];
      }
    },
    // Remove a data application from a data collection
    removeApplicationFromCollection: (
      state,
      {
        payload: { index, id },
      }: PayloadAction<{
        /** The id of the data application to remove */
        id: ID<'dataApplication'>;
        /** The path to the data collection to remove from */
        index: DataCollectionIndex;
      }>,
    ) => {
      if (state.privacyCenter) {
        _set(
          state,
          ['privacyCenter', 'dataPractices', ...index, 'dataApplications'],
          // Preferable for type to be inferred in filter but we lose it from get, long term
          // the reducer and action will be changed
          (_get as any)(state, [
            'privacyCenter',
            'dataPractices',
            ...index,
            'dataApplications',
          ]).filter(
            (dataApplication: DataApplication) => dataApplication.id !== id,
          ),
        );

        _set(
          state,
          ['privacyCenter', 'dataPractices', ...index, 'isModified'],
          true,
        );
      }
    },
    // Connect a data application to the currently selected data collection
    addDataApplicationToCollection: (
      state,
      {
        payload: { index, dataApplication },
      }: PayloadAction<{
        /** The data application to be added to the selected data collection */
        dataApplication: DataApplication;
        /** The index to the currently selected data collection */
        index: DataCollectionIndex;
      }>,
    ) => {
      if (state.privacyCenter) {
        _set(
          state,
          ['privacyCenter', 'dataPractices', ...index, 'dataApplications'],
          [
            ...(_get as any)(state, [
              'privacyCenter',
              'dataPractices',
              ...index,
              'dataApplications',
            ]),
            dataApplication,
          ],
        );
        _set(
          state,
          ['privacyCenter', 'dataPractices', ...index, 'isModified'],
          true,
        );
      }
    },
    // Set the icon of the data collection or data category
    setDataApplications: (
      state,
      {
        payload: { dataApplications },
      }: PayloadAction<{
        /** The index to the data practice node */
        dataApplications: DataApplication[];
      }>,
    ) => {
      if (state.privacyCenter) {
        state.privacyCenter.dataApplications = dataApplications;
      }
    },
    // Set the icon of the data collection or data category
    setDataCollectionIcon: (
      state,
      {
        payload: { icon, index },
      }: PayloadAction<{
        /** The new icon */
        icon: string;
        /** The index to the data practice node */
        index: DataCollectionIndex;
      }>,
    ) => {
      if (state.privacyCenter) {
        _set(state, ['privacyCenter', 'dataPractices', ...index, 'logo'], icon);
        _set(
          state,
          ['privacyCenter', 'dataPractices', ...index, 'isModified'],
          true,
        );
      }
    },
    // Set the title of a dataPractice
    setDataPracticeTitle: (
      state,
      {
        payload: { title, index },
      }: PayloadAction<{
        /** The new title */
        title: string;
        /** The index to the data practice node */
        index: DataCollectionIndex | DataCategoryIndex;
      }>,
    ) => {
      if (state.privacyCenter) {
        _set(
          state,
          [
            'privacyCenter',
            'dataPractices',
            ...index,
            'title',
            'defaultMessage',
          ],
          title,
        );
        _set(
          state,
          ['privacyCenter', 'dataPractices', ...index, 'isModified'],
          true,
        );
      }
    },
    // Set the description on a data collection
    setDataCollectionDescription: (
      state,
      {
        payload: { description, index },
      }: PayloadAction<{
        /** The new description */
        description: string;
        /** The index to the data collection */
        index: DataCollectionIndex;
      }>,
    ) => {
      const msg = (_get as any)(state, [
        'privacyCenter',
        'dataPractices',
        ...index,
        'description',
      ]);

      if (!msg) {
        _set(
          state,
          ['privacyCenter', 'dataPractices', ...index, 'description'],
          createDefinedMessage(description),
        );
      } else {
        _set(
          state,
          [
            'privacyCenter',
            'dataPractices',
            ...index,
            'description',
            'defaultMessage',
          ],
          description,
        );
      }

      _set(
        state,
        ['privacyCenter', 'dataPractices', ...index, 'isModified'],
        true,
      );
    },
    // Set the title of a dataApplication
    setDataApplicationTitle: (
      state,
      {
        payload: { title, id },
      }: PayloadAction<{
        /** The new title */
        title: string;
        /** The id of the data application */
        id: ID<'dataApplication'>;
      }>,
    ) => {
      if (state.privacyCenter) {
        state.privacyCenter.dataApplications.forEach((dataApplication) => {
          if (dataApplication.id === id) {
            dataApplication.title.defaultMessage = title;
          }
        });
      }
    },
    // Set the description on a data application
    setDataApplicationDescription: (
      state,
      {
        payload: { description, id },
      }: PayloadAction<{
        /** The new description */
        description: string;
        /** The id of the data application */
        id: ID<'dataApplication'>;
      }>,
    ) => {
      if (state.privacyCenter) {
        state.privacyCenter.dataApplications.forEach((dataApplication) => {
          if (dataApplication.id === id) {
            dataApplication.description.defaultMessage = description;
          }
        });
      }
    },
    // Create a new data category at a certain index within dataPractices
    createDataCategory: (
      state,
      {
        payload: { index, newDataCategory },
      }: PayloadAction<{
        /** The index */
        index: CreateDataCategoryIndex;
        /** The new data category to create */
        newDataCategory: DataCategory;
      }>,
    ) =>
      void _set(
        state,
        ['privacyCenter', 'dataPractices', ...index.slice(0, -1)],
        [
          ...(_get as any)(
            state,
            ['privacyCenter', 'dataPractices', ...index.slice(0, -1)],
            [],
          ),
          { ...newDataCategory, isModified: true },
        ],
      ),
    // Create a new data collection at a certain index within dataPractices
    createDataCollection: (
      state,
      {
        payload: { index, newDataCollection },
      }: PayloadAction<{
        /** The index */
        index: CreateDataCollectionIndex;
        /** The new data collection to create */
        newDataCollection: DataCollection;
      }>,
    ) =>
      void _set(
        state,
        ['privacyCenter', 'dataPractices', ...index.slice(0, -1)],
        [
          ...(_get as any)(
            state,
            ['privacyCenter', 'dataPractices', ...index.slice(0, -1)],
            [],
          ),
          { ...newDataCollection, isModified: true },
        ],
      ),
    // Deletes a data category or collection based only on index
    deleteDataPractice: (
      state,
      {
        payload: index,
      }: PayloadAction<DataCollectionIndex | DataCategoryIndex>,
    ) => {
      // The index to remove
      const toRemove = index[index.length - 1];

      // The index to get/set into
      const thisIndex = index.slice(0, -1);

      // The current value
      const current: any[] = (_get as any)(
        state.privacyCenter?.dataPractices,
        thisIndex,
      );

      // Delete the value at the index
      _set(
        state.privacyCenter?.dataPractices || {},
        thisIndex,
        (current || []).filter((c, i) => i !== toRemove),
      );
    },
  },
});

export const {
  setPrivacyCenter,
  resetPrivacyCenter,
  changeAsset,
  changeAssetAlt,
  changeTextStyle,
  changeTextStyleLink,
  changeColor,
  changeComponentStyle,
  changeHome,
  changeShowPolicies,
  changeEmailSettings,
  changeShowCookies,
  changeShowDataFlows,
  changeCustomSubdomain,
  changeDefaultLocale,
  changeLocales,
  changePreferBrowserDefaultLocale,
  addTextStyleAsset,
  removeTextStyleAsset,
  changeHideDataPractices,
  changeShowTrackingTechnologies,
  changeAllowPartitionChange,
  changeShowPartition,
  changeShowManageYourPrivacy,
  changeShowSaleOfInfo,
  changeUnauthenticatedDoNotSellRegions,
  changeTransformAccessReportJsonToCsv,
  reorderDataPractices,
  removeApplicationFromCollection,
  addDataApplicationToCollection,
  changeShowConsentManager,
  setDataApplications,
  setDataCollectionIcon,
  setDataPracticeTitle,
  changeShowPrivacyRequestButton,
  setDataCollectionDescription,
  setDataApplicationTitle,
  setDataApplicationDescription,
  createDataCategory,
  createDataCollection,
  changeShowPrivacyCenter,
  deleteDataPractice,
  changeShowMarketingPreferences,
} = privacyCenterSlice.actions;

/**
 * The RequestsProcessedDisclosureStatsSettingsState reducer state
 */
export interface RequestsProcessedDisclosureStatsSettingsState {
  /** The settings */
  requestsProcessedDisclosureStatsSettings?: RequestsProcessedDisclosureStatsSettings;
  /** The new settings to apply */
  newRequestsProcessedDisclosureStatsSettings: RequestsProcessedDisclosureStatsSettings;
}

export const requestsProcessedDisclosureStatsSettingsSlice = createSlice({
  name: 'RequestsProcessedDisclosureStatsSettings',
  initialState: {} as RequestsProcessedDisclosureStatsSettingsState,
  reducers: {
    resetRequestsProcessedDisclosureStatsSettings: (state) => ({
      ...state,
      newRequestsProcessedDisclosureStatsSettings: {
        ...state.requestsProcessedDisclosureStatsSettings,
      } as RequestsProcessedDisclosureStatsSettings,
    }),

    // Update the settings in the store. This is for syncing with the backend state
    setInitialRequestsProcessedDisclosureStatsSettings: (
      state,
      { payload }: PayloadAction<RequestsProcessedDisclosureStatsSettings>,
    ) => ({
      ...state,
      requestsProcessedDisclosureStatsSettings: {
        ...state.requestsProcessedDisclosureStatsSettings,
        ...payload,
      } as RequestsProcessedDisclosureStatsSettings,
      // "sync" new and old states, aka no modifications
      newRequestsProcessedDisclosureStatsSettings: {
        ...state.requestsProcessedDisclosureStatsSettings,
        ...payload,
      } as RequestsProcessedDisclosureStatsSettings,
    }),
    // Update the settings in the store
    setRequestsProcessedDisclosureStatsSettings: (
      state,
      {
        payload,
      }: PayloadAction<Partial<RequestsProcessedDisclosureStatsSettings>>,
    ) => ({
      ...state,
      newRequestsProcessedDisclosureStatsSettings: {
        ...state.newRequestsProcessedDisclosureStatsSettings,
        ...payload,
      } as RequestsProcessedDisclosureStatsSettings,
    }),
    setSingleOverride: (
      state,
      {
        payload,
      }: PayloadAction<
        Omit<RequestsProcessedDisclosureStatOverrideInput, 'statValue'> & {
          /** Optional stat value */
          statValue?: number;
        }
      >,
    ) => {
      // Remove duplicate/s from array
      const dedupedOverrides =
        state.newRequestsProcessedDisclosureStatsSettings.valueOverrides.filter(
          (override) =>
            !(
              override.year === payload.year &&
              override.statType === payload.statType &&
              override.actionType === payload.actionType
            ),
        );

      return {
        ...state,
        newRequestsProcessedDisclosureStatsSettings: {
          ...state.newRequestsProcessedDisclosureStatsSettings,
          // Un-set override if stat value is empty
          valueOverrides: payload.statValue
            ? [...dedupedOverrides, payload]
            : dedupedOverrides,
        } as RequestsProcessedDisclosureStatsSettings,
      };
    },
  },
});
/* eslint-enable max-lines */
