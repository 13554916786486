import { InlineTextInput } from '@main/ad-core-components';
import { AttributeKeyType } from '@transcend-io/privacy-types';
import React from 'react';

import { ATTRIBUTE_KEY_TEXT_TYPES } from '../../constants';
import {
  AttributeKeyPreview,
  AttributeValuePreview,
  SelectAttribute,
  SelectAttributeDefaultOption,
} from '../SelectAttribute';

/** Props for AttributeInput component */
export interface AttributeInputProps {
  /** Attribute key to edit */
  attributeKey: AttributeKeyPreview;
  /** Values */
  values: string | AttributeValuePreview[];
  /** Callback to change the values */
  setValue: (values: string | AttributeValuePreview[]) => void;
  /** Callback to fire on blur */
  onBlur: () => void;
  /** Whether the Select or Text Input should have the outline shown */
  showOutline?: boolean;
  /** Whether the input should be displayed as readonly */
  readOnly?: boolean;
}

/**
 * Component to conditional display/edit attributes based off of attribute key type
 */
export const AttributeInput: React.FC<AttributeInputProps> = ({
  attributeKey,
  values,
  setValue,
  onBlur,
  showOutline,
  readOnly,
}) => {
  if (ATTRIBUTE_KEY_TEXT_TYPES.includes(attributeKey.type)) {
    return (
      <InlineTextInput
        showOutline={showOutline}
        value={values as string}
        showPlaceholder={false}
        onChange={(e: any) => {
          setValue(e.target.value);
        }}
        onBlur={onBlur}
        readOnly={readOnly}
      />
    );
  }

  return (
    <SelectAttribute
      showOutline={showOutline}
      values={values as SelectAttributeDefaultOption[]}
      attributeKey={attributeKey}
      isMulti={attributeKey.type === AttributeKeyType.MultiSelect}
      onUpdate={(selectedValues) =>
        setValue(selectedValues as AttributeValuePreview[])
      }
      onBlur={onBlur}
      disabled={readOnly}
    />
  );
};
