import 'antd/lib/switch/style/index';

import { SwitchProps as AntSwitchProps } from 'antd/lib/switch';
import React, { useState } from 'react';

import { Popconfirm, PopconfirmProps } from '../Popconfirm';
import { StyledAntSwitch } from './wrappers';

/**
 * Props for Switch
 */
export interface SwitchProps extends AntSwitchProps {
  /** The validation status (not passed) */
  validateStatus?: string;
  /** Form id */
  id?: string;
  /** props for the Popconfirm */
  confirmProps?: Omit<PopconfirmProps, 'onConfirm' | 'onCancel'>;
  /** Show confirm when turning on */
  showConfirmWhenTurningOn?: boolean;
  /** Show confirm when turning off */
  showConfirmWhenTurningOff?: boolean;
  /** the html name */
  name?: string;
  /** if the component is controlled by the onChange we don't want to mess with the internal checked state */
  disableCheckOverride?: boolean;
}

/**
 * Switch toggle, will replace the definition above once we remove the rest of
 * the old forms
 */
export const Switch: React.FC<SwitchProps> = ({
  onChange,
  confirmProps,
  showConfirmWhenTurningOff,
  showConfirmWhenTurningOn,
  disableCheckOverride,
  ...rest
}) => {
  const [checkOverride, setCheckOverride] = useState<boolean>();
  const [onChangeEvent, setOnChangeEvent] = useState<MouseEvent>();

  const showConfirm =
    confirmProps &&
    ((rest.checked && showConfirmWhenTurningOff) ||
      (!rest.checked && showConfirmWhenTurningOn));

  return (
    <Popconfirm
      {...confirmProps}
      onConfirm={() => {
        onChange?.(!!checkOverride, onChangeEvent!);
        setCheckOverride(undefined);
        setOnChangeEvent(undefined);
      }}
      onCancel={() => {
        setCheckOverride(undefined);
        setOnChangeEvent(undefined);
      }}
      disabled={!showConfirm}
    >
      {/* @deprecated TODO: https://transcend.height.app/T-19886 - remove ant */}
      <StyledAntSwitch
        {...rest}
        checked={checkOverride ?? rest.checked}
        onChange={
          showConfirm
            ? (val, event) => {
                // if the component is controlled by the onChange we don't want to mess with the checked state
                if (!disableCheckOverride) setCheckOverride(val);
                setOnChangeEvent(event);
              }
            : onChange
        }
        aria-busy={rest.loading ? 'true' : 'false'}
      />
    </Popconfirm>
  );
};
