import styled from 'styled-components';

/**
 * Optional container for the CodeBlock component
 */
export const CodeBlockContainer = styled.div`
  overflow-y: auto;
  border-radius: 12px;
  border: 1px solid ${({ theme }) => theme.colors.transcendNavy4};
  padding: 1em;
`;
