import {
  Button,
  commonButtonMessages,
  FormSubmitButton,
} from '@main/ad-core-components';
import {
  FlexRowCenterBoth,
  Form,
  Progress,
  StyleUtils,
  useForm,
} from '@main/core-ui';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { useTheme } from 'styled-components';

import { AssigneesStep } from './AssigneesStep';
import { useRequestAssessmentFromInventorySubmit } from './helpers';
import { requestAssessmentFromInventoryMessages } from './messages';
import { TitleAndColumnsStep } from './TitleAndColumnsStep';
import {
  RequestAssessmentFromInventoryFormProps,
  RequestAssessmentFromInventoryFormValues,
} from './types';

const TOTAL_STEPS = 2;

/**
 * Component to request an assessment based on fields from an inventory table
 */
export function RequestAssessmentFromInventoryForm<D extends object>({
  columns,
  nativeColumnHeaders,
  nativeColumnDescriptions,
  syncModel,
  selectedRows,
  setAssessmentGroupId,
  columnNameToSyncOptionMapping,
  attributeColumnHeaders,
}: RequestAssessmentFromInventoryFormProps<D>): JSX.Element {
  const { formatMessage } = useIntl();
  const theme = useTheme();

  const formMethods = useForm<RequestAssessmentFromInventoryFormValues>({
    shouldUnregister: false,
    defaultValues: {
      rowAssignees: selectedRows.map(({ id, title }) => ({
        rowId: id,
        rowTitle: title,
        assignees: [],
      })),
    },
  });

  const watchedTitle = formMethods.watch('title');
  const watchedColumnIds = formMethods.watch('columnIds');

  // 1-indexed, not 0-indexed
  const [currentStep, setCurrentStep] = useState(1);

  const { handleSubmit, loading } = useRequestAssessmentFromInventorySubmit({
    getValues: formMethods.getValues,
    nativeColumnHeaders,
    nativeColumnDescriptions,
    syncModel,
    selectedRows,
    setAssessmentGroupId,
    columnNameToSyncOptionMapping,
    attributeColumnHeaders,
  });

  return (
    <Form useFormMethods={formMethods} onSubmit={handleSubmit}>
      <div
        style={{
          color: theme.colors.transcendNavy3,
          fontWeight: 600,
          marginBottom: StyleUtils.Spacing.l,
        }}
      >
        {/* Step counter/label */}
        {formatMessage(requestAssessmentFromInventoryMessages.progress, {
          currentStep,
          totalSteps: TOTAL_STEPS,
        })}

        <Progress value={currentStep} max={TOTAL_STEPS} />
      </div>

      {/* Step 1 - Choose columns */}
      {currentStep === 1 && (
        <TitleAndColumnsStep
          columns={columns}
          nativeColumnHeaders={nativeColumnHeaders}
          columnNameToSyncOptionMapping={columnNameToSyncOptionMapping}
          attributeColumnHeaders={attributeColumnHeaders}
        />
      )}

      {/* Step 2 - Assign users */}
      {currentStep === 2 && <AssigneesStep />}

      {/* Buttons */}
      <FlexRowCenterBoth style={{ gap: StyleUtils.Spacing.md }}>
        {/*  Back button */}
        {currentStep > 1 && (
          <Button
            variant="secondary"
            onClick={() => setCurrentStep((step) => step - 1)}
          >
            {formatMessage(commonButtonMessages.back)}
          </Button>
        )}

        {/* Next button */}
        {currentStep < TOTAL_STEPS && (
          <Button
            onClick={() => setCurrentStep((step) => step + 1)}
            disabled={
              !watchedTitle ||
              !watchedColumnIds ||
              watchedColumnIds.length === 0
            }
          >
            {formatMessage(commonButtonMessages.next)}
          </Button>
        )}

        {/* Submit button */}
        {currentStep === TOTAL_STEPS && (
          <FormSubmitButton loading={loading}>
            {formatMessage(requestAssessmentFromInventoryMessages.buttonLabel)}
          </FormSubmitButton>
        )}
      </FlexRowCenterBoth>
    </Form>
  );
}
