import { AuditEventCode } from '@main/audit-types';
import { AuditEventFiltersInput } from '@main/audit-types/src/schema';
import React from 'react';

import { FilterManager } from '../../FilterManager';
import { auditTrailFilterMessages } from './messages';
import {
  AdditionalFilterOptions,
  useAuditTrailFilters,
} from './useAuditTrailFilters';

export interface AuditTrailFilterProps {
  /** Codes permitted for this audit trail */
  allowedCodes: AuditEventCode[];
  /** The currently applied filters */
  filters: AuditEventFiltersInput | undefined;
  /** Callback when the filters are changed */
  setFilters: (filters: AuditEventFiltersInput) => void;
  /** Filter configuration for additional properties to filter on for this audit trail */
  additionalFilters?: AdditionalFilterOptions;
}

export const AuditTrailFilter: React.FC<AuditTrailFilterProps> = ({
  allowedCodes,
  filters,
  setFilters,
  additionalFilters,
}) => {
  const filterManagerProps = useAuditTrailFilters({
    allowedCodes,
    filters,
    setFilters,
    ...additionalFilters,
  });

  return (
    <FilterManager
      id="audit-trail-filter"
      placeholder={auditTrailFilterMessages.placeholder}
      {...filterManagerProps}
    />
  );
};
