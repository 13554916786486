import {
  AssessmentQuestionSubType,
  AssessmentQuestionType,
  AssessmentSyncModel,
} from '@transcend-io/privacy-types';

import {
  ASSESSMENT_SELECT_QUESTION_TYPE_TO_SYNC_COLUMNS,
  ASSESSMENT_SELECT_QUESTION_TYPE_TO_SYNC_MODELS,
  ASSESSMENT_TEXT_QUESTION_TYPE_TO_SYNC_COLUMNS,
  AssessmentQuestionInput,
} from '@main/datamap-types';

import { ColumnNameToSyncOptionMapping } from '../types';

export const getQuestionConfigForColumn = (
  columnName: string,
  modelName: AssessmentSyncModel,
  columnNameToSyncOptionMapping: ColumnNameToSyncOptionMapping,
): Pick<
  AssessmentQuestionInput,
  'type' | 'syncModel' | 'syncColumn' | 'subType'
> | null => {
  const syncOption = columnNameToSyncOptionMapping[columnName];

  // Long answer question
  if (
    (
      ASSESSMENT_TEXT_QUESTION_TYPE_TO_SYNC_COLUMNS[
        // Check long answer before short since short answer is inclusive of short
        AssessmentQuestionType.LongAnswer
      ][modelName] || []
    ).includes(syncOption)
  ) {
    return {
      type: AssessmentQuestionType.LongAnswer,
      syncModel: modelName,
      syncColumn: syncOption,
    };
  }

  // Short answer question
  if (
    (
      ASSESSMENT_TEXT_QUESTION_TYPE_TO_SYNC_COLUMNS[
        AssessmentQuestionType.ShortAnswer
      ][modelName] || []
    ).includes(syncOption)
  ) {
    return {
      type: AssessmentQuestionType.ShortAnswer,
      syncModel: modelName,
      syncColumn: syncOption,
    };
  }

  // Static multi-select question
  const staticMultiSelectSubType = Object.entries(
    ASSESSMENT_SELECT_QUESTION_TYPE_TO_SYNC_COLUMNS[
      AssessmentQuestionType.MultiSelect
    ],
  ).filter(([, columnsByModelName]) =>
    (columnsByModelName[modelName] || []).includes(syncOption),
  )[0]?.[0]; // the second 0 index is the subType

  if (staticMultiSelectSubType) {
    return {
      type: AssessmentQuestionType.MultiSelect,
      syncModel: modelName,
      syncColumn: syncOption,
      subType: staticMultiSelectSubType as AssessmentQuestionSubType,
    };
  }

  // Static single select question
  const staticSingleSelectSubType = Object.entries(
    ASSESSMENT_SELECT_QUESTION_TYPE_TO_SYNC_COLUMNS[
      AssessmentQuestionType.SingleSelect
    ],
  ).filter(([, columnsByModelName]) =>
    (columnsByModelName[modelName] || []).includes(syncOption),
  )[0]?.[0];

  if (staticSingleSelectSubType) {
    return {
      type: AssessmentQuestionType.SingleSelect,
      syncModel: modelName,
      syncColumn: syncOption,
      subType: staticSingleSelectSubType as AssessmentQuestionSubType,
    };
  }

  // Dynamic multi-select question
  if (
    (
      ASSESSMENT_SELECT_QUESTION_TYPE_TO_SYNC_MODELS[
        AssessmentQuestionType.MultiSelect
      ][syncOption] || []
    ).includes(modelName)
  ) {
    return {
      type: AssessmentQuestionType.MultiSelect,
      syncModel: modelName,
      subType: syncOption,
    };
  }

  // Dynamic single select question
  if (
    (
      ASSESSMENT_SELECT_QUESTION_TYPE_TO_SYNC_MODELS[
        AssessmentQuestionType.SingleSelect
      ][syncOption] || []
    ).includes(modelName)
  ) {
    return {
      type: AssessmentQuestionType.SingleSelect,
      syncModel: modelName,
      subType: syncOption,
    };
  }

  return null;
};
