import styled from 'styled-components';

import { FlexRowCenterVertical, StyleUtils } from '@main/core-ui';

import { InlineTextInput } from '../InlineTextInput';

export const StyledInlineTextInput = styled(InlineTextInput)`
  :hover,
  :focus,
  :active {
    & + .url-input-buttons {
      opacity: 1;
    }
  }
`;

export const ButtonWrapper = styled(FlexRowCenterVertical)`
  opacity: 0;
  transition: opacity 0.2s;
  gap: ${StyleUtils.Spacing.xs};
  :hover,
  :focus,
  :active {
    opacity: 1;
  }
`;
