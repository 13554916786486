/* eslint-disable max-lines */
import { LargeLanguageModelClient } from '@transcend-io/privacy-types';

import { TeamPreview, UserPreview } from '@main/access-control-types';
import { AgentPreview, PromptPreview } from '@main/attribute-types';
import {
  CodePackagePreview,
  RepositoryPreview,
} from '@main/code-scanning-types';
import { mkInput, mkOrder, mkType, SchemaToType } from '@main/schema-utils';

import { AgentFilePreview } from './agentFile';
import { AgentFunctionInput, AgentFunctionPreview } from './agentFunction';
import { ApplicationPreview } from './application';
import { ApplicationUserPreview } from './appUser';
import { AgentOrderField } from './enums';
import { LargeLanguageModelPreview } from './largeLanguageModel';
import { PathfinderPreview } from './pathfinder';
import { ReportPromptRunInput } from './promptRun';

export const DeleteAgentsInput = mkInput({
  name: 'DeleteAgentsInput',
  comment: 'Input for deleting agents',
  fields: {
    ids: {
      comment: 'The id of the agents to delete',
      type: 'id',
      modelName: 'agent',
      list: true,
      optional: true,
    },
    agentIds: {
      comment:
        'The id of the agents in the remote system, e.g. the OpenAI assistant ID',
      type: 'string',
      list: true,
      optional: true,
    },
    applicationId: ReportPromptRunInput.fields.applicationId,
    applicationName: ReportPromptRunInput.fields.applicationName,
    codePackageId: ReportPromptRunInput.fields.codePackageId,
    codePackageName: ReportPromptRunInput.fields.codePackageName,
    repositoryId: ReportPromptRunInput.fields.repositoryId,
    repositoryName: ReportPromptRunInput.fields.repositoryName,
    applicationUserCoreIdentifier:
      ReportPromptRunInput.fields.applicationUserCoreIdentifier,
    applicationUserName: ReportPromptRunInput.fields.applicationUserName,
    pathfinderId: ReportPromptRunInput.fields.pathfinderId,
    pathfinderName: ReportPromptRunInput.fields.pathfinderName,
  },
});

/** Override type */
export type DeleteAgentsInput = SchemaToType<typeof DeleteAgentsInput>;

export const UpdateAgentInput = mkInput({
  name: 'UpdateAgentInput',
  comment: 'Input for updating an agent',
  fields: {
    id: {
      comment: 'The id of the agent. Either this or agentId must be provided',
      modelName: 'agent',
      type: 'id',
      optional: true,
    },
    agentId: {
      comment:
        'The ID of the agent in the remote system. Either this or id must be provided',
      type: 'string',
      optional: true,
    },
    name: {
      comment: 'The new name of the agent',
      type: 'string',
      optional: true,
    },
    description: {
      comment: 'The new description of the agent',
      type: 'string',
      optional: true,
    },
    instructions: {
      comment: 'The instructions of the agent',
      type: 'string',
      optional: true,
    },
    codeInterpreterEnabled: {
      comment: 'Whether agent has code interpreter enabled',
      type: 'boolean',
      optional: true,
    },
    promptId: {
      comment: 'The ID of the prompt to use for this agent',
      modelName: 'prompt',
      type: 'id',
      optional: true,
    },
    promptTitle: {
      comment:
        'The title of the prompt to use for this agent. This or promptId must be specified.',
      type: 'string',
      optional: true,
    },
    largeLanguageModelId: {
      comment: 'The ID of the large language model to use for this agent',
      modelName: 'largeLanguageModel',
      type: 'id',
      optional: true,
    },
    largeLanguageModelName: {
      comment:
        'The name of the large language model that the agent is based on',
      type: 'string',
      optional: true,
    },
    largeLanguageModelClient: {
      comment:
        'The client of the large language model that the agent is based on',
      type: { LargeLanguageModelClient },
      optional: true,
    },
    retrievalEnabled: {
      comment: 'Whether agent has code retrieval enabled',
      type: 'boolean',
      optional: true,
    },
    ownerIds: {
      comment: 'The unique ids of the users to assign as owners of this agent',
      modelName: 'user',
      type: 'id',
      optional: true,
      list: true,
    },
    ownerEmails: {
      comment: 'The emails of user that manage the agent',
      type: 'string',
      list: true,
      optional: true,
    },
    teamIds: {
      comment: 'The ids of the teams that should be responsible for this agent',
      type: 'id',
      modelName: 'team',
      list: true,
      optional: true,
    },
    teamNames: {
      comment: 'The names of the teams that maintain the agent',
      type: 'string',
      list: true,
      optional: true,
    },
    agentFileIds: {
      comment: 'The unique ids of the files to assign to this agent',
      modelName: 'agentFile',
      type: 'id',
      optional: true,
      list: true,
    },
    fileIds: {
      comment: 'The IDs of the files to assign to this agent',
      type: 'string',
      optional: true,
      list: true,
    },
    agentFunctionIds: {
      comment: 'The unique ids of the functions to assign to this agent',
      modelName: 'agentFunction',
      type: 'id',
      optional: true,
      list: true,
    },
    agentFunctions: {
      comment: 'The agent function definitions for the agent',
      type: AgentFunctionInput,
      optional: true,
      list: true,
    },
    applicationId: ReportPromptRunInput.fields.applicationId,
    applicationName: ReportPromptRunInput.fields.applicationName,
    codePackageId: ReportPromptRunInput.fields.codePackageId,
    codePackageName: ReportPromptRunInput.fields.codePackageName,
    repositoryId: ReportPromptRunInput.fields.repositoryId,
    repositoryName: ReportPromptRunInput.fields.repositoryName,
    applicationUserCoreIdentifier:
      ReportPromptRunInput.fields.applicationUserCoreIdentifier,
    applicationUserName: ReportPromptRunInput.fields.applicationUserName,
    pathfinderName: ReportPromptRunInput.fields.pathfinderName,
    pathfinderId: ReportPromptRunInput.fields.pathfinderId,
  },
});

/** Override type */
export type UpdateAgentInput = SchemaToType<typeof UpdateAgentInput>;

export const CreateAgentInput = mkInput({
  name: 'CreateAgentInput',
  comment: 'Input for creating an agent',
  fields: {
    name: {
      comment: 'The name of the agent',
      type: 'string',
    },
    agentId: {
      comment: 'The ID of the agent in the remote system',
      type: 'string',
    },
    instructions: {
      comment: 'The instructions of the agent',
      type: 'string',
    },
    description: {
      comment: 'The description of the agent',
      type: 'string',
      optional: true,
    },
    codeInterpreterEnabled: {
      comment: 'Whether agent has code interpreter enabled',
      type: 'boolean',
    },
    retrievalEnabled: {
      comment: 'Whether agent has code retrieval enabled',
      type: 'boolean',
    },
    promptId: {
      comment:
        'The ID of the prompt to use for this agent. This or promptTitle must be specified.',
      modelName: 'prompt',
      type: 'id',
      optional: true,
    },
    promptTitle: {
      comment:
        'The title of the prompt to use for this agent. This or promptId must be specified.',
      type: 'string',
      optional: true,
    },
    largeLanguageModelId: {
      comment:
        'The ID of the large language model to use for this agent. ' +
        'This or largeLanguageModelName & largeLanguageModelVersion must be specified.',
      modelName: 'largeLanguageModel',
      type: 'id',
      optional: true,
    },
    largeLanguageModelName: {
      comment:
        'The name of the large language model that the agent is based on',
      type: 'string',
      optional: true,
    },
    largeLanguageModelClient: {
      comment:
        'The client of the large language model that the agent is based on',
      type: { LargeLanguageModelClient },
      optional: true,
    },
    ownerIds: {
      comment: 'The unique ids of the users to assign as owners of this agent',
      modelName: 'user',
      type: 'id',
      optional: true,
      list: true,
    },
    ownerEmails: {
      comment: 'The emails of user that manage the agent',
      type: 'string',
      list: true,
      optional: true,
    },
    teamIds: {
      comment: 'The ids of the teams that should be responsible for this agent',
      type: 'id',
      modelName: 'team',
      list: true,
      optional: true,
    },
    teamNames: {
      comment: 'The names of the teams that maintain the agent',
      type: 'string',
      list: true,
      optional: true,
    },
    fileIds: {
      comment: 'The IDs of the files to assign to this agent',
      type: 'string',
      optional: true,
      list: true,
    },
    agentFileIds: {
      comment: 'The unique ids of the files to assign to this agent',
      modelName: 'agentFile',
      type: 'id',
      optional: true,
      list: true,
    },
    agentFunctionIds: {
      comment: 'The unique ids of the functions to assign to this agent',
      modelName: 'agentFunction',
      type: 'id',
      optional: true,
      list: true,
    },
    agentFunctions: {
      comment: 'The agent function definitions for the agent',
      type: AgentFunctionInput,
      optional: true,
      list: true,
    },
    applicationId: ReportPromptRunInput.fields.applicationId,
    applicationName: ReportPromptRunInput.fields.applicationName,
    codePackageId: ReportPromptRunInput.fields.codePackageId,
    codePackageName: ReportPromptRunInput.fields.codePackageName,
    repositoryId: ReportPromptRunInput.fields.repositoryId,
    repositoryName: ReportPromptRunInput.fields.repositoryName,
    runByEmployeeEmail: ReportPromptRunInput.fields.runByEmployeeEmail,
    applicationUserCoreIdentifier:
      ReportPromptRunInput.fields.applicationUserCoreIdentifier,
    applicationUserName: ReportPromptRunInput.fields.applicationUserName,
    pathfinderId: ReportPromptRunInput.fields.pathfinderId,
    pathfinderName: ReportPromptRunInput.fields.pathfinderName,
  },
});

/** Override type */
export type CreateAgentInput = SchemaToType<typeof CreateAgentInput>;

export const Agent = mkType({
  name: 'Agent',
  comment: 'An LLM-based agent, capable of performing tasks autonomously',
  fields: {
    ...AgentPreview.fields,
    description: {
      comment: 'The description of the agent',
      type: 'string',
    },
    instructions: {
      comment: 'The instructions of the agent',
      type: 'string',
    },
    updatedAt: {
      comment: 'The date the prompt was last updated',
      type: 'Date',
    },
    codeInterpreterEnabled: {
      comment: 'Whether agent has code interpreter enabled',
      type: 'boolean',
    },
    retrievalEnabled: {
      comment: 'Whether agent has code retrieval enabled',
      type: 'boolean',
    },
    promptRunCount: {
      comment: 'The number of prompt runs by this agent',
      type: 'int',
    },
    prompt: {
      comment: 'The prompt that the agent uses for instructions',
      type: PromptPreview,
      optional: true,
    },
    codePackage: {
      comment: 'The code package that created the agent',
      type: CodePackagePreview,
      optional: true,
    },
    repository: {
      comment: 'The repository that created the agent',
      type: RepositoryPreview,
      optional: true,
    },
    creator: {
      comment: 'The user that created the agent',
      type: UserPreview,
      optional: true,
    },
    largeLanguageModel: {
      comment: 'The LLM that the agent is based on',
      type: LargeLanguageModelPreview,
      optional: true,
    },
    teams: {
      comment: 'The team/s that the prompt is assigned to',
      type: TeamPreview,
      list: true,
    },
    owners: {
      comment: 'The user/s that the prompt is assigned to',
      type: UserPreview,
      list: true,
    },
    applications: {
      comment: 'The applications that have interacted with the agent',
      type: () => ApplicationPreview,
      list: true,
    },
    pathfinders: {
      comment: 'The pathfinders that have interacted with the agent',
      type: () => PathfinderPreview,
      list: true,
    },
    applicationUsers: {
      comment: 'The application users that have interacted with the agent',
      type: () => ApplicationUserPreview,
      list: true,
    },
    agentFunctions: {
      comment: 'The functions assigned to the agent',
      type: AgentFunctionPreview,
      list: true,
    },
    agentFiles: {
      comment: 'The files assigned to the agent',
      type: AgentFilePreview,
      list: true,
    },
  },
});

/** Override type */
export type Agent = SchemaToType<typeof Agent>;

export const AgentFiltersInput = mkInput({
  name: 'AgentFiltersInput',
  comment: 'Inputs for filtering a list of agents',
  fields: {
    ids: {
      comment: 'The ids of the agents',
      type: 'id',
      modelName: 'agent',
      optional: true,
      list: true,
    },
    promptIds: {
      comment: 'The ids of the prompts',
      type: 'id',
      modelName: 'prompt',
      optional: true,
      list: true,
    },
    text: {
      comment: 'Filter by text (name of the agent)',
      optional: true,
      type: 'string',
    },
    names: {
      comment: 'Filter by the names of the agents',
      optional: true,
      type: 'string',
      list: true,
    },
    agentIds: {
      comment: 'Filter by the agent ids',
      optional: true,
      type: 'string',
      list: true,
    },
    largeLanguageModelIds: {
      comment: 'The ids of the large language models',
      type: 'id',
      modelName: 'largeLanguageModel',
      optional: true,
      list: true,
    },
    ownerIds: {
      comment: 'Filter by the assigned owner IDs',
      optional: true,
      type: 'id',
      modelName: 'user',
      list: true,
    },
    teamIds: {
      comment: 'Filter by the assigned team IDs',
      optional: true,
      type: 'id',
      modelName: 'team',
      list: true,
    },
    agentFileIds: {
      comment: 'Filter by agent file IDs',
      modelName: 'agentFile',
      type: 'id',
      optional: true,
      list: true,
    },
    agentFunctionIds: {
      comment: 'Filter by agent function IDs',
      modelName: 'agentFunction',
      type: 'id',
      optional: true,
      list: true,
    },
  },
});

/** Override type */
export type AgentFiltersInput = SchemaToType<typeof AgentFiltersInput>;

export const UpdateAgentsInput = mkInput({
  name: 'UpdateAgentsInput',
  comment: 'Input for bulk updating one or more agents',
  fields: {
    agents: {
      comment:
        'List of agents with the properties that should be updated for each',
      type: UpdateAgentInput,
      list: true,
    },
  },
});

/** Override type */
export type UpdateAgentsInput = SchemaToType<typeof UpdateAgentsInput>;

/** Order for a prompt partials query */
export const AgentOrder = mkOrder(Agent.name, AgentOrderField);
/* eslint-enable max-lines */
