import React, { ChangeEventHandler } from 'react';

import { RangeLabel, StyledRangeInput } from './wrappers';

export interface IRangeProps {
  /** the minimum value */
  min: number;
  /** the label for the minimum value */
  minLabel?: string;
  /** the maximum value */
  max: number;
  /** the label for the maximum value */
  maxLabel?: string;
  /** the value */
  value?: number;
  /** the callback to fire when value changes */
  onChange?: ChangeEventHandler<HTMLInputElement>;
}

export const Range = ({
  minLabel,
  maxLabel,
  ...props
}: IRangeProps): JSX.Element => (
  <>
    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
      <RangeLabel>{minLabel ?? props.min}</RangeLabel>
      <RangeLabel>{maxLabel ?? props.max}</RangeLabel>
    </div>
    <StyledRangeInput type="range" {...props} />
  </>
);
