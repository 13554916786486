import * as t from 'io-ts';

import { dbModelId } from '@main/schema-utils';

import { AuditEventCode } from '../schema/enums';
import { mkAuditEventCodec } from '../types';

export const AuditEventPreference = mkAuditEventCodec(
  AuditEventCode.Preference,
  t.type({
    /** ID of the preference */
    preferenceId: dbModelId('preference'),
  }),
);

/** Override types. */
export type AuditEventPreference = t.TypeOf<typeof AuditEventPreference>;
