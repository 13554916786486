import { defineMessages } from '@transcend-io/internationalization';

export const privacyRequestsPageTitleMessages = defineMessages(
  'admin-dashboard.PrivacyRequests.privacyRequestsPageTitle',
  {
    dsrAutomation: {
      defaultMessage: 'DSR Automation',
    },
    dashboardMenuLabel: {
      defaultMessage: 'Dashboard',
    },
    dashboardPageTitle: {
      defaultMessage: 'DSR Automation Dashboard',
    },
    workflows: {
      defaultMessage: 'Workflows',
    },
    workflowsNew: {
      defaultMessage: 'Workflow Configuration',
    },
    configuration: {
      defaultMessage: 'Configuration',
    },
    dataPoints: {
      defaultMessage: 'Datapoints',
    },
    integrations: {
      defaultMessage: 'Integrations',
    },
    dataSubjects: {
      defaultMessage: 'Data Subjects',
    },
    requestTypes: {
      defaultMessage: 'Request Types',
    },
    identifiers: {
      defaultMessage: 'Identifiers',
    },
    enrichers: {
      defaultMessage: 'Enrichers & Preflight',
    },
    incomingRequests: {
      defaultMessage: 'Incoming Requests',
    },
    privacyCenter: {
      defaultMessage: 'Privacy Center',
    },
    legalHolds: {
      defaultMessage: 'Legal Holds',
    },
    legalMatters: {
      defaultMessage: 'Legal Matters',
    },
    requestSettings: {
      defaultMessage: 'Request Settings',
    },
    dataActions: {
      defaultMessage: 'Data Actions',
    },
    dataRetention: {
      defaultMessage: 'Data Retention',
    },
    serviceConfiguration: {
      defaultMessage: 'Configure Integration',
    },
    bulkRespond: {
      defaultMessage: 'Bulk Respond',
    },
    emailSettings: {
      defaultMessage: 'Email Settings',
    },
  },
);
