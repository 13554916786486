import {
  endpoints,
  TeamOrderField,
  TeamPreview,
} from '@main/access-control-types';
import { buildUseInfiniteScroll, Icon } from '@main/core-ui';
import { createNewId, OrderDirection } from '@main/schema-utils';
import React, { useState } from 'react';
import { MenuPosition, OptionsType } from 'react-select';

import { PaginatedSelect, PaginatedSelectProps } from '../PaginatedSelect';
import { selectTeamsMessages } from './messages';

export const SELECT_TEAMS_NODES = {
  id: null,
  name: null,
} as const;

/**
 * Selected team
 */
export type SelectedTeam = Pick<
  TeamPreview,
  keyof typeof SELECT_TEAMS_NODES
> & {
  /** Whether team is newly created team or existing team */
  isNew?: boolean;
};

const useSelectTeams = buildUseInfiniteScroll(endpoints.teams, 'SelectTeams', {
  nodes: SELECT_TEAMS_NODES,
});

export interface SelectTeamProps
  extends Omit<
    PaginatedSelectProps<SelectedTeam, true>,
    'isQueryLoading' | 'queryError' | 'fetchMore' | 'onEndsTyping'
  > {
  /** On change handler */
  onChange?: (teams: OptionsType<SelectedTeam>) => void;
  /** Show option to create team */
  allowCreate?: boolean;
  /** How to position the menu (see docs for React Select) */
  menuPosition?: MenuPosition;
}

/**
 * Select a specific team
 */
export const SelectTeams: React.FC<SelectTeamProps> = ({
  allowCreate = false,
  placeholderDescriptor = selectTeamsMessages.placeholder,
  showOutline = true,
  menuPosition = 'fixed',
  ...paginatedSelectProps
}) => {
  const [searchText, setSearchText] = useState('');
  const { data, loading, error, fetchMore } = useSelectTeams({
    variables: {
      filterBy: {
        text: searchText,
      },
      orderBy: [{ field: TeamOrderField.Name, direction: OrderDirection.ASC }],
    },
    fetchPolicy: 'cache-and-network',
  });

  return (
    <PaginatedSelect
      id="select-teams"
      showOutline={showOutline}
      placeholderDescriptor={placeholderDescriptor}
      options={data?.nodes ?? []}
      isQueryLoading={loading}
      isCreatable={allowCreate}
      isMulti
      menuPosition={menuPosition}
      getOptionValue={({ id }: SelectedTeam) => id}
      getOptionLabel={({ name }: SelectedTeam) => name}
      queryError={error}
      getOptionLogo={({ isNew }: SelectedTeam) =>
        isNew ? <Icon type="circle-add" /> : ''
      }
      getNewOptionData={(inputValue) => ({
        isNew: true as const,
        name: inputValue,
        id: createNewId<'team'>(),
      })}
      fetchMore={fetchMore}
      onEndsTyping={setSearchText}
      {...paginatedSelectProps}
    />
  );
};
