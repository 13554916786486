import intersection from 'lodash/intersection';
import isEqual from 'lodash/isEqual';
import sortBy from 'lodash/sortBy';

import {
  AssessmentQuestionSubType,
  AssessmentQuestionType,
} from '@transcend-io/privacy-types';
import { Requirize } from '@transcend-io/type-utils';

import {
  ASSESSMENT_SELECT_QUESTION_TYPE_TO_SYNC_COLUMNS,
  ASSESSMENT_SELECT_QUESTION_TYPE_TO_SYNC_MODELS,
  SYNC_MODEL_TO_SUB_TYPE,
} from '../constants';
import { AssessmentQuestion } from '../schema';
import {
  DynamicAssessmentQuestionSubType,
  StaticAssessmentQuestionSubType,
} from '../schema/enums';

/**
 * Whether the sub-type of an assessment question requires a dynamic answer
 * fetched from the data inventory.
 *
 * @param subType - the sub-type of the assessment question
 * @returns whether the sub-type is dynamic
 */
export const isDynamicAssessmentQuestionSubType = (
  subType?: AssessmentQuestionSubType,
): subType is DynamicAssessmentQuestionSubType =>
  !!subType && Object.keys(DynamicAssessmentQuestionSubType).includes(subType);

/**
 * Whether the sub-type of an assessment question requires a static answer
 * that does not need to be fetched from the data inventory.
 *
 * @param subType - the sub-type of the assessment question
 * @returns whether the sub-type is dynamic
 */
export const isStaticAssessmentQuestionSubType = (
  subType?: AssessmentQuestionSubType,
): subType is StaticAssessmentQuestionSubType =>
  !!subType && Object.keys(StaticAssessmentQuestionSubType).includes(subType);

const questionIsOnlySingleSelect = ({
  subType,
  syncModel,
  syncColumn,
}: Requirize<AssessmentQuestion, 'syncModel'>): boolean =>
  !(isDynamicAssessmentQuestionSubType(subType)
    ? (
        ASSESSMENT_SELECT_QUESTION_TYPE_TO_SYNC_MODELS[
          AssessmentQuestionType.MultiSelect
        ][subType] || []
      ).includes(syncModel)
    : (
        ASSESSMENT_SELECT_QUESTION_TYPE_TO_SYNC_COLUMNS[
          AssessmentQuestionType.MultiSelect
        ][subType][syncModel] || []
      ).includes(syncColumn));

const questionWillOverride = (
  question: Requirize<AssessmentQuestion, 'syncModel'>,
): boolean =>
  question.syncOverride ||
  question.type === AssessmentQuestionType.ShortAnswer ||
  question.type === AssessmentQuestionType.LongAnswer ||
  (question.type === AssessmentQuestionType.SingleSelect &&
    // A question that is only a single select cannot add more values and therefore will override any existing value
    questionIsOnlySingleSelect(question));

const questionHasSyncModel = (
  question: AssessmentQuestion,
): question is Requirize<AssessmentQuestion, 'syncModel'> =>
  !!question.syncModel;

/**
 *
 * @param currentQuestion - the question to evaluate for conflicts
 * @param questions - all questions in the form (not just the section!)
 * @returns a list of questions that conflict with the current question
 */
export const validateSyncRowSelections = (
  currentQuestion: AssessmentQuestion,
  questions: AssessmentQuestion[],
): AssessmentQuestion[] => {
  const conflictingQuestions: AssessmentQuestion[] = [];
  if (!questionHasSyncModel(currentQuestion)) {
    return [];
  }

  // Check if this questions sync options and selected answer(s) conflict with any other questions
  questions.forEach((question) => {
    if (question.id === currentQuestion.id || !questionHasSyncModel(question)) {
      return;
    }

    const sortedSelectedQuestionAnswers = sortBy(
      question.selectedAnswers.map(({ value }) => value),
    );
    const sortedSelectedCurrentQuestionAnswers = sortBy(
      currentQuestion.selectedAnswers.map(({ value }) => value),
    );

    // Questions of the same type can override each other
    if (
      question.subType === currentQuestion.subType &&
      question.syncModel === currentQuestion.syncModel &&
      question.syncColumn === currentQuestion.syncColumn &&
      question.attributeKey?.id === currentQuestion.attributeKey?.id &&
      (questionWillOverride(currentQuestion) ||
        questionWillOverride(question)) &&
      // The questions update the same rows
      intersection(question.syncRowIds, currentQuestion.syncRowIds).length >
        0 &&
      // The selected answers are different and will therefore conflict
      !isEqual(
        sortedSelectedQuestionAnswers,
        sortedSelectedCurrentQuestionAnswers,
      )
    ) {
      conflictingQuestions.push(question);
    }

    // Questions of opposite types can override each other.
    // ex. a data silo question synced to the business entity table could override a business entity question synced to the data silo table
    if (
      question.subType === SYNC_MODEL_TO_SUB_TYPE[currentQuestion.syncModel] &&
      SYNC_MODEL_TO_SUB_TYPE[question.syncModel] === currentQuestion.subType &&
      (questionWillOverride(currentQuestion) ||
        questionWillOverride(question)) &&
      // The questions update the same rows
      ((intersection(
        question.syncRowIds,
        currentQuestion.selectedAnswers.map(({ value }) => value),
      ).length > 0 &&
        // The selected answers are different and will therefore conflict
        !isEqual(
          sortedSelectedQuestionAnswers,
          sortBy(currentQuestion.syncRowIds),
        )) ||
        // The questions update the same rows
        (intersection(
          currentQuestion.syncRowIds,
          question.selectedAnswers.map(({ value }) => value),
        ).length > 0 &&
          // The selected answers are different and will therefore conflict
          !isEqual(
            sortedSelectedCurrentQuestionAnswers,
            sortBy(question.syncRowIds),
          )))
    ) {
      conflictingQuestions.push(question);
    }
  });

  return conflictingQuestions;
};
