import { defineMessages } from '@transcend-io/internationalization';

export const newSoftwareDevelopmentKitFormMessages = defineMessages(
  'admin-dashboard.CodeScanning.components.NewSoftwareDevelopmentKitForm.newSoftwareDevelopmentKitForm',
  {
    nameLabel: {
      defaultMessage: 'SDK name',
    },
    descriptionLabel: {
      defaultMessage: 'SDK description',
    },
    urlLabel: {
      defaultMessage: 'SDK URL',
    },
    codePackageTypeLabel: {
      defaultMessage: 'SDK type',
    },
    submitButton: {
      defaultMessage: 'Create SDK',
    },
    submitSuccess: {
      defaultMessage: 'New SDK created!',
    },
  },
);
