import { mkType, SchemaToType } from '@main/schema-utils';

// TODO: https://transcend.height.app/T-28717 - this file is duplicated because access-control-types should not import pc-types

export const AssetFile = mkType({
  name: 'AssetFile',
  comment:
    'An asset file uploaded by an organization to be displayed on their privacy center',
  fields: {
    key: {
      comment: 'The key where the file is stored',
      type: 'string',
    },
    size: {
      comment: 'The file size in bytes',
      type: 'int',
    },
    mimetype: {
      comment: 'The mimetype of the file',
      type: 'string',
    },
    id: {
      comment: 'The unique id of the asset file',
      modelName: 'assetFile',
      type: 'id',
    },
    src: {
      comment: 'The public url where the asset file can be downloaded',
      type: 'string',
    },
  },
});

/** Override type */
export type AssetFile = SchemaToType<typeof AssetFile>;
