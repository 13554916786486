import {
  SelectedTeam,
  SelectTeamProps,
  SelectTeams,
  useInlineInput,
} from '@main/ad-core-components';
import { endpoints } from '@main/code-scanning-types';
import { buildUseMutation } from '@main/core-ui';
import type { ID } from '@main/schema-utils';
import React from 'react';

interface InlineCodePackagesTeamsUpdateProps
  extends Omit<SelectTeamProps, 'onChange' | 'onBlur' | 'value'> {
  /** The ID of the parent code package for this cell */
  id: ID<'codePackage'>;
  /** The value for the cell */
  value: SelectedTeam[];
}

const useUpdateCodePackages = buildUseMutation(endpoints.updateCodePackages);

export const InlineCodePackagesTeamsUpdate: React.FC<
  InlineCodePackagesTeamsUpdateProps
> = ({ id, value: initialValue, isLoading, ...props }) => {
  const [updateCodePackages, { loading }] = useUpdateCodePackages();

  const { value, setValue, onBlur } = useInlineInput<SelectedTeam[] | null>({
    initialValue,
    onUpdate: (values) =>
      updateCodePackages({
        variables: {
          input: {
            codePackages: [
              {
                id,
                teamIds: (values || []).map(({ id }) => id),
              },
            ],
          },
        },
      }).then(({ data, errors }) => ({
        data: data?.codePackages[0]?.teams,
        errors,
      })),
  });

  return (
    <SelectTeams
      value={value || []}
      isLoading={loading || isLoading}
      onChange={(newValues) => {
        setValue([...newValues]);
      }}
      onBlur={onBlur}
      {...props}
    />
  );
};
