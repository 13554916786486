import { SoftwareDevelopmentKit } from '@main/code-scanning-types';
import type { ID } from '@main/schema-utils';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { CellProps, Column } from 'react-table';

import { ActionsCellWrapper, ActionsHeaderWrapper } from '../wrappers';
import { ApproveSdkButton } from './components';
import { triageSDKsMessages } from './messages';

interface ActionColumnProp {
  /** airgap bundle ID */
  airgapBundleId: ID<'airgapBundle'>;
}

export const ACTIONS_COLUMN = ({
  airgapBundleId,
}: ActionColumnProp): Column<SoftwareDevelopmentKit> => ({
  id: 'actions',
  Header: () => (
    <ActionsHeaderWrapper>
      <FormattedMessage {...triageSDKsMessages.actionsColumnHeader} />
    </ActionsHeaderWrapper>
  ),
  Cell: ({ row }: CellProps<SoftwareDevelopmentKit>) => {
    const { id, name, description, catalog } = row.original;

    return (
      <ActionsCellWrapper>
        <ApproveSdkButton
          airgapBundleId={airgapBundleId}
          slug={name}
          description={description}
          consentServiceCatalogName={catalog?.integrationName}
          softwareDevelopmentKitId={id}
        />
      </ActionsCellWrapper>
    );
  },
  minWidth: 232,
});
