import { EditorView } from '@tiptap/pm/view';

import { FileUploadOptions, useUploadFile } from '../utils/uploadFile';

interface RichEditorHandleDropOptions {
  /** The editor view which manages the DOM structure that represents an editable document. */
  view: EditorView;
  /** The event emitted by the drag action */
  event: DragEvent;
  /** Whether the file was moved within the editor or from outside it  */
  moved: boolean;
  /** Options to handle the file that is dropped */
  uploadOptions: FileUploadOptions;
}

export const useHandleFileDrop = (): ((
  opts: RichEditorHandleDropOptions,
) => boolean) => {
  const uploadFile = useUploadFile();

  const handleDrop = ({
    view,
    event,
    moved,
    uploadOptions,
  }: RichEditorHandleDropOptions): boolean => {
    if (!moved && event.dataTransfer?.files?.[0]) {
      const file = event.dataTransfer.files[0];

      // Handle an image file
      if (file.type.match(/^image\/.*/)) {
        const data = new FormData();
        data.append('file', file);

        uploadFile(file, uploadOptions).then(({ src }) => {
          // Place the now uploaded image in the editor where it was dropped
          const { schema } = view.state;
          const coordinates = view.posAtCoords({
            left: event.clientX,
            top: event.clientY,
          });

          // Create the image element
          const node = schema.nodes.image.create({ src });
          if (coordinates) {
            const transaction = view.state.tr.insert(coordinates.pos, node); // places it in the correct position
            view.dispatch(transaction);
          }
        });

        // File drop handled
        return true;
      }
    }

    // File drop not handled - use default handler
    return false;
  };

  return handleDrop;
};
