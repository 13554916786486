import { makeEnum } from '@transcend-io/type-utils';

export const PRIVACY_CENTER_PATH_PREFIX = '/privacy-center';

export const AdminDashboardPrivacyCenterPath = makeEnum({
  GeneralSettings: `${PRIVACY_CENTER_PATH_PREFIX}/general-settings`,
  Policies: `${PRIVACY_CENTER_PATH_PREFIX}/policies`,
  RequestsProcessedStats: `${PRIVACY_CENTER_PATH_PREFIX}/requests-processed-stats`,
  AssetFiles: `${PRIVACY_CENTER_PATH_PREFIX}/asset-files`,
  ColorsAndStyles: `${PRIVACY_CENTER_PATH_PREFIX}/colors-and-styles`,
  MessagesInternationalization: `${PRIVACY_CENTER_PATH_PREFIX}/messages-internationalization`,
  DataPractices: `${PRIVACY_CENTER_PATH_PREFIX}/data-practices`,
  AuditTrail: `${PRIVACY_CENTER_PATH_PREFIX}/audit-trail`,
  ActionItems: `${PRIVACY_CENTER_PATH_PREFIX}/action-items`,
  AuthenticationMethods: `${PRIVACY_CENTER_PATH_PREFIX}/authentication-methods`,
});

/** Overrides type */
export type AdminDashboardPrivacyCenterPath =
  (typeof AdminDashboardPrivacyCenterPath)[keyof typeof AdminDashboardPrivacyCenterPath];

/**
 * Path prefix for displaying PrivacyCenterCTABanner
 */
export const PRIVACY_CENTER_CTA_PATH_PREFIX =
  AdminDashboardPrivacyCenterPath.GeneralSettings;
