import { DefinedMessage, MenuTabInterface } from '@main/internationalization';
import { applyFields, mkInput, mkType, SchemaToType } from '@main/schema-utils';

import { DataApplicationMethod, DataApplicationStaticType } from './enums';

export const DataApplicationInput = mkInput({
  name: 'DataApplicationInput',
  comment: 'Input for creating a new data application',
  fields: {
    title: {
      comment: 'The title of the data application',
      type: 'string',
    },
    description: {
      comment: 'A description of what the data application means',
      type: 'string',
    },
    method: {
      comment: 'The method in which the data application is gathered',
      type: { DataApplicationMethod },
    },
  },
});

/** Override type */
export type DataApplicationInput = SchemaToType<typeof DataApplicationInput>;

export const UpdateDataApplicationInput = mkInput({
  name: 'UpdateDataApplicationInput',
  comment: 'Input for updating a data application',
  fields: {
    id: {
      comment: 'The id of the data application to update',
      modelName: 'dataApplication',
      type: 'id',
    },
    ...applyFields(DataApplicationInput.fields, (field) => ({
      ...field,
      optional: true,
    })),
  },
});

/** Override type */
export type UpdateDataApplicationInput = SchemaToType<
  typeof UpdateDataApplicationInput
>;

export const DataApplication = mkType({
  name: 'DataApplication',
  comment: 'A way in which the organization applies the data they collect',
  interfaces: [MenuTabInterface],
  fields: {
    ...MenuTabInterface.fields,
    id: {
      comment: 'The unique id',
      modelName: 'dataApplication',
      type: 'id',
    },
    description: {
      comment: 'A brief overview of the data application',
      type: DefinedMessage,
    },
    method: {
      comment: 'The method in which the data application is gathered',
      type: { DataApplicationMethod },
    },
    staticType: {
      comment: 'The static type underlying the data application',
      optional: true,
      type: { DataApplicationStaticType },
    },
    color: {
      comment: 'The color to display in the admin dashboard select input',
      type: 'string',
    },
  },
});

/** Override type */
export type DataApplication = SchemaToType<typeof DataApplication>;
