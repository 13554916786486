import { defineMessages } from '@transcend-io/internationalization';

export const sdkMessages = defineMessages(
  'admin-dashboard.CodeScanning.SoftwareDevelopmentKits.sdk',
  {
    newSdk: {
      defaultMessage: 'New SDK',
    },
    deleteSuccess: {
      defaultMessage:
        '{count, plural, one {SDK} other {SDKs}} successfully deleted',
    },
    sdkCount: {
      defaultMessage: '{count} {count, plural, one {SDK} other {SDKs}}',
    },
    noDataMessage: {
      defaultMessage: 'No SDKs to display.',
    },
    confirmDeleteButton: {
      defaultMessage: 'Delete {count, plural, one {SDK} other {{count} SDKs}}',
    },
    confirmDeletePrompt: {
      defaultMessage:
        'Are you sure you want to delete {count, plural, one {this SDK} other {{count} SDKs}}?',
    },
    sdkUserCount: {
      defaultMessage: '{count} {count, plural, one {user} other {users}}',
    },
  },
);

export const sdkColumnHeaderMessages = defineMessages(
  'admin-dashboard.CodeScanning.SoftwareDevelopmentKits.sdkColumnHeader',
  {
    name: {
      defaultMessage: 'Name',
    },
    description: {
      defaultMessage: 'Description',
    },
    createdAt: {
      defaultMessage: 'Created At',
    },
    codePackageType: {
      defaultMessage: 'Package Type',
    },
    repositoryUrl: {
      defaultMessage: 'Repository Link',
    },
    documentationLinks: {
      defaultMessage: 'Documentation',
    },
    sdk: {
      defaultMessage: 'Linked SDK',
    },
    catalog: {
      defaultMessage: 'Integration',
    },
    guessedCatalog: {
      defaultMessage: 'Guessed Integration',
    },
    repositories: {
      defaultMessage: 'Repositories',
    },
    registryUrl: {
      defaultMessage: 'Registry URL',
    },
    codePackages: {
      defaultMessage: 'Packages',
    },
    owners: {
      defaultMessage: 'Owners',
    },
    teams: {
      defaultMessage: 'Teams',
    },
  },
);

export const sdkModalMessages = defineMessages(
  'admin-dashboard.CodeScanning.SoftwareDevelopmentKits.sdkModal',
  {
    modalButton: {
      defaultMessage: 'New SDK',
    },
    header: {
      defaultMessage: 'New SDK',
    },
    introduction: {
      defaultMessage:
        'Create a new SDK that you want to track calls to your large language models.',
    },
    submitButton: {
      defaultMessage: 'Create New SDK',
    },
    submitSuccess: {
      defaultMessage: 'New SDK created!',
    },
  },
);
