/**
 * The fields that a pull request can be ordered on
 */
export enum PullRequestOrderField {
  /** The title of the pull request */
  Title = 'title',
  /** The age of the pull request */
  CreatedAt = 'createdAt',
  /** The last updated time */
  UpdatedAt = 'updatedAt',
}
