import { RequestDataSiloStatus } from '@transcend-io/privacy-types';
import { makeEnum } from '@transcend-io/type-utils';

/**
 * The statuses that indicate a successful completion
 */
export const RequestDataSiloStatusSuccess = makeEnum({
  /** Successfully resolved */
  Resolved: 'RESOLVED',
  /** Skipped processing because already completed */
  Skipped: 'SKIPPED',
  /** The request not processed due to some exception */
  SkippedDueToException: 'SKIPPED_DUE_TO_EXCEPTION',
});

/**
 * Overload type
 */
export type RequestDataSiloStatusSuccess =
  (typeof RequestDataSiloStatusSuccess)[keyof typeof RequestDataSiloStatusSuccess];

/**
 * The statuses that a RequestDataSilo can take on when exposed
 * through our GraphQL API. This is a subset of the statuses that can appear on
 * the column.
 */
export const VisualRequestDataSiloStatus = makeEnum({
  ...RequestDataSiloStatus,
  /**
   * The RequestDataSilo has dependencies and is awaiting those dependencies
   * to resolve before continuing
   */
  WaitingOnDependencies: 'WAITING_ON_DEPENDENCIES',
  /**
   * The RequestDataSilo is blocked because there are other requests with the
   * same core identifier
   */
  WaitingOnOtherRequests: 'WAITING_ON_OTHER_REQUESTS',
  /**
   * The data silo is disconnected and thus cannot begin processing
   */
  DataSiloDisconnected: 'DATA_SILO_DISCONNECTED',
  /**
   * The request is not in a status to begin processing yet
   */
  RequestPending: 'REQUEST_PENDING',
  /**
   * The data subject request is not in a building state and so all
   * jobs are canceled
   */
  RequestCanceled: 'REQUEST_CANCELED',
  /**
   * Waiting on manual entry
   */
  Manual: 'MANUAL',
});

/**
 * Overload type
 */
export type VisualRequestDataSiloStatus =
  (typeof VisualRequestDataSiloStatus)[keyof typeof VisualRequestDataSiloStatus];

/**
 * Status of request/data-silo that an employee can update to
 */
export enum UpdateRequestDataSiloStatus {
  /** Still waiting on async actor */
  Waiting = 'WAITING',
  /** The service worker successfully processed the row. */
  Resolved = 'RESOLVED',
  /** The request data silo is being redacted, and needs to be submitted before being sent off */
  ActionRequired = 'ACTION_REQUIRED',
  /** Skipped processing because already completed */
  Skipped = 'SKIPPED',
  /** The request not processed due to some exception */
  SkippedDueToException = 'SKIPPED_DUE_TO_EXCEPTION',
}

/**
 * The type of integration automations that can be queued
 */
export enum RequestDataSiloQueueType {
  /**
   * The primary action for the DSR. Typically this is just the resolver for the action, but in the case where the action supports a delay,
   * it is the action that is run before the delay occurs. i.e. an erasure -> access, access -> access, custom -> custom
   */
  Primary = 'PRIMARY',
  /** Same as primary but will remove all profiles before running (think of as a fresh start) -- This is when the retrigger button is clicked */
  PrimaryWithWipe = 'PRIMARY_WITH_WIPE',
}

/**
 * Fields that a request data silo can be ordered by
 */
export enum RequestDataSiloOrderField {
  /** When the request data silo was created */
  CreatedAt = 'createdAt',
  /** The time the request data silo was updated */
  UpdatedAt = 'updatedAt',
  /** The title of the associated data silo */
  Title = 'title', // dataSilo
}

/**
 * Associated models that can be sorted on
 */
export enum RequestDataSiloSortAssociation {
  /** Sort on dataSilo title */
  DataSilo = 'dataSilo',
}

/**
 * A state that represents the relation between a request and a dataSilo.
 * The difference between this and `RequestDataSiloStatus` is that `RequestDataSiloStatus`
 * is the status of thee relationship between these models once a request is been queued
 * to process a data silo.
 *
 * This status can represent the relationship or potentially lack of relation between a data silo and request
 */
export enum RequestDataSiloConnectionStatus {
  /**
   * The request and data silo are directly connected to one another
   */
  DirectlyConnected = 'DIRECTLY_CONNECTED',
  /**
   * The request has a child request that was connected to this data silo.
   * This is normally the result of re-processing an old erasure request.
   */
  ChildConnected = 'CHILD_CONNECTED',
  /**
   * The data silo is not associated with the request yet but it is possible
   * for that connection to be made. This is the process of queuing up the
   * data silo for existing open requests
   */
  CanConnect = 'CAN_CONNECT',
  /**
   * This is a case where the data silo is capable of processing the request,
   * but the request and all of its children have not yet processed.
   *
   * This is similar to `CAN_CONNECT` with the exception that a new DSR has to be
   * initiated to complete the processing.
   */
  ShouldConnect = 'SHOULD_CONNECT',
  /**
   * The data silo is not capable of processing the request.
   */
  NoConnection = 'NO_CONNECTION',
}
