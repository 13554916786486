import { mkType, SchemaToType } from '@main/schema-utils';

export const PromptThreadPreview = mkType({
  name: 'PromptThreadPreview',
  comment: 'A preview of a prompt thread',
  fields: {
    id: {
      comment: 'The id of the prompt thread',
      modelName: 'promptThread',
      type: 'id',
    },
    threadId: {
      comment: 'The ID of the thread in the remote system',
      type: 'string',
    },
    slackMessageTs: {
      comment:
        'The ts of the slack message is the thread is a slack conversation',
      type: 'string',
      optional: true,
    },
    slackTeamId: {
      comment: 'The ID of the slack team if the thread is a slack conversation',
      type: 'string',
      optional: true,
    },
    slackChannelId: {
      comment:
        'The ID of the slack channel if the thread is a slack conversation',
      type: 'string',
      optional: true,
    },
    slackChannelName: {
      comment:
        'The name of the slack channel if the thread is a slack conversation',
      type: 'string',
      optional: true,
    },
  },
});

/** Override type */
export type PromptThreadPreview = SchemaToType<typeof PromptThreadPreview>;
