import styled, { css, keyframes } from 'styled-components';

import { TooltipPosition } from './types';

const fadeIn = keyframes`
  0% { opacity: 0% }
  75% { opacity: 0% }
  100% { opacity: 100% }
`;

const containerPositionStyles: Record<
  TooltipPosition,
  ReturnType<typeof css>
> = {
  'above-left': css`
    transform: translate(calc(-100% + 12px), calc(-100% - 24px));
  `,
  'above-center': css`
    transform: translate(calc(-50% - 10px), calc(-100% - 16px));
  `,
  'above-right': css`
    transform: translate(-31px, calc(-100% - 16px));
  `,
  'left-center': css`
    transform: translate(calc(-100% - 24px), calc(-50% - 8px));
  `,
  'left-down': css`
    transform: translate(calc(-100% - 16px), -24px);
  `,
  'right-down': css`
    transform: translate(12px, -24px);
  `,
  'right-center': css`
    transform: translate(12px, calc(-50% - 8px));
  `,
  'below-left': css`
    transform: translate(31px);
  `,
  'below-center': css`
    transform: translate(-50%);
  `,
  'below-right': css`
    transform: translate(-31px);
  `,
};

const caretPositionStyles: Record<TooltipPosition, ReturnType<typeof css>> = {
  'above-left': css`
    bottom: -6px;
    right: 14px;
    transform: rotate(-45deg);
  `,
  'above-center': css`
    bottom: -6px;
    left: calc(50% - 6px);
    transform: rotate(-45deg);
  `,
  'above-right': css`
    bottom: -6px;
    left: 14px;
    transform: rotate(-45deg);
  `,
  'left-center': css`
    top: calc(50% - 6px);
    right: -6px;
    transform: rotate(-135deg);
  `,
  'left-down': css`
    top: 6px;
    right: -6px;
    transform: rotate(-135deg);
  `,
  'right-down': css`
    top: 14px;
    left: -6px;
    transform: rotate(45deg);
  `,
  'right-center': css`
    top: calc(50% - 6px);
    left: -6px;
    transform: rotate(45deg);
  `,
  'below-left': css`
    top: -6px;
    right: 14px;
    transform: rotate(135deg);
  `,
  'below-right': css`
    top: -6px;
    left: 14px;
    transform: rotate(135deg);
  `,
  'below-center': css`
    top: -6px;
    left: calc(50% - 6px);
    transform: rotate(135deg);
  `,
};

export const TooltipContainer = styled.div<{
  /** position of tooltip relative to provided svg coordinate */
  tooltipPosition: TooltipPosition;
}>`
  ${({ tooltipPosition }) => containerPositionStyles[tooltipPosition]};
  box-sizing: border-box;
  box-shadow: 1px 4px 9px 0 rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 8px 16px;
  background: ${({ theme }) => theme.colors.white};
  border: ${({ theme }) => `1px solid ${theme.colors.gray3}`};
  pointer-events: none;
  animation: ${css`
      ${fadeIn}`} 300ms ease-out;
`;

export const Caret = styled.div<{
  /** position of tooltip relative to provided svg coordinate */
  tooltipPosition: TooltipPosition;
}>`
  ${({ tooltipPosition }) => caretPositionStyles[tooltipPosition]};
  position: absolute;
  height: 12px;
  width: 12px;
  background: ${({ theme }) => theme.colors.white};
  border-left: ${({ theme }) => `1px solid ${theme.colors.gray3}`};
  border-bottom: ${({ theme }) => `1px solid ${theme.colors.gray3}`};
`;
