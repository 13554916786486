import { Icon, ReactSelect, SelectTagWrapper, SingleTag } from '@main/core-ui';
import { dataCategoryTypeMessages } from '@main/datamap-types';
import { DataCategoryType } from '@transcend-io/privacy-types';
import React, { useCallback } from 'react';
import { useIntl } from 'react-intl';
import type { MenuPosition } from 'react-select';

import {
  DATA_CATEGORY_TYPE_COLOR_NAMES,
  DATA_CATEGORY_TYPE_COLORS,
} from '../../constants';
import { ropaSelectCategoryMessages } from './messages';

/** An option for the select component */
export interface SelectCategoryOption {
  /** The value for the option */
  value: DataCategoryType;
  /** The label for the option */
  label: string;
}

/** Props for the `InlineSelectCategory` component */
interface SelectCategoriesProps {
  /** The currently selected values */
  value?: DataCategoryType[];
  /** Whether the input is disabled (e.g. due to the data being derived) */
  disabled?: boolean;
  /** Whether the input is being updated/loading */
  loading?: boolean;
  /** Callback to fire when component is blurred */
  onBlur?: () => void;
  /** Callback to fire when the category is updated */
  onChange: (categories: DataCategoryType[]) => void;
  /** Whether to show the select input outlines */
  showSelectOutline: boolean;
  /** Whether to hide the `UNSPECIFIED` option */
  hideUnspecified?: boolean;
  /** How to position the menu (see docs for React Select) */
  menuPosition?: MenuPosition;
  /** Whether the select accepts multiple values */
  isMulti?: boolean;
}

// Used for ROPA only
export const SelectCategories: React.FC<SelectCategoriesProps> = ({
  value,
  disabled,
  loading,
  showSelectOutline,
  onBlur,
  onChange,
  isMulti = true,
  hideUnspecified = false,
  menuPosition,
}) => {
  const { formatMessage } = useIntl();
  const toOption = useCallback(
    (value: DataCategoryType) => ({
      value,
      label: formatMessage(dataCategoryTypeMessages[value]),
      ...(disabled ? { logo: <Icon type="lock" /> } : {}),
    }),
    [disabled],
  );
  const options = Object.values(DataCategoryType)
    .filter(
      (category) =>
        category !== DataCategoryType.NotPersonalData &&
        (!hideUnspecified || category !== DataCategoryType.Unspecified),
    )
    .map(toOption);

  return (
    <SelectTagWrapper>
      <ReactSelect<boolean, SelectCategoryOption>
        options={options}
        value={value ? value.map((value) => toOption(value)) : null}
        menuPosition={menuPosition}
        getOptionLabel={(option) => option.label}
        showOutline={showSelectOutline}
        isLoading={loading}
        isDisabled={disabled}
        placeholder={formatMessage(ropaSelectCategoryMessages.placeholder)}
        colorMap={DATA_CATEGORY_TYPE_COLORS}
        isClearable={!isMulti}
        formatOptionPill={(option) => (
          <SingleTag
            label={option.label}
            color={DATA_CATEGORY_TYPE_COLOR_NAMES[option.value]}
          />
        )}
        isMulti={isMulti}
        onChange={(option) =>
          // always return a list for type simplicity
          !option
            ? onChange([])
            : Array.isArray(option)
              ? onChange(option.map(({ value }) => value))
              : onChange([(option as SelectCategoryOption).value as any])
        }
        onBlur={onBlur}
      />
    </SelectTagWrapper>
  );
};

/**
 * Select a single integration
 */
export const SelectCategory: React.FC<
  Omit<SelectCategoriesProps, 'value' | 'onChange'> & {
    /** Set the newly selected data silos */
    onChange: (category: DataCategoryType) => void;
    /** Selected value */
    value?: DataCategoryType;
  }
> = ({ onChange, value, ...props }) => (
  <SelectCategories
    {...props}
    isMulti={false}
    value={value ? [value] : []}
    onChange={([value]) => onChange(value)}
  />
);
