import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { GenericMessageDescriptor } from '@main/core-ui';

/**
 * The redux store for Routes
 */
export interface RoutesState {
  /** True if actively fetching user */
  isLoadingUser: boolean;
  /** Title to put in the document head */
  title: null | GenericMessageDescriptor;
}

export const routesSlice = createSlice({
  name: 'AdminDashboardRoutes',
  initialState: {
    isLoadingUser: true,
    breadcrumbs: null,
    title: null,
  } as RoutesState,
  reducers: {
    // Current user metadata is actively being loaded from backend
    setIsLoadingUser: (
      state,
      { payload: isLoadingUser }: PayloadAction<boolean>,
    ) => ({
      ...state,
      isLoadingUser,
    }),
    setDocumentTitle: (
      state,
      { payload: title }: PayloadAction<RoutesState['title']>,
    ) => ({
      ...state,
      title,
    }),
  },
});

export const { setIsLoadingUser, setDocumentTitle } = routesSlice.actions;
