import { Icon } from '@main/core-ui';
import React from 'react';
import { useTheme } from 'styled-components';

import { Button } from '../Button';
import { StyledPill, StyledPillLabel } from './wrappers';

interface FilterPillProps {
  /** Pill label */
  label: React.ReactNode;
  /** Handler for the 'x' button */
  onDismiss: React.MouseEventHandler<HTMLButtonElement>;
}

export const FilterPill: React.FC<FilterPillProps> = ({
  onDismiss,
  label,
  children,
}) => {
  const theme = useTheme();

  return (
    <StyledPill>
      <StyledPillLabel>{label}</StyledPillLabel>
      {children}
      <Button
        className="dismiss-filter"
        variant="link"
        style={{ padding: '0 2px' }}
        onClick={onDismiss}
        icon={
          <Icon type="close" color={theme.colors.transcendNavy3} size={10} />
        }
      />
    </StyledPill>
  );
};
