import { QueryResult } from '@apollo/client';

import {
  AssessmentForm,
  endpoints,
  SelectedAssessmentAnswer,
  SelectedAssessmentAnswerRaw,
} from '@main/datamap-types';
import { ResponseToType } from '@main/schema-utils';

const transformAnswer = (
  answer: SelectedAssessmentAnswerRaw,
): SelectedAssessmentAnswer => ({
  ...answer,
  additionalContext: answer.additionalContext
    ? JSON.parse(answer.additionalContext)
    : undefined,
});

export const transformRawAssessmentFormToken = (
  data: QueryResult<
    ResponseToType<typeof endpoints.assessmentFormToken.response>
  >['data'],
): QueryResult<{
  /** The transformed assessment forms */
  assessmentForm: AssessmentForm;
}>['data'] => ({
  ...data,
  assessmentForm: {
    ...data?.assessmentForm,
    sections: (data?.assessmentForm?.sections || []).map((section) => ({
      ...section,
      questions: (section.questions || []).map(
        ({ displayLogic, riskLogic, selectedAnswers, ...question }) => ({
          ...question,
          ...(displayLogic ? { displayLogic: JSON.parse(displayLogic) } : {}),
          ...(riskLogic
            ? { riskLogic: riskLogic.map((rl) => JSON.parse(rl)) }
            : {}),
          selectedAnswers: selectedAnswers.map(transformAnswer),
          previousSubmissions: question.previousSubmissions.map(
            ({ answers, ...submission }) => ({
              ...submission,
              answers: answers.map(transformAnswer),
            }),
          ),
        }),
      ),
    })),
  } as AssessmentForm,
});

export const transformRawAssessmentForms = (
  data: QueryResult<
    ResponseToType<typeof endpoints.assessmentForms.response>
  >['data'],
): QueryResult<{
  /** The total count of the forms */
  totalCount?: number;
  /** The transformed assessment forms */
  nodes: AssessmentForm[];
}>['data'] => ({
  ...data,
  nodes: (data?.nodes || []).map(
    (form) =>
      ({
        ...form,
        sections: (form?.sections || []).map((section) => ({
          ...section,
          questions: (section.questions || []).map(
            ({ displayLogic, riskLogic, ...question }) => ({
              ...question,
              ...(displayLogic
                ? { displayLogic: JSON.parse(displayLogic) }
                : {}),
              ...(riskLogic
                ? { riskLogic: riskLogic.map((rl) => JSON.parse(rl)) }
                : {}),
              selectedAnswers: question.selectedAnswers.map(transformAnswer),
              previousSubmissions: question.previousSubmissions.map(
                ({ answers, ...submission }) => ({
                  ...submission,
                  answers: answers.map(transformAnswer),
                }),
              ),
            }),
          ),
        })),
      }) as AssessmentForm,
  ),
});
