import { useEffect, useState } from 'react';

import {
  UseMultiWebWorkerResult,
  useWebWorkers,
} from '../../../hooks/useWebWorkers';
import {
  ModuleDiffDetails,
  ModuleDiffRecord,
  ModuleDiffResult,
  ModuleDiffResults,
  ModuleKeyEnum,
} from './types';

/**
 * Creates the function to initialize the worker map
 *
 * @param configs - Mapping whose keys represent the module keys in the worker map
 * @returns a function to initialize the worker map
 */
function createWorkerMapInit(
  configs: ModuleDiffRecord,
): () => { [key in ModuleKeyEnum]: Worker } {
  return () =>
    Object.keys(configs).reduce(
      (agg, key) => ({
        ...agg,
        [key as ModuleKeyEnum]: new Worker(
          new URL('./diff-worker.ts', import.meta.url),
        ),
      }),
      {} as { [key in ModuleKeyEnum]: Worker },
    );
}

/**
 * Hook to use the diff web worker
 * NOTE: This is necessary because the admin dashboard components aren't compiled as es modules
 * If we change that one day then we can avoid using this passthrough (necessary bc of import.meta)
 *
 * @param moduleConfigs - arguments to pass along to the worker
 * @returns Diffed config results
 */
export function useDiffWorkers(
  moduleConfigs: ModuleDiffRecord,
): UseMultiWebWorkerResult<ModuleKeyEnum, ModuleDiffResult, ModuleDiffResults> {
  const [init, setInit] = useState<() => { [key in ModuleKeyEnum]: Worker }>(
    () => createWorkerMapInit(moduleConfigs),
  );

  useEffect(() => {
    setInit(() => createWorkerMapInit(moduleConfigs));
  }, [moduleConfigs]);

  return useWebWorkers<
    ModuleDiffDetails,
    ModuleDiffResult,
    ModuleDiffResults,
    ModuleKeyEnum
  >(
    init,
    moduleConfigs,
    (agg, [key, result]) => ({ ...agg, [key]: result }),
    {},
  );
}
