import styled, { css, Keyframes, keyframes } from 'styled-components';

import { FlexRowCenterVertical } from '@main/core-ui';

const onItemMountAnimation = (startingColor: string): Keyframes => keyframes`
  0% {
    background: ${startingColor};
  }
  100% {
    background: white;
  }
`;

export const ItemWrapper = styled(FlexRowCenterVertical)`
  gap: 0.5em;
  border-radius: 0.5em;
  transition: background 200ms ease-out;

  ${({ theme }) => css`
    animation: ${onItemMountAnimation(theme.colors.transcend4)} 200ms ease-out;
  `}
`;
