import sortBy from 'lodash/sortBy';

import { ONE_DAY, ONE_HOUR, ONE_MINUTE, ONE_WEEK } from '@main/utils';

import { ScheduleInputUnit } from './types';

export const UNIT_TO_INTERVAL: Record<ScheduleInputUnit, number> = {
  [ScheduleInputUnit.Hourly]: ONE_HOUR,
  [ScheduleInputUnit.Daily]: ONE_DAY,
  [ScheduleInputUnit.Weekly]: ONE_WEEK,
  [ScheduleInputUnit.Minute]: ONE_MINUTE,
};

/**
 * parses the frequency value and tries to calculate the unit and multiple for that frequency
 *
 * @param frequencyMs - the frequency to parse
 * @param allowedUnits - the list of units that are allowed
 * @returns the unit and multiple for the input frequency
 */
export function parseFrequencyForUnitAndMultiple(
  frequencyMs: number,
  allowedUnits: ScheduleInputUnit[],
): {
  /** the unit calculated from the frequency */
  unit: ScheduleInputUnit;
  /** the multiple of the unit */
  multiple: number;
} {
  // sort the units in descending order
  const sortedAllowedUnitsAndIntervals = sortBy(
    allowedUnits.map((unit) => ({ interval: UNIT_TO_INTERVAL[unit], unit })),
    'interval',
  ).reverse();

  // get the highest unit that fits into the frequency
  const highestUnit =
    sortedAllowedUnitsAndIntervals.find(
      ({ interval }) => frequencyMs > interval,
    )?.unit ??
    // default to smallest unit if all units are larger than frequency
    sortedAllowedUnitsAndIntervals[allowedUnits.length - 1].unit;

  // try to get the exact frequency (handles cases where the frequency is
  // more than one unit but not an even multiple, e.g. 36 hours vs 1.5 days)
  const exactUnit =
    sortedAllowedUnitsAndIntervals.find(
      ({ interval }) => frequencyMs % interval === 0,
    )?.unit ??
    // fall back to the highest unit
    highestUnit;

  const multiple = frequencyMs / UNIT_TO_INTERVAL[exactUnit];
  return { unit: exactUnit, multiple };
}
