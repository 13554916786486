import { ColorPalette } from '@main/theme';

export const AXIS_HEIGHT_BOTTOM = 64;
export const BAR_STACK_SPACING = 2;
export const BAR_WIDTH = 32;
export const HOVER_SCALE = 1.08;
export const BAR_MIN_SPACING = 2;
export const BAR_MIN_WIDTH = 4;
export const BAR_MIN_HEIGHT = 2;

export const DEFAULT_COLORS: (keyof ColorPalette)[] = [
  'blue2',
  'mint2',
  'yellow2',
  'red2',
  'orange2',
  'pink2',
  'purple2',
  'indigo2',
];
