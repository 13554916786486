import { DefinedMessage } from '@transcend-io/internationalization';
import React from 'react';
import { useIntl } from 'react-intl';
import { useTheme } from 'styled-components';

import { rangeInputMessages } from './messages';
import {
  FocusRing,
  Input,
  LabelsSection,
  Track,
  TrackBase,
  TrackMaskDisabled,
  TrackMaskLeft,
  TrackMaskRight,
} from './wrappers';

/**
 * mask options for which part of the track to mask
 */
export type TrackMask = 'below' | 'above' | 'none' | undefined;

export interface RangeInputProps
  extends Omit<
    React.ComponentPropsWithRef<'input'>,
    'children' | 'onChange' | 'type' | 'value' | 'min' | 'max' | 'aria-label'
  > {
  /** callback invoked when input state changes */
  onChange?: (value: number | undefined) => void;
  /** labels on bottom of range input */
  labels?: Array<DefinedMessage>;
  /** current value of range input */
  value?: number;
  /** minimum value for range */
  min?: number;
  /** maximum value for range */
  max?: number;
  /** label for accessibility as visible labels only label parts of the range - Optional if used with RangePercent component */
  'aria-label'?: string;
  /** color gradient stops */
  trackGradientColorStops?: Array<string>;
  /** weather to mask below or above current value */
  trackMask?: TrackMask;
  /** flag to add padding on either side of the slider */
  padExtremes?: boolean;
}

const DEFAULT_LABELS = [
  rangeInputMessages.low,
  rangeInputMessages.medium,
  rangeInputMessages.high,
];

export const RangeInput = React.forwardRef<HTMLInputElement, RangeInputProps>(
  (
    {
      disabled = false,
      value = 0,
      onChange,
      min = 0,
      max = 100,
      labels = DEFAULT_LABELS,
      trackGradientColorStops,
      trackMask = 'none',
      padExtremes = false,
      ...props
    },
    ref,
  ) => {
    const { formatMessage } = useIntl();
    const { colors } = useTheme();
    const colorStops = trackGradientColorStops || [
      colors.red1,
      colors.purple1,
      colors.mint1,
    ];
    const valueAsPercent = (value / max) * 100;
    const totalTrackIncrements = max - min;
    const valueAsSteps = value - min;

    return (
      <div style={{ width: '100%', position: 'relative' }}>
        <Input
          disabled={disabled}
          type="range"
          min={min}
          max={max}
          value={value}
          onChange={(evt) => {
            onChange?.(parseInt(evt.target.value));
          }}
          ref={ref}
          valueAsPercent={valueAsPercent}
          colorStops={colorStops}
          padExtremes={padExtremes}
          {...props}
        />
        <FocusRing />
        <Track>
          {!disabled && <TrackBase colorStops={colorStops} />}
          {disabled && <TrackMaskDisabled />}
          {trackMask === 'below' && !disabled && (
            <TrackMaskLeft
              valueAsSteps={valueAsSteps}
              totalTrackIncrements={totalTrackIncrements}
              padExtremes={padExtremes}
            />
          )}
          {trackMask === 'above' && !disabled && (
            <TrackMaskRight
              valueAsSteps={valueAsSteps}
              totalTrackIncrements={totalTrackIncrements}
              padExtremes={padExtremes}
            />
          )}
        </Track>
        {labels?.length !== 0 && (
          <LabelsSection
            colorStops={colorStops}
            aria-hidden
            padExtremes={padExtremes}
          >
            {labels.map((label) => formatMessage(label)).join(' ')}
          </LabelsSection>
        )}
      </div>
    );
  },
);
