import * as amplitude from '@amplitude/analytics-browser';

import { CurrentUser, OrganizationPreview } from '@main/access-control-types';
import { logger } from '@main/core-ui';

import { AMPLITUDE_API_KEY, INTERCOM_VERSION } from '../../args';
import { setDataDogContext, setDataDogUser } from '../../datadog';

/**
 * Helper to get the primary Organization for this user (parent org or else this org)
 *
 * @param user - Logged in user
 * @returns the organization (parent org or this org)
 */
function getPrimaryOrganization(
  user: CurrentUser,
): OrganizationPreview | undefined {
  if (user.parentRole?.id) {
    const parentUserId = user.parentRole.id;
    const foundParentRole = user.roles.find((role) => role.id === parentUserId);

    if (!foundParentRole) {
      logger.error(
        `Unable to resolve user parent role for user ${user.parentRole.id}`,
      );
      return undefined;
    }

    return foundParentRole.organization;
  }

  return user.organization;
}

/**
 * Reset tracking on logout
 */
export function resetTracking(): void {
  if (window.analytics) {
    // Reset tracking in Segment (see https://segment.com/docs/connections/spec/b2b-saas/#signed-out)
    window.analytics.reset();
  }

  // Reset tracking in Intercom
  if (window.intercomSettings) {
    delete window.intercomSettings.name;
    delete window.intercomSettings.email;
    delete window.intercomSettings.created_at;
    delete window.intercomSettings.company;
  }

  // Reset tracking in Amplitude
  amplitude.reset();
}

let intercomIsInjected = false;

/**
 * Inject intercom into the dom
 */
export function injectIntercom(): void {
  // copied from https://app.intercom.com/a/apps/f7yqnv3x/settings/web
  const ic = window.Intercom;
  if (typeof ic === 'function') {
    (ic as any)('reattach_activator');
    ic('update', window.intercomSettings);
  } else {
    const d = document;
    /**
     * intercom
     */

    // eslint-disable-next-line no-var, vars-on-top, jsdoc/require-jsdoc
    var i: any = function inter(): void {
      i.c(arguments);
    };
    i.q = [];
    /**
     * Intercom
     *
     * @param args - args
     */
    i.c = function (args: any) {
      i.q.push(args);
    };
    window.Intercom = i;
    /**
     * Loading
     */
    const l = function load(): void {
      const s = d.createElement('script');
      s.type = 'text/javascript';
      s.async = true;
      s.src = `https://widget.intercom.io/widget/${INTERCOM_VERSION}`;
      const x = d.getElementsByTagName('script')[0];
      x.parentNode?.insertBefore(s, x);
    };
    if (document.readyState === 'complete') {
      l();
    } else if ((window as any).attachEvent) {
      (window as any).attachEvent('onload', l);
    } else {
      window.addEventListener('load', l, false);
    }
  }
}

/**
 * Initialize Amplitude
 *
 * @see https://amplitude.com/docs/sdks/analytics/browser/browser-sdk-2
 */
export function initAmplitude(): void {
  // Don't initialize Amplitude for Cypress tests
  if ('Cypress' in window) {
    return;
  }

  amplitude.init(AMPLITUDE_API_KEY, {
    autocapture: { elementInteractions: true },
  });
}

/**
 * Identify a user
 *
 * @param user - Logged in user to track
 */
export const identifyUser = (user: CurrentUser): void => {
  // Track with DataDog
  setDataDogContext('organization', {
    uri: user.organization?.uri,
  });
  setDataDogUser(user.id);
  // Start session replay recording ONLY for internal testing organizations
  if (
    // Internal testing organizations (like eShopIt / Rideshare)
    user.organization?.tier === 'INTERNAL' &&
    // Except Transcend
    user.organization?.uri !== 'transcend' &&
    window.DD_RUM
  ) {
    window.DD_RUM.startSessionReplayRecording({ force: true });
  }

  // Get the user and organization (parent user and parent org)
  const primaryUser = user.parentRole || user;
  const primaryOrganization = getPrimaryOrganization(user);
  const { analyticsId } = user;

  // Identify with Segment
  if (window.analytics) {
    if (!analyticsId) {
      logger.warn('No analyticsId found for userId', user.id);
      window.analytics.identify({
        email: primaryUser.email,
      });
    } else {
      // Identify this user (by their parent role)
      window.analytics.identify(analyticsId, {
        email: primaryUser.email,
      });
    }

    // Group this user into their organization (by their parent organization)
    if (primaryOrganization) {
      window.analytics.group(primaryOrganization.id);
    }
  }

  // Identify with Amplitude
  const identifyEvent = new amplitude.Identify();
  identifyEvent.set('email', primaryUser.email);
  amplitude.setUserId(analyticsId);
  if (primaryOrganization) {
    const groupIdentifyEvent = new amplitude.Identify();
    amplitude.groupIdentify(
      'organizationId',
      primaryOrganization.id,
      groupIdentifyEvent,
    );
  }

  // Identify in Intercom
  window.intercomSettings = {
    api_base: 'https://api-iam.intercom.io',
    alignment: 'left',
    hide_default_launcher: true,
    app_id: INTERCOM_VERSION,
    name: primaryUser.name,
    email: primaryUser.email
      .trim()
      .toLowerCase()
      .replaceAll(/\+.*@|@/g, '@'),
    user_hash: user.intercomId,
    user_id: primaryUser.id,
    created_at: Math.floor(+new Date(primaryUser.createdAt) / 1000),
    company: primaryOrganization
      ? {
          company_id: primaryOrganization.id,
          name: primaryOrganization.name,
        }
      : undefined,
  };

  if (!intercomIsInjected) {
    injectIntercom();
    intercomIsInjected = true;
  }
};
