import * as t from 'io-ts';

import { dbModelId } from '@main/schema-utils';

import { AuditEventCode } from '../schema/enums';
import {
  mkAuditEventCodec,
  mkAuditEventJoinCodec,
  mkAuditJoinStateCodec,
} from '../types';

export const AuditEventAssessmentSection = mkAuditEventCodec(
  AuditEventCode.AssessmentSection,
  t.intersection([
    t.type({
      /** ID of the assessment section */
      assessmentSectionId: dbModelId('assessmentSection'),
    }),
    t.partial({
      /** ID of the assessment form template that owns the section */
      assessmentFormTemplateId: dbModelId('assessmentFormTemplate'),
      /** ID of the assessment form that owns the section */
      assessmentFormId: dbModelId('assessmentForm'),
    }),
  ]),
);

/** Override types. */
export type AuditEventAssessmentSection = t.TypeOf<
  typeof AuditEventAssessmentSection
>;

export const AuditEventAssessmentSectionAssignee = mkAuditEventJoinCodec(
  AuditEventCode.AssessmentSectionAssignee,
  mkAuditJoinStateCodec('user'),
  t.intersection([
    t.type({
      /** ID of the assessment section */
      assessmentSectionId: dbModelId('assessmentSection'),
    }),
    t.partial({
      /** ID of the assessment form that owns the form */
      assessmentFormId: dbModelId('assessmentForm'),
    }),
  ]),
);

/** Override types. */
export type AuditEventAssessmentSectionAssignee = t.TypeOf<
  typeof AuditEventAssessmentSectionAssignee
>;
