import { defineMessages } from '@transcend-io/internationalization';

import { AssessmentAttributeStatus } from '@main/attribute-types';

export const selectAttributeMessages = defineMessages(
  'admin-dashboard.DataMap.components.SelectAttribute.selectAttribute',
  {
    placeholder: {
      defaultMessage: 'Add values',
    },
    none: {
      defaultMessage: 'N/A',
    },
    assessmentUrlOptionName: {
      defaultMessage: 'Link to External {templateTitle}',
    },
    assessmentUrlPlaceholder: {
      defaultMessage: 'Add link to {templateTitle}...',
    },
    newAssessmentOptionName: {
      defaultMessage: 'Create New {templateTitle}',
    },
    noOptions: {
      defaultMessage:
        'No custom field values exist for this custom field yet, values can be added in ' +
        'the Custom Fields settings page',
    },
  },
);

export const assessmentAttributeStatusMessages =
  defineMessages<AssessmentAttributeStatus>(
    'admin-dashboard.DataMap.components.SelectAttribute.assessmentAttributeStatus',
    {
      [AssessmentAttributeStatus.Needed]: {
        defaultMessage: '{templateTitle} Needed',
      },
      [AssessmentAttributeStatus.NotNeeded]: {
        defaultMessage: 'Not Required',
      },
    },
  );
