import {
  LargeLanguageModelClient,
  PromptStatus,
} from '@transcend-io/privacy-types';

import {
  mkInput,
  mkInterface,
  mkOrder,
  mkType,
  SchemaToType,
} from '@main/schema-utils';

import { LargeLanguageModelOrderField } from './enums';

export const LargeLanguageModelInterface = mkInterface({
  name: 'LargeLanguageModelInterface',
  comment: 'An interface for a large language model',
  fields: {
    id: {
      comment: 'The id of the large language model',
      modelName: 'largeLanguageModel',
      type: 'id',
    },
    name: {
      comment: 'The identifying name of the large language model',
      type: 'string',
    },
    description: {
      comment: 'A description of the large language model',
      type: 'string',
    },
    client: {
      comment:
        'The large language model client being integrated into (e.g. openai)',
      type: { LargeLanguageModelClient },
    },
    status: {
      comment:
        'The review status of the large language model - is this model approved for usage within the business',
      type: { PromptStatus },
    },
    isTranscendHosted: {
      comment:
        'Whether or not the model is hosted by Transcend or hosted by the customer',
      type: 'boolean',
    },
    contextLength: {
      comment:
        'The allowed context length for the model (e.g. how many tokens can go into a single call to the model)',
      type: 'int',
      optional: true,
    },
    inputTokenPrice: {
      comment:
        'The price per token paid for input tokens that are passed into the model',
      type: 'float',
      optional: true,
    },
    outputTokenPrice: {
      comment: 'The price per token that comes out of the model',
      type: 'float',
      optional: true,
    },
  },
});

/** Override type */
export type LargeLanguageModelInterface = SchemaToType<
  typeof LargeLanguageModelInterface
>;

export const LargeLanguageModelInput = mkInput({
  name: 'LargeLanguageModelInput',
  comment: 'Model inputs for upserting new models',
  fields: {
    client: {
      comment: 'The types of LLM client',
      type: { LargeLanguageModelClient },
    },
    name: {
      comment: 'The name of the LLM client',
      type: 'string',
    },
  },
});

/** Override type */
export type LargeLanguageModelInput = SchemaToType<
  typeof LargeLanguageModelInput
>;

export const LargeLanguageModelPreview = mkType({
  name: 'LargeLanguageModelPreview',
  comment: 'A preview of a large language model',
  interfaces: [LargeLanguageModelInterface],
  fields: {
    ...LargeLanguageModelInterface.fields,
  },
});

/** Override type */
export type LargeLanguageModelPreview = SchemaToType<
  typeof LargeLanguageModelPreview
>;

export const LargeLanguageModel = mkType({
  name: 'LargeLanguageModel',
  comment: 'A large language model registered by the business',
  interfaces: [LargeLanguageModelInterface],
  fields: {
    ...LargeLanguageModelInterface.fields,
    createdAt: {
      comment: 'The date the prompt was created',
      type: 'Date',
    },
    updatedAt: {
      comment: 'The date the prompt was last updated',
      type: 'Date',
    },
    promptRunCount: {
      comment:
        'The number of prompt runs that have been executed for this model',
      type: 'int',
    },
    promptCount: {
      comment:
        'The number of prompts that are configured that have been executed for this model',
      type: 'int',
    },
  },
});

/** Override type */
export type LargeLanguageModel = SchemaToType<typeof LargeLanguageModel>;

export const LargeLanguageModelFiltersInput = mkInput({
  name: 'LargeLanguageModelFiltersInput',
  comment: 'Inputs for filtering large language models',
  fields: {
    ids: {
      comment: 'The ids of the large language models',
      type: 'id',
      modelName: 'largeLanguageModel',
      optional: true,
      list: true,
    },
    clients: {
      comment: 'The types of LLM clients to filter for',
      type: { LargeLanguageModelClient },
      optional: true,
      list: true,
    },
    text: {
      comment: 'Filter by text (title of the model)',
      optional: true,
      type: 'string',
    },
  },
});

/** Override type */
export type LargeLanguageModelFiltersInput = SchemaToType<
  typeof LargeLanguageModelFiltersInput
>;

/**
 * Order for a largeLanguageModels query
 */
export const LargeLanguageModelOrder = mkOrder(
  LargeLanguageModel.name,
  LargeLanguageModelOrderField,
);

/** Override type */
export type LargeLanguageModelOrder = SchemaToType<
  typeof LargeLanguageModelOrder
>;
