/* eslint-disable max-lines */
import {
  AssessmentQuestionSubType,
  AssessmentQuestionType,
  AssessmentsDisplayLogicAction,
  AssessmentSyncColumn,
  AssessmentSyncModel,
  ComparisonOperator,
  LogicOperator,
} from '@transcend-io/privacy-types';
import { ObjByString } from '@transcend-io/type-utils';

import { UserPreview } from '@main/access-control-types';
import { AttributeKeyPreview } from '@main/attribute-types';
import { mkInput, mkType, SchemaToType } from '@main/schema-utils';

import {
  AssessmentAnswer,
  AssessmentAnswerOptionInput,
  SelectedAssessmentAnswer,
  SelectedAssessmentAnswerRaw,
} from './assessmentAnswer';
import {
  AssessmentAnswerSubmission,
  AssessmentAnswerSubmissionRaw,
} from './assessmentEvent';
import { AssessmentQuestionComment } from './assessmentQuestionComment';
import { RiskCategory } from './riskCategory';
import { RiskFramework } from './riskFramework';
import { RiskLevel } from './riskLevel';

export const RuleInput = mkInput({
  name: 'RuleInput',
  comment:
    'The rule to evaluate in the display logic of an assessment question',
  fields: {
    dependsOnQuestionReferenceId: {
      comment:
        'The reference id of the question whose answer is compared by this rule',
      type: 'string',
    },
    comparisonOperator: {
      comment:
        'The operator to use when comparing the question answer to the operands',
      type: { ComparisonOperator },
    },
    comparisonOperands: {
      comment: 'The values to compare the question answer to',
      type: 'string',
      list: true,
      optional: true,
    },
  },
});

/** Override type */
export type RuleInput = SchemaToType<typeof RuleInput>;

export const NestedRuleInput = mkInput({
  name: 'NestedRuleInput',
  comment:
    'Input for a nested rule in the display logic of an assessment question',
  fields: {
    logicOperator: {
      comment: 'The operator to use when comparing the nested rules',
      type: { LogicOperator },
    },
    rules: {
      comment:
        'The rules to evaluate and be compared with to other using the LogicOperator',
      type: RuleInput,
      list: true,
      optional: true,
    },
    nestedRules: {
      comment:
        'The nested rules to add one more level of nesting to the rules. They are also compared to each other.',
      type: () => NestedRuleInput,
      list: true,
      optional: true,
    },
  },
});

/** Override type */
export type NestedRuleInput = SchemaToType<typeof NestedRuleInput>;

export const DisplayLogicInput = mkInput({
  name: 'DisplayLogicInput',
  comment: 'Input for the display logic of an assessment question',
  fields: {
    action: {
      comment: 'The action to take',
      type: { AssessmentsDisplayLogicAction },
    },
    rule: {
      comment: 'The rule to evaluate',
      type: RuleInput,
      optional: true,
    },
    nestedRule: {
      comment: 'The nested rule to evaluate',
      type: NestedRuleInput,
      optional: true,
    },
  },
});

/** Override type */
export type DisplayLogicInput = SchemaToType<typeof DisplayLogicInput>;

export const RiskAssignmentInput = mkInput({
  name: 'RiskAssignmentInput',
  comment:
    'Input for the risk to assign to an assessment question if it matches the risk logic rule',
  fields: {
    riskLevelId: {
      comment: 'The ID risk level to assign to a question.',
      type: 'id',
      modelName: 'riskLevel',
      optional: true,
    },
    riskMatrixColumnId: {
      comment: 'The ID risk matrix column to assign to a question.',
      type: 'id',
      modelName: 'riskMatrixColumn',
      optional: true,
    },
    riskMatrixRowId: {
      comment: 'The ID risk matrix row to assign to a question.',
      type: 'id',
      modelName: 'riskMatrixRow',
      optional: true,
    },
  },
});

/** Override type */
export type RiskAssignmentInput = SchemaToType<typeof RiskAssignmentInput>;

export const RiskLogicInput = mkInput({
  name: 'RiskLogicInput',
  comment: 'Input for the risk logic of an assessment question',
  fields: {
    riskAssignment: {
      comment:
        'The risk to assign to this question if the response matches the provided logic',
      type: RiskAssignmentInput,
    },
    comparisonOperator: {
      comment:
        'The operator to use when comparing the response to the operands',
      type: { ComparisonOperator },
    },
    comparisonOperands: {
      comment: 'The values to compare the response to',
      type: 'string',
      list: true,
    },
  },
});

/** Override type */
export type RiskLogicInput = SchemaToType<typeof RiskLogicInput>;

export const AssessmentQuestionRaw = mkType({
  name: 'AssessmentQuestionRaw',
  comment:
    'A question of an assessment form or template that belongs to a section.',
  fields: {
    id: {
      comment: 'The id of the assessment question',
      type: 'id',
      modelName: 'assessmentQuestion',
    },
    title: {
      comment: 'The title of the assessment question',
      type: 'string',
    },
    index: {
      comment: 'The index of the assessment question',
      type: 'int',
    },
    type: {
      comment: 'The type of the assessment question',
      type: { AssessmentQuestionType },
    },
    subType: {
      comment: 'The sub-type of the assessment question',
      type: { AssessmentQuestionSubType },
    },
    placeholder: {
      comment: 'The placeholder of the assessment question',
      type: 'string',
    },
    description: {
      comment: 'The description of the assessment question',
      type: 'string',
    },
    isRequired: {
      comment: 'Whether the assessment question is required',
      type: 'boolean',
    },
    displayLogic: {
      comment:
        'The logic for determining whether this question should show in the assessment form',
      type: 'string',
    },
    riskLogic: {
      comment:
        'The logic for determining the risk score for the assessment question',
      type: 'string',
      list: true,
    },
    requireRiskEvaluation: {
      comment: 'Whether the question requires a risk score',
      type: 'boolean',
    },
    requireRiskMatrixEvaluation: {
      comment:
        'Whether the question requires the risk to be assigned via a risk matrix',
      type: 'boolean',
    },
    riskCategories: {
      comment: 'The risk categories the risk score applies to',
      type: RiskCategory,
      list: true,
    },
    riskFramework: {
      comment: 'The risk framework the risk categories belong to',
      type: RiskFramework,
      optional: true,
    },
    riskLevel: {
      comment:
        'The risk level assigned to this question either automatically based on the response, or manually by the respondent',
      type: RiskLevel,
      optional: true,
    },
    reviewerRiskLevel: {
      comment:
        'The risk level manually assigned to this question by the reviewer',
      type: RiskLevel,
      optional: true,
    },
    riskLevelFromRiskMatrix: {
      comment:
        'The risk level calculated from the risk matrix values assigned to this question, ' +
        'either automatically based on the response, or manually by the respondent',
      type: RiskLevel,
      optional: true,
    },
    answerOptions: {
      comment: 'The possible Assessment Answers options to this question',
      type: AssessmentAnswer,
      list: true,
    },
    selectedAnswers: {
      comment: 'The selected Assessment Answers for this question',
      type: SelectedAssessmentAnswerRaw,
      list: true,
    },
    respondent: {
      comment: 'The user who responded the Assessment Question',
      type: UserPreview,
      optional: true,
    },
    attributeKey: {
      comment:
        'The attribute key whose values the question should be responded with',
      type: AttributeKeyPreview,
      optional: true,
    },
    externalRespondentEmail: {
      comment:
        'The email of the external user who responded the Assessment Question',
      type: 'string',
      optional: true,
    },
    comments: {
      comment: 'The comments to this question',
      type: AssessmentQuestionComment,
      list: true,
    },
    allowedMimeTypes: {
      comment: 'The mime types allowed for the file question',
      type: 'string',
      list: true,
    },
    updatedAt: {
      comment: 'The date the question was last answered or edited',
      type: 'Date',
    },
    referenceId: {
      comment:
        'Used to identify the question within a form or template so it can be referenced in conditional logic.',
      type: 'string',
    },
    previousSubmissions: {
      comment:
        'Selected Assessment Answers for previous submissions of this question',
      type: AssessmentAnswerSubmissionRaw,
      list: true,
    },
    allowSelectOther: {
      comment: 'Whether the question allows selecting Other',
      type: 'boolean',
    },
    syncModel: {
      comment: 'The data inventory model that the question syncs back to',
      type: { AssessmentSyncModel },
      optional: true,
    },
    syncColumn: {
      comment:
        'The column of the data inventory model that the question syncs back to',
      type: { AssessmentSyncColumn },
      optional: true,
    },
    syncRowIds: {
      comment:
        'The row IDs of the data inventory table that this question syncs back to.',
      type: 'string',
      list: true,
    },
    syncOverride: {
      comment:
        'Whether this question should override existing values in the data inventory',
      type: 'boolean',
    },
    presignedRiskFileUrl: {
      comment: 'The presigned AWS url for where the risk file is stored',
      type: 'string',
      optional: true,
    },
  },
});

/** Override type */
export type AssessmentQuestionRaw = SchemaToType<typeof AssessmentQuestionRaw>;

export const AssessmentQuestionFiltersInput = mkInput({
  name: 'AssessmentQuestionFiltersInput',
  comment: 'Inputs for filtering a list of assessment questions',
  fields: {
    ids: {
      comment: 'The ids of the assessment questions',
      type: 'id',
      modelName: 'assessmentQuestion',
      optional: true,
      list: true,
    },
    text: {
      type: 'string',
      comment:
        'Filter the comments by the text present in its title or placeholder',
      optional: true,
    },
    types: {
      comment: 'The types of the questions',
      type: { AssessmentQuestionType },
      optional: true,
      list: true,
    },
    assessmentSectionIds: {
      comment: 'The IDs of the parent sections',
      type: 'id',
      modelName: 'assessmentSection',
      optional: true,
      list: true,
    },
    respondentIds: {
      comment: 'The IDs of the users who responded the questions',
      type: 'id',
      modelName: 'user',
      optional: true,
      list: true,
    },
  },
});

/** Override type */
export type AssessmentQuestionFiltersInput = SchemaToType<
  typeof AssessmentQuestionFiltersInput
>;

export const AssessmentQuestionInput = mkInput({
  name: 'AssessmentQuestionInput',
  comment: 'Input for creating an assessment question',
  fields: {
    title: AssessmentQuestionRaw.fields.title,
    type: AssessmentQuestionRaw.fields.type,
    subType: {
      ...AssessmentQuestionRaw.fields.subType,
      optional: true,
    },
    placeholder: {
      ...AssessmentQuestionRaw.fields.placeholder,
      optional: true,
    },
    description: {
      ...AssessmentQuestionRaw.fields.description,
      optional: true,
    },
    isRequired: {
      ...AssessmentQuestionRaw.fields.isRequired,
      optional: true,
    },
    referenceId: AssessmentQuestionRaw.fields.referenceId,
    displayLogic: {
      comment:
        'The logic for determining whether this question should show in the assessment form',
      type: DisplayLogicInput,
      optional: true,
    },
    riskLogic: {
      comment:
        'The logic for determining the risk score for the assessment question',
      type: RiskLogicInput,
      list: true,
      optional: true,
    },
    riskCategoryIds: {
      comment: 'The IDs of the risk categories the risk score applies to',
      type: 'id',
      modelName: 'riskCategory',
      list: true,
      optional: true,
    },
    riskFrameworkId: {
      comment: 'The ID of the risk framework the risk score applies to',
      type: 'id',
      modelName: 'riskFramework',
      optional: true,
    },
    answerOptions: {
      comment: 'The possible answer options to this question',
      type: AssessmentAnswerOptionInput,
      optional: true,
      list: true,
    },
    allowedMimeTypes: {
      ...AssessmentQuestionRaw.fields.allowedMimeTypes,
      optional: true,
    },
    allowSelectOther: {
      ...AssessmentQuestionRaw.fields.allowSelectOther,
      optional: true,
    },
    syncModel: {
      comment:
        'The data inventory model that this assessment question syncs to',
      type: { AssessmentSyncModel },
      optional: true,
    },
    syncColumn: {
      comment:
        'The column of the data inventory that this assessment question syncs to',
      type: { AssessmentSyncColumn },
      optional: true,
    },
    attributeKeyId: {
      comment: 'The ID of the attributeKey used to respond this question.',
      type: 'id',
      modelName: 'attributeKey',
      optional: true,
    },
    requireRiskEvaluation: {
      ...AssessmentQuestionRaw.fields.requireRiskEvaluation,
      optional: true,
    },
    requireRiskMatrixEvaluation: {
      ...AssessmentQuestionRaw.fields.requireRiskMatrixEvaluation,
      optional: true,
    },
  },
});

/** Override type */
export type AssessmentQuestionInput = SchemaToType<
  typeof AssessmentQuestionInput
>;

/** Type of the full, parsed AssessmentQuestion; raw and full types are helpful when using `buildUseTransformQuery` */
export type AssessmentQuestion = Omit<
  AssessmentQuestionRaw,
  'displayLogic' | 'selectedAnswers' | 'previousSubmissions' | 'riskLogic'
> &
  Pick<AssessmentQuestionInput, 'displayLogic' | 'riskLogic'> & {
    /** Parsed selected assessment answers for this question */
    selectedAnswers: SelectedAssessmentAnswer[];
    /** Parsed previous submissions for this question */
    previousSubmissions: AssessmentAnswerSubmission[];
  };

export const CreateAssessmentQuestionInput = mkInput({
  name: 'CreateAssessmentQuestionInput',
  comment: 'Input for creating an assessment question',
  fields: {
    title: AssessmentQuestionRaw.fields.title,
    type: AssessmentQuestionRaw.fields.type,
    subType: AssessmentQuestionInput.fields.subType,
    placeholder: AssessmentQuestionInput.fields.placeholder,
    description: AssessmentQuestionInput.fields.description,
    isRequired: AssessmentQuestionInput.fields.isRequired,
    displayLogic: AssessmentQuestionInput.fields.displayLogic,
    riskLogic: AssessmentQuestionInput.fields.riskLogic,
    riskCategoryIds: AssessmentQuestionInput.fields.riskCategoryIds,
    riskFrameworkId: AssessmentQuestionInput.fields.riskFrameworkId,
    answerOptions: AssessmentQuestionInput.fields.answerOptions,
    allowedMimeTypes: AssessmentQuestionInput.fields.allowedMimeTypes,
    referenceId: AssessmentQuestionInput.fields.referenceId,
    allowSelectOther: AssessmentQuestionInput.fields.allowSelectOther,
    syncModel: AssessmentQuestionInput.fields.syncModel,
    syncColumn: AssessmentQuestionInput.fields.syncColumn,
    attributeKeyId: AssessmentQuestionInput.fields.attributeKeyId,
    assessmentSectionId: {
      comment:
        'The ID of the assessment section under which to add this question',
      type: 'id',
      modelName: 'assessmentSection',
    },
  },
});

/** Override type */
export type CreateAssessmentQuestionInput = SchemaToType<
  typeof CreateAssessmentQuestionInput
>;

export const UpdateAssessmentFormQuestionInput = mkInput({
  name: 'UpdateAssessmentFormQuestionInput',
  comment: 'Input for updating an assessment question that belongs to a form.',
  fields: {
    id: {
      comment: 'The ID of the assessment question to update',
      type: 'id',
      modelName: 'assessmentQuestion',
    },
    syncRowIds: {
      ...AssessmentQuestionRaw.fields.syncRowIds,
      optional: true,
    },
    syncOverride: {
      ...AssessmentQuestionRaw.fields.syncOverride,
      optional: true,
    },
    riskCategoryIds: {
      ...CreateAssessmentQuestionInput.fields.riskCategoryIds,
      optional: true,
    },
    riskFrameworkId: AssessmentQuestionInput.fields.riskFrameworkId,
    requireRiskEvaluation: AssessmentQuestionInput.fields.requireRiskEvaluation,
    reviewerRiskLevelId: {
      comment:
        'ID of the risk level to manually assign to this question. Must match the risk framework of the risk categories.',
      type: 'id',
      modelName: 'riskLevel',
      optional: true,
    },
    excludeRisk: {
      comment:
        'Whether to completely wipe out any risk association with the question',
      type: 'boolean',
      optional: true,
    },
  },
});

/** Override type */
export type UpdateAssessmentFormQuestionInput = SchemaToType<
  typeof UpdateAssessmentFormQuestionInput
>;

export const UpdateAssessmentTemplateQuestionInput = mkInput({
  name: 'UpdateAssessmentTemplateQuestionInput',
  comment:
    'Input for updating an assessment question in an assessment form template',
  fields: {
    id: {
      comment: 'The ID of the assessment question to update',
      type: 'id',
      modelName: 'assessmentQuestion',
    },
    index: {
      ...AssessmentQuestionRaw.fields.index,
      optional: true,
    },
    title: {
      ...CreateAssessmentQuestionInput.fields.title,
      optional: true,
    },
    type: {
      ...CreateAssessmentQuestionInput.fields.type,
      optional: true,
    },
    subType: CreateAssessmentQuestionInput.fields.subType,
    answerOptions: CreateAssessmentQuestionInput.fields.answerOptions,
    placeholder: CreateAssessmentQuestionInput.fields.placeholder,
    description: CreateAssessmentQuestionInput.fields.description,
    isRequired: CreateAssessmentQuestionInput.fields.isRequired,
    displayLogic: CreateAssessmentQuestionInput.fields.displayLogic,
    riskLogic: CreateAssessmentQuestionInput.fields.riskLogic,
    riskCategoryIds: CreateAssessmentQuestionInput.fields.riskCategoryIds,
    riskFrameworkId: CreateAssessmentQuestionInput.fields.riskFrameworkId,
    allowedMimeTypes: CreateAssessmentQuestionInput.fields.allowedMimeTypes,
    allowSelectOther: CreateAssessmentQuestionInput.fields.allowSelectOther,
    syncModel: AssessmentQuestionInput.fields.syncModel,
    syncColumn: AssessmentQuestionInput.fields.syncColumn,
    attributeKeyId: AssessmentQuestionInput.fields.attributeKeyId,
    referenceId: {
      ...AssessmentQuestionInput.fields.referenceId,
      optional: true,
    },
  },
});

/** Override type */
export type UpdateAssessmentTemplateQuestionInput = SchemaToType<
  typeof UpdateAssessmentTemplateQuestionInput
>;

export const DeleteAssessmentQuestionsInput = mkInput({
  name: 'DeleteAssessmentQuestionsInput',
  comment: 'Input for deleting multiple assessment questions',
  fields: {
    ids: {
      comment: 'The ids of the assessment questions to delete',
      type: 'id',
      modelName: 'assessmentQuestion',
      list: true,
    },
  },
});

/** Override type */
export type DeleteAssessmentQuestionsInput = SchemaToType<
  typeof DeleteAssessmentQuestionsInput
>;

export const AssessmentQuestionSelectOptionsFiltersInput = mkInput({
  name: 'AssessmentQuestionSelectOptionsFiltersInput',
  comment:
    'Inputs for getting a list of possible answer options for an assessment question',
  fields: {
    organizationId: {
      comment:
        'The id of the organization whose answer options we want to retrieve',
      type: 'id',
      modelName: 'organization',
      optional: true,
    },
    subType: {
      comment:
        'The Assessment Question subType whose option values we want to retrieve',
      type: { AssessmentQuestionSubType },
    },
    text: {
      comment: 'Filter the options by the text search',
      type: 'string',
      optional: true,
    },
    attributeKeyId: {
      comment: 'The ID of the attribute key whose values we want to retrieve',
      type: 'id',
      modelName: 'attributeKey',
      optional: true,
    },
  },
});

/** Override type */
export type AssessmentQuestionSelectOptionsFiltersInput = SchemaToType<
  typeof AssessmentQuestionSelectOptionsFiltersInput
>;

export const AssessmentQuestionSelectOptionsTokenFiltersInput = mkInput({
  name: 'AssessmentQuestionSelectOptionsTokenFiltersInput',
  comment:
    'Inputs for getting a list of possible answer options for an assessment question using a token',
  fields: {
    id: {
      comment:
        'The id of the question whose answer options we want to retrieve',
      type: 'id',
      modelName: 'assessmentQuestion',
    },
    text: {
      comment: 'Filter the options by the text search',
      type: 'string',
      optional: true,
    },
  },
});

/** Override type */
export type AssessmentQuestionSelectOptionsTokenFiltersInput = SchemaToType<
  typeof AssessmentQuestionSelectOptionsTokenFiltersInput
>;

export const AssessmentQuestionSelectOptionRaw = mkType({
  name: 'AssessmentQuestionSelectOption',
  comment: 'The possible answer options for a dynamic select-type question.',
  fields: {
    id: {
      comment:
        'The id of the option in its respective database table (not an assessment answer)',
      type: 'string',
    },
    additionalContext: {
      comment:
        'JSON stringified data to provide necessary context for displaying the option',
      type: 'string',
    },
  },
});

/** Override type */
export type AssessmentQuestionSelectOptionRaw = SchemaToType<
  typeof AssessmentQuestionSelectOptionRaw
>;

/** Type of the full, parsed AssessmentAnswer; raw and full types are helpful when using `buildUseTransformQuery` */
export type AssessmentQuestionSelectOption = Omit<
  AssessmentQuestionSelectOptionRaw,
  'additionalContext'
> & {
  /** Additional context necessary to display answers that were dynamically populated from the database */
  additionalContext: ObjByString;
};

/* eslint-enable max-lines */
