import {
  InlineTextInput,
  requestActionMessages,
} from '@main/ad-core-components';
import { StyledFlexRowCenterVertical } from '@main/ad-core-components/src/UploadCsvTable/wrappers';
import {
  RequestsProcessedDisclosureStat,
  RequestsProcessedDisclosureStatsTableHeaders,
} from '@main/pc-types';
import { RequestAction } from '@transcend-io/privacy-types';
import isNaN from 'lodash/isNaN';
import parseInt from 'lodash/parseInt';
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { CellProps, Column } from 'react-table';

import { requestsProcessedDisclosureStatsSettingsSlice } from '../../slice';
import { selectRequestsProcessedDisclosureStatsInput } from '../selectors';

export const REQUEST_TYPE_COLUMN: Column<RequestsProcessedDisclosureStat> = {
  accessor: 'type',
  minWidth: 85,
  width: 150,
  Cell: ({ value }: CellProps<RequestsProcessedDisclosureStat>) => (
    <StyledFlexRowCenterVertical>
      <FormattedMessage {...requestActionMessages[value as RequestAction]} />
    </StyledFlexRowCenterVertical>
  ),
};

export const GENERIC_STAT_COLUMN: (
  statType: RequestsProcessedDisclosureStatsTableHeaders,
  year: number,
) => Column<RequestsProcessedDisclosureStat> = (statType, year) => ({
  accessor: statType,
  minWidth: 85,
  width: 120,
  Cell: ({ value, row }: CellProps<RequestsProcessedDisclosureStat>) => {
    const dispatch = useDispatch();
    const [text, setText] = useState<string>('');
    const { valueOverrides, disabledActions } = useSelector(
      selectRequestsProcessedDisclosureStatsInput,
    );

    const currentOverride = valueOverrides?.filter(
      (override) =>
        year === override.year &&
        statType === override.statType &&
        row.original.type === override.actionType,
    )?.[0];

    // Keep text value up to date with overrides. Doing it with useEffect enable the usage of
    // the "Discard changes" button
    useEffect(() => {
      setText(currentOverride?.statValue ? `${currentOverride.statValue}` : '');
    }, [currentOverride]);

    return (
      <StyledFlexRowCenterVertical>
        <InlineTextInput
          value={text}
          placeholder={value}
          showPlaceholder
          // We still show stats for disabled rows, but don't let them be edited
          disabled={disabledActions.includes(row.original.type)}
          onChange={(e) => setText(e.target.value)}
          onBlur={(e) => {
            const statValue = parseInt(e.target.value, 10);

            // Only save the override if it's a valid int or if it's empty (unset)
            if (!isNaN(statValue) || e.target.value === '') {
              dispatch(
                requestsProcessedDisclosureStatsSettingsSlice.actions.setSingleOverride(
                  {
                    year,
                    actionType: row.original.type,
                    statType,
                    statValue: e.target.value === '' ? undefined : statValue,
                  },
                ),
              );
            } else {
              // Reset text to default if invaid number
              setText(value);
            }
          }}
        />
      </StyledFlexRowCenterVertical>
    );
  },
});
