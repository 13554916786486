import { defineMessages } from '@transcend-io/internationalization';

export const selectTeamsMessages = defineMessages(
  'ad-core-components.SelectTeams.selectTeams',
  {
    placeholder: { defaultMessage: 'Assign Teams' },
    header: {
      defaultMessage: 'Teams',
    },
  },
);
