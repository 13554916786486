import { defineMessages } from '@transcend-io/internationalization';

export const joinAssociationPreviewMessages = defineMessages(
  'ad-core-components.JoinAssociationPreview.joinAssociationPreview',
  {
    all: {
      defaultMessage: 'All',
    },
    none: {
      defaultMessage: 'None',
    },
  },
);
