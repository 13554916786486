import React from 'react';
import { useIntl } from 'react-intl';

import { byteSizeMessages } from './messages';

/**
 * Props
 */
export interface ByteSizeProps {
  /** Prop child  */
  children: number;
}

/**
 * Convert bytes to human readable format
 *
 * @param size - The size in bytes
 * @returns The human readable form
 */
function humanFileSize(size: number): string {
  const i = Math.floor(Math.log(size) / Math.log(1024));
  const exp = 1024 ** i;
  return `${(size / exp).toFixed(2)} ${['B', 'kB', 'MB', 'GB', 'TB'][i]}`;
}

/**
 * Displays bytes in string representation
 */
export function ByteSize({ children }: ByteSizeProps): JSX.Element {
  const { formatMessage } = useIntl();
  return (
    <span>
      {children
        ? humanFileSize(children)
        : formatMessage(byteSizeMessages.none)}
    </span>
  );
}
