import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { endpoints } from '@main/access-control-types';
import { ADMIN_DASHBOARD_URL } from '@main/admin-dashboard/src/args';
import { logout as logoutAction } from '@main/admin-dashboard/src/Auth/App/slice';
import { buildUseMutation, formatErrorMessage, message } from '@main/core-ui';

const useLogoutMutation = buildUseMutation(endpoints.logout);

/**
 * Hook to log out of the admin dashboard
 *
 * @returns the logout function
 */
export const useLogout = (): (() => void) => {
  const [logout, { error, data }] = useLogoutMutation();
  const dispatch = useDispatch();

  useEffect(() => {
    if (error) {
      message.error(formatErrorMessage(error.message));
    }
  }, [error]);

  useEffect(() => {
    if (data?.success) {
      dispatch(logoutAction());
      // Reload the page to be extra
      window.location.href = ADMIN_DASHBOARD_URL.addUrlParts('/login').href;
    }
  });

  return logout;
};
