import { Icon, IconType, Tooltip } from '@main/core-ui';
import { DefinedMessage } from '@transcend-io/internationalization';
import React from 'react';
import { useIntl } from 'react-intl';

import { StyledButton } from './wrappers';

interface ToolbarButtonProps {
  /** Title of the Button */
  title: DefinedMessage | string;
  /** OnClick handler when button is clicked */
  onClick: (event: React.MouseEvent<HTMLElement>) => void;
  /** Button disabled or not */
  disabled: boolean;
  /** Icon to display */
  icon: IconType;
}

/**
 * Custom Toolbar using Tooltip and Styled Button
 */
export function ToolbarButton({
  title,
  onClick,
  disabled,
  icon,
}: ToolbarButtonProps): JSX.Element {
  const { formatMessage } = useIntl();
  const titleLabel = typeof title === 'string' ? title : formatMessage(title);

  return (
    <Tooltip title={titleLabel}>
      <StyledButton
        aria-label={titleLabel}
        onClick={onClick}
        disabled={disabled}
      >
        <Icon type={icon} size={16} />
      </StyledButton>
    </Tooltip>
  );
}
