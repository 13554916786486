/**
 * Rich Editor Extensions
 */
export enum RichEditorExtension {
  Default = 'DEFAULT',
  TextStyling = 'TEXT_STYLING',
  AllText = 'ALL_TEXT',
  AllTextNoHeaders = 'ALL_TEXT_NO_HEADERS',
  Image = 'IMAGE',
  Table = 'TABLE',
}
