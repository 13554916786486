import styled from 'styled-components';

export const TabGroupRow = styled.div`
  display: flex;
  flex-direction: row;
  height: 2.5rem;
  align-items: end;
`;

export const InactiveTabGroup = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 0.2rem;
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray3};
  align-items: end;

  &:first-child {
    padding-right: 0.2rem;
  }

  &:last-child {
    padding-left: 0.2rem;
    flex-grow: 1;
  }
`;

export const Tab = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.gray3};
  border-bottom: none;
  padding: 0.5rem 1.5rem;
  border-radius: 5px 5px 0 0;
  cursor: pointer;
  transition: padding 0.5s;
`;

export const ActiveTab = styled(Tab)`
  color: ${({ theme }) => theme.colors.transcend};
  padding: 0.6rem 1.5rem;
`;

export const InactiveTab = styled(Tab)`
  height: 100%;
  background-color: ${({ theme }) => theme.colors.gray1};

  &:hover {
    padding: 0.6rem 1.5rem;
  }
`;

export const LoadingSpinnerWrapper = styled.div`
  margin-left: 6px;
  display: inline-block;
`;
