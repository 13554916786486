import { makeEnum } from '@transcend-io/type-utils';

/**
 * A class of data subjects
 */
export const MockDataSubjectClass = makeEnum({
  /** The end user for that company. This is often the situation where the business is the data controller. */
  EndUser: 'END_USER',
  /** An employee of the organization */
  Employee: 'EMPLOYEE',
  /** A business contact, sales outbound */
  BusinessContact: 'BUSINESS_CONTACT',
  /** Another category not listed */
  Other: 'OTHER',
});

/** Type override */
export type MockDataSubjectClass =
  (typeof MockDataSubjectClass)[keyof typeof MockDataSubjectClass];
