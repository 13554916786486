import styled from 'styled-components';

import { StyleUtils } from '@main/core-ui';

export const StyledStepsList = styled.ul`
  list-style-type: none;
  counter-reset: list-counter;
  padding: 0;
  margin: 0;
  position: sticky;
  top: ${StyleUtils.Spacing.md};
  height: fit-content;

  li {
    counter-increment: list-counter;
    margin: 0 0 ${StyleUtils.Spacing.l} 0;
    padding-left: ${StyleUtils.Spacing.xl};
    position: relative;

    h3 {
      margin: 0;
      font-size: 1rem;
    }
  }

  li:last-child {
    margin-bottom: 0;
  }
`;

export interface CircleLogoDisplayProps {
  /** Is the item marked as complete */
  isComplete: boolean;
}

export const CircleLogoDisplay = styled.div<CircleLogoDisplayProps>`
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  background-color: ${({ isComplete, theme }) =>
    isComplete ? theme.colors.success : '#fff'};
  color: ${({ theme }) => theme.colors.transcendNavy3};
  ${({ isComplete }) => (isComplete ? '' : 'border: 1px solid #e9ebed;')}
  border-radius: 50%;
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  font-weight: bold;
`;
