import { FilterConfigItem } from '@main/ad-core-components';
import {
  SelectAgentFunctions,
  SelectedAgentFunction,
} from '@main/admin-dashboard/src/Pathfinder/components/SelectAgentFunctions';
import { buildUseLazyQuery } from '@main/core-ui';
import { endpoints } from '@main/prompt-types';
import { ID } from '@main/schema-utils';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { MessageDescriptor } from 'react-intl';

import { filterManagerHookMessages } from './messages';

/**
 * Filters definition with property for agent function values specified
 */
type AgentFunctionEnrichedFilters = Record<string, unknown> &
  Record<
    /**
     * AgentFunction values that are currently filtering the table
     */
    string,
    SelectedAgentFunction[] | undefined
  >;

const useAgentFunctions = buildUseLazyQuery(endpoints.agentFunctions);

export const useAgentFunctionFilters = <
  T extends Record<string, unknown>,
  E extends AgentFunctionEnrichedFilters,
>({
  agentFunctionIdsFilterKey = 'agentFunctionIds',
  filters,
  setFilters,
  label,
  enrichedAgentFunctionKey = 'agentFunctions',
}: {
  /** The property name of the filter for agentFunction ids */
  agentFunctionIdsFilterKey?: keyof T;
  /** The currently applied filters */
  filters?: T;
  /** Callback when the filters are changed */
  setFilters: (filters: T) => void;
  /** Label for the filter menu */
  label: string | MessageDescriptor;
  /** The key to use if more than one useAgentFunctionFilters is used in a FilterManager */
  enrichedAgentFunctionKey?: keyof E;
}): {
  /**  The agentFunction keys enabled on this table */
  selectedAgentFunctions: SelectedAgentFunction[];
  /** The filter configuration to be passed to the filter manager */
  agentFunctionFiltersConfig: FilterConfigItem<E>;
  /** Callback for when the filter is cleared in the filter manager */
  clearAgentFunctionFilters: (key: Extract<keyof E, string>) => void;
} => {
  const getSelectedAgentFunctions = useAgentFunctions();
  const [selectedAgentFunctions, setSelectedAgentFunctions] = useState<
    SelectedAgentFunction[]
  >([]);
  const [fetchingAgentFunctions, setFetchingAgentFunctions] = useState(false);

  // Populate initial complex filter values
  useEffect(() => {
    if (filters) {
      const agentFunctionIds = (filters[agentFunctionIdsFilterKey] ??
        []) as ID<'agentFunction'>[];

      const filtersAndEnrichedFiltersMatch =
        agentFunctionIds.sort().join() ===
        selectedAgentFunctions
          .map(({ id }) => id)
          .sort()
          .join();

      if (agentFunctionIds.length === 0 && selectedAgentFunctions.length > 0) {
        setSelectedAgentFunctions([]);
      } else if (!filtersAndEnrichedFiltersMatch && !fetchingAgentFunctions) {
        // Prevent over-firing this setter while data is still being fetched
        setFetchingAgentFunctions(true);
        getSelectedAgentFunctions({
          filterBy: { ids: agentFunctionIds },
        })
          .then(({ data }) => {
            setSelectedAgentFunctions(data.nodes);
          })
          .finally(() => {
            setFetchingAgentFunctions(false);
          });
      }
    }
  }, [
    filters,
    fetchingAgentFunctions,
    getSelectedAgentFunctions,
    selectedAgentFunctions.length,
    agentFunctionIdsFilterKey,
    selectedAgentFunctions,
  ]);

  const agentFunctionFiltersConfig = useMemo(
    () =>
      ({
        filterKey: enrichedAgentFunctionKey,
        label,
        pillOptions: {
          label: ({
            filterValues: { [enrichedAgentFunctionKey]: agentFunctions = [] },
            index = 0,
          }) => {
            const model = agentFunctions[index];
            return model?.name;
          },
        },
        filter: (
          <SelectAgentFunctions
            isMulti
            placeholderDescriptor={
              filterManagerHookMessages.agentFunctionsPlaceholder
            }
            menuPosition="absolute"
            value={selectedAgentFunctions}
            showOutline={false}
            onChange={(agentFunctions) => {
              setSelectedAgentFunctions([...agentFunctions]);
              setFilters({
                ...filters,
                [agentFunctionIdsFilterKey]: agentFunctions.map(({ id }) => id),
              } as T);
            }}
          />
        ),
      }) as FilterConfigItem<AgentFunctionEnrichedFilters>,
    [
      enrichedAgentFunctionKey,
      label,
      selectedAgentFunctions,
      filters,
      agentFunctionIdsFilterKey,
    ],
  );

  const clearAgentFunctionFilters = useCallback(
    (key) => {
      if (key === enrichedAgentFunctionKey) {
        setFilters({
          ...filters,
          [agentFunctionIdsFilterKey]: [],
        } as T);
        setSelectedAgentFunctions([]);
      }
    },
    [enrichedAgentFunctionKey, filters, agentFunctionIdsFilterKey],
  );

  return {
    selectedAgentFunctions,
    agentFunctionFiltersConfig,
    clearAgentFunctionFilters,
  };
};
