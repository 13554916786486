/* eslint-disable max-lines */
import {
  buildInlineTextInputWithUpdate,
  HeaderCheckbox,
  HideableCheckbox,
  NA,
} from '@main/ad-core-components';
import {
  StyledFlexRowCenterBoth,
  StyledFlexRowCenterVertical,
} from '@main/admin-dashboard/src/DataMap/components';
import { endpoints, SoftwareDevelopmentKit } from '@main/code-scanning-types';
import { A } from '@main/core-ui';
import moment from 'moment';
import React, { ChangeEvent } from 'react';
import { CellProps, Column, HeaderProps } from 'react-table';

import { DATE_FORMAT } from '../../Assessments/constants';
import {
  PreviewSdkMetadata,
  SelectCodePackageType,
  SelectRepositories,
} from '../components';
import { InlineSoftwareDevelopmentKitsCatalogUpdate } from './InlineSoftwareDevelopmentKitsCatalogUpdate';
import { InlineSoftwareDevelopmentKitsCodePackagesUpdate } from './InlineSoftwareDevelopmentKitsCodePackagesUpdate';
import { InlineSoftwareDevelopmentKitsDocumentationLinksUpdate } from './InlineSoftwareDevelopmentKitsDocumentationLinksUpdate';
import { InlineSoftwareDevelopmentKitsOwnersUpdate } from './InlineSoftwareDevelopmentKitsOwnersUpdate';
import { InlineSoftwareDevelopmentKitsTeamsUpdate } from './InlineSoftwareDevelopmentKitsTeamsUpdate';

// TODO: https://transcend.height.app/T-31725 - attributes
const InlineText = buildInlineTextInputWithUpdate(
  endpoints.updateSoftwareDevelopmentKits,
);

export const CHECKBOX_COLUMN: (
  selectedSoftwareDevelopmentKits: Set<SoftwareDevelopmentKit['id']>,
  setSelectedSoftwareDevelopmentKits: (
    selectedSoftwareDevelopmentKits: Set<SoftwareDevelopmentKit['id']>,
  ) => void,
) => Column<SoftwareDevelopmentKit> = (
  selectedSoftwareDevelopmentKits,
  setSelectedSoftwareDevelopmentKits,
) => ({
  id: 'selected',
  accessor: 'id',
  disableResizing: true,
  width: 40,
  minWidth: 40,
  Header: ({ data }: HeaderProps<SoftwareDevelopmentKit>) => (
    <StyledFlexRowCenterBoth>
      <HeaderCheckbox
        checked={
          selectedSoftwareDevelopmentKits.size > 0 &&
          selectedSoftwareDevelopmentKits.size === data.length
        }
        onChange={() => {
          if (selectedSoftwareDevelopmentKits.size === data.length) {
            setSelectedSoftwareDevelopmentKits(new Set());
          } else {
            setSelectedSoftwareDevelopmentKits(
              new Set(data.map(({ id }: SoftwareDevelopmentKit) => id)),
            );
          }
        }}
      />
    </StyledFlexRowCenterBoth>
  ),
  Cell: ({ value }: CellProps<SoftwareDevelopmentKit>) => (
    <StyledFlexRowCenterBoth>
      <HideableCheckbox
        checked={selectedSoftwareDevelopmentKits.has(value)}
        onChange={(e: ChangeEvent<HTMLInputElement>) => {
          const newSelectedSoftwareDevelopmentKits = new Set<
            SoftwareDevelopmentKit['id']
          >(selectedSoftwareDevelopmentKits);
          if (e.target.checked) {
            newSelectedSoftwareDevelopmentKits.add(value);
          } else {
            newSelectedSoftwareDevelopmentKits.delete(value);
          }
          setSelectedSoftwareDevelopmentKits(
            newSelectedSoftwareDevelopmentKits,
          );
        }}
      />
    </StyledFlexRowCenterBoth>
  ),
});

export const NAME_COLUMN: Column<SoftwareDevelopmentKit> = {
  accessor: 'name',
  Cell: ({
    value,
    row,
  }: CellProps<SoftwareDevelopmentKit, SoftwareDevelopmentKit['name']>) => (
    <StyledFlexRowCenterVertical>
      <InlineText
        value={value}
        getValueFromReturnedData={(data) =>
          data?.softwareDevelopmentKits?.[0]?.name
        }
        updateItemWithValue={(updatedValue) => ({
          variables: {
            input: {
              softwareDevelopmentKits: [
                {
                  id: row.original.id,
                  name: updatedValue,
                },
              ],
            },
          },
        })}
      />
    </StyledFlexRowCenterVertical>
  ),
  maxWidth: 400,
  width: 240,
  minWidth: 150,
};

export const DESCRIPTION_COLUMN: Column<SoftwareDevelopmentKit> = {
  accessor: 'description',
  Cell: ({
    value,
    row,
  }: CellProps<
    SoftwareDevelopmentKit,
    SoftwareDevelopmentKit['description']
  >) => (
    <StyledFlexRowCenterVertical>
      <InlineText
        value={value}
        getValueFromReturnedData={(data) =>
          data?.softwareDevelopmentKits?.[0]?.description
        }
        updateItemWithValue={(updatedValue) => ({
          variables: {
            input: {
              softwareDevelopmentKits: [
                {
                  id: row.original.id,
                  description: updatedValue,
                },
              ],
            },
          },
        })}
      />
    </StyledFlexRowCenterVertical>
  ),

  minWidth: 150,
  width: 250,
  maxWidth: 500,
};

export const REPOSITORY_URL_COLUMN: Column<SoftwareDevelopmentKit> = {
  accessor: 'repositoryUrl',
  Cell: ({
    value,
    row,
  }: CellProps<
    SoftwareDevelopmentKit,
    SoftwareDevelopmentKit['repositoryUrl']
  >) => (
    <StyledFlexRowCenterVertical>
      <InlineText
        value={value || ''}
        getValueFromReturnedData={(data) =>
          data?.softwareDevelopmentKits?.[0]?.repositoryUrl
        }
        updateItemWithValue={(updatedValue) => ({
          variables: {
            input: {
              softwareDevelopmentKits: [
                {
                  id: row.original.id,
                  repositoryUrl: updatedValue,
                },
              ],
            },
          },
        })}
      />
    </StyledFlexRowCenterVertical>
  ),
  width: 200,
  minWidth: 150,
};

export const CREATED_AT_COLUMN: Column<SoftwareDevelopmentKit> = {
  accessor: 'createdAt',
  Cell: ({
    value,
  }: CellProps<
    SoftwareDevelopmentKit,
    SoftwareDevelopmentKit['createdAt']
  >) => (
    <StyledFlexRowCenterVertical>
      {moment(value).format(DATE_FORMAT)}
    </StyledFlexRowCenterVertical>
  ),
  width: 200,
  minWidth: 150,
};

export const OWNERS_COLUMN: Column<SoftwareDevelopmentKit> = {
  accessor: 'owners',
  Cell: ({
    row,
    value,
  }: CellProps<SoftwareDevelopmentKit, SoftwareDevelopmentKit['owners']>) => (
    <StyledFlexRowCenterVertical>
      <InlineSoftwareDevelopmentKitsOwnersUpdate
        id={row.original.id}
        value={value}
      />
    </StyledFlexRowCenterVertical>
  ),
  width: 200,
  minWidth: 150,
};

export const REGISTRY_URL_COLUMN: Column<SoftwareDevelopmentKit> = {
  accessor: 'registryUrl',
  Cell: ({
    value,
  }: CellProps<
    SoftwareDevelopmentKit,
    SoftwareDevelopmentKit['registryUrl']
  >) => (
    <StyledFlexRowCenterVertical>
      <A href={value}>{value}</A>
    </StyledFlexRowCenterVertical>
  ),
  maxWidth: 500,
  width: 260,
  minWidth: 150,
};

export const TEAMS_COLUMN: Column<SoftwareDevelopmentKit> = {
  accessor: 'teams',
  Cell: ({
    row,
    value,
  }: CellProps<SoftwareDevelopmentKit, SoftwareDevelopmentKit['teams']>) => (
    <StyledFlexRowCenterVertical>
      <InlineSoftwareDevelopmentKitsTeamsUpdate
        id={row.original.id}
        value={value}
      />
    </StyledFlexRowCenterVertical>
  ),
  width: 200,
  minWidth: 150,
};

export const CODE_PACKAGES_COLUMN: Column<SoftwareDevelopmentKit> = {
  accessor: 'codePackages',
  Cell: ({
    row,
    value,
  }: CellProps<
    SoftwareDevelopmentKit,
    SoftwareDevelopmentKit['codePackages']
  >) => (
    <StyledFlexRowCenterVertical>
      <InlineSoftwareDevelopmentKitsCodePackagesUpdate
        id={row.original.id}
        value={value}
      />
    </StyledFlexRowCenterVertical>
  ),
  maxWidth: 450,
  width: 240,
  minWidth: 150,
};

export const REPOSITORIES_COLUMN: Column<SoftwareDevelopmentKit> = {
  accessor: 'repositories',
  Cell: ({
    value,
  }: CellProps<
    SoftwareDevelopmentKit,
    SoftwareDevelopmentKit['repositories']
  >) => (
    <StyledFlexRowCenterVertical>
      <SelectRepositories value={value} isDisabled isMulti={false} />
    </StyledFlexRowCenterVertical>
  ),
  width: 220,
  minWidth: 150,
};

export const CODE_PACKAGE_TYPE_COLUMN: Column<SoftwareDevelopmentKit> = {
  accessor: 'codePackageType',
  Cell: ({
    value,
  }: CellProps<
    SoftwareDevelopmentKit,
    SoftwareDevelopmentKit['codePackageType']
  >) => (
    <StyledFlexRowCenterVertical>
      <SelectCodePackageType value={value} isDisabled isMulti={false} />
    </StyledFlexRowCenterVertical>
  ),
  width: 200,
  minWidth: 150,
};

export const DOCUMENTATION_LINKS_COLUMN: Column<SoftwareDevelopmentKit> = {
  accessor: 'documentationLinks',
  Cell: ({
    row,
    value,
  }: CellProps<
    SoftwareDevelopmentKit,
    SoftwareDevelopmentKit['documentationLinks']
  >) => (
    <StyledFlexRowCenterVertical>
      <InlineSoftwareDevelopmentKitsDocumentationLinksUpdate
        id={row.original.id}
        value={value}
      />
    </StyledFlexRowCenterVertical>
  ),
  width: 200,
  minWidth: 150,
};

export const CATALOG_COLUMN: Column<SoftwareDevelopmentKit> = {
  accessor: 'catalog',
  Cell: ({
    row,
    value,
  }: CellProps<SoftwareDevelopmentKit, SoftwareDevelopmentKit['catalog']>) => (
    <StyledFlexRowCenterVertical>
      <InlineSoftwareDevelopmentKitsCatalogUpdate
        id={row.original.id}
        value={value}
        guessedValue={row.original.guessedCatalog}
      />
    </StyledFlexRowCenterVertical>
  ),
  width: 200,
  minWidth: 150,
};

export const SDK_COLUMN: Column<SoftwareDevelopmentKit> = {
  accessor: 'sdk',
  Cell: ({
    value,
  }: CellProps<SoftwareDevelopmentKit, SoftwareDevelopmentKit['sdk']>) => (
    <StyledFlexRowCenterVertical>
      {value ? <PreviewSdkMetadata sdk={value} /> : <NA />}
    </StyledFlexRowCenterVertical>
  ),
  width: 200,
  minWidth: 150,
};
/* eslint-enable max-lines */
