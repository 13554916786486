/* eslint-disable react-hooks/rules-of-hooks */
import { localPoint } from '@visx/event';
import { scaleOrdinal } from '@visx/scale';
import React, { useMemo, useState } from 'react';
import { useTheme } from 'styled-components';

import { BarChartTooltip, BarStacks } from '../BarStacks';
import { DEFAULT_COLORS } from '../constants';
import { useChartGrowUpwardsSVGAnimation } from '../hooks';
import { getMinMaxY } from '../utils';
import { XYChartTemplate } from '../XYChartTemplate';
import { GroupedBarChartProps } from './types';
import {
  getChartDimensions,
  getGroupXRange,
  getHighlightedBarFromX,
  getParsedData,
} from './utils';
import { StyledChartContainer } from './wrappers';

export const GroupedBarChart: React.FC<GroupedBarChartProps> = ({
  data: externalData,
  noFillSeriesNames = [],
  colors = DEFAULT_COLORS,
  scale = 'linear',
  yUnitTransform,
  yAxisBottomLabel,
  yAxisTopLabel,
  hideMissingData,
  renderExtraTooltipMetadata,
  prettySeriesNameTransform,
}) => {
  const [highlightedBar, setHighlightedBar] = useState<
    | {
        /** name of group */
        groupName: string;
        /** name of bar */
        seriesName: string;
      }
    | undefined
  >();
  const theme = useTheme();

  const { barNames, barSeriesNames, groupNames, internalChartData } = useMemo(
    () => getParsedData(externalData),
    [externalData],
  );

  const legendRange = colors.map((c) => theme.colors[c]);
  const legendScale = scaleOrdinal({
    domain: barNames,
    range: legendRange,
  });

  /**
   * This sets up the XYChart by telling it that our X Axis is group names and our
   * Y axis/scale is based on the maximum Y values within those groups. I think this
   * is reasonable because XYTemplate is agnostic about what we actually plot in the
   * chart body svg, we are just passing in props to make it look right based on our data
   */
  const { min, max, remappedPoints } = useMemo(() => {
    const values = [
      ...internalChartData.map(({ series }) => getMinMaxY(series, false).min),
      ...internalChartData.map(({ series }) => getMinMaxY(series, false).max),
    ];
    const min = Math.min(...values);
    const max = Math.max(...values);
    return {
      min,
      max,
      remappedPoints: {
        series: [
          {
            name: 'primer',
            points: internalChartData.map(({ name }) => ({
              key: name,
              value: 0,
            })),
          },
        ],
      },
    };
  }, [internalChartData]);

  return (
    <XYChartTemplate
      data={remappedPoints}
      tooltip={BarChartTooltip}
      scale={scale}
      legendScale={legendScale}
      yUnitTransform={yUnitTransform}
      isStacked
      yAxisBottomLabel={yAxisBottomLabel}
      yAxisTopLabel={yAxisTopLabel}
      yMaxOverride={max}
      yMinOverride={min}
    >
      {({
        yScale,
        chartInteriorHeight,
        chartInteriorWidth,
        updateTooltip,
        hideTooltip,
        hasNegativeY,
        setPreferredTooltipPosition,
        xScale: groupXScale,
        boundingRectLeft,
        boundingRectTop,
        hiddenKeys,
      }) => {
        const barsAnimationStyles =
          useChartGrowUpwardsSVGAnimation(chartInteriorHeight);
        /* eslint-enable react-hooks/rules-of-hooks */

        const { groupWidth, barWidth } = getChartDimensions(
          externalData,
          chartInteriorWidth,
        );

        const highlightedBarFromX = getHighlightedBarFromX({
          chartInteriorWidth,
          groupNames,
          barNames,
          groupWidth,
          groupXScale,
        });

        return (
          <StyledChartContainer
            height={chartInteriorHeight}
            width={chartInteriorWidth}
            style={barsAnimationStyles}
            onMouseMove={(evt) => {
              const { x } = localPoint(evt) ?? { x: 0, y: 0 };
              const newHighlightedBar = highlightedBarFromX(x);
              if (
                newHighlightedBar.groupName !== highlightedBar?.groupName ||
                newHighlightedBar.seriesName !== highlightedBar?.seriesName
              ) {
                setHighlightedBar(newHighlightedBar);
              }
            }}
            onMouseLeave={() => setHighlightedBar(undefined)}
          >
            {internalChartData.map(({ name, series }) => (
              <BarStacks
                hideMissingData={hideMissingData}
                hasNegativeY={hasNegativeY}
                color={(k, i) => legendScale(barNames[i])}
                key={name}
                keys={barSeriesNames}
                groupHeight={chartInteriorHeight}
                groupWidth={groupWidth}
                data={series}
                xScale={
                  getGroupXRange({
                    groupXScale,
                    groupWidth,
                    barNames,
                    chartInteriorWidth,
                    groupName: name,
                    numGroups: externalData.groups.length,
                  }).scale
                }
                yScale={yScale}
                x={(d) => d.x}
                colors={colors}
                noFillSeriesNames={noFillSeriesNames}
                barWidth={barWidth}
                updateTooltip={updateTooltip}
                hideTooltip={hideTooltip}
                setPreferredTooltipPosition={setPreferredTooltipPosition}
                yUnitTransform={yUnitTransform}
                useBarColorForTooltipLegend
                highlightedX={
                  highlightedBar?.groupName === name
                    ? highlightedBar.seriesName
                    : undefined
                }
                renderExtraTooltipMetadata={renderExtraTooltipMetadata}
                groupName={name}
                prettySeriesNameTransform={prettySeriesNameTransform}
                boundingRectLeft={boundingRectLeft}
                boundingRectTop={boundingRectTop}
                hiddenKeys={hiddenKeys}
              />
            ))}
          </StyledChartContainer>
        );
      }}
    </XYChartTemplate>
  );
};
