import { endpoints } from '@main/access-control-types';
import {
  buildUseQuery,
  ErrorText,
  FlexRowCenterHorizontal,
  LoadingSpinner,
  multipleValidators,
  Size,
  StyleUtils,
  Validators,
} from '@main/core-ui';
import React from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { Controller, ControllerProps } from 'react-hook-form';

const useGoogleSiteKey = buildUseQuery(endpoints.googleSiteKey);

/**
 * Google Recaptcha
 */
export const FormReCAPTCHA: React.FC<
  Omit<ControllerProps, 'control' | 'render'> & {
    /** formMethods.control is required */
    control: any;
  }
> = (props) => {
  const { data, loading, error } = useGoogleSiteKey();

  return (
    <div style={StyleUtils.CommonMargins.vertical(Size.l)}>
      {loading && (
        <FlexRowCenterHorizontal>
          <LoadingSpinner />
        </FlexRowCenterHorizontal>
      )}
      {error ? (
        <ErrorText error={error} />
      ) : data ? (
        <Controller
          rules={{
            validate: multipleValidators([Validators.REQUIRED]),
          }}
          render={({ field }) => (
            <ReCAPTCHA onChange={field.onChange} sitekey={data.key} />
          )}
          {...props}
        />
      ) : (
        <></>
      )}
    </div>
  );
};
