import { AgentPreview, PromptPreview } from '@main/attribute-types';
import { DataSubCategoryPreview } from '@main/datamap-types';
import { mkInput, mkOrder, mkType, SchemaToType } from '@main/schema-utils';

import { ApplicationPreview } from './application';
import { ApplicationUserOrderField } from './enums';

export const ApplicationUserPreview = mkType({
  name: 'ApplicationUserPreview',
  comment: 'A preview of an application user',
  fields: {
    id: {
      comment: 'The id of the application user',
      modelName: 'appUser',
      type: 'id',
    },
    name: {
      comment: 'The title of the application user',
      type: 'string',
      optional: true,
    },
    coreIdentifier: {
      comment: 'The core identifier of the application user',
      type: 'string',
    },
    createdAt: {
      comment: 'The date the application user was created',
      type: 'Date',
    },
    updatedAt: {
      comment: 'The date the application user was last updated',
      type: 'Date',
    },
    lastSeenAt: {
      comment:
        'The date the application user was last seen interacting with an application',
      type: 'Date',
      optional: true,
    },
  },
});

/** Override type */
export type ApplicationUserPreview = SchemaToType<
  typeof ApplicationUserPreview
>;

export const ApplicationUser = mkType({
  name: 'ApplicationUser',
  comment: 'A user that was found to be interacting with an application',
  fields: {
    ...ApplicationUserPreview.fields,
    applications: {
      comment: 'The list of applications that this user has interacted with',
      list: true,
      type: ApplicationPreview,
    },
    prompts: {
      comment: 'The list of prompts that this user has interacted with',
      list: true,
      type: PromptPreview,
    },
    agents: {
      comment: 'The list of agents that this user has interacted with',
      list: true,
      type: AgentPreview,
    },
    promptRunDataSubCategories: {
      comment:
        'Data sub categories that were detected in the prompt runs for this user',
      type: DataSubCategoryPreview,
      list: true,
    },
    promptRunCount: {
      comment: 'The number of prompt runs that this app user has ran',
      type: 'int',
    },
    // TODO: https://transcend.height.app/T-31595 - add attributes
    // attributeValues: {
    //   comment: 'The attribute values used to label this assessment',
    //   type: AttributeValue,
    //   list: true,
    // },
  },
});

/** Override type */
export type ApplicationUser = SchemaToType<typeof ApplicationUser>;

export const ApplicationUserFiltersInput = mkInput({
  name: 'ApplicationUserFiltersInput',
  comment: 'Inputs for filtering a list of applicationUsers',
  fields: {
    ids: {
      comment: 'The ids of the application users',
      type: 'id',
      modelName: 'appUser',
      optional: true,
      list: true,
    },
    promptIds: {
      comment: 'The ids of the prompts that this app user has called',
      type: 'id',
      modelName: 'prompt',
      optional: true,
      list: true,
    },
    agentIds: {
      comment: 'The ids of the agents that this user has interacted with',
      type: 'id',
      modelName: 'agent',
      optional: true,
      list: true,
    },
    dataSubCategoryIds: {
      comment:
        'The ids of the data sub categories that this user has interacted with',
      type: 'id',
      modelName: 'dataSubCategory',
      optional: true,
      list: true,
    },
    applicationIds: {
      comment: 'The ids of the applications that this user has interacted with',
      type: 'id',
      modelName: 'application',
      optional: true,
      list: true,
    },
    text: {
      comment: 'Filter by text (name, coreIdentifier of the application user)',
      optional: true,
      type: 'string',
    },
    coreIdentifier: {
      comment: 'Filter by fuzzy coreIdentifier',
      optional: true,
      type: 'string',
    },
    coreIdentifiers: {
      comment: 'Filter by exact coreIdentifier',
      optional: true,
      list: true,
      type: 'string',
    },
  },
});

/** Override type */
export type ApplicationUserFiltersInput = SchemaToType<
  typeof ApplicationUserFiltersInput
>;

export const DeleteApplicationUsersInput = mkInput({
  name: 'DeleteApplicationUsersInput',
  comment: 'Input for deleting a group of application users',
  fields: {
    ids: {
      comment: 'The id of the application users to delete',
      type: 'id',
      modelName: 'appUser',
      list: true,
    },
  },
});

/** Override type */
export type DeleteApplicationUsersInput = SchemaToType<
  typeof DeleteApplicationUsersInput
>;

export const UpdateApplicationUserInput = mkInput({
  name: 'UpdateApplicationUserInput',
  comment: 'Input for updating an application user',
  fields: {
    id: {
      comment:
        'The id of the application user (necessary when updating coreIdentifier)',
      modelName: 'appUser',
      type: 'id',
      optional: true,
    },
    name: {
      comment: 'The new name of the application user',
      type: 'string',
      optional: true,
    },
    coreIdentifier: {
      comment: 'The core identifier of the application user',
      type: 'string',
      optional: true,
    },
  },
});

/** Override type */
export type UpdateApplicationUserInput = SchemaToType<
  typeof UpdateApplicationUserInput
>;

export const UpdateOrCreateApplicationUsersInput = mkInput({
  name: 'UpdateOrCreateApplicationUsersInput',
  comment: 'Input for bulk updating/creating one or more applicationUsers',
  fields: {
    applicationUsers: {
      comment:
        'List of applicationUsers with the properties that should be updated for each',
      type: UpdateApplicationUserInput,
      list: true,
    },
  },
});

/** Override type */
export type UpdateOrCreateApplicationUsersInput = SchemaToType<
  typeof UpdateOrCreateApplicationUsersInput
>;

/** Order for a applicationUsers query */
export const ApplicationUserOrder = mkOrder(
  ApplicationUser.name,
  ApplicationUserOrderField,
);
