import { resolveColor, ResolveColorOptions } from '@main/theme';
import styled from 'styled-components';

/**
 * Props for circle
 */
export interface CircleProps extends ResolveColorOptions {
  /** Height of circle */
  height?: string;
}

/**
 * A circle
 */
export const Circle = styled.span<CircleProps>`
  border-radius: 50%;
  display: inline-block;
  background-color: ${resolveColor()};
  height: ${({ height }) => height || '10px'};
  width: ${({ height }) => height || '10px'};
`;
