import { tableMessages } from '@main/ad-core-components/src/Table/messages';
import {
  FlexRowCenterVertical,
  FormatMessageArgs,
  GenericMessageDescriptor,
  Pagination,
  ReactSelect,
  StyleUtils,
  useFormatMessageGeneric,
  UsePaginationResult,
} from '@main/core-ui';
import { DefinedMessages } from '@transcend-io/internationalization';
import React, {
  ComponentType,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
} from 'react';
import {
  Column,
  PluginHook,
  TableOptions,
  useBlockLayout,
  useColumnOrder,
  useResizeColumns,
  useTable,
} from 'react-table';

import { buildColumnHeadersFromMessages } from './helpers';
import { TableView, TableViewProps } from './TableView';
import { TablePaginationWrapper } from './wrappers';

export interface PageSizeOption {
  /** the page size */
  value: number;
  /** the stringified page size */
  label: ReactNode;
}

export interface TableProps<D extends object>
  extends Omit<TableViewProps<D>, 'table'> {
  /** Column definitions */
  columns: Column<D>[];
  /** Data */
  data: D[];
  /** Message to display when data is empty */
  noDataMessage?: GenericMessageDescriptor;
  /** useTable options */
  useTableOptions?: Omit<TableOptions<D>, 'columns' | 'data'>;
  /** React Table plugins to apply (optional) */
  plugins?: PluginHook<D>[];
  /** React Table plugins to include on every table */
  defaultPlugins?: PluginHook<D>[];
  /** Wrapper for TableView (optional) */
  viewWrapper?: ComponentType;
  /** Options to activate pagination */
  paginationOptions?: {
    /** If table is paginated, set these options (optional) */
    paginationResult: UsePaginationResult;
    /** If table is paginated, provide record count (optional) */
    recordCount: number;
    /** record count message */
    recordCountMessage?: GenericMessageDescriptor;
    /** Callback to fire when page changes (optional) */
    onPageChange?: (page: number) => void;
    /** Hide the page selector */
    hidePageSizeSelector?: boolean;
  };
  /** names of columns to hide */
  hiddenColumns?: string[];
  /** Messages to use as column headers if no Header prop is provided on the column definition */
  columnHeaderMessages?: DefinedMessages;
  /** Messages to use as tooltips in column headers if no Header prop is provided on the column definition */
  columnTooltipMessages?: DefinedMessages;
  /** The column names in the order they will be displayed */
  orderedColumns?: string[];
  /** Any format message args associated with the tooltip messages */
  columnTooltipInfoArgs?: Record<string, FormatMessageArgs>;
}

/**
 * The table component.
 */
export function Table<D extends object>({
  columns: initialColumns,
  data = [],
  noDataMessage = tableMessages.noData,
  useTableOptions = {},
  plugins = [],
  defaultPlugins = [useBlockLayout, useResizeColumns, useColumnOrder],
  viewWrapper: ViewWrapper = React.Fragment,
  paginationOptions,
  hiddenColumns,
  columnHeaderMessages,
  columnTooltipMessages,
  columnTooltipInfoArgs,
  scrollHorizontally = true,
  orderedColumns,
  ...viewOptions
}: TableProps<D>): JSX.Element {
  const { formatMessageGeneric } = useFormatMessageGeneric();

  const Header = useMemo(
    () =>
      columnHeaderMessages
        ? buildColumnHeadersFromMessages(
            columnHeaderMessages,
            columnTooltipMessages,
            columnTooltipInfoArgs,
          )
        : null,
    [columnHeaderMessages, columnTooltipMessages, columnTooltipInfoArgs],
  );

  const columns = useMemo(
    () =>
      !Header
        ? initialColumns
        : initialColumns.map((column) => {
            if (!('Header' in column)) {
              return {
                ...column,
                Header,
              };
            }
            return column;
          }),
    [initialColumns, Header],
  );

  const table = useTable(
    {
      columns,
      data,
      getRowId: useCallback(
        (row, relativeIndex, parent) =>
          parent
            ? [parent.id, row.id ?? relativeIndex].join('.')
            : row.id ?? relativeIndex,
        [],
      ),
      ...(hiddenColumns
        ? {
            initialState: {
              hiddenColumns,
              columnOrder: [],
            },
          }
        : {}),
      ...useTableOptions,
    },
    ...defaultPlugins,
    ...plugins,
  );

  useEffect(() => {
    if (hiddenColumns) {
      table.setHiddenColumns(hiddenColumns);
    }
  }, [table, hiddenColumns]);

  useEffect(() => {
    if (orderedColumns && orderedColumns.length > 0) {
      table.setColumnOrder(orderedColumns);
    }
  }, [table, orderedColumns]);

  const pagination = useMemo(() => {
    if (!paginationOptions) return null;

    const {
      paginationResult: { currentPage, setCurrentPage, pageSize, setPageSize },
      recordCount = 0,
      recordCountMessage,
      onPageChange,
      hidePageSizeSelector,
    } = paginationOptions;

    return (
      <TablePaginationWrapper>
        <div style={{ fontWeight: 600 }}>
          {recordCountMessage &&
            formatMessageGeneric(recordCountMessage, {
              count: recordCount,
            })}
        </div>
        <FlexRowCenterVertical style={{ gap: StyleUtils.Spacing.sm }}>
          <Pagination
            onChange={(page) => {
              setCurrentPage?.(page);
              onPageChange?.(page);
            }}
            current={currentPage}
            total={recordCount}
            pageSize={pageSize}
            hideOnSinglePage
          />
          {!hidePageSizeSelector && (
            // Extra flex wrapper keeps the select from stretching too large
            <FlexRowCenterVertical>
              <ReactSelect<false, PageSizeOption>
                value={{
                  value: pageSize,
                  label: formatMessageGeneric(tableMessages.pageSize, {
                    number: pageSize,
                  }),
                }}
                options={[10, 25, 50, 100].map((number) => ({
                  value: number,
                  label: formatMessageGeneric(tableMessages.pageSize, {
                    number,
                  }),
                }))}
                isClearable={false}
                onChange={(selection) => {
                  if (selection) {
                    setPageSize(selection.value);
                  }
                }}
              />
            </FlexRowCenterVertical>
          )}
        </FlexRowCenterVertical>
      </TablePaginationWrapper>
    );
  }, [formatMessageGeneric, paginationOptions]);

  const { loading } = viewOptions;

  return (
    <>
      <ViewWrapper>
        <TableView
          table={table}
          scrollHorizontally={scrollHorizontally}
          {...viewOptions}
        />
        {!loading && data.length === 0 && (
          <div style={{ padding: StyleUtils.Spacing.xl, textAlign: 'center' }}>
            {formatMessageGeneric(noDataMessage)}
          </div>
        )}
      </ViewWrapper>
      {pagination}
    </>
  );
}
