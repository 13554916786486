import styled, { css } from 'styled-components';

import { NAV_LINK_VERTICAL_PADDING } from '@main/core-ui';
import { sp } from '@main/theme';

import { BootstrapFormCheckInput } from './CheckboxGroup';
import {
  CONTENT_PADDING,
  TOTAL_HEIGHT_PADDING_AND_BREADCRUMB,
} from './constants';

const checkboxStyles = css`
  position: static;
  height: ${sp(2)};
  width: ${sp(2)};
  margin: 0 auto;
`;

export const HeaderCheckbox = styled(BootstrapFormCheckInput)`
  ${checkboxStyles}
`;

export const HideableCheckbox = styled(BootstrapFormCheckInput)`
  ${checkboxStyles}
`;

export const TableWrapper = styled.div`
  flex-grow: 1;
  overflow-y: auto;

  tbody tr {
    min-height: 52px;
    background-color: ${({ theme }) => theme.colors.white};

    ${HideableCheckbox} {
      visibility: hidden;
    }

    ${HideableCheckbox}:checked,
    &:hover ${HideableCheckbox} {
      visibility: visible;
    }
  }
`;

/**
 * Used for when the content of an Admin Dashboard page should not scroll.
 * Requires a page breadcrumb and content padding.
 * Make sure this is actually the case!
 */
export const DataViewWrapper = styled.div`
  // full-height, minus the top and bottom page padding,
  // and the height and margin (same as content padding) of the breadcrumbs
  height: calc(100vh - ${TOTAL_HEIGHT_PADDING_AND_BREADCRUMB}px);
  display: flex;
  flex-direction: column;

  ${TableWrapper} {
    margin-right: -${CONTENT_PADDING}px;

    // Use linear-gradient to simulate a border on left and bottom edges.
    // Can't use border property as it will cover the table itself.
    background: linear-gradient(
        to right,
        ${({ theme }) => theme.colors.gray3} 1px,
        transparent 1px,
        transparent 100%
      ),
      linear-gradient(
        to top,
        ${({ theme }) => theme.colors.gray3} 1px,
        transparent 1px,
        transparent 100%
      );
    background-color: ${({ theme }) => theme.colors.gray1};
  }
`;

export const TabBarContainer = styled.div`
  margin-bottom: ${CONTENT_PADDING}px;
  // counteract the top padding of the nav-links to maintain consistent spacing
  margin-top: -${NAV_LINK_VERTICAL_PADDING}px;
`;
