import { AwsRegion } from '@main/utils';

import { StepAction } from './codecs';

export const SUPPORTED_AUDITOR_REGIONS = [
  AwsRegion.California,
  AwsRegion.Ireland,
] as const;

/** Union of auditor (non-GQL) region strings */
export type AuditorRegion = (typeof SUPPORTED_AUDITOR_REGIONS)[number];

/** Enum of GQL region values */
export const AuditorGQLRegion = {
  /** us-west-1 */
  California: 'California',
  /** eu-west-1 */
  Ireland: 'Ireland',
};

/** Union of AuditorGQLRegion values */
export type AuditorGQLRegionValues =
  (typeof AuditorGQLRegion)[keyof typeof AuditorGQLRegion];

export const auditorGQLToAwsRegionMap: Record<
  AuditorGQLRegionValues,
  AuditorRegion
> = {
  [AuditorGQLRegion.California]: AwsRegion.California,
  [AuditorGQLRegion.Ireland]: AwsRegion.Ireland,
};

export const auditorAwsToGQLRegionMap = Object.fromEntries(
  Object.entries(auditorGQLToAwsRegionMap).map(([a, b]) => [b, a]),
);

export const DEFAULT_BROWSER_STEPS: StepAction[] = [
  {
    action: 'scrollRandomly',
  },
  {
    action: 'sleep',
    duration: 1000,
  },
];
