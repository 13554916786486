import { defineMessages } from '@transcend-io/internationalization';

export const requestsProcessedStatsMessages = defineMessages(
  'admin-dashboard.PrivacyCenter.RequestsProcessedStats.requestsProcessedStats',
  {
    changeShowRequestsProcessedStats: {
      defaultMessage: 'Show Requests Processed Stats Table',
    },
    changeShowRequestsProcessedStatsDescription: {
      defaultMessage: 'Should the table appear in the privacy center?',
    },
    selectDisabledActions: {
      defaultMessage: 'Select Hidden Action Types',
    },
    selectDisabledActionsDescription: {
      defaultMessage:
        'Select the action types that you would like to be hidden from the stats table in the Privacy Center',
    },
    overridesTable: {
      defaultMessage: 'Edit Overrides for Table',
    },
    overridesTableDescription: {
      defaultMessage:
        'Add or remove manual overrides to the automatically calculated stats',
    },
    overridesTableButton: {
      defaultMessage: 'Edit Overrides',
    },
    overridesTableYearSelection: {
      defaultMessage: 'Select Year:',
    },
  },
);
