import {
  DEFAULT_MACROREGIONS_MAP,
  IsoCountryCode,
  MacroRegion,
} from '@transcend-io/privacy-types';

/**
 * version necessary to be able to set your own consent expiry
 *  https://transcend.height.app/T-31749 - remove once everybody has upgraded
 */
export const MINIMUM_AIRGAP_CONSENT_EXPIRY_VERSION = '8.26.10';

export const sixMonthExpiryCountries: Set<string> = new Set([
  /* France and associated countries, afaict the regions have the exact same
   * laws, the other 3 types could potentially overwrite the 6 month period */
  IsoCountryCode.FR,
  // French overseas regions
  IsoCountryCode.GF,
  IsoCountryCode.GP,
  IsoCountryCode.MQ,
  IsoCountryCode.YT,
  IsoCountryCode.RE,
  // French overseas collectivities
  IsoCountryCode.PF,
  IsoCountryCode.BL,
  IsoCountryCode.MF,
  IsoCountryCode.PM,
  IsoCountryCode.WF,
  // French Sui generis collectivity
  IsoCountryCode.NC,
  // French overeseas territory
  IsoCountryCode.TF,
  // Ireland
  IsoCountryCode.IE,
]);

export const EU_COUNTRIES: Set<string> = new Set(
  DEFAULT_MACROREGIONS_MAP[MacroRegion.EU],
);
