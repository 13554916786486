import 'antd/lib/select/style/index';

import Select, { SelectProps } from 'antd/lib/select';
import styled from 'styled-components';

/**
 * props for the select
 */
export type AntSelectProps<T = any> = SelectProps<T>;

// need to mess with the types here because styled components erase generics
/**
 * @deprecated TODO: https://transcend.height.app/T-19886 - remove ant
 */
export const AntSelect = {
  ...(styled(Select)`
    .ant-select-selection {
      border-radius: 0.6em;
    }

    .ant-select-selection__choice {
      border-radius: 0.6em;
    }
  ` as any),
  Option: Select.Option,
  OptGroup: Select.OptGroup,
};
