import { Organization } from '@main/access-control-types';
import { useGetConsentManager } from '@main/admin-dashboard/src/ConsentManager/useGetConsentManager';
import { FlexColumn, useFeatureFlags } from '@main/core-ui';
import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { matchPath, useLocation } from 'react-router-dom';

import {
  selectRequiredOrganization,
  selectRequiredUser,
} from '../../../Auth/App/selectors';
import {
  getKeyForNavItem,
  getNavItemHrefOrEvaluatedHref,
  getTo,
  isNavGateAllowed,
} from './helpers';
import { NavMenuLink } from './NavMenuLink';
import { NavMenuItem } from './types';
import { NavItemCollapsibleHeightWrapper } from './wrappers';

interface NavMenuLinkWithChildrenProps {
  /** the current nav link */
  item: NavMenuItem;
  /** is the navbar wide or skinny? */
  wideNavbar?: boolean;
}

/**
 * NavMenuSection
 */
export const NavMenuLinkWithChildren: React.FC<
  NavMenuLinkWithChildrenProps
> = ({ item, wideNavbar }) => {
  const { featureSet, tier }: Organization = useSelector(
    selectRequiredOrganization,
  );
  const featureFlags = useFeatureFlags();
  const { pathname: currentPath } = useLocation();
  const user = useSelector(selectRequiredUser);
  const organization: Organization = useSelector(selectRequiredOrganization);
  const [consentManager] = useGetConsentManager();

  const [childrenVisible, setChildrenVisible] = useState(false);
  useEffect(() => {
    const hrefOrEvaluated = getNavItemHrefOrEvaluatedHref(
      getTo(item, featureFlags),
      user,
      organization,
    );
    const someChildMatches =
      // the current item is active
      ([hrefOrEvaluated, item.basePath].some(
        (path) => path && matchPath(path, currentPath),
      ) ||
        // any of it's children are active
        item.childItems?.some((child) => {
          const childHrefOrEvaluated = getNavItemHrefOrEvaluatedHref(
            getTo(child, featureFlags),
            user,
            organization,
          );
          return (
            childHrefOrEvaluated && matchPath(childHrefOrEvaluated, currentPath)
          );
        })) ??
      false;
    // open children on route changes if navigating into a group
    if (someChildMatches) {
      setChildrenVisible(true);
    }
  }, [item, currentPath]);

  const visibleChildItems = useMemo(
    () =>
      item.childItems?.filter(
        (child) =>
          (isNavGateAllowed(child.gate, featureFlags, featureSet, tier) ||
            child.gate?.ctaPage) &&
          (child.isVisibleCustom?.({
            consentManager,
            organization,
            featureFlags,
            featureSet,
            tier,
          }) ??
            true),
      ) ?? [],
    [item.childItems, consentManager],
  );

  const childrenVisibleAndNotSkinny = childrenVisible && wideNavbar;
  const gateAllowed = isNavGateAllowed(
    item.gate,
    featureFlags,
    featureSet,
    tier,
  );

  return (
    <FlexColumn>
      <NavMenuLink
        item={item}
        gateAllowed={gateAllowed}
        wideNavbar={wideNavbar}
        childrenVisible={childrenVisibleAndNotSkinny}
        onCollapseToggle={
          visibleChildItems.length > 0
            ? () => setChildrenVisible(!childrenVisible)
            : undefined
        }
      />
      {visibleChildItems.length > 0 && gateAllowed && (
        <NavItemCollapsibleHeightWrapper
          style={{
            maxHeight: childrenVisibleAndNotSkinny
              ? visibleChildItems.length * 35
              : 0,
            opacity: childrenVisibleAndNotSkinny ? 1 : 0,
          }}
        >
          {visibleChildItems.map((child, i) => (
            <NavMenuLink
              key={getKeyForNavItem(child, i)}
              item={child}
              gateAllowed={isNavGateAllowed(
                child.gate,
                featureFlags,
                featureSet,
                tier,
              )}
              wideNavbar
            />
          ))}
        </NavItemCollapsibleHeightWrapper>
      )}
    </FlexColumn>
  );
};
