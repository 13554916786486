import { TabBarContainer } from '@main/ad-core-components/src/wrappers';
import { Tab, TabBar, useRoutePath } from '@main/core-ui';
import { AdminDashboardDataMapPath } from '@main/datamap-types';
import React from 'react';
import { useIntl } from 'react-intl';

import { inventoryTabMessages } from './messages';

export const InventoryTabs: React.FC = () => {
  const { formatMessage } = useIntl();
  const routePath = useRoutePath();

  const tabs: Tab[] = [
    {
      path: AdminDashboardDataMapPath.DataSilos,
      title: formatMessage(inventoryTabMessages.dataSilos),
    },
    {
      path: AdminDashboardDataMapPath.DataPoints,
      title: formatMessage(inventoryTabMessages.dataPoints),
    },
    {
      path: AdminDashboardDataMapPath.Vendors,
      title: formatMessage(inventoryTabMessages.vendors),
    },
    {
      path: AdminDashboardDataMapPath.DataCategories,
      title: formatMessage(inventoryTabMessages.dataCategories),
    },
    {
      path: AdminDashboardDataMapPath.Purposes,
      title: formatMessage(inventoryTabMessages.purposes),
    },
    {
      path: AdminDashboardDataMapPath.BusinessEntities,
      title: formatMessage(inventoryTabMessages.businessEntities),
    },
  ];

  return (
    <TabBarContainer>
      <TabBar activePath={routePath} tabs={tabs} />
    </TabBarContainer>
  );
};
