/* eslint-disable max-lines */
import {
  CompletedRequestStatus,
  IsoCountryCode,
  RequestAction,
  RequestOrigin,
  RequestStatus,
} from '@transcend-io/privacy-types';

import { TeamPreview, UserPreview } from '@main/access-control-types';
import { AttributeInput, AttributeValue } from '@main/attribute-types';
import { ConsentPartition, EnrichedPreference } from '@main/cm-types';
import {
  Action,
  ActionPreview,
  DataSubject,
  LocalizedTemplate,
  PostCompileStatus,
  RegionInput,
  RequestSteps,
} from '@main/datamap-types';
import { DefinedMessage } from '@main/internationalization';
import {
  mkInput,
  mkInterface,
  mkOrder,
  mkType,
  SchemaToType,
} from '@main/schema-utils';
import { DeprecatedRequestIdentifiersInput } from '@main/sombra-types';

import {
  RequestDataSiloConnectionStatus,
  RequestOrderField,
  VisualRequestDataSiloStatus,
} from './enums';
import { Profile } from './profile';
import { RequestIdentifier } from './RequestIdentifier';

// TODO: https://github.com/transcend-io/main/issues/1228 - Interface for RequestDetails
export const RequestDetails = mkType({
  name: 'RequestDetails',
  comment: 'Request details exposed when accessing a request data silo',
  fields: {
    id: {
      comment: 'The unique ID of the request',
      modelName: 'request',
      type: 'id',
    },
    createdAt: {
      comment: 'The time the request was created',
      type: 'Date',
    },
    type: {
      comment: 'The type of the request',
      type: { RequestAction },
    },
    activeType: {
      comment: 'TThe active type of the request',
      type: { RequestAction },
    },
    subjectType: {
      comment: 'The type of data subject',
      type: 'string',
    },
    status: {
      comment: 'The current status of the request',
      type: { RequestStatus },
    },
    isTest: {
      comment: 'Indicates if the request is a test request',
      type: 'boolean',
    },
    email: {
      comment: 'The main email address used for communication',
      type: 'string',
      optional: true,
    },
    coreIdentifier: {
      comment:
        'The core identifier is the global user identifier specific to the organization',
      type: 'string',
    },
  },
});

/** Override type */
export type RequestDetails = SchemaToType<typeof RequestDetails>;

export const RequestHistoryFiltersInput = mkInput({
  name: 'RequestHistoryFiltersInput',
  comment:
    'Filters for the request history. These are requests viewed on the privacy center.',
  fields: {
    status: {
      comment: 'Filter only certain request statuses',
      type: { RequestStatus },
      list: true,
      optional: true,
    },
    type: {
      comment: 'Filter only certain request types',
      type: { RequestAction },
      list: true,
      optional: true,
    },
    isActive: {
      comment:
        'Filter to only show requests that are active from the perspective of the data subject',
      type: 'boolean',
      optional: true,
    },
    partitionKeys: {
      comment:
        'The partition keys that this DSR is kicked off for (often the data controller in context)',
      type: 'string',
      optional: true,
      list: true,
    },
    partitionIds: {
      comment:
        'The partition key UUIDs that this DSR is kicked off for (often the data controller in context)',
      type: 'id',
      modelName: 'airgapPartition',
      optional: true,
      list: true,
    },
    purposeNames: {
      comment: 'Filter on requests triggered by a certain purpose change',
      type: 'string',
      list: true,
      optional: true,
    },
    purposeIds: {
      comment: 'Filter on requests triggered by a certain purpose change',
      type: 'id',
      modelName: 'purpose',
      list: true,
      optional: true,
    },
  },
});

/** Override type */
export type RequestHistoryFiltersInput = SchemaToType<
  typeof RequestHistoryFiltersInput
>;

export const RequestsContextInput = mkInput({
  name: 'RequestsContextInput',
  comment: 'The input for fetching additional context for each request',
  fields: {
    dataSiloId: {
      comment:
        'The id of the data silo to determine if each request has processed or not',
      modelName: 'dataSilo',
      optional: true,
      type: 'id',
    },
  },
});

/** Override type */
export type RequestsContextInput = SchemaToType<typeof RequestsContextInput>;

export const RequestFiltersInput = mkInput({
  name: 'RequestFiltersInput',
  comment: 'Filters that can be applied when looking up requests',
  fields: {
    text: {
      comment:
        'Find any request form inputs or request ids that are like a text string',
      optional: true,
      type: 'string',
    },
    status: RequestHistoryFiltersInput.fields.status,
    type: RequestHistoryFiltersInput.fields.type,
    isArchived: {
      comment: 'Filter for requests that are archived',
      type: 'boolean',
      optional: true,
    },
    isClosed: {
      comment: 'Filter for requests that are closed',
      type: 'boolean',
      optional: true,
    },
    isBounced: {
      comment: 'Filter for requests that are bounced',
      type: 'boolean',
      optional: true,
    },
    isOpened: {
      comment: 'Filter for requests that have reports that are opened or not',
      type: 'boolean',
      optional: true,
    },
    origin: {
      comment: 'Filter only certain request origin',
      type: { RequestOrigin },
      list: true,
      optional: true,
    },
    isTest: {
      comment: 'Filter for test or non test requests',
      type: 'boolean',
      optional: true,
    },
    isSilent: {
      comment: 'Filter for silent or non silent requests',
      type: 'boolean',
      optional: true,
    },
    isContextExpired: {
      comment: 'Filter for requests with expired CEK contexts',
      type: 'boolean',
      optional: true,
    },
    hasUnreadEmails: {
      comment: 'Filter for requests that have unread emails',
      type: 'boolean',
      optional: true,
    },
    hasChildren: {
      comment: 'Filter for requests that have children',
      type: 'boolean',
      optional: true,
    },
    isChild: {
      comment:
        'Filter for requests that were created as child requests or filter out child requests',
      type: 'boolean',
      optional: true,
    },
    missingDataSiloIds: {
      comment: 'Find requests that are not processing for these data silo IDs',
      modelName: 'dataSilo',
      optional: true,
      list: true,
      type: 'id',
    },
    subjectIds: {
      comment: 'Filter to requests for these subjects',
      modelName: 'subject',
      optional: true,
      list: true,
      type: 'id',
    },
    createdAtAfter: {
      comment: 'Filter to requests after a create date',
      optional: true,
      type: 'string',
    },
    createdAtBefore: {
      comment: 'Filter to requests before a create date',
      optional: true,
      type: 'string',
    },
    locales: {
      comment: 'Filter to requests of specific locales',
      optional: true,
      list: true,
      type: 'string', // Should be LanguageKey enum but GraphQL does not allow hyphens in enums
    },
    attributeValueIds: {
      comment: 'Filter to requests labeled with specific attribute values',
      optional: true,
      type: 'id',
      modelName: 'attributeValue',
      list: true,
    },
    countries: {
      comment: 'Filter to requests labeled with specific ISO countries',
      optional: true,
      type: { IsoCountryCode },
      list: true,
    },
    countrySubDivisions: {
      comment:
        'Filter to requests labeled with specific ISO country subdivisions',
      optional: true,
      type: 'string',
      list: true,
    },
    ownerIds: {
      comment:
        'Filter to requests by individuals who are assigned to the request',
      modelName: 'user',
      type: 'id',
      optional: true,
      list: true,
    },
    teamIds: {
      comment: 'Filter to requests by teams who are assigned to the request',
      type: 'id',
      modelName: 'team',
      list: true,
      optional: true,
    },
    hasEnricherErrors: {
      comment: 'Filter to requests that have enricher errors',
      type: 'boolean',
      optional: true,
    },
    identifierValue: {
      comment: 'Filter to requests with an exact identifier value',
      type: 'string',
      optional: true,
    },
    partitionKeys: RequestHistoryFiltersInput.fields.partitionKeys,
    partitionIds: RequestHistoryFiltersInput.fields.partitionIds,
    purposeNames: RequestHistoryFiltersInput.fields.purposeNames,
    purposeIds: RequestHistoryFiltersInput.fields.purposeIds,
  },
});

/** Override type */
export type RequestFiltersInput = SchemaToType<typeof RequestFiltersInput>;

export const RequestFiltersForDataSiloInput = mkInput({
  name: 'RequestFiltersForDataSiloInput',
  comment: 'Filters that can be applied when looking up requests',
  fields: {
    dataSiloId: { ...RequestsContextInput.fields.dataSiloId, optional: false },
    text: RequestFiltersInput.fields.text,
    status: RequestFiltersInput.fields.status,
    type: RequestFiltersInput.fields.type,
    isTest: RequestFiltersInput.fields.isTest,
    subjectIds: RequestFiltersInput.fields.subjectIds,
    isChild: RequestFiltersInput.fields.isChild,
    requestHasSilo: {
      comment: "Filter to only requests that have or don't have the silo",
      type: 'boolean',
      optional: true,
    },
    hasErrors: {
      comment: 'Filter to only requests that have errors for the silo',
      type: 'boolean',
      optional: true,
    },
  },
});

/** Override type */
export type RequestFiltersForDataSiloInput = SchemaToType<
  typeof RequestFiltersForDataSiloInput
>;

export const RequestInput = mkInput({
  name: 'RequestInput',
  comment: 'Input for creating a data subject request',
  fields: {
    type: {
      comment: 'The type of data subject request',
      type: { RequestAction },
    },
    subjectType: {
      comment: "The unique name of the organization's data subject class",
      type: 'string',
    },
    requestIdentifiers: {
      comment:
        'The identifiers to key the data subject by that need to be verified',
      optional: true,
      type: DeprecatedRequestIdentifiersInput,
    },
    region: {
      comment: 'The region that the request was submitted in',
      optional: true,
      type: RegionInput,
    },
    locale: {
      comment: 'The locale to translate the request it',
      optional: true,
      type: 'string',
    },
    details: {
      comment: 'Misc details from the data subject about their request',
      optional: true,
      type: 'string',
    },
    // TODO: https://github.com/transcend-io/main/issues/692 - implement per org
    captcha: {
      comment: 'The google recaptcha result',
      optional: true,
      type: 'string',
    },
    postCompileStatus: {
      comment:
        'The status to transition the request into after a successful compilation',
      type: { PostCompileStatus },
      optional: true,
    },
    dataSiloIds: {
      comment: 'The data silos to process for this request',
      type: 'id',
      modelName: 'dataSilo',
      optional: true,
      list: true,
    },
    ignoreDataSiloIds: {
      comment:
        // eslint-disable-next-line max-len
        'The set of data silo IDs that should NOT be processed. When specified, the default set of data silos will be calculated and then these IDs will be subtracted from that list.',
      type: 'id',
      modelName: 'dataSilo',
      optional: true,
      list: true,
    },
    parentId: {
      comment: 'The id of the parent request',
      type: 'id',
      modelName: 'request',
      optional: true,
    },
    partitionKey: {
      comment:
        'The partition key that this DSR is kicked off for (often the data controller in context)',
      type: 'string',
      optional: true,
    },
  },
});

/** Override type */
export type RequestInput = SchemaToType<typeof RequestInput>;

export const EmployeeRequestInput = mkInput({
  name: 'EmployeeRequestInput',
  comment:
    'Input for an employee to submit a data subject request on behalf of the data subject',
  fields: {
    subjectType: RequestInput.fields.subjectType,
    type: RequestInput.fields.type,
    requestIdentifiers: RequestInput.fields.requestIdentifiers,
    locale: RequestInput.fields.locale,
    details: RequestInput.fields.details,
    region: RequestInput.fields.region,
    postCompileStatus: RequestInput.fields.postCompileStatus,
    isSilent: {
      comment: 'When true, no emails should be sent to the data subject',
      type: 'boolean',
      optional: true,
    },
    requestId: {
      comment: 'The ID of the request to re-submit.',
      type: 'id',
      modelName: 'request',
      optional: true,
    },
    isTest: {
      comment: 'When true, request is a test request',
      type: 'boolean',
      optional: true,
    },
    skipSendingReceipt: {
      comment: 'When true, do not send a receipt email',
      type: 'boolean',
      optional: true,
    },
    emailReceiptTemplateId: {
      comment: 'The ID of the email template to send',
      type: 'id',
      modelName: 'template',
      optional: true,
    },
    skipWaitingPeriod: {
      comment:
        'When true, skip any waiting period associated with the workflow.',
      type: 'boolean',
      optional: true,
    },
    skipEnrichmentChecks: {
      comment:
        'The IDs of the enrichment checks that should be skipped on this workflow',
      type: 'id',
      modelName: 'enricher',
      list: true,
      optional: true,
    },
    completedRequestStatus: {
      comment:
        'When uploading a backlog of old privacy requests for the purposes of audit history,' +
        ' set this status to create a request that is in a completed state.',
      optional: true,
      type: { CompletedRequestStatus },
    },
    createdAt: {
      comment:
        'Specify a time when the request was made, else assumes time of submission.',
      optional: true,
      type: 'string',
    },
    replyToEmailAddresses: {
      comment:
        "A set of email addresses that should be CC'd on any communications send for that request.",
      optional: true,
      type: 'string',
      list: true,
    },
    dataSiloIds: RequestInput.fields.dataSiloIds,
    ignoreDataSiloIds: RequestInput.fields.ignoreDataSiloIds,
    parentId: RequestInput.fields.parentId,
    attributes: {
      comment: 'Attribute Values to label requests',
      type: AttributeInput,
      optional: true,
      list: true,
    },
    partitionKey: {
      comment:
        'The partition key that this DSR is kicked off for (often the data controller in context)',
      type: 'string',
      optional: true,
    },
  },
});

/** Override type */
export type EmployeeRequestInput = SchemaToType<typeof EmployeeRequestInput>;

export const EmployeeUpdateRequestIdentifiersInput = mkInput({
  name: 'EmployeeUpdateRequestIdentifiersInput',
  comment: 'Input for an employee to update the identifiers on a request',
  fields: {
    requestEnricherId: {
      comment: 'The id of the request enricher to update',
      modelName: 'RequestEnricher',
      type: 'id',
      optional: true,
    },
    requestId: {
      comment: 'The id of the request to update',
      modelName: 'request',
      type: 'id',
      optional: true,
    },
  },
});

/** Override type */
export type EmployeeUpdateRequestIdentifiersInput = SchemaToType<
  typeof EmployeeUpdateRequestIdentifiersInput
>;

export const DsrPurposeChange = mkType({
  name: 'DsrPurposeChange',
  comment:
    'Represents a change in a consent or communication purpose for a request',
  fields: {
    consent: {
      comment:
        'The boolean value of the consent that was changed for this purpose',
      type: 'boolean',
    },
    title: {
      comment: 'The display title of the purpose change',
      type: 'string',
    },
    name: {
      comment: 'The unique name of the purpose ame',
      type: 'string',
    },
    preferences: {
      comment:
        'The set of preferences tied to the purpose change event - this is a JSON object stringified',
      type: 'string',
    },
    enrichedPreferences: {
      comment: 'The set of preference options enriched with metadata',
      type: EnrichedPreference,
      list: true,
    },
  },
});

/** Override type */
export type DsrPurposeChange = SchemaToType<typeof DsrPurposeChange>;

export const RequestInterface = mkInterface({
  name: 'RequestInterface',
  comment: 'The interface for a data subject request',
  fields: {
    id: {
      comment: 'The unique ID of the data subject request',
      modelName: 'request',
      type: 'id',
    },
    createdAt: {
      comment: 'The time the request was made',
      type: 'Date',
    },
    updatedAt: {
      comment: 'The time the request was last updated',
      type: 'Date',
    },
    lastTransitionTime: {
      comment: 'The time the request was last transitioned',
      type: 'Date',
      optional: true,
    },
    secondaryStartTime: {
      comment: 'The time at which the secondary action will begin',
      type: 'Date',
      optional: true,
    },
    status: {
      comment: 'The current status of the request',
      type: { RequestStatus },
    },
    type: {
      comment: 'The type of request being made',
      type: { RequestAction },
    },
    activeType: {
      comment: 'The active type of the request being made',
      type: { RequestAction },
    },
    postCompileStatus: {
      comment:
        'The status to transition the request into after a successful compilation',
      type: { PostCompileStatus },
      optional: true,
    },
    subjectType: {
      comment: 'The type of data subject, unique to the organization',
      type: 'string',
    },
    locale: {
      comment: 'The locale the request should be translated to',
      type: 'string',
    },
    details: {
      comment: 'Additional details submit with the request',
      type: 'string',
      optional: true,
    },
    report: {
      comment: 'The DSR report template to send',
      type: LocalizedTemplate,
      optional: true,
    },
    secondaryReport: {
      comment:
        'The DSR secondary report template to send after deletion is completed',
      type: LocalizedTemplate,
      optional: true,
    },
    reportSendTime: {
      comment: 'The time the compiling phase was completed',
      type: 'Date',
      optional: true,
    },
    secondaryReportSendTime: {
      comment: 'The time the ERASURE report was sent',
      type: 'Date',
      optional: true,
    },
    reportOpenedTime: {
      comment: 'The time the report was opened if a download link was included',
      type: 'Date',
      optional: true,
    },
    isOpened: {
      comment:
        'Indicates if report has been opened, else undefined if not relevant in the context',
      type: 'boolean',
      optional: true,
    },
    canDownload: {
      comment: 'Indicator as to whether the request can be downloaded',
      type: 'boolean',
    },
    canViewByDataSubject: {
      comment:
        'Indicator as to whether the request can be viewable by data subject on Privacy Center',
      type: 'boolean',
    },
    canRevoke: {
      comment: 'Indicator as to whether the request can be revoked',
      type: 'boolean',
    },
    isActive: {
      comment:
        "Indicator as to whether the request is active from the employee's perspective",
      type: 'boolean',
    },
    isClosed: {
      comment: 'Indicator as to whether the request is completed',
      type: 'boolean',
    },
    isArchived: {
      comment: 'Indicator as to whether the request has been archived',
      type: 'boolean',
    },
    isActiveDataSubject: {
      comment:
        'Indicator as to whether the request is active from the data subjects perception',
      type: 'boolean',
    },
    email: {
      comment: 'The email to communicate with',
      type: 'string',
      optional: true,
    },
    coreIdentifier: {
      comment:
        'The core identifier is the global user identifier specific to the organization',
      type: 'string',
    },
    isContextExpired: {
      comment: 'Is the Encrypted CEK context for a request expired',
      type: 'boolean',
    },
    country: {
      comment: 'ISO country where data silo has data in',
      type: { IsoCountryCode },
      optional: true,
    },
    countrySubDivision: {
      comment: 'ISO country where data silo has data in',
      type: 'string',
      optional: true,
    },
    purpose: {
      comment:
        'The consent purpose that triggered this DSR and its boolean value',
      type: DsrPurposeChange,
      optional: true,
    },
    partition: {
      comment:
        'The partition key that this DSR is kicked off for (often the data controller that the request is scoped to)',
      type: ConsentPartition,
      optional: true,
    },
  },
});

/** Override type */
export type RequestInterface = SchemaToType<typeof RequestInterface>;

export const RequestHistory = mkType({
  name: 'RequestHistory',
  comment: 'A preview of a data subject request',
  interfaces: [RequestInterface],
  fields: {
    ...RequestInterface.fields,
    subject: {
      comment: 'The data subject class information',
      type: DataSubject,
    },
    action: {
      comment: 'The action config',
      type: Action,
    },
    zipFileName: {
      comment:
        'The name of the zip file to be downloaded to, this is constructed on the browser',
      type: 'string',
    },
  },
});

/** Override type */
export type RequestHistory = SchemaToType<typeof RequestHistory>;

/** Order for a requestHistory query */
export const RequestHistoryOrder = mkOrder(
  RequestHistory.name,
  RequestOrderField,
);

/** Override type */
export type RequestHistoryOrder = SchemaToType<typeof RequestHistoryOrder>;

export const Request = mkType({
  name: 'Request',
  comment: 'Represents a data subject request',
  interfaces: [RequestInterface],
  fields: {
    ...RequestInterface.fields,
    subject: {
      comment: 'The data subject class information',
      type: DataSubject,
    },
    report: {
      comment: 'The DSR report template to send',
      type: LocalizedTemplate,
      optional: true,
    },
    owners: {
      comment: 'The list of individual users who are assigned to this request',
      list: true,
      type: UserPreview,
    },
    teams: {
      comment: 'The list of teams who are assigned to this request',
      list: true,
      type: TeamPreview,
    },
    action: {
      comment: 'The data subject action configuration',
      type: ActionPreview,
    },
    overdueTime: {
      comment: 'The total number of days that the request is valid for',
      type: 'int',
    },
    completionDuration: {
      comment:
        'If the request is completed, the total amount of time it took to complete the request, in minutes.',
      type: 'float',
      optional: true,
    },
    overduePercentage: {
      comment: 'The percentage of time until the request is overdue',
      type: 'float',
    },
    daysRemaining: {
      comment: 'The number of days remaining until overdue',
      optional: true,
      type: 'int',
    },
    bounced: {
      comment:
        'The number of times that the emails in the request have bounced',
      type: 'int',
    },
    isTest: RequestDetails.fields.isTest,
    isSilent: {
      comment:
        'Indicates if the request is silent and should not be sending messages',
      type: 'boolean',
    },
    origin: {
      comment: 'Indicates by what means the request was made',
      type: { RequestOrigin },
    },
    link: {
      comment: 'The link the request lives at on the admin dashboard',
      type: 'string',
    },
    steps: {
      comment: 'The workflow for the request',
      type: RequestSteps,
    },
    parentId: {
      comment: 'ID of the parent request',
      type: 'id',
      modelName: 'request',
      optional: true,
    },
    openChildRequestId: {
      comment: 'ID of an open child request of the same type as this one',
      type: 'id',
      modelName: 'request',
      optional: true,
    },
    replyToEmailAddresses: {
      comment:
        "A set of email addresses that should be CC'd on any communications send for that request.",
      type: 'string',
      list: true,
    },
    dataSiloConnectionStatus: {
      comment:
        'The status of the connection between the request and the data silo in context',
      type: { RequestDataSiloConnectionStatus },
      optional: true,
    },
    attributeValues: {
      comment: 'The attribute values used to label this request',
      type: AttributeValue,
      list: true,
    },
  },
});

/** Override type */
export type Request = SchemaToType<typeof Request>;

export const RequestForDataSilo = mkType({
  name: 'RequestForDataSilo',
  comment: 'Represents a data subject request',
  fields: {
    id: RequestInterface.fields.id,
    createdAt: RequestInterface.fields.createdAt,
    status: RequestInterface.fields.status,
    type: RequestInterface.fields.type,
    email: RequestInterface.fields.email,
    coreIdentifier: RequestInterface.fields.coreIdentifier,
    subjectType: RequestInterface.fields.subjectType,
    isTest: Request.fields.isTest,
    parentId: Request.fields.parentId,
    openChildRequestId: Request.fields.openChildRequestId,
    dataSiloConnectionStatus: Request.fields.dataSiloConnectionStatus,
    requestDataSiloStatus: {
      comment: 'The current status of the request/data silo combo',
      type: { VisualRequestDataSiloStatus },
      optional: true,
    },
    requestFileCount: {
      comment:
        'The number of request files that this request data silo has resolved',
      type: 'int',
      optional: true,
    },
    profileCount: {
      comment: 'The number of profiles that this request has resolved',
      type: 'int',
      optional: true,
    },
    requestIdentifierCount: {
      comment:
        'The number of request identifiers that this request has resolved',
      type: 'int',
      optional: true,
    },
    scheduledAt: {
      comment: 'When the next action is scheduled',
      type: 'Date',
      optional: true,
    },
    errorCount: {
      comment: 'The number of errors for the request',
      type: 'int',
    },
  },
});

/** Override type */
export type RequestForDataSilo = SchemaToType<typeof RequestForDataSilo>;

/** Order for a requests query */
export const RequestOrder = mkOrder(Request.name, RequestOrderField);

/** Override type */
export type RequestOrder = SchemaToType<typeof RequestOrder>;

export const FireSecondaryActionInput = mkInput({
  name: 'FireSecondaryActionInput',
  comment: "Input for manually firing a request's secondary action",
  fields: {
    id: {
      comment: 'The unique ID of the data subject request',
      modelName: 'request',
      type: 'id',
    },
  },
});

/** Override type */
export type FireSecondaryActionInput = SchemaToType<
  typeof FireSecondaryActionInput
>;

export const ReducedRequestPreview = mkType({
  name: 'ReducedRequestPreview',
  comment:
    'Represents a subset of the data subject request fields merged with fields ' +
    'to be consumed by the bulk request completion UI',
  fields: {
    id: {
      comment: 'The unique ID of the request',
      modelName: 'request',
      type: 'id',
    },
    type: {
      comment: 'The type of request being made',
      type: { RequestAction },
    },
    status: {
      comment: 'The status of request being made',
      type: { RequestStatus },
    },
    email: {
      comment: 'The email to communicate with',
      type: 'string',
      optional: true,
    },
    daysRemaining: {
      comment: 'The number of days remaining until overdue',
      optional: true,
      type: 'int',
    },
    postCompileStatus: {
      comment:
        'The status to transition the request into after a successful compilation',
      type: { PostCompileStatus },
      optional: true,
    },
    overdueTime: {
      comment: 'The total number of days that the request is valid for',
      type: 'int',
    },
    requestIdentifiers: {
      comment: 'The request identifiers to lookup the data subject by',
      list: true,
      type: RequestIdentifier,
    },
    country: RequestInterface.fields.country,
    countrySubDivision: RequestInterface.fields.countrySubDivision,
    attributeValues: {
      comment: 'The attribute values used to label this request',
      type: AttributeValue,
      list: true,
    },
    subjectType: {
      comment: 'The type of the data subject, unique to the organization',
      type: 'string',
    },
    details: {
      comment: 'Details submitted in the request form',
      type: 'string',
      optional: true,
    },
    subjectTitle: {
      comment: 'The title of the data subject',
      type: DefinedMessage,
    },
  },
});

/** Override type */
export type ReducedRequestPreview = SchemaToType<typeof ReducedRequestPreview>;

export const BulkCompletionReducedRequest = mkType({
  name: 'BulkCompletionReducedRequest',
  comment:
    'Represents a subset of the data subject request fields merged with fields from ' +
    'the relevant data silo to be consumed by the bulk request completion UI',
  fields: {
    id: {
      comment: 'The unique ID of the request dataSilo',
      modelName: 'RequestDataSilo',
      type: 'id',
    },
    createdAt: {
      comment: 'The time the request was made',
      type: 'Date',
    },
    updatedAt: {
      comment: 'The time the request was last updated',
      type: 'Date',
    },
    link: {
      comment: 'Link directly to request data silo view',
      type: 'string',
    },
    details: {
      comment: 'Notes specific to this RequestDataSilo',
      type: 'string',
    },
    status: {
      comment: 'Status of the RequestDataSilos',
      type: { VisualRequestDataSiloStatus },
    },
    accessRequestProfiles: {
      comment:
        'The set of profiles that need to be uploaded for an ACCESS request.',
      type: Profile,
      list: true,
    },
    request: {
      comment: 'Request specific metadata',
      type: ReducedRequestPreview,
    },
  },
});

/** Override type */
export type BulkCompletionReducedRequest = SchemaToType<
  typeof BulkCompletionReducedRequest
>;

export const UpdateRequestDataSiloDetailsInput = mkInput({
  name: 'UpdateRequestDataSiloDetailsInput',
  comment: 'Input for updating a request data silo details',
  fields: {
    id: {
      comment: 'ID of RequestDataSilo to update details for',
      type: 'id',
      modelName: 'RequestDataSilo',
    },
    token: {
      comment: 'The token to validate (required if not logged in)',
      type: 'string',
      optional: true,
    },
    details: {
      comment: 'The request details to update',
      type: 'string',
    },
  },
});

/** Override type */
export type UpdateRequestDataSiloDetailsInput = SchemaToType<
  typeof UpdateRequestDataSiloDetailsInput
>;

export const BulkCompletionReducedRequestInput = mkInput({
  name: 'BulkCompletionReducedRequestInput',
  comment: 'Filters that can be applied when looking up requests',
  fields: {
    token: {
      comment: 'The token to validate (required if not logged in)',
      type: 'string',
      optional: true,
    },
    isResolved: {
      comment: 'Filter for requests that have been resolved or not',
      type: 'boolean',
    },
    dataSiloId: {
      comment: 'the id for the data silo (required if token not specified)',
      type: 'id',
      modelName: 'dataSilo',
      optional: true,
    },
    communicationId: {
      comment: 'The id for the communication to filter by',
      type: 'id',
      modelName: 'communication',
      optional: true,
    },
    requestType: {
      comment: 'The requestType to filter by',
      type: { RequestAction },
      optional: true,
    },
  },
});

/** Override type */
export type BulkCompletionReducedRequestInput = SchemaToType<
  typeof BulkCompletionReducedRequestInput
>;

/* eslint-enable max-lines */
