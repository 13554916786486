import * as t from 'io-ts';
import { DateFromNumber } from 'io-ts-types/lib/DateFromNumber';

import { partialRecord, valuesOf } from '@transcend-io/type-utils';

import { AnalyticsDimension, AnalyticsEvent } from './schema/enums';

/**
 * A summary of an interval for an analytics event
 *
 * Used for chart types like pie charts
 */
export const AnalyticsSummary = t.type({
  /** The number of a given event that occurred in a given timeframe */
  measure: t.number,
});

/** Type override */
export type AnalyticsSummary = t.TypeOf<typeof AnalyticsSummary>;

/**
 * A summary of an interval for an analytics event with a timestamp
 *
 * Used for chart types like line charts
 */
export const TimeseriesItem = t.intersection([
  AnalyticsSummary,
  t.type({
    /** The (epoch) time in seconds */
    time: DateFromNumber,
  }),
]);

/** Type override */
export type TimeseriesItem = t.TypeOf<typeof TimeseriesItem>;

/** Request for analytics data in an aggregated format, like for a pie chart */
export const TelemetryAnalyticsAggregateQuery = t.intersection([
  // required fields
  t.type({
    /** ID ofo the consent manager to lookup analytics for */
    consentManagerId: t.string,
    /** Start time of the window to lookup analytics data. Time is in epoch seconds */
    start: t.number,
    /** End time of the window to lookup analytics data. Time is in epoch seconds */
    end: t.number,
    /** The metric to query data about */
    metric: valuesOf(AnalyticsEvent),
  }),
  // optional fields
  t.partial({
    /** Exact match filters to apply to the query. Dimensions are tag-like metadata about metrics */
    filters: partialRecord(valuesOf(AnalyticsDimension), t.string),
    /** And dimensions specified here will be included in each row of the output */
    includeDimensions: t.array(valuesOf(AnalyticsDimension)),
    /** A raw filter string specified by the backend. Must start with AND */
    rawFilterString: t.string,
    /** A list of raw projection strings to include in the query */
    rawSelectColumns: t.record(t.string, t.string),
    /** The unique id for the request */
    uniqueId: t.string,
  }),
]);

/** Type override */
export type TelemetryAnalyticsAggregateQuery = t.TypeOf<
  typeof TelemetryAnalyticsAggregateQuery
>;

/** Response for analytics data in an aggregated format */
export const TelemetryAnalyticsAggregateResponse = t.array(
  t.intersection([
    // required fields
    t.type({
      /** The sum of the aggregated data for this binned data point */
      measureValue: t.number,
    }),
    // optional fields
    t.partial({
      /** Any dimension from `includeDimensions` in the query should appear here */
      dimensions: partialRecord(valuesOf(AnalyticsDimension), t.string),
      /** A list of raw projection results */
      rawSelectValues: t.record(t.string, t.string),
    }),
  ]),
);
/** Type override */
export type TelemetryAnalyticsAggregateResponse = t.TypeOf<
  typeof TelemetryAnalyticsAggregateResponse
>;

/** Request for analytics data in a timeseries format */
export const TelemetryAnalyticsTimeseriesQuery = t.intersection([
  // required fields
  t.type({
    /** ID ofo the consent manager to lookup analytics for */
    consentManagerId: t.string,
    /** Start time of the window to lookup analytics data. Time is in epoch seconds */
    start: t.number,
    /** End time of the window to lookup analytics data. Time is in epoch seconds */
    end: t.number,
    /**
     * The width of the data points in the timeseries data outputs. Each data point
     * will be rounded to the nearest of this interval.
     *
     * Any string supported by `parse_duration` in a Timestream query is supported.
     * See: https://docs.aws.amazon.com/timestream/latest/developerguide/date-time-functions.html
     */
    binInterval: t.string,
    /** The metric to query data about */
    metric: valuesOf(AnalyticsEvent),
  }),
  // optional fields
  t.partial({
    /** Exact match filters to apply to the query. Dimensions are tag-like metadata about metrics */
    filters: partialRecord(valuesOf(AnalyticsDimension), t.string),
    /** And dimensions specified here will be included in each row of the output */
    includeDimensions: t.array(valuesOf(AnalyticsDimension)),
    /** A raw filter string specified by the backend. Must start with AND */
    rawFilterString: t.string,
    /** A list of raw projection strings to include in the query */
    rawSelectColumns: t.record(t.string, t.string),
    /** The unique id for the request */
    uniqueId: t.string,
  }),
]);

/** Type override */
export type TelemetryAnalyticsTimeseriesQuery = t.TypeOf<
  typeof TelemetryAnalyticsTimeseriesQuery
>;

/** Response for analytics data in a timeseries format */
export const TelemetryAnalyticsTimeseriesResponse = t.array(
  t.intersection([
    // required fields
    t.type({
      /** The binned time of the data point in epoch seconds */
      time: t.number,
      /** The sum of the aggregated data for this binned data point */
      measureValue: t.number,
    }),
    // optional fields
    t.partial({
      /** Any dimension from `includeDimensions` in the query should appear here */
      dimensions: partialRecord(valuesOf(AnalyticsDimension), t.string),
      /** A list of raw projection results */
      rawSelectValues: t.record(t.string, t.string),
    }),
  ]),
);
/** Type override */
export type TelemetryAnalyticsTimeseriesResponse = t.TypeOf<
  typeof TelemetryAnalyticsTimeseriesResponse
>;
