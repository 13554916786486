import {
  Badge,
  ErrorAlert,
  FlexColumn,
  FlexRow,
  FlexRowCenterVertical,
  GenericMessageDescriptor,
  Icon,
  LoadingSpinner,
  StyleUtils,
  Tooltip,
  useFormatMessageGeneric,
} from '@main/core-ui';
import React, { ReactNode, useState } from 'react';

import { Button } from '../Button';
import { Card } from '../Card';
import { DropdownItem, DropdownMenu } from '../DropdownMenu';
import { InfoTooltip } from '../InfoTooltip';
import { DashboardCachedAt, DashboardCachedAtProps } from './DashboardCachedAt';
import { dashboardTileMessages } from './messages';

export interface DashboardTileProps
  extends Pick<DashboardCachedAtProps, 'onClickForceRefetch' | 'cachedAt'> {
  /** the primary header */
  header: GenericMessageDescriptor;
  /** the secondary header */
  subheader?: GenericMessageDescriptor;
  /** the extra info text to show via tooltip */
  info?: GenericMessageDescriptor;
  /** the slot for any custom actions for the tile */
  actions?: ReactNode;
  /** the percent change to show for the tile */
  percentChange?: number;
  /** if true, treat negative percents as good and positive as bad? */
  invertPercentColor?: boolean;
  /** the tile contents */
  children?: ReactNode;
  /** is it loading? */
  loading?: boolean;
  /** is it double wide? */
  wide?: boolean;
  /** does it have an error? */
  error?: any;
  /** the options for the dropdown menu */
  menuOptions?: DropdownItem[];
  /** whether to display the content beneath or to the right of the header and subheader */
  isColumnLayout?: boolean;
  /** whether the actions are hidden under a collapse */
  collapsible?: boolean;
}

/**
 * DashboardTile
 */
export const DashboardTile: React.FC<DashboardTileProps> = ({
  header,
  subheader,
  info,
  actions,
  percentChange,
  children,
  cachedAt,
  loading,
  error,
  wide,
  menuOptions,
  invertPercentColor,
  onClickForceRefetch,
  isColumnLayout = true,
  collapsible = false,
}) => {
  const { formatMessageGeneric } = useFormatMessageGeneric();
  const [showContents, setShowContents] = useState(!collapsible);
  return (
    <Card
      style={{
        ...(isColumnLayout
          ? StyleUtils.Flex.Column.base
          : {
              ...StyleUtils.Flex.Row.base,
              ...StyleUtils.Flex.SpaceBetween,
              alignItems: 'center',
            }),
        gap: StyleUtils.Spacing.md,
        gridColumn: wide ? '1 / span 2' : undefined,
      }}
    >
      <FlexColumn style={isColumnLayout ? {} : StyleUtils.Flex.SpaceBetween}>
        <FlexRowCenterVertical style={StyleUtils.Flex.SpaceBetween}>
          <FlexRowCenterVertical style={{ gap: StyleUtils.Spacing.sm }}>
            <h2 style={{ margin: 0 }}>{formatMessageGeneric(header)}</h2>
            {info && (
              <InfoTooltip
                contents={info}
                popoverWidth="500px"
                trigger="click"
              />
            )}
            {percentChange && (
              <Tooltip
                title={formatMessageGeneric(
                  dashboardTileMessages.percentChangeTooltip,
                )}
              >
                <Badge
                  round
                  color={
                    percentChange > 0 === !invertPercentColor ? 'mint3' : 'red3'
                  }
                >
                  {percentChange > 0 ? '+' : ''}
                  {Math.round(percentChange * 10) / 10}%
                </Badge>
              </Tooltip>
            )}
            {(onClickForceRefetch || cachedAt) && (
              <DashboardCachedAt
                cachedAt={cachedAt}
                loading={loading}
                onClickForceRefetch={onClickForceRefetch}
              />
            )}
          </FlexRowCenterVertical>
          <FlexRowCenterVertical style={{ gap: StyleUtils.Spacing.sm }}>
            {actions}
            {menuOptions && <DropdownMenu items={menuOptions} />}
            {collapsible && (
              <Button
                variant="secondary"
                icon={<Icon type={showContents ? 'caret-up' : 'caret-down'} />}
                size="sm"
                onClick={() => setShowContents(!showContents)}
                noBorder
              />
            )}
          </FlexRowCenterVertical>
        </FlexRowCenterVertical>
        {subheader && (
          <FlexRow
            style={
              isColumnLayout ? { paddingBottom: StyleUtils.Spacing.l } : {}
            }
          >
            {formatMessageGeneric(subheader)}
          </FlexRow>
        )}
      </FlexColumn>

      {showContents && children}

      {loading && <LoadingSpinner />}
      {error && <ErrorAlert error={error} />}
    </Card>
  );
};
