import { defineMessages } from '@transcend-io/internationalization';

import { PrivacyCenterEmailSettingsKey } from './types';

export const emailSettingsMessages = defineMessages(
  'admin-dashboard.PrivacyRequests.EmailSettings.Configuration.emailSettings',
  {
    // needed for loadable
    header: {
      defaultMessage: 'Email Settings',
    },
    configuration: {
      defaultMessage: 'Configuration',
    },
    testing: {
      defaultMessage: 'Testing',
    },
    supportEmailTitle: {
      defaultMessage: 'Support Email',
    },
    replyToEmailTitle: {
      defaultMessage: 'Reply-To Email',
    },
    replyToEmailDescription: {
      defaultMessage:
        'The email address that should be used as a reply-to for all outbound emails sent to the data subject.',
    },
    emailPrefixTitle: {
      defaultMessage: 'Email Prefix',
    },
    emailPrefixDescription: {
      defaultMessage:
        'The email prefix that should be used for all outbound emails sent to the data subject.',
    },
    useCustomEmailDomainTitle: {
      defaultMessage: 'Use custom email domain',
    },
    useCustomEmailDomainDescription: {
      defaultMessage:
        'When turned on, the configured custom email domain will be used for all outbound emails sent to the data subject.',
    },
    supportEmailDescription: {
      defaultMessage:
        'An email address to show on the privacy center that your data subjects can reach out to for support.',
    },
    useNoReplyEmailAddressTitle: {
      defaultMessage: 'Use "no-reply" email address',
    },
    useNoReplyEmailAddressDescription: {
      /* eslint-disable-next-line max-len */
      defaultMessage: `When turned on, data subject communications initiated by the privacy center will come from a "no-reply" email address.`,
    },
    customDomainDisabledError: {
      defaultMessage: 'Verified Email Domains are required to use this feature',
    },
    prefixValidationError: {
      defaultMessage:
        'Please enter only an email prefix (everything before the @), no spaces allowed',
    },
    emailDomainsDescription: {
      defaultMessage: 'To configure custom Email Domains, please go to {link}.',
    },
    privacyCenter: {
      defaultMessage: 'Privacy Center',
    },
    testEmailSettings: {
      defaultMessage: 'Test Email Settings',
    },
    testEmailSettingsDescription: {
      defaultMessage:
        'Send a test email to confirm that the current email settings function correctly. This can help ' +
        'determine if the configured custom email domains are working correctly or help identify network issues ' +
        'for self-hosted sombras. Statuses are inferred from error messages so may not be entirely accurate. ' +
        'Consult Support for a more detailed investigation',
    },
  },
);

export const privacyCenterEmailSettingsMessages =
  defineMessages<PrivacyCenterEmailSettingsKey>(
    'admin-dashboard.PrivacyRequests.EmailSettings.Configuration.privacyCenterEmailSettings',
    {
      [PrivacyCenterEmailSettingsKey.SupportEmail]: {
        defaultMessage: 'Support Email',
      },
      [PrivacyCenterEmailSettingsKey.ReplyToEmail]: {
        defaultMessage: 'Reply-To Email',
      },
      [PrivacyCenterEmailSettingsKey.EmailPrefix]: {
        defaultMessage: 'Email Prefix',
      },
      [PrivacyCenterEmailSettingsKey.UseCustomEmailDomain]: {
        defaultMessage: 'Use custom email domain',
      },
      [PrivacyCenterEmailSettingsKey.UseNoReplyEmailAddress]: {
        defaultMessage: 'Use "no-reply" email address',
      },
    },
  );

export const privacyCenterEmailSettingsDescriptionMessages =
  defineMessages<PrivacyCenterEmailSettingsKey>(
    'admin-dashboard.PrivacyRequests.EmailSettings.Configuration.privacyCenterEmailSettingsDescription',
    {
      [PrivacyCenterEmailSettingsKey.ReplyToEmail]: {
        defaultMessage:
          'The email address that should be used as a reply-to for all outbound emails ' +
          'sent to the data subject.',
      },
      [PrivacyCenterEmailSettingsKey.EmailPrefix]: {
        defaultMessage:
          'The email prefix is the entire local portion of the email address ' +
          '(e.g XXXXX@privacy.transcend.io) that should be used for all outbound emails sent ' +
          'to the data subject.',
      },
      [PrivacyCenterEmailSettingsKey.UseCustomEmailDomain]: {
        defaultMessage:
          'When turned on, the configured custom email domain will be used for all outbound ' +
          'emails sent to the data subject.',
      },
      [PrivacyCenterEmailSettingsKey.SupportEmail]: {
        defaultMessage:
          'An email address to show on the privacy center that your data subjects can ' +
          'reach out to for support.',
      },
      [PrivacyCenterEmailSettingsKey.UseNoReplyEmailAddress]: {
        defaultMessage:
          'When turned on, data subject communications initiated by the privacy center ' +
          'will come from a "no-reply" email address.',
      },
    },
  );
