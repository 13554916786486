import { RATE_LIMIT_PRECEDENCE, RateLimitType } from '../constants';

/**
 * Compare two rate limits, and find the most restrictive one of the two.
 *
 * @param x - First argument.
 * @param y - Second argument.
 * @returns Most restrictive of the two.
 */
export function rateLimitTypeComparator(
  x: RateLimitType,
  y: RateLimitType,
): 1 | 0 | -1 {
  if (x === y) {
    return 0;
  }
  const xIdx = RATE_LIMIT_PRECEDENCE.indexOf(x as RateLimitType);
  const yIdx = RATE_LIMIT_PRECEDENCE.indexOf(y as RateLimitType);
  if (xIdx === -1) {
    throw new Error(`${x} not found in precedence list. Please fix`);
  }
  if (yIdx === -1) {
    throw new Error(`${y} not found in precedence list. Please fix`);
  }
  return xIdx < yIdx ? -1 : 1;
}
