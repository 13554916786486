import { useEffect, useMemo } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';

import { indexBy } from '@main/utils';

import { useQueryParam, useRoutePath } from '../hooks';
import { TabBarHookResult, TabHookProp } from './types';

/**
 * hook to handle complex tab bar tab configurations
 *
 * @param root0 - the args
 * @returns the props needed for the TabBar and the current selected tab
 */
export function useTabBarTabs({
  activePath,
  pathArgs,
  tabs,
  defaultPath,
  useQueryParams,
}: {
  /** the active path (supports unfilled params) */
  activePath?: string;
  /** a map of the path params to fill in */
  pathArgs?: Record<string, string | undefined>;
  /** the tabs to use (supports unfilled params in the urls) */
  tabs: TabHookProp[];
  /** the default path to use if the paths are invalid */
  defaultPath?: string;
  /** whether to use query params instead of url paths for the tabs */
  useQueryParams?: boolean;
}): TabBarHookResult {
  const routePath = useRoutePath();
  const navigate = useNavigate();
  const { value: tabQueryParam } = useQueryParam({
    name: 'tab',
  });
  const getQueryParamPath = (tab: string): string =>
    `${routePath}${tab ? '?tab=' : ''}${tab}`;

  // use a dummy url to allow using the built in url parser
  const activePathWithoutQueryParams = generatePath(
    useQueryParams
      ? getQueryParamPath(tabQueryParam ?? '')
      : activePath
        ? new URL(`https://example.com${activePath}`).pathname
        : routePath,
    pathArgs,
  );
  const filledTabs = useMemo(
    () =>
      tabs.map(({ path, ...other }) => ({
        ...other,
        path: generatePath(
          useQueryParams ? getQueryParamPath(path) : path,
          pathArgs,
        ),
      })),
    [tabs, pathArgs],
  );
  const tabsByPathMap = useMemo(
    () => indexBy(filledTabs, 'path'),
    [filledTabs],
  );
  const activeTab = tabsByPathMap[activePathWithoutQueryParams];
  const filledDefaultPath = defaultPath
    ? generatePath(
        useQueryParams ? getQueryParamPath(defaultPath) : defaultPath,
        pathArgs,
      )
    : filledTabs[0].path;

  useEffect(() => {
    if (!activeTab) {
      navigate(filledDefaultPath, { replace: true });
    }
  }, [activePathWithoutQueryParams, tabsByPathMap, filledDefaultPath]);
  const activeContents =
    activePathWithoutQueryParams &&
    activeTab &&
    (typeof activeTab.contents === 'function'
      ? activeTab.contents()
      : activeTab.contents);
  return {
    filledActivePath: activePathWithoutQueryParams,
    filledTabs,
    activeTabContents: activeContents,
    activeTab,
  };
}
