import { SpecialTrackingPurpose } from '@transcend-io/airgap.js-types';
import { DataFlowScope } from '@transcend-io/privacy-types';
import { createEnum } from '@transcend-io/type-utils';

// The valid purposes indexed by type
const SPECIAL_PURPOSES = createEnum(Object.values(SpecialTrackingPurpose));

/**
 * Test if a string is a 'special' tracking purpose
 *
 * @param purpose - The purpose to test
 * @returns True if a valid special tracking purpose
 */
export function isSpecialTrackingPurpose(
  purpose: string,
): purpose is SpecialTrackingPurpose {
  return !!purpose && purpose in SPECIAL_PURPOSES;
}

const VALID_DATA_FLOW_TYPES = createEnum(Object.values(DataFlowScope));

/**
 * Helper to check if a string is a valid data flow type
 *
 * @param type - The type to validate
 * @returns true if valid data flow type
 */
export function isValidDataFlowType(type?: string): type is DataFlowScope {
  return !!type && type in VALID_DATA_FLOW_TYPES;
}
