import { defineMessages } from '@transcend-io/internationalization';

export const tableMessages = defineMessages('ad-core-components.Table.table', {
  noData: {
    defaultMessage: 'No data to display.',
  },
  pageSize: {
    defaultMessage: '{number} per page',
  },
});
