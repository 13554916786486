import { defineMessages } from '@transcend-io/internationalization';

import { PrivacyCenterAssetName } from '@main/pc-types';

/**
 * Privacy Center Asset File Messages
 */
export const privacyCenterAssetFileMessages =
  defineMessages<PrivacyCenterAssetName>(
    'admin-dashboard.PrivacyCenter.AssetFiles.privacyCenterAssetFile',
    {
      favicon: {
        defaultMessage: 'Favicon',
      },
      hero: {
        defaultMessage: 'Hero Image',
      },
      logo: {
        defaultMessage: 'Logo',
      },
      logoOnSolid: {
        defaultMessage: 'Logo on Side Menu',
      },
      widgetBg: {
        defaultMessage: 'Sidebar Widget Background',
      },
    },
  );

/**
 * Privacy Center Asset File Messages
 */
export const privacyCenterAssetFileDescriptionMessages =
  defineMessages<PrivacyCenterAssetName>(
    'admin-dashboard.PrivacyCenter.AssetFiles.privacyCenterAssetFileDescription',
    {
      favicon: {
        defaultMessage:
          'The shortcut icon to display on browser tabs (.png, .svg, .ico, recommended 1:1 aspect ratio)',
      },
      hero: {
        defaultMessage:
          'The image to display on your main page banner (.png, .svg, recommended 3:2 aspect ratio)',
      },
      logo: {
        defaultMessage:
          "Your organization's logo (.png). Recommended height of image should be 38px.",
      },
      logoOnSolid: {
        defaultMessage:
          "An optional version (i.e., black or white) of your logo to be displayed on your sidebar's color (.png, .svg)",
      },
      widgetBg: {
        defaultMessage: `The background image for the "About Transcend" sidebar widget (.png, .svg, recommended
          minimum height between 400-600px and portrait orientation)`,
      },
    },
  );

export const assetFilesMessages = defineMessages(
  'admin-dashboard.PrivacyCenter.AssetFiles.assetFiles',
  {
    header: {
      defaultMessage: 'Asset Files',
    },
    replace: {
      defaultMessage: 'Replace',
    },
    upload: {
      defaultMessage: 'Upload',
    },
    altDescription: {
      defaultMessage: 'Image alt text caption (for accessibility)',
    },
    altPlaceholder: {
      defaultMessage: 'Upload an image to edit its alt text',
    },
    changeFile: {
      defaultMessage: 'Change File',
    },
    resetToDefault: {
      defaultMessage: 'Reset to Default',
    },
  },
);

export const assetFilesColumnHeadersMessages = defineMessages(
  'admin-dashboard.PrivacyCenter.AssetFiles.assetFilesColumnHeaders',
  {
    name: {
      defaultMessage: 'Name',
    },
    alt: {
      defaultMessage: 'Alt Text',
    },
    upload: {
      defaultMessage: 'Upload File',
    },
  },
);

export const assetFilesColumnTooltipMessages = defineMessages(
  'admin-dashboard.PrivacyCenter.AssetFiles.assetFilesColumnTooltip',
  {
    name: {
      defaultMessage: 'The name & description of the asset',
    },
    alt: {
      defaultMessage: 'The alt text for the asset',
    },
    upload: {
      defaultMessage: 'Upload a new file',
    },
  },
);
