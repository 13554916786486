/**
 * The set of valid NODE_ENV environments.
 */
export enum Environment {
  /** A production server running on aws */
  Production = 'production',
  /** A development server running locally */
  Development = 'development',
  /** Running tests such as mocha/chai */
  Test = 'test',
}

/**
 * The types of browser auto completes to assign to an HTML form item
 */
export enum FormAutoComplete {
  /** full name */
  Name = 'NAME',
  /** prefix or title (Mr., Mrs. Dr., etc.) */
  HonorificPrefix = 'HONORIFIC_PREFIX',
  /** given or first name */
  GivenName = 'GIVEN_NAME',
  /** additional or middle name */
  AdditionalName = 'ADDITIONAL_NAME',
  /** additional or middle name initial */
  AdditionalNameInitial = 'ADDITIONAL_NAME_INITIAL',
  /** family name, surname, or last name */
  FamilyName = 'FAMILY_NAME',
  /** suffix (Jr., II, etc.) */
  HonorificSuffix = 'HONORIFIC_SUFFIX',
  /** nickname */
  Nickname = 'NICKNAME',
  /** full street address condensed into one line */
  StreetAddress = 'STREET_ADDRESS',
  /** first line of street address */
  AddressLine1 = 'ADDRESS_LINE1',
  /** second line of street address */
  AddressLine2 = 'ADDRESS_LINE2',
  /** third line of street address */
  AddressLine3 = 'ADDRESS_LINE3',
  /** locality or city */
  Locality = 'LOCALITY',
  /** same as locality */
  City = 'CITY',
  /** administrative area, state, province, or region */
  AdministrativeArea = 'ADMINISTRATIVE_AREA',
  /** same as administrative-area */
  State = 'STATE',
  /** same as administrative-area */
  Province = 'PROVINCE',
  /** same as administrative-area */
  Region = 'REGION',
  /** postal or ZIP code */
  PostalCode = 'POSTAL_CODE',
  /** country name */
  CountryName = 'COUNTRY_NAME',
  /** email address */
  Email = 'EMAIL',
  /** full phone number, including country code */
  Tel = 'TEL',
  /** international country code */
  TelCountryCode = 'TEL_COUNTRY_CODE',
  /** national phone number = full number minus country code */
  TelNational = 'TEL_NATIONAL',
  /** area code */
  TelAreaCode = 'TEL_AREA_CODE',
  /** local phone number = full number minus country and area codes */
  TelLocal = 'TEL_LOCAL',
  /** first part of local phone number */
  TelLocalPrefix = 'TEL_LOCAL_PREFIX',
  /** second part of local phone number */
  TelLocalSuffix = 'TEL_LOCAL_SUFFIX',
  /** phone extension number */
  TelExtension = 'TEL_EXTENSION',
  /** full fax number, including country code */
  Fax = 'FAX',
  /** international country code */
  FaxCountryCode = 'FAX_country_code',
  /** national fax number = full number minus country code */
  FaxNational = 'FAX_NATIONAL',
  /** area code */
  FaxAreaCode = 'FAX_AREA_CODE',
  /** local fax number = full number minus country and area codes */
  FaxLocal = 'FAX_LOCAL',
  /** first part of local fax number */
  FaxLocalPrefix = 'FAX_LOCAL_PREFIX',
  /** second part of local fax number */
  FaxLocalSuffix = 'FAX_LOCAL_SUFFIX',
  /** fax extension number */
  FaxExtension = 'FAX_EXTENSION',
  /** full name, as it appears on credit card */
  CcName = 'CC_NAME',
  /** given or first name, as it appears on credit card */
  CcGivenName = 'CC_GIVEN_NAME',
  /** additional or middle name (or initial), as it appears on credit card */
  CcAdditionalName = 'CC_ADDITIONAL_NAME',
  /** family name, surname, or last name, as it appears on credit card */
  CcFamilyName = 'CC_FAMILY_NAME',
  /** credit card number */
  CcNumber = 'CC_NUMBER',
  /** month of expiration of credit card */
  CcExpMonth = 'CC_EXP_MONTH',
  /** year of expiration of credit card */
  CcExpYear = 'CC_EXP_YEAR',
  /** date of expiration of credit card */
  CcExp = 'CC_EXP',
  /** credit card security code */
  CcCsc = 'CC_CSC',
  /** birthday */
  Bday = 'BDAY',
  /** year of birthday */
  BdayYear = 'BDAY_YEAR',
  /** month of birthday */
  BdayMonth = 'BDAY_MONTH',
  /** day of birthday */
  BdayDay = 'BDAY_DAY',
  /** company or organization */
  Org = 'ORG',
  /** user's position or title within company or organization */
  OrganizationTitle = 'ORGANIZATION_TITLE',
  /** sex or gender */
  Sex = 'SEX',
  /** gender identity */
  GenderIdentity = 'GENDER_IDENTITY',
  /** Website URL */
  Url = 'URL',
  /** photo or avatar */
  Photo = 'PHOTO',
  /** New password */
  NewPassword = 'NEW_PASSWORD',
  /** Current password */
  CurrentPassword = 'CURRENT_PASSWORD',
}

/**
 * The valid json schema form item formats
 */
export enum FormFormat {
  /** Choose a date */
  Date = 'DATE',
  /** A date/time combo */
  DateTime = 'DATE_TIME',
  /** Defines a field for an e-mail address */
  Email = 'EMAIL',
  /** Defines a password field (characters are masked) */
  Password = 'PASSWORD',
  /** A phone number */
  PhoneNumber = 'PHONE_NUMBER',
  /** Choose a time of day */
  Time = 'TIME',
}

/**
 * AWS Regions that we use. We typically stick to Ireland.
 */
export enum AwsRegion {
  /** Our primary region where most infra is hosted */
  Ireland = 'eu-west-1',
  /** Where our backups are hosted */
  Frankfurt = 'eu-central-1',
  /** Some AWS resources only live in us-east-1 */
  Virginia = 'us-east-1',
  /** West coast best coast */
  California = 'us-west-1',
}
