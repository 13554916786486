/**
 * The standard padding, in pixels, of the content layout
 */
export const CONTENT_PADDING = 24;

/**
 * The breadcrumb height, in pixels
 */
export const PAGE_BREADCRUMB_HEIGHT = 18;

/**
 * The breadcrumb margin bottom, in pixels
 */
export const PAGE_BREADCRUMB_MARGIN_BOTTOM = CONTENT_PADDING;

/**
 * The total height of the breadcrumb and padding content
 *
 * Use this for full-height content views:
 *
 * @example height: calc(100vh - ${NON_CONTENT_VERTICAL_PIXELS}px);
 */
export const TOTAL_HEIGHT_PADDING_AND_BREADCRUMB =
  CONTENT_PADDING * 2 + PAGE_BREADCRUMB_HEIGHT + PAGE_BREADCRUMB_MARGIN_BOTTOM;

/**
 * props for autofocus to bypass the a11y eslint rule. `autoFocus` is only bad on
 * initial pageload, but the a11y rule isn't smart enough to detect that
 * source: https://stackoverflow.com/a/71848275
 */
export const AUTOFOCUS_CONDITIONAL_COMPONENT_PROP = { autoFocus: true };
