import { defineMessages } from '@transcend-io/internationalization';

export const pageTitleMessages = defineMessages(
  'ad-core-components.PageTitle.pageTitle',
  {
    iconAltText: {
      defaultMessage: 'Page title icon',
    },
  },
);
