import styled from 'styled-components';

import { DatePicker, DatePickerProps } from '@main/ad-core-components';

export const ViewWrapper = styled.div`
  tr {
    background-color: ${({ theme }) => theme.colors.gray2};
  }

  border-bottom: 1px solid ${({ theme }) => theme.colors.gray3};
`;

export const StyledDueDatePicker = styled(DatePicker)<
  DatePickerProps & {
    /** color override */
    color?: string;
  }
>`
  input.ant-calendar-picker-input:not(.ant-input-disabled) {
    ${({ color }) => (color ? `color: ${color};` : '')}
    border-color: transparent;

    &:hover {
      border-color: ${({ theme }) => theme.colors.gray3};
    }
  }
`;
