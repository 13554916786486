import { FilterConfigItem } from '@main/ad-core-components';
import {
  SelectApplicationUsers,
  SelectedApplicationUser,
} from '@main/admin-dashboard/src/Pathfinder/components/SelectApplicationUsers';
import { buildUseLazyQuery } from '@main/core-ui';
import { endpoints } from '@main/prompt-types';
import { ID } from '@main/schema-utils';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { MessageDescriptor } from 'react-intl';

import { filterManagerHookMessages } from './messages';

/**
 * Filters definition with property for application user values specified
 */
type ApplicationUserEnrichedFilters = Record<string, unknown> &
  Record<
    /**
     * Application user values that are currently filtering the table
     */
    string,
    SelectedApplicationUser[] | undefined
  >;

const useLazyApplicationUsers = buildUseLazyQuery(endpoints.applicationUsers);

export const useApplicationUserFilters = <
  T extends Record<string, unknown>,
  E extends ApplicationUserEnrichedFilters,
>({
  appUserIdsFilterKey = 'appUserIds',
  filters,
  setFilters,
  label,
  enrichedApplicationUserKey = 'applicationUsers',
}: {
  /** The property name of the filter for application user ids */
  appUserIdsFilterKey?: keyof T;
  /** The currently applied filters */
  filters?: T;
  /** Callback when the filters are changed */
  setFilters: (filters: T) => void;
  /** Label for the filter menu */
  label: string | MessageDescriptor;
  /** The key to use if more than one useApplicationUserFilters is used in a FilterManager */
  enrichedApplicationUserKey?: keyof E;
}): {
  /**  The application user keys enabled on this table */
  selectedApplicationUsers: SelectedApplicationUser[];
  /** The filter configuration to be passed to the filter manager */
  applicationUserFiltersConfig: FilterConfigItem<E>;
  /** Callback for when the filter is cleared in the filter manager */
  clearApplicationUserFilters: (key: Extract<keyof E, string>) => void;
} => {
  const getSelectedApplicationUsers = useLazyApplicationUsers();
  const [selectedApplicationUsers, setSelectedApplicationUsers] = useState<
    SelectedApplicationUser[]
  >([]);
  const [fetchingApplicationUsers, setFetchingApplicationUsers] =
    useState(false);

  // Populate initial complex filter values
  useEffect(() => {
    if (filters) {
      const appUserIds = (filters[appUserIdsFilterKey] ??
        []) as ID<'appUser'>[];

      const filtersAndEnrichedFiltersMatch =
        appUserIds.sort().join() ===
        selectedApplicationUsers
          .map(({ id }) => id)
          .sort()
          .join();

      if (appUserIds.length === 0 && selectedApplicationUsers.length > 0) {
        setSelectedApplicationUsers([]);
      } else if (!filtersAndEnrichedFiltersMatch && !fetchingApplicationUsers) {
        // Prevent over-firing this setter while data is still being fetched
        setFetchingApplicationUsers(true);
        getSelectedApplicationUsers({ filterBy: { ids: appUserIds } })
          .then(({ data }) => {
            setSelectedApplicationUsers(data.nodes);
          })
          .finally(() => {
            setFetchingApplicationUsers(false);
          });
      }
    }
  }, [
    filters,
    fetchingApplicationUsers,
    getSelectedApplicationUsers,
    selectedApplicationUsers.length,
    appUserIdsFilterKey,
    selectedApplicationUsers,
  ]);

  const applicationUserFiltersConfig = useMemo(
    () =>
      ({
        filterKey: enrichedApplicationUserKey,
        label,
        pillOptions: {
          label: ({
            filterValues: {
              [enrichedApplicationUserKey]: applicationUsers = [],
            },
            index = 0,
          }) => applicationUsers[index]?.name,
        },
        filter: (
          <SelectApplicationUsers
            isMulti
            placeholderDescriptor={
              filterManagerHookMessages.applicationUsersPlaceholder
            }
            menuPosition="absolute"
            value={selectedApplicationUsers}
            showOutline={false}
            onChange={(applicationUsers) => {
              setSelectedApplicationUsers([...applicationUsers]);
              setFilters({
                ...filters,
                [appUserIdsFilterKey]: applicationUsers.map(({ id }) => id),
              } as T);
            }}
          />
        ),
      }) as FilterConfigItem<ApplicationUserEnrichedFilters>,
    [
      enrichedApplicationUserKey,
      label,
      selectedApplicationUsers,
      filters,
      appUserIdsFilterKey,
    ],
  );

  const clearApplicationUserFilters = useCallback(
    (key) => {
      if (key === enrichedApplicationUserKey) {
        setFilters({
          ...filters,
          [appUserIdsFilterKey]: [],
        } as T);
        setSelectedApplicationUsers([]);
      }
    },
    [enrichedApplicationUserKey, filters, appUserIdsFilterKey],
  );

  return {
    selectedApplicationUsers,
    applicationUserFiltersConfig,
    clearApplicationUserFilters,
  };
};
