import { FilterConfigItem } from '@main/ad-core-components';
import {
  SelectApplications,
  SelectedApplication,
} from '@main/admin-dashboard/src/Pathfinder/components/SelectApplications';
import { buildUseLazyQuery } from '@main/core-ui';
import { endpoints } from '@main/prompt-types';
import { ID } from '@main/schema-utils';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { MessageDescriptor } from 'react-intl';

import { filterManagerHookMessages } from './messages';

/**
 * Filters definition with property for application values specified
 */
type ApplicationEnrichedFilters = Record<string, unknown> &
  Record<
    /**
     * Application values that are currently filtering the table
     */
    string,
    SelectedApplication[] | undefined
  >;

const useLazyApplications = buildUseLazyQuery(endpoints.applications);

export const useApplicationFilters = <
  T extends Record<string, unknown>,
  E extends ApplicationEnrichedFilters,
>({
  applicationIdsFilterKey = 'applicationIds',
  filters,
  setFilters,
  label,
  enrichedApplicationKey = 'applications',
}: {
  /** The property name of the filter for application ids */
  applicationIdsFilterKey?: keyof T;
  /** The currently applied filters */
  filters?: T;
  /** Callback when the filters are changed */
  setFilters: (filters: T) => void;
  /** Label for the filter menu */
  label: string | MessageDescriptor;
  /** The key to use if more than one useApplicationFilters is used in a FilterManager */
  enrichedApplicationKey?: keyof E;
}): {
  /**  The application keys enabled on this table */
  selectedApplications: SelectedApplication[];
  /** The filter configuration to be passed to the filter manager */
  applicationFiltersConfig: FilterConfigItem<E>;
  /** Callback for when the filter is cleared in the filter manager */
  clearApplicationFilters: (key: Extract<keyof E, string>) => void;
} => {
  const getSelectedApplications = useLazyApplications();
  const [selectedApplications, setSelectedApplications] = useState<
    SelectedApplication[]
  >([]);
  const [fetchingApplications, setFetchingApplications] = useState(false);

  // Populate initial complex filter values
  useEffect(() => {
    if (filters) {
      const applicationIds = (filters[applicationIdsFilterKey] ??
        []) as ID<'application'>[];

      const filtersAndEnrichedFiltersMatch =
        applicationIds.sort().join() ===
        selectedApplications
          .map(({ id }) => id)
          .sort()
          .join();

      if (applicationIds.length === 0 && selectedApplications.length > 0) {
        setSelectedApplications([]);
      } else if (!filtersAndEnrichedFiltersMatch && !fetchingApplications) {
        // Prevent over-firing this setter while data is still being fetched
        setFetchingApplications(true);
        getSelectedApplications({
          filterBy: { ids: applicationIds },
        })
          .then(({ data }) => {
            setSelectedApplications(data.nodes);
          })
          .finally(() => {
            setFetchingApplications(false);
          });
      }
    }
  }, [
    filters,
    fetchingApplications,
    getSelectedApplications,
    selectedApplications.length,
    applicationIdsFilterKey,
    selectedApplications,
  ]);

  const applicationFiltersConfig = useMemo(
    () =>
      ({
        filterKey: enrichedApplicationKey,
        label,
        pillOptions: {
          label: ({
            filterValues: { [enrichedApplicationKey]: applications = [] },
            index = 0,
          }) => {
            const application = applications[index];
            return application?.name || '';
          },
        },
        filter: (
          <SelectApplications
            isMulti
            placeholderDescriptor={
              filterManagerHookMessages.applicationsPlaceholder
            }
            menuPosition="absolute"
            value={selectedApplications}
            showOutline={false}
            onChange={(applications) => {
              setSelectedApplications([...applications]);
              setFilters({
                ...filters,
                [applicationIdsFilterKey]: applications.map(({ id }) => id),
              } as T);
            }}
          />
        ),
      }) as FilterConfigItem<ApplicationEnrichedFilters>,
    [
      enrichedApplicationKey,
      label,
      selectedApplications,
      filters,
      applicationIdsFilterKey,
    ],
  );

  const clearApplicationFilters = useCallback(
    (key) => {
      if (key === enrichedApplicationKey) {
        setFilters({
          ...filters,
          [applicationIdsFilterKey]: [],
        } as T);
        setSelectedApplications([]);
      }
    },
    [enrichedApplicationKey, filters, applicationIdsFilterKey],
  );

  return {
    selectedApplications,
    applicationFiltersConfig,
    clearApplicationFilters,
  };
};
