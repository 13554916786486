import { cases } from '@transcend-io/handlebars-utils';

import { createLocalizedMessage } from '@main/internationalization';

import { PolicyMenuTab } from '../schema';

// Import JSDOM iff being run on server
const { JSDOM } =
  typeof window === 'undefined' ? require('jsdom') : { JSDOM: undefined };

/**
 * Count the number of h1, h2, h3 headers
 *
 * @param htmlString - HTML string to parse headers from
 * @returns Mapping from header to list of headers of that type
 */
export function getHighestHeaders(htmlString: string): string[] {
  if (typeof window === 'undefined') {
    const wind = new JSDOM(htmlString).window;
    const { document } = wind;

    const h1Items = document.querySelectorAll('h1');
    const h2Items = document.querySelectorAll('h2');
    const h3Items = document.querySelectorAll('h3');

    const itemsToUse =
      h1Items.length > 0 ? h1Items : h2Items.length > 0 ? h2Items : h3Items;
    return Array.from(itemsToUse)
      .map((h1: any) => h1.textContent?.trim())
      .filter((x) => !!x);
  }

  // on browser
  const doc = window.document.implementation.createHTMLDocument('foo');
  doc.documentElement.innerHTML = htmlString;
  const h1Items = doc.querySelectorAll('h1');
  const h2Items = doc.querySelectorAll('h2');
  const h3Items = doc.querySelectorAll('h3');

  const itemsToUse =
    h1Items.length > 0 ? h1Items : h2Items.length > 0 ? h2Items : h3Items;
  return Array.from(itemsToUse)
    .map((h1: any) => h1.textContent?.trim())
    .filter((x) => !!x);
}

/**
 * Turns a list of headers into a list of menu tabs
 *
 * @param content - The HTML content to parse to side menu
 * @returns A list of menu tabs that will feed directly to cache for side menu
 */
export function contentToSideMenu(content: string): PolicyMenuTab[] {
  const headers = getHighestHeaders(content);

  // Add in Menu Tab attributes
  return headers.map((headerText) => ({
    visualId: cases.paramCase(headerText),
    id: cases.paramCase(headerText),
    title: createLocalizedMessage(headerText),
  }));
}
