import { createLoadable } from '@main/core-ui';

import { globalMessagesMessages } from './messages';

// Load the component dynamically
export default createLoadable({
  loader: () => import('./GlobalMessages'),
  messages: globalMessagesMessages,
  logo: 'global',
});
