import { FlexRow, Icon, StyleUtils } from '@main/core-ui';
import { debounce } from '@main/utils';
import { Group } from '@visx/group';
import { ParentSize } from '@visx/responsive';
import { Pie } from '@visx/shape';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useTheme } from 'styled-components';

import { DEFAULT_COLORS } from '../constants';
import {
  DONUT_MARGIN,
  DONUT_THICKNESS,
  HOVER_DEBOUNCE_DELAY,
  LEGEND_MARGIN,
} from './constants';
import { DonutChartProps, InternalDonutChartDataPoint } from './types';
import { getInternalDonutChartData, getTotal } from './utils';
import { ChartContainer, Legend, LegendRow, LegendText } from './wrappers';

export const DonutChart: React.FC<DonutChartProps> = ({
  data,
  colors = DEFAULT_COLORS,
  unitLabel,
  legendLabel,
  hideLegend = false,
  maxDonutSize,
  onClickLegendEntry,
  centerValueOptions,
  outlineThickness = DONUT_THICKNESS,
}) => {
  const { formatNumber } = useIntl();
  const internalChartData = getInternalDonutChartData(data);
  const theme = useTheme();
  const total = getTotal(internalChartData);
  const [hoveredSegment, setHoveredSegmentNotDebounced] = useState<
    string | null
  >(null);
  const [centerValue, setCenterValueNotDebounced] = useState<number>(
    centerValueOptions?.defaultValue ?? total,
  );

  useEffect(() => {
    if (centerValueOptions?.defaultValue) {
      setCenterValueNotDebounced(centerValueOptions?.defaultValue);
    }
  }, [centerValueOptions?.defaultValue]);

  const setCenterValue = debounce(
    (value) =>
      setCenterValueNotDebounced(centerValueOptions?.defaultValue ?? value),
    HOVER_DEBOUNCE_DELAY,
  );
  const setHoveredSegment = debounce(
    (value) => setHoveredSegmentNotDebounced(value),
    HOVER_DEBOUNCE_DELAY,
  );

  return (
    <ParentSize>
      {({ height: containerHeight, width: containerWidth }) => {
        const size = Math.min(containerHeight, containerWidth);
        const donutCenter = {
          x: size / 2,
          y: size / 2,
        };
        const outerRadius =
          Math.min(containerHeight - DONUT_MARGIN * 2, maxDonutSize ?? 10000) /
          2;
        const innerRadius = outerRadius - outlineThickness;
        const legendLeft =
          donutCenter.x + outerRadius + (hideLegend ? 0 : LEGEND_MARGIN);

        return (
          <ChartContainer
            style={{ height: containerHeight, width: containerWidth }}
          >
            <svg
              height={containerHeight}
              width={containerWidth}
              style={{ top: 0, left: 0 }}
            >
              <Group top={donutCenter.y} left={donutCenter.x}>
                <Pie<InternalDonutChartDataPoint>
                  data={internalChartData}
                  // make pie value a minimum of 1% of total otherwise it won't be visible
                  pieValue={(d) => Math.max(d.value, 0.01 * total)}
                  outerRadius={(arc) =>
                    arc.data.label === hoveredSegment
                      ? outerRadius + outlineThickness / 2
                      : outerRadius
                  }
                  innerRadius={(arc) =>
                    arc.data.label === hoveredSegment
                      ? innerRadius - outlineThickness / 2
                      : innerRadius
                  }
                  cornerRadius={outerRadius - innerRadius}
                  padAngle={0.015}
                >
                  {(pie) =>
                    pie.arcs.map((arc, idx) => {
                      const fill = theme.colors[colors[idx % colors.length]];
                      return (
                        <g key={`arc-${idx}`}>
                          <path
                            d={pie.path(arc) || ''}
                            style={{
                              transition: 'all 250ms ease-out',
                              cursor: 'pointer',
                            }}
                            fill={fill}
                            onMouseEnter={() => {
                              setHoveredSegment(arc.data.label);
                              setCenterValue(arc.data.value);
                            }}
                            onMouseLeave={() => {
                              setHoveredSegment(null);
                              setCenterValue(total);
                            }}
                          />
                        </g>
                      );
                    })
                  }
                </Pie>
              </Group>
              <text
                x={donutCenter.x}
                y={donutCenter.y}
                fill={theme.colors.transcendNavy}
                fontSize={centerValueOptions?.fontSize ?? 44}
                fontWeight={centerValueOptions?.fontWeight ?? 600}
                textAnchor="middle"
                pointerEvents="none"
                dy={4}
              >
                {formatNumber(centerValue, {
                  notation: 'compact',
                  ...(centerValueOptions?.formatNumberOptions ?? {}),
                })}
              </text>
              {unitLabel && (
                <text
                  x={donutCenter.x}
                  y={donutCenter.y}
                  dy={28}
                  fill={theme.colors.transcendNavy2}
                  fontSize={14}
                  textAnchor="middle"
                  pointerEvents="none"
                >
                  {unitLabel}
                </text>
              )}
            </svg>
            {!hideLegend && (
              <div
                style={{
                  left: legendLeft,
                  position: 'absolute',
                  top: 0,
                  bottom: 0,
                  right: 0,
                  overflowY: 'auto',
                }}
              >
                <Legend>
                  {internalChartData.map(({ label, value, key }, idx) => (
                    <LegendRow
                      key={label}
                      hovered={label === hoveredSegment ? 'true' : 'false'}
                      onMouseEnter={() => {
                        setHoveredSegment(label);
                        setCenterValue(value);
                      }}
                      onMouseLeave={() => {
                        setHoveredSegment(null);
                        setCenterValue(total);
                      }}
                      onClick={() => onClickLegendEntry?.(key)}
                    >
                      <div
                        style={{
                          height: '8px',
                          width: '8px',
                          background: theme.colors[colors[idx % colors.length]],
                          borderRadius: '6px',
                        }}
                      />
                      {legendLabel !== undefined ? (
                        legendLabel({ value, label, total, key })
                      ) : (
                        <LegendText>
                          <FlexRow style={{ gap: StyleUtils.Spacing.sm }}>
                            <span>{label}</span>
                            {onClickLegendEntry && key && (
                              <span className="icon-wrapper">
                                <Icon
                                  type="external-link"
                                  size={12}
                                  color={theme.colors.transcend}
                                />
                              </span>
                            )}
                          </FlexRow>
                          <span>
                            {Math.round((value / (total || 1)) * 100)}%
                          </span>
                        </LegendText>
                      )}
                    </LegendRow>
                  ))}
                </Legend>
              </div>
            )}
          </ChartContainer>
        );
      }}
    </ParentSize>
  );
};
