import { SoftwareDevelopmentKitPreview } from '@main/code-scanning-types';
import { mkInput, mkType, SchemaToType } from '@main/schema-utils';

import { ConsentApplicationPreview } from './consentApplication';
import { ConsentServicePreview } from './consentService';
import { TrackingPurpose } from './purpose';

export const ConsentSdk = mkType({
  name: 'ConsentSdk',
  comment: 'The SDK that should be governed based on user consent',
  fields: {
    id: {
      type: 'id',
      modelName: 'consentSdk',
      comment: 'ID of the consent SDK',
    },
    slug: {
      type: 'string',
      comment: 'Unique identifier for this consent SDK',
    },
    description: {
      type: 'string',
      comment: 'Description of this consent SDK',
      optional: true,
    },
    purposes: {
      comment: 'A list of tracking purposes associated with this SDK',
      type: TrackingPurpose,
      list: true,
    },
    consentService: {
      comment: 'The consent service this SDK belongs to',
      type: ConsentServicePreview,
    },
    consentApplication: {
      comment: 'The consent app this SDK belongs to',
      type: ConsentApplicationPreview,
    },
    softwareDevelopmentKit: {
      type: SoftwareDevelopmentKitPreview,
      comment: 'The scanned SDK this consent SDK belongs to',
      optional: true,
    },
  },
});

/** Override type */
export type ConsentSdk = SchemaToType<typeof ConsentSdk>;

export const ConsentSdkFiltersInput = mkInput({
  name: 'ConsentSdkFiltersInput',
  comment: 'Input for filtering consent SDKs',
  fields: {
    text: {
      type: 'string',
      comment: 'Filter the consent SDKs by fuzzy match on slug and description',
      optional: true,
    },
    consentServiceIds: {
      type: 'id',
      modelName: 'consentService',
      comment: 'Filter the consent SDKs by their associated service',
      list: true,
      optional: true,
    },
    consentApplicationIds: {
      type: 'id',
      modelName: 'consentApplication',
      comment: 'Filter the consent SDKs by their associated application',
      list: true,
      optional: true,
    },
    purposeIds: {
      type: 'id',
      modelName: 'purpose',
      comment: 'Filter the consent SDKs by purpose ID',
      list: true,
      optional: true,
    },
  },
});

/** Override type */
export type ConsentSdkFiltersInput = SchemaToType<
  typeof ConsentSdkFiltersInput
>;

export const ConsentSdkUpsertInput = mkInput({
  name: 'ConsentSdkUpsertInput',
  comment: 'Input for creating or updating a consent SDK',
  fields: {
    slug: ConsentSdk.fields.slug,
    consentApplicationId: {
      type: 'id',
      modelName: 'consentApplication',
      comment: 'ID of the consent application this SDK belongs to',
      optional: true,
    },
    consentServiceId: {
      type: 'id',
      modelName: 'consentService',
      comment: 'ID of the consent service this SDK belongs to',
      optional: true,
    },
    consentServiceCatalogName: {
      type: 'string',
      comment:
        'The catalog integration name of the consent service this SDK belongs to. ' +
        'If one does not exist, we will automatically create a new consent service entry.',
      optional: true,
    },
    description: ConsentSdk.fields.description,
    purposeIds: {
      type: 'id',
      modelName: 'purpose',
      comment: 'IDs of the tracking purposes associated with this SDK',
      optional: true,
      list: true,
    },
    softwareDevelopmentKitId: {
      type: 'id',
      modelName: 'softwareDevelopmentKit',
      comment: 'ID of the scanned SDK this consent SDK belongs to',
      optional: true,
    },
  },
});

/** Override type */
export type ConsentSdkUpsertInput = SchemaToType<typeof ConsentSdkUpsertInput>;

export const CreateOrUpdateConsentSdkInput = mkInput({
  name: 'CreateOrUpdateConsentSdkInput',
  comment: 'Input for creating a new consent SDKs',
  fields: {
    airgapBundleId: {
      type: 'id',
      modelName: 'airgapBundle',
      comment: 'ID of the airgap bundle this SDK belongs to',
    },
    consentSdks: {
      comment: 'The list of consent SDKs to create or update',
      type: ConsentSdkUpsertInput,
      list: true,
    },
  },
});

/** Override type */
export type CreateOrUpdateConsentSdkInput = SchemaToType<
  typeof CreateOrUpdateConsentSdkInput
>;

export const DeleteConsentSdkInput = mkInput({
  name: 'DeleteConsentSdkInput',
  comment: 'Input for deleting consent sdks by ID',
  fields: {
    airgapBundleId: {
      type: 'id',
      modelName: 'airgapBundle',
      comment: 'ID of the airgap bundle this SDK belongs to',
    },
    ids: {
      type: 'id',
      modelName: 'consentSdk',
      comment: 'ID of the consent sdks to delete',
      list: true,
    },
  },
});

/** Override type */
export type DeleteConsentSdkInput = SchemaToType<typeof DeleteConsentSdkInput>;
