import { createSelector } from 'reselect';

import { ConsentManagerV2State } from './slice';

/**
 * The selector for the `ConsentManager` state store slice
 */
export const getConsentManager = (state: any): ConsentManagerV2State =>
  state.ConsentManagerV2;

/**
 * The ID of the selected consent manager
 */
export const selectSelectedConsentManagerId = createSelector(
  getConsentManager,
  ({ selectedConsentManagerId }) => selectedConsentManagerId,
);

/**
 * The consent manager for this organization
 */
export const selectConsentManagers = createSelector(
  getConsentManager,
  ({ consentManagers }) => consentManagers,
);

/**
 * The consent managers type enforced to exist
 */
export const selectConsentManager = createSelector(
  selectSelectedConsentManagerId,
  selectConsentManagers,
  (selectedConsentManagerId, consentManagers) =>
    selectedConsentManagerId
      ? consentManagers[selectedConsentManagerId]
      : undefined,
);

export const selectDefaultConsent = createSelector(
  getConsentManager,
  ({ defaultConsent }) => defaultConsent,
);

/**
 * select selected consent services (for the tcf vendors table)
 */
export const selectSelectedConsentServices = createSelector(
  getConsentManager,
  ({ consentServices }) => consentServices,
);

/**
 * select selected consent applications
 */
export const selectSelectedConsentApplications = createSelector(
  getConsentManager,
  ({ consentApplications }) => consentApplications,
);

/**
 * select selected consent services (for the tcf vendors table)
 */
export const selectSelectedConsentSdks = createSelector(
  getConsentManager,
  ({ consentSdks }) => consentSdks,
);

/** select selected tcf stacks */
export const selectSelectedTcfStacks = createSelector(
  getConsentManager,
  ({ tcfStacks }) => tcfStacks,
);
