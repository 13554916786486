import { makeEnum } from '@transcend-io/type-utils';

import { AirgapBundleType } from '@main/ag-types';

import { AirgapIssueSeverity } from '../../airgapChangelog';

export const BundleDeployStatus = AirgapBundleType;

/** Override type */
export type BundleDeployStatus =
  (typeof BundleDeployStatus)[keyof typeof BundleDeployStatus];

/**
 * The possible CDNs where airgap.js can be hosted
 */
export const AirgapCdn = makeEnum({
  Cloudflare: 'CLOUDFLARE',
  Cloudfront: 'CLOUDFRONT',
});

/** Override type */
export type AirgapCdn = (typeof AirgapCdn)[keyof typeof AirgapCdn];

/** graphql type enum version of issue severity */
export const AirgapIssueSeverityGQL = makeEnum({
  ...AirgapIssueSeverity,
});

/**
 * Status of custom domains
 */
export const CustomDomainStatus = makeEnum({
  /** The custom domain is active */
  Active: 'ACTIVE',
  /** The custom domain is deactivated and queued for deletion in Cloudflare */
  Deactivated: 'DEACTIVATED',
  /** The custom domain has been deleted */
  Deleted: 'DELETED',
});

/** Override type */
export type CustomDomainStatus =
  (typeof CustomDomainStatus)[keyof typeof CustomDomainStatus];
