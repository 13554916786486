import { TranscendProduct } from '@transcend-io/privacy-types';

import type { ExtendableRoute } from '@main/admin-dashboard/src/routes';
import { AdminDashboardAuditorPath } from '@main/auditor-types';

import { createOnboardingItemsComponent } from '../Auth/ActionItems/OnboardingItemsCollections/OnboardingItemCollections';
import { navMenuMessages } from '../routes/OrganizationRoute/NavMenu/messages';
import { HomeLoadable } from './Home/Loadable';
import { auditorMessages } from './messages';
import RunCookiesLoadable from './Run/Cookies/Loadable';
import RunDataFlowsLoadable from './Run/DataFlows/Loadable';
import RunDetailsLoadable from './Run/Details/Loadable';
import RunFindingsLoadable from './Run/Findings/Loadable';
import RunSummaryLoadable from './Run/Summary/Loadable';
import { SchedulesLoadable } from './Schedules/Loadable';

export const auditorBreadcrumb = {
  id: 'auditor-header',
  displayText: auditorMessages.auditor,
  href: AdminDashboardAuditorPath.Auditor,
};

export const auditorRoutes: Record<AdminDashboardAuditorPath, ExtendableRoute> =
  {
    [AdminDashboardAuditorPath.Auditor]: {
      path: AdminDashboardAuditorPath.Auditor,
      title: auditorMessages.auditor,
      component: HomeLoadable,
      breadcrumbs: [
        auditorBreadcrumb,
        {
          id: 'auditor-runs',
          displayText: auditorMessages.runs,
          href: AdminDashboardAuditorPath.Auditor,
        },
      ],
    },
    [AdminDashboardAuditorPath.Schedules]: {
      path: AdminDashboardAuditorPath.Schedules,
      title: auditorMessages.schedules,
      breadcrumbs: [
        auditorBreadcrumb,
        {
          id: 'auditor-schedules',
          displayText: auditorMessages.schedules,
          href: AdminDashboardAuditorPath.Schedules,
        },
      ],
      component: SchedulesLoadable,
    },
    [AdminDashboardAuditorPath.RunSummary]: {
      path: AdminDashboardAuditorPath.RunSummary,
      title: auditorMessages.summary,
      breadcrumbs: [
        auditorBreadcrumb,
        {
          id: 'auditor-run',
          displayText: auditorMessages.run,
        },
        {
          id: 'auditor-run-summary',
          displayText: auditorMessages.summary,
        },
      ],
      component: RunSummaryLoadable,
    },
    [AdminDashboardAuditorPath.RunDetails]: {
      path: AdminDashboardAuditorPath.RunDetails,
      title: auditorMessages.details,
      breadcrumbs: [
        auditorBreadcrumb,
        {
          id: 'auditor-run',
          displayText: auditorMessages.run,
        },
        {
          id: 'auditor-run-summary',
          displayText: auditorMessages.details,
        },
      ],
      component: RunDetailsLoadable,
    },
    [AdminDashboardAuditorPath.RunFindings]: {
      path: AdminDashboardAuditorPath.RunFindings,
      title: auditorMessages.findings,
      breadcrumbs: [
        auditorBreadcrumb,
        {
          id: 'auditor-run',
          displayText: auditorMessages.run,
        },
        {
          id: 'auditor-run-findings',
          displayText: auditorMessages.findings,
        },
      ],
      component: RunFindingsLoadable,
    },
    [AdminDashboardAuditorPath.RunDataFlows]: {
      path: AdminDashboardAuditorPath.RunDataFlows,
      title: auditorMessages.networkRequests,
      breadcrumbs: [
        auditorBreadcrumb,
        {
          id: 'auditor-run',
          displayText: auditorMessages.run,
        },
        {
          id: 'auditor-run-data-flows',
          displayText: auditorMessages.networkRequests,
        },
      ],
      component: RunDataFlowsLoadable,
    },
    [AdminDashboardAuditorPath.RunCookies]: {
      path: AdminDashboardAuditorPath.RunCookies,
      title: auditorMessages.cookies,
      breadcrumbs: [
        auditorBreadcrumb,
        {
          id: 'auditor-run',
          displayText: auditorMessages.run,
        },
        {
          id: 'auditor-run-cookies',
          displayText: auditorMessages.cookies,
        },
      ],
      component: RunCookiesLoadable,
    },
    [AdminDashboardAuditorPath.ActionItems]: {
      path: AdminDashboardAuditorPath.ActionItems,
      title: navMenuMessages.linkActionItems,
      component: createOnboardingItemsComponent(TranscendProduct.WebAuditor),
      breadcrumbs: [auditorBreadcrumb],
    },
  };
