import { SelectSubCategoryOption } from '.';
import { CLASSIFICATION_METHODS } from './constants';

/**
 * Fields used to sort by best guess
 */
export interface BestGuessSortCriteria {
  /** classification method */
  classificationMethod?: SelectSubCategoryOption['classificationMethod'];
  /** Model version for guess */
  classifierVersion?: SelectSubCategoryOption['classifierVersion'];
  /** % confidence of guess */
  confidence: SelectSubCategoryOption['confidence'];
  /** The name for the subcategory */
  name: SelectSubCategoryOption['name'];
}

export const sortByBestGuess = (
  a: BestGuessSortCriteria,
  b: BestGuessSortCriteria,
): number => {
  if (
    a.classificationMethod &&
    b.classificationMethod &&
    a.classificationMethod !== b.classificationMethod
  ) {
    return (
      CLASSIFICATION_METHODS.indexOf(b.classificationMethod) -
      CLASSIFICATION_METHODS.indexOf(a.classificationMethod)
    );
  }

  return (
    (a.classifierVersion && b.classifierVersion
      ? b.classifierVersion - a.classifierVersion
      : 0) ||
    (b.confidence || 0) - (a.confidence || 0) ||
    b.name.localeCompare(a.name)
  );
};
