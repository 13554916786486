import { makeEnum } from '@transcend-io/type-utils';

export const AUDITOR_PATH_PREFIX = '/auditor';

export const AdminDashboardAuditorPath = makeEnum({
  Auditor: AUDITOR_PATH_PREFIX,
  Schedules: `${AUDITOR_PATH_PREFIX}/schedules`,
  ActionItems: `${AUDITOR_PATH_PREFIX}/action-items`,
  RunSummary: `${AUDITOR_PATH_PREFIX}/run/:runId/summary`,
  RunFindings: `${AUDITOR_PATH_PREFIX}/run/:runId/findings`,
  RunDataFlows: `${AUDITOR_PATH_PREFIX}/run/:runId/requests`,
  RunCookies: `${AUDITOR_PATH_PREFIX}/run/:runId/cookies`,
  RunDetails: `${AUDITOR_PATH_PREFIX}/run/:runId/details`,
});

/** Override type */
export type AdminDashboardAuditorPath =
  (typeof AdminDashboardAuditorPath)[keyof typeof AdminDashboardAuditorPath];
