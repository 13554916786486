import { FlexRowCenterVertical, StyleUtils } from '@main/core-ui';
import type { Editor } from '@tiptap/react';
import React from 'react';
import { useIntl } from 'react-intl';

import { HEADING_LEVELS } from '../constants';
import { RichEditorProps } from '../RichEditor';
import { TipTapMarkType, TipTapNodeType } from './enums';
import { HeadingButtonGroup } from './HeadingButtonGroup';
import { extensionIsEnabled } from './helpers';
import { ImageButtonGroup } from './ImageButtonGroup';
import { LinkButtonGroup } from './LinkButtonGroup';
import { ListButtonGroup } from './ListButtonGroup';
import { toolbarMessages } from './messages';
import { TableButtonGroup } from './TableButtonGroup';
import { TextStyleButtonGroup } from './TextStyleButtonGroup';
import { ToolbarButton } from './ToolbarButton';
import { StyledSeparator, StyledToolbar } from './wrappers';

export interface ToolbarProps
  extends Pick<
    RichEditorProps,
    'uploadOptions' | 'editable' | 'transformImageSrc'
  > {
  /** The TipTap editor */
  editor: Editor | null;
  /** Content to show inline with the toolbar. Content is right-aligned when toolbar is not in read-only mode */
  additionalToolbarContent?: React.ReactNode;
}

/**
 * A toolbar for the text editor
 *
 * @see https://tiptap.dev/api/commands - Commands to the rich text editor (what happens onClick)
 * @see https://www.radix-ui.com/docs/primitives/components/toolbar - Primitive component for the Toolbar (purely functional; no styling)
 * @see https://www.figma.com/file/dTxJKYLi46aK9n1xDbPb46/%5BADv2%5D-Rich-Text-Editor - Figma design
 */
export const Toolbar: React.FC<ToolbarProps> = ({
  editor,
  editable,
  additionalToolbarContent,
  uploadOptions,
  transformImageSrc,
}) => {
  const { formatMessage } = useIntl();
  if (!editor) return null;

  return (
    <>
      {(editable || additionalToolbarContent) && (
        <StyledToolbar>
          {editable
            ? [
                // Text styling group
                ...(extensionIsEnabled(editor, TipTapMarkType.Bold) ||
                extensionIsEnabled(editor, TipTapMarkType.Italic) ||
                extensionIsEnabled(editor, TipTapMarkType.Strike)
                  ? [<TextStyleButtonGroup key="text" editor={editor} />]
                  : []),

                // Link group
                ...(extensionIsEnabled(editor, TipTapMarkType.Link)
                  ? [<LinkButtonGroup key="link" editor={editor} />]
                  : []),

                // Clear formatting button
                <ToolbarButton
                  key="clear"
                  title={formatMessage(toolbarMessages.clearFormatting)}
                  onClick={() =>
                    editor.chain().focus().unsetAllMarks().clearNodes().run()
                  }
                  icon="clear-formatting"
                  disabled={false}
                />,

                // Heading group
                ...(extensionIsEnabled(editor, TipTapNodeType.Heading, {
                  levels: HEADING_LEVELS,
                })
                  ? [<HeadingButtonGroup key="heading" editor={editor} />]
                  : []),

                // List group
                ...((extensionIsEnabled(editor, TipTapNodeType.OrderedList) ||
                  extensionIsEnabled(editor, TipTapNodeType.BulletList)) &&
                // hide list buttons when table is active
                !editor.isActive(TipTapNodeType.Table)
                  ? [<ListButtonGroup key="list" editor={editor} />]
                  : []),

                // Image group
                ...(extensionIsEnabled(editor, TipTapNodeType.Image) &&
                uploadOptions
                  ? [
                      <ImageButtonGroup
                        key="image"
                        editor={editor}
                        uploadOptions={uploadOptions}
                        transformImageSrc={transformImageSrc}
                      />,
                    ]
                  : []),

                // Table group
                ...(extensionIsEnabled(editor, TipTapNodeType.Table)
                  ? [<TableButtonGroup key="table" editor={editor} />]
                  : []),

                // Additional content
                ...(additionalToolbarContent
                  ? [
                      <FlexRowCenterVertical
                        key="additional-content"
                        style={{
                          marginLeft: 'auto',
                          gap: StyleUtils.Spacing.sm,
                        }}
                      >
                        {additionalToolbarContent}
                      </FlexRowCenterVertical>,
                    ]
                  : []),
              ]
                // join button groups with a separator in between groups
                .reduce<React.ReactNode[] | null>(
                  (prev, curr) =>
                    prev === null
                      ? [curr]
                      : [
                          prev,
                          <StyledSeparator key={`after-${curr.key}`} />,
                          curr,
                        ],
                  null,
                )
            : additionalToolbarContent}
        </StyledToolbar>
      )}
    </>
  );
};
