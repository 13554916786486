import {
  GenericMessageDescriptor,
  useFormatMessageGeneric,
} from '@main/core-ui';
import React from 'react';

import {
  BootstrapButtonGroup,
  BootstrapRadioButtonGroupProps,
  BootstrapToggleButton,
  BootstrapToggleButtonProps,
} from './BootstrapButtonGroup';

/**
 * A radio button option
 */
export interface RadioButtonOption<T = any> {
  /** The identifying name of the option */
  name: string;
  /** The identifying value of the option */
  value?: T;
  /** The label message for the option */
  label: GenericMessageDescriptor;
  /** Whether the radio button is disabled */
  disabled?: boolean;
}

export interface ButtonGroupProps extends BootstrapRadioButtonGroupProps<any> {
  /** Style object for the button */
  buttonStyle?: React.CSSProperties;
  /** The options to display */
  options: RadioButtonOption[];
  /** The variant for the toggle button */
  variant?: BootstrapToggleButtonProps['variant'];
}

/**
 * ButtonGroup
 */
export const ButtonGroup: React.FC<ButtonGroupProps> = React.forwardRef(
  (
    { buttonStyle, options, variant = 'outline-primary', ...buttonProps },
    ref,
  ) => {
    const { formatMessageGeneric } = useFormatMessageGeneric();

    return (
      <BootstrapButtonGroup {...buttonProps} ref={ref}>
        {options.map(({ label, name, value, disabled }) => (
          <BootstrapToggleButton
            key={name}
            id={`${buttonProps.name}-${name}`}
            name={name}
            value={value ?? name}
            style={buttonStyle}
            disabled={disabled}
            variant={variant}
          >
            {formatMessageGeneric(label)}
          </BootstrapToggleButton>
        ))}
      </BootstrapButtonGroup>
    );
  },
);
