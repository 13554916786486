import type { RequestStatus } from '@transcend-io/privacy-types';
import { createEnum, filterEnum } from '@transcend-io/type-utils';

import { REQUEST_STATUSES } from './statuses';

/**
 * The statuses that are action items and require attention
 */
export const REQUEST_STATUSES_ACTION_ITEM = filterEnum(
  REQUEST_STATUSES,
  (status) => !!status.isActionItem,
);

/**
 * The statuses that are action items
 */
export const REQUEST_STATUS_ACTION_ITEM = createEnum(
  REQUEST_STATUSES_ACTION_ITEM,
);

/**
 * Determines whether a request is in a state where action is required
 *
 * @param status - The status of the request to check
 * @returns True if the request is in a state where action is required
 */
export function isActionItem(status: RequestStatus): boolean {
  return status in REQUEST_STATUS_ACTION_ITEM;
}
