import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { CurrentUser, endpoints } from '@main/access-control-types';
import {
  selectUser,
  selectUserLoggedIn,
} from '@main/admin-dashboard/src/Auth/App/selectors';
import type { ApolloError } from '@main/core-ui';
import { buildUseMutation, useRouter } from '@main/core-ui';

import { selectCTABanners } from './selectors';
import { hideBanner } from './slice';

const useRequestDemo = buildUseMutation(
  endpoints.requestDemo,
  endpoints.requestDemo.name,
);

export interface UseCTABanner {
  /** Schedule demo handler */
  scheduleDemo: () => void;
  /** Loading an action */
  loading: boolean;
  /** An error occurred with an action */
  error?: ApolloError | undefined;
  /** Flag to signal if banner can be displayed */
  canDisplay: boolean;
  /** Already requested demo */
  alreadyRequestedDemo: boolean;
}

export interface UseCTABannerProps {
  /** A key for this banner */
  showKey: string;
  /** Callback to determine when to show this banner */
  showCondition: (user: CurrentUser) => boolean;
}

export const useCTABanner = ({
  showKey,
  showCondition,
}: UseCTABannerProps): UseCTABanner => {
  const { location } = useRouter();
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const userLoggedIn = useSelector(selectUserLoggedIn);
  const hiddenBanners = useSelector(selectCTABanners);

  const [displayBanner, setDisplayBanner] = useState(false);
  const displayBannerTimeoutRef = useRef<NodeJS.Timeout>();

  const [requestDemo, { loading, error }] = useRequestDemo();

  useEffect(() => {
    if (user && userLoggedIn) {
      if (showCondition(user)) {
        displayBannerTimeoutRef.current = setTimeout(() => {
          setDisplayBanner(true);
        }, 1000);
      } else {
        setDisplayBanner(false);
      }
    } else {
      setDisplayBanner(false);
    }

    return () => {
      if (displayBannerTimeoutRef.current) {
        clearTimeout(displayBannerTimeoutRef.current);
      }
    };
  }, [location, user, userLoggedIn, hiddenBanners, showCondition, showKey]);

  return {
    canDisplay: displayBanner,
    alreadyRequestedDemo: !!hiddenBanners && !!hiddenBanners[showKey],
    loading,
    error,
    scheduleDemo: () => {
      requestDemo({
        variables: {
          input: {
            email: user?.email || '',
            companyName: user?.organization.name || '',
            pathname: location.pathname || '',
          },
        },
      }).then(() => {
        dispatch(hideBanner(showKey));
      });
    },
  };
};
