import { AdminDashboardPublicMiscPath } from '@main/access-control-types';
import ChangePassword from '@main/admin-dashboard/src/Auth/ChangePassword/Loadable';
import DemoForm from '@main/admin-dashboard/src/Auth/DemoForm/Loadable';
import ForgotPassword from '@main/admin-dashboard/src/Auth/ForgotPassword/Loadable';
import BulkRequestCompletion from '@main/admin-dashboard/src/PrivacyRequests/BulkRequestCompletion/Loadable';
import type { ExtendableRoute } from '@main/admin-dashboard/src/routes';
import { PublicOnlyRouteWrapper } from '@main/admin-dashboard/src/routes/PublicOnlyRoute';

import { CreateOrganizationLoadable } from '../CreateOrganization/Loadable';

export const miscPublicRoutes: {
  [k in AdminDashboardPublicMiscPath]: ExtendableRoute;
} = {
  [AdminDashboardPublicMiscPath.BulkRequests]: {
    path: AdminDashboardPublicMiscPath.BulkRequests,
    component: BulkRequestCompletion,
    wrapper: PublicOnlyRouteWrapper,
  },
  [AdminDashboardPublicMiscPath.ChangePassword]: {
    path: AdminDashboardPublicMiscPath.ChangePassword,
    component: ChangePassword,
    wrapper: PublicOnlyRouteWrapper,
  },
  [AdminDashboardPublicMiscPath.ResetPassword]: {
    path: AdminDashboardPublicMiscPath.ResetPassword,
    component: ForgotPassword,
    wrapper: PublicOnlyRouteWrapper,
  },
  [AdminDashboardPublicMiscPath.Demo]: {
    path: AdminDashboardPublicMiscPath.Demo,
    component: DemoForm,
    wrapper: PublicOnlyRouteWrapper,
  },
  [AdminDashboardPublicMiscPath.CreateOrganization]: {
    path: AdminDashboardPublicMiscPath.CreateOrganization,
    wrapper: PublicOnlyRouteWrapper,
    component: CreateOrganizationLoadable,
  },
};
