import { defineMessages } from '@transcend-io/internationalization';

export const simpleTextListMessages = defineMessages(
  'ad-core-components.SimpleTextList.simpleTextList',
  {
    addValueToList: {
      defaultMessage: 'Add value to list',
    },
    removeFromList: {
      defaultMessage: 'Remove value from list',
    },
    valueAlreadyAdded: {
      defaultMessage: 'Value already added',
    },
    invalidValue: {
      defaultMessage: 'Invalid value',
    },
  },
);
