import React from 'react';

import {
  MultipleChoice,
  MultipleChoiceOption,
  MultipleChoiceProps,
} from './MultipleChoice';

/**
 * option representing a valid value and label pair
 */
export type CheckboxInputGroupOption<T extends string> =
  MultipleChoiceOption<T>;

/**
 * props for CheckboxInputGroup wrapper around MultipleChoice
 */
export type CheckboxInputGroupProps<
  T extends string,
  K extends Array<CheckboxInputGroupOption<T>>,
> = Omit<MultipleChoiceProps<T, K>, 'type'>;

export const CheckboxInputGroup: <
  T extends string,
  K extends Array<CheckboxInputGroupOption<T>>,
>(
  props: CheckboxInputGroupProps<T, K>,
) => React.ReactElement = (props) => (
  <MultipleChoice {...props} type="checkbox" />
);
