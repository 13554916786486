import { Popover } from '@main/core-ui';
import { ColorPalette } from '@main/theme';
import React from 'react';
import { useTheme } from 'styled-components';

import { Button } from '../Button';
import {
  StyledColorButton,
  StyledColorLabel,
  StyledMainSelectedColor,
  StyledSelectedColorMark,
} from './wrappers';

export interface StaticColorPickerProps {
  /** The currently selected color */
  selectedColor: keyof ColorPalette;
  /** The list of colors to select from */
  colorOptions: (keyof ColorPalette)[];
  /** Callback for when a color is selected */
  onSelectColor: (color: keyof ColorPalette) => void;
}

/**
 * Static color picker with button and popover to select from a limited list of color options
 */
export const StaticColorPicker: React.FC<StaticColorPickerProps> = ({
  selectedColor,
  colorOptions,
  onSelectColor,
}) => {
  const theme = useTheme();

  return (
    <Popover
      contents={
        <div style={{ display: 'flex', padding: 8 }}>
          {colorOptions.map((color) => (
            <StyledColorButton
              key={color}
              color={theme.colors[color]}
              variant="secondary"
              onClick={() => onSelectColor(color)}
            >
              {color === selectedColor && <StyledSelectedColorMark />}
            </StyledColorButton>
          ))}
          <StyledColorLabel>
            {selectedColor.charAt(0).toUpperCase() + selectedColor.slice(1, -1)}
          </StyledColorLabel>
        </div>
      }
      placement="top"
    >
      <Button
        id="static-color-picker"
        variant="secondary"
        style={{ padding: 8, border: 'none', background: theme.colors.gray1 }}
      >
        <StyledMainSelectedColor
          id="selected-color"
          color={theme.colors[selectedColor]}
        />
      </Button>
    </Popover>
  );
};
