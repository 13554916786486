import { matchPath } from 'react-router-dom';

import { Optionalize } from '@transcend-io/type-utils';

import {
  CurrentUser,
  FeatureGate,
  FeatureSet,
  Organization,
  OrganizationTier,
} from '@main/access-control-types';
import { FeatureFlags } from '@main/core-ui';

import { NavGateProps, NavMenuItem } from './types';

/**
 * Calculates if any of the feature flags ang feature sets that are required are enabled.
 * Requires at least one featureSet (if specified) AND the specified feature flag to be enabled (if specified)
 *
 * @param gate - the gate definition
 * @param featureFlags - the current map of feature flags
 * @param featureSet - the current feature set for the org
 * @param tier - the current org tier
 * @returns whether the gate is satisfied (if the nav item should be allowed)
 */
export function isNavGateAllowed(
  gate: Omit<NavGateProps, 'ctaPage'> | undefined,
  featureFlags: FeatureFlags,
  featureSet: FeatureSet,
  tier: OrganizationTier,
): boolean {
  if (!gate) {
    return true;
  }

  if (gate.isNavGateAllowed !== undefined) {
    return gate.isNavGateAllowed(featureFlags, featureSet, tier);
  }

  const featureFlagCorrect =
    !gate.featureFlagKey || featureFlags[gate.featureFlagKey];
  const featureSetCorrect =
    !gate.featureSetKeys ||
    gate.featureSetKeys.length === 0 ||
    gate.featureSetKeys.some(
      (key) =>
        (typeof featureSet[key] === 'string' &&
          featureSet[key] !== FeatureGate.Blocked) ||
        (typeof featureSet[key] === 'number' && featureSet[key] !== 0) ||
        (typeof featureSet[key] === 'boolean' && featureSet[key] === true),
    );

  // If the gate has an alternative destination, allow it the gate is allowed,
  // regardless of if the feature flag is actually true.
  // A blocked feature set overrides the feature flag though.
  if (gate.featureFlagKey && gate.featureFlagTo && featureSetCorrect) {
    return true;
  }

  return featureFlagCorrect && featureSetCorrect;
}

/**
 * gets a unique react key for the nav item. Using message ids like this usually
 * isn't a great idea, but these are stable so it doesn't matter
 *
 * @param item - the item to use
 * @param i - the current index as a fallback
 * @returns the string key
 */
export function getKeyForNavItem(
  item: Pick<NavMenuItem, 'to' | 'label'>,
  i: number,
): string {
  return typeof item.to === 'string' ? item.to : (item.label as any)?.id ?? i;
}

/**
 * Get the destination link or CTA page function for the nav item,
 * depending on the feature flag
 *
 * @param item - the item to determine the destination link for
 * @param featureFlags - the current feature flags
 * @returns the destination link
 */
export function getTo(
  item: Pick<NavMenuItem, 'to' | 'gate'>,
  featureFlags: FeatureFlags,
): NavMenuItem['to'] {
  return item?.gate?.featureFlagKey &&
    featureFlags[item.gate.featureFlagKey] &&
    item.gate.featureFlagTo
    ? item.gate.featureFlagTo
    : item.to;
}
/**
 * returns either the specified to href if it's a string or evaluates the function prop
 *
 * @param item - the item to check
 * @param user - the current user
 * @param organization - the current org
 * @returns either the current string to href or the evauluation result
 */
export function getNavItemHrefOrEvaluatedHref(
  item: NavMenuItem['to'],
  user: CurrentUser,
  organization: Organization,
): string | false {
  return typeof item === 'function' ? item({ user, organization }) : item;
}

/**
 * checks if the nav item is active
 *
 * @param item - the item to check
 * @param hrefOrEvaluated - the items href or evaluated href
 * @param currentPath - the current location
 * @returns if the item is active
 */
export function isNavItemActive(
  item: Optionalize<NavMenuItem, 'icon'>,
  hrefOrEvaluated: string | false,
  currentPath: string,
): boolean {
  const childItemsOrDefault = item.childItems ?? [];
  // does the current location share this item's base path?
  const matchingPath =
    item.basePath ||
    hrefOrEvaluated ||
    (item.gate?.ctaPage && getCtaPageHref(item.gate.ctaPage.id)) ||
    'garbage-text-for-sure-no-match';
  const partialMatch = matchPath(`${matchingPath}/*`, currentPath);
  // does the current location exactly match this item's path?
  const exactMatch = matchPath(matchingPath, currentPath);
  const isActive =
    (!!exactMatch ||
      (!!item.basePath &&
        !!partialMatch &&
        // and not a folder
        childItemsOrDefault.length === 0)) &&
    !(item.activePathExcludes ?? []).some((exclude) =>
      matchPath(exclude, currentPath),
    );

  return isActive;
}

/**
 * builds an href for the cta page id
 *
 * @param id - the cta page id
 * @returns the cta page href
 */
export function getCtaPageHref(id: string): string {
  return `/cta/${id}`;
}
