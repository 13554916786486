import 'antd/lib/tag/style/index';

import AntTag, { TagProps as AntTagProps } from 'antd/lib/tag';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { betaMessages } from './messages';

/**
 * Tag props
 */
export type TagProps = AntTagProps;

/**
 * A tag
 */
export const Tag = AntTag;

export const BETA_PROPS = {
  color: 'green',
  children: <FormattedMessage {...betaMessages.beta} />,
};

/**
 * Flag a feature as being in beta
 */
export const Beta: React.FC = () => {
  const { formatMessage } = useIntl();
  // @deprecated TODO: https://transcend.height.app/T-19886 - remove ant
  return <Tag color="green">{formatMessage(betaMessages.beta)}</Tag>;
};
