/* eslint-disable react-hooks/rules-of-hooks */

import { FlexColumn, StyleUtils } from '@main/core-ui';
import { curveLinear, curveMonotoneX } from '@visx/curve';
import { localPoint } from '@visx/event';
import { Group } from '@visx/group';
import { Point } from '@visx/point';
import { AreaClosed, LinePath } from '@visx/shape';
import orderBy from 'lodash/orderBy';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useTheme } from 'styled-components';

import {
  StyledVisxTooltipColorCircle,
  VisxTooltipGridWrapper,
  VisxTooltipKeyWrapper,
  VisxTooltipValueWrapper,
} from '../BarStacks/wrappers';
import { DEFAULT_COLORS } from '../constants';
import { useChartGrowUpwardsSVGAnimation } from '../hooks';
import { InternalChartDataPoint } from '../types';
import { getValueData } from '../utils';
import { XYChartTemplate } from '../XYChartTemplate';
import { LineChartProps, LineChartTooltipData } from './types';
import { ChartSVGLine } from './wrappers';

const LineChartTooltip: React.FC<{
  /** tooltip data */
  tooltipData: LineChartTooltipData;
}> = ({ tooltipData }) => {
  const domain = tooltipData.legendItems ?? [];
  const sortedDomain =
    domain.length > 2
      ? orderBy(
          tooltipData.legendItems,
          ['numberValue', 'value', 'key'],
          ['desc', 'desc', 'asc'],
        )
      : domain;
  return (
    <FlexColumn style={{ gap: StyleUtils.Spacing.md }}>
      {tooltipData.title && (
        <div style={{ fontWeight: 600 }}>{tooltipData.title}</div>
      )}
      {tooltipData.subtitle && (
        <div style={{ fontWeight: 600 }}>{tooltipData.subtitle}</div>
      )}
      <VisxTooltipGridWrapper>
        {sortedDomain.map(({ key, value, hexColor }) => (
          <React.Fragment key={key}>
            {hexColor ? (
              <StyledVisxTooltipColorCircle color={hexColor} />
            ) : (
              <div />
            )}
            <VisxTooltipKeyWrapper
              style={{
                fontWeight: key === tooltipData.hoveredKey ? 600 : undefined,
              }}
            >
              {key}
            </VisxTooltipKeyWrapper>
            <VisxTooltipValueWrapper
              style={{
                fontWeight: key === tooltipData.hoveredKey ? 600 : undefined,
              }}
            >
              {value}
            </VisxTooltipValueWrapper>
          </React.Fragment>
        ))}
      </VisxTooltipGridWrapper>
    </FlexColumn>
  );
};

export const LineChart: React.FC<LineChartProps> = ({
  data,
  colors = DEFAULT_COLORS,
  unitLabel,
  scale = 'linear',
  shouldCurveLine = false,
  shouldShowPoints = false,
  shouldFillUnderLine = false,
  yUnitTransform,
  maxBottomLabels,
  displayPreviousXValueInTooltip,
  displayNextXValueInTooltip,
}) => {
  const { formatNumber } = useIntl();
  const [highlightedX, setHighlightedX] = useState<InternalChartDataPoint>();
  const [highlightedKey, setHighlightedKey] = useState<string>();
  const yUnitTransformOrDefault = yUnitTransform ?? ((s) => formatNumber(s));

  return (
    <XYChartTemplate<LineChartTooltipData>
      data={data}
      scale={scale}
      colors={colors}
      tooltip={LineChartTooltip}
      hideLegend={data.series.length === 1}
      maxBottomLabels={maxBottomLabels}
    >
      {({
        data,
        xScale,
        yScale,
        legendScale,
        seriesNames,
        chartInteriorHeight,
        chartInteriorWidth,
        hideTooltip,
        updateTooltip,
        setPreferredTooltipPosition,
        boundingRectLeft,
        boundingRectTop,
        hiddenKeys,
      }) => {
        useEffect(() => setPreferredTooltipPosition('above-center'), []);
        const linesAnimationStyles =
          useChartGrowUpwardsSVGAnimation(chartInteriorHeight);
        const theme = useTheme();
        /* eslint-enable react-hooks/rules-of-hooks */

        // Since the legends are center-aligned, we have to shift everything over by half
        const xShiftAmount = chartInteriorWidth / data.length / 2;

        return (
          <ChartSVGLine
            height={chartInteriorHeight}
            width={chartInteriorWidth}
            style={linesAnimationStyles}
            onMouseMove={(evt) => {
              const coords: Point =
                localPoint(evt) ?? ({ x: 0, y: 0 } as Point);

              // Get the x position closest to the cursor
              const closestX = data.reduce((prev, curr) =>
                Math.abs((xScale(curr.x) || 0) - (coords.x - xShiftAmount)) <
                Math.abs((xScale(prev.x) || 0) - (coords.x - xShiftAmount))
                  ? curr
                  : prev,
              );
              const closestIndex = data.findIndex(
                (item) => item.x === closestX.x,
              );
              // Get the highest Y value at that closest x point
              const maxY = Math.min(
                ...getValueData(data, closestX.x).map(
                  ([key]) => yScale(closestX[key]) || chartInteriorHeight,
                ),
              );
              const closestYKey = getValueData(data, closestX.x)
                .map(([key, value]) => ({ key, value }))
                .filter(({ key }) => !hiddenKeys.has(key))
                .reduce((prev, curr) =>
                  Math.abs((yScale(curr.value) || 0) - coords.y) <
                  Math.abs((yScale(prev.value) || 0) - coords.y)
                    ? curr
                    : prev,
                );
              const tooltipClientY = boundingRectTop + maxY;
              const tooltipClientX =
                boundingRectLeft + (xScale(closestX.x) || 0) + xShiftAmount;

              if (
                highlightedX === closestX &&
                highlightedKey === closestYKey.key
              ) {
                return;
              }
              setHighlightedX(closestX);
              setHighlightedKey(closestYKey.key);
              const prevX = data[closestIndex - 1];
              const nextX = data[closestIndex + 1];
              updateTooltip({
                tooltipOpen: true,
                tooltipTop: tooltipClientY,
                tooltipLeft: tooltipClientX,
                tooltipData: {
                  title: `${
                    displayPreviousXValueInTooltip && prevX
                      ? `${prevX.x} - `
                      : ''
                  }${closestX.x}${displayNextXValueInTooltip && nextX ? ` - ${nextX.x}` : ''}`,
                  subtitle: unitLabel,
                  legendItems: getValueData(data, closestX.x)
                    .map(([key, value]) => ({
                      key,
                      value: yUnitTransformOrDefault(value),
                      numberValue: value,
                      hexColor: legendScale(key),
                    }))
                    .filter(({ key }) => !hiddenKeys.has(key)),
                  hoveredKey: closestYKey.key,
                },
              });
            }}
            onMouseLeave={() => {
              // hide the tooltip and reset the highlighted x value
              hideTooltip();
              setHighlightedX(undefined);
              setHighlightedKey(undefined);
            }}
          >
            {highlightedX && (
              <>
                <rect
                  x={xScale(highlightedX.x)}
                  y="0"
                  width={chartInteriorWidth / data.length}
                  height={chartInteriorHeight}
                  opacity="5%"
                />
                <rect
                  x={(xScale(highlightedX.x) || 0) + xShiftAmount}
                  y="0"
                  width={1}
                  height={chartInteriorHeight}
                  opacity="5%"
                />
              </>
            )}
            {/** https://airbnb.io/visx/docs/shape */}
            {seriesNames.map((seriesName, key) =>
              hiddenKeys.has(seriesName) ? null : (
                <Group key={key} left={xShiftAmount}>
                  {shouldShowPoints &&
                    data.map((d, j) => (
                      <circle
                        key={key + j}
                        r={3}
                        cx={xScale(d.x) || 0}
                        cy={yScale(d[seriesName]) || chartInteriorHeight}
                        fill={legendScale(seriesName)}
                      />
                    ))}
                  <LinePath
                    stroke={legendScale(seriesName)}
                    strokeWidth={3}
                    data={data}
                    curve={shouldCurveLine ? curveMonotoneX : curveLinear}
                    x={(d) => xScale(d.x) || 0}
                    y={(d) => yScale(d[seriesName]) || chartInteriorHeight}
                    style={{
                      opacity:
                        !highlightedKey || seriesName === highlightedKey
                          ? '100%'
                          : '30%',
                    }}
                  />

                  {shouldFillUnderLine && (
                    <AreaClosed
                      fill={legendScale(seriesName)}
                      fillOpacity="10%"
                      data={data}
                      curve={shouldCurveLine ? curveMonotoneX : curveLinear}
                      x={(d) => xScale(d.x) || 0}
                      y={(d) => yScale(d[seriesName]) || chartInteriorHeight}
                      yScale={yScale}
                    />
                  )}
                  {highlightedX && (
                    <circle
                      r={
                        !highlightedKey || seriesName === highlightedKey ? 6 : 3
                      }
                      stroke={theme.colors.white}
                      strokeWidth={
                        !highlightedKey || seriesName === highlightedKey ? 2 : 1
                      }
                      cx={xScale(highlightedX.x) || 0}
                      cy={
                        yScale(highlightedX[seriesName]) || chartInteriorHeight
                      }
                      fill={legendScale(seriesName)}
                    />
                  )}
                </Group>
              ),
            )}
          </ChartSVGLine>
        );
      }}
    </XYChartTemplate>
  );
};
