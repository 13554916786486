import { Icon } from '@main/core-ui';
import React, { useCallback } from 'react';
import {
  BaseEdge,
  EdgeLabelRenderer,
  EdgeProps,
  getSmoothStepPath,
  useReactFlow,
} from 'reactflow';
import { useTheme } from 'styled-components';

import { Button } from '../Button';

// Example: https://reactflow.dev/examples/edges/custom-edges
export const DeletableEdge: React.FC<EdgeProps> = ({
  id,
  selected,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  style,
  markerEnd,
  pathOptions,
}) => {
  const theme = useTheme();

  // Gets the smooth step configuration used here:
  // https://github.com/xyflow/xyflow/blob/428fced240f13f3703c73e50d261d9144439a174/packages/react/src/components/Edges/SmoothStepEdge.tsx
  const [path, labelX, labelY] = getSmoothStepPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
    borderRadius: pathOptions?.borderRadius,
    offset: pathOptions?.offset,
  });
  const { deleteElements } = useReactFlow();

  const onEdgeClick = useCallback(
    (event, id) => {
      event.stopPropagation();
      // Remove edge
      deleteElements({ edges: [{ id }] });
    },
    [deleteElements],
  );

  return (
    <>
      <BaseEdge path={path} markerEnd={markerEnd} style={style} />
      <EdgeLabelRenderer>
        <div
          style={{
            position: 'absolute',
            transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`,
            fontSize: 12,
            // everything inside EdgeLabelRenderer has no pointer events by default
            // if you have an interactive element, set pointer-events: all
            pointerEvents: 'all',
          }}
        >
          {selected && (
            <Button
              style={{
                background: theme.colors.gray3,
                borderRadius: '50%',
                height: 16,
                width: 16,
                fontSize: 6,
                padding: 1,
              }}
              variant="secondary"
              iconOnly
              icon={<Icon type="close" />}
              onClick={(event) => onEdgeClick(event, id)}
            />
          )}
        </div>
      </EdgeLabelRenderer>
    </>
  );
};
