import { defineMessages } from '@transcend-io/internationalization';

export const cookiesMessages = defineMessages(
  'admin-dashboard.Auditor.Run.Cookies.cookies',
  {
    header: {
      defaultMessage: 'Cookies',
    },
  },
);
