import { FormCheckboxGroup, FormInput } from '@main/ad-core-components';
import { Validators } from '@main/core-ui';
import pick from 'lodash/pick';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';

import { columnsToMessages } from '../../../components';
import { requestAssessmentFromInventoryMessages } from './messages';
import { RequestAssessmentFromInventoryFormProps } from './types';

/**
 * Component to request an assessment based on fields from an inventory table
 */
export function TitleAndColumnsStep<D extends object>({
  columns,
  nativeColumnHeaders,
  columnNameToSyncOptionMapping,
}: Pick<
  RequestAssessmentFromInventoryFormProps<D>,
  | 'columns'
  | 'attributeColumnHeaders'
  | 'nativeColumnHeaders'
  | 'columnNameToSyncOptionMapping'
>): JSX.Element {
  const { formatMessage } = useIntl();

  const columnOptions = useMemo(
    () =>
      columnsToMessages({
        columns,
        uneditableColumns: [],
        nativeColumnHeaders:
          // only use the columns that are supported by the sync option mapping
          pick(nativeColumnHeaders, Object.keys(columnNameToSyncOptionMapping)),
        // TODO: https://transcend.height.app/T-37243 - support syncing to attributes
        attributeColumnHeaders: {},
      }).map(({ id, message }) => ({
        value: id,
        label: message,
      })) ?? [],
    [columnNameToSyncOptionMapping, columns, nativeColumnHeaders],
  );

  return (
    <>
      {/* Assessment Title */}
      <FormInput
        name="title"
        label={requestAssessmentFromInventoryMessages.titleLabel}
        required
        rules={[Validators.REQUIRED, Validators.MAX_LENGTH(255)]}
      />

      {/* Columns */}
      <FormCheckboxGroup
        name="columnIds"
        required
        options={columnOptions}
        rules={[Validators.REQUIRED]}
        label={formatMessage(
          requestAssessmentFromInventoryMessages.chooseColumnsLabel,
        )}
        info={requestAssessmentFromInventoryMessages.chooseColumnsDescription}
        columnCount={2}
      />
    </>
  );
}
