import { defineMessages } from '@transcend-io/internationalization';

export const uploadMessages = defineMessages(
  'ad-core-components.Upload.upload',
  {
    error: {
      defaultMessage: '{file} upload failed',
    },
    header: {
      defaultMessage: 'Click or drag file to this area to upload',
    },
    info: {
      defaultMessage: 'Support for a single or bulk upload.',
    },
    singleInfo: {
      defaultMessage: 'Support for a single file',
    },
    success: {
      defaultMessage: '{file} uploaded successfully',
    },
  },
);
