import { configureStore } from '@reduxjs/toolkit';
import {
  FLUSH,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
  REHYDRATE,
} from 'redux-persist';
import { createTracker } from 'redux-segment';
import thunk from 'redux-thunk';

import { NODE_ENV } from '@main/core-ui';

import { adminDashboardPersistedReducer } from './reducer';

export const adminDashboardStore = configureStore({
  devTools:
    NODE_ENV === 'development'
      ? {
          trace: true,
          traceLimit: 25,
        }
      : false,
  reducer: adminDashboardPersistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).prepend(thunk, ...(NODE_ENV === 'production' ? [createTracker()] : [])),
  preloadedState: {},
});

/** Shape of dispatch */
export type AdminDashboardDispatch = typeof adminDashboardStore.dispatch;
