import { statusDescriptionMessages, statusTitleMessages } from './messages';
import type { RequestStatusConfig } from './types';

/**
 * ## Canceled
 * The organization decided to cancel the request, and stop its processing.
 *
 * An organization member may choose to cancel a request for some reason.
 * This will stop the processing of the request and notify the Data Subject of why the cancellation occurred.
 *
 * ### ON CHANGE TO
 *  - We notify the Data Subject with the cancellation email notice
 */
export const CANCELED_STATUS: RequestStatusConfig = {
  color: 'warning',
  icon: 'close',
  isClosed: true,
  isError: true,
  canArchive: true,
  messages: {
    description: statusDescriptionMessages.CANCELED,
    title: statusTitleMessages.CANCELED,
  },
  transitions: [],
};
