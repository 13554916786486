import { defineMessages } from '@transcend-io/internationalization';

export const wizardPanelMessages = defineMessages(
  'ad-core-components.WizardPanel.wizardPanel',
  {
    selectBackend: {
      defaultMessage: 'Select Backend',
    },
  },
);
