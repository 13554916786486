import { defineMessages } from '@transcend-io/internationalization';

export const siloDiscoveryTabMessages = defineMessages(
  'admin-dashboard.DataMap.components.SiloDiscoveryTab.siloDiscoveryTab',
  {
    triage: {
      defaultMessage: 'Triage',
    },
    junk: {
      defaultMessage: 'Junk',
    },
  },
);
