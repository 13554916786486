import { Badge, LoadingSpinner } from '@main/core-ui';
import { DefinedMessage } from '@transcend-io/internationalization';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';

import {
  ActiveTab,
  InactiveTab,
  InactiveTabGroup,
  LoadingSpinnerWrapper,
  TabGroupRow,
} from './wrappers';

/** Enum of badge types to display next to the title */
export enum TabBadgeType {
  Loading = 'LOADING',
  Active = 'ACTIVE',
}

interface TabI {
  /** Title shown in the tab box */
  title: DefinedMessage;
  /** Component to render when this tab is selected */
  component: JSX.Element;
  /** Type of badge to show (loading vs active vs none) */
  badgeType?: TabBadgeType;
}

export interface TabbedViewProps {
  /** Set of tabs to render */
  tabs: TabI[];
}

export interface TabProps {
  /** Whether this is the selected tab or not */
  active?: boolean;
  /** Type of badge to show (loading vs active vs none) */
  badgeType?: TabBadgeType;
  /** Tab selection function */
  onClick?: React.MouseEventHandler;
  /** Title shown in the tab box */
  title: DefinedMessage;
}

const Tab: React.FC<TabProps> = ({
  active = false,
  badgeType,
  onClick,
  title,
}) => {
  const { formatMessage } = useIntl();
  const TabElement = active ? ActiveTab : InactiveTab;
  return (
    <TabElement onClick={onClick}>
      {formatMessage(title)}
      {badgeType === TabBadgeType.Active && (
        <Badge
          color="red1"
          round
          style={{
            display: 'inline-block',
            height: '6px',
            minWidth: '6px',
            padding: '0px',
            marginLeft: '6px',
          }}
        />
      )}
      {badgeType === TabBadgeType.Loading && (
        <LoadingSpinnerWrapper>
          <LoadingSpinner size={10} />
        </LoadingSpinnerWrapper>
      )}
    </TabElement>
  );
};

export const TabbedView: React.FC<TabbedViewProps> = ({ tabs }) => {
  const [selectedTab, setSelectedTab] = useState(0);

  const beforeGroupNodes = tabs.slice(0, selectedTab).map((tab, idx) => (
    <Tab
      onClick={() => {
        setSelectedTab(idx);
      }}
      badgeType={tab.badgeType}
      title={tab.title}
      key={idx}
    />
  ));
  const afterGroupNodes = tabs.slice(selectedTab + 1).map((tab, idx) => (
    <Tab
      onClick={() => {
        setSelectedTab(idx + selectedTab + 1);
      }}
      badgeType={tab.badgeType}
      title={tab.title}
      key={idx}
    />
  ));

  const tabViews = tabs.map(
    (tab, idx) => idx === selectedTab && <div key={idx}>{tab.component}</div>,
  );

  return (
    <>
      <TabGroupRow>
        {selectedTab !== 0 && (
          <InactiveTabGroup>{beforeGroupNodes}</InactiveTabGroup>
        )}
        <Tab
          active
          badgeType={tabs[selectedTab].badgeType}
          title={tabs[selectedTab].title}
        />
        <InactiveTabGroup>{afterGroupNodes}</InactiveTabGroup>
      </TabGroupRow>
      {tabViews}
    </>
  );
};
