import styled from 'styled-components';

export const CheckboxGroupWrapper = styled.div<{
  /** number of columns */
  columnCount: number;
}>`
  ${({ columnCount }) =>
    columnCount > 1 &&
    `
    display: grid;
    grid-template-columns: repeat(${columnCount}, 1fr);
    grid-auto-flow: row;
  `}
`;
