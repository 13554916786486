import { defineMessages } from '@transcend-io/internationalization';

import { PolicyType } from '@main/pc-types';

export const policyTypeMessages = defineMessages<PolicyType>(
  'admin-dashboard.PrivacyCenter.Policies.policyType',
  {
    [PolicyType.PrivacyPolicy]: {
      defaultMessage: 'Privacy Policy',
    },
    [PolicyType.CookiePolicy]: {
      defaultMessage: 'Cookie Policy',
    },
    [PolicyType.Custom]: {
      defaultMessage: 'Custom',
    },
  },
);

// Form form Items
export const formItemMessages = defineMessages(
  'admin-dashboard.PrivacyCenter.Policies.formItem',
  {
    privacyPolicyUrl: {
      defaultMessage: 'Privacy Policy URL',
    },
    privacyPolicyUrlInfo: {
      defaultMessage:
        'Import a policy by scraping the HTML from one of your websites',
    },
  },
);

export const policiesMessages = defineMessages(
  'admin-dashboard.PrivacyCenter.Policies.policies',
  {
    addPolicyButton: {
      defaultMessage: 'Add Policy',
    },
    header: {
      defaultMessage: 'Policies',
    },
    loadPolicy: {
      defaultMessage: 'Load Policy',
    },
    showOnPrivacyCenterEnabled: {
      defaultMessage: 'Enabled for {num} Languages',
    },
    showOnPrivacyCenterDisabled: {
      defaultMessage: 'Disabled',
    },
    preview: {
      defaultMessage: 'Preview Policy',
    },
    titleLabel: {
      defaultMessage: 'Title',
    },
    effectiveDateLabel: {
      defaultMessage: 'Effective Date',
    },
    localeLabel: {
      defaultMessage: 'Select Language to Edit',
    },
    displayOrderLabel: {
      defaultMessage: 'Display Order',
    },
    html: {
      defaultMessage: 'Load from HTML',
    },
    microsoftWord: {
      defaultMessage:
        // eslint-disable-next-line max-len
        'If you need to import your policy from a Microsoft Word document, first follow {link} to export your document to HTML. You can then copy and paste the HTML into the editor below.',
    },
    link: {
      defaultMessage: 'these instructions',
    },
    url: {
      defaultMessage: 'Load from URL',
    },
    loaded: {
      defaultMessage: 'Loaded',
    },
    translate: {
      defaultMessage: 'Translate Policy',
    },
    edit: {
      defaultMessage: 'Edit Policy',
    },
    none: {
      defaultMessage: 'No Policy Found',
    },
    loadPreview: {
      defaultMessage: 'Load Preview',
    },
    savePolicy: {
      defaultMessage: 'Apply Imported Policy',
    },
    defaultHeader: {
      defaultMessage: 'New Policy',
    },
    saveBeforeExport: {
      defaultMessage: 'Please save your policy before exporting',
    },
    disableEffectiveOn: {
      defaultMessage: 'Show In Privacy Center',
    },
    privacyCenterSideMenu: {
      defaultMessage: 'Show in Sidebar',
    },
  },
);
