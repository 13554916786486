import styled from 'styled-components';

import { sp } from '@main/theme';

/**
 * Employee upload CSV DSR modal wrapper
 */
export const DocumentationReferencesWrapper = styled.div`
  display: inline-block;

  h1 &,
  h2 &,
  h3 &,
  h4 &,
  h5 &,
  h6 &,
  p & {
    margin-left: ${sp(0.5)};
  }
`;
