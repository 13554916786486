import { defineMessages } from '@transcend-io/internationalization';

export const pullRequestsMessages = defineMessages(
  'admin-dashboard.CodeScanning.PullRequests.pullRequests',
  {
    newPullRequest: {
      defaultMessage: 'New Pull Request',
    },
    deleteSuccess: {
      defaultMessage:
        '{count, plural, one {Pull Request} other {Pull Requests}} successfully deleted',
    },
    pullRequestsCount: {
      defaultMessage:
        '{count} {count, plural, one {Pull Request} other {Pull Requests}}',
    },
    noDataMessage: {
      defaultMessage: 'No pull requests to display.',
    },
    confirmDeleteButton: {
      defaultMessage:
        'Delete {count, plural, one {Pull Request} other {{count} Pull Requests}}',
    },
    confirmDeletePrompt: {
      defaultMessage:
        'Are you sure you want to delete {count, plural, one {this pull request} other {{count} pull requests}}?',
    },
    pullRequestUserCount: {
      defaultMessage: '{count} {count, plural, one {user} other {users}}',
    },
  },
);

export const pullRequestsColumnHeaderMessages = defineMessages(
  'admin-dashboard.CodeScanning.PullRequests.pullRequestsColumnHeader',
  {
    title: {
      defaultMessage: 'title',
    },
    description: {
      defaultMessage: 'Description',
    },
    url: {
      defaultMessage: 'URL',
    },
    remoteId: {
      defaultMessage: 'Remote ID',
    },
    createdAt: {
      defaultMessage: 'Created At',
    },
    repository: {
      defaultMessage: 'Repository',
    },
    users: {
      defaultMessage: 'Users',
    },
  },
);

export const pullRequestModalMessages = defineMessages(
  'admin-dashboard.CodeScanning.PullRequests.pullRequestModal',
  {
    modalButton: {
      defaultMessage: 'New Pull Request',
    },
    header: {
      defaultMessage: 'New Pull Request',
    },
    introduction: {
      defaultMessage:
        'Create a new pull request that you want to track calls to your large language models.',
    },
    submitButton: {
      defaultMessage: 'Create New Pull Request',
    },
    submitSuccess: {
      defaultMessage: 'New pull request created!',
    },
  },
);
