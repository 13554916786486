import {
  extractFormItemWrapperProps,
  FormItemWrapper,
  IFormFieldProps,
  IFormItemWrapperProps,
  multipleValidators,
  TValidator,
} from '@main/core-ui';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { ButtonGroup, ButtonGroupProps } from './ButtonGroup';

export interface IFormButtonGroupProps extends IFormFieldProps {
  /** the validation rules for the button group */
  rules?: TValidator[];
  /** Custom style overrides */
  style?: any;
}

/**
 * A raw form button group for use cases where formatting is not desired
 */
export const FormButtonGroupRaw: React.FC<
  IFormButtonGroupProps & ButtonGroupProps
> = ({ name, defaultValue, rules, ...bootstrapButtonGroupProps }) => {
  const { control } = useFormContext();
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      rules={rules ? { validate: multipleValidators(rules) } : undefined}
      render={({ field }) => (
        <ButtonGroup
          {...bootstrapButtonGroupProps}
          {...field}
          name={name}
          defaultValue={defaultValue}
        />
      )}
    />
  );
};

/**
 * A form button group with formatting and error display
 */
export const FormButtonGroup: React.FC<
  IFormButtonGroupProps &
    ButtonGroupProps &
    Omit<IFormItemWrapperProps, 'errors'>
> = (props) => {
  const {
    formState: { errors },
  } = useFormContext();
  const { passthroughProps, wrapperProps } = extractFormItemWrapperProps(props);
  return (
    <FormItemWrapper errors={errors} {...wrapperProps}>
      <FormButtonGroupRaw {...passthroughProps} />
    </FormItemWrapper>
  );
};
