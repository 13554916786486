import { defineMessages } from '@transcend-io/internationalization';

export const pathfinderPageTitleMessages = defineMessages(
  'admin-dashboard.Pathfinder.pathfinderPageTitle',
  {
    pathfinder: {
      defaultMessage: 'Pathfinder',
    },
    pathfinders: {
      defaultMessage: 'Pathfinders',
    },
    applications: {
      defaultMessage: 'Applications',
    },
    appUsers: {
      defaultMessage: 'Users',
    },
    pathfinderPolicies: {
      defaultMessage: 'Policies',
    },
    agents: {
      defaultMessage: 'Assistants',
    },
    agentFunctions: {
      defaultMessage: 'Assistant Functions',
    },
    agentFiles: {
      defaultMessage: 'Assistant Files',
    },
  },
);
