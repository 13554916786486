import { createLoadable } from '@main/core-ui';

// Load the component dynamically
export const AgentFileLoadable = createLoadable({
  loader: () => import('./AgentFile'),
});

export const NewAgentFileLoadable = createLoadable({
  loader: () => import('./NewAgentFile'),
});
