import type { RequestStatus } from '@transcend-io/privacy-types';
import { createEnum, filterEnum } from '@transcend-io/type-utils';

import { isActive } from './isActive';
import { REQUEST_STATUSES } from './statuses';

/**
 * The statuses of a request where the request is active in the context of a data subject
 */
export const REQUEST_STATUSES_ACTIVE_DATA_SUBJECT = filterEnum(
  REQUEST_STATUSES,
  (status, statusName) =>
    !status.isClosed ||
    !!status.canDownload ||
    isActive(statusName) ||
    !!status.canViewByDataSubject,
);

/**
 * The data subject active statuses keyed by status
 */
export const REQUEST_STATUS_ACTIVE_DATA_SUBJECT = createEnum(
  REQUEST_STATUSES_ACTIVE_DATA_SUBJECT,
);

/**
 * Check if a request is active in the context of a data subject
 *
 * @param status - The status of the request to check
 * @returns True if the request is active in context of a data subject
 */
export function isActiveDataSubject(status: RequestStatus): boolean {
  return status in REQUEST_STATUS_ACTIVE_DATA_SUBJECT;
}
