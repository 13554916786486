import { gql } from '@apollo/client';
import {
  SELECT_USER_NODES,
  SelectedUser,
  SelectUserProps,
  useInlineInput,
} from '@main/ad-core-components';
import { buildUseMutation } from '@main/core-ui';
import { endpoints, SubDataPoint } from '@main/datamap-types';
import { ID } from '@main/schema-utils';
import React from 'react';

import { objectToGqlString } from '../../helpers/objectToGqlString';
import { updateSubDataPointsGqlCache } from '../../helpers/updateSubDataPointsGqlCache';
import { buildSelectUser } from './buildSelectUser';

const useUpdateDataPoint = buildUseMutation(
  endpoints.updateOrCreateDataPoint,
  'UpdateDataPointOwners',
  {
    dataPoint: {
      id: null,
      owners: SELECT_USER_NODES,
    },
  },
);

/** Props for the `InlineSelectDataPointOwners` component */
interface InlineSelectDataPointOwnersProps
  extends Omit<SelectUserProps<true>, 'onChange' | 'onBlur'> {
  /** The ID of the parent data point for this cell */
  id: ID<'dataPoint'>;
  /** The value for the cell */
  value: SelectedUser[];
  /** The ID of the dataSilo that owns the data point */
  dataSiloId: ID<'dataSilo'>;
  /** Other subdatapoints with this datapoint id whose owners should also be updated after a change */
  subDataPointsToUpdate?: SubDataPoint[];
}

export const InlineSelectDataPointOwners: React.FC<
  InlineSelectDataPointOwnersProps
> = ({
  id,
  value: initialValue,
  dataSiloId,
  subDataPointsToUpdate,
  ...props
}) => {
  const [updateDataPoint, { loading }] = useUpdateDataPoint({
    ...(subDataPointsToUpdate
      ? {
          update: (cache, { data }) => {
            const { dataPoint } = (data as any).updateOrCreateDataPoint;

            // Manually update the cache so that we don't have to refetch the entire (slow) subdatapoints query
            updateSubDataPointsGqlCache({
              subDataPointsToUpdate,
              cache,
              data: {
                dataPointOwners: dataPoint.owners,
              },
              fragment: gql`
                fragment DataPointOwners on SubDataPoint {
                  dataPointOwners {
                    ${objectToGqlString(SELECT_USER_NODES)}
                  }
                }
              `,
              // dataPoint should always be defined, but could get forgotten in the GQL response fields
              shouldUpdate: (sdp) => sdp.dataPoint?.id === dataPoint.id,
            });
          },
        }
      : {}),
  });

  return buildSelectUser(
    loading,
    useInlineInput<SelectedUser[]>({
      initialValue,
      optionsComparator: (existing, updated) =>
        JSON.stringify(existing.map(({ id }) => id).sort()) ===
        JSON.stringify(updated.map(({ id }) => id).sort()),
      onUpdate: (values) =>
        updateDataPoint({
          variables: {
            input: {
              id,
              dataSiloId,
              ownerIds: values
                .filter((option) => !option.isNew)
                .map((option) => (option as any).id),
            },
          },
        }).then(({ data, errors }) => ({
          data: data?.dataPoint?.owners,
          errors,
        })),
    }),
    props,
  );
};
