import { Icon, Popover, useOnClickOutside } from '@main/core-ui';
import { indexBy } from '@main/utils';
import React, { useMemo, useState } from 'react';
import { MessageDescriptor } from 'react-intl';
import { useTheme } from 'styled-components';

import { Button } from '../Button';
import { FilterPillsSection } from './FilterPillsSection';
import { FilterPopover } from './FilterPopover';
import { filterManagerMessages } from './messages';
import { FiltersConfig } from './types';
import { StyledPillBox } from './wrappers';

export interface FilterManagerProps<TFilterInput> {
  /** HTML id for filter element */
  id?: string;
  /** The key to use for the search text */
  defaultSearchKey?: Extract<keyof TFilterInput, string>;
  /** The configuration to use */
  filtersConfig: FiltersConfig<TFilterInput>;
  /** Values */
  filtersValues: TFilterInput;
  /** Placeholder text */
  placeholder?: MessageDescriptor;
  /** Callback to clear a given filter key */
  clearFilter: (filterKey: Extract<keyof TFilterInput, string>) => void;
  /** global onChange to be passed to children as default */
  onChange?: (
    filterKey: keyof TFilterInput,
    value: TFilterInput[keyof TFilterInput],
  ) => void;
}

export const FilterManager = <T extends Record<string, unknown>>({
  id,
  onChange,
  defaultSearchKey,
  filtersConfig,
  filtersValues,
  placeholder = filterManagerMessages.defaultPlaceholder,
  clearFilter,
}: FilterManagerProps<T>): JSX.Element => {
  // need a div element so we can close it when the user clicks outside
  // useOnClickOutside requires element as a param
  const [filterDivElement, setFilterDivElement] =
    useState<HTMLDivElement | null>(null);
  const [popoverContainer, setPopoverContainer] =
    useState<HTMLDivElement | null>(null);

  // close the menu if user clicks outside
  useOnClickOutside(filterDivElement, () => setMenuIsOpen(false));

  const [currentSelectorKey, setCurrentSelectorKey] = useState<
    Extract<keyof T, string> | ''
  >('');

  const filtersConfigLookup = useMemo(
    () => indexBy(filtersConfig, 'filterKey'),
    [filtersConfig],
  );

  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const hasAppliedFilters = Object.keys(filtersValues).length > 0;

  const theme = useTheme();

  return (
    <div ref={setPopoverContainer}>
      <Popover
        id={`${`${id}-` || ''}filter-popover`}
        show={menuIsOpen}
        trigger={undefined}
        contents={
          <div ref={setFilterDivElement}>
            <FilterPopover
              id={id} // id not assigned here; just passed down
              onChange={onChange}
              filtersConfig={filtersConfig}
              filtersValues={filtersValues}
              setCurrentSelectorKey={setCurrentSelectorKey}
              filtersConfigLookup={filtersConfigLookup}
              currentSelectorKey={currentSelectorKey}
              closeMenu={() => setMenuIsOpen(false)}
            />
          </div>
        }
        hideArrow
        noPadding
        width={currentSelectorKey ? '400px' : '16em'}
        placement="bottom-start"
        // Render the popup with the pillbox as its DOM parent; helps avoid unexpected behavior with
        // other "click outside" functionality, i.e. when rendering FilterManager inside a modal
        container={popoverContainer}
      >
        <StyledPillBox
          id={id}
          onClick={() => {
            // If there's no filter config, we're just doing a simple text search,
            // so no need to render an empty popover menu
            if (filtersConfig.length > 0) {
              setMenuIsOpen(true);
            }
          }}
        >
          <FilterPillsSection
            filtersConfigLookup={filtersConfigLookup}
            filtersValues={filtersValues}
            placeholder={placeholder}
            defaultSearchKey={defaultSearchKey}
            onChange={onChange}
            clearFilter={(filterKey) => {
              clearFilter(filterKey);
              setCurrentSelectorKey('');
            }}
            setActiveMenu={(filterKey) => {
              setCurrentSelectorKey(filterKey);
              setMenuIsOpen(true);
            }}
            setMenuIsOpen={setMenuIsOpen}
          />
          {filtersConfig.length > 0 && (
            <Button
              id={`${id ? `${id}-` : ''}add-filter`}
              size="sm"
              variant="link"
              style={{
                padding: '0px',
                ...(hasAppliedFilters ? { opacity: 1 } : {}),
              }}
              onClick={() => {
                setCurrentSelectorKey('');
                setMenuIsOpen(true);
              }}
              icon={
                <Icon
                  type="circle-add"
                  color={theme.colors.transcendNavy3}
                  size={18}
                />
              }
            />
          )}
        </StyledPillBox>
      </Popover>
    </div>
  );
};
