import { transparentize } from 'polished';
import styled from 'styled-components';

import { Button } from '../Button';

export interface DropzoneWrapperProps {
  /** Whether there is an uploaded image previewed */
  $hasPreview: boolean;
  /** Whether the dropzone is active */
  isDragActive: boolean;
}

// Optimized for one image previews only for now
export const DropzoneWrapper = styled.div<DropzoneWrapperProps>`
  width: 96px;
  height: 96px;
  margin-right: 16px;
  background: ${({ theme, $hasPreview, isDragActive }) =>
    $hasPreview
      ? theme.colors.white
      : isDragActive
        ? theme.colors.gray2
        : theme.colors.gray1};
  border: 1px dashed ${({ theme }) => theme.colors.transcendNavy4};
  border-radius: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  transition: all 0.3s;
  outline-color: ${({ theme }) => transparentize(0.6, theme.colors.transcend)};
`;

export const DropzoneText = styled.div`
  color: ${({ theme }) => theme.colors.transcendNavy3};
  font-size: 12px;
  text-align: center;
  padding: 8px;
`;

export const ImagePreview = styled.img`
  max-width: 100%;
  max-height: 100%;
  border-radius: 10px;
`;

export const DescriptionWrapper = styled.div`
  font-size: 16px;
  color: ${({ theme }) => theme.colors.transcendNavy3};
  font-weight: 300;
  margin-bottom: 4px;
`;

export const UploadButton = styled(Button)`
  margin-right: 8px;
`;

export const RemoveButton = styled(Button)`
  border: 1px solid ${({ theme }) => theme.colors.red3};
`;
