import { Tooltip } from '@main/core-ui';
import React from 'react';
import { useIntl } from 'react-intl';

import { HEADING_LEVELS } from '../constants';
import { TipTapNodeType } from './enums';
import { extensionIsEnabled } from './helpers';
import { toolbarMessages } from './messages';
import { ToolbarButtonGroupProps } from './types';
import { StyledToggle } from './wrappers';

export const HeadingButtonGroup: React.FC<ToolbarButtonGroupProps> = ({
  editor,
}) => {
  const { formatMessage } = useIntl();

  return (
    <>
      {extensionIsEnabled(editor, TipTapNodeType.Heading, {
        levels: HEADING_LEVELS,
      }) &&
        HEADING_LEVELS.map((level) => (
          <Tooltip
            key={level}
            title={formatMessage(toolbarMessages.heading, {
              level,
            })}
          >
            <StyledToggle
              key={level}
              aria-label={formatMessage(toolbarMessages.heading, {
                level,
              })}
              pressed={editor.isActive(TipTapNodeType.Heading, {
                level,
              })}
              onPressedChange={() =>
                editor.chain().focus().toggleHeading({ level }).run()
              }
            >
              H{level}
            </StyledToggle>
          </Tooltip>
        ))}
    </>
  );
};
