import { TranscendProduct } from '@transcend-io/privacy-types';

import BulkRequestCompletion from '@main/admin-dashboard/src/PrivacyRequests/BulkRequestCompletion/Loadable';
import LegalHolds from '@main/admin-dashboard/src/PrivacyRequests/LegalHolds/Loadable';
import { LegalMatters } from '@main/admin-dashboard/src/PrivacyRequests/LegalMatters/Loadable';
import Request from '@main/admin-dashboard/src/PrivacyRequests/Request/Loadable';
import RequestDataSilo from '@main/admin-dashboard/src/PrivacyRequests/Request/RequestDataSilo/Loadable';
import { RequestIdentifierSettingsLoadable } from '@main/admin-dashboard/src/PrivacyRequests/RequestIdentifierSettings/Loadable';
import Requests from '@main/admin-dashboard/src/PrivacyRequests/Requests/Loadable';
import RequestSettings from '@main/admin-dashboard/src/PrivacyRequests/RequestSettings/Loadable';
import type { ExtendableRoute } from '@main/admin-dashboard/src/routes';
import { AdminDashboardPrivacyRequestPath } from '@main/dsr-types';

import { createOnboardingItemsComponent } from '../Auth/ActionItems/OnboardingItemsCollections/OnboardingItemCollections';
import { privacyCenterPageTitleMessages } from '../PrivacyCenter/messages';
import { navMenuMessages } from '../routes/OrganizationRoute/NavMenu/messages';
import { ActionsLoadable } from './Actions/Loadable';
import { GetStarted } from './components/GetStarted/Loadable';
import { PrivacyRequestsDashboardLoadable } from './components/PrivacyRequestsDashboard/Loadable';
import { DataSubjectsLoadable } from './DataSubjects/Loadable';
import { EmailSettingsLoadable } from './EmailSettings/Configuration/Loadable';
import { TemplatesLoadable } from './EmailSettings/Templates/Loadable';
import { EmailSettingsTestingLoadable } from './EmailSettings/Testing/Loadable';
import { privacyRequestsPageTitleMessages } from './messages';
import { RetentionScheduleLoadable } from './RetentionSchedule/Loadable';
import { WorkflowsV2Loadable } from './WorkflowsV2/Loadable';
import { WorkflowsV2NewWizardLoadable } from './WorkflowsV2/NewWizard/Loadable';

export const privacyRequestsBreadcrumb = {
  id: 'privacy-requests-header',
  displayText: privacyRequestsPageTitleMessages.dsrAutomation,
  href: AdminDashboardPrivacyRequestPath.PrivacyRequestDashboard,
};

const incomingRequestsBreadcrumbs = [
  privacyRequestsBreadcrumb,
  {
    id: AdminDashboardPrivacyRequestPath.IncomingRequests,
    displayText: privacyRequestsPageTitleMessages.incomingRequests,
    href: AdminDashboardPrivacyRequestPath.IncomingRequests,
  },
];

const legalHoldsBreadcrumbs = [
  privacyRequestsBreadcrumb,
  {
    id: AdminDashboardPrivacyRequestPath.LegalHolds,
    href: AdminDashboardPrivacyRequestPath.LegalHolds,
    displayText: privacyRequestsPageTitleMessages.legalHolds,
  },
];

export const workflowsBreadcrumbs = [
  privacyRequestsBreadcrumb,
  {
    id: AdminDashboardPrivacyRequestPath.Workflows,
    href: AdminDashboardPrivacyRequestPath.Workflows,
    displayText: privacyRequestsPageTitleMessages.workflows,
  },
];

export const workflowsV2Breadcrumbs = [
  privacyRequestsBreadcrumb,
  {
    id: AdminDashboardPrivacyRequestPath.Workflows,
    href: AdminDashboardPrivacyRequestPath.Workflows,
    displayText: privacyRequestsPageTitleMessages.workflows,
  },
];

const emailSettingsBreadcrumbs = [
  privacyRequestsBreadcrumb,
  {
    id: AdminDashboardPrivacyRequestPath.EmailSettingsTemplates,
    displayText: privacyCenterPageTitleMessages.emailSettings,
    href: AdminDashboardPrivacyRequestPath.EmailSettingsTemplates,
  },
];

const privacyRequestSettingsBreadcrumbs = [
  privacyRequestsBreadcrumb,
  {
    id: AdminDashboardPrivacyRequestPath.Settings,
    displayText: privacyRequestsPageTitleMessages.requestSettings,
    href: AdminDashboardPrivacyRequestPath.Settings,
  },
];

const workflowsConfigurationBreadcrumbs = [
  ...workflowsV2Breadcrumbs,
  {
    id: AdminDashboardPrivacyRequestPath.Workflows,
    href: AdminDashboardPrivacyRequestPath.Workflows,
    displayText: privacyRequestsPageTitleMessages.workflowsNew,
  },
];

export const privacyRequestsRoutes: {
  [k in AdminDashboardPrivacyRequestPath]: ExtendableRoute;
} = {
  [AdminDashboardPrivacyRequestPath.GetStarted]: {
    path: AdminDashboardPrivacyRequestPath.GetStarted,
    component: GetStarted,
    // no title - h1 & Helmet is in Onboarding component
  },
  [AdminDashboardPrivacyRequestPath.IncomingRequests]: {
    path: AdminDashboardPrivacyRequestPath.IncomingRequests,
    title: privacyRequestsPageTitleMessages.incomingRequests,
    component: Requests,
    breadcrumbs: incomingRequestsBreadcrumbs,
  },
  [AdminDashboardPrivacyRequestPath.Request]: {
    path: AdminDashboardPrivacyRequestPath.Request,
    component: Request,
    // Fallback value. This is set on the page dynamically.
    breadcrumbs: incomingRequestsBreadcrumbs,
  },
  [AdminDashboardPrivacyRequestPath.LegalHolds]: {
    path: AdminDashboardPrivacyRequestPath.LegalHolds,
    title: privacyRequestsPageTitleMessages.legalHolds,
    component: LegalHolds,
    breadcrumbs: legalHoldsBreadcrumbs,
  },
  [AdminDashboardPrivacyRequestPath.LegalMatters]: {
    path: AdminDashboardPrivacyRequestPath.LegalMatters,
    title: privacyRequestsPageTitleMessages.legalMatters,
    component: LegalMatters,
    breadcrumbs: [
      ...legalHoldsBreadcrumbs,
      {
        id: AdminDashboardPrivacyRequestPath.LegalMatters,
        href: AdminDashboardPrivacyRequestPath.LegalMatters,
        displayText: privacyRequestsPageTitleMessages.legalMatters,
      },
    ],
  },
  [AdminDashboardPrivacyRequestPath.Workflows]: {
    path: AdminDashboardPrivacyRequestPath.Workflows,
    component: WorkflowsV2Loadable,
    title: privacyRequestsPageTitleMessages.workflows,
    breadcrumbs: workflowsV2Breadcrumbs,
  },
  [AdminDashboardPrivacyRequestPath.WorkflowsConfiguration]: {
    path: AdminDashboardPrivacyRequestPath.WorkflowsConfiguration,
    component: WorkflowsV2NewWizardLoadable,
    title: privacyRequestsPageTitleMessages.workflowsNew,
    breadcrumbs: workflowsConfigurationBreadcrumbs,
  },
  [AdminDashboardPrivacyRequestPath.RequestDataSilo]: {
    path: AdminDashboardPrivacyRequestPath.RequestDataSilo,
    component: RequestDataSilo,
    breadcrumbs: workflowsBreadcrumbs,
  },
  [AdminDashboardPrivacyRequestPath.BulkRequests]: {
    path: AdminDashboardPrivacyRequestPath.BulkRequests,
    title: privacyRequestsPageTitleMessages.bulkRespond,
    component: BulkRequestCompletion,
    breadcrumbs: [
      privacyRequestsBreadcrumb,
      {
        id: AdminDashboardPrivacyRequestPath.BulkRequests,
        href: AdminDashboardPrivacyRequestPath.BulkRequests,
        displayText: privacyRequestsPageTitleMessages.bulkRespond,
      },
    ],
  },
  [AdminDashboardPrivacyRequestPath.Settings]: {
    path: AdminDashboardPrivacyRequestPath.Settings,
    title: privacyRequestsPageTitleMessages.requestSettings,
    component: RequestSettings,
    breadcrumbs: privacyRequestSettingsBreadcrumbs,
  },
  [AdminDashboardPrivacyRequestPath.SettingsDataActions]: {
    path: AdminDashboardPrivacyRequestPath.SettingsDataActions,
    title: privacyRequestsPageTitleMessages.dataActions,
    component: ActionsLoadable,
    breadcrumbs: privacyRequestSettingsBreadcrumbs,
  },
  [AdminDashboardPrivacyRequestPath.SettingsDataSubjects]: {
    path: AdminDashboardPrivacyRequestPath.SettingsDataSubjects,
    title: privacyRequestsPageTitleMessages.dataSubjects,
    component: DataSubjectsLoadable,
    breadcrumbs: privacyRequestSettingsBreadcrumbs,
  },
  [AdminDashboardPrivacyRequestPath.SettingsDataRetention]: {
    path: AdminDashboardPrivacyRequestPath.SettingsDataRetention,
    title: privacyRequestsPageTitleMessages.dataRetention,
    component: RetentionScheduleLoadable,
    breadcrumbs: privacyRequestSettingsBreadcrumbs,
  },
  [AdminDashboardPrivacyRequestPath.Identifiers]: {
    path: AdminDashboardPrivacyRequestPath.Identifiers,
    title: privacyRequestsPageTitleMessages.identifiers,
    component: RequestIdentifierSettingsLoadable,
    breadcrumbs: [
      privacyRequestsBreadcrumb,
      {
        id: AdminDashboardPrivacyRequestPath.Identifiers,
        href: AdminDashboardPrivacyRequestPath.Identifiers,
        displayText: privacyRequestsPageTitleMessages.identifiers,
      },
    ],
  },
  [AdminDashboardPrivacyRequestPath.PrivacyRequestDashboard]: {
    path: AdminDashboardPrivacyRequestPath.PrivacyRequestDashboard,
    title: privacyRequestsPageTitleMessages.dashboardPageTitle,
    component: PrivacyRequestsDashboardLoadable,
    breadcrumbs: [
      privacyRequestsBreadcrumb,
      {
        id: AdminDashboardPrivacyRequestPath.PrivacyRequestDashboard,
        href: AdminDashboardPrivacyRequestPath.PrivacyRequestDashboard,
        displayText: privacyRequestsPageTitleMessages.dashboardMenuLabel,
      },
    ],
  },
  [AdminDashboardPrivacyRequestPath.EmailSettingsConfiguration]: {
    path: AdminDashboardPrivacyRequestPath.EmailSettingsConfiguration,
    title: privacyCenterPageTitleMessages.emailSettings,
    component: EmailSettingsLoadable,
    breadcrumbs: emailSettingsBreadcrumbs,
  },
  [AdminDashboardPrivacyRequestPath.EmailSettingsTemplates]: {
    path: AdminDashboardPrivacyRequestPath.EmailSettingsTemplates,
    title: privacyCenterPageTitleMessages.emailSettings,
    component: TemplatesLoadable,
    breadcrumbs: emailSettingsBreadcrumbs,
  },
  [AdminDashboardPrivacyRequestPath.EmailSettingsTesting]: {
    path: AdminDashboardPrivacyRequestPath.EmailSettingsTesting,
    title: privacyCenterPageTitleMessages.emailSettings,
    component: EmailSettingsTestingLoadable,
    breadcrumbs: emailSettingsBreadcrumbs,
  },
  [AdminDashboardPrivacyRequestPath.ActionItems]: {
    path: AdminDashboardPrivacyRequestPath.ActionItems,
    title: navMenuMessages.linkActionItems,
    component: createOnboardingItemsComponent(TranscendProduct.DsrAutomation),
    breadcrumbs: [privacyRequestsBreadcrumb],
  },
};
