import styled from 'styled-components';

import { StyleUtils } from '@main/core-ui';

import { Accordion as BootstrapAccordion } from './BootstrapAccordion';

const accordionBorderRadius = (borderRadius: string): string => `
    // Override BS radius to match our protocols
    &:first-of-type,
    &:first-of-type .accordion-button {
      border-top-left-radius: ${borderRadius};
      border-top-right-radius: ${borderRadius};
    }
    &:last-of-type,
    &:last-of-type .accordion-button.collapsed {
      border-bottom-left-radius: ${borderRadius};
      border-bottom-right-radius: ${borderRadius};
    }
`;

export const StyledAccordionItem = styled(BootstrapAccordion.Item)<{
  /** Whether to show a border around the accordion items */
  bordered?: 'true' | 'false' | 'borderless';
  /** Whether to remove the left and right padding on the accordion header and body */
  $noHorizontalPadding?: boolean;
}>`
  ${accordionBorderRadius('12px')}

  ${({ bordered }) =>
    bordered === 'false'
      ? `
        border-left: none;
        border-right: none;

        ${accordionBorderRadius('0px')}
      `
      : bordered === 'borderless'
        ? `
          border: none;
        `
        : ``}

  ${({ $noHorizontalPadding }) =>
    $noHorizontalPadding
      ? `
          .accordion-button, .accordion-body {
            padding-left: 0;
            padding-right: 0;
          }
        `
      : ''}
`;

export const StyledAccordionHeader = styled(BootstrapAccordion.Header)<{
  /** Whether to disable the button for this accordion item */
  $disabled: boolean;
  /** Whether to hide the bottom border */
  $noBottomBorderWhenExpanded?: boolean;
  /** Whether to show the header icon on the right (defaults to left) */
  $headerIconOnRight?: boolean;
}>`
  border-left: none;
  border-right: none;

  .accordion-button {
    color: ${({ theme }) => theme.colors.transcendNavy2};
    font-weight: 600;
    font-size: 14px;
    display: flex;
    ${({ $headerIconOnRight }) =>
      $headerIconOnRight &&
      `
    flex-direction: row-reverse;
    justify-content: space-between;
    `}
    align-items: center;
    gap: ${StyleUtils.Spacing.sm};

    &:not(.collapsed) {
      color: ${({ theme }) => theme.colors.transcendNavy2};
      background: transparent;
      ${({ $noBottomBorderWhenExpanded }) =>
        $noBottomBorderWhenExpanded ? 'box-shadow: none;' : ''};
    }
  }

  .accordion-button::after {
    content: none;
  }
`;
