import React from 'react';
import { useTheme } from 'styled-components';

/**
 * Dropdown icon
 */
export const Dropdown: React.FC = (props) => {
  const theme = useTheme();
  return (
    <svg
      width="36px"
      height="23px"
      viewBox="0 0 36 23"
      version="1.1"
      {...props}
    >
      <defs />
      <g
        id="Symbols"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Button/dashboardsquare"
          transform="translate(-46.000000, -40.000000)"
          fill={theme.colors.primary}
          fillRule="nonzero"
        >
          <g
            id="inputicon/dropdown"
            transform="translate(40.000000, 28.000000)"
          >
            <polygon
              id="Shape"
              points="11.121 12.879 6.879 17.121 24 34.242 41.121 17.121 36.879 12.879 24 25.758"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};
