import styled from 'styled-components';

import { FlexRowCenterVertical, StyleUtils } from '@main/core-ui';

export const ImportedRowsTableWrapper = styled.div`
  max-width: 850px;
  overflow-x: scroll;
`;

export const StyledFlexRowCenterVertical = styled(FlexRowCenterVertical)`
  // Need to expand to full height to center cell contents after using useBlockLayout
  height: 100%;
`;

export const DescriptionWrapper = styled.p`
  margin-right: ${StyleUtils.Spacing.xs};
  font-weight: 400;
  color: ${({ theme }) => theme.colors.transcendNavy3};
  ${({ theme }) => theme.componentStyles.h3};
  line-height: 1.5;
  max-width: 32em;
`;

export const TitleContainerWrapper = styled.div`
  display: flex;
  align-self: start;
  margin-right: ${StyleUtils.Spacing.xl};
`;
