import { AntIcon } from '@main/core-ui/src/AntIcon';
import {
  getLocalizedPath,
  getUnderlyingPath,
} from '@main/internationalization';
import type { MessageValues } from '@transcend-io/internationalization';
import React, { ReactNode } from 'react';
import { MessageDescriptor, useIntl } from 'react-intl';
import {
  createPath,
  Location,
  NavLink,
  parsePath,
  useLocation,
} from 'react-router-dom';
import styled from 'styled-components';

import { Avatar } from '../Avatar';

/**
 * Plain a tag
 */
const PlainA = styled.a``;

/**
 * Props
 */
export interface AProps
  extends Omit<React.HTMLAttributes<Element>, 'className'> {
  /** The children in the anchor tag */
  children?: React.ReactNode;
  /** Indicates if the link should be opened in a new tab (external links are automatically opened in a new tab) */
  external?: boolean;
  /** The location to link to */
  href?: string | Location;
  /** The icon to display */
  icon?: string | ReactNode;
  /** The intl message to display as the link contents */
  message?: MessageDescriptor;
  /** Values to pass to message */
  values?: MessageValues;
  /** On click instead of href, fill render as a span instead of a link */
  onClick?: () => void;
  /** Specified target open location. */
  target?: '_blank' | '_self' | '_parent' | '_top';

  /** Optional pass-through 'exact' prop from react-router's NavLink */
  exact?: boolean;
  /** Optional pass-through 'className' prop from react-router's NavLink */
  className?: string | ((isActive: boolean) => string);
  /** Optional pass-through 'activeClassName' prop from react-router's NavLink */
  activeClassName?: string;
  /** Whether the link should be disabled -- to appease tsc */
  disabled?: boolean;
}

/**
 * A link to a certain page, an anchor tag
 *
 * @example <caption>Example usage of A</caption>
 *
 * function simpleExample(){
 * return (<A href="/hey-donag">HEY</A>);
 * }
 */
export function A({
  children,
  href = '',
  icon,
  external,
  message,
  onClick,
  values,
  target,
  style,
  ...extraProps
}: AProps): JSX.Element {
  const { formatMessage } = useIntl();
  const currentLocation = useLocation();

  // Determine if the link is not to a local URL
  const { origin } = window.location;
  const isExternal =
    typeof href === 'string'
      ? new URL(href, origin).origin !== origin || external
      : false;
  const isMail = typeof href === 'string' && href.startsWith('mailto:');

  // Ensure noopener is set on outbound links
  const extra =
    !target && isExternal && !isMail
      ? { rel: 'noopener noreferrer', target: '_blank' }
      : {};

  // Determine base component
  const ATag: any = onClick || isMail || isExternal ? PlainA : NavLink;

  // For relative links, determine the destination to go to after merging search params
  let destination = href;
  if (!isExternal && typeof destination === 'string') {
    // first ensure the href is a relative path
    destination = destination.replace(origin, '');

    // merge query parameters
    const { pathname = '', search, hash } = parsePath(destination);
    const { locale } = getUnderlyingPath(currentLocation.pathname);
    destination = createPath({
      pathname: locale ? getLocalizedPath(pathname, locale) : pathname,
      search,
      hash,
    });
  }

  return (
    <ATag
      onClick={
        !onClick
          ? undefined
          : // prevent href from redirecting
            (e: any) => {
              e.preventDefault();
              onClick();
            }
      }
      {...extraProps}
      {...extra}
      to={destination}
      id={message?.id?.toString() || href}
      href={href}
      style={{
        cursor: 'pointer',
        ...style,
      }}
    >
      {icon && (
        <span>
          {typeof icon === 'string' ? (
            icon.startsWith('https://') ? (
              <Avatar size={24} src={icon} />
            ) : (
              <AntIcon type={icon} />
            )
          ) : (
            icon
          )}
          &nbsp;
        </span>
      )}
      {message && formatMessage(message, values)}
      {children}
    </ATag>
  );
}
