import * as t from 'io-ts';

import { dbModelId } from '@main/schema-utils';

import { AuditEventCode } from '../schema/enums';
import { mkAuditEventCodec } from '../types';

export const AuditEventRiskLevel = mkAuditEventCodec(
  AuditEventCode.RiskLevel,
  t.intersection([
    t.type({
      /** ID of the risk level */
      riskLevelId: dbModelId('riskLevel'),
    }),
    t.partial({
      /** ID of the risk framework to which the risk level belongs */
      riskFrameworkId: dbModelId('riskFramework'),
    }),
  ]),
);

/** Override types. */
export type AuditEventRiskLevel = t.TypeOf<typeof AuditEventRiskLevel>;
