import 'antd/lib/upload/style/index';

import Upload, {
  UploadChangeParam as AntUploadChangeParam,
  UploadProps,
} from 'antd/lib/upload';

/**
 * The upload props
 */
export type AntUploadProps = UploadProps;

/**
 * Params when upload changes
 */
export type UploadChangeParam = AntUploadChangeParam;

/**
 * The ant upload
 *
 * @deprecated TODO: https://transcend.height.app/T-19886 - remove ant
 */
export const AntUpload = Upload;
