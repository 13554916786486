import type {
  ApolloQueryResult,
  FetchMoreOptions,
  FetchMoreQueryOptions,
} from '@apollo/client';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';

import { Requirize } from '@transcend-io/type-utils';

import { usePagination } from './usePagination';

interface FetchMorePaginationProps<TFetchMore> {
  /** The page size */
  defaultPageSize: number;
  /** The Apollo fetchMore query */
  fetchMore: TFetchMore;
  /** whether or not to skip the first query */
  skip?: boolean;
  /** The name of the query parameter to use to determine current page */
  queryParam?: string;
}

/**
 * Hook to handle pagination with a GraphQL query.
 *
 * This is done by storing the current page to useState,
 * and using the fetchMore property returned in a buildUseQuery hook
 * to update the variables to have an offset equivalent to the current page.
 *
 * @param arg - the args
 * @returns the current page, a function to handle page changes, and a function to set the page
 */
export function useFetchMorePagination<
  TFetchMore extends (
    options: Requirize<
      FetchMoreOptions<
        any,
        {
          /** The page offset should be defined as a param to this graphQL endpoint */
          offset?: number;
        }
      >,
      'updateQuery'
    > &
      FetchMoreQueryOptions<
        {
          /** The page offset should be defined as a param to this graphQL endpoint */
          offset?: number;
        },
        'offset'
      >,
  ) => Promise<ApolloQueryResult<any>>,
>({
  defaultPageSize,
  fetchMore,
  skip,
  queryParam,
}: FetchMorePaginationProps<TFetchMore>): [
  number,
  Dispatch<SetStateAction<number>>,
  (max?: number) => void,
] {
  // The current page
  const { currentPage, setCurrentPage, useMaxPage } = usePagination({
    queryParam,
  });
  // don't call fetchMore on first call (already done by useQuery)
  const [firstCall, setFirstCall] = useState(true);

  useEffect(() => {
    if (!skip && !firstCall) {
      // fetch more items and save to apollo cache
      fetchMore({
        variables: {
          offset: (currentPage - 1) * defaultPageSize,
        },
        updateQuery: (prev: any, { fetchMoreResult }: any) => {
          if (!fetchMoreResult) {
            return prev;
          }
          setCurrentPage(currentPage);
          return fetchMoreResult;
        },
      });
    }
    if (firstCall) {
      setFirstCall(false);
    }
  }, [currentPage]);

  return [currentPage, setCurrentPage, useMaxPage];
}
