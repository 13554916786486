/**
 * Create a `textarea` and copy the given value to it.
 *
 * @param value - Value to copy to clipboard.
 */
export function copyValueToClipboard(value: string): void {
  const el = document.createElement('textarea');
  el.value = value;
  document.body.appendChild(el);
  el.select();
  document.execCommand('copy');
  document.body.removeChild(el);
}
