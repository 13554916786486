import { createLoadable } from '@main/core-ui';

// import { failureMessages, oAuthMessages, successMessages } from './messages';

/**
 * Landing page
 */
export const OAuthLanding = createLoadable({
  loader: () => import('./Landing'),
  // messages: failureMessages,
});
