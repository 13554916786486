import { defineMessages } from '@transcend-io/internationalization';

export const confirmDeleteModalMessages = defineMessages(
  'ad-core-components.ConfirmDeleteModal.confirmDeleteModal',
  {
    confirm: {
      defaultMessage: 'Confirm Deletion',
    },
    cancelButton: {
      defaultMessage: 'Never Mind',
    },
  },
);
