import { makeEnum } from '@transcend-io/type-utils';

/**
 * The names of regular old database models
 */
export const StandardModelName = makeEnum({
  /** Configuration for allowed data subject request types, i.e. access, erasure */
  Action: 'action',
  /** An action item */
  ActionItem: 'actionItem',
  /** An action item collection */
  ActionItemCollection: 'actionItemCollection',
  /** An action item subscription */
  ActionItemSubscription: 'actionItemSubscription',
  /** An action item run lock */
  ActionItemRunLock: 'actionItemRunLock',
  /** An application - typically tracked for the purposes of AI governance */
  Application: 'application',
  /** An end user that interacts with an application */
  AppUser: 'appUser',
  /** An AI agent built on top of a large language model & prompt */
  Agent: 'agent',
  /** A file that an AI agent has in its retrieval context */
  AgentFile: 'agentFile',
  /** A function that an AI agent is capable of calling */
  AgentFunction: 'agentFunction',
  /** An attribute key defined by an organization to group attribute values (labels for resources eg. datapoints, dataSilos etc.) */
  AttributeKey: 'attributeKey',
  /** An activity that occurred during the request lifecycle */
  Activity: 'activity',
  /** A compiled airgap build for a specific org or site */
  AirgapBundle: 'airgapBundle',
  /** A compiled airgap build for a specific org or site */
  AirgapBundleModule: 'airgapBundleModule',
  /** A set of configuration for a specific airgap bundle */
  AirgapBundleConfiguration: 'airgapBundleConfiguration',
  /** Partitions for consent across bundles for an organization */
  airgapPartition: 'airgapPartition',
  /** A set of catalogs associated with an airgap bundle for consent data processing */
  consentService: 'consentService',
  /** A set of native applications (mobile, TV, etc) governed by our consent module */
  consentApplication: 'consentApplication',
  /** A set of SDKs installed on native applications governed by our consent module */
  consentSdk: 'consentSdk',
  /** An SDK declaration of metadata, synced from contentful */
  sdk: 'sdk',
  /** An instance of an SDK, discovered to be in use by an organization */
  softwareDevelopmentKit: 'softwareDevelopmentKit',
  /** A version of an airgap bundle */
  AirgapBundleVersion: 'airgapBundleVersion',
  /** A set of cookies associated with a specific airgap bundle */
  AirgapCookie: 'airgapCookie',
  /** A set of cookie-host pairs associated with a specific airgap bundle */
  AirgapCookieDomain: 'airgapCookieDomain',
  /** A set of domains associated with a specific airgap bundle */
  AirgapCustomDomain: 'airgapCustomDomain',
  /** A set of data flows associated with a specific airgap bundle */
  AirgapDataFlow: 'airgapDataFlow',
  /** A set of theme settings for a specific airgap bundle */
  AirgapTheme: 'airgapTheme',
  /** A custom sync endpoint */
  AirgapSyncEndpoint: 'airgapSyncEndpoint',
  /** An api key used for API access */
  ApiKey: 'apiKey',
  /** An asset on the privacy center */
  AssetFile: 'assetFile',
  /** Attribute values defined by an organization to label resources (eg. datapoints, data silos) */
  AttributeValue: 'attributeValue',
  /** A finding from an auditor run */
  AuditorFinding: 'auditorFinding',
  /** Historic run record of the Web Auditor */
  AuditorRun: 'auditorRun',
  /** Schedules for the Web Auditor */
  AuditorSchedule: 'auditorSchedule',
  /** A data flow from an auditor run */
  AuditorRunDataFlow: 'auditorRunDataFlow',
  /** A cookie from an auditor run */
  AuditorRunCookie: 'auditorRunCookie',
  /** A cached backend response */
  BackendCache: 'backendCache',
  /** An config event to record as part of an audit trail */
  ConfigAuditEvent: 'configAuditEvent',
  /** An entry in the catalog of integrations */
  Catalog: 'catalog',
  /** An SSL certificate issued by AWS */
  Certificate: 'certificate',
  /** A package of code within a repository */
  CodePackage: 'codePackage',
  /** A communication message sent between the organization and data subject */
  Communication: 'communication',
  /** A legal contract uploaded to Transcend */
  Contract: 'contract',
  /** An instance of scanning contracts */
  ContractScan: 'contractScan',
  /** The cloudfront configuration hosting a privacy center */
  Cloudfront: 'cloudfront',
  /** A run of a daemon, useful for optimizing Contentful syncs */
  DaemonRun: 'daemonRun',
  /** Queries to be used by the database integration */
  DatabaseIntegrationQuery: 'databaseIntegrationQuery',
  /** The hierarchal level for a datapoint */
  DataPointLevel: 'dataPointLevel',
  /** Subcategories for an organization's data inventory */
  DataSubCategory: 'dataSubCategory',
  /** Configuration for an exportable data inventory report */
  DataReport: 'dataReport',
  /** A demo request */
  Demo: 'demo',
  /** An answer of an assessment form */
  AssessmentAnswer: 'assessmentAnswer',
  /** An question of an assessment form */
  AssessmentQuestion: 'assessmentQuestion',
  /** An group of questions of an assessment form */
  AssessmentSection: 'assessmentSection',
  /** A set of emails templates to use when emailing assessment users */
  AssessmentEmailSet: 'assessmentEmailSet',
  /** An editable template for an assessmentForm with questions and possible answers */
  AssessmentFormTemplate: 'assessmentFormTemplate',
  /** A comment to an assessment form */
  AssessmentFormComment: 'assessmentFormComment',
  /** The external assignees of an assessment form */
  AssessmentFormExternalAssignee: 'assessmentFormExternalAssignee',
  /** The external assignees of an assessment section */
  AssessmentSectionExternalAssignee: 'assessmentSectionExternalAssignee',
  /** A comment to an assessment form question */
  AssessmentQuestionComment: 'assessmentQuestionComment',
  /** A comment to an assessment form section */
  AssessmentSectionComment: 'assessmentSectionComment',
  /** A group of assessment forms */
  AssessmentGroup: 'assessmentGroup',
  /** An assessment form derived from a template */
  AssessmentForm: 'assessmentForm',
  /** Preferences for users and organizations */
  Preference: 'preference',
  /** An event associated with a change to an assessmentForm */
  AssessmentEvent: 'assessmentEvent',
  /** A file for an assessment or assessment template */
  AssessmentFile: 'assessmentFile',
  /** Customer Domains from which Transcend can send emails */
  EmailDomain: 'emailDomain',
  /** Data subjects can login to the privacy center via email magic link, issued by transcend */
  EmailLogin: 'emailLogin',
  /** Customer Addresses from which Transcend can send emails */
  EmailSenderAddress: 'emailSenderAddress',
  /** Mapping from customer email addresses to attribute values for dynamic email addresses */
  EmailSenderAddressAttributeValue: 'EmailSenderAddressAttributeValue',
  /** Mapping from customer email addresses to privacy centers for dynamic email addresses */
  EmailSenderAddressPrivacyCenter: 'EmailSenderAddressPrivacyCenter',
  /** Configuration for something that converts identifiers from one type to another */
  Enricher: 'enricher',
  /** Enricher dependency that an enricher is depended on to finish running first */
  EnricherDependentEnricher: 'EnricherDependentEnricher',
  /** Failed login attempts to the admin dashboard via username/password auth */
  FailedLoginAttempt: 'FailedLoginAttempt',
  /** The features enabled for an organization */
  FeatureSet: 'featureSet',
  /** A custom form item in a DSR action form */
  FormItem: 'formItem',
  /** The configuration a specific identifier that the organization uses to key personal data by */
  Identifier: 'identifier',
  /** Hold a list of individuals that are placed on some sort of legal hold. */
  LegalHold: 'legalHold',
  /** A legal matter is a reason why an individual may be placed on a legal hold */
  LegalMatter: 'legalMatter',
  /** The legal holds are because of the following legal matters */
  LegalMatterLegalHold: 'LegalMatterLegalHold',
  /** A lookup process for an integration */
  LookupProcess: 'lookupProcess',
  /** An internationalized message string */
  Message: 'message',
  /** An in-flight oauth transaction */
  OauthSession: 'oauthSession',
  /** The core model for a Transcend account */
  Organization: 'organization',
  /** User passwords to the admin dashboard */
  Password: 'password',
  /** Plugins that can run against data silos. */
  Plugin: 'plugin',
  /** A policy definition displayed on the privacy center */
  Policy: 'policy',
  /** The versions of an individual policy */
  PolicyVersion: 'policyVersion',
  /** A privacy center configuration */
  PrivacyCenter: 'privacyCenter',
  /** A privacy center configuration */
  PrivacyCenterVersion: 'privacyCenterVersion',
  /** An instance of an identity found in a data silo, for a specific request by a data subject */
  Profile: 'profile',
  /** An AI prompt */
  Prompt: 'prompt',
  /** A model that represents threads - a concept of grouping together multiple AI invocations into a related conversations. */
  PromptThread: 'promptThread',
  /** An instance of executing an AI prompt against some text */
  PromptRun: 'promptRun',
  /** A message sent in a conversations against a particular prompt run */
  PromptRunMessage: 'promptRunMessage',
  /** A pull request - a change to code within a repository */
  PullRequest: 'pullRequest',
  /** The registered LLMs for each organization */
  largeLanguageModel: 'largeLanguageModel',
  /** Deployed instances of pathfinder */
  Pathfinder: 'pathfinder',
  /** Policies that should be applied to a particular pathfinder instance */
  PathfinderPolicy: 'pathfinderPolicy',
  /** A processing activity */
  ProcessingActivity: 'processingActivity',
  /** A partial template that can be used to compose more complex prompts */
  PromptPartial: 'promptPartial',
  /** A group of prompts that work well together */
  PromptGroup: 'promptGroup',
  /** A tracking purpose (in context of airgap purpose map) */
  Purpose: 'purpose',
  /** Purpose of Processing Subcategories for an organization's data inventory */
  ProcessingPurposeSubCategory: 'processingPurposeSubCategory',
  /** A code repository, often in github */
  Repository: 'repository',
  /** A data subject request */
  Request: 'request',
  /** A file stored with a request */
  RequestFile: 'requestFile',
  /** The category that groups integration catalog entries together */
  SaaSCategory: 'saaSCategory',
  /** The vendor static metdata stored in contentful */
  SaaSVendor: 'saaSVendor',
  /** The configuration for the dashboard savings chart */
  SavingsConfiguration: 'savingsConfiguration',
  /** The colors detected and returned by the sitescanner */
  ScanColor: 'scanColor',
  /** Access control scopes specific to an organization */
  Scope: 'scope',
  /** The seed enricher model, for holding seed enrichment history */
  SeedEnricher: 'seedEnricher',
  /** Login sessions on the admin dashboard */
  Session: 'Session',
  /** A signup code when registering a new organization */
  SignupCode: 'signupCode',
  /** Cookies from a sitescan */
  ScanCookiePurpose: 'scanCookiePurpose',
  /** Cookie-host pairs from a sitescan */
  ScanCookieHostPurpose: 'scanCookieHostPurpose',
  /** data flows from a sitescan */
  ScanDomainPurpose: 'scanDomainPurpose',
  /** A sombra instance configurations used to encrypt data */
  Sombra: 'sombra',
  /** API keys belonging to specific Sombras. */
  SombraApiKey: 'sombraApiKey',
  /** An audit log to keep track of Sombra config changes. */
  SombraAuditLog: 'sombraAuditLog',
  /** A table to keep track of keys used on Sombra. */
  SombraKey: 'sombraKey',
  /** A data subject configuration supported by the organization */
  Subject: 'subject',
  /** A table of instances of configured SSO providers, specific to individual orgs. */
  SsoProvider: 'ssoProvider',
  /** A table of domains associated with different ssoProviders */
  SsoProviderDomain: 'ssoProviderDomain',
  /** Transparency & Consent Framework (TCF) List Version */
  TCFVendorListVersion: 'tcfVendorListVersion',
  /** A dimension table of TCF Features */
  TCFFeature: 'tcfFeature',
  /** A dimension table of TCF Purposes */
  TCFPurpose: 'tcfPurpose',
  /** A dimension table of TCF Special Features */
  TCFSpecialFeature: 'tcfSpecialFeature',
  /** A dimension table of TCF Special Purposes */
  TCFSpecialPurpose: 'tcfSpecialPurpose',
  /** TCF Vendors */
  TCFVendor: 'tcfVendor',
  /** A team of Transcend users sharing the same scopes */
  Team: 'team',
  /** An email template */
  Template: 'template',
  /** Randomly generated tokens used for temporary password */
  Token: 'token',
  /** Privacy center stylings */
  Theme: 'theme',
  /** A transcend user to the admin dashboard */
  User: 'user',
  /** A third party vendor that an organization may have a business relationship with */
  Vendor: 'vendor',
  /** An affiliate or business unit grouped within a larger organization */
  BusinessEntity: 'businessEntity',
  /** Sitescan Status */
  Sitescan: 'sitescan',
  /** Transcend wide configurations for organization specified regions */
  Experience: 'experience',
  /** Consent Manager UI for Experience */
  ConsentManagerUi: 'consentManagerUi',
  /** Shopify */
  ShopifyAccessToken: 'shopifyAccessToken',
  /** External OAuth tokens */
  ExternalOAuthToken: 'externalOAuthToken',
  /** TCF Vendor Policy URLs: URLs to privacy policies for vendors in different languages */
  TCFVendorUrl: 'tcfVendorUrl',
  /** TCF Data Category: type of data vendors may collect and utilize */
  TCFDataCategory: 'tcfDataCategory',
  /** TCF Stack: combinations of purposes and special features used to summarize vendor data usage */
  TCFStack: 'tcfStack',
  /** The organization-level TCF purposes */
  OrgTcfPurpose: 'orgTcfPurpose',
  /** The organization-level TCF special features */
  OrgTcfSpecialFeature: 'orgTcfSpecialFeature',
  /** The hierarchal level for a scanned object */
  ScannedObjectPath: 'scannedObjectPath',
  /** Settings for requests processed CCPA Disclosure on the PC */
  RequestsProcessedStatsSetting: 'requestsProcessedStatsSetting',
  /** Configurable retention policy to manage an organizations data */
  RetentionSchedule: 'retentionSchedule',
  /** Configuration for a consent workflow trigger */
  consentWorkflowTrigger: 'consentWorkflowTrigger',
  /** Configuration for purposes that trigger a consent workflow */
  consentWorkflowTriggerPurpose: 'consentWorkflowTriggerPurpose',
  /** Workflow Config */
  workflowConfig: 'workflowConfig',
  /** Workflow instance */
  workflowInstance: 'workflowInstance',
  /** Workflow Job */
  workflowJob: 'workflowJob',
  /** Model for how risk is defined */
  riskFramework: 'riskFramework',
  /** Discrete levels of risk within a risk framework */
  riskLevel: 'riskLevel',
  /** Discrete categories of risk within a risk framework */
  riskCategory: 'riskCategory',
  /** Part of the risk matrix of a framework, used, in combination with a column, to calculate risk level */
  riskMatrixRow: 'riskMatrixRow',
  /** Part of the risk matrix of a framework, used, in combination with a row, to calculate risk level */
  riskMatrixColumn: 'riskMatrixColumn',
  /** A risk matrix column and row combination that defines a risk level */
  RiskLevelRiskMatrixRowRiskMatrixColumn:
    'RiskLevelRiskMatrixRowRiskMatrixColumn',
  /** Risk categories associated with an assessment question, from which risk levels can be assigned for their answers */
  AssessmentQuestionRiskCategory: 'AssessmentQuestionRiskCategory',
  /** Configuration for Preference topics that can be associated to a purpose as metadata */
  preferenceTopic: 'preferenceTopic',
  /** Preference options that can be associated to a purpose preference */
  preferenceOptionValue: 'preferenceOptionValue',
});

/**
 * Overload with type for all database model names
 */
export type StandardModelName =
  (typeof StandardModelName)[keyof typeof StandardModelName];
