import React from 'react';
import { OptionTypeBase } from 'react-select';
import CreatableSelect, {
  Props as CreatableSelectProps,
} from 'react-select/creatable';
import styled from 'styled-components';

export interface IReactSelectCreatableOption<T = any> extends OptionTypeBase {
  /** The label */
  label: string;
  /** The value */
  value: T;
}

/**
 * Props for ReactCreatableSelect component
 */
export type ReactSelectCreatableProps = CreatableSelectProps<
  IReactSelectCreatableOption,
  false
>;

/**
 * Wrapper to put `classNamePrefix` on `ReactSelectCreatable` so it can be
 * styled with styled-components.
 *
 * @deprecated
 */
const ReactSelectCreatableBase: React.FC<
  Omit<ReactSelectCreatableProps, 'classNamePrefix'>
> = (props) => (
  <CreatableSelect {...props} classNamePrefix="react-select-creatable" />
);

/**
 * Our styled version of `CreatableSelect`
 */
export const ReactSelectCreatable = styled(ReactSelectCreatableBase)`
  .react-select-creatable__control {
    border-radius: 0.6em;
    transition: border 0.2s;
    cursor: text;

    &:hover {
      border: 1px solid #5c61ff;
    }
  }

  .react-select-creatable__control--is-focused {
    border: 1px solid ${({ theme }) => theme.colors.transcend};
    box-shadow: 0 0 0 2px rgb(51 51 255 / 20%);
  }

  .react-select-creatable__placeholder {
    font-size: 14px;
    padding: 0;
    color: ${({ theme }) => theme.colors.transcendNavy3};
  }

  .react-select-creatable__value-container {
    padding: 0 14px;
  }

  .react-select-creatable__multi-value__label {
    font-weight: 600;
    color: ${({ theme }) => theme.colors.transcendNavy};
  }

  .react-select-creatable__multi-value__remove {
    cursor: pointer;
    color: ${({ theme }) => theme.colors.transcendNavy3};

    &:hover,
    &:active,
    &:focus {
      background: transparent;
      color: ${({ theme }) => theme.colors.transcendNavy};
    }
  }
`;
