import { createLoadable } from '@main/core-ui';

import { testEmailSettingsMessages } from './messages';

// Load the component dynamically
export const EmailSettingsTestingLoadable = createLoadable({
  loader: () => import('./TestEmailSettings'),
  messages: testEmailSettingsMessages,
  logo: 'form',
});
