import { ConditionalContainer } from '@main/ad-core-components';
import { A, Icon } from '@main/core-ui';
import { AdminDashboardAssessmentsPath } from '@main/datamap-types';
import { ID } from '@main/schema-utils';
import { AssessmentFormStatus } from '@transcend-io/privacy-types';
import React from 'react';
import { generatePath } from 'react-router-dom';
import { useTheme } from 'styled-components';

import { ASSESSMENT_FORM_STATUS_ICON_TYPES } from '../../constants';
import { AssessmentBadgeProps } from './types';
import { StatusIconWrapper, StyledBadge } from './wrappers';

const SharedAssessmentBadge: React.FC<
  AssessmentBadgeProps & {
    /** ID for this assessment. Required for a linked badge */
    id: ID<'assessmentForm'> | ID<'assessmentFormTemplate'>;
    /** Which type of badge to render */
    type: 'assessmentForm' | 'assessmentFormTemplate';
  }
> = ({ children, id, isLinked = true, assessmentFormStatus, type }) => {
  const theme = useTheme();

  return (
    <ConditionalContainer
      condition={isLinked && !!id}
      container={(children) => (
        <A
          href={
            type === 'assessmentForm'
              ? generatePath(AdminDashboardAssessmentsPath.AssessmentForm, {
                  assessmentFormId: id,
                })
              : generatePath(
                  AdminDashboardAssessmentsPath.AssessmentFormTemplate,
                  {
                    assessmentFormTemplateId: id,
                  },
                )
          }
        >
          {children}
        </A>
      )}
    >
      <StyledBadge
        color="gray1"
        isLinked={isLinked}
        assessmentFormStatus={assessmentFormStatus}
      >
        {assessmentFormStatus && (
          <StatusIconWrapper assessmentFormStatus={assessmentFormStatus}>
            <Icon
              type={ASSESSMENT_FORM_STATUS_ICON_TYPES[assessmentFormStatus]}
              color="white"
              size={16}
            />
          </StatusIconWrapper>
        )}
        <Icon
          type={type === 'assessmentForm' ? 'file' : 'assessment-template'}
          color={theme.colors.transcend}
        />
        {children}
      </StyledBadge>
    </ConditionalContainer>
  );
};

export const AssessmentFormBadge: React.FC<
  Omit<AssessmentBadgeProps, 'assessmentFormStatus' | 'assessmentStatus'> & {
    /** ID for this assessment form. Required for a linked badge */
    id: ID<'assessmentForm'>;
    /** Status of assessment form */
    status?: AssessmentFormStatus;
  }
> = ({ status, ...props }) => (
  <SharedAssessmentBadge
    {...props}
    assessmentFormStatus={status}
    type="assessmentForm"
  />
);

export const AssessmentFormTemplateBadge: React.FC<
  AssessmentBadgeProps & {
    /** ID for this assessment. Required for a linked badge */
    id: ID<'assessmentFormTemplate'>;
  }
> = (props) => (
  <SharedAssessmentBadge {...props} type="assessmentFormTemplate" />
);
