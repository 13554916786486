import {
  buildUseQuery,
  formatErrorMessage,
  ReactSelect,
  ReactSelectExtendedProps,
} from '@main/core-ui';
import { DataSubject, endpoints } from '@main/datamap-types';
import React from 'react';
import { useIntl } from 'react-intl';

import { DATA_MAP_DATA_SUBJECT_FIELDS } from './constants';

const useSubjects = buildUseQuery(
  endpoints.internalSubjects,
  'DataMapSelectInternalSubjects',
  {
    ...DATA_MAP_DATA_SUBJECT_FIELDS,
  },
);

export const SelectSubjects: React.FC<
  ReactSelectExtendedProps<true, DataSubject>
> = ({ isLoading, ...props }) => {
  const { formatMessage } = useIntl();
  const { data, loading, error } = useSubjects();

  return (
    <ReactSelect<true, DataSubject>
      options={data}
      isMulti
      isLoading={isLoading || loading}
      noOptionsMessage={
        error ? () => formatErrorMessage(error.message) : undefined
      }
      getOptionLabel={(option) => formatMessage(option.title)}
      getOptionValue={(option) => option.id}
      showOutline={false}
      closeMenuOnSelect={false}
      {...props}
    />
  );
};
