import { defineMessages } from '@transcend-io/internationalization';

export const editDetailsMessages = defineMessages(
  'ad-core-components.EditDetails.editDetails',
  {
    addNotes: {
      defaultMessage: 'Add Notes',
    },
    editNotes: {
      defaultMessage: 'Edit Notes',
    },
  },
);
