import * as t from 'io-ts';

import { dbModelId } from '@main/schema-utils';

import { AuditEventCode } from '../schema/enums';
import { mkAuditEventCodec } from '../types';

export const AuditEventAssessmentQuestion = mkAuditEventCodec(
  AuditEventCode.AssessmentQuestion,
  t.intersection([
    t.type({
      /** ID of the assessment question */
      assessmentQuestionId: dbModelId('assessmentQuestion'),
    }),
    t.partial({
      /** ID of the assessment section that owns the question */
      assessmentSectionId: dbModelId('assessmentSection'),
      /** ID of the assessment template that owns the question */
      assessmentFormTemplateId: dbModelId('assessmentFormTemplate'),
      /** ID of the assessment form that owns the question */
      assessmentFormId: dbModelId('assessmentForm'),
    }),
  ]),
);

/** Override types. */
export type AuditEventAssessmentQuestion = t.TypeOf<
  typeof AuditEventAssessmentQuestion
>;
