import styled, { css } from 'styled-components';

import { OrganizationIcon, VendorIcon } from '@main/ad-core-components';
import { FlexColumnCenterBoth, StyleUtils } from '@main/core-ui';

const iconStyles = css`
  border: ${({ theme }) => `1px solid ${theme.colors.transcendNavy4}`};
  background: ${({ theme }) => theme.colors.white};
  margin-left: -${StyleUtils.Spacing.md};
  border-radius: 6px;
  padding: 3px;
`;

export const StyledTitle = styled.div`
  word-break: break-all;
  font-size: 12px;
  color: rgb(28, 41, 56);
  ${({ theme }) => theme.colors.transcendNavy};
`;

export const StyledOrganizationIcon = styled(OrganizationIcon)`
  ${iconStyles}
`;

export const StyledVendorIcon = styled(VendorIcon)`
  ${iconStyles}
`;

export const StyledOverflowIndicator = styled(FlexColumnCenterBoth)`
  height: 24px;
  min-width: 24px;
  font-weight: 600;
  ${iconStyles}
`;
