import {
  FilterManager,
  FiltersConfig,
  InlineTextInput,
  SelectedUser,
} from '@main/ad-core-components';
import {
  useRepositoryFilters,
  useUserFilters,
} from '@main/admin-dashboard/src/hooks';
import { PullRequestFiltersInput } from '@main/code-scanning-types';
import pickBy from 'lodash/pickBy';
import React, { useMemo, useRef } from 'react';
import { useIntl } from 'react-intl';

import { pullRequestsColumnHeaderMessages } from '../../PullRequests/messages';
import { SelectedRepository } from '../SelectRepositories';
import { pullRequestFilterMessages } from './messages';

/**
 * Filters we support for pull requests
 */
// eslint-disable-next-line @typescript-eslint/consistent-type-definitions
type SupportedFilters = {
  /** Text to search the pull requests by */
  text?: string;
  /** Text to search on title only */
  title?: string;
  /** Assigned user */
  users?: SelectedUser[];
  /** Assigned repositories */
  repositories?: SelectedRepository[];
};

interface PullRequestFilterProps {
  /** The currently applied filters */
  filters?: PullRequestFiltersInput;
  /** Callback when the filters are changed */
  setFilters: (filters: PullRequestFiltersInput) => void;
}

export const PullRequestFilter: React.FC<PullRequestFilterProps> = ({
  filters,
  setFilters,
}) => {
  const { formatMessage } = useIntl();

  const { selectedUsers, userFiltersConfig, clearUserFilters } = useUserFilters(
    {
      userIdsFilterKey: 'userIds',
      filters,
      setFilters,
      label: pullRequestsColumnHeaderMessages.users,
      enrichedUserKey: 'users',
    },
  );

  const {
    selectedRepositories,
    repositoryFiltersConfig,
    clearRepositoryFilters,
  } = useRepositoryFilters({
    filters,
    setFilters,
    label: pullRequestsColumnHeaderMessages.repository,
  });

  const nameInputRef = useRef<HTMLInputElement>(null);

  const filterConfig: FiltersConfig<SupportedFilters> = [
    repositoryFiltersConfig as any,
    userFiltersConfig as any,
    {
      filterKey: 'title',
      label: pullRequestsColumnHeaderMessages.title,
      pillOptions: {
        label: ({ filterValues: { title } }) => title,
      },
      filter: (
        <InlineTextInput
          ref={nameInputRef}
          name="search"
          style={{
            background: 'none',
            border: 0,
            boxShadow: 'none',
            minWidth: 20,
            width: 'auto',
          }}
          placeholder={formatMessage(pullRequestsColumnHeaderMessages.title)}
          aria-label={formatMessage(pullRequestsColumnHeaderMessages.title)}
          onChange={({ target: { value: newValue } }) => {
            setFilters({
              ...filters,
              title: newValue,
            });
          }}
        />
      ),
    },
  ];

  // Map the basic filters from the query to the enriched values for the filter manager
  const enrichedFilters = useMemo<SupportedFilters>(
    () =>
      pickBy(
        {
          ...filters,
          repositories: selectedRepositories,
          users: selectedUsers,
        },
        (v) => (Array.isArray(v) ? v.length > 0 : v !== undefined),
      ),
    [filters, selectedRepositories, selectedUsers],
  );

  return (
    <FilterManager
      id="pull-request-filter"
      defaultSearchKey="text"
      filtersValues={enrichedFilters}
      filtersConfig={filterConfig}
      placeholder={pullRequestFilterMessages.textLabel}
      onChange={(key, value) =>
        setFilters({
          ...filters,
          [key]: value,
        })
      }
      clearFilter={(key) => {
        clearUserFilters(key);
        clearRepositoryFilters(key);
        setFilters({
          ...filters,
          [key]: undefined,
        });
      }}
    />
  );
};
