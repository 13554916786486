import { transparentize } from 'polished';
import styled, { css } from 'styled-components';

import { FlexColumn, FlexRowCenterVertical } from '@main/core-ui';

import { INPUT_ICON_SIZE } from './constants';

const INPUT_ICON_X_SPACING = '0.6em';
const borderRadius = '0.6em';

const inputCss = css<{
  /** does the input have an icon */
  hasIcon?: boolean;
  /** does the input have an addon on the right */
  hasAddonRight?: boolean;
  /** does the input have an addon on the left */
  hasAddonLeft?: boolean;
}>`
  background-color: transparent;
  flex-grow: 1;
  border: 1px solid ${({ theme }) => theme.colors.transcendNavy4};
  border-top-left-radius: ${({ hasAddonLeft }) =>
    hasAddonLeft ? 0 : borderRadius};
  border-bottom-left-radius: ${({ hasAddonLeft }) =>
    hasAddonLeft ? 0 : borderRadius};
  border-top-right-radius: ${({ hasAddonRight }) =>
    hasAddonRight ? 0 : borderRadius};
  border-bottom-right-radius: ${({ hasAddonRight }) =>
    hasAddonRight ? 0 : borderRadius};
  padding: 0.3em 0.75em 0.3em
    ${({ hasIcon }) =>
      hasIcon
        ? `calc(${INPUT_ICON_SIZE} + ${INPUT_ICON_X_SPACING} * 2)`
        : '.75em'};
  color: ${({ theme }) => theme.colors.transcendNavy};
  transition: border 0.2s;

  &::placeholder {
    color: ${({ theme }) => theme.colors.transcendNavy4};
  }

  &:disabled::placeholder {
    color: ${({ theme }) => theme.colors.transcendNavy3};
  }

  &:disabled {
    color: ${({ theme }) => theme.colors.transcendNavy3};
    background-color: ${({ theme }) => theme.colors.gray2};
  }

  &:focus {
    outline-color: ${({ theme }) =>
      transparentize(0.5, theme.colors.transcend)};
    border-color: ${({ theme }) => theme.colors.transcend};
  }
`;

export const StyledInput = styled.input<{
  /** does the input have an icon */
  hasIcon?: boolean;
}>`
  height: 36px;
  ${inputCss}
`;

export const StyledTextArea = styled.textarea<{
  /** does the input have an icon */
  hasIcon?: boolean;
}>`
  ${inputCss}
`;

export const InputWrapper = styled(FlexColumn)`
  position: relative;
  flex-grow: 1;
`;

export const InputAddonWrapper = styled(FlexRowCenterVertical)<{
  /** does the input have an addon on the right */
  isAddonRight?: boolean;
}>`
  border: 1px solid ${({ theme }) => theme.colors.transcendNavy4};
  background-color: ${({ theme }) => theme.colors.gray4};
  /** don't double border between input and addon */
  border-${({ isAddonRight }) => (isAddonRight ? 'left' : 'right')}-width: 0;
  padding: 0.2em 0.75em;

  border-top-left-radius: ${({ isAddonRight }) =>
    !isAddonRight ? borderRadius : 0};
  border-bottom-left-radius: ${({ isAddonRight }) =>
    !isAddonRight ? borderRadius : 0};
  border-top-right-radius: ${({ isAddonRight }) =>
    isAddonRight ? borderRadius : 0};
  border-bottom-right-radius: ${({ isAddonRight }) =>
    isAddonRight ? borderRadius : 0};
`;

export const InputIconWrapper = styled(FlexRowCenterVertical)`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: ${INPUT_ICON_SIZE};
  margin: 0.3em ${INPUT_ICON_X_SPACING};
  pointer-events: none;
`;
