import { defineMessages } from '@transcend-io/internationalization';

export const promptTabMessages = defineMessages(
  'admin-dashboard.Prompts.PromptTabs.promptTab',
  {
    prompts: { defaultMessage: 'Prompts' },
    groups: { defaultMessage: 'Groups' },
    partials: { defaultMessage: 'Partials' },
    promptRuns: { defaultMessage: 'Runs' },
    models: { defaultMessage: 'Models' },
  },
);
