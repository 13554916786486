import {
  addQueryParams,
  getQueryParams,
  locationToHref,
  logger,
} from '@main/core-ui';
import React from 'react';
import {
  generatePath,
  Location,
  Navigate,
  Params,
  useLocation,
  useParams,
} from 'react-router-dom';

/**
 * Props for RedirectRoute
 */
export interface RedirectRouteProps {
  /** The route to redirect to */
  to:
    | Partial<Location>
    | ((
        params: Readonly<Params<string>>,
        location: Location,
      ) => Partial<Location>);
}

/**
 * A route that redirects to another page
 */
export const RedirectRoute: React.FC<RedirectRouteProps> = ({ to }) => {
  const location = useLocation();
  const params = useParams();
  const toLocation = typeof to !== 'function' ? to : to(params, location);
  toLocation.pathname = toLocation.pathname
    ? generatePath(toLocation.pathname, params ?? {})
    : undefined;
  // Log that a redirect happened, to be monitored on DataDog.
  logger.warn(
    `RedirectRoute: ${locationToHref(location)} -> ${locationToHref(
      toLocation,
    )}`,
  );

  return (
    <Navigate
      to={{
        ...addQueryParams(location, getQueryParams(toLocation.search || '')),
        ...toLocation,
      }}
      replace
    />
  );
};
