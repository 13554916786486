import { MenuTabInterface } from '@main/internationalization';
import { mkInput, mkType, SchemaToType } from '@main/schema-utils';

import { PolicyType } from './enums';
import { PolicyVersion, PolicyVersionInput } from './policyVersion';

export const LoadPrivacyPolicyInput = mkInput({
  name: 'LoadPrivacyPolicyInput',
  comment: 'Input for loading a privacy policy from an HTML page',
  fields: {
    url: {
      comment: 'The url to load the privacy policy from',
      type: 'string',
    },
  },
});

/** Override type */
export type LoadPrivacyPolicyInput = SchemaToType<
  typeof LoadPrivacyPolicyInput
>;

export const PolicyInput = mkInput({
  name: 'PolicyInput',
  comment: 'Make changes to a policy',
  fields: {
    id: {
      comment:
        'The unique ID of the policy to update, or left blank to create a new policy',
      optional: true,
      modelName: 'policy',
      type: 'id',
    },
    title: {
      comment: 'The updated title of the policy',
      type: 'string',
      optional: true,
    },
    type: {
      comment: 'The static type of policy',
      type: { PolicyType },
      optional: true,
    },
    version: {
      comment: 'The policy version being updated',
      type: PolicyVersionInput,
      optional: true,
    },
    disableEffectiveOn: {
      comment: 'When true, do not show the effectiveOn date in the policy.',
      type: 'boolean',
      optional: true,
    },
    disabledLocales: {
      comment:
        'The locales that are enabled for this policy. When empty list, all locales are enabled.',
      type: 'string',
      list: true,
      optional: true,
    },
  },
});

/** Override type */
export type PolicyInput = SchemaToType<typeof PolicyInput>;

export const PolicyMenuTab = mkType({
  name: 'PolicyMenuTab',
  comment: 'A side menu for a policy on the privacy center',
  interfaces: [MenuTabInterface],
  fields: {
    ...MenuTabInterface.fields,
  },
});

/** Override type */
export type PolicyMenuTab = SchemaToType<typeof PolicyMenuTab>;

export const Policy = mkType({
  name: 'Policy',
  comment: 'A policy on the privacy center',
  interfaces: [MenuTabInterface],
  fields: {
    ...MenuTabInterface.fields,
    createdAt: {
      comment: 'The time the Policy was created',
      type: 'Date',
    },
    updatedAt: {
      comment: 'The time the Policy was updated',
      type: 'Date',
    },
    slug: {
      comment:
        'The unique slug of the policy, used as the query parameter to identify the policy',
      type: 'string',
    },
    versions: {
      comment: 'The versions of the policy',
      list: true,
      type: PolicyVersion,
    },
    type: {
      comment: 'The type of the policy',
      type: { PolicyType },
    },
    disabledLocales: {
      comment:
        'The locales that are enabled for this policy. When empty list, all locales are enabled.',
      type: 'string',
      list: true,
    },
    disableEffectiveOn: {
      comment: 'When true, do not show the effectiveOn date in the policy.',
      type: 'boolean',
    },
    children: {
      comment:
        'When rendering on the side menu, these are the headers of each HTML section',
      list: true,
      type: PolicyMenuTab,
    },
  },
});

/** Override type */
export type Policy = SchemaToType<typeof Policy>;
