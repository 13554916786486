import { LanguageKey } from '@transcend-io/internationalization';

import type { PrivacyCenterStringified } from '@main/pc-types';
import type {
  SombraDataSubjectAuthMethod,
  SombraEmployeeAuthMethod,
} from '@main/sombra-types';
import type { PartialTheme, PrivacyCenterTheme } from '@main/theme';

/**
 * The information needed to construct authentication with sombra
 */
export interface PrivacyCenterAuthContext {
  /**
   * The secret established between the data subject and the organization's authentication server, that
   * is encrypted and forwarded to sombra in order to authenticate the data subject request.
   *
   * This secret is shared between privacy center and sombra only, it should never be presented in plain text to the backend.
   *
   * This could be a short-lived authCode when the authentication method is oauth, or a JWT magic link.
   *
   * The short-lived secret is presented in the login mutation and exchanged for a longer-lived session token that can
   * be used on subsequent requests
   */
  authSecret?: string;
  /**
   * The types of authentication strategies that can be passed to sombra
   */
  authMethod?: SombraDataSubjectAuthMethod | SombraEmployeeAuthMethod;
  /**
   * We need to store the sombra public key for simplicity in creating one-time diffie-hellman encryption keys
   * for GraphQL queries and mutations that are being forwarded to sombra
   */
  sombraPublicKey?: string;
}

/**
 * The privacy center as its sent over GraphQL, partially stringified.
 *
 * TODO: https://github.com/transcend-io/main/issues/7461 - remove serialization in schema
 */
export interface PrivacyCenter
  extends Omit<
    PrivacyCenterStringified,
    'locales' | 'defaultLocale' | 'theme' | 'partialTheme'
  > {
  /** Cast to enums */
  defaultLocale: LanguageKey;
  /** Cast to enums */
  locales: LanguageKey[];
  /** The themes associated with the privacy center. */
  theme: PrivacyCenterTheme;
  /** The partial theme without the defaults set */
  partialTheme: PartialTheme;
}

/**
 * the list of publishable privacy center modules
 */
export enum PrivacyCenterModule {
  PrivacyCenter = 'PRIVACY_CENTER',
  Policies = 'POLICIES',
  Messages = 'MESSAGES',
  Subjects = 'SUBJECTS',
  RequestsProcessedStats = 'REQUESTS_PROCESSED_STATS',
  Purposes = 'PURPOSES',
}

export interface PrivacyCenterModuleContext {
  /** the gql operation name to query */
  operationName: string;
  /** the endpoint to query */
  endpoint: any;
  /** the output filename of the module */
  moduleFilename: string;
  /** the column name in the version table */
  versionColumnName: string;
  /** is the module localized? */
  localized: boolean;
}
