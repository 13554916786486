import { statusDescriptionMessages, statusTitleMessages } from './messages';
import type { RequestStatusConfig } from './types';

/**
 * ## Delayed Request Status
 * The primary action has been executed and report sent, and the request is in a delayed state before the secondary action is executed.
 */
export const DELAYED_STATUS: RequestStatusConfig = {
  color: 'darkPrimary',
  icon: 'clock',
  canDownload: true,
  canViewByDataSubject: true,
  messages: {
    description: statusDescriptionMessages.DELAYED,
    title: statusTitleMessages.DELAYED,
  },
  secondaryOnly: true,
  transitions: [
    /** The data subject invalidates one of their identifier, i.e. clicks "Not you?" in the email */
    'FAILED_VERIFICATION',
    /** After wait period secondary action is fired */
    'SECONDARY',
    /** An administrator can cancel the request */
    'CANCELED',
    /** The data subject can cancel the request */
    'REVOKED',
  ],
};
