import { defineMessages } from '@transcend-io/internationalization';

/**
 * Message definitions for the RichEditor component
 */
export const richEditorMessages = defineMessages(
  'ad-core-components.RichEditor.richEditor',
  {
    addImageHeading: {
      defaultMessage: 'Add image',
    },
    addLinkHeading: {
      defaultMessage: 'Add link',
    },
    imageUrl: {
      defaultMessage: 'Image address (url)',
    },
    imageAlt: {
      defaultMessage: 'Image alt text',
    },
    fileDropUnsupported: {
      defaultMessage: 'File attachments are not supported in this editor',
    },
    linkDisplayText: {
      defaultMessage: 'Text to display',
    },
    linkDestination: {
      defaultMessage: 'Web address (url)',
    },
    submitLabel: {
      defaultMessage: 'Apply',
    },
    defaultPlaceholder: {
      defaultMessage: 'Start writing...',
    },
  },
);
