import {
  CustomizableText,
  OptionalConfigurableColorPaletteColor,
  RequiredConfigurableColorPaletteColor,
} from '@transcend-io/privacy-types';

import {
  DEFAULT_REPORT_FOOTER,
  DEFAULT_REPORT_HEADER,
} from '@main/datamap-types';
import { NO_SPECIAL_CHARACTERS_REGEX } from '@main/utils';

export const INITIAL_TEMPLATE = `${DEFAULT_REPORT_HEADER}<p>...</p>${DEFAULT_REPORT_FOOTER}`;

/**
 * the group names for template variables
 */
export enum TemplateVariableUseCase {
  Request = 'REQUEST',
  AutomatedVendorCoordination = 'AUTOMATED_VENDOR_COORDINATION',
  Assessment = 'ASSESSMENT',
  PrivacyCenter = 'PRIVACY_CENTER',
}

/**
 * The different categories of emails that we send from the perspective of template
 * variables on the backend. Each category represents a different set of available
 * template variables
 */
export enum EmailTemplateVariableCategory {
  PrivacyCenter = 'PRIVACY_CENTER',
  Organization = 'ORGANIZATION',
  Basic = 'BASIC',
  Request = 'REQUEST',
  EmailVerification = 'EMAIL_VERIFICATION',
  Assessments = 'ASSESSMENTS',
  Avc = 'AVC',
}

export const ProductUseCaseToTemplateVariableCategoryMap: Record<
  TemplateVariableUseCase,
  EmailTemplateVariableCategory
> = {
  [TemplateVariableUseCase.Request]: EmailTemplateVariableCategory.Request,
  [TemplateVariableUseCase.AutomatedVendorCoordination]:
    EmailTemplateVariableCategory.Avc,
  [TemplateVariableUseCase.Assessment]:
    EmailTemplateVariableCategory.Assessments,
  [TemplateVariableUseCase.PrivacyCenter]:
    EmailTemplateVariableCategory.PrivacyCenter,
};

export const AVAILABLE_TEMPLATE_LINKS: Partial<
  Record<TemplateVariableUseCase, string>
> = {
  [TemplateVariableUseCase.AutomatedVendorCoordination]:
    // eslint-disable-next-line max-len
    'https://docs.transcend.io/docs/privacy-requests/connecting-data-silos/automated-vendor-coordination#configure-email-templates',
  [TemplateVariableUseCase.Request]:
    'https://docs.transcend.io/docs/privacy-requests/configuring-requests/email-templates#template-variables',
  [TemplateVariableUseCase.Assessment]:
    'https://docs.transcend.io/docs/articles/assessments/assessments-email-sets#using-email-template-variables',
};

/**
 * the raw template variable details (in future might want to make this an object
 * with extra props like description, potentially null, etc.)
 */
type RawTemplateVariableString = string;
/**
 * a mapping from the categories of emails that are sent to the different template
 * variables available for use when sending them
 */
export const EmailTemplateVariableAvailabilityMap: Record<
  EmailTemplateVariableCategory,
  // extends
  (
    | EmailTemplateVariableCategory
    // raw value
    | RawTemplateVariableString
    // get value from theme
    | ((props: {
        /** list of attribute keys for the org */
        attributeKeyNames?: string[];
        /** list of identifier names for the org */
        identifierNames?: string[];
      }) => RawTemplateVariableString[])
  )[]
> = {
  [EmailTemplateVariableCategory.Basic]: [
    // white label boolean
    'noBranding',
  ],
  [EmailTemplateVariableCategory.Avc]: [
    // white label boolean
    'noBranding',
    'organizationName',
    'requestDetails',
    'completionLink',
    'completionText',
    'actionTitle',
    'dataSiloTitle',
  ],
  [EmailTemplateVariableCategory.Organization]: [
    EmailTemplateVariableCategory.Basic,
    'organizationName',
    'organizationUri',
    'noVerifyText',
    'country',
  ],
  [EmailTemplateVariableCategory.PrivacyCenter]: [
    EmailTemplateVariableCategory.Organization,
    // dot '.' is used for nested object access
    // keys come from these sources:
    // - https://github.com/transcend-io/privacy-types/blob/main/src/theme.ts
    // - https://github.com/transcend-io/main/blob/bb673220c6399c0fbf8708180309c9ae91977183/frontend-support/theme/src/theme.ts#L12
    // - https://github.com/transcend-io/main/blob/bb673220c6399c0fbf8708180309c9ae91977183/frontend-support/theme/src/theme.ts#L136
    () =>
      (
        Object.values({
          ...OptionalConfigurableColorPaletteColor,
          ...RequiredConfigurableColorPaletteColor,
        }) ?? []
      ).map((key) => `colors.${key}`),
    // text styles, keys come from https://github.com/transcend-io/privacy-types/blob/main/src/theme.ts#L185
    () =>
      (Object.values(CustomizableText) ?? []).flatMap((key) =>
        ['name', 'url'].map((prop) => `${key}.${prop}`),
      ),
    'buttonStyles',
    'emailContainerStyles',
    'companyLogo',
    'baseUrl',
    'noVerifyUrl',
    'verifyUrl',
    'prettySenderName',
  ],
  // request, report email, sendCommunication, request receipt, request revoke are same
  [EmailTemplateVariableCategory.Request]: [
    EmailTemplateVariableCategory.PrivacyCenter,
    'dataSubjectType',
    'daysRemaining',
    'purpose',
    'partition',
    'type',
    'downloadAvailabilityDuration',
    'postCompileStatus',
    'countrySubDivision',
    ({ identifierNames }) =>
      (identifierNames ?? []).map(
        (name) =>
          `identifier-${name.replace(NO_SPECIAL_CHARACTERS_REGEX, '-')}`,
      ),
    ({ attributeKeyNames }) =>
      (attributeKeyNames ?? []).map(
        (name) => `attribute-${name.replace(NO_SPECIAL_CHARACTERS_REGEX, '-')}`,
      ),
  ],
  // email & government id verification are same
  [EmailTemplateVariableCategory.EmailVerification]: [
    EmailTemplateVariableCategory.Request,
    'noVerifyText',
  ],
  [EmailTemplateVariableCategory.Assessments]: [
    EmailTemplateVariableCategory.Organization,
    'verifyUrl',
    'assessmentAssignee',
    'assessmentUser',
    'assessmentName',
    'assessmentComment',
    'assessmentSectionNames',
  ],
};
