import {
  FlexRow,
  GenericMessageDescriptor,
  Icon,
  IconType,
  LoadingSpinner,
  useFormatMessageGeneric,
} from '@main/core-ui';
import React from 'react';
import { CSSProperties } from 'styled-components';

import { INPUT_ICON_SIZE } from './constants';
import {
  InputAddonWrapper,
  InputIconWrapper,
  InputWrapper,
  StyledInput,
  StyledTextArea,
} from './wrappers';

export interface InputBaseProps {
  /** the icon type or content to display */
  icon?: IconType | React.ReactNode;
  /** the placeholder to use */
  placeholder?: GenericMessageDescriptor;
  /** optional style overrides */
  style?: CSSProperties;
  /** optional class overrides */
  className?: string;
  /** show a loading indicator */
  loading?: boolean;
  /** is it a text area? */
  isTextArea: boolean;
  /** addon to display on the right side of the input */
  addonRight?: React.ReactNode;
  /** addon to display on the left side of the input */
  addonLeft?: React.ReactNode;
  /** styles for the flex row container of the input */
  containerStyle?: CSSProperties;
  /** styles for the input component */
  inputComponentStyle?: CSSProperties;
}

/**
 * Input props
 */
export type InputProps = Omit<
  React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  >,
  'placeholder'
> &
  Omit<InputBaseProps, 'isTextArea'>;
/**
 * TextArea props
 */
export type TextAreaProps = Omit<
  React.DetailedHTMLProps<
    React.TextareaHTMLAttributes<HTMLTextAreaElement>,
    HTMLTextAreaElement
  >,
  'placeholder'
> &
  Omit<InputBaseProps, 'isTextArea'>;

/**
 * Input or TextArea
 */
const InputBase = React.forwardRef<
  HTMLInputElement | HTMLTextAreaElement,
  InputBaseProps
>(
  (
    {
      icon,
      placeholder,
      style,
      loading,
      className,
      isTextArea,
      addonRight,
      addonLeft,
      containerStyle,
      inputComponentStyle,
      ...rawInputProps
    },
    ref,
  ) => {
    const { formatMessageGeneric } = useFormatMessageGeneric();

    const iconOrLoading = loading ? <LoadingSpinner size={16} /> : icon;
    const InputComponent: any = isTextArea ? StyledTextArea : StyledInput;

    return (
      <FlexRow style={containerStyle}>
        {addonLeft && (
          <InputAddonWrapper isAddonRight={false}>
            {addonLeft}
          </InputAddonWrapper>
        )}
        <InputWrapper style={style} className={className}>
          <InputComponent
            ref={ref as any}
            hasIcon={!!iconOrLoading}
            placeholder={formatMessageGeneric(placeholder) as string}
            hasAddonRight={!!addonRight}
            hasAddonLeft={!!addonLeft}
            style={inputComponentStyle}
            {...(rawInputProps as any)}
          />
          {iconOrLoading && (
            <InputIconWrapper>
              {typeof iconOrLoading === 'string' ? (
                <Icon type={iconOrLoading as IconType} size={INPUT_ICON_SIZE} />
              ) : (
                iconOrLoading
              )}
            </InputIconWrapper>
          )}
        </InputWrapper>
        {addonRight && (
          <InputAddonWrapper isAddonRight>{addonRight}</InputAddonWrapper>
        )}
      </FlexRow>
    );
  },
);

export const Input = React.forwardRef<HTMLInputElement, InputProps>(
  (props, ref) => <InputBase {...props} ref={ref} isTextArea={false} />,
);
export const TextArea = React.forwardRef<HTMLTextAreaElement, TextAreaProps>(
  (props, ref) => <InputBase {...props} ref={ref} isTextArea />,
);
