import * as t from 'io-ts';

import { dbModelId } from '@main/schema-utils';

import { AuditEventCode } from '../schema/enums';
import { mkAuditEventCodec } from '../types';

export const AuditEventRiskMatrixRow = mkAuditEventCodec(
  AuditEventCode.RiskMatrixRow,
  t.intersection([
    t.type({
      /** ID of the risk matrix row */
      riskMatrixRowId: dbModelId('riskMatrixRow'),
    }),
    t.partial({
      /** ID of the risk framework to which the risk matrix row belongs */
      riskFrameworkId: dbModelId('riskFramework'),
    }),
  ]),
);

/** Override types. */
export type AuditEventRiskMatrixRow = t.TypeOf<typeof AuditEventRiskMatrixRow>;
