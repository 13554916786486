import { AuditEventPreview } from '@main/audit-types';
import { FlexColumn } from '@main/core-ui';
import React from 'react';
import { useIntl } from 'react-intl';

import { AuditEventCustomItemTitle } from './AuditEventCustomItemTitle';
import { AuditTrailListItemLayout } from './AuditTrailListItemLayout';
import { useFormatAuditTrailJoinMessage } from './hooks';
import {
  auditTrailJoinMessages,
  auditTrailPrettyCodeMessages,
} from './messages';
import { AuditTrailBoldWrapper } from './wrappers';

interface AuditTrailJoinListItemProps {
  /** the events to display */
  events: AuditEventPreview[];
}

/**
 * AuditTrailJoinListItem
 */
export const AuditTrailJoinListItem: React.FC<AuditTrailJoinListItemProps> = ({
  events,
}) => {
  const { formatMessage } = useIntl();
  const formatAuditTrailJoinMessage = useFormatAuditTrailJoinMessage();

  const [firstEvent] = events;
  const createdAt = new Date((firstEvent as any).createdAt);
  const isCleared =
    events.length === 1 && !firstEvent.afterState && !firstEvent.beforeState;
  const isReplaced = events.every(
    (event) => event.additionalContext?.replaceEntireJoin,
  );
  const hasMoreDetails = !isCleared;

  const typeMessage = auditTrailPrettyCodeMessages[firstEvent.code]
    ? formatMessage(auditTrailPrettyCodeMessages[firstEvent.code])
    : firstEvent.code;
  return (
    <AuditTrailListItemLayout
      createdAt={createdAt}
      actorTitle={firstEvent.additionalContext.actorTitle}
      actorProfilePictureUrl={firstEvent.actorUser?.profilePicture}
      apiKeyId={firstEvent.actorApiKeyId}
      eventTitle={formatAuditTrailJoinMessage(firstEvent, events)}
      hasMoreDetails={hasMoreDetails}
      details={
        !isCleared && (
          <FlexColumn style={{ gap: '.25em' }}>
            {isReplaced && (
              <div>
                {formatMessage(auditTrailJoinMessages.replacedDescription, {
                  type: typeMessage,
                })}
              </div>
            )}
            {events.map((event) =>
              event.afterState ? (
                <li key={event.id}>
                  {isReplaced ? (
                    <AuditTrailBoldWrapper>
                      <AuditEventCustomItemTitle event={event} />
                    </AuditTrailBoldWrapper>
                  ) : (
                    formatMessage(auditTrailJoinMessages.addedSingle, {
                      name: (
                        <AuditTrailBoldWrapper>
                          <AuditEventCustomItemTitle event={event} />
                        </AuditTrailBoldWrapper>
                      ),
                    })
                  )}
                </li>
              ) : (
                <li key={event.id}>
                  {formatMessage(auditTrailJoinMessages.removedSingle, {
                    name: (
                      <AuditTrailBoldWrapper>
                        <AuditEventCustomItemTitle event={event} />
                      </AuditTrailBoldWrapper>
                    ),
                  })}
                </li>
              ),
            )}
          </FlexColumn>
        )
      }
    />
  );
};
