import { FilterConfigItem } from '@main/ad-core-components';
import {
  SelectedPathfinder,
  SelectPathfinders,
} from '@main/admin-dashboard/src/Pathfinder/components/SelectPathfinders';
import { buildUseLazyQuery } from '@main/core-ui';
import { endpoints } from '@main/prompt-types';
import { ID } from '@main/schema-utils';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { MessageDescriptor } from 'react-intl';

import { filterManagerHookMessages } from './messages';

/**
 * Filters definition with property for pathfinder values specified
 */
type PathfinderEnrichedFilters = Record<string, unknown> &
  Record<
    /**
     * Pathfinder values that are currently filtering the table
     */
    string,
    SelectedPathfinder[] | undefined
  >;

const useLazyPathfinders = buildUseLazyQuery(endpoints.pathfinders);

export const usePathfinderFilters = <
  T extends Record<string, unknown>,
  E extends PathfinderEnrichedFilters,
>({
  pathfinderIdsFilterKey = 'pathfinderIds',
  filters,
  setFilters,
  label,
  enrichedPathfinderKey = 'pathfinders',
}: {
  /** The property name of the filter for pathfinder ids */
  pathfinderIdsFilterKey?: keyof T;
  /** The currently applied filters */
  filters?: T;
  /** Callback when the filters are changed */
  setFilters: (filters: T) => void;
  /** Label for the filter menu */
  label: string | MessageDescriptor;
  /** The key to use if more than one usePathfinderFilters is used in a FilterManager */
  enrichedPathfinderKey?: keyof E;
}): {
  /**  The pathfinder keys enabled on this table */
  selectedPathfinders: SelectedPathfinder[];
  /** The filter configuration to be passed to the filter manager */
  pathfinderFiltersConfig: FilterConfigItem<E>;
  /** Callback for when the filter is cleared in the filter manager */
  clearPathfinderFilters: (key: Extract<keyof E, string>) => void;
} => {
  const getSelectedPathfinders = useLazyPathfinders();
  const [selectedPathfinders, setSelectedPathfinders] = useState<
    SelectedPathfinder[]
  >([]);
  const [fetchingPathfinders, setFetchingPathfinders] = useState(false);

  // Populate initial complex filter values
  useEffect(() => {
    if (filters) {
      const pathfinderIds = (filters[pathfinderIdsFilterKey] ??
        []) as ID<'pathfinder'>[];

      const filtersAndEnrichedFiltersMatch =
        pathfinderIds.sort().join() ===
        selectedPathfinders
          .map(({ id }) => id)
          .sort()
          .join();

      if (pathfinderIds.length === 0 && selectedPathfinders.length > 0) {
        setSelectedPathfinders([]);
      } else if (!filtersAndEnrichedFiltersMatch && !fetchingPathfinders) {
        // Prevent over-firing this setter while data is still being fetched
        setFetchingPathfinders(true);
        getSelectedPathfinders({ filterBy: { ids: pathfinderIds } })
          .then(({ data }) => {
            setSelectedPathfinders(data.nodes);
          })
          .finally(() => {
            setFetchingPathfinders(false);
          });
      }
    }
  }, [
    filters,
    fetchingPathfinders,
    getSelectedPathfinders,
    selectedPathfinders.length,
    pathfinderIdsFilterKey,
    selectedPathfinders,
  ]);

  const pathfinderFiltersConfig = useMemo(
    () =>
      ({
        filterKey: enrichedPathfinderKey,
        label,
        pillOptions: {
          label: ({
            filterValues: { [enrichedPathfinderKey]: pathfinders = [] },
            index = 0,
          }) => pathfinders[index]?.name,
        },
        filter: (
          <SelectPathfinders
            isMulti
            placeholderDescriptor={
              filterManagerHookMessages.pathfindersPlaceholder
            }
            menuPosition="absolute"
            value={selectedPathfinders}
            showOutline={false}
            onChange={(pathfinders) => {
              setSelectedPathfinders([...pathfinders]);
              setFilters({
                ...filters,
                [pathfinderIdsFilterKey]: pathfinders.map(({ id }) => id),
              } as T);
            }}
          />
        ),
      }) as FilterConfigItem<PathfinderEnrichedFilters>,
    [
      enrichedPathfinderKey,
      label,
      selectedPathfinders,
      filters,
      pathfinderIdsFilterKey,
    ],
  );

  const clearPathfinderFilters = useCallback(
    (key) => {
      if (key === enrichedPathfinderKey) {
        setFilters({
          ...filters,
          [pathfinderIdsFilterKey]: [],
        } as T);
        setSelectedPathfinders([]);
      }
    },
    [enrichedPathfinderKey, filters, pathfinderIdsFilterKey],
  );

  return {
    selectedPathfinders,
    pathfinderFiltersConfig,
    clearPathfinderFilters,
  };
};
