import { ApolloQueryResult, QueryResult } from '@apollo/client';
import {
  AuditEventPreview,
  AuditEventPreviewRaw,
  endpoints,
} from '@main/audit-types';
import { buildUseLazyQuery, buildUseQuery } from '@main/core-ui';
import { ParamsToType } from '@main/schema-utils';
import React, { ReactNode, useMemo } from 'react';
import { useIntl } from 'react-intl';

import {
  auditTrailBaseTypePrettyCodeMessages,
  auditTrailJoinMessages,
  auditTrailPrettyCodeMessages,
  auditTrailStateMessages,
} from './messages';
import { DisplayedBaseType } from './types';
import { AuditTrailBoldWrapper } from './wrappers';

const useAuditTrailQuery = buildUseQuery(endpoints.auditEvents);
const useAuditTrailQueryLazy = buildUseLazyQuery(endpoints.auditEvents);

interface AuditData {
  /** the event list */
  nodes: AuditEventPreview[];
  /** the total count of events */
  totalCount: number;
}

/**
 * the response type of the audit query with the extra parsed data
 */
type ParsedQueryResponse = ApolloQueryResult<AuditData | undefined>;

const parseAuditJsonFields = (event: AuditEventPreviewRaw): AuditEventPreview =>
  ({
    ...event,
    beforeState: event.beforeState ? JSON.parse(event.beforeState) : undefined,
    afterState: event.afterState ? JSON.parse(event.afterState) : undefined,
    additionalContext: event.additionalContext
      ? JSON.parse(event.additionalContext)
      : undefined,
  }) as AuditEventPreview;

/**
 * hook that wraps the audit query in a helper function that parses the JSON
 * state & context blobs from each event response
 *
 * @returns the wrapped hook function
 */
export const buildUseAuditTrailQuery =
  (): ((
    options: Parameters<typeof useAuditTrailQuery>[0],
  ) => QueryResult<
    AuditData,
    ParamsToType<typeof endpoints.auditEvents.params>
  >) =>
  (
    options: Parameters<typeof useAuditTrailQuery>[0],
  ): QueryResult<
    AuditData,
    ParamsToType<typeof endpoints.auditEvents.params>
  > => {
    const response = useAuditTrailQuery(options);
    const nodes = useMemo(
      () => response.data?.nodes.map(parseAuditJsonFields) ?? [],
      [response.data?.nodes],
    );

    return {
      ...response,
      data: response.data ? { ...response.data, nodes } : undefined,
    } as any;
  };

/**
 * lazy version of buildUseAuditTrailQuery
 *
 * @returns the wrapped hook function
 */
export const buildUseAuditTrailQueryLazy =
  (): (() => (
    options: Parameters<ReturnType<typeof useAuditTrailQueryLazy>>[0],
  ) => Promise<ParsedQueryResponse>) =>
  () => {
    const exec = useAuditTrailQueryLazy();
    return async (
      options: Parameters<ReturnType<typeof useAuditTrailQueryLazy>>[0],
    ): Promise<ParsedQueryResponse> => {
      const response = await exec(options);
      const nodes = response.data?.nodes.map(parseAuditJsonFields) ?? [];

      return {
        ...response,
        data: response.data ? { ...response.data, nodes } : undefined,
      };
    };
  };

/**
 * data required to properly format the audit trail messages
 */
type AuditTrailMessageDetails = Pick<
  AuditEventPreview,
  'beforeState' | 'afterState' | 'additionalContext' | 'code'
>;

export const useFormatAuditTrailMessage = (): ((
  event: AuditTrailMessageDetails,
  plainTextOnly?: boolean,
) => ReactNode) => {
  const { formatMessage } = useIntl();

  const formatAuditTrailMessage = (
    event: AuditTrailMessageDetails,
    plainTextOnly = false,
  ): ReactNode => {
    const isDeleted = event.beforeState && !event.afterState;
    const isCreated = !event.beforeState && event.afterState;
    const { additionalMessage, baseModelTitle: title } =
      event.additionalContext;

    return formatMessage(
      isDeleted
        ? auditTrailStateMessages.deleted
        : isCreated
          ? auditTrailStateMessages.created
          : auditTrailStateMessages.updated,
      {
        name: plainTextOnly ? (
          <AuditTrailBoldWrapper>{title}</AuditTrailBoldWrapper>
        ) : (
          title
        ),
        type: formatMessage(auditTrailPrettyCodeMessages[event.code]),
        additionalMessage,
      },
    );
  };
  return formatAuditTrailMessage;
};

export const useFormatAuditTrailJoinMessage = (): ((
  firstEvent: AuditTrailMessageDetails,
  events: AuditEventPreview[],
  plainTextOnly?: boolean,
) => ReactNode) => {
  const { formatMessage } = useIntl();

  const formatAuditTrailJoinMessage = (
    firstEvent: AuditTrailMessageDetails,
    events: AuditEventPreview[],
    plainTextOnly = false,
  ): ReactNode => {
    const isCleared =
      events.length === 1 && !firstEvent.afterState && !firstEvent.beforeState;
    const isReplaced = events.every(
      (event) => event.additionalContext?.replaceEntireJoin,
    );
    const hasAdded = events.some((event) => !event.beforeState);
    const hasRemoved = events.some((event) => !event.afterState);

    const typeMessage = auditTrailPrettyCodeMessages[firstEvent.code]
      ? formatMessage(auditTrailPrettyCodeMessages[firstEvent.code])
      : firstEvent.code;

    const title = firstEvent.additionalContext.baseModelTitle;
    return formatMessage(
      isCleared
        ? auditTrailJoinMessages.cleared
        : isReplaced
          ? auditTrailJoinMessages.replaced
          : hasAdded && hasRemoved
            ? auditTrailJoinMessages.updated
            : hasAdded
              ? auditTrailJoinMessages.added
              : auditTrailJoinMessages.removed,
      {
        name: plainTextOnly ? (
          title
        ) : (
          <AuditTrailBoldWrapper>{title}</AuditTrailBoldWrapper>
        ),
        type: typeMessage,
        baseType: auditTrailBaseTypePrettyCodeMessages[
          firstEvent.code as DisplayedBaseType
        ]
          ? formatMessage(
              auditTrailBaseTypePrettyCodeMessages[
                firstEvent.code as DisplayedBaseType
              ],
            )
          : '',
      },
    );
  };
  return formatAuditTrailJoinMessage;
};
