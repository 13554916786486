import {
  ApolloQueryResult,
  BasePaginatedSelectProps,
  FetchMoreQueryOptions,
  RawPaginatedSelect,
} from '@main/core-ui';
import React, { PropsWithChildren, ReactElement } from 'react';
import { OptionTypeBase } from 'react-select';

/**
 * Props for PaginatedSelect
 */
export interface PaginatedSelectProps<
  TNode extends OptionTypeBase,
  IsMulti extends boolean,
> extends BasePaginatedSelectProps<TNode, IsMulti> {
  /** A function to paginate more values from the query hook */
  fetchMore: (
    options: FetchMoreQueryOptions<{
      /** The page offset */
      offset?: number;
      /** The page limit */
      first?: number;
    }>,
  ) => Promise<ApolloQueryResult<TNode[]> | void> | void;
}

/**
 * A React component for displaying paginated queries in dropdowns.
 *
 * This Select component does not need to be inside a form. It has custom props
 * to make pagination using hooks easy, but otherwise takes in the same props
 * as the default react-select component.
 *
 * This component is written as a function, and does not use React.FC, because
 * it uses generic parameters for its props.
 *
 * Paginated queries that this component calls should define a
 * [merge policy]{@link https://www.apollographql.com/docs/react/caching/cache-field-behavior/#the-merge-function}
 * in the [Apollo Client cache configuration file]{@link @main/admin-dashboard/src/graphql}.
 * The results from the paginated query should be merged with existing results
 * in the cache, so that this component displays all results fetched thus far.
 * The helper `buildUseInfiniteScroll` should help with fetching the next page
 * and caching the merged result set.
 */
export function PaginatedSelect<
  TNode extends OptionTypeBase,
  IsMulti extends boolean,
>({
  fetchMore,
  ...selectProps
}: PropsWithChildren<PaginatedSelectProps<TNode, IsMulti>>): ReactElement {
  return (
    <RawPaginatedSelect<TNode, IsMulti>
      {...selectProps}
      isClearable={selectProps.isDisabled ? false : selectProps.isClearable}
      getNextPage={() => fetchMore({})}
    />
  );
}
