/**
 * The fields that an prompt can be ordered on
 */
export enum PromptGroupOrderField {
  /** The title of the prompt */
  Title = 'title',
  /** The age of the prompt */
  CreatedAt = 'createdAt',
  /** The last updated time */
  UpdatedAt = 'updatedAt',
}
