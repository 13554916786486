import { statusDescriptionMessages, statusTitleMessages } from './messages';
import type { RequestStatusConfig } from './types';

/**
 * ## Set On Hold
 * The organization can decide that some requests cannot be processed, and are thus placed on hold.
 *
 * An example of this is if the organization hold a black-list of accounts that cannot be destroyed. They can place the request on indefinite hold
 * if someone on the black-list submits an erasure request.
 *
 * ### ON CHANGE TO
 *  - We notify the Data Subject that their request is on hold
 *  - Display the on hold situation as an action item to logged in organization members
 */
export const ON_HOLD_STATUS: RequestStatusConfig = {
  color: 'error',
  icon: 'shield-magnifying-glass',
  isActionItem: true,
  isBuilding: true,
  messages: {
    description: statusDescriptionMessages.ON_HOLD,
    title: statusTitleMessages.ON_HOLD,
  },
  transitions: [
    /** The request can be moved off hold, and compiled normally */
    'COMPILING',
    /** Organization member clicks cancel, triggering cancelation email and the request is permanently killed */
    'CANCELED',
  ],
};
