import { defineMessages } from '@transcend-io/internationalization';

export const multipleChoiceMessages = defineMessages(
  'ad-core-components.MultipleChoice.multipleChoice',
  {
    clear: {
      defaultMessage: 'Clear',
    },
    selectAll: {
      defaultMessage: 'Select All',
    },
  },
);

export const yesOrNoMessages = defineMessages(
  'ad-core-components.MultipleChoice.yesOrNo',
  {
    yes: {
      defaultMessage: 'Yes',
    },
    no: {
      defaultMessage: 'No',
    },
  },
);
