import {
  StaticColorPicker,
  StaticColorPickerProps,
} from '@main/ad-core-components';
import { FormItemWrapper, IFormItemWrapperProps } from '@main/core-ui';
import { ColorPaletteColor } from '@main/theme';
import React from 'react';
import { Controller } from 'react-hook-form';
import { CSSProperties } from 'styled-components';

/** Props for FormStaticColorButton component */
export interface FormStaticColorButtonProps
  extends Omit<IFormItemWrapperProps, 'name'> {
  /** The name of this field (identifies a unique attribute value color field) */
  name: string;
}

export const FormStaticColorButton: React.FC<
  FormStaticColorButtonProps & {
    /** Styles for the form item wrapper specifically */
    formItemWrapperStyle?: CSSProperties;
    /** Color options the user can pick from */
    colorOptions: StaticColorPickerProps['colorOptions'];
  }
> = ({ name, formItemWrapperStyle, colorOptions, ...props }) => (
  <FormItemWrapper
    name={name}
    formItemWrapperStyle={formItemWrapperStyle}
    {...props}
  >
    <Controller
      name={name}
      render={({ field }) => (
        <StaticColorPicker
          selectedColor={
            (field.value as ColorPaletteColor | undefined) ?? 'transcendNavy2'
          }
          colorOptions={colorOptions}
          onSelectColor={(color) => field.onChange(color)}
        />
      )}
    />
  </FormItemWrapper>
);
