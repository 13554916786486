import { DefaultTheme } from 'styled-components';

import {
  AttributeKeyType,
  DataCategoryType,
  ProcessingPurpose,
} from '@transcend-io/privacy-types';

import { DarkColorOption } from '@main/core-ui';

/**
 * DRY snippet to use for response fields that are messages
 */
export const MESSAGE_FIELDS = {
  id: null,
  defaultMessage: null,
};

export const DATA_CATEGORY_TYPE_COLOR_NAMES: {
  [categoryType in DataCategoryType]: keyof DarkColorOption;
} = {
  [DataCategoryType.Financial]: 'lime1',
  [DataCategoryType.Health]: 'orange1',
  [DataCategoryType.Contact]: 'purple1',
  [DataCategoryType.Location]: 'blue1',
  [DataCategoryType.Demographic]: 'mint1',
  [DataCategoryType.Id]: 'pink1',
  [DataCategoryType.OnlineActivity]: 'orange1',
  [DataCategoryType.UserProfile]: 'lime1',
  [DataCategoryType.SocialMedia]: 'mint1',
  [DataCategoryType.Connection]: 'blue1',
  [DataCategoryType.Tracking]: 'purple1',
  [DataCategoryType.Device]: 'pink1',
  [DataCategoryType.Survey]: 'orange1',
  [DataCategoryType.Other]: 'brown1',
  [DataCategoryType.Unspecified]: 'transcendNavy2',
  [DataCategoryType.NotPersonalData]: 'transcendNavy2',
  [DataCategoryType.IntegrationIdentifier]: 'red1',
};

export const DATA_CATEGORY_TYPE_COLORS: (theme: DefaultTheme) => (option: {
  /** The value being selected */
  value: DataCategoryType;
}) => string =
  (theme) =>
  ({ value }) =>
    theme.colors[DATA_CATEGORY_TYPE_COLOR_NAMES[value] ?? 'gray1'];

export const PURPOSE_OF_PROCESSING_COLOR_NAMES: {
  [categoryType in ProcessingPurpose]: keyof DarkColorOption;
} = {
  [ProcessingPurpose.Essential]: 'orange1',
  [ProcessingPurpose.AdditionalFunctionality]: 'lime1',
  [ProcessingPurpose.Advertising]: 'mint1',
  [ProcessingPurpose.Marketing]: 'blue1',
  [ProcessingPurpose.Analytics]: 'purple1',
  [ProcessingPurpose.Personalization]: 'pink1',
  [ProcessingPurpose.OperationSecurity]: 'orange1',
  [ProcessingPurpose.Legal]: 'blue1',
  [ProcessingPurpose.Transfer]: 'mint1',
  [ProcessingPurpose.Sale]: 'lime1',
  [ProcessingPurpose.HR]: 'purple1',
  [ProcessingPurpose.Other]: 'brown1',
  [ProcessingPurpose.Unspecified]: 'transcendNavy2',
};

export const PURPOSE_OF_PROCESSING_COLORS: (theme: DefaultTheme) => (option: {
  /** The processing purpose for the option */
  purpose: ProcessingPurpose;
}) => string =
  (theme) =>
  ({ purpose }) =>
    theme.colors[PURPOSE_OF_PROCESSING_COLOR_NAMES[purpose] ?? 'gray1'];

export const ATTRIBUTE_KEY_TEXT_TYPES = [
  AttributeKeyType.URL,
  AttributeKeyType.EMAIL,
  AttributeKeyType.Text,
  AttributeKeyType.TELEPHONE,
];
