import { createSlice, PayloadAction } from '@reduxjs/toolkit';

/**
 * The CTABanners reducer state
 */
export interface CTABannersState {
  /** The key of a banner */
  [key: string]: boolean;
}

export const ctaBannersSlice = createSlice({
  name: 'ctaBanners',
  initialState: {} as CTABannersState,
  reducers: {
    hideBanner: (state, { payload }: PayloadAction<string>) => ({
      ...state,
      [payload]: true,
    }),
  },
});

export const { hideBanner } = ctaBannersSlice.actions;
