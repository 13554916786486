import React, { ReactElement } from 'react';
import { useTheme } from 'styled-components';

import { SvgFormIcon } from './wrappers';

export const FormErrorIcon = (): ReactElement => {
  const theme = useTheme();

  return (
    <SvgFormIcon fill={theme.colors.black}>
      <path
        fill="none"
        stroke={theme.colors.transcend}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="2"
        // eslint-disable-next-line max-len
        d="M21.382,18.967L12.585,4.331c-0.265-0.441-0.904-0.441-1.169,0L2.618,18.967C2.345,19.421,2.672,20,3.202,20h17.595C21.328,20,21.655,19.421,21.382,18.967z"
      />
      <path d="M13,18h-2v-2h2V18z M13,14h-2V9h2V14z" />
    </SvgFormIcon>
  );
};
