import { createLoadable } from '@main/core-ui';

import { emailDomainMessages } from './messages';

// Load the component dynamically
export const EmailDomainsLoadable = createLoadable({
  loader: () => import('./EmailDomains'),
  messages: emailDomainMessages,
  logo: 'mail',
});
