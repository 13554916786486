import { createLoadable } from '@main/core-ui';

import { policiesMessages } from './messages';

// Load the component dynamically
export default createLoadable({
  loader: () => import('./Policies'),
  messages: policiesMessages,
  logo: 'highlight',
});
