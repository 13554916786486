import { IWithClassName, message } from '@main/core-ui';
import { sanitizeHtml } from '@main/utils';
import { Content, EditorContent, EditorEvents, useEditor } from '@tiptap/react';
import React, { useEffect, useMemo } from 'react';
import { useIntl } from 'react-intl';
import sanitizeHtmlLib from 'sanitize-html';

import { FileUploadOptions } from '../utils/uploadFile';
import {
  CustomImageOptions,
  getEnabledExtensions,
  RichEditorExtension,
} from './extensions';
import { richEditorMessages } from './messages';
import { Toolbar } from './Toolbar';
import { useHandleFileDrop } from './useHandleFileDrop';
import { StyledEditor, StyledEditorCardWrapper } from './wrappers';

export { RichEditorExtension };
export type { EditorEvents };

export interface RichEditorProps extends IWithClassName {
  /** The document's content in HTML or JSON format */
  content?: Content;
  /** The callback to fire when content is modified */
  onUpdate?: (props: EditorEvents['update']) => void;
  /** Whether this is in edit mode (or read-only) */
  editable?: boolean;
  /** Custom placeholder text, when this document is empty */
  placeholder?: string;
  /** Allow tables in this editor? (default: true) */
  withTables?: boolean;
  /** sanitize the html */
  sanitize?: boolean;
  /** ignore safety concerns and do not sanitize the html */
  sanitizeOptions?: sanitizeHtmlLib.IOptions;
  /** Content to show inline with the toolbar. Content is right-aligned when toolbar is not in read-only mode */
  additionalToolbarContent?: React.ReactNode;
  /** shrink down the editor as much as possible */
  compact?: boolean;
  /** extensions enabled for this editor instance */
  enabledExtensions?: RichEditorExtension[];
  /** Options to handle the file that is dropped */
  uploadOptions?: FileUploadOptions;
  /** Callback to transform the src of an image (e.g. to use presigned urls) */
  transformImageSrc?: CustomImageOptions['transformSrc'];
  /**
   * When true, unload and reload the on change handler when the `onUpdate` function changes
   * tip tap editor does not update the onChange handler when the content changes
   * need to use this workaround
   * see https://github.com/ueberdosis/tiptap/issues/2403#issuecomment-1017840603
   */
  updateOnChangeHandler?: boolean;
}

/**
 * A WYSIWYG editor component.
 *
 * @returns The editor
 */
export function RichEditor({
  className,
  content: rawContent,
  onUpdate,
  editable = true,
  updateOnChangeHandler = false,
  placeholder,
  additionalToolbarContent,
  compact,
  sanitize,
  sanitizeOptions,
  enabledExtensions = [],
  uploadOptions,
  transformImageSrc,
}: RichEditorProps): JSX.Element {
  const { formatMessage } = useIntl();
  const handleDrop = useHandleFileDrop();

  const content =
    sanitize && typeof rawContent === 'string'
      ? sanitizeHtml(rawContent, sanitizeOptions)
      : rawContent;

  // Get the editor extensions to use
  const addedExtensions = useMemo(() => {
    // Set the placeholder text when the content is empty
    const placeholderToUse =
      placeholder || formatMessage(richEditorMessages.defaultPlaceholder);

    return getEnabledExtensions(
      [...new Set([RichEditorExtension.Default, ...enabledExtensions])],
      {
        placeholder: placeholderToUse,
        imageOptions: {
          transformSrc: transformImageSrc,
        },
      },
    );
  }, [enabledExtensions, formatMessage, placeholder]);

  const editor = useEditor(
    {
      extensions: addedExtensions,
      content,
      editable,
      onUpdate,
      editorProps: {
        handleDrop: (view, event, slice, moved) => {
          // Prevent file from opening in new tab
          event.preventDefault();
          if (!uploadOptions) {
            message.error(
              formatMessage(richEditorMessages.fileDropUnsupported),
            );
            // Use default handling
            return false;
          }
          return handleDrop({ view, event, moved, uploadOptions });
        },
      },
    },
    [
      editable,
      // If the editor is not editable, allow new content from parent to refresh the editor
      // If the editor is editable, this will create a bad UX as the editor will rerender too frequently
      ...(!editable ? [content] : [true]),
    ],
  );

  // tip tap editor does not update the onChange handler when the content changes
  // need to use this workaround
  // see https://github.com/ueberdosis/tiptap/issues/2403#issuecomment-1017840603
  useEffect(() => {
    if (updateOnChangeHandler && editor && onUpdate) {
      editor.off('update');
      editor.on('update', onUpdate);
    }
  }, [editor, onUpdate, updateOnChangeHandler]);

  return (
    <div className={className}>
      <Toolbar
        editor={editor}
        editable={editable}
        additionalToolbarContent={additionalToolbarContent}
        uploadOptions={uploadOptions}
        transformImageSrc={transformImageSrc}
      />
      <StyledEditor compact={compact}>
        <EditorContent editor={editor} />
      </StyledEditor>
    </div>
  );
}

export interface RichEditorInCardProps extends RichEditorProps {
  /** e.g., 200px, the height of scrollable area for the Card */
  height?: string;
}

/**
 * A WYSIWYG editor component, in a scrollable Card container
 * Good for short text embedded in complex UIs
 *
 * Use RichEditor for a more "full-screen" experiences with long text
 *
 * @returns The editor
 */
export const RichEditorInCard: React.FC<RichEditorInCardProps> = ({
  height,
  ...props
}) => (
  <StyledEditorCardWrapper
    padding="0"
    style={{ height }}
    compact={props.compact}
  >
    <RichEditor {...props} />
  </StyledEditorCardWrapper>
);
