import {
  DescriptionWrapper,
  LabelWrapper,
  requestActionMessages,
  SelectWrapper,
  SettingsItem,
  SettingsWrapper,
  TitleWrapper,
} from '@main/ad-core-components';
import { ReactSelect, Skeleton } from '@main/core-ui';
import { RequestAction } from '@transcend-io/privacy-types';
import React from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import { requestsProcessedDisclosureStatsSettingsSlice } from '../slice';
import { requestsProcessedStatsMessages } from './messages';
import { selectRequestsProcessedDisclosureStatsInput } from './selectors';

/**
 * Change the action types that are excluded from the table
 */
export const SelectDisabledActionTypes: React.FC = () => {
  const { formatMessage } = useIntl();
  const requestsProcessedDisclosureStatsSettings = useSelector(
    selectRequestsProcessedDisclosureStatsInput,
  );
  const dispatch = useDispatch();

  if (!requestsProcessedDisclosureStatsSettings) {
    return <Skeleton />;
  }
  return (
    <SettingsWrapper>
      <SettingsItem>
        <LabelWrapper>
          <TitleWrapper>
            {formatMessage(
              requestsProcessedStatsMessages.selectDisabledActions,
            )}
          </TitleWrapper>
          <DescriptionWrapper>
            {formatMessage(
              requestsProcessedStatsMessages.selectDisabledActionsDescription,
            )}
          </DescriptionWrapper>
        </LabelWrapper>
        <SelectWrapper>
          <ReactSelect
            isMulti
            value={requestsProcessedDisclosureStatsSettings.disabledActions.map(
              (action) => ({
                value: action,
                label: formatMessage(requestActionMessages[action]),
              }),
            )}
            onChange={(actions) => {
              dispatch(
                requestsProcessedDisclosureStatsSettingsSlice.actions.setRequestsProcessedDisclosureStatsSettings(
                  { disabledActions: actions.map(({ value }) => value) },
                ),
              );
            }}
            options={Object.values(RequestAction).map((action) => ({
              value: action,
              label: formatMessage(requestActionMessages[action]),
            }))}
          />
        </SelectWrapper>
      </SettingsItem>
    </SettingsWrapper>
  );
};
