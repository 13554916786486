import React from 'react';
import { useTheme } from 'styled-components';

/**
 * Collapse icon
 */
export const Collapse: React.FC = (props) => {
  const theme = useTheme();
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <g
        id="CollapseIcon"
        stroke={theme.colors.accentOnSidebar}
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <path
          d="M12 9.929L16.571 14.5 18.071 13 12 6.929 5.929 13 7.429 14.5z"
          fill={theme.colors.accentOnSidebar}
          fillRule="nonzero"
        />
      </g>
    </svg>
  );
};
