import { Icon } from '@main/core-ui';
import React from 'react';
import { useIntl } from 'react-intl';

import { Button } from '../Button';
import { uploadCsvMessages } from './messages';

/**
 * Button to reset the experience and re-upload from scratch
 */
export const ResetUploadedRows: React.FC<{
  /** callback function for when table reset occurs */
  onReset: () => void;
}> = ({ onReset }) => {
  const { formatMessage } = useIntl();

  return (
    <Button
      variant="danger"
      icon={<Icon type="retry" />}
      onClick={() => {
        onReset();
      }}
    >
      {formatMessage(uploadCsvMessages.reset)}
    </Button>
  );
};
