import React from 'react';
import { CellProps, Column } from 'react-table';

import { ColumnHeader } from './ColumnHeader';
import { RowCell } from './RowCell';
import { RowWithUploadStatus } from './types';

/**
 * Creates a column for a csv table that has a dynamic number of columns
 */
export function defineDynamicColumn<T extends {}>(
  name: string,
  columnHeaders: string[],
  hasInvalidCell: boolean,
  maxRowHeight?: number,
): Column<T> {
  const id = `${name}-dynamic`;
  return {
    id,
    accessor: (row: T) => row[name],
    Header: () => (
      <ColumnHeader
        columns={columnHeaders}
        name={name}
        hasInvalidCell={hasInvalidCell}
      />
    ),
    Cell: ({ value, row }: CellProps<RowWithUploadStatus<T>, string>) => (
      <RowCell
        value={value}
        isValid={row.original.columnIsValidMap[name]}
        maxRowHeight={maxRowHeight}
      />
    ),
    minWidth: 260,
  };
}
