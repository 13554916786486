import omit from 'lodash/omit';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ApolloError, buildUseQuery } from '@main/core-ui';
import {
  endpoints,
  RequestsProcessedDisclosureStatsSettings,
} from '@main/pc-types';
import { ID } from '@main/schema-utils';

import { requestsProcessedDisclosureStatsSettingsSlice } from '../slice';
import { useParsedPrivacyCenter } from '../useParsedPrivacyCenter';
import { selectRequestsProcessedDisclosureStatsSettings } from './selectors';

const useRequestsProcessedDisclosureStatsSettings = buildUseQuery(
  endpoints.requestsProcessedDisclosureStatsSettings,
);

/**
 * Fetch the requests processed stats settings
 *
 * @returns Data, loading, error
 */
export function useRequestsProcessedStatsSettings(): {
  /** The requests processed stats settings */
  requestsProcessedStatsSettings?: RequestsProcessedDisclosureStatsSettings;
  /** Loading */
  loading: boolean;
  /** Error */
  error?: ApolloError;
} {
  const dispatch = useDispatch();
  const { privacyCenter } = useParsedPrivacyCenter();

  const { data, loading, error } = useRequestsProcessedDisclosureStatsSettings({
    fetchPolicy: 'cache-and-network',
    // Don't fetch the data if the ID is not present
    skip: !privacyCenter?.id,
    variables: {
      input: {
        privacyCenterId: privacyCenter?.id as ID<'privacyCenter'>,
      },
    },
  });

  useEffect(() => {
    if (data) {
      // Remove __typename or graphql will complain
      const formattedData = {
        ...data,
        valueOverrides: data.valueOverrides.map((override) =>
          omit(override, ['__typename']),
        ),
      } as typeof data;

      dispatch(
        requestsProcessedDisclosureStatsSettingsSlice.actions.setInitialRequestsProcessedDisclosureStatsSettings(
          formattedData,
        ),
      );
    }
  }, [data]);

  return {
    requestsProcessedStatsSettings: useSelector(
      selectRequestsProcessedDisclosureStatsSettings,
    ),
    loading,
    error,
  };
}
