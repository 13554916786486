import { Badge, Icon } from '@main/core-ui';
import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';

import { ConditionalContainer } from '../ConditionalContainer';
import { useDebounce } from '../hooks/useDebounce';
import { InlineTextInput } from '../InlineTextInput';
import { FilterManagerProps } from '.';
import { FilterPill } from './FilterPill';
import { filterManagerMessages } from './messages';

export interface DefaultFilterSearchProps<T>
  extends Pick<
    FilterManagerProps<T>,
    'onChange' | 'clearFilter' | 'defaultSearchKey' | 'placeholder'
  > {
  /** The key to use for the search text */
  defaultSearchKey: Extract<keyof T, string>;
  /** The current search value */
  value?: string;
  /** Focus event handler */
  onFocus?: React.FocusEventHandler<HTMLInputElement>;
  /** Function to set the menu to open/closed */
  setMenuIsOpen: (isOpen: boolean) => void;
}
export const DefaultFilterSearch = <T extends Record<string, unknown>>({
  clearFilter,
  defaultSearchKey,
  onChange,
  placeholder,
  setMenuIsOpen,
  value,
}: DefaultFilterSearchProps<T>): JSX.Element => {
  const [localValue, setLocalValue] = useState(value);
  const [searching, setIsSearching] = useState(false);
  // Default to pill if value is populated
  const [showPill, setShowPill] = useState(!!value);

  const { formatMessage } = useIntl();

  const inputRef = useRef<HTMLInputElement>(null);

  // keep track of the last value set on debounce to distinguish external changes to filters from local ones
  const lastValueSetRef = useRef<string>(value || '');

  // use a large debounce to avoid overlapping api calls
  const debouncedOnChange = useDebounce(
    (newValue: string) => {
      lastValueSetRef.current = newValue; // have to set lastSetRef before changing the parent value
      onChange?.(defaultSearchKey, newValue as T[keyof T]);

      setIsSearching(false);
      // Maintain focus as element switches to being wrapped in pill
      inputRef.current?.focus();
    },
    550,
    { trailing: true },
    [onChange],
  );

  const onChangeWithDebounce = ({
    target,
  }: ChangeEvent<{
    /** The new value */
    value: string;
  }>): void => {
    // set locally
    setLocalValue(target.value);
    setIsSearching(true);
    setMenuIsOpen(false);
    debouncedOnChange(target.value);
  };

  // Sets pill after debounce; setting in debounce uses old value or updates too often
  useEffect(() => {
    setShowPill(!!value);
  }, [showPill, value]);

  // Updates local value if text filter is updated in parent (i.e. changed by an external change to the query params)
  useEffect(() => {
    if (value !== lastValueSetRef.current) {
      setLocalValue(value || '');
      lastValueSetRef.current = value || '';
    }
  }, [value]);

  return (
    <ConditionalContainer
      condition={showPill}
      container={(children) => (
        <FilterPill
          label={<Icon type="search" />}
          onDismiss={(e) => {
            e.stopPropagation();
            setLocalValue('');
            setShowPill(false);
            clearFilter(defaultSearchKey);
          }}
        >
          <Badge color="gray1">{children}</Badge>
        </FilterPill>
      )}
    >
      <InlineTextInput
        ref={inputRef}
        name="search"
        style={{
          background: 'none',
          border: 0,
          boxShadow: showPill ? undefined : 'none',
          padding: showPill ? ' 2px 8px' : 0,
          margin: showPill ? '-2px -8px' : 0,
          minWidth: 20,
          width: !placeholder && !showPill && !localValue ? 25 : 'auto',
        }}
        onBlur={() => setShowPill(!!localValue)}
        aria-label={formatMessage(filterManagerMessages.searchLabel)}
        placeholder={showPill || !placeholder ? '' : formatMessage(placeholder)}
        aria-busy={searching ? 'true' : 'false'}
        onChange={onChangeWithDebounce}
        value={localValue}
        onClick={(e: React.MouseEvent<HTMLInputElement>) =>
          // prevent menu from opening when the pill is visible
          showPill ? e.stopPropagation() : undefined
        }
      />
    </ConditionalContainer>
  );
};
