import { Skeleton } from '@main/core-ui';
import React from 'react';

import { UpdatePrivacyCenter } from '../Update';
import { RequestsProcessedOverridesTable } from './RequestsProcessedOverridesTable/RequestsProcessedOverridesTable';
import { SelectDisabledActionTypes } from './SelectDisabledActionTypes';
import { ToggleShowRequestsProcessedStats } from './ToggleShowRequestsProcessedStats';
import { useRequestsProcessedStatsSettings } from './useRequestsProcessedStatsSettings';

export const RequestsProcessedStats: React.FC = () => {
  const { requestsProcessedStatsSettings } =
    useRequestsProcessedStatsSettings();

  if (!requestsProcessedStatsSettings) {
    return (
      <>
        <Skeleton />
        <Skeleton />
        <Skeleton />
      </>
    );
  }

  return (
    <>
      <UpdatePrivacyCenter />
      <ToggleShowRequestsProcessedStats />
      <SelectDisabledActionTypes />
      <RequestsProcessedOverridesTable />
    </>
  );
};

export default RequestsProcessedStats;
