import styled from 'styled-components';

import { responsive } from '@main/theme';

const Z_INDEX = 1000;

export const DisabledBackgroundWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 250px; // need to match sidemenu
  right: 0;
  bottom: 0;
  z-index: ${Z_INDEX};
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.white};
  opacity: 0.5;
`;

export const CTABannerWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
`;

export const CTABannerContent = styled.div`
  z-index: ${Z_INDEX + 1};
  position: fixed;
  bottom: -200px;
  max-width: 855px;
  padding: 20px 28px;
  margin: 40px;
  border-radius: 12px;
  background-color: ${({ theme }) => theme.colors.yellow1};
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: bottom 0.25s ease-in-out;

  ${responsive.atLeastDesktop} {
    width: 100%;
  }

  ${responsive.beforeDesktop} {
    flex-direction: column;
  }
`;

export const TitleWrapper = styled.div`
  text-align: center;
  color: ${({ theme }) => theme.colors.black};
  font-size: 18px;
  font-weight: 600;

  ${responsive.atLeastDesktop} {
    width: 100%;
  }

  ${responsive.beforeDesktop} {
    font-size: 16px;
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${responsive.atLeastDesktop} {
    margin-left: 50px;
  }

  ${responsive.beforeDesktop} {
    margin-top: 20px;
  }
`;
