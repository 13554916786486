import { statusDescriptionMessages, statusTitleMessages } from './messages';
import type { RequestStatusConfig } from './types';

/**
 * When the secondary action has completed compilation across all data systems,
 * the secondary action is typically irreversible
 *
 * TODO: https://transcend.height.app/T-2562 - remove erasure + access request
 */
export const SECONDARY_COMPLETED_STATUS: RequestStatusConfig = {
  color: 'success',
  icon: 'file-checkmark',
  isClosed: true,
  isSuccess: true,
  canArchive: true,
  messages: {
    description: statusDescriptionMessages.SECONDARY_COMPLETED,
    title: statusTitleMessages.SECONDARY_COMPLETED,
  },
  secondaryOnly: true,
  transitions: [],
};
