import Form from 'react-bootstrap/Form';
import styled from 'styled-components';

export const StyledFormControl = styled(Form.Control)`
  background-color: transparent;
  border: 1px solid transparent;
  transition: border-color 0.3s ease-in-out;
  font-size: 14px;
  ${({ theme, $showOutline }) =>
    $showOutline ? `border: 1px solid ${theme.colors.gray3}` : ''};

  &:hover:not(.is-invalid):not(.is-valid) {
    border: ${({ theme }) => `1px solid ${theme.colors.gray3}`};
    background: ${({ theme }) => theme.colors.white};
  }

  &:disabled {
    background: ${({ theme }) => theme.colors.white};
  }
`;
