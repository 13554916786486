import styled from 'styled-components';

import { AdminDashboardPublicOrPrivatePath } from '@main/access-control-types';
import InvalidateUserEmail from '@main/admin-dashboard/src/Auth/InvalidateUserEmail/Loadable';
import { LoginFailure } from '@main/admin-dashboard/src/Auth/Login/Loadable';
import LoginEmail from '@main/admin-dashboard/src/Auth/LoginEmail/Loadable';
import SamlLoginRedirect from '@main/admin-dashboard/src/Auth/SamlLoginRedirect/Loadable';
import { BulkRequestCompletionLoggedOut } from '@main/admin-dashboard/src/PrivacyRequests/BulkRequestCompletion/Loadable';
import type { ExtendableRoute } from '@main/admin-dashboard/src/routes';

import {
  AssessmentFormLoggedOutLoadable,
  CreateAssessmentFormLoggedOutLoadable,
} from '../../Assessments/AssessmentForm/Loadable';

export const Div = styled.div``;

export const miscBasicRoutes: {
  [k in AdminDashboardPublicOrPrivatePath]: ExtendableRoute;
} = {
  [AdminDashboardPublicOrPrivatePath.AssessmentForm]: {
    path: AdminDashboardPublicOrPrivatePath.AssessmentForm,
    component: AssessmentFormLoggedOutLoadable,
    wrapper: Div,
  },
  [AdminDashboardPublicOrPrivatePath.CreateAssessmentForm]: {
    path: AdminDashboardPublicOrPrivatePath.CreateAssessmentForm,
    component: CreateAssessmentFormLoggedOutLoadable,
    wrapper: Div,
  },
  [AdminDashboardPublicOrPrivatePath.BulkRequests]: {
    path: AdminDashboardPublicOrPrivatePath.BulkRequests,
    component: BulkRequestCompletionLoggedOut,
    wrapper: Div,
  },
  [AdminDashboardPublicOrPrivatePath.LoginFailure]: {
    path: AdminDashboardPublicOrPrivatePath.LoginFailure,
    component: LoginFailure,
    wrapper: Div,
  },
  [AdminDashboardPublicOrPrivatePath.SamlLoginRedirect]: {
    path: AdminDashboardPublicOrPrivatePath.SamlLoginRedirect,
    component: SamlLoginRedirect,
    wrapper: Div,
  },
  [AdminDashboardPublicOrPrivatePath.InvalidateUserEmail]: {
    path: AdminDashboardPublicOrPrivatePath.InvalidateUserEmail,
    component: InvalidateUserEmail,
    wrapper: Div,
  },
  [AdminDashboardPublicOrPrivatePath.Login]: {
    path: AdminDashboardPublicOrPrivatePath.Login,
    component: LoginEmail,
    wrapper: Div,
  },
};
