import { createLoadable } from '@main/core-ui';

import { generalSettingsMessages } from './messages';

// Load the component dynamically
export default createLoadable({
  loader: () => import('./index'),
  messages: generalSettingsMessages,
  logo: 'setting',
});
