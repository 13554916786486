import {
  extractFormItemWrapperProps,
  FormItemWrapper,
  IFormFieldProps,
  IFormItemWrapperProps,
  IWithClassName,
} from '@main/core-ui';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { Switch, SwitchProps } from './Switch';

export interface HtmlIdProp {
  /** the html id for the component */
  id?: string;
}

/**
 * A raw form switch for use cases where formatting is not desired
 */
export const FormSwitchRaw: React.FC<
  IWithClassName & IFormFieldProps & SwitchProps & HtmlIdProp
> = ({ name, defaultValue, ...antSwitchProps }) => {
  const { control } = useFormContext();
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ field: { onChange, value, name } }) => (
        // @deprecated TODO: https://transcend.height.app/T-19886 - remove ant
        <Switch
          onChange={onChange}
          name={name}
          {...antSwitchProps}
          checked={value}
        />
      )}
    />
  );
};

/**
 * A form switch with formatting and error display
 */
export const FormSwitch: React.FC<
  IWithClassName &
    IFormFieldProps &
    SwitchProps &
    Omit<IFormItemWrapperProps, 'errors'> &
    HtmlIdProp
> = (props) => {
  const {
    formState: { errors },
  } = useFormContext();
  const { passthroughProps, wrapperProps } = extractFormItemWrapperProps(props);
  return (
    <FormItemWrapper errors={errors} {...wrapperProps} isFullWidth={false}>
      <FormSwitchRaw {...passthroughProps} />
    </FormItemWrapper>
  );
};
