import { FlexColumn } from '@main/core-ui';
import moment from 'moment';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { MessageDescriptor } from 'react-intl';

import type { FilterConfigItem } from '../FilterManager';
import { DATE_FORMAT } from './constants';
import { DateRange, DateRangePicker } from './DateRangePicker';

/**
 * Filters definition with property for created at before and after specified
 */
type DateRangeEnrichedFilters<K extends string> = Record<string, unknown> &
  /** Requests created between a range */
  Record<K, DateRange | undefined>;

/**
 * Hook to get the filter configuration for request created at date filter
 *
 * @returns the config for the filters and a function to clear the filters
 */
type DateRangeFiltersBuilder<
  K extends string,
  T extends Record<string, unknown>,
  E extends DateRangeEnrichedFilters<string>,
> = (filterParams: {
  /** name for the property that filters on before the created at date */
  dateRangeStartFilterKey?: keyof T;
  /** name for the property that filters on after the created at date */
  dateRangeEndFilterKey?: keyof T;
  /** The currently applied filters */
  filters?: T;
  /** Callback when the filters are changed */
  setFilters: (filters: T) => void;
  /** Label for the filter menu */
  label: string | MessageDescriptor;
  /** The key to use for enriched filters FilterManager */
  enrichedDateRangeKey: K;
}) => {
  /** The date range currently filtered on */
  dateRange: DateRange | undefined;
  /** The filter configuration to be passed to the filter manager */
  dateRangeFiltersConfig: FilterConfigItem<E>;
  /** Callback for when the filter is cleared in the filter manager */
  clearDateRangeFilters: (key: Extract<keyof E, string>) => void;
};

/**
 *
 * Builds elements of the filters config needed for filtering by requests created at before and after dates
 *
 * @param filterParams - the currently applied filters and a function to set the filters
 * @returns Elements of the filters config needed for filtering by created at dates
 */
export const useDateRangeFilters: DateRangeFiltersBuilder<
  string,
  Record<string, unknown>,
  DateRangeEnrichedFilters<string>
> = ({
  dateRangeStartFilterKey = 'createdAtBefore',
  dateRangeEndFilterKey = 'createdAtAfter',
  label,
  filters,
  setFilters,
  enrichedDateRangeKey,
}) => {
  const [dateRange, setDateRange] = useState<DateRange | undefined>(undefined);

  // Populate the initial filter values
  useEffect(() => {
    if (
      filters &&
      filters[dateRangeEndFilterKey] &&
      filters[dateRangeStartFilterKey]
    ) {
      const startDate = moment(
        filters[dateRangeEndFilterKey] as string,
      ).toDate();
      const endDate = moment(
        filters[dateRangeStartFilterKey] as string,
      ).toDate();

      setDateRange({
        from: startDate,
        to: endDate,
      });
    }
  }, []);

  const dateRangeFiltersConfig: FilterConfigItem<
    DateRangeEnrichedFilters<string>
  > = useMemo(
    () => ({
      filterKey: enrichedDateRangeKey,
      label,
      pillOptions: {
        label: ({
          filterValues: { [enrichedDateRangeKey]: dateRange = undefined },
        }) => {
          const formattedFrom =
            dateRange?.from && moment(dateRange.from).format(DATE_FORMAT);
          const formattedTo =
            dateRange?.to && moment(dateRange.to).format(DATE_FORMAT);

          const formatted =
            formattedFrom && formattedTo
              ? `${formattedFrom} - ${formattedTo}`
              : 'Invalid date range';

          return <>{formatted}</>;
        },
      },
      filter: (
        <FlexColumn>
          <DateRangePicker
            value={dateRange}
            onChange={(dateRange) => {
              setDateRange(dateRange);
              setFilters({
                ...filters,
                [dateRangeEndFilterKey]: dateRange?.from
                  ? dateRange?.from?.toISOString()
                  : undefined,
                [dateRangeStartFilterKey]: dateRange?.to
                  ? dateRange?.to?.toISOString()
                  : undefined,
              });
            }}
          />
        </FlexColumn>
      ),
    }),
    [dateRangeStartFilterKey, dateRangeEndFilterKey, filters, label, dateRange],
  );

  const clearDateRangeFilters = useCallback(
    (key) => {
      if (key === enrichedDateRangeKey) {
        setFilters({
          ...filters,
          [dateRangeStartFilterKey]: undefined,
          [dateRangeEndFilterKey]: undefined,
        });
        setDateRange(undefined);
      }
    },
    [dateRange, filters],
  );

  return {
    dateRange,
    dateRangeFiltersConfig,
    clearDateRangeFilters,
  };
};
