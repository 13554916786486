import { PaginatedSelect } from '@main/ad-core-components';
import { AssessmentSecondaryType, endpoints } from '@main/attribute-types';
import {
  Alert,
  buildUseInfiniteScroll,
  buildUseLazyQuery,
  Icon,
  SelectTagWrapper,
} from '@main/core-ui';
import { createNewId, ID } from '@main/schema-utils';
import { AttributeKeyType } from '@transcend-io/privacy-types';
import groupBy from 'lodash/groupBy';
import isEqual from 'lodash/isEqual';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { MenuPosition, OptionsType } from 'react-select';
import { useTheme } from 'styled-components';

import { ATTRIBUTE_VALUE_MAP, AttributeKeyPreview } from './constants';
import { selectAttributeMessages } from './messages';
import { SelectAttributePillDisplay } from './SelectAttributePillDisplay';
import {
  isCustomOption,
  SelectAttributeDefaultOption,
  SelectAttributeOption,
} from './types';

const useAttributeValues = buildUseInfiniteScroll(endpoints.attributeValues);
const useAttributeValuesLazy = buildUseLazyQuery(endpoints.attributeValues);

/** Props for the `SelectAttribute` component */
export interface SelectAttributeProps {
  /** The selected attribute values for this resource */
  values: SelectAttributeDefaultOption[] | ID<'attributeValue'>[];
  /**
   * The attribute key the attribute values are being selected from.
   * Can provide undefined if all values are provided but no attributeKey is available
   */
  attributeKey: AttributeKeyPreview | undefined;
  /** Whether the input is disabled */
  disabled?: boolean;
  /** Whether to support multiple selection */
  isMulti?: boolean;
  /** Whether the input is being updated/loading */
  loading?: boolean;
  /** How to position the menu (see docs for React Select) */
  menuPosition?: MenuPosition;
  /** Callback to fire when the values are updated */
  onUpdate?: (
    values: SelectAttributeDefaultOption[],
    previouslySelected?: SelectAttributeDefaultOption[],
  ) => void;
  /** Callback to fire on blur */
  onBlur?: (selected: SelectAttributeDefaultOption[]) => void;
  /** whether or not to show the outline on the select */
  showOutline?: boolean;
  /** whether to disable the select when no attributes exist for the specified key */
  disableCreate?: boolean;
}

const hasNoCustomValues = (
  values: SelectAttributeOption[],
): values is SelectAttributeDefaultOption[] =>
  values &&
  Array.isArray(values) &&
  values.every((value) => !isCustomOption(value));

export const SelectAttribute: React.FC<SelectAttributeProps> = ({
  values: initialValues,
  attributeKey,
  disabled = false,
  menuPosition = 'fixed',
  isMulti = false,
  loading,
  onUpdate,
  onBlur: _onBlur,
  showOutline,
  disableCreate,
}) => {
  const isAssessmentAttribute = useMemo(
    () => attributeKey?.type === AttributeKeyType.Assessment,
    [attributeKey],
  );

  const theme = useTheme();
  const { formatMessage } = useIntl();
  const [values, setValues] = useState<SelectAttributeOption[]>([]);
  const [changed, setChanged] = useState(false);
  const [text, setText] = useState<string | undefined>();
  const fetchAttributeValues = useAttributeValuesLazy();

  useEffect(() => {
    if (
      initialValues.length > 0 &&
      (
        initialValues as (SelectAttributeDefaultOption | ID<'attributeValue'>)[]
      ).every((value) => typeof value === 'string')
    ) {
      fetchAttributeValues({
        filterBy: { ids: initialValues as ID<'attributeValue'>[] },
        first: 25,
      }).then(({ data: { nodes } }) => setValues(nodes));
    } else {
      setValues(initialValues as SelectAttributeOption[]);
    }
  }, [initialValues]);

  const {
    data,
    loading: attributeValuesLoading,
    fetchMore,
    error,
  } = useAttributeValues({
    variables: {
      filterBy: {
        attributeKeys: attributeKey ? [attributeKey.id] : [],
        ...(text ? { text } : {}),
      },
    },
    skip: disabled && !!attributeKey,
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
  });

  const options = useMemo(() => {
    if (!data) {
      return [];
    }

    if (isAssessmentAttribute) {
      const groupedNodes = groupBy(data.nodes, 'assessmentSecondaryType');

      const statuses = groupedNodes[AssessmentSecondaryType.Status] || [];
      const assessments =
        groupedNodes[AssessmentSecondaryType.Assessment] || [];

      const linkOption = values.some(
        (option) =>
          !isCustomOption(option) &&
          option.assessmentSecondaryType === AssessmentSecondaryType.Url,
      )
        ? []
        : [
            {
              type: 'custom',
              attributeKey,
              id: 'url',
              name: formatMessage(
                selectAttributeMessages.assessmentUrlOptionName,
                {
                  templateTitle: attributeKey?.assessmentFormTemplate?.title,
                },
              ),
              logo: (
                <Icon type="link-assessment" color={theme.colors.transcend} />
              ),
            },
          ];
      // enforce strict ordering: status, link, assessment
      return [
        ...statuses.sort((a, b) => a.name.localeCompare(b.name)),
        ...linkOption,
        ...assessments.sort((a, b) => a.name.localeCompare(b.name)),
      ] as SelectAttributeOption[];
    }

    return data.nodes as SelectAttributeDefaultOption[];
  }, [
    attributeKey,
    data,
    formatMessage,
    isAssessmentAttribute,
    theme.colors.transcend,
    values,
  ]);

  const getNewOptionData = useCallback(
    (inputValue: string) =>
      ({
        name: inputValue,
        id: createNewId<'attributeValue'>(),
        color: undefined,
        canDelete: true,
        attributeKey: {
          id: attributeKey?.id,
          name: attributeKey?.name,
        },
      }) as SelectAttributeDefaultOption,
    [attributeKey],
  );

  const onChange = useCallback(
    (
      selected:
        | SelectAttributeOption
        | OptionsType<SelectAttributeOption>
        | null,
    ) => {
      const hasNewValues =
        isMulti && Array.isArray(selected) && Array.isArray(values)
          ? !isEqual(
              selected.map((option) => option.name),
              values.map((value) => value.name),
            )
          : !isEqual(selected, values);
      setChanged(hasNewValues);
      const newValues = isMulti
        ? (selected as SelectAttributeOption[])
        : selected === null || typeof selected === 'undefined'
          ? []
          : ([selected] as SelectAttributeOption[]);
      setValues(newValues);
      if (
        onUpdate &&
        hasNewValues &&
        hasNoCustomValues(newValues) &&
        hasNoCustomValues(values)
      ) {
        onUpdate(newValues, values);
      }
    },
    [isMulti, onUpdate, values],
  );

  const onBlur = useCallback(() => {
    if (_onBlur && changed && hasNoCustomValues(values)) {
      _onBlur(values);
    }
  }, [_onBlur, changed, values]);

  return (
    <SelectTagWrapper className="select-attribute">
      <PaginatedSelect
        overflow="badge"
        options={options}
        getOptionValue={({ id }: SelectAttributeOption) => id}
        getOptionLabel={({ name }: SelectAttributeOption) => name}
        isMulti={isMulti}
        isCreatable={!disableCreate}
        showOutline={!!showOutline}
        isClearable={!isMulti && !isAssessmentAttribute}
        onEndsTyping={setText}
        isDisabled={disabled || (options.length === 0 && disableCreate)}
        fetchMore={fetchMore}
        isQueryLoading={loading || attributeValuesLoading}
        queryError={error}
        menuPosition={menuPosition}
        getNewOptionData={getNewOptionData}
        onChange={onChange}
        onBlur={onBlur}
        placeholderDescriptor={
          disabled
            ? selectAttributeMessages.none
            : selectAttributeMessages.placeholder
        }
        value={values}
        formatOptionPill={(option, { context }) => (
          <SelectAttributePillDisplay
            attributeKey={attributeKey}
            option={option}
            values={values}
            getNewOptionData={getNewOptionData}
            isInMenu={context === 'menu'}
            onChange={onChange}
            onBlur={onBlur}
          />
        )}
        colorMap={ATTRIBUTE_VALUE_MAP}
      />
      {disableCreate && attributeKey && options.length === 0 && (
        <Alert variant="info" description={selectAttributeMessages.noOptions} />
      )}
    </SelectTagWrapper>
  );
};
