import { Extension, Node } from '@tiptap/core';
import Bold from '@tiptap/extension-bold';
import BulletList from '@tiptap/extension-bullet-list';
import Document from '@tiptap/extension-document';
import Dropcursor from '@tiptap/extension-dropcursor';
import Gapcursor from '@tiptap/extension-gapcursor';
import HardBreak from '@tiptap/extension-hard-break';
import Heading from '@tiptap/extension-heading';
import History from '@tiptap/extension-history';
import { ImageOptions } from '@tiptap/extension-image';
import Italic from '@tiptap/extension-italic';
import Link from '@tiptap/extension-link';
import ListItem from '@tiptap/extension-list-item';
import OrderedList from '@tiptap/extension-ordered-list';
import Paragraph from '@tiptap/extension-paragraph';
import Placeholder from '@tiptap/extension-placeholder';
import Strike from '@tiptap/extension-strike';
import Text from '@tiptap/extension-text';
import Typography from '@tiptap/extension-typography';
import Underline from '@tiptap/extension-underline';
import sanitizeHtmlLib from 'sanitize-html';

import { Optionalize } from '@transcend-io/type-utils';

import { HEADING_LEVELS } from '../constants';
import { RichEditorExtension } from './enums';
import Image from './Image';

const CustomTypography = Typography.configure({
  openDoubleQuote: false,
  closeDoubleQuote: false,
  openSingleQuote: false,
  closeSingleQuote: false,
});

/**
 * Default RichEditor Extensions that are always handy
 */
export const DEFAULT_RICH_EDITOR_EXTENSIONS = [
  Document,
  Text,
  History,
  CustomTypography,
  Paragraph, // seems like you get an error if this is not included
  HardBreak, // makes sense to me to put this anywhere paragraphs are allowed
];
/**
 * RichEditor extensions for styling text content
 */
export const TEXT_STYLING_RICH_EDITOR_EXTENSIONS = [
  Bold,
  Italic,
  Strike,
  CustomTypography,
  Placeholder,
  Underline,
];

/**
 * All RichEditor extensions for text-based content
 */
export const ALL_TEXT_RICH_EDITOR_EXTENSIONS = [
  ...TEXT_STYLING_RICH_EDITOR_EXTENSIONS,
  CustomTypography,
  Placeholder,
  ListItem,
  OrderedList,
  BulletList,
  Heading.configure({
    levels: HEADING_LEVELS,
  }),
  Link.configure({
    protocols: ['mailto'],
    openOnClick: false,
  }),
];

/**
 * All RichEditor extensions for text-based content except headers
 */
export const ALL_TEXT_NO_HEADERS_RICH_EDITOR_EXTENSIONS = [
  ...TEXT_STYLING_RICH_EDITOR_EXTENSIONS,
  CustomTypography,
  Placeholder,
  ListItem,
  OrderedList,
  BulletList,
  Link.configure({
    protocols: ['mailto'],
    openOnClick: false,
  }),
];

export interface CustomImageOptions
  extends Optionalize<
    ImageOptions,
    'inline' | 'allowBase64' | 'HTMLAttributes'
  > {
  /** Callback to transform the viewed src of an image (e.g. to use presigned urls) */
  transformSrc?: (src: string) => Promise<string>;
}

// RichEditor image support extensions
export const IMAGE_RICH_EDITOR_EXTENSIONS = (
  imageOptions?: CustomImageOptions,
): (Extension | Node)[] => [
  imageOptions
    ? Image.configure({
        // allow linked images
        inline: true,
        // These are the defaults, which Tiptap types want to be specified
        allowBase64: false,
        HTMLAttributes: {},
        ...imageOptions,
      })
    : Image,
  Dropcursor,
  Gapcursor,
];

export const SANITIZE_OPTIONS_FOR_EXTENSION: Record<
  RichEditorExtension,
  sanitizeHtmlLib.IOptions
> = {
  [RichEditorExtension.TextStyling]: {
    allowedTags: ['b', 'i', 'em', 'u', 's', '&nbsp', 'strong', 'p', 'br'],
    allowedAttributes: {},
  },
  [RichEditorExtension.Default]: {
    allowedTags: ['&nbsp', 'p', 'br'],
  },
  [RichEditorExtension.AllText]: {
    allowedTags: [
      'b',
      'i',
      'em',
      'u',
      's',
      'br',
      'hr',
      '&nbsp',
      'strong',
      'section',
      'p',
      'li',
      'ul',
      'ol',
      'a',
      'h1',
      'h2',
      'h3',
      'h4',
      'h5',
      'h6',
    ],
    allowedAttributes: {
      a: ['href', 'target', 'rel'],
      h1: ['id', 'class'],
      h2: ['id', 'class'],
      h3: ['id', 'class'],
    },
  },
  [RichEditorExtension.AllTextNoHeaders]: {
    allowedTags: [
      'b',
      'i',
      'em',
      'u',
      's',
      'br',
      'hr',
      '&nbsp',
      'strong',
      'section',
      'p',
      'li',
      'ul',
      'ol',
      'a',
    ],
    allowedAttributes: {
      a: ['href', 'target', 'rel'],
    },
  },
  [RichEditorExtension.Image]: {
    allowedTags: ['img'],
    allowedAttributes: {
      img: ['src', 'alt'],
    },
  },
  [RichEditorExtension.Table]: {
    allowedTags: ['table', 'tbody', 'td', 'tr', 'table', 'th'],
    allowedAttributes: {
      table: ['border', 'cellpadding', 'cellspacing', 'class'],
      th: ['align', 'colspan', 'rowspan', 'colwidth', 'style'],
      td: ['align', 'colspan', 'rowspan', 'colwidth', 'style'],
    },
  },
};
