import * as t from 'io-ts';

import { dbModelId } from '@main/schema-utils';

import { AuditEventCode } from '../schema/enums';
import {
  mkAuditEventCodec,
  mkAuditEventJoinCodec,
  mkAuditJoinStateCodec,
} from '../types';

export const AuditEventBusinessEntity = mkAuditEventCodec(
  AuditEventCode.BusinessEntity,
  t.type({
    /** ID of the business entity */
    businessEntityId: dbModelId('businessEntity'),
  }),
);

/** Override types. */
export type AuditEventBusinessEntity = t.TypeOf<
  typeof AuditEventBusinessEntity
>;

export const AuditEventBusinessEntityAttribute = mkAuditEventJoinCodec(
  AuditEventCode.BusinessEntityAttribute,
  mkAuditJoinStateCodec(
    'attributeValue',
    t.type({
      /** the display title for the attribute key */
      attributeKeyTitle: t.string,
    }),
  ),
  t.type({
    /** ID of the businessEntity */
    businessEntityId: dbModelId('businessEntity'),
  }),
);

/** Override types. */
export type AuditEventBusinessEntityAttribute = t.TypeOf<
  typeof AuditEventBusinessEntityAttribute
>;

export const AuditEventBusinessEntityTeam = mkAuditEventJoinCodec(
  AuditEventCode.BusinessEntityTeam,
  mkAuditJoinStateCodec('team'),
  t.type({
    /** ID of the Business Entity */
    businessEntityId: dbModelId('businessEntity'),
  }),
);

/** Override types. */
export type AuditEventBusinessEntityTeam = t.TypeOf<
  typeof AuditEventBusinessEntityTeam
>;

export const AuditEventBusinessEntityOwner = mkAuditEventJoinCodec(
  AuditEventCode.BusinessEntityOwner,
  mkAuditJoinStateCodec('user'),
  t.type({
    /** ID of the Business Entity */
    businessEntityId: dbModelId('businessEntity'),
  }),
);

/** Override types. */
export type AuditEventBusinessEntityOwner = t.TypeOf<
  typeof AuditEventBusinessEntityOwner
>;
