import 'react-day-picker/style.css';

import { Popover } from '@main/core-ui';
import moment from 'moment';
import React, { useState } from 'react';
import type {
  DateRange,
  PropsBase,
  PropsRange,
  PropsRangeRequired,
} from 'react-day-picker';

import { Input, InputProps } from '../Input';
import { DATE_FORMAT } from './constants';
import { DayPickerCore } from './DayPickerCore';

export type { DateRange };

export interface DateRangePickerProps
  extends Omit<InputProps, 'value' | 'onChange'> {
  /** The currently-selected date value */
  value: DateRange | undefined;
  /** Handler for when the date value is changed */
  onChange: (dateRange: DateRange | undefined) => void;
  /**
   * React DayPicker props
   *
   * @see https://daypicker.dev/docs/customization
   */
  dayPickerProps?: Omit<
    PropsBase & (PropsRange | PropsRangeRequired),
    'mode' | 'selected' | 'onSelected'
  >;
  /**
   * Force a specific locale for the calendar. Otherwise uses react-intl `useIntl().locale`
   */
  forceLocale?: string;
}

const DEFAULT = {
  from: moment().subtract(1, 'week').toDate(),
  to: new Date(),
} as const;

export const DateRangePicker: React.FC<DateRangePickerProps> = ({
  value,
  onChange,
  dayPickerProps,
  forceLocale,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  ref,
  ...inputProps
}) => {
  // The popover container
  const [containerRef, setContainerRef] = useState<HTMLDivElement | null>(null);

  const isControlled = onChange !== undefined;
  const readInputFormat = DATE_FORMAT;

  // If this is an uncontrolled component, manage state internally
  const [internalValue, setInternalValue] = useState<DateRange | undefined>(
    DEFAULT,
  );

  // React DayPicker's date state, which is either controlled or uncontrolled
  const currentValue = isControlled ? value : internalValue;

  // The input field's string value
  const [fromInputValue, setFromInputValue] = useState<string>(() =>
    currentValue?.from ? moment(currentValue.from).format(readInputFormat) : '',
  );
  // The input field's string value
  const [toInputValue, setToInputValue] = useState<string>(() =>
    currentValue?.to ? moment(currentValue.to).format(readInputFormat) : '',
  );

  /**
   * The DayPicker's currently-selected month. This tracks the 'from' month of the range.
   * By controlling `month` ourselves, the DayPicker opens to the supplied month, rather than the current month.
   */
  const [month, setMonth] = useState<Date>(
    () => currentValue?.from || DEFAULT.from,
  );

  // Function to update the DayPicker's state. State is maintained either internally (uncontrolled) or by the user (controlled).
  const setDate = (date: DateRange | undefined): void => {
    if (isControlled) {
      // User callback
      onChange(date);
    } else {
      // Update the React DayPicker component
      setInternalValue(date);
    }
  };

  // When the DayPicker changes, update the text input field
  const handleDayPickerChange = (
    dateRange: DateRange | undefined,
    bubbles: boolean = true,
  ): void => {
    if (!dateRange) {
      setFromInputValue('');
      setToInputValue('');
      setDate(undefined);
    } else {
      const newFromDate = moment(dateRange.from);
      const newToDate = moment(dateRange.to);

      if (newFromDate.isValid() && newToDate.isValid()) {
        setDate({
          from: newFromDate.toDate(),
          to: newToDate.toDate(),
        });
        if (bubbles) {
          setFromInputValue(newFromDate.format(readInputFormat));
          setToInputValue(newToDate.format(readInputFormat));
        }
        setMonth(newFromDate.toDate());
      }
    }
  };

  // When the text input field changes, update the DayPicker
  const handleFromInputChange = (
    e: React.ChangeEvent<HTMLInputElement>,
  ): void => {
    setFromInputValue(e.target.value); // keep the input value in sync

    const fromMoment = moment(e.target.value);

    if (fromMoment.isValid()) {
      // If the user inputted a 'from' date that is after the 'to' date
      if (currentValue?.to && fromMoment.isAfter(currentValue.to)) {
        const from = fromMoment.toDate();
        // Update the 'to' date to match 'from' date
        const to = from;

        setDate({
          from,
          to,
        });
        setToInputValue(fromMoment.format(readInputFormat));
        setMonth(from);
      } else {
        // Update the date range normally, preserving the existing 'to' date
        const from = fromMoment.toDate();
        const to = currentValue?.to || DEFAULT.to;

        setDate({
          from,
          to,
        });
        setMonth(from);
      }
    } else {
      setDate(undefined);
    }
  };

  // When the text input field changes, update the DayPicker
  const handleToInputChange = (
    e: React.ChangeEvent<HTMLInputElement>,
  ): void => {
    setToInputValue(e.target.value); // keep the input value in sync

    const toMoment = moment(e.target.value);

    if (toMoment.isValid()) {
      // If the user inputted a 'to' date that is before the 'from' date
      if (currentValue?.from && toMoment.isBefore(currentValue.from)) {
        const to = toMoment.toDate();
        // Update the 'from' date to match 'to' date
        const from = to;

        setDate({
          from,
          to,
        });

        setFromInputValue(toMoment.format(readInputFormat));
        setMonth(from);
      } else {
        // Update the date range normally, preserving the existing 'from' date
        const from = currentValue?.from || DEFAULT.from;
        const to = toMoment.toDate();

        setDate({
          from,
          to,
        });
        setMonth(from);
      }
    } else {
      setDate(undefined);
      setMonth(DEFAULT.from);
    }
  };

  return (
    <div>
      <Popover
        contents={
          <div>
            <DayPickerCore
              {...dayPickerProps}
              mode="range"
              month={month}
              onMonthChange={setMonth}
              numberOfMonths={dayPickerProps?.numberOfMonths || 2}
              selected={currentValue}
              onSelect={(dateRange) => handleDayPickerChange(dateRange)}
              forceLocale={forceLocale}
            />
          </div>
        }
        container={containerRef}
        placement="bottom"
      >
        <div style={{ width: 'min-content' }}>
          <div
            className="DateRangePickerInput"
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              width: 'min-content',
              gap: '0.5em',
            }}
          >
            <Input
              {...inputProps}
              style={{ width: '180px' }}
              icon="calendar"
              loading={!containerRef}
              value={fromInputValue}
              onChange={handleFromInputChange}
            />
            –
            <Input
              {...inputProps}
              style={{ width: '180px' }}
              icon="calendar"
              loading={!containerRef}
              value={toInputValue}
              onChange={handleToInputChange}
            />
          </div>
          <div ref={(node) => setContainerRef(node)} />
        </div>
      </Popover>
    </div>
  );
};
