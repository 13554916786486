/**
 * The fields that the promptRuns query can be ordered on
 */
export enum PromptRunOrderField {
  /** The name of the prompt run */
  Name = 'name',
  /** The created date of the prompt run */
  CreatedAt = 'createdAt',
  /** The updated date of the prompt run */
  UpdatedAt = 'updatedAt',
}
