export const CTA_IMAGE_SOURCE = {
  assessment:
    'https://misc-assets-transcend.s3.amazonaws.com/ctas/Assessments.svg',
  consent:
    'https://misc-assets-transcend.s3.amazonaws.com/ctas/Consent+Mgmt.svg',
  contractScanning:
    'https://misc-assets-transcend.s3.amazonaws.com/ctas/Contract+Scanning.svg',
  dataLineage:
    'https://misc-assets-transcend.s3.amazonaws.com/ctas/Data+Lineage.svg',
  dsrAutomation:
    'https://misc-assets-transcend.s3.amazonaws.com/ctas/DSR+Automation.svg',
  pathfinder:
    'https://misc-assets-transcend.s3.amazonaws.com/ctas/Pathfinder.svg',
  preferenceStore:
    'https://misc-assets-transcend.s3.amazonaws.com/ctas/Preference+Store.svg',
  privacyCenter:
    'https://misc-assets-transcend.s3.amazonaws.com/ctas/Privacy+Center.svg',
  promptManagement:
    'https://misc-assets-transcend.s3.amazonaws.com/ctas/Prompt+Manager.svg',
  siloDisco:
    'https://misc-assets-transcend.s3.amazonaws.com/ctas/Silo+Discovery.svg',
  structuredDisco:
    'https://misc-assets-transcend.s3.amazonaws.com/ctas/Structured+Discovery.svg',
  unstructuredDisco:
    'https://misc-assets-transcend.s3.amazonaws.com/ctas/Unstructured+Discovery.svg',
  sombra: 'https://misc-assets-transcend.s3.amazonaws.com/ctas/Sombra.svg',
  webAuditor:
    'https://misc-assets-transcend.s3.amazonaws.com/ctas/Web+Auditor.svg',
};
