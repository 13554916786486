/* eslint-disable max-lines */
import {
  AdminDashboardInfrastructurePath,
  AdminDashboardPrivateMiscPath,
  SOMBRA_PATH_PREFIX,
} from '@main/access-control-types';
import { AdminDashboardAuditorPath } from '@main/auditor-types';
import {
  AdminDashboardCmPath,
  CONSENT_MANAGER_PATH_PREFIX,
  NATIVE_APPS_PATH_PREFIX,
  PreferenceStorePath,
  TCF_PATH_PREFIX,
  USER_PREFERENCE_TOPICS_PATH_PREFIX,
  USER_PREFERENCES_PATH_PREFIX,
} from '@main/cm-types';
import {
  AdminDashboardCodeScanningPath,
  CODE_SCANNING_PATH_PREFIX,
} from '@main/code-scanning-types';
import { AdminDashboardContractScanningPath } from '@main/contract-scanning-types';
import { FeatureFlagKey, IconType } from '@main/core-ui';
import {
  AdminDashboardAssessmentsPath,
  AdminDashboardDataMapPath,
  ASSESSMENTS_PATH_PREFIX,
  CONTENT_CLASSIFICATION_PATH_PREFIX,
  DATA_INVENTORY_PATH_PREFIX,
  SILO_DISCOVERY_PATH_PREFIX,
  UNSTRUCTURED_DISCOVERY_PATH_PREFIX,
} from '@main/datamap-types';
import {
  AdminDashboardPrivacyRequestPath,
  EMAIL_SETTINGS_PATH_PREFIX,
  PRIVACY_REQUESTS_PATH_PREFIX,
} from '@main/dsr-types';
import { AdminDashboardPrivacyCenterPath } from '@main/pc-types';
import {
  AdminDashboardPathfinderPath,
  AdminDashboardPromptsPath,
} from '@main/prompt-types';
import { indexBy } from '@main/utils';
import React from 'react';

import { CTA_IMAGE_SOURCE } from '../CtaPage/constants';
import { ActionItemCountBadge } from './ActionItemCountBadge';
import { ctaPageMessages, navMenuMessages } from './messages';
import { CtaPageProps, NavMenuSection } from './types';

export const AD_NAV_ITEMS: NavMenuSection[] = [
  {
    label: null,
    items: [
      {
        label: navMenuMessages.linkActionItems,
        basePath: AdminDashboardPrivateMiscPath.ActionItems,
        to: ({ user }) =>
          user.isAdmin
            ? AdminDashboardPrivateMiscPath.ActionItemsAll
            : AdminDashboardPrivateMiscPath.ActionItemsMine,
        icon: IconType.LightningBox,
        statusContent: <ActionItemCountBadge />,
      },
    ],
  },
  {
    label: navMenuMessages.sectionDataAssetIntelligence,
    items: [
      {
        label: navMenuMessages.linkDataInventory,
        basePath: DATA_INVENTORY_PATH_PREFIX,
        activePathExcludes: [
          AdminDashboardDataMapPath.TriageSiloDiscovery,
          AdminDashboardDataMapPath.JunkSiloDiscovery,
        ],
        to: false,
        icon: IconType.GlobeCube,
        childItems: [
          {
            label: navMenuMessages.linkDataInventoryDashboard,
            to: AdminDashboardDataMapPath.DataMappingDashboard,
          },
          {
            label: navMenuMessages.linkDataInventoryBase,
            to: AdminDashboardDataMapPath.DataSilos,
            basePath: DATA_INVENTORY_PATH_PREFIX,
            activePathExcludes: [
              AdminDashboardDataMapPath.TriageSiloDiscovery,
              AdminDashboardDataMapPath.JunkSiloDiscovery,
            ],
          },
          {
            label: navMenuMessages.repositories,
            to: AdminDashboardCodeScanningPath.Repositories,
            basePath: CODE_SCANNING_PATH_PREFIX,
          },
          {
            label: navMenuMessages.linkDataInventoryDataReports,
            to: AdminDashboardDataMapPath.DataReports,
          },
          {
            label: navMenuMessages.linkDataInventoryAuditTrail,
            to: AdminDashboardDataMapPath.DataInventoryAudit,
          },
          {
            label: navMenuMessages.linkOnboardingItems,
            to: AdminDashboardDataMapPath.ActionItems,
            gate: {
              featureSetKeys: ['actionItemChecklists'],
            },
          },
        ],
      },
      {
        label: navMenuMessages.linkDataLineage,
        to: AdminDashboardDataMapPath.DataLineage,
        icon: IconType.NodeTriangle,
        gate: {
          featureSetKeys: ['dataLineage'],
          ctaPage: {
            id: 'data-lineage',
            title: ctaPageMessages.dataLineageTitle,
            description: ctaPageMessages.dataLineageDescription,
            productName: navMenuMessages.linkDataLineage,
            bulletPoints: [
              ctaPageMessages.dataLineageBullet1,
              ctaPageMessages.dataLineageBullet2,
              ctaPageMessages.dataLineageBullet3,
            ],
            primaryLinkLabel: ctaPageMessages.dataLineagePrimaryLinkText,
            primaryLink: 'https://transcend.io/contact',
            imageSrc: CTA_IMAGE_SOURCE.dataLineage,
          },
        },
      },
    ],
  },
  {
    label: navMenuMessages.sectionDataDiscovery,
    items: [
      {
        label: navMenuMessages.linkSiloDiscovery,
        to: AdminDashboardDataMapPath.TriageSiloDiscovery,
        basePath: SILO_DISCOVERY_PATH_PREFIX,
        icon: IconType.Cube,
        gate: {
          featureSetKeys: ['siloDiscovery'],
          ctaPage: {
            id: 'silo-discovery',
            title: ctaPageMessages.siloDiscoTitle,
            description: ctaPageMessages.siloDiscoDescription,
            productName: navMenuMessages.linkSiloDiscovery,
            bulletPoints: [
              ctaPageMessages.siloDiscoBullet1,
              ctaPageMessages.siloDiscoBullet2,
              ctaPageMessages.siloDiscoBullet3,
            ],
            primaryLinkLabel: ctaPageMessages.siloDiscoPrimaryLinkText,
            primaryLink: 'https://transcend.io/contact',
            imageSrc: CTA_IMAGE_SOURCE.siloDisco,
          },
        },
      },
      {
        label: navMenuMessages.linkStructuredDiscovery,
        to: AdminDashboardDataMapPath.ContentClassificationActive,
        basePath: CONTENT_CLASSIFICATION_PATH_PREFIX,
        icon: IconType.CubeGrid,
        gate: {
          featureSetKeys: ['structuredDataDiscovery'],
          ctaPage: {
            id: 'structured-discovery',
            title: ctaPageMessages.structuredDiscoTitle,
            description: ctaPageMessages.structuredDiscoDescription,
            productName: navMenuMessages.linkStructuredDiscovery,
            bulletPoints: [
              ctaPageMessages.structuredDiscoBullet1,
              ctaPageMessages.structuredDiscoBullet2,
              ctaPageMessages.structuredDiscoBullet3,
            ],
            primaryLinkLabel: ctaPageMessages.structuredDiscoPrimaryLinkText,
            primaryLink: 'https://transcend.io/contact',
            imageSrc: CTA_IMAGE_SOURCE.structuredDisco,
          },
        },
      },
      {
        label: navMenuMessages.linkUnstructuredDiscovery,
        to: AdminDashboardDataMapPath.UnstructuredDiscoveryActive,
        basePath: UNSTRUCTURED_DISCOVERY_PATH_PREFIX,
        icon: IconType.Sphere,
        gate: {
          featureSetKeys: ['unstructuredDataDiscovery'],
          ctaPage: {
            id: 'unstructured-discovery',
            title: ctaPageMessages.unstructuredDiscoTitle,
            description: ctaPageMessages.unstructuredDiscoDescription,
            productName: navMenuMessages.linkUnstructuredDiscovery,
            bulletPoints: [
              ctaPageMessages.unstructuredDiscoBullet1,
              ctaPageMessages.unstructuredDiscoBullet2,
              ctaPageMessages.unstructuredDiscoBullet3,
            ],
            primaryLinkLabel: ctaPageMessages.unstructuredDiscoPrimaryLinkText,
            primaryLink: 'https://transcend.io/contact',
            imageSrc: CTA_IMAGE_SOURCE.unstructuredDisco,
          },
        },
      },
    ],
  },
  {
    label: navMenuMessages.sectionPrivacyOperations,
    items: [
      {
        label: navMenuMessages.linkDsrAutomation,
        to: false,
        basePath: PRIVACY_REQUESTS_PATH_PREFIX,
        icon: IconType.HandShapes,
        gate: {
          featureSetKeys: ['dsrAutomation'],
          ctaPage: {
            id: 'dsr-automation',
            title: ctaPageMessages.dsrAutomationTitle,
            description: ctaPageMessages.dsrAutomationDescription,
            productName: navMenuMessages.linkDsrAutomation,
            bulletPoints: [
              ctaPageMessages.dsrAutomationBullet1,
              ctaPageMessages.dsrAutomationBullet2,
              ctaPageMessages.dsrAutomationBullet3,
            ],
            primaryLinkLabel: ctaPageMessages.dsrAutomationPrimaryLinkText,
            primaryLink: 'https://transcend.io/contact',
            imageSrc: CTA_IMAGE_SOURCE.dsrAutomation,
          },
        },
        childItems: [
          {
            label: navMenuMessages.linkDsrDashboard,
            to: AdminDashboardPrivacyRequestPath.PrivacyRequestDashboard,
          },
          {
            label: navMenuMessages.linkDsrWorkflows,
            to: AdminDashboardPrivacyRequestPath.Workflows,
            gate: {
              featureFlagKey: FeatureFlagKey.EnableWorkflowsV2Ui,
            },
          },
          {
            label: navMenuMessages.linkIncomingRequests,
            to: AdminDashboardPrivacyRequestPath.IncomingRequests,
            basePath: AdminDashboardPrivacyRequestPath.IncomingRequests,
          },
          {
            label: navMenuMessages.linkBulkRespond,
            to: AdminDashboardPrivacyRequestPath.BulkRequests,
          },
          {
            label: navMenuMessages.linkRequestSettings,
            to: AdminDashboardPrivacyRequestPath.SettingsDataActions,
            basePath: AdminDashboardPrivacyRequestPath.Settings,
          },
          {
            label: navMenuMessages.linkIdentifiers,
            to: AdminDashboardPrivacyRequestPath.Identifiers,
          },
          {
            label: navMenuMessages.linkEmailSettings,
            to: AdminDashboardPrivacyRequestPath.EmailSettingsTemplates,
            basePath: EMAIL_SETTINGS_PATH_PREFIX,
          },
          {
            label: navMenuMessages.linkLegalHolds,
            to: AdminDashboardPrivacyRequestPath.LegalHolds,
          },
          {
            label: navMenuMessages.linkOnboardingItems,
            to: AdminDashboardPrivacyRequestPath.ActionItems,
            gate: {
              featureSetKeys: ['actionItemChecklists'],
            },
          },
        ],
      },
      {
        label: navMenuMessages.linkConsentManagement,
        to: false,
        icon: IconType.ThumbsUpCheck,
        basePath: CONSENT_MANAGER_PATH_PREFIX,
        gate: {
          featureSetKeys: ['webConsent'],
          ctaPage: {
            id: 'consent',
            title: ctaPageMessages.consentTitle,
            description: ctaPageMessages.consentDescription,
            productName: navMenuMessages.linkConsentManagement,
            bulletPoints: [
              ctaPageMessages.consentBullet1,
              ctaPageMessages.consentBullet2,
              ctaPageMessages.consentBullet3,
            ],
            primaryLinkLabel: ctaPageMessages.consentPrimaryLinkText,
            primaryLink: 'https://transcend.io/contact',
            imageSrc: CTA_IMAGE_SOURCE.consent,
          },
        },
        childItems: [
          {
            label: navMenuMessages.linkConsentDashboard,
            to: AdminDashboardCmPath.ConsentManager,
          },
          {
            label: navMenuMessages.linkConsentServices,
            to: AdminDashboardCmPath.ConsentServices,
            gate: {
              featureFlagKey: FeatureFlagKey.ConsentServices,
            },
          },
          {
            label: navMenuMessages.linkDataFlows,
            to: AdminDashboardCmPath.DataFlowsApproved,
            basePath: AdminDashboardCmPath.DataFlowsTriage,
          },
          {
            label: navMenuMessages.linkCookies,
            to: AdminDashboardCmPath.CookiesApproved,
            basePath: AdminDashboardCmPath.CookiesTriage,
          },
          {
            label: navMenuMessages.linkRegionalExperiences,
            to: AdminDashboardCmPath.RegionalExperiences,
            basePath: AdminDashboardCmPath.RegionalExperiences,
          },
          {
            label: navMenuMessages.linkDeveloperSettings,
            to: AdminDashboardCmPath.DeveloperSettings,
            basePath: AdminDashboardCmPath.DeveloperSettings,
          },
          {
            label: navMenuMessages.linkDisplaySettings,
            to: AdminDashboardCmPath.DisplaySettings,
            basePath: AdminDashboardCmPath.DisplaySettings,
          },
          {
            label: navMenuMessages.linkNativeConsent,
            to: AdminDashboardCmPath.Sdks,
            basePath: NATIVE_APPS_PATH_PREFIX,
          },
          {
            label: navMenuMessages.linkTcfSettings,
            to: AdminDashboardCmPath.TcfVendors,
            basePath: TCF_PATH_PREFIX,
            isVisibleCustom: ({ consentManager }) =>
              !!consentManager && consentManager.useIabFramework,
          },
          {
            label: navMenuMessages.linkConsentAuditTrail,
            to: AdminDashboardCmPath.AuditTrail,
          },
          {
            label: navMenuMessages.linkOnboardingItems,
            to: AdminDashboardCmPath.ActionItems,
            gate: {
              featureSetKeys: ['actionItemChecklists'],
            },
          },
        ],
      },
      {
        label: navMenuMessages.linkPreferenceManagement,
        to: PreferenceStorePath.PreferenceStore,
        basePath: PreferenceStorePath.PreferenceStore,
        icon: IconType.ServerDatabasesCheckmark,
        gate: {
          featureSetKeys: ['preferenceStore'],
          ctaPage: {
            id: 'preference-store',
            title: ctaPageMessages.prefStoreTitle,
            description: ctaPageMessages.prefStoreDescription,
            bulletPoints: [
              ctaPageMessages.prefStoreBullet1,
              ctaPageMessages.prefStoreBullet2,
              ctaPageMessages.prefStoreBullet3,
            ],
            primaryLinkLabel: ctaPageMessages.prefStorePrimaryLinkText,
            primaryLink: 'https://transcend.io/contact',
            imageSrc: CTA_IMAGE_SOURCE.preferenceStore,
          },
        },
        childItems: [
          {
            label: navMenuMessages.linkUserPreferences,
            to: PreferenceStorePath.PreferenceStore,
            basePath: USER_PREFERENCES_PATH_PREFIX,
          },
          {
            label: navMenuMessages.linkPreferenceTopics,
            to: PreferenceStorePath.PreferenceTopics,
            basePath: USER_PREFERENCE_TOPICS_PATH_PREFIX,
            gate: {
              featureSetKeys: ['consentWorkflows'],
            },
          },
          {
            label: navMenuMessages.linkDeveloperSettings,
            to: PreferenceStorePath.PreferenceStoreSettings,
          },
          {
            label: navMenuMessages.linkConsentWorkflows,
            to: PreferenceStorePath.ConsentWorkflows,
            gate: {
              featureSetKeys: ['consentWorkflows'],
            },
          },
          {
            label: navMenuMessages.linkOnboardingItems,
            to: PreferenceStorePath.ActionItems,
            gate: {
              featureSetKeys: ['actionItemChecklists'],
            },
          },
        ],
      },
      {
        label: navMenuMessages.linkPrivacyCenter,
        to: false,
        icon: IconType.UserCard,
        gate: {
          featureSetKeys: ['privacyCenter'],
          ctaPage: {
            id: 'privacy-center',
            title: ctaPageMessages.privacyCenterTitle,
            description: ctaPageMessages.privacyCenterDescription,
            bulletPoints: [
              ctaPageMessages.privacyCenterBullet1,
              ctaPageMessages.privacyCenterBullet2,
              ctaPageMessages.privacyCenterBullet3,
            ],
            primaryLinkLabel: ctaPageMessages.privacyCenterPrimaryLinkText,
            primaryLink: 'https://transcend.io/contact',
            imageSrc: CTA_IMAGE_SOURCE.privacyCenter,
          },
        },
        childItems: [
          {
            label: navMenuMessages.linkGeneralSettings,
            to: AdminDashboardPrivacyCenterPath.GeneralSettings,
          },
          {
            label: navMenuMessages.linkPolicies,
            to: AdminDashboardPrivacyCenterPath.Policies,
          },
          {
            label: navMenuMessages.linkRequestsProcessedStats,
            to: AdminDashboardPrivacyCenterPath.RequestsProcessedStats,
            gate: {
              featureFlagKey:
                FeatureFlagKey.ShowPCRequestsProcessedStatsSettings,
            },
          },
          {
            label: navMenuMessages.linkAssetFiles,
            to: AdminDashboardPrivacyCenterPath.AssetFiles,
          },
          {
            label: navMenuMessages.linkMessages,
            to: AdminDashboardPrivacyCenterPath.MessagesInternationalization,
          },
          {
            label: navMenuMessages.linkColors,
            to: AdminDashboardPrivacyCenterPath.ColorsAndStyles,
          },
          {
            label: navMenuMessages.linkAuthenticationMethods,
            to: AdminDashboardPrivacyCenterPath.AuthenticationMethods,
          },
          {
            label: navMenuMessages.linkDataPractices,
            to: AdminDashboardPrivacyCenterPath.DataPractices,
          },
          {
            label: navMenuMessages.linkPrivacyCenterAuditTrail,
            to: AdminDashboardPrivacyCenterPath.AuditTrail,
          },
          {
            label: navMenuMessages.linkOnboardingItems,
            to: AdminDashboardPrivacyCenterPath.ActionItems,
            gate: {
              featureSetKeys: ['actionItemChecklists'],
            },
          },
        ],
      },
    ],
  },
  {
    label: navMenuMessages.sectionRiskIntelligence,
    items: [
      {
        label: navMenuMessages.linkWebAuditor,
        to: AdminDashboardAuditorPath.Auditor,
        basePath: AdminDashboardAuditorPath.Auditor,
        icon: IconType.MagnifyingGlassPlusBox,
        gate: {
          featureSetKeys: ['auditorMonthlyUsageLimit'],
          ctaPage: {
            id: 'web-auditor',
            title: ctaPageMessages.webAuditorTitle,
            description: ctaPageMessages.webAuditorCtaDescription,
            bulletPoints: [
              ctaPageMessages.webAuditorCtaFeature1,
              ctaPageMessages.webAuditorCtaFeature2,
              ctaPageMessages.webAuditorCtaFeature3,
            ],
            primaryLinkLabel: ctaPageMessages.webAuditorPrimaryLinkText,
            primaryLink: 'https://transcend.io/contact',
            imageSrc: CTA_IMAGE_SOURCE.webAuditor,
          },
        },
        childItems: [
          {
            label: navMenuMessages.linkAuditorRuns,
            to: AdminDashboardAuditorPath.Auditor,
          },
          {
            label: navMenuMessages.linkAuditorSchedules,
            to: AdminDashboardAuditorPath.Schedules,
          },
          {
            label: navMenuMessages.linkOnboardingItems,
            to: AdminDashboardAuditorPath.ActionItems,
            gate: {
              featureSetKeys: ['actionItemChecklists'],
            },
          },
        ],
      },
      {
        label: navMenuMessages.linkContractScanning,
        to: AdminDashboardContractScanningPath.Contracts,
        basePath: AdminDashboardContractScanningPath.Contracts,
        icon: IconType.MagnifyingGlassDocument,
        gate: {
          featureSetKeys: ['contractScanning'],
          ctaPage: {
            id: 'contract-scanning',
            title: ctaPageMessages.contractScanningTitle,
            description: ctaPageMessages.contractScanningDescription,
            productName: navMenuMessages.linkContractScanning,
            bulletPoints: [
              ctaPageMessages.contractScanningBullet1,
              ctaPageMessages.contractScanningBullet2,
              ctaPageMessages.contractScanningBullet3,
            ],
            primaryLinkLabel: ctaPageMessages.contractScanningPrimaryLinkText,
            primaryLink: 'https://transcend.io/contact',
            imageSrc: CTA_IMAGE_SOURCE.contractScanning,
          },
        },
      },
      {
        label: navMenuMessages.linkAssessments,
        to: AdminDashboardAssessmentsPath.AssessmentGroups,
        basePath: ASSESSMENTS_PATH_PREFIX,
        icon: IconType.DocumentQuestion,
        gate: {
          featureSetKeys: ['assessments'],
          ctaPage: {
            id: 'assessments',
            title: ctaPageMessages.assessmentTitle,
            description: ctaPageMessages.assessmentDescription,
            productName: navMenuMessages.linkAssessments,
            bulletPoints: [
              ctaPageMessages.assessmentBullet1,
              ctaPageMessages.assessmentBullet2,
              ctaPageMessages.assessmentBullet3,
            ],
            primaryLinkLabel: ctaPageMessages.assessmentPrimaryLinkText,
            primaryLink: 'https://transcend.io/contact',
            imageSrc: CTA_IMAGE_SOURCE.assessment,
          },
        },
      },
    ],
  },
  {
    label: navMenuMessages.sectionAiGovernance,
    items: [
      {
        label: navMenuMessages.linkPathfinder,
        to: AdminDashboardPathfinderPath.Pathfinders,
        basePath: AdminDashboardPathfinderPath.Pathfinders,
        icon: IconType.Compass,
        gate: {
          featureSetKeys: ['pathfinder'],
          ctaPage: {
            id: 'pathfinder',
            title: ctaPageMessages.pathfinderTitle,
            description: ctaPageMessages.pathfinderDescription,
            productName: navMenuMessages.linkPathfinder,
            bulletPoints: [
              ctaPageMessages.pathfinderBullet1,
              ctaPageMessages.pathfinderBullet2,
              ctaPageMessages.pathfinderBullet3,
            ],
            primaryLinkLabel: ctaPageMessages.pathfinderPrimaryLinkText,
            primaryLink: 'https://transcend.io/contact',
            imageSrc: CTA_IMAGE_SOURCE.pathfinder,
          },
        },
      },
      {
        label: navMenuMessages.linkPromptManagement,
        to: AdminDashboardPromptsPath.Prompts,
        basePath: AdminDashboardPromptsPath.Prompts,
        icon: IconType.TextSparkle,
        gate: {
          featureSetKeys: ['prompts'],
          ctaPage: {
            id: 'prompt-management',
            title: ctaPageMessages.promptsTitle,
            description: ctaPageMessages.promptsDescription,
            productName: navMenuMessages.linkPromptManagement,
            bulletPoints: [
              ctaPageMessages.promptsBullet1,
              ctaPageMessages.promptsBullet2,
              ctaPageMessages.promptsBullet3,
            ],
            primaryLinkLabel: ctaPageMessages.promptsPrimaryLinkText,
            primaryLink: 'https://transcend.io/contact',
            imageSrc: CTA_IMAGE_SOURCE.promptManagement,
          },
        },
      },
    ],
  },
  {
    label: navMenuMessages.sectionInfrastructure,
    items: [
      {
        label: navMenuMessages.linkIntegrations,
        to: AdminDashboardInfrastructurePath.Integrations,
        basePath: AdminDashboardInfrastructurePath.Integrations,
        icon: IconType.RowNodes,
      },
      {
        label: navMenuMessages.linkSombra,
        to: AdminDashboardInfrastructurePath.Sombras,
        basePath: SOMBRA_PATH_PREFIX,
        icon: IconType.EyeSlash,
      },
      {
        to: AdminDashboardInfrastructurePath.AttributesManagement,
        label: navMenuMessages.linkCustomFields,
        icon: IconType.PencilGrid,
        basePath: AdminDashboardInfrastructurePath.AttributesManagement,
      },
      {
        label: navMenuMessages.linkDeveloperTools,
        to: false,
        icon: IconType.CodeMonitor,
        childItems: [
          {
            to: AdminDashboardInfrastructurePath.EmailDomains,
            label: navMenuMessages.linkEmailDomains,
          },
          {
            to: AdminDashboardInfrastructurePath.ApiKeys,
            label: navMenuMessages.linkApiKeys,
          },
          // TODO: https://transcend.height.app/T-30923 - Add CLI/Terraform back to sidebar
          // {
          //   to: false,
          //   label: navMenuMessages.linkCliTerraform,
          // },
        ],
      },
    ],
  },
];

/**
 * list of extra CTA pages not directly connected to the navbar
 */
const EXTRA_CTA_PAGES: CtaPageProps[] = [];

/**
 * A lookup that represents all CTAs defined in the nav menu constant above and
 * the extra CTA pages constant. Used as the source of all content for the CTAPage
 */
export const CTA_PAGES_BY_ID: Record<string, CtaPageProps> = indexBy(
  AD_NAV_ITEMS.map((section) =>
    section.items.map((item) => [
      item.gate?.ctaPage,
      ...(item.childItems?.map((popItem) => popItem.gate?.ctaPage) ?? []),
    ]),
  )
    .flat(3)
    .concat(EXTRA_CTA_PAGES)
    .filter((item) => !!item) as CtaPageProps[],
  'id',
);
/* eslint-enable max-lines */
