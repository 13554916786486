import { mkInput, mkType, SchemaToType } from '@main/schema-utils';

// Compatible with CustomerTokenInput codec used by setTokenSecretHandler
export const TokenSecrets = mkType({
  name: 'TokenSecrets',
  comment: 'Secrets for signing JWTs',
  fields: {
    partition: {
      comment: 'partition associated with this JWT',
      type: 'string',
    },
    secrets: {
      comment: 'Secrets for signing and verifying JWTs (current and former)',
      type: 'string',
      list: true,
    },
  },
});

/** Override type */
export type TokenSecrets = SchemaToType<typeof TokenSecrets>;

export const TokenSecretInput = mkInput({
  name: 'TokenSecretInput',
  comment: 'Secret for signing and verifying JWTs',
  fields: {
    // Compatible with RequireAirgapBundle middleware
    id: {
      comment: 'The ID of this Consent Manager bundle.',
      modelName: 'airgapBundle',
      type: 'id',
    },
    secret: {
      comment:
        'Secret for signing and verifying JWTs. If empty and there is no existing key saved, we can auto-generate.',
      type: 'string',
      optional: true,
    },
  },
});

/** Override type */
export type TokenSecretInput = SchemaToType<typeof TokenSecretInput>;
