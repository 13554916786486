import { mkQuery, PAGINATION_INPUT_PARAMS } from '@main/schema-utils';

import { AuditEventFiltersInput, AuditEventPreviewRaw } from './schema';

export const auditEvents = mkQuery({
  name: 'auditEvents',
  comment: 'The list of data silos with content classification available',
  params: {
    ...PAGINATION_INPUT_PARAMS,
    filterBy: AuditEventFiltersInput.optional(),
  },
  response: {
    nodes: AuditEventPreviewRaw.list(),
    totalCount: 'int',
  },
});
