import { FilterConfigItem } from '@main/ad-core-components';
import { ConsentPartition, endpoints } from '@main/cm-types';
import { buildUseLazyQuery } from '@main/core-ui';
import { ID } from '@main/schema-utils';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { MessageDescriptor } from 'react-intl';

import { SelectPartitions } from '../../components/SelectPartitions';

/**
 * Filters definition with property for partition values specified
 */
type PartitionEnrichedFilters = Record<string, unknown> &
  Record<
    /**
     * Partition values that are currently filtering the table
     */
    string,
    ConsentPartition[] | undefined
  >;

const useLazyPartitions = buildUseLazyQuery(endpoints.consentPartitions);

export const usePartitionFilters = <
  T extends Record<string, unknown>,
  E extends PartitionEnrichedFilters,
>({
  partitionIdsFilterKey = 'partitionIds',
  filters,
  setFilters,
  label,
  enrichedPartitionKey = 'partitions',
}: {
  /** The property name of the filter for partition ids */
  partitionIdsFilterKey?: keyof T;
  /** The currently applied filters */
  filters?: T;
  /** Callback when the filters are changed */
  setFilters: (filters: T) => void;
  /** Label for the filter menu */
  label: string | MessageDescriptor;
  /** The key to use if more than one usePartitionFilters is used in a FilterManager */
  enrichedPartitionKey?: keyof E;
}): {
  /**  The partition keys enabled on this table */
  selectedPartitions: ConsentPartition[];
  /** The filter configuration to be passed to the filter manager */
  partitionFiltersConfig: FilterConfigItem<E>;
  /** Callback for when the filter is cleared in the filter manager */
  clearPartitionFilters: (key: Extract<keyof E, string>) => void;
} => {
  const getSelectedPartitions = useLazyPartitions();
  const [selectedPartitions, setSelectedPartitions] = useState<
    ConsentPartition[]
  >([]);
  const [fetchingPartitions, setFetchingPartitions] = useState(false);

  // Populate initial complex filter values
  useEffect(() => {
    if (filters) {
      const partitionIds = (filters[partitionIdsFilterKey] ??
        []) as ID<'airgapPartition'>[];

      const filtersAndEnrichedFiltersMatch =
        partitionIds.sort().join() ===
        selectedPartitions
          .map(({ id }) => id)
          .sort()
          .join();

      if (partitionIds.length === 0 && selectedPartitions.length > 0) {
        setSelectedPartitions([]);
      } else if (!filtersAndEnrichedFiltersMatch && !fetchingPartitions) {
        // Prevent over-firing this setter while data is still being fetched
        setFetchingPartitions(true);
        getSelectedPartitions({ filterBy: { ids: partitionIds } })
          .then(({ data }) => {
            setSelectedPartitions(data.nodes);
          })
          .finally(() => {
            setFetchingPartitions(false);
          });
      }
    }
  }, [
    filters,
    fetchingPartitions,
    getSelectedPartitions,
    selectedPartitions.length,
    partitionIdsFilterKey,
    selectedPartitions,
  ]);

  const partitionFiltersConfig = useMemo(
    () =>
      ({
        filterKey: enrichedPartitionKey,
        label,
        pillOptions: {
          label: ({
            filterValues: { [enrichedPartitionKey]: partitions = [] },
            index = 0,
          }) => partitions[index]?.name,
        },
        filter: (
          <SelectPartitions
            menuPosition="absolute"
            loading={fetchingPartitions}
            selectedPartitions={selectedPartitions}
            onChange={(partitions) => {
              setSelectedPartitions([...partitions]);
              setFilters({
                ...filters,
                [partitionIdsFilterKey]: partitions.map(({ id }) => id),
              } as T);
            }}
          />
        ),
      }) as FilterConfigItem<PartitionEnrichedFilters>,
    [
      enrichedPartitionKey,
      label,
      selectedPartitions,
      filters,
      partitionIdsFilterKey,
      fetchingPartitions,
      setFilters,
    ],
  );

  const clearPartitionFilters = useCallback(
    (key) => {
      if (key === enrichedPartitionKey) {
        setFilters({
          ...filters,
          [partitionIdsFilterKey]: [],
        } as T);
        setSelectedPartitions([]);
      }
    },
    [enrichedPartitionKey, filters, partitionIdsFilterKey],
  );

  return {
    selectedPartitions,
    partitionFiltersConfig,
    clearPartitionFilters,
  };
};
