import { endpoints } from '@main/access-control-types';
import { useInlineInput } from '@main/ad-core-components';
import {
  AttributeInput,
  InlineAttributeInputProps,
} from '@main/admin-dashboard/src/DataMap/components';
import { ATTRIBUTE_KEY_TEXT_TYPES } from '@main/admin-dashboard/src/DataMap/constants';
import { AttributeValue } from '@main/attribute-types';
import { buildUseMutation } from '@main/core-ui';
import type { ID } from '@main/schema-utils';
import {
  AttributeKeyType,
  AttributeSupportedResourceType,
} from '@transcend-io/privacy-types';
import React from 'react';

import { RowTableContext } from '../types';

export interface InlineActionItemAttributeInputProps
  extends Omit<
    InlineAttributeInputProps<AttributeSupportedResourceType.ActionItem>,
    'id' | 'refetchQueries' | 'resourceType'
  > {
  /** ID of the resource */
  ids: ID<'actionItem'>[];
  /** The table context */
  context: RowTableContext;
  /** Queries to refetch on success */
  refetchQueries?: string[];
}

const useUpdateActionItems = buildUseMutation(endpoints.updateActionItems);

/**
 * Component to conditional display/edit attributes based off of attribute key type
 */
export function InlineActionItemAttributeInput({
  attributeKey,
  context,
  values,
  ids,
  refetchQueries = ['GlobalActionItems', 'GlobalActionItemsSubTable'],
  readOnly,
}: InlineActionItemAttributeInputProps): React.ReactElement {
  const [updateActionItems] = useUpdateActionItems(
    refetchQueries
      ? {
          refetchQueries,
          awaitRefetchQueries: true,
        }
      : {},
  );
  const textTypes = ATTRIBUTE_KEY_TEXT_TYPES;

  const { value, setValue, onBlur } = useInlineInput({
    initialValue: textTypes.includes(attributeKey.type)
      ? values.length > 0
        ? values[0].name
        : ''
      : values,
    onUpdate: (updatedValue) =>
      updateActionItems({
        variables: {
          input: {
            attributes: [
              {
                attributeKeyId: attributeKey.id,
                attributeValueNames:
                  textTypes.includes(attributeKey.type) ||
                  (attributeKey.type === AttributeKeyType.Assessment &&
                    typeof updatedValue === 'string')
                    ? [updatedValue as string]
                    : (updatedValue as AttributeValue[]).map(
                        ({ name }) => name,
                      ),
                attributeValueIds: [],
              },
            ],
            ...(context.count > 1
              ? {
                  queryFilters: context.filters,
                  rollupFilters: [context.rollupFilters],
                }
              : {
                  ids,
                }),
          },
        },
      }).then(({ data, errors }) => ({
        data: updatedValue,
        errors,
        success: data?.success,
      })),
  });

  return (
    <AttributeInput
      values={value}
      attributeKey={attributeKey}
      setValue={setValue}
      onBlur={onBlur}
      readOnly={readOnly}
    />
  );
}
