import {
  DocumentNode,
  OperationVariables,
  QueryHookOptions,
  QueryResult,
  useQuery,
} from '@apollo/client';

/**
 * Small wrapper around `useQuery` so that we can use it imperatively.
 *
 * TODO: https://github.com/apollographql/react-apollo/issues/3499 - remove if useLazyQuery
 * can return a promise
 *
 * @see Credit: https://github.com/apollographql/react-apollo/issues/3499#issuecomment-586039082
 * @example
 * const callQuery = useImperativeQuery(query, options)
 * const handleClick = async () => {
 * const{ data, error } = await callQuery()
 * }
 * @param query - Query to execute
 * @param options - Query options
 * @returns Function to call the query
 */
export function useImperativeQuery<
  TData = any,
  TVariables = OperationVariables,
>(
  query: DocumentNode,
  options: QueryHookOptions<TData, TVariables> = {},
): QueryResult<TData, TVariables>['refetch'] {
  const { refetch } = useQuery<TData, TVariables>(query, {
    ...options,
    skip: true,
  });

  return (queryVariables: TVariables) => refetch(queryVariables);
}
