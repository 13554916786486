import { createLoadable } from '@main/core-ui';

export const BulkRequestCompletionLoggedOut = createLoadable({
  loader: () => import('./BulkRequestCompletionLoggedOut'),
});

// Load the component dynamically
export default createLoadable({
  loader: () => import('./BulkRequestCompletion'),
});
