import styled, { css, keyframes } from 'styled-components';

const containerFadeIn = keyframes`
  0% {
    opacity: 0
  }
  30% {
    opacity: 0
  }
  100% {
    opacity: 100%
  }
`;

export const ChartContainer = styled.div`
  overflow: visible;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  animation: ${css`
    ${containerFadeIn} 700ms ease-out
  `};
`;

export const Legend = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 4px;
  color: ${({ theme }) => theme.colors.transcendNavy2};
  margin: auto 0;
  min-height: 100%;
`;

export const LegendRow = styled.div<{
  /** whether segment is being hovered */ hovered: 'true' | 'false';
}>`
  width: 100%;
  display: grid;
  grid-template-columns: 8px 1fr;
  gap: 0.3em;
  align-items: center;
  padding: 4px 8px;
  border-radius: 6px;
  cursor: pointer;
  background: ${({ hovered, theme }) =>
    hovered === 'true' ? theme.colors.gray4 : theme.colors.white};
  font-weight: ${({ hovered }) => (hovered === 'true' ? 600 : 500)};
  transition:
    background-color 200ms ease-out,
    color 200ms ease-out;

  :hover {
    background: ${({ theme }) => theme.colors.gray4};
    font-weight: 600;

    span {
      color: ${({ theme }) => theme.colors.transcendNavy};
    }
  }
  span {
    color: ${({ hovered, theme }) =>
      hovered === 'true'
        ? theme.colors.transcendNavy
        : theme.colors.transcendNavy2};
  }
`;

export const LegendText = styled.div`
  display: flex;
  justify-content: space-between;
  flex: 1;
  align-items: center;
  font-weight: inherit;

  svg {
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
  }

  :hover svg {
    opacity: 1;
  }
`;
