import { defineMessages } from '@transcend-io/internationalization';

export const promptsPageTitleMessages = defineMessages(
  'admin-dashboard.Prompts.promptsPageTitle',
  {
    prompts: {
      defaultMessage: 'Prompt Manager',
    },
    partials: {
      defaultMessage: 'Prompt Partials',
    },
    groups: {
      defaultMessage: 'Prompt Groups',
    },
    template: {
      defaultMessage: 'Template',
    },
    prompt: {
      defaultMessage: 'Prompt',
    },
    partial: {
      defaultMessage: 'Partial',
    },
    group: {
      defaultMessage: 'Group',
    },
    models: {
      defaultMessage: 'Models',
    },
    promptRuns: {
      defaultMessage: 'Runs',
    },
  },
);

export const promptButtonMessages = defineMessages(
  'admin-dashboard.Prompts.promptButton',
  {
    editButton: {
      defaultMessage: 'Edit',
    },
    copyLinkButton: {
      defaultMessage: 'Copy Link',
    },
    previewButton: {
      defaultMessage: 'Preview',
    },
    discardChangesButton: {
      defaultMessage: 'Discard Changes',
    },
    saveChangesButton: {
      defaultMessage: 'Save Changes',
    },
    saveChangesNewButton: {
      defaultMessage: 'Create New',
    },
    popoverLabel: {
      defaultMessage: 'More Actions',
    },
  },
);
