import * as t from 'io-ts';

import { dbModelId } from '@main/schema-utils';

import { AuditEventCode } from '../schema/enums';
import { mkAuditEventCodec } from '../types';

export const AuditEventAirgapPartition = mkAuditEventCodec(
  AuditEventCode.AirgapPartition,
  t.type({
    /** ID of the airgap partition */
    airgapPartitionId: dbModelId('airgapPartition'),
  }),
);

/** Override types. */
export type AuditEventAirgapPartition = t.TypeOf<
  typeof AuditEventAirgapPartition
>;
