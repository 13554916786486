import { defineMessages } from '@transcend-io/internationalization';

export const sombraSettingsMessages = defineMessages(
  'admin-dashboard.Infrastructure.SombraSettings.sombraSettings',
  {
    header: {
      defaultMessage: 'Sombra',
    },
    changelogHeader: {
      defaultMessage: 'Sombra Changelog',
    },
    employeeAuth: {
      defaultMessage: 'Require SSO Login for Sensitive Operations',
    },
    unAuthPreferenceStoreUpdate: {
      defaultMessage:
        'Enable data subjects to modify their preferences without requiring authentication. ' +
        'This is necessary for CPRA compliance.',
    },
    singleSignOnNotSetup: {
      defaultMessage:
        'SSO Login is not setup. In order to require SSO login for sensitive operations, ' +
        'you must configure SSO for your account under {singleSignOn}.',
    },
    singleSignOnLink: {
      defaultMessage: 'Administration Settings',
    },
    singleSignOn: {
      defaultMessage: 'SSO Login',
    },
    preferenceStoreAuthentication: {
      defaultMessage: 'Preference Store Authentication',
    },
    updateSuccess: {
      defaultMessage: 'Successfully updated configuration',
    },
    sombraTenantConfiguration: {
      defaultMessage: 'Sombra Tenant Configuration',
    },
    setup: {
      defaultMessage: 'Setup',
    },
    employeeAuthSingleTenantInstructions: {
      defaultMessage: `
        Your organization's Sombra is hosted on premise.
        To require SSO login for sensitive operations, ensure that the Sombra
        configuration \`EMPLOYEE_AUTHENTICATION_METHODS\` excludes \`transcend\`
        authentication method.\n
      `,
    },
    preferenceStoreSingleTenantInstructions: {
      defaultMessage: `
        Your organization's Sombra is hosted on premise.
        To let dataSubjects update their preferences without authentication,
        ensure that the Sombra configuration \`ALLOW_UNAUTHENTICATED_PREFERENCE_UPDATES\`
        is set to true. If not configured, it will default to \`false\`.\n
      `,
    },
    changelog: {
      defaultMessage: 'Changelog',
    },
    apiKeys: {
      defaultMessage: 'API Keys',
    },
    security: {
      defaultMessage: 'Security Settings',
    },
    rotate: {
      defaultMessage: 'Rotate Keys',
    },
    sombras: {
      defaultMessage: 'Sombra Gateways',
    },
    singleTenantHosted: {
      defaultMessage: `
      Your organization's Sombra is on dedicated server managed by Transcend.
      Your Sombra is on version {version} and is kept up to date by Transcend.
    `,
    },
    multiTenantHosted: {
      defaultMessage: `
      Your organization's Sombra is managed by Transcend.
      Your Sombra is on version {version} and is kept up to date by Transcend.
    `,
    },
    onPremiseNeedsUpdating: {
      defaultMessage: `
      Your organization's Sombra is hosted on premise. Your Sombra is on version {version}
      whereas the latest Sombra available for deployment is version {mostRecentSombra}.
      You can see instructions for how to update your Sombra at docs.transcend.io.
      A changelog of what more recent versions have is available in the table below.
    `,
    },
    latestVersion: {
      defaultMessage: `Latest docker tag: {mostRecentSombra}`,
    },
    onPremiseUpToDate: {
      defaultMessage: `
      Your organization's Sombra is hosted on premise and is currently up-to-date with
      the most recent Sombra build published by Transcend.
    `,
    },
  },
);
