import { defineMessages } from '@transcend-io/internationalization';

export const selectUserMessages = defineMessages(
  'ad-core-components.SelectUser.selectUser',
  {
    label: {
      defaultMessage: 'Users',
    },
    inviteViaEmail: {
      defaultMessage: 'Invite {email} to organization',
    },
    placeholder: {
      defaultMessage: 'Name or email',
    },
  },
);
