import {
  FlexColumn,
  FlexRow,
  FlexRowCenterVertical,
  GenericMessageDescriptor,
  Icon,
  StyleUtils,
  Tooltip,
  useFormatMessageGeneric,
} from '@main/core-ui';
import { createFakeUuidv4 } from '@main/utils';
import map from 'lodash/map';
import sortBy from 'lodash/sortBy';
import React, { useMemo, useState } from 'react';

import { Button } from '../Button';
import { InlineTextInput } from '../InlineTextInput';
import { simpleTextListMessages } from './messages';
import { ItemWrapper } from './wrappers';

export interface ListItem {
  /** key of item, e.g. slack channel id */
  key: string;
  /** value displayed for item, e.g. slack channel name */
  value: string;
}

export interface SimpleTextListProps {
  /** used as label of input */
  title: GenericMessageDescriptor;
  /** fired when the input is valid and submitted */
  onAdd: (value: string, newList: string[]) => void;
  /** fired when an item is removed from the list */
  onRemove: (key: string, newList: string[]) => void;
  /** items in list */
  items: ListItem[];
  /** placeholder text for input */
  placeholder: GenericMessageDescriptor;
  /** is the list loading? */
  loading?: boolean;
  /** sort items by value? */
  sortItemsByValue?: boolean;
  /** validate items before adding them. Returns true if valid or false/message if not */
  isValid?: (
    value: string,
    currentList: string[],
  ) => boolean | GenericMessageDescriptor;
}

export const SimpleTextList: React.FC<SimpleTextListProps> = ({
  title,
  placeholder,
  onAdd,
  onRemove,
  items,
  loading,
  sortItemsByValue,
  isValid,
}) => {
  const [inputValue, setInputValue] = useState('');
  const { formatMessageGeneric } = useFormatMessageGeneric();

  const sortedItems = useMemo(
    () => (sortItemsByValue ? sortBy(items, 'value') : items),
    [items, sortItemsByValue],
  );
  const rawValues = map(sortedItems, 'value');
  const valueInList = rawValues.includes(inputValue);
  const isValidResult = isValid?.(inputValue, rawValues) ?? true;
  const inputIsValid =
    !valueInList && inputValue !== '' && isValidResult === true;

  let errorMessage: GenericMessageDescriptor;
  const isInvalidWithNoMessageOverride =
    !inputIsValid && isValidResult === false;
  const isInvalidWithMessageOverride =
    !inputIsValid &&
    isValidResult !== undefined &&
    typeof isValidResult !== 'boolean';

  if (inputIsValid) {
    errorMessage = undefined;
  } else if (isInvalidWithNoMessageOverride) {
    errorMessage = simpleTextListMessages.invalidValue;
  } else if (isInvalidWithMessageOverride) {
    errorMessage = isValidResult;
  } else if (valueInList) {
    errorMessage = simpleTextListMessages.valueAlreadyAdded;
  }

  const accessibilityIDs = useMemo(
    () => ({
      textInput: createFakeUuidv4(),
      addButton: createFakeUuidv4(),
      list: createFakeUuidv4(),
    }),
    [],
  );
  const onSubmit = (): void => {
    if (inputIsValid) {
      onAdd(inputValue, [...rawValues, inputValue]);
      setInputValue('');
    }
  };

  return (
    <FlexColumn style={{ gap: StyleUtils.Spacing.sm }}>
      <label htmlFor={accessibilityIDs.textInput}>
        {formatMessageGeneric(title)}
      </label>
      <FlexRowCenterVertical style={{ gap: StyleUtils.Spacing.sm }}>
        <InlineTextInput
          showOutline
          value={inputValue}
          id={accessibilityIDs.textInput}
          placeholder={formatMessageGeneric(placeholder)?.toLocaleString()}
          isValid={inputIsValid}
          onChange={(evt) => setInputValue(evt.target.value)}
          onKeyDown={(evt) => {
            if (evt.key === 'Enter' && !loading) onSubmit();
          }}
          isInvalid={!inputIsValid && inputValue !== ''}
          title={formatMessageGeneric(errorMessage) as string}
        />
        <Button
          aria-label={formatMessageGeneric(
            simpleTextListMessages.addValueToList,
          )?.toLocaleString()}
          aria-controls={accessibilityIDs.list}
          icon={<Icon type="plus" />}
          onClick={onSubmit}
          disabled={loading || !inputIsValid}
          loading={loading}
        />
      </FlexRowCenterVertical>
      <FlexColumn
        id={accessibilityIDs.list}
        aria-live="polite"
        style={{ gap: StyleUtils.Spacing.sm, overflowY: 'scroll' }}
      >
        {sortedItems.map(({ key, value }) => (
          <ItemWrapper key={key}>
            <FlexRow style={{ flexGrow: 1, minWidth: 0 }}>
              <Tooltip
                title={value}
                style={{
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  padding: StyleUtils.Spacing.sm,
                }}
              >
                {value}
              </Tooltip>
            </FlexRow>
            <Button
              aria-label={formatMessageGeneric(
                simpleTextListMessages.removeFromList,
              )?.toLocaleString()}
              size="sm"
              variant="secondary"
              icon={<Icon type="trash" />}
              disabled={loading}
              onClick={() => {
                onRemove(
                  key,
                  sortedItems
                    .filter((item) => key !== item.key)
                    .map(({ value }) => value),
                );
              }}
            />
          </ItemWrapper>
        ))}
      </FlexColumn>
    </FlexColumn>
  );
};
