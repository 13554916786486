import styled, { DefaultTheme } from 'styled-components';

import { FormItemWrapper, StyleUtils } from '@main/core-ui';
import { hexToRgb } from '@main/theme-types';

import { CheckboxValue } from './types';

interface CheckboxContainerProps {
  /** js flag for whether checkbox is disabled as divs don't have a disabled state in html */
  disabled: boolean;
  /** current checked state of checkbox */
  checkboxValue: CheckboxValue;
  /** styled-components theme set up by a theme provider in each app */
  theme: DefaultTheme;
}

const hexToHalfOpacity = (hexColor: string): string => {
  const { r, g, b } = hexToRgb(hexColor);
  return `rgba(${r}, ${g}, ${b}, 0.5)`;
};

/**
 * ui state of the checkbox div
 */
type CheckboxUIState = 'default' | 'hover' | 'active' | 'focus' | 'disabled';
/**
 * utility function to get a mapping of ui states to colors from a given theme
 */
type GetColorsMap = (theme: DefaultTheme) => {
  [key in CheckboxUIState]: { [key in CheckboxValue]: string };
};
const getBorderColorsMap: GetColorsMap = ({ colors }: DefaultTheme) => ({
  default: {
    unchecked: colors.transcendNavy2,
    checked: colors.transcend,
    'partially-checked': colors.transcend,
  },
  hover: {
    unchecked: colors.transcend2,
    checked: colors.transcend2,
    'partially-checked': colors.transcend2,
  },
  active: {
    unchecked: colors.transcend,
    checked: colors.transcendNavy2,
    'partially-checked': colors.transcendNavy2,
  },
  focus: {
    unchecked: colors.transcend4,
    checked: colors.transcend4,
    'partially-checked': colors.transcend4,
  },
  disabled: {
    unchecked: hexToHalfOpacity(colors.transcendNavy2),
    checked: hexToHalfOpacity(colors.transcendNavy2),
    'partially-checked': hexToHalfOpacity(colors.transcendNavy2),
  },
});
const getBackgroundColorsMap: GetColorsMap = ({ colors }: DefaultTheme) => ({
  default: {
    unchecked: colors.white,
    checked: colors.transcend,
    'partially-checked': colors.transcend,
  },
  hover: {
    unchecked: colors.white,
    checked: colors.transcend2,
    'partially-checked': colors.transcend2,
  },
  active: {
    unchecked: colors.white,
    checked: colors.transcendNavy2,
    'partially-checked': colors.transcendNavy2,
  },
  focus: {
    unchecked: colors.gray1,
    checked: colors.transcend,
    'partially-checked': colors.transcend,
  },
  disabled: {
    unchecked: hexToHalfOpacity(colors.transcendNavy2),
    checked: hexToHalfOpacity(colors.transcendNavy2),
    'partially-checked': hexToHalfOpacity(colors.transcendNavy2),
  },
});

const getColor =
  (colorsMapGetter: GetColorsMap) =>
  (checkboxUIState: CheckboxUIState) =>
  ({ disabled, checkboxValue, theme }: CheckboxContainerProps) =>
    colorsMapGetter(theme)[disabled ? 'disabled' : checkboxUIState][
      checkboxValue
    ];
const getBorderColor = getColor(getBorderColorsMap);
const getBackgroundColor = getColor(getBackgroundColorsMap);

export const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  cursor: pointer;
`;

export const CheckboxWrapper = styled.div<CheckboxContainerProps>`
  position: relative;
  height: 16px;
  width: 16px;
  min-width: 16px;
  border-radius: 5px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-width: 1px;
  border-style: solid;
  border-color: ${getBorderColor('default')};
  background: ${getBackgroundColor('default')};
  background-size: ${({ checkboxValue }) =>
    checkboxValue === 'unchecked' ? '0%' : '100%'};

  :hover {
    border-color: ${getBorderColor('hover')};
    background: ${getBackgroundColor('hover')};
  }

  :active {
    transform: ${({ disabled }) => (disabled ? '' : 'scale(90%)')};
    // transition is set on transform, but when we click we want
    // the scale to change immediately or it won't feel as responsive
    transition: transform 0ms;
    border-color: ${getBorderColor('active')};
    background: ${getBackgroundColor('active')};
  }

  :focus-visible :focus {
    outline: none;
    border-color: ${getBorderColor('focus')};
    background: ${getBackgroundColor('focus')};
  }

  transition: all 200ms ease-out;
`;

export const IconWrapper = styled.div`
  position: absolute;
  top: -5px;
`;

export const LabelWrapper = styled.label`
  cursor: pointer;
  font-size: 14px;
  color: ${({ theme }) => theme.colors.transcendNavy2};
`;

export const InlineFormCheckbox = styled(FormItemWrapper)`
  flex-direction: row;
  display: flex;
  align-items: center;
  gap: ${StyleUtils.Spacing.sm};

  label {
    margin-bottom: 0;
    order: 2;
  }
`;
