import {
  Accordion,
  Button,
  Modal,
  WorkerizedJsonDiff,
} from '@main/ad-core-components';
import {
  Badge,
  buildUseQuery,
  FlexColumn,
  FlexRowCenterBoth,
  formatErrorMessage,
  LoadingSpinner,
  message,
  ModalRawProps,
  StyleUtils,
  TabBar,
} from '@main/core-ui';
import { localeDescriptorMessages } from '@main/internationalization';
import { endpoints } from '@main/pc-types';
import type { ID } from '@main/schema-utils';
import isEqual from 'lodash/isEqual';
import React, { useEffect, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { selectPrivacyCenterId } from '../selectors';
import {
  privacyCenterModuleNames,
  reviewChangesAndPublishMessages,
} from './messages';
import { PreparePublishSteps } from './PreparePublishSteps';

const usePrivacyCenterVersionModules = buildUseQuery(
  endpoints.privacyCenterVersionModules,
);

interface ParsedPrivacyCenterModules {
  /** privacy center module */
  privacyCenter: any;
  /** policies module */
  policies: any;
  /** messages module */
  messages: any;
  /** subjects module */
  subjects: any;
  /** requests processed stats module */
  requestsProcessedStats: any;
  /** purposes module */
  purposes: any;
}

export const ReviewChangesAndPublishModal: React.FC<
  ModalRawProps & {
    /** Call back to indicate whether changes were made */
    onModified: (isModified: boolean) => void;
    /** Call back to publish changes (or upgrade version) */
    onPublish: (privacyCenterVersionId?: ID<'privacyCenterVersion'>) => void;
    /** Whether the operation to publish changes is ongoing */
    publishLoading: boolean;
    /** Whether the operation to save draft is ongoing */
    saveDraftLoading: boolean;
  }
> = ({
  onModified,
  onPublish,
  publishLoading,
  saveDraftLoading,
  ...modalProps
}) => {
  const { formatMessage } = useIntl();
  const privacyCenterId = useSelector(selectPrivacyCenterId);
  const [showPreliminarySteps, setShowPreliminarySteps] = useState(true);

  const {
    data: deployedData,
    error: deployedErr,
    loading: deployedLoading,
  } = usePrivacyCenterVersionModules({
    variables: {
      input: {
        privacyCenterId,
        inDeployState: true,
      },
    },
    skip: !privacyCenterId,
    // We want to fetch the latest configuration change when the modal opens
    fetchPolicy: 'network-only',
    // should show loading spinner when reloading after changes
    notifyOnNetworkStatusChange: true,
  });

  const {
    data: savedData,
    error: savedErr,
    loading: savedLoading,
  } = usePrivacyCenterVersionModules({
    variables: {
      input: {
        privacyCenterId,
        inDeployState: false,
      },
    },
    skip: !privacyCenterId,
    // We want to fetch the latest configuration change when the modal opens
    fetchPolicy: 'network-only',
    // should show loading spinner when reloading after changes
    notifyOnNetworkStatusChange: true,
  });
  const anyInitialStepsLoading =
    savedLoading || deployedLoading || saveDraftLoading;

  useEffect(() => {
    if (!anyInitialStepsLoading) {
      // delay hiding to allow the user to see the steps marked as complete before disappearing
      setTimeout(() => {
        setShowPreliminarySteps(false);
      }, 1000);
    }
  }, [anyInitialStepsLoading]);

  // Convert deployed modules to JSON
  const currentState: ParsedPrivacyCenterModules = useMemo(
    () => ({
      privacyCenter: JSON.parse(deployedData?.privacyCenterModule ?? '{}'),
      policies: JSON.parse(deployedData?.policiesModule ?? '{}'),
      messages: JSON.parse(deployedData?.messagesModule ?? '{}'),
      subjects: JSON.parse(deployedData?.subjectsModule ?? '{}'),
      requestsProcessedStats: JSON.parse(
        deployedData?.privacyCenterRequestsProcessedStatsModule ?? '{}',
      ),
      purposes: JSON.parse(deployedData?.purposesModule ?? '{}'),
    }),
    [deployedData],
  );

  // Convert saved modules to JSON if non-null
  const pendingState: ParsedPrivacyCenterModules = useMemo(
    () => ({
      privacyCenter: savedData?.privacyCenterModule
        ? JSON.parse(savedData?.privacyCenterModule)
        : null,
      policies: savedData?.policiesModule
        ? JSON.parse(savedData?.policiesModule)
        : null,
      messages: savedData?.messagesModule
        ? JSON.parse(savedData?.messagesModule)
        : null,
      subjects: savedData?.subjectsModule
        ? JSON.parse(savedData?.subjectsModule)
        : null,
      requestsProcessedStats:
        savedData?.privacyCenterRequestsProcessedStatsModule
          ? JSON.parse(savedData?.privacyCenterRequestsProcessedStatsModule)
          : null,
      purposes: savedData?.purposesModule
        ? JSON.parse(savedData?.purposesModule)
        : null,
    }),
    [savedData],
  );

  // Determine if the Privacy Center has been modified
  const [isModified, setIsModified] = useState(false);
  useEffect(() => {
    const modifiedModules = Object.entries(pendingState).filter(
      ([key, value]) =>
        value != null &&
        !isEqual(
          pendingState[key as keyof ParsedPrivacyCenterModules],
          currentState[key as keyof ParsedPrivacyCenterModules],
        ),
    );
    setIsModified(savedData !== undefined && modifiedModules.length > 0);
    onModified(isModified);
  }, [currentState, isModified, onModified, pendingState, savedData]);

  useEffect(() => {
    if (deployedErr) {
      message.error(formatErrorMessage(deployedErr.message));
    }
    if (savedErr) {
      message.error(formatErrorMessage(savedErr.message));
    }
  }, [deployedErr, savedErr, formatMessage]);

  const tabHasChanges = (tabName: keyof ParsedPrivacyCenterModules): boolean =>
    savedData &&
    pendingState[tabName] &&
    !isEqual(pendingState[tabName], currentState[tabName]);

  // TabBar controls
  const [currentTab, setCurrentTab] = useState<
    keyof ParsedPrivacyCenterModules
  >(Object.keys(currentState)[0] as keyof ParsedPrivacyCenterModules);
  const tabs = useMemo(
    () =>
      Object.keys(currentState).map((key) => ({
        title: (
          <span
            onClick={() =>
              setCurrentTab(key as keyof typeof privacyCenterModuleNames)
            }
            style={{ display: 'flex', alignItems: 'center' }}
          >
            {formatMessage(
              privacyCenterModuleNames[
                key as keyof typeof privacyCenterModuleNames
              ],
            )}
            {tabHasChanges(key as keyof ParsedPrivacyCenterModules) && (
              <Badge
                color="red1"
                round
                style={{
                  display: 'inline-block',
                  height: '6px',
                  minWidth: '6px',
                  padding: '0px',
                  marginLeft: '6px',
                }}
              />
            )}
          </span>
        ),
        path: key,
      })),

    [currentState, savedData, pendingState],
  );

  const currentTabState = currentState[currentTab];
  const pendingTabState = pendingState[currentTab];

  const localesToDisplay = useMemo(
    () =>
      Object.keys(pendingTabState ?? {}).filter(
        (locale) => currentTabState[locale] !== pendingTabState[locale],
      ),
    [currentTabState, pendingTabState],
  );

  const accordionItems = useMemo(
    () =>
      localesToDisplay
        .filter((locale) => locale in localeDescriptorMessages)
        .map((locale) => ({
          key: locale,
          header: formatMessage(localeDescriptorMessages[locale]),
          body: (
            <WorkerizedJsonDiff
              value={currentTabState[locale] ?? {}}
              otherValue={pendingTabState[locale] ?? {}}
              isPretty
            />
          ),
        })),
    [currentTabState, formatMessage, localesToDisplay, pendingTabState],
  );

  return (
    <Modal
      show
      width={900}
      {...modalProps}
      header={reviewChangesAndPublishMessages.modalTitle}
    >
      <FlexColumn style={{ gap: StyleUtils.Spacing.md, marginBottom: '6px' }}>
        {showPreliminarySteps ? (
          <PreparePublishSteps
            saveDraftLoading={saveDraftLoading}
            savedStateLoading={savedLoading}
            liveStateLoading={deployedLoading}
          />
        ) : (
          <>
            <TabBar tabs={tabs} activePath={currentTab} unlinked />
            {deployedLoading || savedLoading ? (
              <LoadingSpinner />
            ) : (
              <div
                style={{
                  maxHeight: '60vh',
                  overflow: 'auto',
                }}
              >
                {tabHasChanges(currentTab) ? (
                  <>
                    <div>
                      {formatMessage(
                        reviewChangesAndPublishMessages.reviewChanges,
                      )}
                    </div>
                    {currentTab === 'messages' || currentTab === 'policies' ? (
                      <Accordion
                        destroyOnCollapse
                        defaultActiveKey={undefined}
                        style={{ marginTop: StyleUtils.Spacing.sm }}
                        items={accordionItems}
                      />
                    ) : (
                      <WorkerizedJsonDiff
                        value={currentTabState}
                        otherValue={pendingTabState}
                      />
                    )}
                  </>
                ) : (
                  <FlexRowCenterBoth style={{ padding: StyleUtils.Spacing.xl }}>
                    <FormattedMessage
                      {...reviewChangesAndPublishMessages.noChangesDetected}
                      values={{
                        module: formatMessage(
                          privacyCenterModuleNames[currentTab],
                        ),
                      }}
                    />
                  </FlexRowCenterBoth>
                )}
              </div>
            )}
            <div>
              <Button
                onClick={() => {
                  onPublish(savedData?.id);
                }}
                loading={deployedLoading || savedLoading || publishLoading}
                disabled={!isModified}
              >
                {formatMessage(
                  reviewChangesAndPublishMessages.setPrivacyCenterLive,
                )}
              </Button>
            </div>
          </>
        )}
      </FlexColumn>
    </Modal>
  );
};
