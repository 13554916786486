import groupBy from 'lodash/groupBy';

import { applyEnum, ObjByString } from '@transcend-io/type-utils';

/**
 * Group a list by a particular key. Similar to lodash.groupBy but preserving typing of keys.
 *
 * So far only works with the second arg extending string
 *
 * @param obj - The object to apply the function to
 * @param by - The key to group by
 * @param enm - The enum that the `by` refers to
 * @returns The object grouped by `by`
 */
export function getGroups<T extends ObjByString, TKey extends keyof T>(
  obj: T[],
  by: TKey | ((obj: T) => TKey),
  enm: { [k in string | number]: T[TKey] },
): { [k in T[TKey]]: T[] } {
  // Account for enum keys
  return {
    ...applyEnum(enm, () => []),
    ...groupBy<T>(obj, by),
  };
}
