import { createLoadable } from '@main/core-ui';

import { dataPracticesMessages } from './messages';

// Load the component dynamically
export default createLoadable({
  loader: () => import('./index'),
  messages: dataPracticesMessages,
  logo: 'barcode',
});
