import {
  FlexRowCenterVertical,
  Icon,
  useFormatMessageGeneric,
} from '@main/core-ui';
import React from 'react';
import { useTheme } from 'styled-components';

import { Button } from '../Button';
import { BreadcrumbItem } from './types';
import { StyledBreadcrumbNav } from './wrappers';

interface BreadcrumbProps {
  /** label to help screen-readers distinguish this nav from other navs */
  'aria-label': string;
  /** the items to display */
  items: readonly BreadcrumbItem[];
}

/**
 * Breadcrumb
 */
export const Breadcrumb: React.FC<BreadcrumbProps> = ({
  items,
  'aria-label': ariaLabel,
}) => {
  const { formatMessageGeneric } = useFormatMessageGeneric();
  const theme = useTheme();

  return (
    <StyledBreadcrumbNav aria-label={ariaLabel}>
      <FlexRowCenterVertical as="ol" style={{ flexWrap: 'wrap' }}>
        {items.map(({ icon, href, onClick, displayText, id }, i) => {
          const isLastItem = i === items.length - 1;
          return (
            <li key={id}>
              <FlexRowCenterVertical>
                <Button
                  icon={icon}
                  disabled={onClick === undefined && href === undefined}
                  onClick={onClick}
                  href={href}
                  variant="link"
                  style={{
                    fontWeight: 600,
                    color: isLastItem
                      ? theme.colors.transcendNavy
                      : theme.colors.transcendNavy2,
                    cursor: !href && !onClick ? 'unset' : undefined,
                  }}
                >
                  {formatMessageGeneric(displayText)}
                </Button>
                {!isLastItem && <Icon type="caret-right" />}
              </FlexRowCenterVertical>
            </li>
          );
        })}
      </FlexRowCenterVertical>
    </StyledBreadcrumbNav>
  );
};
