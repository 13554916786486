import FormCheck, {
  FormCheckProps,
  FormCheckType,
} from 'react-bootstrap/FormCheck';

/**
 * the checkbox
 */
export const BootstrapFormCheck = FormCheck;

/**
 * the props for the checkbox
 */
export type BootstrapFormCheckProps = FormCheckProps;

/**
 * the type of checkbox: checkbox, radio or switch
 */
export type BootstrapFormCheckType = FormCheckType;

/**
 * The input element of FormCheck
 */
export const BootstrapFormCheckInput = FormCheck.Input;
