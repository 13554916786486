import { useQueryParams } from '@main/core-ui';
import qs from 'query-string';
import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';

export * from './useRedirectToReLogin';

export const RedirectToLogin: React.FC = () => {
  const queryParams = useQueryParams();
  const location = useLocation();

  return (
    <Navigate
      to={{
        pathname: '/login',
        search: qs.stringify({
          redirectTo: queryParams.redirectTo || JSON.stringify(location),
        }),
      }}
    />
  );
};
