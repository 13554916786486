import { defineMessages } from '@transcend-io/internationalization';

export const onboardingItemCollectionTableMessages = defineMessages(
  'admin-dashboard.Auth.ActionItems.OnboardingItemsTable.onboardingItemCollectionTable',
  {
    markResolved: { defaultMessage: 'Mark Resolved' },
    markUnresolved: { defaultMessage: 'Mark Unresolved' },
    hide: { defaultMessage: 'Hide' },
    show: { defaultMessage: 'Show' },
    addItem: { defaultMessage: 'Add Item' },
    title: { defaultMessage: 'Title' },
    importFromCsv: { defaultMessage: 'Import from CSV' },
  },
);
