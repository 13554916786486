import AntSwitch from 'antd/lib/switch';
import styled from 'styled-components';

// @deprecated TODO: https://transcend.height.app/T-19886 - remove ant
export const StyledAntSwitch = styled(AntSwitch)`
  &.ant-switch:after {
    background-color: ${({ theme }) => theme.colors.gray3};
    top: -5px;
    margin-left: -5px;
  }

  &.ant-switch-checked:after {
    margin-left: 5px;
  }

  height: 10px;
  margin: 4px;
  min-width: 30px;
  max-width: 30px;
`;
