import { BarRounded } from '@visx/shape';
import { animated } from 'react-spring';
import styled, { css } from 'styled-components';

import { BAR_WIDTH, HOVER_SCALE } from '../constants';

export const ChartSVGBars = styled(animated.svg)`
  position: absolute;
  bottom: 0;
  left: 0;
`;

interface StyledBarRoundedProps {
  /** x position of bar in svg */
  x: number;
  /** color of bar r */
  r: string;
  /** color of bar g */
  g: string;
  /** color of bar b */
  b: string;
  /** bar is manually expanded */
  expanded: 'true' | 'false';
}

const expansionStyles = ({
  x,
  r,
  g,
  b,
}: Omit<StyledBarRoundedProps, 'expanded'>): ReturnType<typeof css> => css`
  transform: ${`translateX(-${
    HOVER_SCALE * x - x + (HOVER_SCALE * BAR_WIDTH - BAR_WIDTH) / 2
  }px) scaleX(${HOVER_SCALE})`};
  filter: ${`drop-shadow(0px 0px 4px rgb(${r} ${g} ${b} / 0.6))`};
`;

export const StyledBarRounded = styled(BarRounded)<StyledBarRoundedProps>`
  :hover {
    ${expansionStyles}
  }

  ${({ expanded, x, r, g, b }) =>
    expanded === 'true' ? expansionStyles({ x, r, g, b }) : ''}
  cursor: pointer;
  transition: all 200ms ease-out;
`;
