import { makeEnum } from '@transcend-io/type-utils';

/**
 * Airgap bundle type
 */
export enum AirgapBundleType {
  /** A deployed production-ready airgap bundle */
  Deployed = 'DEPLOYED',
  /** An airgap bundle that's awaiting deploy */
  DeployPending = 'DEPLOY_PENDING',
  /** An airgap bundle that is in preview mode for the organization */
  Preview = 'PREVIEW',
  /** An airgap bundle that is being rolled back */
  RollbackPending = 'ROLLBACK_PENDING',
  /** An error was encountered during prod bundle deployment */
  Error = 'ERROR',
  /** An error was encountered during test bundle deployment */
  TestError = 'TEST_ERROR',
}

/**
 * The IAB Agreements that publishers can agree to
 */
export const IabAgreement = makeEnum({
  /** Limited Service Provider Agreement */
  LSPA: 'LSPA',
  /** Multi-State Privacy Agreement */
  MSPA: 'MSPA',
});

/** Type override */
export type IabAgreement = (typeof IabAgreement)[keyof typeof IabAgreement];

/**
 * Set of IAB signals that we can communicate
 */
export const IABSignal = makeEnum({
  GppUsp: 'GPP_USP',
});

/** Type override */
export type IABSignal = (typeof IABSignal)[keyof typeof IABSignal];

/**
 * Module type
 */
export const ModuleKind = makeEnum({
  ui: 'ui',
  plugin: 'plugin',
  policies: 'policies',
});

/** Type override */
export type ModuleKind = (typeof ModuleKind)[keyof typeof ModuleKind];
