import { defineMessages } from '@transcend-io/internationalization';

/**
 * Message definitions for buttons in the toolbar
 */
export const toolbarMessages = defineMessages(
  'ad-core-components.RichEditor.Toolbar.toolbar',
  {
    addColumnBefore: {
      defaultMessage: 'Add column before',
    },
    addColumnAfter: {
      defaultMessage: 'Add column after',
    },
    addRowBefore: {
      defaultMessage: 'Add row before',
    },
    addRowAfter: {
      defaultMessage: 'Add row after',
    },
    bold: {
      defaultMessage: 'Bold',
    },
    bulletedList: {
      defaultMessage: 'Bulleted list',
    },
    clearFormatting: {
      defaultMessage: 'Clear formatting',
    },
    heading: {
      defaultMessage: 'Heading {level}',
    },
    image: {
      defaultMessage: 'Image',
    },
    italic: {
      defaultMessage: 'Italic',
    },
    link: {
      defaultMessage: 'Link',
    },
    mergeCells: {
      defaultMessage: 'Merge cells',
    },
    orderedList: {
      defaultMessage: 'Ordered list',
    },
    removeColumn: {
      defaultMessage: 'Remove column',
    },
    removeRow: {
      defaultMessage: 'Remove row',
    },
    removeTable: {
      defaultMessage: 'Remove table',
    },
    strikethrough: {
      defaultMessage: 'Strikethrough',
    },
    splitCell: {
      defaultMessage: 'Split cell',
    },
    table: {
      defaultMessage: 'Table',
    },
    toggleHeaderCell: {
      defaultMessage: 'Toggle header cell',
    },
    toggleHeaderColumn: {
      defaultMessage: 'Toggle header column',
    },
    toggleHeaderRow: {
      defaultMessage: 'Toggle header row',
    },
    underline: {
      defaultMessage: 'Underline',
    },
    unlink: {
      defaultMessage: 'Remove link',
    },
  },
);
