import { Badge } from '@main/core-ui';
import React from 'react';
import { useIntl } from 'react-intl';

import { useActionItemStatusCount } from '../hooks';

/**
 * ActionItemCountBadge
 */
export const ActionItemCountBadge: React.FC = () => {
  const { count, loading } = useActionItemStatusCount();
  const { formatNumber } = useIntl();

  return count === undefined || count === 0 || loading ? null : (
    <Badge
      round
      color="danger"
      style={{
        maxHeight: '24px',
        padding: '2px 6px',
      }}
      ignoreAccessibilityAndUseLightText
    >
      {formatNumber(count, {
        notation: 'compact',
      })}
    </Badge>
  );
};
