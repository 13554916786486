import { DataViewWrapper, Table, TableWrapper } from '@main/ad-core-components';
import { useGetConsentManager } from '@main/admin-dashboard/src/ConsentManager/useGetConsentManager';
import {
  endpoints,
  SoftwareDevelopmentKitOrderField,
} from '@main/code-scanning-types';
import {
  buildUseQuery,
  ErrorAlert,
  Skeleton,
  usePagination,
} from '@main/core-ui';
import { MOBILE_SDK_CODE_PACKAGE_TYPES } from '@main/datamap-types';
import { OrderDirection } from '@main/schema-utils';
import React, { useMemo } from 'react';

import {
  CATALOG_COLUMN,
  CODE_PACKAGE_TYPE_COLUMN,
  CODE_PACKAGES_COLUMN,
  DESCRIPTION_COLUMN,
  NAME_COLUMN,
  REGISTRY_URL_COLUMN,
  REPOSITORIES_COLUMN,
  REPOSITORY_URL_COLUMN,
} from '../../../CodeScanning/SoftwareDevelopmentKits/columns';
import { ACTIONS_COLUMN } from './columns';
import { sdkColumnHeaderMessages, sdkMessages } from './messages';

const useSoftwareDevelopmentKits = buildUseQuery(
  endpoints.softwareDevelopmentKits,
  'TriageSoftwareDevelopmentKits',
);

export const TriageSDKs: React.FC = () => {
  const [consentManager, { loading: bundleLoading, error: bundleError }] =
    useGetConsentManager();

  const columns = useMemo(() => {
    const cols = [
      NAME_COLUMN,
      CODE_PACKAGE_TYPE_COLUMN,
      REGISTRY_URL_COLUMN,
      CODE_PACKAGES_COLUMN,
      REPOSITORIES_COLUMN,
      DESCRIPTION_COLUMN,
      REPOSITORY_URL_COLUMN,
      CATALOG_COLUMN,
    ];
    return consentManager?.id
      ? [...cols, ACTIONS_COLUMN({ airgapBundleId: consentManager.id })]
      : cols;
  }, [consentManager?.id]);

  const filterBy = {
    linkedToConsentSdk: false,
    codePackageTypes: MOBILE_SDK_CODE_PACKAGE_TYPES,
  };

  const paginationResult = usePagination({
    queryParam: 'page',
    resetPageOnChange: [filterBy],
  });

  const { currentPage, useMaxPage, pageSize } = paginationResult;

  const { data, loading, error } = useSoftwareDevelopmentKits({
    variables: {
      first: pageSize,
      offset: (currentPage - 1) * pageSize,
      filterBy,
      orderBy: [
        {
          field: SoftwareDevelopmentKitOrderField.Name,
          direction: OrderDirection.ASC,
        },
      ],
    },
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
  });

  useMaxPage(data?.totalCount ? data.totalCount / pageSize : undefined);

  return (
    <DataViewWrapper>
      {bundleLoading ? (
        <>
          <Skeleton />
          <Skeleton />
          <Skeleton />
        </>
      ) : bundleError || error ? (
        <ErrorAlert error={bundleError || error} />
      ) : (
        <Table
          data={data?.nodes ?? []}
          freezeLeftmostColumns={3}
          columns={columns}
          columnHeaderMessages={sdkColumnHeaderMessages}
          paginationOptions={{
            paginationResult,
            recordCount: data?.totalCount ?? 0,
            recordCountMessage: sdkMessages.sdkCount,
          }}
          loading={loading}
          viewWrapper={TableWrapper}
          noDataMessage={sdkMessages.noDataMessage}
        />
      )}
    </DataViewWrapper>
  );
};
