import { useEffect, useState } from 'react';

/** Worker task state */
export enum MultiWorkerLoadingState {
  AllPending = 'ALL_PENDING',
  AllFinished = 'ALL_FINISHED',
  Intermediate = 'INTERMEDIATE',
}

/**
 * The result of the useWebWorker hook
 */
export type UseMultiWebWorkerResult<
  ModuleKey extends string,
  RawResultI,
  ReducedResultI,
> =
  | {
      /** Is the result pending? */
      isLoading: MultiWorkerLoadingState.AllPending;
      /** The result from the worker */
      results: undefined;
    }
  | {
      /** Is the result pending? */
      isLoading: MultiWorkerLoadingState.Intermediate;
      /** The reduced result from the worker */
      results: Partial<ReducedResultI>;
      /** Raw map of results pre-reduction */
      rawResults: { [key in ModuleKey]: RawResultI };
    }
  | {
      /** Is the result pending? */
      isLoading: MultiWorkerLoadingState.AllFinished;
      /** The reduced result from the worker */
      results: ReducedResultI;
      /** Raw map of results pre-reduction */
      rawResults: { [key in ModuleKey]: RawResultI };
    };

/** semantically named mapping of module keys to their assigned workers */
type WorkerMap<K extends string> = { [key in K]: Worker };
/** semantically named mapping of module keys to their worker's initialization messages */
type MessageMap<K extends string, M> = { [key in K]: M };
/** semantically named mapping of module keys to their worker's raw results */
type RawResultMap<K extends string, R> = { [key in K]: R };

/**
 * Hook to use a web worker
 *
 * @param workersInit - The initialization function for the web worker
 * @param messageMap - The message to send to the web worker
 * @param reducer - The reduction function to send all results through on finish
 * @param reductionInit - The reduction function to send all results through on finish
 * @returns The result of the web worker and if it is loading
 */
export function useWebWorkers<
  MessageI,
  RawResultI,
  ReducedResultI,
  ModuleKey extends string = string,
>(
  workersInit: () => WorkerMap<ModuleKey>,
  messageMap: MessageMap<ModuleKey, MessageI>,
  reducer: (
    agg: Partial<ReducedResultI>,
    result: [ModuleKey, RawResultI],
  ) => Partial<ReducedResultI>,
  reductionInit: Partial<ReducedResultI>,
): UseMultiWebWorkerResult<ModuleKey, RawResultI, ReducedResultI> {
  const [workers, setWorkers] = useState<WorkerMap<ModuleKey> | undefined>(
    undefined,
  );
  const [isLoading, setIsLoading] = useState<MultiWorkerLoadingState>(
    MultiWorkerLoadingState.AllPending,
  );
  const [results, setResults] = useState<RawResultMap<ModuleKey, RawResultI>>(
    {} as RawResultMap<ModuleKey, RawResultI>,
  );

  // Initialize the worker
  useEffect(() => {
    const workers = workersInit();
    setWorkers(workers);
    return () => {
      (Object.values(workers) as Worker[]).forEach((worker) =>
        worker.terminate(),
      );
    };
  }, [workersInit]);

  // Send the messages and listen for the responses
  useEffect(() => {
    if (!workers) {
      return;
    }
    // NOTE: could refactor this to memoize completed results if they share a key
    setResults({} as { [key in ModuleKey]: RawResultI });

    setIsLoading(MultiWorkerLoadingState.AllPending);

    (Object.entries(workers) as [ModuleKey, Worker][]).forEach(
      ([key, worker]) => {
        // eslint-disable-next-line no-param-reassign
        worker.onmessage = (event: MessageEvent<RawResultI>) => {
          setResults((resultsObj) => ({ ...resultsObj, [key]: event.data }));
        };
      },
    );

    (Object.entries(workers) as [ModuleKey, Worker][]).forEach(
      ([key, worker]) => {
        worker.postMessage(messageMap[key]);
      },
    );
  }, [messageMap, workers]);

  useEffect(() => {
    const numResults = Object.values(results).length;
    if (numResults > 0 && numResults === Object.values(workers ?? {}).length) {
      setIsLoading(MultiWorkerLoadingState.AllFinished);
    } else if (
      numResults > 0 &&
      isLoading === MultiWorkerLoadingState.AllPending
    ) {
      setIsLoading(MultiWorkerLoadingState.Intermediate);
    }
  }, [isLoading, results, workers]);

  if (isLoading === MultiWorkerLoadingState.AllPending) {
    return { isLoading, results: undefined };
  }

  // NOTE: could refactor this to also use another worker?
  const reduction = (
    Object.entries(results) as [ModuleKey, RawResultI][]
  ).reduce<Partial<ReducedResultI>>(reducer, reductionInit);

  if (isLoading === MultiWorkerLoadingState.Intermediate) {
    return { isLoading, results: reduction, rawResults: results };
  }

  return {
    isLoading,
    results: reduction as ReducedResultI,
    rawResults: results,
  };
}
