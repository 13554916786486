import React, { useContext } from 'react';

/**
 * the return of a useState hook
 */
export type UseStateResult<T> = [T, (newValue: T | undefined) => void];

export interface DeepStateProviderProps<T> {
  /** the current value of the provider */
  value?: T;
  /** handler for when the consumer sets the value */
  onValueChanged?: (newValue: T | undefined) => void;
}

/**
 * A convenience wrapper around React Context and useState to create a deeply
 * accessible useState hook via React Context. When compared to redux, this has
 * the benefit of being directly traceable via imports (no background magic), as
 * the consumed hook is imported directly from the state source.
 *
 * This should be used primarily for non-persistent storage (shouldn't be used for
 * caching). Use redux for more persistent state, as our configuration already
 * persists redux to localStorage
 */
export function createDeepState<T>(defaultValue?: T): {
  /** the provider for the useState data */
  Provider: React.FC<DeepStateProviderProps<T>>;
  /** the use state hook for this shared context */
  useValue: () => UseStateResult<T | undefined>;
  /** the use state hook for this shared context which will throw if not present */
  useValueRequired: () => UseStateResult<T>;
} {
  const DeepContext = React.createContext<UseStateResult<T | undefined>>([
    undefined,
    // eslint-disable-next-line no-empty-function
    () => {},
  ]);

  const Provider: React.FC<DeepStateProviderProps<T>> = ({
    children,
    value,
    onValueChanged,
  }) => (
    <DeepContext.Provider
      value={[
        value ?? defaultValue,
        (newValue: T | undefined) => {
          onValueChanged?.(newValue);
        },
      ]}
    >
      {children}
    </DeepContext.Provider>
  );

  return {
    Provider,
    useValue: () => useContext(DeepContext),
    useValueRequired: () => {
      const [val, setter] = useContext(DeepContext);
      if (val === undefined || val === null) {
        throw new Error(
          `Deep state required hook requires a value, but received ${val}`,
        );
      }
      return [val, setter as (newValue: T | undefined) => void];
    },
  };
}
