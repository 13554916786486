import {
  GenericFormattedMessage,
  GenericMessageDescriptor,
  IWithClassName,
  IWithStyle,
} from '@main/core-ui';
import React, { MouseEventHandler } from 'react';

import {
  StyledCard,
  StyledCardLink,
  StyledCardTitle,
  StyledCardTitleContainer,
  StyledCardTitleWrapper,
} from './wrappers';

export interface CardProps {
  /** Optional HTML id for the card element */
  id?: string;
  /** Optional card title, i.e. Connected Services Activity cards */
  title?: GenericMessageDescriptor;
  /**
   * Additional react components that will be displayed
   * inline and right aligned to the title
   */
  titleExtras?: React.ReactNode;
  /** Centered title text or not */
  centered?: boolean;
  /** Optional custom padding */
  padding?: string;
  /** Optional custom border radius */
  borderRadius?: string;
  /** Optional card onClick link */
  href?: string;
  /** Optional card onClick handler */
  onClick?: MouseEventHandler;
  /** Optional title style override */
  titleStyle?: React.CSSProperties;
}

/**
 * The main card component for blocks of content in ADv2.
 * Standardizes styling such as border, colors, padding, etc.
 * Abstracts out padding and border radius explicitly in case those have to be tweaked,
 * but if any other style changes are desired then this component probably shouldn't be used,
 * or you can utilize styled-components with `styled(Card)`...`` instead.
 */
export const Card: React.FC<CardProps & IWithClassName & IWithStyle> = ({
  title,
  centered = false,
  padding = '24px',
  borderRadius = '12px',
  href,
  children,
  titleExtras,
  titleStyle,
  ...cardProps
}) => {
  const cardContent = (
    <>
      {title && !titleExtras && (
        <StyledCardTitle style={titleStyle} centered={centered}>
          <GenericFormattedMessage message={title} />
        </StyledCardTitle>
      )}
      {title && titleExtras && (
        <StyledCardTitleContainer style={{ alignItems: 'center' }}>
          <StyledCardTitleWrapper style={titleStyle}>
            {title}
          </StyledCardTitleWrapper>
          {titleExtras}
        </StyledCardTitleContainer>
      )}
      {children}
    </>
  );
  return href ? (
    <StyledCardLink
      to={href}
      padding={padding}
      radius={borderRadius}
      {...cardProps}
    >
      {cardContent}
    </StyledCardLink>
  ) : (
    <StyledCard padding={padding} radius={borderRadius} {...cardProps}>
      {cardContent}
    </StyledCard>
  );
};
