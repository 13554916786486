import { defineMessages } from '@transcend-io/internationalization';
import type { RequestStatus } from '@transcend-io/privacy-types';

/**
 * Default descriptions
 */
export const statusDescriptionMessages = defineMessages<RequestStatus>(
  'dsr-types.helpers.statuses.statusDescription',
  {
    REQUEST_MADE: {
      defaultMessage: 'The data subject submitted a request',
    },
    FAILED_VERIFICATION: {
      defaultMessage:
        'One of the identifiers could not be verified and the request could not continue',
    },
    ENRICHING: {
      defaultMessage:
        'The identity of the data subject is being verified by the preflight checks',
    },
    ON_HOLD: {
      defaultMessage: 'The request was placed on temporary hold',
    },
    WAITING: {
      defaultMessage:
        'The request is queued to begin compiling data across your integrations',
    },
    COMPILING: {
      defaultMessage:
        'Your request is being processed across all of your integrations',
    },
    APPROVING: {
      defaultMessage: 'The request is ready for review and approval',
    },
    DELAYED: {
      defaultMessage:
        'The integrations have been successfully queried, and we are delaying the execution of the erasure request',
    },
    COMPLETED: {
      defaultMessage:
        'The request was completed and any file access has been revoked',
    },
    DOWNLOADABLE: {
      defaultMessage:
        'The request was compiled and is available for the data subject to download',
    },
    VIEW_CATEGORIES: {
      defaultMessage:
        'The request is not available for download, but its associated data collection categories are visible',
    },
    CANCELED: {
      defaultMessage: 'The request was canceled and not fulfilled',
    },
    SECONDARY: {
      defaultMessage: 'Removing data across your integrations',
    },
    SECONDARY_COMPLETED: {
      defaultMessage:
        'All integrations reported that they removed the data they hold on the data subject',
    },
    SECONDARY_APPROVING: {
      defaultMessage:
        'The request is ready for review and approval before sending final email confirming deletion',
    },
    REVOKED: {
      defaultMessage: 'The data subject revoked the request',
    },
  },
);

/**
 * Default descriptions
 */
export const statusTitleMessages = defineMessages<RequestStatus>(
  'dsr-types.helpers.statuses.statusTitle',
  {
    REQUEST_MADE: {
      defaultMessage: 'New data subject request',
    },
    FAILED_VERIFICATION: {
      defaultMessage: 'Verification failed',
    },
    ENRICHING: {
      defaultMessage: 'Preflight',
    },
    ON_HOLD: {
      defaultMessage: 'On hold',
    },
    WAITING: {
      defaultMessage: 'Queued',
    },
    COMPILING: {
      defaultMessage: 'Compiling',
    },
    APPROVING: {
      defaultMessage: 'Waiting for approval',
    },
    DELAYED: {
      defaultMessage: 'Delaying execution',
    },
    COMPLETED: {
      defaultMessage: 'Completed',
    },
    DOWNLOADABLE: {
      defaultMessage: 'Downloadable',
    },
    VIEW_CATEGORIES: {
      defaultMessage: 'View categories',
    },
    CANCELED: {
      defaultMessage: 'Canceled',
    },
    SECONDARY: {
      defaultMessage: 'Removing data',
    },
    SECONDARY_COMPLETED: {
      defaultMessage: 'Data removed',
    },
    SECONDARY_APPROVING: {
      defaultMessage: 'Approval after data removed',
    },
    REVOKED: {
      defaultMessage: 'Revoked',
    },
  },
);
