import { defineMessages } from '@transcend-io/internationalization';

import { ApiKeyStatus } from '@main/access-control-types';

export const apiKeyStatusMessages = defineMessages<ApiKeyStatus>(
  'admin-dashboard.Infrastructure.ApiKeys.apiKeyStatus',
  {
    [ApiKeyStatus.Fresh]: {
      defaultMessage: 'Fresh',
    },
    [ApiKeyStatus.Warning]: {
      defaultMessage: 'Warning',
    },
    [ApiKeyStatus.Stale]: {
      defaultMessage: 'Stale',
    },
  },
);

export const apiKeyStatusDescriptionMessages = defineMessages<
  ApiKeyStatus.Warning | ApiKeyStatus.Stale
>('admin-dashboard.Infrastructure.ApiKeys.apiKeyStatusDescription', {
  [ApiKeyStatus.Warning]: {
    defaultMessage: 'Warning - API Key is more than one year old',
  },
  [ApiKeyStatus.Stale]: {
    defaultMessage:
      'Stale - API Key is more than two years old. Please consider rotating this key soon.',
  },
});

export const apiKeysFilterMessages = defineMessages(
  'admin-dashboard.Infrastructure.ApiKeys.apiKeysFilter',
  {
    status: {
      defaultMessage: 'Status',
    },
  },
);

export const apiKeysFormMessages = defineMessages(
  'admin-dashboard.Infrastructure.ApiKeys.apiKeysForm',
  {
    title: {
      defaultMessage: 'Title',
    },
    titlePlaceholder: {
      defaultMessage: 'My Title',
    },
  },
);

export const apiKeysMessages = defineMessages(
  'admin-dashboard.Infrastructure.ApiKeys.apiKeys',
  {
    header: {
      defaultMessage: 'API Keys',
    },
    apiKeyAge: {
      defaultMessage: '{age} {age, plural, one {day} other {days}} old',
    },
    lastUsedAt: {
      defaultMessage: 'Last used at: {date}',
    },
    noMatches: {
      defaultMessage: 'No matching API keys',
    },
    none: {
      defaultMessage: 'No API keys have been created',
    },
    dataSilos: {
      defaultMessage:
        '{count} {count, plural, one {integration} other {integration}}',
    },
    scopes: {
      defaultMessage: '{count} {count, plural, one {scope} other {scope}}',
    },
    // create
    button: {
      defaultMessage: 'Create API Key',
    },
    create: {
      defaultMessage: 'New API Key',
    },
    deleted: {
      defaultMessage: 'Deleted',
    },
    success: {
      defaultMessage: 'Successfully created API key!',
    },
    // duplicate
    duplicateHeader: {
      defaultMessage:
        'Are you sure you want to duplicate this API key? The scopes will be copied to a new secret.',
    },
    duplicateSubmit: {
      defaultMessage: 'Duplicate',
    },
    // update
    updateHeader: {
      defaultMessage: 'Edit API Key - {title}',
    },
    submit: {
      defaultMessage: 'Update API key',
    },
    updateSuccess: {
      defaultMessage: 'Successfully updated API key!',
    },
    // select
    placeholder: {
      defaultMessage: 'Select API Keys',
    },
    // created modal
    createdModalHeader: {
      defaultMessage: 'API Key Created: {title}',
    },
    createdModalInfo: {
      defaultMessage:
        'Save this API key somewhere secure. It should be treated as a secret that is not committed to code, and should be cycled regularly. Once you close this modal, you will not be able to view the API key secret again.', // eslint-disable-line max-len
    },
  },
);
