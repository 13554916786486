import { mkInput, mkType, SchemaToType } from '@main/schema-utils';

export const SavingsConfigurationPreview = mkType({
  name: 'SavingsConfigurationPreview',
  comment: 'The savings configuration for the org',
  fields: {
    numManualSystems: {
      comment:
        'Number of external systems where staff manually action data from',
      type: 'float',
    },
    numEmailSystems: {
      comment:
        'Number of extra external systems where staff email the vendor requesting ' +
        "action on a subject's data not yet connected in transcend",
      type: 'float',
    },
    legalSalary: {
      comment: 'Salary of Legal/Operations Manager',
      type: 'float',
    },
    salesSalary: {
      comment: 'Salary of Sales/Marketing/HR Manager',
      type: 'float',
    },
    engineerSalary: {
      comment: 'Salary of Software Engineer',
      type: 'float',
    },
  },
});

/** Override type */
export type SavingsConfigurationPreview = SchemaToType<
  typeof SavingsConfigurationPreview
>;

export const UpdateSavingsConfigurationInput = mkInput({
  name: 'UpdateSavingsConfigurationInput',
  comment: 'Input for updating the savings configuration for the org',
  fields: {
    numManualSystems: {
      comment:
        'Number of external systems where staff manually action data from',
      type: 'float',
    },
    numEmailSystems: {
      comment:
        'Number of extra external systems where staff email the vendor requesting ' +
        "action on a subject's data not yet connected in transcend",
      type: 'float',
    },
    legalSalary: {
      comment: 'Salary of Legal/Operations Manager',
      type: 'float',
    },
    salesSalary: {
      comment: 'Salary of Sales/Marketing/HR Manager',
      type: 'float',
    },
    engineerSalary: {
      comment: 'Salary of Software Engineer',
      type: 'float',
    },
  },
});

/** Override type */
export type UpdateSavingsConfigurationInput = SchemaToType<
  typeof UpdateSavingsConfigurationInput
>;
