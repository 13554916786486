import { ReactSelect } from '@main/core-ui';
import { createFakeUuidv4 } from '@main/utils';
import React, { useMemo } from 'react';
import { MessageDescriptor, useIntl } from 'react-intl';
import { NamedProps } from 'react-select';

import { RangeInputProps, TrackMask } from '../RangeInput';
import { rangePercentMessages as messages } from './messages';
import { NumberInput } from './wrappers';

/**
 * direction of range relative to current value of slider/numberInput
 */
export type RelativeRangeDirection = 'above' | 'below' | 'is';

export interface RelativeRangeOption {
  /** value displayed in dropdown */
  label: string;
  /** value passed to RangeInput */
  value: RelativeRangeDirection;
}

export interface RangePercentProps {
  /** input element, for use with @main/ad-core-components/RangeInput */
  children: React.ReactElement<RangeInputProps>;
  /** title displayed at top of component */
  titleMessage: MessageDescriptor;
  /** range direction relative to value */
  rangeDirection: RelativeRangeDirection;
  /** callback when dropdown value changes */
  onChangeDirection: (value: RelativeRangeDirection) => void;
  /** Whether or not to show the percent input */
  hideInput?: boolean;
  /** Whether to show the title at the top */
  showTitle?: boolean;
  /** The react-select menu position */
  menuPosition?: NamedProps['menuPosition'];
}

const RANGE_DIRECTION_MASKS: Record<RelativeRangeDirection, TrackMask> = {
  above: 'below',
  below: 'above',
  is: 'none',
};

export const RangePercent: React.FC<RangePercentProps> = ({
  children: child,
  titleMessage,
  rangeDirection = 'above',
  onChangeDirection,
  hideInput = false,
  showTitle = true,
  menuPosition = 'absolute',
}) => {
  const rangeLabelId = useMemo(() => createFakeUuidv4(), []);
  const { formatMessage } = useIntl();
  const { value, onChange } = child.props;
  const relativeRangeOptions: Array<RelativeRangeOption> = useMemo(
    () => [
      {
        label: formatMessage(messages.belowOptionLabel),
        value: 'below',
      },
      {
        label: formatMessage(messages.aboveOptionLabel),
        value: 'above',
      },
      {
        label: formatMessage(messages.isOptionLabel),
        value: 'is',
      },
    ],
    [formatMessage],
  );

  const rangeInputDescriptions: Record<
    NonNullable<RelativeRangeDirection>,
    string
  > = useMemo(
    () => ({
      above: formatMessage(messages.above, {
        title: titleMessage.defaultMessage,
        value,
      }),
      below: formatMessage(messages.below, {
        title: titleMessage.defaultMessage,
        value,
      }),
      is: formatMessage(messages.is, {
        title: titleMessage.defaultMessage,
        value,
      }),
    }),
    [formatMessage, titleMessage.defaultMessage, value],
  );
  const title = formatMessage(titleMessage);
  const rangeInput = React.cloneElement<RangeInputProps>(child, {
    ...child.props,
    name: title,
    trackMask: RANGE_DIRECTION_MASKS[rangeDirection],
    // hide redundant input
    'aria-hidden': true,
  });

  return (
    <fieldset style={{ minWidth: '250px', textAlign: 'center' }}>
      <legend
        style={{
          fontSize: '20px',
          color: 'black',
          fontWeight: 400,
          ...(!showTitle ? { display: 'none' } : {}),
        }}
      >
        {title}
      </legend>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          marginTop: '16px',
          marginBottom: '6px',
        }}
      >
        <ReactSelect<false, RelativeRangeOption>
          menuPosition={menuPosition}
          style={{ marginRight: '12px', flex: 1 }}
          onChange={(value) => onChangeDirection(value?.value || 'above')}
          options={relativeRangeOptions}
          value={
            relativeRangeOptions.filter((o) => o.value === rangeDirection)[0]
          }
          variant="light"
        />
        <span style={{ display: 'none' }} id={rangeLabelId}>
          {rangeInputDescriptions[rangeDirection]}
        </span>
        <NumberInput
          style={hideInput ? { display: 'none' } : {}}
          type="number"
          value={value === undefined ? '' : value}
          aria-describedby={rangeLabelId}
          name={title}
          onChange={(evt) => {
            const newVal =
              evt.target.value === '' ? undefined : parseInt(evt.target.value);
            const clampedNewVal =
              newVal === undefined
                ? newVal
                : Math.max(Math.min(100, newVal), 0);
            onChange(clampedNewVal);
          }}
        />
        {!hideInput && <span>%</span>}
      </div>
      {rangeInput}
    </fieldset>
  );
};
