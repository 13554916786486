import { FilterConfigItem } from '@main/ad-core-components';
import {
  SelectAgentFiles,
  SelectedAgentFile,
} from '@main/admin-dashboard/src/Pathfinder/components/SelectAgentFiles';
import { buildUseLazyQuery } from '@main/core-ui';
import { endpoints } from '@main/prompt-types';
import { ID } from '@main/schema-utils';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { MessageDescriptor } from 'react-intl';

import { filterManagerHookMessages } from './messages';

/**
 * Filters definition with property for agent file values specified
 */
type AgentFileEnrichedFilters = Record<string, unknown> &
  Record<
    /**
     * AgentFile values that are currently filtering the table
     */
    string,
    SelectedAgentFile[] | undefined
  >;

const useAgentFiles = buildUseLazyQuery(endpoints.agentFiles);

export const useAgentFileFilters = <
  T extends Record<string, unknown>,
  E extends AgentFileEnrichedFilters,
>({
  agentFileIdsFilterKey = 'agentFileIds',
  filters,
  setFilters,
  label,
  enrichedAgentFileKey = 'agentFiles',
}: {
  /** The property name of the filter for agentFile ids */
  agentFileIdsFilterKey?: keyof T;
  /** The currently applied filters */
  filters?: T;
  /** Callback when the filters are changed */
  setFilters: (filters: T) => void;
  /** Label for the filter menu */
  label: string | MessageDescriptor;
  /** The key to use if more than one useAgentFileFilters is used in a FilterManager */
  enrichedAgentFileKey?: keyof E;
}): {
  /**  The agentFile keys enabled on this table */
  selectedAgentFiles: SelectedAgentFile[];
  /** The filter configuration to be passed to the filter manager */
  agentFileFiltersConfig: FilterConfigItem<E>;
  /** Callback for when the filter is cleared in the filter manager */
  clearAgentFileFilters: (key: Extract<keyof E, string>) => void;
} => {
  const getSelectedAgentFiles = useAgentFiles();
  const [selectedAgentFiles, setSelectedAgentFiles] = useState<
    SelectedAgentFile[]
  >([]);
  const [fetchingAgentFiles, setFetchingAgentFiles] = useState(false);

  // Populate initial complex filter values
  useEffect(() => {
    if (filters) {
      const agentFileIds = (filters[agentFileIdsFilterKey] ??
        []) as ID<'agentFile'>[];

      const filtersAndEnrichedFiltersMatch =
        agentFileIds.sort().join() ===
        selectedAgentFiles
          .map(({ id }) => id)
          .sort()
          .join();

      if (agentFileIds.length === 0 && selectedAgentFiles.length > 0) {
        setSelectedAgentFiles([]);
      } else if (!filtersAndEnrichedFiltersMatch && !fetchingAgentFiles) {
        // Prevent over-firing this setter while data is still being fetched
        setFetchingAgentFiles(true);
        getSelectedAgentFiles({
          filterBy: { ids: agentFileIds },
        })
          .then(({ data }) => {
            setSelectedAgentFiles(data.nodes);
          })
          .finally(() => {
            setFetchingAgentFiles(false);
          });
      }
    }
  }, [
    filters,
    fetchingAgentFiles,
    getSelectedAgentFiles,
    selectedAgentFiles.length,
    agentFileIdsFilterKey,
    selectedAgentFiles,
  ]);

  const agentFileFiltersConfig = useMemo(
    () =>
      ({
        filterKey: enrichedAgentFileKey,
        label,
        pillOptions: {
          label: ({
            filterValues: { [enrichedAgentFileKey]: agentFiles = [] },
            index = 0,
          }) => {
            const model = agentFiles[index];
            return model?.name;
          },
        },
        filter: (
          <SelectAgentFiles
            isMulti
            placeholderDescriptor={
              filterManagerHookMessages.agentFilesPlaceholder
            }
            menuPosition="absolute"
            value={selectedAgentFiles}
            showOutline={false}
            onChange={(agentFiles) => {
              setSelectedAgentFiles([...agentFiles]);
              setFilters({
                ...filters,
                [agentFileIdsFilterKey]: agentFiles.map(({ id }) => id),
              } as T);
            }}
          />
        ),
      }) as FilterConfigItem<AgentFileEnrichedFilters>,
    [
      enrichedAgentFileKey,
      label,
      selectedAgentFiles,
      filters,
      agentFileIdsFilterKey,
    ],
  );

  const clearAgentFileFilters = useCallback(
    (key) => {
      if (key === enrichedAgentFileKey) {
        setFilters({
          ...filters,
          [agentFileIdsFilterKey]: [],
        } as T);
        setSelectedAgentFiles([]);
      }
    },
    [enrichedAgentFileKey, filters, agentFileIdsFilterKey],
  );

  return {
    selectedAgentFiles,
    agentFileFiltersConfig,
    clearAgentFileFilters,
  };
};
