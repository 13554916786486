import { Organization } from '@main/access-control-types';
import {
  FlexColumn,
  StyleUtils,
  useFeatureFlags,
  useFormatMessageGeneric,
} from '@main/core-ui';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { selectRequiredOrganization } from '../../../Auth/App/selectors';
import { AD_NAV_ITEMS } from './constants';
import { getKeyForNavItem, isNavGateAllowed } from './helpers';
import { NavMenuLinkWithChildren } from './NavMenuLinkWithChildren';
import { NavItemCollapsibleHeightWrapper } from './wrappers';

interface NavMenuItemsProps {
  /** is the side menu skinny or wide? */
  wideNavbar?: boolean;
}

/**
 * NavMenuItems
 */
export const NavMenu: React.FC<NavMenuItemsProps> = ({ wideNavbar }) => {
  const { featureSet, tier }: Organization = useSelector(
    selectRequiredOrganization,
  );
  const featureFlags = useFeatureFlags();
  const { formatMessageGeneric } = useFormatMessageGeneric();

  const filteredSections = useMemo(
    () =>
      AD_NAV_ITEMS.filter((section) =>
        isNavGateAllowed(section.gate, featureFlags, featureSet, tier),
      ),
    [featureFlags, featureSet, tier],
  );

  return (
    <FlexColumn
      style={{
        gap: StyleUtils.Spacing.sm,
        paddingBottom: '2em',
        flexGrow: 1,
        overflowY: 'auto',
        overflowX: 'hidden',
      }}
    >
      {filteredSections.map((section, i) => (
        <React.Fragment key={(section.label as any)?.id ?? i}>
          {section.label && (
            <NavItemCollapsibleHeightWrapper
              style={{
                textTransform: 'uppercase',
                flexShrink: 0,
                fontSize: 10,
                maxHeight: wideNavbar ? 15 : 0,
                opacity: wideNavbar ? 1 : 0,
                fontWeight: 600,
                letterSpacing: '.8px',
              }}
            >
              {formatMessageGeneric(section.label)}
            </NavItemCollapsibleHeightWrapper>
          )}
          <FlexColumn>
            {section.items
              .filter(
                (item) =>
                  isNavGateAllowed(item.gate, featureFlags, featureSet, tier) ||
                  item.gate?.ctaPage,
              )
              .map((item, i) => (
                <NavMenuLinkWithChildren
                  key={getKeyForNavItem(item, i)}
                  item={item}
                  wideNavbar={wideNavbar}
                />
              ))}
          </FlexColumn>

          {i < filteredSections.length - 1 && (
            <hr
              style={{
                margin: `${StyleUtils.Spacing.xs} 0`,
                marginRight: '1.25em',
                minHeight: 1,
              }}
            />
          )}
        </React.Fragment>
      ))}
    </FlexColumn>
  );
};
