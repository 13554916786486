/* eslint-disable max-lines */
import {
  DataCategoryType,
  ProcessingPurpose,
  SubDataPointDataSubCategoryGuessStatus,
} from '@transcend-io/privacy-types';

import { TeamPreview, UserPreview } from '@main/access-control-types';
import { AttributeInput, AttributeValue } from '@main/attribute-types';
import { mkInput, mkType, type SchemaToType } from '@main/schema-utils';
import { ColumnEncryptionType } from '@main/sombra-types';

import { DataPoint, DataPointSubDataPointInput } from './dataPoint';
import { DataSubCategory } from './dataSubCategory';
import { DataSubject } from './dataSubject';
import {
  ContentClassificationStatus,
  Controllership,
  IncludeNotPersonalData,
  RefetchSampleMethod,
  RetentionType,
} from './enums';
import { PurposeSubCategory } from './processingPurpose';
import { SubDataPointDataSubCategoryGuess } from './SubDataPointDataSubCategoryGuess';
import { VendorPreview } from './vendorPreview';

export const SubDataPoint = mkType({
  name: 'SubDataPoint',
  comment:
    'A property of a datapoint, corresponding to a single piece of personal data',
  fields: {
    id: {
      comment: 'The ID of the subdatapoint',
      type: 'id',
      modelName: 'subDataPoint',
    },
    createdAt: {
      comment: 'The time the subdatapoint was first discovered',
      type: 'Date',
    },
    name: {
      comment: 'The name of the subdatapoint',
      type: 'string',
    },
    slug: {
      comment: 'The slug of the sub datapoint for templating',
      type: 'string',
    },
    dataPoint: {
      comment: 'The datapoint for this subdatapoint',
      type: DataPoint,
    },
    dataSiloId: {
      comment: 'The ID of the dataSilo for this subdatapoint',
      type: 'id',
      modelName: 'dataSilo',
    },
    dataPointId: {
      comment: 'The ID of the datapoint to associate with this subDataPoint',
      type: 'id',
      modelName: 'dataPoint',
    },
    description: {
      comment: 'A description for the subdatapoint',
      type: 'string',
      optional: true,
    },
    accessRequestVisibilityEnabled: {
      comment: `When true, this subdatapoint should be revealed in a data access request.
When false, this field should be redacted.`,
      type: 'boolean',
    },
    erasureRequestRedactionEnabled: {
      comment: `When true, this subdatapoint should be redacted during an erasure request.
There normally is a choice of enabling hard deletion or redaction at the
datapoint level, but if redaction is enabled, this column can be used
to define which fields should be redacted..`,
      type: 'boolean',
    },
    categories: {
      comment: 'The category of personal data for this subdatapoint',
      type: DataSubCategory,
      list: true,
    },
    pendingCategoryGuesses: {
      comment:
        'Guessed subcategories by our classifier that are pending review',
      type: SubDataPointDataSubCategoryGuess,
      list: true,
    },
    purposes: {
      comment: 'The processing purposes for this subdatapoint',
      type: PurposeSubCategory,
      list: true,
    },
    attributeValues: {
      comment: 'The attribute values used to label this subdatapoint',
      type: AttributeValue,
      list: true,
    },
    dataSiloTeams: {
      comment:
        'The list of teams who are responsible for managing the parent data silo',
      list: true,
      type: TeamPreview,
    },
    dataPointOwners: {
      comment:
        'The owners of the DataPoint that is parent of this SubDataPoint',
      list: true,
      type: UserPreview,
    },
    dataSiloOwners: {
      comment: 'The owners of the DataSilo that is parent of this SubDataPoint',
      list: true,
      type: UserPreview,
    },
    dataSiloVendor: {
      comment: 'The vendor of the DataSilo that is parent of this SubDataPoint',
      type: VendorPreview,
      optional: true,
    },
    dataPointTeams: {
      comment:
        'The list of teams who are responsible for managing the parent data point',
      list: true,
      type: TeamPreview,
    },
    contentClassificationStatus: {
      comment: 'The content classification status for this SubDataPoint',
      type: { ContentClassificationStatus },
    },
    canRefetchSample: {
      comment:
        'Whether or not we can refetch the samples for this SubDataPoint',
      type: 'boolean',
    },
    controllership: {
      comment: "The organization's controllership of the subdatapoint",
      type: { Controllership },
      optional: true,
    },
    lastClassifiedAt: {
      comment: "The last time the subdatapoint's categories were classified",
      type: 'Date',
      optional: true,
    },
    lastRunAt: {
      comment:
        "The last time the subdatapoint's categories were schedule to be classified",
      type: 'Date',
      optional: true,
    },
    subjects: {
      comment:
        'The list of data subjects that are allowed for this sub-datapoint',
      type: DataSubject,
      list: true,
    },
    retentionType: {
      comment: 'The type of retention schedule for this subdatapoint',
      type: { RetentionType },
    },
    retentionPeriod: {
      comment:
        'The number of days personal data is retained (for stated period retention types)',
      type: 'int',
      optional: true,
    },
    encryptedSamplesS3Key: {
      comment: 'S3 key for retrieving encrypted samples',
      type: 'string',
      optional: true,
    },
    error: {
      comment:
        'Error message if there was an error encountered while fetching samples or classifying this subdatapoint',
      type: 'string',
      optional: true,
    },
    encryption: {
      comment: 'The encryptions to apply to this subdatapoint',
      type: { ColumnEncryptionType },
      optional: true,
    },
  },
});

/** Override type */
export type SubDataPoint = SchemaToType<typeof SubDataPoint>;

export const CreateSubDataPointInput = mkInput({
  name: 'CreateSubDataPointInput',
  comment: 'Input for creating a single subdatapoint',
  fields: {
    name: DataPointSubDataPointInput.fields.name,
    description: DataPointSubDataPointInput.fields.description,
    dataPointId: {
      comment: 'The ID of the datapoint to associate with this subDataPoint',
      type: 'id',
      modelName: 'dataPoint',
    },
    dataSubCategories: {
      comment:
        'The IDs of the subcategories to associate with this subdatapoint',
      type: 'id',
      modelName: 'dataSubCategory',
      list: true,
    },
    processingPurposeSubCategories: {
      comment:
        'The IDs of the sub-purposes to associate with this subdatapoint',
      type: 'id',
      modelName: 'processingPurposeSubCategory',
      list: true,
    },
    accessRequestVisibilityEnabled: {
      comment: `When true, this subdatapoint should be revealed in a data access request.
When false, this field should be redacted.`,
      type: 'boolean',
      optional: true,
    },
    erasureRequestRedactionEnabled: {
      comment: `When true, this subdatapoint should be redacted during an erasure request.
There normally is a choice of enabling hard deletion or redaction at the
datapoint level, but if redaction is enabled, this column can be used
to define which fields should be redacted..`,
      type: 'boolean',
      optional: true,
    },
    attributes: {
      comment: 'The attribute values associated with this subdatapoint',
      type: AttributeInput,
      list: true,
      optional: true,
    },
    controllership: {
      comment: "The organization's controllership of the subdatapoint",
      type: { Controllership },
      optional: true,
    },
    retentionType: {
      comment: 'The type of retention schedule for this subdatapoint',
      type: { RetentionType },
      optional: true,
    },
    retentionPeriod: {
      comment:
        'The number of days personal data is retained (for stated period retention types)',
      type: 'int',
      optional: true,
    },
  },
});

/** Override type */
export type CreateSubDataPointInput = SchemaToType<
  typeof CreateSubDataPointInput
>;

export const UpdateSubDataPointInput = mkInput({
  name: 'UpdateSubDataPointInput',
  comment: 'Input for updating a single subdatapoint',
  fields: {
    id: {
      type: 'id',
      comment: 'The ID of the subdatapoint to update with the included fields.',
      modelName: 'subDataPoint',
    },
    name: {
      ...DataPointSubDataPointInput.fields.name,
      optional: true,
    },
    accessRequestVisibilityEnabled:
      DataPointSubDataPointInput.fields.accessRequestVisibilityEnabled,
    erasureRequestRedactionEnabled:
      DataPointSubDataPointInput.fields.erasureRequestRedactionEnabled,
    description: DataPointSubDataPointInput.fields.description,
    dataSubCategories: {
      ...CreateSubDataPointInput.fields.dataSubCategories,
      comment:
        'The IDs of the subcategories to associate with this subdatapoint',
      optional: true,
    },
    rejectedGuesses: {
      comment:
        'The IDs of any rejected subcategory guesses for this subdatapoint',
      type: 'id',
      modelName: 'SubDataPointDataSubCategoryGuess',
      list: true,
      optional: true,
    },
    processingPurposeSubCategories: {
      ...CreateSubDataPointInput.fields.processingPurposeSubCategories,
      comment:
        'The IDs of the sub-purposes to associate with this subdatapoint',
      optional: true,
    },
    attributes: {
      comment: 'The attribute values used to label this subdatapoint',
      type: AttributeInput,
      list: true,
      optional: true,
    },
    dataPointOwnerIds: {
      comment:
        'The IDs of the users to assign as owners of the parent datapoint',
      type: 'id',
      modelName: 'user',
      optional: true,
      list: true,
    },
    dataPointTeamIds: {
      comment:
        'The IDs of the teams to assign as owners of the parent datapoint',
      type: 'id',
      modelName: 'team',
      optional: true,
      list: true,
    },
    controllership: {
      comment: "The organization's controllership of the subdatapoint",
      type: { Controllership },
      optional: true,
    },
    subjectIds: {
      optional: true,
      comment: 'The list of subject IDs to allow for this sub-datapoint',
      type: 'id',
      modelName: 'subject',
      list: true,
    },
    retentionType: {
      comment: 'The type of retention schedule for this sub-datapoint',
      type: { RetentionType },
      optional: true,
    },
    retentionPeriod: {
      comment:
        'The number of days data for this sub-datapoint is retained (for stated period retention types)',
      type: 'int',
      optional: true,
    },
    rejectOutstandingGuesses: {
      comment:
        'Whether or not to reject all outstanding guesses for this subdatapoint',
      type: 'boolean',
      optional: true,
    },
    reclassify: {
      comment:
        'Whether or not to reclassify guessed categories for the subdatapoint.',
      type: 'boolean',
      optional: true,
    },
    encryption: {
      comment: 'The encryptions to apply to this subdatapoint',
      type: { ColumnEncryptionType },
      optional: true,
    },
  },
});

/** Override type */
export type UpdateSubDataPointInput = SchemaToType<
  typeof UpdateSubDataPointInput
>;

export const UpdateSubDataPointsInput = mkInput({
  name: 'UpdateSubDataPointsInput',
  comment: 'Input for bulk updating subdatapoints',
  fields: {
    subDataPoints: {
      comment:
        'List of subdatapoints with the properties that should be updated for each',
      type: UpdateSubDataPointInput,
      list: true,
    },
  },
});

/** Override type */
export type UpdateSubDataPointsInput = SchemaToType<
  typeof UpdateSubDataPointsInput
>;

export const SubDataPointCursorInput = mkInput({
  name: 'SubDataPointCursorInput',
  comment: 'Cursor for paginating subdatapoints',
  fields: {
    createdAt: {
      comment:
        'Filter to requests after a create date. Must be an empty string if fetching the very first page.',
      type: 'string',
      optional: true,
    },
    id: {
      comment:
        'ID of the last subdatapoint in the previous page of results. This ID is not included in the next page of results',
      type: 'id',
      modelName: 'subDataPoint',
      optional: true,
    },
  },
});

/** Override type */
export type SubDataPointCursorInput = SchemaToType<
  typeof SubDataPointCursorInput
>;

export const SubDataPointFiltersInput = mkInput({
  name: 'SubDataPointFiltersInput',
  comment: 'Input for filtering a list of subdatapoints',
  fields: {
    ids: {
      comment: 'Filter by subdatapoint ids',
      type: 'id',
      modelName: 'subDataPoint',
      optional: true,
      list: true,
    },
    text: {
      comment: 'Filter by text',
      optional: true,
      type: 'string',
    },
    dataPointName: {
      comment:
        'Filter by the datapoint name directly. ' +
        'Include quotes around the text to perform and exact match, otherwise it will perform a partial match search.',
      optional: true,
      type: 'string',
    },
    subDataPointName: {
      comment:
        'Filter by the subdatapoint name directly. ' +
        'Include quotes around the text to perform and exact match, otherwise it will perform a partial match search.',
      optional: true,
      type: 'string',
    },
    dataPoints: {
      comment: 'Filter by datapoints the subdatapoints belong to',
      type: 'id',
      modelName: 'dataPoint',
      list: true,
      optional: true,
    },
    dataSilos: {
      comment: 'Filter by data silos the subdatapoints belong to',
      type: 'id',
      modelName: 'dataSilo',
      list: true,
      optional: true,
    },
    category: {
      comment: 'Filter by category of personal data',
      type: { DataCategoryType },
      list: true,
      optional: true,
    },
    subCategoryIds: {
      comment: 'Filter by specific subcategory of personal data',
      type: 'id',
      modelName: 'dataSubCategory',
      optional: true,
      list: true,
    },
    subCategories: {
      comment: 'Filter by specific subcategory of personal data',
      type: 'id',
      modelName: 'dataSubCategory',
      optional: true,
      list: true,
    },
    purposes: {
      comment: 'Filter by purpose of processing',
      type: { ProcessingPurpose },
      list: true,
      optional: true,
    },
    processingPurposeSubCategoryIds: {
      comment: 'Filter by specific subcategory of processing purpose',
      type: 'id',
      modelName: 'processingPurposeSubCategory',
      optional: true,
      list: true,
    },
    status: {
      comment: 'Filter by status of guess',
      type: { SubDataPointDataSubCategoryGuessStatus },
      optional: true,
    },
    minConfidence: {
      comment:
        'Filter for subcategories with guesses of a minimum confidence value',
      type: 'float',
      optional: true,
    },
    maxConfidence: {
      comment:
        'Filter for subcategories with guesses of a maximum confidence value',
      type: 'float',
      optional: true,
    },
    includeNotPersonalData: {
      comment:
        'Filter by whether to include or exclude NOT_PERSONAL_DATA category',
      type: { IncludeNotPersonalData },
      optional: true,
    },
    dataSiloTeams: {
      comment:
        'The ids of the teams that should be responsible for the parent data silo',
      type: 'id',
      modelName: 'team',
      list: true,
      optional: true,
    },
    dataPointOwners: {
      comment: 'Filter by the assigned owner of the parent DataPoint',
      optional: true,
      type: 'id',
      modelName: 'user',
      list: true,
    },
    dataSiloOwners: {
      comment: 'Filter by the assigned owner of the parent DataSilo',
      optional: true,
      type: 'id',
      modelName: 'user',
      list: true,
    },
    dataSiloVendorIds: {
      comment: 'Filter by the assigned vendor of the parent DataSilo',
      optional: true,
      type: 'id',
      modelName: 'vendor',
      list: true,
    },
    dataPointTeams: {
      comment:
        'The ids of the teams that should be responsible for the parent data point',
      type: 'id',
      modelName: 'team',
      list: true,
      optional: true,
    },
    attributeValueIds: {
      comment: 'Filter by the attribute values used to label the subdatapoint',
      type: 'id',
      modelName: 'attributeValue',
      list: true,
      optional: true,
    },
    controllerships: {
      comment: 'Filter by controllerships assigned to subdatapoints',
      type: { Controllership },
      list: true,
      optional: true,
    },
    subjectIds: {
      comment: 'Filter by the subjects that are allowed for this sub-datapoint',
      type: 'id',
      modelName: 'subject',
      list: true,
      optional: true,
    },
    retentionTypes: {
      comment: 'Filter by the type of retention schedule for this subdatapoint',
      type: { RetentionType },
      optional: true,
      list: true,
    },
    accessRequestVisibilityEnabled: {
      comment:
        'Filter by whether this subdatapoint should be revealed in a data access request.',
      type: 'boolean',
      optional: true,
    },
    // TODO: https://transcend.height.app/T-40598 - remove this deprecated field
    createdAtAfter: {
      comment: 'Filter to requests after a create date.',
      optional: true,
      type: 'string',
    },
    erasureRequestRedactionEnabled: {
      comment:
        'Filters by whether the subdatapoint should be redacted during an erasure request',
      optional: true,
      type: 'boolean',
    },
    contentClassificationStatus: {
      comment:
        'Filter by subdatapoints whose a content classification status is in this list',
      type: { ContentClassificationStatus },
      optional: true,
      list: true,
    },
    dataPointLevelName: {
      comment: "Filter by any of the subDataPoint's ancestor dataPointLevels",
      type: 'string',
      optional: true,
    },
    cursor: {
      comment: 'Cursor to identify where to start the next page of data.',
      type: SubDataPointCursorInput,
      optional: true,
    },
  },
});

/** Override type */
export type SubDataPointFiltersInput = SchemaToType<
  typeof SubDataPointFiltersInput
>;

export const DeleteSubDataPointsInput = mkInput({
  name: 'DeleteSubDataPointsInput',
  comment: 'Input for deleting a group of subdatapoints',
  fields: {
    ids: {
      comment: 'The id of the subdatapoint to update',
      type: 'id',
      modelName: 'subDataPoint',
      list: true,
    },
  },
});

/** Override type */
export type DeleteSubDataPointsInput = SchemaToType<
  typeof DeleteSubDataPointsInput
>;

export const SubDataPointRefetchSampleInfoInput = mkInput({
  name: 'SubDataPointRefetchSampleInfoInput',
  comment: 'Input for refetching samples',
  fields: {
    id: {
      comment: 'The id of the subDataPoint',
      type: 'id',
      modelName: 'subDataPoint',
    },
    refetchMethod: {
      comment:
        'How to refetch samples for this subDataPoint (e.g., whether to filter for non-null rows or not). ' +
        'Defaults to "DEFAULT"',
      type: { RefetchSampleMethod },
      optional: true,
    },
  },
});

/** Override type */
export type SubDataPointRefetchSampleInfoInput = SchemaToType<
  typeof SubDataPointRefetchSampleInfoInput
>;

export const RefetchSubDataPointSampleInput = mkInput({
  name: 'RefetchSubDataPointSampleInput',
  comment: 'Input for refetching sample for a group of subdatapoint',
  fields: {
    dataSiloId: {
      comment: 'The id of the data silo',
      type: 'id',
      modelName: 'dataSilo',
    },
    subDataPoints: {
      comment:
        'Metadata about how samples for each sub datapoint should be refetched',
      type: SubDataPointRefetchSampleInfoInput,
      list: true,
    },
  },
});

/** Override type */
export type RefetchSubDataPointSampleInput = SchemaToType<
  typeof RefetchSubDataPointSampleInput
>;

export const SubDataPointErrorRollupFilterInput = mkInput({
  name: 'SubDataPointErrorRollupFilterInput',
  comment: 'Input for filtering subdatapoint error rollups',
  fields: {
    dataSiloId: {
      comment: 'The ID of the data silo',
      type: 'id',
      modelName: 'dataSilo',
    },
    dataPointId: {
      comment: 'The ID of the data point',
      type: 'id',
      modelName: 'dataPoint',
      optional: true,
    },
  },
});

/** Override type */
export type SubDataPointErrorRollupFilterInput = SchemaToType<
  typeof SubDataPointErrorRollupFilterInput
>;

export const SubDataPointErrorRollup = mkType({
  name: 'SubDataPointErrorRollup',
  comment:
    'Rollup of errors encountered while scanning or classifying a property (aka subdatapoint)',
  fields: {
    errorMessage: {
      comment: 'The error message',
      type: 'string',
    },
    errorCount: {
      comment: 'The count of this error message',
      type: 'int',
    },
  },
});

/** Override type */
export type SubDataPointErrorRollup = SchemaToType<
  typeof SubDataPointErrorRollup
>;
/* eslint-enable max-lines */
