import { AirgapIssueSeverity } from '../airgapChangelog';
import { compareSeverity } from './compareSeverity';

/**
 * Function to determine the max severity level of a group of issues
 *
 * @param severities - list of issue severities to find the max of
 * @returns the maximum issue severity in the array
 */
export function getMaxSeverity(
  severities: AirgapIssueSeverity[],
): AirgapIssueSeverity {
  let maxSeverity = severities[0];
  // eslint-disable-next-line no-restricted-syntax
  for (const severity of severities) {
    if (severity === AirgapIssueSeverity.High) return severity;
    if (compareSeverity(severity, maxSeverity) === -1) maxSeverity = severity;
  }
  return maxSeverity;
}
