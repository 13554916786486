import { ChatCompletionRole } from '@transcend-io/privacy-types';

import { DataSubCategoryPreview } from '@main/datamap-types';
import { mkInput, mkType, SchemaToType } from '@main/schema-utils';

export const TextCategoryPreview = mkType({
  name: 'TextCategoryPreview',
  comment: 'A data category preview that is classified in a set of text',
  fields: {
    value: {
      comment: 'Instance of the data category that was matched',
      type: 'string',
    },
    snippet: {
      comment: 'Snippet previewing some of the text round the category',
      type: 'string',
    },
  },
});

/** Override type */
export type TextCategoryPreview = SchemaToType<typeof TextCategoryPreview>;

export const DataSubCategoryTextMatch = mkType({
  name: 'DataSubCategoryTextMatch',
  comment: 'A data category that is classified in a set of unstructured text',
  fields: {
    category: {
      comment: 'Data category that was matched',
      type: DataSubCategoryPreview,
    },
    values: {
      comment: 'The list of matching values for that category',
      type: TextCategoryPreview,
      list: true,
    },
  },
});

/** Override type */
export type DataSubCategoryTextMatch = SchemaToType<
  typeof DataSubCategoryTextMatch
>;

export const PromptRunField = mkType({
  name: 'PromptRunField',
  comment:
    'When a prompt run message returns JSON data, this is the parsed version of that JSON data',
  fields: {
    name: {
      comment: 'The name of the field',
      type: 'string',
    },
    value: {
      comment: 'The JSON stringified value of that data',
      type: 'string',
    },
  },
});

/** Override type */
export type PromptRunField = SchemaToType<typeof PromptRunField>;

export const PromptRunMessageInput = mkInput({
  name: 'PromptRunMessageInput',
  comment: 'An input message when recording prompt runs',
  fields: {
    message: {
      comment: 'The message that was sent to the LLM',
      type: 'string',
    },
    template: {
      comment: 'The template the the message was generated from',
      type: 'string',
      optional: true,
    },
    role: {
      comment: 'The role of the message (user input, ai assistant, etc)',
      type: { ChatCompletionRole },
    },
  },
});

/** Override type */
export type PromptRunMessageInput = SchemaToType<typeof PromptRunMessageInput>;

export const PromptRunMessage = mkType({
  name: 'PromptRunMessage',
  comment: 'A message in a chain of messages for a prompt run',
  fields: {
    id: {
      comment: 'The id of the prompt run message',
      modelName: 'promptRunMessage',
      type: 'id',
    },
    message: {
      comment: 'The message that was sent to the LLM',
      type: 'string',
    },
    template: {
      comment: 'The template the the message was generated from',
      type: 'string',
      optional: true,
    },
    role: {
      comment: 'The role of the message (user input, ai assistant, etc)',
      type: { ChatCompletionRole },
    },
    createdAt: {
      comment: 'The date the message was created',
      type: 'Date',
    },
    parsedFields: {
      comment: 'The parsed fields from the message when the response is JSON',
      type: PromptRunField,
      list: true,
    },
    dataCategories: {
      comment: 'The categories of data that were extracted from the message',
      type: DataSubCategoryTextMatch,
      list: true,
    },
  },
});

/** Override type */
export type PromptRunMessage = SchemaToType<typeof PromptRunMessage>;
