import { AntIcon, AntIconProps } from '@main/core-ui/src/AntIcon';
import { Img } from '@main/core-ui/src/Img';
import { resolveColor } from '@main/theme';
import React from 'react';
import { useTheme } from 'styled-components';

import { LogoWrapper } from './wrappers';

export { LogoWrapper };

/**
 * Props
 */
export interface LogoProps extends Omit<AntIconProps, 'type'> {
  /** The location the logo should link to, or the type of icon */
  logo: string;
  /** The SVG element to be used for the logo.  If present, overrides logo prop */
  svg?: JSX.Element;
  /** Strict flag to decide if space between children is to be rendered */
  extraSpace?: boolean;
  /** Children of the logo display */
  children?: React.ReactNode;
  /** The height of the image */
  height?: string;
  /** Specify the font size for an icon (falls back to height prop) */
  iconFontSize?: string;
  /** Shift in y the children */
  shiftY?: string;
}

/**
 * The main page logo in the top left corner
 */
export function Logo({
  children,
  logo,
  svg,
  style,
  extraSpace,
  iconFontSize,
  shiftY,
  color,
  iconTheme,
  loading,
  height = '30px',
  ...logoProps
}: LogoProps): JSX.Element {
  const theme = useTheme();
  const fontSize = iconFontSize || height;
  // backup to icon
  return (
    <span style={color ? { color: resolveColor()({ color, theme }) } : {}}>
      {svg ||
        (logo && logo.includes('://') ? (
          <Img
            height={height}
            src={logo}
            alt={`${logo}-logo`}
            style={style}
            {...logoProps}
            onClick={logoProps.onClick as any}
          />
        ) : (
          <AntIcon
            type={logo}
            iconTheme={iconTheme}
            style={{ fontSize, ...style }}
            loading={loading}
            {...logoProps}
          />
        ))}
      {extraSpace && children && (
        <span>
          &nbsp;<LogoWrapper shiftY={shiftY}>{children}</LogoWrapper>
        </span>
      )}
    </span>
  );
}
