import { TabBarContainer } from '@main/ad-core-components';
import { Tab, TabBar, useRoutePath } from '@main/core-ui';
import { AdminDashboardDataMapPath } from '@main/datamap-types';
import React from 'react';
import { useIntl } from 'react-intl';

import { siloDiscoveryTabMessages } from './messages';

export const SiloDiscoveryTabs: React.FC = () => {
  const { formatMessage } = useIntl();
  const routePath = useRoutePath();

  const tabs: Tab[] = [
    {
      path: AdminDashboardDataMapPath.TriageSiloDiscovery,
      title: formatMessage(siloDiscoveryTabMessages.triage),
    },
    {
      path: AdminDashboardDataMapPath.JunkSiloDiscovery,
      title: formatMessage(siloDiscoveryTabMessages.junk),
    },
  ];

  return (
    <TabBarContainer>
      <TabBar activePath={routePath} tabs={tabs} />
    </TabBarContainer>
  );
};
