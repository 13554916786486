import { defineMessages } from '@transcend-io/internationalization';

export const checkboxGroupMessages = defineMessages(
  'ad-core-components.CheckboxGroup.checkboxGroup',
  {
    showMore: {
      defaultMessage: 'Show more ({count})',
    },
    showLess: {
      defaultMessage: 'Show less',
    },
  },
);
