import { endpoints } from '@main/access-control-types';
import { FilterConfigItem } from '@main/ad-core-components';
import {
  SelectApiKeys,
  SelectedApiKey,
} from '@main/admin-dashboard/src/Infrastructure/ApiKeys/SelectApiKeys';
import { buildUseLazyQuery } from '@main/core-ui';
import { ID } from '@main/schema-utils';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { MessageDescriptor } from 'react-intl';

import { filterManagerHookMessages } from './messages';

/**
 * Filters definition with property for apiKey values specified
 */
type ApiKeyEnrichedFilters = Record<string, unknown> &
  Record<
    /**
     * ApiKey values that are currently filtering the table
     */
    string,
    SelectedApiKey[] | undefined
  >;

const useLazyApiKeys = buildUseLazyQuery(endpoints.apiKeys);

export const useApiKeyFilters = <
  T extends Record<string, unknown>,
  E extends ApiKeyEnrichedFilters,
>({
  apiKeyIdsFilterKey = 'apiKeyIds',
  filters,
  setFilters,
  label,
  enrichedApiKeyKey = 'apiKeys',
}: {
  /** The property name of the filter for apiKey ids */
  apiKeyIdsFilterKey?: keyof T;
  /** The currently applied filters */
  filters?: T;
  /** Callback when the filters are changed */
  setFilters: (filters: T) => void;
  /** Label for the filter menu */
  label: string | MessageDescriptor;
  /** The key to use if more than one useApiKeyFilters is used in a FilterManager */
  enrichedApiKeyKey?: keyof E;
}): {
  /**  The apiKey keys enabled on this table */
  selectedApiKeys: SelectedApiKey[];
  /** The filter configuration to be passed to the filter manager */
  apiKeyFiltersConfig: FilterConfigItem<E>;
  /** Callback for when the filter is cleared in the filter manager */
  clearApiKeyFilters: (key: Extract<keyof E, string>) => void;
} => {
  const getSelectedApiKeys = useLazyApiKeys();
  const [selectedApiKeys, setSelectedApiKeys] = useState<SelectedApiKey[]>([]);
  const [fetchingApiKeys, setFetchingApiKeys] = useState(false);

  // Populate initial complex filter values
  useEffect(() => {
    if (filters) {
      const apiKeyIds = (filters[apiKeyIdsFilterKey] ?? []) as ID<'apiKey'>[];

      const filtersAndEnrichedFiltersMatch =
        apiKeyIds.sort().join() ===
        selectedApiKeys
          .map(({ id }) => id)
          .sort()
          .join();

      if (apiKeyIds.length === 0 && selectedApiKeys.length > 0) {
        setSelectedApiKeys([]);
      } else if (!filtersAndEnrichedFiltersMatch && !fetchingApiKeys) {
        // Prevent over-firing this setter while data is still being fetched
        setFetchingApiKeys(true);
        getSelectedApiKeys({ filterBy: { ids: apiKeyIds } })
          .then(({ data }) => {
            setSelectedApiKeys(data.nodes);
          })
          .finally(() => {
            setFetchingApiKeys(false);
          });
      }
    }
  }, [
    filters,
    fetchingApiKeys,
    getSelectedApiKeys,
    selectedApiKeys.length,
    apiKeyIdsFilterKey,
    selectedApiKeys,
  ]);

  const apiKeyFiltersConfig = useMemo(
    () =>
      ({
        filterKey: enrichedApiKeyKey,
        label,
        pillOptions: {
          label: ({
            filterValues: { [enrichedApiKeyKey]: apiKeys = [] },
            index = 0,
          }) => apiKeys[index]?.title,
        },
        filter: (
          <SelectApiKeys
            isMulti
            placeholderDescriptor={filterManagerHookMessages.apiKeysPlaceholder}
            menuPosition="absolute"
            value={selectedApiKeys}
            showOutline={false}
            onChange={(apiKeys) => {
              setSelectedApiKeys([...apiKeys]);
              setFilters({
                ...filters,
                [apiKeyIdsFilterKey]: apiKeys.map(({ id }) => id),
              } as T);
            }}
          />
        ),
      }) as FilterConfigItem<ApiKeyEnrichedFilters>,
    [enrichedApiKeyKey, label, selectedApiKeys, filters, apiKeyIdsFilterKey],
  );

  const clearApiKeyFilters = useCallback(
    (key) => {
      if (key === enrichedApiKeyKey) {
        setFilters({
          ...filters,
          [apiKeyIdsFilterKey]: [],
        } as T);
        setSelectedApiKeys([]);
      }
    },
    [enrichedApiKeyKey, filters, apiKeyIdsFilterKey],
  );

  return {
    selectedApiKeys,
    apiKeyFiltersConfig,
    clearApiKeyFilters,
  };
};
