/**
 * Fields to order a purpose by when paginating
 */
export enum PurposeOrderField {
  /** Whether the purpose is active */
  Active = 'isActive',
  /** The name of the purpose */
  Name = 'name',
  /** The created at date of the purpose */
  CreatedAt = 'createdAt',
  /** The time the purpose was updated */
  UpdatedAt = 'updatedAt',
}
