import { statusDescriptionMessages, statusTitleMessages } from './messages';
import type { RequestStatusConfig } from './types';

/**
 * ## Manual Approval
 * The request has been compiled, but needs to be reviewed manually before the Data Subject receives their report.
 *
 * For each action, the organization can set whether they want a manual review before sending the request. Some action types will
 * always require manual approval (or postflight approval), especially if the Data Subject is allowed to fill out details for
 * that request.
 *
 * ### ON CHANGE TO
 *  - All organization members with the approval scope will get a notification that a request requires approval.
 */
export const APPROVING_STATUS: RequestStatusConfig = {
  color: 'primary',
  icon: 'exclamation-triangle',
  isActionItem: true,
  isBuilding: true,
  messages: {
    description: statusDescriptionMessages.APPROVING,
    title: statusTitleMessages.APPROVING,
  },
  transitions: [
    /**
     * Something looks wrong with the request and compilation needs to continue. The organization member can start editing/re-triggering
     * data silos and the request will go back to a compiling state.
     */
    'COMPILING',
    /** The data subject invalidates one of their identifier, i.e. clicks "Not you?" in the email */
    'FAILED_VERIFICATION',
    /**
     * An organization member reviews the request, it looks good and they click the button to send the Data Subject
     * a report when no secondary action
     */
    'COMPLETED',
    /**
     * Access requests with data will be made downloadable by the data subject
     */
    'DOWNLOADABLE',
    /**
     * When download reports are turned off, a request can transition from approving state
     * directly to the begin erasure now state
     */
    'SECONDARY',
    /** If there is a secondary action, a report is sent to the data subject but we delay the execution of the secondary action */
    'DELAYED',
    /** Organization member clicks cancel, triggering cancelation email and the request is killed */
    'CANCELED',
    /** Data subject can revoke */
    'REVOKED',
    /** View catagories of request results */
    'VIEW_CATEGORIES',
  ],
};
