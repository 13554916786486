import { createSelector } from 'reselect';

import type { ActionItemState } from './slice';

/**
 * The selector for the `ActionItem` state
 */
export const getActionItem = (state: any): ActionItemState => state.ActionItem;

/**
 * The list of action item collection IDs that are collapsed
 */
export const selectCollapsedActionItemCollections = createSelector(
  getActionItem,
  ({ collapsedActionItemCollections }) => collapsedActionItemCollections,
);
