import { Button } from '@main/ad-core-components';
import {
  Icon,
  SKIP_PRIVACY_CENTER_MODULES_FLAG_KEY,
  useRedirect,
} from '@main/core-ui';
import { ImmutableUrl } from '@main/immutable-url';
import React from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { privacyCenterMessages } from './messages';
import { selectPrivacyCenterUrl } from './selectors';

export const ViewPrivacyCenter: React.FC = () => {
  const privacyCenterUrl = useSelector(selectPrivacyCenterUrl);
  const redirect = useRedirect();
  const { formatMessage } = useIntl();

  return (
    <>
      <Button
        variant="secondary"
        icon={<Icon type="eye-checkmark" />}
        onClick={() =>
          privacyCenterUrl &&
          redirect(
            new ImmutableUrl(privacyCenterUrl)
              .transform({
                searchTransform: () => ({
                  // ignore current params
                  [SKIP_PRIVACY_CENTER_MODULES_FLAG_KEY]: false,
                }),
              })
              .toString(),
            true,
          )
        }
        disabled={!privacyCenterUrl}
        loading={!privacyCenterUrl}
      >
        {formatMessage(privacyCenterMessages.viewLive)}
      </Button>
      <Button
        variant="secondary"
        icon={<Icon type="eye" />}
        onClick={() =>
          privacyCenterUrl &&
          redirect(
            new ImmutableUrl(privacyCenterUrl)
              .transform({
                searchTransform: () => ({
                  // ignore current params
                  [SKIP_PRIVACY_CENTER_MODULES_FLAG_KEY]: true,
                }),
              })
              .toString(),
            true,
          )
        }
        disabled={!privacyCenterUrl}
        loading={!privacyCenterUrl}
      >
        {formatMessage(privacyCenterMessages.viewDraft)}
      </Button>
    </>
  );
};
