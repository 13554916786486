import { endpoints } from '@main/access-control-types';
import {
  buildUseMutation,
  formatErrorMessage,
  message,
  ReactSelect,
  ReactSelectExtendedProps,
} from '@main/core-ui';
import type { ID } from '@main/schema-utils';
import { ActionItemPriorityOverride } from '@transcend-io/privacy-types';
import React from 'react';
import { useIntl } from 'react-intl';

import { actionItemsPriorityMessages } from '../messages';
import { RowTableContext } from '../types';
import { ActionItemPriorityLabel } from './ActionItemPriorityLabel';

const useUpdateActionItems = buildUseMutation(endpoints.updateActionItems);

export interface ActionItemPrioritySelectItem {
  /** The value of the enum */
  value: ActionItemPriorityOverride;
  /** The message to display */
  label: string;
}

/** Props for the `InlineSelectOwners` component */
interface InlineSelectPriorityProps
  extends ReactSelectExtendedProps<false, ActionItemPrioritySelectItem> {
  /** ids of the action items for this cell */
  ids: ID<'actionItem'>[];
  /** The value for the cell */
  value: ActionItemPrioritySelectItem;
  /** Queries to refetch on success */
  refetchQueries?: string[];
  /** The table context */
  context: RowTableContext;
}

export const InlineSelectPriority: React.FC<InlineSelectPriorityProps> = ({
  ids,
  value: initialValue,
  refetchQueries = ['GlobalActionItems', 'GlobalActionItemsSubTable'],
  context,
  ...props
}) => {
  const { formatMessage } = useIntl();
  const [updateActionItems, { loading }] = useUpdateActionItems(
    refetchQueries
      ? {
          refetchQueries,
          awaitRefetchQueries: true,
        }
      : {},
  );

  return (
    <ReactSelect
      isMulti={false}
      variant="light"
      value={initialValue}
      onChange={async (priority) => {
        try {
          const { errors } = await updateActionItems({
            variables: {
              input: {
                priorityOverride: (priority?.value ??
                  null) as unknown as ActionItemPriorityOverride,
                ...(context.count > 1
                  ? {
                      queryFilters: context.filters,
                      rollupFilters: [context.rollupFilters],
                    }
                  : {
                      ids,
                    }),
              },
            },
          });

          if (errors && errors.length > 0) {
            message.error(formatErrorMessage(errors[0].message));
            return;
          }

          message.success();
        } catch (error) {
          message.error(formatErrorMessage(error.message));
        }
      }}
      options={Object.values(ActionItemPriorityOverride).map((value) => ({
        value,
        label: formatMessage(actionItemsPriorityMessages[value]),
      }))}
      showOutline={false}
      isLoading={loading}
      formatOptionPill={(option) => (
        <ActionItemPriorityLabel priority={option.value} />
      )}
      {...props}
    />
  );
};
