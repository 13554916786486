/**
 * The types of policies that can be created
 */
export enum PathfinderPolicyType {
  /** A policy that rejects input into the LLM */
  Reject = 'REJECT',
  /** A policy that anonymizes data categories going into the LLM */
  Anonymize = 'ANONYMIZE',
}

/**
 * The fields that an pathfinder policy can be ordered on
 */
export enum PathfinderPolicyOrderField {
  /** The name of the pathfinder policy */
  Name = 'name',
  /** The type of the pathfinder policy */
  Type = 'type',
  /** The age of the pathfinder policy */
  CreatedAt = 'createdAt',
  /** The last updated time */
  UpdatedAt = 'updatedAt',
}
