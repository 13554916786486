import {
  AdminDashboardPromptsPath,
  PROMPTS_PATH_PREFIX,
} from '@main/prompt-types';

import { ExtendableRoute } from '../routes';
import { BrowseLoadable } from './Browse/Loadable';
import { GroupsLoadable } from './Groups/Loadable';
import { promptsPageTitleMessages } from './messages';
import { ModelsLoadable } from './Models/Loadable';
import { NewPartialLoadable, PartialLoadable } from './Partial/Loadable';
import { PartialsLoadable } from './Partials/Loadable';
import { NewPromptLoadable, PromptLoadable } from './Prompt/Loadable';
import { PromptRunLoadable } from './PromptRun/Loadable';
import { PromptRunsLoadable } from './PromptRuns/Loadable';
import { promptTabMessages } from './PromptTabs/messages';

const promptsBreadcrumb = {
  id: PROMPTS_PATH_PREFIX,
  displayText: promptsPageTitleMessages.prompts,
  href: AdminDashboardPromptsPath.Prompts,
};

const promptsBrowseBreadcrumbs = [
  promptsBreadcrumb,
  {
    id: AdminDashboardPromptsPath.Browse,
    displayText: promptTabMessages.prompts,
    href: AdminDashboardPromptsPath.Browse,
  },
];

const promptsGroupsBreadcrumbs = [
  promptsBreadcrumb,
  {
    id: AdminDashboardPromptsPath.Browse,
    displayText: promptTabMessages.groups,
    href: AdminDashboardPromptsPath.Groups,
  },
];

const promptsModelsBreadcrumbs = [
  promptsBreadcrumb,
  {
    id: AdminDashboardPromptsPath.Browse,
    displayText: promptTabMessages.models,
    href: AdminDashboardPromptsPath.Models,
  },
];

const promptsRunsBreadcrumbs = [
  promptsBreadcrumb,
  {
    id: AdminDashboardPromptsPath.Browse,
    displayText: promptTabMessages.promptRuns,
    href: AdminDashboardPromptsPath.PromptRuns,
  },
];

const promptsPartialsBreadcrumbs = [
  promptsBreadcrumb,
  {
    id: AdminDashboardPromptsPath.Browse,
    displayText: promptTabMessages.partials,
    href: AdminDashboardPromptsPath.Partials,
  },
];

export const promptRoutes: Record<AdminDashboardPromptsPath, ExtendableRoute> =
  {
    [AdminDashboardPromptsPath.Prompts]: {
      path: AdminDashboardPromptsPath.Prompts,
      title: promptsPageTitleMessages.prompts,
      component: BrowseLoadable,
      breadcrumbs: [promptsBreadcrumb],
    },
    [AdminDashboardPromptsPath.Browse]: {
      path: AdminDashboardPromptsPath.Browse,
      title: promptsPageTitleMessages.prompts,
      component: BrowseLoadable,
      breadcrumbs: [promptsBreadcrumb],
    },
    [AdminDashboardPromptsPath.NewPrompt]: {
      path: AdminDashboardPromptsPath.NewPrompt,
      component: NewPromptLoadable,
      breadcrumbs: promptsBrowseBreadcrumbs,
    },
    [AdminDashboardPromptsPath.ViewPrompt]: {
      path: AdminDashboardPromptsPath.ViewPrompt,
      component: PromptLoadable,
      breadcrumbs: promptsBrowseBreadcrumbs,
    },
    [AdminDashboardPromptsPath.EditPrompt]: {
      path: AdminDashboardPromptsPath.EditPrompt,
      component: PromptLoadable,
      breadcrumbs: promptsBrowseBreadcrumbs,
    },
    [AdminDashboardPromptsPath.Partials]: {
      path: AdminDashboardPromptsPath.Partials,
      title: promptsPageTitleMessages.partials,
      component: PartialsLoadable,
      breadcrumbs: promptsPartialsBreadcrumbs,
    },
    [AdminDashboardPromptsPath.NewPartial]: {
      path: AdminDashboardPromptsPath.NewPartial,
      component: NewPartialLoadable,
      breadcrumbs: promptsPartialsBreadcrumbs,
    },
    [AdminDashboardPromptsPath.ViewPartial]: {
      path: AdminDashboardPromptsPath.ViewPartial,
      component: PartialLoadable,
      breadcrumbs: promptsPartialsBreadcrumbs,
    },
    [AdminDashboardPromptsPath.EditPartial]: {
      path: AdminDashboardPromptsPath.EditPartial,
      component: PartialLoadable,
      breadcrumbs: promptsPartialsBreadcrumbs,
    },
    [AdminDashboardPromptsPath.Groups]: {
      path: AdminDashboardPromptsPath.Groups,
      title: promptsPageTitleMessages.groups,
      component: GroupsLoadable,
      breadcrumbs: promptsGroupsBreadcrumbs,
    },
    [AdminDashboardPromptsPath.Models]: {
      path: AdminDashboardPromptsPath.Models,
      title: promptsPageTitleMessages.models,
      component: ModelsLoadable,
      breadcrumbs: promptsModelsBreadcrumbs,
    },
    [AdminDashboardPromptsPath.PromptRuns]: {
      path: AdminDashboardPromptsPath.PromptRuns,
      title: promptsPageTitleMessages.promptRuns,
      component: PromptRunsLoadable,
      breadcrumbs: promptsRunsBreadcrumbs,
    },
    [AdminDashboardPromptsPath.ViewPromptRun]: {
      path: AdminDashboardPromptsPath.ViewPromptRun,
      component: PromptRunLoadable,
      breadcrumbs: promptsRunsBreadcrumbs,
    },
  };
