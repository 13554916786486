import React from 'react';
import { useTheme } from 'styled-components';

/**
 * Download icon
 */
export const Download: React.FC = (props) => {
  const theme = useTheme();
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <g
        id="DownloadIcon"
        stroke={theme.colors.white}
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <path
          d="M10 2L10 11 6 11 12 17 18 11 14 11 14 2z"
          fill={theme.colors.white}
          fillRule="nonzero"
        />
        <path
          fill="none"
          stroke={theme.colors.white}
          strokeMiterlimit="10"
          strokeWidth="2"
          d="M2 21L22 21"
        />
      </g>
    </svg>
  );
};
