import styled from 'styled-components';

import { AssessmentFormStatus } from '@transcend-io/privacy-types';

import { Badge, StyleUtils } from '@main/core-ui';

import { ASSESSMENT_FORM_STATUS_COLOR_NAMES } from '../../constants';
import { AssessmentBadgeProps } from './types';

export const StyledBadge = styled(Badge)<AssessmentBadgeProps>`
  display: flex;
  align-items: center;
  gap: ${StyleUtils.Spacing.xs};

  ${({ assessmentFormStatus }) =>
    assessmentFormStatus
      ? `
      // "!important" because it has to override inline style
          padding: 0px 8px 0px 0 !important;
          align-self: stretch;
        `
      : ''}
  ${({ isLinked, theme }) =>
    isLinked
      ? `
      &:hover,
      &:focus,
      &:active {
        // "!important" because it has to override inline style
        color: ${theme.colors.transcend} !important;
      }`
      : ''};
`;

export const StatusIconWrapper = styled.span<{
  /** The status of the assessment form this icon is for */
  assessmentFormStatus?: AssessmentFormStatus;
}>`
  ${({ theme, assessmentFormStatus }) =>
    assessmentFormStatus
      ? `background-color: ${
          theme.colors[
            ASSESSMENT_FORM_STATUS_COLOR_NAMES[assessmentFormStatus!]
          ]
        };`
      : ''}
  padding: 2px 4px 2px 4px;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  align-self: stretch;
  display: flex;
  align-items: center;
`;
