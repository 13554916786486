import { createLoadable } from '@main/core-ui';

export const CreateAssessmentFormLoggedOutLoadable = createLoadable({
  loader: () => import('./CreateAssessmentFormLoggedOut'),
});

export const AssessmentFormLoggedOutLoadable = createLoadable({
  loader: () => import('./AssessmentFormLoggedOut'),
});

// Load the component dynamically
export const AssessmentFormLoadable = createLoadable({
  loader: () => import('./AssessmentForm'),
});
