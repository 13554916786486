import { statusDescriptionMessages, statusTitleMessages } from './messages';
import type { RequestStatusConfig } from './types';

/**
 * ## Request Made
 * A new data subject request was submit to an organization.
 *
 * This is the initial state of a request. All requests start here, and the request then determines what its first step should be.
 *
 * ### ON CHANGE TO
 *  - We simply calculate the next step
 */
export const REQUEST_MADE_STATUS: RequestStatusConfig = {
  color: 'success',
  icon: 'user',
  isBuilding: true,
  messages: {
    description: statusDescriptionMessages.REQUEST_MADE,
    title: statusTitleMessages.REQUEST_MADE,
  },
  transitions: [
    /** If there are no unverified identifiers and the organization has enrichers configured, we start the enrichment process */
    'ENRICHING',
    /** If there are no unverified identifiers and no configured enrichers, begin waiting for the specified duration */
    'WAITING',
    /** If there are no unverified identifiers, no configured enrichers, and no preflight for that action, simply start compilation */
    'COMPILING',
    /** Data subject can revoke */
    'REVOKED',
  ],
};
