import { makeEnum } from '@transcend-io/type-utils';

export const ScanType = makeEnum({
  /**
   * A full, end to end scan of a data silo.
   * Usually spawns more than 1 ScanRuns.
   */
  FullScan: 'FULL_SCAN',
  /**
   * A scan to only find new information, since our last scan.
   */
  DeltaUpdateScan: 'DELTA_UPDATE_SCAN',
  /**
   * A scan on only a subset of data points of a data silo.
   */
  TargetedScan: 'TARGETED_SCAN',
});

/** Override type */
export type ScanType = (typeof ScanType)[keyof typeof ScanType];

export const ScanLambdaStatus = makeEnum({
  /** Retry the indexing request in some time. */
  Retry: 'RETRY',
  /** A-OK. */
  Success: 'SUCCESS',
  /** Generic error that should be bubbled up to the client. */
  Error: 'ERROR',
  /** The data silo credentials have expired. */
  SiloCredentialsExpired: 'SILO_CREDENTIALS_EXPIRED',
  /** The data silo needs to be updated due to new permissions, etc. */
  SiloPermissionsUpdated: 'SILO_PERMISSIONS_UPDATED',
  /** The plugin run was skipped. */
  Skipped: 'SKIPPED',
  /** If the Scan is in progress. */
  InProgress: 'IN_PROGRESS',
});

/** Override type */
export type ScanLambdaStatus =
  (typeof ScanLambdaStatus)[keyof typeof ScanLambdaStatus];

export const DataPointTypeDisplayName = makeEnum({
  Attribute: 'ATTRIBUTE',
  Bucket: 'BUCKET',
  Catalog: 'CATALOG',
  Collection: 'COLLECTION',
  CollectionField: 'COLLECTION_FIELD',
  Column: 'COLUMN',
  DataPoint: 'DATA_POINT',
  Database: 'DATABASE',
  Dataset: 'DATASET',
  Environment: 'ENVIRONMENT',
  Field: 'FIELD',
  Form: 'FORM',
  List: 'LIST',
  Object: 'OBJECT',
  Organization: 'ORGANIZATION',
  Profile: 'PROFILE',
  Project: 'PROJECT',
  Property: 'PROPERTY',
  Question: 'QUESTION',
  Record: 'RECORD',
  Resource: 'RESOURCE',
  Segment: 'SEGMENT',
  Schema: 'SCHEMA',
  SubDataPoint: 'SUB_DATA_POINT',
  Table: 'TABLE',
  TableAttribute: 'TABLE_ATTRIBUTE',
  UserList: 'USER_LIST',
});

/** Override type */
export type DataPointTypeDisplayName =
  (typeof DataPointTypeDisplayName)[keyof typeof DataPointTypeDisplayName];

/** The objects that can be scanned during Schema Discovery. */
export const ScanObject = makeEnum({
  Catalog: 'CATALOG',
  Database: 'DATABASE',
  Schema: 'SCHEMA',
  Table: 'TABLE',
  Collection: 'COLLECTION',
  Dataset: 'DATASET',
  RecordType: 'RECORD_TYPE',
});

/** Override type */
export type ScanObject = (typeof ScanObject)[keyof typeof ScanObject];
