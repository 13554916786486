import mime from 'mime';

import { UNKNOWN_FILE_EXTENSION, UNKNOWN_MIME_TYPE } from './constants';

mime.define({
  [UNKNOWN_MIME_TYPE]: [UNKNOWN_FILE_EXTENSION],
  'application/vnd.google-apps.folder': ['folder'],
  'application/vnd.google-apps.form': ['gform'],
  'binary/octet-stream': ['binary'],
});

export default mime;
