import {
  PaginatedSelect,
  PaginatedSelectProps,
} from '@main/ad-core-components';
import { buildUseInfiniteScroll, Icon } from '@main/core-ui';
import {
  AgentFileOrderField,
  AgentFilePreview,
  endpoints,
} from '@main/prompt-types';
import { createNewId, OrderDirection } from '@main/schema-utils';
import React, { useState } from 'react';
import { MenuPosition } from 'react-select';

import { selectAgentFilesMessages } from './messages';

const NODES = {
  id: null,
  name: null,
} as const;

/**
 * Selected prompt
 */
export type SelectedAgentFile = Pick<AgentFilePreview, keyof typeof NODES> & {
  /** Whether prompt is newly created prompt or existing prompt */
  isNew?: boolean;
};

const useSelectAgentFiles = buildUseInfiniteScroll(
  endpoints.agentFiles,
  'SelectAgentFiles',
  {
    nodes: NODES,
  },
);

export interface SelectAgentFileProps
  extends Omit<
    PaginatedSelectProps<SelectedAgentFile, true>,
    'isQueryLoading' | 'queryError' | 'fetchMore' | 'onEndsTyping' | 'onChange'
  > {
  /** On change handler */
  onChange?: (agentFiles: SelectedAgentFile[]) => void;
  /** Show option to create prompt */
  allowCreate?: boolean;
  /** How to position the menu (see docs for React Select) */
  menuPosition?: MenuPosition;
}

/**
 * Select a specific prompt
 */
export const SelectAgentFiles: React.FC<SelectAgentFileProps> = ({
  allowCreate = false,
  placeholderDescriptor = selectAgentFilesMessages.placeholder,
  showOutline = true,
  onChange,
  menuPosition = 'fixed',
  ...paginatedSelectProps
}) => {
  const [searchText, setSearchText] = useState('');
  const { data, loading, error, fetchMore } = useSelectAgentFiles({
    variables: {
      filterBy: {
        text: searchText,
      },
      orderBy: [
        { field: AgentFileOrderField.Name, direction: OrderDirection.ASC },
      ],
    },
    fetchPolicy: 'cache-and-network',
  });

  return (
    <PaginatedSelect
      id="select-agent-files"
      showOutline={showOutline}
      placeholderDescriptor={placeholderDescriptor}
      options={data?.nodes ?? []}
      onChange={onChange as any}
      isQueryLoading={loading}
      isCreatable={allowCreate}
      isMulti
      menuPosition={menuPosition}
      getOptionValue={({ id }: SelectedAgentFile) => id}
      getOptionLabel={({ name }: SelectedAgentFile) => name}
      queryError={error}
      getOptionLogo={({ isNew }: SelectedAgentFile) =>
        isNew ? <Icon type="circle-add" /> : ''
      }
      getNewOptionData={(inputValue): SelectedAgentFile => ({
        isNew: true as const,
        name: inputValue,
        id: createNewId<'agentFile'>(),
      })}
      fetchMore={fetchMore}
      onEndsTyping={setSearchText}
      {...paginatedSelectProps}
    />
  );
};
