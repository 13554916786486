import {
  buildInlineTextInputWithUpdate,
  InlineUpdateProps,
} from '@main/ad-core-components';
import { endpoints } from '@main/code-scanning-types';
import type { ID } from '@main/schema-utils';
import React, { useMemo } from 'react';

interface InlineSoftwareDevelopmentKitsDocumentationLinksUpdateProps
  extends Omit<
    Partial<
      InlineUpdateProps<
        (typeof endpoints.updateSoftwareDevelopmentKits)['params'],
        (typeof endpoints.updateSoftwareDevelopmentKits)['response']
      >
    >,
    'value'
  > {
  /** The ID of the parent softwareDevelopmentKit for this cell */
  id: ID<'softwareDevelopmentKit'>;
  /** The value for the cell */
  value: string[];
}

const InlineText = buildInlineTextInputWithUpdate(
  endpoints.updateSoftwareDevelopmentKits,
);
export const InlineSoftwareDevelopmentKitsDocumentationLinksUpdate: React.FC<
  InlineSoftwareDevelopmentKitsDocumentationLinksUpdateProps
> = ({ value, id, ...props }) => {
  const valuesAsString = useMemo(() => value.join(','), [value]);
  return (
    // TODO: https://transcend.height.app/T-32190 - update to better component for handling multiple links
    <InlineText
      value={valuesAsString}
      getValueFromReturnedData={(data) =>
        data?.softwareDevelopmentKits?.[0]?.repositoryUrl
      }
      updateItemWithValue={(updatedValue) => ({
        variables: {
          input: {
            softwareDevelopmentKits: [
              {
                id,
                documentationLinks: updatedValue
                  .split(',')
                  .map((link) => link.trim()),
              },
            ],
          },
        },
      })}
      {...props}
    />
  );
};
