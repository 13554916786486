import { AuditEventCode, AuditEventPreview } from '@main/audit-types';
import { DoubleTag } from '@main/core-ui';
import React from 'react';

export const AuditEventCustomItemTitle: React.FC<{
  /** the event to render */
  event: AuditEventPreview;
}> = ({ event }) => {
  const state = event.beforeState ?? event.afterState;
  if (
    event.code === AuditEventCode.SubDataPointDataSubCategory ||
    event.code === AuditEventCode.DataSiloDataSubCategory
  ) {
    return (
      <DoubleTag
        leftLabel={state.category}
        rightLabel={state.title}
        color="transcendNavy2"
        variant="filled"
      />
    );
  }
  if (
    event.code === AuditEventCode.SubDataPointProcessingPurposeSubCategory ||
    event.code === AuditEventCode.DataSiloProcessingPurposeSubCategory
  ) {
    return (
      <DoubleTag
        leftLabel={state.purpose}
        rightLabel={state.title}
        color="transcendNavy2"
        variant="filled"
      />
    );
  }

  // title should always be there, but in case fall back to id and worst case null
  // if the log is invalid instead of throwing error and breaking the entire UI
  return state?.title ?? state?.id ?? null;
};
