import type { RequestStatus } from '@transcend-io/privacy-types';
import { createEnum, filterEnum } from '@transcend-io/type-utils';

import { REQUEST_STATUSES } from './statuses';

/**
 * The statuses of a request that is closed and no longer needs the request action configurations
 */
export const REQUEST_STATUSES_CLOSED = filterEnum(
  REQUEST_STATUSES,
  (status) => !!status.isClosed,
);

/**
 * The closed statuses keyed by status
 */
export const REQUEST_STATUS_CLOSED = createEnum(REQUEST_STATUSES_CLOSED);

/**
 * Check if a request has been closed
 *
 * @param status - The status of the request to check
 * @returns True if the request has been closed and is not longer actively being built
 */
export function isClosed(status: RequestStatus): boolean {
  return status in REQUEST_STATUS_CLOSED;
}
