import { addQueryParams, removeQueryParams, useRouter } from '@main/core-ui';
import React from 'react';

import { Button, ButtonProps } from '../Button';
import { ModalProps as CoreModalProps } from '../Modal';
import { StyledModal } from './wrappers';

/**
 * Props for the ButtonModal component
 */
export interface ButtonModalProps extends CoreModalProps {
  /** The globally unique name for the modal, this will appear as the `modal` query parameter */
  modalName: string;
  /** Props for modal button open */
  buttonProps: ButtonProps;
}

/**
 * A button that launches a modal on the admin dashboard
 */
export const ButtonModal: React.FC<ButtonModalProps> = ({
  modalName,
  buttonProps,
  onHide,
  ...modalProps
}) => {
  const {
    queryParams: { modal },
    redirect,
    location,
  } = useRouter();
  const isShowing = modal === modalName;

  return (
    <React.Fragment>
      <Button
        id={`modal-${modalName}`}
        onClick={() => redirect(addQueryParams(location, { modal: modalName }))}
        {...buttonProps}
      />
      <StyledModal
        width="fit-content"
        centered
        onHide={() => {
          redirect(removeQueryParams(location, ['modal']));

          // Call parent onHide if provided
          if (onHide) {
            onHide();
          }
        }}
        show={isShowing}
        {...modalProps}
      />
    </React.Fragment>
  );
};
