import { defineMessages } from '@transcend-io/internationalization';

export const useExportGqlToCsvMessages = defineMessages(
  'ad-core-components.hooks.useExportGqlToCsv.useExportGqlToCsv',
  {
    noData: {
      defaultMessage:
        'No data was exported. Check that your filters are returning data, or reach out to support for assistance.',
    },
    idCsvHeader: {
      defaultMessage: 'ID',
    },
  },
);
