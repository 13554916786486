import { defineMessages } from '@transcend-io/internationalization';

export const consentManagerPageTitleMessages = defineMessages(
  'admin-dashboard.ConsentManager.consentManagerPageTitle',
  {
    consentManager: {
      defaultMessage: 'Consent Management',
    },
    dashboardMenuLabel: {
      defaultMessage: 'Dashboard',
    },
    dashboardPageTitle: {
      defaultMessage: 'Consent Management Dashboard',
    },
    dataFlows: {
      defaultMessage: 'Data Flows',
    },
    cookies: {
      defaultMessage: 'Cookies',
    },
    displaySettings: {
      defaultMessage: 'Consent Display Settings',
    },
    developerSettings: {
      defaultMessage: 'Developer Settings',
    },
    regionalExperiences: {
      defaultMessage: 'Regional Experiences',
    },
    tcf: {
      defaultMessage: 'Transparency & Consent Framework',
    },
    auditTrail: {
      defaultMessage: 'Audit Trail',
    },
    applications: {
      defaultMessage: 'Native Applications',
    },
    codePackages: {
      defaultMessage: 'Code Packages',
    },
    softwareDevelopmentKits: {
      defaultMessage: 'Software Development Kits',
    },
    services: {
      defaultMessage: 'Services',
    },
    sdks: {
      defaultMessage: 'Consent SDKs',
    },
  },
);
