/**
 * Fields by which you can use to order cookies
 */
export enum CookieOrderField {
  /** The name of this cookie */
  Name = 'name',
  /** When the cookie was created */
  CreatedAt = 'createdAt',
  /** The time the cookie was updated */
  UpdatedAt = 'updatedAt',
}
