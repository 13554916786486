import { TranscendProduct } from '@transcend-io/privacy-types';

import type { ExtendableRoute } from '@main/admin-dashboard/src/routes';
import { PreferenceStorePath } from '@main/cm-types';

import { createOnboardingItemsComponent } from '../Auth/ActionItems/OnboardingItemsCollections/OnboardingItemCollections';
import { navMenuMessages } from '../routes/OrganizationRoute/NavMenu/messages';
import { ConsentWorkflows } from './ConsentWorkflows/Loadable';
import { preferencePageTitleMessages } from './messages';
import { PreferenceDetails } from './PreferenceDetails/Loadable';
import { PreferenceOptions } from './PreferenceOptions/Loadable';
import { PreferenceStoreSettings } from './PreferenceSettings/Loadable';
import { PreferenceStore } from './PreferenceTable/Loadable';
import { PreferenceTopics } from './PreferenceTopics/Loadable';

const preferenceStoreBreadcrumb = {
  id: 'preference-store-header',
  displayText: preferencePageTitleMessages.preferenceManagement,
  href: PreferenceStorePath.PreferenceStore,
};

const consentWorkflowsBreadcrumb = [
  preferenceStoreBreadcrumb,
  {
    id: PreferenceStorePath.ConsentWorkflows,
    displayText: preferencePageTitleMessages.consentWorkflows,
    href: PreferenceStorePath.ConsentWorkflows,
  },
];

export const preferenceStoreRoutes: {
  [k in PreferenceStorePath]: ExtendableRoute;
} = {
  [PreferenceStorePath.PreferenceStore]: {
    path: PreferenceStorePath.PreferenceStore,
    title: preferencePageTitleMessages.preferenceManagement,
    component: PreferenceStore,
    breadcrumbs: [
      preferenceStoreBreadcrumb,
      {
        id: PreferenceStorePath.PreferenceStore,
        displayText: preferencePageTitleMessages.userPreferences,
        href: PreferenceStorePath.PreferenceStore,
      },
    ],
  },
  [PreferenceStorePath.UserPreferenceSummary]: {
    path: PreferenceStorePath.UserPreferenceSummary,
    title: preferencePageTitleMessages.userPreferenceSummary,
    component: PreferenceDetails,
    breadcrumbs: [
      preferenceStoreBreadcrumb,
      {
        id: PreferenceStorePath.PreferenceStore,
        displayText: preferencePageTitleMessages.userPreferences,
        href: PreferenceStorePath.PreferenceStore,
      },
      {
        id: PreferenceStorePath.UserPreferenceSummary,
        displayText: preferencePageTitleMessages.userPreferenceSummary,
        href: PreferenceStorePath.UserPreferenceSummary,
      },
    ],
  },
  [PreferenceStorePath.PreferenceStoreSettings]: {
    path: PreferenceStorePath.PreferenceStoreSettings,
    title: preferencePageTitleMessages.settings,
    component: PreferenceStoreSettings,
    breadcrumbs: [
      preferenceStoreBreadcrumb,
      {
        id: PreferenceStorePath.PreferenceStoreSettings,
        displayText: preferencePageTitleMessages.settings,
        href: PreferenceStorePath.PreferenceStoreSettings,
      },
    ],
  },
  [PreferenceStorePath.ConsentWorkflows]: {
    path: PreferenceStorePath.ConsentWorkflows,
    title: preferencePageTitleMessages.consentWorkflows,
    component: ConsentWorkflows,
    breadcrumbs: consentWorkflowsBreadcrumb,
  },
  [PreferenceStorePath.PreferenceTopics]: {
    path: PreferenceStorePath.PreferenceTopics,
    title: preferencePageTitleMessages.consentWorkflows,
    component: PreferenceTopics,
    breadcrumbs: [
      preferenceStoreBreadcrumb,
      {
        id: PreferenceStorePath.PreferenceTopics,
        displayText: preferencePageTitleMessages.preferenceTopics,
        href: PreferenceStorePath.PreferenceTopics,
      },
    ],
  },
  [PreferenceStorePath.PreferenceOptions]: {
    path: PreferenceStorePath.PreferenceOptions,
    title: preferencePageTitleMessages.preferenceOptions,
    component: PreferenceOptions,
    breadcrumbs: [
      preferenceStoreBreadcrumb,
      {
        id: PreferenceStorePath.PreferenceOptions,
        displayText: preferencePageTitleMessages.preferenceOptions,
        href: PreferenceStorePath.PreferenceOptions,
      },
    ],
  },
  [PreferenceStorePath.ActionItems]: {
    path: PreferenceStorePath.ActionItems,
    title: navMenuMessages.linkActionItems,
    component: createOnboardingItemsComponent(
      TranscendProduct.PreferenceManagement,
    ),
    breadcrumbs: [preferenceStoreBreadcrumb],
  },
};
