import { statusDescriptionMessages, statusTitleMessages } from './messages';
import type { RequestStatusConfig } from './types';

/**
 * ## Datamap Compilation
 * All identifiers for the request are verified, and the request should be fanned out across
 * all data silos that are configured for that request action.
 *
 * At this point, we finally start distributing the request across the organization's systems, in a load-balanced manner.
 *
 * ### ON CHANGE TO
 *  - All data silos configured for the request's action/data subject class will be queued up to be processed in the context of the request.
 *  - Each integration type will have its own queue that will process requests for all data silos of that integration type
 */
export const COMPILING_STATUS: RequestStatusConfig = {
  color: 'darkPrimary',
  icon: 'database',
  isBuilding: true,
  messages: {
    description: statusDescriptionMessages.COMPILING,
    title: statusTitleMessages.COMPILING,
  },
  transitions: [
    /** The data subject invalidates one of their identifier, i.e. clicks "Not you?" in the email */
    'FAILED_VERIFICATION',
    /**
     * Once all data silos return a successful completion state,
     * the organization has no postflight hook setup for that action,
     * and the action is configured with an approval step
     */
    'APPROVING',
    /**
     * - Once all data silos return a successful completion state
     * - The organization has no postflight hook setup for that action
     * - The action is configured without an approval step
     * - There is no secondary action
     */
    'COMPLETED',
    /**
     * - Once an ACCESS request is completed and is now downloadable from the privacy center or API.
     */
    'DOWNLOADABLE',
    /**
     * - Once a request is completed but can only be previewed by its data collection categories
     * - and is not downloadable
     */
    'VIEW_CATEGORIES',
    /**
     * When download reports are turned off, a request can transition from approving state
     * directly to the begin erasure now state
     */
    'SECONDARY',
    /**
     * - Once all data silos return a successful completion state
     * - The organization has no postflight hook setup for that action
     * - The action is configured without an approval step
     * - There is a secondary action
     */
    'DELAYED',
    /** Organization member clicks cancel, triggering cancelation email and the request is killed */
    'CANCELED',
    /** Place the request on hold */
    'ON_HOLD',
    /** Data subject can revoke */
    'REVOKED',
  ],
};
