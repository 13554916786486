import {
  FlexColumnCenterBoth,
  Icon,
  IconProps,
  IWithClassName,
  IWithStyle,
} from '@main/core-ui';
import React from 'react';
import { useTheme } from 'styled-components';

export const VendorIcon: React.FC<
  IWithStyle &
    IWithClassName &
    Pick<IconProps, 'size'> & {
      /** The logo to display. If none is provided, a fallback icon will be used. */
      logoSquare?: string;
      /**
       * Optional name of the vendor to display the icon for. If the name of the vendor is close by, alt text is not necessary
       * https://html.spec.whatwg.org/multipage/images.html#a-graphical-representation-of-some-of-the-surrounding-text
       */
      alt?: string;
    }
> = ({ className, logoSquare, alt, size, style }) => {
  const theme = useTheme();

  return (
    <FlexColumnCenterBoth
      as="span"
      style={{ ...style, ...(size ? { height: size, width: size } : {}) }}
      className={className}
    >
      {logoSquare ? (
        <img
          src={logoSquare}
          alt={alt}
          style={size ? { height: '100%', width: '100%' } : {}}
        />
      ) : (
        // placeholder for vendors without a logo
        <Icon
          type="circle-dotted-line"
          size={size}
          color={theme.colors.transcendNavy4}
        />
      )}
    </FlexColumnCenterBoth>
  );
};
