import * as t from 'io-ts';

import { dbModelId } from '@main/schema-utils';

import { AuditEventCode } from '../schema/enums';
import { mkAuditEventCodec } from '../types';

export const AuditEventDataSiloPlugin = mkAuditEventCodec(
  AuditEventCode.DataSiloPlugin,
  t.type({
    /** ID of the plugin */
    pluginId: dbModelId('plugin'),
    /** ID of the data silo */
    dataSiloId: dbModelId('dataSilo'),
  }),
);

/** Override types. */
export type AuditEventDataSiloPlugin = t.TypeOf<
  typeof AuditEventDataSiloPlugin
>;
