import { datadogLogs } from '@datadog/browser-logs';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  CurrentUser,
  Organization,
  Preference,
} from '@main/access-control-types';
import type { DhEncryptionKeys } from '@main/sombra-types';

import { setDataDogContext } from '../../datadog';
import { identifyUser, resetTracking } from './tracking';

/**
 * The redux store for App
 */
export interface AppState {
  /** The currently logged in user */
  user?: CurrentUser;
  /** URL of the Transcend backend to use and related login information */
  selectedBackend?: {
    /** The URL for the backend */
    url: string;
  };
  /** When true, the currently logged in employee attempted to login to sombra and failed */
  noSombraAuth: boolean;
  /** Organization preferences */
  organizationPreference?: Preference | null;
  /** The current user's preferences */
  userPreference?: Preference | null;
  /** The sombra session secret to leverage to sign diffie hellman payloads */
  sombraSessionSecret?: string;
  /**
   * Store the sombra public key to use for diffie hellman related calls
   * when using the unauthenticated AVC view.
   *
   * If a user is logged in, the user.organization.sombraPublicKeyEcdh
   * will be used in priority over this value - to enforce that this value
   * is only used when the user is not authenticated using a session
   */
  sombraPublicKeyEcdhForAvc?: string;
  /**
   * When logging in via SSO, the diffie hellman decryption keys need to be cached to local storage
   * so that they can be used to decrypt items upon redirection back to the app
   *
   * This is only used for SSO logins, in all other situations, the diffie hellman keys can be
   * generated in memory, and used to decrypt the response in memory.
   */
  dhEncryptionKeysForSsoLogin?: DhEncryptionKeys;
}

export const INITIAL_STATE = {
  noSombraAuth: false,
  user: undefined,
  selectedBackend: undefined,
  sombraSessionSecret: undefined,
  organizationPreference: undefined,
  userPreference: undefined,
  dhEncryptionKeysForSsoLogin: undefined,
  sombraPublicKeyEcdhForAvc: undefined,
} as AppState;

/**
 * Reducer for the Global App
 * The reducer takes care of the auth state changes in our app through actions
 */
export const adminDashboardAuthSlice = createSlice({
  name: 'AdminDashboardAuth',
  initialState: INITIAL_STATE,
  reducers: {
    // Sets the authentication state of the application (the current user)
    setUser: (
      state,
      { payload: user }: PayloadAction<CurrentUser | undefined>,
    ) => {
      if (user) {
        identifyUser(user);
      }
      return { ...state, user };
    },
    // Store the sombra authentication secret once logged in
    setSombraSessionSecret: (
      state,
      { payload: sombraSessionSecret }: PayloadAction<string | undefined>,
    ) => ({
      ...state,
      sombraSessionSecret,
      noSombraAuth: !sombraSessionSecret,
      // no longer needed once logged in
      dhEncryptionKeysForSsoLogin: undefined,
      sombraPublicKeyEcdhForAvc: undefined,
    }),
    // Store the sombra authentication for the AVC connection
    setSombraSessionSecretForAvc: (
      state,
      {
        payload,
      }: PayloadAction<{
        /** The updated sombra session secret that will be used to authenticate to the AVC view */
        sombraSessionSecret: string;
        /** The sombra public key to use */
        sombraPublicKeyEcdhForAvc: string;
      }>,
    ) => ({
      ...state,
      ...payload,
    }),
    // Store the dh encryption keys for SSO logins
    setDhEncryptionKeysForSsoLogin: (
      state,
      {
        payload: dhEncryptionKeysForSsoLogin,
      }: PayloadAction<DhEncryptionKeys | undefined>,
    ) => ({
      ...state,
      dhEncryptionKeysForSsoLogin,
    }),
    // change backend URL that is talked to
    setSelectedBackend: (state, { payload: url }: PayloadAction<string>) => {
      setDataDogContext('selectedBackend', url);
      datadogLogs.logger.info(`Selected backend: ${url}`, {
        selectedBackend: url,
      });
      return {
        ...state,
        selectedBackend: {
          url,
        },
      };
    },
    setPreferences: (
      state,
      {
        payload: { userPreference, organizationPreference },
      }: PayloadAction<{
        /** preferences for a user */
        userPreference?: Preference | null;
        /** preferences for an organization */
        organizationPreference?: Preference | null;
      }>,
    ) => ({
      ...state,
      ...(typeof userPreference === 'undefined' ? {} : { userPreference }),
      ...(typeof organizationPreference === 'undefined'
        ? {}
        : { organizationPreference }),
    }),
    // Logs out the current user
    logout: () => {
      // empty the cache
      localStorage.clear(); // TODO should be handled at different level

      // reset trackers that were set at login
      resetTracking();

      return INITIAL_STATE;
    },
    // The user successfully logged in
    userLoggedIn: (
      state,
      {
        payload: { user, sombraSessionSecret },
      }: PayloadAction<{
        /** The logged in user */
        user: CurrentUser;
        /** The sombra session secret */
        sombraSessionSecret: string | undefined;
      }>,
    ) => ({
      ...state,
      user,
      sombraSessionSecret,
      // Cannot login to sombra if failed attempt at using transcend login and this is a role based login
      // If logging in with SSO or password, user will be prompted to re-login when asking for elevated permissions
      noSombraAuth: !sombraSessionSecret,
      // no longer needed once logged in
      dhEncryptionKeysForSsoLogin: undefined,
    }),
    // The organization global settings were updated
    organizationUpdated: (
      state,
      { payload: organization }: PayloadAction<Organization>,
    ) => {
      // not great to have to if statement but probably better fix in future
      if (state.user) {
        state.user.organization = organization;
      }
    },
    // The user is not able to login to sombra
    setNoSombraAuth: (
      state,
      { payload: noSombraAuth }: PayloadAction<boolean>,
    ) => ({
      ...state,
      noSombraAuth,
    }),
  },
});

export const {
  setUser,
  setSombraSessionSecret,
  setNoSombraAuth,
  setPreferences,
  setSelectedBackend,
  setDhEncryptionKeysForSsoLogin,
  userLoggedIn,
  setSombraSessionSecretForAvc,
  logout,
  organizationUpdated,
} = adminDashboardAuthSlice.actions;
