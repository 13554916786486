import { useMemo } from 'react';

import { AttributeKey } from '@main/attribute-types';

export const useAttributeColumnHeaders = (attributeKeys?: {
  /** the list of attribute keys */
  nodes: AttributeKey[];
}): Record<string, string> =>
  useMemo(
    () =>
      // Changing this output will result in potential breakage of existing customer's saved preferences and/or data reports/
      // Proceed with caution (and migrations).
      (attributeKeys?.nodes || []).reduce(
        (headers, { id, name }) => ({ ...headers, [id]: name }),
        {},
      ),
    [attributeKeys],
  );
