import { makeEnum } from '@transcend-io/type-utils';

/**
 *  The types of communication each marketing purpose could be associated to
 */

export const CommunicationType = makeEnum({
  Email: 'EMAIL',
  Phone: 'PHONE',
  Push: 'PUSH',
});

/** Override type */
export type CommunicationType =
  (typeof CommunicationType)[keyof typeof CommunicationType];

/**
 * The authentication level required for updating a purpose on preference store
 */
export const PreferenceStoreAuthLevel = makeEnum({
  Required: 'REQUIRED',
  Optional: 'OPTIONAL',
  AllowOptOut: 'ALLOW_OPT_OUT',
});

/** Override type */
export type PreferenceStoreAuthLevel =
  (typeof PreferenceStoreAuthLevel)[keyof typeof PreferenceStoreAuthLevel];

/**
 * The supported option on conditional expression
 */
export const SupportedConditionals = makeEnum({
  Or: 'Or',
  And: 'And',
});

/** Override type */
export type SupportedConditionals =
  (typeof SupportedConditionals)[keyof typeof SupportedConditionals];
