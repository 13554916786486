import { ApolloClient } from '@main/core-ui';
import { ImmutableUrl } from '@main/immutable-url';

import { BACKEND_API_URLS } from './args';
import { createApolloClient } from './graphql';

export const ALL_REGION_APOLLO_CLIENTS = new Map<string, ApolloClient<any>>(
  BACKEND_API_URLS.map((url) => [
    url,
    createApolloClient(new ImmutableUrl(url)),
  ]),
);
