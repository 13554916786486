import { ApolloCache, DocumentNode, TypedDocumentNode } from '@apollo/client';

import { SubDataPoint } from '@main/datamap-types';

export const updateSubDataPointsGqlCache = ({
  subDataPointsToUpdate,
  cache,
  data,
  fragment,
  shouldUpdate,
}: {
  /** Subdatapoints to evaluate for updates */
  subDataPointsToUpdate;
  /** The cache itself */
  cache: ApolloCache<any>;
  /** The data with which to update the cached subdatapoints */
  data: any;
  /** The GQL fragment for the fields getting updated */
  fragment: DocumentNode | TypedDocumentNode<any, any>;
  /** Function to evaluate whether or not to update the subdatapoint in the cache */
  shouldUpdate: (sdp: SubDataPoint) => boolean;
}): void => {
  cache.modify({
    fields: {
      subDataPoints: (subDataPointRefs) => {
        subDataPointsToUpdate.forEach((subDataPoint) => {
          if (shouldUpdate(subDataPoint)) {
            cache.writeFragment({
              id: `SubDataPoint:{"id":"${subDataPoint.id}"}`,
              data,
              fragment,
            });
          }
        });
        // This stops a refetch from automatically being rerun
        return subDataPointRefs;
      },
    },
  });
};
