import React, { useCallback, useEffect, useState } from 'react';

import { AddLinkModal } from '../AddLinkModal';
import { TipTapMarkType } from './enums';
import { toolbarMessages } from './messages';
import { ToolbarButton } from './ToolbarButton';
import { ToolbarButtonGroupProps } from './types';

export const LinkButtonGroup: React.FC<ToolbarButtonGroupProps> = ({
  editor,
}) => {
  const [showModal, setShowModal] = useState(false);

  const handleKeyDown = useCallback(
    (event: KeyboardEvent): void => {
      if (
        event.key === 'k' &&
        (event.ctrlKey || event.metaKey) &&
        editor.isEditable &&
        !showModal &&
        editor.isFocused
      ) {
        event.preventDefault();
        setShowModal(true);
      }
    },
    [editor.isFocused, editor.isEditable, showModal],
  );

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [editor.isFocused, editor.isEditable, handleKeyDown]);

  return (
    <>
      <ToolbarButton
        title={
          editor.isActive(TipTapMarkType.Link)
            ? toolbarMessages.unlink
            : toolbarMessages.link
        }
        onClick={() =>
          editor.isActive(TipTapMarkType.Link)
            ? // Unset link
              editor.chain().focus().unsetLink().run()
            : // Open modal to set link
              setShowModal(true)
        }
        icon={editor.isActive(TipTapMarkType.Link) ? 'link-unlink' : 'link'}
        disabled={false}
      />

      {showModal && (
        <AddLinkModal
          editor={editor}
          onHide={() => setShowModal(false)}
          onSubmit={({ destination }) => {
            // Unlink if destination is empty
            if (destination === '') {
              editor.chain().focus().extendMarkRange('link').unsetLink().run();
              return;
            }

            // Update link
            editor
              .chain()
              .focus()
              .extendMarkRange('link')
              .setLink({ href: destination })
              .run();

            // Close modal
            setShowModal(false);
          }}
          show
        />
      )}
    </>
  );
};
