import type { RequestStatus } from '@transcend-io/privacy-types';
import { createEnum } from '@transcend-io/type-utils';

import { REQUEST_STATUSES_BUILDING } from './isBuilding';

/**
 * The statuses that are action items and require attention
 */
export const REQUEST_STATUSES_ACTIVE = [
  ...REQUEST_STATUSES_BUILDING,
  'SECONDARY',
] as RequestStatus[];

/**
 * The statuses that are action items
 */
export const REQUEST_STATUS_ACTIVE = createEnum(REQUEST_STATUSES_ACTIVE);

/**
 * A request is active if its building or Secondary
 *
 * @param status - The status of the request to check
 * @returns True if the a building or secondary status
 */
export function isActive(status: RequestStatus): boolean {
  return status in REQUEST_STATUS_ACTIVE;
}
