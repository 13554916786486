/**
 * joins and ellipsizes (if necessary) a list of csv values
 *
 * @param list - the list to join
 * @param count - the total count of values
 * @returns the joined csv with an ellipsis on the end if count > list.length
 */
export function ellipsizeAndJoinCsvValues(
  list: (string | undefined)[] | undefined,
  count: number,
): string {
  return list && list.filter((item) => !!item).length > 0
    ? list.join(',') + (count > list.length ? ', ...' : '')
    : '';
}
