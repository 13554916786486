/* eslint-disable max-lines */
import { findAllWithRegex, RegExpMatch } from '@transcend-io/type-utils';

import { ConsentManagerStyleItem } from '../schema';

/**
 * Default style sheet encoding for consent manger ui
 */
export interface DefaultConsentManagerStyleItem
  extends ConsentManagerStyleItem {
  /**
   * Ordering of css style in media. This is only needed for the purposes of testing equality
   * when translating a CSS file to the `DefaultConsentManagerStyleItem` and back.
   */
  indexOfMediaStyle?: number;
}

export const MEDIA_STYLE_SELECTOR = `@media (min-width: 640px)`;

/**
 * Clean CSS for logic comparison
 *
 * @param css - The CSS string
 * @returns CSS string stripped down to bare minimum to compare
 */
export function cleanCssFileForComparison(css: string): string {
  return (
    css
      // remove comments
      .replace(/\/\*[\s\S]*?\*\/|([^\\:]|^)\/\/.*$/gm, '')
      // remove extra newlines
      .split('\n')
      .map((x) => x.trim())
      .filter((x) => x)
      .join('\n')
  );
}

export const DEFAULT_STYLES_HEADER_NAME = 'Misc';

/**
 * Construct the CSS variable string from theme inputs
 *
 * @param options - Options
 * @returns CSS string
 */
export function buildStylesheetWithVariables({
  primaryColor,
  fontColor,
  styles,
}: {
  /** Primary color */
  primaryColor: string;
  /** Font color */
  fontColor: string;
  /** Styles */
  styles: ConsentManagerStyleItem[];
}): string {
  return `/** Variables to insert into the remainder of the stylesheet */
* {
  --primary-color: ${primaryColor};
  --text-color: ${fontColor};
}

${styleItemsToStylesheet(styles)}`;
}

/**
 * Finds the line that a given char index is on within the corpus
 *
 * @param lineIndices - list of line start indices
 * @param charIdx - char index within the corpus
 * @returns line num the char is found on
 */
function getLineNum(lineIndices: number[], charIdx: number): number {
  return lineIndices.findIndex(
    (lineNum, idx, arr) =>
      lineNum <= charIdx && charIdx < (arr[idx + 1] ?? -Infinity),
  );
}

/**
 * Computes the line range that a parsed style match string exists between
 *
 * @param matchIndex - starting index of the match within the corpus
 * @param matchLength - length of the match string
 * @param lineIndices - list of line start indices
 * @param offset - offset to add to the start if we dont match from the beginning of the string
 * @returns the [start, end] range (in lines) that the style begins and ends on
 */
function getStyleLineRange(
  matchIndex: number,
  matchLength: number,
  lineIndices: number[],
  offset: number = 0,
): [number, number] {
  const start = matchIndex + offset;
  const end = start + matchLength;
  const startLine = getLineNum(lineIndices, start);
  let endLine = getLineNum(lineIndices, end);
  endLine = endLine === -1 ? lineIndices.length - 1 : endLine;
  return [startLine, endLine];
}

/**
 * Marks the line usage array as used/unused within a given line range
 *
 * @param usedArray - array that details whether a line has been used or not
 * @param range - starting and ending line to mark as used/unused
 * @param used - whether to mark the line used or unused
 */
function markLineUsage(
  usedArray: boolean[],
  range: [number, number],
  used: boolean = true,
): void {
  for (let i = range[0]; i <= range[1]; i += 1) {
    // eslint-disable-next-line no-param-reassign
    usedArray[i] = used;
  }
}

/**
 * Sums up the number of each brace char and returns an object containing the result
 *
 * @param matchString - string to sum up opening/closing braces in
 * @returns object with the sum total of brace chars
 */
function sumBraces(matchString: string): {
  /** num of opening braces */
  '{': number;
  /** num of closing braces */
  '}': number;
} {
  return Array(...matchString).reduce(
    (sums, char) => {
      if (['{', '}'].includes(char)) {
        return { ...sums, [char]: sums[char] + 1 };
      }
      return sums;
    },
    { '{': 0, '}': 0 },
  );
}

/**
 * Takes in a list of parsed style match strings and filters out those with a mismatched
 * number of opening or closing braces (indicating either a typo or accidental combination
 * of two separate styles)
 *
 * @param styles - full list of parsed styles
 * @returns list of styles minus improperly formatted ones
 */
function filterMismatchedBraces<T extends RegExpMatch>(styles: T[]): T[] {
  return styles.filter((ms) => {
    const braceSums = sumBraces(ms.fullMatch);
    return braceSums['{'] === braceSums['}'];
  });
}

/**
 * Extract a mapping from class name -> default CSS
 *
 * @param cssString - The CSS string to parse
 * @param checkErrors - Whether or not to check for parsing errors
 * @returns Object mapping style to CSS string
 */
export function extractStyleItems(
  cssString: string,
  checkErrors: boolean = false,
): {
  /** style items parsed from the raw css stylesheet string */
  styleItems: DefaultConsentManagerStyleItem[];
  /** set of error line numbers */
  errors: Set<number>;
} {
  let lines: string[] | undefined;
  let lineIndices: number[] | undefined;
  let linesUsed: boolean[] | undefined;
  if (checkErrors) {
    lines = cssString.split('\n');
    lineIndices = [0];
    Array(...cssString).forEach((char, idx) => {
      if (char === '\n') {
        lineIndices!.push(idx + 1);
      }
    });
    linesUsed = Array<boolean>(lines.length).fill(false);
  }
  // Read in headers sections to give styles a logic grouping
  const headers = findAllWithRegex(
    {
      value: /\/\*{2}\*+\n\s*\*\s*(.+?)\n\s*\*{2}\*+\//g,
      matches: ['section'],
    },
    cssString,
  );
  const reversedHeaders = headers.reverse();
  if (checkErrors) {
    reversedHeaders.forEach((header) => {
      const range = getStyleLineRange(
        header.matchIndex,
        header.fullMatch.length,
        lineIndices!,
      );
      markLineUsage(linesUsed!, range, true);
    });
  }

  // extract all styles selectors
  const customStyles = findAllWithRegex(
    {
      value: /\/\*\* (.+?) \*\/\n(.+)( |,\n.+){([\s\S]+?)\n}/g,
      matches: ['comment', 'selector', 'selector2', 'styles'],
    },
    cssString,
  );
  if (checkErrors) {
    const filteredStyles = customStyles.filter((cs) => {
      if (cs.fullMatch.includes('@media')) return true;
      const braceSums = sumBraces(cs.fullMatch);
      return braceSums['{'] === braceSums['}'];
    });
    filteredStyles.forEach((cStyle) => {
      const range = getStyleLineRange(
        cStyle.matchIndex,
        cStyle.fullMatch.length,
        lineIndices!,
      );
      markLineUsage(linesUsed!, range, true);
    });
  }

  // Inputs before media styles included
  const inputsWithoutMedia = customStyles.map(
    ({ comment, selector, selector2, styles, matchIndex, fullMatch }): any => ({
      header:
        reversedHeaders.find((header) => matchIndex > header.matchIndex)
          ?.section || DEFAULT_STYLES_HEADER_NAME,
      comment,
      selector: (selector + selector2).trim(),
      styles: cleanCssFileForComparison(styles),
      rawStyles: styles,
      matchIndex,
      length: fullMatch.length,
    }),
  );

  // Special case media styles
  const withoutMediaStyles = inputsWithoutMedia.filter(
    (input) => input.selector !== MEDIA_STYLE_SELECTOR,
  );
  const mediaStyle = inputsWithoutMedia.find(
    (input) => input.selector === MEDIA_STYLE_SELECTOR,
  );
  if (!mediaStyle) {
    throw new Error(
      `Expected to find selector: ${MEDIA_STYLE_SELECTOR} in cm.css`,
    );
  }
  const mediaStyles = findAllWithRegex(
    {
      value: /(\.[\s\S]+?) {([\s\S]+?)}/g,
      matches: ['selector', 'styles'],
    },
    mediaStyle.styles,
  );

  // merge media styles and regular styles
  mediaStyles.forEach((media, ind) => {
    const existing = withoutMediaStyles.find(
      (selector) => selector.selector.trim() === media.selector.trim(),
    );
    if (existing) {
      existing.mediaStyles = cleanCssFileForComparison(media.styles);
      existing.indexOfMediaStyle = ind;
    } else {
      withoutMediaStyles.push({
        styles: '',
        mediaStyles: cleanCssFileForComparison(media.styles),
        selector: media.selector.trim(),
        indexOfMediaStyle: ind,
        header: 'Media Styles',
        comment: '',
      });
    }
  });

  if (checkErrors) {
    const rawMediaStyles = findAllWithRegex(
      {
        value: /(\.[\s\S]+?) {([\s\S]+?)}/g,
        matches: ['selector', 'styles'],
      },
      mediaStyle.rawStyles,
    );
    const filteredMediaStyles = filterMismatchedBraces(rawMediaStyles);

    const [mediaStart, mediaEnd] = getStyleLineRange(
      mediaStyle.matchIndex,
      mediaStyle.length,
      lineIndices!,
    );
    markLineUsage(linesUsed!, [mediaStart + 2, mediaEnd - 1], false);
    filteredMediaStyles.forEach((mStyle) => {
      const offset = lineIndices![mediaStart + 2] - 1; // -1 because we catch the previous \n char
      const range = getStyleLineRange(
        mStyle.matchIndex,
        mStyle.fullMatch.length,
        lineIndices!,
        offset,
      );
      markLineUsage(linesUsed!, range, true);
    });
    lines!.forEach((line, idx) => {
      const trimmed = line.trim();
      if (!trimmed || trimmed.startsWith('//')) {
        linesUsed![idx] = true;
      }
    });
  }

  return {
    styleItems: withoutMediaStyles.map((si) => ({
      header: si.header,
      comment: si.comment,
      selector: si.selector,
      styles: si.styles,
      ...(si.indexOfMediaStyle != null
        ? { indexOfMediaStyle: si.indexOfMediaStyle }
        : {}),
      ...(si.mediaStyles != null ? { mediaStyles: si.mediaStyles } : {}),
    })),
    errors: new Set(
      linesUsed
        ?.map((lu, idx) => (lu ? undefined : idx))
        ?.filter((x) => x !== undefined) ?? [],
    ) as Set<number>,
  };
}

/**
 * Given a set of styles, convert them into a .css stylesheet
 *
 * @param styles - Styles to convert
 * @returns A style sheet string, this could be saved to disk like `stylesheet.css`
 */
export function styleItemsToStylesheet(
  styles: DefaultConsentManagerStyleItem[],
): string {
  // construct top level styles
  let stylesheet = '';
  styles
    .filter((style) => style.styles)
    .forEach((style) => {
      stylesheet += `/** ${style.comment} */\n${
        style.selector
      } {\n${style.styles
        .split('\n')
        .map((ln) => `  ${ln}`)
        .join('\n')}\n}\n\n`;
    });

  // construct media files for devices > 640px
  let mediaStyles = '';
  styles
    .filter((style) => style.mediaStyles)
    .sort((a, b) => (a.indexOfMediaStyle || 0) - (b.indexOfMediaStyle || 0))
    .forEach((style) => {
      mediaStyles += `${style.selector} {\n${style
        .mediaStyles!.split('\n')
        .map((ln) => `  ${ln}`)
        .join('\n')}\n}\n`;
    });

  // make the style sheet
  return `${stylesheet}${
    mediaStyles
      ? `/** At least tablet */\n${MEDIA_STYLE_SELECTOR} {\n${mediaStyles
          .split('\n')
          .map((ln) => `  ${ln}`)
          .join('\n')}\n}\n`
      : ''
  }`;
}
/* eslint-enable max-lines */
