import { mkInput, SchemaToType } from '@main/schema-utils';

import { PrivacyCenterType } from './enums';

export const PrivacyCenterLookupInput = mkInput({
  name: 'PrivacyCenterLookupInput',
  comment: 'Input for querying a privacy center',
  fields: {
    url: {
      comment: 'Lookup privacy center by URL',
      optional: true,
      type: 'string',
    },
    type: {
      comment:
        'Get by type (if an organization is in context, i.e. admin dashboard)',
      optional: true,
      type: { PrivacyCenterType },
    },
  },
});

/** Override type */
export type PrivacyCenterLookupInput = SchemaToType<
  typeof PrivacyCenterLookupInput
>;

export const PrivacyCenterIdInput = mkInput({
  name: 'PrivacyCenterIdInput',
  comment: 'Input for querying a privacy center from the admin dashboard',
  fields: {
    privacyCenterId: {
      comment: 'ID of the privacy center',
      modelName: 'privacyCenter',
      type: 'id',
    },
  },
});

/** Override type */
export type PrivacyCenterIdInput = SchemaToType<typeof PrivacyCenterIdInput>;
