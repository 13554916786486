// Locally, we do not check this file as the local Typescript server in editors cannot handle the large JSON files.
// On CI, we will remove this full comment block and will still typecheck this file
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import {
  LanguageKey,
  TranslatedMessages,
} from '@transcend-io/internationalization';

/**
 * Translations of intl messages
 */
export const ADMIN_DASHBOARD_TRANSLATIONS: {
  [k in LanguageKey]: () => Promise<{
    /** Default export */
    default: TranslatedMessages;
  }>;
} = {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  [LanguageKey.En]: () => import('./en.json'),
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  [LanguageKey.Ar]: () => import('./ar.json'),
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  [LanguageKey.Fr]: () => import('./fr.json'),
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  [LanguageKey.De]: () => import('./de.json'),
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  [LanguageKey.It]: () => import('./it.json'),
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  [LanguageKey.Ja]: () => import('./ja.json'),
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  [LanguageKey.Ru]: () => import('./ru.json'),
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  [LanguageKey.Es]: () => import('./es.json'),

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  [LanguageKey.ArAe]: () => import('./ar-AE.json'),
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  [LanguageKey.FrFr]: () => import('./fr-FR.json'),
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  [LanguageKey.DeDe]: () => import('./de-DE.json'),
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  [LanguageKey.ItIt]: () => import('./it-IT.json'),
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  [LanguageKey.AfZz]: () => import('./af-ZA.json'),
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  [LanguageKey.BgBg]: () => import('./bg-BG.json'),
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  [LanguageKey.ZhCn]: () => import('./zh-CN.json'),
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  [LanguageKey.HrHr]: () => import('./hr-HR.json'),
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  [LanguageKey.CsCz]: () => import('./cs-CZ.json'),
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  [LanguageKey.DaDk]: () => import('./da-DK.json'),
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  [LanguageKey.EnGb]: () => import('./en-GB.json'),
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  [LanguageKey.EnCa]: () => import('./en-CA.json'),
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  [LanguageKey.EnAe]: () => import('./en-AE.json'),
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  [LanguageKey.FiFi]: () => import('./fi-FI.json'),
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  [LanguageKey.ElGr]: () => import('./el-GR.json'),
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  [LanguageKey.HiIn]: () => import('./hi-IN.json'),
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  [LanguageKey.HuHu]: () => import('./hu-HU.json'),
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  [LanguageKey.IdId]: () => import('./id-ID.json'),
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  [LanguageKey.JaJp]: () => import('./ja-JP.json'),
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  [LanguageKey.KoKr]: () => import('./ko-KR.json'),
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  [LanguageKey.LtLt]: () => import('./lt-LT.json'),
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  [LanguageKey.MsMy]: () => import('./ms-MY.json'),
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  [LanguageKey.MrIn]: () => import('./mr-IN.json'),
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  [LanguageKey.NbNi]: () => import('./nb-NO.json'),
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  [LanguageKey.PlPl]: () => import('./pl-PL.json'),
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  [LanguageKey.PtBr]: () => import('./pt-BR.json'),
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  [LanguageKey.PtPt]: () => import('./pt-PT.json'),
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  [LanguageKey.RoRo]: () => import('./ro-RO.json'),
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  [LanguageKey.RuRu]: () => import('./ru-RU.json'),
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  [LanguageKey.SrLatnRs]: () => import('./sr-Latn-RS.json'),
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  [LanguageKey.SvSe]: () => import('./sv-SE.json'),
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  [LanguageKey.TaIn]: () => import('./ta-IN.json'),
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  [LanguageKey.ThTh]: () => import('./th-TH.json'),
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  [LanguageKey.TrTr]: () => import('./tr-TR.json'),
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  [LanguageKey.UkUa]: () => import('./uk-UA.json'),
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  [LanguageKey.ViVn]: () => import('./vi-VN.json'),
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  [LanguageKey.ZuZa]: () => import('./zu-ZA.json'),
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  [LanguageKey.EnUS]: () => import('./en-US.json'),
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  [LanguageKey.EnAu]: () => import('./en-AU.json'),
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  [LanguageKey.FrBe]: () => import('./fr-BE.json'),
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  [LanguageKey.FrCa]: () => import('./fr-CA.json'),
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  [LanguageKey.EnIe]: () => import('./en-IE.json'),
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  [LanguageKey.NlNl]: () => import('./nl-NL.json'),
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  [LanguageKey.NlBe]: () => import('./nl-BE.json'),
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  [LanguageKey.EsEs]: () => import('./es-ES.json'),
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  [LanguageKey.Es419]: () => import('./es-419.json'),
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  [LanguageKey.ZhHk]: () => import('./zh-HK.json'),
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  [LanguageKey.DeAt]: () => import('./de-AT.json'),
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  [LanguageKey.DeCh]: () => import('./de-CH.json'),
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  [LanguageKey.ItCh]: () => import('./it-CH.json'),
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  [LanguageKey.FrCh]: () => import('./fr-CH.json'),
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  [LanguageKey.HeIl]: () => import('./he-IL.json'),
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  [LanguageKey.EnNz]: () => import('./en-NZ.json'),
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  [LanguageKey.EtEe]: () => import('./et-EE.json'),
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  [LanguageKey.IsIs]: () => import('./is-IS.json'),
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  [LanguageKey.LvLv]: () => import('./lv-LV.json'),
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  [LanguageKey.MtMt]: () => import('./mt-MT.json'),
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  [LanguageKey.SkSk]: () => import('./sk-SK.json'),
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  [LanguageKey.SlSl]: () => import('./sl-SL.json'),
};
