import {
  GenericFormattedMessage,
  GenericMessageDescriptor,
  message,
} from '@main/core-ui';
import { Optionalize } from '@transcend-io/type-utils';
import mime from 'mime/lite';
import React, { useState } from 'react';
import { Accept, useDropzone } from 'react-dropzone';
import { useIntl } from 'react-intl';

import {
  fileIsAcceptedType,
  fileIsLessThanMaxSize,
  fileUploadMessages,
  FileUploadOptions,
  uploadFile,
} from '../utils';
import { uploadImageMessages } from './messages';
import {
  DescriptionWrapper,
  DropzoneText,
  DropzoneWrapper,
  ImagePreview,
  RemoveButton,
  UploadButton,
} from './wrappers';

/**
 * File with details of its uploaded src
 */

interface UploadedFile {
  /** name of the file as uploaded */
  name: string;
  /** Details from the uploaded response */
  uploadedFile: {
    /** The location of the uploaded file */
    src: string;
  };
}

export interface UploadImageProps
  extends Omit<Optionalize<FileUploadOptions, 'allowedSize'>, 'accept'> {
  /** The max number of files to accept. 0 means no limit  */
  maxFiles?: number;
  /** Whether to allow drag and drop of multiple files */
  multiple?: boolean;
  /** The url to POST the file to */
  uploadUrl: string;
  /** Optionally customized dropzone text */
  dropzoneText?: GenericMessageDescriptor;
  /** Optionally customized upload image button text */
  uploadButtonText?: GenericMessageDescriptor;
  /** Callback for successful upload */
  onSuccess?: (files: UploadedFile['uploadedFile'][]) => void;
  /** Callback for an upload error */
  onError?: (error: any) => void;
  /** Values, if component is controlled via parent */
  values?: UploadedFile[];
  /** File types to accept */
  accept: Accept;
}

/** MVP Upload component for ADv2 for single image uploads, maybe be extended later */
export const UploadImage = ({
  accept = {
    'image/*': [],
  },
  maxFiles = 1,
  multiple = false,
  uploadUrl,
  dropzoneText,
  uploadButtonText,
  onSuccess,
  onError,
  values,
  allowedSize = 10,
}: UploadImageProps): JSX.Element => {
  const { formatMessage } = useIntl();
  // State to keep track of the image file previews after uploading
  const [localFilePreview, setFilePreview] = useState<UploadedFile[]>(
    values || [],
  );
  const filePreview = values || localFilePreview;
  // State to keep track of past uploaded files (by name) to prevent repeated fetch calls
  const [previouslyUploadedFiles, setPreviouslyUploadedFiles] = useState<
    string[]
  >([]);

  const onDrop = async (acceptedFiles: File[]): Promise<void> => {
    const promises = acceptedFiles
      // Filter for new files only (not previously uploaded)
      .filter((file) => previouslyUploadedFiles.indexOf(file.name) < 0)
      .map((file) => {
        if (!fileIsLessThanMaxSize(file, allowedSize)) {
          message.error(
            formatMessage(fileUploadMessages.fileSizeError, { allowedSize }),
          );
          return Promise.reject();
        }
        if (!fileIsAcceptedType(file, accept)) {
          message.error(
            formatMessage(fileUploadMessages.fileSizeError, { allowedSize }),
          );
          return Promise.reject();
        }

        return uploadFile(file, uploadUrl)
          .then((data) => {
            message.success(
              formatMessage(uploadImageMessages.success, {
                file: file.name,
              }),
            );
            return { name: file.name, uploadedFile: data };
          })
          .catch((error) => {
            message.error(
              error.message ||
                formatMessage(uploadImageMessages.error, {
                  file: file.name,
                }),
            );
            if (onError) {
              onError?.(error);
            }
            return undefined;
          });
      });

    // Add the new successful uploads (by name) to the list of previously uploaded files
    const uploadedFiles = (await Promise.all(promises)).reduce<UploadedFile[]>(
      (all, file) => (file ? [...all, file] : all),
      [],
    );

    onSuccess?.(uploadedFiles.map(({ uploadedFile }) => uploadedFile));
    // Update state with uploaded file names
    setPreviouslyUploadedFiles(
      (uploadedFiles || [])
        .map(({ name }) => name)
        .concat(previouslyUploadedFiles) as string[],
    );
    // Render file image previews
    setFilePreview((current) => [...uploadedFiles, ...current]);
  };

  const removePreview = (): void => {
    setFilePreview([]);
    onSuccess?.([]);
  };

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
    open,
  } = useDropzone({ accept, onDrop, maxFiles, multiple });

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <DropzoneWrapper
        {...getRootProps({ isDragActive, isDragAccept, isDragReject })}
        $hasPreview={filePreview.length > 0}
      >
        <input {...getInputProps()} />
        {filePreview.map((file) => (
          <ImagePreview
            src={file.uploadedFile.src}
            alt={formatMessage(uploadImageMessages.previewAlt)}
            key={file.name}
          />
        ))}
        {filePreview.length === 0 && (
          <DropzoneText>
            {dropzoneText ? (
              <GenericFormattedMessage message={dropzoneText} />
            ) : (
              formatMessage(uploadImageMessages.dropzonePlaceholder)
            )}
          </DropzoneText>
        )}
      </DropzoneWrapper>
      <div>
        <DescriptionWrapper>
          {formatMessage(fileUploadMessages.fileSizeError, {
            allowedSize,
          })}
          <br />
          {formatMessage(fileUploadMessages.fileTypeError, {
            types: Object.keys(accept)
              .map((type) =>
                // split is always at least 1
                type.split('/*').length > 1
                  ? type.split('/*')[0]
                  : mime.getExtension(type),
              )
              .join(', '),
          })}
        </DescriptionWrapper>
        <div style={{ display: 'flex' }}>
          <UploadButton variant="secondary" onClick={() => open()}>
            {uploadButtonText ? (
              <GenericFormattedMessage message={uploadButtonText} />
            ) : (
              formatMessage(uploadImageMessages.uploadImage)
            )}
          </UploadButton>
          <RemoveButton
            variant="outline-danger"
            onClick={removePreview}
            disabled={filePreview.length === 0}
          >
            {formatMessage(uploadImageMessages.removeImage)}
          </RemoveButton>
        </div>
      </div>
    </div>
  );
};
