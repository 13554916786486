import * as t from 'io-ts';

import { dbModelId } from '@main/schema-utils';

import { AuditEventCode } from '../schema/enums';
import { mkAuditEventCodec } from '../types';

export const AuditEventAssessmentGroup = mkAuditEventCodec(
  AuditEventCode.AssessmentGroup,
  t.intersection([
    t.type({
      /** ID of the assessment group */
      assessmentGroupId: dbModelId('assessmentGroup'),
    }),
    t.partial({
      /** ID of the assessment form template from which the group's children are created */
      assessmentFormTemplateId: dbModelId('assessmentFormTemplate'),
    }),
  ]),
);

/** Override types. */
export type AuditEventAssessmentGroup = t.TypeOf<
  typeof AuditEventAssessmentGroup
>;
