import { createLoadable } from '@main/core-ui';

import { emailSettingsMessages } from './messages';

// Load the component dynamically
export const EmailSettingsLoadable = createLoadable({
  loader: () => import('./index'),
  messages: emailSettingsMessages,
  logo: 'setting',
});
