import { gql } from '@apollo/client';
import {
  InlineSelectTeams,
  SelectedTeam,
  SelectTeamProps,
} from '@main/ad-core-components';
import { buildUseMutation } from '@main/core-ui';
import { endpoints, SubDataPoint } from '@main/datamap-types';
import type { ID } from '@main/schema-utils';
import React from 'react';

import { updateSubDataPointsGqlCache } from '../../helpers/updateSubDataPointsGqlCache';

const useUpdateDataPoint = buildUseMutation(
  endpoints.updateOrCreateDataPoint,
  'UpdateDataPointTeams',
  {
    dataPoint: {
      id: null,
      teams: {
        id: null,
        name: null,
      },
    },
  },
);

/** Props for the `InlineSelectDataPointTeams` component */
interface InlineSelectDataPointTeamsProps
  extends Omit<SelectTeamProps, 'onChange' | 'onBlur' | 'value'> {
  /** The ID of the parent data point for this cell */
  id: ID<'dataPoint'>;
  /** The ID of the parent data silo for this cell */
  dataSiloId: ID<'dataSilo'>;
  /** The value for the cell */
  value: SelectedTeam[];
  /** Other subdatapoints with this datapoint id whose teams should also be updated after a change */
  subDataPointsToUpdate?: SubDataPoint[];
}

export const InlineSelectDataPointTeams: React.FC<
  InlineSelectDataPointTeamsProps
> = ({ id, dataSiloId, value, isLoading, subDataPointsToUpdate, ...props }) => {
  const [updateDataPoint, { loading }] = useUpdateDataPoint({
    ...(subDataPointsToUpdate
      ? {
          update: (cache, { data }) => {
            const { dataPoint } = (data as any).updateOrCreateDataPoint;

            // Manually update the cache so that we don't have to refetch the entire (slow) subdatapoints query
            updateSubDataPointsGqlCache({
              subDataPointsToUpdate,
              cache,
              data: {
                dataPointTeams: dataPoint.teams,
              },
              fragment: gql`
                fragment DataPointTeams on SubDataPoint {
                  dataPointTeams {
                    id
                    name
                  }
                }
              `,
              // dataPoint should always be defined, but could get forgotten in the GQL response fields
              shouldUpdate: (sdp) => sdp.dataPoint?.id === dataPoint.id,
            });
          },
        }
      : {}),
  });

  return (
    <InlineSelectTeams
      value={value}
      loading={loading || isLoading}
      onUpdate={(teamIds) =>
        updateDataPoint({
          variables: {
            input: {
              id,
              dataSiloId,
              teamIds,
            },
          },
        }).then(({ data, errors }) => ({
          data: data?.dataPoint?.teams,
          errors,
        }))
      }
      {...props}
    />
  );
};
