import { createLoadable } from '@main/core-ui';

// Load the components dynamically
export const PromptLoadable = createLoadable({
  loader: () => import('./Prompt'),
});

export const NewPromptLoadable = createLoadable({
  loader: () => import('./NewPrompt'),
});
