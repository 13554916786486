import { FilterConfigItem } from '@main/ad-core-components';
import { buildUseLazyQuery } from '@main/core-ui';
import { endpoints } from '@main/datamap-types';
import { ID } from '@main/schema-utils';
import { ProcessingPurpose } from '@transcend-io/privacy-types';
import partition from 'lodash/partition';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { MessageDescriptor } from 'react-intl';

import { PurposeTitle } from '../../DataMap/components';
import { SelectPurposeFilter } from '../../DataMap/DataPoints/components/DataPointsFilter/SelectPurposeFilter';
import {
  isPurposeSubCategory,
  PurposesItem,
} from '../../DataMap/Purposes/typeGuards';
import { USE_PROCESSING_PURPOSE_FILTER_FIELDS } from './constants';

/**
 * Filters definition with property for purposes specified
 */
type ProcessingPurposeEnrichedFilters = Record<string, unknown> & {
  /** Combined purpose and subcategory filter selection */
  purposes?: PurposesItem[];
};

/**
 * Hook to get the filter configuration for purposes and purpose subcategories
 *
 * @returns the config for the filters and a function to clear the filters
 */
type ProcessingPurposeFiltersBuilder<
  T extends Record<string, unknown>,
  E extends ProcessingPurposeEnrichedFilters,
> = (filterParams: {
  /** name for the property that filters on the name of the parent purpose */
  processingPurposeFilterKey?: keyof T;
  /** name for the property that filters on the ids of the purpose subcategory */
  processingPurposeSubCategoryFilterKey?: keyof T;
  /** The currently applied filters */
  filters?: T;
  /** Callback when the filters are changed */
  setFilters: (filters: T) => void;
  /** Label for the filter menu */
  label: string | MessageDescriptor;
  /** Whether to hide the option to filter on unspecified purposes */
  omitUnspecified?: boolean;
}) => {
  /** The purposes currently filtered on */
  selectedProcessingPurposes: PurposesItem[];
  /** The filter configuration to be passed to the filter manager */
  processingPurposeFiltersConfig: FilterConfigItem<E>;
  /** Callback for when the filter is cleared in the filter manager */
  clearProcessingPurposeFilters: (key: Extract<keyof E, string>) => void;
};

const useLazySubPurposes = buildUseLazyQuery(
  endpoints.processingPurposeSubCategories,
  'UseProcessingPurposeFilters',
  {
    totalCount: null,
    nodes: USE_PROCESSING_PURPOSE_FILTER_FIELDS,
  },
);

/**
 *
 * Builds elements of the filters config needed for filtering by purposes and subcategories
 *
 * @param filterParams - the currently applied filters and a function to set the filters
 * @returns Elements of the filters config needed for filtering by purposes and subcategories
 */
export const useProcessingPurposeFilters: ProcessingPurposeFiltersBuilder<
  Record<string, unknown>,
  ProcessingPurposeEnrichedFilters
> = ({
  processingPurposeFilterKey = 'purposes',
  processingPurposeSubCategoryFilterKey = 'processingPurposeSubCategoryIds',
  label,
  filters,
  setFilters,
  omitUnspecified = true,
}) => {
  const getSelectedSubPurposes = useLazySubPurposes();

  const [selectedPurposeItems, setSelectedPurposeItems] = useState<
    PurposesItem[]
  >([]);

  // Populate initial complex filter values
  useEffect(() => {
    if (filters) {
      if (selectedPurposeItems.length === 0) {
        const parentPurposeFilter = filters[processingPurposeFilterKey];
        const subPurposeFilter = filters[processingPurposeSubCategoryFilterKey];

        const selectedParentPurposes = (
          parentPurposeFilter
            ? Array.isArray(parentPurposeFilter)
              ? parentPurposeFilter
              : [parentPurposeFilter]
            : []
        ).map(
          (purpose: ProcessingPurpose) =>
            ({ purpose, isDefault: true }) as PurposesItem,
        );

        if (Array.isArray(subPurposeFilter) && subPurposeFilter.length > 0) {
          getSelectedSubPurposes({
            filterBy: { ids: subPurposeFilter },
          }).then(({ data }) => {
            setSelectedPurposeItems([
              ...(data.nodes || []),
              ...selectedParentPurposes,
            ]);
          });
        } else if (
          Array.isArray(parentPurposeFilter) &&
          parentPurposeFilter.length > 0
        ) {
          setSelectedPurposeItems(selectedParentPurposes);
        }
      }
    }
  }, []);

  const processingPurposeFiltersConfig: FilterConfigItem<ProcessingPurposeEnrichedFilters> =
    useMemo(
      () => ({
        filterKey: 'purposes',
        label,
        renderPill: ({ filterValues: { purposes = [] }, index = 0 }) => {
          const purpose = purposes[index];
          return (
            <PurposeTitle
              key={`purpose-option-${index}`}
              name={isPurposeSubCategory(purpose) ? purpose.name : undefined}
              purpose={purpose.purpose}
            />
          );
        },
        filter: (
          <SelectPurposeFilter
            menuPosition="absolute"
            showOutline
            omitUnspecified={omitUnspecified}
            value={selectedPurposeItems}
            onChange={(selections = []) => {
              setSelectedPurposeItems(selections);
              const [processingPurposeSubCategoryIds, purposes] = partition(
                selections,
                isPurposeSubCategory,
              );
              setFilters({
                ...filters,
                [processingPurposeFilterKey]: purposes.reduce<
                  ProcessingPurpose[]
                >((purposes, purpose) => [...purposes, purpose.purpose], []),
                [processingPurposeSubCategoryFilterKey]:
                  processingPurposeSubCategoryIds.reduce<
                    ID<'processingPurposeSubCategory'>[]
                  >((ids, subPurpose) => [...ids, subPurpose.id], []),
              });
            }}
          />
        ),
      }),
      [
        filters,
        label,
        omitUnspecified,
        processingPurposeFilterKey,
        processingPurposeSubCategoryFilterKey,
        selectedPurposeItems,
      ],
    );

  const clearProcessingPurposeFilters = useCallback(
    (key) => {
      if (key === 'purposes') {
        setFilters({
          ...filters,
          [processingPurposeFilterKey]: [],
          [processingPurposeSubCategoryFilterKey]: [],
        });
        setSelectedPurposeItems([]);
      }
    },
    [
      filters,
      processingPurposeFilterKey,
      processingPurposeSubCategoryFilterKey,
    ],
  );

  return {
    selectedProcessingPurposes: selectedPurposeItems,
    processingPurposeFiltersConfig,
    clearProcessingPurposeFilters,
  };
};
