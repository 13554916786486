import { FilterConfigItem } from '@main/ad-core-components';
import { buildUseLazyQuery } from '@main/core-ui';
import { endpoints } from '@main/datamap-types';
import { ID } from '@main/schema-utils';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { MessageDescriptor } from 'react-intl';

import {
  RecipientCategory,
  SelectRecipients,
} from '../../DataMap/DataSilos/components/InlineSelectRecipients/SelectRecipients';

/**
 * Filters definition with property for SaaS category values specified
 */
type RecipientEnrichedFilters = Record<string, unknown> & {
  /**
   * SaaS category values that are currently filtering the table
   */
  recipients?: RecipientCategory[];
};

/**
 * Hook to get the filter configuration for SaaS categories
 *
 * @returns the SaaS category keys along with the config for the filter and a function to clear the filters
 */
type BuildRecipientFilters<
  T extends Record<string, unknown>,
  E extends RecipientEnrichedFilters,
> = ({
  recipientIdsFilterKey,
  filters,
  setFilters,
}: {
  /** The property name of the filter for SaaS category ids */
  recipientIdsFilterKey?: keyof T;
  /** The currently applied filters */
  filters?: T;
  /** Callback when the filters are changed */
  setFilters: (filters: T) => void;
  /** Label for the filter menu */
  label: string | MessageDescriptor;
}) => {
  /**  The SaaS category keys enabled on this table */
  selectedRecipients: RecipientCategory[];
  /** The filter configuration to be passed to the filter manager */
  recipientFiltersConfig: FilterConfigItem<E>;
  /** Callback for when the filter is cleared in the filter manager */
  clearRecipientFilters: (key: Extract<keyof E, string>) => void;
};

const useLazySaaSCategories = buildUseLazyQuery(
  endpoints.saaSCategories,
  endpoints.saaSCategories.name,
  {
    nodes: {
      id: null,
      title: null,
    },
  },
);

export const useRecipientFilters: BuildRecipientFilters<
  Record<string, unknown>,
  RecipientEnrichedFilters
> = ({
  recipientIdsFilterKey = 'saaSCategoryIds',
  filters,
  setFilters,
  label,
}) => {
  const getSelectedSaaSCategories = useLazySaaSCategories();

  const [selectedRecipients, setSelectedRecipients] = useState<
    RecipientCategory[]
  >([]);

  // Populate initial complex filter values
  useEffect(() => {
    if (filters) {
      const recipientIds = (filters[recipientIdsFilterKey] ||
        []) as ID<'saaSCategory'>[];

      if (recipientIds && recipientIds.length > 0) {
        getSelectedSaaSCategories({
          filterBy: { [recipientIdsFilterKey]: recipientIds },
        }).then(({ data }) => {
          setSelectedRecipients(data.nodes);
        });
      }
    }
  }, []);

  const recipientFiltersConfig = useMemo(
    () =>
      ({
        filterKey: 'recipients',
        label,
        pillOptions: {
          label: ({ filterValues: { recipients = [] }, index = 0 }) =>
            recipients[index]?.title,
        },
        filter: (
          <SelectRecipients
            value={selectedRecipients}
            menuPosition="absolute"
            onChange={(recipients) => {
              setSelectedRecipients([...recipients]);
              setFilters({
                ...filters,
                saaSCategoryIds: recipients.map(({ id }) => id),
              });
            }}
          />
        ),
      }) as FilterConfigItem<RecipientEnrichedFilters>,
    [recipientIdsFilterKey, selectedRecipients, filters, label],
  );

  const clearRecipientFilters = useCallback(
    (key) => {
      if (key === 'recipients') {
        setFilters({
          ...filters,
          [recipientIdsFilterKey]: [],
        });
        setSelectedRecipients([]);
      }
    },
    [selectedRecipients, filters, recipientIdsFilterKey],
  );

  return {
    selectedRecipients,
    recipientFiltersConfig,
    clearRecipientFilters,
  };
};
