import { useMemo } from 'react';

import { AnyFunction } from '@transcend-io/type-utils';

import { debounce, DebounceSettings } from '@main/utils';

/**
 * Debounces a callback.
 * If dependencies change too often, the debounce function will be recreated and will not debounce as expected.
 * Consider memoizing options (if used) and wrapping callback in useCallback, then including the callback in dependencies here.
 *
 * @param callback - The function to execute after the debounce
 * @param time - Time in seconds to delay the debounce
 * @param options - Additional options; should memoize/use constant if including in dependencies
 * @param dependencies - Callback dependencies; can be an array containing the callback itself if it has been wrapped in useCallback
 * @returns debounced callback
 */
export const useDebounce = <TFunc extends AnyFunction>(
  callback: TFunc,
  time = 400,
  options: DebounceSettings = { trailing: true },
  dependencies: any[] = [],
): TFunc | (() => void) =>
  useMemo(
    () => debounce(callback, time, options),
    // Callback is not listed as a dependency as it is not always necessary.
    // If it is necessary, include it OR its dependencies in the dependencies array.
    [time, ...dependencies],
  );
