const removeProtocol = ({
  href,
  protocol,
}: Pick<URL, 'href' | 'protocol'>): string =>
  href.slice(protocol.length).replace(/^\/*/, '');

const matchWithOptionalEndingSlash = (input: string, parsed: string): boolean =>
  input === parsed || (!input.endsWith('/') && parsed === `${input}/`);

/**
 * Check whether a given URL is absolute and valid
 *
 * @param input - URL to check
 * @returns Whether the URL is absolute and valid
 */
export const isValidAbsoluteURL = (input: string): boolean => {
  try {
    const url = new URL(input);
    const { href, protocol, origin } = url;
    // origin matches
    if (input.startsWith(origin)) {
      return matchWithOptionalEndingSlash(input, href);
    }
    // origin doesn't match (extra slashes were potentially removed)
    if (input.startsWith(protocol)) {
      return matchWithOptionalEndingSlash(
        removeProtocol({ href: input, protocol }),
        removeProtocol(url),
      );
    }
    return false;
  } catch (ex) {
    return false;
  }
};

/**
 * Decode URL-encoded asterisks
 *
 * @param url - URL-encoded string
 * @returns Asterisk-decoded URL-encoded string
 */
const decodeAsterisks = (url: string): string => url.replace(/%2A/gi, '*');

/**
 * Check whether a given URL is absolute and valid as a CSP entry
 *
 * @param input - URL to check
 * @returns Whether the URL is absolute and valid
 */
export const isValidAbsoluteCSPEntry = (input: string): boolean => {
  try {
    const url = new URL(input);
    const { protocol, host, pathname, search, hash } = url;
    // decode asterisks that got URL-encoded for CSP wildcard syntax compatibility
    const origin = `${protocol}//${decodeAsterisks(host)}`;
    const href = `${origin}${pathname}${search}${hash}`;
    // origin matches
    if (input.startsWith(origin)) {
      return matchWithOptionalEndingSlash(input, href);
    }
    // origin doesn't match (extra slashes were potentially removed)
    if (input.startsWith(protocol)) {
      return matchWithOptionalEndingSlash(
        removeProtocol({ href: input, protocol }),
        removeProtocol(url),
      );
    }
    return false;
  } catch (ex) {
    return false;
  }
};
