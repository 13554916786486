import styled from 'styled-components';

import { sp } from '@main/theme';

import { Modal } from '../Modal';

export const StyledModal = styled(Modal)`
  .modal-dialog {
    max-width: min(${sp(122)}, 95vw);
  }

  .modal-body {
    padding: ${sp(2)};
  }
`;
