import {
  extractFormItemWrapperProps,
  FormItemWrapper,
  IFormInputValidatedProps,
  IFormItemWrapperProps,
  multipleValidators,
} from '@main/core-ui';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { selectUserMessages } from './messages';
import { SelectUser } from './SelectUser';

export const FormSelectUsers: React.FC<
  IFormInputValidatedProps &
    Omit<IFormItemWrapperProps, 'errors'> & {
      /** Override is=false to select 1 user */
      isMulti?: boolean;
    }
> = (props) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();
  const { passthroughProps, wrapperProps } = extractFormItemWrapperProps(props);

  return (
    <FormItemWrapper
      errors={errors}
      {...wrapperProps}
      label={wrapperProps.label || selectUserMessages.label}
    >
      <Controller
        name={props.name}
        control={control}
        rules={
          props.rules
            ? { validate: multipleValidators(props.rules) }
            : wrapperProps.required
              ? { required: true }
              : undefined
        }
        render={({ field: { ref: {} = {}, ...controllerProps } }) => (
          <SelectUser
            {...passthroughProps}
            isMulti={passthroughProps.isMulti ?? true}
            {...controllerProps}
          />
        )}
      />
    </FormItemWrapper>
  );
};
