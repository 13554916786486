import { defineMessages } from '@transcend-io/internationalization';

export const deleteButtonMessages = defineMessages(
  'ad-core-components.DeleteButton.deleteButton',
  {
    cancel: {
      defaultMessage: 'Cancel',
    },
    header: {
      defaultMessage: 'Are you sure you want to delete this?',
    },
    ok: {
      defaultMessage: 'OK',
    },
  },
);
