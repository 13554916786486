import React from 'react';
import { useIntl } from 'react-intl';

import { notAuthorizedMessages } from './messages';
import { StyledH3 } from './wrappers';

/**
 * Render that someone does not have permissions to view a page
 */
export const NotAuthorized: React.FC = () => {
  const { formatMessage } = useIntl();
  return (
    <StyledH3>{formatMessage(notAuthorizedMessages.notAuthorized)}</StyledH3>
  );
};
