import { mkType, SchemaToType } from '@main/schema-utils';

export const ProfilePreview = mkType({
  name: 'ProfilePreview',
  comment: 'Preview of a profile',
  fields: {
    id: {
      comment: 'The unique database id of the profile, assigned by transcend',
      modelName: 'profile',
      type: 'id',
    },
    type: {
      comment: 'The name of the identifier that the profile resolved from',
      optional: true,
      type: 'string',
    },
  },
});

/** Override type */
export type ProfilePreview = SchemaToType<typeof ProfilePreview>;
