import { QueueStatus } from '@transcend-io/privacy-types';
import { makeEnum } from '@transcend-io/type-utils';

/**
 * Status of request file
 */
export const RequestFileStatus = makeEnum({
  /**
   * The file was successfully found in the remote system, and temporarily copied to an encrypted
   * file to allow for the download to occur.
   */
  EncryptedCopy: 'ENCRYPTED_COPY',
  /** The datapoint expected data to be uploaded to it or marked as completed manually */
  Manual: 'MANUAL',
  // Implements a queue
  ...QueueStatus,
  /**
   * The requestFile has been fired and the action was initialized successfully, however not complete.
   * This if often useful when we are adding to some sort of queue, and we then have to poll that queue
   * to verify successful completion.
   */
  Polling: 'POLLING',
});

/**
 * Overload type
 */
export type RequestFileStatus =
  (typeof RequestFileStatus)[keyof typeof RequestFileStatus];

/**
 * The statuses that indicate a successful completion
 */
export const RequestFileStatusSuccess = makeEnum({
  /** Successfully resolved */
  Resolved: 'RESOLVED',
  /**
   * The file was successfully found in the remote system, and temporarily copied to an encrypted
   * file to allow for the download to occur.
   */
  EncryptedCopy: 'ENCRYPTED_COPY',
  /** Skipped processing because already completed */
  Skipped: 'SKIPPED',
});

/**
 * Overload type
 */
export type RequestFileStatusSuccess =
  (typeof RequestFileStatusSuccess)[keyof typeof RequestFileStatusSuccess];

/**
 * When the request file gets queued up to be processed in the secondary status,
 * this queue type indicates what type of secondary action should be taken
 */
export enum RequestFileQueueType {
  /**
   * The primary secondary action. In the case of ERASURE, this destroys the file in the remote system
   */
  Secondary = 'SECONDARY',
  /**
   * Rather than destroying the entire file, this will pseudonymize the data subject by scrubbing all PII from the file.
   */
  Redact = 'REDACT',
  /**
   * Do not process the request file
   */
  Disclude = 'DISCLUDE',
}

/**
 * Fields that a requestFile can be ordered by
 */
export enum RequestFileOrderField {
  /** The time the request file was created */
  CreatedAt = 'createdAt',
  /** The time the request file was updated */
  UpdatedAt = 'updatedAt',
  /** The size of the file in bytes */
  Size = 'size',
  /** The status of the file */
  Status = 'status',
  /** The ID of the file */
  Id = 'id',
}

/**
 * Fields that a downloadFile can be ordered by
 */
export enum DownloadFileOrderField {
  /** The time the file was created */
  CreatedAt = 'createdAt',
  /** The time the file was updated */
  UpdatedAt = 'updatedAt',
  /** The size of the file in bytes */
  Size = 'size',
}
