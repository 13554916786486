/**
 * Ways that a data collection can be acquired.
 */
export enum DataCategoryMethod {
  /** Only applies for the dataCollection holding UNASSIGNED nodes */
  Unassigned = 'UNASSIGNED',
  /** The information is given by the user */
  Given = 'GIVEN',
  /** The information is tracked */
  Tracked = 'TRACKED',
  /** The information is acquired */
  Acquired = 'ACQUIRED',
}

/**
 * Data category static defaults
 */
export enum DataCategoryStaticType {
  /** Audit logs */
  AuditLogs = 'AUDIT_LOGS',
  /** Basic information */
  Basic = 'BASIC',
  /** Communication */
  Communication = 'COMMUNICATION',
  /** Cookies */
  Cookies = 'COOKIES',
  /** The types */
  Financial = 'FINANCIAL',
  /** Social */
  Social = 'SOCIAL',
  /** Support */
  Support = 'SUPPORT',
  /** Special type uncategorized */
  Uncategorized = 'UNCATEGORIZED',
}
