/* eslint-disable max-lines */
import { mkInput, mkType, SchemaToType } from '@main/schema-utils';

export const TCFPurpose = mkType({
  name: 'TCFPurpose',
  comment: 'Transparency & Consent Framework (TCF) Purpose',
  fields: {
    id: {
      comment: 'The ID of the purpose as used by TCF',
      type: 'int',
    },
    name: {
      comment: 'The name of the purpose, e.g. "Select basic ads"',
      type: 'string',
    },
    description: {
      comment: 'The description of the purpose',
      type: 'string',
    },
    dataRetentionPeriod: {
      comment: 'The duration for which data is stored (days)',
      type: 'int',
      optional: true,
    },
    illustrations: {
      comment: 'Real-world examples of the purpose',
      type: 'string',
      list: true,
    },
  },
});

/** Override type */
export type TCFPurpose = SchemaToType<typeof TCFPurpose>;

export const TCFDataCategory = mkType({
  name: 'TCFDataCategory',
  comment: 'Transparency & Consent Framework (TCF) data category',
  fields: {
    id: {
      comment: 'The ID of the data category as used by TCF',
      type: 'int',
    },
    name: {
      comment:
        'The name of the data category, e.g. "Browsing and interaction data"',
      type: 'string',
    },
    description: {
      comment: 'The description of the data category',
      type: 'string',
    },
  },
});

/** Override type */
export type TCFDataCategory = SchemaToType<typeof TCFDataCategory>;

export const TCFUrl = mkType({
  name: 'TCFUrl',
  comment:
    'Transparency & Consent Framework (TCF) Vendor URLs for privacy policies in different languages',
  fields: {
    langId: {
      comment: 'The language for these set of URLs',
      type: 'string',
    },
    privacy: {
      comment:
        'Link to the privacy policy for the vendor in the specified language',
      type: 'string',
    },
    legIntClaim: {
      comment:
        'Link to the legitimate interest claim for the vendor in the specified language',
      type: 'string',
    },
  },
});

/** Override type */
export type TCFUrl = SchemaToType<typeof TCFUrl>;

export const TCFVendorOverflow = mkType({
  name: 'TCFVendorOverflow',
  comment:
    'Transparency & Consent Framework (TCF) Vendors http GET request length limit',
  fields: {
    httpGetLimit: {
      comment: 'Vendors http GET request length limit',
      type: 'int',
    },
  },
});

/** Override type */
export type TCFVendorOverflow = SchemaToType<typeof TCFVendorOverflow>;

export const TCFVendorDataRetentionPurpose = mkType({
  name: 'TCFVendorDataRetentionPurpose',
  comment:
    'Transparency & Consent Framework (TCF) Vendors: How long they retain users data for each declared purpose',
  fields: {
    purpose: {
      comment:
        'Vendor purpose for which the retention period applies. If not present, the stdRetention period applies to all',
      type: 'int',
      optional: true,
    },
    dataRetentionPeriod: {
      comment:
        'Retention period for data collected (days) for each declared purpose',
      type: 'int',
      optional: true,
    },
  },
});

/** Override type */
export type TCFVendorDataRetentionPurpose = SchemaToType<
  typeof TCFVendorDataRetentionPurpose
>;

export const TCFVendorDataRetention = mkType({
  name: 'TCFVendorDataRetention',
  comment:
    'Transparency & Consent Framework (TCF) Vendors: How long they retain users data for each declared purpose',
  fields: {
    stdRetention: {
      comment:
        'Retention period for data collected (days). Calculated when same period across all purposes and special purposes',
      type: 'int',
      optional: true,
    },
    purposes: {
      comment: 'How long they retain users data for each declared purpose',
      type: TCFVendorDataRetentionPurpose,
      list: true,
      optional: true,
    },
    specialPurposes: {
      comment:
        'How long they retain users data for each declared special purpose',
      type: TCFVendorDataRetentionPurpose,
      list: true,
      optional: true,
    },
  },
});

/** Override type */
export type TCFVendorDataRetention = SchemaToType<
  typeof TCFVendorDataRetention
>;

export const TCFVendor = mkType({
  name: 'TCFVendor',
  comment:
    'Transparency & Consent Framework (TCF) Vendor--matches output for IAB vendors list',
  fields: {
    id: {
      comment:
        'The ID of the vendor as used by TCF (in postgres, its the tcfId field)',
      type: 'string',
    },
    pgId: {
      type: 'id',
      modelName: 'tcfVendor',
      comment: 'The Postgres ID of the vendor',
    },
    name: {
      comment: 'The name of the vendor, e.g. "Salesforce.com, Inc."',
      type: 'string',
    },
    purposes: {
      comment: 'Purposes declared as performed on the legal basis of consent',
      type: TCFPurpose,
      list: true,
      optional: true,
    },
    legIntPurposes: {
      comment:
        'Purposes declared as performed on the legal basis of legitimate interest',
      type: TCFPurpose,
      list: true,
      optional: true,
    },
    flexiblePurposes: {
      comment:
        'Purposes where the vendor is flexible regarding the legal basis',
      type: TCFPurpose,
      list: true,
      optional: true,
    },
    specialPurposes: {
      comment:
        'Special Purposes declared as performed on the legal basis of a legitimate interest',
      type: TCFPurpose,
      list: true,
      optional: true,
    },
    features: {
      comment:
        'Features the Vendor may utilize when performing some declared Purposes processing',
      type: TCFPurpose,
      list: true,
      optional: true,
    },
    specialFeatures: {
      comment:
        'Special Features the Vendor may utilize when performing some declared Purposes processing',
      type: TCFPurpose,
      list: true,
      optional: true,
    },
    cookieMaxAgeSeconds: {
      comment: 'The longest potential duration for storage on a device',
      type: 'int',
      optional: true,
    },
    usesCookies: {
      comment: 'Whether the vendor uses cookie storage',
      type: 'boolean',
    },
    cookieRefresh: {
      comment:
        'True indicates the vendor may refresh cookie(s). ' +
        'False indicates the vendor does not refresh any time the browser reloads',
      type: 'boolean',
      optional: true,
    },
    usesNonCookieAccess: {
      comment:
        "Indicates the vendor's use of non-cookie storage and access to information already stored on a user's device.",
      type: 'boolean',
      optional: true,
    },
    deviceStorageDisclosureUrl: {
      comment: 'URL to Vendor Device Storage & Operational Disclosures',
      type: 'string',
      optional: true,
    },
    dataDeclaration: {
      comment:
        'Categories of data the Vendor may utilize/collect from user (e.g. precise location data)',
      type: TCFDataCategory,
      list: true,
      optional: true,
    },
    dataRetention: {
      comment: 'How long they retain users data',
      type: TCFVendorDataRetention,
      optional: true,
    },
    deletedDate: {
      comment:
        'If present, vendor is considered deleted after this date/time and MUST NOT be established to users',
      type: 'Date',
      optional: true,
    },
    urls: {
      comment:
        'Vendor URLs for privacy and legitimate interests policies in different languages',
      type: TCFUrl,
      list: true,
      optional: true,
    },
    overflow: {
      comment: 'Vendors http GET request length limit',
      type: TCFVendorOverflow,
      optional: true,
    },
  },
});

/** Override type */
export type TCFVendor = SchemaToType<typeof TCFVendor>;

export const TCFVendorFilterInput = mkInput({
  name: 'TCFVendorFilterInput',
  comment: 'Inputs to filter the TCF Vendor endpoint by',
  fields: {
    text: {
      comment:
        'Filters by whether the substring is present in the GVL vendor name',
      type: 'string',
      optional: true,
    },
  },
});

/** Override type */
export type TCFVendorFilterInput = SchemaToType<typeof TCFVendorFilterInput>;

export const TCFVendorDetailsInput = mkInput({
  name: 'TCFVendorDetailsInput',
  comment: 'Input to retrieve a vendor from the TCF Vendor Details endpoint',
  fields: {
    tcfVendorId: {
      comment:
        'ID of the vendor as used by TCF (in postgres, its the tcfId field)',
      type: 'string',
    },
  },
});

/** Override type */
export type TCFVendorDetailsInput = SchemaToType<typeof TCFVendorDetailsInput>;

export const ShowTCFVendorInput = mkInput({
  name: 'ShowTCFVendorInput',
  comment: 'Inputs to the show/hide vendor in the TCF UI endpoint',
  fields: {
    show: {
      comment:
        "Sets the vendor's (newly created if nonexistent) consent services showInTcfModal field",
      type: 'boolean',
    },
    airgapBundleId: {
      comment: 'The id of the airgap bundle',
      type: 'id',
      modelName: 'airgapBundle',
    },
    tcfVendors: {
      comment: 'The ids of the TCF vendors to show/hide in the modal',
      type: 'id',
      modelName: 'tcfVendor',
      list: true,
      optional: true,
    },
    consentServices: {
      comment: 'The ids of the consent services to show/hide in the modal',
      type: 'id',
      modelName: 'consentService',
      optional: true,
      list: true,
    },
  },
});

/** Override type */
export type ShowTCFVendorInput = SchemaToType<typeof ShowTCFVendorInput>;

export const BundleTCFVendorInput = mkInput({
  name: 'BundleTCFVendorInput',
  comment: 'Inputs to the show/hide vendor in the TCF UI endpoint',
  fields: {
    airgapBundleId: {
      comment: 'The id of the airgap bundle',
      type: 'id',
      modelName: 'airgapBundle',
    },
    isIab: {
      comment: 'Whether or not the vendor is registered with the IAB',
      type: 'boolean',
      optional: true,
    },
    vendorName: {
      comment: 'Substring to compare against the TCF vendor name',
      type: 'string',
      optional: true,
    },
    serviceName: {
      comment: 'Substring to compare against the consent service unique name',
      type: 'string',
      optional: true,
    },
    iabId: {
      comment: 'TCF vendor id to match against',
      type: 'int',
      optional: true,
    },
  },
});

/** Override type */
export type BundleTCFVendorInput = SchemaToType<typeof BundleTCFVendorInput>;

/* eslint-enable max-lines */
