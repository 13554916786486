import { Button } from '@main/ad-core-components';
import { FlexRowCenterBoth, StyleUtils } from '@main/core-ui';
import { AdminDashboardAssessmentsPath } from '@main/datamap-types';
import { ID } from '@main/schema-utils';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { generatePath } from 'react-router-dom';

import { requestAssessmentFromInventoryMessages } from './messages';
import { RequestAssessmentFromInventoryForm } from './RequestAssessmentFromInventoryForm';
import { RequestAssessmentFromInventoryFormProps } from './types';
import { StyledModal } from './wrappers';

/** Props for RequestAssessmentFromInventory component */
export type RequestAssessmentFromInventoryProps<D extends object> = Omit<
  RequestAssessmentFromInventoryFormProps<D>,
  'setAssessmentGroupId'
>;

/**
 * Component to request an assessment based on fields from an inventory table
 */
export function RequestAssessmentFromInventory<D extends object>({
  selectedRows,
  ...props
}: RequestAssessmentFromInventoryProps<D>): JSX.Element {
  const { formatMessage } = useIntl();

  const [visible, setVisible] = useState(false);
  const [assessmentGroupId, setAssessmentGroupId] =
    useState<ID<'assessmentGroup'> | null>(null);

  useEffect(() => {
    if (!visible) {
      // reset the group id when modal is closed
      setAssessmentGroupId(null);
    }
  }, [visible]);

  if (selectedRows.length === 0) {
    return <></>;
  }

  return (
    <>
      <Button variant="secondary" onClick={() => setVisible(true)}>
        {formatMessage(requestAssessmentFromInventoryMessages.buttonLabel)}
      </Button>
      <StyledModal
        show={visible}
        onHide={() => setVisible(false)}
        centerHeader={!!assessmentGroupId}
        header={
          assessmentGroupId
            ? formatMessage(requestAssessmentFromInventoryMessages.success, {
                count: selectedRows.length,
              })
            : requestAssessmentFromInventoryMessages.buttonLabel
        }
      >
        {assessmentGroupId ? (
          <FlexRowCenterBoth style={{ gap: StyleUtils.Spacing.md }}>
            <Button variant="secondary" onClick={() => setVisible(false)}>
              {formatMessage(
                requestAssessmentFromInventoryMessages.returnToDataInventory,
              )}
            </Button>
            <Button
              href={generatePath(
                AdminDashboardAssessmentsPath.AssessmentGroup,
                { assessmentGroupId },
              )}
            >
              {formatMessage(
                requestAssessmentFromInventoryMessages.viewAssessments,
              )}
            </Button>
          </FlexRowCenterBoth>
        ) : (
          <RequestAssessmentFromInventoryForm
            selectedRows={selectedRows}
            setAssessmentGroupId={setAssessmentGroupId}
            {...props}
          />
        )}
      </StyledModal>
    </>
  );
}
