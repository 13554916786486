import {
  DefaultOptionType,
  FlexRow,
  FlexRowCenterVertical,
  ReactSelect,
} from '@main/core-ui';
import { indexBy } from '@main/utils';
import isEqual from 'lodash/isEqual';
import React, { useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';

import { DatePicker } from '../DatePicker';
import { InputNumber } from '../InputNumber';
import {
  parseFrequencyForUnitAndMultiple,
  UNIT_TO_INTERVAL,
} from './constants';
import { scheduleInputMessages, scheduleInputUnitMessages } from './messages';
import { ScheduleInputProps, ScheduleInputUnit } from './types';

/**
 * ScheduleInput
 */
export const ScheduleInput: React.FC<ScheduleInputProps> = ({
  value,
  onChange,
  defaultUnit,
  allowedUnits = [
    ScheduleInputUnit.Weekly,
    ScheduleInputUnit.Daily,
    ScheduleInputUnit.Hourly,
  ],
  hideStartDate,
}) => {
  const { formatMessage } = useIntl();
  const initialValue = useMemo(
    () =>
      value
        ? parseFrequencyForUnitAndMultiple(value.frequencyMs, allowedUnits)
        : undefined,
    [],
  );
  const [unitValue, setUnitValue] = useState(
    initialValue?.unit ?? defaultUnit ?? ScheduleInputUnit.Daily,
  );
  const [multipleValue, setMultipleValue] = useState(
    initialValue?.multiple ?? 1,
  );
  const [timeValue, setTimeValue] = useState<Date | undefined>(
    value?.startDate ?? new Date(),
  );
  const [updatedExternally, setUpdatedExternally] = useState(false);

  useEffect(() => {
    const changedValue =
      !multipleValue || !unitValue || !timeValue
        ? undefined
        : {
            frequencyMs: UNIT_TO_INTERVAL[unitValue] * multipleValue,
            startDate: timeValue,
          };
    if (
      changedValue !== value &&
      !isEqual(changedValue, value) &&
      !updatedExternally
    ) {
      onChange(changedValue);
    } else if (updatedExternally) {
      setUpdatedExternally(false);
    }
  }, [unitValue, multipleValue, timeValue]);

  useEffect(() => {
    if (value) {
      const { unit, multiple } = parseFrequencyForUnitAndMultiple(
        value.frequencyMs,
        allowedUnits,
      );

      if (unit !== unitValue) {
        setUnitValue(unit);
        setUpdatedExternally(true);
      }
      if (multiple !== multipleValue) {
        setMultipleValue(multiple);
        setUpdatedExternally(true);
      }
      if (value.startDate !== timeValue) {
        setTimeValue(value.startDate);
        setUpdatedExternally(true);
      }
    }
  }, [value]);

  const unitOptions = useMemo(
    () =>
      allowedUnits.map((unit) => ({
        label: formatMessage(scheduleInputUnitMessages[unit], {
          num: multipleValue,
        }),
        value: unit,
      })),
    [allowedUnits, multipleValue],
  );
  const unitOptionsMap = useMemo(
    () => indexBy(unitOptions, 'value'),
    [unitOptions],
  );
  return (
    <FlexRow style={{ gap: '.5em', flexWrap: 'wrap' }}>
      <FlexRowCenterVertical>
        {formatMessage(scheduleInputMessages.every)}
      </FlexRowCenterVertical>
      <InputNumber
        style={{ width: '8em' }}
        initialValue={multipleValue}
        onChange={(newVal) => {
          setMultipleValue(Math.max(newVal, 1));
        }}
      />
      <FlexRow style={{ minWidth: '10em', flexGrow: 1 }}>
        <ReactSelect<false, DefaultOptionType<ScheduleInputUnit>>
          value={unitOptionsMap[unitValue]}
          onChange={(option) => {
            setUnitValue(option?.value ?? ScheduleInputUnit.Daily);
          }}
          options={unitOptions}
          variant="light"
        />
      </FlexRow>
      {!hideStartDate && (
        <>
          <FlexRowCenterVertical style={{ whiteSpace: 'nowrap' }}>
            {formatMessage(scheduleInputMessages.startingDateMessage)}
          </FlexRowCenterVertical>
          <DatePicker showTime value={timeValue} onChange={setTimeValue} />
        </>
      )}
    </FlexRow>
  );
};
