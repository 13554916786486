import 'react-day-picker/style.css';

import { Popover, StyleUtils } from '@main/core-ui';
import moment from 'moment';
import React, { useState } from 'react';
import type {
  PropsBase,
  PropsSingle,
  PropsSingleRequired,
} from 'react-day-picker';

import { Input, InputProps } from '../Input';
import {
  DATE_FORMAT,
  EDIT_DATE_TIME_FORMAT,
  EDIT_TIME_FORMAT,
  READ_DATE_TIME_FORMAT,
} from './constants';
import { DayPickerCore } from './DayPickerCore';

export interface DatePickerProps
  extends Omit<InputProps, 'value' | 'onChange'> {
  /** The currently-selected date value */
  value: Date | undefined;
  /** Handler for when the date value is changed */
  onChange: (date: Date | undefined) => void;
  /**
   * React DayPicker props
   *
   * @see https://daypicker.dev/docs/customization
   */
  dayPickerProps?: Omit<
    PropsBase & (PropsSingle | PropsSingleRequired),
    'mode' | 'selected' | 'onSelected'
  >;
  /**
   * Include time picker
   * Defaults to false
   */
  showTime?: boolean;
  /**
   * Force a specific locale for the calendar. Otherwise uses react-intl `useIntl().locale`
   */
  forceLocale?: string;
}

const DEFAULT = new Date();

export const DatePicker: React.FC<DatePickerProps> = ({
  value,
  onChange,
  dayPickerProps,
  showTime = false,
  forceLocale,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  ref,
  ...inputProps
}) => {
  // The popover container
  const [containerRef, setContainerRef] = useState<HTMLDivElement | null>(null);

  const isControlled = onChange !== undefined;
  const editInputFormat = showTime ? EDIT_DATE_TIME_FORMAT : DATE_FORMAT;
  const readInputFormat = showTime ? READ_DATE_TIME_FORMAT : DATE_FORMAT;

  // If this is an uncontrolled component, manage state internally
  const [internalValue, setInternalValue] = useState<Date | undefined>(
    new Date(),
  );

  // React DayPicker's date state, which is either controlled or uncontrolled
  const currentValue = isControlled ? value : internalValue;

  // The input field's string value
  const [inputValue, setInputValue] = useState<string>(() =>
    currentValue ? moment(currentValue).format(readInputFormat) : '',
  );

  /**
   * The DayPicker's currently-selected month.
   * By controlling `month` ourselves, the DayPicker opens to the supplied month, rather than the current month.
   */
  const [month, setMonth] = useState<Date>(() => currentValue || DEFAULT);

  // The currently-selected time
  const [time, setTime] = useState<string | undefined>(() =>
    moment(currentValue || DEFAULT).format(EDIT_TIME_FORMAT),
  );

  // Function to update the DayPicker's state. State is maintained either internally (uncontrolled) or by the user (controlled).
  const setDate = (date: Date | undefined): void => {
    if (isControlled) {
      // User callback
      onChange(date);
    } else {
      // Update the React DayPicker component
      setInternalValue(date);
    }
  };

  // When the DayPicker changes, update the text input field
  const handleDayPickerChange = (
    date: Date | undefined,
    bubbles: boolean = true,
  ): void => {
    if (!date) {
      setInputValue('');
      setDate(undefined);
    } else {
      const newDate = showTime
        ? moment(
            `${moment(date).format(DATE_FORMAT)} ${time}`,
            EDIT_DATE_TIME_FORMAT,
          )
        : moment(date);

      setDate(newDate.toDate());
      if (bubbles) {
        setInputValue(newDate.format(editInputFormat));
      }
      setMonth(newDate.toDate());
    }
  };

  const handleTimeInputChange = (
    e: React.ChangeEvent<HTMLInputElement>,
  ): void => {
    setTime(e.target.value); // keep the input value in sync

    if (currentValue) {
      const newTime = e.target.value;
      const newDate = moment(
        `${moment(currentValue).format(DATE_FORMAT)} ${newTime}`,
        EDIT_DATE_TIME_FORMAT,
      );

      if (newDate.isValid()) {
        setDate(newDate.toDate());
        setInputValue(newDate.format(EDIT_DATE_TIME_FORMAT));
      }
    }
  };

  // When the text input field changes, update the DayPicker
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setInputValue(e.target.value); // keep the input value in sync

    const parsedDate = moment(e.target.value);

    if (parsedDate.isValid()) {
      setDate(parsedDate.toDate());
      setMonth(parsedDate.toDate());
    } else {
      setDate(undefined);
      setMonth(DEFAULT);
    }
  };

  // Once the text input field is blurred (deselected), reformat the input value to match the standard format
  const handleInputOnBlur = (e: React.FocusEvent<HTMLInputElement>): void => {
    const parsedDate = moment(e.target.value);
    if (parsedDate.isValid()) {
      handleDayPickerChange(parsedDate.toDate(), false);
    } else {
      handleDayPickerChange(undefined, false);
    }

    setInputValue(parsedDate.format(editInputFormat));

    // If the user has provided an onBlur callback, call it
    inputProps.onBlur?.(e);
  };

  // When the input receives focus, reformat the input value (in the read format) to match the edit format
  const handleInputOnFocus = (e: React.FocusEvent<HTMLInputElement>): void => {
    const parsedDate = moment(e.target.value, readInputFormat);

    if (parsedDate.isValid()) {
      setInputValue(parsedDate.format(editInputFormat));
    }
    // If the user has provided an onBlur callback, call it
    inputProps.onFocus?.(e);
  };

  return (
    <Popover
      contents={
        <div>
          <DayPickerCore
            {...dayPickerProps}
            mode="single"
            month={month}
            onMonthChange={setMonth}
            selected={currentValue}
            required={inputProps.required}
            showOutsideDays
            onSelect={(date) => handleDayPickerChange(date)}
            forceLocale={forceLocale}
          />
          {showTime && (
            <div style={{ marginTop: StyleUtils.Spacing.sm }}>
              <Input
                type="time"
                value={time}
                onChange={handleTimeInputChange}
                // Time zone for user's location (e.g., "EST"), at currently-selected date (e.g., "EDT" if date is in daylight savings)
                addonRight={(currentValue || DEFAULT)
                  .toLocaleDateString(undefined, {
                    day: '2-digit',
                    timeZoneName: 'short',
                  })
                  .substring(4)}
              />
            </div>
          )}
        </div>
      }
      placement="bottom"
      container={containerRef}
    >
      <div className="DatePickerInput">
        <Input
          {...inputProps}
          icon="calendar"
          loading={!containerRef}
          value={inputValue}
          onChange={handleInputChange}
          onBlur={handleInputOnBlur}
          onFocus={handleInputOnFocus}
        />
        <div ref={(node) => setContainerRef(node)} />
      </div>
    </Popover>
  );
};
