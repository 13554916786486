import { makeEnum } from '@transcend-io/type-utils';

/** Color Scheme for the browser */
export const ColorScheme = makeEnum({
  /** Light Mode for the browser */
  Light: 'light',
  /** Dark Mode for the browser */
  Dark: 'dark',
});

/** Override type */
export type ColorScheme = (typeof ColorScheme)[keyof typeof ColorScheme];
