import { PaginatedSelect } from '@main/ad-core-components';
import { buildUseInfiniteScroll } from '@main/core-ui';
import { endpoints } from '@main/datamap-types';
import { ID } from '@main/schema-utils';
import React, { useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { MenuPosition } from 'react-select';

import { SELECT_DATA_POINT_LEVEL_NODE } from './constants';
import { selectDataPointLevelMessages } from './messages';
import { SelectedDataPointLevel } from './types';

const PAGE_SIZE = 15;

const useDataPointLevel = buildUseInfiniteScroll(
  endpoints.dataPointHierarchy,
  'SelectDataPointLevel',
  {
    nodes: SELECT_DATA_POINT_LEVEL_NODE,
  },
  PAGE_SIZE,
);

export interface SelectDataPointLevelProps {
  /** The selected datapoint level item */
  value?: SelectedDataPointLevel[];
  /** Whether the input is being updated/loading */
  loading?: boolean;
  /** Callback to fire when the category is updated */
  onChange: (selected?: SelectedDataPointLevel[]) => void;
  /** How to position the menu (see docs for React Select) */
  menuPosition?: MenuPosition;
  /** Whether or not to show the outline of this select component */
  showOutline?: boolean;
  /** Data Silo ID for query */
  dataSiloId: ID<'dataSilo'>;
}

export const SelectDataPointLevelFilter: React.FC<
  SelectDataPointLevelProps
> = ({
  dataSiloId,
  value,
  loading = false,
  onChange,
  menuPosition = 'absolute',
  showOutline = false,
}) => {
  const { formatMessage } = useIntl();
  const [search, setSearch] = useState('');

  const {
    data,
    loading: dataPointLevelLoading,
    fetchMore,
    error,
  } = useDataPointLevel({
    variables: {
      first: 100,
      filterBy: {
        ...(search ? { text: search } : {}),
        dataSiloId,
        attributeValueIds: [],
      },
    },
    fetchPolicy: 'cache-first',
  });

  const options = useMemo(() => {
    const newOptions = (data?.nodes as SelectedDataPointLevel[]) ?? [];
    return newOptions;
  }, [data?.nodes]);

  return (
    <PaginatedSelect
      isMulti
      showOutline={showOutline}
      menuPosition={menuPosition}
      options={options}
      getOptionLabel={({ path, name }) =>
        path ? [...path, name].join('.') : name
      }
      fetchMore={fetchMore}
      isQueryLoading={loading || dataPointLevelLoading}
      queryError={error}
      onChange={(values) => onChange(values as SelectedDataPointLevel[])}
      getOptionValue={({ name }) => name}
      placeholder={formatMessage(selectDataPointLevelMessages.placeholder)}
      value={value}
      onEndsTyping={(searchText) => setSearch(searchText)}
    />
  );
};
