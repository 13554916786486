import { useMemo } from 'react';
import { Column } from 'react-table';

/**
 * Shape of the helpers to filter the hidden columns out of the columns array
 */
type GetVisibleColumns = <T extends object>(
  hiddenColumns: string[],
  orderedColumns: string[],
  columns: Column<T>[],
) => Column<T>[];

export const getVisibleColumns: GetVisibleColumns = (
  hiddenColumns,
  orderedColumns,
  columns,
) =>
  columns
    .filter(({ id, accessor }) => {
      const key = id ?? accessor;
      return typeof key === 'string' ? !hiddenColumns.includes(key) : false;
    })
    .sort((a, b) => {
      const aKey = a.id ?? a.accessor;
      const bKey = b.id ?? b.accessor;
      if (typeof aKey !== 'string' || typeof bKey !== 'string') {
        return 0;
      }
      return orderedColumns.indexOf(aKey) - orderedColumns.indexOf(bKey);
    });

export const useVisibleColumns: GetVisibleColumns = (
  hiddenColumns,
  orderedColumns,
  columns,
) =>
  useMemo(
    () => getVisibleColumns(hiddenColumns, orderedColumns, columns),
    [hiddenColumns, orderedColumns, columns],
  );
