/* eslint-disable max-lines */
import * as t from 'io-ts';

import { TokenInput } from '@main/access-control-types';
import {
  ConsentPreferencesInput,
  PrivacyCenterTrackingPurposePreview,
  TrackingTechnology,
  UpdateConsentPreferenceInput,
} from '@main/cm-types';
import { DataSubject } from '@main/datamap-types';
import {
  DownloadFile,
  DownloadFileCounts,
  DownloadFileFiltersInput,
  DownloadFileOrder,
  RequestHistory,
  RequestHistoryFiltersInput,
  RequestHistoryOrder,
  RequestInput,
} from '@main/dsr-types';
import { Message, TranslatedMessagesInput } from '@main/internationalization';
import {
  mkMutation,
  mkQuery,
  PAGINATION_INPUT_PARAMS,
} from '@main/schema-utils';
import {
  ConsentRecordSchema,
  DecryptionContext,
  SombraCoreIdentifier,
  SombraProfile,
} from '@main/sombra-types';

import {
  AssetFile,
  AssetFileOrder,
  Certificate,
  DataCollectionRequestFileCount,
  DataCollectionsRequestFileCountInput,
  DeletePoliciesInput,
  DeployPrivacyCenterVersionInput,
  EmailLoginInput,
  LoadPrivacyPolicyInput,
  Policy,
  PrivacyCenterDeployment,
  PrivacyCenterIdInput,
  PrivacyCenterLookupInput,
  PrivacyCenterStringified,
  PrivacyCenterTranslation,
  PrivacyCenterVersionModules,
  PrivacyCenterVersionModulesInput,
  RequestsProcessedDisclosureStatsByYear,
  RequestsProcessedDisclosureStatsInput,
  RequestsProcessedDisclosureStatsSettings,
  RequestsProcessedDisclosureStatsSettingsInput,
  TranslatedMessagesFilterInput,
  UpdateMessagesInput,
  UpdatePoliciesInput,
  UpdatePrivacyCenterInput,
} from './schema';

export const downloadFiles = mkQuery({
  name: 'downloadFiles',
  comment: 'Get the files to include in a data subject access request',
  params: {
    ...PAGINATION_INPUT_PARAMS,
    dhEncrypted: {
      type: 'string',
      isOptional: true,
      underlyingType: t.type({}),
    },
    filterBy: DownloadFileFiltersInput,
    orderBy: DownloadFileOrder,
    lookup: PrivacyCenterLookupInput.optional(),
  },
  response: {
    nodes: DownloadFile.list(),
    totalCount: 'int',
    totalSize: 'string',
  },
});

export const downloadFilesCountsOnly = mkQuery({
  name: 'downloadFilesCountsOnly',
  comment: 'Get the file counts to include in a data subject access request',
  params: {
    dhEncrypted: 'string',
    filterBy: DownloadFileFiltersInput,
    lookup: PrivacyCenterLookupInput.optional(),
  },
  response: {
    nodes: DownloadFileCounts.list(),
  },
});

export const requestHistory = mkQuery({
  name: 'requestHistory',
  comment:
    'View the history of data subject requests submitted by a particular data subject.',
  params: {
    ...PAGINATION_INPUT_PARAMS,
    dhEncrypted: {
      type: 'string',
      isOptional: true,
      underlyingType: t.type({}),
    },
    filterBy: RequestHistoryFiltersInput.optional(),
    orderBy: RequestHistoryOrder,
    lookup: PrivacyCenterLookupInput.optional(),
  },
  response: {
    nodes: RequestHistory.list(),
    totalCount: 'int',
  },
});

export const wrongLoginEmail = mkMutation({
  name: 'wrongLoginEmail',
  comment:
    'Invalidate an email, indicating the email address did not initiate login',
  params: {
    lookup: PrivacyCenterLookupInput.optional(),
    input: TokenInput,
  },
  response: {
    success: 'boolean',
  },
});

export const verifyRequestToken = mkMutation({
  name: 'verifyRequestToken',
  comment: 'Verify the ownership of a request identifier JWT token',
  params: {
    lookup: PrivacyCenterLookupInput.optional(),
    input: TokenInput,
  },
  response: {
    success: 'boolean',
  },
});

export const wrongRequestToken = mkMutation({
  name: 'wrongRequestToken',
  comment:
    'Invalidate a request identifier, indicating the identifier was not expected to be processed',
  params: {
    lookup: PrivacyCenterLookupInput.optional(),
    input: TokenInput,
  },
  response: {
    success: 'boolean',
  },
});

export const verifyPrivacyCenterLoginEmail = mkMutation({
  name: 'verifyPrivacyCenterLoginEmail',
  comment: 'Login to the privacy center by providing a valid email token',
  params: {
    lookup: PrivacyCenterLookupInput.optional(),
    input: TokenInput,
  },
  response: {
    dataSubject: DataSubject,
    jwtSession: 'string',
  },
});

export const revokeRequest = mkMutation({
  name: 'revokeRequest',
  comment: 'Login to the privacy center via sombra',
  params: {
    dhEncrypted: 'string',
    id: {
      modelName: 'request',
      type: 'id',
    },
    lookup: PrivacyCenterLookupInput.optional(),
  },
  response: {
    request: RequestHistory,
  },
});

export const sombraDataSubjectLogin = mkMutation({
  name: 'sombraDataSubjectLogin',
  comment: 'Login to the privacy center via sombra',
  params: {
    dhEncrypted: 'string',
    lookup: PrivacyCenterLookupInput.optional(),
  },
  response: {
    profile: SombraProfile,
    coreIdentifier: SombraCoreIdentifier,
    dataSubject: DataSubject,
    decryptionContext: DecryptionContext,
  },
});

export const dataSubjectDownloadCEK = mkMutation({
  name: 'dataSubjectDownloadCEK',
  comment:
    'Get the decryption context needed to download a zip of the files for a request on the privacy center',
  params: {
    id: {
      modelName: 'request',
      type: 'id',
    },
    lookup: PrivacyCenterLookupInput.optional(),
    dhEncrypted: 'string',
  },
  response: {
    decryptionContext: DecryptionContext,
  },
});

export const makeDataSubjectRequest = mkMutation({
  name: 'makeDataSubjectRequest',
  comment: 'Data subject submitted a data subject request',
  params: {
    input: RequestInput,
    lookup: PrivacyCenterLookupInput.optional(),
    dhEncrypted: 'string',
  },
  response: {
    request: RequestHistory,
  },
});

export const dataSubjectUpdateConsentPreferences = mkMutation({
  name: 'dataSubjectUpdateConsentPreferences',
  comment: 'Update consent preferences for a given data subject identifier',
  params: {
    input: UpdateConsentPreferenceInput,
    lookup: PrivacyCenterLookupInput.optional(),
    dhEncrypted: {
      type: 'string',
      underlyingType: t.type({}),
    },
  },
  response: {
    data: ConsentRecordSchema.list(),
    /** Optional in case the data is empty */
    decryptionContext: DecryptionContext,
  },
});

export const unauthenticatedPreferenceUpdate = mkMutation({
  name: 'unauthenticatedPreferenceUpdate',
  comment:
    'Update preferences and trigger DSR for a data subject without authentication',
  params: {
    input: UpdateConsentPreferenceInput,
    lookup: PrivacyCenterLookupInput.optional(),
    dhEncrypted: {
      type: 'string',
      underlyingType: t.type({}),
    },
  },
  response: {
    success: 'boolean',
  },
});

export const dataSubjectConsentPreferences = mkQuery({
  name: 'dataSubjectConsentPreferences',
  comment: 'Paginated query for fetching data subject consent preferences data',
  params: {
    input: ConsentPreferencesInput,
    dhEncrypted: {
      type: 'string',
      underlyingType: t.type({}),
    },
    lookup: PrivacyCenterLookupInput.optional(),
  },
  response: {
    nodes: ConsentRecordSchema.list(),
    decryptionContext: DecryptionContext,
  },
});

export const loadPrivacyPolicy = mkMutation({
  name: 'loadPrivacyPolicy',
  comment: 'Load the privacy policy from an external URL into the transcend.',
  params: {
    input: LoadPrivacyPolicyInput,
  },
  response: {
    content: 'string',
  },
});

export const launchPrivacyCenter = mkMutation({
  name: 'launchPrivacyCenter',
  comment: "Launch the organization's privacy page on its own domain",
  params: {
    input: PrivacyCenterIdInput,
  },
  response: {
    success: 'boolean',
  },
});

export const requestCertificate = mkMutation({
  name: 'requestCertificate',
  comment: 'Request a new certificate',
  params: {
    input: PrivacyCenterIdInput,
  },
  response: {
    certificate: Certificate,
  },
});

export const deployCloudfront = mkMutation({
  name: 'deployCloudfront',
  comment: 'Deploy the CloudFront distribution for an organization',
  params: {
    input: PrivacyCenterIdInput,
  },
  response: {
    success: 'boolean',
  },
});

export const updatePrivacyCenter = mkMutation({
  name: 'updatePrivacyCenter',
  comment: "Update the organization's privacy center",
  params: {
    input: UpdatePrivacyCenterInput,
  },
  response: {
    privacyCenter: PrivacyCenterStringified,
  },
});

export const updatePolicies = mkMutation({
  name: 'updatePolicies',
  comment: "Update the organization's privacy center policies",
  params: {
    input: UpdatePoliciesInput,
  },
  response: {
    policies: Policy.list(),
  },
});

export const deletePolicies = mkMutation({
  name: 'deletePolicies',
  comment: "Delete the organization's privacy center policies",
  params: {
    input: DeletePoliciesInput,
  },
  response: {
    success: 'boolean',
  },
});

export const privacyCenterEmailLogin = mkMutation({
  name: 'privacyCenterEmailLogin',
  comment: 'Login to the privacy center via email magic link',
  params: {
    lookup: PrivacyCenterLookupInput.optional(),
    input: EmailLoginInput,
  },
  response: {
    success: 'boolean',
  },
});

export const updateIntlMessages = mkMutation({
  name: 'updateIntlMessages',
  comment:
    'Update the internationalized messages displayed on the privacy center.',
  params: {
    input: UpdateMessagesInput,
  },
  response: {
    messages: Message.list(),
  },
});

export const predictPrivacyPolicyUrl = mkQuery({
  name: 'predictPrivacyPolicyUrl',
  comment:
    'Guess the privacy policy URL based on the domain name of the company',
  params: {},
  response: {
    url: 'string',
  },
});

export const assetFiles = mkQuery({
  name: 'assetFiles',
  comment:
    'Paginate over the list of asset files associated with the organization.',
  params: {
    ...PAGINATION_INPUT_PARAMS,
    orderBy: AssetFileOrder,
  },
  response: {
    nodes: AssetFile.list(),
    totalCount: 'int',
  },
});

export const privacyCenterPolicies = mkQuery({
  name: 'privacyCenterPolicies',
  comment: "Configuration for an organization's privacy center",
  params: {
    lookup: PrivacyCenterLookupInput.optional(),
  },
  response: Policy.list(),
});

export const privacyCenter = mkQuery({
  name: 'privacyCenter',
  comment: "Configuration for an organization's privacy center",
  params: {
    lookup: PrivacyCenterLookupInput.optional(),
  },
  response: PrivacyCenterStringified,
});

export const privacyCenterSubjects = mkQuery({
  name: 'privacyCenterSubjects',
  comment: 'The data subjects that are allowed to submit the form',
  params: {
    lookup: PrivacyCenterLookupInput.optional(),
  },
  response: DataSubject.list(),
});

export const privacyCenterTrackingTechnology = mkQuery({
  name: 'privacyCenterTrackingTechnology',
  comment:
    'The Tracking Technologies in use by the organization owning a privacy center',
  params: {
    lookup: PrivacyCenterLookupInput.optional(),
  },
  response: TrackingTechnology.list(),
});

export const dataCollectionsRequestFileCount = mkQuery({
  name: 'dataCollectionsRequestFileCount',
  comment:
    'The number of request files for each data collection for a given request',
  params: {
    input: DataCollectionsRequestFileCountInput,
    dhEncrypted: {
      type: 'string',
      isOptional: true,
      underlyingType: t.type({}),
    },
    lookup: PrivacyCenterLookupInput.optional(),
  },
  response: {
    requestFileCounts: DataCollectionRequestFileCount.list(),
  },
});

export const privacyCenterTranslatedMessages = mkQuery({
  name: 'privacyCenterTranslatedMessages',
  comment: 'Grab all of the translations for a particular locale',
  params: {
    input: TranslatedMessagesInput,
    lookup: PrivacyCenterLookupInput.optional(),
  },
  response: PrivacyCenterTranslation.list(),
});

export const translatedMessages = mkQuery({
  name: 'translatedMessages',
  comment:
    'List out all messages that need to be translated (excludes policies)',
  params: {
    input: TranslatedMessagesFilterInput.optional(),
  },
  response: Message.list(),
});

export const privacyCenterDeployment = mkQuery({
  name: 'privacyCenterDeployment',
  comment: 'The deployment metadata for a privacy center',
  params: {
    input: PrivacyCenterIdInput,
  },
  response: PrivacyCenterDeployment,
});

export const privacyCenterVersionModules = mkQuery({
  name: 'privacyCenterVersionModules',
  comment: 'Get either the deployed or saved privacy center version modules',
  params: {
    input: PrivacyCenterVersionModulesInput,
  },
  response: PrivacyCenterVersionModules.optional(),
});

export const deployPrivacyCenterVersion = mkMutation({
  name: 'deployPrivacyCenterVersion',
  comment: 'Deploy the saved privacy center version',
  params: {
    input: DeployPrivacyCenterVersionInput,
  },
  response: {
    success: 'boolean',
  },
});

export const requestsProcessedDisclosureStats = mkQuery({
  name: 'requestsProcessedDisclosureStats',
  comment:
    'Get stats for the number of requests processed in a year. Used for CCPA disclosure.',
  params: {
    lookup: PrivacyCenterLookupInput.optional(),
    input: RequestsProcessedDisclosureStatsInput.optional(),
  },
  response: RequestsProcessedDisclosureStatsByYear.list(),
});

export const requestsProcessedDisclosureStatsSettings = mkQuery({
  name: 'requestsProcessedDisclosureStatsSettings',
  comment:
    'Get configured settings for the requests processed disclosure table',
  params: {
    input: PrivacyCenterIdInput,
  },
  response: RequestsProcessedDisclosureStatsSettings,
});

export const setRequestsProcessedDisclosureStatsSettings = mkMutation({
  name: 'setRequestsProcessedDisclosureStatsSettings',
  comment:
    'Set configured settings for the requests processed disclosure table',
  params: {
    input: RequestsProcessedDisclosureStatsSettingsInput,
  },
  response: {
    success: 'boolean',
  },
});

export const privacyCenterPurposes = mkQuery({
  name: 'privacyCenterPurposes',
  comment: 'Get the purposes configured for the privacy center',
  params: {
    lookup: PrivacyCenterLookupInput.optional(),
  },
  response: PrivacyCenterTrackingPurposePreview.list(),
});
/* eslint-enable max-lines */
