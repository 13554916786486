import { defineMessages } from '@transcend-io/internationalization';

export const repositoriesMessages = defineMessages(
  'admin-dashboard.CodeScanning.Repositories.repositories',
  {
    newRepository: {
      defaultMessage: 'New Repository',
    },
    deleteSuccess: {
      defaultMessage:
        '{count, plural, one {Repository} other {Repositories}} successfully deleted',
    },
    repositoriesCount: {
      defaultMessage:
        '{count} {count, plural, one {Repository} other {Repositories}}',
    },
    noDataMessage: {
      defaultMessage: 'No repositories to display.',
    },
    confirmDeleteButton: {
      defaultMessage:
        'Delete {count, plural, one {Repository} other {{count} Repositories}}',
    },
    confirmDeletePrompt: {
      defaultMessage:
        'Are you sure you want to delete {count, plural, one {this repository} other {{count} repositories}}?',
    },
    repositoryUserCount: {
      defaultMessage: '{count} {count, plural, one {user} other {users}}',
    },
  },
);

export const repositoriesColumnHeaderMessages = defineMessages(
  'admin-dashboard.CodeScanning.Repositories.repositoriesColumnHeader',
  {
    name: {
      defaultMessage: 'Name',
    },
    description: {
      defaultMessage: 'Description',
    },
    url: {
      defaultMessage: 'URL',
    },
    createdAt: {
      defaultMessage: 'Created At',
    },
    codePackageCount: {
      defaultMessage: 'Packages',
    },
    softwareDevelopmentKitCount: {
      defaultMessage: 'SDKs',
    },
    promptRunCount: {
      defaultMessage: 'Prompt Runs',
    },
    pullRequestCount: {
      defaultMessage: 'Pull Requests',
    },
    owners: {
      defaultMessage: 'Owners',
    },
    teams: {
      defaultMessage: 'Teams',
    },
  },
);

export const repositoryModalMessages = defineMessages(
  'admin-dashboard.CodeScanning.Repositories.repositoryModal',
  {
    modalButton: {
      defaultMessage: 'New Repository',
    },
    header: {
      defaultMessage: 'New Repository',
    },
    introduction: {
      defaultMessage:
        'Create a new repository that holds code that needs to be mapped or analyzed.',
    },
    submitButton: {
      defaultMessage: 'Create New Repository',
    },
    submitSuccess: {
      defaultMessage: 'New repository created!',
    },
  },
);
