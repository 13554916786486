import styled from 'styled-components';

import { Button, ButtonProps } from '../Button';

export interface MultiItemOverflowDisplayStyleProps {
  /** copied multi image select prop, needs $ because of styled-components */
  $isCircle?: boolean;
  /** copied multi image select prop, needs $ because of styled-components */
  $isInline?: boolean;
  /** copied multi image select prop, needs $ because of styled-components */
  $borderless?: boolean;
}

export const StyledImageIcon = styled.div<MultiItemOverflowDisplayStyleProps>`
  /** Overlap icons if multiple, but keep first one flushed properly */

  :not(:first-child) {
    ${({ $isInline }) =>
      $isInline ? 'margin-left: -.5em;' : 'margin-left: -1em;'};
  }

  button {
    padding: 0;
    border-width: 0;
  }
`;

export const StyledMultiImageOverflowIndicator = styled(Button)<
  ButtonProps & MultiItemOverflowDisplayStyleProps
>`
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: ${({ $isCircle }) => ($isCircle ? '10em' : '6px')};
  ${({ $isInline, theme, $borderless }) =>
    $isInline
      ? `
  margin-left: -.5em;
  height: 24px;
  min-width: 24px;
  font-size: 12px;
  border: ${$borderless ? '0px' : `1px solid ${theme.colors.transcendNavy4}`};
 `
      : `
  margin-left: -1.5em;
  border: 4px solid white;
  height: 34px;
  min-width: 34px;
  font-size: 14px;
  z-index: 2;
  align-self: flex-end;
`}
  cursor: pointer;
  padding: 0px 3px;
`;
