import { FIXED_COLORS } from '@main/theme';

export const LEGEND_HEIGHT = 22;
export const LEGEND_GAP = 12;
export const LABEL_ANGLE_OF_ROTATION = -30;
/** The number of pixels between the graph "ticks", used as a heuristic to start hiding labels */
export const FIRST_OMIT_X_LABELS_BREAKPOINT = 32;
/** The number of pixels between the graph "ticks", used as a heuristic to start hiding EVEN MORE labels */
export const SECOND_OMIT_X_LABELS_BREAKPOINT = 16;
export const AXIS_FONT_SIZE = 12;
export const AXIS_VISX_PADDING = 18;
export const DEFAULT_LABEL_WIDTH_ON_NULL = 100;

export const AXIS_TEXT_PROPS = {
  fontSize: '12px',
  height: '16px',
  fill: FIXED_COLORS.transcendNavy2,
  fontFamily: 'neue-haas-grotesk-display,sans-serif',
};
export const MAX_LEGEND_HEIGHT_PERCENTAGE = 0.5;
