import React from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';

import { FormSelectAssignees } from '../../../Assessments/components/SelectAssignees';
import { requestAssessmentFromInventoryMessages } from './messages';
import { RequestAssessmentFromInventoryFormValues } from './types';

/**
 * Component to request an assessment based on fields from an inventory table
 */
export const AssigneesStep: React.FC = () => {
  const { formatMessage } = useIntl();
  const { control } =
    useFormContext<RequestAssessmentFromInventoryFormValues>();

  const { fields } = useFieldArray({
    control,
    name: 'rowAssignees',
  });

  return (
    <>
      {fields.map(({ rowId, rowTitle }, index) => (
        <FormSelectAssignees
          key={rowId}
          label={formatMessage(
            requestAssessmentFromInventoryMessages.assignUsersLabel,
            { rowTitle },
          )}
          name={`rowAssignees.${index}.assignees` as 'rowAssignees.0.assignees'}
        />
      ))}
    </>
  );
};
