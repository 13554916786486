import { useEffect, useState } from 'react';

/**
 * The result of the useWebWorker hook
 */
export type UseWebWorkerResult<O> =
  | {
      /** Is the result pending? */
      isLoading: true;
      /** The result from the worker */
      result: undefined;
    }
  | {
      /** Is the result pending? */
      isLoading: false;
      /** The result from the worker */
      result: O;
    };

/**
 * Hook to use a web worker
 *
 * @param workerInit - The initialization function for the web worker
 * @param message - The message to send to the web worker
 * @returns The result of the web worker and if it is loading
 */
export function useWebWorker<I, O>(
  workerInit: () => Worker,
  message: I,
): UseWebWorkerResult<O> {
  const [worker, setWorker] = useState<Worker | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(true);
  const [result, setResult] = useState<O | undefined>();

  // Initialize the worker
  useEffect(() => {
    const worker = workerInit();
    setWorker(worker);
    return () => {
      worker.terminate();
    };
  }, []);

  // Send the message and listen for the response
  useEffect(() => {
    if (!worker) {
      return;
    }

    worker.onmessage = (event: MessageEvent<O>) => {
      setResult(event.data);
      setIsLoading(false);
    };

    setIsLoading(true);
    worker.postMessage(message);
  }, [message, worker]);

  if (isLoading) {
    return { isLoading, result: undefined };
  }

  return { isLoading, result: result! };
}
