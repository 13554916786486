import { defineMessages } from '@transcend-io/internationalization';

export const approveSdkButtonMessages = defineMessages(
  'admin-dashboard.ConsentManager.NativeApps.TriageSDKs.components.ApproveSdkButton.approveSdkButton',
  {
    buttonText: {
      defaultMessage: 'Add to Consent SDKs',
    },
    missingIntegration: {
      defaultMessage:
        'Please assign the SDK to an integration before adding it to Consent SDKs',
    },
    approveSuccess: {
      defaultMessage:
        'Successfully added Software Development Kit to Consent SDKs',
    },
  },
);
