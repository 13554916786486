import { Alert, GenericMessageDescriptor } from '@main/core-ui';
import React from 'react';
import { useIntl } from 'react-intl';

import { dashboardChartMessages } from './messages';

interface DashboardEffectiveDateWarningProps {
  /** a custom message override when no data is present */
  noDataMessage?: GenericMessageDescriptor;
  /** a custom message override when partial data is present */
  reducedDataMessage?: GenericMessageDescriptor;
  /** the start date for the chart */
  startDate?: Date;
  /** the returned effective date for the chart */
  effectiveDate?: string;
}

/**
 * DashboardEffectiveDateWarning
 */
export const DashboardEffectiveDateWarning: React.FC<
  DashboardEffectiveDateWarningProps
> = ({ noDataMessage, reducedDataMessage, effectiveDate, startDate }) => {
  const { formatMessage } = useIntl();
  const showNoDataWarning =
    // user has no data yet bc the required version isn't deployed or hasn't sent anything back yet
    effectiveDate === 'true';
  const showReducedDataWarning =
    // user has partial data for the given date range
    effectiveDate &&
    effectiveDate !== 'true' &&
    (!startDate || new Date(effectiveDate) > startDate);
  const effectiveDateString = new Date(effectiveDate!).toLocaleDateString();

  return showNoDataWarning || showReducedDataWarning ? (
    <Alert variant="warning">
      {showNoDataWarning &&
        (noDataMessage ??
          formatMessage(dashboardChartMessages.noDataEffectiveDateWarning, {
            date: effectiveDateString,
          }))}
      {showReducedDataWarning &&
        (reducedDataMessage ??
          formatMessage(
            dashboardChartMessages.reducedDataEffectiveDateWarning,
            { date: effectiveDateString },
          ))}
    </Alert>
  ) : null;
};
