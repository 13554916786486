import {
  buildUseQuery,
  formatErrorMessage,
  Icon,
  ReactSelect,
  ReactSelectExtendedProps,
} from '@main/core-ui';
import { ApplicationUserPreview, endpoints } from '@main/prompt-types';
import { ID } from '@main/schema-utils';
import React, { useCallback, useEffect, useState } from 'react';
import { useTheme } from 'styled-components';

const NODES = {
  id: null,
  name: null,
  coreIdentifier: null,
} as const;

/**
 * Selected application user
 */
export type SelectedApplicationUser = Pick<
  ApplicationUserPreview,
  keyof typeof NODES
> & {
  /** Whether application user is newly created or existing */
  isNew?: boolean;
};

/** An option for the select component */
export interface SelectApplicationUserOption {
  /** The value for the option */
  value: SelectedApplicationUser;
  /** The label for the option */
  label: string;
  /** The logo for the option */
  logo: JSX.Element;
}

const useApplicationUsers = buildUseQuery(
  endpoints.applicationUsers,
  'SelectApplicationUsers',
  {
    nodes: NODES,
  },
);

const isId = (
  value: SelectedApplicationUser | ID<'appUser'>,
): value is ID<'appUser'> => typeof value === 'string';

/** Props for the `SelectApplicationUser` component */
export interface SelectApplicationUserProps
  extends Omit<
    ReactSelectExtendedProps<boolean, SelectedApplicationUser>,
    'onChange'
  > {
  /** The currently selected values */
  value?: SelectedApplicationUser[] | ID<'appUser'>[];
  /** On change handler */
  onChange?: (applicationUsers: SelectedApplicationUser[]) => void;
}

const isSelectedApplicationUserOption = (
  value: SelectApplicationUserOption | undefined,
): value is SelectApplicationUserOption => !!value;

export const SelectApplicationUsers: React.FC<SelectApplicationUserProps> = ({
  isLoading,
  isMulti = true,
  disabled = false,
  value: initialValues,
  onChange,
  ...props
}) => {
  const { data, loading, error } = useApplicationUsers({
    fetchPolicy: 'cache-and-network',
  });
  const theme = useTheme();

  const toOption = useCallback(
    (
      value?: SelectedApplicationUser,
    ): SelectApplicationUserOption | undefined =>
      !value
        ? undefined
        : {
            value,
            label: `${value.coreIdentifier}${
              value.name ? ` (${value.name})` : ''
            }`,
            logo: (
              <Icon type="assessment-template" color={theme.colors.transcend} />
            ),
          },
    [theme],
  );
  const options = (data?.nodes ?? []).map(toOption);

  const [selected, setSelected] = useState<SelectApplicationUserOption[]>();

  useEffect(() => {
    const newSelected = (
      initialValues
        ? initialValues.map((value) =>
            isId(value)
              ? toOption((data?.nodes || []).find(({ id }) => id === value))
              : toOption(value),
          )
        : []
    ).filter(isSelectedApplicationUserOption);

    setSelected(newSelected);
  }, [data, initialValues, toOption]);

  return (
    <ReactSelect<boolean, SelectApplicationUserOption>
      options={options.filter(isSelectedApplicationUserOption)}
      value={selected}
      isMulti={isMulti}
      isLoading={isLoading || loading}
      getOptionLogo={({ logo }) => logo}
      noOptionsMessage={
        error ? () => formatErrorMessage(error.message) : undefined
      }
      getOptionLabel={({ label }) => label}
      getOptionValue={({ value }) => value.id}
      isClearable={false}
      closeMenuOnSelect
      isDisabled={disabled}
      onChange={
        !onChange
          ? undefined
          : (option) =>
              !option
                ? onChange([])
                : Array.isArray(option)
                  ? onChange(option.map(({ value }) => value))
                  : onChange([
                      (option as SelectApplicationUserOption).value as any,
                    ])
      }
      {...props}
    />
  );
};

/**
 * Select a single application user
 */
export const SelectApplicationUser: React.FC<
  Omit<SelectApplicationUserProps, 'value' | 'onChange'> & {
    /** Set the newly selected application user */
    onChange?: (applicationUser: SelectedApplicationUser) => void;
    /** Selected value */
    value?: SelectedApplicationUser | ID<'appUser'>;
  }
> = ({ onChange, value, ...props }) => (
  <SelectApplicationUsers
    {...props}
    isMulti={false}
    value={
      // default type is mixed array rather than array of one type or the other
      value ? ([value] as SelectedApplicationUser[] | ID<'appUser'>[]) : []
    }
    onChange={onChange ? ([value]) => onChange(value) : undefined}
  />
);
