/* eslint-disable max-lines */
import { TranscendProduct } from '@transcend-io/privacy-types';

import type { ExtendableRoute } from '@main/admin-dashboard/src/routes';
import { PublicOnlyRouteWrapper } from '@main/admin-dashboard/src/routes/PublicOnlyRoute';
import {
  AdminDashboardCmOnboardingPath,
  AdminDashboardCmPath,
  AdminDashboardCmPublicPath,
} from '@main/cm-types';

import { createOnboardingItemsComponent } from '../Auth/ActionItems/OnboardingItemsCollections/OnboardingItemCollections';
import { navMenuMessages } from '../routes/OrganizationRoute/NavMenu/messages';
import { ConsentAuditTrailLoadable } from './AuditTrail/Loadable';
import { ConsentPurposes } from './ConsentPurposes/Loadable';
import { ConsentServices } from './ConsentServices/Loadable';
import {
  ApprovedCookies,
  JunkCookies,
  TriageCookies,
} from './Cookies/pages/Loadable';
import { Dashboard } from './Dashboard/Loadable';
import {
  ApprovedDataFlows,
  JunkDataFlows,
  TriageDataFlows,
} from './DataFlows/pages/Loadable';
import { AdvancedSettings } from './DeveloperSettings/AdvancedSettings/Loadable';
import { AirgapChangeLog } from './DeveloperSettings/AirgapChangelog/Loadable';
import { DeployHistory } from './DeveloperSettings/DeployHistory/Loadable';
import { Installation } from './DeveloperSettings/Installation/Loadable';
import { DeveloperSettings } from './DeveloperSettings/Loadable';
import { OtherConsentFrameworks } from './DeveloperSettings/OtherConsentFrameworks/Loadable';
import { DisplaySettingsMessages } from './DisplaySettings/DisplaySettingsMessages/Loadable';
import { DisplaySettings } from './DisplaySettings/Loadable';
import { FlipTheScript } from './FlipTheScript/Loadable';
import { GetStarted } from './GetStarted/Loadable';
import { consentManagerPageTitleMessages } from './messages';
import { ConsentApplications } from './NativeApps/ConsentApplications/Loadable';
import { Sdks } from './NativeApps/Sdks/Loadable';
import { TriageApplications } from './NativeApps/TriageApplications';
import { TriageSDKs } from './NativeApps/TriageSDKs';
import { RegionalExperiences } from './RegionalExperiences/Loadable';
import { ResendVerification } from './Signup/components/ResendVerification/Loadable';
import { Signup } from './Signup/Loadable';
import { TcfFeatures } from './Tcf/TcfFeatures/Loadable';
import { TcfPurposes } from './Tcf/TcfPurposes/Loadable';
import { TcfStacks } from './Tcf/TcfStacks/Loadable';
import { TcfVendorDetailsLoadable } from './Tcf/TcfVendorDetails/Loadable';
import { TcfVendors } from './Tcf/TcfVendors/Loadable';

const consentManagerBreadcrumb = {
  id: 'consent-manager-header',
  displayText: consentManagerPageTitleMessages.consentManager,
  href: AdminDashboardCmPath.ConsentManager,
};

export const tcfBreadcrumbs = [
  consentManagerBreadcrumb,
  {
    id: AdminDashboardCmPath.TcfVendors,
    displayText: consentManagerPageTitleMessages.tcf,
    href: AdminDashboardCmPath.TcfVendors,
  },
];

const cookiesBreadcrumbs = [
  consentManagerBreadcrumb,
  {
    id: AdminDashboardCmPath.CookiesTriage,
    displayText: consentManagerPageTitleMessages.cookies,
    href: AdminDashboardCmPath.CookiesTriage,
  },
];

const dataFlowsBreadcrumbs = [
  consentManagerBreadcrumb,
  {
    id: AdminDashboardCmPath.DataFlowsTriage,
    displayText: consentManagerPageTitleMessages.dataFlows,
    href: AdminDashboardCmPath.DataFlowsTriage,
  },
];

const displaySettingsBreadcrumbs = [
  consentManagerBreadcrumb,
  {
    id: AdminDashboardCmPath.DisplaySettingsMessages,
    displayText: consentManagerPageTitleMessages.displaySettings,
    href: AdminDashboardCmPath.DisplaySettingsMessages,
  },
];

const regionalExperiencesBreadcrumbs = [
  consentManagerBreadcrumb,
  {
    id: AdminDashboardCmPath.RegionalExperiences,
    displayText: consentManagerPageTitleMessages.regionalExperiences,
    href: AdminDashboardCmPath.RegionalExperiences,
  },
];

const nativeAppsBreadcrumbs = [
  consentManagerBreadcrumb,
  {
    id: AdminDashboardCmPath.Applications,
    displayText: consentManagerPageTitleMessages.applications,
    href: AdminDashboardCmPath.Applications,
  },
];

export const publicRoutes: {
  [k in AdminDashboardCmPublicPath]: ExtendableRoute;
} = {
  [AdminDashboardCmPublicPath.Signup]: {
    path: AdminDashboardCmPath.Signup,
    component: Signup,
    wrapper: PublicOnlyRouteWrapper,
    // no title - h1 is in content
  },
  [AdminDashboardCmPublicPath.ResendVerification]: {
    path: AdminDashboardCmPath.ResendVerification,
    component: ResendVerification,
    wrapper: PublicOnlyRouteWrapper,
    // no title - h1 is in content
  },
};

export const onboardingRoutes: {
  [k in AdminDashboardCmOnboardingPath]: ExtendableRoute;
} = {
  [AdminDashboardCmOnboardingPath.OnboardConsentManager]: {
    path: AdminDashboardCmOnboardingPath.OnboardConsentManager,
    component: GetStarted,
    hideInnerPadding: true,
    // no title - h1 & Helmet is in Onboarding component
  },
  [AdminDashboardCmOnboardingPath.FlipTheScript]: {
    path: AdminDashboardCmOnboardingPath.FlipTheScript,
    component: FlipTheScript,
    hideInnerPadding: true,
    // no title - h1 & Helmet is in Onboarding component
  },
};

export const consentManagerRoutes: {
  [k in AdminDashboardCmPath]: ExtendableRoute;
} = {
  ...publicRoutes,
  ...onboardingRoutes,
  [AdminDashboardCmPath.ConsentManager]: {
    path: AdminDashboardCmPath.ConsentManager,
    title: consentManagerPageTitleMessages.dashboardPageTitle,
    component: Dashboard,
    breadcrumbs: [
      consentManagerBreadcrumb,
      {
        id: AdminDashboardCmPath.ConsentManager,
        displayText: consentManagerPageTitleMessages.dashboardMenuLabel,
        href: AdminDashboardCmPath.ConsentManager,
      },
    ],
  },
  [AdminDashboardCmPath.DataFlowsTriage]: {
    path: AdminDashboardCmPath.DataFlowsTriage,
    title: consentManagerPageTitleMessages.dataFlows,
    component: TriageDataFlows,
    breadcrumbs: dataFlowsBreadcrumbs,
  },
  [AdminDashboardCmPath.DataFlowsApproved]: {
    path: AdminDashboardCmPath.DataFlowsApproved,
    title: consentManagerPageTitleMessages.dataFlows,
    component: ApprovedDataFlows,
    breadcrumbs: dataFlowsBreadcrumbs,
  },
  [AdminDashboardCmPath.DataFlowsJunk]: {
    path: AdminDashboardCmPath.DataFlowsJunk,
    title: consentManagerPageTitleMessages.dataFlows,
    component: JunkDataFlows,
    breadcrumbs: dataFlowsBreadcrumbs,
  },
  [AdminDashboardCmPath.CookiesTriage]: {
    path: AdminDashboardCmPath.CookiesTriage,
    title: consentManagerPageTitleMessages.cookies,
    component: TriageCookies,
    breadcrumbs: cookiesBreadcrumbs,
  },
  [AdminDashboardCmPath.CookiesApproved]: {
    path: AdminDashboardCmPath.CookiesApproved,
    title: consentManagerPageTitleMessages.cookies,
    component: ApprovedCookies,
    breadcrumbs: cookiesBreadcrumbs,
  },
  [AdminDashboardCmPath.CookiesJunk]: {
    path: AdminDashboardCmPath.CookiesJunk,
    title: consentManagerPageTitleMessages.cookies,
    component: JunkCookies,
    breadcrumbs: cookiesBreadcrumbs,
  },
  [AdminDashboardCmPath.DisplaySettings]: {
    path: AdminDashboardCmPath.DisplaySettings,
    title: consentManagerPageTitleMessages.displaySettings,
    component: DisplaySettings,
    breadcrumbs: displaySettingsBreadcrumbs,
  },
  [AdminDashboardCmPath.DisplaySettingsMessages]: {
    path: AdminDashboardCmPath.DisplaySettingsMessages,
    title: consentManagerPageTitleMessages.displaySettings,
    component: DisplaySettingsMessages,
    breadcrumbs: displaySettingsBreadcrumbs,
  },
  [AdminDashboardCmPath.DeveloperSettings]: {
    path: AdminDashboardCmPath.DeveloperSettings,
    title: consentManagerPageTitleMessages.developerSettings,
    component: DeveloperSettings,
    breadcrumbs: [
      consentManagerBreadcrumb,
      {
        id: AdminDashboardCmPath.DeveloperSettings,
        displayText: consentManagerPageTitleMessages.developerSettings,
        href: AdminDashboardCmPath.DeveloperSettings,
      },
    ],
  },
  [AdminDashboardCmPath.Installation]: {
    path: AdminDashboardCmPath.Installation,
    title: consentManagerPageTitleMessages.developerSettings,
    component: Installation,
    breadcrumbs: [
      consentManagerBreadcrumb,
      {
        id: AdminDashboardCmPath.DeveloperSettings,
        displayText: consentManagerPageTitleMessages.developerSettings,
        href: AdminDashboardCmPath.DeveloperSettings,
      },
    ],
  },
  [AdminDashboardCmPath.OtherConsentFrameworks]: {
    path: AdminDashboardCmPath.OtherConsentFrameworks,
    title: consentManagerPageTitleMessages.developerSettings,
    component: OtherConsentFrameworks,
    breadcrumbs: [
      consentManagerBreadcrumb,
      {
        id: AdminDashboardCmPath.DeveloperSettings,
        displayText: consentManagerPageTitleMessages.developerSettings,
        href: AdminDashboardCmPath.DeveloperSettings,
      },
    ],
  },
  [AdminDashboardCmPath.AdvancedSettings]: {
    path: AdminDashboardCmPath.AdvancedSettings,
    title: consentManagerPageTitleMessages.developerSettings,
    component: AdvancedSettings,
    breadcrumbs: [
      consentManagerBreadcrumb,
      {
        id: AdminDashboardCmPath.DeveloperSettings,
        displayText: consentManagerPageTitleMessages.developerSettings,
        href: AdminDashboardCmPath.DeveloperSettings,
      },
    ],
  },
  [AdminDashboardCmPath.DeployHistory]: {
    path: AdminDashboardCmPath.DeployHistory,
    title: consentManagerPageTitleMessages.developerSettings,
    component: DeployHistory,
    breadcrumbs: [
      consentManagerBreadcrumb,
      {
        id: AdminDashboardCmPath.DeveloperSettings,
        displayText: consentManagerPageTitleMessages.developerSettings,
        href: AdminDashboardCmPath.DeveloperSettings,
      },
    ],
  },
  [AdminDashboardCmPath.AirgapChangeLog]: {
    path: AdminDashboardCmPath.AirgapChangeLog,
    title: consentManagerPageTitleMessages.developerSettings,
    component: AirgapChangeLog,
    breadcrumbs: [
      consentManagerBreadcrumb,
      {
        id: AdminDashboardCmPath.DeveloperSettings,
        displayText: consentManagerPageTitleMessages.developerSettings,
        href: AdminDashboardCmPath.DeveloperSettings,
      },
    ],
  },
  [AdminDashboardCmPath.RegionalExperiences]: {
    path: AdminDashboardCmPath.RegionalExperiences,
    title: consentManagerPageTitleMessages.regionalExperiences,
    component: RegionalExperiences,
    breadcrumbs: regionalExperiencesBreadcrumbs,
  },
  [AdminDashboardCmPath.ConsentPurposes]: {
    path: AdminDashboardCmPath.ConsentPurposes,
    title: consentManagerPageTitleMessages.regionalExperiences,
    component: ConsentPurposes,
    breadcrumbs: regionalExperiencesBreadcrumbs,
  },
  [AdminDashboardCmPath.TcfVendors]: {
    path: AdminDashboardCmPath.TcfVendors,
    title: consentManagerPageTitleMessages.tcf,
    component: TcfVendors,
    breadcrumbs: tcfBreadcrumbs,
  },
  [AdminDashboardCmPath.TcfVendorDetails]: {
    path: AdminDashboardCmPath.TcfVendorDetails,
    title: consentManagerPageTitleMessages.tcf,
    component: TcfVendorDetailsLoadable,
    // Fall back values. Should be set on the page dynamically.
    breadcrumbs: tcfBreadcrumbs,
  },
  [AdminDashboardCmPath.TcfVendorPurposes]: {
    path: AdminDashboardCmPath.TcfVendorPurposes,
    title: consentManagerPageTitleMessages.tcf,
    component: TcfVendorDetailsLoadable,
    // Fall back values. Should be set on the page dynamically.
    breadcrumbs: tcfBreadcrumbs,
  },
  [AdminDashboardCmPath.TcfVendorFeatures]: {
    path: AdminDashboardCmPath.TcfVendorFeatures,
    title: consentManagerPageTitleMessages.tcf,
    component: TcfVendorDetailsLoadable,
    // Fall back values. Should be set on the page dynamically.
    breadcrumbs: tcfBreadcrumbs,
  },
  [AdminDashboardCmPath.TcfPurposes]: {
    path: AdminDashboardCmPath.TcfPurposes,
    title: consentManagerPageTitleMessages.tcf,
    component: TcfPurposes,
    breadcrumbs: tcfBreadcrumbs,
  },
  [AdminDashboardCmPath.TcfFeatures]: {
    path: AdminDashboardCmPath.TcfFeatures,
    title: consentManagerPageTitleMessages.tcf,
    component: TcfFeatures,
    breadcrumbs: tcfBreadcrumbs,
  },
  [AdminDashboardCmPath.TcfStacks]: {
    path: AdminDashboardCmPath.TcfStacks,
    title: consentManagerPageTitleMessages.tcf,
    component: TcfStacks,
    breadcrumbs: tcfBreadcrumbs,
  },
  [AdminDashboardCmPath.Applications]: {
    path: AdminDashboardCmPath.Applications,
    title: consentManagerPageTitleMessages.applications,
    component: ConsentApplications,
    breadcrumbs: nativeAppsBreadcrumbs,
  },
  [AdminDashboardCmPath.Sdks]: {
    path: AdminDashboardCmPath.Sdks,
    title: consentManagerPageTitleMessages.applications,
    component: Sdks,
    breadcrumbs: nativeAppsBreadcrumbs,
  },
  [AdminDashboardCmPath.ConsentServices]: {
    path: AdminDashboardCmPath.ConsentServices,
    title: consentManagerPageTitleMessages.services,
    component: ConsentServices,
    breadcrumbs: [
      consentManagerBreadcrumb,
      {
        id: AdminDashboardCmPath.ConsentServices,
        displayText: consentManagerPageTitleMessages.services,
        href: AdminDashboardCmPath.ConsentServices,
      },
    ],
  },
  [AdminDashboardCmPath.TriageApplications]: {
    path: AdminDashboardCmPath.TriageApplications,
    title: consentManagerPageTitleMessages.codePackages,
    component: TriageApplications,
    breadcrumbs: [
      consentManagerBreadcrumb,
      {
        id: AdminDashboardCmPath.Applications,
        displayText: consentManagerPageTitleMessages.applications,
        href: AdminDashboardCmPath.Applications,
      },
      {
        id: AdminDashboardCmPath.TriageApplications,
        displayText: consentManagerPageTitleMessages.codePackages,
        href: AdminDashboardCmPath.TriageApplications,
      },
    ],
  },
  [AdminDashboardCmPath.TriageSDKs]: {
    path: AdminDashboardCmPath.TriageSDKs,
    title: consentManagerPageTitleMessages.softwareDevelopmentKits,
    component: TriageSDKs,
    breadcrumbs: [
      consentManagerBreadcrumb,
      {
        id: AdminDashboardCmPath.Sdks,
        displayText: consentManagerPageTitleMessages.applications,
        href: AdminDashboardCmPath.Sdks,
      },
      {
        id: AdminDashboardCmPath.TriageSDKs,
        displayText: consentManagerPageTitleMessages.softwareDevelopmentKits,
        href: AdminDashboardCmPath.TriageSDKs,
      },
    ],
  },
  [AdminDashboardCmPath.AuditTrail]: {
    path: AdminDashboardCmPath.AuditTrail,
    title: consentManagerPageTitleMessages.auditTrail,
    component: ConsentAuditTrailLoadable,
    breadcrumbs: [
      consentManagerBreadcrumb,
      {
        id: AdminDashboardCmPath.AuditTrail,
        displayText: consentManagerPageTitleMessages.auditTrail,
        href: AdminDashboardCmPath.AuditTrail,
      },
    ],
  },
  [AdminDashboardCmPath.ActionItems]: {
    path: AdminDashboardCmPath.ActionItems,
    title: navMenuMessages.linkActionItems,
    component: createOnboardingItemsComponent(
      TranscendProduct.ConsentManagement,
    ),
    breadcrumbs: [consentManagerBreadcrumb],
  },
};
/* eslint-enable max-lines */
