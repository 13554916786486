import { WiredIdentifier } from '@main/datamap-types';
import { mkInput, mkOrder, mkType, SchemaToType } from '@main/schema-utils';

import { RequestIdentifierOrderField, RequestIdentifierSource } from './enums';

export const RequestIdentifier = mkType({
  name: 'RequestIdentifier',
  comment: 'The value of an identifier in the context of a request',
  fields: {
    id: {
      comment: 'The unique ID of the RequestIdentifier',
      modelName: 'RequestIdentifier',
      type: 'id',
    },
    requestId: {
      comment: 'The ID of the request',
      modelName: 'request',
      type: 'id',
    },
    identifierId: {
      comment: 'The ID of the identifier',
      modelName: 'identifier',
      type: 'id',
    },
    error: {
      comment: 'The error message if something went wrong',
      optional: true,
      type: 'string',
    },
    identifier: {
      comment: 'The type of identifier',
      type: WiredIdentifier,
    },
    value: {
      comment: 'The identifier converted to string',
      type: 'string',
    },
    name: {
      comment: 'The name of the custom identifier',
      optional: true,
      type: 'string',
    },
    isVerifiedAtLeastOnce: {
      comment:
        'Indicator as to whether the request identifier has been verified by at least one RequestEnricher.' +
        'Only provided when input.requestEnricherId provided.',
      type: 'boolean',
    },
    isVerified: {
      comment:
        'Indicator as to whether the request identifier has been verified by a specific request enricher. ' +
        'Only provided when input.requestEnricherId provided.',
      optional: true,
      type: 'boolean',
    },
    initialAuthMethod: {
      comment: `When sombra signs an identifier, there is an underlying authentication method that did the verification
      (Usually one of "SombraDataSubjectAuthMethod"). Only provided when input.requestEnricherId provided.`,
      optional: true,
      type: 'string',
    },
    source: {
      comment: 'The source of the RequestIdentifier',
      type: { RequestIdentifierSource },
    },
    isEncrypted: {
      comment: 'Whether or not this identifier is encrypted',
      type: 'boolean',
    },
  },
});

/** Override type */
export type RequestIdentifier = SchemaToType<typeof RequestIdentifier>;

/** Order for a requestIdentifiers query */
export const RequestIdentifiersOrder = mkOrder(
  RequestIdentifier.name,
  RequestIdentifierOrderField,
);

/** Override type */
export type RequestIdentifiersOrder = SchemaToType<
  typeof RequestIdentifiersOrder
>;

export const RequestIdentifiersFiltersInput = mkInput({
  name: 'RequestIdentifiersFiltersInput',
  comment: 'Input for searching for RequestIdentifiers',
  fields: {
    identifierName: {
      comment: 'Filter for a specific identifier',
      type: 'string',
      optional: true,
    },
    text: {
      comment: 'Filter by arbitrary text (search on: name, value, type)',
      type: 'string',
      optional: true,
    },
    isVerifiedAtLeastOnce: {
      comment:
        'Filter by whether the identifier has been verified at least once',
      type: 'boolean',
      optional: true,
    },
    source: {
      comment: 'Filter by source',
      type: { RequestIdentifierSource },
      optional: true,
      list: true,
    },
  },
});

/** Override type */
export type RequestIdentifiersFiltersInput = SchemaToType<
  typeof RequestIdentifiersFiltersInput
>;

export const RequestIdentifiersInput = mkInput({
  name: 'RequestIdentifiersInput',
  comment: 'Input  for RequestIdentifiers',
  fields: {
    requestIds: {
      comment: 'A list of unique IDs of data subject requests',
      modelName: 'request',
      type: 'id',
      list: true,
    },
    requestEnricherId: {
      comment:
        'The unique ID of the RequestEnricher to filter RequestIdentifiers for',
      modelName: 'RequestEnricher',
      type: 'id',
      optional: true,
    },
  },
});

/** Override type */
export type RequestIdentifiersInput = SchemaToType<
  typeof RequestIdentifiersInput
>;

export const RemoveRequestIdentifiersInput = mkInput({
  name: 'RemoveRequestIdentifiersInput',
  comment: 'Input for removing RequestIdentifiers',
  fields: {
    requestId: {
      comment: 'The unique ID of the data subject request',
      modelName: 'request',
      type: 'id',
    },
    requestIdentifierIds: {
      comment: 'The RequestIdentifiers to delete',
      modelName: 'RequestIdentifier',
      type: 'id',
      list: true,
    },
  },
});

/** Override type */
export type RemoveRequestIdentifiersInput = SchemaToType<
  typeof RemoveRequestIdentifiersInput
>;
