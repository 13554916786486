import { gql } from '@apollo/client';
import {
  InlineSelectTeams,
  SelectedTeam,
  SelectTeamProps,
} from '@main/ad-core-components';
import { buildUseMutation } from '@main/core-ui';
import { endpoints, SubDataPoint } from '@main/datamap-types';
import type { ID } from '@main/schema-utils';
import React from 'react';

import { updateSubDataPointsGqlCache } from '../../helpers/updateSubDataPointsGqlCache';

const useUpdateDataSilos = buildUseMutation(
  endpoints.updateDataSilos,
  'UpdateDataSiloTeams',
  {
    dataSilos: {
      id: null,
      teams: {
        id: null,
        name: null,
      },
    },
  },
);

/** Props for the `InlineSelectDataSiloTeams` component */
export interface InlineSelectDataSiloTeamsProps
  extends Omit<SelectTeamProps, 'onChange' | 'onBlur'> {
  /** The ID of the parent data silo for this cell */
  id: ID<'dataSilo'>;
  /** The value for the cell */
  value: SelectedTeam[];
  /** Other subdatapoints whose teams should potentially be updated after a change */
  subDataPointsToUpdate?: SubDataPoint[];
}

export const InlineSelectDataSiloTeams: React.FC<
  InlineSelectDataSiloTeamsProps
> = ({ id, value, subDataPointsToUpdate, ...props }) => {
  const [updateDataSilos, { loading }] = useUpdateDataSilos({
    ...(subDataPointsToUpdate
      ? {
          update: (cache, { data }) => {
            const [dataSilo] = (data as any).updateDataSilos.dataSilos;

            // Manually update the cache so that we don't have to refetch the entire (slow) subdatapoints query
            updateSubDataPointsGqlCache({
              subDataPointsToUpdate,
              cache,
              data: {
                dataSiloTeams: dataSilo.teams,
              },
              fragment: gql`
                fragment DataSiloTeams on SubDataPoint {
                  dataSiloTeams {
                    id
                    name
                  }
                }
              `,
              // dataPoint should always be defined, but could get forgotten in the GQL response fields
              shouldUpdate: (sdp) => sdp.dataPoint.dataSilo.id === dataSilo.id,
            });
          },
        }
      : {}),
  });

  return (
    <InlineSelectTeams
      value={value}
      loading={loading}
      onUpdate={(teams) =>
        updateDataSilos({
          variables: {
            input: {
              dataSilos: [
                {
                  id,
                  teams,
                },
              ],
            },
          },
        }).then(({ data, errors }) => ({
          data: data?.dataSilos[0]?.teams,
          errors,
        }))
      }
      {...props}
    />
  );
};
