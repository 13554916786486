import { RequestAction, RequestStatus } from '@transcend-io/privacy-types';

/**
 * The main workflow for a successful access request
 */
const REQUEST_LIFECYCLE_ACCESS_ORDER: RequestStatus[] = [
  /** Request begins */
  RequestStatus.RequestMade,
  /** Enrich identifiers */
  RequestStatus.Enriching,
  /** Request queued */
  RequestStatus.Waiting,
  /** Request compilation */
  RequestStatus.Compiling,
  /** Approval step */
  RequestStatus.Approving,
  /** Approved and sent download report */
  RequestStatus.ViewCategories,
  /** Approved and sent download report */
  RequestStatus.Downloadable,
  /** Request has been completed. A bit redundant with archived but archived may become a boolean */
  RequestStatus.Completed,
];

/**
 * The true ordering of the request lifecycle statuses with a primary and secondary action
 */
const REQUEST_LIFECYCLE_ERASURE_ORDER: RequestStatus[] = [
  /** Request begins */
  RequestStatus.RequestMade,
  /** Enrich identifiers */
  RequestStatus.Enriching,
  /** Request queued */
  RequestStatus.Waiting,
  /** Request compilation */
  RequestStatus.Compiling,
  /** Approval step */
  RequestStatus.Approving,
  /** Primary report sent and delaying execution of secondary action */
  RequestStatus.Delayed,
  /** Secondary action fired */
  RequestStatus.Secondary,
  /** Secondary waiting on approval */
  RequestStatus.SecondaryApproving,
  /** Secondary action completed */
  RequestStatus.SecondaryCompleted,
];

/**
 * The main workflow for any other request type
 */
const REQUEST_LIFECYCLE_DEFAULT_ORDER: RequestStatus[] = [
  /** Request begins */
  RequestStatus.RequestMade,
  /** Enrich identifiers */
  RequestStatus.Enriching,
  /** Request queued */
  RequestStatus.Waiting,
  /** Request compilation */
  RequestStatus.Compiling,
  /** Approval step */
  RequestStatus.Approving,
  /** Completed and notified. */
  RequestStatus.Completed,
];

/**
 * Determine the order of request steps based on the DSR type
 *
 * TODO: https://github.com/transcend-io/main/issues/2758 - will change when access/erasure split apart
 *
 * @param type - The type of action
 * @returns The list of request statuses
 */
export function getRequestStepsByType(type: RequestAction): RequestStatus[] {
  return type === RequestAction.Access
    ? REQUEST_LIFECYCLE_ACCESS_ORDER
    : type === RequestAction.Erasure
      ? REQUEST_LIFECYCLE_ERASURE_ORDER
      : REQUEST_LIFECYCLE_DEFAULT_ORDER;
}
