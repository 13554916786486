import { Policy } from '@main/pc-types';

import { ModifiedPolicy } from './types';

/**
 *
 * @param policy - a full policy
 * @returns the policy without extra parameters on any of the content properties
 */
export const deployedPolicyToModifiedPolicy = ({
  versions,
  ...policy
}: Policy): ModifiedPolicy => ({
  ...policy,
  versions: versions.map(({ content, ...version }) => ({
    ...version,
    ...(content
      ? {
          content: {
            // don't use other non-input Message properties here, like id, or any of the __typename properties
            defaultMessage: content.defaultMessage,
            translations: content.translations,
          },
        }
      : {}),
  })),
});
