import type { RequestStatus } from '@transcend-io/privacy-types';
import { createEnum, filterEnum } from '@transcend-io/type-utils';

import { REQUEST_STATUSES } from './statuses';

/**
 * The statuses where a request is in an error
 */
export const REQUEST_STATUSES_ERROR = filterEnum(
  REQUEST_STATUSES,
  (status) => !!status.isError,
);

/**
 * The statuses where a request is in an error, keyed by status
 */
export const REQUEST_STATUS_ERROR = createEnum(REQUEST_STATUSES_ERROR);

/**
 * Indicates that a request is in an error state
 *
 * @param status - The status of the request to check
 * @returns True if the request is in an error state
 */
export function isError(status: RequestStatus): boolean {
  return status in REQUEST_STATUS_ERROR;
}
