import {
  DefaultOptionType,
  extractFormItemWrapperProps,
  FormItemWrapper,
  IFormInputValidatedProps,
  IFormItemWrapperProps,
  multipleValidators,
  ReactSelectByValue,
  ReactSelectByValueProps,
} from '@main/core-ui';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { GroupTypeBase, OptionTypeBase } from 'react-select';
import { CSSProperties } from 'styled-components';

export interface FormReactSelectProps<
  IsMulti extends boolean,
  T extends OptionTypeBase = DefaultOptionType,
  G extends GroupTypeBase<T> = GroupTypeBase<T>,
  V extends string = string,
> extends IFormInputValidatedProps,
    Omit<
      ReactSelectByValueProps<IsMulti, T, G, V>,
      'value' | 'onChange' | 'onBlur' | 'name'
    > {}

/**
 * A react select component wrapper for use in forms. Automatically unwraps values
 * from options so you don't need to deal with translating the option types to values and back
 */
export function FormReactSelectRaw<
  IsMulti extends boolean,
  T extends OptionTypeBase = DefaultOptionType,
  G extends GroupTypeBase<T> = GroupTypeBase<T>,
  V extends string = string,
>({
  name,
  defaultValue,
  rules,
  ...reactSelectProps
}: FormReactSelectProps<IsMulti, T, G, V>): JSX.Element {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      rules={rules ? { validate: multipleValidators(rules) } : undefined}
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      render={({ field: { ref, ...field } }) => (
        <ReactSelectByValue<IsMulti, T, G, V>
          {...reactSelectProps}
          {...field}
        />
      )}
    />
  );
}

/**
 * A form react select with formatting and error display. Automatically unwraps values
 * from options so you don't need to deal with translating the option types to values and back
 */
export function FormReactSelect<
  IsMulti extends boolean,
  T extends OptionTypeBase = DefaultOptionType,
  G extends GroupTypeBase<T> = GroupTypeBase<T>,
  V extends string = string,
>(
  props: FormReactSelectProps<IsMulti, T, G, V> &
    Omit<IFormItemWrapperProps, 'errors'> & {
      /** Styles for the form item wrapper specifically */
      formItemWrapperStyle?: CSSProperties;
    },
): JSX.Element {
  const {
    formState: { errors },
  } = useFormContext();
  const { passthroughProps, wrapperProps } = extractFormItemWrapperProps(props);

  return (
    <FormItemWrapper errors={errors} {...wrapperProps}>
      <FormReactSelectRaw<IsMulti, T, G, V> {...passthroughProps} />
    </FormItemWrapper>
  );
}
