import { AgentPreview } from '@main/attribute-types';
import { mkInput, mkOrder, mkType, SchemaToType } from '@main/schema-utils';

import { ApplicationPreview } from './application';
import { PathfinderOrderField } from './enums';
import {
  LargeLanguageModelInput,
  LargeLanguageModelPreview,
} from './largeLanguageModel';
import { PathfinderPolicyPreview } from './pathfinderPolicy';

export const PathfinderPreview = mkType({
  name: 'PathfinderPreview',
  comment: 'A preview of a pathfinder instance',
  fields: {
    id: {
      comment: 'The id of the pathfinder',
      modelName: 'pathfinder',
      type: 'id',
    },
    name: {
      comment: 'The name of the pathfinder instance',
      type: 'string',
    },
    url: {
      comment: 'The URL of the pathfinder service',
      type: 'string',
      optional: true,
    },
    createdAt: {
      comment: 'The date the pathfinder was created',
      type: 'Date',
    },
    updatedAt: {
      comment: 'The date the pathfinder was last updated',
      type: 'Date',
    },
    lastSeenAt: {
      comment: 'The date the pathfinder was last seen reporting a prompt run',
      type: 'Date',
      optional: true,
    },
  },
});

/** Override type */
export type PathfinderPreview = SchemaToType<typeof PathfinderPreview>;

export const Pathfinder = mkType({
  name: 'Pathfinder',
  comment:
    'A pathfinder service that has access to particular large language models',
  fields: {
    ...PathfinderPreview.fields,
    promptRunCount: {
      comment: 'The number of prompt runs that this pathfinder has reported',
      type: 'int',
    },
    applications: {
      comment: 'The applications that have access to this pathfinder instance',
      type: () => ApplicationPreview,
      list: true,
    },
    agents: {
      comment: 'The agents that have access to this pathfinder instance',
      type: () => AgentPreview,
      list: true,
    },
    pathfinderPolicies: {
      comment: 'The policies that are applied to this this pathfinder instance',
      type: () => PathfinderPolicyPreview,
      list: true,
    },
    largeLanguageModels: {
      comment:
        'The large language models that this pathfinder instance has access to',
      type: LargeLanguageModelPreview,
      list: true,
    },
  },
});

/** Override type */
export type Pathfinder = SchemaToType<typeof Pathfinder>;

export const PathfinderFiltersInput = mkInput({
  name: 'PathfinderFiltersInput',
  comment: 'Inputs for filtering a list of pathfinders',
  fields: {
    ids: {
      comment: 'The ids of the pathfinders',
      type: 'id',
      modelName: 'pathfinder',
      optional: true,
      list: true,
    },
    applicationIds: {
      comment:
        'The ids of the applications that have access to this pathfinder application',
      type: 'id',
      modelName: 'application',
      optional: true,
      list: true,
    },
    pathfinderPolicyIds: {
      comment:
        'The ids of the pathfinder policies used within this pathfinder application',
      type: 'id',
      modelName: 'pathfinderPolicy',
      optional: true,
      list: true,
    },
    largeLanguageModelIds: {
      comment:
        'The ids of the large language models that this pathfinder application has access to',
      type: 'id',
      modelName: 'largeLanguageModel',
      optional: true,
      list: true,
    },
    text: {
      comment: 'Filter by text (name, url)',
      optional: true,
      type: 'string',
    },
    names: {
      comment: 'Filter by name',
      optional: true,
      type: 'string',
      list: true,
    },
  },
});

/** Override type */
export type PathfinderFiltersInput = SchemaToType<
  typeof PathfinderFiltersInput
>;

export const DeletePathfindersInput = mkInput({
  name: 'DeletePathfindersInput',
  comment: 'Input for deleting a set of pathfinders',
  fields: {
    ids: {
      comment: 'The id of the pathfinders to delete',
      type: 'id',
      modelName: 'pathfinder',
      list: true,
    },
  },
});

/** Override type */
export type DeletePathfindersInput = SchemaToType<
  typeof DeletePathfindersInput
>;

export const CreatePathfinderInput = mkInput({
  name: 'CreatePathfinderInput',
  comment: 'Input for creating a pathfinder',
  fields: {
    name: {
      comment: 'The name of the pathfinder',
      type: 'string',
    },
    url: {
      comment: 'The URL of the pathfinder',
      type: 'string',
      optional: true,
    },
    applicationIds: {
      comment:
        'The IDs of the applications that interact with the pathfinder service',
      modelName: 'application',
      type: 'id',
      list: true,
      optional: true,
    },
    applicationNames: {
      comment:
        'The names of the applications that interact with the pathfinder service',
      type: 'string',
      optional: true,
      list: true,
    },
    pathfinderPolicyIds: {
      comment:
        'The IDs of the pathfinder policies that should be applied to this pathfinder instance',
      modelName: 'pathfinderPolicy',
      type: 'id',
      list: true,
      optional: true,
    },
    pathfinderPolicyNames: {
      comment:
        'The names of the pathfinder policies that should be applied to this pathfinder instance',
      type: 'string',
      list: true,
      optional: true,
    },
    largeLanguageModelIds: {
      comment:
        'The IDs of the large language models that this pathfinder instance has access to',
      modelName: 'largeLanguageModel',
      type: 'id',
      list: true,
      optional: true,
    },
    largeLanguageModelInputs: {
      comment:
        'The list of large language models to upsert for this pathfinder',
      type: LargeLanguageModelInput,
      list: true,
      optional: true,
    },
    agentIds: {
      comment:
        'The IDs of the agents that should be created for this pathfinder',
      modelName: 'agent',
      type: 'id',
      list: true,
      optional: true,
    },
    agentNames: {
      comment:
        'The names of the agents that should be created for this pathfinder',
      type: 'string',
      list: true,
      optional: true,
    },
    agentRemoteIds: {
      comment:
        'The remote IDs of the agents that should be created for this pathfinder',
      type: 'string',
      list: true,
      optional: true,
    },
  },
});

/** Override type */
export type CreatePathfinderInput = SchemaToType<typeof CreatePathfinderInput>;

export const UpdatePathfinderInput = mkInput({
  name: 'UpdatePathfinderInput',
  comment: 'Input for updating a pathfinder',
  fields: {
    id: {
      comment: 'The id of the pathfinder',
      modelName: 'pathfinder',
      type: 'id',
    },
    name: {
      comment: 'The new name of the pathfinder',
      type: 'string',
      optional: true,
    },
    url: CreatePathfinderInput.fields.url,
    applicationIds: CreatePathfinderInput.fields.applicationIds,
    applicationNames: CreatePathfinderInput.fields.applicationNames,
    pathfinderPolicyIds: CreatePathfinderInput.fields.pathfinderPolicyIds,
    pathfinderPolicyNames: CreatePathfinderInput.fields.pathfinderPolicyNames,
    largeLanguageModelIds: CreatePathfinderInput.fields.largeLanguageModelIds,
    largeLanguageModelInputs:
      CreatePathfinderInput.fields.largeLanguageModelInputs,
    agentIds: CreatePathfinderInput.fields.agentIds,
    agentNames: CreatePathfinderInput.fields.agentNames,
    agentRemoteIds: CreatePathfinderInput.fields.agentRemoteIds,
  },
});

/** Override type */
export type UpdatePathfinderInput = SchemaToType<typeof UpdatePathfinderInput>;

export const UpdatePathfindersInput = mkInput({
  name: 'UpdatePathfindersInput',
  comment: 'Input for bulk updating one or more pathfinders',
  fields: {
    pathfinders: {
      comment:
        'List of pathfinders with the properties that should be updated for each',
      type: UpdatePathfinderInput,
      list: true,
    },
  },
});

/** Override type */
export type UpdatePathfindersInput = SchemaToType<
  typeof UpdatePathfindersInput
>;

/** Order for a pathfinders query */
export const PathfinderOrder = mkOrder(Pathfinder.name, PathfinderOrderField);
