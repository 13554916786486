import { createRegexForTag } from '@main/utils';

const JSON_TAG_REGEX = createRegexForTag('json');

/**
 * Safely parse JSON from prompt output
 *
 * @param data - Raw prompt response
 * @returns Json parsed data or null
 */
export function parseJsonFromPromptOutput(data: string): unknown {
  try {
    return JSON.parse((JSON_TAG_REGEX.exec(data) || [])[1] || data);
  } catch (err) {
    return null;
  }
}
