import { DataFlowScope } from '@transcend-io/privacy-types';

import { DataFlowType } from '@main/cm-types/src/schema/enums';

import { parseDataFlowInput } from './parseDataFlow';
import type { DataFlowDescriptor, DataFlowUIHints } from './types';

export const getTypeFromDataFlows = (
  dataFlows: DataFlowDescriptor[],
): DataFlowType | undefined =>
  dataFlows
    .map(({ scope }): DataFlowType | undefined => {
      const urlScopes = [
        DataFlowScope.Host,
        DataFlowScope.Path,
        DataFlowScope.CSP,
      ];
      if (urlScopes.includes(scope as any)) {
        return DataFlowType.URL;
      }
      if (scope === DataFlowScope.QueryParam) {
        return DataFlowType.QueryParam;
      }
      if (scope === DataFlowScope.RegExp) {
        return DataFlowType.RegExp;
      }
      return undefined;
    })
    .find((type) => type);

/**
 * Get data flow modal UI hints from for a given input URL
 *
 * @param input - Entered URL
 * @returns Data flow UI hints
 */
export const getDataFlowUIHints = (input: string): DataFlowUIHints => {
  const dataFlows = parseDataFlowInput(input);
  const type = getTypeFromDataFlows(dataFlows);
  if (!type) {
    throw new Error('Unable to determine data flow type');
  }
  const scopes = new Set(dataFlows.map(({ scope }) => scope));
  const hints = { type, scopes };
  return hints;
};
