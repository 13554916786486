import { FilterConfigItem } from '@main/ad-core-components';
import {
  SelectAttribute,
  SelectAttributeDefaultOption,
} from '@main/admin-dashboard/src/DataMap/components';
import {
  AttributeValue,
  endpoints,
  MAX_ATTRIBUTE_KEYS,
} from '@main/attribute-types';
import { buildUseLazyQuery, buildUseQuery } from '@main/core-ui';
import { GqlObject, GraphQLResponse, ID } from '@main/schema-utils';
import {
  AttributeKeyType,
  AttributeSupportedResourceType,
} from '@transcend-io/privacy-types';
import differenceBy from 'lodash/differenceBy';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

/**
 * Filters definition with property for attribute values specified
 */
interface AttributeFilters extends Record<string, unknown> {
  /**
   * Attribute value ids that are currently filtering the table
   */
  attributeValueIds?: ID<'attributeValue'>[];
}

/**
 * Filters definition with property for attribute values specified
 */
type AttributeEnrichedFilters = Record<string, unknown> & {
  /**
   * Attribute values that are currently filtering the table
   */
  [k in ID<'attributeKey'>]: SelectAttributeDefaultOption[];
};

/**
 * Hook to get the filter configuration for attribute keys for some table
 *
 * @returns the attribute keys along with the config for the filter and a function to clear the filters
 */
type BuildAttributeFiltersResult<
  T extends AttributeFilters,
  E extends AttributeEnrichedFilters,
> = ({
  filters,
  setFilters,
}: {
  /** The currently applied filters */
  filters?: T;
  /** Callback when the filters are changed */
  setFilters: (filters: T) => void;
  /** The table to get attribute keys for, expressed as the enabledOn enum */
  enabledOn?: AttributeSupportedResourceType;
}) => {
  /**  The attribute keys enabled on this table */
  selectedAttributes: {
    [id: string]: SelectAttributeDefaultOption[];
  };
  /** The filter configuration to be passed to the filter manager */
  attributeFiltersConfig: FilterConfigItem<E>[];
  /** Callback for when the filter is cleared in the filter manager */
  clearAttributeFilters: (key: Extract<keyof E, string>) => void;
};

/**
 *
 * Builds elements of the filters config needed for filtering by attribute values
 *
 * @param operationName -
 * @param responseFields -
 * @returns Elements of the filters config needed for filtering by attribute values
 */
export function buildUseAttributeFilters<
  T extends AttributeFilters,
  E extends AttributeEnrichedFilters,
  TResult extends GraphQLResponse,
>(
  operationName?: string,
  responseFields?: GqlObject<TResult>,
): BuildAttributeFiltersResult<T, E> {
  const useAttributeKeys = buildUseQuery(
    endpoints.attributeKeys,
    operationName,
    responseFields,
  );

  const useLazyAttributeValues = buildUseLazyQuery(endpoints.attributeValues);

  return ({ filters, setFilters, enabledOn }) => {
    const { data } = useAttributeKeys({
      variables: {
        first: MAX_ATTRIBUTE_KEYS,
        filterBy: {
          ...(enabledOn ? { enabledOn: [enabledOn] } : {}),
        },
      },
    });

    const [selectedAttributes, setSelectedAttributes] = useState<{
      [id: string]: SelectAttributeDefaultOption[];
    }>({});
    const getSelectedAttributeValues = useLazyAttributeValues();

    // Populate initial complex filter values
    useEffect(() => {
      if (filters) {
        if (filters.attributeValueIds && filters.attributeValueIds.length > 0) {
          getSelectedAttributeValues({
            filterBy: { ids: filters.attributeValueIds },
          }).then(({ data }) => {
            const attributes = data.nodes.reduce<{
              [key: string]: AttributeValue[];
            }>(
              (attributes, value) => ({
                ...attributes,
                [value.attributeKey.id]: [
                  ...(attributes[value.attributeKey.id] || []),
                  value,
                ],
              }),
              {},
            );
            setSelectedAttributes(attributes);
          });
        }
      }
    }, []);

    const attributeFiltersConfig = useMemo(
      () =>
        (data?.nodes || [])
          .filter(
            ({ type }) =>
              type === AttributeKeyType.MultiSelect ||
              type === AttributeKeyType.SingleSelect,
          )
          .map(
            (key) =>
              ({
                filterKey: key.id,
                label: key.name,
                pillOptions: {
                  ignoreAccessibilityAndUseLightText: true,
                  color: ({ filterValues = {}, index = 0 }) =>
                    // awful casting required because TS can't use ID to index filters' signature
                    (filterValues as Record<string, AttributeValue[]>)[
                      key.id
                    ]?.[index].color || 'transcendNavy2',
                  label: ({ filterValues, index = 0 }) =>
                    // awful casting required because TS can't use ID to index filters' signature
                    (filterValues as Record<string, AttributeValue[]>)[
                      key.id
                    ]?.[index].name,
                },
                filter: (
                  <SelectAttribute
                    isMulti
                    attributeKey={key}
                    menuPosition="absolute"
                    values={selectedAttributes[key.id] || []}
                    onUpdate={(attributes, previousAttributes) => {
                      setSelectedAttributes((selectedAttributes) => ({
                        ...selectedAttributes,
                        [key.id]: attributes,
                      }));

                      const attributesForOtherKeys = differenceBy(
                        filters?.attributeValueIds || [],
                        (previousAttributes || []).map(({ id }) => id),
                      );

                      setFilters({
                        ...filters,
                        attributeValueIds: [
                          ...attributesForOtherKeys,
                          ...attributes.map(({ id }) => id),
                        ],
                      } as any); // TS doesn't trust that filters is assignable to setFilters because the type is too broad
                    }}
                  />
                ),
              }) as FilterConfigItem<{
                [k in ID<'attributeKey'>]: SelectAttributeDefaultOption[];
              }>, // need cast for typing because it maps as literal
          ),
      [data?.nodes, filters, selectedAttributes, setFilters],
    );

    const clearAttributeFilters = useCallback(
      (key) => {
        if (Object.prototype.hasOwnProperty.call(selectedAttributes, key)) {
          setSelectedAttributes((attributes) => {
            const newAttributes = { ...attributes };
            delete newAttributes[key];
            return newAttributes;
          });

          const attributesForOtherKeys = differenceBy(
            filters?.attributeValueIds || [],
            (selectedAttributes[key] || []).map(({ id }) => id),
          );

          setFilters({
            ...filters,
            attributeValueIds: attributesForOtherKeys,
          } as any); // TS doesn't trust that filters is assignable to setFilters because the type is too broad
        }
      },
      [selectedAttributes, filters],
    );

    return {
      selectedAttributes,
      attributeFiltersConfig,
      clearAttributeFilters,
    };
  };
}
