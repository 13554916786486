import * as t from 'io-ts';

import { dbModelId } from '@main/schema-utils';

import { AuditEventCode } from '../schema/enums';
import {
  mkAuditEventCodec,
  mkAuditEventJoinCodec,
  mkAuditJoinStateCodec,
} from '../types';

export const AuditEventAssessmentForm = mkAuditEventCodec(
  AuditEventCode.AssessmentForm,
  t.intersection([
    t.type({
      /** ID of the assessment form */
      assessmentFormId: dbModelId('assessmentForm'),
    }),
    t.partial({
      /** ID of the assessment form template that owns the form */
      assessmentFormTemplateId: dbModelId('assessmentFormTemplate'),
    }),
    t.partial({
      /** ID of the assessment group template that owns the group */
      assessmentGroupId: dbModelId('assessmentGroup'),
    }),
  ]),
);

/** Override types. */
export type AuditEventAssessmentForm = t.TypeOf<
  typeof AuditEventAssessmentForm
>;

export const AuditEventAssessmentFormReviewer = mkAuditEventJoinCodec(
  AuditEventCode.AssessmentFormReviewer,
  mkAuditJoinStateCodec('user'),
  t.intersection([
    t.type({
      /** ID of the assessment form */
      assessmentFormId: dbModelId('assessmentForm'),
    }),
    /** ID of the assessment form template */
    t.partial({
      /** ID of the assessment form template that owns the form */
      assessmentFormTemplateId: dbModelId('assessmentFormTemplate'),
    }),
    t.partial({
      /** ID of the assessment group template that owns the group */
      assessmentGroupId: dbModelId('assessmentGroup'),
    }),
  ]),
);

/** Override types. */
export type AuditEventAssessmentFormReviewer = t.TypeOf<
  typeof AuditEventAssessmentFormReviewer
>;

export const AuditEventAssessmentFormAssignee = mkAuditEventJoinCodec(
  AuditEventCode.AssessmentFormAssignee,
  mkAuditJoinStateCodec('user'),
  t.intersection([
    t.type({
      /** ID of the assessment form */
      assessmentFormId: dbModelId('assessmentForm'),
    }),
    /** ID of the assessment form template */
    t.partial({
      /** ID of the assessment form template that owns the form */
      assessmentFormTemplateId: dbModelId('assessmentFormTemplate'),
    }),
    t.partial({
      /** ID of the assessment group template that owns the group */
      assessmentGroupId: dbModelId('assessmentGroup'),
    }),
  ]),
);

/** Override types. */
export type AuditEventAssessmentFormAssignee = t.TypeOf<
  typeof AuditEventAssessmentFormAssignee
>;

export const AuditEventAssessmentFormComment = mkAuditEventCodec(
  AuditEventCode.AssessmentFormComment,
  t.intersection([
    t.type({
      /** ID of the assessment form comment */
      assessmentFormCommentId: dbModelId('assessmentFormComment'),
      /** ID of the assessment form */
      assessmentFormId: dbModelId('assessmentForm'),
    }),
    t.partial({
      /** ID of the assessment form template that owns the form */
      assessmentFormTemplateId: dbModelId('assessmentFormTemplate'),
    }),
    t.partial({
      /** ID of the assessment group template that owns the group */
      assessmentGroupId: dbModelId('assessmentGroup'),
    }),
  ]),
);

/** Override types. */
export type AuditEventAssessmentFormComment = t.TypeOf<
  typeof AuditEventAssessmentFormComment
>;
