import {
  Breadcrumb,
  BreadcrumbItem,
  PageTitleProps,
  RedirectToLogin,
} from '@main/ad-core-components';
import { selectUser } from '@main/admin-dashboard/src/Auth/App/selectors';
import ErrorBoundary from '@main/admin-dashboard/src/Auth/ErrorBoundary';
import {
  FlexRowCenterHorizontal,
  GenericFormattedMessage,
  LoadingSpinner,
  useFormatMessageGeneric,
} from '@main/core-ui';
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { MessageDescriptor, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { selectDocumentTitle, selectIsLoadingUser } from '../selectors';
import { PageBreadcrumbsContext } from './BreadcrumbProvider';
import { organizationPageMessages } from './messages';
import { useNavGateAutoRedirect } from './NavMenu/hooks';
import { Sidebar } from './Sidebar';
import {
  ContentBody,
  ContentWrapper,
  ContentWrapperInner,
  PageBreadcrumbWrapper,
  PageContainerWrapper,
  PageWrapper,
} from './wrappers';

export interface OrganizationPageProps extends Omit<PageTitleProps, 'title'> {
  /** The title of the page */
  title?: MessageDescriptor;
  /** Whether we should hide the inner padding in content area (usually for onboarding) */
  hideInnerPadding?: boolean;
  /** Navigational breadcrumbs for this page and its parents */
  breadcrumbs?: readonly BreadcrumbItem[];
}

/**
 * OrganizationPage
 */
export const OrganizationPage: React.FC<OrganizationPageProps> = ({
  children,
  title: staticTitle,
  hideInnerPadding = false,
  breadcrumbs: staticBreadcrumbs,
}) => {
  const user = useSelector(selectUser);
  const isLoadingUser = useSelector(selectIsLoadingUser);
  const dynamicTitle = useSelector(selectDocumentTitle);

  const { formatMessage } = useIntl();
  const { formatMessageGeneric } = useFormatMessageGeneric();

  // auto redirect to cta pages if the current page is gated
  useNavGateAutoRedirect();

  const [breadcrumbs, setBreadcrumbs] = React.useState(
    staticBreadcrumbs ?? null,
  );
  const title = dynamicTitle || staticTitle;

  useEffect(() => {
    setBreadcrumbs(staticBreadcrumbs ?? null);
  }, [staticBreadcrumbs]);

  if (!user) {
    if (isLoadingUser) {
      return (
        <FlexRowCenterHorizontal>
          <LoadingSpinner />
        </FlexRowCenterHorizontal>
      );
    }
    return <RedirectToLogin />;
  }

  return (
    <PageContainerWrapper>
      <PageWrapper>
        <Sidebar />
        <ContentWrapper id="content-wrapper">
          <ContentWrapperInner hidePadding={hideInnerPadding}>
            <PageBreadcrumbsContext.Provider
              value={{
                breadcrumbs,
                setBreadcrumbs,
              }}
            >
              <main>
                {/* page headers are a requirement of accessibility */}
                {title && (
                  <>
                    <Helmet>
                      <title>{`${formatMessageGeneric(title)}${
                        title !== 'Transcend' ? ' | Transcend' : ''
                      }`}</title>
                    </Helmet>

                    <h1 className="visually-hidden">
                      <GenericFormattedMessage message={title} />
                    </h1>
                  </>
                )}

                {breadcrumbs && breadcrumbs.length > 0 && (
                  <PageBreadcrumbWrapper>
                    <Breadcrumb
                      aria-label={formatMessage(
                        organizationPageMessages.breadcrumbsAriaLabel,
                      )}
                      items={breadcrumbs}
                    />
                  </PageBreadcrumbWrapper>
                )}

                <ContentBody>
                  <ErrorBoundary>{children}</ErrorBoundary>
                </ContentBody>
              </main>
            </PageBreadcrumbsContext.Provider>
          </ContentWrapperInner>
        </ContentWrapper>
      </PageWrapper>
    </PageContainerWrapper>
  );
};
