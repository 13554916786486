import * as t from 'io-ts';

import { dbModelId } from '@main/schema-utils';

import { AuditEventCode } from '../schema/enums';
import { mkAuditEventCodec } from '../types';

export const AuditEventAssessmentFormTemplate = mkAuditEventCodec(
  AuditEventCode.AssessmentFormTemplate,
  t.type({
    /** ID of the assessment form template */
    assessmentFormTemplateId: dbModelId('assessmentFormTemplate'),
  }),
);

/** Override types. */
export type AuditEventAssessmentFormTemplate = t.TypeOf<
  typeof AuditEventAssessmentFormTemplate
>;
