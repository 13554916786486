import {
  ACTION_ITEM_CONFIG_MAPPING,
  actionItemFilterMessages,
} from '@main/access-control-types';
import {
  Badge,
  FlexColumn,
  FlexRowCenterVertical,
  Icon,
  Popover,
  StyleUtils,
} from '@main/core-ui';
import { ActionItemCode } from '@transcend-io/privacy-types';
import React, { ReactElement } from 'react';
import { useIntl } from 'react-intl';
import { useTheme } from 'styled-components';

import {
  actionItemProductAreaLabels,
  actionItemsSubscriptionsMessages,
} from '../ActionItemSubscriptionsButton/messages';
import { ActionItemPriorityLabel } from '../components/ActionItemPriorityLabel';
import { ACTION_ITEM_FRONTEND_MAPPING } from '../constants';
import { PRODUCT_AREA_COLORS } from './constants';
import { getActionItemExampleMessage } from './helpers';

interface ActionItemCodeInfoPopoverProps {
  /** the action item code */
  code: ActionItemCode;
  /** a custom icon other than the info icon */
  icon?: ReactElement;
}

/**
 * ActionItemCodeInfoPopover
 */
export const ActionItemCodeInfoPopover: React.FC<
  ActionItemCodeInfoPopoverProps
> = ({ code, icon }) => {
  const { formatMessage } = useIntl();
  const theme = useTheme();

  return (
    <Popover
      trigger={['hover', 'focus']}
      placement="auto"
      width="500px"
      contents={
        <FlexColumn
          style={{
            gap: StyleUtils.Spacing.sm,
            padding: StyleUtils.Spacing.sm,
          }}
        >
          <div
            style={{
              fontWeight: 600,
              color: theme.colors.transcendNavy,
            }}
          >
            {formatMessage(actionItemFilterMessages[code])}
          </div>
          <FlexRowCenterVertical
            style={{
              gap: StyleUtils.Spacing.sm,
            }}
          >
            {ACTION_ITEM_CONFIG_MAPPING[code].productAreas.map(
              (supportedArea) => (
                <Badge
                  key={supportedArea}
                  color={PRODUCT_AREA_COLORS[supportedArea]}
                >
                  {formatMessage(actionItemProductAreaLabels[supportedArea])}
                </Badge>
              ),
            )}
          </FlexRowCenterVertical>
          <FlexRowCenterVertical style={{ gap: StyleUtils.Spacing.sm }}>
            <span>
              {formatMessage(actionItemsSubscriptionsMessages.defaultPriority)}
            </span>
            {ACTION_ITEM_CONFIG_MAPPING[code].defaultPriority ? (
              <ActionItemPriorityLabel
                priority={ACTION_ITEM_CONFIG_MAPPING[code].defaultPriority!}
              />
            ) : (
              <i>{formatMessage(actionItemsSubscriptionsMessages.none)}</i>
            )}
          </FlexRowCenterVertical>
          <div>
            {formatMessage(
              ACTION_ITEM_FRONTEND_MAPPING[code].descriptionMessage,
            )}
          </div>
          <div>{formatMessage(actionItemsSubscriptionsMessages.example)}</div>
          <i>{getActionItemExampleMessage(formatMessage, code)}</i>
        </FlexColumn>
      }
    >
      {icon ?? (
        <FlexRowCenterVertical style={{ display: 'inline-flex' }}>
          <Icon type="i-circle" />
        </FlexRowCenterVertical>
      )}
    </Popover>
  );
};
