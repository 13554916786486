import { Icon, IWithClassName, Skeleton } from '@main/core-ui';
import React, { useContext, useEffect } from 'react';
import { AccordionContext } from 'react-bootstrap';
import { AccordionEventKey } from 'react-bootstrap/esm/AccordionContext';
import { useTheme } from 'styled-components';

import {
  Accordion as BootstrapAccordion,
  AccordionProps as BootstrapAccordionProps,
} from './BootstrapAccordion';
import { StyledAccordionHeader, StyledAccordionItem } from './wrappers';

/**
 * Props for Collapse
 */
export interface AccordionProps
  extends BootstrapAccordionProps,
    IWithClassName {
  /** Whether to show a border around the accordion items */
  bordered?: boolean | 'borderless';
  /** The panels to display */
  items: {
    /** The key of the item to render it in an array */
    key: string;
    /** The content for the clickable header */
    header: React.ReactNode;
    /** The content for the expandable body */
    body: React.ReactNode;
  }[];
  /** Indicates contents are loading, when panels are empty a skeleton is rendered */
  loading?: boolean;
  /** Whether the component should be destroyed when collapsed */
  destroyOnCollapse?: boolean;
  /** Whether to remove the left and right padding on the accordion headers and bodies */
  noHorizontalPadding?: boolean;
  /** Whether to hide the bottom border. This is mostly useful for single-item accordions, in combination with `flush` and `noPadding` */
  noBottomBorderWhenExpanded?: boolean;
  /** Whether to show the header icon on the right (defaults to left) */
  headerIconOnRight?: boolean;
}

const isActiveKey = (
  activeEventKey: AccordionEventKey,
  itemKey: string,
): boolean =>
  Array.isArray(activeEventKey)
    ? activeEventKey.includes(itemKey)
    : activeEventKey === itemKey;

const AccordionHeader: React.FC<
  Pick<AccordionProps, 'noBottomBorderWhenExpanded' | 'headerIconOnRight'> & {
    /** The item to display */
    item: AccordionProps['items'][0];
  }
> = ({ item, noBottomBorderWhenExpanded, headerIconOnRight }) => {
  const theme = useTheme();
  const { activeEventKey } = useContext(AccordionContext);
  const isActive = isActiveKey(activeEventKey, item.key);
  return (
    <StyledAccordionHeader
      forwardedAs="header"
      $noBottomBorderWhenExpanded={noBottomBorderWhenExpanded}
      $headerIconOnRight={headerIconOnRight}
    >
      <Icon
        type={isActive ? 'caret-down' : 'caret-right'}
        color={theme.colors.transcendNavy3}
        size="1.2em"
      />

      {item.header}
    </StyledAccordionHeader>
  );
};

const AccordionBody: React.FC<
  Pick<AccordionProps, 'destroyOnCollapse'> & {
    /** The item to display */
    item: AccordionProps['items'][0];
  }
> = ({ item, destroyOnCollapse }) => {
  const { activeEventKey } = useContext(AccordionContext);

  const [rendered, setRendered] = React.useState(false);

  const isActive = isActiveKey(activeEventKey, item.key);

  // Lazy render the body when the item is first expanded, then keep it rendered
  // even when closed, unless destroyOnCollapse is true,
  useEffect(() => {
    if (isActive) {
      setRendered(true);
    }
    return () => {
      if (destroyOnCollapse) {
        setRendered(false);
      }
    };
  }, [destroyOnCollapse, isActive]);

  return rendered || isActive ? (
    <BootstrapAccordion.Body>{item.body}</BootstrapAccordion.Body>
  ) : (
    <></>
  );
};

/**
 * A collapsible list
 */
export const Accordion: React.FC<AccordionProps> = ({
  bordered,
  items,
  noHorizontalPadding,
  loading,
  destroyOnCollapse,
  noBottomBorderWhenExpanded,
  ...props
}) => (
  <BootstrapAccordion defaultActiveKey="0" alwaysOpen {...props}>
    {items.map((item) => (
      <StyledAccordionItem
        eventKey={item.key}
        key={item.key}
        $noHorizontalPadding={noHorizontalPadding}
        bordered={bordered?.toString()}
      >
        <AccordionHeader
          item={item}
          noBottomBorderWhenExpanded={noBottomBorderWhenExpanded}
          headerIconOnRight={props.headerIconOnRight}
        />
        <AccordionBody item={item} destroyOnCollapse={destroyOnCollapse} />
      </StyledAccordionItem>
    ))}
    {items.length === 0 && loading && (
      <React.Fragment>
        <Skeleton />
        <Skeleton />
        <Skeleton />
      </React.Fragment>
    )}
  </BootstrapAccordion>
);
