import * as t from 'io-ts';

import { dbModelId } from '@main/schema-utils';

import { AuditEventCode } from '../schema/enums';
import { mkAuditEventCodec } from '../types';

export const AuditEventAirgapTheme = mkAuditEventCodec(
  AuditEventCode.AirgapTheme,
  t.type({
    /** ID of the airgap theme */
    airgapThemeId: dbModelId('airgapTheme'),
    /** ID of the (parent) airgap bundle */
    airgapBundleId: dbModelId('airgapBundle'),
  }),
);

/** Override types. */
export type AuditEventAirgapTheme = t.TypeOf<typeof AuditEventAirgapTheme>;
