import * as t from 'io-ts';

import { dbModelId } from '@main/schema-utils';

import { AuditEventCode } from '../schema/enums';
import { mkAuditEventCodec } from '../types';

export const AuditEventPolicy = mkAuditEventCodec(
  AuditEventCode.Policy,
  t.type({
    /** ID of the policy */
    policyId: dbModelId('policy'),
    /** ID of the privacy center */
    privacyCenterId: dbModelId('privacyCenter'),
  }),
);

/** Override types. */
export type AuditEventPolicy = t.TypeOf<typeof AuditEventPolicy>;

export const AuditEventPolicyVersion = mkAuditEventCodec(
  AuditEventCode.PolicyVersion,
  t.type({
    /** ID of the policy */
    policyId: dbModelId('policy'),
    /** ID of the privacy center */
    privacyCenterId: dbModelId('privacyCenter'),
  }),
);

/** Override types. */
export type AuditEventPolicyVersion = t.TypeOf<typeof AuditEventPolicyVersion>;
