import { defineMessages } from '@transcend-io/internationalization';

export const infrastructurePageTitleMessages = defineMessages(
  'admin-dashboard.Infrastructure.infrastructurePageTitle',
  {
    infrastructure: {
      defaultMessage: 'Infrastructure',
    },
    sombra: {
      defaultMessage: 'Sombra',
    },
    emailDomains: {
      defaultMessage: 'Email Domains',
    },
    apiKeys: {
      defaultMessage: 'API Keys',
    },
    integrations: {
      defaultMessage: 'Integrations',
    },
    connectNewService: {
      defaultMessage: 'Connect Integrations',
    },
    attributeManagement: {
      defaultMessage: 'Custom Fields',
    },
    createNewAttribute: {
      defaultMessage: 'Create New Custom Field',
    },
  },
);
