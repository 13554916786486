import * as t from 'io-ts';

import { decodeCodec } from '@transcend-io/type-utils';

/**
 * Use local storage for messaging. Set message in local storage and clear it right away
 * This is a safe way how to communicate with other tabs while not leaving any traces
 *
 * @param messageName - The name of the message
 * @param message - The message to broadcast
 */
export function tabMessageBroadcast<T extends object>(
  messageName: string,
  message: T,
): void {
  localStorage.setItem(messageName, JSON.stringify(message));
  localStorage.removeItem(messageName);
}

/**
 * Receive message from another tab
 *
 * @param messageName - The message name to receive
 * @param ev - The local storage event to listen on
 * @param codec - The codec for validating the message shape
 * @returns The message or undefined if no message sent
 */
export function tabMessageReceive<TCodec extends t.Any>(
  messageName: string,
  ev: StorageEvent,
  codec: TCodec,
): t.TypeOf<TCodec> | undefined {
  // ignore other keys
  if (ev.key !== messageName || !ev.newValue) {
    return undefined;
  }

  // ignore empty msg or msg reset
  const message = JSON.parse(ev.newValue);
  if (!message) {
    return undefined;
  }

  // Validate message shape
  return decodeCodec(codec, message);
}
