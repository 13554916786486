import { mkInput, mkType, SchemaToType } from '@main/schema-utils';

import { TrackingPurpose } from './purpose';

export const TCFSpecialFeature = mkType({
  name: 'TCFSpecialFeature',
  comment: 'Transparency & Consent Framework (TCF) Special Feature',
  fields: {
    id: {
      comment: 'The ID of the special feature as used by TCF',
      type: 'int',
    },
    name: {
      comment:
        'The name of the special feature, e.g. "Use precise geolocation data"',
      type: 'string',
    },
    description: {
      comment: 'The description of the special feature',
      type: 'string',
    },
    illustrations: {
      comment: 'Real-world examples of the special feature',
      type: 'string',
      list: true,
    },
  },
});

/** Override type */
export type TCFSpecialFeature = SchemaToType<typeof TCFSpecialFeature>;

export const TCFSpecialFeatureTrackingPurpose = mkType({
  name: 'TCFSpecialFeatureTrackingPurpose',
  comment: 'TCF Special Feature for a particular consent bundle',
  fields: {
    id: {
      comment: 'The ID of the special feature as used by TCF',
      type: 'id',
      modelName: 'orgTcfSpecialFeature',
    },
    tcfSpecialFeature: {
      type: TCFSpecialFeature,
      comment: 'The TCF Special Feature',
    },
    isApplicable: {
      type: 'boolean',
      comment: 'Whether this feature is applicable to the organization',
    },
    vendorsDeclaring: {
      type: 'int',
      comment: 'total number of vendors declaring this feature',
    },
    purposes: {
      comment:
        'A list of tracking purposes associated with this special feature',
      type: TrackingPurpose,
      list: true,
    },
  },
});

/** Override type */
export type TCFSpecialFeatureTrackingPurpose = SchemaToType<
  typeof TCFSpecialFeatureTrackingPurpose
>;

export const UpdateTCFSpecialFeatureTrackingPurposesInput = mkInput({
  name: 'UpdateTCFSpecialFeatureTrackingPurposesInput',
  comment:
    'Input used to update the mapping from TCF Special Features to Airgap tracking purposes',
  fields: {
    tcfSpecialFeatureId: {
      comment: 'The TCF Special Feature',
      type: 'id',
      modelName: 'tcfSpecialFeature',
    },
    trackingPurposeIds: {
      comment:
        'The Airgap tracking purposes associated with this TCF special feature',
      type: 'id',
      modelName: 'purpose',
      list: true,
      optional: true,
    },
    isApplicable: {
      comment:
        'Whether or not this TCF special feature is applicable for the organization',
      type: 'boolean',
      optional: true,
    },
  },
});

/** Override type */
export type UpdateTCFSpecialFeatureTrackingPurposesInput = SchemaToType<
  typeof UpdateTCFSpecialFeatureTrackingPurposesInput
>;

export const OrgTcfSpecialFeatureFilterInput = mkInput({
  name: 'OrgTcfSpecialFeatureFilterInput',
  comment: 'Inputs to filter the Org TCF Special Features endpoint by',
  fields: {
    text: {
      comment:
        'Filters the special features by whether the substring is present in the name',
      type: 'string',
      optional: true,
    },
    tcfSpecialFeatureIds: {
      comment: 'Filters the special features by the TCF Special Feature IDs',
      type: 'id',
      modelName: 'tcfSpecialFeature',
      list: true,
      optional: true,
    },
  },
});

/** Override type */
export type OrgTcfSpecialFeatureFilterInput = SchemaToType<
  typeof OrgTcfSpecialFeatureFilterInput
>;
