import { FilterConfigItem } from '@main/ad-core-components';
import {
  SelectAgents,
  SelectedAgent,
} from '@main/admin-dashboard/src/Pathfinder/components/SelectAgent';
import { buildUseLazyQuery } from '@main/core-ui';
import { endpoints } from '@main/prompt-types';
import { ID } from '@main/schema-utils';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { MessageDescriptor } from 'react-intl';

import { filterManagerHookMessages } from './messages';

/**
 * Filters definition with property for agent values specified
 */
type AgentEnrichedFilters = Record<string, unknown> &
  Record<
    /**
     * Agent values that are currently filtering the table
     */
    string,
    SelectedAgent[] | undefined
  >;

const useAgents = buildUseLazyQuery(endpoints.agents);

export const useAgentFilters = <
  T extends Record<string, unknown>,
  E extends AgentEnrichedFilters,
>({
  agentIdsFilterKey = 'agentIds',
  filters,
  setFilters,
  label,
  enrichedAgentKey = 'agents',
}: {
  /** The property name of the filter for agent ids */
  agentIdsFilterKey?: keyof T;
  /** The currently applied filters */
  filters?: T;
  /** Callback when the filters are changed */
  setFilters: (filters: T) => void;
  /** Label for the filter menu */
  label: string | MessageDescriptor;
  /** The key to use if more than one useAgentFilters is used in a FilterManager */
  enrichedAgentKey?: keyof E;
}): {
  /**  The agent keys enabled on this table */
  selectedAgents: SelectedAgent[];
  /** The filter configuration to be passed to the filter manager */
  agentFiltersConfig: FilterConfigItem<E>;
  /** Callback for when the filter is cleared in the filter manager */
  clearAgentFilters: (key: Extract<keyof E, string>) => void;
} => {
  const getSelectedAgents = useAgents();
  const [selectedAgents, setSelectedAgents] = useState<SelectedAgent[]>([]);
  const [fetchingAgents, setFetchingAgents] = useState(false);

  // Populate initial complex filter values
  useEffect(() => {
    if (filters) {
      const agentIds = (filters[agentIdsFilterKey] ?? []) as ID<'agent'>[];

      const filtersAndEnrichedFiltersMatch =
        agentIds.sort().join() ===
        selectedAgents
          .map(({ id }) => id)
          .sort()
          .join();

      if (agentIds.length === 0 && selectedAgents.length > 0) {
        setSelectedAgents([]);
      } else if (!filtersAndEnrichedFiltersMatch && !fetchingAgents) {
        // Prevent over-firing this setter while data is still being fetched
        setFetchingAgents(true);
        getSelectedAgents({
          filterBy: { ids: agentIds },
        })
          .then(({ data }) => {
            setSelectedAgents(data.nodes);
          })
          .finally(() => {
            setFetchingAgents(false);
          });
      }
    }
  }, [
    filters,
    fetchingAgents,
    getSelectedAgents,
    selectedAgents.length,
    agentIdsFilterKey,
    selectedAgents,
  ]);

  const agentFiltersConfig = useMemo(
    () =>
      ({
        filterKey: enrichedAgentKey,
        label,
        pillOptions: {
          label: ({
            filterValues: { [enrichedAgentKey]: agents = [] },
            index = 0,
          }) => {
            const model = agents[index];
            return model?.name;
          },
        },
        filter: (
          <SelectAgents
            isMulti
            placeholderDescriptor={filterManagerHookMessages.agentsPlaceholder}
            menuPosition="absolute"
            value={selectedAgents}
            showOutline={false}
            onChange={(agents) => {
              setSelectedAgents([...agents]);
              setFilters({
                ...filters,
                [agentIdsFilterKey]: agents.map(({ id }) => id),
              } as T);
            }}
          />
        ),
      }) as FilterConfigItem<AgentEnrichedFilters>,
    [enrichedAgentKey, label, selectedAgents, filters, agentIdsFilterKey],
  );

  const clearAgentFilters = useCallback(
    (key) => {
      if (key === enrichedAgentKey) {
        setFilters({
          ...filters,
          [agentIdsFilterKey]: [],
        } as T);
        setSelectedAgents([]);
      }
    },
    [enrichedAgentKey, filters, agentIdsFilterKey],
  );

  return {
    selectedAgents,
    agentFiltersConfig,
    clearAgentFilters,
  };
};
