import styled from 'styled-components';

import { StyledButton } from '../Button/wrappers';

export const StyledBreadcrumbNav = styled.nav`
  ol {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  li:first-child {
    ${StyledButton} {
      padding-left: 0;
    }
  }

  li:last-child {
    ${StyledButton} {
      padding-right: 0;
    }
  }

  ${StyledButton} {
    line-height: 1;
  }
`;
