import { makeEnum } from '@transcend-io/type-utils';

/** Same Site for the browser */
export const SameSite = makeEnum({
  /** Strict Same Site for the browser */
  Strict: 'Strict',
  /** Lax Same Site for the browser */
  Lax: 'Lax',
  /** None Same Site for the browser */
  None: 'None',
});

/** Override type */
export type SameSite = (typeof SameSite)[keyof typeof SameSite];
