import { Tooltip } from '@main/core-ui';
import React from 'react';
import Moment from 'react-moment';

export interface FromNowProps {
  /** date used to derive "X days" from now text */
  date: Date;
}

export const FromNow: React.FC<FromNowProps> = ({ date }) => (
  <Tooltip title={date.toLocaleString()}>
    <Moment fromNow>{date.toISOString()}</Moment>
  </Tooltip>
);
