import styled from 'styled-components';

import { FormCheckboxGroup, Modal } from '@main/ad-core-components';
import { CheckboxGroupWrapper } from '@main/ad-core-components/src/CheckboxGroup/wrapper';
import { ModalHeader, StyleUtils } from '@main/core-ui';

export const StyledFormCheckboxGroup = styled(FormCheckboxGroup)`
  ${CheckboxGroupWrapper} {
    column-count: 2;
  }
`;

export const StyledModal = styled(Modal)`
  ${({ centerHeader }) =>
    centerHeader
      ? `
          ${ModalHeader} {
            margin-bottom: ${StyleUtils.Spacing.md}
          }
        `
      : ''}
`;
