import { defineMessages } from '@transcend-io/internationalization';
import { CodePackageType } from '@transcend-io/privacy-types';

export const selectCodePackageTypeMessages = defineMessages(
  'admin-dashboard.CodeScanning.components.SelectCodePackageType.selectCodePackageType',
  {
    label: { defaultMessage: 'Package type' },
  },
);

export const codePackageTypeMessages = defineMessages<CodePackageType>(
  'admin-dashboard.CodeScanning.components.SelectCodePackageType.codePackageType',
  {
    [CodePackageType.PackageJson]: {
      defaultMessage: 'Package JSON',
    },
    [CodePackageType.RequirementsTxt]: {
      defaultMessage: 'Requirements.txt',
    },
    [CodePackageType.Gradle]: {
      defaultMessage: 'Gradle',
    },
    [CodePackageType.CocoaPods]: {
      defaultMessage: 'CocoaPods',
    },
    [CodePackageType.Pubspec]: {
      defaultMessage: 'Pubspec',
    },
    [CodePackageType.Gemfile]: {
      defaultMessage: 'Gemfile',
    },
    [CodePackageType.ComposerJson]: {
      defaultMessage: 'Composer JSON',
    },
  },
);
