import { AgentPreview } from '@main/attribute-types';
import { mkInput, mkOrder, mkType, SchemaToType } from '@main/schema-utils';

import { AgentFunctionOrderField } from './enums';

export const AgentFunctionPreview = mkType({
  name: 'AgentFunctionPreview',
  comment: 'A preview of an agent function instance',
  fields: {
    id: {
      comment: 'The id of the agent function',
      modelName: 'agentFunction',
      type: 'id',
    },
    name: {
      comment: 'The name of the agent function',
      type: 'string',
    },
    createdAt: {
      comment: 'The date the agent function was created',
      type: 'Date',
    },
    updatedAt: {
      comment: 'The date the agent function was last updated',
      type: 'Date',
    },
  },
});

/** Override type */
export type AgentFunctionPreview = SchemaToType<typeof AgentFunctionPreview>;

export const AgentFunction = mkType({
  name: 'AgentFunction',
  comment: 'A legal agentFunction',
  fields: {
    ...AgentFunctionPreview.fields,
    description: {
      comment: 'The description of the agent function',
      type: 'string',
    },
    parameters: {
      comment: 'The JSON scheme definition for the function parameters',
      type: 'string',
    },
    agents: {
      comment: 'The list of agents that have access to this function',
      type: () => AgentPreview,
      list: true,
    },
  },
});

/** Override type */
export type AgentFunction = SchemaToType<typeof AgentFunction>;

export const AgentFunctionFiltersInput = mkInput({
  name: 'AgentFunctionFiltersInput',
  comment: 'Inputs for filtering a list of agent functions',
  fields: {
    ids: {
      comment: 'The ids of the agent functions',
      type: 'id',
      modelName: 'agentFunction',
      optional: true,
      list: true,
    },
    agentIds: {
      comment: 'The ids of the agents that have access to the function',
      type: 'id',
      modelName: 'agent',
      optional: true,
      list: true,
    },
    text: {
      comment: 'Filter by text (name, description of the agent function)',
      optional: true,
      type: 'string',
    },
    name: {
      comment: 'Filter by name',
      optional: true,
      type: 'string',
    },
  },
});

/** Override type */
export type AgentFunctionFiltersInput = SchemaToType<
  typeof AgentFunctionFiltersInput
>;

export const DeleteAgentFunctionsInput = mkInput({
  name: 'DeleteAgentFunctionsInput',
  comment: 'Input for deleting a group of agent functions',
  fields: {
    ids: {
      comment: 'The id of the agent functions to delete',
      type: 'id',
      modelName: 'agentFunction',
      list: true,
    },
  },
});

/** Override type */
export type DeleteAgentFunctionsInput = SchemaToType<
  typeof DeleteAgentFunctionsInput
>;

export const AgentFunctionInput = mkInput({
  name: 'AgentFunctionInput',
  comment: 'Input for creating an agent function',
  fields: {
    name: {
      comment: 'The name of the agent function',
      type: 'string',
    },
    description: {
      comment: 'The description of the agent function',
      type: 'string',
      optional: true,
    },
    parameters: {
      comment: 'The JSON schema definition of the function parameters',
      type: 'string',
    },
  },
});

/** Override type */
export type AgentFunctionInput = SchemaToType<typeof AgentFunctionInput>;

export const CreateAgentFunctionInput = mkInput({
  name: 'CreateAgentFunctionInput',
  comment: 'Input for creating an agent function',
  fields: {
    name: {
      comment: 'The name of the agent function',
      type: 'string',
    },
    description: {
      comment: 'The description of the agent function',
      type: 'string',
      optional: true,
    },
    parameters: {
      comment: 'The JSON schema definition of the function parameters',
      type: 'string',
    },
    agentIds: {
      comment: 'The ids of the agents that have access to the function',
      type: 'id',
      modelName: 'agent',
      optional: true,
      list: true,
    },
    agentNames: {
      comment: 'The names of the agents that have access to the function',
      type: 'string',
      optional: true,
      list: true,
    },
    agentRemoteIds: {
      comment: 'The remote ids of the agents that have access to the function',
      type: 'string',
      optional: true,
      list: true,
    },
  },
});

/** Override type */
export type CreateAgentFunctionInput = SchemaToType<
  typeof CreateAgentFunctionInput
>;

export const UpdateAgentFunctionInput = mkInput({
  name: 'UpdateAgentFunctionInput',
  comment: 'Input for updating an agent function',
  fields: {
    id: {
      comment: 'The id of the agent function',
      modelName: 'agentFunction',
      type: 'id',
    },
    name: {
      comment: 'The new name of the agent function',
      type: 'string',
      optional: true,
    },
    description: {
      comment: 'The new description of the agent function',
      type: 'string',
      optional: true,
    },
    parameters: {
      comment: 'The JSON schema definition of the function parameters',
      type: 'string',
      optional: true,
    },
    agentIds: CreateAgentFunctionInput.fields.agentIds,
    agentNames: CreateAgentFunctionInput.fields.agentNames,
    agentRemoteIds: CreateAgentFunctionInput.fields.agentRemoteIds,
  },
});

/** Override type */
export type UpdateAgentFunctionInput = SchemaToType<
  typeof UpdateAgentFunctionInput
>;

export const UpdateAgentFunctionsInput = mkInput({
  name: 'UpdateAgentFunctionsInput',
  comment: 'Input for bulk updating one or more agentFunctions',
  fields: {
    agentFunctions: {
      comment:
        'List of agent functions with the properties that should be updated for each',
      type: UpdateAgentFunctionInput,
      list: true,
    },
  },
});

/** Override type */
export type UpdateAgentFunctionsInput = SchemaToType<
  typeof UpdateAgentFunctionsInput
>;

/** Order for an agent functions query */
export const AgentFunctionOrder = mkOrder(
  AgentFunction.name,
  AgentFunctionOrderField,
);
