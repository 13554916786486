/* eslint-disable react-hooks/rules-of-hooks */

import React from 'react';

import { BarChartTooltip, BarChartTooltipData, BarStacks } from '../BarStacks';
import { DEFAULT_COLORS } from '../constants';
import { useChartGrowUpwardsSVGAnimation } from '../hooks';
import { XYChartTemplate } from '../XYChartTemplate';
import { BarChartProps } from './types';
import { ChartSVGBars } from './wrappers';

export const BarChart: React.FC<BarChartProps> = ({
  data,
  colors = DEFAULT_COLORS,
  scale: originalScale = 'linear',
  oneColorPerSeries,
  maxBottomLabels,
}) => {
  const stacked = data.series.length > 1;
  // log scale is buggy for stacked
  const scale = stacked ? 'linear' : originalScale;
  return (
    <XYChartTemplate<BarChartTooltipData>
      data={data}
      scale={scale}
      colors={colors}
      tooltip={BarChartTooltip}
      hideLegend={!stacked}
      isStacked={stacked}
      maxBottomLabels={maxBottomLabels}
    >
      {({
        data,
        xScale,
        yScale,
        legendScale,
        seriesNames,
        chartInteriorHeight,
        chartInteriorWidth,
        hideTooltip,
        updateTooltip,
        setPreferredTooltipPosition,
        hasNegativeY,
        boundingRectLeft,
        boundingRectTop,
        hiddenKeys,
      }) => {
        const barsAnimationStyles =
          useChartGrowUpwardsSVGAnimation(chartInteriorHeight);
        /* eslint-enable react-hooks/rules-of-hooks */

        return (
          <ChartSVGBars
            height={chartInteriorHeight}
            width={chartInteriorWidth}
            style={barsAnimationStyles}
          >
            <BarStacks
              hasNegativeY={hasNegativeY}
              data={data}
              keys={seriesNames}
              x={(d) => d.x}
              xScale={xScale}
              yScale={yScale}
              color={legendScale}
              colors={colors}
              groupHeight={chartInteriorHeight}
              groupWidth={chartInteriorWidth}
              hideTooltip={hideTooltip}
              updateTooltip={updateTooltip}
              setPreferredTooltipPosition={setPreferredTooltipPosition}
              useBarColorForTooltipLegend={!stacked}
              stacked={stacked}
              yScaleType={scale}
              oneColorPerSeries={oneColorPerSeries}
              boundingRectLeft={boundingRectLeft}
              boundingRectTop={boundingRectTop}
              hiddenKeys={hiddenKeys}
            />
          </ChartSVGBars>
        );
      }}
    </XYChartTemplate>
  );
};
