import { defineMessages } from '@transcend-io/internationalization';

export const selectIntegrationsMessages = defineMessages(
  'admin-dashboard.DataMap.SelectIntegrations.selectIntegrations',
  {
    placeholderMulti: {
      defaultMessage: 'Select Integrations',
    },
    placeholderSingle: {
      defaultMessage: 'Select Integration',
    },
    title: {
      defaultMessage: 'Integrations',
    },
  },
);
