import * as t from 'io-ts';

import { dbModelId } from '@main/schema-utils';

import { AuditEventCode } from '../schema/enums';
import {
  mkAuditEventCodec,
  mkAuditEventJoinCodec,
  mkAuditJoinStateCodec,
} from '../types';

export const AuditEventProcessingPurposeSubCategory = mkAuditEventCodec(
  AuditEventCode.ProcessingPurposeSubCategory,
  t.type({
    /** ID of the purpose subcategory */
    processingPurposeSubCategoryId: dbModelId('processingPurposeSubCategory'),
  }),
);

/** Override types. */
export type AuditEventProcessingPurposeSubCategory = t.TypeOf<
  typeof AuditEventProcessingPurposeSubCategory
>;

export const AuditEventProcessingPurposeSubCategoryOwner =
  mkAuditEventJoinCodec(
    AuditEventCode.ProcessingPurposeSubCategoryOwner,
    mkAuditJoinStateCodec('user'),
    t.type({
      /** ID of the purpose subcategory */
      processingPurposeSubCategoryId: dbModelId('processingPurposeSubCategory'),
    }),
  );

/** Override types. */
export type AuditEventProcessingPurposeSubCategoryOwner = t.TypeOf<
  typeof AuditEventProcessingPurposeSubCategoryOwner
>;

export const AuditEventProcessingPurposeSubCategoryTeam = mkAuditEventJoinCodec(
  AuditEventCode.ProcessingPurposeSubCategoryTeam,
  mkAuditJoinStateCodec('team'),
  t.type({
    /** ID of the purpose subcategory */
    processingPurposeSubCategoryId: dbModelId('processingPurposeSubCategory'),
  }),
);

/** Override types. */
export type AuditEventProcessingPurposeSubCategoryTeam = t.TypeOf<
  typeof AuditEventProcessingPurposeSubCategoryTeam
>;

export const AuditEventProcessingPurposeSubCategoryAttribute =
  mkAuditEventJoinCodec(
    AuditEventCode.ProcessingPurposeSubCategoryAttribute,
    mkAuditJoinStateCodec(
      'attributeValue',
      t.type({
        /** the display title for the attribute key */
        attributeKeyTitle: t.string,
      }),
    ),
    t.type({
      /** ID of the processingPurposeSubCategory */
      processingPurposeSubCategoryId: dbModelId('processingPurposeSubCategory'),
    }),
  );

/** Override types. */
export type AuditEventProcessingPurposeSubCategoryAttribute = t.TypeOf<
  typeof AuditEventProcessingPurposeSubCategoryAttribute
>;
