import { defineMessages } from '@transcend-io/internationalization';

export const adminMenuMessages = defineMessages(
  'admin-dashboard.routes.OrganizationRoute.AdminMenu.adminMenu',
  {
    administration: {
      defaultMessage: 'Administration',
    },
    account: {
      defaultMessage: 'Account',
    },
    inviteUsers: {
      defaultMessage: 'Invite users',
    },
    whatsNew: {
      defaultMessage: "What's new?",
    },
    systemStatus: {
      defaultMessage: 'System status',
    },
    documentation: {
      defaultMessage: 'Documentation',
    },
    contactSupport: {
      defaultMessage: 'Ask a question',
    },
    logout: {
      defaultMessage: 'Log out',
    },
    defaultIconAlt: {
      defaultMessage: 'Your organization logo icon',
    },
    organizationIconAlt: {
      defaultMessage: '{organizationName} logo icon',
    },
    currentOrganization: {
      defaultMessage: 'Current organization',
    },
    noMatches: {
      defaultMessage: 'No matches',
    },
  },
);
