import { createSlice } from '@reduxjs/toolkit';

/**
 * The CTABanners reducer state
 */
export interface DsrAutomationState {
  /** Has a demo been requested? */
  isDemoRequested: boolean;
}

export const dsrAutomationSlice = createSlice({
  name: 'dsrAutomation',
  initialState: {
    isDemoRequested: false,
  } as DsrAutomationState,
  reducers: {
    hideDemoButton: (state) => ({
      ...state,
      isDemoRequested: true,
    }),
  },
});

export const { hideDemoButton } = dsrAutomationSlice.actions;
