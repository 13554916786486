import { formatErrorMessage, message, Popover } from '@main/core-ui';
import React, { useEffect, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useTheme } from 'styled-components';

import { uploadCsvMessages } from './messages';
import { StyledFlexRowCenterVertical } from './wrappers';

/**
 * The header column for each dynamic column
 */
export const ColumnHeader: React.FC<{
  /** name of column */
  name: string;
  /** map of dynamic columns */
  columns: string[];
  /** errors */
  error?: Error;
  /** whether this column has an invalid cell */
  hasInvalidCell: boolean;
}> = ({ name, columns, error, hasInvalidCell }) => {
  const theme = useTheme();
  const { formatMessage } = useIntl();

  // whether this column is imported
  const isToBeImported = useMemo(() => columns.includes(name), [name, columns]);

  useEffect(() => {
    if (error) {
      message.error(formatErrorMessage(error.message));
    }
  }, [error]);

  return (
    <StyledFlexRowCenterVertical
      style={
        hasInvalidCell
          ? { color: theme.colors.error }
          : { color: theme.colors.black }
      }
    >
      <Popover
        trigger="click"
        rootClose
        placement="top"
        contents={formatMessage(
          isToBeImported
            ? uploadCsvMessages.willImport
            : uploadCsvMessages.willNotImport,
        )}
      >
        <>{name}</>
      </Popover>
    </StyledFlexRowCenterVertical>
  );
};
