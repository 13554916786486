import { mkType, SchemaToType } from '@main/schema-utils';

export const DataSiloSummary = mkType({
  name: 'DataSiloSummary',
  comment: 'High level summary metrics for a data silo',
  interfaces: [],
  fields: {
    requestsSucceededTotal: {
      comment:
        'The total number of privacy requests that have been successfully resolved against this data silo.',
      type: 'int',
    },
    requestsAwaitingTotal: {
      comment:
        'The total number of privacy requests that are actively waiting for completion.',
      type: 'int',
    },
    requestsQueuedTotal: {
      comment: 'The total number of privacy requests that are queued.',
      type: 'int',
    },
    requestsErrorsTotal: {
      comment:
        'The total number of privacy requests that are experiencing some error state.',
      type: 'int',
    },
    pluginErrorsTotal: {
      comment: 'The total number of plugins that are experiencing errors',
      type: 'int',
      optional: true,
    },
    lookupProcessErrorsTotal: {
      comment:
        'The total number of lookup processes that are experiencing errors.',
      type: 'int',
      optional: true,
    },
    lookupProcessIndexedTotal: {
      comment: 'The total number of records that have been indexed.',
      type: 'int',
      optional: true,
    },
  },
});

/** Override type */
export type DataSiloSummary = SchemaToType<typeof DataSiloSummary>;
