import { AdminDashboardPrivateMiscPath } from '@main/access-control-types';
import { Profile } from '@main/admin-dashboard/src/Auth/AccountProfile/Loadable';
import ActionItems from '@main/admin-dashboard/src/Auth/ActionItems/Loadable';
import AssumeRole from '@main/admin-dashboard/src/Auth/AssumeRole/Loadable';
import { miscPrivatePageTitleMessages } from '@main/admin-dashboard/src/Auth/MiscellaneousPrivate/messages';
import ReLogin from '@main/admin-dashboard/src/Auth/ReLogin/Loadable';
import type { ExtendableRoute } from '@main/admin-dashboard/src/routes';

import { CtaPage } from '../../routes/OrganizationRoute/CtaPage/CtaPage';
import { accountPageTitleMessages } from '../AccountProfile/messages';
import { CreateOrganizationLoadable } from '../CreateOrganization/Loadable';

const actionItemsBreadcrumb = {
  id: AdminDashboardPrivateMiscPath.ActionItems,
  displayText: miscPrivatePageTitleMessages.actionItems,
  href: AdminDashboardPrivateMiscPath.ActionItems,
};

export const miscPrivateRoutes: {
  [k in AdminDashboardPrivateMiscPath]: ExtendableRoute;
} = {
  [AdminDashboardPrivateMiscPath.ActionItems]: {
    path: AdminDashboardPrivateMiscPath.ActionItems,
    title: miscPrivatePageTitleMessages.actionItems,
    component: ActionItems,
    breadcrumbs: [actionItemsBreadcrumb],
  },
  [AdminDashboardPrivateMiscPath.CreateOrganizationLoggedIn]: {
    path: AdminDashboardPrivateMiscPath.CreateOrganizationLoggedIn,
    component: CreateOrganizationLoadable,
  },
  [AdminDashboardPrivateMiscPath.AssumeRole]: {
    path: AdminDashboardPrivateMiscPath.AssumeRole,
    component: AssumeRole,
  },
  [AdminDashboardPrivateMiscPath.ReLogin]: {
    path: AdminDashboardPrivateMiscPath.ReLogin,
    component: ReLogin,
  },
  [AdminDashboardPrivateMiscPath.Profile]: {
    path: AdminDashboardPrivateMiscPath.Profile,
    title: accountPageTitleMessages.account,
    component: Profile,
    breadcrumbs: [
      {
        id: AdminDashboardPrivateMiscPath.Profile,
        displayText: accountPageTitleMessages.account,
        href: AdminDashboardPrivateMiscPath.Profile,
      },
    ],
  },
  [AdminDashboardPrivateMiscPath.ActionItemsMine]: {
    path: AdminDashboardPrivateMiscPath.ActionItemsMine,
    title: miscPrivatePageTitleMessages.actionItems,
    component: ActionItems,
    breadcrumbs: [actionItemsBreadcrumb],
  },
  [AdminDashboardPrivateMiscPath.ActionItemsMyTeam]: {
    path: AdminDashboardPrivateMiscPath.ActionItemsMyTeam,
    title: miscPrivatePageTitleMessages.actionItems,
    component: ActionItems,
    breadcrumbs: [actionItemsBreadcrumb],
  },
  [AdminDashboardPrivateMiscPath.ActionItemsAll]: {
    path: AdminDashboardPrivateMiscPath.ActionItemsAll,
    title: miscPrivatePageTitleMessages.actionItems,
    component: ActionItems,
    breadcrumbs: [actionItemsBreadcrumb],
  },
  [AdminDashboardPrivateMiscPath.CtaPage]: {
    path: AdminDashboardPrivateMiscPath.CtaPage,
    component: CtaPage,
    breadcrumbs: [],
    hideInnerPadding: true,
  },
};
