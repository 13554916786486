import { UserPreview } from '@main/access-control-types';
import { PromptPreview } from '@main/attribute-types';
import { mkInput, mkOrder, mkType, SchemaToType } from '@main/schema-utils';

import { ContractPreview } from './contract';
import { ContractScanOrderField, ContractScanStatus } from './enums';

export const ContractScanPreview = mkType({
  name: 'ContractScanPreview',
  comment: 'A preview of a contract scan',
  fields: {
    id: {
      comment: 'The id of the contract scan',
      modelName: 'contractScan',
      type: 'id',
    },
    name: {
      comment: 'The name of the contract scan',
      type: 'string',
    },
    createdAt: {
      comment: 'The date the contract scan was created',
      type: 'Date',
    },
    updatedAt: {
      comment: 'The date the contract scan was last updated',
      type: 'Date',
    },
  },
});

/** Override type */
export type ContractScanPreview = SchemaToType<typeof ContractScanPreview>;

export const ContractScan = mkType({
  name: 'ContractScan',
  comment: 'An instance of scanning a group of contracts',
  fields: {
    ...ContractScanPreview.fields,
    status: {
      comment: 'The status of the contract scan',
      type: { ContractScanStatus },
    },
    user: {
      comment: 'The user who created this contract scan',
      type: UserPreview,
      optional: true,
    },
    contractCount: {
      comment: 'The total number of contracts in this contract scan',
      type: 'int',
    },
    createdPromptRunCount: {
      comment:
        'The total number of prompt runs that are created for this contract scan',
      type: 'int',
    },
    prompts: {
      comment: 'The prompts that were applied in this contract scan',
      type: PromptPreview,
      list: true,
    },
    contracts: {
      comment: 'The contracts that were scanned in this contract scan',
      type: () => ContractPreview,
      list: true,
    },
    completedPromptRunCount: {
      comment:
        'The total number of prompt runs that ran successfully for this contract scan',
      type: 'int',
    },
    errorPromptRunCount: {
      comment:
        'Indicator of whether this contract scan encountered errors while running prompts',
      type: 'int',
    },
  },
});

/** Override type */
export type ContractScan = SchemaToType<typeof ContractScan>;

export const ContractScanFiltersInput = mkInput({
  name: 'ContractScanFiltersInput',
  comment: 'Inputs for filtering contract scans',
  fields: {
    ids: {
      comment: 'The ids of the contract scans',
      type: 'id',
      modelName: 'contractScan',
      optional: true,
      list: true,
    },
    statuses: {
      comment: 'The statuses of the contract scans',
      type: { ContractScanStatus },
      optional: true,
      list: true,
    },
    userIds: {
      comment: 'The ids of the users who created the contracts can',
      type: 'id',
      modelName: 'user',
      optional: true,
      list: true,
    },
    contractIds: {
      comment: 'The ids of the contracts that were scanned',
      type: 'id',
      modelName: 'contract',
      optional: true,
      list: true,
    },
    promptIds: {
      comment: 'The ids of the prompts that were applied',
      type: 'id',
      modelName: 'prompt',
      optional: true,
      list: true,
    },
    text: {
      comment: 'Filter by name of contract scan',
      optional: true,
      type: 'string',
    },
  },
});

/** Override type */
export type ContractScanFiltersInput = SchemaToType<
  typeof ContractScanFiltersInput
>;

export const DeleteContractScansInput = mkInput({
  name: 'DeleteContractScansInput',
  comment: 'Input for deleting a group of contract scans',
  fields: {
    ids: {
      comment: 'The id of the contract scans to delete',
      type: 'id',
      modelName: 'contractScan',
      list: true,
    },
  },
});

/** Override type */
export type DeleteContractScansInput = SchemaToType<
  typeof DeleteContractScansInput
>;

export const CreateContractScanInput = mkInput({
  name: 'CreateContractScanInput',
  comment: 'Input for creating a contract scan',
  fields: {
    name: {
      comment: 'The name of the contract scan',
      type: 'string',
    },
    promptIds: {
      comment: 'The prompts that should be run over the contracts',
      modelName: 'prompt',
      type: 'id',
      optional: true,
      list: true,
    },
    contractIds: {
      comment: 'The contracts that should be scanned',
      modelName: 'contract',
      type: 'id',
      optional: true,
      list: true,
    },
  },
});

/** Override type */
export type CreateContractScanInput = SchemaToType<
  typeof CreateContractScanInput
>;

export const UpdateContractScanInput = mkInput({
  name: 'UpdateContractScanInput',
  comment: 'Input for updating a contract scan',
  fields: {
    id: {
      comment: 'The id of the contract scan',
      modelName: 'contractScan',
      type: 'id',
    },
    name: {
      comment: 'The new name of the contract scan',
      type: 'string',
      optional: true,
    },
  },
});

/** Override type */
export type UpdateContractScanInput = SchemaToType<
  typeof UpdateContractScanInput
>;

export const UpdateContractScansInput = mkInput({
  name: 'UpdateContractScansInput',
  comment: 'Input for bulk updating one or more contract scans',
  fields: {
    contractScans: {
      comment:
        'List of contract scans with the properties that should be updated for each',
      type: UpdateContractScanInput,
      list: true,
    },
  },
});

/** Override type */
export type UpdateContractScansInput = SchemaToType<
  typeof UpdateContractScansInput
>;

/** Order for a contract scans query */
export const ContractScanOrder = mkOrder(
  ContractScan.name,
  ContractScanOrderField,
);
