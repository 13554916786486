import { FindingClass, FindingSeverity } from './schema/enums';

export interface AuditorRuleMappingItem {
  /** the default severity for a finding */
  defaultSeverity: FindingSeverity;
  /** Is this class enabled for PDF export */
  enabledForExport?: boolean;
}

export const AUDITOR_RULE_CONFIG_MAPPING: Record<
  FindingClass,
  AuditorRuleMappingItem
> = {
  [FindingClass.AirgapNotFound]: {
    defaultSeverity: FindingSeverity.Informational,
  },
  [FindingClass.UnexpectedCookieStored]: {
    defaultSeverity: FindingSeverity.Medium,
  },
  [FindingClass.UnexpectedDataFlow]: {
    defaultSeverity: FindingSeverity.Medium,
  },
  [FindingClass.UnregulatedCookieStored]: {
    defaultSeverity: FindingSeverity.Informational,
  },
  [FindingClass.UnregulatedNetworkRequestMade]: {
    defaultSeverity: FindingSeverity.Informational,
  },
  [FindingClass.CookieMisclassified]: {
    defaultSeverity: FindingSeverity.Medium,
  },
  [FindingClass.DataFlowMisclassified]: {
    defaultSeverity: FindingSeverity.Medium,
  },
  [FindingClass.FacebookLDU]: {
    defaultSeverity: FindingSeverity.Medium,
    enabledForExport: true,
  },
  [FindingClass.GoogleRDP]: {
    defaultSeverity: FindingSeverity.Medium,
    enabledForExport: true,
  },
  [FindingClass.TwitterAds]: {
    defaultSeverity: FindingSeverity.Medium,
    enabledForExport: true,
  },
  [FindingClass.TikTokPixel]: {
    defaultSeverity: FindingSeverity.Medium,
    enabledForExport: true,
  },
  [FindingClass.PinterestAds]: {
    defaultSeverity: FindingSeverity.Medium,
    enabledForExport: true,
  },
  [FindingClass.MicrosoftAdvertising]: {
    defaultSeverity: FindingSeverity.Medium,
    enabledForExport: true,
  },
  [FindingClass.Other]: { defaultSeverity: FindingSeverity.Medium },
};
