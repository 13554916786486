import { Container } from '@main/core-ui';
import React from 'react';

/**
 * Props for Wrapper
 */
export interface WrapperProps {
  /** The child page contents */
  children: React.ReactNode;
}

/**
 * Wrapper for the PublicOnlyRoute
 */
export function Wrapper({ children }: WrapperProps): JSX.Element {
  return <Container spacing={75}>{children}</Container>;
}

export default Wrapper;
