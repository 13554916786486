import { easings, PickAnimated, SpringValues, useSpring } from 'react-spring';

/**
 * return type of grid animation hook
 */
type UseChartGridSVGAnimationReturnType = SpringValues<
  PickAnimated<{
    /** animation delay */
    delay: number;
    /** from styles */
    from: {
      /** initial width */
      width: number;
    };
    /** final styles */
    to: {
      /** final width */
      width: number;
    };
    /** animation config */
    config: {
      /** animation duration */
      duration: number;
      /** easing fn */
      easing: (t: number) => number;
    };
  }>
>;
// this animation makes the grid lines appear to grow from the y-axis
export const useChartGridSVGAnimation = (
  chartWidth: number,
): UseChartGridSVGAnimationReturnType =>
  useSpring({
    delay: 300,
    config: {
      duration: 750,
      easing: easings.easeOutQuart,
    },
    from: {
      width: 0,
    },
    to: {
      width: chartWidth,
    },
  });
