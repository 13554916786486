import type { MacroregionMapInput, RegionRegimesConfig } from './core';

export const DEFAULT_MACROREGIONS_MAP_INPUT: MacroregionMapInput = [
  [
    'EU',
    /**
     * List of EU country codes used in language parts.
     *
     * Extracted from https://github.com/segmentio/in-eu/blob/c1e56df0ed99c7e1dd35295a358faf79ca79b1c1/index.js#L40-L101
     */
    // eslint-disable-next-line max-len
    'AI AT AW AX BE BG BL BM CW CY CZ DE DK EE ES FI FK FO FR GB GF GG GI GL GP HR HU IE IM IO IT JE KY LT LU LV MF MQ MS MT NC NL PF PL PM PN PT RE RO SE SH SI SK SX TC UK VG WF YT',
  ],
];

// TODO: https://transcend.height.app/T-30130 - add modules (ui.js and tcf/ui.js) when ready
export const DEFAULT_REGIONS_TO_REGIMES_MAP: RegionRegimesConfig = [
  // [[['US']], ['ADPPA']],
  [
    [['US', 'CA']],
    ['CPRA'],
    // CPRA timezone matcher. no longer used by default:
    // 'in',
    // false,
    // ['America/Los_Angeles']
  ],
  // [[['US', 'CO']], ['CPA']],
  // [[['US', 'VA']], ['CDPA']],
  // [[['US', 'UT']], ['UCPA']],
  // [[['US', 'NV']], ['Nevada SB220']],
  [[['EU'], ['GB'], ['NO'], ['IS'], ['LI']], ['GDPR'], 'in', true, ['Europe']],
  [
    [['BR']],
    ['LGPD'],
    'in',
    false,
    // list of Brazil time zones (sans 'America/'):
    // eslint-disable-next-line max-len
    'Araguaina Bahia Belem Boa_Vista Campo_Grande Cuiaba Eirunepe Fortaleza Maceio Manaus Noronha Porto_Velho Recife Rio_Branco Santarem Sao_Paulo'.split(
      ' ',
    ),
  ],
];

// Privacy-enhancing tracker override modules
export const GOOGLE_CONSENT_MODE_MODULE = 'GoogleConsentMode';
export const GOOGLE_ADS_RDP_MODULE = 'GoogleAdsRDP';
export const FACEBOOK_LDU_MODULE = 'FacebookLDU';
export const FACEBOOK_LDU_STRICT_MODULE = `${FACEBOOK_LDU_MODULE}:strict`;
export const YOUTUBE_PRIVACY_ENHANCED_MODE_MODULE =
  'YouTubePrivacyEnhancedMode';
export const VIMEO_DNT_MODULE = 'VimeoDNT';
export const WISTIA_DNT_MODULE = 'WistiaDNT';
