import orderBy from 'lodash/orderBy';
import sortBy from 'lodash/sortBy';
import sum from 'lodash/sum';
import sumBy from 'lodash/sumBy';
import moment from 'moment';

import { ExternalDataSeries } from '@main/ad-core-components';
import { AnalyticsResult } from '@main/analytics-types';
import {
  FormatMessageGenericHook,
  GenericMessageDescriptor,
} from '@main/core-ui/src/GenericFormattedMessage';

import { dashboardChartMessages } from './messages';

export function clampToStartOfDay(date: Date): Date;
/**
 * clamps a date to the start of the day to improve consistency
 *
 * @param date - the date to clamp
 * @returns the same date but at the start of the day
 */
export function clampToStartOfDay(date?: Date): Date | undefined {
  if (!date) {
    return undefined;
  }
  return moment(date).startOf('day').toDate();
}

export function clampToEndOfDay(date: Date): Date;
/**
 * clamps a date to the end of the day to improve consistency
 *
 * @param date - the date to clamp
 * @returns the same date but at the end of the day
 */
export function clampToEndOfDay(date: Date): Date {
  return moment(date).endOf('day').toDate();
}

export interface RemapAnalyticsPrettyNamesProps {
  /** The data to remap  */
  data: AnalyticsResult;
  /** Map of keys to pretty labels */
  prettyLabelMap?: Record<string, GenericMessageDescriptor>;
  /** Map of keys to pretty series names */
  prettySeriesMap?: Record<string, GenericMessageDescriptor>;
  /** Default label to use if none available in prettyLabelMap */
  defaultLabel?: GenericMessageDescriptor;
  /** Format date keys? */
  dateKeys?: boolean;
  /** should we sort by values in desc order */
  sortByValueDesc?: boolean;
  /** formatMessageGeneric function from useFormatMessageGeneric hook */
  formatMessageGeneric: FormatMessageGenericHook['formatMessageGeneric'];
  /** should we calculate a separate series for the total? */
  calculateTotal?: boolean;
}

/**
 * Remap data from analyticsData endpoint to data series expected by charts
 *
 * @param props - see interface RemapAnalyticsPrettyNamesProps
 * @returns an array of data series
 */
export const remapAnalyticsPrettyNames = ({
  data,
  prettyLabelMap,
  prettySeriesMap,
  defaultLabel,
  dateKeys,
  sortByValueDesc,
  formatMessageGeneric,
  calculateTotal,
}: RemapAnalyticsPrettyNamesProps): ExternalDataSeries['series'] => {
  const remappedSeriesList = data.series.map(({ points, name }) => {
    const mappedPoints = points.map(({ key, value }) => ({
      key: key
        ? prettyLabelMap?.[key]
          ? (formatMessageGeneric(prettyLabelMap[key]) as string)
          : dateKeys
            ? new Date(key).toLocaleDateString()
            : key
        : (formatMessageGeneric(defaultLabel) as string),
      value,
    }));

    return {
      points: sortByValueDesc
        ? orderBy(mappedPoints, ['value', 'key'], ['desc', 'asc'])
        : mappedPoints,
      name:
        name && prettySeriesMap?.[name]
          ? (formatMessageGeneric(prettySeriesMap[name]) as string)
          : name,
    };
  });

  const result = remappedSeriesList;
  if (calculateTotal && remappedSeriesList.length > 1) {
    // calculate the totals and append as a separate series
    result.push({
      name: formatMessageGeneric(dashboardChartMessages.total) as string,
      points: remappedSeriesList[0].points.map(({ key }, i) => ({
        key,
        value: sum(data.series.map(({ points }) => points[i]?.value)),
      })),
    });
  }

  return sortBy(
    result,
    (series) => sumBy(series.points, 'value') / series.points.length,
  );
};
