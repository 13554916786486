import { defineMessages } from '@transcend-io/internationalization';

import { ScheduleInputUnit } from './types';

export const scheduleInputMessages = defineMessages(
  'ad-core-components.ScheduleInput.scheduleInput',
  {
    startingDateMessage: {
      defaultMessage: 'starting on',
    },
    every: {
      defaultMessage: 'Every',
    },
  },
);

export const scheduleInputUnitMessages = defineMessages<ScheduleInputUnit>(
  'ad-core-components.ScheduleInput.scheduleInputUnit',
  {
    [ScheduleInputUnit.Daily]: {
      defaultMessage: '{num,plural, one {Day} other {Days}}',
    },
    [ScheduleInputUnit.Hourly]: {
      defaultMessage: '{num,plural, one {Hour} other {Hours}}',
    },
    [ScheduleInputUnit.Weekly]: {
      defaultMessage: '{num,plural, one {Week} other {Weeks}}',
    },
    [ScheduleInputUnit.Minute]: {
      defaultMessage: '{num,plural, one {Minute} other {Minutes}}',
    },
  },
);
