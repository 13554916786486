import type { Editor } from '@tiptap/react';
import isEqual from 'lodash/isEqual';

import { TipTapMarkType, TipTapNodeType } from './enums';

/**
 * Helper to check if an extension is enabled. If an `options` parameter is provided,
 * it will also check if the extension is enabled with those `options`.
 *
 * @param editor - the editor instance for which to check if the extension is enabled
 * @param name - the name of the extension to check
 * @param options - options for the extension to also check if they are set as specified
 * @returns true if the extension is enabled with any options, if provided
 */
export const extensionIsEnabled = (
  editor: Editor,
  name: TipTapMarkType | TipTapNodeType,
  options?: Record<string, any>,
): boolean =>
  !!editor.extensionManager.extensions.find((extension) => {
    let found = extension.name === name;
    if (options) {
      Object.keys(options).forEach((key) => {
        if (!isEqual(extension.options[key], options[key])) {
          found = false;
        }
      });
    }

    return found;
  });
