import {
  FlexRowCenterVertical,
  IWithClassName,
  IWithStyle,
} from '@main/core-ui';
import React from 'react';

/**
 * Props for InnerCellWrapper
 */
interface InnerCellWrapperProps extends IWithStyle, IWithClassName {}

/**
 *
 * Styling helper for the standard table inner cell wrapper
 */
export const InnerCellWrapper: React.FC<InnerCellWrapperProps> = ({
  children,
  style,
  className,
}) => (
  <FlexRowCenterVertical
    // table cells should not have divs in them
    as="span"
    style={{ height: '100%', ...style }}
    className={className}
  >
    {children}
  </FlexRowCenterVertical>
);
