/**
 * Safely attempt to perform the atob() conversion.
 *
 * @param data - Input string.
 * @returns atob() transformed data.
 */
export function atobSafe(data: string): string {
  if (typeof atob === 'function') {
    return atob(data);
  }
  if (typeof Buffer === 'function') {
    return Buffer.from(data, 'base64').toString('binary');
  }
  throw new Error('atob() not supported on this browser');
}
