import type { MessageDescriptor } from 'react-intl';

import { getKeys } from '@transcend-io/type-utils';

/**
 * Option
 */
interface EnumOption<TEnum extends string> {
  /** The option to label */
  label: MessageDescriptor;
  /** The name */
  name: TEnum;
  /** The value (same as name) */
  value: TEnum;
  /** The logo */
  logo?: string;
}

/**
 * Convert an enum to options
 *
 * @param messageEnum - The message enum to convert to options
 * @param icons - Mapping from message key to icon message
 * @returns The options
 */
export function enumToOptions<TEnum extends string>(
  messageEnum: { [k in TEnum]: MessageDescriptor },
  icons?: { [k in TEnum]: string },
): EnumOption<TEnum>[] {
  return getKeys(messageEnum).map((name) => ({
    label: messageEnum[name],
    name,
    value: name,
    logo: icons ? icons[name] : undefined,
  }));
}
