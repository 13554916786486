import { FilterConfigItem } from '@main/ad-core-components';
import { RegionOption, RegionTitle, SelectRegion } from '@main/core-ui';
import {
  ISO_31662,
  IsoCountryCode,
  IsoCountrySubdivisionCode,
} from '@transcend-io/privacy-types';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { MessageDescriptor } from 'react-intl';

/**
 * Filters definition with property for regions specified
 */
type RegionEnrichedFilters = Record<string, unknown> &
  /** Combined country and Country Subdivision ISO code */
  Record<string, RegionOption[] | undefined>;

/**
 * Hook to get the filter configuration for regions
 *
 * @returns the config for the filters and a function to clear the filters
 */
type RegionFiltersBuilder<
  T extends Record<string, unknown>,
  E extends RegionEnrichedFilters,
> = (filterParams: {
  /** Name for the property that filters on country code. Used when separateCountryAndSubdivisionFilters is true. */
  countryFilterKey?: keyof T;
  /** Name for the property that filters on country subdivision code. Used when separateCountryAndSubdivisionFilters is true. */
  countrySubDivisionFilterKey?: keyof T;
  /** Name for the property that filters on country and subdivision. Used when separateCountryAndSubdivisionFilters is false. */
  regionsFilterKey?: keyof T;
  /** The currently applied filters */
  filters?: T;
  /** Callback when the filters are changed */
  setFilters: (filters: T) => void;
  /** Label for the filter menu */
  label: string | MessageDescriptor;
  /** The key to use if more than one useRegionFilters is used in a FilterManager */
  enrichedRegionsKey?: keyof E;
  /** Whether to use separate filter keys for country and country subdivision */
  separateCountryAndSubdivisionFilters?: boolean;
}) => {
  /** The regions currently filtered on */
  selectedRegions: RegionOption[];
  /** The filter configuration to be passed to the filter manager */
  regionFiltersConfig: FilterConfigItem<E>;
  /** Callback for when the filter is cleared in the filter manager */
  clearRegionFilters: (key: Extract<keyof E, string>) => void;
};

/**
 *
 * Builds elements of the filters config needed for filtering by regions
 *
 * @param filterParams - the currently applied filters and a function to set the filters
 * @returns Elements of the filters config needed for filtering by regions
 */
export const useRegionFilters: RegionFiltersBuilder<
  Record<string, unknown>,
  RegionEnrichedFilters
> = ({
  countryFilterKey = 'country',
  countrySubDivisionFilterKey = 'countrySubDivision',
  regionsFilterKey = 'regions',
  label,
  filters,
  setFilters,
  enrichedRegionsKey = 'regions',
  separateCountryAndSubdivisionFilters = true,
}) => {
  const [selectedRegions, setSelectedRegions] = useState<RegionOption[]>([]);

  // Populate initial complex filter values
  useEffect(() => {
    if (filters) {
      if (separateCountryAndSubdivisionFilters) {
        const country = (filters[countryFilterKey] || []) as IsoCountryCode[];
        const countrySubDivision = (filters[countrySubDivisionFilterKey] ||
          []) as IsoCountrySubdivisionCode[];

        if (country.length > 0 || countrySubDivision.length > 0) {
          const selectedCountries = country.map(
            (country: IsoCountryCode) =>
              ({ country, countrySubDivision: undefined }) as RegionOption,
          );
          const selectedCountrySubDivisions = countrySubDivision.map(
            (countrySubDivision: string) =>
              ({ country: undefined, countrySubDivision }) as RegionOption,
          );
          setSelectedRegions([
            ...selectedCountrySubDivisions,
            ...selectedCountries,
          ]);
        }
      } else {
        setSelectedRegions((filters[regionsFilterKey] as RegionOption[]) ?? []);
      }
    }
  }, []);

  const regionFiltersConfig: FilterConfigItem<RegionEnrichedFilters> = useMemo(
    () => ({
      filterKey: enrichedRegionsKey,
      label,
      renderPill: ({
        filterValues: { [enrichedRegionsKey]: regions = [] },
        index = 0,
      }) => {
        const region = regions[index];
        return (
          <RegionTitle
            country={
              region.country ??
              (region.countrySubDivision?.slice(0, 2) as IsoCountryCode)
            }
            countrySubDivision={
              region.countrySubDivision as keyof typeof ISO_31662
            }
          />
        );
      },
      filter: (
        <SelectRegion
          menuPosition="absolute"
          isMulti
          value={selectedRegions}
          onChange={(selectedRegions = []) => {
            setSelectedRegions(selectedRegions);
            setFilters({
              ...filters,
              ...(separateCountryAndSubdivisionFilters
                ? {
                    [countryFilterKey]: (selectedRegions
                      .filter(
                        ({ country, countrySubDivision }) =>
                          // ONLY set country if there is no countrySubDivision selected
                          country && !countrySubDivision,
                      )
                      .map(({ country }) => country) ?? []) as IsoCountryCode[],
                    [countrySubDivisionFilterKey]: (selectedRegions
                      .filter(({ countrySubDivision }) => countrySubDivision)
                      .map(({ countrySubDivision }) => countrySubDivision) ??
                      []) as IsoCountrySubdivisionCode[],
                  }
                : { [regionsFilterKey]: selectedRegions }),
            });
          }}
        />
      ),
    }),
    [
      countryFilterKey,
      countrySubDivisionFilterKey,
      enrichedRegionsKey,
      filters,
      label,
      regionsFilterKey,
      selectedRegions,
      separateCountryAndSubdivisionFilters,
    ],
  );

  const clearRegionFilters = useCallback(
    (key) => {
      if (key === enrichedRegionsKey) {
        setFilters({
          ...filters,
          ...(separateCountryAndSubdivisionFilters
            ? {
                [countryFilterKey]: [],
                [countrySubDivisionFilterKey]: [],
              }
            : { [regionsFilterKey]: [] }),
        });
        setSelectedRegions([]);
      }
    },
    [
      countryFilterKey,
      countrySubDivisionFilterKey,
      enrichedRegionsKey,
      filters,
      regionsFilterKey,
      separateCountryAndSubdivisionFilters,
    ],
  );

  return {
    selectedRegions,
    regionFiltersConfig,
    clearRegionFilters,
  };
};
