import { statusDescriptionMessages, statusTitleMessages } from './messages';
import type { RequestStatusConfig } from './types';

/**
 * ## Waiting on compilation
 * All identifiers for the request are verified, and now the request waits before
 * starting compilation.
 */
export const WAITING_STATUS: RequestStatusConfig = {
  color: 'darkPrimary',
  icon: 'clock',
  isBuilding: true,
  messages: {
    description: statusDescriptionMessages.WAITING,
    title: statusTitleMessages.WAITING,
  },
  transitions: [
    /** All enrichers for each identifier have been resolved and the request has waited for the required duration. */
    'COMPILING',
    /** Organization member clicks cancel, triggering cancelation email and the request is killed */
    'CANCELED',
    /** Organization member clicks onHold, triggering email and the request is placed temporarily on hold */
    'ON_HOLD',
    /** Data subject can revoke */
    'REVOKED',
  ],
};
