import {
  buildInlineTextInputWithUpdate,
  HeaderCheckbox,
  HideableCheckbox,
} from '@main/ad-core-components';
import {
  StyledFlexRowCenterBoth,
  StyledFlexRowCenterVertical,
} from '@main/admin-dashboard/src/DataMap/components';
import { CodePackage, endpoints } from '@main/code-scanning-types';
import moment from 'moment';
import React, { ChangeEvent } from 'react';
import { CellProps, Column, HeaderProps } from 'react-table';

import { DATE_FORMAT } from '../../Assessments/constants';
import { SelectRepository } from '../components';
import { CodePackagePromptRunCountPreview } from './CodePackagePromptRunCountPreview';
// import { InlineCodePackageDataSiloUpdate } from './InlineCodePackageDataSiloUpdate';
import { InlineCodePackagesOwnersUpdate } from './InlineCodePackagesOwnersUpdate';
import { InlineCodePackagesSoftwareDevelopmentKitsUpdate } from './InlineCodePackagesSoftwareDevelopmentKitsUpdate';
import { InlineCodePackagesTeamsUpdate } from './InlineCodePackagesTeamsUpdate';
import { InlineCodePackageTypeUpdate } from './InlineCodePackageTypeUpdate';

// TODO: https://transcend.height.app/T-31725 - attributes
const InlineText = buildInlineTextInputWithUpdate(endpoints.updateCodePackages);

export const CHECKBOX_COLUMN: (
  selectedCodePackages: Set<CodePackage['id']>,
  setSelectedCodePackages: (
    selectedCodePackages: Set<CodePackage['id']>,
  ) => void,
) => Column<CodePackage> = (selectedCodePackages, setSelectedCodePackages) => ({
  id: 'selected',
  accessor: 'id',
  disableResizing: true,
  width: 40,
  minWidth: 40,
  Header: ({ data }: HeaderProps<CodePackage>) => (
    <StyledFlexRowCenterBoth>
      <HeaderCheckbox
        checked={
          selectedCodePackages.size > 0 &&
          selectedCodePackages.size === data.length
        }
        onChange={() => {
          if (selectedCodePackages.size === data.length) {
            setSelectedCodePackages(new Set());
          } else {
            setSelectedCodePackages(
              new Set(data.map(({ id }: CodePackage) => id)),
            );
          }
        }}
      />
    </StyledFlexRowCenterBoth>
  ),
  Cell: ({ value }: CellProps<CodePackage>) => (
    <StyledFlexRowCenterBoth>
      <HideableCheckbox
        checked={selectedCodePackages.has(value)}
        onChange={(e: ChangeEvent<HTMLInputElement>) => {
          const newSelectedCodePackages = new Set<CodePackage['id']>(
            selectedCodePackages,
          );
          if (e.target.checked) {
            newSelectedCodePackages.add(value);
          } else {
            newSelectedCodePackages.delete(value);
          }
          setSelectedCodePackages(newSelectedCodePackages);
        }}
      />
    </StyledFlexRowCenterBoth>
  ),
});

export const NAME_COLUMN: Column<CodePackage> = {
  accessor: 'name',
  Cell: ({ value, row }: CellProps<CodePackage, CodePackage['name']>) => (
    <StyledFlexRowCenterVertical>
      <InlineText
        value={value}
        getValueFromReturnedData={(data) => data?.codePackages?.[0]?.name}
        updateItemWithValue={(updatedValue) => ({
          variables: {
            input: {
              codePackages: [
                {
                  id: row.original.id,
                  name: updatedValue,
                },
              ],
            },
          },
        })}
      />
    </StyledFlexRowCenterVertical>
  ),
  maxWidth: 400,
  width: 240,
  minWidth: 150,
};

export const DESCRIPTION_COLUMN: Column<CodePackage> = {
  accessor: 'description',
  Cell: ({
    value,
    row,
  }: CellProps<CodePackage, CodePackage['description']>) => (
    <StyledFlexRowCenterVertical>
      <InlineText
        value={value}
        getValueFromReturnedData={(data) =>
          data?.codePackages?.[0]?.description
        }
        updateItemWithValue={(updatedValue) => ({
          variables: {
            input: {
              codePackages: [
                {
                  id: row.original.id,
                  description: updatedValue,
                },
              ],
            },
          },
        })}
      />
    </StyledFlexRowCenterVertical>
  ),

  minWidth: 150,
  width: 250,
  maxWidth: 500,
};

export const RELATIVE_PATH_COLUMN: Column<CodePackage> = {
  accessor: 'relativePath',
  Cell: ({
    value,
    row,
  }: CellProps<CodePackage, CodePackage['relativePath']>) => (
    <StyledFlexRowCenterVertical>
      <InlineText
        value={value}
        getValueFromReturnedData={(data) =>
          data?.codePackages?.[0]?.relativePath
        }
        updateItemWithValue={(updatedValue) => ({
          variables: {
            input: {
              codePackages: [
                {
                  id: row.original.id,
                  relativePath: updatedValue,
                },
              ],
            },
          },
        })}
      />
    </StyledFlexRowCenterVertical>
  ),
  width: 250,
  maxWidth: 500,
  minWidth: 150,
};

export const CREATED_AT_COLUMN: Column<CodePackage> = {
  accessor: 'createdAt',
  Cell: ({ value }: CellProps<CodePackage, CodePackage['createdAt']>) => (
    <StyledFlexRowCenterVertical>
      {moment(value).format(DATE_FORMAT)}
    </StyledFlexRowCenterVertical>
  ),
  width: 200,
  minWidth: 150,
};

export const LAST_SEEN_AT_COLUMN: Column<CodePackage> = {
  accessor: 'lastSeenAt',
  Cell: ({ value }: CellProps<CodePackage, CodePackage['lastSeenAt']>) => (
    <StyledFlexRowCenterVertical>
      {moment(value).format(DATE_FORMAT)}
    </StyledFlexRowCenterVertical>
  ),
  width: 200,
  minWidth: 150,
};

export const OWNERS_COLUMN: Column<CodePackage> = {
  accessor: 'owners',
  Cell: ({ row, value }: CellProps<CodePackage, CodePackage['owners']>) => (
    <StyledFlexRowCenterVertical>
      <InlineCodePackagesOwnersUpdate id={row.original.id} value={value} />
    </StyledFlexRowCenterVertical>
  ),
  width: 200,
  minWidth: 150,
};

export const TEAMS_COLUMN: Column<CodePackage> = {
  accessor: 'teams',
  Cell: ({ row, value }: CellProps<CodePackage, CodePackage['teams']>) => (
    <StyledFlexRowCenterVertical>
      <InlineCodePackagesTeamsUpdate id={row.original.id} value={value} />
    </StyledFlexRowCenterVertical>
  ),
  width: 200,
  minWidth: 150,
};

export const SOFTWARE_DEVELOPMENT_KITS_COLUMN: Column<CodePackage> = {
  accessor: 'softwareDevelopmentKits',
  Cell: ({
    row,
    value,
  }: CellProps<CodePackage, CodePackage['softwareDevelopmentKits']>) => (
    <StyledFlexRowCenterVertical>
      <InlineCodePackagesSoftwareDevelopmentKitsUpdate
        id={row.original.id}
        value={value.map(
          ({ softwareDevelopmentKit }) => softwareDevelopmentKit,
        )}
      />
    </StyledFlexRowCenterVertical>
  ),
  maxWidth: 400,
  width: 270,
  minWidth: 150,
};

export const REPOSITORY_COLUMN: Column<CodePackage> = {
  accessor: 'repository',
  Cell: ({ value }: CellProps<CodePackage, CodePackage['repository']>) => (
    <StyledFlexRowCenterVertical>
      <SelectRepository value={value} isDisabled isMulti={false} />
    </StyledFlexRowCenterVertical>
  ),
  minWidth: 150,
  width: 200,
  maxWidth: 500,
};

export const TYPE_COLUMN: Column<CodePackage> = {
  accessor: 'type',
  Cell: ({ value, row }: CellProps<CodePackage, CodePackage['type']>) => (
    <StyledFlexRowCenterVertical>
      <InlineCodePackageTypeUpdate id={row.original.id} value={value} />
    </StyledFlexRowCenterVertical>
  ),
  width: 200,
  minWidth: 150,
};

// TODO: https://transcend.height.app/T-32155 - add component
// export const DATA_SILO_COLUMN: Column<CodePackage> = {
//   accessor: 'dataSilo',
//   Cell: ({ value, row }: CellProps<CodePackage, CodePackage['dataSilo']>) => (
//     <StyledFlexRowCenterVertical>
//       <InlineCodePackageDataSiloUpdate id={row.original.id} value={value} />
//     </StyledFlexRowCenterVertical>
//   ),
//   width: 200,
//   minWidth: 150,
// };

export const PROMPT_RUN_COUNT_COLUMN: Column<CodePackage> = {
  accessor: 'promptRunCount',
  Cell: ({ value, row }: CellProps<CodePackage>) => (
    <StyledFlexRowCenterVertical>
      <CodePackagePromptRunCountPreview id={row.original.id} value={value} />
    </StyledFlexRowCenterVertical>
  ),
  minWidth: 150,
};
