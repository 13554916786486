/* eslint-disable max-lines */
import { InitialViewState } from '@transcend-io/airgap.js-types';
import { RegionsOperator } from '@transcend-io/privacy-types';

import { IABSignal } from '@main/ag-types';
import { Region, RegionInput } from '@main/datamap-types';
import { mkInput, mkOrder, mkType, SchemaToType } from '@main/schema-utils';

import { ConsentManagerUI } from './consentManagerUI';
import { ExperienceOrderField, OnConsentExpiry } from './enums';
import { TrackingPurpose } from './purpose';

export const Experience = mkType({
  name: 'Experience',
  comment:
    'A set of transcend configurations for an organization defined set of regions',
  fields: {
    id: {
      comment: 'The unique UUID of the experience',
      modelName: 'experience',
      type: 'id',
    },
    name: {
      comment: 'The name of the experience',
      type: 'string',
    },
    displayName: {
      comment: 'The name of the experience',
      type: 'string',
    },
    regions: {
      comment:
        'A list of regions to be included/not included in this experience',
      list: true,
      type: Region,
    },
    operator: {
      comment:
        'Whether the listed regions are included or excluded from the experience',
      type: { RegionsOperator },
    },
    displayPriority: {
      comment:
        'If data subject linked to multiple experiences, display priority for experiences. Lower number, higher priority.',
      type: 'int',
    },
    viewState: {
      comment: 'The view state to display on transcend.showConsentManager()',
      type: { InitialViewState },
    },
    ui: {
      comment: 'The UI module to use',
      type: ConsentManagerUI,
      optional: true,
    },
    purposes: {
      comment: 'A list of tracking purposes associated with this experience',
      type: TrackingPurpose,
      list: true,
    },
    optedOutPurposes: {
      comment:
        'A list of tracking purposes associated with this experience that will be opted out by default',
      type: TrackingPurpose,
      list: true,
    },
    browserLanguages: {
      comment: 'A list of browser languages that this experience applies to',
      type: 'string',
      list: true,
    },
    browserTimeZones: {
      comment: 'A list of browser time zones that this experience applies to',
      type: 'string',
      list: true,
    },
    iabSignals: {
      comment: 'Set of iab signals to communicate',
      type: { IABSignal },
      list: true,
    },
    consentExpiry: {
      comment:
        "Time in months after which a user's opt-in consent should expire",
      type: 'int',
    },
    onConsentExpiry: {
      comment: "Action taken when the user's consent has expired",
      type: { OnConsentExpiry },
    },
    isActive: {
      comment: 'Whether the regional experience is active for an organization',
      type: 'boolean',
    },
  },
});

/** Override type */
export type Experience = SchemaToType<typeof Experience>;

export const ExperienceFiltersInput = mkInput({
  name: 'ExperienceFiltersInput',
  comment: 'Filter experiences',
  fields: {
    text: {
      comment: 'Filter by text',
      optional: true,
      type: 'string',
    },
    regions: {
      comment: 'Filter by regions',
      optional: true,
      type: RegionInput,
      list: true,
    },
    operator: {
      comment: 'Whether listed regions are excluded or included in experience',
      optional: true,
      type: { RegionsOperator },
    },
    browserLanguages: {
      comment: 'Filter by language',
      optional: true,
      type: 'string',
      list: true,
    },
    browserTimeZones: {
      comment: 'Filter by time zones',
      optional: true,
      type: 'string',
      list: true,
    },
    isActive: {
      comment: 'Filter by whether the experience is active',
      optional: true,
      type: 'boolean',
    },
  },
});

/** Override type */
export type ExperienceFiltersInput = SchemaToType<
  typeof ExperienceFiltersInput
>;

export const CreateExperienceInput = mkInput({
  name: 'CreateExperienceInput',
  comment: 'Input used to create a new experience for an organization',
  fields: {
    name: {
      comment: 'Name of the experience',
      optional: false,
      type: 'string',
    },
    displayName: {
      comment: 'Display name of the experience',
      optional: true,
      type: 'string',
    },
    regions: {
      comment: 'Regions to be included/not included in this experience',
      optional: false,
      type: RegionInput,
      list: true,
    },
    operator: {
      comment: 'Whether listed regions are excluded or included in experience',
      optional: true,
      type: { RegionsOperator },
    },
    displayPriority: {
      comment:
        'If data subject linked to multiple experiences, display priority for experiences. Lower number, higher priority.',
      type: 'int',
      optional: false,
    },
    viewState: {
      comment: 'The view state to display on transcend.showConsentManager()',
      type: { InitialViewState },
      optional: false,
    },
    ui: {
      comment: 'The UI module to use (overrides View State if appropriate)',
      type: 'string',
      optional: true,
    },
    purposes: {
      comment: 'The purposes associated with this experience',
      type: 'id',
      modelName: 'purpose',
      list: true,
      optional: false,
    },
    optedOutPurposes: {
      comment:
        'The purposes associated with this experience that are default opted out',
      type: 'id',
      modelName: 'purpose',
      list: true,
      optional: false,
    },
    browserLanguages: {
      comment: 'A list of browser languages that this experience applies to',
      type: 'string',
      list: true,
      optional: true,
    },
    browserTimeZones: {
      comment: 'A list of browser time zones that this experience applies to',
      type: 'string',
      list: true,
      optional: true,
    },
    iabSignals: {
      comment: 'Set of iab signals to communicate',
      type: { IABSignal },
      list: true,
      optional: true,
      default: [],
    },
    consentExpiry: {
      comment: 'Number of months after which consent values will expire',
      type: 'int',
      optional: true,
    },
    onConsentExpiry: {
      comment: "Action taken when the user's consent has expired",
      type: { OnConsentExpiry },
      optional: true,
    },
    isActive: {
      comment: 'Whether the regional experience is active for an organization',
      type: 'boolean',
      optional: true,
      default: true,
    },
  },
});

/** Override type */
export type CreateExperienceInput = SchemaToType<typeof CreateExperienceInput>;

export const UpdateExperienceInput = mkInput({
  name: 'UpdateExperienceInput',
  comment: 'Input used to update an existing experience for an organization',
  fields: {
    id: {
      comment: 'The id of the experience',
      type: 'id',
      modelName: 'experience',
    },
    name: {
      ...CreateExperienceInput.fields.name,
      optional: true,
    },
    regions: {
      ...CreateExperienceInput.fields.regions,
      optional: true,
    },
    operator: {
      ...CreateExperienceInput.fields.operator,
      optional: true,
    },
    displayPriority: {
      ...CreateExperienceInput.fields.displayPriority,
      optional: true,
    },
    viewState: {
      ...CreateExperienceInput.fields.viewState,
      optional: true,
    },
    ui: {
      ...CreateExperienceInput.fields.ui,
      optional: true,
    },
    purposes: {
      ...CreateExperienceInput.fields.purposes,
      optional: true,
    },
    optedOutPurposes: {
      ...CreateExperienceInput.fields.optedOutPurposes,
      optional: true,
    },
    browserLanguages: {
      ...CreateExperienceInput.fields.browserLanguages,
      optional: true,
    },
    browserTimeZones: {
      ...CreateExperienceInput.fields.browserTimeZones,
      optional: true,
    },
    iabSignals: {
      ...CreateExperienceInput.fields.iabSignals,
      optional: true,
    },
    consentExpiry: {
      ...CreateExperienceInput.fields.consentExpiry,
      optional: true,
    },
    onConsentExpiry: {
      ...CreateExperienceInput.fields.onConsentExpiry,
      optional: true,
    },
    isActive: {
      ...CreateExperienceInput.fields.isActive,
      optional: true,
    },
  },
});

/** Override type */
export type UpdateExperienceInput = SchemaToType<typeof UpdateExperienceInput>;

export const DeleteExperiencesInput = mkInput({
  name: 'DeleteExperiencesInput',
  comment: 'Input used to delete a list of experiences via experience id',
  fields: {
    ids: {
      comment: 'The IDs of the Experiences to delete.',
      modelName: 'experience',
      type: 'id',
      list: true,
    },
  },
});

/** Override type */
export type DeleteExperiencesInput = SchemaToType<
  typeof DeleteExperiencesInput
>;

/**
 * Order for a experiences query
 */
export const ExperienceOrder = mkOrder(Experience.name, ExperienceOrderField);

/** Override type */
export type ExperienceOrder = SchemaToType<typeof ExperienceOrder>;

export const DisableExperiencesInput = mkInput({
  name: 'DisableExperiencesInput',
  comment:
    'Input used to enable/disable a list of experiences via experience id',
  fields: {
    ids: {
      comment: 'The IDs of the Experiences to disable.',
      modelName: 'experience',
      type: 'id',
      list: true,
    },
    isActive: {
      ...CreateExperienceInput.fields.isActive,
      optional: true,
    },
  },
});

/** Override type */
export type DisableExperiencesInput = SchemaToType<
  typeof DisableExperiencesInput
>;
/* eslint-enable max-lines */
