import * as t from 'io-ts';

import { dbModelId } from '@main/schema-utils';

import { AuditEventCode } from '../schema/enums';
import { mkAuditEventCodec } from '../types';

export const AuditEventDataReport = mkAuditEventCodec(
  AuditEventCode.DataReport,
  t.type({
    /** ID of the data report */
    dataReportId: dbModelId('dataReport'),
  }),
);

/** Override types. */
export type AuditEventDataReport = t.TypeOf<typeof AuditEventDataReport>;
