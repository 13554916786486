import { Icon, IconType, Tooltip } from '@main/core-ui';
import { DefinedMessage } from '@transcend-io/internationalization';
import React from 'react';
import { useIntl } from 'react-intl';

import { StyledToggle } from './wrappers';

interface ToolbarToggleProps {
  /** Title of the Button */
  title: DefinedMessage | string;
  /** Button onClick handler when the status changes */
  onPressedChange: () => void;
  /** Button Disabled */
  disabled: boolean;
  /** Button Pressed/Unpressed  */
  pressed: boolean;
  /** Icon to display */
  icon: IconType;
}

/**
 * Custom Toolbar Toggle with Tooltip and StyledToggle
 */
export function ToolbarToggle({
  title,
  onPressedChange,
  disabled,
  pressed,
  icon,
}: ToolbarToggleProps): JSX.Element {
  const { formatMessage } = useIntl();
  const titleLabel = typeof title === 'string' ? title : formatMessage(title);

  return (
    <Tooltip title={titleLabel}>
      <StyledToggle
        aria-label={titleLabel}
        pressed={pressed}
        onPressedChange={onPressedChange}
        disabled={disabled}
      >
        <Icon type={icon} size={16} />
      </StyledToggle>
    </Tooltip>
  );
}
