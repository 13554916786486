import { Organization } from '@main/access-control-types';
import {
  Badge,
  FlexRowCenterBoth,
  Icon,
  StyleUtils,
  useFeatureFlags,
  useFormatMessageGeneric,
} from '@main/core-ui';
import { Optionalize } from '@transcend-io/type-utils';
import React, { useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useTheme } from 'styled-components';

import {
  selectRequiredOrganization,
  selectRequiredUser,
} from '../../../Auth/App/selectors';
import {
  getCtaPageHref,
  getNavItemHrefOrEvaluatedHref,
  getTo,
  isNavItemActive,
} from './helpers';
import { navMenuMessages } from './messages';
import { NavMenuItem } from './types';
import {
  NavItemLabelWrapper,
  NavItemStatusBadgeWrapper,
  StyledNavItemCollapseButton,
  StyledNavLinkRaw,
} from './wrappers';

interface NavMenuLinkProps {
  /** the item to render */
  item: Optionalize<NavMenuItem, 'icon'>;
  /** is the gate allowed? */
  gateAllowed: boolean;
  /** is the nav skinny or wide */
  wideNavbar?: boolean;
  /** are the children currently visible? */
  childrenVisible?: boolean;
  /** handler for when the collapsed state is toggled */
  onCollapseToggle?: () => void;
}

const DEFAULT_WIDE_NAV_ITEM_WIDTH = 215;

/**
 * NavMenuItems
 */
export const NavMenuLink: React.FC<NavMenuLinkProps> = ({
  item,
  gateAllowed,
  wideNavbar,
  childrenVisible,
  onCollapseToggle,
}) => {
  const { formatMessageGeneric } = useFormatMessageGeneric();
  const { formatNumber } = useIntl();
  const featureFlags = useFeatureFlags();
  const user = useSelector(selectRequiredUser);
  const organization: Organization = useSelector(selectRequiredOrganization);
  const { pathname: currentPath } = useLocation();

  const theme = useTheme();

  const hrefOrEvaluated = getNavItemHrefOrEvaluatedHref(
    getTo(item, featureFlags),
    user,
    organization,
  );
  const childItemsOrDefault = item.childItems ?? [];
  const isActive = isNavItemActive(item, hrefOrEvaluated, currentPath);
  const isFolderAndAllowed = gateAllowed && childItemsOrDefault.length > 0;
  const linkColor =
    isActive && !isFolderAndAllowed
      ? theme.colors.primary
      : theme.colors.transcendNavy2;
  const href =
    typeof hrefOrEvaluated === 'string' && gateAllowed
      ? hrefOrEvaluated
      : item.gate?.ctaPage &&
          // don't show cta if is allowed and is folder
          !isFolderAndAllowed
        ? getCtaPageHref(item.gate.ctaPage.id)
        : // should only get here for nav menus with child items and no root page
          '#';

  const linkElement = useRef<HTMLAnchorElement>();
  const [prevWidth, setPrevWidth] = useState<number>();
  // keep track of ideal width for animation
  useEffect(() => {
    setTimeout(() => {
      if (prevWidth === undefined && wideNavbar) {
        setPrevWidth(linkElement.current?.parentElement?.clientWidth);
      }
    }, 500);
  }, [linkElement.current, wideNavbar]);

  return (
    <StyledNavLinkRaw
      ref={linkElement as any}
      to={isFolderAndAllowed ? '' : href}
      style={{
        color: linkColor,
        minWidth: Math.min(
          prevWidth ?? DEFAULT_WIDE_NAV_ITEM_WIDTH,
          DEFAULT_WIDE_NAV_ITEM_WIDTH,
        ),
      }}
      onClick={isFolderAndAllowed ? () => onCollapseToggle?.() : undefined}
    >
      <FlexRowCenterBoth
        style={{ padding: StyleUtils.Spacing.xs, minWidth: 32 }}
      >
        {item.icon && <Icon type={item.icon} size={24} color={linkColor} />}
      </FlexRowCenterBoth>
      <NavItemLabelWrapper $isExpanded={wideNavbar}>
        {formatMessageGeneric(item.label)}
      </NavItemLabelWrapper>
      <NavItemStatusBadgeWrapper
        $isExpanded={wideNavbar && !!item.statusContent}
      >
        {item.statusContent &&
          (typeof item.statusContent === 'number' ? (
            <Badge
              round
              color="danger"
              style={{
                maxHeight: '24px',
                padding: '2px 6px',
              }}
              ignoreAccessibilityAndUseLightText
            >
              {formatNumber(item.statusContent, {
                notation: 'compact',
              })}
            </Badge>
          ) : (
            item.statusContent
          ))}
      </NavItemStatusBadgeWrapper>
      {onCollapseToggle && gateAllowed && (
        <StyledNavItemCollapseButton
          icon={
            <Icon
              color={theme.colors.transcendNavy2}
              type={childrenVisible ? 'caret-up' : 'caret-down'}
            />
          }
          iconOnly
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            onCollapseToggle();
          }}
          variant="link"
          size="sm"
          $isExpanded={wideNavbar}
          aria-label={
            formatMessageGeneric(navMenuMessages.expandNavSection) as string
          }
        />
      )}
    </StyledNavLinkRaw>
  );
};
