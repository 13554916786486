import {
  FilterManager,
  FiltersConfig,
  InlineTextInput,
  SelectedTeam,
  SelectedUser,
} from '@main/ad-core-components';
import {
  useSoftwareDevelopmentKitFilters,
  useTeamFilters,
  useUserFilters,
} from '@main/admin-dashboard/src/hooks';
import { RepositoryFiltersInput } from '@main/code-scanning-types';
import pickBy from 'lodash/pickBy';
import React, { useMemo, useRef } from 'react';
import { useIntl } from 'react-intl';

import { repositoriesColumnHeaderMessages } from '../../Repositories/messages';
import { SelectedSoftwareDevelopmentKit } from '../SelectSoftwareDevelopmentKits';
import { repositoryFilterMessages } from './messages';

/**
 * Filters we support for repositories
 */
// eslint-disable-next-line @typescript-eslint/consistent-type-definitions
type SupportedFilters = {
  /** Text to search the repositories by */
  text?: string;
  /** Text to search on name only */
  name?: string;
  /** Assigned owner */
  owners?: SelectedUser[];
  /** Assigned teams */
  teams?: SelectedTeam[];
  /** SDKs in repository */
  softwareDevelopmentKits?: SelectedSoftwareDevelopmentKit[];
};

interface RepositoryFilterProps {
  /** The currently applied filters */
  filters?: RepositoryFiltersInput;
  /** Callback when the filters are changed */
  setFilters: (filters: RepositoryFiltersInput) => void;
}

export const RepositoryFilter: React.FC<RepositoryFilterProps> = ({
  filters,
  setFilters,
}) => {
  const { formatMessage } = useIntl();

  const {
    selectedUsers: selectedOwners,
    userFiltersConfig: ownerFiltersConfig,
    clearUserFilters: clearOwnerFilters,
  } = useUserFilters({
    userIdsFilterKey: 'ownerIds',
    filters,
    setFilters,
    label: repositoriesColumnHeaderMessages.owners,
    enrichedUserKey: 'owners',
  });

  const { selectedTeams, teamFiltersConfig, clearTeamFilters } = useTeamFilters(
    {
      filters,
      setFilters,
      label: repositoriesColumnHeaderMessages.teams,
    },
  );

  const {
    selectedSoftwareDevelopmentKits,
    softwareDevelopmentKitFiltersConfig,
    clearSoftwareDevelopmentKitFilters,
  } = useSoftwareDevelopmentKitFilters({
    filters,
    setFilters,
    label: repositoryFilterMessages.sdks,
  });

  const nameInputRef = useRef<HTMLInputElement>(null);

  const filterConfig: FiltersConfig<SupportedFilters> = [
    teamFiltersConfig as any,
    ownerFiltersConfig as any,
    softwareDevelopmentKitFiltersConfig as any,
    {
      filterKey: 'name',
      label: repositoriesColumnHeaderMessages.name,
      pillOptions: {
        label: ({ filterValues: { name } }) => name,
      },
      filter: (
        <InlineTextInput
          ref={nameInputRef}
          name="search"
          style={{
            background: 'none',
            border: 0,
            boxShadow: 'none',
            minWidth: 20,
            width: 'auto',
          }}
          placeholder={formatMessage(repositoriesColumnHeaderMessages.name)}
          aria-label={formatMessage(repositoriesColumnHeaderMessages.name)}
          onChange={({ target: { value: newValue } }) => {
            setFilters({
              ...filters,
              name: newValue,
            });
          }}
        />
      ),
    },
  ];

  // Map the basic filters from the query to the enriched values for the filter manager
  const enrichedFilters = useMemo<SupportedFilters>(
    () =>
      pickBy(
        {
          ...filters,
          teams: selectedTeams,
          softwareDevelopmentKits: selectedSoftwareDevelopmentKits,
          owners: selectedOwners,
        },
        (v) => (Array.isArray(v) ? v.length > 0 : v !== undefined),
      ),
    [filters, selectedTeams, selectedOwners, selectedSoftwareDevelopmentKits],
  );

  return (
    <FilterManager
      id="repository-filter"
      defaultSearchKey="text"
      filtersValues={enrichedFilters}
      filtersConfig={filterConfig}
      placeholder={repositoryFilterMessages.textLabel}
      onChange={(key, value) =>
        setFilters({
          ...filters,
          [key]: value,
        })
      }
      clearFilter={(key) => {
        clearSoftwareDevelopmentKitFilters(key);
        clearOwnerFilters(key);
        clearTeamFilters(key);
        setFilters({
          ...filters,
          [key]: undefined,
        });
      }}
    />
  );
};
