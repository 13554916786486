import { AssessmentFormStatus } from '@transcend-io/privacy-types';

import { UserPreview } from '@main/access-control-types';
import { AttributeValue } from '@main/attribute-types';
import { mkInput, mkType, SchemaToType } from '@main/schema-utils';

import { AssessmentFormTemplatePreview } from './assessmentFormTemplate';

export const AssessmentGroupPreview = mkType({
  name: 'AssessmentGroupPreview',
  comment: 'Subset of fields for assessment group',
  fields: {
    id: {
      comment: 'The id of the assessment group',
      type: 'id',
      modelName: 'assessmentGroup',
    },
    title: {
      comment: 'The title of the assessment group',
      type: 'string',
    },
    description: {
      comment: 'The description of the assessment group',
      type: 'string',
    },
  },
});

/** Override type */
export type AssessmentGroupPreview = SchemaToType<
  typeof AssessmentGroupPreview
>;

export const AssessmentGroup = mkType({
  name: 'AssessmentGroup',
  comment: 'Group of assessment forms',
  fields: {
    id: AssessmentGroupPreview.fields.id,
    title: AssessmentGroupPreview.fields.title,
    description: AssessmentGroupPreview.fields.description,
    creator: {
      comment: 'The user who created the assessment group',
      type: UserPreview,
      optional: true,
    },
    lastEditor: {
      comment: 'The user who last edited the assessment group',
      type: UserPreview,
      optional: true,
    },
    reviewers: {
      comment:
        'The users responsible for reviewing new assessment forms in this group',
      type: UserPreview,
      optional: true,
      list: true,
    },
    status: {
      comment:
        'The status of the assessment group, derived from its member assessments',
      type: { AssessmentFormStatus },
    },
    assessmentFormTemplate: {
      comment: 'The template this assessment group was created from',
      type: AssessmentFormTemplatePreview,
      optional: true,
    },
    isArchived: {
      comment: 'Whether the group is archived',
      type: 'boolean',
    },
    dueDate: {
      comment: 'The date by which the assessment group should be completed',
      type: 'Date',
      optional: true,
    },
    createdAt: {
      comment: 'The date the assessment group was created',
      type: 'Date',
    },
    updatedAt: {
      comment: 'The date the assessment group was last updated',
      type: 'Date',
    },
    shareLink: {
      comment:
        'The link that external users can use to create forms under this group',
      type: 'string',
      optional: true,
    },
    attributeValues: {
      comment: 'The attribute values used to label this assessment',
      type: AttributeValue,
      optional: true,
      list: true,
    },
  },
});

/** Override type */
export type AssessmentGroup = SchemaToType<typeof AssessmentGroup>;

export const DeleteAssessmentGroupsInput = mkInput({
  name: 'DeleteAssessmentGroupsInput',
  comment: 'Input for deleting multiple assessment groups',
  fields: {
    ids: {
      comment: 'The id of the assessment groups to delete',
      type: 'id',
      modelName: 'assessmentGroup',
      list: true,
    },
  },
});

/** Override type */
export type DeleteAssessmentGroupsInput = SchemaToType<
  typeof DeleteAssessmentGroupsInput
>;

export const CreateAssessmentGroupInput = mkInput({
  name: 'CreateAssessmentGroupInput',
  comment: 'Input for creating an assessment group',
  fields: {
    title: {
      comment: 'The title of the assessment group',
      type: 'string',
    },
    description: {
      comment: 'The description of the assessment group',
      type: 'string',
      optional: true,
    },
    assessmentFormTemplateId: {
      comment: 'The template that all forms created within this group will use',
      type: 'id',
      modelName: 'assessmentFormTemplate',
    },
  },
});

/** Override type */
export type CreateAssessmentGroupInput = SchemaToType<
  typeof CreateAssessmentGroupInput
>;

export const UpdateAssessmentGroupInput = mkInput({
  name: 'UpdateAssessmentGroupInput',
  comment: 'Input for updating an assessment group',
  fields: {
    id: {
      comment: 'The id of the assessment group',
      modelName: 'assessmentGroup',
      type: 'id',
    },
    title: { ...CreateAssessmentGroupInput.fields.title, optional: true },
    description: {
      ...CreateAssessmentGroupInput.fields.description,
      optional: true,
    },
    isArchived: {
      comment: 'Whether the group to archive or unarchive this group',
      type: 'boolean',
      optional: true,
    },
    dueDate: {
      comment: 'The new due date for filling out the forms within this group',
      type: 'Date',
      optional: true,
    },
    createToken: {
      comment:
        'Whether to create a new token that grants external access for creating forms under this group',
      type: 'boolean',
      optional: true,
    },
    deleteAllTokens: {
      comment:
        'Whether to delete the tokens that grants external access for creating forms under this group',
      type: 'boolean',
      optional: true,
    },
    reviewerIds: {
      comment:
        'The IDs of the users who will be assigned to review new forms in this group',
      type: 'id',
      modelName: 'user',
      optional: true,
      list: true,
    },
  },
});

/** Override type */
export type UpdateAssessmentGroupInput = SchemaToType<
  typeof UpdateAssessmentGroupInput
>;

export const AssessmentGroupFiltersInput = mkInput({
  name: 'AssessmentGroupFiltersInput',
  comment: 'Inputs for filtering a list of assessment groups',
  fields: {
    ids: {
      comment: 'The ids of assessment groups',
      type: 'id',
      modelName: 'assessmentGroup',
      optional: true,
      list: true,
    },
    text: {
      comment: 'The titles or descriptions of assessment groups',
      optional: true,
      type: 'string',
    },
    templateIds: {
      comment: 'The IDs of templates from which assessment groups were created',
      type: 'id',
      modelName: 'assessmentFormTemplate',
      optional: true,
      list: true,
    },
  },
});

/** Override type */
export type AssessmentGroupFiltersInput = SchemaToType<
  typeof AssessmentGroupFiltersInput
>;
