import React from 'react';
import { useIntl } from 'react-intl';

import { TipTapNodeType } from './enums';
import { toolbarMessages } from './messages';
import { ToolbarButton } from './ToolbarButton';
import { ToolbarButtonGroupProps } from './types';
import { StyledSeparator } from './wrappers';

/**
 * Used by Toolbar, additional toolbar buttons for tables
 *
 * @returns additional toolbar buttons
 */
export const TableButtonGroup: React.FC<ToolbarButtonGroupProps> = ({
  editor,
}) => {
  const { formatMessage } = useIntl();

  // If table is not currently being edited, show the "insert table" button
  if (!editor.isActive(TipTapNodeType.Table)) {
    return (
      <ToolbarButton
        title={formatMessage(toolbarMessages.table)}
        onClick={() =>
          editor.chain().focus().insertTable({ rows: 3, cols: 3 }).run()
        }
        icon="insert-table"
        disabled={false}
      />
    );
  }

  return (
    <>
      <ToolbarButton
        title={formatMessage(toolbarMessages.removeTable)}
        onClick={() => editor.chain().focus().deleteTable().run()}
        icon="remove-table"
        disabled={false}
      />

      <StyledSeparator />
      <ToolbarButton
        title={formatMessage(toolbarMessages.addColumnBefore)}
        onClick={() => editor.chain().focus().addColumnBefore().run()}
        disabled={!editor.can().addColumnBefore()}
        icon="insert-table-column-left"
      />

      <ToolbarButton
        title={formatMessage(toolbarMessages.addColumnAfter)}
        onClick={() => editor.chain().focus().addColumnAfter().run()}
        disabled={!editor.can().addColumnAfter()}
        icon="insert-table-column-right"
      />

      <ToolbarButton
        title={formatMessage(toolbarMessages.removeColumn)}
        onClick={() => editor.chain().focus().deleteColumn().run()}
        disabled={!editor.can().deleteColumn()}
        icon="remove-table-column"
      />

      <StyledSeparator />

      <ToolbarButton
        title={formatMessage(toolbarMessages.addRowBefore)}
        onClick={() => editor.chain().focus().addRowBefore().run()}
        disabled={!editor.can().addRowBefore()}
        icon="insert-table-row-above"
      />

      <ToolbarButton
        title={formatMessage(toolbarMessages.addRowAfter)}
        onClick={() => editor.chain().focus().addRowAfter().run()}
        disabled={!editor.can().addRowAfter()}
        icon="insert-table-row-below"
      />

      <ToolbarButton
        title={formatMessage(toolbarMessages.removeRow)}
        onClick={() => editor.chain().focus().deleteRow().run()}
        disabled={!editor.can().deleteRow()}
        icon="remove-table-row"
      />

      <StyledSeparator />

      <ToolbarButton
        title={formatMessage(toolbarMessages.mergeCells)}
        onClick={() => editor.chain().focus().mergeCells().run()}
        disabled={!editor.can().mergeCells()}
        icon="merge-table-cells"
      />

      <ToolbarButton
        title={formatMessage(toolbarMessages.splitCell)}
        onClick={() => editor.chain().focus().splitCell().run()}
        disabled={!editor.can().splitCell()}
        icon="split-table-cell"
      />

      <StyledSeparator />

      <ToolbarButton
        title={formatMessage(toolbarMessages.toggleHeaderColumn)}
        onClick={() => editor.chain().focus().toggleHeaderColumn().run()}
        disabled={!editor.can().toggleHeaderColumn()}
        icon="toggle-table-header-column"
      />

      <ToolbarButton
        title={formatMessage(toolbarMessages.toggleHeaderRow)}
        onClick={() => editor.chain().focus().toggleHeaderRow().run()}
        disabled={!editor.can().toggleHeaderRow()}
        icon="toggle-table-header-row"
      />

      <ToolbarButton
        title={formatMessage(toolbarMessages.toggleHeaderCell)}
        onClick={() => editor.chain().focus().toggleHeaderCell().run()}
        disabled={!editor.can().toggleHeaderCell()}
        icon="toggle-table-header-cell"
      />
    </>
  );
};
