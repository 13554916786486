import { ColorPalette } from '@main/theme';
import React from 'react';

import { PartialRightTagContainer } from './wrappers';

export interface PartialRightTagProps {
  /** The main color of the tag, used for background or border */
  color: keyof ColorPalette;
  /** The tag variant, either filled or outline */
  variant: 'filled' | 'outline';
  /** Main label of the tag (right) */
  label: string | JSX.Element;
  /** the icon to appear at the rightmost point in the tag  */
  icon?: JSX.Element;
  /** Additional styles */
  style: React.CSSProperties;
  /** Index of the tag */
  index: number;
  /** Whether the tag is the last in the list */
  last: boolean;
}

export const PartialRightTag: React.FC<PartialRightTagProps> = ({
  color,
  variant,
  label,
  icon,
  style,
  index,
  last,
}): JSX.Element => (
  <PartialRightTagContainer
    color={color}
    variant={variant}
    last={last}
    style={{
      ...style,
    }}
  >
    {label}
    {icon && icon[index]}
  </PartialRightTagContainer>
);
