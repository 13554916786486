import {
  createDeepState,
  FlexRow,
  GenericMessageDescriptor,
  Icon,
  ModalRawProps,
  StyleUtils,
  useFormatMessageGeneric,
} from '@main/core-ui';
import React, { useEffect, useState } from 'react';
import { useTheme } from 'styled-components';

import { Button, commonButtonMessages } from '../Button';
import { AUTOFOCUS_CONDITIONAL_COMPONENT_PROP } from '../constants';
import { modalMessages } from './messages';
import { StyledModalRaw, SuccessContentWrapper } from './wrappers';

interface DeepModalProps {
  /** the modal width */
  modalWidth?: string | number;
  /** whether to show the success UI */
  showSuccess?: boolean;
  /** handler for when the reset button is clicked, will hide the button if undefined */
  onReset?: () => void;
}

const {
  Provider: DeepModalStateProvider,
  useValueRequired: useUpdateModalProps,
} = createDeepState<DeepModalProps>();

export { useUpdateModalProps };

/**
 * Props for Modal.
 */
export interface ModalProps extends ModalRawProps {
  /** header for the success screen */
  successHeader?: GenericMessageDescriptor;
  /** description for the success screen */
  successDescription?: GenericMessageDescriptor;
}

export const Modal: React.FC<ModalProps> = ({
  successHeader = modalMessages.successHeaderDefault,
  successDescription,
  children,
  width,
  ...modalRawProps
}) => {
  const { formatMessageGeneric } = useFormatMessageGeneric();
  const theme = useTheme();
  const [deepModalProps, setDeepModalProps] = useState<DeepModalProps>({
    modalWidth: width,
  });
  const { modalWidth, showSuccess, onReset } = deepModalProps;

  useEffect(() => {
    if (width !== modalWidth) {
      setDeepModalProps({ modalWidth: width });
    }
  }, [width]);

  return (
    <DeepModalStateProvider
      value={deepModalProps}
      onValueChanged={(newValue) =>
        setDeepModalProps({
          ...deepModalProps,
          ...(newValue ?? { modalWidth: width }),
        })
      }
    >
      <StyledModalRaw
        {...modalRawProps}
        width={modalWidth ?? width}
        $showSuccess={showSuccess}
        onHide={() => {
          modalRawProps?.onHide();
          setDeepModalProps({ showSuccess: false });
        }}
        extraChildren={
          <SuccessContentWrapper
            style={!showSuccess ? { display: 'none' } : {}}
          >
            <div>
              <Icon
                type="checkmark-circle"
                size="10em"
                color={theme.colors.success}
              />
            </div>
            <h2>{formatMessageGeneric(successHeader)}</h2>
            {successDescription && (
              <div>{formatMessageGeneric(successDescription)}</div>
            )}
            {/* conditionally render buttons so that the autofocus will work */}
            {showSuccess && (
              <FlexRow style={{ gap: StyleUtils.Spacing.sm }}>
                <Button
                  variant="secondary"
                  onClick={() => {
                    onReset?.();
                    setDeepModalProps({
                      ...deepModalProps,
                      showSuccess: false,
                    });
                  }}
                >
                  {formatMessageGeneric(modalMessages.startOver)}
                </Button>
                <Button
                  variant="primary"
                  {...AUTOFOCUS_CONDITIONAL_COMPONENT_PROP}
                  onClick={() => {
                    setDeepModalProps({
                      ...deepModalProps,
                      showSuccess: false,
                    });
                    modalRawProps.onHide?.();
                  }}
                >
                  {formatMessageGeneric(commonButtonMessages.close)}
                </Button>
              </FlexRow>
            )}
          </SuccessContentWrapper>
        }
      >
        {children}
      </StyledModalRaw>
    </DeepModalStateProvider>
  );
};
