import React, { useEffect } from 'react';
import { IntlProvider } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import { selectLocale, selectTranslations } from './selectors';
import { useChangeTranslations } from './useChangeTranslations';

/**
 * This component connects the redux state language locale to the
 * IntlProvider component and i18n messages (loaded from `src/translations`)
 *
 * Separate from the core version because immutable JS isn't used here.
 */
export const LanguageProvider: React.FC = ({ children }) => {
  const locale = useSelector(selectLocale);
  const translations = useSelector(selectTranslations);
  const dispatch = useDispatch();
  const changeTranslations = useChangeTranslations();

  // Lazy load in translations
  useEffect(() => {
    changeTranslations(locale);
  }, [locale, dispatch]);

  return (
    <IntlProvider key={locale} locale={locale} messages={translations}>
      {React.Children.only(children)}
    </IntlProvider>
  );
};
