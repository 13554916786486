import { createLoadable } from '@main/core-ui';

import { requestSettingsMessages } from './messages';

// Load the component dynamically
export default createLoadable({
  loader: () => import('./index'),
  messages: requestSettingsMessages,
  logo: 'security-scan',
});
