/**
 * The fields that a code package can be ordered on
 */
export enum CodePackageOrderField {
  /** The name of the code package */
  Name = 'name',
  /** The age of the code package */
  CreatedAt = 'createdAt',
  /** The last updated time */
  UpdatedAt = 'updatedAt',
}
