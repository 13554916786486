import * as t from 'io-ts';

import { dbModelId } from '@main/schema-utils';

import { AuditEventCode } from '../schema/enums';
import { mkAuditEventCodec } from '../types';

export const AuditEventPurpose = mkAuditEventCodec(
  AuditEventCode.TrackingPurpose,
  t.type({
    /** ID of the purpose */
    purposeId: dbModelId('purpose'),
  }),
);

/** Override types. */
export type AuditEventPurpose = t.TypeOf<typeof AuditEventPurpose>;
