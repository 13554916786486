import { defineMessages } from '@transcend-io/internationalization';

export const contractScanningTabMessages = defineMessages(
  'admin-dashboard.ContractScanning.ContractScanningTabs.contractScanningTab',
  {
    contracts: {
      defaultMessage: 'Contracts',
    },
    contractScans: {
      defaultMessage: 'Past Scans',
    },
  },
);
