import { defineMessages } from '@transcend-io/internationalization';

import { DataSiloConnectionState } from '@main/datamap-types';

/**
 * Managing re-connect state
 */
export const dataSiloReConnectionStateMessages = defineMessages<string>(
  'admin-dashboard.components.DisconnectedDataSiloAlert.dataSiloReConnectionState',
  {
    [DataSiloConnectionState.Expired]: {
      defaultMessage: 'Our connection to this integration expired!',
    },
    [DataSiloConnectionState.Partial]: {
      defaultMessage: 'This integration requires further authentication!',
    },
    [DataSiloConnectionState.PermissionsUpdated]: {
      defaultMessage: 'This integration requires new permissions!',
    },
    [DataSiloConnectionState.NotConfigured]: {
      defaultMessage: 'This integration has not yet been configured!',
    },
  },
);
