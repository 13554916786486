import { PreferenceTopicType } from '@transcend-io/privacy-types';

import { DefinedMessage } from '@main/internationalization';
import { mkInput, mkType, SchemaToType } from '@main/schema-utils';

import {
  EnrichedPreferenceOption,
  PreferenceOptionValue,
  PrivacyCenterPreferenceOptionValue,
} from './preferenceOptionValue';
import { PreferenceTopicPreview } from './preferenceTopicPreview';
import { TrackingPurpose, TrackingPurposePreview } from './purpose';

export const PreferenceTopic = mkType({
  name: 'PreferenceTopic',
  comment: 'Preference topic configuration on the Admin Dashboard.',
  fields: {
    id: PreferenceTopicPreview.fields.id,
    slug: PreferenceTopicPreview.fields.slug,
    title: PreferenceTopicPreview.fields.title,
    color: PreferenceTopicPreview.fields.color,
    type: {
      comment: 'The type of preference topic',
      type: { PreferenceTopicType },
    },
    displayDescription: {
      comment: 'The display description of the preference topic',
      type: DefinedMessage,
    },
    showInPrivacyCenter: {
      comment:
        'Whether the preference topic should be shown in the privacy center',
      type: 'boolean',
    },
    defaultConfiguration: {
      comment:
        'The default configuration for this preference topic when the purpose is opted in or out',
      type: 'string',
    },
    preferenceOptionValues: {
      comment: 'The option values for this preference topic',
      type: PreferenceOptionValue,
      list: true,
    },
    purpose: {
      comment: 'The purpose this preference topic is associated with',
      type: TrackingPurposePreview,
    },
  },
});

/** Override type */
export type PreferenceTopic = SchemaToType<typeof PreferenceTopic>;

export const PrivacyCenterPreferenceTopic = mkType({
  name: 'PrivacyCenterPreferenceTopic',
  comment:
    'Privacy Center Preference topic configuration on the Privacy Center.',
  fields: {
    title: PreferenceTopic.fields.title,
    displayDescription: PreferenceTopic.fields.displayDescription,
    slug: PreferenceTopic.fields.slug,
    type: PreferenceTopic.fields.type,
    defaultConfiguration: PreferenceTopic.fields.defaultConfiguration,
    preferenceOptionValues: {
      comment: 'The option values for this preference topic',
      type: PrivacyCenterPreferenceOptionValue,
      list: true,
    },
  },
});

/** Override type */
export type PrivacyCenterPreferenceTopic = SchemaToType<
  typeof PrivacyCenterPreferenceTopic
>;

export const PrivacyCenterTrackingPurposePreview = mkType({
  name: 'PrivacyCenterTrackingPurposePreview',
  comment: 'Preview of purpose with subset of fields',
  fields: {
    title: {
      comment: 'The display title of the purpose',
      type: DefinedMessage,
    },
    displayDescription: {
      comment: 'The display description of the purpose',
      type: DefinedMessage,
    },
    trackingType: TrackingPurpose.fields.trackingType,
    isActive: TrackingPurpose.fields.isActive,
    authLevel: TrackingPurpose.fields.authLevel,
    showInPrivacyCenter: TrackingPurpose.fields.showInPrivacyCenter,
    preferenceTopics: {
      comment: 'Topics associated with this purpose',
      type: PrivacyCenterPreferenceTopic,
      optional: true,
      list: true,
    },
  },
});

/** Override type */
export type PrivacyCenterTrackingPurposePreview = SchemaToType<
  typeof PrivacyCenterTrackingPurposePreview
>;

export const PreferenceTopicFilterInput = mkInput({
  name: 'PreferenceTopicFilterInput',
  comment: 'Filter input for preferences',
  fields: {
    text: {
      type: 'string',
      comment: 'Filter by name',
      optional: true,
    },
    purposeIds: {
      type: 'id',
      modelName: 'purpose',
      comment: 'Filter the preference topics by purpose IDs',
      list: true,
      optional: true,
    },
    showInPrivacyCenter: {
      type: 'boolean',
      comment: 'Filter the preference topics by showInPrivacyCenter',
      optional: true,
    },
    trackingTypes: {
      type: 'string',
      comment: 'Filter the preference topics by tracking types',
      optional: true,
      list: true,
    },
  },
});

/** Override type */
export type PreferenceTopicFilterInput = SchemaToType<
  typeof PreferenceTopicFilterInput
>;

export const CreateOrUpdatePreferenceTopicInput = mkInput({
  name: 'CreateOrUpdatePreferenceTopicInput',
  comment: 'Input for upserting a preference',
  fields: {
    id: {
      type: 'id',
      modelName: 'preferenceTopic',
      comment: 'The ID of the preference topic to upsert',
      optional: true,
    },
    title: {
      type: 'string',
      comment: 'The display name of this preference topic',
      optional: true,
    },
    slug: {
      type: 'string',
      comment: 'The slug of the preference topic',
      optional: true,
    },
    type: {
      type: { PreferenceTopicType },
      comment: 'The type of preference topic',
      optional: true,
    },
    displayDescription: {
      type: 'string',
      comment: 'preference topic description (used in preference Store UI) ',
      optional: true,
    },
    showInPrivacyCenter: {
      type: 'boolean',
      comment:
        'Whether the preference topic should be shown in the privacy center',
      optional: true,
    },
    defaultConfiguration: {
      type: 'string',
      comment:
        'The default configuration for this preference topic when the purpose is opted in or out',
      optional: true,
    },
    purposeId: {
      type: 'id',
      modelName: 'purpose',
      comment: 'The purpose this preference topic is associated with',
      optional: true,
    },
    preferenceOptionValueIds: {
      type: 'id',
      modelName: 'preferenceOptionValue',
      comment: 'The options this preference topic is associated with',
      list: true,
      optional: true,
    },
  },
});

/** Override type */
export type CreateOrUpdatePreferenceTopicInput = SchemaToType<
  typeof CreateOrUpdatePreferenceTopicInput
>;

export const DeletePreferenceTopicsInput = mkInput({
  name: 'DeletePreferenceTopicsInput',
  comment: 'Input for deleting a preference',
  fields: {
    ids: {
      type: 'id',
      modelName: 'preferenceTopic',
      comment: 'ID of the preference topic to delete',
      list: true,
    },
  },
});

/** Override type */
export type DeletePreferenceTopicsInput = SchemaToType<
  typeof DeletePreferenceTopicsInput
>;

export const EnrichedPreference = mkType({
  name: 'EnrichedPreference',
  comment: 'Represents a preference with all metadata enriched',
  fields: {
    id: {
      comment: 'A unique ID for the preference change',
      type: 'string',
    },
    name: {
      comment: 'The unique name of the preference option slug',
      type: 'string',
    },
    selectValue: {
      comment: 'The single select value of the preference',
      type: EnrichedPreferenceOption,
      optional: true,
    },
    booleanValue: {
      comment: 'The boolean value of the preference',
      type: 'boolean',
      optional: true,
    },
    selectValues: {
      comment: 'The multi select values of the preference',
      type: EnrichedPreferenceOption,
      list: true,
      optional: true,
    },
    preferenceTopic: {
      comment:
        'The preference topic metadata for this. If not provided, this may have been deleted',
      type: PreferenceTopicPreview,
      optional: true,
    },
  },
});

/** Override type */
export type EnrichedPreference = SchemaToType<typeof EnrichedPreference>;
