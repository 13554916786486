import { combineReducers } from '@reduxjs/toolkit';
import {
  createMigrate,
  MigrationManifest,
  persistReducer,
} from 'redux-persist';
import autoMergeLevel1 from 'redux-persist/lib/stateReconciler/autoMergeLevel1';
import storage from 'redux-persist/lib/storage';

import { persistedFiltersSlice } from '@main/core-ui';

import { actionItemSlice } from './Auth/ActionItems/slice';
import { adminDashboardAuthSlice } from './Auth/App/slice';
import { languageProviderSlice } from './Auth/LanguageProvider/slice';
import { consentManagerV2Slice } from './ConsentManager/slice';
import { dataMapV2Slice } from './DataMap/slice';
import { ctaBannersSlice } from './hooks';
import { connectSiloSlice } from './Infrastructure/ConnectSilo/slice';
import { setSubdatapointSettingsSlice } from './Infrastructure/Integrations/DataSiloDetails/ManageDatapoints/SetSubdatapointSettings/slice'; // eslint-disable-line max-len
import { dataSiloManageDataPointsSlice } from './Infrastructure/Integrations/DataSiloDetails/ManageDatapoints/slice'; // eslint-disable-line max-len
import { preferenceStoreSlice } from './PreferenceStore/slice';
import { dataPracticesSlice } from './PrivacyCenter/DataPractices/slice';
import { policiesSlice } from './PrivacyCenter/Policies/slice';
import {
  privacyCenterSlice,
  requestsProcessedDisclosureStatsSettingsSlice,
} from './PrivacyCenter/slice';
import { legalHoldSlice } from './PrivacyRequests/LegalHolds/slice';
import { requestDataSiloSlice } from './PrivacyRequests/Request/RequestDataSilo/slice';
import { requestSlice } from './PrivacyRequests/Request/slice';
import { dsrAutomationSlice } from './PrivacyRequests/slice';
import { routesSlice } from './routes/slice';

export const adminDashboardPreloadedReducers = {
  [actionItemSlice.name]: actionItemSlice.reducer,
  [setSubdatapointSettingsSlice.name]: setSubdatapointSettingsSlice.reducer,
  [adminDashboardAuthSlice.name]: adminDashboardAuthSlice.reducer,
  [languageProviderSlice.name]: languageProviderSlice.reducer,
  [routesSlice.name]: routesSlice.reducer,
  [legalHoldSlice.name]: legalHoldSlice.reducer,
  [connectSiloSlice.name]: connectSiloSlice.reducer,
  [privacyCenterSlice.name]: privacyCenterSlice.reducer,
  [dataSiloManageDataPointsSlice.name]: dataSiloManageDataPointsSlice.reducer,
  [dataPracticesSlice.name]: dataPracticesSlice.reducer,
  [policiesSlice.name]: policiesSlice.reducer,
  [requestSlice.name]: requestSlice.reducer,
  [requestDataSiloSlice.name]: requestDataSiloSlice.reducer,
  [consentManagerV2Slice.name]: consentManagerV2Slice.reducer,
  [dataMapV2Slice.name]: dataMapV2Slice.reducer,
  [ctaBannersSlice.name]: ctaBannersSlice.reducer,
  [dsrAutomationSlice.name]: dsrAutomationSlice.reducer,
  [persistedFiltersSlice.name]: persistedFiltersSlice.reducer,
  [requestsProcessedDisclosureStatsSettingsSlice.name]:
    requestsProcessedDisclosureStatsSettingsSlice.reducer,
  [preferenceStoreSlice.name]: preferenceStoreSlice.reducer,
};

/** The root reducer without persistance */
export const adminDashboardRootReducer = combineReducers(
  adminDashboardPreloadedReducers,
);

/**
 * Migrate the shape of the store
 *
 * Each migration should take in state and return state. The typings in the package are incorrect.
 * See: https://github.com/rt2zz/redux-persist/issues/1065
 *
 * If you add a migration here, you must also increment the version in the persistReducer call below.
 */
const migrations: MigrationManifest = {
  // These migrations are no longer necessary
  0: (state) => state,
  1: (state) => state,
  2: (state) => state,
  3: (state) => state,
};

/** Persist the store between page reloads */
export const adminDashboardPersistedReducer: typeof adminDashboardRootReducer =
  persistReducer(
    {
      // whenever the shape of the store changes, this version can be updated to
      // which will tell the browser to ignore the old cached values
      version: 3,
      key: 'root',
      // in theory localForage will be preferable for better perf, but
      // 1) https://github.com/rt2zz/redux-persist/issues/1148 &
      // 2) we're likely to see more significant perf gains streamlining what we
      // write to our store + persist independent of storage tooling choices
      // TODO: https://github.com/transcend-io/main/issues/2866 - remove this as a default once we're further along in
      storage,
      migrate: createMigrate(migrations),
      stateReconciler: autoMergeLevel1,
      whitelist: [
        'LanguageProvider',
        'ctaBanners',
        'AdminDashboardAuth',
        'PersistedFilters',
        'ActionItem',
      ],
    },
    adminDashboardRootReducer as any,
  ) as any;
