let codecTester: HTMLMediaElement;

/**
 * Determine if the file mimetype is known for displaying
 *
 * @param mimetype - The mimetype to test for viewing
 * @returns 'probably', 'maybe', or '' depending on if mime type can be displayed
 */
export function isViewableMimetype(mimetype: string): CanPlayTypeResult {
  if (!codecTester) {
    codecTester = document.createElement('video');
  }
  const lowerMimetype = mimetype.toLowerCase();
  if (
    lowerMimetype.startsWith('video/') ||
    lowerMimetype.startsWith('audio/')
  ) {
    return codecTester.canPlayType(lowerMimetype);
  }
  const viewableText =
    /^\s*(?:text\/\S*|application\/(?:xml|json)|\S*\/\S*\+xml|\S*\/\S*\+json)\s*(?:$|;)/;
  return (
    ((viewableText.test(lowerMimetype) || lowerMimetype.startsWith('image/')) &&
      /**
       * Unfortunately there isn't a canPlayType for images
       * Not all image formats are supported. For example,
       * see https://caniuse.com/#feat=heif
       */
      'probably') ||
    ''
  );
}
