/**
 * The fields that an prompt partial can be ordered on
 */
export enum PromptPartialOrderField {
  /** The title of the prompt partial */
  Title = 'title',
  /** The age of the prompt partial */
  CreatedAt = 'createdAt',
  /** The last updated time */
  UpdatedAt = 'updatedAt',
}
