/* eslint-disable max-lines */
import {
  BackendSyncOption,
  ConsentBundleType,
  ConsentPrecedenceOption,
  CspOption,
  SignedIabAgreementOption,
  TelemetryPartitionStrategy,
  UnknownRequestPolicy,
  UspapiOption,
} from '@transcend-io/privacy-types';

import { ConsentManagerOnboardingStatus } from '@main/access-control-types';
import { CustomHeader, CustomHeaderInput } from '@main/datamap-types';
import { mkInput, mkType, SchemaToType } from '@main/schema-utils';

import {
  ConsentManagerBundleConfig,
  ConsentManagerBundleConfigInput,
  SyncedConsentManagerBundleConfig,
} from './consentManagerConfiguration';
import { ConsentPartition } from './consentPartition';
import {
  AirgapIssueSeverityGQL,
  AnalyticsDimension,
  AnalyticsEvent,
  BundleDeployStatus,
} from './enums';

export const DeployConsentManagerInput = mkInput({
  name: 'DeployConsentManagerInput',
  comment:
    '[DEPRECATED] The options for deploying/publishing changes to the consent manager',
  fields: {
    bundleType: {
      comment:
        'Whether to update the "test" consent manager bundle hosted at /cm-test.' +
        ' Or to update the "production" consent manager bundle hosted at /cm. ' +
        ' Defaults to updating the production bundle.',
      type: { ConsentBundleType },
      optional: true,
    },
  },
});

/** Override type */
export type DeployConsentManagerInput = SchemaToType<
  typeof DeployConsentManagerInput
>;

export const ConsentManager = mkType({
  name: 'ConsentManager',
  comment:
    'The configuration and metadata for the consent manager (aka Airgap).',
  fields: {
    id: {
      comment: 'The ID of this Consent Manager bundle.',
      modelName: 'airgapBundle',
      type: 'id',
    },
    name: {
      comment: 'The name of this Consent Manager bundle.',
      type: 'string',
    },
    off: {
      comment: 'Whether the consent manager should regulate requests.',
      type: 'boolean',
    },
    state: {
      comment: 'The state of this current Consent Manager bundle.',
      type: { BundleDeployStatus },
    },
    configuration: {
      comment: 'The configuration for this Consent Manager.',
      type: () => ConsentManagerBundleConfig,
    },
    bundleURL: {
      comment:
        'The absolute URL of where the consent manager JS bundle is hosted.',
      type: 'string',
    },
    testBundleURL: {
      comment:
        'The absolute URL of where the test bundle of the consent manager is hosted.',
      type: 'string',
    },
    onboardingStatus: {
      comment: 'The onboarding status of this consent manager',
      type: { ConsentManagerOnboardingStatus },
    },
    lastDeployedAt: {
      comment:
        'When the Consent Manager was last deployed (may be undefined if never deployed)',
      type: 'Date',
      optional: true,
    },
    version: {
      comment: 'The airgap.js core library version',
      type: 'string',
    },
    canUpdateVersion: {
      comment:
        'Whether or not there is a later airgap.js version that this bundle can upgrade to',
      type: 'boolean',
    },
    useDefaultTcfSettings: {
      comment:
        'Whether or not this consent manager uses the default TCF settings',
      type: 'boolean',
      optional: true,
    },
    tcfUiBundleURL: {
      comment:
        'The absolute URL of where the tcf ui bundle of the consent manager is hosted.',
      type: 'string',
    },
    partition: {
      comment: 'The selected consent partition',
      type: ConsentPartition,
      optional: true,
    },
    customDomain: {
      comment: 'The custom domain from which to load airgap modules',
      type: 'string',
      optional: true,
    },
    isTranscendHosted: {
      comment:
        'Are the modules for this bundle hosted by Transcend, or is the customer hosting them?',
      type: 'boolean',
    },
    useModules: {
      comment: 'Whether to use experience-specific modules',
      type: 'boolean',
    },
    useIabFramework: {
      comment: 'Whether to use IAB Consent Frameworks',
      type: 'boolean',
    },
    deployError: {
      comment: 'The error was encountered during bundle deployment, if any',
      type: 'string',
      optional: true,
    },
    webhookUrl: {
      comment:
        'The URL to send POST request notifications to whenever a deployment occurs',
      type: 'string',
      optional: true,
    },
    headers: {
      comment:
        'Custom headers to include in outbound deployment webhook notification',
      list: true,
      type: CustomHeader,
    },
    prefixScript: {
      comment: 'Custom JS script to run before airgap.js loads',
      type: 'string',
      optional: true,
    },
    suffixScript: {
      comment: 'Custom JS script to run after airgap.js loads',
      type: 'string',
      optional: true,
    },
  },
});

/** Override type */
export type ConsentManager = SchemaToType<typeof ConsentManager>;

export const CreateConsentManagerInput = mkInput({
  name: 'CreateConsentManagerInput',
  comment: 'The input for creating a new consent manager (aka Airgap).',
  fields: {
    name: {
      comment:
        'The name of this Consent Manager bundle. Defaults to the organization uri.',
      type: 'string',
      optional: true,
    },
    privacyCenterId: {
      comment: 'The ID of the associated privacy center',
      type: 'id',
      modelName: 'privacyCenter',
    },
    off: {
      comment:
        'True means your consent manager will only report telemetry data and does not regulate requests. ' +
        'Defaults to true.',
      type: 'boolean',
      optional: true,
    },
    configuration: {
      comment: 'The configuration for this Consent Manager.',
      type: () => ConsentManagerBundleConfigInput,
      optional: true,
    },
    webhookUrl: {
      comment:
        'The URL to send POST request notifications to whenever a deployment occurs',
      type: 'string',
      optional: true,
    },
    headers: {
      comment: 'Custom headers to include in outbound deployment webhook',
      list: true,
      optional: true,
      type: CustomHeaderInput,
    },
  },
});

/** Override type */
export type CreateConsentManagerInput = SchemaToType<
  typeof CreateConsentManagerInput
>;

export const ToggleUnknownRequestPolicyInput = mkInput({
  name: 'ToggleUnknownRequestPolicyInput',
  comment:
    'Toggle the Content Security Policy directive for the Consent Manager',
  fields: {
    id: {
      comment: 'The ID of this Consent Manager bundle.',
      modelName: 'airgapBundle',
      type: 'id',
    },
    unknownRequestPolicy: {
      comment:
        'What the consent manager should do when it encounters unknown requests',
      type: { UnknownRequestPolicy },
    },
  },
});

/** Override type */
export type ToggleUnknownRequestPolicyInput = SchemaToType<
  typeof ToggleUnknownRequestPolicyInput
>;

export const ToggleUnknownCookiePolicyInput = mkInput({
  name: 'ToggleUnknownCookiePolicyInput',
  comment: 'Toggle the unknown cookie policy for the Consent Manager',
  fields: {
    id: {
      comment: 'The ID of this Consent Manager bundle.',
      modelName: 'airgapBundle',
      type: 'id',
    },
    unknownCookiePolicy: {
      comment:
        'What the consent manager should do when it encounters unknown cookies',
      type: { UnknownRequestPolicy },
    },
  },
});

/** Override type */
export type ToggleUnknownCookiePolicyInput = SchemaToType<
  typeof ToggleUnknownCookiePolicyInput
>;

export const ToggleTelemetryPartitionStrategyInput = mkInput({
  name: 'ToggleTelemetryPartitionStrategyInput',
  comment: 'Input for toggling the Consent telemetry partitioning strategy',
  fields: {
    id: {
      comment: 'The ID of this Consent Manager bundle.',
      modelName: 'airgapBundle',
      type: 'id',
    },
    strategy: {
      comment: 'How the Consent Manager should partition telemetry data',
      type: { TelemetryPartitionStrategy },
    },
  },
});

/** Override type */
export type ToggleTelemetryPartitionStrategyInput = SchemaToType<
  typeof ToggleTelemetryPartitionStrategyInput
>;

export const ToggleConsentManagerEnforcementInput = mkInput({
  name: 'ToggleConsentManagerEnforcementInput',
  comment: 'Toggle the consent manager enforcement on/off.',
  fields: {
    id: {
      comment: 'The ID of this Consent Manager bundle.',
      modelName: 'airgapBundle',
      type: 'id',
    },
    off: {
      comment: 'Whether the consent manager should regulate requests.',
      type: 'boolean',
    },
  },
});

/** Override type */
export type ToggleConsentManagerEnforcementInput = SchemaToType<
  typeof ToggleConsentManagerEnforcementInput
>;

export const UpdateDomainListInput = mkInput({
  name: 'UpdateDomainListInput',
  comment: 'List of domains that the consent manager is allowed to run on',
  fields: {
    id: {
      comment: 'The ID of this Consent Manager bundle.',
      modelName: 'airgapBundle',
      type: 'id',
    },
    domains: {
      comment: 'List of domains',
      type: 'string',
      list: true,
    },
  },
});

/** Override type */
export type UpdateDomainListInput = SchemaToType<typeof UpdateDomainListInput>;

export const SyncedConsentManager = mkType({
  name: 'SyncedConsentManager',
  comment: 'A consent manager to sync data privacy preferences with',
  fields: {
    id: ConsentManager.fields.id,
    configuration: {
      comment: 'The sync configuration for this Consent Manager.',
      type: () => SyncedConsentManagerBundleConfig,
    },
    bundleURL: {
      comment:
        'The absolute URL of where the consent manager JS bundle is hosted.',
      type: 'string',
    },
    testBundleURL: {
      comment:
        'The absolute URL of where the test bundle of the consent manager is hosted.',
      type: 'string',
    },
    tcfUiBundleURL: {
      comment:
        'The absolute URL of where the tcf ui bundle of the consent manager is hosted.',
      type: 'string',
    },
  },
});

/** Override type */
export type SyncedConsentManager = SchemaToType<typeof SyncedConsentManager>;

export const AirgapBundleTimeseriesAnalyticsInput = mkInput({
  name: 'AirgapBundleTimeseriesAnalyticsInput',
  comment: 'Input for querying timeseries analytics data for an airgap bundle',
  fields: {
    metric: {
      comment: 'The metric to query for',
      type: { AnalyticsEvent },
    },
    start: {
      comment: 'The start time of the window to analyze',
      type: 'int',
    },
    end: {
      comment: 'The end time of the window to analyze',
      type: 'int',
    },
    binInterval: {
      comment: 'The time interval to bin datapoints by',
      type: 'string',
    },
    forceRefetch: {
      comment: 'Whether the cache should ignore the ttl and manually refetch',
      type: 'boolean',
      optional: true,
    },
  },
});

/** Override type */
export type AirgapBundleTimeseriesAnalyticsInput = SchemaToType<
  typeof AirgapBundleTimeseriesAnalyticsInput
>;

export const AirgapBundleTimeseriesAnalyticsItem = mkType({
  name: 'AirgapBundleTimeseriesAnalyticsItem',
  comment: 'A single item containing timeseries analytics data',
  fields: {
    metric: {
      comment: 'The name of the metric this item measures',
      type: { AnalyticsEvent },
    },
    measure: {
      comment: 'The value of the item',
      type: 'string',
    },
    time: {
      comment: 'The time this item represents',
      type: 'int',
    },
  },
});

/** Override type */
export type AirgapBundleTimeseriesAnalyticsItem = SchemaToType<
  typeof AirgapBundleTimeseriesAnalyticsItem
>;

export const AirgapBundleTimeseriesAnalyticsResult = mkType({
  name: 'AirgapBundleTimeseriesAnalyticsResult',
  comment: 'Airgap timeseries analytics data',
  fields: {
    items: {
      comment: 'The name of the metric this item measures',
      type: AirgapBundleTimeseriesAnalyticsItem,
      list: true,
    },
    cachedAt: {
      comment: 'The last time the data was fetched',
      type: 'Date',
      optional: true,
    },
  },
});

/** Override type */
export type AirgapBundleTimeseriesAnalyticsResult = SchemaToType<
  typeof AirgapBundleTimeseriesAnalyticsResult
>;

/**
 * The transformed time series data, with measure being a number instead of string
 * GraphQL Int can only represent 32-bit integers, whereas JS can handle 64 Int
 */
export type TransformedTimeseriesAnalyticsItem = Omit<
  AirgapBundleTimeseriesAnalyticsItem,
  'measure'
> & {
  /** The value of this analytics item, as a number */
  measure: number;
};

export const AirgapBundleAggregateAnalyticsInput = mkInput({
  name: 'AirgapBundleAggregateAnalyticsInput',
  comment: 'Input for querying aggregate analytics data for an airgap bundle',
  fields: {
    metric: AirgapBundleTimeseriesAnalyticsInput.fields.metric,
    start: AirgapBundleTimeseriesAnalyticsInput.fields.start,
    end: AirgapBundleTimeseriesAnalyticsInput.fields.end,
    includeDimensions: {
      comment: 'Dimensions to include in the response',
      type: { AnalyticsDimension },
      list: true,
      optional: true,
    },
    forceRefetch: {
      comment: 'Whether the cache should ignore the ttl and manually refetch',
      type: 'boolean',
      optional: true,
    },
  },
});

/** Override type */
export type AirgapBundleAggregateAnalyticsInput = SchemaToType<
  typeof AirgapBundleAggregateAnalyticsInput
>;

export const AnalyticsDimensions = mkType({
  name: 'AnalyticsDimensions',
  comment: 'Dimensions from a query of telemetry analytics',
  fields: Object.values(AnalyticsDimension).reduce(
    (acc, dimension) => ({
      ...acc,
      [dimension]: {
        comment: `The dimension value for ${dimension}`,
        type: 'string',
        optional: true,
      },
    }),
    {} as {
      [k in (typeof AnalyticsDimension)[keyof typeof AnalyticsDimension]]: {
        /** Comment for the dimension */
        comment: string;
        /** The type of the field */
        type: 'string';
        /** The dimension is always an optional field */
        optional: true;
      };
    },
  ),
});

/** Override type */
export type AnalyticsDimensions = SchemaToType<typeof AnalyticsDimensions>;

export const AirgapBundleAggregateAnalyticsItem = mkType({
  name: 'AirgapBundleAggregateAnalyticsItem',
  comment: 'A single item containing aggregate analytics data',
  fields: {
    metric: AirgapBundleTimeseriesAnalyticsItem.fields.metric,
    measure: AirgapBundleTimeseriesAnalyticsItem.fields.measure,
    dimensions: {
      comment: 'Additional dimension fields for the item',
      type: AnalyticsDimensions,
      optional: true,
    },
  },
});

/** Override type */
export type AirgapBundleAggregateAnalyticsItem = SchemaToType<
  typeof AirgapBundleAggregateAnalyticsItem
>;

export const AirgapBundleAggregateAnalyticsResult = mkType({
  name: 'AirgapBundleAggregateAnalyticsResult',
  comment: 'Airgap aggregate analytics data result',
  fields: {
    items: {
      comment: 'The list of results',
      type: AirgapBundleAggregateAnalyticsItem,
      list: true,
    },
    cachedAt: {
      comment: 'The last time the data was fetched',
      type: 'Date',
      optional: true,
    },
  },
});

/** Override type */
export type AirgapBundleAggregateAnalyticsResult = SchemaToType<
  typeof AirgapBundleAggregateAnalyticsResult
>;

/**
 * The transformed time series data, with measure being a number instead of string
 * GraphQL Int can only represent 32-bit integers, whereas JS can handle 64 Int
 */
export type TransformedAggregateAnalyticsItem = Omit<
  AirgapBundleAggregateAnalyticsItem,
  'measure'
> & {
  /** The value of this analytics item, as a number */
  measure: number;
};

export const ToggleUseDefaultTcfSettingsInput = mkInput({
  name: 'ToggleUseDefaultTcfSettingsInput',
  comment:
    'Toggle whether or not this Consent Manager bundle should the default settings for TCF',
  fields: {
    id: {
      comment: 'The ID of this Consent Manager bundle.',
      modelName: 'airgapBundle',
      type: 'id',
    },
    useDefaultTcfSettings: {
      comment: 'Whether to use the default settings for TCF',
      type: 'boolean',
      optional: true,
    },
  },
});

/** Override type */
export type ToggleUseDefaultTcfSettingsInput = SchemaToType<
  typeof ToggleUseDefaultTcfSettingsInput
>;

export const ToggleUspapiInput = mkInput({
  name: 'ToggleUspapiInput',
  comment: 'Input for toggling the Consent US Privacy API',
  fields: {
    id: {
      comment: 'The ID of this Consent Manager bundle.',
      modelName: 'airgapBundle',
      type: 'id',
    },
    uspapi: {
      comment: 'Whether or not to use the US Privacy API',
      type: { UspapiOption },
      optional: true,
    },
    signedIabAgreement: {
      comment: 'Whether the site owner has signed the IAB agreement',
      type: { SignedIabAgreementOption },
      optional: true,
    },
  },
});

/** Override type */
export type ToggleUspapiInput = SchemaToType<typeof ToggleUspapiInput>;

export const ToggleConsentPrecedenceInput = mkInput({
  name: 'ToggleConsentPrecedenceInput',
  comment: 'Input for toggling the Consent precedence',
  fields: {
    id: {
      comment: 'The ID of this Consent Manager bundle.',
      modelName: 'airgapBundle',
      type: 'id',
    },
    consentPrecedence: {
      comment: 'Which consent source does consent manager gives precedence to',
      type: { ConsentPrecedenceOption },
    },
  },
});

/** Override type */
export type ToggleConsentPrecedenceInput = SchemaToType<
  typeof ToggleConsentPrecedenceInput
>;

export const UpdateSyncGroupsInput = mkInput({
  name: 'UpdateSyncGroupsInput',
  comment: 'Input for updating sync groups',
  fields: {
    id: {
      comment: 'The ID of this Consent Manager bundle.',
      modelName: 'airgapBundle',
      type: 'id',
    },
    syncGroups: {
      comment:
        'JSON string with airgapBundleId as key and array of domains as value',
      type: 'string',
      optional: true,
    },
  },
});

/** Override type */
export type UpdateSyncGroupsInput = SchemaToType<typeof UpdateSyncGroupsInput>;

export const UpdateConsentManagerPartitionInput = mkInput({
  name: 'UpdateConsentManagerPartitionInput',
  comment: 'Input for updating consent partition key',
  fields: {
    id: {
      comment: 'The ID of this Consent Manager bundle.',
      modelName: 'airgapBundle',
      type: 'id',
    },
    partitionId: {
      comment: 'The ID of the partition to use for this bundle',
      type: 'id',
      modelName: 'airgapPartition',
      optional: true,
    },
  },
});

/** Override type */
export type UpdateConsentManagerPartitionInput = SchemaToType<
  typeof UpdateConsentManagerPartitionInput
>;

export const UpdateSyncEndpointInput = mkInput({
  name: 'UpdateSyncEndpointInput',
  comment: 'Input for updating the airgap XDI consent sync endpoint URL',
  fields: {
    id: {
      comment: 'The ID of this consent manager bundle.',
      modelName: 'airgapBundle',
      type: 'id',
    },
    syncEndpoint: {
      comment: 'XDI consent sync endpoint URL',
      type: 'string',
    },
  },
});

/** Override type */
export type UpdateSyncEndpointInput = SchemaToType<
  typeof UpdateSyncEndpointInput
>;

export const UpdateLoadOptionsInput = mkInput({
  name: 'UpdateLoadOptionsInput',
  comment: 'Input for updating the loadOptions for an airgap bundle',
  fields: {
    id: {
      comment: 'The id for this consent manager bundle.',
      modelName: 'airgapBundle',
      type: 'id',
    },
    backendSync: {
      comment: 'Backend consent sync',
      type: { BackendSyncOption },
      optional: true,
    },
    backendSyncEndpoint: {
      comment: 'Backend consent sync endpoint URL',
      type: 'string',
      optional: true,
    },
    syncEndpoint: {
      comment: 'XDI consent sync endpoint URL',
      type: 'string',
      optional: true,
    },
    partition: {
      comment: 'Key used to partition consent records',
      type: 'string',
      optional: true,
    },
    syncGroups: {
      comment:
        'JSON string with airgapBundleId as key and array of domains as value',
      type: 'string',
      optional: true,
    },
    consentPrecedence: {
      comment: 'Which consent source does consent manager gives precedence to',
      type: { ConsentPrecedenceOption },
      optional: true,
    },
    uspapi: {
      comment: 'Whether or not to use the US Privacy API',
      type: { UspapiOption },
      optional: true,
    },
    signedIabAgreement: {
      comment: 'Whether the site owner has signed the IAB agreement',
      type: { SignedIabAgreementOption },
      optional: true,
    },
    telemetryPartitioning: {
      comment: 'How the Consent Manager should partition telemetry data',
      type: { TelemetryPartitionStrategy },
      optional: true,
    },
    unknownCookiePolicy: {
      comment:
        'What the consent manager should do when it encounters unknown cookies',
      type: { UnknownRequestPolicy },
      optional: true,
    },
    unknownRequestPolicy: {
      comment:
        'What the consent manager should do when it encounters unknown requests',
      type: { UnknownRequestPolicy },
      optional: true,
    },
    csp: {
      comment: 'The Content Security Policy option',
      type: { CspOption },
      optional: true,
    },
    /** @deprecated - TODO: https://transcend.height.app/T-28974 - remove */
    telemetrySampleRate: {
      comment: 'The Sampling Rate for telemetry data',
      type: 'string',
      optional: true,
    },
    reqCookieSampleRate: {
      comment:
        'Client-side sampling rate for outgoing requests and cookie mutations',
      type: 'string',
      optional: true,
    },
  },
});

/** Override type */
export type UpdateLoadOptionsInput = SchemaToType<
  typeof UpdateLoadOptionsInput
>;

export const UpdateConsentManagerInput = mkInput({
  name: 'UpdateConsentManagerInput',
  comment: 'Input for updating the consent manager',
  fields: {
    customDomain: {
      comment:
        'The custom domain from which to load airgap modules, only compatible with Cloudflare CDN or customer-hosted CDN.',
      type: 'string',
      optional: true,
    },
    version: {
      comment: 'The version of airgap.js to use',
      type: 'string',
      optional: true,
    },
    isTranscendHosted: {
      comment:
        "Whether or not the custom sync endpoints are hosted by Transcend or on the customer's own CDN. Defaults to true.",
      type: 'boolean',
      optional: true,
    },
    useModules: {
      comment: 'Whether to use experience-specific modules',
      type: 'boolean',
      optional: true,
    },
    useIabFramework: {
      comment: 'Whether to use IAB Consent Frameworks',
      type: 'boolean',
      optional: true,
    },
    webhookUrl: {
      comment:
        'The URL to send POST request notifications to whenever a deployment occurs',
      type: 'string',
      optional: true,
    },
    headers: {
      comment: 'Custom headers to include in outbound deployment webhook',
      list: true,
      optional: true,
      type: CustomHeaderInput,
    },
  },
});

/** Override type */
export type UpdateConsentManagerInput = SchemaToType<
  typeof UpdateConsentManagerInput
>;

export const SyncEndpointCustomDomain = mkType({
  name: 'SyncEndpointCustomDomain',
  comment: 'Custom domain hosting a sync endpoint',
  fields: {
    domain: {
      comment: 'The custom domain',
      type: 'string',
    },
    isDefault: {
      comment: 'Whether this is the default sync endpoint',
      type: 'boolean',
    },
  },
});

/** Override type */
export type SyncEndpointCustomDomain = SchemaToType<
  typeof SyncEndpointCustomDomain
>;

export const MakeDefaultSyncEndpointInput = mkInput({
  name: 'MakeDefaultSyncEndpointInput',
  comment: 'Sync endpoint to make default',
  fields: {
    domain: {
      comment: 'Sync endpoint to make default',
      type: 'string',
    },
  },
});

/** Override type */
export type MakeDefaultSyncEndpointInput = SchemaToType<
  typeof MakeDefaultSyncEndpointInput
>;

export const SyncEndpointCustomDomainInput = mkInput({
  name: 'SyncEndpointCustomDomainInput',
  comment: 'Custom domain hosting a sync endpoint',
  fields: {
    domain: SyncEndpointCustomDomain.fields.domain,
    isDefault: {
      ...SyncEndpointCustomDomain.fields.isDefault,
      optional: true,
    },
  },
});

/** Override type */
export type SyncEndpointCustomDomainInput = SchemaToType<
  typeof SyncEndpointCustomDomainInput
>;

export const SetSyncEndpointInput = mkInput({
  name: 'SetSyncEndpointInput',
  comment: 'list of custom domains hosting the sync endpoint',
  fields: {
    syncEndpoints: {
      comment: 'List of custom domains hosting the sync endpoint',
      type: SyncEndpointCustomDomainInput,
      list: true,
    },
  },
});

/** Override type */
export type SetSyncEndpointInput = SchemaToType<typeof SetSyncEndpointInput>;

export const AirgapVersionsInput = mkInput({
  name: 'AirgapVersionsInput',
  comment: 'Input for fetching airgap versions',
  fields: {
    limit: {
      type: 'int',
      comment: 'How many versions to fetch (defaults to 50)',
      optional: true,
    },
    forceRefetch: {
      type: 'boolean',
      comment: 'Whether to clear the cache & force a refetch',
      optional: true,
    },
  },
});

/** Override type */
export type AirgapVersionsInput = SchemaToType<typeof AirgapVersionsInput>;

export const AirgapVersionIssue = mkType({
  name: 'AirgapVersionIssue',
  comment: 'A known issue associated with a given airgap version',
  fields: {
    description: {
      type: 'string',
      comment: 'Description of the known issue',
    },
    severity: {
      comment: 'Severity of the known issue',
      type: { AirgapIssueSeverityGQL },
    },
    fixedIn: {
      comment: 'Version the issue was fixed in',
      type: 'string',
    },
  },
});

/** Override type */
export type AirgapVersionIssue = SchemaToType<typeof AirgapVersionIssue>;

export const AirgapVersion = mkType({
  name: 'AirgapVersion',
  comment: 'The airgap version and associated metadata',
  fields: {
    version: {
      type: 'string',
      comment: 'The version of airgap.js',
    },
    description: {
      comment: 'Changes introduced in this version',
      type: 'string',
      optional: true,
    },
    descriptions: {
      comment: 'Changes introduced in this version, as a list',
      type: 'string',
      list: true,
    },
    issues: {
      comment: 'Changes introduced in this version, as a list',
      type: AirgapVersionIssue,
      list: true,
    },
    releasedOn: {
      comment: 'When this version was released',
      type: 'string',
    },
  },
});

/** Override type */
export type AirgapVersion = SchemaToType<typeof AirgapVersion>;

export const AirgapBundleInput = mkInput({
  name: 'AirgapBundleInput',
  comment: 'Airgap Bundle ID',
  fields: {
    airgapBundleId: {
      comment: 'The id of the airgap bundle',
      type: 'id',
      modelName: 'airgapBundle',
    },
  },
});

/** Override type */
export type AirgapBundleInput = SchemaToType<typeof AirgapBundleInput>;

export const OptionalAirgapBundleInput = mkInput({
  name: 'OptionalAirgapBundleInput',
  comment: 'Airgap Bundle ID',
  fields: {
    airgapBundleId: {
      comment: 'The id of the airgap bundle',
      type: 'id',
      modelName: 'airgapBundle',
      optional: true,
    },
  },
});

/** Override type */
export type OptionalAirgapBundleInput = SchemaToType<
  typeof OptionalAirgapBundleInput
>;

export const ConsentManagerConfigurationJson = mkType({
  name: 'ConsentManagerConfigurationJson',
  comment: 'The configuration for the consent manager.',
  fields: {
    id: {
      comment: 'The ID of this Consent Manager bundle.',
      modelName: 'airgapBundle',
      type: 'id',
    },
    testConfigurationJson: {
      comment:
        'The JSON containing all the configurations for this Consent Manager Test bundle.',
      type: 'string',
    },
    configurationJson: {
      comment:
        'The JSON containing all the configurations for this Consent Manager.',
      type: 'string',
    },
    useIabFramework: {
      comment: 'Whether to use IAB Consent Frameworks',
      type: 'boolean',
    },
  },
});

/** Override type */
export type ConsentManagerConfigurationJson = SchemaToType<
  typeof ConsentManagerConfigurationJson
>;
/* eslint-enable max-lines */
