import { transparentize } from 'polished';
import React from 'react';
import { useTheme } from 'styled-components';

/**
 * DataReady icon
 */
export const DataReady: React.FC = (props) => {
  const theme = useTheme();
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <g
        id="RequestStatusIcon"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <path
          d="M18,22H6c-1.1,0-2-0.9-2-2V4c0-1.1,0.9-2,2-2h8l6,6v12C20,21.1,19.1,22,18,22z M6,4v16h12V8.8L13.2,4H6z"
          fill={theme.colors.secondary}
          fillRule="nonzero"
        />
        <path
          d="M18.5 9L13 9 13 3.5z"
          fill={theme.colors.secondary}
          fillRule="nonzero"
        />
        <path
          d="M19,20c0,0.6-0.4,1-1,1H6c-0.6,0-1-0.4-1-1V4c0-0.6,0.4-1,1-1h7.6L19,8.4 V20z"
          fill={transparentize(0.7, theme.colors.secondary)}
          fillRule="nonzero"
        />
        <path
          // eslint-disable-next-line max-len
          d="M14.5 11L14.5 11c.8 0 1.5.7 1.5 1.5l0 0c0 .8-.7 1.5-1.5 1.5l0 0c-.8 0-1.5-.7-1.5-1.5l0 0C13 11.7 13.7 11 14.5 11zM12 18c-2.3 0-3.6-1.2-3.7-1.3l1.4-1.4c0 0 .8.7 2.3.7 1.5 0 2.3-.7 2.3-.7l1.4 1.4C15.6 16.8 14.3 18 12 18zM8 12H11V14H8z"
          fill={theme.colors.secondary}
          fillRule="nonzero"
        />
      </g>
    </svg>
  );
};
