import { Icon, StyleUtils } from '@main/core-ui';
import { dataCategoryTypeMessages } from '@main/datamap-types';
import React, { MouseEvent } from 'react';
import { useIntl } from 'react-intl';
import type Select from 'react-select';
import type { ActionMeta } from 'react-select';

import { DataCategoryPill } from './DataCategoryPill';
import type { SelectSubCategoryOption } from './types';
import { DoubleTagButton } from './wrappers';

interface SelectCategoryPillDisplayParams {
  /** the option to display */
  option: SelectSubCategoryOption;
  /** is the pill in the dropdown menu or is it a value */
  isInMenu: boolean;
  /** Callback to fire when the category is updated */
  onUpdate: (
    selections: SelectSubCategoryOption[],
    meta: ActionMeta<SelectSubCategoryOption>,
  ) => void;
  /** Reference to React Select component */
  selectRef: React.RefObject<Select>;
  /** The selected data subcategories for this subdatapoint */
  values: SelectSubCategoryOption[];
}

export const SelectCategoryPillDisplay: React.FC<
  SelectCategoryPillDisplayParams
> = ({ option, onUpdate, isInMenu, selectRef, values }) => {
  const { formatMessage } = useIntl();

  const {
    guessId,
    recommendationId,
    name,
    value,
    pendingApproval,
    classificationMethod,
  } = option;

  const hasGuessStyling = (guessId || recommendationId) && !pendingApproval;

  const categoryLabel = (
    <>
      {hasGuessStyling && (
        <DoubleTagButton
          variant="link"
          onMouseDown={(e: MouseEvent<HTMLButtonElement>) => {
            // required to stop react-select menu from opening
            e.stopPropagation();
            e.preventDefault();
          }}
          onClick={(e) => {
            onUpdate?.([...values, option], {
              action: 'select-option',
              option,
            });

            // stops double-handling the event
            e.stopPropagation();
            e.preventDefault();
            if (selectRef.current && !isInMenu) {
              selectRef.current.focus();
            }
          }}
        >
          <Icon type="checkmark" />
        </DoubleTagButton>
      )}
      {formatMessage(dataCategoryTypeMessages[value])}
    </>
  );

  const rightIcon = // only show if a guess and inside the dropdown
    hasGuessStyling && isInMenu ? (
      <DoubleTagButton
        variant="link"
        onClick={(e) => {
          const updatedSelections = [...values];
          const foundIndex = updatedSelections.findIndex(
            ({ id }) => id === option.id,
          );
          if (foundIndex > -1) {
            updatedSelections.splice(foundIndex, 1);
          }
          onUpdate?.(updatedSelections, {
            action: 'remove-value',
            removedValue: option,
          });
          // stops double-handling the event
          e.stopPropagation();
          e.preventDefault();
        }}
        style={{
          marginRight: '-5px',
          marginLeft: StyleUtils.Spacing.xs,
        }}
      >
        <Icon type="close-thick" size={8} />
      </DoubleTagButton>
    ) : undefined;

  return (
    <DataCategoryPill
      variant={hasGuessStyling ? 'outline' : 'filled'}
      classificationMethod={classificationMethod}
      label={categoryLabel}
      rightIcon={rightIcon}
      name={name}
      dataCategory={option.value}
    />
  );
};
