import { statusDescriptionMessages, statusTitleMessages } from './messages';
import type { RequestStatusConfig } from './types';

/**
 * ## Enrich Initial Identifiers
 * The verified initial identifiers are enriched to determine other identifiers that data silos may key that Data Subject by.
 *
 * Transcend will notify servers or people to lookup additional identifiers for the Data Subject. A common example of this is
 * to take an email and send a webhook to a server that will lookup user ids keyed to that email.
 *
 * The enricher can take in one identifier, and return many identifiers. The enrichers can be repeatedly called. For example:
 *  - enricher1: [email] -> [userId]
 *  - enricher2: [userId] -> [phone, email]
 *
 * If a request comes in for `my-test-request@transcend.io`:
 *  1. enricher1 called with `my-test-request@transcend.io` and returns [`10fb61d7-69d6-4c6d-9e88-181763a1d2f3`]
 *  2. enricher2 called with `10fb61d7-69d6-4c6d-9e88-181763a1d2f3` and returns [`my-test-request@transcend.io`, `my-test-request-2@transcend.io`]
 *  3. enricher1 called again with `my-test-request-2@transcend.io` and returns [].
 *  (`my-test-request@transcend.io` is not re-triggered since its repeated)
 *
 * ### ON CHANGE TO
 *  - All existing identifiers will be queued up to be enriched individually.
 *  - When pulled off the queue, a server enricher will be notified with a load-balanced webhook
 *  - Transcend users connected to person enrichers will get a notification and/or an email
 */
export const ENRICHING_STATUS: RequestStatusConfig = {
  color: 'darkPrimary',
  icon: 're-scan',
  isBuilding: true,
  messages: {
    description: statusDescriptionMessages.ENRICHING,
    title: statusTitleMessages.ENRICHING,
  },
  transitions: [
    /** The data subject invalidates one of their identifier, i.e. clicks "Not you?" in the email */
    'FAILED_VERIFICATION',
    /** All enrichers for each identifier have been resolved, and the organization has a waiting period before compilation */
    'WAITING',
    /** All enrichers for each identifier have been resolved, and the organization does not have a preflight hook setup for the action. */
    'COMPILING',
    /** Organization member clicks cancel, triggering cancelation email and the request is killed */
    'CANCELED',
    /** Organization member clicks onHold, triggering email and the request is placed temporarily on hold */
    'ON_HOLD',
    /** Data subject can revoke */
    'REVOKED',
  ],
};
