import { AssessmentFormStatus } from '@transcend-io/privacy-types';

import { UserPreview } from '@main/access-control-types';
import { mkInput, mkType, SchemaToType } from '@main/schema-utils';

import {
  AssessmentQuestion,
  AssessmentQuestionInput,
  AssessmentQuestionRaw,
} from './assessmentQuestion';

export const CreateAssessmentSectionInput = mkInput({
  name: 'CreateAssessmentSectionInput',
  comment: 'Input for creating an assessment section',
  fields: {
    title: {
      comment: 'The title of the assessment section',
      type: 'string',
    },
    assessmentFormTemplateId: {
      comment:
        'The ID of the assessment form template under which to add this section',
      type: 'id',
      modelName: 'assessmentFormTemplate',
    },
    questions: {
      comment: 'The questions belonging to this group',
      type: AssessmentQuestionInput,
      optional: true,
      list: true,
    },
  },
});

/** Override type */
export type CreateAssessmentSectionInput = SchemaToType<
  typeof CreateAssessmentSectionInput
>;

export const AssessmentSectionExternalAssignee = mkType({
  name: 'AssessmentSectionExternalAssignee',
  comment: 'The external users assigned to fill out an assessment section.',
  fields: {
    id: {
      comment: 'The id of the external assignee.',
      type: 'id',
      modelName: 'assessmentSectionExternalAssignee',
    },
    email: {
      comment: 'The email of the external assignee.',
      type: 'string',
    },
  },
});

/** Override type */
export type AssessmentSectionExternalAssignee = SchemaToType<
  typeof AssessmentSectionExternalAssignee
>;

export const AssessmentSectionRaw = mkType({
  name: 'AssessmentSectionRaw',
  comment: 'Assessment section that belongs to a template or a form',
  fields: {
    id: {
      comment: 'The id of the assessment section',
      type: 'id',
      modelName: 'assessmentSection',
    },
    title: {
      comment: 'The title of the assessment section',
      type: 'string',
    },
    status: {
      comment: 'The status of the assessment section',
      type: 'string',
      optional: true,
    },
    index: {
      comment: 'The index of the assessment section',
      type: 'int',
    },
    questions: {
      comment: 'The questions belonging to this group',
      type: AssessmentQuestionRaw,
      list: true,
    },
    assignees: {
      comment:
        'The users assigned to provide answers for this assessment section',
      type: UserPreview,
      list: true,
    },
    externalAssignees: {
      comment:
        'The external users assigned to provide answers for this assessment section',
      type: AssessmentSectionExternalAssignee,
      list: true,
    },
    isReviewed: {
      comment: 'Whether the section has been reviewed',
      type: 'boolean',
    },
  },
});

/** Override type */
export type AssessmentSectionRaw = SchemaToType<typeof AssessmentSectionRaw>;

export const AssessmentSectionPreview = mkType({
  name: 'AssessmentSectionPreview',
  comment:
    'Preview of an assessment section that belongs to a template or a form',
  fields: {
    id: {
      comment: 'The id of the assessment section',
      type: 'id',
      modelName: 'assessmentSection',
    },
    title: {
      comment: 'The title of the assessment section',
      type: 'string',
    },
    index: {
      comment: 'The index of the assessment section',
      type: 'int',
    },
  },
});

/** Override type */
export type AssessmentSectionPreview = SchemaToType<
  typeof AssessmentSectionPreview
>;

/** Type of the full, parsed AssessmentSection; raw and full types are helpful when using `buildUseTransformQuery` */
export type AssessmentSection = Omit<AssessmentSectionRaw, 'questions'> & {
  /** questions with parsed `displayLogic` */
  questions: AssessmentQuestion[];
};

export const UpdateAssessmentTemplateSectionInput = mkInput({
  name: 'UpdateAssessmentTemplateSectionInput',
  comment: 'Input for updating an assessment template section',
  fields: {
    id: {
      comment: 'The id of the assessment section to update',
      modelName: 'assessmentSection',
      type: 'id',
    },
    title: {
      ...AssessmentSectionRaw.fields.title,
      optional: true,
    },
    index: {
      ...AssessmentSectionRaw.fields.index,
      optional: true,
    },
    questions: CreateAssessmentSectionInput.fields.questions,
  },
});

/** Override type */
export type UpdateAssessmentTemplateSectionInput = SchemaToType<
  typeof UpdateAssessmentTemplateSectionInput
>;

export const UpdateAssessmentFormSectionInput = mkInput({
  name: 'UpdateAssessmentFormSectionInput',
  comment: 'Input for updating an assessment form section',
  fields: {
    id: {
      comment: 'The id of the assessment section to update',
      modelName: 'assessmentSection',
      type: 'id',
    },
    assigneeIds: {
      comment:
        'The IDs of the users assigned to fill out this assessmentSection',
      type: 'id',
      modelName: 'user',
      list: true,
      optional: true,
    },
    externalAssigneeEmails: {
      comment:
        'The emails of the external users assigned to provide answers for this assessmentSection',
      type: 'string',
      optional: true,
      list: true,
    },
    isReviewed: {
      comment: 'Whether the section has been reviewed',
      type: 'boolean',
      optional: true,
    },
    status: {
      comment: 'The status of the assessment',
      type: { AssessmentFormStatus },
      optional: true,
    },
  },
});

/** Override type */
export type UpdateAssessmentFormSectionInput = SchemaToType<
  typeof UpdateAssessmentFormSectionInput
>;

export const updateAssessmentFormSectionsInput = mkInput({
  name: 'updateAssessmentFormSectionsInput',
  comment: 'Input for bulk updating assessment form sections',
  fields: {
    assessmentFormId: {
      comment: 'The id of the assessment form containing the sections',
      type: 'id',
      modelName: 'assessmentForm',
    },
    comment: {
      comment:
        'Comment to be added to the parent form when updating the sections.',
      type: 'string',
      optional: true,
    },
    assessmentSections: {
      comment: 'The list of assessment form section to update',
      type: UpdateAssessmentFormSectionInput,
      list: true,
    },
  },
});

/** Override type */
export type updateAssessmentFormSectionsInput = SchemaToType<
  typeof updateAssessmentFormSectionsInput
>;

export const DeleteAssessmentSectionsInput = mkInput({
  name: 'DeleteAssessmentSectionsInput',
  comment: 'Input for deleting multiple assessment sections',
  fields: {
    ids: {
      comment: 'The ids of the assessment sections to delete',
      type: 'id',
      modelName: 'assessmentSection',
      list: true,
    },
  },
});

/** Override type */
export type DeleteAssessmentSectionsInput = SchemaToType<
  typeof DeleteAssessmentSectionsInput
>;

export const AssessmentSectionInput = mkInput({
  name: 'AssessmentSectionInput',
  comment: 'Input for creating an assessment section',
  fields: {
    title: {
      comment: 'The title of the assessment section',
      type: 'string',
    },
    questions: {
      comment: 'The questions belonging to this group',
      type: AssessmentQuestionInput,
      optional: true,
      list: true,
    },
  },
});

/** Override type */
export type AssessmentSectionInput = SchemaToType<
  typeof AssessmentSectionInput
>;
