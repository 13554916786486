import { FilterConfigItem } from '@main/ad-core-components';
import { endpoints } from '@main/cm-types';
import { buildUseLazyQuery } from '@main/core-ui';
import { ID } from '@main/schema-utils';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { MessageDescriptor } from 'react-intl';

import {
  SelectedTcfSpecialFeature,
  SelectTcfSpecialFeature,
} from '../../ConsentManager/Tcf/TcfStacks/components/SelectTcfSpecialFeature';
import { TcfDoubleTag } from '../../ConsentManager/Tcf/TcfStacks/components/TcfDoubleTag';

/**
 * Filters definition with property for TCF Special Feature specified
 */
type TcfSpecialFeatureEnrichedFilters = Record<string, unknown> &
  Record<
    /**
     * TCF Special Feature that are currently filtering the data
     */
    string,
    SelectedTcfSpecialFeature[] | undefined
  >;

const useLazyTcfSpecialFeatures = buildUseLazyQuery(
  endpoints.orgTcfSpecialFeatures,
  endpoints.orgTcfSpecialFeatures.name,
  { nodes: { tcfSpecialFeature: { id: null, name: null } } },
);

export const useTcfSpecialFeatureFilters = <
  T extends Record<string, unknown>,
  E extends TcfSpecialFeatureEnrichedFilters,
>({
  tcfSpecialFeatureIdsFilterKey = 'specialFeatureIds',
  filters,
  setFilters,
  label,
  enrichedTcfSpecialFeatureKey = 'specialFeatures',
}: {
  /** The property name of the filter for TCF special feature ids */
  tcfSpecialFeatureIdsFilterKey?: keyof T;
  /** The currently applied filters */
  filters?: T;
  /** Callback when the filters are changed */
  setFilters: (filters: T) => void;
  /** Label for the filter menu */
  label: string | MessageDescriptor;
  /** The key to use if more than one useTcfSpecialFeatureFilters is used in a FilterManager */
  enrichedTcfSpecialFeatureKey?: keyof E;
}): {
  /**  The tcf special feature keys enabled on this table */
  selectedTcfSpecialFeatures: SelectedTcfSpecialFeature[];
  /** The filter configuration to be passed to the filter manager */
  tcfSpecialFeatureFiltersConfig: FilterConfigItem<E>;
  /** Callback for when the filter is cleared in the filter manager */
  clearTcfSpecialFeatureFilters: (key: Extract<keyof E, string>) => void;
} => {
  const getSelectedTcfSpecialFeatures = useLazyTcfSpecialFeatures();
  const [selectedTcfSpecialFeatures, setSelectedTcfSpecialFeatures] = useState<
    SelectedTcfSpecialFeature[]
  >([]);

  // Populate initial complex filter values
  useEffect(() => {
    if (filters) {
      const tcfSpecialFeatureIds = (filters[tcfSpecialFeatureIdsFilterKey] ??
        []) as ID<'tcfSpecialFeature'>[];

      if (
        tcfSpecialFeatureIds.length > 0 &&
        selectedTcfSpecialFeatures.length === 0
      ) {
        getSelectedTcfSpecialFeatures({
          input: {},
          filterBy: {
            tcfSpecialFeatureIds,
          },
        }).then(({ data }) => {
          setSelectedTcfSpecialFeatures(
            data.nodes.map(({ tcfSpecialFeature }) => tcfSpecialFeature),
          );
        });
      } else {
        setSelectedTcfSpecialFeatures([]);
      }
    }
  }, []);

  const tcfSpecialFeatureFiltersConfig = useMemo(
    () =>
      ({
        filterKey: enrichedTcfSpecialFeatureKey,
        label,
        renderPill: ({
          filterValues: {
            [enrichedTcfSpecialFeatureKey]: specialFeatures = [],
          },
          index = 0,
        }) =>
          specialFeatures[index] && (
            <TcfDoubleTag option={specialFeatures[index]} />
          ),
        filter: (
          <SelectTcfSpecialFeature
            value={selectedTcfSpecialFeatures}
            menuPosition="absolute"
            onChange={(tcfSpecialFeatures) => {
              setSelectedTcfSpecialFeatures(tcfSpecialFeatures);
              setFilters({
                ...filters,
                [tcfSpecialFeatureIdsFilterKey]: tcfSpecialFeatures.map(
                  ({ id }) => id,
                ),
              } as T);
            }}
          />
        ),
      }) as FilterConfigItem<TcfSpecialFeatureEnrichedFilters>,
    [
      enrichedTcfSpecialFeatureKey,
      label,
      selectedTcfSpecialFeatures,
      filters,
      tcfSpecialFeatureIdsFilterKey,
    ],
  );

  const clearTcfSpecialFeatureFilters = useCallback(
    (key) => {
      if (key === enrichedTcfSpecialFeatureKey) {
        setFilters({
          ...filters,
          [tcfSpecialFeatureIdsFilterKey]: [],
        } as T);
        setSelectedTcfSpecialFeatures([]);
      }
    },
    [enrichedTcfSpecialFeatureKey, filters, tcfSpecialFeatureIdsFilterKey],
  );

  return {
    selectedTcfSpecialFeatures,
    tcfSpecialFeatureFiltersConfig,
    clearTcfSpecialFeatureFilters,
  };
};
