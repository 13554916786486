import {
  GenericMessageDescriptor,
  RadioInput,
  useFormatMessageGeneric,
} from '@main/core-ui';
import React from 'react';
import { MessageDescriptor, useIntl } from 'react-intl';

import { CheckboxInput } from '../CheckboxInput';
import { multipleChoiceMessages } from './messages';
import { Legend, ToggleAllButton } from './wrappers';

export interface MultipleChoiceOption<T extends string> {
  /** message to be translated for the option label */
  label: GenericMessageDescriptor;
  /** value passed to onChange */
  value: T;
}

/**
 * type of object passed to RadioGroup options prop
 */
export type MultipleChoiceOptions<T extends string> = Array<
  MultipleChoiceOption<T>
>;

export interface MultipleChoiceBaseProps
  extends Omit<
    React.ComponentPropsWithRef<'fieldset'>,
    'children' | 'onChange' | 'type' | 'title'
  > {
  /** name of group passed to underlying hidden input elements */
  title: MessageDescriptor;
  /** internal name for ref as forwardRef components don't support generics, so we're doing a workaround */
  fieldsetRef?: React.Ref<HTMLFieldSetElement>;
  /** internal flag for whether to use checkbox or radio */
  type: 'checkbox' | 'radio';
  /** shows clear button (or toggle all button for checkboxes) when value isn't null */
  hideClearButton?: boolean;
  /** flag to visually hide legend */
  hideLegend?: boolean;
}

/* internally treat everything as an array
  wrapper components will perform translation from
  single value to array of a single value (Radio) or
  pass along the supplied array of values
 */
export interface MultipleChoiceProps<
  T extends string,
  K extends Array<MultipleChoiceOption<T>>,
  J = K[number]['value'],
  V = Array<J>,
> extends MultipleChoiceBaseProps {
  /** current checked value of group */
  value?: V;
  /** choices */
  options: K;
  /** callback for when a choice is selected */
  onChange?: (value: V) => void;
  /** callback for when the clear button (or toggle all button for checkboxes) is clicked */
  onToggleAll?: (isSelectAll: boolean) => void;
}

export const MultipleChoice: <
  T extends string,
  K extends Array<MultipleChoiceOption<T>>,
>(
  props: MultipleChoiceProps<T, K>,
) => React.ReactElement = ({
  hideClearButton = false,
  value = [],
  onChange,
  onToggleAll,
  title: titleMessage,
  fieldsetRef,
  options,
  type,
  hideLegend = false,
  disabled,
  ...props
}) => {
  const { formatMessage } = useIntl();
  const { formatMessageGeneric } = useFormatMessageGeneric();
  const name = formatMessage(titleMessage);
  const showSelectAll = value.length === 0 && type === 'checkbox';

  return (
    <fieldset
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: '6px',
      }}
      ref={fieldsetRef}
      disabled={disabled}
      {...props}
    >
      <div
        style={{
          height: !hideLegend ? '24px' : '0',
          display: 'flex',
          position: 'relative',
          justifyContent: hideLegend ? 'flex-end' : 'space-between',
          top: hideLegend ? '6px' : '0',
        }}
      >
        <Legend
          style={{
            ...(hideLegend
              ? { height: '0', width: '0', overflow: 'hidden' }
              : {}),
          }}
        >
          {name}
        </Legend>
        {!hideClearButton && (
          <ToggleAllButton
            disabled={disabled}
            onClick={() =>
              onToggleAll
                ? onToggleAll(showSelectAll)
                : onChange?.(
                    showSelectAll ? options.map(({ value }) => value) : [],
                  )
            }
            style={{
              zIndex: hideLegend ? 2 : 0,
            }}
          >
            {formatMessage(
              showSelectAll
                ? multipleChoiceMessages.selectAll
                : multipleChoiceMessages.clear,
            )}
          </ToggleAllButton>
        )}
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '6px',
          zIndex: 1,
        }}
      >
        {options.map(({ value: optionValue, label }) =>
          type === 'radio' ? (
            <RadioInput
              key={optionValue}
              name={titleMessage}
              value={optionValue}
              checked={optionValue === value[0]}
              onChange={() => onChange?.([optionValue])}
              disabled={disabled}
            >
              {formatMessageGeneric(label)}
            </RadioInput>
          ) : (
            <CheckboxInput
              key={optionValue}
              value={value.includes(optionValue) ? 'checked' : 'unchecked'}
              onChange={() =>
                onChange?.(
                  value.includes(optionValue)
                    ? value.filter((v) => v !== optionValue)
                    : [...value, optionValue],
                )
              }
              disabled={disabled}
            >
              {formatMessageGeneric(label)}
            </CheckboxInput>
          ),
        )}
      </div>
    </fieldset>
  );
};
