import { defineMessages } from '@transcend-io/internationalization';

export const newRepositoryFormMessages = defineMessages(
  'admin-dashboard.CodeScanning.components.NewRepositoryForm.newRepositoryForm',
  {
    nameLabel: {
      defaultMessage: 'Repository name',
    },
    descriptionLabel: {
      defaultMessage: 'Repository description',
    },
    urlLabel: {
      defaultMessage: 'Repository URL',
    },
    submitButton: {
      defaultMessage: 'Create Repository',
    },
    submitSuccess: {
      defaultMessage: 'New repository created!',
    },
  },
);
