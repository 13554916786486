import { ReactSelect, ReactSelectExtendedProps } from '@main/core-ui';
import { DefinedMessage } from '@main/internationalization';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';

import { CONTROLLERSHIP_COLORS, CONTROLLERSHIP_OPTIONS } from './constants';
import { SelectControllershipOption } from './types';

/** Props for the `SelectControllerships` component */
export interface SelectControllershipsProps<IsMulti extends boolean>
  extends Omit<
    ReactSelectExtendedProps<IsMulti, SelectControllershipOption>,
    'value' | 'onBlur' | 'onChange'
  > {
  /** The value for the cell */
  value?: SelectControllershipOption[] | null;
  /** Callback to fire when component blurs */
  onBlur?: () => void;
  /** Callback to fire when the value is updated */
  onChange: (controllerships: SelectControllershipOption[]) => void;
  /** Groups of options to display before the main list of options */
  optionGroups?: {
    /** The label of the group */
    label: string;
    /** The options within this group */
    options: SelectControllershipOption[];
  }[];
  /** The label for the main list of options, used if other option groups are provided */
  defaultOptionGroupLabel?: DefinedMessage;
}

/**
 * Component to select one or more controllership values
 */
export function SelectControllerships<IsMulti extends boolean>({
  value,
  onBlur,
  onChange,
  optionGroups,
  defaultOptionGroupLabel,
  ...props
}: SelectControllershipsProps<IsMulti>): JSX.Element {
  const { formatMessage } = useIntl();

  const options = useMemo(
    () => [
      ...(optionGroups
        ? [
            ...optionGroups,
            {
              label: defaultOptionGroupLabel
                ? formatMessage(defaultOptionGroupLabel)
                : '',
              options: CONTROLLERSHIP_OPTIONS,
            },
          ]
        : CONTROLLERSHIP_OPTIONS),
    ],
    [defaultOptionGroupLabel, formatMessage, optionGroups],
  );

  return (
    <ReactSelect<IsMulti, SelectControllershipOption>
      options={options}
      value={value}
      onChange={(option) => {
        onChange(
          // always return a list for type simplicity
          Array.isArray(option) ? option : [option],
        );
      }}
      onBlur={onBlur}
      getOptionLabel={(option) => formatMessage(option.label)}
      colorMap={CONTROLLERSHIP_COLORS}
      {...props}
    />
  );
}

/** Props for the `SelectControllership` component */
export type SelectControllershipProps = Omit<
  SelectControllershipsProps<false>,
  'value' | 'onChange'
> & {
  /** Set the newly selected controllership */
  onChange: (controllership: SelectControllershipOption) => void;
  /** Selected controllership */
  value?: SelectControllershipOption;
};

/**
 * Select a single integration
 */
export const SelectControllership: React.FC<SelectControllershipProps> = ({
  onChange,
  value,
  ...props
}) => (
  <SelectControllerships
    {...props}
    isMulti={false}
    value={value ? [value] : []}
    onChange={([controllership]) => onChange(controllership)}
  />
);
