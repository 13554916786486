import { statusDescriptionMessages, statusTitleMessages } from './messages';
import type { RequestStatusConfig } from './types';

/**
 * ## Revoked
 * The data subject revoked the request
 *
 * A data subject may revoke a request explicitly or do so implicitly by submitting a duplicate request.
 *
 * ### ON CHANGE TO
 *  - We notify the Data Subject with the revoked email notice
 */
export const REVOKED_STATUS: RequestStatusConfig = {
  color: 'lightPrimary',
  icon: 'stop-hand',
  isClosed: true,
  isError: true,
  canArchive: true,
  messages: {
    description: statusDescriptionMessages.REVOKED,
    title: statusTitleMessages.REVOKED,
  },
  transitions: [],
};
