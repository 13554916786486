import * as t from 'io-ts';

import { dbModelId } from '@main/schema-utils';

import { AuditEventCode } from '../schema/enums';
import { mkAuditEventCodec } from '../types';

export const AuditEventRiskFramework = mkAuditEventCodec(
  AuditEventCode.RiskFramework,
  t.type({
    /** ID of the risk framework */
    riskFrameworkId: dbModelId('riskFramework'),
  }),
);

/** Override types. */
export type AuditEventRiskFramework = t.TypeOf<typeof AuditEventRiskFramework>;
