import { ConsentManagerLanguageKey } from '@transcend-io/internationalization';
import { apply, invert, makeEnum } from '@transcend-io/type-utils';

/** Required airgap module names */
export const RequiredAirgapModuleName = makeEnum({
  /** Airgap.js core module */
  core: 'core',
});

/** Type override */
export type RequiredAirgapModuleName =
  (typeof RequiredAirgapModuleName)[keyof typeof RequiredAirgapModuleName];

/**
 * Convert enum to something GraphQL friendly:
 *
 * e.g. { En: En, FrFr: FrFr, ... }
 */
export const TranslationEnumAirgapModuleName = apply(
  ConsentManagerLanguageKey,
  (v, k) => k,
);

const TcfModuleToLocale = invert(
  apply(ConsentManagerLanguageKey, (v, k) => `tcf${k}` as const),
);

export const TcfTranslationModuleNames = apply(
  invert(apply(ConsentManagerLanguageKey, (v, k) => `tcf${k}` as const)),
  (v, k) => k,
);

export const TcfTranslations = apply(TcfTranslationModuleNames, (v, k) => ({
  moduleName: k,
  locale: TcfModuleToLocale[k],
}));

export const PeripheralAirgapModuleName = makeEnum({
  /** Transcend Secure XDI bundle */
  xdi: 'xdi',
  /** Transcend Consent Manager Userscript header metadata bundle */
  userscript: 'userscript',
  /** airgap.js explorer (userscript utilities) bundle */
  explorer: 'explorer',
  /** metadata about services used by airgap */
  metadata: 'metadata',
});

/** Type override */
export type PeripheralAirgapModuleName =
  (typeof PeripheralAirgapModuleName)[keyof typeof PeripheralAirgapModuleName];

export const CoreUIModuleName = makeEnum({
  /** Transcend Consent Manager UI bundle */
  ui: 'ui',
  /** The stylesheet for the consent manager */
  stylesheet: 'stylesheet',
  /** Each translation gets its own file */
  ...TranslationEnumAirgapModuleName,
});

/** Type override */
export type CoreUIModuleName =
  (typeof CoreUIModuleName)[keyof typeof CoreUIModuleName];

export const IABModuleName = makeEnum({
  /** Transparency & Consent Framework UI bundle */
  tcfUi: 'tcfUi',
  /** GPP bundle */
  gpp: 'gpp',
  /** The stylesheet for the TCF UI */
  tcfStylesheet: 'tcfStylesheet',
  /** Transparency & Consent Framework Vendors list */
  tcfVendorsList: 'tcfVendorsList',
  ...TcfTranslationModuleNames,
});

/** Type override */
export type IABModuleName = (typeof IABModuleName)[keyof typeof IABModuleName];

export const NativeAppModuleName = makeEnum({
  /** Configuration module for native apps (mobile, tvs)  */
  nativeAppConfig: 'nativeAppConfig',
  /** Mobile Bridge JavaScript */
  bridgeJs: 'bridgeJs',
  /** Mobile Bridge HTML */
  bridgeHtml: 'bridgeHtml',
});

/** Type override */
export type NativeAppModuleName =
  (typeof NativeAppModuleName)[keyof typeof NativeAppModuleName];

/** Optional airgap module names */
export const DefaultAirgapModuleName = makeEnum({
  ...RequiredAirgapModuleName,
  ...PeripheralAirgapModuleName,
  ...CoreUIModuleName,
});

/** Type override */
export type DefaultAirgapModuleName =
  (typeof DefaultAirgapModuleName)[keyof typeof DefaultAirgapModuleName];

/** Optional airgap module names */
export const OptionalAirgapModuleName = makeEnum({
  ...PeripheralAirgapModuleName,
  ...CoreUIModuleName,
  ...IABModuleName,
  ...NativeAppModuleName,
});

/** Type override */
export type OptionalAirgapModuleName =
  (typeof OptionalAirgapModuleName)[keyof typeof OptionalAirgapModuleName];

/** Airgap module name */
export const AirgapModuleName = makeEnum({
  ...RequiredAirgapModuleName,
  ...OptionalAirgapModuleName,
});

/** Type override */
export type AirgapModuleName =
  (typeof AirgapModuleName)[keyof typeof AirgapModuleName];
