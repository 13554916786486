import { ReactElement } from 'react';

interface ConditionalContainerProps {
  /** Whether or not to include the container */
  condition: boolean;
  /** The containing element, expressed as a function */
  container: (children: ReactElement<any, any>) => ReactElement<any, any>;
  /** The contained element */
  children: ReactElement<any, any>;
}

// Adds a container component based on a condition; if condition
// is not present, no container is added
export const ConditionalContainer: React.FC<ConditionalContainerProps> = ({
  condition,
  container,
  children,
}) => (condition ? container(children) : children);
