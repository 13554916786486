import {
  AUTOCOMPLETE_OFF_PROPS,
  FlexColumn,
  Form,
  StyleUtils,
  useForm,
} from '@main/core-ui';
import { Editor, isNodeSelection } from '@tiptap/core';
import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';

import { FormSubmitButton } from '../Button';
import { FormInput } from '../Input';
import { Modal, ModalProps } from '../Modal';
import { UploadImage } from '../UploadImage';
import { FileUploadOptions } from '../utils/uploadFile';
import { CustomImageOptions } from './extensions';
import { richEditorMessages } from './messages';
import { TipTapNodeType } from './Toolbar/enums';
import { useTransformImageSrc } from './useTransformImageSrc';

export interface AddImageModalProps extends ModalProps {
  /** The editor instance  */
  editor: Editor;
  /** Callback to fire when form is submitted */
  onSubmit: (values: AddImageFormData) => void;
  /** Options for a file upload */
  uploadOptions: FileUploadOptions;
  /** Callback to transform the viewed src of an image (e.g. to use presigned urls) */
  transformImageSrc?: CustomImageOptions['transformSrc'];
}

export interface AddImageFormData {
  /** The image URL to link to */
  url: string;
  /** The text for the image alt attribute */
  alt: string;
}

export const AddImageModal = ({
  editor,
  onSubmit,
  uploadOptions,
  transformImageSrc,
  ...props
}: AddImageModalProps): JSX.Element | null => {
  const { formatMessage } = useIntl();

  const formMethods = useForm<AddImageFormData>({
    defaultValues: { url: '', alt: '' },
  });

  const url = formMethods.watch('url');
  const srcToDisplay = useTransformImageSrc(url, transformImageSrc);

  // When the modal is opened, set the form state to the selected image, if any
  useEffect(() => {
    const { selection } = editor.state;

    const { src = '', alt = '' } =
      isNodeSelection(selection) &&
      selection.node.type.name === TipTapNodeType.Image
        ? selection.node.attrs
        : {};

    formMethods.setValue('url', src);
    formMethods.setValue('alt', alt);
  }, [editor]);

  return (
    <Modal header={richEditorMessages.addImageHeading} {...props}>
      <Form
        useFormMethods={formMethods}
        onSubmit={onSubmit}
        style={{ display: 'flex', flexDirection: 'column' }}
      >
        <FlexColumn style={{ gap: StyleUtils.Spacing.sm }}>
          <UploadImage
            {...uploadOptions}
            onSuccess={(files) => {
              const file = files[0];
              formMethods.setValue('url', file?.src || '');
            }}
            values={
              url
                ? [
                    {
                      name: url,
                      uploadedFile: { src: srcToDisplay },
                    },
                  ]
                : undefined
            }
          />

          <FormInput
            name="alt"
            label={richEditorMessages.imageAlt}
            {...AUTOCOMPLETE_OFF_PROPS}
          />
        </FlexColumn>

        <FormSubmitButton>
          {formatMessage(richEditorMessages.submitLabel)}
        </FormSubmitButton>
      </Form>
    </Modal>
  );
};
