import get from 'lodash/get';
import has from 'lodash/has';
import set from 'lodash/set';
import {
  FieldErrors,
  FieldValues,
  Resolver,
  UnpackNestedValue,
} from 'react-hook-form';

import { ValidatorKey } from './enums';
import { TValidator } from './types';
import { multipleValidators } from './validators';

const validateValue = ({
  name,
  value,
  acc,
  schema,
  values,
}: {
  /** Path to the value being validated */
  name: string;
  /** Value being validated */
  value: any;
  /** All the errors so far */
  acc: FieldErrors | Record<string, never>;
  /** The schema describing what fields need to be validated and how */
  schema: Record<string, TValidator | TValidator[]>;
  /** All the form values */
  values: UnpackNestedValue<FieldValues>;
}): FieldErrors | Record<string, never> => {
  let error: typeof acc = {};

  const nameParts = name.split('.');
  const schemaKeys = Object.keys(schema);

  const matchingSchemaValidators: (TValidator | TValidator[])[] = [];

  // Find all matching validators, including all wildcards and a mix of wildcards and indices
  // e.g. "foo.0.bar.4.baz" matches both schema properties "foo.*.bar.*.baz" and "foo.*.bar.4.baz"
  schemaKeys.forEach((key) => {
    const keyParts = key.split('.');
    const hasValidator =
      keyParts.length === nameParts.length &&
      keyParts.every(
        (part, idx) =>
          part === nameParts[idx] ||
          (!Number.isNaN(parseInt(nameParts[idx], 10)) && part === '*'),
      );
    if (hasValidator) {
      matchingSchemaValidators.push(schema[key]);
    }
  });

  if (matchingSchemaValidators.length > 0) {
    matchingSchemaValidators.forEach((schemaValidator) => {
      // Normalize the validators input to be one object, with different keys for each validator
      const validators = multipleValidators(schemaValidator);

      // Build the errors object
      error = {
        ...error,
        ...Object.values(ValidatorKey).reduce((keysAcc, key) => {
          if (validators[key]) {
            const message = validators[key](value, values);

            if (message) {
              return set(keysAcc, name.replace(/\.(\d+)(\.|$)/g, '[$1]$2'), {
                message,
                type: key,
                types: { [key]: message },
              });
            }
          }
          return keysAcc;
        }, acc),
      };
    });
  }

  return {
    ...acc,
    ...error,
  };
};

/**
 * Custom resolver to perform form validation on an entire react-hook-form
 * based on the provided schema.
 *
 * This can be helpful when you must validate unmounted form inputs, however
 * it is recommended in most cases to hide instead of unmounting them.
 *
 * @template T - The type of field values.
 * @param schema - The validation schema.
 * @returns the resolver function.
 */
export function formValidationResolver<T extends FieldValues>(
  schema: Record<string, TValidator | TValidator[]>,
): Resolver<
  T,
  {
    /**
     * Whether to validate all fields, or just the changed ones. When using `mode: 'onChange'`,
     * it is especially more performative to only validate the changed fields
     */
    validateAll?: boolean;
  }
> {
  return (values, context, { names }) => {
    let errors = {};

    if (names && !context?.validateAll) {
      // Validate changed fields
      errors = names.reduce<FieldErrors | Record<string, never>>(
        (acc, name) => {
          const hasValue = has(values, name);

          // Deleted fields can still be present in names, but will not have values
          if (!hasValue) {
            return acc;
          }
          const value = get(values, name);

          return validateValue({ name, value, acc, schema, values });
        },
        {},
      );
    } else {
      // Flatten nested fields so that the keys are paths to the nested values. This mimics the structure of the names array.
      const flattenedValues = {};
      const flattenObject = (obj: any, path: string = ''): void => {
        // eslint-disable-next-line no-restricted-syntax
        for (const key in obj) {
          const newPath = path ? `${path}.${key}` : key;

          if (
            typeof obj[key] === 'object' &&
            obj[key] !== null &&
            // empty arrays get saved as "scalar" values, otherwise they won't show up in the output at all
            !(Array.isArray(obj[key]) && obj[key].length === 0)
          ) {
            flattenObject(obj[key], newPath);
          } else {
            flattenedValues[newPath] = obj[key];
          }
        }
      };
      flattenObject(values);

      errors = Object.entries(flattenedValues).reduce(
        (acc, [name, value]) =>
          validateValue({ name, value, acc, schema, values }),
        {},
      );
    }

    return { values, errors };
  };
}
