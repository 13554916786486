import { createLoadable } from '@main/core-ui';

// Load the component dynamically
export const AgentFunctionLoadable = createLoadable({
  loader: () => import('./AgentFunction'),
});

export const NewAgentFunctionLoadable = createLoadable({
  loader: () => import('./NewAgentFunction'),
});
