import React, { useCallback, useEffect, useState } from 'react';
import { MessageDescriptor, useIntl } from 'react-intl';

import { Button } from '../Button';
import { CloseButton } from '../CloseButton';
import {
  ButtonWrapper,
  CTABannerContent,
  CTABannerWrapper,
  DisabledBackgroundWrapper,
  TitleWrapper,
} from './wrappers';

export interface CTABannerProps {
  /** The title for this banner */
  titleMessage?: MessageDescriptor;
  /** text to be formatted and shown on button */
  buttonMessage?: MessageDescriptor;
  /** flag to show or hide button, default is false */
  showButton?: boolean;
  /** callback passed to cta button */
  onClickButton?: () => void;
  /** callback passed to close button */
  onClickCloseButton?: () => void;
  /** flag passed to cta button */
  loading?: boolean;
  /** wether the banner is visible */
  canDisplay?: boolean;
  /** flag to show or hide close button, default is false */
  showCloseButton?: boolean;
  /** Message formatting for the title of the banner */
  titleMessageArgs?: Record<string, string | React.ReactElement>;
}

export const CTABanner = ({
  titleMessage,
  buttonMessage,
  onClickButton,
  onClickCloseButton,
  canDisplay = true,
  loading = false,
  showButton = false,
  showCloseButton = false,
  titleMessageArgs,
}: CTABannerProps): JSX.Element => {
  const [bannerEl, setBannerEl] = useState<HTMLDivElement | null>();
  const bannerRef = useCallback((node) => {
    setBannerEl(node);
  }, []);

  useEffect(() => {
    if (bannerEl) {
      bannerEl.style.bottom = '0';
      bannerEl.querySelector('button')?.focus();
    }
  }, [bannerEl]);

  const { formatMessage } = useIntl();

  return (
    <>
      {canDisplay && (
        <>
          <DisabledBackgroundWrapper />
          <CTABannerWrapper>
            <CTABannerContent ref={bannerRef}>
              <TitleWrapper>
                {titleMessage
                  ? formatMessage(titleMessage, titleMessageArgs)
                  : ''}
              </TitleWrapper>
              {showButton && (
                <ButtonWrapper>
                  <Button
                    size="lg"
                    disabled={loading}
                    loading={loading}
                    onClick={() => onClickButton?.()}
                  >
                    {buttonMessage ? formatMessage(buttonMessage) : ''}
                  </Button>
                </ButtonWrapper>
              )}
              {showCloseButton && <CloseButton onClick={onClickCloseButton} />}
            </CTABannerContent>
          </CTABannerWrapper>
        </>
      )}
    </>
  );
};
