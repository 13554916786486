import { FlexRowCenterVertical } from '@main/core-ui';
import { resolveColor, ResolveColorOptions } from '@main/theme';
import styled from 'styled-components';

/**
 * Display text in a specific color
 *
 * red -> warning
 * green -> success
 */
export const Color = styled(FlexRowCenterVertical)<ResolveColorOptions>`
  color: ${resolveColor()};
  display: inline-flex;
  gap: 0.5em;
`;
