import {
  FormItemWrapper,
  IFormItemWrapperProps,
  multipleValidators,
  Validators,
} from '@main/core-ui';
import React from 'react';
import { Controller } from 'react-hook-form';

import { SelectPurpose } from '../SelectPurpose';

export interface FormSelectPurposeProps
  extends Omit<IFormItemWrapperProps, 'name'> {
  /** Whether to hide the `UNSPECIFIED` option */
  hideUnspecified?: boolean;
}

export const FormSelectPurpose: React.FC<FormSelectPurposeProps> = ({
  hideUnspecified,
  ...props
}) => (
  <FormItemWrapper name="purpose" {...props}>
    <Controller
      name="purpose"
      rules={{ validate: multipleValidators(Validators.REQUIRED) }}
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      render={({ field: { ref, onChange, ...field } }) => (
        <SelectPurpose
          showSelectOutline
          {...field}
          onChange={onChange}
          hideUnspecified={hideUnspecified}
        />
      )}
    />
  </FormItemWrapper>
);
