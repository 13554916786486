import { defineMessages } from '@transcend-io/internationalization';

export const isVerifiedMessages = defineMessages(
  'ad-core-components.IsVerified.isVerified',
  {
    verified: {
      defaultMessage: 'Verified',
    },
    unverified: {
      defaultMessage: 'Unverified',
    },
  },
);
