/**
 * range constants
 */
export enum AnalyticsStartDate {
  LastWeek = 'LAST_WEEK',
  LastMonth = 'LAST_MONTH',
  LastSixMonths = 'LAST_SIX_MONTHS',
  LastYear = 'LAST_YEAR',
  AllTime = 'ALL_TIME',
  Custom = 'CUSTOM',
}

/**
 * value of range picker
 */
export interface LastTimePeriodValue {
  /** start of range */
  startDate: Date;
  /** end of range */
  endDate: Date;
}

export interface LastTimePeriodSelectProps {
  /** the current value */
  value?: LastTimePeriodValue;
  /** the handler when the value changes */
  onChange: (val: LastTimePeriodValue) => void;
  /** the createdAt of the org */
  orgCreatedAt: string;
  /** The default start date */
  defaultStartDate?: (typeof AnalyticsStartDate)[keyof typeof AnalyticsStartDate];
}
