import { DoubleTag, SingleTag } from '@main/core-ui';
import { dataCategoryTypeMessages } from '@main/datamap-types';
import type { DataCategoryType } from '@transcend-io/privacy-types';
import React from 'react';
import { useIntl } from 'react-intl';

import { DATA_CATEGORY_TYPE_COLOR_NAMES } from '../../constants';

/** Props for the `DataCategoryTitle` component */
interface DataCategoryTitleProps {
  /** The category for the cell */
  category: DataCategoryType;
  /** The name of the subcategory */
  name?: string;
}

export const DataCategoryTitle: React.FC<DataCategoryTitleProps> = ({
  category,
  name,
}) => {
  const { formatMessage } = useIntl();

  return name ? (
    <DoubleTag
      variant="filled"
      leftLabel={formatMessage(dataCategoryTypeMessages[category])}
      rightLabel={name}
      color={DATA_CATEGORY_TYPE_COLOR_NAMES[category]}
    />
  ) : (
    <SingleTag
      label={formatMessage(dataCategoryTypeMessages[category])}
      color={DATA_CATEGORY_TYPE_COLOR_NAMES[category]}
    />
  );
};
