import sanitizeHtmlLib from 'sanitize-html';

export const DEFAULT_ALLOWED_TAGS = [
  'b',
  'i',
  'em',
  's',
  'br',
  'hr',
  '&nbsp',
  'strong',
  'section',
  'p',
  'li',
  'ul',
  'ol',
  'u',
  'a',
  'h1',
  'h2',
  'h3',
  'h4',
  'h5',
  'h6',
  'table',
  'tbody',
  'thead',
  'th',
  'td',
  'tr',
  'img',
  'div',
  'span',
  'head',
  'body',
  'title',
  'meta',
  'figure',
];

export const DEFAULT_ALLOWED_ATTRIBUTES = {
  a: ['href', 'target', 'rel'],
  h1: ['id', 'class'],
  h2: ['id', 'class'],
  figure: ['style'],
  h3: ['id', 'class'],
  meta: ['charset'],
  table: ['border', 'cellpadding', 'cellspacing', 'class'],
  img: ['src', 'srcset', 'alt', 'title', 'width', 'height', 'loading'],
  td: ['align', 'rowspan', 'colspan', 'style'],
  th: ['align', 'rowspan', 'colspan', 'style'],
};

export const DEFAULT_SCHEMES = ['http', 'https', 'ftp', 'mailto', 'data'];

/**
 * The default options
 *
 * @param allowedTags - the tags to be allowed after sanitization
 * @param allowedAttributes - the attributes to be allowed after sanitization
 * @param allowedSchemes - Allowed scheme types
 * @returns Default options
 */
export const getDefaultSanitizeHtmlOptions = (
  allowedTags: string[] = DEFAULT_ALLOWED_TAGS,
  allowedAttributes: Record<string, string[]> = DEFAULT_ALLOWED_ATTRIBUTES,
  allowedSchemes: string[] = DEFAULT_SCHEMES,
): sanitizeHtmlLib.IOptions => ({
  allowedTags,
  allowedSchemes,
  allowedAttributes,
  allowedIframeHostnames: ['www.youtube.com'],
  transformTags: {
    // ensure links open securely in a new tab
    a: sanitizeHtmlLib.simpleTransform('a', {
      target: '_blank',
      rel: 'noopener noreferrer',
    }),
  },
});

/**
 * Sanitize arbitrary html before save
 *
 * @param html - The raw html string
 * @param options - The sanitize-html options
 * @returns The sanitized html
 */
export function sanitizeHtml(
  html: string,
  options: sanitizeHtmlLib.IOptions = getDefaultSanitizeHtmlOptions(),
): string {
  return sanitizeHtmlLib(html, options);
}
