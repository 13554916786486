import type { FetchResult, MutationFunctionOptions } from '@apollo/client';
import * as t from 'io-ts';

import type { ObjByString } from '@transcend-io/type-utils';

import type {
  DhEncryptedParam,
  ParamsToType,
  ResponseToType,
} from '@main/schema-utils';
import {
  DecryptionContext,
  DhEncryptionKeys,
  DiffieHellmanParams,
  DiffieHellmanResponse,
  SombraDataSubjectAuthMethod,
  SombraEmployeeAuthMethod,
} from '@main/sombra-types';

import { createDhEncrypted, decrypt, toBuffer } from './utils';

/**
 * Parameters for the diffie hellman hooks
 */
export interface DiffieHellmanHookParams {
  /** Secret to sign payloads with */
  authSecret?: string;
  /** Authentication method */
  authMethod?: SombraEmployeeAuthMethod | SombraDataSubjectAuthMethod;
  /** The diffie hellman keys to use */
  dhEncryptionKeys?: DhEncryptionKeys;
}

/**
 * A function that can generate new dhEncrypted values for a given payload
 */
export type MakeDhEncrypted = (payload?: ObjByString) => string;

/**
 * When the request responses with a decryption context, decrypt it
 */
export type DecryptResponse = (decryptionContext: DecryptionContext) => Buffer;

/**
 * This hooks creates two functions:
 *
 * 1. a function that takes in some data to be signed and dhEncrypts it
 * 2. a function that can decode a response to that original dhEncrypted request
 *
 * @param options - Diffie hellman options
 * @returns Hook functions described above
 */
export function createDhPayloadHelpers<TRequired extends boolean>({
  authSecret,
  dhEncryptionKeys,
  authMethod,
}: TRequired extends true
  ? Required<DiffieHellmanHookParams>
  : DiffieHellmanHookParams): [
  // This is undefined if the user is not authenticated to sombra and thus cannot establish a dh channel
  TRequired extends true ? MakeDhEncrypted : undefined | MakeDhEncrypted,
  DecryptResponse,
] {
  return [
    !dhEncryptionKeys || !authSecret || !authMethod
      ? (undefined as any) // hard to type enforce with generic
      : (payload = {}) =>
          createDhEncrypted(dhEncryptionKeys, authSecret, authMethod, payload),
    ({ iv, wrappedKey, authTag }) =>
      decrypt(wrappedKey, {
        key: toBuffer(dhEncryptionKeys!.kek),
        iv,
        authTag,
      }),
  ];
}

/**
 * Options to the dh mutate function
 */
export type DhOptions<
  TParams extends DiffieHellmanParams,
  TResult extends DiffieHellmanResponse,
> = MutationFunctionOptions<
  ResponseToType<TResult>,
  // Since we set dhEncrypted internally, the client does not need to
  Omit<ParamsToType<TParams>, 'dhEncrypted'>
> &
  (TParams['dhEncrypted'] extends DhEncryptedParam<any>
    ? TParams['dhEncrypted']['isOptional'] extends true
      ? {
          /** The diffie hellman input */
          dhEncryptedInput?: t.TypeOf<TParams['dhEncrypted']['underlyingType']>;
        }
      : {
          /** The diffie hellman input */
          dhEncryptedInput: t.TypeOf<TParams['dhEncrypted']['underlyingType']>;
        }
    : {
        /**
         * No dh input required
         * This is undefined when we are using dhEncrypted as basically a session token but don't care about
         * signing anything inside the payload
         */
        dhEncryptedInput?: undefined;
      });

/**
 * The mutation function for a diffie hellman request
 */
export type DiffieHellmanMutate<
  TParams extends DiffieHellmanParams,
  TResult extends DiffieHellmanResponse,
> = (options?: DhOptions<TParams, TResult>) => Promise<
  FetchResult<ResponseToType<TResult>> & {
    /** The unwrapped response from the diffie hellman channel */
    unwrappedResponse?: Buffer;
    /**
     * A function that can be used to decrypt a DecryptionContext for that request.
     * If the return type has a decryption context store under the top level field
     * "decryptionContext" it will be unwrapped by default and stored at `unwrappedResponse`
     */
    decryptDhResponse: DecryptResponse;
  }
>;

/**
 * Wrap the mutate function with diffie hellman side effects:
 * - adds a dhEncrypted: string parameter to variables
 * - unwrap the nested value inside `decryptionContext` of the response
 *
 * @param mutate - The mutate function to wrap
 * @param makeDhEncrypted - Function to generate dhEncrypted
 * @param decryptDhResponse - Function to decrypt the decryptionContext
 * @param setUnwrappedResponse - Callback on successful decrypt
 * @returns The wrapped mutate functions
 */
export function wrapMutateWithDiffieHellman<
  TParams extends DiffieHellmanParams,
  TResult extends DiffieHellmanResponse,
>(
  mutate: (
    options?: MutationFunctionOptions<
      ResponseToType<TResult>,
      ParamsToType<TParams>
    >,
  ) => Promise<FetchResult<ResponseToType<TResult>>>,
  makeDhEncrypted: MakeDhEncrypted,
  decryptDhResponse: DecryptResponse,
  setUnwrappedResponse: (unwrapped: Buffer) => void,
): DiffieHellmanMutate<TParams, TResult> {
  return async (
    { dhEncryptedInput, variables = {}, ...mutateOptions } = {} as DhOptions<
      TParams,
      TResult
    >,
  ) => {
    // Make the mutation
    const rawResponse = await mutate({
      ...mutateOptions,
      variables: {
        ...variables,
        dhEncrypted: makeDhEncrypted(dhEncryptedInput),
      } as any, // difficult to type
    });
    // pass decryptDhResponse to child in case fields other than decryptionContext need to be unwrapped
    const response = { ...rawResponse, decryptDhResponse };

    // Return response if no data
    const { data } = response;
    if (!data) {
      return response;
    }

    // Attempt to unpack the diffie hellman response
    // generic is difficult to get working with
    const decryptionContext = data?.decryptionContext as any; // hard to type

    if (decryptionContext) {
      const newUnwrappedResponse = decryptDhResponse(decryptionContext);
      setUnwrappedResponse(newUnwrappedResponse);
      return { ...response, unwrappedResponse: newUnwrappedResponse };
    }
    return response;
  };
}
