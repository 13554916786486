import { defineMessages } from '@transcend-io/internationalization';

export const legalHoldMessages = defineMessages(
  'admin-dashboard.PrivacyRequests.LegalHolds.legalHold',
  {
    valueColumnHeader: { defaultMessage: 'Value' },
    identifierColumnHeader: { defaultMessage: 'Identifier' },
    deleteSuccess: {
      defaultMessage: 'Successfully removed {count} legal holds',
    },
    deleteRecords: {
      defaultMessage: `Delete {count} {count, plural, one {Legal Hold} other {Legal Holds}}`,
    },
    addLegalHold: {
      defaultMessage: 'Add Legal Hold',
    },
    header: {
      defaultMessage: 'Legal Holds',
    },
    description: {
      defaultMessage:
        'Keep track of legal holds. Use this list to automatically freeze privacy requests when a user requests deletion.',
    },
    exportButton: {
      defaultMessage: 'Export',
    },
    importButton: {
      defaultMessage: 'Import',
    },
    missingValue: {
      defaultMessage: 'Expected each row in CSV to have "value" column defined',
    },
    missingIdentifier: {
      defaultMessage:
        'Expected each row in CSV to have "identifier" column defined',
    },
    requestCountColumnHeader: {
      defaultMessage: 'DSRs',
    },
    noRequests: {
      defaultMessage: 'No matching requests',
    },
    viewRequests: {
      defaultMessage:
        'View {count} {count, plural, one {Request} other {Requests}}',
    },
    valueLabel: {
      defaultMessage: 'Value',
    },
    legalHoldCount: {
      defaultMessage:
        '{count} {count, plural, one {Legal Hold} other {Legal Holds}}',
    },
    identifier: {
      defaultMessage: 'Identifier Type',
    },
    submitButton: {
      defaultMessage: 'Create Legal Hold',
    },
    submitSuccess: {
      defaultMessage: 'Successfully created legal hold!',
    },
    valuePlaceholder: {
      defaultMessage: 'user-on-hold@gmail.com',
    },
  },
);
