import { DataCategoryType } from '@transcend-io/privacy-types';

import { DataInventoryCategory } from '@main/datamap-types';

import type { SelectSubCategoryOption } from '../../../components';

export const UNSPECIFIED_SUBCATEGORY: SelectSubCategoryOption = {
  value: DataCategoryType.Unspecified,
  name: 'Unspecified',
  isFixed: true,
};

export const UNSPECIFIED_INVENTORY_SUB_CATEGORY: DataInventoryCategory = {
  id: '' as DataInventoryCategory['id'],
  category: DataCategoryType.Unspecified,
  name: 'Unspecified',
  slug: 'unspecified',
  dataPointCount: 0,
  attributeValues: [],
  owners: [],
  teams: [],
};
