import {
  extractFormItemWrapperProps,
  FormItemWrapper,
  IFormFieldProps,
  IFormItemWrapperProps,
  multipleValidators,
  TValidator,
} from '@main/core-ui';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { selectTeamsMessages } from './messages';
import { SelectTeams } from './SelectTeams';

export interface IFormTeamsSelectProps extends IFormFieldProps {
  /** the validation rules for the input */
  rules?: TValidator[];
  /** Whether single or multiple teams */
  isMulti?: boolean;
}

export const FormSelectTeams: React.FC<
  IFormTeamsSelectProps & Omit<IFormItemWrapperProps, 'errors'>
> = (props) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();
  const { passthroughProps, wrapperProps } = extractFormItemWrapperProps(props);

  return (
    <FormItemWrapper
      errors={errors}
      {...wrapperProps}
      label={wrapperProps.label || selectTeamsMessages.header}
    >
      <Controller
        name={props.name}
        control={control}
        rules={
          props.rules
            ? { validate: multipleValidators(props.rules) }
            : undefined
        }
        render={({ field: { ref: {} = {}, ...controllerProps } }) => (
          <SelectTeams {...passthroughProps} {...controllerProps} />
        )}
      />
    </FormItemWrapper>
  );
};
