import {
  PopoverProps,
  StyledPopoverBody,
  useOnClickOutside,
} from '@main/core-ui';
import { FIXED_COLORS } from '@main/theme';
import { hexToRgb } from '@main/theme-types';
import { ISO_31661, IsoCountryCode } from '@transcend-io/privacy-types';
import { EqualEarth } from '@visx/geo';
import { ParentSize } from '@visx/responsive';
import { scaleLinear } from '@visx/scale';
import { Portal, useTooltip } from '@visx/tooltip';
import panzoom, { PanZoom } from 'panzoom';
import React, {
  MutableRefObject,
  useCallback,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';

import geo from './world.countries.alpha-2.geo.json'; // map color and positioning constants
import { MapSVG, StyledFeature } from './wrappers';

// map color and positioning constants
const COUNTRY_WEIGHTED_FILL_BASE_COLOR = hexToRgb(FIXED_COLORS.transcend);
const COUNTRY_DEFAULT_FILL_COLOR = FIXED_COLORS.gray3;
const COUNTRY_WEIGHTED_FILL_OPACITY_MIN = 0.25;
const COUNTRY_WEIGHTED_FILL_OPACITY_MAX = 1;
const MIN_ZOOM = 1;
const MAX_ZOOM = 3;
const MAP_SCALE_COEFFICIENT = 0.2;
const getMapXOffset = (width: number): number => width / 2 - width / 60;
const getMapYOffset = (width: number): number => 0.27 * width;

/**
 * union type of all alpha2 country codes we use in privacy-types
 */
export type CountryCode = keyof typeof IsoCountryCode;

export interface GeoJSONFeature {
  /** geographic feature like country or region */
  type: 'Feature';
  /** feature code */
  id: CountryCode;
  /** actual geometry that will be translated to a svg path */
  geometry: {
    /** geometry data */
    coordinates: [number, number][][];
    /** type of geometry */
    type: 'Polygon';
  };
  /** additional feature meta data */
  properties: {
    /** name of feature */
    name: string;
  };
}

// parse json into typed structure
const world = geo as {
  /** type of feature */
  type: 'FeatureCollection';
  /** geometry of feature */
  features: GeoJSONFeature[];
};

/**
 * mapping from country codes to a weight that will be used to calculate the fill color of the region
 * relative to the weights of other regions. For instance, you could map region codes to the total number
 * of data-silos in that region and the region with the most silos will be 100% opacity while the region
 * with the least silos will be at the minimum 25% opacity with all other region weights determining their
 * relative opacity within the range set by those two extremes.
 */
export type FeatureFillWeights = {
  [key in CountryCode]?: number;
};

interface WorldMapProps {
  /** weights used to determine opacity of the regions fill color */
  featureFillWeights?: FeatureFillWeights;
  /** tooltip render prop */
  tooltip: React.FC<{
    /** alpha2 country code for the region that the tooltip is rendering relative to */
    countryCode: CountryCode;
    /** readable name of country */
    countryName: string;
  }>;
  /** callback fired when user clicks on a region in the map */
  onClickRegion?: ({
    countryCode,
    countryName,
  }: {
    /** the country code */
    countryCode: CountryCode;
    /** the country name */
    countryName: string;
  }) => void;
  /** popover prop overrides */
  popoverProps?: Pick<
    PopoverProps,
    'width' | 'height' | 'scrollable' | 'noPadding'
  >;
}

export const WorldMap: React.FC<WorldMapProps> = ({
  featureFillWeights = {},
  tooltip: renderToolTip,
  onClickRegion,
  popoverProps,
}) => {
  const svgRef: MutableRefObject<SVGSVGElement | null> =
    useRef<SVGSVGElement>(null);
  const panzoomInstance = useRef<PanZoom | undefined>();

  const [tooltipElement, setTooltipElement] = useState<HTMLDivElement>();
  // Set a provided element to be the popover element, called only once to prevent re-renders
  const setTooltipElementRef = useCallback(
    (el: HTMLDivElement) => setTooltipElement(el),
    [],
  );

  const colorWeights = Object.values(featureFillWeights);
  const fillScale = scaleLinear({
    domain: [Math.min(...colorWeights), Math.max(...colorWeights)],
    range: [
      COUNTRY_WEIGHTED_FILL_OPACITY_MIN,
      COUNTRY_WEIGHTED_FILL_OPACITY_MAX,
    ],
  });

  const getRGBA = useCallback(
    (fillWeight: number): string =>
      `rgba(${COUNTRY_WEIGHTED_FILL_BASE_COLOR.r},${
        COUNTRY_WEIGHTED_FILL_BASE_COLOR.g
      },${COUNTRY_WEIGHTED_FILL_BASE_COLOR.b},${fillScale(fillWeight)})`,
    [fillScale],
  );

  const {
    tooltipLeft,
    tooltipTop,
    tooltipOpen,
    showTooltip,
    hideTooltip,
    tooltipData,
  } = useTooltip<{
    /** feature ID as alpha2 country code, this may need to be expanded for subregions */
    countryCode: CountryCode;
  }>();

  useLayoutEffect(() => {
    if (svgRef.current !== null) {
      panzoomInstance.current = panzoom(svgRef.current, {
        // zoom levels are tuned by eye
        minZoom: MIN_ZOOM,
        maxZoom: MAX_ZOOM,
        smoothScroll: false,
        zoomSpeed: 0.07,
      });
    }
    const onScroll: () => void = () => {
      hideTooltip();
      panzoomInstance.current?.resume();
    };
    document.addEventListener('scroll', onScroll);
    return () => {
      document.removeEventListener('scroll', onScroll);
      panzoomInstance.current?.dispose();
    };
  }, [svgRef.current]);

  useOnClickOutside(tooltipElement, () => {
    hideTooltip();
    panzoomInstance.current?.resume();
  });

  return (
    <ParentSize>
      {({ width }) => (
        <>
          <MapSVG ref={svgRef} width={width} height={2 * width}>
            <EqualEarth<GeoJSONFeature>
              data={world.features}
              // initial scale tuned by eye
              scale={width * MAP_SCALE_COEFFICIENT}
              /* translation tuned by eye such that
               * map is always a set distance from the top of container (calculated from width)
               * and centered horizontally in the container
               */
              translate={[getMapXOffset(width), getMapYOffset(width)]}
            >
              {(projection) => (
                <g>
                  {projection.features.map(({ feature, path }, idx) => {
                    if (path === null) return null;

                    const fillWeight = featureFillWeights[feature.id];
                    const fillColor =
                      fillWeight === undefined
                        ? COUNTRY_DEFAULT_FILL_COLOR
                        : getRGBA(fillWeight);

                    return (
                      <StyledFeature
                        key={`map-feature-${idx}`}
                        d={path}
                        fill={fillColor}
                        onClick={(event) => {
                          if (panzoomInstance.current !== undefined) {
                            panzoomInstance.current.pause();
                            onClickRegion?.({
                              countryCode: feature.id,
                              countryName: ISO_31661[feature.id],
                            });

                            showTooltip({
                              tooltipLeft: event.clientX + 10,
                              tooltipTop: event.clientY - 31,
                              tooltipData: { countryCode: feature.id },
                            });
                          }
                        }}
                      />
                    );
                  })}
                </g>
              )}
            </EqualEarth>
          </MapSVG>
          {tooltipOpen && tooltipData !== undefined ? (
            <Portal>
              <div
                key={`tooltip-${tooltipData.countryCode}`}
                style={{
                  position: 'fixed',
                  top: tooltipTop,
                  left: tooltipLeft,
                  width: 'max-content',
                }}
              >
                <StyledPopoverBody ref={setTooltipElementRef} {...popoverProps}>
                  {renderToolTip({
                    ...tooltipData,
                    countryName: ISO_31661[tooltipData.countryCode],
                  })}
                </StyledPopoverBody>
              </div>
            </Portal>
          ) : null}
        </>
      )}
    </ParentSize>
  );
};
