/* eslint-disable max-lines */
import * as t from 'io-ts';

import { RequestAction } from '@transcend-io/privacy-types';
import { valuesOf } from '@transcend-io/type-utils';

import { TokenInput } from '@main/access-control-types';
import {
  AdditionalTimeInput,
  CommunicationInput,
  DecryptCommunicationInput,
  DecryptedEmailContents,
} from '@main/datamap-types';
import {
  DATABASE_PREFERENCE_PARAMS,
  dbModelId,
  mkMutation,
  mkQuery,
  PAGINATION_INPUT_PARAMS,
} from '@main/schema-utils';
import {
  DataSubjectAuthContext,
  DecryptionContext,
  EnrichedIdentifiers,
  ReSignEncryptedCEKContextsInput,
  UpdateRequestInput,
} from '@main/sombra-types';

import {
  Activity,
  ActivityFiltersInput,
  ActivityOrder,
  AddEnrichersToWorkflowConfigInput,
  BulkCompletionReducedRequest,
  BulkCompletionReducedRequestInput,
  CreateLegalHoldInput,
  CreateLegalMatterInput,
  CreateWorkflowInput,
  DataPointSummary,
  DataSiloSummary,
  DeleteLegalHoldsInput,
  DeleteLegalMattersInput,
  DownloadFileCounts,
  DownloadFileFiltersInput,
  EmployeeRequestInput,
  EmployeeUpdateRequestIdentifiersInput,
  FireSecondaryActionInput,
  LegalHold,
  LegalHoldOrder,
  LegalHoldsFiltersInput,
  LegalMatter,
  LegalMatterOrder,
  LegalMattersFiltersInput,
  MarkBulkRequestsAsCompletedInput,
  MarkLinkOnlyRequestFileAsReadyInput,
  ProfileDataPoint,
  ProfileDataPointFiltersInput,
  ProfileDataPointOrder,
  RedactRequestFilesInput,
  RemoveEnrichersFromWorkflowConfigInput,
  RemoveRequestIdentifiersInput,
  Request,
  RequestDataSilo,
  RequestDataSiloDataPointInput,
  RequestDataSiloFiltersInput,
  RequestDataSiloInput,
  RequestDataSiloOrder,
  RequestEnricher,
  RequestEnrichersFiltersInput,
  RequestEnrichersInput,
  RequestEnrichersOrder,
  RequestFile,
  RequestFileFiltersInput,
  RequestFileOrder,
  RequestFileTokenFiltersInput,
  RequestFiltersForDataSiloInput,
  RequestFiltersInput,
  RequestForDataSilo,
  RequestIdentifier,
  RequestIdentifiersFiltersInput,
  RequestIdentifiersInput,
  RequestIdentifiersOrder,
  RequestOrder,
  RequestsContextInput,
  UpdateEnrichersInWorkflowConfigInput,
  UpdateLegalMatterInput,
  UpdateRequestDataSiloDetailsInput,
  UpdateRequestDataSiloStatusesInput,
  UpdateRequestDataSiloStatusInput,
  UpdateRequestFileStatusInput,
  UpdateWorkflowCommunicationSettingsInput,
  UpdateWorkflowConfigInput,
  WorkflowCommunicationSettings,
  WorkflowConfig,
  WorkflowConfigIdInput,
  WorkflowConfigInput,
  WorkflowDataSiloInput,
  WorkflowEnricher,
  WorkflowEnrichersInput,
  WorkflowFilterInput,
  WorkflowIdentifier,
} from './schema';

export const activities = mkQuery({
  name: 'activities',
  comment: 'The audit trail for data subject request resolution',
  params: {
    ...PAGINATION_INPUT_PARAMS,
    filterBy: ActivityFiltersInput,
    orderBy: ActivityOrder,
  },
  response: {
    nodes: Activity.list(),
    totalCount: 'int',
  },
});

export const cancelRequest = mkMutation({
  name: 'cancelRequest',
  comment:
    'Cancel a request and notify the user that the request has been canceled',
  params: {
    input: CommunicationInput,
  },
  response: {
    request: Request,
  },
});

export const notifyAdditionalTime = mkMutation({
  name: 'notifyAdditionalTime',
  comment:
    'Notify that data subject that additional time is needed to complete the request',
  params: {
    input: AdditionalTimeInput,
  },
  response: {
    success: 'boolean',
  },
});

export const resumeRequest = mkMutation({
  name: 'resumeRequest',
  comment: 'Resume processing of a request that is placed on hold',
  params: {
    id: {
      modelName: 'request',
      type: 'id',
    },
  },
  response: {
    request: Request,
  },
});

export const placeRequestOnHold = mkMutation({
  name: 'placeRequestOnHold',
  comment: 'Place an active request on hold',
  params: {
    id: {
      modelName: 'request',
      type: 'id',
    },
  },
  response: {
    request: Request,
  },
});

export const updateRequestDataSiloDetails = mkMutation({
  name: 'updateRequestDataSiloDetails',
  comment:
    'Update the details for a specified bulk response request (aka RequestDataSilo)',
  params: {
    input: UpdateRequestDataSiloDetailsInput,
  },
  response: {
    success: 'boolean',
  },
});

export const compileRequest = mkMutation({
  name: 'compileRequest',
  comment: 'Begin compiling a request that is queued',
  params: {
    id: {
      type: 'id',
      modelName: 'request',
    },
  },
  response: {
    request: Request,
  },
});

export const employeeMakeDataSubjectRequest = mkMutation({
  name: 'employeeMakeDataSubjectRequest',
  comment: 'Submit a data subject request as an employee in the organization',
  params: {
    input: EmployeeRequestInput,
    dhEncrypted: {
      type: 'string',
      isOptional: true, // `dhEncrypted` can also be passed in via request header
      underlyingType: t.type({
        attestedAuthContext: DataSubjectAuthContext,
        actionType: valuesOf(RequestAction),
      }),
    },
  },
  response: {
    request: Request,
  },
});

export const employeeUpdateRequestIdentifiers = mkMutation({
  name: 'employeeUpdateRequestIdentifiers',
  comment:
    'Update attested identifiers on the request as an employee in the organization',
  params: {
    input: EmployeeUpdateRequestIdentifiersInput,
    dhEncrypted: {
      type: 'string',
      underlyingType: t.type({
        identifiers: EnrichedIdentifiers,
      }),
    },
  },
  response: {
    requestEnricher: RequestEnricher,
  },
});

export const unwrapProfileIdentifier = mkMutation({
  name: 'unwrapProfileIdentifier',
  comment: 'Unwrap identifier for a profile',
  params: {
    id: {
      modelName: 'profile',
      type: 'id',
    },
    dhEncrypted: 'string',
  },
  response: {
    decryptionContext: DecryptionContext,
  },
});

export const unwrapRequestIdentifier = mkMutation({
  name: 'unwrapRequestIdentifier',
  comment: 'Unwrap identifier for a request',
  params: {
    id: {
      modelName: 'RequestIdentifier',
      type: 'id',
    },
    dhEncrypted: 'string',
  },
  response: {
    decryptionContext: DecryptionContext,
  },
});

export const unwrapEmailContents = mkMutation({
  name: 'unwrapEmailContents',
  comment: 'Unwrap the subject and body of a request email',
  params: {
    input: DecryptCommunicationInput,
    dhEncrypted: 'string',
  },
  response: {
    communications: DecryptedEmailContents.list(),
  },
});

export const employeeDownloadCEK = mkMutation({
  name: 'employeeDownloadCEK',
  comment:
    'Get the decryption context needed to download a zip of the files for a request',
  params: {
    id: {
      modelName: 'request',
      type: 'id',
    },
    dhEncrypted: 'string',
  },
  response: {
    decryptionContext: DecryptionContext,
  },
});

export const updateRequest = mkMutation({
  name: 'updateRequest',
  comment:
    'Update the details of a request or mark it as a test/silent request',
  params: {
    input: UpdateRequestInput,
  },
  response: {
    request: Request,
  },
});

export const employeeReSignEncryptedCEKContexts = mkMutation({
  name: 'employeeReSignEncryptedCEKContexts',
  comment: 'ReSign the encrypted CEK context for each of the given requests',
  params: {
    input: ReSignEncryptedCEKContextsInput,
    dhEncrypted: {
      type: 'string',
      underlyingType: t.type({
        requestIds: t.array(dbModelId('request')),
      }),
    },
  },
  response: {
    done: 'boolean',
  },
});

export const approveRequest = mkMutation({
  name: 'approveRequest',
  comment: 'Approve a request and send download link to user',
  params: {
    input: CommunicationInput,
  },
  response: {
    request: Request,
  },
});

export const approveRequestSecondary = mkMutation({
  name: 'approveRequestSecondary',
  comment:
    'Approve a request in the secondary state - this is approval after deletion is completed.',
  params: {
    input: CommunicationInput,
  },
  response: {
    request: Request,
  },
});

export const requestDataSilos = mkQuery({
  name: 'requestDataSilos',
  comment: 'Get the data silo statuses associated with a request',
  params: {
    ...PAGINATION_INPUT_PARAMS,
    ...DATABASE_PREFERENCE_PARAMS,
    filterBy: RequestDataSiloFiltersInput,
    orderBy: RequestDataSiloOrder,
  },
  response: {
    nodes: RequestDataSilo.list(),
    totalCount: 'int',
  },
});

export const requestDataSilo = mkQuery({
  name: 'requestDataSilo',
  comment: 'Get a data silo lookup for a particular request',
  params: {
    input: RequestDataSiloInput,
  },
  response: RequestDataSilo,
});

export const profileDataPoints = mkQuery({
  name: 'profileDataPoints',
  comment: 'List out ProfileDataPoint metadata',
  params: {
    ...PAGINATION_INPUT_PARAMS,
    ...DATABASE_PREFERENCE_PARAMS,
    filterBy: ProfileDataPointFiltersInput,
    orderBy: ProfileDataPointOrder,
  },
  response: {
    nodes: ProfileDataPoint.list(),
    totalCount: 'int',
  },
});

export const requestFiles = mkQuery({
  name: 'requestFiles',
  comment:
    'Get the files to include in a data subject access request download zip',
  params: {
    ...PAGINATION_INPUT_PARAMS,
    filterBy: RequestFileFiltersInput,
    orderBy: RequestFileOrder,
  },
  response: {
    nodes: RequestFile.list(),
    totalCount: 'int',
    totalSize: 'float',
  },
});

export const requestFilesCountsOnly = mkQuery({
  name: 'requestFilesCountsOnly',
  comment: 'Get files counts as if they were downloaded from privacy center',
  params: {
    filterBy: DownloadFileFiltersInput,
  },
  response: {
    nodes: DownloadFileCounts.list(),
  },
});

export const requestFilesToken = mkQuery({
  name: 'requestFilesToken',
  comment:
    'Get the files to include in a data subject access request download zip, but authenticated via a token magic link.',
  params: {
    ...PAGINATION_INPUT_PARAMS,
    filterBy: RequestFileTokenFiltersInput,
    orderBy: RequestFileOrder,
    input: TokenInput.optional(),
  },
  response: {
    nodes: RequestFile.list(),
    totalCount: 'int',
    totalSize: 'int',
  },
});

export const deleteRequestFile = mkMutation({
  name: 'deleteRequestFile',
  comment: 'Remove a specific file from a request',
  params: {
    id: {
      comment: 'The unique id of the request file to delete',
      modelName: 'requestFile',
      type: 'id',
    },
  },
  response: {
    success: 'boolean',
  },
});

export const deleteRequestFileToken = mkMutation({
  name: 'deleteRequestFileToken',
  comment: 'Remove a specific file from a request using a magic link',
  params: {
    id: {
      comment: 'The unique id of the request file to delete',
      modelName: 'requestFile',
      type: 'id',
    },
    input: TokenInput,
  },
  response: {
    success: 'boolean',
  },
});

export const redactRequestFiles = mkMutation({
  name: 'redactRequestFiles',
  comment: 'Set the redaction indices for subjective request files',
  params: {
    input: RedactRequestFilesInput,
  },
  response: {
    requestFiles: RequestFile.list(),
  },
});

export const removeRequestIdentifiers = mkMutation({
  name: 'removeRequestIdentifiers',
  comment: 'Remove a set of RequestIdentifiers on a request',
  params: {
    input: RemoveRequestIdentifiersInput,
  },
  response: {
    count: 'int',
  },
});

export const skipRequestEnricher = mkMutation({
  name: 'skipRequestEnricher',
  comment: 'Skip a RequestEnricher from processing',
  params: {
    id: {
      type: 'id',
      modelName: 'RequestEnricher',
    },
  },
  response: {
    requestEnricher: RequestEnricher,
  },
});

export const request = mkQuery({
  name: 'request',
  comment: 'Get a request by id',
  params: {
    id: {
      comment: 'The id of the request',
      type: 'id',
      modelName: 'request',
    },
  },
  response: Request,
});

export const fireSecondaryAction = mkMutation({
  name: 'fireSecondaryAction',
  comment: 'Manually fire the secondary action for a request',
  params: {
    input: FireSecondaryActionInput,
  },
  response: {
    request: Request,
  },
});

export const changeRequestDataSiloStatus = mkMutation({
  name: 'changeRequestDataSiloStatus',
  comment: 'Mark a request data silo as completed',
  params: {
    input: UpdateRequestDataSiloStatusInput,
  },
  response: {
    requestDataSilo: RequestDataSilo,
  },
});

export const changeRequestDataSiloStatuses = mkMutation({
  name: 'changeRequestDataSiloStatuses',
  comment: 'Mark a request data silo as completed',
  params: {
    input: UpdateRequestDataSiloStatusesInput,
  },
  response: {
    count: 'int',
  },
});

export const requests = mkQuery({
  name: 'requests',
  comment: 'List the data subject requests made to the logged in organization',
  params: {
    ...PAGINATION_INPUT_PARAMS,
    filterBy: RequestFiltersInput.optional(),
    orderBy: RequestOrder,
    input: RequestsContextInput.optional(),
    ...DATABASE_PREFERENCE_PARAMS,
  },
  response: {
    nodes: Request.list(),
    totalCount: 'int',
  },
});

export const legalHolds = mkQuery({
  name: 'legalHolds',
  comment: 'List the legal holds for the existing organization',
  params: {
    ...PAGINATION_INPUT_PARAMS,
    filterBy: LegalHoldsFiltersInput.optional(),
    orderBy: LegalHoldOrder,
    ...DATABASE_PREFERENCE_PARAMS,
  },
  response: {
    nodes: LegalHold.list(),
    totalCount: 'int',
  },
});

export const legalMatters = mkQuery({
  name: 'legalMatters',
  comment: 'List the legal matters for the existing organization',
  params: {
    ...PAGINATION_INPUT_PARAMS,
    filterBy: LegalMattersFiltersInput.optional(),
    orderBy: LegalMatterOrder,
  },
  response: {
    nodes: LegalMatter.list(),
    totalCount: 'int',
  },
});

export const createLegalHolds = mkMutation({
  name: 'createLegalHolds',
  comment: 'Create a new legal hold',
  params: {
    input: CreateLegalHoldInput,
  },
  response: {
    legalHolds: LegalHold.list(),
  },
});

export const deleteLegalHolds = mkMutation({
  name: 'deleteLegalHolds',
  comment: 'Remove an existing legal hold',
  params: {
    input: DeleteLegalHoldsInput,
  },
  response: {
    count: 'int',
  },
});

export const deleteLegalMatters = mkMutation({
  name: 'deleteLegalMatters',
  comment: 'Remove legal matters',
  params: {
    input: DeleteLegalMattersInput,
  },
  response: {
    count: 'int',
  },
});

export const createLegalMatter = mkMutation({
  name: 'createLegalMatter',
  comment: 'Create a new legal matter',
  params: {
    input: CreateLegalMatterInput,
  },
  response: {
    legalMatter: LegalMatter,
  },
});

export const updateLegalMatter = mkMutation({
  name: 'updateLegalMatter',
  comment: 'Update the configuration of a legal matter',
  params: {
    input: UpdateLegalMatterInput,
  },
  response: {
    legalMatter: LegalMatter,
  },
});

export const requestIdentifiers = mkQuery({
  name: 'requestIdentifiers',
  comment: 'List the identifiers for a given request',
  params: {
    ...PAGINATION_INPUT_PARAMS,
    ...DATABASE_PREFERENCE_PARAMS,
    filterBy: RequestIdentifiersFiltersInput.optional(),
    orderBy: RequestIdentifiersOrder,
    input: RequestIdentifiersInput,
  },
  response: {
    nodes: RequestIdentifier.list(),
    totalCount: 'int',
  },
});

export const requestEnrichers = mkQuery({
  name: 'requestEnrichers',
  comment: 'List the enrichers for a given request',
  params: {
    ...PAGINATION_INPUT_PARAMS,
    ...DATABASE_PREFERENCE_PARAMS,
    filterBy: RequestEnrichersFiltersInput.optional(),
    orderBy: RequestEnrichersOrder,
    input: RequestEnrichersInput,
  },
  response: {
    nodes: RequestEnricher.list(),
    totalCount: 'int',
  },
});

export const requestsForDataSilo = mkQuery({
  name: 'requestsForDataSilo',
  comment:
    'List the data subject requests made to the logged in organization for a specified data silo',
  params: {
    ...PAGINATION_INPUT_PARAMS,
    filterBy: RequestFiltersForDataSiloInput.optional(),
  },
  response: {
    nodes: RequestForDataSilo.list(),
    totalCount: 'int',
  },
});

export const dataSiloSummary = mkQuery({
  name: 'dataSiloSummary',
  comment:
    'Get a summary of the status of a data silo being processed over all privacy requests',
  params: {
    id: {
      type: 'id',
      modelName: 'dataSilo',
    },
  },
  response: DataSiloSummary,
});

export const updateRequestFileStatus = mkMutation({
  name: 'updateRequestFileStatus',
  comment:
    // eslint-disable-next-line max-len
    "Manually update the state of a request file. Used only in the case a remote request file that is on our systems, and the client needs to manually confirm they've actioned upon it.",
  params: {
    input: UpdateRequestFileStatusInput,
  },
  response: {
    requestFile: RequestFile,
  },
});

export const markLinkOnlyRequestFilesAsReady = mkMutation({
  name: 'markLinkOnlyRequestFilesAsReady',
  comment:
    'Manually mark all link-only Request Files as Ready, associated with the given Request Data Silo',
  params: {
    input: MarkLinkOnlyRequestFileAsReadyInput,
  },
  response: {
    requestFiles: RequestFile.list(),
  },
});

export const savePaVCompletionStatus = mkMutation({
  name: 'savePaVCompletionStatus',
  comment: 'Save the Prompt a vendor request completion status',
  params: {
    input: MarkBulkRequestsAsCompletedInput,
  },
  response: {
    success: 'boolean',
  },
});

export const listReducedRequestsForDataSilo = mkQuery({
  name: 'listReducedRequestsForDataSilo',
  comment:
    'List reduced-scope requests for a data silo, designed primarily' +
    ' for users not logged-in',
  params: {
    ...PAGINATION_INPUT_PARAMS,
    input: BulkCompletionReducedRequestInput,
  },
  response: {
    nodes: BulkCompletionReducedRequest.list(),
    totalCount: 'int',
  },
});

export const retryProfileDataPoint = mkMutation({
  name: 'retryProfileDataPoint',
  comment: 'Re-run a datapoint resolver for a specific profile',
  params: {
    id: {
      type: 'id',
      modelName: 'ProfileDataPoint',
    },
  },
  response: {
    profileDataPoint: ProfileDataPoint,
  },
});

export const retryRequestDataSilo = mkMutation({
  name: 'retryRequestDataSilo',
  comment:
    'Retry a RequestDataSilo and begin compilation process from scratch (note cannot be done after secondary action)',
  params: {
    id: {
      type: 'id',
      modelName: 'RequestDataSilo',
    },
  },
  response: {
    requestDataSilo: RequestDataSilo,
  },
});

export const retryRequestEnricher = mkMutation({
  name: 'retryRequestEnricher',
  comment: 'Retry a request enricher that is not in a completed state',
  params: {
    id: {
      type: 'id',
      modelName: 'RequestEnricher',
    },
  },
  response: {
    requestEnricher: RequestEnricher,
  },
});

export const retryProfileDataPointSecondary = mkMutation({
  name: 'retryProfileDataPointSecondary',
  comment:
    'Re-run a datapoint resolver for a specific profile in a secondary state',
  params: {
    id: {
      type: 'id',
      modelName: 'ProfileDataPoint',
    },
  },
  response: {
    profileDataPoint: ProfileDataPoint,
  },
});

export const requestDataSiloDataPoints = mkQuery({
  name: 'requestDataSiloDataPoints',
  comment: 'List out datapoints for a specific RequestDataSilo',
  params: {
    ...PAGINATION_INPUT_PARAMS,
    input: RequestDataSiloDataPointInput.optional(),
    id: {
      modelName: 'RequestDataSilo',
      type: 'id',
    },
  },
  response: {
    nodes: DataPointSummary.list(),
    totalCount: 'int',
  },
});

export const workflows = mkQuery({
  name: 'workflows',
  comment: 'List the workflows for the organization',
  params: {
    ...PAGINATION_INPUT_PARAMS,
    filterBy: WorkflowFilterInput.optional(),
  },
  response: {
    nodes: WorkflowConfig.list(),
    totalCount: 'int',
  },
});

export const workflowConfig = mkQuery({
  name: 'workflowConfig',
  comment: 'get a workflowConfig',
  params: {
    input: WorkflowConfigInput,
  },
  response: {
    workflowConfig: WorkflowConfig,
  },
});

export const workflowIdentifiers = mkQuery({
  name: 'workflowIdentifiers',
  comment: 'Get identifiers for a given workflow',
  params: {
    ...PAGINATION_INPUT_PARAMS,
    input: WorkflowConfigIdInput,
  },
  response: {
    nodes: WorkflowIdentifier.list(),
    totalCount: 'int',
  },
});

export const workflowEnrichers = mkQuery({
  name: 'workflowEnrichers',
  comment: 'Get enrichers for a given workflow',
  params: {
    ...PAGINATION_INPUT_PARAMS,
    input: WorkflowEnrichersInput,
  },
  response: {
    nodes: WorkflowEnricher.list(),
    totalCount: 'int',
  },
});

export const addEnrichersToWorkflowConfig = mkMutation({
  name: 'addEnrichersToWorkflowConfig',
  comment: 'Add a list of enrichers to a given workflow',
  params: {
    input: AddEnrichersToWorkflowConfigInput,
  },
  response: {
    success: 'boolean',
  },
});

export const updateEnrichersInWorkflowConfig = mkMutation({
  name: 'updateEnrichersInWorkflowConfig',
  comment: 'Update a list of enrichers in a given workflow',
  params: {
    input: UpdateEnrichersInWorkflowConfigInput,
  },
  response: {
    success: 'boolean',
  },
});

export const removeEnrichersFromWorkflowConfig = mkMutation({
  name: 'removeEnrichersFromWorkflowConfig',
  comment: 'Remove a list of enrichers from a given workflow',
  params: {
    input: RemoveEnrichersFromWorkflowConfigInput,
  },
  response: {
    success: 'boolean',
  },
});

export const workflowCommunicationsSettings = mkQuery({
  name: 'workflowCommunicationsSettings',
  comment: 'Get communications for a given workflow',
  params: {
    input: WorkflowConfigIdInput,
  },
  response: {
    nodes: WorkflowCommunicationSettings.list(),
    totalCount: 'int',
  },
});

export const createWorkflow = mkMutation({
  name: 'createWorkflow',
  comment: 'Create workflow with basic details',
  params: {
    input: CreateWorkflowInput,
  },
  response: {
    workflowConfig: WorkflowConfig,
  },
});

export const updateWorkflowConfig = mkMutation({
  name: 'updateWorkflowConfig',
  comment: 'updates basic details for a workflowConfig',
  params: {
    input: UpdateWorkflowConfigInput,
  },
  response: {
    success: 'boolean',
  },
});

export const addDataSiloToWorkflowConfig = mkMutation({
  name: 'addDataSiloToWorkflowConfig',
  comment: 'adds a data silo to a workflow',
  params: {
    input: WorkflowDataSiloInput,
  },
  response: {
    success: 'boolean',
  },
});

export const removeDataSiloFromWorkflowConfig = mkMutation({
  name: 'removeDataSiloFromWorkflowConfig',
  comment: 'remove a data silo from a workflow',
  params: {
    input: WorkflowDataSiloInput,
  },
  response: {
    success: 'boolean',
  },
});

export const updateWorkflowCommunicationSettings = mkMutation({
  name: 'updateWorkflowCommunicationSettings',
  comment: 'Update a workflows communication settings',
  params: {
    input: UpdateWorkflowCommunicationSettingsInput,
  },
  response: {
    success: 'boolean',
  },
});

/* eslint-enable max-lines */
