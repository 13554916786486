const NUMBERS = '0123456789';
const LETTER_NUMBERS = 'abcdefghijklmnopqrstuvwxyz0123456789';

/**
 * Returns a random string of length `len` using only lowercase letters and numbers,
 * generated using `Math.random()`.
 *
 * ```typescript
 * // Generate a random string of length 10
 * const randomStr = randomString(10);
 * ```
 *
 * @param len - The length of the string
 * @param includeNumbers - When true, only generate a string with random numbers.
 * @returns A random string
 */
export function randomString(len: number, includeNumbers = false): string {
  let text = '';
  const charset = includeNumbers ? NUMBERS : LETTER_NUMBERS;
  for (let i = 0; i < len; i += 1) {
    text += charset.charAt(Math.floor(Math.random() * charset.length));
  }
  return text;
}
