/**
 * the options of settings to use
 */
export enum PrivacyCenterEmailSettingsKey {
  ReplyToEmail = 'replyToEmail',
  EmailPrefix = 'emailPrefix',
  SupportEmail = 'supportEmail',
  UseNoReplyEmailAddress = 'useNoReplyEmailAddress',
  UseCustomEmailDomain = 'useCustomEmailDomain',
}
