import { defineMessages } from '@transcend-io/internationalization';

export const selectVendorBusinessEntityMessages = defineMessages(
  'admin-dashboard.DataMap.components.SelectBusinessEntities.selectVendorBusinessEntity',
  {
    placeholder: {
      defaultMessage: 'Assign Business Entity',
    },
  },
);
