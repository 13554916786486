import { useInlineInput } from '@main/ad-core-components';
import {
  SelectCatalog,
  SelectCatalogProps,
} from '@main/admin-dashboard/src/DataMap/TriageSiloDiscovery/components/SelectCatalog/SelectCatalog';
import { endpoints } from '@main/code-scanning-types';
import { buildUseMutation } from '@main/core-ui';
import { SelectableCatalog } from '@main/datamap-types';
import type { ID } from '@main/schema-utils';
import React from 'react';

interface InlineSoftwareDevelopmentKitsCatalogUpdateProps
  extends Omit<SelectCatalogProps, 'onChange' | 'onBlur' | 'value'> {
  /** The ID of the parent softwareDevelopmentKit for this cell */
  id: ID<'softwareDevelopmentKit'>;
  /** The value for the cell */
  value?: SelectableCatalog;
  /** Guessed catalog */
  guessedCatalog?: SelectableCatalog;
}

const useUpdateSoftwareDevelopmentKits = buildUseMutation(
  endpoints.updateSoftwareDevelopmentKits,
);

export const InlineSoftwareDevelopmentKitsCatalogUpdate: React.FC<
  InlineSoftwareDevelopmentKitsCatalogUpdateProps
> = ({ id, value: initialValue, guessedCatalog, ...props }) => {
  const [updateSoftwareDevelopmentKits, { loading }] =
    useUpdateSoftwareDevelopmentKits();

  const { value, setValue, onBlur } = useInlineInput<
    SelectableCatalog | undefined
  >({
    initialValue: initialValue || guessedCatalog,
    onUpdate: (value) =>
      updateSoftwareDevelopmentKits({
        variables: {
          input: {
            softwareDevelopmentKits: [
              {
                id,
                catalogIntegrationName: (value?.integrationName ||
                  null) as string,
              },
            ],
          },
        },
      }).then(({ data, errors }) => ({
        data: data?.softwareDevelopmentKits[0]?.catalog,
        errors,
      })),
  });

  return (
    <SelectCatalog
      value={value}
      isLoading={loading}
      onChange={(newValue) => {
        setValue(newValue || undefined);
      }}
      // Render a badge to indicate a guessed catalog
      {...(!initialValue && guessedCatalog
        ? {
            getOptionLabel: (option) => `*${option.title}`,
          }
        : {})}
      onBlur={onBlur}
      {...props}
    />
  );
};
