import { defineMessages } from '@transcend-io/internationalization';

export const onboardingItemCollectionsMessages = defineMessages(
  'admin-dashboard.Auth.ActionItems.OnboardingItemsCollections.onboardingItemCollections',
  {
    createCollection: {
      defaultMessage: 'Add Checklist',
    },
    titleLabel: {
      defaultMessage: 'Title',
    },
    titleDescription: {
      defaultMessage: 'The displayed title of the collection',
    },
    descriptionLabel: {
      defaultMessage: 'Description',
    },
    descriptionDescription: {
      defaultMessage: 'The displayed description of the collection',
    },
    hiddenDescription: {
      defaultMessage:
        '{count} {count, plural, one {checklist is} other {checklists are}} hidden',
    },
    hiddenAreVisible: {
      defaultMessage:
        'Showing {count} hidden {count, plural, one {checklist} other {checklists}}',
    },
  },
);
