import styled from 'styled-components';

import { Button } from '@main/ad-core-components';

export const StyledWrapper = styled.div`
  width: 100%;

  .hideable-select__multi-value {
    border: none;
    height: auto;
  }
  .styled-option-label {
    padding: 0;
    border-width: 0;
  }
`;

export const DoubleTagButton = styled(Button)`
  padding: 0;
  height: 18px;
  min-height: 18px;
  width: 18px;
  margin: 0 5px 0 -3px;
  color: inherit;

  svg {
    flex-shrink: 0;
  }

  :hover,
  :focus,
  :active {
    color: inherit;
  }
`;

export const StyledRightLabelSpan = styled.span`
  display: inline-flex;
  color: ${({ theme }) => theme.colors.transcendNavy};
  margin: 0px 7px;
  white-space: nowrap;
  cursor: pointer;
  align-items: center;

  svg {
    flex-shrink: 0;
  }
`;
