import { defineMessages } from '@transcend-io/internationalization';

export const andMoreMessages = defineMessages(
  'ad-core-components.AndMore.andMore',
  {
    numMore: {
      defaultMessage: '{num} more',
    },
  },
);
