import { useEffect, useState } from 'react';

import { CustomImageOptions } from './extensions';

export const useTransformImageSrc = (
  src: string,
  transformSrc?: CustomImageOptions['transformSrc'],
): string => {
  const [transformedUrl, setTransformedUrl] = useState(src);

  useEffect(() => {
    let mounted = true;

    if (transformSrc) {
      transformSrc(src).then((result: string) => {
        // Can't perform react state update on unmounted component
        if (mounted) {
          setTransformedUrl(result);
        }
      });
    }
    return () => {
      mounted = false;
    };
  }, [transformSrc, src]);

  return transformedUrl;
};
