/* eslint-disable max-lines */
import { CodePackageType } from '@transcend-io/privacy-types';

import { TeamPreview, UserPreview } from '@main/access-control-types';
import { DataSiloPreview } from '@main/datamap-types';
import { mkInput, mkOrder, mkType, SchemaToType } from '@main/schema-utils';

import { CodePackageOrderField } from './enums';
import { RepositoryPreview } from './repository';
import { SoftwareDevelopmentKitForPackage } from './softwareDevelopmentKit';

export const CodePackagePreview = mkType({
  name: 'CodePackagePreview',
  comment: 'A preview of a code package instance',
  fields: {
    id: {
      comment: 'The id of the code package',
      modelName: 'codePackage',
      type: 'id',
    },
    name: {
      comment: 'The name of the code package',
      type: 'string',
    },
    description: {
      comment: 'The description of the code package',
      type: 'string',
    },
    createdAt: {
      comment: 'The date the code package was created',
      type: 'Date',
    },
    updatedAt: {
      comment: 'The date the code package was last updated',
      type: 'Date',
    },
    lastSeenAt: {
      comment: 'The date the code package was last seen reported',
      type: 'Date',
    },
    type: {
      comment: 'The type of code package',
      type: { CodePackageType },
    },
    relativePath: {
      comment:
        'Relative path to where the code package lives within the repository',
      type: 'string',
    },
  },
});

/** Override type */
export type CodePackagePreview = SchemaToType<typeof CodePackagePreview>;

export const CodePackage = mkType({
  name: 'CodePackage',
  comment:
    'A code package that contains code submitted to a particular repository',
  fields: {
    ...CodePackagePreview.fields,
    softwareDevelopmentKits: {
      comment: 'The SDKs that the code package has within it',
      type: () => SoftwareDevelopmentKitForPackage,
      list: true,
    },
    dataSilo: {
      comment: 'The data silo that the code package relates to',
      type: DataSiloPreview,
      optional: true,
    },
    repository: {
      comment: 'The repository that code package resides in',
      type: () => RepositoryPreview,
    },
    owners: {
      comment: 'The owners that maintain the code package',
      type: UserPreview,
      list: true,
    },
    teams: {
      comment: 'The teams that maintain the code package',
      type: TeamPreview,
      list: true,
    },
    promptRunCount: {
      comment: 'The number of prompt runs that this code package has ran',
      type: 'int',
    },
  },
});

/** Override type */
export type CodePackage = SchemaToType<typeof CodePackage>;

export const CodePackageFiltersInput = mkInput({
  name: 'CodePackageFiltersInput',
  comment: 'Inputs for filtering a list of code packages',
  fields: {
    ids: {
      comment: 'The ids of the code packages',
      type: 'id',
      modelName: 'codePackage',
      optional: true,
      list: true,
    },
    ownerIds: {
      comment: 'The ids of the owners that maintain the code packages',
      type: 'id',
      modelName: 'user',
      optional: true,
      list: true,
    },
    teamIds: {
      comment: 'The ids of the teams that maintain the code packages',
      type: 'id',
      modelName: 'team',
      optional: true,
      list: true,
    },
    repositoryIds: {
      comment: 'The ids of the repositories that the code packages are made to',
      type: 'id',
      modelName: 'repository',
      optional: true,
      list: true,
    },
    softwareDevelopmentKitIds: {
      comment:
        'The ids of the software development kits that the code packages are made to',
      type: 'id',
      modelName: 'softwareDevelopmentKit',
      optional: true,
      list: true,
    },
    text: {
      comment: 'Filter by text (name, description, url)',
      optional: true,
      type: 'string',
    },
    name: {
      comment: 'Filter by code package name',
      optional: true,
      type: 'string',
    },
    names: {
      comment: 'Filter on exact code package names',
      optional: true,
      type: 'string',
      list: true,
    },
    linkedToConsentApp: {
      comment:
        'Filter by whether or not the entry is linked to a consent application',
      optional: true,
      type: 'boolean',
    },
    types: {
      comment: 'The types of code package to include',
      type: { CodePackageType },
      optional: true,
      list: true,
    },
  },
});

/** Override type */
export type CodePackageFiltersInput = SchemaToType<
  typeof CodePackageFiltersInput
>;

export const DeleteCodePackagesInput = mkInput({
  name: 'DeleteCodePackagesInput',
  comment: 'Input for deleting a set of code packages',
  fields: {
    ids: {
      comment: 'The id of the code packages to delete',
      type: 'id',
      modelName: 'codePackage',
      list: true,
    },
  },
});

/** Override type */
export type DeleteCodePackagesInput = SchemaToType<
  typeof DeleteCodePackagesInput
>;

export const CreateCodePackageInput = mkInput({
  name: 'CreateCodePackageInput',
  comment: 'Input for creating a code package',
  fields: {
    name: {
      comment: 'The name of the code package',
      type: 'string',
    },
    description: {
      comment: 'The description of the code package',
      type: 'string',
      optional: true,
    },
    type: {
      comment: 'The type of code package',
      type: { CodePackageType },
    },
    relativePath: {
      comment:
        'Relative path to where the code package lives within the repository',
      type: 'string',
    },
    repositoryId: {
      comment: 'The ID of the repository that code package resides in',
      modelName: 'repository',
      type: 'id',
      optional: true,
    },
    repositoryName: {
      comment: 'The name of the repository that code package resides in',
      type: 'string',
      optional: true,
    },
    softwareDevelopmentKitIds: {
      comment:
        'The ids of the software development kits that the code packages are related to',
      type: 'id',
      modelName: 'softwareDevelopmentKit',
      optional: true,
      list: true,
    },
    ownerIds: {
      comment: 'The IDs of owners that maintain the code package',
      modelName: 'user',
      type: 'id',
      list: true,
      optional: true,
    },
    ownerEmails: {
      comment: 'The emails of owners that maintain the code package',
      type: 'string',
      list: true,
      optional: true,
    },
    teamIds: {
      comment: 'The IDs of owners that maintain the code package',
      modelName: 'team',
      type: 'id',
      list: true,
      optional: true,
    },
    teamNames: {
      comment: 'The names of team that maintain the code package',
      type: 'string',
      list: true,
      optional: true,
    },
  },
});

/** Override type */
export type CreateCodePackageInput = SchemaToType<
  typeof CreateCodePackageInput
>;

export const UpdateCodePackageInput = mkInput({
  name: 'UpdateCodePackageInput',
  comment: 'Input for updating a code package',
  fields: {
    id: {
      comment: 'The id of the code package',
      modelName: 'codePackage',
      type: 'id',
    },
    name: {
      comment: 'The name of the code package',
      type: 'string',
      optional: true,
    },
    description: {
      comment: 'The description of the code package',
      type: 'string',
      optional: true,
    },
    type: {
      comment: 'The type of code package',
      type: { CodePackageType },
      optional: true,
    },
    relativePath: {
      comment:
        'Relative path to where the code package lives within the repository',
      type: 'string',
      optional: true,
    },
    ownerIds: {
      comment: 'The IDs of owners that maintain the code package',
      modelName: 'user',
      type: 'id',
      list: true,
      optional: true,
    },
    ownerEmails: {
      comment: 'The emails of owners that maintain the code package',
      type: 'string',
      list: true,
      optional: true,
    },
    teamIds: {
      comment: 'The IDs of owners that maintain the code package',
      modelName: 'team',
      type: 'id',
      list: true,
      optional: true,
    },
    teamNames: {
      comment: 'The names of team that maintain the code package',
      type: 'string',
      list: true,
      optional: true,
    },
    softwareDevelopmentKitIds: {
      comment:
        'The ids of the software development kits that the code packages are related to',
      type: 'id',
      modelName: 'softwareDevelopmentKit',
      optional: true,
      list: true,
    },
  },
});

/** Override type */
export type UpdateCodePackageInput = SchemaToType<
  typeof UpdateCodePackageInput
>;

export const UpdateCodePackagesInput = mkInput({
  name: 'UpdateCodePackagesInput',
  comment: 'Input for bulk updating one or more code packages',
  fields: {
    codePackages: {
      comment:
        'List of code packages with the properties that should be updated for each',
      type: UpdateCodePackageInput,
      list: true,
    },
  },
});

/** Override type */
export type UpdateCodePackagesInput = SchemaToType<
  typeof UpdateCodePackagesInput
>;

/** Order for a code packages query */
export const CodePackageOrder = mkOrder(
  CodePackage.name,
  CodePackageOrderField,
);
/* eslint-enable max-lines */
