import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import difference from 'lodash/difference';
import uniq from 'lodash/uniq';

import type { ID } from '@main/schema-utils';

/**
 * the preference store state
 */
export interface PreferenceStoreState {
  /** The preference topics state */
  preferenceTopics: ID<'preferenceTopic'>[];
  /** The consent workflows state */
  consentWorkflows: ID<'consentWorkflowTrigger'>[];
  /** The preference options state */
  preferenceOptions: ID<'preferenceOptionValue'>[];
}

export const preferenceStoreSlice = createSlice({
  name: 'PreferenceStore',
  initialState: {
    preferenceTopics: [],
    consentWorkflows: [],
    preferenceOptions: [],
  } as PreferenceStoreState,
  reducers: {
    selectPreferenceTopic: (
      state,
      { payload }: PayloadAction<ID<'preferenceTopic'>>,
    ) => {
      state.preferenceTopics.push(payload);
    },
    unselectPreferenceTopic: (
      { preferenceTopics, ...state },
      { payload }: PayloadAction<ID<'preferenceTopic'>>,
    ) => ({
      ...state,
      preferenceTopics: preferenceTopics.filter((id) => id !== payload),
    }),
    selectPreferenceTopics: (
      state,
      { payload }: PayloadAction<ID<'preferenceTopic'>[]>,
    ) => {
      state.preferenceTopics = uniq(state.preferenceTopics.concat(payload));
    },
    unselectPreferenceTopics: (
      state,
      { payload }: PayloadAction<ID<'preferenceTopic'>[]>,
    ) => {
      state.preferenceTopics = difference(state.preferenceTopics, payload);
    },
    resetSelectedPreferenceTopics: (state) => ({
      ...state,
      preferenceTopics: [],
    }),
    selectPreferenceOption: (
      state,
      { payload }: PayloadAction<ID<'preferenceOptionValue'>>,
    ) => {
      state.preferenceOptions.push(payload);
    },
    unselectPreferenceOption: (
      { preferenceOptions, ...state },
      { payload }: PayloadAction<ID<'preferenceOptionValue'>>,
    ) => ({
      ...state,
      preferenceOptions: preferenceOptions.filter((id) => id !== payload),
    }),
    selectPreferenceOptions: (
      state,
      { payload }: PayloadAction<ID<'preferenceOptionValue'>[]>,
    ) => {
      state.preferenceOptions = uniq(state.preferenceOptions.concat(payload));
    },
    unselectPreferenceOptions: (
      state,
      { payload }: PayloadAction<ID<'preferenceOptionValue'>[]>,
    ) => {
      state.preferenceOptions = difference(state.preferenceOptions, payload);
    },
    resetSelectedPreferenceOptions: (state) => ({
      ...state,
      preferenceOptions: [],
    }),
    selectConsentWorkflow: (
      state,
      { payload }: PayloadAction<ID<'consentWorkflowTrigger'>>,
    ) => {
      state.consentWorkflows.push(payload);
    },
    unselectConsentWorkflow: (
      { consentWorkflows, ...state },
      { payload }: PayloadAction<ID<'consentWorkflowTrigger'>>,
    ) => ({
      ...state,
      consentWorkflows: consentWorkflows.filter((id) => id !== payload),
    }),
    selectConsentWorkflows: (
      state,
      { payload }: PayloadAction<ID<'consentWorkflowTrigger'>[]>,
    ) => {
      state.consentWorkflows = uniq(state.consentWorkflows.concat(payload));
    },
    unselectConsentWorkflows: (
      state,
      { payload }: PayloadAction<ID<'consentWorkflowTrigger'>[]>,
    ) => {
      state.consentWorkflows = difference(state.consentWorkflows, payload);
    },
    resetSelectedConsentWorkflows: (state) => ({
      ...state,
      consentWorkflows: [],
    }),
  },
});

export const {
  selectPreferenceTopic,
  unselectPreferenceTopic,
  selectPreferenceTopics,
  unselectPreferenceTopics,
  resetSelectedPreferenceTopics,
  selectPreferenceOption,
  unselectPreferenceOption,
  selectPreferenceOptions,
  unselectPreferenceOptions,
  resetSelectedPreferenceOptions,
  selectConsentWorkflow,
  unselectConsentWorkflow,
  selectConsentWorkflows,
  unselectConsentWorkflows,
  resetSelectedConsentWorkflows,
} = preferenceStoreSlice.actions;
