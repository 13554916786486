import {
  FlexColumn,
  FlexRow,
  Tooltip,
  useFormatMessageGeneric,
  useListOverflow,
} from '@main/core-ui';
import React from 'react';
import { useIntl } from 'react-intl';
import { useTheme } from 'styled-components';

import { andMoreMessages } from './messages';
import { AndMoreProps } from './types';

/**
 * AndMore
 */
export const AndMore: React.FC<AndMoreProps> = ({
  list,
  limit = 3,
  andMoreMessage = andMoreMessages.numMore,
  showTooltip = true,
  countAndMoreAsSegment,
  style,
}) => {
  const { formatMessageGeneric } = useFormatMessageGeneric();
  const { formatList } = useIntl();
  const theme = useTheme();

  const { isOverflowing, listBeforeOverflow, listAfterOverflow } =
    useListOverflow({ list, limit, countAndMoreAsSegment });

  return (
    <div
      style={
        style ?? {
          color: theme.colors.transcendNavy,
          fontWeight: 600,
          fontSize: 18,
        }
      }
    >
      {formatList(
        listBeforeOverflow.concat(
          isOverflowing
            ? [
                <Tooltip
                  key="andMore"
                  title={
                    showTooltip ? (
                      <FlexColumn style={{ color: 'unset' }}>
                        {listAfterOverflow.map((val, i) => (
                          <FlexRow key={i} style={{ color: 'unset' }}>
                            {val}
                          </FlexRow>
                        ))}
                      </FlexColumn>
                    ) : undefined
                  }
                >
                  <span style={{ fontWeight: showTooltip ? 600 : undefined }}>
                    {formatMessageGeneric(andMoreMessage, {
                      num: listAfterOverflow.length,
                    })}
                  </span>
                </Tooltip>,
              ]
            : [],
        ),
        { type: 'conjunction' },
      )}
    </div>
  );
};
