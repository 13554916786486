import { CodePackagePreview } from '@main/code-scanning-types';
import { mkInput, mkType, SchemaToType } from '@main/schema-utils';

export const ConsentApplicationPreview = mkType({
  name: 'ConsentApplicationPreview',
  comment: 'The application (namespace) for storing consent data',
  fields: {
    id: {
      type: 'id',
      modelName: 'consentApplication',
      comment: 'ID of the consent application',
    },
    name: {
      type: 'string',
      comment: 'Unique name of this consent application',
    },
    description: {
      type: 'string',
      comment: 'Description of this consent application',
    },
    codePackage: {
      type: CodePackagePreview,
      comment: 'The code package associated with this consent application',
      optional: true,
    },
  },
});

/** Override type */
export type ConsentApplicationPreview = SchemaToType<
  typeof ConsentApplicationPreview
>;

export const ConsentApplication = mkType({
  name: 'ConsentApplication',
  comment: 'The application (namespace) for storing consent data',
  fields: {
    ...ConsentApplicationPreview.fields,
    sdkCount: {
      type: 'int',
      comment: 'Number of SDKs associated with this consent application',
    },
  },
});

/** Override type */
export type ConsentApplication = SchemaToType<typeof ConsentApplication>;

export const ConsentApplicationFiltersInput = mkInput({
  name: 'ConsentApplicationFiltersInput',
  comment: 'Input for filter consent application',
  fields: {
    ids: {
      ...ConsentApplication.fields.id,
      list: true,
      optional: true,
    },
    text: {
      type: 'string',
      comment: 'Filter the consent applications by name',
      optional: true,
    },
  },
});

/** Override type */
export type ConsentApplicationFiltersInput = SchemaToType<
  typeof ConsentApplicationFiltersInput
>;

export const ConsentApplicationUpsertInput = mkInput({
  name: 'ConsentApplicationUpsertInput',
  comment: 'Input for creating or updating a consent application',
  fields: {
    id: {
      ...ConsentApplication.fields.id,
      optional: true,
    },
    name: {
      ...ConsentApplication.fields.name,
      optional: true,
    },
    description: {
      ...ConsentApplication.fields.description,
      optional: true,
    },
    codePackageId: {
      type: 'id',
      modelName: 'codePackage',
      comment:
        'ID of the code package to associate with this consent application',
      optional: true,
    },
  },
});

/** Override type */
export type ConsentApplicationUpsertInput = SchemaToType<
  typeof ConsentApplicationUpsertInput
>;

export const CreateOrUpdateConsentApplicationInput = mkInput({
  name: 'CreateOrUpdateConsentApplicationInput',
  comment: 'Input for creating a new consent application',
  fields: {
    consentApplications: {
      comment: 'The list of consent applications to create or update',
      type: ConsentApplicationUpsertInput,
      list: true,
    },
  },
});

/** Override type */
export type CreateOrUpdateConsentApplicationInput = SchemaToType<
  typeof CreateOrUpdateConsentApplicationInput
>;

export const DeleteConsentApplicationInput = mkInput({
  name: 'DeleteConsentApplicationInput',
  comment: 'Input for deleting consent applications by ID',
  fields: {
    ids: {
      type: 'id',
      modelName: 'consentApplication',
      comment: 'ID of the consent application to delete',
      list: true,
    },
  },
});

/** Override type */
export type DeleteConsentApplicationInput = SchemaToType<
  typeof DeleteConsentApplicationInput
>;
