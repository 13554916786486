import {
  PaginatedSelect,
  PaginatedSelectProps,
} from '@main/ad-core-components';
import { buildUseInfiniteScroll } from '@main/core-ui';
import {
  endpoints,
  SaaSCategory,
  SaaSCategoryOrderField,
} from '@main/datamap-types';
import { OrderDirection } from '@main/schema-utils';
import React, { useState } from 'react';
import { OptionsType } from 'react-select';

/**
 * Recipient category/SaaS Category
 */
export type RecipientCategory = Pick<SaaSCategory, 'id' | 'title'>;

const useSaaSCategories = buildUseInfiniteScroll(
  endpoints.saaSCategories,
  endpoints.saaSCategories.name,
  {
    totalCount: null,
    nodes: {
      id: null,
      title: null,
    },
  },
);

export interface SelectRecipientsProps
  extends Omit<
    PaginatedSelectProps<RecipientCategory, true>,
    'fetchMore' | 'onChange'
  > {
  /** On change handler */
  onChange?: (categories: OptionsType<RecipientCategory>) => void;
}

export const SelectRecipients: React.FC<SelectRecipientsProps> = ({
  isQueryLoading,
  ...props
}) => {
  const [searchText, setSearchText] = useState('');
  const { data, loading, fetchMore, error } = useSaaSCategories({
    fetchPolicy: 'cache-and-network',
    variables: {
      filterBy: {
        categoryText: searchText,
      },
      orderBy: [
        { field: SaaSCategoryOrderField.Title, direction: OrderDirection.ASC },
      ],
    },
  });

  return (
    <PaginatedSelect
      options={data?.nodes ?? []}
      isMulti
      getOptionLabel={({ title }) => title}
      fetchMore={fetchMore}
      isQueryLoading={isQueryLoading || loading}
      queryError={error}
      isClearable={false}
      showOutline={false}
      onEndsTyping={setSearchText}
      {...props}
    />
  );
};
