import { makeEnum } from '@transcend-io/type-utils';

/**
 * The possible options for configuring default consent
 *
 * These options are simplified for the UI.
 * Their equivalence in the airgap.js browser API:
 * Opt-in => 'off' / false
 * Opt-out (unless for any reason not to; legal or otherwise) => 'Auto'
 */
export const DefaultConsentOption = makeEnum({
  OptIn: 'off',
  OptOut: 'Auto',
});

/** Override type */
export type DefaultConsentOption =
  (typeof DefaultConsentOption)[keyof typeof DefaultConsentOption];
