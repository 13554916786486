import styled from 'styled-components';

export const SupportTableHeaders = styled.th`
  font-size: 10px;
  font-weight: 600;
  text-transform: uppercase;
  cursor: auto;
  letter-spacing: 0.5px;
  color: ${({ theme }) => theme.colors.transcendNavy2};
  padding-bottom: 16px;

  &:not(:last-child) {
    padding-right: 20px;
  }
`;

export const SupportTableBodyCell = styled.td`
  padding-bottom: 16px;

  &:not(:last-child) {
    padding-right: 20px;
  }
`;

export const SupportTableRowLabel = styled.div`
  font-size: 14px;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.transcendNavy};
  display: flex;
  align-items: center;
  gap: 4px;
`;

export const SupportTableRowDescription = styled.p`
  font-size: 12px;
  font-weight: 300;
  color: ${({ theme }) => theme.colors.transcendNavy3};
  margin: 0;
`;
