import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import type { ID } from '@main/schema-utils';

/**
 * The DataPractices redux store
 */
export interface DataPracticesState {
  /** The id of the data collection that is being edited */
  activeDataCollectionId: ID<'dataCollection'> | undefined;
  /** The id of the data category that is being edited */
  activeDataCategoryId: ID<'dataCategory'> | undefined;
}

export const dataPracticesSlice = createSlice({
  name: 'PrivacyCenter.DataPractices',
  initialState: {} as DataPracticesState,
  reducers: {
    // Close the modal and stop editing
    closeDataPracticeModal: (state) => {
      state.activeDataCategoryId = undefined;
      state.activeDataCollectionId = undefined;
    },
    // Open the modal for editing a data category
    selectDataCategory: (
      state,
      { payload: dataCategoryId }: PayloadAction<ID<'dataCategory'>>,
    ) => {
      state.activeDataCollectionId = undefined;
      state.activeDataCategoryId = dataCategoryId;
    },
    // Open the modal for editing a data collection
    selectDataCollection: (
      state,
      { payload: dataCollectionId }: PayloadAction<ID<'dataCollection'>>,
    ) => {
      state.activeDataCategoryId = undefined;
      state.activeDataCollectionId = dataCollectionId;
    },
  },
});

export const {
  closeDataPracticeModal,
  selectDataCollection,
  selectDataCategory,
} = dataPracticesSlice.actions;
