/**
 * the current selected unit
 */
export enum ScheduleInputUnit {
  Weekly = 'Weekly',
  Daily = 'Daily',
  Hourly = 'Hourly',
  Minute = 'Minute',
}

export interface ScheduleInputPair {
  /** the frequency in ms */
  frequencyMs: number;
  /** the start date of the interval */
  startDate: Date;
}

export interface ScheduleInputProps {
  /** the value of the input */
  value?: ScheduleInputPair;
  /** the onchange handler */
  onChange: (changed?: ScheduleInputPair) => void;
  /** whether to allow time to be specified */
  defaultUnit?: ScheduleInputUnit;
  /** whether to allow time to be specified */
  allowedUnits?: ScheduleInputUnit[];
  /** whether to hide the date picker components */
  hideStartDate?: boolean;
}
