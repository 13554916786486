/**
 * The fields that an legalMatters can be ordered on
 */
export enum LegalMatterOrderField {
  /** Sort alphabetically */
  Title = 'title',
  /** The time the legal hold was made */
  CreatedAt = 'createdAt',
  /** The time the legal hold was updated */
  UpdatedAt = 'updatedAt',
}
