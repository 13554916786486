import isEqual from 'lodash/isEqual';
import { createSelector } from 'reselect';

import { applyEnum } from '@transcend-io/type-utils';

import {
  PrivacyCenterAssetName,
  UpdatePrivacyCenterInput,
} from '@main/pc-types';
import { NEW_INDICATOR } from '@main/schema-utils';
import { indexBy, indexTree } from '@main/utils';

import type { DataCategoryIndex, DataCollectionIndex } from './slice';
import { PrivacyCenterState } from './slice';

export const getPrivacyCenterView = (state: any): PrivacyCenterState =>
  state.PrivacyCenter;

/**
 * The privacy center that is the current state of production (does not contain local changes)
 */
export const selectDeployedPrivacyCenter = createSelector(
  getPrivacyCenterView,
  ({ deployedPrivacyCenter }) => deployedPrivacyCenter,
);

/**
 * The privacy center that contains all local changes
 */
export const selectPrivacyCenter = createSelector(
  getPrivacyCenterView,
  ({ privacyCenter }) => privacyCenter,
);

/**
 * The ID of the privacy center
 */
export const selectPrivacyCenterId = createSelector(
  selectPrivacyCenter,
  // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
  (privacyCenter) => privacyCenter?.id!, // type enforcing this as it will always be string once moved to the URL
);

/**
 * The fully defined theme
 */
export const selectTheme = createSelector(
  selectPrivacyCenter,
  (privacyCenter) => privacyCenter?.theme,
);

/**
 * The partial theme
 */
export const selectPartialTheme = createSelector(
  selectPrivacyCenter,
  (privacyCenter) => privacyCenter?.partialTheme,
);

/**
 * The URL
 */
export const selectPrivacyCenterUrl = createSelector(
  selectPrivacyCenter,
  (privacyCenter) => privacyCenter?.url,
);

/**
 * The nested data practices
 */
export const selectDataPractices = createSelector(
  selectPrivacyCenter,
  (privacyCenter) => privacyCenter?.dataPractices || [],
);

/**
 * The data applications
 */
export const selectDataApplications = createSelector(
  selectPrivacyCenter,
  (privacyCenter) => privacyCenter?.dataApplications || [],
);

/**
 * Lookup data application by id
 */
export const selectDataApplicationIndex = createSelector(
  selectDataApplications,
  (dataApplications) => indexBy(dataApplications, 'id'),
);

/**
 * The index of the data category within the nested data practice object off the Privacy Center
 */
export const selectDataCategoryIndex = createSelector(
  selectDataPractices,
  (dataPractices): { [k in string]: DataCategoryIndex } =>
    indexTree(dataPractices as any, 'id' as any).pathLookup as any,
);

/**
 * The index of the data collection within the nested data practice object off the Privacy Center
 */
export const selectDataCollectionIndex = createSelector(
  selectDataPractices,
  (dataPractices): { [k in string]: DataCollectionIndex } =>
    indexTree(dataPractices as any, 'id' as any).pathLookup as any,
);

/**
 * Indicator that the asset files have changed and need to be saved
 */
export const selectIsModified = createSelector(
  selectDeployedPrivacyCenter,
  selectPrivacyCenter,
  (deployedPrivacyCenter, privacyCenter) =>
    !!deployedPrivacyCenter &&
    !!privacyCenter &&
    !isEqual(deployedPrivacyCenter, privacyCenter),
);

/**
 * The data practices as an input type
 */
export const selectPrivacyCenterInput = createSelector(
  selectPrivacyCenter,
  (privacyCenter): UpdatePrivacyCenterInput | undefined =>
    !privacyCenter
      ? undefined
      : {
          privacyCenterId: privacyCenter.id,
          // Get the id and alt text of the assets that were updated
          assets: applyEnum(PrivacyCenterAssetName, (name) => {
            const asset = privacyCenter.assets[name];
            return asset
              ? { id: asset.id, alt: asset.alt?.defaultMessage }
              : null!;
          }),
          // Get the privacy center home url
          home: privacyCenter.home.defaultMessage,
          locales: privacyCenter.locales,
          isDisabled: privacyCenter.isDisabled,
          customSubdomain: privacyCenter.customSubdomain,
          supportEmail: privacyCenter.supportEmail,
          replyToEmail: privacyCenter.replyToEmail,
          emailPrefix: privacyCenter.emailPrefix,
          defaultLocale: privacyCenter.defaultLocale,
          preferBrowserDefaultLocale: privacyCenter.preferBrowserDefaultLocale,
          hideDataPractices: privacyCenter.hideDataPractices,
          showTrackingTechnologies: privacyCenter.showTrackingTechnologies,
          showManageYourPrivacy: privacyCenter.showManageYourPrivacy,
          showSaleOfInfo: privacyCenter.showSaleOfInfo,
          unauthenticatedDoNotSellRegions:
            privacyCenter.unauthenticatedDoNotSellRegions.map((region) => ({
              country: region.country,
              countrySubDivision: region.countrySubDivision,
            })),
          showMarketingPreferences: privacyCenter.showMarketingPreferences,
          showPrivacyRequestButton: privacyCenter.showPrivacyRequestButton,
          showPolicies: privacyCenter.showPolicies,
          showCookies: privacyCenter.showCookies,
          showConsentManager: privacyCenter.showConsentManager,
          showDataFlows: privacyCenter.showDataFlows,
          useNoReplyEmailAddress: privacyCenter.useNoReplyEmailAddress,
          useCustomEmailDomain: privacyCenter.useCustomEmailDomain,
          transformAccessReportJsonToCsv:
            privacyCenter.transformAccessReportJsonToCsv,
          allowPartitionChange: privacyCenter.allowPartitionChange,
          showPartition: privacyCenter.showPartition,
          // Get editable (partial theme) colors
          colorPalette: privacyCenter.partialTheme.colors,
          componentStyles: privacyCenter.partialTheme.componentStyles,
          textStyles: privacyCenter.partialTheme.textStyles,
          dataApplications: privacyCenter.dataApplications.map(
            (dataApplication) => ({
              id: dataApplication.id,
              title: dataApplication.title.defaultMessage,
              description: dataApplication.description.defaultMessage,
              method: dataApplication.method,
            }),
          ),
          // Grab the inputs that cannot be inferred from shape of data practices
          dataPractices: privacyCenter.dataPractices.map((practice) => ({
            method: practice.method,
            children: practice.children.map((category) => ({
              id: category.id.includes(NEW_INDICATOR) ? undefined : category.id,
              title: category.title.defaultMessage,
              staticType: category.staticType,
              children: (category.children || []).map((collection) => ({
                id: collection.id.includes(NEW_INDICATOR)
                  ? undefined
                  : collection.id,
                title: collection.title.defaultMessage,
                description: collection.description?.defaultMessage,
                staticType: collection.staticType,
                dataApplicationIds: collection.dataApplications.map(
                  ({ id }) => id,
                ),
                icon: collection.logo,
              })),
            })),
          })),
        },
);

/**
 * The url of the privacy center
 */
export const selectUrl = createSelector(
  selectDeployedPrivacyCenter,
  (deployedPrivacyCenter) => deployedPrivacyCenter?.url,
);

/**
 * The custom subdomain of the (fully launched) privacy center
 */
export const selectCustomSubdomain = createSelector(
  selectPrivacyCenter,
  (privacyCenter) => privacyCenter?.customSubdomain,
);

/**
 * The base domain of the (fully launched) privacy center
 */
export const selectDomain = createSelector(
  selectPrivacyCenter,
  (privacyCenter) => privacyCenter?.transcendHostedDomain,
);
