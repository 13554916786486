import { FilterConfigItem } from '@main/ad-core-components';
import {
  SelectedRepository,
  SelectRepositories,
} from '@main/admin-dashboard/src/CodeScanning/components/SelectRepositories';
import { endpoints } from '@main/code-scanning-types';
import { buildUseLazyQuery } from '@main/core-ui';
import { ID } from '@main/schema-utils';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { MessageDescriptor } from 'react-intl';

import { filterManagerHookMessages } from './messages';

/**
 * Filters definition with property for repository values specified
 */
type RepositoryEnrichedFilters = Record<string, unknown> &
  Record<
    /**
     * Repository values that are currently filtering the table
     */
    string,
    SelectedRepository[] | undefined
  >;

const useLazyRepositories = buildUseLazyQuery(endpoints.repositories);

export const useRepositoryFilters = <
  T extends Record<string, unknown>,
  E extends RepositoryEnrichedFilters,
>({
  repositoryIdsFilterKey = 'repositoryIds',
  filters,
  setFilters,
  label,
  enrichedRepositoryKey = 'repositories',
}: {
  /** The property name of the filter for repository ids */
  repositoryIdsFilterKey?: keyof T;
  /** The currently applied filters */
  filters?: T;
  /** Callback when the filters are changed */
  setFilters: (filters: T) => void;
  /** Label for the filter menu */
  label: string | MessageDescriptor;
  /** The key to use if more than one useRepositoryFilters is used in a FilterManager */
  enrichedRepositoryKey?: keyof E;
}): {
  /**  The repository keys enabled on this table */
  selectedRepositories: SelectedRepository[];
  /** The filter configuration to be passed to the filter manager */
  repositoryFiltersConfig: FilterConfigItem<E>;
  /** Callback for when the filter is cleared in the filter manager */
  clearRepositoryFilters: (key: Extract<keyof E, string>) => void;
} => {
  const getSelectedRepositories = useLazyRepositories();
  const [selectedRepositories, setSelectedRepositories] = useState<
    SelectedRepository[]
  >([]);
  const [fetchingRepositories, setFetchingRepositories] = useState(false);

  // Populate initial complex filter values
  useEffect(() => {
    if (filters) {
      const repositoryIds = (filters[repositoryIdsFilterKey] ??
        []) as ID<'repository'>[];

      const filtersAndEnrichedFiltersMatch =
        repositoryIds.sort().join() ===
        selectedRepositories
          .map(({ id }) => id)
          .sort()
          .join();

      if (repositoryIds.length === 0 && selectedRepositories.length > 0) {
        setSelectedRepositories([]);
      } else if (!filtersAndEnrichedFiltersMatch && !fetchingRepositories) {
        // Prevent over-firing this setter while data is still being fetched
        setFetchingRepositories(true);
        getSelectedRepositories({ filterBy: { ids: repositoryIds } })
          .then(({ data }) => {
            setSelectedRepositories(data.nodes);
          })
          .finally(() => {
            setFetchingRepositories(false);
          });
      }
    }
  }, [
    filters,
    fetchingRepositories,
    getSelectedRepositories,
    selectedRepositories.length,
    repositoryIdsFilterKey,
    selectedRepositories,
  ]);

  const repositoryFiltersConfig = useMemo(
    () =>
      ({
        filterKey: enrichedRepositoryKey,
        label,
        pillOptions: {
          label: ({
            filterValues: { [enrichedRepositoryKey]: repositories = [] },
            index = 0,
          }) => repositories[index]?.name,
        },
        filter: (
          <SelectRepositories
            isMulti
            placeholderDescriptor={
              filterManagerHookMessages.repositoriesPlaceholder
            }
            menuPosition="absolute"
            value={selectedRepositories}
            showOutline={false}
            onChange={(repositories) => {
              setSelectedRepositories([...repositories]);
              setFilters({
                ...filters,
                [repositoryIdsFilterKey]: repositories.map(({ id }) => id),
              } as T);
            }}
          />
        ),
      }) as FilterConfigItem<RepositoryEnrichedFilters>,
    [
      enrichedRepositoryKey,
      label,
      selectedRepositories,
      filters,
      repositoryIdsFilterKey,
    ],
  );

  const clearRepositoryFilters = useCallback(
    (key) => {
      if (key === enrichedRepositoryKey) {
        setFilters({
          ...filters,
          [repositoryIdsFilterKey]: [],
        } as T);
        setSelectedRepositories([]);
      }
    },
    [enrichedRepositoryKey, filters, repositoryIdsFilterKey],
  );

  return {
    selectedRepositories,
    repositoryFiltersConfig,
    clearRepositoryFilters,
  };
};
