import { defineMessages } from '@transcend-io/internationalization';

export const codePackagesMessages = defineMessages(
  'admin-dashboard.CodeScanning.CodePackages.codePackages',
  {
    newPackage: {
      defaultMessage: 'New Package',
    },
    deleteSuccess: {
      defaultMessage:
        '{count, plural, one {Package} other {Packages}} successfully deleted',
    },
    packagesCount: {
      defaultMessage: '{count} {count, plural, one {Package} other {Packages}}',
    },
    noDataMessage: {
      defaultMessage: 'No packages to display.',
    },
    confirmDeleteButton: {
      defaultMessage:
        'Delete {count, plural, one {Package} other {{count} Packages}}',
    },
    confirmDeletePrompt: {
      defaultMessage:
        'Are you sure you want to delete {count, plural, one {this package} other {{count} packages}}?',
    },
    codePackagesCount: {
      defaultMessage: '{count} {count, plural, one {package} other {packages}}',
    },
  },
);

export const codePackagesColumnHeaderMessages = defineMessages(
  'admin-dashboard.CodeScanning.CodePackages.codePackagesColumnHeader',
  {
    name: {
      defaultMessage: 'Name',
    },
    description: {
      defaultMessage: 'Description',
    },
    createdAt: {
      defaultMessage: 'Created At',
    },
    lastSeenAt: {
      defaultMessage: 'Last Seen At',
    },
    type: {
      defaultMessage: 'Package Type',
    },
    relativePath: {
      defaultMessage: 'Relative Path',
    },
    promptRunCount: {
      defaultMessage: 'Prompt Runs',
    },
    softwareDevelopmentKits: {
      defaultMessage: 'SDKs',
    },
    owners: {
      defaultMessage: 'Owners',
    },
    teams: {
      defaultMessage: 'Teams',
    },
    dataSilo: {
      defaultMessage: 'Data Silo',
    },
    repository: {
      defaultMessage: 'Repository',
    },
  },
);

export const packageModalMessages = defineMessages(
  'admin-dashboard.CodeScanning.CodePackages.packageModal',
  {
    modalButton: {
      defaultMessage: 'New Package',
    },
    header: {
      defaultMessage: 'New Package',
    },
    introduction: {
      defaultMessage:
        'Create a new package that you want to track calls to your large language models.',
    },
    submitButton: {
      defaultMessage: 'Create New Package',
    },
    submitSuccess: {
      defaultMessage: 'New package created!',
    },
  },
);
