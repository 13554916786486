import { createSelector } from 'reselect';

import { CTABannersState } from './slice';

export const getCTABanners = (state: any): CTABannersState => state.ctaBanners;

export const selectCTABanners = createSelector(
  getCTABanners,
  (ctaBanners) => ctaBanners,
);
