import { defineMessages } from '@transcend-io/internationalization';

export const saveChangesMessages = defineMessages(
  'ad-core-components.SaveChanges.saveChanges',
  {
    unsaved: {
      defaultMessage:
        'You have unsaved changes, are you sure you want to leave?',
    },
    save: {
      defaultMessage: 'Save Changes',
    },
    discard: {
      defaultMessage: 'Discard',
    },
    previewTitle: {
      defaultMessage: 'Preview Changes',
    },
  },
);
