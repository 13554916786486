import * as t from 'io-ts';
import { Any, IntersectionC, LiteralC, UnionC } from 'io-ts';

import { DatabaseModelName, dbModelId, IDCodec } from '@main/schema-utils';

import { AuditEventCode } from './schema/enums';

export const AuditEventAdditionalContextBase = t.intersection([
  t.type({
    baseModelTitle: t.string,
    actorTitle: t.string,
  }),
  t.partial({
    /**
     * whether the row is part of a replacement of the entire join list or just an
     * individual addition or removal
     */
    replaceEntireJoin: t.boolean,
    actionItemCode: t.string,
    /** additional message to be displayed */
    additionalMessage: t.string,
  }),
]);

/** Override types. */
export type AuditEventAdditionalContextBase = t.TypeOf<
  typeof AuditEventAdditionalContextBase
>;

export const AuditEventBase = t.intersection([
  t.type({
    /** The id of the associated org */
    organizationId: dbModelId('organization'),
    /** JSON data before change */
    beforeState: t.any,
    /** JSON data after change */
    afterState: t.any,
    /** Optional JSON data with additional context */
    additionalContext: AuditEventAdditionalContextBase,
    /** UUID of transaction */
    transactionUuid: t.string,
  }),
  t.partial({
    /** the id of the user making the change */
    actorUserId: dbModelId('user'),
    /** the id of the api key making the change */
    actorApiKeyId: dbModelId('apiKey'),
  }),
]);

/** Override types. */
export type AuditEventBase = t.TypeOf<typeof AuditEventBase>;

/**
 * makes the default join table state props
 *
 * @param modelName - the name of the model being joined
 * @param additionalState - additional state beyond the base join state
 * @returns the state codec
 */
export function mkAuditJoinStateCodec<
  T extends DatabaseModelName,
  U extends Any,
>(
  modelName: T,
  additionalState?: U,
): UnionC<
  [
    t.UndefinedC,
    t.IntersectionC<
      [
        t.TypeC<{
          /** the id of the model being joined */
          id: IDCodec<T>;
          /** the title of the model being joined */
          title: t.StringC;
        }>,
        t.PartialC<{}> | NonNullable<U>,
      ]
    >,
  ]
> {
  return t.union([
    t.undefined,
    t.intersection([
      t.type({ id: dbModelId(modelName), title: t.string }),
      additionalState ?? t.partial({}),
    ]),
  ]);
}

/**
 * The audit event codec type returned by mkAuditEventCodec
 * and mkAuditEventJoinCodec
 */
export type AuditEventCodec<
  CODE extends AuditEventCode,
  STATE extends Any,
  EXTRA extends Any,
> = IntersectionC<
  [
    t.TypeC<{
      /** the id of the model being joined */
      code: LiteralC<CODE>;
    }>,
    t.PartialC<{
      /** the before state of the model */
      beforeState: STATE;
      /** the after state of the model */
      afterState: STATE;
    }>,
    EXTRA,
    typeof AuditEventBase,
  ]
>;

/**
 * makes the default base table state props
 *
 * @param code - the audit event code for the base model
 * @param additionalProps - the additional props to include at the top level
 * @returns the state codec
 */
export function mkAuditEventCodec<
  CODE extends AuditEventCode,
  EXTRA extends Any,
>(code: CODE, additionalProps: EXTRA): AuditEventCodec<CODE, t.Any, EXTRA> {
  return mkAuditEventJoinCodec(code, t.any as any, additionalProps);
}

/**
 * makes the default base table state props for a join
 *
 * @param code - the audit event code for the base model
 * @param state - the changeable state of the base model
 * @param additionalProps - the additional props to include at the top level
 * @returns the state codec
 */
export function mkAuditEventJoinCodec<
  CODE extends AuditEventCode,
  STATE extends Any,
  EXTRA extends Any,
>(
  code: CODE,
  state: STATE,
  additionalProps: EXTRA,
): AuditEventCodec<CODE, STATE, EXTRA> {
  return t.intersection([
    t.type({ code: t.literal(code) }),
    t.partial({ beforeState: state, afterState: state }),
    additionalProps,
    AuditEventBase,
  ]);
}
