import { arrayDifference } from 'interval-operations';

import type { Intervals } from '@main/dsr-types';

/**
 * Remove an interval from a list of intervals
 *
 * @param selections - The list of ranges to merge
 * @param removeRange - The interval to remove
 * @returns Union all intervals
 */
export function differenceIntervals(
  selections: Intervals,
  removeRange: [number, number],
): Intervals {
  // Ensure all intervals are positive
  return arrayDifference(selections, [removeRange]) as Intervals;
}
