/* eslint-disable max-lines */
import {
  AdminDashboardAdministrationPath,
  AdminDashboardPrivateMiscPath,
} from '@main/access-control-types';
import { Input, OrganizationIcon } from '@main/ad-core-components';
import { SUPPORT_CONTACT } from '@main/admin-dashboard/src/args';
import {
  selectOrganizationFaviconSrc,
  selectOrganizationName,
  selectUser,
} from '@main/admin-dashboard/src/Auth/App/selectors';
import {
  FlexColumn,
  FlexRowCenterVertical,
  Icon,
  LoadingSpinner,
  Popover,
  StyleUtils,
  Tooltip,
  useRedirect,
} from '@main/core-ui';
import { IconType } from '@main/core-ui/src/Icon/constants';
import { SIDEBAR_TRANSITION } from '@main/theme';
import orderBy from 'lodash/orderBy';
import qs from 'query-string';
import React, { ReactNode, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { generatePath, Location, useLocation } from 'react-router-dom';
import { useTheme } from 'styled-components';

import { useIntercomLoaded } from './hooks';
import { adminMenuMessages } from './messages';
import { useLogout } from './useLogout';
import {
  EmailWrapper,
  MenuItemLink,
  OrganizationAdminSection,
  OrganizationNameLabel,
  StyledButton,
} from './wrappers';

/**
 * Admin menu item props
 */
export interface MenuItemProps {
  /** href to link to */
  href?: string;
  /** The text label */
  text: ReactNode;
  /** The icon type, be rendered with ad-core-components/Icon */
  icon: IconType | JSX.Element;
  /** The right-hand icon type, be rendered with ad-core-components/Icon */
  rightIcon?: IconType | JSX.Element;
  /** onClick handler instead of href if needed, i.e. logging out */
  onClick?: () => void;
  /** Any extra styling for the list item */
  style?: React.CSSProperties;
  /** Is the sidebar expanded? (optional) */
  isExpanded?: boolean;
  /** disabled? (optional) */
  disabled?: boolean;
  /** html id override */
  id?: string;
}

/**
 * Admin menu item
 */
export const MenuItem = React.forwardRef<HTMLDivElement, MenuItemProps>(
  (
    {
      href,
      text,
      icon,
      rightIcon,
      onClick,
      style,
      isExpanded = true,
      id,
      disabled,
    }: MenuItemProps,
    ref,
  ): JSX.Element => (
    <div ref={ref} style={style} id={id}>
      <MenuItemLink
        href={href}
        onClick={onClick}
        style={{
          opacity: disabled ? 0.6 : 1,
        }}
        disabled={disabled}
      >
        <FlexRowCenterVertical
          style={{
            padding: '2px',
            overflow: 'hidden',
            flexGrow: 1,
            gap: StyleUtils.Spacing.md,
            color: 'inherit',
          }}
        >
          <FlexRowCenterVertical style={{ width: 28, color: 'inherit' }}>
            {typeof icon === 'string' ? <Icon type={icon} size={24} /> : icon}
          </FlexRowCenterVertical>
          <span style={{ fontWeight: 600, color: 'inherit' }}>
            {isExpanded && text}
          </span>
        </FlexRowCenterVertical>
        {rightIcon && (
          <FlexRowCenterVertical style={{ marginRight: 12, marginLeft: 2 }}>
            {typeof rightIcon === 'string' ? (
              <Icon type={rightIcon} size={16} />
            ) : (
              rightIcon
            )}
          </FlexRowCenterVertical>
        )}
      </MenuItemLink>
    </div>
  ),
);

export interface AdminMenuProps {
  /** Is the sidebar expanded? (optional) */
  isExpanded?: boolean;
}

/**
 * assembles the org switching URL for the given role and user at the given location
 *
 * @param location - the current location (to return to after logging in)
 * @param roleId - the target role id
 * @param userId - the current user id
 * @returns the target org switching href or undefined if is the current org
 */
function getHrefForRole(
  location: Location,
  roleId?: string,
  userId?: string,
): string | undefined {
  return roleId && userId && roleId !== userId
    ? `${generatePath(AdminDashboardPrivateMiscPath.AssumeRole, {
        roleId,
      })}?${qs.stringify({
        backTo: `${location.pathname}${location.search}${location.hash}`,
      })}`
    : undefined;
}

/**
 * Admin org/user menu
 */
export const AdminMenu: React.FC<AdminMenuProps> = ({ isExpanded = true }) => {
  const { formatMessage } = useIntl();
  const organizationFaviconSrc = useSelector(selectOrganizationFaviconSrc);
  const organizationName = useSelector(selectOrganizationName);
  const user = useSelector(selectUser);
  const location = useLocation();
  const redirect = useRedirect();
  const theme = useTheme();
  const logout = useLogout();
  const [orgSearch, setOrgSearch] = useState('');
  const { intercomLoaded, intercomFailed } = useIntercomLoaded();

  const switcherContents = useMemo(() => {
    const filteredRoles = orderBy(
      user?.roles ?? [],
      [
        (role) =>
          // sort all cypress to the end
          role.organization.name.toLowerCase().includes('cypress') ? 1 : 0,
        // then sort alphabetically
        (role) => role.organization.name,
      ],
      ['asc', 'asc'],
    ).filter(
      (role) =>
        !orgSearch.trim() ||
        role.organization.name
          .toLowerCase()
          .includes(orgSearch.trim().toLowerCase()),
    );

    return (
      <FlexColumn>
        <Input
          {
            // can't add line-ignores here
            ...{ autoFocus: true }
          }
          value={orgSearch}
          onChange={(e) => {
            setOrgSearch(e.target.value);
          }}
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
          }}
          onKeyUp={(e) => {
            const href = getHrefForRole(
              location,
              filteredRoles[0]?.id,
              user?.id,
            );
            if (e.key === 'Enter' && href) {
              redirect(href);
            }
          }}
          icon="search"
          style={{ marginBottom: StyleUtils.Spacing.sm }}
        />
        {filteredRoles.length === 0 && (
          <div style={{ padding: StyleUtils.Spacing.sm }}>
            {formatMessage(adminMenuMessages.noMatches)}
          </div>
        )}
        {filteredRoles.map((role) => (
          <MenuItem
            key={role.organization.id}
            href={getHrefForRole(location, role?.id, user?.id)}
            text={role.organization.name}
            rightIcon={
              role.id === user?.id ? (
                <Tooltip
                  title={formatMessage(adminMenuMessages.currentOrganization)}
                  style={StyleUtils.Flex.Row.CenterVertical}
                >
                  <Icon type="checkmark" />
                </Tooltip>
              ) : undefined
            }
            icon={
              <OrganizationIcon
                organizationIconSrc={role.organization.favicon?.src}
                organizationName={role.organization.name}
                size={24}
              />
            }
          />
        ))}
      </FlexColumn>
    );
  }, [user, location, orgSearch]);

  const intercomDoneLoadingOrFailed = intercomLoaded || intercomFailed;
  const contents = (
    <FlexColumn>
      <Popover
        contents={switcherContents}
        placement="right"
        width="240px"
        height="300px"
        hideArrow
        scrollable
      >
        <MenuItem
          text={organizationName || 'Loading...'}
          icon={
            <OrganizationIcon
              organizationIconSrc={organizationFaviconSrc}
              organizationName={organizationName}
              size={28}
            />
          }
          rightIcon="switch"
          style={{
            marginBottom: 14,
            backgroundColor: theme.colors.gray1,
            borderRadius: 4,
          }}
        />
      </Popover>
      {user && (
        <MenuItem
          href={AdminDashboardPrivateMiscPath.Profile}
          icon={
            <OrganizationIcon
              organizationName={user.name}
              style={{ flexShrink: 0 }}
              size={28}
              circle
            />
          }
          text={
            <FlexColumn
              style={{
                fontSize: '12px',
                ...StyleUtils.Flex.Column.AlignLeft,
                lineHeight: '14px',
                // Gets text overflow to work properly in children:
                // https://css-tricks.com/flexbox-truncated-text/#aa-the-solution-is-min-width-0-on-the-flex-child
                minWidth: 0,
              }}
            >
              <div style={{ fontWeight: 600 }}>{user.name}</div>
              <EmailWrapper>{user.email}</EmailWrapper>
            </FlexColumn>
          }
        />
      )}
      <MenuItem
        href={AdminDashboardAdministrationPath.Organization}
        text={formatMessage(adminMenuMessages.administration)}
        icon="team"
      />
      <MenuItem
        href={AdminDashboardAdministrationPath.Users}
        text={formatMessage(adminMenuMessages.inviteUsers)}
        icon="user-add"
        style={{ marginBottom: 14 }}
      />
      <MenuItem
        href="https://docs.transcend.io/docs/changelog"
        text={formatMessage(adminMenuMessages.whatsNew)}
        icon="sparkles"
      />
      <MenuItem
        href="https://status.transcend.io/"
        text={formatMessage(adminMenuMessages.systemStatus)}
        icon="system-status"
      />
      <MenuItem
        href="https://docs.transcend.io/docs"
        text={formatMessage(adminMenuMessages.documentation)}
        icon="book"
      />
      <MenuItem
        id="org-admin-support"
        text={formatMessage(adminMenuMessages.contactSupport)}
        icon={
          intercomDoneLoadingOrFailed ? (
            <Icon
              type="question-bubble"
              size={24}
              color={theme.colors.secondary}
            />
          ) : (
            <LoadingSpinner small />
          )
        }
        disabled={!intercomDoneLoadingOrFailed}
        onClick={() => {
          if (intercomLoaded) {
            Intercom('show');
          }
          if (intercomFailed) {
            redirect(`mailto:${SUPPORT_CONTACT}`);
          }
        }}
      />
      <MenuItem
        onClick={logout}
        text={formatMessage(adminMenuMessages.logout)}
        icon="log-out"
      />
    </FlexColumn>
  );

  return (
    <Popover contents={contents} placement="top-start" width="240px" hideArrow>
      <StyledButton
        variant="secondary"
        style={{
          width: '100%',
        }}
      >
        <OrganizationAdminSection id="org-admin-menu">
          <OrganizationIcon
            organizationIconSrc={organizationFaviconSrc}
            organizationName={organizationName}
            style={{ marginRight: 10 }}
          />
          <FlexRowCenterVertical style={{ flexGrow: 1 }}>
            <FlexColumn
              style={{
                ...StyleUtils.Flex.Column.AlignLeft,
                flexGrow: 1,
                lineHeight: '20px',
                opacity: isExpanded ? 1 : 0,
                transitionProperty: 'opacity',
                ...SIDEBAR_TRANSITION,
              }}
            >
              <OrganizationNameLabel>{organizationName}</OrganizationNameLabel>
              <div>{user?.name}</div>
            </FlexColumn>
            <Icon type="caret-down" color={theme.colors.transcendNavy} />
          </FlexRowCenterVertical>
        </OrganizationAdminSection>
      </StyledButton>
    </Popover>
  );
};
/* eslint-enable max-lines */
