import {
  Dispatch,
  SetStateAction,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useSelector } from 'react-redux';

import { TableId } from '@main/access-control-types';
import { ID } from '@main/schema-utils';

import { selectPreferences } from '../../Auth/App/selectors';

export const useHiddenColumns = (
  tableId: TableId,
  providedState?: [
    string[] | undefined,
    Dispatch<SetStateAction<string[]>> | undefined,
  ],
  assessmentGroupId?: ID<'assessmentGroup'>,
): [string[], Dispatch<SetStateAction<string[]>>] => {
  const [localHiddenColumns, setLocalHiddenColumns] = useState<string[]>();
  const [providedHiddenColumns, providedSetHiddenColumns] = providedState || [];
  const hiddenColumns = useMemo(
    () => providedHiddenColumns ?? localHiddenColumns,
    [providedHiddenColumns, localHiddenColumns],
  );
  const setHiddenColumns = useMemo(
    () => providedSetHiddenColumns ?? setLocalHiddenColumns,
    [providedSetHiddenColumns, setLocalHiddenColumns],
  );

  const { userPreference, organizationPreference } =
    useSelector(selectPreferences);

  const userTableConfiguration = assessmentGroupId
    ? (userPreference?.tables?.RISK_INTELLIGENCE_ASSESSMENTS ?? []).find(
        (t) => t.assessmentGroupId === assessmentGroupId,
      )
    : userPreference?.tables?.[tableId];
  const isPersonalTab = !!userTableConfiguration?.isPreferred;

  const previousIsPersonalTab = useRef(isPersonalTab);

  // Update hidden columns from saved preferences when switching tab or on first load
  useEffect(() => {
    if (
      typeof hiddenColumns === 'undefined' ||
      isPersonalTab !== previousIsPersonalTab.current
    ) {
      const organizationTableConfiguration = assessmentGroupId
        ? (
            organizationPreference?.tables?.RISK_INTELLIGENCE_ASSESSMENTS ?? []
          ).find((t) => t.assessmentGroupId === assessmentGroupId)
        : organizationPreference?.tables?.[tableId];

      previousIsPersonalTab.current = isPersonalTab;
      setHiddenColumns(
        (isPersonalTab && userTableConfiguration?.hiddenColumns) ||
          // use org preferences for org tab and as fallback if on personal tab
          organizationTableConfiguration?.hiddenColumns ||
          [],
      );
    }
  }, [
    isPersonalTab,
    organizationPreference,
    userTableConfiguration,
    assessmentGroupId,
    tableId,
    userPreference,
    setHiddenColumns,
    hiddenColumns,
  ]);

  // memoize so new array is not created/returned on every rerender
  const state = useMemo(
    () =>
      // Have to cast because TS doesn't automatically interpret this as a tuple
      [hiddenColumns || [], setHiddenColumns] as [
        string[],
        Dispatch<SetStateAction<string[]>>,
      ],
    [hiddenColumns, setHiddenColumns],
  );

  return state;
};
