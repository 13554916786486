import {
  CreateActionItemsInput,
  endpoints,
  GlobalActionItem,
} from '@main/access-control-types';
import {
  commonButtonMessages,
  commonFormStatusMessages,
  FormInput,
  FormSubmitButton,
  Modal,
} from '@main/ad-core-components';
import {
  AUTOCOMPLETE_OFF_PROPS,
  buildUseMutation,
  Form,
  formatErrorMessage,
  message,
  useForm,
  Validators,
} from '@main/core-ui';
import { ID } from '@main/schema-utils';
import { ActionItemCode } from '@transcend-io/privacy-types';
import React from 'react';
import { useIntl } from 'react-intl';

import { onboardingItemCollectionTableMessages } from './messages';

const useCreateActionItems = buildUseMutation(endpoints.createActionItems);
const useUpdateActionItems = buildUseMutation(endpoints.updateActionItems);

interface CreateOnboardingCollectionModalProps {
  /** handler on hiding of the modal */
  onHide: () => void;
  /** the collection to update, null if create */
  item?: GlobalActionItem;
  /** the current collection id */
  collectionId: ID<'actionItemCollection'>;
}

/**
 * CreateOnboardingCollectionModal
 */
export const CreateOnboardingItemModal: React.FC<
  CreateOnboardingCollectionModalProps
> = ({ onHide, item, collectionId }) => {
  const { formatMessage } = useIntl();
  const [createActionItems, { loading: createLoading }] = useCreateActionItems({
    notifyOnNetworkStatusChange: true,
  });
  const [updateActionItems, { loading: updateLoading }] = useUpdateActionItems({
    notifyOnNetworkStatusChange: true,
  });
  const formMethods = useForm<Pick<CreateActionItemsInput, 'title'>>({
    defaultValues: { title: item?.titles?.[0] },
  });

  return (
    <Modal
      show
      onHide={() => {
        onHide();
        formMethods.reset();
      }}
      header={onboardingItemCollectionTableMessages.addItem}
    >
      <Form
        useFormMethods={formMethods}
        onSubmit={async (values) => {
          try {
            if (item?.ids) {
              await updateActionItems({
                variables: {
                  input: { ...values, ids: item.ids },
                },
              });
            } else {
              await createActionItems({
                variables: {
                  input: [
                    {
                      ...values,
                      type: ActionItemCode.Onboarding,
                      collectionIds: [collectionId],
                    },
                  ],
                },
              });
            }
            message.success(
              formatMessage(commonFormStatusMessages.createSuccess),
            );
            onHide();
            formMethods.reset();
          } catch (err) {
            message.error(formatErrorMessage(err));
          }
        }}
      >
        <FormInput
          name="title"
          label={onboardingItemCollectionTableMessages.title}
          required
          {...AUTOCOMPLETE_OFF_PROPS}
          rules={[Validators.REQUIRED]}
        />
        <FormSubmitButton loading={createLoading || updateLoading}>
          {formatMessage(commonButtonMessages.save)}
        </FormSubmitButton>
      </Form>
    </Modal>
  );
};
