import { defineMessages } from '@transcend-io/internationalization';

export const byteSizeMessages = defineMessages(
  'ad-core-components.Mimetype.byteSize',
  {
    none: {
      defaultMessage: 'None',
    },
  },
);
