import * as t from 'io-ts';

import { dbModelId } from '@main/schema-utils';

import { AuditEventCode } from '../schema/enums';
import { mkAuditEventCodec } from '../types';

const baseFields = {
  /** ID of the scanned object */
  scannedObjectId: dbModelId('scannedObject'),
  /** ID of the data silo */
  dataSiloId: dbModelId('dataSilo'),
};

export const AuditEventScannedObject = mkAuditEventCodec(
  AuditEventCode.ScannedObject,
  t.type(baseFields),
);

/** Override types. */
export type AuditEventScannedObject = t.TypeOf<typeof AuditEventScannedObject>;
