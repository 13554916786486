import {
  Button,
  DescriptionWrapper,
  LabelWrapper,
  Modal,
  SelectWrapper,
  SettingsItem,
  SettingsWrapper,
  Table,
  TitleWrapper,
} from '@main/ad-core-components';
import {
  buildUseQuery,
  FlexRowCenterBoth,
  formatErrorMessage,
  message,
  ReactSelect,
  Skeleton,
  StyleUtils,
} from '@main/core-ui';
import {
  endpoints,
  RequestsProcessedDisclosureStatsTableHeaders,
} from '@main/pc-types';
import React, { useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { selectPrivacyCenterUrl } from '../../selectors';
import { requestsProcessedStatsMessages } from '../messages';
import { selectRequestsProcessedDisclosureStatsInput } from '../selectors';
import { GENERIC_STAT_COLUMN, REQUEST_TYPE_COLUMN } from './columns';
import { requestsProcessedStatsTableHeaderMessages } from './messages';

const useRequestsProcessedDisclosureStats = buildUseQuery(
  endpoints.requestsProcessedDisclosureStats,
);

/**
 * Change the overrides for the requests processed stats table
 */
export const RequestsProcessedOverridesTable: React.FC = () => {
  const { formatMessage } = useIntl();
  const requestsProcessedDisclosureStatsSettings = useSelector(
    selectRequestsProcessedDisclosureStatsInput,
  );
  const privacyCenterUrl = useSelector(selectPrivacyCenterUrl);
  const [selectedYear, setSelectedYear] = useState(2024);

  const [showModal, setShowModal] = useState(false);

  const { data, loading, error } = useRequestsProcessedDisclosureStats({
    variables: {
      input: {
        showRawValues: true,
      },
      lookup: {
        url: privacyCenterUrl,
      },
    },
  });

  // flash error
  useEffect(() => {
    if (error) {
      message.error(formatErrorMessage(error.message));
    }
  }, [error]);

  const columns = useMemo(
    () => [
      REQUEST_TYPE_COLUMN,
      GENERIC_STAT_COLUMN(
        RequestsProcessedDisclosureStatsTableHeaders.Received,
        selectedYear,
      ),
      GENERIC_STAT_COLUMN(
        RequestsProcessedDisclosureStatsTableHeaders.Processed,
        selectedYear,
      ),
      GENERIC_STAT_COLUMN(
        RequestsProcessedDisclosureStatsTableHeaders.Denied,
        selectedYear,
      ),
      GENERIC_STAT_COLUMN(
        RequestsProcessedDisclosureStatsTableHeaders.MedianNumberOfDays,
        selectedYear,
      ),
    ],
    [selectedYear],
  );

  if (!requestsProcessedDisclosureStatsSettings || !data) {
    return <Skeleton />;
  }
  return (
    <SettingsWrapper>
      <SettingsItem>
        <LabelWrapper>
          <TitleWrapper>
            {formatMessage(requestsProcessedStatsMessages.overridesTable)}
          </TitleWrapper>
          <DescriptionWrapper>
            {formatMessage(
              requestsProcessedStatsMessages.overridesTableDescription,
            )}
          </DescriptionWrapper>
        </LabelWrapper>
        <Button onClick={() => setShowModal(true)} variant="secondary">
          {formatMessage(requestsProcessedStatsMessages.overridesTableButton)}
        </Button>
      </SettingsItem>
      <Modal
        show={showModal}
        onHide={() => {
          setShowModal(false);
        }}
        width="80%"
        header={requestsProcessedStatsMessages.overridesTableButton}
      >
        <FlexRowCenterBoth style={{ gap: StyleUtils.Spacing.sm }}>
          {formatMessage(
            requestsProcessedStatsMessages.overridesTableYearSelection,
          )}
          <SelectWrapper>
            <ReactSelect
              value={{ value: selectedYear, label: `${selectedYear}` }}
              onChange={(year) => {
                setSelectedYear((year as any).value);
              }}
              options={data.map(({ year }) => ({
                value: year,
                label: `${year}`,
              }))}
            />
          </SelectWrapper>
        </FlexRowCenterBoth>
        <Table
          data={(
            data?.filter((dataRow) => dataRow.year === selectedYear)?.[0]
              ?.stats ?? []
          ).filter(
            (stat) =>
              !requestsProcessedDisclosureStatsSettings.disabledActions.includes(
                stat.type,
              ),
          )}
          columns={columns}
          loading={loading}
          columnHeaderMessages={requestsProcessedStatsTableHeaderMessages}
        />
      </Modal>
    </SettingsWrapper>
  );
};
