import {
  CustomizableComponent,
  CustomizableText,
} from '@transcend-io/privacy-types';
import { applyEnum } from '@transcend-io/type-utils';

import { mkInput, SchemaToType } from '@main/schema-utils';

export const ComponentStylesInput = mkInput({
  name: 'ComponentStylesInput',
  comment: 'Input to update component styles',
  fields: applyEnum(CustomizableComponent, (componentType) => ({
    comment: `Custom styling for "${componentType}"`,
    optional: true,
    type: 'string',
  })),
});

/** Override type */
export type ComponentStylesInput = SchemaToType<typeof ComponentStylesInput>;

export const FontBasicInput = mkInput({
  name: 'FontBasicInput',
  comment: 'Input for font styles',
  fields: {
    name: {
      comment: `Name of font face`,
      type: 'string',
    },
    url: {
      comment: `URL of font face`,
      type: 'string',
    },
  },
});

/** Override type */
export type FontBasicInput = SchemaToType<typeof FontBasicInput>;

export const FontInput = mkInput({
  name: 'FontInput',
  comment: 'Input for font metadata',
  fields: {
    name: {
      comment: `Name of font face`,
      type: 'string',
    },
    url: {
      comment: `URL of font face`,
      type: 'string',
    },
    assets: {
      comment: `Additional font assets to load`,
      type: FontBasicInput,
      list: true,
      optional: true,
    },
  },
});

/** Override type */
export type FontInput = SchemaToType<typeof FontInput>;

export const TextStylesInput = mkInput({
  name: 'TextStylesInput',
  comment: 'Input to update text styles',
  fields: applyEnum(CustomizableText, (textType) => ({
    comment: `Font information for "${textType}"`,
    optional: true,
    type: FontInput,
  })),
});

/** Override type */
export type TextStylesInput = SchemaToType<typeof TextStylesInput>;
