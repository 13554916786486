import { ItemsWithOverflow } from '@main/core-ui';
import { PurposeSubCategoryPreview } from '@main/datamap-types';
import React from 'react';

import { PurposeTitle } from '../PurposeTitle';
import { UNSPECIFIED_PURPOSE } from './constants';

export const ProcessingPurposesReadOnly: React.FC<{
  /** List of data processing purposes to render */
  processingPurposeSubCategories: Pick<
    PurposeSubCategoryPreview,
    'id' | 'purpose' | 'name'
  >[];
  /** Number of items to display before putting the rest into an overflow container */
  overflowLimit?: number;
}> = ({ processingPurposeSubCategories, overflowLimit = 1 }) => (
  <ItemsWithOverflow
    items={
      processingPurposeSubCategories.length === 0
        ? [UNSPECIFIED_PURPOSE]
        : processingPurposeSubCategories
    }
    limit={overflowLimit}
    renderElement={(purpose) => (
      <PurposeTitle
        key={purpose.id || purpose.purpose}
        purpose={purpose.purpose}
        name={purpose.name}
      />
    )}
  />
);
