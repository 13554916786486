import {
  FilterManager,
  FiltersConfig,
  InlineTextInput,
  SelectedTeam,
  SelectedUser,
} from '@main/ad-core-components';
import { SelectedCatalog } from '@main/admin-dashboard/src/DataMap/TriageSiloDiscovery/types';
import {
  useCatalogFilters,
  useCodePackageFilters,
  useRepositoryFilters,
  useTeamFilters,
  useUserFilters,
} from '@main/admin-dashboard/src/hooks';
import { SoftwareDevelopmentKitFiltersInput } from '@main/code-scanning-types';
import pickBy from 'lodash/pickBy';
import React, { useMemo, useRef } from 'react';
import { useIntl } from 'react-intl';

import { sdkColumnHeaderMessages } from '../../SoftwareDevelopmentKits/messages';
import { SelectedCodePackage } from '../SelectCodePackages';
import { SelectedRepository } from '../SelectRepositories';
import { softwareDevelopmentKitFilterMessages } from './messages';

/**
 * Filters we support for software development kits
 */
// eslint-disable-next-line @typescript-eslint/consistent-type-definitions
type SupportedFilters = {
  /** Text to search the software development kits by */
  text?: string;
  /** Text to search on name only */
  name?: string;
  /** Assigned owner */
  owners?: SelectedUser[];
  /** Assigned teams */
  teams?: SelectedTeam[];
  /** Repositories with software development kits */
  softwareDevelopmentKits?: SelectedRepository[];
  /** Code packages */
  codePackages?: SelectedCodePackage[];
  /** Selected confirmed catalogs to filter on */
  confirmedCatalogs?: SelectedCatalog[];
  /** Selected guessed catalogs to filter on */
  guessedCatalogs?: SelectedCatalog[];
};

interface SoftwareDevelopmentKitFilterProps {
  /** The currently applied filters */
  filters?: SoftwareDevelopmentKitFiltersInput;
  /** Callback when the filters are changed */
  setFilters: (filters: SoftwareDevelopmentKitFiltersInput) => void;
}

export const SoftwareDevelopmentKitFilter: React.FC<
  SoftwareDevelopmentKitFilterProps
> = ({ filters, setFilters }) => {
  const { formatMessage } = useIntl();

  const {
    selectedUsers: selectedOwners,
    userFiltersConfig: ownerFiltersConfig,
    clearUserFilters: clearOwnerFilters,
  } = useUserFilters({
    userIdsFilterKey: 'ownerIds',
    filters,
    setFilters,
    label: sdkColumnHeaderMessages.owners,
    enrichedUserKey: 'owners',
  });

  const { selectedTeams, teamFiltersConfig, clearTeamFilters } = useTeamFilters(
    {
      filters,
      setFilters,
      label: sdkColumnHeaderMessages.teams,
    },
  );

  const {
    selectedRepositories,
    repositoryFiltersConfig,
    clearRepositoryFilters,
  } = useRepositoryFilters({
    filters,
    setFilters,
    label: sdkColumnHeaderMessages.repositories,
  });

  const {
    selectedCodePackages,
    codePackageFiltersConfig,
    clearCodePackageFilters,
  } = useCodePackageFilters({
    filters,
    setFilters,
    label: sdkColumnHeaderMessages.codePackages,
  });

  const {
    selectedCatalogs: selectedConfirmedCatalogs,
    catalogFiltersConfig: confirmedCatalogFiltersConfig,
    clearCatalogFilters: clearConfirmedCatalogFilters,
  } = useCatalogFilters({
    catalogIntegrationNamesFilterKey: 'confirmedIntegrationNames',
    filters,
    enrichedCatalogKey: 'confirmedCatalogs',
    setFilters,
    label: sdkColumnHeaderMessages.catalog,
  });

  const {
    selectedCatalogs: selectedGuessedCatalogs,
    catalogFiltersConfig: guessedCatalogFiltersConfig,
    clearCatalogFilters: clearGuessedCatalogFilters,
  } = useCatalogFilters({
    catalogIntegrationNamesFilterKey: 'guessedIntegrationNames',
    filters,
    enrichedCatalogKey: 'guessedCatalogs',
    setFilters,
    label: sdkColumnHeaderMessages.guessedCatalog,
  });

  const nameInputRef = useRef<HTMLInputElement>(null);

  const filterConfig: FiltersConfig<SupportedFilters> = [
    teamFiltersConfig as any,
    ownerFiltersConfig as any,
    repositoryFiltersConfig as any,
    codePackageFiltersConfig as any,
    confirmedCatalogFiltersConfig as any,
    guessedCatalogFiltersConfig as any,
    {
      enrichedCatalogKey: 'name',
      label: sdkColumnHeaderMessages.name,
      pillOptions: {
        label: ({ filterValues: { name } }) => name,
      },
      filter: (
        <InlineTextInput
          ref={nameInputRef}
          name="search"
          style={{
            background: 'none',
            border: 0,
            boxShadow: 'none',
            minWidth: 20,
            width: 'auto',
          }}
          placeholder={formatMessage(sdkColumnHeaderMessages.name)}
          aria-label={formatMessage(sdkColumnHeaderMessages.name)}
          onChange={({ target: { value: newValue } }) => {
            setFilters({
              ...filters,
              name: newValue,
            });
          }}
        />
      ),
    },
  ];

  // Map the basic filters from the query to the enriched values for the filter manager
  const enrichedFilters = useMemo<SupportedFilters>(
    () =>
      pickBy(
        {
          ...filters,
          teams: selectedTeams,
          softwareDevelopmentKits: selectedRepositories,
          owners: selectedOwners,
          codePackages: selectedCodePackages,
          confirmedCatalogs: selectedConfirmedCatalogs,
          guessedCatalogs: selectedGuessedCatalogs,
          repositories: selectedRepositories,
        },
        (v) => (Array.isArray(v) ? v.length > 0 : v !== undefined),
      ),
    [
      filters,
      selectedTeams,
      selectedRepositories,
      selectedOwners,
      selectedCodePackages,
      selectedConfirmedCatalogs,
      selectedGuessedCatalogs,
    ],
  );

  return (
    <FilterManager
      id="software-development-kit-filter"
      defaultSearchKey="text"
      filtersValues={enrichedFilters}
      filtersConfig={filterConfig}
      placeholder={softwareDevelopmentKitFilterMessages.textLabel}
      onChange={(key, value) =>
        setFilters({
          ...filters,
          [key]: value,
        })
      }
      clearFilter={(key) => {
        clearOwnerFilters(key);
        clearRepositoryFilters(key);
        clearCodePackageFilters(key);
        clearConfirmedCatalogFilters(key);
        clearGuessedCatalogFilters(key);
        clearTeamFilters(key);
        setFilters({
          ...filters,
          [key]: undefined,
        });
      }}
    />
  );
};
