import { defineMessages } from '@transcend-io/internationalization';

import {
  ActionItemSubscriptionNotificationMethod,
  ProductArea,
} from '@main/access-control-types';

export const actionItemsSubscriptionsMessages = defineMessages(
  'admin-dashboard.Auth.ActionItems.ActionItemsTable.ActionItemSubscriptionsButton.actionItemsSubscriptions',
  {
    manageNotifications: {
      defaultMessage: 'Manage Notifications',
    },
    manageOrgNotifications: {
      defaultMessage: 'Manage Organization Notifications',
    },
    subscriptionsDescription: {
      defaultMessage:
        'Select Action Item types for which you would like to subscribe to receive ' +
        'notifications. Digests are sent periodically',
    },
    bulkDescription: {
      defaultMessage:
        'Select multiple action item types in order to bulk configure them via the dropdown below.',
    },
    noNotifications: {
      defaultMessage: 'No Notifications',
    },
    subscribeAll: {
      defaultMessage: 'Subscribe to All',
    },
    notificationsMuted: {
      defaultMessage: 'Notifications Muted',
    },
    example: {
      defaultMessage: 'Example message:',
    },
    selectAll: {
      defaultMessage: 'Select All',
    },
    applyToSelected: {
      defaultMessage: 'Apply ({num})',
    },
    bulkEdit: {
      defaultMessage: 'Bulk Edit',
    },
    showBulkEdit: {
      defaultMessage: 'Show Bulk Tools',
    },
    hideBulkEdit: {
      defaultMessage: 'Hide Bulk Tools',
    },
    selectNotificationMethods: {
      defaultMessage: 'Select notification methods to enable',
    },
    selectNotificationMethodsBulk: {
      defaultMessage: 'Select notification methods to bulk apply',
    },
    noAllowListItemsDescription: {
      defaultMessage:
        'No channel options have been allow-listed. Please select some options above',
    },
    defaultPriority: {
      defaultMessage: 'Default Priority',
    },
    none: {
      defaultMessage: 'None',
    },
  },
);

export const actionItemSubscriptionMethodLabels =
  defineMessages<ActionItemSubscriptionNotificationMethod>(
    'admin-dashboard.Auth.ActionItems.ActionItemsTable.ActionItemSubscriptionsButton.actionItemSubscriptionMethodLabels',
    {
      [ActionItemSubscriptionNotificationMethod.Email]: {
        defaultMessage: 'Email',
      },
      [ActionItemSubscriptionNotificationMethod.Slack]: {
        defaultMessage: 'Slack',
      },
    },
  );

export const actionItemProductAreaLabels = defineMessages<ProductArea>(
  'admin-dashboard.Auth.ActionItems.ActionItemsTable.ActionItemSubscriptionsButton.actionItemProductAreaLabels',
  {
    [ProductArea.Administration]: {
      defaultMessage: 'Administration',
    },
    [ProductArea.DataMapping]: {
      defaultMessage: 'Data Mapping',
    },
    [ProductArea.Consent]: {
      defaultMessage: 'Consent',
    },
    [ProductArea.DsrAutomation]: {
      defaultMessage: 'DSR Automation',
    },
    [ProductArea.PrivacyCenter]: {
      defaultMessage: 'Privacy Center',
    },
    [ProductArea.Assessments]: {
      defaultMessage: 'Assessments',
    },
  },
);
