import React from 'react';
import { useTheme } from 'styled-components';

/**
 * Long Text icon
 */
export const LongText: React.FC = (props) => {
  const theme = useTheme();
  return (
    <svg
      width="48px"
      height="30px"
      viewBox="0 0 48 30"
      version="1.1"
      {...props}
    >
      <defs />
      <g
        id="Symbols"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Button/dashboardsquare"
          transform="translate(-40.000000, -37.000000)"
          fill={theme.colors.primary}
        >
          <g
            id="inputicon/long-text"
            transform="translate(40.000000, 28.000000)"
          >
            <path
              // eslint-disable-next-line max-len
              d="M0,9 L30,9 L30,15 L0,15 L0,9 Z M36,9 L48,9 L48,15 L36,15 L36,9 Z M0,21 L48,21 L48,27 L0,27 L0,21 Z M18,33 L48,33 L48,39 L18,39 L18,33 Z M0,33 L12,33 L12,39 L0,39 L0,33 Z"
              id="Shape"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};
