/* eslint-disable max-len */
import { defineMessages } from '@transcend-io/internationalization';

import { PageType } from './schema/enums';

/**
 * Default messages
 */
export const messages = defineMessages<PageType>('pc-types.src.messages', {
  [PageType.DataPractices]: {
    defaultMessage: 'Data Practices',
    description: 'The nav title for the "Data Practices" page',
  },
  [PageType.Policies]: {
    defaultMessage: 'Our Policies',
    description: 'The nav title for the "Policies" page',
  },
  [PageType.TrackingTechnologies]: {
    defaultMessage: 'Tracking Technologies',
    description: 'The nav title for the "Tracking Technologies" page',
  },
  [PageType.ManageYourPrivacy]: {
    defaultMessage: 'Privacy Settings',
    description: 'The nav title for the "Privacy Settings" page',
  },
  [PageType.SaleOfInfo]: {
    defaultMessage: 'Sale of Info',
    description: 'The nav title for the "Sale of Info" page',
  },
  [PageType.RequestsProcessedReporting]: {
    defaultMessage: 'Request Metrics',
    description: 'The nav title for the "Requests Metrics" page',
  },
  [PageType.CommunicationsPreferences]: {
    defaultMessage: 'Communications Preferences',
    description: 'The nav title for the "Communications Preferences" page',
  },
});

/**
 * The message definitions that we expose for customization on the privacy center.
 * In the future, we will likely expand this list but for now we have an allow list of messages.
 *
 * TODO: https://transcend.height.app/T-6114 - move these messages back to their local places
 */
export const privacyCenterCustomizableMessages = defineMessages(
  'pc-types.src.privacyCenterCustomizable',
  {
    heroTitle: {
      description: 'The main "hero" message in the header of the page.',
      defaultMessage:
        "The data we collect, how it's used, and who we share it with",
    },
    heroDescription: {
      description: 'The description text below the main "hero".',
      defaultMessage:
        'We care about your privacy and want to help you understand how we collect, use, and share your personal information.',
    },
    takeControlDescription: {
      description:
        'The description text in the take control modal, describing the privacy actions that the data subject can make.',
      defaultMessage: `As part of that commitment, we give you control over your personal data.
You can access your data, delete it, and manage how it gets used.`,
    },
    privacyCenterTitle: {
      description:
        'The title of the privacy center, used throughout various messages',
      defaultMessage: 'Privacy Center',
    },
    metaTitle: {
      description:
        'The meta HTML title, displayed on an open tab, and used by search engines for indexing purposes.',
      defaultMessage: '{organizationName} Privacy Center',
    },
    metaDescription: {
      description:
        'The meta HTML description, used by search engines for indexing purposes.',
      defaultMessage:
        'Learn about how {organizationName} handles personal data, and take action on your privacy preferences.',
    },
    aboutTranscendDescription: {
      description: `This is the final section ("Limitations of the Privacy Center") for the "About Transcend" page.
      Unlike the rest of the text on this page, this can be customized for your organization.
      Feel free to include FAQs, support emails, or any other links here.`,
      defaultMessage: `You can read more about any limitations of each request in
their descriptions by clicking the {takeControl} button or by checking out the {policies} tab.`,
    },
  },
);

/**
 * Privacy Center URL messages regarding the deployment of the privacy center
 */
export const privacyCenterUrlMessages = defineMessages(
  'pc-types.src.privacyCenterUrl',
  {
    requestCertificateInfo: {
      defaultMessage:
        'Please request a certificate to allow Transcend to host the Privacy Center on your domain.',
    },
    validateDnsInfo: {
      defaultMessage:
        'Please add the following CNAME record into your DNS database to validate the certificate. Note that it may take several hours for Transcend to receive confirmation that the certificate has been validated. If Transcend is unable to validate the certificate within 72 hours, the certificate may time out and a new one must be requested.',
    },
    validateDnsError: {
      defaultMessage:
        'An error occurred while attempting to validate the DNS records. Please request a new certificate.',
    },
    validateDnsSuccess: {
      defaultMessage:
        'The DNS records have been validated and your Privacy Center is ready to be launched. Press Launch to host the Privacy Center on your domain.',
    },
    launchPrivacyCenterInfo: {
      defaultMessage: 'Success! Your Privacy Center has been launched.',
    },
    error: {
      defaultMessage: 'An error occurred. Please contact Transcend for help.',
    },
  },
);
/* eslint-enable max-len */
