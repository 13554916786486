import {
  FlexColumn,
  FlexRowCenterVertical,
  GenericMessageDescriptor,
  Popover,
  StyleUtils,
  useFormatMessageGeneric,
} from '@main/core-ui';
import type { MessageValues } from '@transcend-io/internationalization';
import React, { useState } from 'react';
import { OverlayTriggerProps } from 'react-bootstrap/OverlayTrigger';
import { CSSProperties } from 'styled-components';

import { Button } from '../Button';
import { popConfirmMessages } from './messages';

export { popConfirmMessages };

/**
 * Props for Popconfirm
 */
export interface PopconfirmProps
  extends Pick<OverlayTriggerProps, 'placement'> {
  /** Cancel message */
  cancelMessage?: GenericMessageDescriptor;
  /** Ok message */
  okMessage?: GenericMessageDescriptor;
  /** Are you sure title message */
  sureMessage?: GenericMessageDescriptor;
  /** Values to pass to messages */
  values?: MessageValues;
  /** Popconfirm style */
  popupStyle?: CSSProperties;
  /** Function to call when the confirmation is accepted */
  onConfirm?: () => void;
  /** Function to call when the confirmation is canceled */
  onCancel?: () => void;
  /** Disabled */
  disabled?: boolean;
  /** Title */
  title?: GenericMessageDescriptor;
  /** Width */
  width?: string;
  /** Children */
  children?: OverlayTriggerProps['children'];
}

/**
 * A popconfirm component
 */
export function Popconfirm({
  cancelMessage = popConfirmMessages.cancel,
  okMessage = popConfirmMessages.ok,
  sureMessage = popConfirmMessages.sure,
  values = {},
  onConfirm,
  onCancel,
  popupStyle,
  disabled,
  title,
  placement,
  width,
  children,
}: PopconfirmProps): JSX.Element {
  const { formatMessageGeneric } = useFormatMessageGeneric();
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <Popover
      disabled={disabled}
      trigger="click"
      placement={placement}
      title={title}
      isExpanded={isExpanded}
      setIsExpanded={setIsExpanded}
      contents={
        <FlexColumn style={{ gap: StyleUtils.Spacing.sm, ...popupStyle }}>
          <div>{formatMessageGeneric(sureMessage, values)}</div>
          <FlexRowCenterVertical style={StyleUtils.Flex.SpaceBetween}>
            <Button
              onClick={() => {
                onConfirm?.();
                setIsExpanded(false);
              }}
            >
              {formatMessageGeneric(okMessage, values)}
            </Button>
            <Button
              onClick={() => {
                onCancel?.();
                setIsExpanded(false);
              }}
              variant="secondary"
            >
              {formatMessageGeneric(cancelMessage, values)}
            </Button>
          </FlexRowCenterVertical>
        </FlexColumn>
      }
      width={width || '250px'}
    >
      {children || <></>}
    </Popover>
  );
}
