import type { RequestStatus } from '@transcend-io/privacy-types';
import { createEnum, filterEnum } from '@transcend-io/type-utils';

import { REQUEST_STATUSES } from './statuses';

/**
 * The statuses that a request can be downloaded in
 */
export const REQUEST_STATUSES_DOWNLOADABLE = filterEnum(
  REQUEST_STATUSES,
  (status) => !!status.canDownload,
);

/**
 * The statuses that a request can be downloaded in, keyed by status
 */
export const REQUEST_STATUS_DOWNLOADABLE = createEnum(
  REQUEST_STATUSES_DOWNLOADABLE,
);

/**
 * Determine if the request is in a state where the data download link could be exposed.
 *
 * @param status - The status of the request to check
 * @returns True if the request can be downloaded from its current state
 */
export function canDownload(status: RequestStatus): boolean {
  return status in REQUEST_STATUS_DOWNLOADABLE;
}
