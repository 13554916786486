/**
 * an enum with the tab names and paths
 */
export enum DataSiloDetailsTab {
  Activities = 'activities',
  Connection = 'connection',
  DsrAutomation = 'dsr-automation',
  SiloDiscovery = 'silo-discovery',
  StructuredDiscovery = 'structured-discovery',
  UnstructuredDiscovery = 'unstructured-discovery',
  Lookups = 'lookups',
  AuditTrail = 'audit-trail',
  ManageDatapoints = 'manage-datapoints',
  ProcessedRequests = 'processed-requests',
  ActionItems = 'action-items',
}
