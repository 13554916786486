import { gql } from '@apollo/client';
import { datadogLogs } from '@datadog/browser-logs';
import { CurrentUser, endpoints } from '@main/access-control-types';
import { TITLE } from '@main/admin-dashboard/src/args';
import ErrorBoundary from '@main/admin-dashboard/src/Auth/ErrorBoundary';
import { AdminDashboardGlobalStyles } from '@main/admin-dashboard/src/global-styles';
import { ADMIN_DASHBOARD_THEME } from '@main/admin-dashboard/src/theme';
import { buildUseQuery, DEPLOY_ENV, Toaster, useLDClient } from '@main/core-ui';
import { mkQueryString } from '@main/schema-utils';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import { ThemeProvider } from 'styled-components';

import { useChangeSelectedBackend } from '../../hooks';
import { ALL_REGION_APOLLO_CLIENTS } from '../../regionalClients';
import { setIsLoadingUser } from '../../routes/slice';
import { SelectLanguage } from './SelectLanguage';
import { selectSelectedBackendOrDefault } from './selectors';
import { setUser } from './slice';
import { usePreferences } from './usePreferences';

const useUser = buildUseQuery(endpoints.user);

/**
 * The main container for the SPA
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 */
export const App: React.FC = ({ children }) => {
  const { loading, data, error, refetch } = useUser();
  const dispatch = useDispatch();
  const ldClient = useLDClient();
  const [userData, setUserData] = useState<CurrentUser | undefined>(undefined);
  const selectedBackendUrl = useSelector(selectSelectedBackendOrDefault);
  const changeBackendUrl = useChangeSelectedBackend();

  // On page load, check all backends for if the session cookie is valid for that backend.
  // This is necessary for detecting the backend URL if the user were in a secondary backend
  // (e.g. the us backend), and they were to clear local storage
  useEffect(() => {
    Promise.all(
      [...ALL_REGION_APOLLO_CLIENTS.entries()].map(
        async ([backendUrl, client]) => {
          const { data, error } = await client.query({
            query: gql`
              ${mkQueryString(endpoints.user, 'CheckUserSession', {
                id: null,
              })}
            `,
          });
          return {
            data,
            error,
            backendUrl,
          };
        },
      ),
    )
      .then((results) => {
        const regionWithUserResultsData = results
          .filter(({ error }) => !error)
          .find(({ data }) => !!data?.user);

        // If a session is found with a backend that is not the currently selected backend
        // switch to that backend here
        if (
          !selectedBackendUrl &&
          regionWithUserResultsData?.data?.user &&
          regionWithUserResultsData.backendUrl &&
          regionWithUserResultsData.backendUrl !== selectedBackendUrl
        ) {
          changeBackendUrl(regionWithUserResultsData.backendUrl);
          refetch();
        }
      })
      .catch((err) => {
        datadogLogs.logger.error(
          `Failed to load current user from our backends`,
          err,
        );
        dispatch(setIsLoadingUser(false));
      });
    // we only care to run this once on mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  // fetch the user on load to check the session
  useEffect(() => {
    setUserData(data);
    if (!loading && !error) {
      dispatch(setUser(data));
    }

    if (!data) {
      return;
    }

    ldClient?.identify({
      // if not production, identify by email to prevent extra billing. New uuids
      // are generated each testing deploy, so we will get billed for extra users here
      key:
        DEPLOY_ENV !== 'prod'
          ? data.organization.uri
          : data.parentRole?.id || data.id,
      custom: {
        // add any non-PII custom fields here
        organization: data.organization.uri,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, data, loading, error]);

  useEffect(() => {
    dispatch(setIsLoadingUser(loading));
  }, [dispatch, loading]);

  usePreferences();

  return (
    <React.Fragment>
      <Helmet defaultTitle={TITLE} titleTemplate={`%s - ${TITLE}`}>
        <meta content={TITLE} name="description" />
      </Helmet>
      <ThemeProvider theme={ADMIN_DASHBOARD_THEME}>
        <React.Fragment>
          <AdminDashboardGlobalStyles />
          <ErrorBoundary>
            <div>{children}</div>
            {!userData && <SelectLanguage />}
          </ErrorBoundary>
          <Toaster />
        </React.Fragment>
      </ThemeProvider>
    </React.Fragment>
  );
};
