import { PaginatedSelect } from '@main/ad-core-components';
import { buildUseInfiniteScroll } from '@main/core-ui';
import { endpoints } from '@main/datamap-types';
import React, { useState } from 'react';
import type { MenuPosition } from 'react-select';

import { selectIntegrationsMessages } from '../../../SelectIntegrations/messages';
import { CATALOG_SELECTION_NODES, SelectedCatalog } from '../../types';

interface SelectCatalogsProps {
  /** the current catalog selection  */
  value?: SelectedCatalog[];
  /** handler for when the selection changes */
  onChange: (selection: SelectedCatalog[]) => void;
  /** The menu position */
  menuPosition?: MenuPosition;
  /** Whether to show outline of input */
  showOutline?: boolean;
}

const useCatalogs = buildUseInfiniteScroll(
  endpoints.catalogs,
  'SelectIntegrations',
  {
    nodes: CATALOG_SELECTION_NODES,
    totalCount: null,
  },
);

/**
 * SelectCatalogs
 */
export const SelectCatalogs: React.FC<SelectCatalogsProps> = ({
  value = [],
  onChange,
  menuPosition,
  showOutline,
}) => {
  const [searchText, setSearchText] = useState('');
  const { data, loading, error, fetchMore } = useCatalogs({
    variables: { filterBy: { text: searchText } },
  });
  return (
    <PaginatedSelect<SelectedCatalog, true>
      id="select-catalogs"
      isQueryLoading={loading}
      captureMenuScroll
      queryError={error}
      isMulti
      onEndsTyping={setSearchText}
      options={data?.nodes || []}
      getOptionValue={({ id }) => id}
      getOptionLabel={({ title }) => title}
      getOptionLogo={({ logoSquare }) => logoSquare}
      fetchMore={fetchMore}
      value={value}
      placeholderDescriptor={selectIntegrationsMessages.placeholderSingle}
      onChange={(selected) => onChange([...selected])}
      variant="primary"
      showOutline={showOutline}
      menuPosition={menuPosition}
    />
  );
};
