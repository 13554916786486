import { PromptFilePurpose } from '@transcend-io/privacy-types';

import { UserPreview } from '@main/access-control-types';
import { AgentPreview } from '@main/attribute-types';
import { mkInput, mkOrder, mkType, SchemaToType } from '@main/schema-utils';

import { AgentFileOrderField } from './enums';
import { ReportPromptRunInput } from './promptRun';

export const AgentFilePreview = mkType({
  name: 'AgentFilePreview',
  comment: 'A preview of an agent file instance',
  fields: {
    id: {
      comment: 'The id of the agent file',
      modelName: 'agentFile',
      type: 'id',
    },
    name: {
      comment: 'The name of the agent file',
      type: 'string',
    },
    fileId: {
      comment: 'ID of the file in remote system',
      type: 'string',
    },
    initialFileName: {
      comment:
        'The name of the initial file, when this file is a chunk of another file',
      type: 'string',
      optional: true,
    },
    createdAt: {
      comment: 'The date the agent file was created',
      type: 'Date',
    },
    updatedAt: {
      comment: 'The date the agent file was last updated',
      type: 'Date',
    },
  },
});

/** Override type */
export type AgentFilePreview = SchemaToType<typeof AgentFilePreview>;

export const AgentFile = mkType({
  name: 'AgentFile',
  comment: 'A legal agentFile',
  fields: {
    ...AgentFilePreview.fields,
    description: {
      comment: 'The description of the agent file',
      type: 'string',
    },
    fileUploadedAt: {
      comment: 'Date that the file was uploaded to the remote system',
      type: 'Date',
    },
    size: {
      comment: 'Size of the file in bytes',
      type: 'int',
    },
    purpose: {
      comment: 'Purpose of the file',
      type: { PromptFilePurpose },
    },
    agents: {
      comment: 'The list of agents that have access to this file',
      type: () => AgentPreview,
      list: true,
    },
    creator: {
      comment: 'The user that created the file',
      type: UserPreview,
      optional: true,
    },
  },
});

/** Override type */
export type AgentFile = SchemaToType<typeof AgentFile>;

export const AgentFileFiltersInput = mkInput({
  name: 'AgentFileFiltersInput',
  comment: 'Inputs for filtering a list of agent files',
  fields: {
    ids: {
      comment: 'The ids of the agent files',
      type: 'id',
      modelName: 'agentFile',
      optional: true,
      list: true,
    },
    agentIds: {
      comment: 'The ids of the agents that have access to the file',
      type: 'id',
      modelName: 'agent',
      optional: true,
      list: true,
    },
    purposes: {
      comment: 'The file purposes to filter on',
      type: { PromptFilePurpose },
      optional: true,
      list: true,
    },
    text: {
      comment: 'Filter by text (name, description of the agent file)',
      optional: true,
      type: 'string',
    },
    fileIds: {
      comment: 'Filter by file IDs',
      optional: true,
      list: true,
      type: 'string',
    },
    names: {
      comment: 'Filter by name',
      optional: true,
      list: true,
      type: 'string',
    },
    initialFileNames: {
      comment: 'Filter by initial file name',
      type: 'string',
      list: true,
      optional: true,
    },
  },
});

/** Override type */
export type AgentFileFiltersInput = SchemaToType<typeof AgentFileFiltersInput>;

export const DeleteAgentFilesInput = mkInput({
  name: 'DeleteAgentFilesInput',
  comment: 'Input for deleting a group of agent files',
  fields: {
    ids: {
      comment: 'The id of the agent files to delete',
      type: 'id',
      modelName: 'agentFile',
      list: true,
      optional: true,
    },
    fileIds: {
      comment:
        'The id of the files in the remote system, e.g. the OpenAI file ID',
      type: 'string',
      list: true,
      optional: true,
    },
    applicationId: ReportPromptRunInput.fields.applicationId,
    applicationName: ReportPromptRunInput.fields.applicationName,
    codePackageId: ReportPromptRunInput.fields.codePackageId,
    codePackageName: ReportPromptRunInput.fields.codePackageName,
    repositoryId: ReportPromptRunInput.fields.repositoryId,
    repositoryName: ReportPromptRunInput.fields.repositoryName,
    applicationUserCoreIdentifier:
      ReportPromptRunInput.fields.applicationUserCoreIdentifier,
    applicationUserName: ReportPromptRunInput.fields.applicationUserName,
    pathfinderId: ReportPromptRunInput.fields.pathfinderId,
    pathfinderName: ReportPromptRunInput.fields.pathfinderName,
  },
});

/** Override type */
export type DeleteAgentFilesInput = SchemaToType<typeof DeleteAgentFilesInput>;

export const CreateAgentFileInput = mkInput({
  name: 'CreateAgentFileInput',
  comment: 'Input for creating an agent file',
  fields: {
    name: {
      comment: 'The name of the agent file',
      type: 'string',
    },
    description: {
      comment: 'The description of the agent file',
      type: 'string',
      optional: true,
    },
    fileUploadedAt: {
      comment: 'Date that the file was uploaded to the remote system',
      type: 'Date',
    },
    fileId: {
      comment: 'ID of the file in remote system',
      type: 'string',
    },
    initialFileName: {
      comment:
        'The name of the initial file, when this file is a chunk of another file',
      type: 'string',
      optional: true,
    },
    size: {
      comment: 'Size of the file in bytes',
      type: 'int',
    },
    purpose: {
      comment: 'Purpose of the file',
      type: { PromptFilePurpose },
    },
    agentIds: {
      comment: 'The ids of the agents that have access to the file',
      type: 'id',
      modelName: 'agent',
      optional: true,
      list: true,
    },
    agentNames: {
      comment: 'The names of the agents that have access to the file',
      type: 'string',
      optional: true,
      list: true,
    },
    agentRemoteIds: {
      comment: 'The remote ids of the agents that have access to the file',
      type: 'string',
      optional: true,
      list: true,
    },
    applicationId: ReportPromptRunInput.fields.applicationId,
    applicationName: ReportPromptRunInput.fields.applicationName,
    codePackageId: ReportPromptRunInput.fields.codePackageId,
    codePackageName: ReportPromptRunInput.fields.codePackageName,
    repositoryId: ReportPromptRunInput.fields.repositoryId,
    repositoryName: ReportPromptRunInput.fields.repositoryName,
    runByEmployeeEmail: ReportPromptRunInput.fields.runByEmployeeEmail,
    applicationUserCoreIdentifier:
      ReportPromptRunInput.fields.applicationUserCoreIdentifier,
    applicationUserName: ReportPromptRunInput.fields.applicationUserName,
    pathfinderId: ReportPromptRunInput.fields.pathfinderId,
    pathfinderName: ReportPromptRunInput.fields.pathfinderName,
  },
});

/** Override type */
export type CreateAgentFileInput = SchemaToType<typeof CreateAgentFileInput>;

export const UpdateAgentFileInput = mkInput({
  name: 'UpdateAgentFileInput',
  comment: 'Input for updating an agent file',
  fields: {
    id: {
      comment: 'The id of the agent file',
      modelName: 'agentFile',
      type: 'id',
    },
    name: {
      comment: 'The new name of the agent file',
      type: 'string',
      optional: true,
    },
    initialFileName: {
      comment:
        'The name of the initial file, when this file is a chunk of another file',
      type: 'string',
      optional: true,
    },
    description: {
      comment: 'The new description of the agent file',
      type: 'string',
      optional: true,
    },
    fileUploadedAt: {
      comment: 'Date that the file was uploaded to the remote system',
      type: 'Date',
      optional: true,
    },
    fileId: {
      comment: 'ID of the file in remote system',
      type: 'string',
      optional: true,
    },
    size: {
      comment: 'Size of the file in bytes',
      type: 'int',
      optional: true,
    },
    purpose: {
      comment: 'Purpose of the file',
      type: { PromptFilePurpose },
      optional: true,
    },
    agentIds: CreateAgentFileInput.fields.agentIds,
    agentNames: CreateAgentFileInput.fields.agentNames,
    agentRemoteIds: CreateAgentFileInput.fields.agentRemoteIds,
  },
});

/** Override type */
export type UpdateAgentFileInput = SchemaToType<typeof UpdateAgentFileInput>;

export const UpdateAgentFilesInput = mkInput({
  name: 'UpdateAgentFilesInput',
  comment: 'Input for bulk updating one or more agentFiles',
  fields: {
    agentFiles: {
      comment:
        'List of agent files with the properties that should be updated for each',
      type: UpdateAgentFileInput,
      list: true,
    },
  },
});

/** Override type */
export type UpdateAgentFilesInput = SchemaToType<typeof UpdateAgentFilesInput>;

/** Order for an agent files query */
export const AgentFileOrder = mkOrder(AgentFile.name, AgentFileOrderField);
