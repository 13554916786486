import {
  extractFormItemWrapperProps,
  FormItemWrapper,
  IFormFieldProps,
  IFormItemWrapperProps,
  multipleValidators,
  TValidator,
} from '@main/core-ui';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { RichEditor, RichEditorInCard, RichEditorProps } from './RichEditor';

export interface IFormRichEditorProps extends IFormFieldProps {
  /** Whether to show an outline around the editor */
  showOutline?: boolean;
  /** The value passed in to RichEditor content */
  value?: string;
  /** The validation rules for the input */
  rules?: TValidator[];
}

/**
 * A raw form RichEditor editor for use cases where formatting is not desired.
 */
export const FormRichEditorRaw: React.FC<
  IFormRichEditorProps & Omit<RichEditorProps, 'content'>
> = ({
  name,
  value,
  defaultValue = '',
  rules,
  showOutline,
  ...richEditorProps
}) => {
  const { control } = useFormContext();
  const FormEditor = showOutline ? RichEditorInCard : RichEditor;

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      rules={rules ? { validate: multipleValidators(rules) } : undefined}
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      render={({ field: { value: fieldValue, onChange, ref, ...field } }) => (
        <FormEditor
          content={fieldValue || value || defaultValue}
          {...richEditorProps}
          {...field}
          onUpdate={({ editor }) => {
            onChange(editor.getHTML());
          }}
          withTables={false}
          compact
          sanitize
        />
      )}
    />
  );
};

/**
 * A form RichEditor with formatting and error display.
 */
export const FormRichEditor: React.FC<
  IFormRichEditorProps & RichEditorProps & Omit<IFormItemWrapperProps, 'errors'>
> = (props) => {
  const {
    formState: { errors },
  } = useFormContext();
  const { passthroughProps, wrapperProps } = extractFormItemWrapperProps(props);

  return (
    <FormItemWrapper errors={errors} {...wrapperProps}>
      <FormRichEditorRaw {...passthroughProps} />
    </FormItemWrapper>
  );
};
