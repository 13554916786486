/* eslint-disable max-lines */
import {
  mkMutation,
  mkQuery,
  PAGINATION_INPUT_PARAMS,
} from '@main/schema-utils';

import {
  AddMessagesToPromptRunInput,
  Agent,
  AgentFile,
  AgentFileFiltersInput,
  AgentFileOrder,
  AgentFiltersInput,
  AgentFunction,
  AgentFunctionFiltersInput,
  AgentFunctionOrder,
  AgentOrder,
  Application,
  ApplicationFiltersInput,
  ApplicationOrder,
  ApplicationUser,
  ApplicationUserFiltersInput,
  ApplicationUserOrder,
  CreateAgentFileInput,
  CreateAgentFunctionInput,
  CreateAgentInput,
  CreateApplicationInput,
  CreatePathfinderInput,
  CreatePathfinderPolicyInput,
  CreatePromptGroupInput,
  CreatePromptInput,
  CreatePromptPartialInput,
  CreatePromptThreadInput,
  DeleteAgentFilesInput,
  DeleteAgentFunctionsInput,
  DeleteAgentsInput,
  DeleteApplicationsInput,
  DeleteApplicationUsersInput,
  DeletePathfinderPoliciesInput,
  DeletePathfindersInput,
  DeletePromptGroupsInput,
  DeletePromptPartialsInput,
  DeletePromptsInput,
  DeletePromptThreadsInput,
  ExecutePromptRunInput,
  LargeLanguageModel,
  LargeLanguageModelFiltersInput,
  LargeLanguageModelOrder,
  Pathfinder,
  PathfinderFiltersInput,
  PathfinderOrder,
  PathfinderPolicy,
  PathfinderPolicyFiltersInput,
  PathfinderPolicyOrder,
  Prompt,
  PromptFiltersInput,
  PromptGroup,
  PromptGroupFiltersInput,
  PromptGroupOrder,
  PromptGroupTemplated,
  PromptOrder,
  PromptPartial,
  PromptPartialFiltersInput,
  PromptPartialOrder,
  PromptPartialTemplated,
  PromptRun,
  PromptRunFiltersInput,
  PromptRunOrder,
  PromptsWithVariablesInput,
  PromptTemplated,
  PromptThread,
  PromptThreadFiltersInput,
  PromptThreadOrder,
  PromptVariableData,
  PromptVariableFormItem,
  ReportPromptRunInput,
  UpdateAgentFilesInput,
  UpdateAgentFunctionsInput,
  UpdateAgentsInput,
  UpdateApplicationsInput,
  UpdateOrCreateApplicationUsersInput,
  UpdatePathfinderPoliciesInput,
  UpdatePathfindersInput,
  UpdatePromptGroupsInput,
  UpdatePromptPartialsInput,
  UpdatePromptsInput,
  UpdatePromptThreadsInput,
} from './schema';

export const createPrompt = mkMutation({
  name: 'createPrompt',
  comment: 'Creates a new Prompt',
  params: {
    input: CreatePromptInput,
  },
  response: { prompt: Prompt },
});

export const deletePrompts = mkMutation({
  name: 'deletePrompts',
  comment: 'Delete multiple prompts from an organization',
  params: {
    input: DeletePromptsInput,
  },
  response: {
    success: 'boolean',
  },
});

export const updatePrompts = mkMutation({
  name: 'updatePrompts',
  comment: 'Updates a set of prompts',
  params: {
    input: UpdatePromptsInput,
  },
  response: { prompts: Prompt.list() },
});

export const largeLanguageModels = mkQuery({
  name: 'largeLanguageModels',
  comment: 'List of large language models registered in the instance',
  params: {
    ...PAGINATION_INPUT_PARAMS,
    filterBy: LargeLanguageModelFiltersInput.optional(),
    orderBy: LargeLanguageModelOrder,
  },
  response: {
    nodes: LargeLanguageModel.list(),
    totalCount: 'int',
  },
});

export const promptRuns = mkQuery({
  name: 'promptRuns',
  comment:
    'List the execution results for the prompts registered in the instance',
  params: {
    ...PAGINATION_INPUT_PARAMS,
    filterBy: PromptRunFiltersInput.optional(),
    orderBy: PromptRunOrder,
  },
  response: {
    nodes: PromptRun.list(),
    totalCount: 'int',
  },
});

export const prompts = mkQuery({
  name: 'prompts',
  comment: 'List the prompts',
  params: {
    ...PAGINATION_INPUT_PARAMS,
    filterBy: PromptFiltersInput.optional(),
    orderBy: PromptOrder,
  },
  response: {
    nodes: Prompt.list(),
    totalCount: 'int',
  },
});

export const executePromptRun = mkMutation({
  name: 'executePromptRun',
  comment:
    'Run a prompt and return the results. The results will also be available via the promptRuns query.',
  params: {
    input: ExecutePromptRunInput,
  },
  response: { promptRun: PromptRun },
});

export const reportPromptRun = mkMutation({
  name: 'reportPromptRun',
  comment:
    'When executing a prompt that runs in a separate application, ' +
    'this endpoint can be used to report the status of the prompt run.',
  params: {
    input: ReportPromptRunInput,
  },
  response: { promptRun: PromptRun },
});

export const addMessagesToPromptRun = mkMutation({
  name: 'addMessagesToPromptRun',
  comment: 'Update an existing prompt run with additional messages',
  params: {
    input: AddMessagesToPromptRunInput,
  },
  response: { promptRun: PromptRun },
});

export const updateAgents = mkMutation({
  name: 'updateAgents',
  comment: 'Updates a set of agents',
  params: {
    input: UpdateAgentsInput,
  },
  response: { agents: Agent.list() },
});

export const createAgent = mkMutation({
  name: 'createAgent',
  comment: 'Creates a new Prompt Template',
  params: {
    input: CreateAgentInput,
  },
  response: { agent: Agent },
});

export const agents = mkQuery({
  name: 'agents',
  comment: 'List the agents',
  params: {
    ...PAGINATION_INPUT_PARAMS,
    filterBy: AgentFiltersInput.optional(),
    orderBy: AgentOrder,
  },
  response: {
    nodes: Agent.list(),
    totalCount: 'int',
  },
});

export const deleteAgents = mkMutation({
  name: 'deleteAgents',
  comment: 'Delete multiple agents from an organization',
  params: {
    input: DeleteAgentsInput,
  },
  response: { success: 'boolean' },
});

export const agentFunctions = mkQuery({
  name: 'agentFunctions',
  comment: 'List of agent functions registered in the instance',
  params: {
    ...PAGINATION_INPUT_PARAMS,
    filterBy: AgentFunctionFiltersInput.optional(),
    orderBy: AgentFunctionOrder,
  },
  response: {
    nodes: AgentFunction.list(),
    totalCount: 'int',
  },
});

export const updateAgentFunctions = mkMutation({
  name: 'updateAgentFunctions',
  comment: 'Updates a set of agent functions',
  params: {
    input: UpdateAgentFunctionsInput,
  },
  response: { agentFunctions: AgentFunction.list() },
});

export const createAgentFunction = mkMutation({
  name: 'createAgentFunction',
  comment: 'Creates a new agent file',
  params: {
    input: CreateAgentFunctionInput,
  },
  response: { agentFunction: AgentFunction },
});

export const deleteAgentFunctions = mkMutation({
  name: 'deleteAgentFunctions',
  comment: 'Delete multiple agent functions from an organization',
  params: {
    input: DeleteAgentFunctionsInput,
  },
  response: { success: 'boolean' },
});

export const agentFiles = mkQuery({
  name: 'agentFiles',
  comment: 'List of agentFiles registered in the instance',
  params: {
    ...PAGINATION_INPUT_PARAMS,
    filterBy: AgentFileFiltersInput.optional(),
    orderBy: AgentFileOrder,
  },
  response: {
    nodes: AgentFile.list(),
    totalCount: 'int',
  },
});

export const updateAgentFiles = mkMutation({
  name: 'updateAgentFiles',
  comment: 'Updates a set of agentFiles',
  params: {
    input: UpdateAgentFilesInput,
  },
  response: { agentFiles: AgentFile.list() },
});

export const createAgentFile = mkMutation({
  name: 'createAgentFile',
  comment: 'Creates a new agentFile',
  params: {
    input: CreateAgentFileInput,
  },
  response: { agentFile: AgentFile },
});

export const deleteAgentFiles = mkMutation({
  name: 'deleteAgentFiles',
  comment: 'Delete multiple agentFiles from an organization',
  params: {
    input: DeleteAgentFilesInput,
  },
  response: { success: 'boolean' },
});

export const updatePromptPartials = mkMutation({
  name: 'updatePromptPartials',
  comment: 'Updates a set of partial prompts',
  params: {
    input: UpdatePromptPartialsInput,
  },
  response: { promptPartials: PromptPartial.list() },
});

export const createPromptPartial = mkMutation({
  name: 'createPromptPartial',
  comment: 'Creates a new Prompt Partial',
  params: {
    input: CreatePromptPartialInput,
  },
  response: { promptPartial: PromptPartial },
});

export const promptPartials = mkQuery({
  name: 'promptPartials',
  comment: 'List the prompt partials',
  params: {
    ...PAGINATION_INPUT_PARAMS,
    filterBy: PromptPartialFiltersInput.optional(),
    orderBy: PromptPartialOrder,
  },
  response: {
    nodes: PromptPartial.list(),
    totalCount: 'int',
  },
});

export const deletePromptPartials = mkMutation({
  name: 'deletePromptPartials',
  comment: 'Delete multiple prompt partials from an organization',
  params: {
    input: DeletePromptPartialsInput,
  },
  response: { success: 'boolean' },
});

export const updatePromptGroups = mkMutation({
  name: 'updatePromptGroups',
  comment: 'Updates a Prompt Group',
  params: {
    input: UpdatePromptGroupsInput,
  },
  response: { promptGroups: PromptGroup.list() },
});

export const createPromptGroup = mkMutation({
  name: 'createPromptGroup',
  comment: 'Creates a new Prompt Group',
  params: {
    input: CreatePromptGroupInput,
  },
  response: { promptGroup: PromptGroup },
});

export const promptsWithVariables = mkQuery({
  name: 'promptsWithVariables',
  comment:
    'Given a list of prompts, compile all of the necessary metadata that is needed to render the prompt',
  params: {
    input: PromptsWithVariablesInput,
  },
  response: {
    prompts: PromptTemplated.list(),
    promptPartials: PromptPartialTemplated.list(),
    promptGroups: PromptGroupTemplated.list(),
    calculatedVariables: PromptVariableData.list(),
    runtimeVariables: PromptVariableFormItem.list(),
  },
});

export const promptGroups = mkQuery({
  name: 'promptGroups',
  comment: 'List the prompt groups',
  params: {
    ...PAGINATION_INPUT_PARAMS,
    filterBy: PromptGroupFiltersInput.optional(),
    orderBy: PromptGroupOrder,
  },
  response: {
    nodes: PromptGroup.list(),
    totalCount: 'int',
  },
});

export const deletePromptGroups = mkMutation({
  name: 'deletePromptGroups',
  comment: 'Delete multiple prompt groups from an organization',
  params: {
    input: DeletePromptGroupsInput,
  },
  response: { success: 'boolean' },
});

export const applications = mkQuery({
  name: 'applications',
  comment: 'List of applications registered in the instance',
  params: {
    ...PAGINATION_INPUT_PARAMS,
    filterBy: ApplicationFiltersInput.optional(),
    orderBy: ApplicationOrder,
  },
  response: {
    nodes: Application.list(),
    totalCount: 'int',
  },
});

export const updateApplications = mkMutation({
  name: 'updateApplications',
  comment: 'Updates a set of applications',
  params: {
    input: UpdateApplicationsInput,
  },
  response: { applications: Application.list() },
});

export const createApplication = mkMutation({
  name: 'createApplication',
  comment: 'Creates a new application',
  params: {
    input: CreateApplicationInput,
  },
  response: { application: Application },
});

export const deleteApplications = mkMutation({
  name: 'deleteApplications',
  comment: 'Delete multiple applications from an organization',
  params: {
    input: DeleteApplicationsInput,
  },
  response: { success: 'boolean' },
});

export const applicationUsers = mkQuery({
  name: 'applicationUsers',
  comment:
    'List of users that were registered to applications within the instance',
  params: {
    ...PAGINATION_INPUT_PARAMS,
    filterBy: ApplicationUserFiltersInput.optional(),
    orderBy: ApplicationUserOrder,
  },
  response: {
    nodes: ApplicationUser.list(),
    totalCount: 'int',
  },
});

export const updateOrCreateApplicationUsers = mkMutation({
  name: 'updateOrCreateApplicationUsers',
  comment: 'Updates or create a set of application users',
  params: {
    input: UpdateOrCreateApplicationUsersInput,
  },
  response: { applicationUsers: ApplicationUser.list() },
});

export const deleteApplicationUsers = mkMutation({
  name: 'deleteApplicationUsers',
  comment: 'Delete multiple application users from an organization',
  params: {
    input: DeleteApplicationUsersInput,
  },
  response: { success: 'boolean' },
});

export const pathfinders = mkQuery({
  name: 'pathfinders',
  comment: 'List of pathfinders registered in the instance',
  params: {
    ...PAGINATION_INPUT_PARAMS,
    filterBy: PathfinderFiltersInput.optional(),
    orderBy: PathfinderOrder,
  },
  response: {
    nodes: Pathfinder.list(),
    totalCount: 'int',
  },
});

export const updatePathfinders = mkMutation({
  name: 'updatePathfinders',
  comment: 'Updates a set of pathfinders',
  params: {
    input: UpdatePathfindersInput,
  },
  response: { pathfinders: Pathfinder.list() },
});

export const createPathfinder = mkMutation({
  name: 'createPathfinder',
  comment: 'Creates a new pathfinder',
  params: {
    input: CreatePathfinderInput,
  },
  response: { pathfinder: Pathfinder },
});

export const deletePathfinders = mkMutation({
  name: 'deletePathfinders',
  comment: 'Delete multiple pathfinders from an organization',
  params: {
    input: DeletePathfindersInput,
  },
  response: { success: 'boolean' },
});

export const pathfinderPolicies = mkQuery({
  name: 'pathfinderPolicies',
  comment: 'List of pathfinder policies registered in the instance',
  params: {
    ...PAGINATION_INPUT_PARAMS,
    filterBy: PathfinderPolicyFiltersInput.optional(),
    orderBy: PathfinderPolicyOrder,
  },
  response: {
    nodes: PathfinderPolicy.list(),
    totalCount: 'int',
  },
});

export const updatePathfinderPolicies = mkMutation({
  name: 'updatePathfinderPolicies',
  comment: 'Updates a set of pathfinder policies',
  params: {
    input: UpdatePathfinderPoliciesInput,
  },
  response: { pathfinderPolicies: PathfinderPolicy.list() },
});

export const createPathfinderPolicy = mkMutation({
  name: 'createPathfinderPolicy',
  comment: 'Creates a new pathfinder policy',
  params: {
    input: CreatePathfinderPolicyInput,
  },
  response: { pathfinderPolicy: PathfinderPolicy },
});

export const deletePathfinderPolicies = mkMutation({
  name: 'deletePathfinderPolicies',
  comment: 'Delete multiple pathfinder policies from an organization',
  params: {
    input: DeletePathfinderPoliciesInput,
  },
  response: { success: 'boolean' },
});

export const promptThreads = mkQuery({
  name: 'promptThreads',
  comment: 'List of prompt threads registered in the instance',
  params: {
    ...PAGINATION_INPUT_PARAMS,
    filterBy: PromptThreadFiltersInput.optional(),
    orderBy: PromptThreadOrder,
  },
  response: {
    nodes: PromptThread.list(),
    totalCount: 'int',
  },
});

export const updatePromptThreads = mkMutation({
  name: 'updatePromptThreads',
  comment: 'Updates a set of prompt threads',
  params: {
    input: UpdatePromptThreadsInput,
  },
  response: { promptThreads: PromptThread.list() },
});

export const createPromptThread = mkMutation({
  name: 'createPromptThread',
  comment: 'Creates a new prompt thread',
  params: {
    input: CreatePromptThreadInput,
  },
  response: { promptThread: PromptThread },
});

export const deletePromptThreads = mkMutation({
  name: 'deletePromptThreads',
  comment: 'Delete multiple prompt threads from an organization',
  params: {
    input: DeletePromptThreadsInput,
  },
  response: { success: 'boolean' },
});

/* eslint-enable max-lines */
