import { MenuTabInterface } from '@main/internationalization';
import { mkInput, mkType, SchemaToType } from '@main/schema-utils';

import { DataCollection, DataCollectionInput } from './dataCollection';
import { DataCategoryMethod, DataCategoryStaticType } from './enums';

export const DataCategoryInput = mkInput({
  name: 'DataCategoryInput',
  comment: 'Input for creating a data category',
  fields: {
    id: {
      comment: 'The id of the update data category, or undefined if new',
      optional: true,
      modelName: 'dataCategory',
      type: 'id',
    },
    title: {
      comment: 'The title of the data category',
      type: 'string',
    },
    staticType: {
      comment: 'The static type of the data category',
      optional: true,
      type: { DataCategoryStaticType },
    },
    children: {
      comment: 'The children (data collections within menu tab)',
      list: true,
      type: DataCollectionInput,
    },
  },
});

/** Override type */
export type DataCategoryInput = SchemaToType<typeof DataCategoryInput>;

export const DataCategoryMethodMenuInput = mkInput({
  name: 'DataCategoryMethodMenuInput',
  comment: 'Input for changing data category method menus',
  fields: {
    method: {
      comment: 'The data collection method',
      type: { DataCategoryMethod },
    },
    children: {
      comment: 'The data categories to update',
      list: true,
      type: DataCategoryInput,
    },
  },
});

/** Override type */
export type DataCategoryMethodMenuInput = SchemaToType<
  typeof DataCategoryMethodMenuInput
>;

export const DataCategory = mkType({
  name: 'DataCategory',
  comment: 'A category of data, rended to data subjected on the privacy center',
  interfaces: [MenuTabInterface],
  fields: {
    ...MenuTabInterface.fields,
    staticType: {
      comment:
        'The underlying static type from which this data category was created. Defaults to null',
      optional: true,
      type: { DataCategoryStaticType },
    },
    method: {
      comment: 'Data category method',
      optional: true,
      type: { DataCategoryMethod },
    },
    children: {
      comment:
        'The data collection data categories that should be displayed as children of this data category',
      list: true,
      type: DataCollection,
    },
  },
});

/** Override type */
export type DataCategory = SchemaToType<typeof DataCategory>;

export const DataCategoryMethodMenu = mkType({
  name: 'DataCategoryMethodMenu',
  comment: 'A tab list of data categories grouped by method',
  interfaces: [MenuTabInterface],
  fields: {
    ...MenuTabInterface.fields,
    method: {
      comment: 'Data category method',
      type: { DataCategoryMethod },
    },
    children: {
      comment:
        'The data categories that should be displayed as children of this method grouping',
      list: true,
      type: DataCategory,
    },
  },
});

/** Override type */
export type DataCategoryMethodMenu = SchemaToType<
  typeof DataCategoryMethodMenu
>;
