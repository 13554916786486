import {
  commonFormStatusMessages,
  SaveChanges,
} from '@main/ad-core-components';
import {
  buildUseMutation,
  formatErrorMessage,
  Icon,
  message,
  StyleUtils,
} from '@main/core-ui';
import { endpoints, parsePrivacyCenter } from '@main/pc-types';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import { privacyCenterMessages } from './messages';
import {
  selectIsRequestsProcessedDisclosureStatsModified,
  selectRequestsProcessedDisclosureStatsInput,
} from './RequestsProcessedStats/selectors';
import { ReviewChangesAndPublishButton } from './ReviewChangesAndPublishButton';
import {
  selectIsModified,
  selectPrivacyCenterId,
  selectPrivacyCenterInput,
} from './selectors';
import {
  requestsProcessedDisclosureStatsSettingsSlice,
  resetPrivacyCenter,
  setPrivacyCenter,
} from './slice';
import { ViewPrivacyCenter } from './ViewPrivacyCenter';

const useUpdatePrivacyCenter = buildUseMutation(endpoints.updatePrivacyCenter);
const useSetRequestsProcessedDisclosureStatsSettings = buildUseMutation(
  endpoints.setRequestsProcessedDisclosureStatsSettings,
);

/**
 * Update the base of the privacy center
 */
export const UpdatePrivacyCenter: React.FC = () => {
  const isPCModified = useSelector(selectIsModified);
  const privacyCenterInput = useSelector(selectPrivacyCenterInput);
  const [updatePrivacyCenter, updatePrivacyCenterResult] =
    useUpdatePrivacyCenter({
      refetchQueries: ['PrivacyCenterVersionModules'],
    });

  const isRequestsProcessedDisclosureStatsModified = useSelector(
    selectIsRequestsProcessedDisclosureStatsModified,
  );
  const setRequestsProcessedDisclosureStatsSettingsInput = useSelector(
    selectRequestsProcessedDisclosureStatsInput,
  );
  const privacyCenterId = useSelector(selectPrivacyCenterId);
  const [
    setRequestsProcessedDisclosureStatsSettings,
    setRequestsProcessedDisclosureStatsSettingsResult,
  ] = useSetRequestsProcessedDisclosureStatsSettings({
    refetchQueries: ['PrivacyCenterVersionModules'],
  });

  const isModified = isPCModified || isRequestsProcessedDisclosureStatsModified;

  const dispatch = useDispatch();
  const { formatMessage } = useIntl();
  const [savingDraft, setSavingDraft] = useState(false);

  useEffect(() => {
    if (updatePrivacyCenterResult.error) {
      message.error(
        formatErrorMessage(updatePrivacyCenterResult.error.message),
      );
    }
    if (setRequestsProcessedDisclosureStatsSettingsResult.error) {
      message.error(
        formatErrorMessage(
          setRequestsProcessedDisclosureStatsSettingsResult.error.message,
        ),
      );
    }
  }, [
    updatePrivacyCenterResult.error,
    setRequestsProcessedDisclosureStatsSettingsResult.error,
  ]);

  const savePrivacyCenterDraft = async (isDraft: boolean): Promise<void> => {
    let success = false;
    if (privacyCenterInput) {
      setSavingDraft(isDraft);
      const { data } = await updatePrivacyCenter({
        variables: {
          input: privacyCenterInput,
        },
      });
      if (data) {
        success = true;
        dispatch(setPrivacyCenter(parsePrivacyCenter(data.privacyCenter)));
      }
    }

    if (setRequestsProcessedDisclosureStatsSettingsInput) {
      setSavingDraft(isDraft);
      const { data } = await setRequestsProcessedDisclosureStatsSettings({
        variables: {
          input: {
            tableEnabled:
              setRequestsProcessedDisclosureStatsSettingsInput.tableEnabled,
            valueOverrides:
              setRequestsProcessedDisclosureStatsSettingsInput.valueOverrides,
            disabledActions:
              setRequestsProcessedDisclosureStatsSettingsInput.disabledActions,
            privacyCenterId,
          },
        },
      });

      if (data && data.success) {
        success = true;
        dispatch(
          requestsProcessedDisclosureStatsSettingsSlice.actions.setInitialRequestsProcessedDisclosureStatsSettings(
            setRequestsProcessedDisclosureStatsSettingsInput,
          ),
        );
      }
    }

    // Print a message on success
    if (success) {
      message.success(formatMessage(commonFormStatusMessages.saveSuccess));
    }
  };

  return (
    <div
      style={{
        width: '100%',
        ...StyleUtils.Flex.Row.AlignRight,
        gap: StyleUtils.Spacing.sm,
      }}
    >
      <ViewPrivacyCenter />
      <SaveChanges
        isModified={isModified}
        saving={updatePrivacyCenterResult.loading && savingDraft}
        saveMessage={privacyCenterMessages.saveChanges}
        saveButtonProps={{
          size: 'sm',
          variant: 'outline-secondary',
          icon: <Icon type="floppy-disk" />,
        }}
        discardButtonProps={{ size: 'sm' }}
        onClear={() => {
          dispatch(resetPrivacyCenter());
          dispatch(
            requestsProcessedDisclosureStatsSettingsSlice.actions.resetRequestsProcessedDisclosureStatsSettings(),
          );
        }}
        onSave={() => savePrivacyCenterDraft(true)}
      />
      <ReviewChangesAndPublishButton
        savePCDraft={
          isModified ? () => savePrivacyCenterDraft(false) : undefined
        }
        savePCDraftSaving={updatePrivacyCenterResult.loading && !savingDraft}
      />
    </div>
  );
};
