import { defineMessages } from '@transcend-io/internationalization';

export const commonFormStatusMessages = defineMessages(
  'ad-core-components.Form.commonFormStatus',
  {
    updateSuccess: {
      defaultMessage: 'Updated Successfully',
    },
    createSuccess: {
      defaultMessage: 'Created Successfully',
    },
    deleteSuccess: {
      defaultMessage: 'Deleted Successfully',
    },
    saveSuccess: {
      defaultMessage: 'Saved Successfully',
    },
    addSuccess: {
      defaultMessage: 'Added Successfully',
    },
  },
);
