import {
  endpoints,
  UserFiltersInput,
  UserPreview,
} from '@main/access-control-types';
import {
  AUTOCOMPLETE_OFF_PROPS,
  buildUseInfiniteScroll,
  Icon,
  Validators,
} from '@main/core-ui';
import { createNewId, ID } from '@main/schema-utils';
import React, { useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { MenuPosition } from 'react-select';

import { OrganizationIcon } from '../OrganizationIcon';
import { PaginatedSelect, PaginatedSelectProps } from '../PaginatedSelect';
import { selectUserMessages } from './messages';

export const SELECT_USER_NODES = {
  id: null,
  name: null,
  email: null,
  profilePicture: null,
} as const;

export const NONE_USER = {
  id: '' as ID<'user'>,
  name: 'None',
  email: '',
  profilePicture: '',
};

/**
 * Selected user
 */
export type SelectedUser = Pick<UserPreview, keyof typeof SELECT_USER_NODES> & {
  /** When true, option is a newly created value */
  isNew?: boolean;
};

/**
 * Props for SelectUser component
 */
export interface SelectUserProps<
  IsMulti extends boolean,
  T extends SelectedUser = SelectedUser,
> extends Omit<
    PaginatedSelectProps<SelectedUser, IsMulti>,
    | 'isQueryLoading'
    | 'queryError'
    | 'fetchMore'
    | 'onEndsTyping'
    | 'isCreatable'
    | 'onChange'
    | 'isValidNewOption'
  > {
  /** Whether to include an option for choosing no users */
  includeNone?: boolean;
  /** Show invite option for non-existent users */
  allowInvite?: boolean;
  /** Additional filters */
  filterBy?: UserFiltersInput;
  /** On change */
  onChange?: (users: T[]) => void;
  /** How to position the menu (see docs for React Select) */
  menuPosition?: MenuPosition;
  /**
   * Extra options that allow non-users, like external assignees on assessments,
   * to be included in the list of users in the options list
   */
  extraOptions?: T[];
}

const useUsers = buildUseInfiniteScroll(endpoints.users, endpoints.users.name, {
  totalCount: null,
  nodes: SELECT_USER_NODES,
});

/**
 * Component to select one or more users from the current organization.
 */
export function SelectUser<
  TIsMulti extends boolean,
  T extends SelectedUser = SelectedUser,
>({
  allowInvite = false,
  placeholderDescriptor = selectUserMessages.placeholder,
  showOutline = true,
  onChange,
  filterBy,
  includeNone,
  menuPosition = 'fixed',
  isDisabled,
  extraOptions,
  ...paginatedSelectProps
}: SelectUserProps<TIsMulti, T>): JSX.Element {
  const { formatMessage } = useIntl();
  const [searchText, setSearchText] = useState('');
  const { data, loading, error, fetchMore } = useUsers({
    variables: {
      filterBy: {
        text: searchText,
        ...filterBy,
      },
    },
    fetchPolicy: 'cache-and-network',
  });

  const options = useMemo(
    () =>
      [
        ...(includeNone ? [NONE_USER] : []),
        ...(extraOptions || []),
        ...(data?.nodes.map((node) => ({
          isNew: false as const,
          ...node,
        })) || []),
      ] as T[],
    [data, includeNone, extraOptions],
  );

  return (
    <PaginatedSelect
      id="select-users"
      showOutline={showOutline}
      placeholderDescriptor={placeholderDescriptor}
      options={options}
      isQueryLoading={loading}
      queryError={error}
      menuPosition={menuPosition}
      fetchMore={fetchMore}
      onEndsTyping={setSearchText}
      isCreatable={allowInvite}
      getOptionValue={({ id }: T) => id}
      getOptionLabel={({ email, name, isNew }: T) => (isNew ? email : name)}
      getOptionLogo={({ profilePicture, isNew }: T) =>
        isNew ? (
          <Icon type="moving-mail" />
        ) : (
          <OrganizationIcon
            organizationIconSrc={profilePicture}
            size={20}
            style={{ fontSize: '12px' }}
            circle
          />
        )
      }
      formatCreateLabel={(inputValue) =>
        formatMessage(selectUserMessages.inviteViaEmail, {
          email: inputValue,
        })
      }
      getNewOptionData={(inputValue, optionLabel) => ({
        isNew: true as const,
        name: inputValue.split('@')[0],
        // In order for the `formatCreateLabel` to work along with `getOptionValue` and `getNewOptionData`,
        // you have to set `optionLabel` as whatever you are getting in `getOptionLabel`. Somehow, react-select
        // must swap `optionsLabel` for `inputValue`, because in `onChange`, email will be equal to `inputValue`.
        // This swap only works if `formatCreateLabel` uses `inputValue` in the output.
        email: optionLabel,
        profilePicture: '',
        id: createNewId<'user'>(),
      })}
      isValidNewOption={(inputValue) => {
        if (!inputValue || !data || data.nodes.length > 0) return false;

        return !Validators.EMAIL.EMAIL!(inputValue);
      }}
      onChange={onChange as any}
      isDisabled={isDisabled}
      {...(AUTOCOMPLETE_OFF_PROPS as any)}
      {...paginatedSelectProps}
    />
  );
}
