import {
  Avatar,
  FlexColumn,
  FlexRowCenterVertical,
  Popover,
  StyleUtils,
} from '@main/core-ui';
import React from 'react';

import { Copy } from '../Copy';
import { PreviewUserWrapper } from './wrappers';

/**
 * Props for PreviewUser
 */
export interface PreviewUserProps {
  /** Users profile picture */
  profilePicture: string;
  /** Email of user */
  email?: string;
  /** Name of user */
  name: string;
}

/**
 * Preview a single user where popover shows email
 */
export function PreviewUser({
  name,
  email,
  profilePicture,
}: PreviewUserProps): JSX.Element {
  const content = (
    <FlexRowCenterVertical
      style={{ display: 'inline-flex', gap: StyleUtils.Spacing.sm }}
    >
      <Avatar src={profilePicture} size={24} />
      <span>{name}</span>
    </FlexRowCenterVertical>
  );
  return (
    <PreviewUserWrapper>
      {email ? (
        <Popover contents={<Copy value={email} />}>{content}</Popover>
      ) : (
        content
      )}
    </PreviewUserWrapper>
  );
}

/**
 * Preview a single user where popover shows name and email
 */
export function UserPopover({
  name,
  email,
  profilePicture,
}: PreviewUserProps): JSX.Element {
  const content = <Avatar src={profilePicture} size={24} />;
  return (
    <PreviewUserWrapper>
      {email ? (
        <Popover
          contents={
            <FlexColumn style={{ gap: StyleUtils.Spacing.sm }}>
              <Copy value={name} />
              <Copy value={email} />
            </FlexColumn>
          }
        >
          {content}
        </Popover>
      ) : (
        content
      )}
    </PreviewUserWrapper>
  );
}
