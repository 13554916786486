import styled from 'styled-components';

/**
 * Props for header wrapper
 */
export interface HeaderWrapperProps {
  /** When true, no nav */
  hideNav?: boolean;
}

/**
 * The wrapper for a Header
 */
export const HeaderWrapper = styled.div<HeaderWrapperProps>`
  height: ${({ hideNav }) => (hideNav ? '0' : '80px')};
  padding-bottom: ${({ hideNav }) => (hideNav ? '0' : '120px')};
  display: flex;
  justify-content: space-between;
`;

/**
 * The TranscendLogo wrapper
 */
export const LogoWrapper = styled.div`
  padding: 13px 50px;
`;

/**
 * Padding for partnerships logo
 */
export const PartnershipsWrapper = styled.div`
  padding: 13px 10px;
`;

/**
 * Wrapper for lang select
 */
export const LanguageSelectWrapper = styled.div`
  position: fixed;
  bottom: 8px;
  right: 8px;
`;
