import get from 'lodash/get';
import { IntlFormatters } from 'react-intl';
import { Column, CsvCellValue } from 'react-table';

import { cases } from '@transcend-io/handlebars-utils';

import { logger } from '@main/core-ui';

import { useExportGqlToCsvMessages } from './messages';
import { CsvColumnDefinition } from './types';

/**
 * Updates all of the keys in a csv row to use Capital Case (every word is capitalized)
 *
 * @param obj - the object whose keys to capitalize
 * @returns a cloned input object with keys in Capital Case
 */
export const capitalCasifyCsvKeys = (
  obj: Record<string, any>,
): Record<string, any> =>
  Object.fromEntries(
    Object.entries(obj).map(([key, value]) => [cases.capitalCase(key), value]),
  );

export const transformColumnsToCsvHeaders = (
  { columns, headerMessages }: CsvColumnDefinition,
  formatMessage: IntlFormatters['formatMessage'],
): string[] =>
  columns.reduce(
    (csvHeaders, { accessor, id, csvHeader, toCsvCell, toCsvCells }) => {
      let header: string | string[];
      const messageKey = id ?? accessor;

      if (!toCsvCell && !toCsvCells) {
        // If there is no CSV cell definition, then including the header will
        // make the columns misaligned, so we just skip it
        return csvHeaders;
      }

      if (csvHeaders.length === 0) {
        csvHeaders.push(
          formatMessage(
            headerMessages.id ?? useExportGqlToCsvMessages.idCsvHeader,
          ),
        );
      }

      if (csvHeader) {
        header =
          typeof csvHeader === 'string'
            ? csvHeader
            : (Array.isArray(csvHeader) ? csvHeader : [csvHeader]).map((h) =>
                typeof h === 'string' ? h : formatMessage(h),
              );
      } else if (typeof messageKey === 'string' && headerMessages[messageKey]) {
        header =
          typeof headerMessages[messageKey] === 'string'
            ? headerMessages[messageKey]
            : formatMessage(headerMessages[messageKey]);
      } else {
        header = '';
      }

      return [...csvHeaders, ...(Array.isArray(header) ? header : [header])];
    },
    [] as string[],
  );

export const transformColumnsToCsvCells = <T extends Record<string, any>>(
  columns: Column<T>[],
  obj: T,
  formatMessage: IntlFormatters['formatMessage'],
): CsvCellValue[] =>
  columns.reduce((csvCells, { accessor, id, toCsvCell, toCsvCells }) => {
    const key = typeof accessor === 'string' ? accessor : id;
    if (!key) {
      logger.error(
        new Error(
          'Column definition is missing an accessor or id. Data for this column cannot be exported',
        ),
      );
      return csvCells;
    }
    if (!toCsvCell && !toCsvCells) {
      // If there is no CSV cell definition, just skip it
      return csvCells;
    }

    if (csvCells.length === 0) {
      csvCells.push(obj.id);
    }

    if (toCsvCells) {
      const cells = toCsvCells(
        {
          value: get(obj, key.split('.')),
          data: obj,
        },
        formatMessage,
      );
      if (cells.length > 0) {
        csvCells.push(...cells);
      } else {
        csvCells.push('');
      }
    } else {
      // We already checked above that one of the two CSV-transforming
      // methods has been defined, so it is safe to assert non-null
      const cell = toCsvCell!(
        {
          value: get(obj, key.split('.')),
          data: obj,
        },
        formatMessage,
      );
      csvCells.push(cell);
    }

    return csvCells;
  }, [] as CsvCellValue[]);
