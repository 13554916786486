import { FilterConfigItem } from '@main/ad-core-components';
import { SingleTag } from '@main/core-ui';
import { Controllership } from '@main/datamap-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import {
  CONTROLLERSHIP_COLOR_NAMES,
  controllershipToOption,
} from './constants';
import { controllershipMessages } from './messages';
import { SelectControllerships } from './SelectControllerships';

export const getControllershipFilterConfig = <
  TFilterInput extends {
    /** Filter for controllerships */
    controllerships: Controllership[];
  },
>({
  label,
  value,
  onChange,
}: {
  /** Label to display in the filter manager */
  label: FilterConfigItem<TFilterInput>['label'];
  /** Value being filtered on */
  value: Controllership[];
  /** Handler for when the value is updated */
  onChange: (controllerships: Controllership[]) => void;
}): FilterConfigItem<TFilterInput> => ({
  filterKey: 'controllerships' as Extract<keyof TFilterInput, string>,
  label,
  renderPill: ({ filterValues: { controllerships = [] }, index = 0 }) => {
    const controllership = controllerships[index];
    return (
      <SingleTag
        color={CONTROLLERSHIP_COLOR_NAMES[controllership]}
        label={<FormattedMessage {...controllershipMessages[controllership]} />}
      />
    );
  },
  filter: (
    <SelectControllerships
      isMulti
      value={value.map(controllershipToOption)}
      menuPosition="absolute"
      onChange={(selected) => onChange(selected.map(({ value }) => value))}
    />
  ),
});
