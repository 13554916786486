import React from 'react';

import { AttributeKeyType } from '@transcend-io/privacy-types';

import {
  AssessmentFormPreview,
  AssessmentSecondaryType,
} from '@main/attribute-types';
import { ID } from '@main/schema-utils';
import { ColorPalette } from '@main/theme';

/** An option for the select component */
export interface SelectAttributeDefaultOption {
  /** Type of SelectAttributeOption */
  type?: 'default';
  /** The ID of the attribute value */
  id: ID<'attributeValue'>;
  /** The name for the attribute value  */
  name: string;
  /** The color for the attribute value  */
  color?: string;
  /** Whether the attribute value can be deleted  */
  canDelete: boolean;
  /** Type value for an assessment attribute */
  assessmentSecondaryType?: AssessmentSecondaryType;
  /** A snippet of the assessment form, if it exists */
  assessmentForm?: AssessmentFormPreview;
  /** The attribute key the value is nested under */
  attributeKey: {
    /** The name of attribute key */
    name: string;
    /** The id of attribute key */
    id: ID<'attributeKey'>;
    /** The type of the attribute */
    type: AttributeKeyType;
  };
}

export interface SelectAttributeCustomOption {
  /** Type of SelectAttributeOption */
  type: 'custom';
  /** An arbitrary identifier for this option */
  id: string;
  /** A display value */
  name: string;
  /** Color to display */
  color?: keyof ColorPalette;
  /** Logo to display before the name */
  logo?: React.ReactElement;
  /** Action to take when this option is selected */
  onChange?: () => void;
  /** The attribute key the value is nested under */
  attributeKey: {
    /** The name of attribute key */
    name: string;
    /** The id of attribute key */
    id: ID<'attributeKey'>;
    /** The type of the attribute */
    type: AttributeKeyType;
  };
}

/**
 * An option for the SelectAttribute component
 */
export type SelectAttributeOption =
  | SelectAttributeDefaultOption
  | SelectAttributeCustomOption;

export const isCustomOption = (
  option: SelectAttributeOption,
): option is SelectAttributeCustomOption => option.type === 'custom';
