/**
 * The fields that an agent file can be ordered on
 */
export enum AgentFileOrderField {
  /** The name of the agent file */
  Name = 'name',
  /** The purpose of the agent file */
  Purpose = 'purpose',
  /** The age of the agent file */
  CreatedAt = 'createdAt',
  /** The last updated time */
  UpdatedAt = 'updatedAt',
}
