import { statusDescriptionMessages, statusTitleMessages } from './messages';
import type { RequestStatusConfig } from './types';

/**
 * ## Downloadable
 *
 * The data subject request is in a state where the download zip is exposed to the
 * privacy center, and a data subject can access the files.
 */
export const DOWNLOADABLE_STATUS: RequestStatusConfig = {
  color: 'success',
  icon: 'download',
  canDownload: true,
  canViewByDataSubject: true,
  isClosed: true,
  isSuccess: true,
  messages: {
    description: statusDescriptionMessages.DOWNLOADABLE,
    title: statusTitleMessages.DOWNLOADABLE,
  },
  primaryOnly: true,
  transitions: ['COMPLETED'],
};
