import {
  AUDIT_EVENT_SENSITIVE_FIELDS,
  AuditEventCode,
  AuditEventPreview,
} from '@main/audit-types';
import { FlexColumn, StyleUtils } from '@main/core-ui';
import { cases } from '@transcend-io/handlebars-utils';
import { LanguageKey } from '@transcend-io/internationalization';
import { Change, diffLines } from 'diff';
import isObject from 'lodash/isObject';
import uniq from 'lodash/uniq';
import { transparentize } from 'polished';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useTheme } from 'styled-components';

import { ChangeBlock } from '../JsonDiff';
import { AuditTrailListItemLayout } from './AuditTrailListItemLayout';
import { useFormatAuditTrailMessage } from './hooks';
import { auditTrailSensitiveChangeMessages } from './messages';
import {
  AuditTrailStateDiffRowContentWrapper,
  AuditTrailStateDiffRowWrapper,
  AuditTrailStateDiffWrapper,
} from './wrappers';

interface AuditTrailStateListItemProps {
  /** the event to display */
  event: AuditEventPreview;
}

interface AuditTrailStateValueRowProps {
  /** the key of the changed field */
  fieldName: string;
  /** the value of the changed field */
  value: unknown;
  /** the value of the other state of the field */
  otherValue: unknown;
  /** is the current row in the before state */
  isBefore: boolean;
}

/**
 * Returns whether or not the value is a large diff
 */
function calcIsLargeDiff(value: any): boolean {
  return (
    isObject(value) ||
    (typeof value === 'string' && (value.includes('\n') || value.length > 200))
  );
}

/**
 * AuditTrailStateValueRow
 */
export const AuditTrailStateValueRow: React.FC<
  AuditTrailStateValueRowProps
> = ({ fieldName, value, otherValue, isBefore }) => {
  const { formatMessage } = useIntl();
  const theme = useTheme();

  const isLargeDiff = calcIsLargeDiff(value) || calcIsLargeDiff(otherValue);

  const stringifyValue = (val: unknown): unknown =>
    isObject(val)
      ? JSON.stringify(val, null, 2)
          // add line breaks after html closing blocks, except for when they are
          // immediately followed by ", (end of JSON field)
          .replace(/(<\/\S*?>)(?!(",|$))/g, '$1\n')
      : val;

  const stringifiedValue = useMemo(() => stringifyValue(value), [value]);
  const stringifiedOtherValue = useMemo(
    () => (otherValue === undefined ? undefined : stringifyValue(otherValue)),
    [value],
  );
  // in the case of an undefined other value => an object, we want to use an empty object to match the diff
  const updatedOtherValue = useMemo(
    () => (otherValue === undefined ? {} : otherValue),
    [otherValue],
  );
  const diffRows = useMemo(
    () =>
      // in the case of an undefined other value => an object, hardcode the diff to show empty object to object
      (isLargeDiff && !stringifiedValue) || !stringifiedOtherValue
        ? ((isBefore
            ? [
                {
                  value: stringifiedValue ?? '--',
                  removed: true,
                },
                {
                  value: stringifiedOtherValue ?? '--',
                  added: true,
                },
              ]
            : [
                {
                  value: stringifiedOtherValue ?? '--',
                  removed: true,
                },
                {
                  value: stringifiedValue ?? '--',
                  added: true,
                },
              ]) as Change[])
        : // no need to calculate a diff if either value is undefined or not a string
          typeof stringifiedOtherValue !== 'string' ||
            typeof stringifiedValue !== 'string' ||
            !stringifiedOtherValue ||
            !stringifiedValue
          ? undefined
          : isBefore
            ? // is on the left side of the diff, so value is before, othervalue is after
              diffLines(
                stringifiedValue as string,
                stringifiedOtherValue as string,
              )
            : // is on the right side of the diff, so value is after, othervalue is before
              diffLines(
                stringifiedOtherValue as string,
                stringifiedValue as string,
              ),
    [stringifiedValue, stringifiedOtherValue],
  );

  return (
    <AuditTrailStateDiffRowWrapper
      style={{
        ...(isLargeDiff
          ? { flexDirection: 'column' }
          : { flexDirection: 'row', ...StyleUtils.Flex.SpaceBetween }),
      }}
    >
      {auditTrailSensitiveChangeMessages[
        fieldName as AUDIT_EVENT_SENSITIVE_FIELDS
      ] ? (
        formatMessage(
          auditTrailSensitiveChangeMessages[
            fieldName as AUDIT_EVENT_SENSITIVE_FIELDS
          ],
        )
      ) : (
        <>
          <span style={{ fontWeight: 600 }}>
            {cases.sentenceCase(fieldName)}:
          </span>
          <AuditTrailStateDiffRowContentWrapper
            style={{
              ...(isLargeDiff
                ? {
                    textAlign: 'left',
                    justifyContent: 'flex-start',
                    flexDirection: 'column',
                  }
                : {
                    textAlign: 'right',
                    justifyContent: 'flex-end',
                    flexDirection: 'row',
                  }),
            }}
          >
            {isLargeDiff && diffRows ? (
              <ChangeBlock
                changes={diffRows}
                isBefore={isBefore}
                otherValue={updatedOtherValue}
              />
            ) : (
              <span
                style={{
                  borderRadius: '0.2em',
                  padding: `0 ${StyleUtils.Spacing.xs}`,
                  marginRight: isBefore ? StyleUtils.Spacing.md : undefined,
                  backgroundColor: isBefore
                    ? transparentize(0.65, theme.colors.negativeHighlight)
                    : transparentize(0.65, theme.colors.positiveHighlight),
                }}
              >
                {typeof value === 'boolean'
                  ? value.toString().toUpperCase()
                  : (value as any) ?? '--'}
              </span>
            )}
          </AuditTrailStateDiffRowContentWrapper>
        </>
      )}
    </AuditTrailStateDiffRowWrapper>
  );
};

/**
 * gets the entries for the event state, and sorts and filters the event state rows
 *
 * @param state - the state to break down
 * @param isCreated - is the event a create event
 * @param includedFields - the fields to include in the diff
 * @param allKeys - the list of all keys in the before and after states
 * @returns the sorted and filtered entries
 */
const sortAndFilterRows = (
  state: any,
  isCreated: boolean,
  includedFields: string[] | undefined,
  allKeys: string[],
): {
  /** state row key */
  key: string;
  /** state row value */
  value: any;
}[] =>
  allKeys
    .map((key) => ({ key, value: (state ?? {})[key] }))
    .filter(
      // filter out null and undefined values for create events
      ({ key, value }) =>
        (!isCreated || (value !== undefined && value !== null)) &&
        (!includedFields || includedFields.includes(key)),
    )
    .sort(({ key: keyA }, { key: keyB }) => keyA.localeCompare(keyB));

/**
 * AuditTrailStateListItem
 */
export const AuditTrailStateListItem: React.FC<
  AuditTrailStateListItemProps
> = ({ event }) => {
  const formatAuditTrailMessage = useFormatAuditTrailMessage();

  const isDeleted = event.beforeState && !event.afterState;
  const isCreated = !event.beforeState && event.afterState;
  const showDiffAlways = event.code === AuditEventCode.Message;
  const createdAt = new Date((event as any).createdAt);
  const hasMoreDetails = !isCreated && !isDeleted;
  const allKeys = useMemo(
    () =>
      uniq([
        ...Object.keys(event.beforeState ?? {}),
        ...Object.keys(event.afterState ?? {}),
      ]),
    [event.beforeState, event.afterState],
  );
  const sortedBeforeStateEntries = useMemo(
    () =>
      sortAndFilterRows(
        event.beforeState,
        isCreated,
        event.code === AuditEventCode.Message
          ? ['defaultMessage', ...Object.values(LanguageKey)]
          : undefined,
        allKeys,
      ),
    [event.beforeState, allKeys],
  );
  const sortedAfterStateEntries = useMemo(
    () =>
      sortAndFilterRows(
        event.afterState,
        isCreated,
        event.code === AuditEventCode.Message
          ? ['defaultMessage', ...Object.values(LanguageKey)]
          : undefined,
        allKeys,
      ),
    [event.afterState, allKeys],
  );

  const hasBothStates = event.beforeState && event.afterState;
  return (
    <AuditTrailListItemLayout
      createdAt={createdAt}
      actorTitle={event.additionalContext.actorTitle}
      actorProfilePictureUrl={event.actorUser?.profilePicture}
      apiKeyId={event.actorApiKeyId}
      eventTitle={formatAuditTrailMessage(event, true)}
      hasMoreDetails={hasMoreDetails || showDiffAlways}
      details={
        <div
          onClick={(event) => event.stopPropagation()}
          style={{
            cursor: 'default',
            ...(hasBothStates
              ? {
                  display: 'grid',
                  gridTemplateColumns: `minmax(0,1fr) minmax(0,1fr)`,
                }
              : StyleUtils.Flex.Column.base),
          }}
        >
          {event.beforeState && (
            <FlexColumn>
              <AuditTrailStateDiffWrapper>
                {sortedBeforeStateEntries.map(({ key, value }, i) => (
                  <AuditTrailStateValueRow
                    key={key}
                    fieldName={key}
                    value={value}
                    otherValue={sortedAfterStateEntries[i]?.value}
                    isBefore
                  />
                ))}
              </AuditTrailStateDiffWrapper>
            </FlexColumn>
          )}
          {event.afterState && (
            <FlexColumn>
              <AuditTrailStateDiffWrapper>
                {sortedAfterStateEntries.map(({ key, value }, i) => (
                  <AuditTrailStateValueRow
                    key={key}
                    fieldName={key}
                    value={value}
                    otherValue={sortedBeforeStateEntries[i]?.value}
                    isBefore={false}
                  />
                ))}
              </AuditTrailStateDiffWrapper>
            </FlexColumn>
          )}
        </div>
      }
    />
  );
};
