import { useInlineInput } from '@main/ad-core-components';
import { endpoints } from '@main/code-scanning-types';
import { buildUseMutation } from '@main/core-ui';
import type { ID } from '@main/schema-utils';
import React from 'react';

import {
  SelectedSoftwareDevelopmentKit,
  SelectSoftwareDevelopmentKitProps,
  SelectSoftwareDevelopmentKits,
} from '../components';

interface InlineCodePackagesSoftwareDevelopmentKitsUpdateProps
  extends Omit<
    SelectSoftwareDevelopmentKitProps,
    'onChange' | 'onBlur' | 'value'
  > {
  /** The ID of the parent codePackage for this cell */
  id: ID<'codePackage'>;
  /** The value for the cell */
  value: SelectedSoftwareDevelopmentKit[];
}

const useUpdateCodePackages = buildUseMutation(endpoints.updateCodePackages);

export const InlineCodePackagesSoftwareDevelopmentKitsUpdate: React.FC<
  InlineCodePackagesSoftwareDevelopmentKitsUpdateProps
> = ({ id, value: initialValue, isLoading, ...props }) => {
  const [updateCodePackages, { loading }] = useUpdateCodePackages();

  const { value, setValue, onBlur } = useInlineInput<
    SelectedSoftwareDevelopmentKit[] | null
  >({
    initialValue,
    onUpdate: (values) =>
      updateCodePackages({
        variables: {
          input: {
            codePackages: [
              {
                id,
                softwareDevelopmentKitIds: (values || []).map(({ id }) => id),
              },
            ],
          },
        },
      }).then(({ data, errors }) => ({
        data: (data?.codePackages[0]?.softwareDevelopmentKits || []).map(
          ({ softwareDevelopmentKit }) => softwareDevelopmentKit,
        ),
        errors,
      })),
  });

  return (
    <SelectSoftwareDevelopmentKits
      value={value || []}
      isLoading={loading || isLoading}
      onChange={(newValues) => {
        setValue(newValues);
      }}
      onBlur={onBlur}
      {...props}
    />
  );
};
