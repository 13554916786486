import styled from 'styled-components';

export const RangeLabel = styled.span`
  font-size: 12px;
  font-weight: 600;
`;

export const StyledRangeInput = styled.input`
  width: 100%;
`;
