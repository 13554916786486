/* eslint-disable max-lines */
import {
  Alert,
  FlexColumnCenterBoth,
  FlexRowCenterBoth,
  FlexRowCenterHorizontal,
  StyleUtils,
} from '@main/core-ui';
import { DefinedMessage } from '@transcend-io/internationalization';
import React, { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { CellProps, Column, HeaderProps } from 'react-table';

import { DocumentationReferences } from '../DocumentationReferences';
import { NotAuthorized } from '../NotAuthorized';
import { Table } from '../Table';
import { TableToolbar } from '../TableToolBar';
import { HeaderCheckbox, HideableCheckbox, TableWrapper } from '../wrappers';
import { defineDynamicColumn } from './columns';
import { DeleteSelectedRowsButton } from './DeleteSelectedRowsButton';
import { DownloadErroredRows } from './DownloadErroredRows';
import {
  DownloadSampleCsvButton,
  DownloadSampleCsvButtonProps,
} from './DownloadSampleCsvButton';
import { uploadCsvMessages } from './messages';
import { PendingRowUploadStatus } from './PendingRowStatus';
import { ResetUploadedRows } from './ResetUploadedRows';
import { SubmitPendingRows, SubmitPendingRowsProps } from './SubmitPendingRows';
import {
  CsvColumn,
  CsvUploadDefaultColumnName,
  RowUploadResult,
  RowUploadStatus,
  RowWithUploadStatus,
} from './types';
import {
  UploadFromCsvButton,
  UploadFromCsvButtonProps,
} from './UploadFromCsvButton';
import {
  DescriptionWrapper,
  ImportedRowsTableWrapper,
  TitleContainerWrapper,
} from './wrappers';

interface UploadCsvTableProps<T extends {}> {
  /** The title of the table */
  title: string;
  /** a description to display on the modals initial view */
  description?: string;
  /** The URL to the documentation */
  documentationUrl?: string;
  /** props for the final save button when uploading all valid rows in a csv */
  submitPendingRowsProps: Omit<SubmitPendingRowsProps<T>, 'validPendingRows'>;
  /** props for the initial upload of a csv file to parse and display the data in a table */
  uploadCsvFileButtonProps?: Omit<
    UploadFromCsvButtonProps<T>,
    'expectedColumns' | 'setFileSizeError'
  >;
  /** props for the download sample csv button */
  downloadSampleCsvButtonProps?: Omit<
    DownloadSampleCsvButtonProps<T>,
    'expectedColumns'
  >;
  /** The message to display when the upload is successful */
  uploadSuccessfulMessage?: DefinedMessage;
  /** The message to display for the record count */
  recordCountMessage: DefinedMessage;
  /** If the user is authorized to upload */
  authorized?: boolean;
  /** If authorization is required */
  authRequired?: boolean;
  /** Row upload statuses keyed by the id of the row */
  progressStatusMap?: Record<string, RowUploadResult>;
  /** map of expected columns, keyed by name, containing custom validation logic */
  expectedColumns: Record<keyof T, CsvColumn<T>>;
  /** The page size for the table */
  pageSize?: number;
  /** The maximum height of a row */
  maxRowHeight?: number;
}

/**
 * Table of rows parsed from a csv that are pending upload
 */
export function UploadCsvTable<T extends {}>({
  title,
  documentationUrl,
  recordCountMessage,
  submitPendingRowsProps,
  uploadCsvFileButtonProps,
  downloadSampleCsvButtonProps,
  authorized,
  authRequired,
  progressStatusMap,
  expectedColumns,
  uploadSuccessfulMessage,
  description,
  pageSize = 10,
  maxRowHeight,
}: UploadCsvTableProps<T>): JSX.Element {
  // table states
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState('');
  const [isCompleted, setIsCompleted] = useState(false);
  const [selectedRows, setSelectedRows] = useState<string[]>([]);
  const [pendingRows, setPendingRows] = useState<RowWithUploadStatus<T>[]>([]);
  const [validPendingRows, setValidPendingRows] = useState<
    RowWithUploadStatus<T>[]
  >([]);
  const [errorPendingRows, setErrorPendingRows] = useState<
    RowWithUploadStatus<T>[]
  >([]);
  const [fileSizeError, setFileSizeError] = useState(false);

  const { formatMessage } = useIntl();

  // Filter the rows in memory
  const filteredRows = useMemo(() => {
    const lowerText = search.toLowerCase();
    return (
      !search
        ? pendingRows
        : pendingRows.filter((row) =>
            Object.values(row).find(
              (cellValue) =>
                !!cellValue &&
                typeof cellValue === 'string' &&
                cellValue.toLowerCase().includes(lowerText),
            ),
          )
    ).slice((currentPage - 1) * pageSize, currentPage * pageSize);
  }, [pendingRows, search, currentPage]);

  const columns = useMemo(() => {
    setIsCompleted(false);
    // Construct the columns based on unique data
    const firstRow = pendingRows[0];
    const expectedColumnsKeys = Object.keys(expectedColumns);
    const firstRowKeys = Object.keys(firstRow ?? {});

    return !firstRow
      ? []
      : [
          {
            accessor: CsvUploadDefaultColumnName.Id,
            disableResizing: true,
            width: 40,
            minWidth: 40,
            Header: ({ data }: HeaderProps<RowWithUploadStatus<T>>) => {
              const ids: string[] = data.map(
                (row: RowWithUploadStatus<T>) => row.id,
              );
              return (
                <FlexRowCenterBoth style={{ height: '100%' }}>
                  <HeaderCheckbox
                    checked={
                      selectedRows.length === ids.length &&
                      selectedRows.length > 0
                    }
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                      setSelectedRows(
                        e.target.checked
                          ? ids
                          : selectedRows.filter(
                              (selectedId) => !ids.includes(selectedId),
                            ),
                      );
                    }}
                  />
                </FlexRowCenterBoth>
              );
            },
            Cell: ({ value: id }: CellProps<RowWithUploadStatus<T>>) => (
              <FlexRowCenterBoth key={id} style={{ alignItems: 'flex-start' }}>
                <HideableCheckbox
                  checked={selectedRows.includes(id)}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    setSelectedRows(
                      e.target.checked
                        ? [...selectedRows, id]
                        : selectedRows.filter((row) => row !== id),
                    );
                  }}
                />
              </FlexRowCenterBoth>
            ),
          },
          {
            accessor: CsvUploadDefaultColumnName.UploadStatus,
            Header: () => (
              <FlexRowCenterBoth style={{ height: '100%' }}>
                <FormattedMessage {...uploadCsvMessages.status} />
              </FlexRowCenterBoth>
            ),
            Cell: ({
              value,
              row,
            }: CellProps<RowWithUploadStatus<T>, RowUploadStatus>) => (
              <FlexRowCenterBoth>
                <PendingRowUploadStatus
                  errorDetails={row.original.errorDetails}
                  errorMessage={row.original.errorMessage}
                  uploadStatus={value}
                />
              </FlexRowCenterBoth>
            ),
            minWidth: 240,
          },
          ...[
            ...expectedColumnsKeys,
            // rest of the columns that are not expected
            ...firstRowKeys.filter((k) => !expectedColumnsKeys.includes(k)),
          ]
            .filter(
              (k) =>
                !Object.values(CsvUploadDefaultColumnName).includes(k as any),
            )
            .map((colHeader) => {
              const cols: CsvColumn<T>[] = Object.values(expectedColumns);
              const colNames = cols.map(
                ({ columnName }) => columnName as string,
              );

              const hasInvalidCell =
                colHeader in firstRow.columnIsValidMap
                  ? pendingRows.some((row) => !row.columnIsValidMap[colHeader])
                  : !!expectedColumns[colHeader]?.required;

              return defineDynamicColumn<T>(
                colHeader,
                colNames,
                hasInvalidCell,
                maxRowHeight,
              );
            }),
        ];
  }, [selectedRows, expectedColumns, pendingRows]) as Column<
    RowWithUploadStatus<T>
  >[];

  const handleParseComplete = (results: RowWithUploadStatus<T>[]): void => {
    setPendingRows(results);
  };

  useEffect(() => {
    // every time the progress status map updates (upload in is progress)
    // update the list of pending rows to ensure that the statuses are updated in real time
    setPendingRows((rows) =>
      rows.map((row) => ({
        ...row,
        ...(progressStatusMap
          ? {
              uploadStatus:
                progressStatusMap[row.id]?.uploadStatus || row.uploadStatus,
              errorMessage:
                progressStatusMap[row.id]?.errorMessage || row.errorMessage,
            }
          : {}),
      })),
    );
  }, [progressStatusMap]);

  useEffect(() => {
    // every time the list of pending rows updates (deletion, etc.)
    // update the list of valid/error rows to ensure they are removed as well
    setValidPendingRows(() =>
      pendingRows.filter(
        (item) => item.uploadStatus === RowUploadStatus.Pending,
      ),
    );
    setErrorPendingRows(() =>
      pendingRows.filter((item) => item.uploadStatus === RowUploadStatus.Error),
    );
  }, [pendingRows]);

  if (authRequired && !authorized) {
    return <NotAuthorized />;
  }

  return (
    <FlexColumnCenterBoth
      style={{
        padding: pendingRows.length === 0 ? '3em' : undefined,
      }}
    >
      <TitleContainerWrapper>
        <h3 style={{ marginRight: StyleUtils.Spacing.xs, fontWeight: 600 }}>
          {title}
        </h3>
        {!!documentationUrl && (
          <DocumentationReferences references={[documentationUrl]} />
        )}
      </TitleContainerWrapper>
      {pendingRows.length === 0 ? (
        <>
          <DescriptionWrapper>
            {description ||
              formatMessage(uploadCsvMessages.description, {
                documentationSentence: documentationUrl
                  ? 'Follow our documentation instructions on how to do so. '
                  : '',
              })}
          </DescriptionWrapper>
          <FlexRowCenterBoth
            style={{
              gap: StyleUtils.Spacing.md,
              marginTop: StyleUtils.Spacing.xl,
              minWidth: '14em',
            }}
          >
            <UploadFromCsvButton<T>
              key="add-record"
              transformRows={uploadCsvFileButtonProps?.transformRows}
              onParseComplete={handleParseComplete}
              allLoading={uploadCsvFileButtonProps?.allLoading}
              allErrors={uploadCsvFileButtonProps?.allErrors}
              expectedColumns={expectedColumns}
              setFileSizeError={setFileSizeError}
            />
            <DownloadSampleCsvButton<T>
              expectedColumns={Object.values(expectedColumns)}
              requiredColumnsOnly={
                downloadSampleCsvButtonProps?.requiredColumnsOnly
              }
              buttonLabel={downloadSampleCsvButtonProps?.buttonLabel}
              csvFileName={downloadSampleCsvButtonProps?.csvFileName}
            />
          </FlexRowCenterBoth>
        </>
      ) : (
        <ImportedRowsTableWrapper>
          <TableToolbar
            searchValue={search}
            onSearch={(searchValue) => {
              setSearch(searchValue);
            }}
            rightAlignedContent={[
              ...(errorPendingRows.length > 0
                ? [
                    <DownloadErroredRows<T>
                      erroredRows={errorPendingRows}
                      key="download-errored-rows"
                    />,
                  ]
                : []),
              ...(validPendingRows.length > 0
                ? [
                    <SubmitPendingRows<T>
                      key="upload-pending-rows"
                      {...submitPendingRowsProps}
                      onSubmit={submitPendingRowsProps.onSubmit}
                      validPendingRows={validPendingRows}
                      onSuccess={() => setIsCompleted(true)}
                    />,
                  ]
                : []),
              ...(selectedRows.length > 0
                ? [
                    <DeleteSelectedRowsButton
                      selectedRows={selectedRows}
                      onDelete={() => {
                        setSelectedRows([]);
                        setPendingRows((prev) =>
                          prev.filter(
                            (item) => !selectedRows.includes(item.id),
                          ),
                        );
                      }}
                      key="delete-selected-rows"
                    />,
                  ]
                : []),
              <ResetUploadedRows
                onReset={() => {
                  setSelectedRows([]);
                  setPendingRows([]);
                }}
                key="reset-uploaded-rows"
              />,
              <UploadFromCsvButton<T>
                key="table-upload-button"
                transformRows={uploadCsvFileButtonProps?.transformRows}
                onParseComplete={handleParseComplete}
                allLoading={uploadCsvFileButtonProps?.allLoading}
                allErrors={uploadCsvFileButtonProps?.allErrors}
                expectedColumns={expectedColumns}
                setFileSizeError={setFileSizeError}
              />,
            ]}
          />
          <Table<RowWithUploadStatus<T>>
            columns={columns}
            data={filteredRows}
            loading={false}
            paginationOptions={{
              paginationResult: {
                currentPage,
                setCurrentPage,
                useMaxPage: () => null,
                pageSize,
                setPageSize: () => null,
                tableOptions: {
                  manualPagination: true,
                  useControlledState: (state: any) =>
                    useMemo(
                      () => ({
                        ...state,
                        pageSize,
                        pageIndex: currentPage - 1,
                      }),
                      [state, currentPage],
                    ),
                },
              },
              recordCount: pendingRows.length,
              recordCountMessage,
              hidePageSizeSelector: true,
            }}
            viewWrapper={TableWrapper}
          />
          {isCompleted && (
            <FlexRowCenterHorizontal>
              <Alert variant="success">
                {formatMessage(
                  uploadSuccessfulMessage || uploadCsvMessages.uploadCompleted,
                )}
              </Alert>
            </FlexRowCenterHorizontal>
          )}
        </ImportedRowsTableWrapper>
      )}
      {fileSizeError && (
        <Alert
          variant="danger"
          style={{ marginTop: StyleUtils.Spacing.l, width: '25rem' }}
        >
          {formatMessage(uploadCsvMessages.tooManyRows)}
        </Alert>
      )}
    </FlexColumnCenterBoth>
  );
}
/* eslint-enable max-lines */
