import { AdminDashboardPrivateMiscPath } from '@main/access-control-types';
import { selectUser } from '@main/admin-dashboard/src/Auth/App/selectors';
import { parseRedirectTo } from '@main/admin-dashboard/src/Auth/Login/parseRedirectTo';
import {
  FlexRowCenterHorizontal,
  LoadingSpinner,
  useQueryParams,
} from '@main/core-ui';
import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';

import { selectIsLoadingUser } from '../selectors';
import Wrapper from './Wrapper';

/**
 * A route for a url that should only be accessible when not logged in
 */
export const PublicOnlyRouteWrapper: React.FC = ({ children }) => {
  const user = useSelector(selectUser);
  const isLoadingUser = useSelector(selectIsLoadingUser);
  const { redirectTo = AdminDashboardPrivateMiscPath.ActionItems } =
    useQueryParams();
  return user ? (
    isLoadingUser ? (
      <FlexRowCenterHorizontal>
        <LoadingSpinner />
      </FlexRowCenterHorizontal>
    ) : (
      <Navigate to={parseRedirectTo(redirectTo)} />
    )
  ) : (
    <Wrapper>{children}</Wrapper>
  );
};
