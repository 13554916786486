import { useEffect, useState } from 'react';

/**
 * Returns false until a specified timeout is reached, then returns true
 *
 * @param delayMs - the amount of time to wait
 * @returns delay props
 */
export const useMountDelay = (
  delayMs: number,
): {
  /** is the delay complete? */
  isComplete: boolean;
  /** function to skip the remaining delay */
  skipDelay: () => void;
} => {
  const [isComplete, setIsComplete] = useState(false);
  const [timeoutHandle, setTimeoutHandle] = useState<number>();
  const clearTimeoutHandle = (): void => window.clearTimeout(timeoutHandle);

  useEffect(() => {
    setTimeoutHandle(window.setTimeout(() => setIsComplete(true), delayMs));

    return clearTimeoutHandle;
  }, []);

  return {
    isComplete,
    skipDelay: () => {
      setIsComplete(true);
      clearTimeoutHandle();
    },
  };
};
