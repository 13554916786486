import React from 'react';

import { TipTapNodeType } from './enums';
import { extensionIsEnabled } from './helpers';
import { toolbarMessages } from './messages';
import { ToolbarToggle } from './ToolbarToggle';
import { ToolbarButtonGroupProps } from './types';

export const ListButtonGroup: React.FC<ToolbarButtonGroupProps> = ({
  editor,
}) => (
  <>
    {extensionIsEnabled(editor, TipTapNodeType.BulletList) && (
      <ToolbarToggle
        title={toolbarMessages.bulletedList}
        pressed={editor.isActive(TipTapNodeType.BulletList)}
        onPressedChange={() => editor.chain().focus().toggleBulletList().run()}
        icon="bulleted-list"
        disabled={false}
      />
    )}

    {extensionIsEnabled(editor, TipTapNodeType.OrderedList) && (
      <ToolbarToggle
        title={toolbarMessages.orderedList}
        pressed={editor.isActive(TipTapNodeType.OrderedList)}
        onPressedChange={() => editor.chain().focus().toggleOrderedList().run()}
        icon="ordered-list"
        disabled={false}
      />
    )}
  </>
);
