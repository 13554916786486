import { FilterConfigItem } from '@main/ad-core-components';
import {
  SelectContracts,
  SelectedContract,
} from '@main/admin-dashboard/src/ContractScanning/components/SelectContracts';
import { endpoints } from '@main/contract-scanning-types';
import { buildUseLazyQuery } from '@main/core-ui';
import { ID } from '@main/schema-utils';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { MessageDescriptor } from 'react-intl';

import { filterManagerHookMessages } from './messages';

/**
 * Filters definition with property for contract values specified
 */
type ContractEnrichedFilters = Record<string, unknown> &
  Record<
    /**
     * Contract values that are currently filtering the table
     */
    string,
    SelectedContract[] | undefined
  >;

const useLazyContracts = buildUseLazyQuery(endpoints.contracts);

export const useContractFilters = <
  T extends Record<string, unknown>,
  E extends ContractEnrichedFilters,
>({
  contractIdsFilterKey = 'contractIds',
  filters,
  setFilters,
  label,
  enrichedContractKey = 'contracts',
}: {
  /** The property name of the filter for contract ids */
  contractIdsFilterKey?: keyof T;
  /** The currently applied filters */
  filters?: T;
  /** Callback when the filters are changed */
  setFilters: (filters: T) => void;
  /** Label for the filter menu */
  label: string | MessageDescriptor;
  /** The key to use if more than one useContractFilters is used in a FilterManager */
  enrichedContractKey?: keyof E;
}): {
  /**  The contract keys enabled on this table */
  selectedContracts: SelectedContract[];
  /** The filter configuration to be passed to the filter manager */
  contractFiltersConfig: FilterConfigItem<E>;
  /** Callback for when the filter is cleared in the filter manager */
  clearContractFilters: (key: Extract<keyof E, string>) => void;
} => {
  const getSelectedContracts = useLazyContracts();
  const [selectedContracts, setSelectedContracts] = useState<
    SelectedContract[]
  >([]);
  const [fetchingContracts, setFetchingContracts] = useState(false);

  // Populate initial complex filter values
  useEffect(() => {
    if (filters) {
      const contractIds = (filters[contractIdsFilterKey] ??
        []) as ID<'contract'>[];

      const filtersAndEnrichedFiltersMatch =
        contractIds.sort().join() ===
        selectedContracts
          .map(({ id }) => id)
          .sort()
          .join();

      if (contractIds.length === 0 && selectedContracts.length > 0) {
        setSelectedContracts([]);
      } else if (!filtersAndEnrichedFiltersMatch && !fetchingContracts) {
        // Prevent over-firing this setter while data is still being fetched
        setFetchingContracts(true);
        getSelectedContracts({ filterBy: { ids: contractIds } })
          .then(({ data }) => {
            setSelectedContracts(data.nodes);
          })
          .finally(() => {
            setFetchingContracts(false);
          });
      }
    }
  }, [
    filters,
    fetchingContracts,
    getSelectedContracts,
    selectedContracts.length,
    contractIdsFilterKey,
    selectedContracts,
  ]);

  const contractFiltersConfig = useMemo(
    () =>
      ({
        filterKey: enrichedContractKey,
        label,
        pillOptions: {
          label: ({
            filterValues: { [enrichedContractKey]: contracts = [] },
            index = 0,
          }) => contracts[index]?.name,
        },
        filter: (
          <SelectContracts
            isMulti
            placeholderDescriptor={
              filterManagerHookMessages.contractsPlaceholder
            }
            menuPosition="absolute"
            value={selectedContracts}
            showOutline={false}
            onChange={(contracts) => {
              setSelectedContracts([...contracts]);
              setFilters({
                ...filters,
                [contractIdsFilterKey]: contracts.map(({ id }) => id),
              } as T);
            }}
          />
        ),
      }) as FilterConfigItem<ContractEnrichedFilters>,
    [
      enrichedContractKey,
      label,
      selectedContracts,
      filters,
      contractIdsFilterKey,
    ],
  );

  const clearContractFilters = useCallback(
    (key) => {
      if (key === enrichedContractKey) {
        setFilters({
          ...filters,
          [contractIdsFilterKey]: [],
        } as T);
        setSelectedContracts([]);
      }
    },
    [enrichedContractKey, filters, contractIdsFilterKey],
  );

  return {
    selectedContracts,
    contractFiltersConfig,
    clearContractFilters,
  };
};
