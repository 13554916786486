import { createLoadable } from '@main/core-ui';

// Load the component dynamically
export const ApprovedCookies = createLoadable({
  loader: () => import('./ApprovedCookies'),
});

export const JunkCookies = createLoadable({
  loader: () => import('./JunkCookies'),
});

export const TriageCookies = createLoadable({
  loader: () => import('./TriageCookies'),
});
