/* eslint-disable max-lines */
import { defineMessages } from '@transcend-io/internationalization';
import {
  ActionItemCode,
  ActionItemPriorityOverride,
} from '@transcend-io/privacy-types';

export const actionItemsTableMessages = defineMessages(
  'admin-dashboard.Auth.ActionItems.ActionItemsTable.actionItemsTable',
  {
    task: {
      defaultMessage: 'Tasks',
    },
    count: {
      defaultMessage: 'Counts',
    },
    teams: {
      defaultMessage: 'Assignees (Teams)',
    },
    users: {
      defaultMessage: 'Assignees (Users)',
    },
    dueDate: {
      defaultMessage: 'Due Date',
    },
    priority: {
      defaultMessage: 'Priority',
    },
    documentationLink: {
      defaultMessage: 'Documentation',
    },
    type: {
      defaultMessage: 'Type',
    },
    startDueDate: {
      defaultMessage: 'Due Date (Start)',
    },
    endDueDate: {
      defaultMessage: 'Due Date (End)',
    },
    resolvedLabel: {
      defaultMessage: 'Show Resolved',
    },
    resolvedLabelYes: {
      defaultMessage: 'Yes',
    },
    resolvedLabelNo: {
      defaultMessage: 'No',
    },
    exportToCsv: {
      defaultMessage: 'Export to CSV',
    },
    filtersMessage: {
      defaultMessage: 'Click to filter action items',
    },
    viewMore: {
      defaultMessage: 'View More',
    },
    viewDetails: {
      defaultMessage: 'View Details',
    },
    none: {
      defaultMessage: 'None',
    },
    title: {
      defaultMessage: 'Title',
    },
    parentTitle: {
      defaultMessage: 'Parent Title',
    },
    parentType: {
      defaultMessage: 'Parent Type',
    },
    noTeam: {
      defaultMessage: 'You are not assigned to any teams',
    },
    markResolved: {
      defaultMessage: 'Mark Resolved',
    },
    markUnresolved: {
      defaultMessage: 'Mark Unresolved',
    },
    itemOverdue: {
      defaultMessage: 'Action Item overdue',
    },
    multipleNotes: {
      defaultMessage: 'Multiple notes...',
    },
    notes: {
      defaultMessage: 'Notes',
    },
    multipleLinks: {
      defaultMessage: 'Multiple links...',
    },
    links: {
      defaultMessage: 'Links',
    },
    dataSilo: {
      defaultMessage: 'Data Silo',
    },
  },
);

export const actionItemsTableCSVMessages = defineMessages(
  'admin-dashboard.Auth.ActionItems.ActionItemsTable.actionItemsTableCSV',
  {
    ids: {
      defaultMessage: 'ids',
    },
    type: {
      defaultMessage: 'type',
    },
    teams: {
      defaultMessage: 'teams',
    },
    users: {
      defaultMessage: 'users',
    },
    dueDate: {
      defaultMessage: 'dueDate',
    },
    priority: {
      defaultMessage: 'priority',
    },
    resolved: {
      defaultMessage: 'resolved',
    },
    documentationLink: {
      defaultMessage: 'documentationLink',
    },
  },
);

export const actionItemsPriorityMessages =
  defineMessages<ActionItemPriorityOverride>(
    'admin-dashboard.Auth.ActionItems.ActionItemsTable.actionItemsPriority',
    {
      [ActionItemPriorityOverride.WontDo]: {
        defaultMessage: "Won't Do",
      },
      [ActionItemPriorityOverride.Low]: {
        defaultMessage: 'Low',
      },
      [ActionItemPriorityOverride.Medium]: {
        defaultMessage: 'Medium',
      },
      [ActionItemPriorityOverride.High]: {
        defaultMessage: 'High',
      },
      [ActionItemPriorityOverride.Critical]: {
        defaultMessage: 'Critical',
      },
    },
  );

export const actionItemsTabsMessages = defineMessages(
  'admin-dashboard.Auth.ActionItems.ActionItemsTable.actionItemsTabs',
  {
    mine: {
      defaultMessage: 'Mine',
    },
    myTeam: {
      defaultMessage: 'My Team',
    },
    all: {
      defaultMessage: 'All',
    },
  },
);

export const actionItemRichDescriptions = defineMessages<
  ActionItemCode | 'default'
>(
  'admin-dashboard.Auth.ActionItems.ActionItemsTable.actionItemRichDescriptions',
  {
    default: {
      defaultMessage: 'Empty',
    },
    [ActionItemCode.ApiKeyStale]: {
      defaultMessage:
        '{count, plural, one {API key {title} needs to be updated} other {{prettyCount} API keys need to be updated}}',
    },
    [ActionItemCode.ActionMissingSilos]: {
      defaultMessage:
        '{count, plural, one {Action {title} Missing Silo} other {{prettyCount} Actions Missing Silos}}',
    },
    [ActionItemCode.AssessmentFormAssigned]: {
      defaultMessage:
        '{count, plural, one {{title} assessment assigned for completion} ' +
        'other {{prettyCount} assessments assigned for completion}}',
    },
    [ActionItemCode.AssessmentFormNeedsReview]: {
      defaultMessage:
        '{count, plural, one {Assessment {title} has been completed and needs review} ' +
        'other {{prettyCount} assessments have been completed and need review}}',
    },
    [ActionItemCode.DataSiloNeedsReconnect]: {
      defaultMessage:
        '{count, plural, one {Integration {title} needs to be reconnected or delegated for reconnection} ' +
        'other {{prettyCount} Integrations need to be reconnected or delegated for reconnection}}',
    },
    [ActionItemCode.DataSiloNotConfigured]: {
      defaultMessage:
        '{count, plural, one {Integration {title} needs to be configured or delegated for configuration} ' +
        'other {{prettyCount} Integrations need to be configured or delegated for configuration}}',
    },
    [ActionItemCode.DataSiloNeedsReconnectAssigned]: {
      defaultMessage:
        '{count, plural, one {Assigned Integration {title} needs reconnection} ' +
        'other {{prettyCount} Assigned Integrations need reconnection}}',
    },
    [ActionItemCode.DataSiloNotConfiguredAssigned]: {
      defaultMessage:
        '{count, plural, one {Integration {title} assigned for configuration} ' +
        'other {{prettyCount} Integrations assigned for configuration}}',
    },
    [ActionItemCode.DataSiloMissingIdentifiers]: {
      defaultMessage:
        '{count, plural, one {{title} is missing identifiers} ' +
        'other {{prettyCount} Data Silos are missing identifiers}}',
    },
    [ActionItemCode.LookupProcessesWithErrors]: {
      defaultMessage:
        '{count, plural, one {{title} integration has stalled while indexing for an {requestType} request} ' +
        'other {{prettyCount} integrations have stalled while indexing for requests}}',
    },
    [ActionItemCode.DataSilosNeedingManualEntry]: {
      defaultMessage:
        '{count, plural, one {{title} has requests that need manual processing} ' +
        'other {{prettyCount} integrations need privacy requests to be manually processed}}',
    },
    [ActionItemCode.ProfileDataPointStatus]: {
      defaultMessage:
        '{count, plural, one {Profile datapoint {title} has thrown an error} ' +
        'other {{prettyCount} profile datapoints have thrown errors}}',
    },
    [ActionItemCode.DataPoint]: {
      defaultMessage:
        '{count, plural, one {Integration {title} is configured for Data Access ' +
        'Requests and has datapoints missing the privacy center "Description" or "Data Collection Tag"} ' +
        'other {{prettyCount} Integrations configured for Data Access Requests with datapoints that are missing ' +
        'the privacy center "Description" or "Data Collection Tag"}}',
    },
    [ActionItemCode.DataPointMissingFields]: {
      defaultMessage:
        '{count, plural, one {Datapoint Object {title} for silo {parentTitle} has properties that are missing categories} ' +
        'other {{prettyCount} Datapoints have properties missing categories}}',
    },
    [ActionItemCode.RequestExpiry]: {
      defaultMessage:
        '{count, plural, one {Request {title} has expired} ' +
        'other {{prettyCount} requests have expired}}',
    },
    [ActionItemCode.RequestIdentifierNeedsVerification]: {
      defaultMessage:
        '{count, plural, one {Request Identifier {title} needs verification for {requestType} request} ' +
        'other {{prettyCount} request identifiers need verification}}',
    },
    [ActionItemCode.RequestActionableStatus]: {
      defaultMessage:
        '{count, plural, one {Request {title} needs approval to proceed} ' +
        'other {{prettyCount} requests need approval to proceed}}',
    },
    [ActionItemCode.RequestOnHold]: {
      defaultMessage:
        '{count, plural, one {Request {title} is On Hold and needs review} ' +
        'other {{prettyCount} requests are On Hold and need review}}',
    },
    [ActionItemCode.RequestDataSiloMarkedActionable]: {
      defaultMessage:
        '{count, plural, one {Request Data Silo {title} flagged for manual review} ' +
        'other {{prettyCount} request Data Silos flagged for manual review}}',
    },
    [ActionItemCode.CommunicationUnread]: {
      defaultMessage:
        '{count, plural, one {{title} has an unread request message} ' +
        'other {{prettyCount} unread request messages}}',
    },
    [ActionItemCode.DataSiloCommunicationUnread]: {
      defaultMessage:
        '{count, plural, one {{title} has an unread Data Silo message} ' +
        'other {{prettyCount} unread Data Silo messages}}',
    },
    [ActionItemCode.RequestDataSiloError]: {
      defaultMessage:
        '{count, plural, one {{title} has an error finding user data for a request} ' +
        'other {{prettyCount} data silos have errors finding user data for a request}}',
    },
    [ActionItemCode.RequestDataSiloFilesNeedReview]: {
      defaultMessage:
        '{count, plural, one {{title} has files that have been marked for review ' +
        'before an {requestType} request continues processing.} ' +
        'other {{prettyCount} data silos have files that have been marked for review}}',
    },
    [ActionItemCode.RequestEnricherError]: {
      defaultMessage:
        '{count, plural, one {Request {title} encountered an error running preflight checks} ' +
        'other {{prettyCount} requests have preflight checks with errors}}',
    },
    [ActionItemCode.RequestEnricherPersonNeedsManualEntry]: {
      defaultMessage:
        '{count, plural, one {Preflight {title} needs manual entry to continue} ' +
        'other {{prettyCount} preflight checks need manual entry to continue}}',
    },
    [ActionItemCode.UserAwaitingNotification]: {
      defaultMessage:
        '{count, plural, one {{title} is awaiting notification to start onboarding} ' +
        'other {{prettyCount} users awaiting notification to start onboarding}}',
    },
    [ActionItemCode.UserNeedsConfiguration]: {
      defaultMessage:
        '{count, plural, one {{title} requires permissions to use Transcend} ' +
        'other {{prettyCount} Users require permissions to use Transcend}}',
    },
    [ActionItemCode.RequestAssignedToUser]: {
      defaultMessage:
        '{count, plural, one {Request {title} assigned for processing} ' +
        'other {{prettyCount} requests assigned for processing}}',
    },
    [ActionItemCode.DataSiloNeedsTraining]: {
      defaultMessage:
        '{count, plural, one {{title} needs training on discovered datapoints} ' +
        'other {{prettyCount} data silos need training on discovered datapoints}}',
    },
    [ActionItemCode.DataFlowNeedsReview]: {
      defaultMessage:
        '{count, plural, one {Discovered data flow {title} needs review} ' +
        'other {{prettyCount} discovered data flows need review}}',
    },
    [ActionItemCode.DataFlowAssignedForReview]: {
      defaultMessage:
        '{count, plural, one {Data flow {title} assigned for review} ' +
        'other {{prettyCount} data flows assigned for review}}',
    },
    [ActionItemCode.CookieNeedsReview]: {
      defaultMessage:
        '{count, plural, one {Discovered cookie {title} needs review} ' +
        'other {{prettyCount} discovered cookies need review}}',
    },
    [ActionItemCode.CookieAssignedForReview]: {
      defaultMessage:
        '{count, plural, one {Cookie {title} assigned for review} ' +
        'other {{prettyCount} cookies assigned for review}}',
    },
    [ActionItemCode.ConsentManagerVersionUpgrade]: {
      defaultMessage:
        'Install Airgap consent manager version {latestAirgapVersion} update',
    },
    [ActionItemCode.BusinessEntityNeedsDocumentation]: {
      defaultMessage:
        '{count, plural, one {Business entity {title} needs documentation}' +
        'other {{prettyCount} business entities need documentation}}',
    },
    [ActionItemCode.SombraVersionUpgrade]: {
      defaultMessage: 'Install Sombra Security update',
    },
    [ActionItemCode.SombraNeedsKeyRotation]: {
      defaultMessage: 'Sombra keys expiring',
    },
    [ActionItemCode.PluginsWithErrors]: {
      defaultMessage:
        '{count, plural, one {Plugin for {title} has errors} ' +
        'other {{prettyCount} plugins have errors}}',
    },
    [ActionItemCode.Onboarding]: {
      defaultMessage: '{title}',
    },
    [ActionItemCode.DataPointDatabaseQueryNeedsApproval]: {
      defaultMessage:
        '{count, plural, one {Datapoint {title} from silo {parentTitle} has database queries that need approval} ' +
        'other {{prettyCount} datapoints have database queries that need approval}}',
    },
  },
);

export const actionItemFullDescriptions = defineMessages<ActionItemCode>(
  'admin-dashboard.Auth.ActionItems.ActionItemsTable.actionItemFullDescriptions',
  {
    [ActionItemCode.ApiKeyStale]: {
      defaultMessage:
        'This alert is generated for API keys that were created over 2 years ago. ' +
        'Regularly cycling your API keys reduces operating risk should a key ever become compromised. ' +
        'In order to resolve this issue, you should create a new API key under "Infrastructure -> API Keys", ' +
        'replace that API key in your code, and then remove the old API key once it is no longer in use.',
    },
    [ActionItemCode.ActionMissingSilos]: {
      defaultMessage:
        'This alert is generated when you have a DSR Automation Workflow that has been set live, ' +
        'but there are no integrations that have been configured to be run for that workflow. ' +
        'The result of this is that if a data subject were to submit a request, ' +
        'nothing would happen and the request would never be closed. ' +
        'In order to resolve this issue, you should go to "Infrastructure -> Integrations", ' +
        'and configure at least one integration to run for this workflow.',
    },
    [ActionItemCode.AssessmentFormAssigned]: {
      defaultMessage:
        'This alert is generated when there are assessments assigned to an employee at your company. ' +
        'In order to resolve this alert, the employee assigned the assessment should fill out and submit said assessment.',
    },
    [ActionItemCode.AssessmentFormNeedsReview]: {
      defaultMessage:
        'This alert is generated when an assessment is completed and needs to be reviewed ' +
        'by the individual that is assigned to review that assessment. ' +
        'In order to resolve this alert, the reviewer of the assessment should review the completed assessment, ' +
        'and then approve it, reject it, or request changes.',
    },
    [ActionItemCode.DataSiloNeedsReconnect]: {
      defaultMessage:
        'This alert is generated when an integration disconnects and needs to be re-connected. ' +
        'It order to be resolve this alert, a system owner should re-authenticate the system by ' +
        'updating the integration credentials on the "Infrastructure -> Integration -> <Integration> -> Connection" page.',
    },
    [ActionItemCode.DataSiloNotConfigured]: {
      defaultMessage:
        'The data silo needs to be connected. It will not process requests until ' +
        'it is configured',
    },
    [ActionItemCode.DataSiloNeedsReconnectAssigned]: {
      defaultMessage:
        'The data silo is disconnected and is assigned. It needs to be reconnected in order ' +
        'for requests to continue processing',
    },
    [ActionItemCode.DataSiloNotConfiguredAssigned]: {
      defaultMessage:
        'The data silo needs to be connected and is assigned. It will not process requests until ' +
        'it is configured',
    },
    [ActionItemCode.DataSiloMissingIdentifiers]: {
      defaultMessage:
        'The data silo has no identifiers assigned. It will not be able to process requests ' +
        'until it some identifiers are selected',
    },
    [ActionItemCode.LookupProcessesWithErrors]: {
      defaultMessage:
        'The integration has lookup processes that have errors while indexing. Data points that ' +
        'depend on the failing lookup processes will not be able to process until the lookup ' +
        'processes successfully finish indexing',
    },
    [ActionItemCode.DataSilosNeedingManualEntry]: {
      defaultMessage:
        'The integration has a requests that require manual data entry or approval. The requests ' +
        'will not finish executing until the manual work is finished',
    },
    [ActionItemCode.ProfileDataPointStatus]: {
      defaultMessage:
        'The Profile Datapoint has encountered an error while processing. Review the ' +
        'error to identify and fix the error or contact support for further assistance',
    },
    [ActionItemCode.DataPoint]: {
      defaultMessage:
        'The Integration is configured for Data Access Requests and has datapoints missing the privacy ' +
        'center "Description" or "Data Collection Tag". These fields are used to display ' +
        'the privacy center so without these fields the privacy center will not be accurate',
    },
    [ActionItemCode.DataPointMissingFields]: {
      defaultMessage:
        'The Datapoint object has properties that are not assigned any categories. These ' +
        'are useful for organizing and filtering your Data Map',
    },
    [ActionItemCode.RequestExpiry]: {
      defaultMessage:
        'The request has been processing for longer than the allowed time period. Failing ' +
        'to meet this timeline may result in compliance violations',
    },
    [ActionItemCode.RequestIdentifierNeedsVerification]: {
      defaultMessage:
        'The Request Identifier needs to be verified. This will block further request processing',
    },
    [ActionItemCode.RequestActionableStatus]: {
      defaultMessage:
        'The request needs to be approved before resuming processing',
    },
    [ActionItemCode.RequestOnHold]: {
      defaultMessage:
        'The request is marked as On Hold and needs to be reviewed before resuming processing',
    },
    [ActionItemCode.RequestDataSiloMarkedActionable]: {
      defaultMessage:
        'The Request Data Silo needs manual review before resuming processing',
    },
    [ActionItemCode.CommunicationUnread]: {
      defaultMessage:
        'The request has received an email response that is currently unread. Email ' +
        'responses can contain important information such as proof of identity and ' +
        'other compliance requests',
    },
    [ActionItemCode.DataSiloCommunicationUnread]: {
      defaultMessage:
        'The request data silo has received an email response that is currently unread. ' +
        'Data Silo email responses can contain important information such as new identifiers ' +
        'or silo status updates',
    },
    [ActionItemCode.RequestDataSiloError]: {
      defaultMessage:
        'The Request Data Silo has encountered an error while servicing the request, which ' +
        'will block request completion until resolved and retried. Errors can be caused by ' +
        'service outages of the integration or misconfigured integrations',
    },
    [ActionItemCode.RequestDataSiloFilesNeedReview]: {
      defaultMessage:
        'The Request Data Silo has files that need to be manually reviewed before resuming ' +
        'processing',
    },
    [ActionItemCode.RequestEnricherError]: {
      defaultMessage:
        'The request preflight check encountered an error while enriching the supplied identifiers. ' +
        'Errors can be resolved manually and will block further request processing',
    },
    [ActionItemCode.RequestEnricherPersonNeedsManualEntry]: {
      defaultMessage:
        'The request preflight check needs data to be entered to complete enrichment for the request, ' +
        'which will block further request processing until it is completed',
    },
    [ActionItemCode.UserAwaitingNotification]: {
      defaultMessage:
        'The user has been added to Transcend, but has not yet been notified via email. ' +
        'In order for the user to be able to register their account and be able to login, ' +
        'the initial email must be sent',
    },
    [ActionItemCode.UserNeedsConfiguration]: {
      defaultMessage:
        'The user account has no permissions or team permissions assigned, which means ' +
        'they will not be able to use any features in Transcend. Either add the user to ' +
        'a team with permissions or add permissions to the user directly in order to allow ' +
        'the user to use Transcend',
    },
    [ActionItemCode.RequestAssignedToUser]: {
      defaultMessage: 'The open request is currently assigned to the user',
    },
    [ActionItemCode.DataSiloNeedsTraining]: {
      defaultMessage:
        'The data silo has discovered datapoints with unapproved category suggestions. Reviewing ' +
        'these suggestions will improve future classification accuracy',
    },
    [ActionItemCode.DataFlowNeedsReview]: {
      defaultMessage: 'The data flow needs review before it will be regulated',
    },
    [ActionItemCode.DataFlowAssignedForReview]: {
      defaultMessage:
        'The data flow needs review before it will be regulated and has been assigned ' +
        'to a user for review',
    },
    [ActionItemCode.CookieNeedsReview]: {
      defaultMessage: 'The cookie needs review before it will be regulated',
    },
    [ActionItemCode.CookieAssignedForReview]: {
      defaultMessage:
        'The cookie needs review before it will be regulated and has been assigned ' +
        'to a user for review',
    },
    [ActionItemCode.ConsentManagerVersionUpgrade]: {
      defaultMessage:
        'A new version of consent manager has been released with new features and compatibility ' +
        'updates. It is recommended that consent manager be updated once per quarter to ensure ' +
        'maximum compatibility with new browser APIs and to allow the latest features to be used',
    },
    [ActionItemCode.BusinessEntityNeedsDocumentation]: {
      defaultMessage:
        'The business entity is missing any of: Address, Headquarter Country, Headquarter ' +
        'Sub Division, Data Protection Officer Email, or Data Protection Officer Name',
    },
    [ActionItemCode.SombraVersionUpgrade]: {
      defaultMessage:
        'A new sombra version has been released with new features and compatibility ' +
        'updates. It is recommended that consent manager be updated once per quarter to ensure ' +
        'maximum compatibility with Transcend, to allow the latest features to be used, and ' +
        'to provide the most stable and consistent experience with Transcend',
    },
    [ActionItemCode.SombraNeedsKeyRotation]: {
      defaultMessage:
        'Sombra keys have not been rotated in over 2 years. Regularly cycling keys ' +
        'reduces operating risk should the keys ever become compromised',
    },
    [ActionItemCode.PluginsWithErrors]: {
      defaultMessage:
        'The plugin has encountered errors while processing in the background and ' +
        'should be manually review for misconfiguration',
    },
    [ActionItemCode.Onboarding]: {
      defaultMessage: 'The checklist item has not yet been marked as done',
    },
    [ActionItemCode.DataPointDatabaseQueryNeedsApproval]: {
      defaultMessage:
        'The generated datapoint query for a database integration needs approval before ' +
        'it can be used',
    },
  },
);

export const actionItemDocumentationMessages = defineMessages(
  'admin-dashboard.Auth.ActionItems.ActionItemsTable.actionItemDocumentation',
  {
    default: {
      defaultMessage: 'Documentation Link',
    },
    connectingSaasTools: {
      defaultMessage: 'Connecting Saas Tools',
    },
    triagingCookiesDataFlows: {
      defaultMessage: 'Guide to Triaging & Classifying Cookies and Data Flows',
    },
    creatingAnIntegration: {
      defaultMessage: 'Creating an Integration',
    },
    confirmingClassifications: {
      defaultMessage: 'Confirming Classifications',
    },
    configuringAnIntegration: {
      defaultMessage: 'Configuring an Integration',
    },
    viewStatusOfRequest: {
      defaultMessage: 'View the Status of a Request',
    },
    managingDataInventory: {
      defaultMessage: 'Managing data inventory',
    },
    dataInventory: {
      defaultMessage: 'Data Inventory',
    },
    accessControl: {
      defaultMessage: 'Access Control',
    },
    assessments: {
      defaultMessage: 'Assessments',
    },
    reviewRequest: {
      defaultMessage: 'Review and Approve a Request ',
    },
    sombraKeyRotations: {
      defaultMessage: 'Sombra Key Rotations',
    },
    deployingSombra: {
      defaultMessage: 'Deploying Sombra',
    },
    usingTheBulkRequestInterface: {
      defaultMessage: 'Using the Bulk Request Interface',
    },
    cancellingAPrivacyRequest: {
      defaultMessage: 'Cancelling a DSR',
    },
    endToEndEncryption: {
      defaultMessage: 'End-to-end Encryption in Transcend',
    },
    reviewDataFlows: {
      defaultMessage: 'Triaging & Classifying Data Flows',
    },
    reviewCookies: {
      defaultMessage: 'Triaging & Classifying Cookies',
    },
    dataSiloDiscovery: {
      defaultMessage: 'Silo Discovery',
    },
  },
);
/* eslint-enable max-lines */
