import { cases } from '@transcend-io/handlebars-utils';
import { useEffect, useState } from 'react';
import { defaultLocale, Locale } from 'react-day-picker';
import { useIntl } from 'react-intl';
/**
 * Get the locale data to localize the calendar
 */
export function useCalendarLocale({
  forceLocale,
}: {
  /** Optionally pass a locale, otherwise `useIntl().locale` will be used */
  forceLocale?: string;
}): Locale {
  const { locale: userConfiguredLocale } = useIntl();
  const locale = forceLocale || userConfiguredLocale;

  const [calendarLocaleData, setCalendarLocaleData] =
    useState<Locale>(defaultLocale);
  useEffect(() => {
    /**
     * Load the calendar translations for the current locale
     */
    async function getCalendarTranslations(): Promise<void> {
      if (!['en', 'en-US'].includes(locale)) {
        const localeDataset = await import('react-day-picker/locale');

        // Format the locale for the react-day-picker/locales dictionary
        const localeKey = cases.camelCase(locale);
        const topLocaleKey = locale.split('-')[0];
        const localeData =
          localeDataset[localeKey] || localeDataset[topLocaleKey];

        if (localeData) {
          setCalendarLocaleData(localeDataset[locale]);
        }
      }
    }
    getCalendarTranslations();
  });

  return calendarLocaleData;
}
