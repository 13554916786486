import { menuPortalStyles } from '@main/core-ui/src/RawPaginatedSelect/wrappers';
import { createGlobalStyle } from 'styled-components';

/**
 * Styles applied globally across the app.
 *
 * The amount of styling done here should be minimal
 */
export const AdminDashboardGlobalStyles = createGlobalStyle`
  html,
  body {
    height: 100%;
    font-family: neue-haas-grotesk-display, sans-serif;
    color: ${({ theme }) => theme.colors.lightPrimary};
    background-color: ${({ theme }) => theme.colors.white};
  }

  a {
    color: ${({ theme }) => theme.colors.transcend};
    font-weight: 600;
  }

  h1 {
    font-size: 38px;
    font-weight: 600;
    color: ${({ theme }) => theme.colors.black};
  }

  h2 {
    font-size: 24px;
    font-weight: 600;
    color: ${({ theme }) => theme.colors.black};
  }

  h3 {
    font-size: 18px;
    font-weight: 500;
    color: ${({ theme }) => theme.colors.black};
  }

  h4 {
    font-size: 14px;
  }

  p {
    color: ${({ theme }) => theme.colors.black};
  }

  div, b {
    font-weight: 500;
    color: ${({ theme }) => theme.colors.secondary};
  }

  #app {
    min-height: 100%;
  }

  hr {
    background-color: rgba(34, 34, 34, 0.0980392);
    margin: 12px 0;
    opacity: 1;
  }

  [contenteditable=true]:empty:before {
    content: attr(placeholder);
    display: block;
    color: ${({ theme }) => theme.colors.transcendNavy4};
  }

  .ant-select-dropdown {
    z-index: 1100 !important;
  }

  ${menuPortalStyles}
`;
