import { Icon, message } from '@main/core-ui';
import React from 'react';
import { useIntl } from 'react-intl';

import { Button, ButtonProps } from '../Button';
import { copyValueToClipboard } from './copyValueToClipboard';
import { copyMessages } from './messages';

export { copyValueToClipboard };

/**
 * Props for Copy
 */
export interface CopyProps extends ButtonProps {
  /** The item to copy */
  value: string;
  /** The display item if not the value */
  children?: React.ReactNode;
  /** Styles */
  style?: React.CSSProperties;
}

/**
 * For copying text
 *
 * ```tsx
 * <Copy value={'Text to copy'} />
 * <Copy value={'Text to copy'} >
 * Copy Me
 * </Copy>
 * ```
 */
export const Copy: React.FC<CopyProps> = ({
  children,
  value,
  style,
  iconOnly,
  ...rest
}) => {
  const { formatMessage } = useIntl();
  return (
    <Button
      variant="secondary"
      icon={<Icon type="copy" />}
      size="sm"
      style={{
        display: 'inline-flex',
        wordBreak: 'break-word',
        whiteSpace: 'normal',
        ...style,
      }}
      iconOnly={iconOnly}
      {...rest}
      onClick={() => {
        copyValueToClipboard(value);
        message.success(formatMessage(copyMessages.copied));
      }}
    >
      {children || (iconOnly ? undefined : value)}
    </Button>
  );
};
