import crypto from 'crypto-browserify';

import { ImmutableUrl } from '@main/immutable-url';
import {
  CONTENT_ENCRYPTION_ALGORITHM,
  DhEncryptionKeys,
  ECDH_CURVE,
  IV_RANDOMNESS,
  SombraDataSubjectAuthMethod,
  SombraEmployeeAuthMethod,
} from '@main/sombra-types';

/**
 * The encryption keys
 */
export interface EncryptionKeys {
  /** The shared DH secret. */
  secret: Buffer;
  /** The local DH publicKey, base64 & uncompressed. */
  publicKey: string;
}

/**
 * Generates a shared diffie hellman secret
 *
 * @param otherPublicKey - The public key belonging to the other party.
 * @returns The public key and shared secret.
 */
export const generateKeys = (otherPublicKey: string): EncryptionKeys => {
  const ecdh = crypto.createECDH(ECDH_CURVE);
  const publicKey = ecdh.generateKeys('base64', 'uncompressed');
  return {
    secret: ecdh.computeSecret(otherPublicKey, 'base64'),
    publicKey,
  };
};

/**
 * Create a new set of diffie-hellman encryption keys
 *
 * @param sombraPublicKey - The public key of the encryption server
 * @returns A newly generated set of diffie hellman encryption keys
 */
export const createDhEncryptionKeys = (
  sombraPublicKey: string,
): DhEncryptionKeys => {
  if (!sombraPublicKey) {
    throw new Error(
      `Cannot create DH encryption keys, sombra public key is empty: ${JSON.stringify(sombraPublicKey)}`,
    );
  }
  const { secret, publicKey } = generateKeys(sombraPublicKey);
  return {
    publicKey,
    sombraPublicKey,
    kek: HKDF(secret).toString('base64'),
  };
};

/**
 * Options needed for dh encryption
 */
export interface DhEncryptionOptions {
  /** Keys to use */
  dhEncryptionKeys: DhEncryptionKeys;
  /** Secret to sign */
  authSecret: string;
  /** Method */
  authMethod: SombraEmployeeAuthMethod;
}

/**
 * Construct a dhEncrypted
 *
 * @param dhEncryptionKeys - The generated encryption keys
 * @param authSecret - The auth secret to encrypt and send to sombra
 * @param authMethod - The auth strategy that the secret
 * @param body - The body content to encrypt
 * @returns The stringified dh encrypted payload
 */
export const createDhEncrypted = (
  { kek, publicKey }: DhEncryptionKeys,
  authSecret: string,
  authMethod: SombraDataSubjectAuthMethod | SombraEmployeeAuthMethod,
  body = {},
): string => {
  // Encrypt secret
  const { encryptedContent, iv, authTag } = encrypt(
    kek,
    JSON.stringify({ authSecret, body }),
  );

  return JSON.stringify({
    payload: encryptedContent.toString('base64'),
    iv: iv.toString('base64'),
    authMethod,
    authTag: authTag.toString('base64'),
    publicKey,
  });
};

/**
 * Context needed to employee peek a file
 */
export type PeekContext = {
  /** Dh-encrypted auth */
  dhEncrypted: string;
  /** The URL to download from */
  downloadUrl: string;
} & DhEncryptionKeys;

/**
 * Construct a temporary KEK to peek at a request file
 *
 * @param publicKeyECDH - The dh public key of sombra
 * @param sombraSessionSecret - The employee sombra session secret
 * @param downloadUrl - The download URL without dh auth
 * @returns The dh encrypted and one time keys used to encrypt the file
 */
export function createPeekParameters(
  publicKeyECDH: string,
  sombraSessionSecret: string,
  downloadUrl: string,
): PeekContext {
  // Create a dh channel with sombra
  const dhEncryptionKeys = createDhEncryptionKeys(publicKeyECDH);
  const dhEncrypted = createDhEncrypted(
    dhEncryptionKeys,
    sombraSessionSecret,
    SombraEmployeeAuthMethod.Session,
  );

  return {
    dhEncrypted,
    ...dhEncryptionKeys,
    downloadUrl: new ImmutableUrl(downloadUrl).transform({
      searchTransform: (qs) => ({ ...qs, dhEncrypted }),
    }).href,
  };
}

/**
 * Convert string or buffer to buffer
 *
 * @param input - A base64 string or buffer
 * @param encoding - The buffer encoding
 * @returns A buffer
 */
export const toBuffer = (
  input: string | Buffer,
  encoding: BufferEncoding = 'base64',
): Buffer => (typeof input === 'string' ? Buffer.from(input, encoding) : input);

/**
 * Creates a cryptographically secure key from arbitrary inputs.
 *
 * @param inputs - string or buffers to concatenate and process.
 * @returns Cryptographically secure before to be used as encryption key.
 */
export const HKDF = (...inputs: (string | Buffer)[]): Buffer => {
  // Concatenate inputs into a single buffer
  const ikm = inputs
    .map((input) => (Buffer.isBuffer(input) ? input : Buffer.from(input)))
    .reduce((acc, cur) => Buffer.concat([acc, cur]));

  const iv = Buffer.alloc(256); // consider adding an iv

  const hmac = crypto.createHmac('sha256', iv);
  hmac.update(ikm);
  return hmac.digest();
};

/**
 * Options used to decrypt encrypted data
 */
export interface DecryptOptions {
  /** The encryption key with which to decrypt the data. */
  key: string | Buffer;
  /** The initialization vector. */
  iv: string | Buffer;
  /** The authentication tag to validation the decryption. */
  authTag: string | Buffer;
}

/**
 * Gets a decryption stream
 *
 * @param encryptedData - The data to decrypt.
 * @param options - The configuration options.
 * @returns The decrypted data.
 */
export const decrypt = (
  encryptedData: string | Buffer,
  { key, iv, authTag }: DecryptOptions,
): Buffer => {
  // Create the decipher (decryption stream)
  const decipher = crypto.createDecipheriv(
    CONTENT_ENCRYPTION_ALGORITHM,
    toBuffer(key),
    toBuffer(iv),
  );

  // Check authenticity and integrity of the data
  decipher.setAuthTag(toBuffer(authTag));

  // Return the decrypted data
  return Buffer.concat([
    decipher.update(toBuffer(encryptedData)),
    decipher.final(),
  ]);
};

/**
 * Encryption context
 */
export interface EncryptionContext {
  /** The encrypted content. */
  encryptedContent: Buffer;
  /** The initialization vector for the encryption. */
  iv: Buffer;
  /** The auth tag generated for the encryption. */
  authTag: Buffer;
}

/**
 * Gets a decryption stream
 *
 * @param key - The encryption key with which to encrypt the data
 * @param content - The content to encrypt
 * @returns The encrypted data
 */
export const encrypt = (
  key: Buffer | string,
  content: Buffer | string,
): EncryptionContext => {
  const iv = crypto.randomBytes(IV_RANDOMNESS);
  const cipher = crypto.createCipheriv(
    CONTENT_ENCRYPTION_ALGORITHM,
    toBuffer(key),
    iv,
  );

  const encryptedContent = Buffer.concat([
    cipher.update(toBuffer(content, 'utf-8')),
    cipher.final(),
  ]);
  const authTag = cipher.getAuthTag();

  return { encryptedContent, iv, authTag };
};
