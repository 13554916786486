import { NodeViewRendererProps } from '@tiptap/core';
import Image from '@tiptap/extension-image';
import { NodeViewWrapper, ReactNodeViewRenderer } from '@tiptap/react';
import React from 'react';

import { useTransformImageSrc } from '../useTransformImageSrc';
import { CustomImageOptions } from './constants';

/** Image extension that allows for custom options, e.g. the ability to supply a callback that transforms the src */
const ImageNode = (
  props: NodeViewRendererProps & {
    /** Whether this node is selected. Should be part of NodeViewRendererProps, but isn't */
    selected?: boolean;
  },
): JSX.Element => {
  const { src, alt } = props.node.attrs;
  const srcToDisplay = useTransformImageSrc(
    src,
    props.extension.options.transformSrc,
  );

  let className = 'image';
  if (props.selected) {
    // Keeps class on the node view that is set there on the default node view from tiptap/prosemirror,
    // but is not automatically added to the extension
    className += ' ProseMirror-selectednode';
  }

  return (
    <NodeViewWrapper className={className} data-drag-handle>
      <img src={srcToDisplay} alt={alt} />
    </NodeViewWrapper>
  );
};

export default Image.extend({
  addOptions: () =>
    ({
      ...Image.options,
      transformSrc: undefined,
    }) as CustomImageOptions,
  addNodeView: () => ReactNodeViewRenderer(ImageNode),
});
