import { defineMessages } from '@transcend-io/internationalization';

import { RequestsProcessedDisclosureStatsTableHeaders } from '@main/pc-types';

export const requestsProcessedStatsTableHeaderMessages = defineMessages(
  'admin-dashboard.PrivacyCenter.RequestsProcessedStats.RequestsProcessedOverridesTable.requestsProcessedStatsTableHeader',
  {
    type: {
      defaultMessage: 'Request Type',
    },
    [RequestsProcessedDisclosureStatsTableHeaders.Processed]: {
      defaultMessage: 'Processed',
    },
    [RequestsProcessedDisclosureStatsTableHeaders.Denied]: {
      defaultMessage: 'Denied',
    },
    [RequestsProcessedDisclosureStatsTableHeaders.MedianNumberOfDays]: {
      defaultMessage: 'Median Number Of Days',
    },
    [RequestsProcessedDisclosureStatsTableHeaders.Received]: {
      defaultMessage: 'Received',
    },
  },
);
