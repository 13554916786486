import {
  PaginatedSelect,
  PaginatedSelectProps,
} from '@main/ad-core-components';
import {
  buildUseInfiniteScroll,
  buildUseLazyQuery,
  BuildUseLazyQueryReturn,
  WatchQueryFetchPolicy,
} from '@main/core-ui';
import {
  DataSiloBulkPreviewOrderField,
  DataSiloFiltersInput,
  endpoints,
} from '@main/datamap-types';
import { ID, OrderDirection } from '@main/schema-utils';
import React, { useState } from 'react';

import { selectIntegrationsMessages } from './messages';

const PAGE_SIZE = 15;

export const DATA_SILO_SELECTION_NODES = {
  id: null,
  title: null,
  catalog: {
    logoSquare: null,
  },
} as const;

/**
 * Selection callback type. Can be a resource or data selection of
 */
export interface DataSiloSelection {
  /** ID of the data silo */
  id: ID<'dataSilo'>;
  /** Title of the data silo */
  title: string;
  /** Catalog of the data silo */
  catalog: {
    /** Logo square of the data silo */
    logoSquare: string;
  };
}

const useDataSilos = buildUseInfiniteScroll(
  endpoints.dataSilos,
  'SelectIntegrations',
  {
    nodes: DATA_SILO_SELECTION_NODES,
    totalCount: null,
  },
  PAGE_SIZE,
);

export const useLazyDataSilos: BuildUseLazyQueryReturn<
  typeof endpoints.dataSilos.params,
  typeof endpoints.dataSilos.response
> = buildUseLazyQuery(endpoints.dataSilos, 'LazyDataSilosFilter', {
  nodes: DATA_SILO_SELECTION_NODES,
});

export interface SelectIntegrationProps<IsMulti extends boolean>
  extends Omit<
    PaginatedSelectProps<DataSiloSelection, IsMulti>,
    | 'isQueryLoading'
    | 'queryError'
    | 'fetchMore'
    | 'onEndsTyping'
    | 'fetchPolicy'
    | 'valueLoading'
    | 'value'
    | 'onChange'
  > {
  /** Set the newly selected data silos */
  onChange: (dataSilos: DataSiloSelection[]) => void;
  /** Selected value */
  value?: DataSiloSelection[];
  /** Filter the data silos */
  filterBy?: Omit<DataSiloFiltersInput, 'text'>;
  /** is the value loading */
  valueLoading?: boolean;
  /** The fetch policy */
  fetchPolicy?: WatchQueryFetchPolicy;
}

/**
 * Component to select one or more integrations from the current organization.
 */
export function SelectIntegrations<IsMulti extends boolean>({
  onChange,
  filterBy,
  valueLoading,
  isMulti = true,
  fetchPolicy = 'cache-first',
  ...props
}: SelectIntegrationProps<IsMulti>): JSX.Element {
  const [searchText, setSearchText] = useState('');

  const { data, loading, error, fetchMore } = useDataSilos({
    variables: {
      filterBy: {
        ...(searchText ? { text: searchText } : {}),
        ...filterBy,
      },
      orderBy: [
        {
          field: DataSiloBulkPreviewOrderField.Title,
          direction: OrderDirection.ASC,
        },
      ],
    },
    fetchPolicy,
    notifyOnNetworkStatusChange: true,
  });

  return (
    <PaginatedSelect
      id="select-data-silos"
      isQueryLoading={valueLoading || loading}
      captureMenuScroll
      queryError={error}
      onEndsTyping={setSearchText}
      options={data?.nodes || []}
      getOptionValue={({ id }) => id}
      getOptionLabel={({ title }) => title}
      getOptionLogo={({ catalog }) => catalog?.logoSquare}
      fetchMore={fetchMore}
      isMulti={isMulti}
      placeholderDescriptor={
        isMulti
          ? selectIntegrationsMessages.placeholderMulti
          : selectIntegrationsMessages.placeholderSingle
      }
      onChange={(selected) =>
        // always return a list for type simplicity
        !selected
          ? onChange([])
          : Array.isArray(selected)
            ? onChange([...selected])
            : onChange([selected as any])
      }
      {...props}
    />
  );
}

/**
 * Select a single integration
 */

export const SelectIntegration: React.FC<
  Omit<SelectIntegrationProps<false>, 'value' | 'onChange'> & {
    /** Set the newly selected data silos */
    onChange: (dataSilo: DataSiloSelection) => void;
    /** Selected value */
    value?: DataSiloSelection;
  }
> = ({ onChange, value, ...props }) => (
  <SelectIntegrations
    {...props}
    isMulti={false}
    value={value ? [value] : []}
    onChange={([silo]) => onChange(silo)}
  />
);
