import { UserPreview } from '@main/access-control-types';
import { mkInput, mkOrder, mkType, SchemaToType } from '@main/schema-utils';

import { PullRequestOrderField } from './enums';
import { RepositoryPreview } from './repository';

export const PullRequestPreview = mkType({
  name: 'PullRequestPreview',
  comment: 'A preview of a pull request instance',
  fields: {
    id: {
      comment: 'The id of the pull request',
      modelName: 'pullRequest',
      type: 'id',
    },
    title: {
      comment: 'The title of the pull request',
      type: 'string',
    },
    description: {
      comment: 'The description of the pull request',
      type: 'string',
    },
    url: {
      comment: 'The URL of the pull request',
      type: 'string',
    },
    remoteId: {
      comment: 'The ID of the pull request in the remote tool',
      type: 'string',
    },
    createdAt: {
      comment: 'The date the pull request was created',
      type: 'Date',
    },
    updatedAt: {
      comment: 'The date the pull request was last updated',
      type: 'Date',
    },
  },
});

/** Override type */
export type PullRequestPreview = SchemaToType<typeof PullRequestPreview>;

export const PullRequest = mkType({
  name: 'PullRequest',
  comment:
    'A pull request that contains code submitted to a particular repository',
  fields: {
    ...PullRequestPreview.fields,
    repository: {
      comment: 'The repository that pull request is created for',
      type: () => RepositoryPreview,
    },
    users: {
      comment: 'The users that made the pull request',
      type: () => UserPreview,
      list: true,
    },
  },
});

/** Override type */
export type PullRequest = SchemaToType<typeof PullRequest>;

export const PullRequestFiltersInput = mkInput({
  name: 'PullRequestFiltersInput',
  comment: 'Inputs for filtering a list of pull requests',
  fields: {
    ids: {
      comment: 'The ids of the pull requests',
      type: 'id',
      modelName: 'pullRequest',
      optional: true,
      list: true,
    },
    userIds: {
      comment: 'The ids of the users that created the pull requests',
      type: 'id',
      modelName: 'user',
      optional: true,
      list: true,
    },
    repositoryIds: {
      comment: 'The ids of the repositories that the pull requests are made to',
      type: 'id',
      modelName: 'repository',
      optional: true,
      list: true,
    },
    text: {
      comment: 'Filter by text (title, description, url)',
      optional: true,
      type: 'string',
    },
    title: {
      comment: 'Filter by pull request title',
      optional: true,
      type: 'string',
    },
    titles: {
      comment: 'Filter on exact pull request titles',
      optional: true,
      type: 'string',
      list: true,
    },
  },
});

/** Override type */
export type PullRequestFiltersInput = SchemaToType<
  typeof PullRequestFiltersInput
>;

export const DeletePullRequestsInput = mkInput({
  name: 'DeletePullRequestsInput',
  comment: 'Input for deleting a set of pull requests',
  fields: {
    ids: {
      comment: 'The id of the pull requests to delete',
      type: 'id',
      modelName: 'pullRequest',
      list: true,
    },
  },
});

/** Override type */
export type DeletePullRequestsInput = SchemaToType<
  typeof DeletePullRequestsInput
>;

export const CreatePullRequestInput = mkInput({
  name: 'CreatePullRequestInput',
  comment: 'Input for creating a pull request',
  fields: {
    title: {
      comment: 'The title of the pull request',
      type: 'string',
    },
    description: {
      comment: 'The description of the pull request',
      type: 'string',
      optional: true,
    },
    url: {
      comment: 'The URL of the pull request',
      type: 'string',
    },
    remoteId: {
      comment: 'The ID of the pull request in the remote tool',
      type: 'string',
    },
    repositoryId: {
      comment: 'The ID of the repository that the pull request is for',
      modelName: 'repository',
      type: 'id',
      optional: true,
    },
    repositoryName: {
      comment: 'The name of the repository',
      type: 'string',
      optional: true,
    },
    userIds: {
      comment: 'The IDs of users that made the pull request',
      modelName: 'user',
      type: 'id',
      list: true,
      optional: true,
    },
    userEmails: {
      comment: 'The emails of users that made the pull request',
      type: 'string',
      list: true,
      optional: true,
    },
  },
});

/** Override type */
export type CreatePullRequestInput = SchemaToType<
  typeof CreatePullRequestInput
>;

export const UpdatePullRequestInput = mkInput({
  name: 'UpdatePullRequestInput',
  comment: 'Input for updating a pull request',
  fields: {
    id: {
      comment: 'The id of the pull request',
      modelName: 'pullRequest',
      type: 'id',
    },
    title: {
      comment: 'The title of the pull request',
      type: 'string',
      optional: true,
    },
    description: {
      comment: 'The description of the pull request',
      type: 'string',
      optional: true,
    },
    url: {
      comment: 'The URL of the pull request',
      type: 'string',
      optional: true,
    },
    userIds: {
      comment: 'The IDs of users that made the pull request',
      modelName: 'user',
      type: 'id',
      list: true,
      optional: true,
    },
    userEmails: {
      comment: 'The emails of users that made the pull request',
      type: 'string',
      list: true,
      optional: true,
    },
  },
});

/** Override type */
export type UpdatePullRequestInput = SchemaToType<
  typeof UpdatePullRequestInput
>;

export const UpdatePullRequestsInput = mkInput({
  name: 'UpdatePullRequestsInput',
  comment: 'Input for bulk updating one or more pull requests',
  fields: {
    pullRequests: {
      comment:
        'List of pull requests with the properties that should be updated for each',
      type: UpdatePullRequestInput,
      list: true,
    },
  },
});

/** Override type */
export type UpdatePullRequestsInput = SchemaToType<
  typeof UpdatePullRequestsInput
>;

/** Order for a pull requests query */
export const PullRequestOrder = mkOrder(
  PullRequest.name,
  PullRequestOrderField,
);
