import { createLoadable } from '@main/core-ui';

// Load the component dynamically
export const ApprovedDataFlows = createLoadable({
  loader: () => import('./ApprovedDataFlows'),
});

export const JunkDataFlows = createLoadable({
  loader: () => import('./JunkDataFlows'),
});

export const TriageDataFlows = createLoadable({
  loader: () => import('./TriageDataFlows'),
});
