import { mkInput, mkType, SchemaToType } from '@main/schema-utils';

export const DeployPrivacyCenterVersionInput = mkInput({
  name: 'DeployPrivacyCenterVersionInput',
  comment:
    'Input for querying a privacy center version from the admin dashboard',
  fields: {
    privacyCenterVersionId: {
      comment: 'ID of the privacy center version',
      modelName: 'privacyCenterVersion',
      type: 'id',
    },
  },
});

/** Override type */
export type DeployPrivacyCenterVersionInput = SchemaToType<
  typeof DeployPrivacyCenterVersionInput
>;

export const PrivacyCenterVersionModulesInput = mkInput({
  name: 'PrivacyCenterVersionModulesInput',
  comment: 'Input to get the latest saved or deployed privacy center version',
  fields: {
    privacyCenterId: {
      comment: 'ID of the privacy center',
      modelName: 'privacyCenter',
      type: 'id',
    },
    inDeployState: {
      comment:
        'Indicates whether to get latest privacy center version modules in deployed or saved state',
      type: 'boolean',
    },
  },
});

/** Override type */
export type PrivacyCenterVersionModulesInput = SchemaToType<
  typeof PrivacyCenterVersionModulesInput
>;

export const PrivacyCenterVersionModules = mkType({
  name: 'PrivacyCenterVersionModules',
  comment: 'Items in current privacy center version',
  fields: {
    id: {
      comment: 'A unique identifier for the Privacy Center Version',
      modelName: 'privacyCenterVersion',
      optional: true,
      type: 'id',
    },
    privacyCenterModule: {
      comment:
        'The JSON containing the configurations of the privacy center module',
      optional: true,
      type: 'string',
    },
    policiesModule: {
      comment: 'The JSON containing the configurations of the policies module',
      optional: true,
      type: 'string',
    },
    messagesModule: {
      comment: 'The JSON containing the configurations of the messages module',
      optional: true,
      type: 'string',
    },
    subjectsModule: {
      comment: 'The JSON containing the configurations of the subjects module',
      optional: true,
      type: 'string',
    },
    privacyCenterRequestsProcessedStatsModule: {
      comment:
        'The JSON containing the configurations of the requests processed stats module',
      optional: true,
      type: 'string',
    },
    purposesModule: {
      comment: 'The JSON containing the configurations of the purposes module',
      optional: true,
      type: 'string',
    },
  },
});

/** Override type */
export type PrivacyCenterVersionModules = SchemaToType<
  typeof PrivacyCenterVersionModules
>;
