import { endpoints } from '@main/access-control-types';
import {
  InlineSelectTeams,
  SelectedTeam,
  SelectTeamProps,
} from '@main/ad-core-components';
import { buildUseMutation } from '@main/core-ui';
import type { ID } from '@main/schema-utils';
import React from 'react';

import { RowTableContext } from '../types';

const useUpdateActionItems = buildUseMutation(endpoints.updateActionItems);

interface InlineSelectAssigneeTeams extends SelectTeamProps {
  /** ids of the action items for this cell */
  ids: ID<'actionItem'>[];
  /** The value for the cell */
  value: SelectedTeam[];
  /** Queries to refetch on success */
  refetchQueries?: string[];
  /** The table context */
  context: RowTableContext;
}

export const InlineSelectAssigneeTeams: React.FC<InlineSelectAssigneeTeams> = ({
  ids,
  value,
  refetchQueries = ['GlobalActionItems', 'GlobalActionItemsSubTable'],
  context,
}) => {
  const [updateActionItems, { loading }] = useUpdateActionItems(
    refetchQueries
      ? {
          refetchQueries,
          awaitRefetchQueries: true,
        }
      : {},
  );

  return (
    <InlineSelectTeams
      value={value}
      loading={loading}
      onUpdate={(teamIds) =>
        updateActionItems({
          variables: {
            input: {
              assigneesTeamIds: teamIds,
              ...(context.count > 1
                ? {
                    queryFilters: context.filters,
                    rollupFilters: [context.rollupFilters],
                  }
                : {
                    ids,
                  }),
            },
          },
        }).then(({ errors }) => ({
          data: [],
          errors,
        }))
      }
    />
  );
};
