import { defineMessages } from '@transcend-io/internationalization';

export const documentationReferencesMessages = defineMessages(
  'ad-core-components.DocumentationReferences.documentationReferences',
  {
    tooltipWithNumber: {
      defaultMessage: 'View documentation {index}',
    },
  },
);
