import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ApolloError, buildUseQuery } from '@main/core-ui';
import { endpoints, parsePrivacyCenter, PrivacyCenter } from '@main/pc-types';

import { selectPrivacyCenter } from './selectors';
import { setPrivacyCenter } from './slice';

const usePrivacyCenter = buildUseQuery(endpoints.privacyCenter);

/**
 * Fetch the privacy center
 *
 * @returns Data, loading, error
 */
export function useParsedPrivacyCenter(): {
  /** Privacy center */
  privacyCenter?: PrivacyCenter;
  /** Loading */
  loading: boolean;
  /** Error */
  error?: ApolloError;
} {
  const dispatch = useDispatch();
  const { data, loading, error } = usePrivacyCenter({
    fetchPolicy: 'cache-and-network',
  });
  const privacyCenter = useSelector(selectPrivacyCenter);
  useEffect(() => {
    if (data) {
      dispatch(setPrivacyCenter(parsePrivacyCenter(data)));
    }
  }, [data]);

  return {
    privacyCenter,
    loading,
    error,
  };
}
