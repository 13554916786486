import { endpoints, TCFSpecialFeature } from '@main/cm-types';
import {
  buildUseQuery,
  formatErrorMessage,
  ReactSelect,
  ReactSelectOverflowOptions,
} from '@main/core-ui';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { MenuPosition } from 'react-select';

import { TcfDoubleTag } from '../TcfDoubleTag';
import { selectTcfSpecialFeatureMessages } from './messages';

const NODES = {
  id: null,
  name: null,
} as const;

const useTcfSpecialFeatures = buildUseQuery(
  endpoints.orgTcfSpecialFeatures,
  'SelectTcfSpecialFeatures',
  { nodes: { tcfSpecialFeature: NODES } },
);

/**
 * Selected TCF Special Feature
 */
export type SelectedTcfSpecialFeature = Pick<
  TCFSpecialFeature,
  keyof typeof NODES
>;

/** Props for the SelectTcfSpecialFeature component */
export interface SelectTcfSpecialFeatureProps {
  /** The value for the cell */
  value?: SelectedTcfSpecialFeature[];
  /** Whether the input is disabled (e.g. due to the data being derived) */
  disabled?: boolean;
  /** Whether the input is being updated/loading */
  isLoading?: boolean;
  /** Whether to show the select input outlines */
  showSelectOutline?: boolean;
  /** How to position the menu (see docs for React Select) */
  menuPosition?: MenuPosition;
  /** How to display overflow if multi-select */
  overflow?: ReactSelectOverflowOptions<true>;
  /** Callback to fire when the values is updated */
  onChange: (values: SelectedTcfSpecialFeature[]) => void;
}

/** An option for the select component */
export interface SelectTcfSpecialFeatureOption {
  /** The value for the option */
  value: SelectedTcfSpecialFeature;
  /** The label for the option */
  label: string;
}

const isSelectedTcfSpecialFeaturesOption = (
  value: SelectTcfSpecialFeatureOption | undefined,
): value is SelectTcfSpecialFeatureOption => !!value;

/** SelectTcfSpecialFeature: select TCF special features */
export const SelectTcfSpecialFeature: React.FC<
  SelectTcfSpecialFeatureProps
> = ({
  value: initialValues,
  disabled = false,
  isLoading,
  showSelectOutline = false,
  onChange,
  ...props
}) => {
  const { formatMessage } = useIntl();
  const [searchText, setSearchText] = useState('');

  const { data, loading, error } = useTcfSpecialFeatures({
    variables: {
      input: {},
      filterBy: {
        ...(searchText ? { text: searchText } : {}),
      },
    },
    fetchPolicy: 'cache-and-network',
  });

  const toOption = useCallback(
    (
      value?: SelectedTcfSpecialFeature,
    ): SelectTcfSpecialFeatureOption | undefined =>
      !value
        ? undefined
        : {
            value,
            label: value.name,
          },
    [],
  );

  const options = useMemo(
    () =>
      (data?.nodes.map(({ tcfSpecialFeature }) => tcfSpecialFeature) ?? []).map(
        toOption,
      ),
    [data],
  );

  const [selected, setSelected] = useState<SelectTcfSpecialFeatureOption[]>();

  useEffect(() => {
    const newSelected = (
      initialValues ? initialValues.map((value) => toOption(value)) : []
    ).filter(isSelectedTcfSpecialFeaturesOption);

    setSelected(newSelected);
  }, [data, initialValues, toOption]);

  return (
    <ReactSelect<true, SelectTcfSpecialFeatureOption>
      options={options.filter(isSelectedTcfSpecialFeaturesOption)}
      isMulti
      value={selected}
      onChange={(options) =>
        onChange(
          options.map(({ value }) => value) as SelectedTcfSpecialFeature[],
        )
      }
      showOutline={showSelectOutline}
      isDisabled={disabled}
      isClearable={false}
      isLoading={isLoading || loading}
      getOptionLabel={({ label }) => label}
      placeholder={formatMessage(
        selectTcfSpecialFeatureMessages.selectPlaceholder,
      )}
      onEndsTyping={setSearchText}
      noOptionsMessage={
        error ? () => formatErrorMessage(error.message) : undefined
      }
      formatOptionPill={(option) => <TcfDoubleTag option={option.value} />}
      {...props}
    />
  );
};
