import { Button } from '@main/ad-core-components';
import { endpoints } from '@main/cm-types';
import {
  buildUseMutation,
  formatErrorMessage,
  Icon,
  message,
} from '@main/core-ui';
import { ID } from '@main/schema-utils';
import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';

import { approveSdkButtonMessages } from './messages';

const useCreateConsentSdk = buildUseMutation(
  endpoints.createOrUpdateConsentSdks,
);

export interface ApproveSdkButtonProps {
  /** Airgap bundle Id */
  airgapBundleId: ID<'airgapBundle'>;
  /** Unique identifier for this consent SDK */
  slug: string;
  /** Description of SDK */
  description: string;
  /** Consent Service Catalog Name */
  consentServiceCatalogName?: string;
  /** Code Package ID */
  softwareDevelopmentKitId: ID<'softwareDevelopmentKit'>;
}

export const ApproveSdkButton: React.FC<ApproveSdkButtonProps> = ({
  airgapBundleId,
  slug,
  description,
  consentServiceCatalogName,
  softwareDevelopmentKitId,
}) => {
  const { formatMessage } = useIntl();
  const [createConsentSdk, { loading, error }] = useCreateConsentSdk({
    refetchQueries: ['ConsentSdksTable', 'TriageSoftwareDevelopmentKits'],
    awaitRefetchQueries: true,
  });

  useEffect(() => {
    if (error) {
      message.error(formatErrorMessage(error.message));
    }
  }, [error]);

  return (
    <Button
      icon={<Icon type="thumbs-up" />}
      loading={loading}
      onClick={() => {
        if (!consentServiceCatalogName) {
          message.error(
            formatMessage(approveSdkButtonMessages.missingIntegration),
          );
          return;
        }
        createConsentSdk({
          variables: {
            input: {
              airgapBundleId,
              consentSdks: [
                {
                  slug,
                  description,
                  consentServiceCatalogName,
                  softwareDevelopmentKitId,
                },
              ],
            },
          },
        }).then((data) => {
          if (data) {
            message.success(
              formatMessage(approveSdkButtonMessages.approveSuccess),
            );
          }
        });
      }}
    >
      {formatMessage(approveSdkButtonMessages.buttonText)}
    </Button>
  );
};
