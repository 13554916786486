import * as t from 'io-ts';

import { dbModelId } from '@main/schema-utils';

import { AuditEventCode } from '../schema/enums';
import { mkAuditEventCodec } from '../types';

export const AuditEventConsentService = mkAuditEventCodec(
  AuditEventCode.ConsentService,
  t.type({
    /** ID of the consent service */
    consentServiceId: dbModelId('consentService'),
  }),
);

/** Override types. */
export type AuditEventConsentService = t.TypeOf<
  typeof AuditEventConsentService
>;
