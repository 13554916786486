import * as t from 'io-ts';

import { dbModelId } from '@main/schema-utils';

import { AuditEventCode } from '../schema/enums';
import {
  mkAuditEventCodec,
  mkAuditEventJoinCodec,
  mkAuditJoinStateCodec,
} from '../types';

export const AuditEventVendorOwner = mkAuditEventJoinCodec(
  AuditEventCode.VendorOwner,
  mkAuditJoinStateCodec('user'),
  t.type({
    /** ID of the vendor */
    vendorId: dbModelId('vendor'),
  }),
);

/** Override types. */
export type AuditEventVendorOwner = t.TypeOf<typeof AuditEventVendorOwner>;

export const AuditEventVendorBusinessEntity = mkAuditEventJoinCodec(
  AuditEventCode.VendorBusinessEntity,
  mkAuditJoinStateCodec('user'),
  t.type({
    /** ID of the vendor */
    vendorId: dbModelId('vendor'),
    /** Business entity */
    businessEntityId: dbModelId('businessEntity'),
  }),
);

/** Override types. */
export type AuditEventVendorBusinessEntity = t.TypeOf<
  typeof AuditEventVendorBusinessEntity
>;

export const AuditEventVendorTeam = mkAuditEventJoinCodec(
  AuditEventCode.VendorTeam,
  mkAuditJoinStateCodec('team'),
  t.type({
    /** ID of the vendor */
    vendorId: dbModelId('vendor'),
  }),
);

/** Override types. */
export type AuditEventVendorTeam = t.TypeOf<typeof AuditEventVendorTeam>;

export const AuditEventVendor = mkAuditEventCodec(
  AuditEventCode.Vendor,
  t.type({
    /** ID of the vendor */
    vendorId: dbModelId('vendor'),
  }),
);

/** Override types. */
export type AuditEventVendor = t.TypeOf<typeof AuditEventVendor>;

export const AuditEventVendorAttribute = mkAuditEventJoinCodec(
  AuditEventCode.VendorAttribute,
  mkAuditJoinStateCodec(
    'attributeValue',
    t.type({
      /** the display title for the attribute key */
      attributeKeyTitle: t.string,
    }),
  ),
  t.type({
    /** ID of the vendor */
    vendorId: dbModelId('vendor'),
  }),
);

/** Override types. */
export type AuditEventVendorAttribute = t.TypeOf<
  typeof AuditEventVendorAttribute
>;
