import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import uniqBy from 'lodash/uniqBy';

import type { DataPoint } from '@main/datamap-types';

/**
 * Slice to manage the selected datapoints
 */
export interface DataSiloManageDataPointsState {
  /** The selected datapoints */
  selectedDataPoints: DataPoint[];
}

export const dataSiloManageDataPointsSlice = createSlice({
  name: 'DataSiloManageDataPoints',
  initialState: {
    selectedDataPoints: [],
  } as DataSiloManageDataPointsState,
  reducers: {
    // Selected datapoints
    selectDataPoint: (state, { payload }: PayloadAction<DataPoint>) => ({
      ...state,
      selectedDataPoints: uniqBy([...state.selectedDataPoints, payload], 'id'),
    }),
    unselectDataPoint: (
      { selectedDataPoints, ...state },
      { payload }: PayloadAction<DataPoint>,
    ) => ({
      ...state,
      selectedDataPoints: selectedDataPoints.filter(
        ({ id }) => id !== payload.id,
      ),
    }),
    selectDataPoints: (state, { payload }: PayloadAction<DataPoint[]>) => {
      state.selectedDataPoints = uniqBy(
        state.selectedDataPoints.concat(payload),
        'id',
      );
    },
    unselectDataPoints: (state, { payload }: PayloadAction<DataPoint[]>) => {
      const removeIds = payload.map(({ id }) => id);
      state.selectedDataPoints = state.selectedDataPoints.filter(
        ({ id }) => !removeIds.includes(id),
      );
    },
    resetSelectedDataPoints: (state) => ({
      ...state,
      selectedDataPoints: [],
    }),
  },
});

export const {
  selectDataPoint,
  unselectDataPoint,
  selectDataPoints,
  unselectDataPoints,
  resetSelectedDataPoints,
} = dataSiloManageDataPointsSlice.actions;
