import mime from 'mime/lite';
import { useCallback } from 'react';
import { Accept } from 'react-dropzone';
import { useIntl } from 'react-intl';
import { fetch } from 'whatwg-fetch';

import { message } from '@main/core-ui';
import { ONE_MB } from '@main/utils';

import { fileUploadMessages } from './messages';

export interface FileUploadOptions {
  /** Location the file should be uploaded to */
  uploadUrl: string;
  /** File types to accept */
  accept: Accept;
  /** The max size in MB of the file */
  allowedSize: number;
}

/**
 * Upload a file to a url
 *
 * @param file - the file to upload
 * @param uploadUrl - the URL to upload the file to
 * @returns the JSON data response from the upload
 */
export function uploadFile<T = any>(file: File, uploadUrl: string): Promise<T> {
  const data = new FormData();
  data.append('file', file);

  return new Promise((resolve, reject) => {
    fetch(uploadUrl, {
      method: 'POST',
      body: data,
      credentials: 'include',
    })
      .then((res) => {
        res
          .json()
          .then((data) => {
            if (data?.code >= 400) {
              reject(new Error(data.message));
            }
            resolve(data);
          })
          .catch((err) => {
            reject(err);
          });
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export const fileIsLessThanMaxSize = (
  file: File,
  allowedSize: number,
): boolean => {
  // Get file size in MB
  const fileSize = (file.size / ONE_MB).toFixed(4);
  const tooBig = +fileSize > allowedSize;
  return !tooBig;
};

export const fileIsAcceptedType = (file: File, allowedTypes: Accept): boolean =>
  Object.keys(allowedTypes).some(
    (mimeType) =>
      mimeType === '*' ||
      mimeType === file.type ||
      mimeType.split('/*')[0] === file.type.split('/')[0],
  );

/**
 * Hook to upload a file that includes error message displays
 *
 * @returns a function to execute the file upload
 */
export function useUploadFile<T = any>(): (
  file: File,
  { uploadUrl, allowedSize, accept }: FileUploadOptions,
) => Promise<T> {
  const { formatMessage } = useIntl();

  return useCallback(
    (file, { uploadUrl, allowedSize, accept }) => {
      // Validate file size
      if (allowedSize && !fileIsLessThanMaxSize(file, allowedSize)) {
        const errorMessage = formatMessage(fileUploadMessages.fileSizeError, {
          allowedSize,
        });
        message.error(errorMessage);
        return Promise.reject(errorMessage);
      }

      // Validate file type
      if (accept && !fileIsAcceptedType(file, accept)) {
        const errorMessage = formatMessage(fileUploadMessages.fileTypeError, {
          types: Object.keys(accept)
            .map((mimeType) =>
              // split is always at least 1
              mimeType.split('/*').length > 1
                ? mimeType.split('/*')[0]
                : mime.getExtension(mimeType),
            )
            .join(', '),
        });
        message.error(errorMessage);
        return Promise.reject(errorMessage);
      }

      const uploadPromise = uploadFile<T>(file, uploadUrl);

      // Display a loading message
      message.promise(uploadPromise, {
        success: () => formatMessage(fileUploadMessages.fileUploadingSuccess),
        loading: formatMessage(fileUploadMessages.fileUploadingProgress),
        error: (err) =>
          formatMessage(fileUploadMessages.fileUploadingError, {
            error: err.toString(),
          }),
      });

      return uploadPromise;
    },
    [formatMessage],
  );
}
