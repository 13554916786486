import type {
  DataInventoryCategory,
  DataSubCategory,
} from '@main/datamap-types';

/**
 * A more minimal type representing a parent category that can be used to filter data
 */
export type ParentCategoryFilterItem = Pick<
  DataInventoryCategory,
  'id' | 'name' | 'category' | 'slug'
>;

/**
 * A more minimal type representing a subcategory that can be used to filter data
 */
export type SubCategoryFilterItem = Pick<
  DataSubCategory,
  'id' | 'name' | 'category' | 'slug' | 'isDefault'
>;

/**
 * The possible types of categories that can be used to filter data
 */
export type DataCategoriesItem =
  | ParentCategoryFilterItem
  | SubCategoryFilterItem;

/**
 * Narrow type of data category to a subcategory
 *
 * @param category - The data category item
 * @returns Whether the data category item is a subcategory
 */
export function isSubCategory(
  category: DataCategoriesItem,
): category is SubCategoryFilterItem {
  return 'id' in category && 'isDefault' in category;
}
