import { makeEnum } from '@transcend-io/type-utils';

/**
 * Consent expiry behavior
 */
export const ConsentExpiryBehavior = makeEnum({
  /** Reset 'prompted' field */
  Prompt: 'Prompt',
  /** Reset opted-in fields */
  ResetOptIns: 'ResetOptIns',
  /** Reset all fields */
  ResetAll: 'Reset',
});
