import {
  FlexRowCenterVertical,
  FormatMessageArgs,
  Icon,
  Popover,
  useFormatMessageGeneric,
} from '@main/core-ui';
import { DefinedMessage } from '@transcend-io/internationalization';
import React from 'react';
import { FormattedMessage } from 'react-intl';

/**
 * Display column header with optional tooltip
 */
export const ColumnHeaderWithTooltip: React.FC<{
  /** Header message */
  message: DefinedMessage | string;
  /** Tooltip description */
  info?: DefinedMessage | string;
  /** Whether resizing is disabled */
  disableResizing?: boolean;
  /** Tooltip templates */
  infoArgs?: FormatMessageArgs;
}> = ({ message, info, disableResizing = false, infoArgs }) => {
  const { formatMessageGeneric } = useFormatMessageGeneric();

  return (
    <FlexRowCenterVertical
      style={{
        height: '100%',
      }}
    >
      {/* This wrapper keeps the tooltip icon next to the text, even when the text wraps */}
      <div style={{ display: 'inline-block', whiteSpace: 'nowrap' }}>
        <span
          style={{
            // Let the title text wrap - it's just the icon we don't want to let wrap
            whiteSpace: 'normal',
          }}
        >
          {typeof message === 'string' ? (
            message
          ) : (
            <FormattedMessage {...message} />
          )}
        </span>
        {/* non-breaking space sticks the icon to the text */}
        &nbsp;
        {info && (
          <Popover
            contents={
              <div style={{ maxWidth: 400 }}>
                {formatMessageGeneric(info, infoArgs)}
              </div>
            }
            trigger={['click']}
            placement="auto"
            hideArrow
          >
            <span
              style={{
                display: 'inline-block',
                // Manually set spacing for vertical alignment without affecting the title line spacing
                transform: 'translateY(2px)',
                color: 'inherit',
                cursor: 'pointer',
                ...(!disableResizing ? { marginRight: 14 } : {}),
              }}
            >
              <Icon type="i-circle" />
            </span>
          </Popover>
        )}
      </div>
    </FlexRowCenterVertical>
  );
};
