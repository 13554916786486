/* eslint-disable max-lines */
import uniq from 'lodash/uniq';

import {
  ScopeName,
  StaticTeamType,
  TRANSCEND_SCOPES,
} from '@transcend-io/privacy-types';

import { EmailDomainVerificationStatus } from './schema/enums';
import { DefaultTeamInput } from './types';

export const EVERY_MINUTE = 'rate(1 minute)';
export const EVERY_FIVE_MINUTES = 'rate(5 minutes)';
export const EVERY_MONDAY_10AM_ET = 'cron(0 14 ? * MON *)';
export const EVERY_TEN_MINUTES = 'cron(0/10 * * * ? *)';
export const EVERY_TWO_MINUTES = 'cron(0/2 * * * ? *)';
export const EVERY_FOUR_HOURS = 'cron(0 */4 * * ? *)';
export const EVERY_TWELVE_HOURS = 'cron(0 */12 * * ? *)';
export const WEEKDAYS_10AM_ET = 'cron(0 14 ? * MON-FRI *)';

/**
 * The default team configurations (including scopes) to create with a new organization.
 */
export const DEFAULT_TEAM_INPUTS: { [k in StaticTeamType]: DefaultTeamInput } =
  {
    [StaticTeamType.Engineering]: {
      name: 'Engineering',
      staticType: StaticTeamType.Engineering,
      description:
        'Allow engineering team necessary permissions to configure products.',
      scopes: uniq([
        // managing data silos
        ScopeName.ConnectDataSilos,
        ScopeName.ViewApiKeys,
        ScopeName.ViewDataMap,
        ScopeName.ManageApiKeys,
        ScopeName.ManageDataMap,
        // Manage Privacy Request Settings
        ScopeName.ManageDataSubjectRequestSettings,
        ScopeName.ManageRequestIdentities,
        // required to test DSR creation
        ScopeName.MakeDataSubjectRequest,
        ScopeName.ManageRequestCompilation,
        ScopeName.ViewRequests,
        ScopeName.RequestApproval,
        // Consent Manager
        ScopeName.ViewDataFlow,
        ScopeName.ManageConsentManagerDeveloperSettings,
        ScopeName.ManageConsentManagerDisplaySettings,
        ScopeName.ManageDataFlow,
        ScopeName.DeployConsentManager,
        ScopeName.DeployTestConsentManager,
        // Data Mapping
        ScopeName.ManageDataInventory,
        // Privacy Center
        ScopeName.DeployPrivacyCenter,
        ScopeName.ManagePrivacyCenter,
        // Auditor
        ScopeName.ManageAuditor,
        ScopeName.ExecuteAuditor,
        // Code Scanning
        ScopeName.ManageCodeScanning,
        // Pathfinder
        ScopeName.ManagePathfinder,
        // Preference store
        ScopeName.ManageStoredPreferences,
        // Audit events
        ScopeName.ViewAuditEvents,
        // Custom fields
        ScopeName.ManageGlobalAttributes,
        // Assessments
        ScopeName.ManageAssignedAssessments,
        // Prompts
        ScopeName.ManagePrompts,
        ScopeName.ExecutePrompt,
        ScopeName.ApprovePrompts,
        ScopeName.ViewPromptRuns,
        // action items
        ScopeName.ManageActionItemCollections,
      ]),
    },
    [StaticTeamType.DsrManagement]: {
      name: 'DSR Management',
      staticType: StaticTeamType.DsrManagement,
      description:
        'Give full access to data subject request management, including viewing/editing all requests.',
      scopes: uniq([
        ScopeName.ManageDataSubjectRequestSettings,
        ScopeName.ManageEmailTemplates,
        ScopeName.ManageRequestCompilation,
        ScopeName.ManageRequestIdentities,
        ScopeName.RequestApproval,
        ScopeName.ViewDataMap,
        ScopeName.ViewDataSubjectRequestSettings,
        ScopeName.ViewEmailTemplates,
        ScopeName.ViewRequestCompilation,
        ScopeName.ViewRequestIdentitySettings,
        ScopeName.ViewRequests,
        // Audit events
        ScopeName.ViewAuditEvents,
      ]),
    },
    [StaticTeamType.ReadOnly]: {
      name: 'Read Only',
      staticType: StaticTeamType.ReadOnly,
      description:
        'Only allow scopes that list information. Do not allow these members to make any changes to the Transcend account.',
      scopes: [ScopeName.ReadOnly],
    },
    [StaticTeamType.PrivacyCenterManagement]: {
      name: 'Privacy Center Management',
      staticType: StaticTeamType.PrivacyCenterManagement,
      description:
        'Allow these people to manage the Privacy Center configuration and deployment.',
      scopes: uniq([
        ScopeName.DeployPrivacyCenter,
        ScopeName.ManagePrivacyCenter,
        ScopeName.ManageDataSubjectRequestSettings,
        ScopeName.ViewDataSubjectRequestSettings,
        // Audit events
        ScopeName.ViewAuditEvents,
      ]),
    },
    [StaticTeamType.AccessControlManagement]: {
      name: 'Access Control Management',
      staticType: StaticTeamType.AccessControlManagement,
      description: 'The ability to view and manage employees.',
      scopes: uniq([
        ScopeName.ManageAccessControl,
        ScopeName.ManageSombraRootKeys,
        ScopeName.ManageRequestSecurity,
        ScopeName.ManageEmailDomains,
        ScopeName.ManageSSO,
        ScopeName.ManageOrganizationInfo,
        ScopeName.ManageBilling,
        ScopeName.ViewScopes,
        ScopeName.ViewEmployees,
        ScopeName.ViewPromptRuns,
        ScopeName.ViewAuditEvents,
        // action items
        ScopeName.ManageActionItemCollections,
      ]),
    },
    [StaticTeamType.Design]: {
      name: 'Design',
      staticType: StaticTeamType.Design,
      description:
        'Responsible for overseeing the design and user experience of customer facing privacy components.',
      scopes: uniq([
        ScopeName.ManagePrivacyCenter,
        ScopeName.ManageConsentManagerDisplaySettings,
        ScopeName.DeployPrivacyCenter,
      ]),
    },
    [StaticTeamType.LegalPrivacyOps]: {
      name: 'Legal/Privacy Ops',
      staticType: StaticTeamType.LegalPrivacyOps,
      description:
        'Legal/privacy team is responsible for managing privacy policies and overseeing compliance with various laws',
      scopes: uniq([
        // managing data silos
        ScopeName.ConnectDataSilos,
        ScopeName.ViewApiKeys,
        ScopeName.ManageDataMap,
        ScopeName.ManageDataInventory,
        // Manage Privacy Request Settings
        ScopeName.ManageDataSubjectRequestSettings,
        ScopeName.ManageRequestIdentities,
        ScopeName.ManageEmailTemplates,
        ScopeName.ManageLegalHold,
        // required to test DSR creation
        ScopeName.MakeDataSubjectRequest,
        ScopeName.ManageRequestCompilation,
        ScopeName.ViewRequests,
        ScopeName.RequestApproval,
        // Consent Manager
        ScopeName.ViewDataFlow,
        ScopeName.ManageConsentManager,
        ScopeName.ManageDataFlow,
        ScopeName.DeployConsentManager,
        ScopeName.DeployTestConsentManager,
        // Privacy Center
        ScopeName.DeployPrivacyCenter,
        ScopeName.ManagePrivacyCenter,
        // Auditor
        ScopeName.ManageAuditor,
        ScopeName.ExecuteAuditor,
        // Code Scanning
        ScopeName.ViewCodeScanning,
        // Pathfinder
        ScopeName.ManagePathfinder,
        // Preference store
        ScopeName.ManageStoredPreferences,
        // Audit events
        ScopeName.ViewAuditEvents,
        // action items
        ScopeName.ManageActionItemCollections,
        ScopeName.ManageAllActionItems,
        // Custom fields
        ScopeName.ManageGlobalAttributes,
        // Assessments
        ScopeName.ManageAssessments,
        ScopeName.ApproveAssessments,
        // Prompts
        ScopeName.ManagePrompts,
        ScopeName.ExecutePrompt,
        ScopeName.ApprovePrompts,
        ScopeName.ViewPromptRuns,
        // Contract scanning
        ScopeName.ManageContractScanning,
      ]),
    },
    [StaticTeamType.ItInfoSec]: {
      name: 'IT/InfoSec',
      staticType: StaticTeamType.ItInfoSec,
      description:
        'Responsible for managing IT related settings like DNS, connecting systems, setting up SSO.',
      scopes: uniq([
        ScopeName.ConnectDataSilos,
        ScopeName.DeployPrivacyCenter,
        // consent manager
        ScopeName.DeployConsentManager,
        ScopeName.DeployTestConsentManager,
        ScopeName.ManageDataFlow,
        ScopeName.ManageConsentManagerDeveloperSettings,
        // access control
        ScopeName.ManageAccessControl,
        ScopeName.ManageApiKeys,
        ScopeName.ManageOrganizationInfo,
        ScopeName.ManageRequestSecurity,
        ScopeName.ManageSombraRootKeys,
        ScopeName.ManageSSO,
        ScopeName.ManageEmailDomains,
        ScopeName.ViewAuditEvents,
        ScopeName.ManageActionItemCollections,
        // data map
        ScopeName.ManageDataMap,
        ScopeName.ManageDataInventory,
        ScopeName.ManageGlobalAttributes,
        // assessments
        ScopeName.ViewAssessments,
        ScopeName.ManageAssignedAssessments,
        // privacy center
        ScopeName.ManagePrivacyCenter,
        ScopeName.DeployPrivacyCenter,
        // prompt runs
        ScopeName.ViewPromptRuns,
        ScopeName.ManagePrompts,
        // auditor
        ScopeName.ManageAuditor,
        ScopeName.ExecuteAuditor,
        // code scanning
        ScopeName.ManageCodeScanning,
        // pathfinder
        ScopeName.ManagePathfinder,
      ]),
    },
    [StaticTeamType.DatabaseAdminDataEngineering]: {
      name: 'Database Admin/Data Engineering',
      staticType: StaticTeamType.DatabaseAdminDataEngineering,
      description:
        'Database administrators responsible for maintaining database connections to first party data stores.',
      scopes: uniq([
        // managing data silos
        ScopeName.ConnectDataSilos,
        ScopeName.ViewApiKeys,
        ScopeName.ViewDataMap,
        ScopeName.ManageApiKeys,
        ScopeName.ManageDataInventory,
        ScopeName.ManageDataMap,
      ]),
    },
    [StaticTeamType.CloudInfraArchitecture]: {
      name: 'Cloud Infra/Architecture',
      staticType: StaticTeamType.CloudInfraArchitecture,
      description:
        'Responsible for overseeing cloud architecture and connections between Transcend and data systems.',
      scopes: uniq([
        // managing data silos
        ScopeName.ConnectDataSilos,
        ScopeName.ViewApiKeys,
        ScopeName.ViewDataMap,
        ScopeName.ManageApiKeys,
        ScopeName.ManageDataInventory,
        ScopeName.ManageDataMap,
        // access control
        ScopeName.ManageOrganizationInfo,
        ScopeName.ManageRequestSecurity,
        ScopeName.ManageSombraRootKeys,
        ScopeName.ManageSSO,
        ScopeName.ManageEmailDomains,
        ScopeName.ViewAuditEvents,
        ScopeName.ManageActionItemCollections,
        // Consent Manager
        ScopeName.ViewDataFlow,
        ScopeName.ManageConsentManager,
        ScopeName.ManageDataFlow,
        ScopeName.DeployConsentManager,
        ScopeName.DeployTestConsentManager,
      ]),
    },
    [StaticTeamType.ProjectManagers]: {
      name: 'Project Managers',
      description: 'Responsible for overseeing the Transcend implementation',
      staticType: StaticTeamType.ProjectManagers,
      scopes: uniq([ScopeName.FullAdmin]),
    },
    [StaticTeamType.Marketing]: {
      name: 'Marketing',
      staticType: StaticTeamType.Marketing,
      description:
        'Responsible for overseeing advertising technologies used by the company.',
      scopes: uniq([
        // Consent Manager
        ScopeName.ViewDataFlow,
        ScopeName.ManageConsentManager,
        ScopeName.ManageDataFlow,
        ScopeName.DeployConsentManager,
        ScopeName.DeployTestConsentManager,
        // Data Mapping
        ScopeName.ConnectDataSilos,
        // Assigned
        ScopeName.ManageAssignedDataInventory,
        ScopeName.ManageAssignedIntegrations,
        ScopeName.ManageAssignedAssessments,
        ScopeName.ManageAssignedRequests,
      ]),
    },
    [StaticTeamType.SystemOwners]: {
      name: 'System Owners',
      staticType: StaticTeamType.SystemOwners,
      description: 'Responsible for connecting their systems to Transcend',
      scopes: uniq([
        /// Data Mapping
        ScopeName.ConnectDataSilos,
        // Assigned
        ScopeName.ManageAssignedDataInventory,
        ScopeName.ManageAssignedIntegrations,
        ScopeName.ManageAssignedAssessments,
        ScopeName.ManageAssignedRequests,
      ]),
    },
    [StaticTeamType.FrontendDeveloper]: {
      name: 'Front-End Developer',
      staticType: StaticTeamType.FrontendDeveloper,
      description: 'Responsible for managing consent manager',
      scopes: uniq([
        // Consent Manager
        ScopeName.ViewDataFlow,
        ScopeName.ManageConsentManagerDeveloperSettings,
        ScopeName.ManageConsentManagerDisplaySettings,
        ScopeName.ManageDataFlow,
        ScopeName.DeployConsentManager,
        ScopeName.DeployTestConsentManager,
        // Data Mapping
        ScopeName.ViewAssignedDataInventory,
        ScopeName.ViewAssignedIntegrations,
        ScopeName.ViewAssignedIntegrations,
        ScopeName.ViewAssignedAssessments,
        // Preference store
        ScopeName.ManageStoredPreferences,
        // Audit events
        ScopeName.ViewAuditEvents,
        // Custom fields
        ScopeName.ManageGlobalAttributes,
        // Assessments
        ScopeName.ManageAssignedAssessments,
        // action items
        ScopeName.ManageActionItemCollections,
      ]),
    },
  };

/**
 * The statuses that count as 'pending'
 */
export const EMAIL_DOMAIN_PENDING_STATUSES = new Set([
  EmailDomainVerificationStatus.Pending,
  EmailDomainVerificationStatus.NotStarted,
]);

export const TOTAL_SCOPE_COUNT = Object.values(TRANSCEND_SCOPES).length;
/* eslint-enable max-lines */
