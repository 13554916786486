import { DataViewWrapper, Table, TableWrapper } from '@main/ad-core-components';
import { CodePackageOrderField, endpoints } from '@main/code-scanning-types';
import { buildUseQuery, ErrorAlert, usePagination } from '@main/core-ui';
import { MOBILE_SDK_CODE_PACKAGE_TYPES } from '@main/datamap-types';
import { OrderDirection } from '@main/schema-utils';
import React from 'react';

import {
  DESCRIPTION_COLUMN,
  NAME_COLUMN,
  RELATIVE_PATH_COLUMN,
  REPOSITORY_COLUMN,
  SOFTWARE_DEVELOPMENT_KITS_COLUMN,
  TYPE_COLUMN,
} from '../../../CodeScanning/CodePackages/columns';
import { ACTIONS_COLUMN } from './columns';
import {
  codePackagesMessages,
  triageApplicationsColumnHeaderMessages,
} from './messages';

const useCodePackages = buildUseQuery(
  endpoints.codePackages,
  'TriageApplicationsCodePackages',
);

export const TriageApplications: React.FC = () => {
  const columns = [
    NAME_COLUMN,
    TYPE_COLUMN,
    DESCRIPTION_COLUMN,
    REPOSITORY_COLUMN,
    RELATIVE_PATH_COLUMN,
    SOFTWARE_DEVELOPMENT_KITS_COLUMN,
    ACTIONS_COLUMN,
  ];

  const filterBy = {
    linkedToConsentApp: false,
    types: MOBILE_SDK_CODE_PACKAGE_TYPES,
  };

  const paginationResult = usePagination({
    queryParam: 'page',
    resetPageOnChange: [filterBy],
  });

  const { currentPage, useMaxPage, pageSize } = paginationResult;

  const { data, loading, error } = useCodePackages({
    variables: {
      first: pageSize,
      offset: (currentPage - 1) * pageSize,
      filterBy,
      orderBy: [
        {
          field: CodePackageOrderField.Name,
          direction: OrderDirection.ASC,
        },
      ],
    },
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
  });

  useMaxPage(data?.totalCount ? data.totalCount / pageSize : undefined);

  return (
    <DataViewWrapper>
      {error ? (
        <ErrorAlert error={error} />
      ) : (
        <Table
          data={data?.nodes ?? []}
          freezeLeftmostColumns={3}
          columns={columns}
          columnHeaderMessages={triageApplicationsColumnHeaderMessages}
          paginationOptions={{
            paginationResult,
            recordCount: data?.totalCount ?? 0,
            recordCountMessage: codePackagesMessages.codePackagesCount,
          }}
          loading={loading}
          viewWrapper={TableWrapper}
          noDataMessage={codePackagesMessages.noDataMessage}
        />
      )}
    </DataViewWrapper>
  );
};
