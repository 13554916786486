import { Change } from 'diff';

/** Set of modules we show consent manager diffs of */
export enum ModuleKeyEnum {
  CORE = 'core',
  TCF = 'tcf',
  NATIVE_APP = 'nativeApp',
  MESSAGES = 'messages',
  CSS = 'stylesheet',
}

/** Set of module string file types we're diffing */
const ModuleStringFileTypesEnum = {
  CSS: 'css',
} as const;

/** Set of all module file types we're diffing */
export const ModuleFileTypesEnum = {
  ...ModuleStringFileTypesEnum,
  JSON: 'json',
} as const;

/** Module file type object type alias */
type ModuleFileTypesEnumI = typeof ModuleFileTypesEnum;

/** Module file type enum values */
export type ModuleFileTypes = ModuleFileTypesEnumI[keyof ModuleFileTypesEnumI];

/** Module string file type object type alias */
type ModuleStringFileTypesEnumI = typeof ModuleStringFileTypesEnum;

/** Module string file type enum values */
export type ModuleStringFileTypes =
  ModuleStringFileTypesEnumI[keyof ModuleStringFileTypesEnumI];

/** Json module details object sent to the worker */
export interface JsonModuleDiffDetails {
  /** type of module file */
  moduleType: ModuleFileTypesEnumI['JSON'];
  /** diff object type */
  configs: [object, object];
}

/** Text module details object sent to the worker */
export interface TextModuleDiffDetails {
  /** type of module file */
  moduleType: ModuleStringFileTypes;
  /** diff text type */
  configs: [string, string];
}

/** Union of all diff detail types */
export type ModuleDiffDetails = JsonModuleDiffDetails | TextModuleDiffDetails;

/** Mapping of module keys to their associated diff detail types */
export type ModuleDiffRecord = Record<ModuleKeyEnum, ModuleDiffDetails>;

export interface ModuleDiffChangeResult {
  /** Change results from a JsonDiff call */
  changes: Change[];
  /** Whether the current config is deeply equal to the pending config */
  changesMade: true;
}

export interface ModuleDiffUnchangedResult {
  /** Whether the current config is deeply equal to the pending config */
  changesMade: false;
}

/** Union of changed and unchanged diff results */
export type ModuleDiffResult =
  | ModuleDiffChangeResult
  | ModuleDiffUnchangedResult;

/** Mapping of module enums to their diff result */
export type ModuleDiffResults = Record<ModuleKeyEnum, ModuleDiffResult>;
