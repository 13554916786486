import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { SampleData } from '@main/admin-dashboard/src/DataMap/ClassificationDetails/useSampleData';

/**
 * Slice to manage the SetSubdatapointSettings
 */
export interface SetSubdatapointSettingsState {
  /** The selected sample data */
  sampleData: SampleData[];
  /** loading state */
  loading: boolean;
}

export const setSubdatapointSettingsSlice = createSlice({
  name: 'SetSubdatapointSettings',
  initialState: {
    loading: true,
    sampleData: [],
  } as SetSubdatapointSettingsState,
  reducers: {
    setSampleData: (
      state,
      {
        payload: { sampleData, loading },
      }: PayloadAction<SetSubdatapointSettingsState>,
    ) => ({
      ...state,
      loading,
      sampleData,
    }),
  },
});

export const { setSampleData } = setSubdatapointSettingsSlice.actions;
