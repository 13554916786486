import { PaginatedSelect } from '@main/ad-core-components';
import type { ConsentPartition } from '@main/cm-types';
import { endpoints } from '@main/cm-types';
import { buildUseInfiniteScroll } from '@main/core-ui';
import type { ID } from '@main/schema-utils';
import React, { useEffect, useState } from 'react';
import { MenuPosition } from 'react-select';

import { SelectPartitionWrapper } from '../../ConsentManager/DeveloperSettings/components/SetConsentPartition/wrappers';

interface SelectPartitionsProps {
  /** The selected partitions */
  selectedPartitions: ConsentPartition[];
  /** Callback for selecting a partition */
  onChange: (partitions: ConsentPartition[]) => void;
  /** Loading status for the onChange callback */
  loading: boolean;
  /** How to position the menu (see docs for React Select) */
  menuPosition?: MenuPosition;
}

const usePartitions = buildUseInfiniteScroll(endpoints.consentPartitions);

/**
 * Component for selecting from a partition
 */
export function SelectPartitions({
  selectedPartitions,
  onChange,
  loading,
  menuPosition,
}: SelectPartitionsProps): JSX.Element {
  const [searchText, setSearchText] = useState('');
  const [selected, setSelected] = useState(selectedPartitions);

  const {
    data,
    loading: partitionsLoading,
    error,
    fetchMore,
  } = usePartitions({
    variables: {
      filterBy: {
        name: searchText,
      },
    },
    fetchPolicy: 'cache-and-network',
  });

  useEffect(() => {
    setSelected(selectedPartitions);
  }, [selectedPartitions]);

  return (
    <SelectPartitionWrapper>
      <PaginatedSelect<ConsentPartition, true>
        id="select-partitions"
        value={selected}
        fetchMore={fetchMore}
        onEndsTyping={setSearchText}
        options={data?.nodes ?? []}
        isQueryLoading={partitionsLoading}
        loading={loading}
        showOutline
        isMulti
        queryError={error}
        onChange={(value) => {
          onChange(value as any as ConsentPartition[]);
          setSelected(value as any as ConsentPartition[]);
        }}
        getOptionValue={({ id }) => id}
        getOptionLabel={({ name }) => name}
        getNewOptionData={(inputValue) => ({
          name: inputValue,
          // Fake values because new input
          id: '' as ID<'airgapPartition'>,
          partition: '',
        })}
        menuPosition={menuPosition}
        isClearable
      />
    </SelectPartitionWrapper>
  );
}
