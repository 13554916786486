import { mkInput, mkType, SchemaToType } from '@main/schema-utils';

import { AnalyticsDataSource, AnalyticsGroupBy } from './enums';

export const AnalyticsInput = mkInput({
  name: 'AnalyticsInput',
  comment: 'Input for analytics data endpoint',
  fields: {
    dataSource: {
      comment: 'The data source to use when fetching data',
      type: { AnalyticsDataSource },
    },
    startDate: {
      comment: 'The start date of the analytics data',
      type: 'Date',
      optional: true,
    },
    endDate: {
      comment: 'The end date of the analytics data',
      type: 'Date',
      optional: true,
    },
    groupBy: {
      comment: 'How to group the data',
      type: { AnalyticsGroupBy },
      optional: true,
    },
    attributeKey: {
      comment: 'The key of the attribute to filter to',
      type: 'string',
      optional: true,
    },
    airgapBundleId: {
      comment: 'The id of the airgap bundle',
      type: 'id',
      modelName: 'airgapBundle',
      optional: true,
    },
    dataPointId: {
      comment: 'The id of the dataPoint to fetch data for',
      type: 'id',
      modelName: 'dataPoint',
      optional: true,
    },
    dataSiloId: {
      comment: 'The id of the data silo to fetch data for',
      type: 'id',
      modelName: 'dataSilo',
      optional: true,
    },
    forceRefetch: {
      comment: 'Should ignore the cache and force a fetch?',
      type: 'boolean',
      optional: true,
    },
    binInterval: {
      comment: 'The potential bin intervals to use. Defaults to 1d (1 day)',
      type: 'string',
      optional: true,
    },
    binIntervalNum: {
      comment:
        'The potential bin intervals to use as a number. Defaults to ONE_DAY/1000 ',
      type: 'float',
      optional: true,
    },
    smoothTimeseries: {
      comment:
        'Whether or not to smooth the timeseries data. Defaults to true.',
      type: 'boolean',
      optional: true,
    },
    isTestRequest: {
      comment: 'Whether or not to show test requests. Defaults to false.',
      type: 'boolean',
      optional: true,
    },
    uniqueId: {
      comment: 'The unique id for the backend request',
      type: 'string',
      optional: true,
    },
    appId: {
      comment: 'The app id to filter to',
      type: 'string',
      optional: true,
    },
    runId: {
      comment: 'The id of the auditor run to fetch data for',
      type: 'string',
      optional: true,
    },
  },
});

/** Override type */
export type AnalyticsInput = SchemaToType<typeof AnalyticsInput>;

export const AnalyticsMetadata = mkType({
  name: 'AnalyticsMetadata',
  comment: 'Metadata for a specific analytics source',
  fields: {
    percentChange: {
      comment: 'The percent change since the previous interval',
      type: 'float',
      optional: true,
    },
    summaryValue: {
      comment: 'The value that summarizes the entire dataset',
      type: 'float',
      optional: true,
    },
    effectiveDate: {
      comment:
        'The date after which this data is valid. Is an ISO string ' +
        'when an effective date, is "true" when no data is valid (i.e. ' +
        'current configuration is invalid to collect data)',
      type: 'string',
      optional: true,
    },
  },
});

/** Override type */
export type AnalyticsMetadata = SchemaToType<typeof AnalyticsMetadata>;

export const AnalyticsDataPoint = mkType({
  name: 'AnalyticsDataPoint',
  comment: 'A single data point',
  fields: {
    key: {
      comment: 'The key of the data point (usually x axis)',
      type: 'string',
      optional: true,
    },
    value: {
      comment: 'The value of the data point (usually y axis)',
      type: 'float',
    },
  },
});

/** Override type */
export type AnalyticsDataPoint = SchemaToType<typeof AnalyticsDataPoint>;

export const AnalyticsDataSeries = mkType({
  name: 'AnalyticsDataSeries',
  comment: 'A series of datapoints',
  fields: {
    name: {
      comment: 'The name of the data series',
      type: 'string',
    },
    points: {
      comment: 'The list of datapoints for the series',
      type: AnalyticsDataPoint,
      list: true,
    },
  },
});

/** Override type */
export type AnalyticsDataSeries = SchemaToType<typeof AnalyticsDataSeries>;

export const AnalyticsResult = mkType({
  name: 'AnalyticsResult',
  comment: 'Data and metadata for a specific analytics source',
  fields: {
    series: {
      comment: 'The list of data series',
      type: AnalyticsDataSeries,
      list: true,
    },
    metadata: {
      comment: 'The global metadata for the analytics data',
      type: AnalyticsMetadata,
    },
    cachedAt: {
      comment: 'The last time the data was fetched',
      type: 'Date',
      optional: true,
    },
  },
});

/** Override type */
export type AnalyticsResult = SchemaToType<typeof AnalyticsResult>;
