import { defineMessages } from '@transcend-io/internationalization';

import { RowUploadStatus } from './types';

export const uploadCsvMessages = defineMessages(
  'ad-core-components.UploadCsvTable.uploadCsv',
  {
    new: {
      defaultMessage: 'Upload from CSV',
    },
    description: {
      defaultMessage:
        'Make sure to format your CSV properly in order to successfully upload them to Transcend. ' +
        '{documentationSentence}Download our sample CSV template which is preset with one ' +
        'header row that includes all expected fields.',
    },
    uploadCompleted: {
      defaultMessage: 'Upload Completed!',
    },
    reset: {
      defaultMessage: 'Start Over',
    },
    deleteRecords: {
      defaultMessage: `Delete {count} {count, plural, one {row} other {rows}}`,
    },
    status: {
      defaultMessage: `Upload status`,
    },
    validation: {
      defaultMessage: 'Validation Results',
    },
    errorMessage: {
      defaultMessage: 'Error Message',
    },
    exportToCsv: {
      defaultMessage:
        'Download {count} {count, plural, one {error} other {errors}}',
    },
    downloadSampleCsv: {
      defaultMessage: 'Download CSV Template',
    },
    upload: {
      defaultMessage: 'Submit {count} {count, plural, one {row} other {rows}}',
    },
    invalidInputColumns: {
      defaultMessage: 'Columns with invalid input:',
    },
    missingColumns: {
      defaultMessage: 'Missing columns:',
    },
    failedToUpload: {
      defaultMessage: 'Failed to upload',
    },
    nonUniqueValuesFound: {
      defaultMessage: 'Non-unique values found:',
    },
    nonUniqueRow: {
      defaultMessage: 'Column: {columnName} - Rows: {rows}',
    },
    willImport: {
      defaultMessage: `This column is support in the CSV upload and will be imported.`,
    },
    willNotImport: {
      defaultMessage: `This column is NOT supported and will be ignored.`,
    },
    tooManyRows: {
      defaultMessage:
        'The uploaded CSV file has more than 20k rows. Please break this file up into smaller pieces or use our CLI tool.',
    },
    duplicateRowsError: {
      defaultMessage: 'Duplicate rows: {duplicateRows};',
    },
    invalidColumnsError: {
      defaultMessage: 'Invalid columns: {columnNames};',
    },
    missingColumnsError: {
      defaultMessage: 'Missing columns: {columnNames};',
    },
  },
);

export const rowUploadStatusMessages = defineMessages<RowUploadStatus>(
  'ad-core-components.UploadCsvTable.rowUploadStatus',
  {
    [RowUploadStatus.Error]: {
      defaultMessage: 'Error - {error}',
    },
    [RowUploadStatus.Success]: {
      defaultMessage: 'Success',
    },
    [RowUploadStatus.Pending]: {
      defaultMessage: 'Pending',
    },
  },
);
