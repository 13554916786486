import { AdminDashboardPrivateMiscPath } from '@main/access-control-types';
import { Button } from '@main/ad-core-components';
import { Icon, TranscendLogo } from '@main/core-ui';
import { SIDEBAR_TRANSITION } from '@main/theme';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useTheme } from 'styled-components';

import { selectUser } from '../../Auth/App/selectors';
import { AdminMenu } from './AdminMenu';
import { NavMenu } from './NavMenu/NavMenu';
import { LogoWrapper, NavWrapper, SidebarWrapper } from './wrappers';

const IS_SIDEBAR_EXPANDED_NAME = 'isSidebarExpanded';

/**
 * The sidebar menu for OrganizationPage
 *
 * @returns The sidebar
 */
export function Sidebar(): JSX.Element {
  const storedSidebarState = localStorage.getItem(IS_SIDEBAR_EXPANDED_NAME);
  const [isExpanded, setIsExpanded] = useState(
    storedSidebarState !== null ? storedSidebarState === 'true' : true,
  );
  const [isHovered, setIsHovered] = useState(false);
  const isExpandedOrHovered = isExpanded || isHovered;
  const user = useSelector(selectUser);
  const theme = useTheme();

  return (
    <SidebarWrapper
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      isExpanded={isExpandedOrHovered}
      style={{ position: 'relative' }}
    >
      <NavWrapper style={{ paddingRight: 0 }}>
        <Button
          variant="link"
          icon={
            <Icon
              color={theme.colors.transcendNavy2}
              size={20}
              type={
                isExpanded
                  ? 'arrow-left-back-square'
                  : 'arrow-right-back-square'
              }
            />
          }
          iconOnly
          onClick={() => {
            const newState = !isExpanded;
            setIsExpanded(newState);
            // clear hover state so sidebar can collapse
            setIsHovered(newState);
            // persist state
            localStorage.setItem(
              IS_SIDEBAR_EXPANDED_NAME,
              JSON.stringify(newState),
            );
          }}
          style={{
            position: 'absolute',
            right: 5,
            transitionProperty: 'opacity',
            marginTop: '1em',
            ...SIDEBAR_TRANSITION,
            opacity: isHovered ? 1 : 0,
          }}
        />
        <LogoWrapper>
          <Link
            to={
              user?.isAdmin
                ? AdminDashboardPrivateMiscPath.ActionItemsAll
                : AdminDashboardPrivateMiscPath.ActionItemsMine
            }
            style={{ width: 132, display: 'flex' }}
          >
            <TranscendLogo transcendSolid hideWordmark={!isExpandedOrHovered} />
          </Link>
        </LogoWrapper>
        <NavMenu wideNavbar={isExpandedOrHovered} />
      </NavWrapper>
      <AdminMenu isExpanded={isExpandedOrHovered} />
    </SidebarWrapper>
  );
}
