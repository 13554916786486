import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { StepStatus } from '@main/datamap-types';
import type { ID } from '@main/schema-utils';

import type { FormInstance } from './types';

/**
 * The ConnectSilo redux store
 */
export interface ConnectSiloState {
  /** The currently open form  */
  current: number;
  /** Counter used when resetting form  */
  formKey: number;
  /** The id of the newly created data silo */
  dataSiloId?: ID<'dataSilo'>;
  /** Indicates if in a loading state */
  loading: boolean;
  /** The status of the step */
  status: StepStatus;
  /** The currently open form  */
  forms: FormInstance[];
}

export const connectSiloSlice = createSlice({
  name: 'ConnectSilo',
  initialState: {
    current: 0,
    formKey: 0,
    loading: false,
    status: StepStatus.Process,
    forms: [],
  } as ConnectSiloState,
  reducers: {
    // Set the error if it occurs
    setError: (state) => ({
      ...state,
      status: StepStatus.Error,
      loading: false,
    }),
    // Set the loading state
    setLoading: (state, { payload: loading }: PayloadAction<boolean>) => ({
      ...state,
      status: loading ? StepStatus.Process : state.status,
      loading,
    }),
    // When the forms change, reset redux
    reset: (
      state,
      { payload: forms }: PayloadAction<FormInstance[] | undefined>,
    ) => ({
      ...state,
      forms: forms || state.forms,
      current: 0,
      formKey: state.formKey + 1,
      loading: false,
      status: StepStatus.Process,
      apiKey: undefined,
      dataSiloId: undefined,
    }),
    // Set the newly created data silo
    setDataSiloId: (
      state,
      {
        payload: { dataSiloId, current },
      }: PayloadAction<{
        /** ID of data silo */
        dataSiloId: ID<'dataSilo'>;
        /** Current form index */
        current: number;
      }>,
    ) => ({
      ...state,
      dataSiloId,
      current,
      loading: false,
      apiKey: undefined,
    }),
  },
});

export const { setError, reset, setLoading, setDataSiloId } =
  connectSiloSlice.actions;
