import * as t from 'io-ts';

import { valuesOf } from '@transcend-io/type-utils';

import { schemaToCodec } from '@main/schema-utils/src/schemaToCodec';

import { AuditorFinding } from './schema/auditorRun';
import { AuditorGQLRegionEnum, SameSite } from './schema/enums';

/**
 * Type for network requests
 */
export const Request = t.intersection([
  t.type({
    /**
     * The request HTTP headers.
     * Note: security-related headers, including cookie-related ones, are not returned.
     */
    headers: t.record(t.string, t.string),

    /**
     * Whether this request is driving frame's navigation.
     */
    isNavigationRequest: t.boolean,

    /**
     * Request's method (GET, POST, etc.)
     */
    method: t.string,

    /**
     * The request's resource type.
     */
    resourceType: t.string,

    /**
     * URL of the request.
     */
    url: t.string,
  }),
  t.partial({
    /**
     * Request's post body, if any.
     */
    postData: t.string,
    /*
     * Was the request seen by airgap?
     */
    regulatedByAirgap: t.boolean,
    /** Purposes reported by airgap */
    purposes: t.array(t.string),
  }),
]);

/** Type override */
export type Request = t.TypeOf<typeof Request>;

/**
 * Type for Cookies
 */
export const Cookie = t.intersection([
  t.type({
    /**
     * The name of the cookie
     */
    name: t.string,

    /**
     * The value of the cookie
     */
    value: t.string,

    /**
     * The domain of the cookie
     */
    domain: t.string,

    /**
     * The path of the cookie
     */
    path: t.string,

    /**
     * Whether the cookie is HTTP only
     */
    httpOnly: t.boolean,

    /**
     * Whether the cookie is secure
     */
    secure: t.boolean,

    /**
     * The SameSite attribute of the cookie
     */
    sameSite: valuesOf(SameSite),
  }),
  t.partial({
    /**
     * The expiration of the cookie in Unix time in seconds
     */
    expires: t.number,
    /*
     * Was the cookie seen by airgap?
     */
    regulatedByAirgap: t.boolean,
    /** Purposes reported by airgap */
    purposes: t.array(t.string),
  }),
]);

/** Type override */
export type Cookie = t.TypeOf<typeof Cookie>;

// Playwright has a lot of types that aren't exported, so we have to use some of our own definitions
export const ColorScheme = t.union([
  t.literal('light'),
  t.literal('dark'),
  t.literal('no-preference'),
  t.null,
]);

/** Type override */
export type ColorScheme = t.TypeOf<typeof ColorScheme>;

export const DeviceType = t.union([
  t.literal('Desktop Chrome HiDPI'),
  t.literal('iPhone 14 Pro Max'),
  t.literal('iPad Pro 11'),
]);

/** Type override */
export type DeviceType = t.TypeOf<typeof DeviceType>;

/** A selector for an element of a browser page */
export const ElementSelector = t.union([
  t.type({
    text: t.string,
  }),
  t.type({
    buttonName: t.string,
  }),
  t.type({
    linkName: t.string,
  }),
  t.type({
    randomLink: t.literal(true),
  }),
  t.type({
    label: t.string,
  }),
  t.type({
    placeholder: t.string,
  }),
  t.type({
    altText: t.string,
  }),
  t.type({
    selector: t.string,
  }),
]);

/** Type override */
export type ElementSelector = t.TypeOf<typeof ElementSelector>;

/** A browser action step */
export const StepAction = t.union([
  t.type({
    action: t.literal('click'),
    element: ElementSelector,
  }),
  t.type({
    action: t.literal('type'),
    element: ElementSelector,
    text: t.string,
  }),
  t.type({
    action: t.literal('hover'),
    element: ElementSelector,
  }),
  t.type({
    action: t.literal('key'),
    type: t.union([t.literal('Up'), t.literal('Down'), t.literal('Enter')]),
  }),
  t.type({
    action: t.literal('navigate'),
    url: t.string,
  }),
  t.type({
    action: t.literal('scroll'),
    x: t.number,
    y: t.number,
  }),
  t.type({
    action: t.literal('scrollRandomly'),
  }),
  t.type({
    action: t.literal('sleep'),
    duration: t.number,
  }),
]);

/** Type override */
export type StepAction = t.TypeOf<typeof StepAction>;

export const StepActions = t.array(StepAction);

/** Type override */
export type StepActions = t.TypeOf<typeof StepActions>;

export const AirgapProtectionStatus = t.union([
  // Airgap protection is active
  t.literal('protected'),
  // Airgap protection is inactive
  t.literal('unprotected'),
  // Airgap is not present on the site at all
  t.literal('absent'),
]);

/** Type override */
export type AirgapProtectionStatus = t.TypeOf<typeof AirgapProtectionStatus>;

export const AuditorFindingCodec = schemaToCodec(AuditorFinding);

/** Type override */
export type AuditorFindingCodec = t.TypeOf<typeof AuditorFindingCodec>;

export const AuditorGQLRegionCodec = valuesOf(AuditorGQLRegionEnum);

/** Type override */
export type AuditorGQLRegionCodec = t.TypeOf<typeof AuditorGQLRegionCodec>;
