import { defineMessages } from '@transcend-io/internationalization';

/**
 * SearchMessages
 */
export const searchMessages = defineMessages(
  'ad-core-components.Search.search',
  {
    placeholder: {
      defaultMessage: 'Search',
    },
  },
);
