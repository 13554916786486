import { transparentize } from 'polished';
import styled from 'styled-components';

/**
 * Settings item title
 */
export const TitleWrapper = styled.div`
  margin: 0 0 4px 0;
  padding: 0;
  font-size: 14px;
  line-height: 22px;
  color: ${({ theme }) => transparentize(0.35, theme.colors.black)};
  font-weight: 600;
`;

/**
 * Settings item description
 */
export const DescriptionWrapper = styled.div`
  margin: 0;
  padding: 0;
  font-size: 14px;
  line-height: 22px;
  max-width: 400px;
  color: ${({ theme }) => transparentize(0.5, theme.colors.black)};
`;

/**
 * Wrapper for settings item label
 */
export const LabelWrapper = styled.div`
  flex: 1;
`;

/**
 * Wrapper a settings item row
 */
export const SettingsItem = styled.div`
  display: flex;
  align-items: center;
  padding: 10px;
`;

/**
 * Wrapper for selects
 */
export const SelectWrapper = styled.div`
  max-width: 400px;
  width: 100%;
`;

/**
 * Wrapper a settings block
 */
export const SettingsWrapper = styled.div`
  border-bottom: 1px solid
    ${({ theme }) => transparentize(0.3, theme.colors.transcendNavy4)};
`;
