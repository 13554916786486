import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import difference from 'lodash/difference';
import uniq from 'lodash/uniq';

import type { ID } from '@main/schema-utils';

export interface LegalHoldState {
  /** The selected legal holds */
  selectedLegalHolds: ID<'legalHold'>[];
  /** The selected legal matters */
  selectedLegalMatters: ID<'legalMatter'>[];
}

export const legalHoldSlice = createSlice({
  name: 'LegalHold',
  initialState: {
    selectedLegalHolds: [],
    selectedLegalMatters: [],
  } as LegalHoldState,
  reducers: {
    selectLegalHold: (state, { payload }: PayloadAction<ID<'legalHold'>>) => {
      state.selectedLegalHolds.push(payload);
    },
    unselectLegalHold: (
      { selectedLegalHolds, ...state },
      { payload }: PayloadAction<ID<'legalHold'>>,
    ) => ({
      ...state,
      selectedLegalHolds: selectedLegalHolds.filter((id) => id !== payload),
    }),
    selectLegalHolds: (
      state,
      { payload }: PayloadAction<ID<'legalHold'>[]>,
    ) => {
      state.selectedLegalHolds = uniq(state.selectedLegalHolds.concat(payload));
    },
    unselectLegalHolds: (
      state,
      { payload }: PayloadAction<ID<'legalHold'>[]>,
    ) => {
      state.selectedLegalHolds = difference(state.selectedLegalHolds, payload);
    },
    resetSelectedLegalHolds: (state) => ({
      ...state,
      selectedLegalHolds: [],
    }),
    selectLegalMatter: (
      state,
      { payload }: PayloadAction<ID<'legalMatter'>>,
    ) => {
      state.selectedLegalMatters.push(payload);
    },
    unselectLegalMatter: (
      { selectedLegalMatters, ...state },
      { payload }: PayloadAction<ID<'legalMatter'>>,
    ) => ({
      ...state,
      selectedLegalMatters: selectedLegalMatters.filter((id) => id !== payload),
    }),
    selectLegalMatters: (
      state,
      { payload }: PayloadAction<ID<'legalMatter'>[]>,
    ) => {
      state.selectedLegalMatters = uniq(
        state.selectedLegalMatters.concat(payload),
      );
    },
    unselectLegalMatters: (
      state,
      { payload }: PayloadAction<ID<'legalMatter'>[]>,
    ) => {
      state.selectedLegalMatters = difference(
        state.selectedLegalMatters,
        payload,
      );
    },
    resetSelectedLegalMatters: (state) => ({
      ...state,
      selectedLegalMatters: [],
    }),
  },
});

export const {
  selectLegalHold,
  unselectLegalHold,
  selectLegalHolds,
  unselectLegalHolds,
  resetSelectedLegalHolds,
  selectLegalMatter,
  unselectLegalMatter,
  selectLegalMatters,
  unselectLegalMatters,
  resetSelectedLegalMatters,
} = legalHoldSlice.actions;
