import { DefaultTheme } from 'styled-components';

import { AttributeKeyType } from '@transcend-io/privacy-types';

import {
  AssessmentAttributeStatus,
  AssessmentSecondaryType,
  AttributeKey,
  AttributeValue,
} from '@main/attribute-types';
import { ColorPalette } from '@main/theme';

import { SelectAttributeOption } from './types';

export const ASSESSMENT_ATTRIBUTE_STATUS_COLOR_NAMES: {
  [status in AssessmentAttributeStatus]: keyof ColorPalette;
} = {
  [AssessmentAttributeStatus.NotNeeded]: 'gray4',
  [AssessmentAttributeStatus.Needed]: 'red1',
};

export const getAssessmentAttributeColorName = (
  option: SelectAttributeOption,
): keyof ColorPalette => {
  let color: keyof ColorPalette = 'gray1';
  if ('assessmentSecondaryType' in option) {
    switch (option.assessmentSecondaryType) {
      case AssessmentSecondaryType.Status: {
        color =
          ASSESSMENT_ATTRIBUTE_STATUS_COLOR_NAMES[
            option.name as AssessmentAttributeStatus
          ];
        break;
      }
      case AssessmentSecondaryType.Assessment: {
        color = 'gray1';
        break;
      }
      case AssessmentSecondaryType.Url: {
        color = 'white';
        break;
      }
      default:
        color = 'gray1';
    }
  }
  return color;
};

export const getAttributeValueColorName = (
  option: SelectAttributeOption,
): keyof ColorPalette =>
  option.attributeKey?.type === AttributeKeyType.Assessment
    ? getAssessmentAttributeColorName(option)
    : (option.color as keyof ColorPalette) ?? 'transcendNavy2';

// Generates Color map via attribute values color field
export const ATTRIBUTE_VALUE_MAP: (
  theme: DefaultTheme,
) => (option: SelectAttributeOption) => string = (theme) => (option) =>
  theme.colors[getAttributeValueColorName(option)];

export const ATTRIBUTE_KEY_ASSESSMENT_FORM_TEMPLATE_FIELDS = {
  title: null,
};
export const ATTRIBUTE_VALUE_ASSESSMENT_FORM_FIELDS = {
  id: null,
  status: null,
};
export const ATTRIBUTE_KEY_FIELDS = {
  id: null,
  name: null,
  type: null,
  assessmentFormTemplate: ATTRIBUTE_KEY_ASSESSMENT_FORM_TEMPLATE_FIELDS,
};

export const ATTRIBUTE_VALUE_FIELDS = {
  id: null,
  name: null,
  color: null,
  description: null,
  canDelete: null,
  assessmentSecondaryType: null,
  attributeKey: ATTRIBUTE_KEY_FIELDS,
  assessmentForm: ATTRIBUTE_VALUE_ASSESSMENT_FORM_FIELDS,
};

/**
 * An attribute key with only the fields necessary to support the SelectAttribute functionality
 */
export type AttributeKeyPreview = Pick<
  AttributeKey,
  keyof typeof ATTRIBUTE_KEY_FIELDS
>;

/**
 * An attribute value with only the fields necessary to support the SelectAttribute functionality
 */
export type AttributeValuePreview = Pick<
  AttributeValue,
  keyof typeof ATTRIBUTE_VALUE_FIELDS
> & {
  /** The associated attribute key with only the fields necessary to support the SelectAttribute functionality */
  attributeKey: AttributeKeyPreview;
};
