import * as t from 'io-ts';

import { dbModelId } from '@main/schema-utils';

import { AuditEventCode } from '../schema/enums';
import { mkAuditEventCodec } from '../types';

export const AuditEventRiskMatrixColumn = mkAuditEventCodec(
  AuditEventCode.RiskMatrixColumn,
  t.intersection([
    t.type({
      /** ID of the risk matrix column */
      riskMatrixColumnId: dbModelId('riskMatrixColumn'),
    }),
    t.partial({
      /** ID of the risk framework to which the risk matrix column belongs */
      riskFrameworkId: dbModelId('riskFramework'),
    }),
  ]),
);

/** Override types. */
export type AuditEventRiskMatrixColumn = t.TypeOf<
  typeof AuditEventRiskMatrixColumn
>;
