import { formatErrorMessage, message } from '@main/core-ui';
import Papa from 'papaparse';
import React from 'react';
import { useIntl } from 'react-intl';
import * as streamSaver from 'streamsaver';

import { Button } from '../Button';
import { uploadCsvMessages } from './messages';
import { RowErrorDetails, RowWithUploadStatus } from './types';

/**
 * Download any rows that are in an error state
 */
export function DownloadErroredRows<T extends {}>({
  erroredRows,
}: {
  /** all errors within the csv */
  erroredRows: RowWithUploadStatus<T>[];
}): JSX.Element {
  const { formatMessage } = useIntl();

  const getDuplicateRowsMessage = (
    errorDetails: RowErrorDetails | undefined,
  ): string | null => {
    const rowEntries = Object.entries(errorDetails?.duplicateRows ?? {});
    if (rowEntries.length > 0) {
      const rows = rowEntries
        .map(([colName, rows]) =>
          formatMessage(uploadCsvMessages.nonUniqueRow, {
            columnName: `'${colName}'`,
            rows: rows.join(', '),
          }),
        )
        .join(', ');
      return formatMessage(uploadCsvMessages.duplicateRowsError, {
        duplicateRows: rows,
      });
    }
    return null;
  };

  const getInvalidInputColumnsMessage = (
    errorDetails: RowErrorDetails | undefined,
  ): string | null => {
    const invalidColumns = errorDetails?.invalidInputColumns ?? [];
    if (invalidColumns.length > 0) {
      return formatMessage(uploadCsvMessages.invalidColumnsError, {
        columnNames: invalidColumns.map((col) => `'${col}'`).join(', '),
      });
    }
    return null;
  };

  const getMissingColumnsMessage = (
    errorDetails: RowErrorDetails | undefined,
  ): string | null => {
    const missingColumns = errorDetails?.missingColumns ?? [];
    if (missingColumns.length > 0) {
      return formatMessage(uploadCsvMessages.missingColumnsError, {
        columnNames: missingColumns.map((col) => `'${col}'`).join(', '),
      });
    }
    return null;
  };

  const getErrorMessage = (errorDetails: RowErrorDetails | undefined): string =>
    [
      getDuplicateRowsMessage(errorDetails),
      getInvalidInputColumnsMessage(errorDetails),
      getMissingColumnsMessage(errorDetails),
    ]
      .filter((message) => !!message)
      .join(' ');

  return (
    <React.Fragment>
      <Button
        variant="secondary"
        id="download-errors-csv"
        onClick={async () => {
          const finalFileName = `failing-csv-uploads-errors-${new Date().toDateString()}.csv`;
          const fileHandle = streamSaver.createWriteStream(finalFileName);
          const writer = fileHandle.getWriter();
          const textEncoder = new TextEncoder();

          const firstPass = true;

          const filteredErroredRows = erroredRows.map((row) => {
            const {
              /* eslint-disable @typescript-eslint/no-unused-vars */
              columnIsValidMap,
              key,
              uploadStatus,
              errorMessage,
              /* eslint-enable @typescript-eslint/no-unused-vars */
              errorDetails,
              ...rest
            } = row;

            const validationColumnName = formatMessage(
              uploadCsvMessages.validation,
            );
            const errorColumnName = formatMessage(
              uploadCsvMessages.errorMessage,
            );

            return {
              ...rest,
              [validationColumnName]: getErrorMessage(errorDetails),
              [errorColumnName]: errorMessage
                ? formatErrorMessage(errorMessage)
                : undefined,
            };
          });

          try {
            const str = Papa.unparse(filteredErroredRows, {
              header: firstPass,
              skipEmptyLines: true,
            });
            const encoded = textEncoder.encode(
              `${firstPass ? '' : '\r\n'}${str}`,
            );

            await writer.write(encoded);
            // for some reason writes don't flush immediately (even when awaiting on
            // the .write() call), so we need to wait for an arbitrary amount of time
            // for them to flush correctly before saving the file
            setTimeout(() => {
              // no need for an await bc it's in a timeout
              writer.close();
            }, 500);
          } catch (e) {
            message.error(formatErrorMessage(e.message));
            await writer.abort();
          }
        }}
        disabled={erroredRows.length === 0}
      >
        {formatMessage(uploadCsvMessages.exportToCsv, {
          count: erroredRows.length,
        })}
      </Button>
    </React.Fragment>
  );
}
