import {
  FlexColumn,
  FlexRowCenterBoth,
  GenericMessageDescriptor,
  StyleUtils,
  useFormatMessageGeneric,
} from '@main/core-ui';
import React from 'react';

import { Button, ButtonProps, commonButtonMessages } from '../Button';
import { Modal, ModalProps } from '../Modal';
import { confirmModalMessages } from './messages';

export interface ConfirmModalProps extends ModalProps {
  /** The content */
  content?: GenericMessageDescriptor;
  /** The label for the confirm button */
  confirmLabel?: GenericMessageDescriptor;
  /** The variant of the confirm button */
  confirmVariant?: ButtonProps['variant'];
  /** The label for the cancel button */
  cancelLabel?: GenericMessageDescriptor;
  /** The variant of the cancel button  */
  cancelVariant?: ButtonProps['variant'];
  /** Callback to fire on confirmation */
  onConfirm: () => void;
}

export const ConfirmModal: React.FC<ConfirmModalProps> = ({
  header = confirmModalMessages.heading,
  content = confirmModalMessages.content,
  confirmLabel = commonButtonMessages.confirm,
  confirmVariant = 'primary',
  cancelLabel = commonButtonMessages.cancel,
  cancelVariant = 'secondary',
  onConfirm,
  onHide,
  ...modalProps
}) => {
  const { formatMessageGeneric } = useFormatMessageGeneric();

  return (
    <Modal onHide={onHide} {...modalProps} header={header} id="confirm-modal">
      <FlexColumn>
        <p>{formatMessageGeneric(content)}</p>
        <FlexRowCenterBoth
          style={{
            gap: StyleUtils.Spacing.md,
            marginTop: StyleUtils.Spacing.xl,
          }}
        >
          <Button onClick={onHide} variant={cancelVariant}>
            {formatMessageGeneric(cancelLabel)}
          </Button>
          <Button onClick={onConfirm} variant={confirmVariant}>
            {formatMessageGeneric(confirmLabel)}
          </Button>
        </FlexRowCenterBoth>
      </FlexColumn>
    </Modal>
  );
};
