import * as t from 'io-ts';

export const CookieMetadata = t.type({
  /** Name of the cookie */
  name: t.string,
  /** The tracking purposes of this cookie */
  trackingPurposes: t.array(t.string),
});

/** Type override */
export type CookieMetadata = t.TypeOf<typeof CookieMetadata>;

export const DataFlowMetadata = t.type({
  /** Value of this data flow */
  value: t.string,
  /** Scope of the data flow (which part of the request it applies to) */
  type: t.string,
  /** The tracking purposes of this data flow */
  trackingPurposes: t.array(t.string),
});

/** Type override */
export type DataFlowMetadata = t.TypeOf<typeof DataFlowMetadata>;

export const AirgapServiceMetadata = t.type({
  /** Title of this service */
  title: t.string,
  /** The URL to the logo of this service */
  logoSquare: t.union([t.null, t.string]),
  /** Description for this service */
  description: t.string,
  /** Associated cookies */
  cookies: t.array(CookieMetadata),
  /** Associated data flows */
  dataFlows: t.array(DataFlowMetadata),
});

/** Type override */
export type AirgapServiceMetadata = t.TypeOf<typeof AirgapServiceMetadata>;

/** Metadata about the services and cookies that airgap regulates */
export const AirgapMetadata = t.type({
  /** All the services regulated by Airgap */
  services: t.array(AirgapServiceMetadata),
});

/** Type override */
export type AirgapMetadata = t.TypeOf<typeof AirgapMetadata>;
