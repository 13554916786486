import { makeEnum } from '@transcend-io/type-utils';

import { FlagIcons } from './flags';

/**
 * The types of icons
 * !!! Add to this whenever a new icon is added !!!
 */
export const IconType = makeEnum({
  AddTable: 'add-table',
  Approved: 'approved',
  ArrowCurvedDownLeft: 'arrow-curved-down-left',
  ArrowDown: 'arrow-down',
  ArrowLeft: 'arrow-left',
  ArrowLeftBackSquare: 'arrow-left-back-square',
  ArrowRight: 'arrow-right',
  ArrowRightBackSquare: 'arrow-right-back-square',
  ArrowRotate: 'arrow-rotate',
  ArrowsMaximize: 'arrows-maximize',
  ArrowUp: 'arrow-up',
  AssessmentTemplate: 'assessment-template',
  Binary: 'binary',
  Bold: 'bold',
  Book: 'book',
  BrowserWebEye: 'browser-web-eye',
  BuildingHomeHouseRealEstate: 'building-home-house-real-estate',
  Bucket: 'bucket',
  BulletedList: 'bulleted-list',
  Calendar: 'calendar',
  CaretDouble: 'caret-double',
  CaretDown: 'caret-down',
  CaretLeft: 'caret-left',
  CaretRight: 'caret-right',
  CaretUp: 'caret-up',
  ChangesRequested: 'changes-requested',
  ChatConversationQuestion: 'chat-conversation-question',
  CheckmarkBubble: 'checkmark-bubble',
  CheckmarkCircle: 'checkmark-circle',
  CheckmarkGear: 'checkmark-gear',
  Checkmark: 'checkmark',
  CircleAdd: 'circle-add',
  CircleDottedLine: 'circle-dotted-line',
  CircleQuestion: 'circle-question',
  ClearFormatting: 'clear-formatting',
  ClipboardCheck: 'clipboard-check',
  Clock: 'clock',
  ClockAndDataSilo: 'clock-and-data-silo',
  ClockArrowBack: 'clock-arrow-back',
  Close: 'close',
  CloseThick: 'close-thick',
  CodeMonitor: 'code-monitor',
  Columns: 'columns',
  Comment: 'comment',
  Compass: 'compass',
  Copy: 'copy',
  CopyAddPlus: 'copy-add-plus',
  CopyItemCheckmark: 'copy-item-checkmark',
  CopySimple: 'copy-simple',
  CreateFile: 'create-file',
  Cube: 'cube',
  CubeGrid: 'cube-grid',
  Database: 'database',
  DatabaseX: 'database-x',
  DataSiloAdd: 'data-silo-add',
  DocumentCheckmark: 'document-checkmark',
  DocumentContractEditPen: 'document-contract-edit-pen',
  DocumentCross: 'document-cross',
  DocumentLegalUsers: 'document-legal-users',
  DocumentQuestion: 'document-question',
  DocumentSearch: 'document-search',
  DotsVertical: 'dots-vertical',
  Download: 'download',
  Draft: 'draft',
  DsrTypeAccess: 'dsr-type-access',
  DsrTypeAutomatedDecisionMakingOptOut:
    'dsr-type-automated-decision-making-opt-out',
  DsrTypeBusinessPurpose: 'dsr-type-business-purpose',
  DsrTypeContactOptOut: 'dsr-type-contact-opt-out',
  DsrTypeErasure: 'dsr-type-erasure',
  DsrTypeRectification: 'dsr-type-rectification',
  DsrTypeRestriction: 'dsr-type-restriction',
  DsrTypeSaleOptOut: 'dsr-type-sale-opt-out',
  DsrTypeTrackingOptOut: 'dsr-type-tracking-opt-out',
  EditFile: 'edit-file',
  Ellipsis: 'ellipsis',
  EmailLetterMailNotification: 'email-letter-mail-notification',
  EmailLetterMore: 'email-letter-more',
  EmployeeBadge: 'employee-badge',
  Envelope: 'envelope',
  ExclamationCircle: 'exclamation-circle',
  ExclamationTriangle: 'exclamation-triangle',
  ExternalLink: 'external-link',
  Eye: 'eye',
  EyeCheckmark: 'eye-checkmark',
  EyeSlash: 'eye-slash',
  File: 'file',
  FileCheckmark: 'file-checkmark',
  FileExclamation: 'file-exclamation',
  FileExport: 'file-export',
  FileFolderStack: 'file-folder-stack',
  FileImport: 'file-import',
  FileLoad: 'file-load',
  Filter: 'filter',
  Fire: 'fire',
  FlagCheckmark: 'flag-checkmark',
  Folder: 'folder',
  FolderPlus: 'folder-plus',
  FloppyDisk: 'floppy-disk',
  Gear: 'gear',
  Glasses: 'glasses',
  GlobeCube: 'globe-cube',
  Grid: 'grid',
  HamburgerMenu: 'hamburger-menu',
  HandShapes: 'hand-shapes',
  HighRiseBuilding: 'high-rise-building',
  ICircle: 'i-circle',
  Image: 'image',
  InReview: 'in-review',
  InsertTable: 'insert-table',
  InsertTableColumnLeft: 'insert-table-column-left',
  InsertTableColumnRight: 'insert-table-column-right',
  InsertTableRowAbove: 'insert-table-row-above',
  InsertTableRowBelow: 'insert-table-row-below',
  Italic: 'italic',
  Junk: 'junk',
  Key: 'key',
  LanguageTranslate: 'language-translate',
  Lightning: 'lightning',
  LightningBox: 'lightning-box',
  LightningMovingFast: 'lightning-moving-fast',
  LinesTriple: 'lines-triple',
  Link: 'link',
  LinkAssessment: 'link-assessment',
  LinkUnlink: 'link-unlink',
  ListTree: 'list-tree',
  Lock: 'lock',
  LockHexagon: 'lock-hexagon',
  LogOut: 'log-out',
  MagnifyingGlassDocument: 'magnifying-glass-document',
  MagnifyingGlassPlusBox: 'magnifying-glass-plus-box',
  MenubarArrowDown: 'menubar-arrow-down',
  MenuBlockCheckmark: 'menu-block-checkmark',
  MergeTableCells: 'merge-table-cells',
  MinusSmall: 'minus-small',
  MonitorCableConnectionAddPlus: 'monitor-cable-connection-add-plus',
  MovingMail: 'moving-mail',
  NodeTriangle: 'node-triangle',
  Notifications: 'notifications',
  NotificationsMuted: 'notifications-muted',
  NotJunk: 'not-junk',
  OrderedList: 'ordered-list',
  Paperclip: 'paperclip',
  PasswordKey: 'password-key',
  Pencil: 'pencil',
  PencilGrid: 'pencil-grid',
  Phone: 'phone',
  PlaneFlightCanceled: 'plane-flight-canceled',
  Plugin: 'plugin',
  PluginAdd: 'plugin-add',
  Plus: 'plus',
  Power: 'power',
  PrivacyCenter: 'privacy-center',
  Profile: 'profile',
  QuestionBubble: 'question-bubble',
  QuestionMark: 'question-mark',
  Radar: 'radar',
  Refresh: 'refresh',
  Rejected: 'rejected',
  RemoveTable: 'remove-table',
  RemoveTableColumn: 'remove-table-column',
  RemoveTableRow: 'remove-table-row',
  ReScan: 're-scan',
  Retry: 'retry',
  RowNodes: 'row-nodes',
  Save: 'save',
  Search: 'search',
  SearchRadarLocation: 'search-radar-location',
  ServerDatabaseCheckmark: 'server-database-checkmark',
  ServerDatabasesCheckmark: 'server-databases-checkmark',
  ServerDatabasesKeyProtection: 'server-databases-key-protection',
  Shapes: 'shapes',
  Share: 'share',
  ShieldMagnifyingGlass: 'shield-magnifying-glass',
  SidebarAssessments: 'sidebar-assessments',
  SidebarConsent: 'sidebar-consent',
  SidebarConsentManager: 'sidebar-consent-manager',
  SidebarDataMap: 'sidebar-data-map',
  Skip: 'skip',
  Slack: 'slack',
  SortAscending: 'sort-ascending',
  SortDescending: 'sort-descending',
  Sparkles: 'sparkles',
  SpeechBubbleSlash: 'speech-bubble-slash',
  Spreadsheet: 'spreadsheet',
  Sphere: 'sphere',
  SplitTableCell: 'split-table-cell',
  Stopwatch: 'stopwatch',
  StopHand: 'stop-hand',
  StoreModernStyle: 'store-modern-style',
  StreetSigns: 'street-signs',
  Strikethrough: 'strikethrough',
  Submitted: 'submitted',
  Switch: 'switch',
  SystemStatus: 'system-status',
  Table: 'table',
  Team: 'team',
  Terminal: 'terminal',
  TextFile: 'text-file',
  TextSparkle: 'text-sparkle',
  ThumbsUp: 'thumbs-up',
  ThumbsUpCheck: 'thumbs-up-check',
  ToggleTableHeaderCell: 'toggle-table-header-cell',
  ToggleTableHeaderColumn: 'toggle-table-header-column',
  ToggleTableHeaderRow: 'toggle-table-header-row',
  Trash: 'trash',
  TrashDeleteRecycle: 'trash-delete-recycle',
  Underline: 'underline',
  Upload: 'upload',
  User: 'user',
  UserAdd: 'user-add',
  UserCard: 'user-card',
  UserExclamation: 'user-exclamation',
  ViewDetails: 'view-details',
  ViewFile: 'view-file',
  XBubble: 'x-bubble',
  XNoPadding: 'x-no-padding',
  ZipFile: 'zip-file',
  ...FlagIcons,
});

/** Type override */
export type IconType = (typeof IconType)[keyof typeof IconType];

/** Props to pass into the svg */
export interface SvgProps {
  /** The icon color */
  color: string;
  /** Secondary icon color */
  color2?: string;
  /** The icon size */
  size: string;
  /** The stroke width */
  strokeWidth?: string;
}
