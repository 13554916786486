import { applyEnum } from '@transcend-io/type-utils';

import { ApiKeyPreview, UserPreview } from '@main/access-control-types';
import { mkInput, mkType, SchemaToType } from '@main/schema-utils';

import { AirgapModuleName } from '../airgapBundleName';
import { AirgapCdn } from './enums/consentManager';

export const RollbackAirgapBundleInput = mkInput({
  name: 'RollbackAirgapBundleInput',
  comment: 'Input for rolling back to a previous airgap bundle version',
  fields: {
    cdn: {
      type: { AirgapCdn },
      comment: 'Rollback modules hosted on this specific CDN',
    },
    ...applyEnum(AirgapModuleName, (name) => ({
      comment: `The version ID for the airgap ${name} module`,
      type: 'string',
      optional: true,
    })),
    sync: {
      comment: 'The version ID for the sync endpoint script',
      type: 'string',
      optional: true,
    },
    core: {
      comment: `The version ID for the airgap core module`,
      type: 'string',
    },
    ui: {
      comment: `The version ID for the airgap ui module`,
      type: 'string',
      optional: true,
    },
    xdi: {
      comment: `The version ID for the airgap xdi module`,
      type: 'string',
      optional: true,
    },
    userscript: {
      comment: `The version ID for the airgap userscript module`,
      type: 'string',
      optional: true,
    },
    explorer: {
      comment: `The version ID for the airgap explorer module`,
      type: 'string',
      optional: true,
    },
    isTest: {
      comment:
        'Whether to rollback the "TEST" or "PRODUCTION" bundle, defaults to "PRODUCTION"',
      type: 'boolean',
    },
  },
});

/** Override type */
export type RollbackAirgapBundleInput = SchemaToType<
  typeof RollbackAirgapBundleInput
>;

export const AirgapBundleVersionsInput = mkInput({
  name: 'AirgapBundleVersionsInput',
  comment: 'Input for querying airgapBundleVersions',
  fields: {
    isTest: {
      comment:
        'Whether to return versions for the "TEST" or "PRODUCTION" bundle',
      type: 'boolean',
    },
    cdn: {
      comment:
        'The CDN to return versions for (defaults to "preferredCdn" of airgapBundle)',
      type: { AirgapCdn },
      optional: true,
    },
  },
});

/** Override type */
export type AirgapBundleVersionsInput = SchemaToType<
  typeof AirgapBundleVersionsInput
>;

export const AirgapBundleVersion = mkType({
  name: 'AirgapBundleVersion',
  comment: 'A version of an airgap bundle',
  fields: {
    ...RollbackAirgapBundleInput.fields,
    coreConfig: {
      comment: `The core module configuration used in this deployed version`,
      type: 'string',
      optional: true,
    },
    createdAt: {
      comment: 'The time at which the version was created',
      type: 'Date',
    },
    version: {
      comment: 'The version of the airgap bundle that was deployed',
      type: 'string',
      optional: true,
    },
    user: {
      comment: 'The user who deployed this version',
      type: UserPreview,
      optional: true,
    },
    apiKey: {
      comment: 'The API key who deployed this version',
      type: ApiKeyPreview,
      optional: true,
    },
  },
});

/** Override type */
export type AirgapBundleVersion = SchemaToType<typeof AirgapBundleVersion>;
