import { scaleBand } from '@visx/scale';
import { ScaleBand } from 'd3-scale';

import { InternalChartData } from '../types';
import { getInternalChartData } from '../utils';
import { BAR_MARGIN, MAX_BAR_WIDTH, MIN_GROUP_PADDING } from './constants';
import { GroupedBarChartProps } from './types';

/**
 * type of x scale
 */
type XScale = ScaleBand<string>;

export const getParsedData = (
  externalData: GroupedBarChartProps['data'],
): {
  /** whether bars are stacked */
  stacked: boolean;
  /** names of bars */
  barNames: string[];
  /** names of bar pieces */
  barSeriesNames: string[];
  /** names of bar groups */
  groupNames: string[];
  /** transformed data to be passed to chart */
  internalChartData: {
    /** name of series */
    name: string;
    /** series data */
    series: InternalChartData;
  }[];
} => {
  const stacked = externalData.groups[0].series.length > 1;
  const barNames = externalData.groups[0].series[0].points.map((d) => d.key);
  const barSeriesNames = externalData.groups[0].series.map((s) => s.name);
  const groupNames = externalData.groups.map((g) => g.name);
  const internalChartData = externalData.groups.map(({ name, series }) => ({
    name,
    series: getInternalChartData({ series }),
  }));

  return {
    stacked,
    barNames,
    barSeriesNames,
    groupNames,
    internalChartData,
  };
};

export const getChartDimensions = (
  externalData: GroupedBarChartProps['data'],
  chartInteriorWidth: number,
): {
  /** width of group */
  groupWidth: number;
  /** width of a bar */
  barWidth: number;
} => {
  const numBarsPerGroup = externalData.groups[0].series[0].points.length;
  const numGroups = externalData.groups.length;

  const groupMaxWidth =
    numBarsPerGroup * MAX_BAR_WIDTH + numBarsPerGroup * 2 * BAR_MARGIN;
  const groupMargin = Math.max(
    (chartInteriorWidth - numGroups * groupMaxWidth) / (numGroups + 1),
    MIN_GROUP_PADDING,
  );
  /**
   * clamp to 0 because initial size of parent is goofy and we end up with
   * negative numbers for a split second before the svg comes into being
   */
  const groupWidth = Math.max(
    (chartInteriorWidth - (numGroups + 1) * groupMargin) / numGroups,
    0,
  );
  const barWidth = Math.min(
    (groupWidth - (numBarsPerGroup + 1) * BAR_MARGIN) / numBarsPerGroup,
    MAX_BAR_WIDTH,
  );

  return {
    groupWidth,
    barWidth,
  };
};

export const getGroupXRange = ({
  groupXScale,
  groupWidth,
  barNames,
  numGroups,
  chartInteriorWidth,
  groupName,
}: {
  /** x scale that gives an x value within the chart for a group name */
  groupXScale: XScale;
  /** width of a group of bars */
  groupWidth: number;
  /** names of bars within a group */
  barNames: string[];
  /** interior width of chart */
  chartInteriorWidth: number;
  /** number of groups */
  numGroups: number;
  /** name of group */
  groupName: string;
}): {
  /** start of range */
  start: number;
  /** end of range */
  end: number;
  /** range scale */
  scale: XScale;
} => {
  const sectionWidth = chartInteriorWidth / numGroups / 2;
  const start = (groupXScale(groupName) || 0) + sectionWidth - groupWidth / 2;
  const end = start + groupWidth;
  const scale = scaleBand({
    domain: barNames,
    range: [start, end],
  });
  return {
    start,
    end,
    scale,
  };
};

export const getHighlightedBarFromX =
  ({
    chartInteriorWidth,
    groupNames,
    barNames,
    groupWidth,
    groupXScale,
  }: {
    /** width of chart container */
    chartInteriorWidth: number;
    /** names of bar groups */
    groupNames: string[];
    /** names of individual bars within a group */
    barNames: string[];
    /** width of bar group within the chart container */
    groupWidth: number;
    /** scale that spits out each group x for a given group name */
    groupXScale: XScale;
  }) =>
  (
    x: number,
  ): {
    /** name of group */
    groupName: string;
    /** name of bar */
    seriesName: string;
  } => {
    const groupName =
      groupNames[Math.floor(x / (chartInteriorWidth / groupNames.length))];
    const { start: groupStartX } = getGroupXRange({
      groupName,
      groupXScale,
      groupWidth,
      barNames,
      chartInteriorWidth,
      numGroups: groupNames.length,
    });
    const barName =
      barNames[Math.floor((x - groupStartX) / (groupWidth / barNames.length))];
    return { groupName, seriesName: barName };
  };
