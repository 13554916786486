import styled from 'styled-components';

export const DragHandleWrapper = styled.span`
  display: flex;
  margin: 0 8px 0 10px;
  color: ${({ theme }) => theme.colors.transcendNavy4};
  svg {
    margin: 0 -10px;
  }
`;

export const SortableListItem = styled.div`
  user-select: none;
  display: flex;
  align-items: center;
  padding: 4px 10px;
  background: ${({ theme }) => theme.colors.white};
  border-radius: 8px;
`;
