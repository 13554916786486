/**
 * Props to disable autocomplete/autofill on a form field.
 * Password managers are known to ignore the `autocomplete="off"` attribute.
 *
 * @see https://stackoverflow.com/questions/59939931/stop-dashlane-auto-fill-on-specific-input-fields
 *
 * @example <FormInput {...AUTOCOMPLETE_OFF_PROPS} />
 */
export const AUTOCOMPLETE_OFF_PROPS = {
  autoComplete: 'off',
  'data-1p-ignore': true, // Disable 1Password autofill
  'data-lpignore': true, // Disable LastPass autofill
  'data-form-type': 'other', // Disable Dashlane autofill
};
