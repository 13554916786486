import { RequestAction } from '@transcend-io/privacy-types';

import { IconType } from '@main/core-ui';
import type { FixedColorPalette } from '@main/theme';

export const REQUEST_ACTION_TO_COMPONENT_MAP: {
  [k in RequestAction]: {
    /** The icon of the card based on request type */
    icon: IconType;
    /** The primary icon color */
    color: keyof FixedColorPalette;
    /** The secondary icon color, if applicable */
    color2?: keyof FixedColorPalette;
  };
} = {
  [RequestAction.Access]: {
    icon: 'dsr-type-access',
    color: 'blue1',
  },
  [RequestAction.AutomatedDecisionMakingOptOut]: {
    icon: 'dsr-type-automated-decision-making-opt-out',
    color: 'mint1',
    color2: 'red1',
  },
  [RequestAction.AutomatedDecisionMakingOptIn]: {
    icon: 'dsr-type-automated-decision-making-opt-out',
    color: 'mint1',
    color2: 'red1',
  },
  [RequestAction.BusinessPurpose]: {
    icon: 'dsr-type-business-purpose',
    color: 'mint1',
    color2: 'purple1',
  },
  [RequestAction.ContactOptOut]: {
    icon: 'dsr-type-contact-opt-out',
    color: 'blue1',
    color2: 'red1',
  },
  [RequestAction.ContactOptIn]: {
    icon: 'dsr-type-contact-opt-out',
    color: 'blue1',
    color2: 'red1',
  },
  [RequestAction.Erasure]: {
    icon: 'dsr-type-erasure',
    color: 'red1',
  },
  [RequestAction.Rectification]: {
    icon: 'dsr-type-rectification',
    color: 'purple1',
  },
  [RequestAction.Restriction]: {
    icon: 'dsr-type-restriction',
    color: 'blue1',
    color2: 'red1',
  },
  [RequestAction.SaleOptOut]: {
    icon: 'dsr-type-sale-opt-out',
    color: 'mint1',
    color2: 'red1',
  },
  [RequestAction.SaleOptIn]: {
    icon: 'dsr-type-sale-opt-out',
    color: 'mint1',
    color2: 'red1',
  },
  [RequestAction.TrackingOptOut]: {
    icon: 'dsr-type-tracking-opt-out',
    color: 'purple1',
    color2: 'red1',
  },
  [RequestAction.TrackingOptIn]: {
    icon: 'dsr-type-tracking-opt-out',
    color: 'purple1',
    color2: 'red1',
  },
  [RequestAction.CustomOptOut]: {
    icon: 'dsr-type-automated-decision-making-opt-out',
    color: 'orange1',
    color2: 'red1',
  },
  [RequestAction.CustomOptIn]: {
    icon: 'dsr-type-automated-decision-making-opt-out',
    color: 'orange2',
    color2: 'red1',
  },
  [RequestAction.UseOfSensitiveInformationOptOut]: {
    icon: 'dsr-type-automated-decision-making-opt-out',
    color: 'orange1',
    color2: 'red1',
  },
  [RequestAction.UseOfSensitiveInformationOptIn]: {
    icon: 'dsr-type-automated-decision-making-opt-out',
    color: 'orange2',
    color2: 'red1',
  },
  [RequestAction.PlaceOnLegalHold]: {
    icon: 'dsr-type-restriction',
    color: 'orange1',
    color2: 'red1',
  },
  [RequestAction.RemoveFromLegalHold]: {
    icon: 'dsr-type-restriction',
    color: 'orange2',
    color2: 'red1',
  },
};
