import { UserPreview } from '@main/access-control-types';
import { Badge } from '@main/core-ui';
import React from 'react';
import { useTheme } from 'styled-components';

import { OrganizationIcon } from '../OrganizationIcon';

export const UserTag: React.FC<{
  /** User to display the icon for */
  user: Pick<UserPreview, 'profilePicture' | 'name'>;
}> = ({ user }) => {
  const theme = useTheme();
  return (
    <Badge
      color="white"
      style={{
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <OrganizationIcon
        organizationIconSrc={user.profilePicture}
        size={18}
        style={{
          borderRadius: '10em',
          fontSize: 12,
          marginRight: 4,
        }}
      />
      <span
        style={{
          fontWeight: 600,
          color: theme.colors.transcendNavy,
        }}
      >
        {user.name}
      </span>
    </Badge>
  );
};
