import { defineMessages } from '@transcend-io/internationalization';

export const fileUploadMessages = defineMessages(
  'ad-core-components.utils.uploadFile.fileUpload',
  {
    fileUploadingError: {
      defaultMessage:
        'An error occurred and your file was not uploaded. Error: {error}',
    },
    fileUploadingProgress: {
      defaultMessage: 'Uploading file...',
    },
    fileUploadingSuccess: {
      defaultMessage: 'File successfully uploaded!',
    },
    fileSizeError: {
      defaultMessage: 'File must less than {allowedSize}MB',
    },
    fileTypeError: {
      defaultMessage: 'File type must be one of: {types}',
    },
  },
);
