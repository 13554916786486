import { BreadcrumbItem } from '@main/ad-core-components';
import { createContext, useContext } from 'react';

/**
 * Context for managing the breadcrumbs displayed at the top of a page
 */
export interface IPageBreadcrumbsContext {
  /** The values of the breadcrumbs to be shown at the top of a page. MUST be memoized. */
  breadcrumbs: readonly BreadcrumbItem[] | null;
  /** A setter for the breadcrumbs */
  setBreadcrumbs: (breadcrumbs: BreadcrumbItem[] | null) => void;
}

export const PageBreadcrumbsContext = createContext<IPageBreadcrumbsContext>({
  breadcrumbs: null,
  // eslint-disable-next-line no-empty-function
  setBreadcrumbs: () => {},
});

export const usePageBreadcrumbsContext = (): IPageBreadcrumbsContext =>
  useContext(PageBreadcrumbsContext);
