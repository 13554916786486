import { defineMessages } from '@transcend-io/internationalization';

export const triageApplicationsColumnHeaderMessages = defineMessages(
  'admin-dashboard.ConsentManager.NativeApps.TriageApplications.triageApplicationsColumnHeader',
  {
    name: {
      defaultMessage: 'Name',
    },
    type: {
      defaultMessage: 'Package Type',
    },
    description: {
      defaultMessage: 'Description',
    },
    repository: {
      defaultMessage: 'Repository',
    },
    relativePath: {
      defaultMessage: 'Relative Path',
    },
    softwareDevelopmentKits: {
      defaultMessage: 'SDKs',
    },
    actions: {
      defaultMessage: 'Actions',
    },
    lastSeenAt: {
      defaultMessage: 'Last Seen At',
    },
    promptRunCount: {
      defaultMessage: 'Prompt Runs',
    },
    owners: {
      defaultMessage: 'Owners',
    },
    teams: {
      defaultMessage: 'Teams',
    },
    dataSilo: {
      defaultMessage: 'Data Silo',
    },
  },
);

export const codePackagesMessages = defineMessages(
  'admin-dashboard.ConsentManager.NativeApps.TriageApplications.codePackages',
  {
    noDataMessage: {
      defaultMessage: 'No packages to display.',
    },
    codePackagesCount: {
      defaultMessage: '{count} {count, plural, one {package} other {packages}}',
    },
  },
);
