import { MessageDescriptor } from 'react-intl';
import { Column } from 'react-table';

import { DefinedMessages } from '@transcend-io/internationalization';

import { GenericMessageDescriptor } from '@main/core-ui';

export interface ColumnIdWithMessage {
  /** The column identifier: either id or accessor, if no id */
  id: string;
  /** The header message for this column */
  message: GenericMessageDescriptor;
}

/**
 *
 * Pairs the column id/accessor with its message for all columns for which configuration can be controlled.
 * The message could be a defined message, in the case of native columns, or a string, in the case of attribute columns.
 *
 * @param config - configuration from which the messages can be determined
 * @returns a list of column ids (derived from the id or accessor on the column definition) paired with their header messages
 */
export const columnsToMessages = <D extends object>({
  columns,
  nativeColumnHeaders,
  attributeColumnHeaders,
  uneditableColumns,
}: {
  /** columns to transform */
  columns: Column<D>[];
  /** Mapping of table column ids (or accessors) to header messages */
  nativeColumnHeaders: DefinedMessages;
  /** Mapping of table column ids for attributes to their header labels */
  attributeColumnHeaders: Record<string, MessageDescriptor | string>;
  /** Ids/accessors of columns whose visibility and order is not controllable */
  uneditableColumns: string[];
}): ColumnIdWithMessage[] =>
  columns.reduce((columnMessages, { id, accessor }) => {
    const key = id || (accessor as string);
    if (
      !key ||
      !(key in nativeColumnHeaders || key in attributeColumnHeaders) ||
      (uneditableColumns && uneditableColumns.includes(key))
    ) {
      return columnMessages;
    }
    return [
      ...columnMessages,
      {
        id: key,
        message:
          key in nativeColumnHeaders
            ? nativeColumnHeaders[key]
            : attributeColumnHeaders[key],
      },
    ];
  }, [] as ColumnIdWithMessage[]);
