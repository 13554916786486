import {
  createSlice,
  PayloadAction,
  Slice,
  SliceCaseReducers,
  ValidateSliceCaseReducers,
} from '@reduxjs/toolkit';

import type {
  Profile,
  ProfileDataPoint,
  RequestDataSilo,
} from '@main/dsr-types';
import type { ID } from '@main/schema-utils';

/**
 * the request data silo state
 */
export interface RequestDataSiloState {
  /** The request data silo contents */
  requestDataSilo?: RequestDataSilo;
  /** The ID of the request currently being requested (different from requestDataSilo.requestId when in flight)  */
  requestId?: ID<'request'>;
  /** The ID of the dataSilo currently being requested (different from requestDataSilo.dataSiloId when in flight)  */
  dataSiloId?: ID<'dataSilo'>;
}

const requestDataSiloSliceReducers: ValidateSliceCaseReducers<
  RequestDataSiloState,
  SliceCaseReducers<RequestDataSiloState>
> = {
  // Set the selected IDs
  setRequestDataSiloIds: (
    state,
    {
      payload,
    }: PayloadAction<{
      /** The ID of the request currently being requested (different from requestDataSilo.requestId when in flight)  */
      requestId?: ID<'request'>;
      /** The ID of the dataSilo currently being requested (different from requestDataSilo.dataSiloId when in flight)  */
      dataSiloId?: ID<'dataSilo'>;
    }>,
  ) => ({
    ...state,
    ...payload,
  }),
  // Set the request data silo in redux
  setRequestDataSilo: (
    state,
    { payload: requestDataSilo }: PayloadAction<RequestDataSilo>,
  ) => ({
    ...state,
    requestDataSilo,
  }),
  // Profile included or discluded
  profileIncludedChanged: (
    state,
    { payload: profile }: PayloadAction<Profile>,
  ) => {
    if (state.requestDataSilo) {
      state.requestDataSilo.profiles = state.requestDataSilo.profiles.map(
        (p) => (p.id !== profile.id ? p : profile),
      );
    }
  },
  // Profile datapoint was retried
  profileDataPointRetried: (
    state,
    { payload: profileDataPoint }: PayloadAction<ProfileDataPoint>,
  ) => {
    if (state.requestDataSilo) {
      state.requestDataSilo.profiles.forEach((profile) => {
        profile.profileDataPoints = profile.profileDataPoints.map((pDp) =>
          pDp.id === profileDataPoint.id ? profileDataPoint : pDp,
        );
      });
    }
  },
};

export const requestDataSiloSlice: Slice<
  RequestDataSiloState,
  SliceCaseReducers<RequestDataSiloState>,
  string
> = createSlice({
  name: 'RequestDataSilo',
  initialState: {} as RequestDataSiloState,
  reducers: requestDataSiloSliceReducers,
});

export const {
  profileDataPointRetried,
  profileIncludedChanged,
  setRequestDataSilo,
  setRequestDataSiloIds,
} = requestDataSiloSlice.actions;
