/**
 * The privacy center policy type.
 */
export enum PolicyType {
  /** A privacy policy */
  PrivacyPolicy = 'PRIVACY_POLICY',
  /** A cookie policy */
  CookiePolicy = 'COOKIE_POLICY',
  /** A custom-defined policy not natively supported */
  Custom = 'CUSTOM',
}
