import { mkInput, mkType, SchemaToType } from '@main/schema-utils';

import { TCFSpecialFeature } from './tcfSpecialFeature';
import { TCFPurpose } from './tcfVendor';

export const TCFStack = mkType({
  name: 'TCFStack',
  comment:
    'Transparency & Consent Framework (TCF) Stack -' +
    'combinations of purposes and features used to simplify understanding of consent management',
  fields: {
    id: {
      comment: 'The ID of the stack as used by TCF',
      type: 'int',
    },
    name: {
      comment:
        'The name of the stack, e.g. "Precise geolocation data, and identification through device scanning"',
      type: 'string',
    },
    description: {
      comment: '',
      type: 'string',
    },
    purposes: {
      comment: 'Purposes grouped together in the stack',
      type: TCFPurpose,
      list: true,
      optional: true,
    },
    specialFeatures: {
      comment: 'Special Features grouped together in the stack',
      type: TCFSpecialFeature,
      list: true,
      optional: true,
    },
  },
});

/** Override type */
export type TCFStack = SchemaToType<typeof TCFStack>;

export const SetTCFStacksInput = mkInput({
  name: 'SetTCFStacksInput',
  comment: 'Input for adding or removing TCF Stacks to an airgap bundle',
  fields: {
    airgapBundleId: {
      comment: 'The airgap bundle to update the stacks for',
      type: 'id',
      modelName: 'airgapBundle',
    },
    tcfStackIds: {
      comment: 'List of TCF Stack ids to add or remove from the airgap bundle.',
      type: 'int',
      list: true,
    },
  },
});

/** Override type */
export type SetTCFStacksInput = SchemaToType<typeof SetTCFStacksInput>;

export const BundleTCFStack = mkType({
  name: 'BundleTCFStack',
  comment:
    'Transparency & Consent Framework (TCF) Stack -' +
    'combinations of purposes and features used to simplify understanding of consent management' +
    'with additional information about whether the stack is in the bundle',
  fields: {
    id: TCFStack.fields.id,
    name: TCFStack.fields.name,
    description: TCFStack.fields.description,
    purposes: TCFStack.fields.purposes,
    specialFeatures: TCFStack.fields.specialFeatures,
    showInTcfModal: {
      comment: 'Whether to include the stack in the TCF modal',
      type: 'boolean',
    },
    availableForAddition: {
      comment:
        'Whether the stack is unavailable for addition to the bundle' +
        'because its purposes/special features are already covered by another stack' +
        'or purposes/special features in the stack are not applicable to the bundle',
      type: 'boolean',
    },
  },
});

/** Override type */
export type BundleTCFStack = SchemaToType<typeof BundleTCFStack>;

export const TCFStackFilterInput = mkInput({
  name: 'TCFStackFilterInput',
  comment: 'Input for filtering TCF Stacks endpoint by',
  fields: {
    text: {
      comment:
        'Filters by whether the substring is present in the GVL vendor name',
      type: 'string',
      optional: true,
    },
    purposeIds: {
      comment: 'The TCF purposes associated with this stack',
      type: 'id',
      modelName: 'tcfPurpose',
      list: true,
      optional: true,
    },
    specialFeatureIds: {
      comment: 'The TCF special features associated with this stack',
      type: 'id',
      modelName: 'tcfSpecialFeature',
      list: true,
      optional: true,
    },
    showInTcfModal: {
      comment: 'Filter to only show stacks that are shown in the TCF modal',
      type: 'boolean',
      optional: true,
    },
  },
});

/** Override type */
export type TCFStackFilterInput = SchemaToType<typeof TCFStackFilterInput>;
