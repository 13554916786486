import styled from 'styled-components';

export const StyledTitle = styled.h3`
  font-size: 34px;
  color: ${({ theme }) => theme.colors.transcendNavy2};
  font-weight: 700;
  text-align: center;
  margin: 0;
`;

export const StyledDescription = styled.div`
  text-align: center;
`;
