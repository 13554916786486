/* eslint-disable max-lines */
import moment from 'moment';

import {
  ActionItemCode,
  ActionItemPriorityOverride,
  DataCategoryType,
  RequestStatus,
  SubDataPointDataSubCategoryGuessStatus,
} from '@transcend-io/privacy-types';

import {
  AdminDashboardAdministrationPath,
  AdminDashboardInfrastructurePath,
  AdminDashboardPublicMiscPath,
  GlobalActionItem,
} from '@main/access-control-types';
import { SelectedUser } from '@main/ad-core-components';
import { AdminDashboardCmPostOnboardingPath } from '@main/cm-types';
import { DarkColorOption, IconType } from '@main/core-ui';
import {
  AdminDashboardAssessmentsPath,
  AdminDashboardDataMapPath,
  DataSiloConnectionState,
} from '@main/datamap-types';
import { AdminDashboardPrivacyRequestPostOnboardingPath } from '@main/dsr-types';
import { DefinedMessage } from '@main/internationalization';

import { safeJoinPath } from './helpers/safeJoinPath';
import {
  actionItemDocumentationMessages,
  actionItemFullDescriptions,
  actionItemRichDescriptions,
} from './messages';

export const DATE_FORMAT = 'L [at] h:mma';

/**
 * Mapping from action item types to their documentation link and icon
 */
export const ACTION_ITEM_FRONTEND_MAPPING: Record<
  ActionItemCode,
  {
    /** The documentation link */
    documentationLink?: string;
    /** The icon to display */
    icon: IconType;
    /** The rich message to display */
    richMessage: DefinedMessage;
    /** The message for the documentation link */
    documentationMessage?: DefinedMessage;
    /** An extra description */
    descriptionMessage: DefinedMessage;
    /** Generate a link to take action. Probably to a filtered view */
    getActionLink?: (
      actionItemRow: GlobalActionItem,
      user: SelectedUser,
    ) => string | undefined;
    /** the type of the parent of the action item. For example, the parent type for Datapoints is DATA_SILO */
    parentType?: string;
  }
> = {
  [ActionItemCode.ActionMissingSilos]: {
    documentationLink:
      'https://docs.transcend.io/docs/privacy-requests/connecting-data-silos/saas-tools#configuring-an-integration',
    icon: 'server-databases-key-protection',
    richMessage: actionItemRichDescriptions[ActionItemCode.ActionMissingSilos],
    descriptionMessage:
      actionItemFullDescriptions[ActionItemCode.ActionMissingSilos],
    documentationMessage:
      actionItemDocumentationMessages.configuringAnIntegration,
    getActionLink: () =>
      AdminDashboardPrivacyRequestPostOnboardingPath.Settings,
  },
  [ActionItemCode.ApiKeyStale]: {
    documentationLink:
      'https://docs.transcend.io/docs/privacy-requests/api-integration/authentication#cycling-api-keys',
    icon: 'password-key',
    richMessage: actionItemRichDescriptions[ActionItemCode.ApiKeyStale],
    descriptionMessage: actionItemFullDescriptions[ActionItemCode.ApiKeyStale],
    getActionLink: () => AdminDashboardInfrastructurePath.ApiKeys,
  },
  [ActionItemCode.AssessmentFormAssigned]: {
    documentationLink: 'https://docs.transcend.io/docs/assessments',
    icon: 'document-legal-users',
    richMessage:
      actionItemRichDescriptions[ActionItemCode.AssessmentFormAssigned],
    descriptionMessage:
      actionItemFullDescriptions[ActionItemCode.AssessmentFormAssigned],
    documentationMessage: actionItemDocumentationMessages.assessments,
    getActionLink: (actionItemRow) =>
      actionItemRow.count === 1
        ? safeJoinPath(AdminDashboardAssessmentsPath.AssessmentForm, {
            assessmentFormId: actionItemRow.sourceIds[0],
          })
        : safeJoinPath(AdminDashboardAssessmentsPath.AssessmentGroups, {}, {}),
  },
  [ActionItemCode.AssessmentFormNeedsReview]: {
    documentationLink: 'https://docs.transcend.io/docs/assessments',
    icon: 'document-legal-users',
    richMessage:
      actionItemRichDescriptions[ActionItemCode.AssessmentFormNeedsReview],
    descriptionMessage:
      actionItemFullDescriptions[ActionItemCode.AssessmentFormNeedsReview],
    documentationMessage: actionItemDocumentationMessages.assessments,
    getActionLink: (actionItemRow) =>
      actionItemRow.count === 1
        ? safeJoinPath(AdminDashboardAssessmentsPath.AssessmentFormResponse, {
            assessmentFormId: actionItemRow.sourceIds[0],
          })
        : safeJoinPath(AdminDashboardAssessmentsPath.AssessmentGroups, {}, {}),
  },
  [ActionItemCode.RequestDataSiloMarkedActionable]: {
    documentationLink:
      'https://docs.transcend.io/docs/privacy-requests/processing-requests/review-and-approval',
    icon: 'edit-file',
    richMessage:
      actionItemRichDescriptions[
        ActionItemCode.RequestDataSiloMarkedActionable
      ],
    descriptionMessage:
      actionItemFullDescriptions[
        ActionItemCode.RequestDataSiloMarkedActionable
      ],
    documentationMessage: actionItemDocumentationMessages.reviewRequest,
    getActionLink: (actionItemRow) =>
      // TODO: https://transcend.height.app/T-24091 - Change rollup behavior to filter
      safeJoinPath(
        AdminDashboardPrivacyRequestPostOnboardingPath.RequestDataSilo,
        {
          requestId: actionItemRow.additionalContexts?.[0].requestId,
          dataSiloId: actionItemRow.additionalContexts?.[0].dataSiloId,
        },
      ),
  },
  [ActionItemCode.RequestDataSiloFilesNeedReview]: {
    documentationLink:
      'https://docs.transcend.io/docs/privacy-requests/processing-requests/review-and-approval',
    icon: 'file',
    richMessage:
      actionItemRichDescriptions[ActionItemCode.RequestDataSiloFilesNeedReview],
    descriptionMessage:
      actionItemFullDescriptions[ActionItemCode.RequestDataSiloFilesNeedReview],
    documentationMessage: actionItemDocumentationMessages.reviewRequest,
    getActionLink: (actionItemRow) =>
      // TODO: https://transcend.height.app/T-24091 - Change rollup behavior to filter
      safeJoinPath(
        AdminDashboardPrivacyRequestPostOnboardingPath.RequestDataSilo,
        {
          requestId: actionItemRow.additionalContexts?.[0].requestId,
          dataSiloId: actionItemRow.additionalContexts?.[0].dataSiloId,
        },
      ),
  },
  [ActionItemCode.CommunicationUnread]: {
    icon: 'email-letter-mail-notification',
    richMessage: actionItemRichDescriptions[ActionItemCode.CommunicationUnread],
    descriptionMessage:
      actionItemFullDescriptions[ActionItemCode.CommunicationUnread],
    getActionLink: (actionItemRow) =>
      safeJoinPath(
        `${AdminDashboardPrivacyRequestPostOnboardingPath.Request}#Communication`,
        {
          requestId: actionItemRow.additionalContexts?.[0].requestId,
        },
      ),
  },
  [ActionItemCode.DataSiloCommunicationUnread]: {
    icon: 'email-letter-mail-notification',
    richMessage:
      actionItemRichDescriptions[ActionItemCode.DataSiloCommunicationUnread],
    descriptionMessage:
      actionItemFullDescriptions[ActionItemCode.DataSiloCommunicationUnread],
    getActionLink: (actionItemRow) =>
      safeJoinPath(
        `${AdminDashboardPrivacyRequestPostOnboardingPath.RequestDataSilo}?view=Communication`,
        {
          requestId: actionItemRow.additionalContexts?.[0].requestId,
          dataSiloId: actionItemRow.additionalContexts?.[0].dataSiloId,
        },
      ),
  },
  [ActionItemCode.DataPoint]: {
    documentationLink:
      'https://docs.transcend.io/docs/overview/understanding-integrations#within-transcend-privacy-requests',
    icon: 'menu-block-checkmark',
    richMessage: actionItemRichDescriptions[ActionItemCode.DataPoint],
    descriptionMessage: actionItemFullDescriptions[ActionItemCode.DataPoint],
    documentationMessage: actionItemDocumentationMessages.default,
    getActionLink: (actionItemRow) =>
      // TODO: https://transcend.height.app/T-24093 - Change rollup behavior to filter
      safeJoinPath(
        AdminDashboardInfrastructurePath.DataSiloManageDatapoints,
        {
          dataSiloId: actionItemRow.additionalContexts?.[0].dataSiloId,
        },
        {
          missingRequiredFields: true,
        },
      ),
  },
  [ActionItemCode.DataPointMissingFields]: {
    documentationLink:
      'https://docs.transcend.io/docs/data-mapping/data-inventory',
    icon: 'menu-block-checkmark',
    richMessage:
      actionItemRichDescriptions[ActionItemCode.DataPointMissingFields],
    descriptionMessage:
      actionItemFullDescriptions[ActionItemCode.DataPointMissingFields],
    documentationMessage: actionItemDocumentationMessages.dataInventory,
    getActionLink: (actionItemRow) =>
      safeJoinPath(
        AdminDashboardDataMapPath.DataPoints,
        {},
        {
          // This combination of status and category causes all subdatapoints without a confirmed category to show up
          status: SubDataPointDataSubCategoryGuessStatus.Approved,
          category: [DataCategoryType.Unspecified],
          ...(actionItemRow.count === 1
            ? {
                dataSilos: [actionItemRow.additionalContexts![0].dataSiloId!],
                dataPointName: `"${actionItemRow.titles![0]}"`,
              }
            : {}),
        },
      ),
    parentType: 'DATA_SILO',
  },
  [ActionItemCode.DataSiloNeedsReconnect]: {
    documentationLink:
      'https://docs.transcend.io/docs/privacy-requests/connecting-data-silos/saas-tools',
    icon: 'server-databases-key-protection',
    richMessage:
      actionItemRichDescriptions[ActionItemCode.DataSiloNeedsReconnect],
    descriptionMessage:
      actionItemFullDescriptions[ActionItemCode.DataSiloNeedsReconnect],
    documentationMessage: actionItemDocumentationMessages.connectingSaasTools,
    getActionLink: (actionItemRow) =>
      actionItemRow.count === 1
        ? safeJoinPath(AdminDashboardInfrastructurePath.DataSiloConnection, {
            dataSiloId: actionItemRow.sourceIds[0],
          })
        : safeJoinPath(
            AdminDashboardInfrastructurePath.Integrations,
            {},
            {
              connectionState: [DataSiloConnectionState.Expired],
            },
          ),
  },
  [ActionItemCode.DataSiloNotConfigured]: {
    documentationLink:
      'https://docs.transcend.io/docs/privacy-requests/connecting-data-silos/saas-tools',
    icon: 'server-databases-checkmark',
    richMessage:
      actionItemRichDescriptions[ActionItemCode.DataSiloNotConfigured],
    descriptionMessage:
      actionItemFullDescriptions[ActionItemCode.DataSiloNotConfigured],
    documentationMessage: actionItemDocumentationMessages.connectingSaasTools,
    getActionLink: (actionItemRow) =>
      actionItemRow.count === 1
        ? safeJoinPath(AdminDashboardInfrastructurePath.DataSiloConnection, {
            dataSiloId: actionItemRow.sourceIds[0],
          })
        : safeJoinPath(
            AdminDashboardInfrastructurePath.Integrations,
            {},
            {
              connectionState: [DataSiloConnectionState.NotConfigured],
            },
          ),
  },
  [ActionItemCode.DataSiloNeedsReconnectAssigned]: {
    documentationLink:
      'https://docs.transcend.io/docs/privacy-requests/connecting-data-silos/saas-tools',
    icon: 'server-databases-key-protection',
    richMessage:
      actionItemRichDescriptions[ActionItemCode.DataSiloNeedsReconnectAssigned],
    descriptionMessage:
      actionItemFullDescriptions[ActionItemCode.DataSiloNeedsReconnectAssigned],
    documentationMessage: actionItemDocumentationMessages.connectingSaasTools,
    getActionLink: (actionItemRow) =>
      actionItemRow.count === 1
        ? safeJoinPath(AdminDashboardInfrastructurePath.DataSiloConnection, {
            dataSiloId: actionItemRow.sourceIds[0],
          })
        : safeJoinPath(
            AdminDashboardInfrastructurePath.Integrations,
            {},
            {
              connectionState: [DataSiloConnectionState.Expired],
            },
          ),
  },
  [ActionItemCode.DataSiloNotConfiguredAssigned]: {
    documentationLink:
      'https://docs.transcend.io/docs/privacy-requests/connecting-data-silos/saas-tools',
    icon: 'server-databases-checkmark',
    richMessage:
      actionItemRichDescriptions[ActionItemCode.DataSiloNotConfiguredAssigned],
    descriptionMessage:
      actionItemFullDescriptions[ActionItemCode.DataSiloNotConfiguredAssigned],
    documentationMessage: actionItemDocumentationMessages.connectingSaasTools,
    getActionLink: (actionItemRow) =>
      actionItemRow.count === 1
        ? safeJoinPath(AdminDashboardInfrastructurePath.DataSiloConnection, {
            dataSiloId: actionItemRow.sourceIds[0],
          })
        : safeJoinPath(
            AdminDashboardInfrastructurePath.Integrations,
            {},
            {
              connectionState: [DataSiloConnectionState.NotConfigured],
            },
          ),
  },
  [ActionItemCode.DataSiloMissingIdentifiers]: {
    documentationLink:
      'https://docs.transcend.io/docs/privacy-requests/connecting-data-silos/saas-tools#configuring-an-integration',
    icon: 'server-databases-key-protection',
    richMessage:
      actionItemRichDescriptions[ActionItemCode.DataSiloMissingIdentifiers],
    descriptionMessage:
      actionItemFullDescriptions[ActionItemCode.DataSiloMissingIdentifiers],
    documentationMessage:
      actionItemDocumentationMessages.configuringAnIntegration,
    getActionLink: (actionItemRow) =>
      // TODO: https://transcend.height.app/T-24093 - Change rollup behavior to filter
      safeJoinPath(AdminDashboardInfrastructurePath.DataSiloDsrAutomation, {
        dataSiloId: actionItemRow.sourceIds[0],
      }),
  },
  [ActionItemCode.DataSiloNeedsTraining]: {
    documentationLink:
      'https://docs.transcend.io/docs/data-mapping/content-classification#confirming-classifications',
    icon: 'menu-block-checkmark',
    richMessage:
      actionItemRichDescriptions[ActionItemCode.DataSiloNeedsTraining],
    descriptionMessage:
      actionItemFullDescriptions[ActionItemCode.DataSiloNeedsTraining],
    documentationMessage:
      actionItemDocumentationMessages.confirmingClassifications,
    getActionLink: (actionItemRow) =>
      safeJoinPath(
        AdminDashboardDataMapPath.DataPoints,
        {},
        {
          dataSilos: [actionItemRow.sourceIds[0]],
        },
      ),
  },
  [ActionItemCode.LookupProcessesWithErrors]: {
    icon: 'stopwatch',
    richMessage:
      actionItemRichDescriptions[ActionItemCode.LookupProcessesWithErrors],
    descriptionMessage:
      actionItemFullDescriptions[ActionItemCode.LookupProcessesWithErrors],
    getActionLink: (actionItemRow) =>
      // TODO: https://transcend.height.app/T-24091 - Change rollup behavior to filter
      safeJoinPath(AdminDashboardInfrastructurePath.DataSiloLookups, {
        dataSiloId: actionItemRow.sourceIds[0],
      }),
  },
  [ActionItemCode.DataSilosNeedingManualEntry]: {
    icon: 'changes-requested',
    richMessage:
      actionItemRichDescriptions[ActionItemCode.DataSilosNeedingManualEntry],
    descriptionMessage:
      actionItemFullDescriptions[ActionItemCode.DataSilosNeedingManualEntry],
    documentationLink:
      'https://docs.transcend.io/docs/privacy-requests/connecting-data-silos/automated-vendor-coordination' +
      '#using-the-bulk-request-interface',
    documentationMessage:
      actionItemDocumentationMessages.usingTheBulkRequestInterface,
    getActionLink: (actionItemRow) =>
      safeJoinPath(
        `${AdminDashboardPublicMiscPath.BulkRequests}?dataSiloId=:dataSiloId`,
        {
          dataSiloId: actionItemRow.sourceIds[0],
        },
      ),
  },
  [ActionItemCode.ProfileDataPointStatus]: {
    icon: 'menu-block-checkmark',
    richMessage:
      actionItemRichDescriptions[ActionItemCode.ProfileDataPointStatus],
    descriptionMessage:
      actionItemFullDescriptions[ActionItemCode.ProfileDataPointStatus],
    getActionLink: (actionItemRow) =>
      // TODO: https://transcend.height.app/T-24091 - Change rollup behavior to filter
      safeJoinPath(
        AdminDashboardPrivacyRequestPostOnboardingPath.RequestDataSilo,
        {
          requestId: actionItemRow.additionalContexts?.[0].requestId,
          dataSiloId: actionItemRow.additionalContexts?.[0].dataSiloId,
        },
      ),
  },
  [ActionItemCode.RequestExpiry]: {
    icon: 'x-bubble',
    richMessage: actionItemRichDescriptions[ActionItemCode.RequestExpiry],
    descriptionMessage:
      actionItemFullDescriptions[ActionItemCode.RequestExpiry],
    getActionLink: (actionItemRow) =>
      actionItemRow.count === 1
        ? safeJoinPath(AdminDashboardPrivacyRequestPostOnboardingPath.Request, {
            requestId: actionItemRow.sourceIds[0],
          })
        : safeJoinPath(
            AdminDashboardPrivacyRequestPostOnboardingPath.IncomingRequests,
            {},
            {
              createdAtBefore: moment().subtract(20, 'days').toISOString(),
              // TODO: https://transcend.height.app/T-27042 - remove me once bug is fixed
              createdAtAfter: moment().subtract(1, 'years').toISOString(),
              isClosed: false,
            },
          ),
  },
  [ActionItemCode.RequestDataSiloError]: {
    documentationLink:
      'https://docs.transcend.io/docs/privacy-requests/processing-requests/view-status-of-a-request',
    icon: 're-scan',
    richMessage:
      actionItemRichDescriptions[ActionItemCode.RequestDataSiloError],
    descriptionMessage:
      actionItemFullDescriptions[ActionItemCode.RequestDataSiloError],
    documentationMessage: actionItemDocumentationMessages.viewStatusOfRequest,
    getActionLink: (actionItemRow) =>
      // TODO: https://transcend.height.app/T-24091 - Change rollup behavior to filter
      safeJoinPath(
        AdminDashboardPrivacyRequestPostOnboardingPath.RequestDataSilo,
        {
          requestId: actionItemRow.additionalContexts?.[0].requestId,
          dataSiloId: actionItemRow.additionalContexts?.[0].dataSiloId,
        },
      ),
  },
  [ActionItemCode.RequestEnricherError]: {
    documentationLink:
      'https://docs.transcend.io/docs/privacy-requests/processing-requests/view-status-of-a-request',
    icon: 'plane-flight-canceled',
    richMessage:
      actionItemRichDescriptions[ActionItemCode.RequestEnricherError],
    descriptionMessage:
      actionItemFullDescriptions[ActionItemCode.RequestEnricherError],
    documentationMessage: actionItemDocumentationMessages.viewStatusOfRequest,
    getActionLink: (actionItemRow) =>
      // TODO: https://transcend.height.app/T-24091 - Change rollup behavior to filter
      safeJoinPath(
        `${AdminDashboardPrivacyRequestPostOnboardingPath.Request}/#Details`,
        {
          requestId: actionItemRow.additionalContexts?.[0].requestId,
        },
      ),
  },
  [ActionItemCode.RequestIdentifierNeedsVerification]: {
    icon: 'checkmark-gear',
    richMessage:
      actionItemRichDescriptions[
        ActionItemCode.RequestIdentifierNeedsVerification
      ],
    descriptionMessage:
      actionItemFullDescriptions[
        ActionItemCode.RequestIdentifierNeedsVerification
      ],
    getActionLink: (actionItemRow) =>
      // TODO: https://transcend.height.app/T-24091 - Change rollup behavior to filter
      safeJoinPath(
        `${AdminDashboardPrivacyRequestPostOnboardingPath.Request}/#Details`,
        {
          requestId: actionItemRow.additionalContexts?.[0].requestId,
        },
      ),
  },
  [ActionItemCode.RequestAssignedToUser]: {
    documentationLink:
      'https://docs.transcend.io/docs/privacy-requests/processing-requests/view-status-of-a-request',
    icon: 'menu-block-checkmark',
    richMessage:
      actionItemRichDescriptions[ActionItemCode.RequestAssignedToUser],
    descriptionMessage:
      actionItemFullDescriptions[ActionItemCode.RequestAssignedToUser],
    documentationMessage: actionItemDocumentationMessages.viewStatusOfRequest,
    getActionLink: (actionItemRow) =>
      // TODO: https://transcend.height.app/T-24091 - Change rollup behavior to filter
      safeJoinPath(
        `${AdminDashboardPrivacyRequestPostOnboardingPath.Request}/#Details`,
        {
          requestId: actionItemRow.sourceIds[0],
        },
      ),
  },
  [ActionItemCode.RequestEnricherPersonNeedsManualEntry]: {
    icon: 'phone',
    richMessage:
      actionItemRichDescriptions[
        ActionItemCode.RequestEnricherPersonNeedsManualEntry
      ],
    descriptionMessage:
      actionItemFullDescriptions[
        ActionItemCode.RequestEnricherPersonNeedsManualEntry
      ],
    getActionLink: (actionItemRow) =>
      // TODO: https://transcend.height.app/T-24091 - Change rollup behavior to filter
      safeJoinPath(
        `${AdminDashboardPrivacyRequestPostOnboardingPath.Request}/#Details`,
        {
          requestId: actionItemRow.additionalContexts?.[0].requestId,
        },
      ),
  },
  [ActionItemCode.UserAwaitingNotification]: {
    documentationLink: 'https://docs.transcend.io/docs/security/access-control',
    icon: 'user',
    richMessage:
      actionItemRichDescriptions[ActionItemCode.UserAwaitingNotification],
    descriptionMessage:
      actionItemFullDescriptions[ActionItemCode.UserAwaitingNotification],
    documentationMessage: actionItemDocumentationMessages.accessControl,
    // TODO: https://transcend.height.app/T-24095 - Add filters to URL for users page
    getActionLink: () => AdminDashboardAdministrationPath.Users,
  },
  [ActionItemCode.UserNeedsConfiguration]: {
    documentationLink: 'https://docs.transcend.io/docs/security/access-control',
    icon: 'user',
    richMessage:
      actionItemRichDescriptions[ActionItemCode.UserNeedsConfiguration],
    descriptionMessage:
      actionItemFullDescriptions[ActionItemCode.UserNeedsConfiguration],
    documentationMessage: actionItemDocumentationMessages.accessControl,
    // TODO: https://transcend.height.app/T-24095 - Add filters to URL for users page
    getActionLink: () => AdminDashboardAdministrationPath.Users,
  },
  [ActionItemCode.RequestActionableStatus]: {
    documentationLink:
      'https://docs.transcend.io/docs/privacy-requests/processing-requests/review-and-approval',
    icon: 'flag-checkmark',
    richMessage:
      actionItemRichDescriptions[ActionItemCode.RequestActionableStatus],
    descriptionMessage:
      actionItemFullDescriptions[ActionItemCode.RequestActionableStatus],
    documentationMessage: actionItemDocumentationMessages.viewStatusOfRequest,
    getActionLink: (actionItemRow) =>
      actionItemRow.count > 1
        ? safeJoinPath(
            AdminDashboardPrivacyRequestPostOnboardingPath.IncomingRequests,
            {},
            {
              status: [
                RequestStatus.Approving,
                RequestStatus.SecondaryApproving,
              ],
            },
          )
        : safeJoinPath(AdminDashboardPrivacyRequestPostOnboardingPath.Request, {
            requestId: actionItemRow.sourceIds[0],
          }),
  },
  [ActionItemCode.RequestOnHold]: {
    documentationLink:
      'https://docs.transcend.io/docs/privacy-requests/processing-requests/view-status-of-a-request',
    icon: 'flag-checkmark',
    richMessage: actionItemRichDescriptions[ActionItemCode.RequestOnHold],
    descriptionMessage:
      actionItemFullDescriptions[ActionItemCode.RequestOnHold],
    documentationMessage: actionItemDocumentationMessages.viewStatusOfRequest,
    getActionLink: (actionItemRow) =>
      actionItemRow.count > 1
        ? safeJoinPath(
            AdminDashboardPrivacyRequestPostOnboardingPath.IncomingRequests,
            {},
            { status: [RequestStatus.OnHold] },
          )
        : safeJoinPath(AdminDashboardPrivacyRequestPostOnboardingPath.Request, {
            requestId: actionItemRow.sourceIds[0],
          }),
  },
  [ActionItemCode.SombraVersionUpgrade]: {
    documentationLink:
      'https://docs.transcend.io/docs/security/end-to-end-encryption/deploying-sombra',
    icon: 'download',
    richMessage:
      actionItemRichDescriptions[ActionItemCode.SombraVersionUpgrade],
    descriptionMessage:
      actionItemFullDescriptions[ActionItemCode.SombraVersionUpgrade],
    documentationMessage: actionItemDocumentationMessages.deployingSombra,
    getActionLink: () => AdminDashboardInfrastructurePath.SombraChangelog,
  },
  [ActionItemCode.SombraNeedsKeyRotation]: {
    documentationLink:
      'https://docs.transcend.io/docs/security/end-to-end-encryption/key-rotation',
    icon: 'lock-hexagon',
    richMessage:
      actionItemRichDescriptions[ActionItemCode.SombraNeedsKeyRotation],
    descriptionMessage:
      actionItemFullDescriptions[ActionItemCode.SombraNeedsKeyRotation],
    documentationMessage: actionItemDocumentationMessages.sombraKeyRotations,
    getActionLink: () => AdminDashboardInfrastructurePath.SombraRotateKeys,
  },
  [ActionItemCode.DataFlowNeedsReview]: {
    documentationLink:
      'https://docs.transcend.io/docs/consent/configuration/triage-cookies-and-dataflows-guide',
    icon: 'search-radar-location',
    richMessage: actionItemRichDescriptions[ActionItemCode.DataFlowNeedsReview],
    descriptionMessage:
      actionItemFullDescriptions[ActionItemCode.DataFlowNeedsReview],
    documentationMessage: actionItemDocumentationMessages.reviewDataFlows,
    getActionLink: () => AdminDashboardCmPostOnboardingPath.DataFlowsTriage,
  },
  [ActionItemCode.DataFlowAssignedForReview]: {
    documentationLink:
      'https://docs.transcend.io/docs/consent/configuration/triage-cookies-and-dataflows-guide',
    icon: 'menu-block-checkmark',
    richMessage:
      actionItemRichDescriptions[ActionItemCode.DataFlowAssignedForReview],
    descriptionMessage:
      actionItemFullDescriptions[ActionItemCode.DataFlowAssignedForReview],
    documentationMessage: actionItemDocumentationMessages.reviewDataFlows,
    getActionLink: () => AdminDashboardCmPostOnboardingPath.DataFlowsTriage,
  },
  [ActionItemCode.CookieNeedsReview]: {
    documentationLink:
      'https://docs.transcend.io/docs/consent/configuration/triage-cookies-and-dataflows-guide',
    icon: 'search-radar-location',
    richMessage: actionItemRichDescriptions[ActionItemCode.CookieNeedsReview],
    descriptionMessage:
      actionItemFullDescriptions[ActionItemCode.CookieNeedsReview],
    documentationMessage: actionItemDocumentationMessages.reviewCookies,
    getActionLink: () => AdminDashboardCmPostOnboardingPath.CookiesTriage,
  },
  [ActionItemCode.CookieAssignedForReview]: {
    documentationLink:
      'https://docs.transcend.io/docs/consent/configuration/triage-cookies-and-dataflows-guide',
    icon: 'menu-block-checkmark',
    richMessage:
      actionItemRichDescriptions[ActionItemCode.CookieAssignedForReview],
    descriptionMessage:
      actionItemFullDescriptions[ActionItemCode.CookieAssignedForReview],
    documentationMessage: actionItemDocumentationMessages.reviewCookies,
    // Same exact link as CookieNeedsReview
    getActionLink: () => AdminDashboardCmPostOnboardingPath.CookiesTriage,
  },
  [ActionItemCode.ConsentManagerVersionUpgrade]: {
    icon: 'menubar-arrow-down',
    richMessage:
      actionItemRichDescriptions[ActionItemCode.ConsentManagerVersionUpgrade],
    descriptionMessage:
      actionItemFullDescriptions[ActionItemCode.ConsentManagerVersionUpgrade],
    getActionLink: () =>
      safeJoinPath(AdminDashboardCmPostOnboardingPath.AirgapChangeLog),
  },
  [ActionItemCode.BusinessEntityNeedsDocumentation]: {
    documentationLink:
      'https://docs.transcend.io/docs/data-mapping/data-inventory',
    icon: 'menu-block-checkmark',
    richMessage:
      actionItemRichDescriptions[
        ActionItemCode.BusinessEntityNeedsDocumentation
      ],
    descriptionMessage:
      actionItemFullDescriptions[
        ActionItemCode.BusinessEntityNeedsDocumentation
      ],
    documentationMessage: actionItemDocumentationMessages.dataInventory,
    getActionLink: (actionItemRow) =>
      safeJoinPath(
        AdminDashboardDataMapPath.BusinessEntities,
        {},
        {
          text:
            actionItemRow.count === 1 ? actionItemRow.titles?.[0] : undefined,
        },
      ),
  },
  [ActionItemCode.PluginsWithErrors]: {
    documentationLink:
      'https://docs.transcend.io/docs/data-mapping/data-silo-discovery',
    icon: 'plugin',
    richMessage: actionItemRichDescriptions[ActionItemCode.PluginsWithErrors],
    descriptionMessage:
      actionItemFullDescriptions[ActionItemCode.PluginsWithErrors],
    documentationMessage: actionItemDocumentationMessages.dataSiloDiscovery,
    getActionLink: (actionItemRow) =>
      safeJoinPath(AdminDashboardInfrastructurePath.DataSiloSiloDiscovery, {
        dataSiloId: actionItemRow.sourceIds[0],
      }),
  },
  // should remain empty
  [ActionItemCode.Onboarding]: {
    icon: 'sparkles',
    richMessage: actionItemRichDescriptions[ActionItemCode.Onboarding],
    descriptionMessage: actionItemFullDescriptions[ActionItemCode.Onboarding],
  },
  [ActionItemCode.DataPointDatabaseQueryNeedsApproval]: {
    icon: 'server-databases-checkmark',
    richMessage:
      actionItemRichDescriptions[
        ActionItemCode.DataPointDatabaseQueryNeedsApproval
      ],
    descriptionMessage:
      actionItemFullDescriptions[
        ActionItemCode.DataPointDatabaseQueryNeedsApproval
      ],
    getActionLink: (actionItemRow) =>
      safeJoinPath(AdminDashboardInfrastructurePath.DataSiloManageDatapoints, {
        dataSiloId: actionItemRow.additionalContexts?.[0].dataSiloId,
      }),
    parentType: 'DATA_SILO',
  },
};

export const ACTION_ITEM_PRIORITY_COLOR_NAMES: {
  [k in ActionItemPriorityOverride]: keyof DarkColorOption;
} = {
  [ActionItemPriorityOverride.Critical]: 'orange1',
  [ActionItemPriorityOverride.High]: 'pink1',
  [ActionItemPriorityOverride.Medium]: 'purple1',
  [ActionItemPriorityOverride.Low]: 'blue1',
  [ActionItemPriorityOverride.WontDo]: 'red1',
};
/* eslint-enable max-lines */
