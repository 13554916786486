import { RequestAction } from '@transcend-io/privacy-types';

import { mkInput, mkType, SchemaToType } from '@main/schema-utils';

import { RequestsProcessedDisclosureStatsTableHeaders } from './enums';

export const RequestsProcessedDisclosureStatsInput = mkInput({
  name: 'RequestsProcessedDisclosureStatsInput',
  comment: 'Input for getting stats for CCPA requests processed disclosure',
  fields: {
    years: {
      comment: 'The calendar years to get metrics for',
      type: 'int',
      list: true,
      optional: true,
    },
    showRawValues: {
      comment:
        'Should the raw results be returned, without overrides or other settings applied',
      type: 'boolean',
      optional: true,
    },
  },
});

/** Override type */
export type RequestsProcessedDisclosureStatsInput = SchemaToType<
  typeof RequestsProcessedDisclosureStatsInput
>;

export const RequestsProcessedDisclosureStat = mkType({
  name: 'RequestsProcessedDisclosureStat',
  comment: 'A single row of requests processd stats',
  fields: {
    type: {
      comment: 'The type of the request',
      type: { RequestAction },
    },
    [RequestsProcessedDisclosureStatsTableHeaders.Received]: {
      comment: 'The total number of requests received',
      type: 'int',
    },
    [RequestsProcessedDisclosureStatsTableHeaders.Processed]: {
      comment: 'The total number of requests processed',
      type: 'int',
    },
    [RequestsProcessedDisclosureStatsTableHeaders.Denied]: {
      comment: 'The total number of requests denied',
      type: 'int',
    },
    [RequestsProcessedDisclosureStatsTableHeaders.MedianNumberOfDays]: {
      comment: 'The median number of days taken for request resolution',
      type: 'int',
    },
  },
});

/** Override type */
export type RequestsProcessedDisclosureStat = SchemaToType<
  typeof RequestsProcessedDisclosureStat
>;

export const RequestsProcessedDisclosureStatsByYear = mkType({
  name: 'RequestsProcessedDisclosureStatsByYear',
  comment: 'Stats on requests processed by year, for CCPA disclosure',
  fields: {
    year: {
      comment: 'The calendar year these stats are for',
      type: 'int',
    },
    stats: {
      comment: 'Disclosure stats by request type',
      type: RequestsProcessedDisclosureStat,
      list: true,
    },
  },
});

/** Override type */
export type RequestsProcessedDisclosureStatsByYear = SchemaToType<
  typeof RequestsProcessedDisclosureStatsByYear
>;

export const RequestsProcessedDisclosureStatOverride = mkType({
  name: 'RequestsProcessedDisclosureStatOverride',
  comment: 'An individual override for a requests processed stat value',
  fields: {
    year: {
      comment: 'The calendar year this stat is for',
      type: 'int',
    },
    actionType: {
      comment: 'The action type of the request',
      type: { RequestAction },
    },
    statType: {
      comment: 'The stat type to override',
      type: { RequestsProcessedDisclosureStatsTableHeaders },
    },
    statValue: {
      comment: 'The value to override with',
      type: 'int',
    },
  },
});

/** Override type */
export type RequestsProcessedDisclosureStatOverride = SchemaToType<
  typeof RequestsProcessedDisclosureStatOverride
>;

export const RequestsProcessedDisclosureStatOverrideInput = mkInput({
  name: 'RequestsProcessedDisclosureStatOverrideInput',
  comment: 'An individual override for a requests processed stat value',
  fields: RequestsProcessedDisclosureStatOverride.fields,
});

/** Override type */
export type RequestsProcessedDisclosureStatOverrideInput = SchemaToType<
  typeof RequestsProcessedDisclosureStatOverrideInput
>;

export const RequestsProcessedDisclosureStatsSettingsInput = mkInput({
  name: 'RequestsProcessedDisclosureStatsSettingsInput',
  comment: 'Input to change settings for Requests Processed Disclosure Stats',
  fields: {
    privacyCenterId: {
      comment: 'The ID of the Privacy Center to update',
      modelName: 'privacyCenter',
      type: 'id',
    },
    disabledActions: {
      comment: 'A list of action types to hide from the result and table',
      type: { RequestAction },
      list: true,
      optional: true,
    },
    tableEnabled: {
      comment:
        'Is the table enabled at all? If disabled, results will not be calculated',
      type: 'boolean',
      optional: true,
    },
    valueOverrides: {
      comment: 'Individual overrides, by year',
      type: RequestsProcessedDisclosureStatOverrideInput,
      list: true,
      optional: true,
    },
  },
});

/** Override type */
export type RequestsProcessedDisclosureStatsSettingsInput = SchemaToType<
  typeof RequestsProcessedDisclosureStatsSettingsInput
>;

export const RequestsProcessedDisclosureStatsSettings = mkType({
  name: 'RequestsProcessedDisclosureStatsSettings',
  comment: 'Settings for Requests Processed Disclosure Stats',
  fields: {
    privacyCenterId: {
      comment: 'The ID of the privacy center',
      modelName: 'privacyCenter',
      type: 'id',
    },
    disabledActions: {
      comment: 'A list of action types to hide from the result and table',
      type: { RequestAction },
      list: true,
    },
    tableEnabled: {
      comment:
        'Is the table enabled at all? If disabled, results will not be calculated',
      type: 'boolean',
    },
    valueOverrides: {
      comment: 'Individual overrides, by year',
      type: RequestsProcessedDisclosureStatOverride,
      list: true,
    },
  },
});

/** Override type */
export type RequestsProcessedDisclosureStatsSettings = SchemaToType<
  typeof RequestsProcessedDisclosureStatsSettings
>;
