import ReactFlow from 'reactflow';
import styled from 'styled-components';

import { Card } from '../Card';

const CONNECT_HANDLE_SIZE = 12;

export const StyledReactFlow = styled(ReactFlow)<{
  /** is the graph read-only? */
  $readOnly?: boolean;
}>`
  .react-flow__edge.selected .react-flow__edge-path,
  .react-flow__edge:focus .react-flow__edge-path,
  .react-flow__edge:focus-visible .react-flow__edge-path {
    stroke: ${({ theme }) => theme.colors.transcendNavy2} !important;
  }

  .react-flow__handle {
    // needs to be visibility: hidden, not display:none for react-flow to work correctly
    // https://reactflow.dev/api-reference/components/handle#notes
    ${({ $readOnly }) => ($readOnly ? 'visibility: hidden;' : '')}
    min-width: ${CONNECT_HANDLE_SIZE}px;
    min-height: ${CONNECT_HANDLE_SIZE}px;
    width: ${CONNECT_HANDLE_SIZE}px;
    height: ${CONNECT_HANDLE_SIZE}px;

    // copy the same border style as parent
    border-width: inherit;
    border-color: inherit;
    background: ${({ theme }) => theme.colors.white};

    &.react-flow__handle-left {
      left: -${CONNECT_HANDLE_SIZE / 2}px;
    }

    &.react-flow__handle-right {
      right: -${CONNECT_HANDLE_SIZE / 2}px;
    }
  }
}
`;

export const StyledDataSiloCard = styled(Card)`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-grow: 1;
  gap: 6px;
  position: relative;
  height: 40px;
  width: 160px;
  border: 1px solid ${({ theme }) => theme.colors.transcendNavy4};
  border-radius: 4px;
  padding: 10px 12px 10px 12px;
`;

export const StyledDataSiloCardTitle = styled.div`
  font-size: 14px;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.transcendNavy};
  word-break: break-word;
  text-overflow: ellipsis;
  overflow: hidden;
  // supported on all major browsers (even non-webkit)
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
`;
