import {
  FormItemWrapper,
  IFormInputValidatedProps,
  IFormItemWrapperProps,
  multipleValidators,
} from '@main/core-ui';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';

import { selectRepositoriesMessages } from './messages';
import { SelectRepository } from './SelectRepositories';

export const FormSelectRepository: React.FC<
  IFormInputValidatedProps &
    Omit<IFormItemWrapperProps, 'errors'> & {
      /** display the outline on the select component */
      showOutline?: boolean;
    }
> = ({
  name,
  label,
  rules,
  info,
  infoArgs,
  errorDisplay,
  required,
  showOutline,
  ...rawInputProps
}) => {
  const { formatMessage } = useIntl();
  const {
    control,
    formState: { errors },
  } = useFormContext();
  return (
    <FormItemWrapper
      name={name}
      label={label || formatMessage(selectRepositoriesMessages.label)}
      info={info}
      infoArgs={infoArgs}
      errors={errors}
      errorDisplay={errorDisplay}
      required={required}
    >
      <Controller
        name={name}
        control={control}
        rules={
          rules
            ? { validate: multipleValidators(rules) }
            : required
              ? { required: true }
              : undefined
        }
        render={({ field: { ref: {} = {}, ...props } }) => (
          <SelectRepository
            {...rawInputProps}
            {...props}
            showSelectOutline={showOutline}
          />
        )}
      />
    </FormItemWrapper>
  );
};
