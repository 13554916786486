import { mkInput, SchemaToType } from '@main/schema-utils';
import {
  MCDConsentLookupInput,
  RecordOfConsentNextTokenInput,
} from '@main/sombra-types';

import { ConsentPreferencesInput } from './consentPreference';

/** Input to the endpoint retrieving records of consent */
export const RecordsOfConsentInput = mkInput({
  name: 'RecordsOfConsentInput',
  comment: 'Input for querying records of consent',
  fields: {
    partition: MCDConsentLookupInput.fields.partition,
    timestampBefore: MCDConsentLookupInput.fields.timestampBefore,
    timestampAfter: MCDConsentLookupInput.fields.timestampAfter,
    updatedBefore: MCDConsentLookupInput.fields.updatedBefore,
    updatedAfter: MCDConsentLookupInput.fields.updatedAfter,
    identifiers: ConsentPreferencesInput.fields.identifiers,
    nextToken: {
      type: RecordOfConsentNextTokenInput,
      comment: 'Token to continue fetching records of consent',
      optional: true,
    },
  },
});

/** Override type */
export type RecordsOfConsentInput = SchemaToType<typeof RecordsOfConsentInput>;
