import { useInlineInput } from '@main/ad-core-components';
import { endpoints } from '@main/code-scanning-types';
import { buildUseMutation } from '@main/core-ui';
import type { ID } from '@main/schema-utils';
import React from 'react';

import {
  SelectCodePackageProps,
  SelectCodePackages,
  SelectedCodePackage,
} from '../components';

interface InlineSoftwareDevelopmentKitsCodePackagesUpdateProps
  extends Omit<SelectCodePackageProps, 'onChange' | 'onBlur' | 'value'> {
  /** The ID of the parent softwareDevelopmentKit for this cell */
  id: ID<'softwareDevelopmentKit'>;
  /** The value for the cell */
  value: SelectedCodePackage[];
}

const useUpdateSoftwareDevelopmentKits = buildUseMutation(
  endpoints.updateSoftwareDevelopmentKits,
);

export const InlineSoftwareDevelopmentKitsCodePackagesUpdate: React.FC<
  InlineSoftwareDevelopmentKitsCodePackagesUpdateProps
> = ({ id, value: initialValue, isLoading, ...props }) => {
  const [updateSoftwareDevelopmentKits, { loading }] =
    useUpdateSoftwareDevelopmentKits();

  const { value, setValue, onBlur } = useInlineInput<
    SelectedCodePackage[] | null
  >({
    initialValue,
    onUpdate: (values) =>
      updateSoftwareDevelopmentKits({
        variables: {
          input: {
            softwareDevelopmentKits: [
              {
                id,
                codePackageIds: (values || []).map(({ id }) => id),
              },
            ],
          },
        },
      }).then(({ data, errors }) => ({
        data: data?.softwareDevelopmentKits[0]?.codePackages,
        errors,
      })),
  });

  return (
    <SelectCodePackages
      value={value || []}
      isLoading={loading || isLoading}
      onChange={(newValues) => {
        setValue([...newValues]);
      }}
      onBlur={onBlur}
      {...props}
    />
  );
};
