import { useEffect, useRef, useState } from 'react';

import { ONE_SECOND } from '@main/utils';

const MAX_INTERCOM_LOAD_TIME = ONE_SECOND * 5;

/**
 * hook to poll to check if intercom is loaded
 *
 * @returns if it's fully loaded
 */
export const useIntercomLoaded = (): {
  /** is intercom loaded yet */
  intercomLoaded: boolean;
  /** is intercom failed to load in the required time window */
  intercomFailed: boolean;
} => {
  const [intercomLoaded, setIntercomLoaded] = useState(false);
  const [intercomFailed, setIntercomFailed] = useState(false);
  const timer = useRef<number>();
  const startTime = useRef(Date.now());

  useEffect(() => {
    const checkIntercomLoaded = (): void => {
      if (typeof Intercom === 'undefined' || intercomLoaded) {
        setIntercomLoaded(true);
        if (timer.current) {
          clearInterval(timer.current);
        }
      }

      if (Date.now() - startTime.current > MAX_INTERCOM_LOAD_TIME) {
        setIntercomFailed(true);
        if (timer.current) {
          clearInterval(timer.current);
        }
      }
    };
    // poll handle every 100ms to check if it's defined
    timer.current = setInterval(checkIntercomLoaded, 100) as any;
    // also check immediately
    checkIntercomLoaded();
  }, []);

  return { intercomLoaded, intercomFailed };
};
