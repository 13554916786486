import { defineMessages } from '@transcend-io/internationalization';

export const confirmNavigationModalMessages = defineMessages(
  'ad-core-components.ConfirmNavigationModal.confirmNavigationModal',
  {
    cancel: {
      defaultMessage: 'Cancel',
    },
    confirm: {
      defaultMessage: 'Proceed',
    },
  },
);
