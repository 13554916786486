import { defineMessages } from '@transcend-io/internationalization';

export const lastTimePeriodSelectMessages = defineMessages(
  'ad-core-components.LastTimePeriodSelect.lastTimePeriodSelect',
  {
    custom: {
      defaultMessage: 'Custom',
    },
    startDate: {
      defaultMessage: 'Start Date',
    },
    endDate: {
      defaultMessage: 'End Date',
    },
    lastOneWeek: {
      defaultMessage: 'Last 1 week',
    },
    lastOneMonth: {
      defaultMessage: 'Last 1 month',
    },
    lastSixMonths: {
      defaultMessage: 'Last 6 months',
    },
    lastYear: {
      defaultMessage: 'Last 1 year',
    },
    allTime: {
      defaultMessage: 'All Time',
    },
  },
);
