import { transparentize } from 'polished';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { FlexRowCenterVertical } from '@main/core-ui';
import { sp } from '@main/theme';

export interface StyledCardProps {
  /** Padding */
  padding?: string;
  /** Border radius */
  radius?: string;
  /** Centered title text or not */
  centered?: boolean;
}

const coreCardStyles = css`
  background: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.transcendNavy4};
  /** So position: absolute for ActionButtonArea works */
  position: relative;
  display: flex;
  flex-direction: column;
`;

export const StyledCard = styled.div<StyledCardProps>`
  ${coreCardStyles}
  border-radius: ${({ radius }) => radius || '12px'};
  padding: ${({ padding }) => padding};

  ${({ onClick, theme }) =>
    onClick
      ? `
  cursor: pointer;
  transition: box-shadow 0.2s;

  &:hover,
  &:focus {
    box-shadow: 0 8px 8px
    ${transparentize(0.93, theme.colors.black)};
  }
  `
      : ''}
`;

export const StyledCardTitle = styled.h2<StyledCardProps>`
  text-align: ${({ centered }) => (centered ? 'center' : 'left')};
  color: ${({ theme }) => theme.colors.transcendNavy};
  font-size: 24px;
  margin-bottom: 28px;
`;

/**
 *  Card title container
 */
export const StyledCardTitleContainer = styled(FlexRowCenterVertical)`
  justify-content: space-between;
  gap: ${sp(1.5)};
`;

/*
 * Card title wrapper
 *
 * TODO: https://transcend.height.app/T-39201 - Remove h2 componentStyle when fixed
 */
export const StyledCardTitleWrapper = styled.h2`
  ${({ theme }) => theme.componentStyles.h2};
  text-align: left;
  flex-grow: 1;
`;

export const StyledCardLink = styled(Link)<StyledCardProps>`
  ${coreCardStyles}

  font-size: unset;
  color: unset;
  font-weight: unset;
  border-radius: ${({ radius }) => radius || '12px'};
  padding: ${({ padding }) => padding};
  text-decoration: none;

  background-color: ${({ theme }) => theme.colors.white};
  transition: box-shadow 0.2s;

  &:hover,
  &:focus {
    box-shadow: 0 8px 8px
      ${({ theme }) => transparentize(0.93, theme.colors.black)};
  }
`;
