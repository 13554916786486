import styled from 'styled-components';

import { Button } from '../Button';

export const StyledColorButton = styled(Button)<{
  /** The color of the button */
  color: string;
}>`
  height: 24px;
  width: 24px;
  padding: 0;
  border-radius: 50%;
  background: ${({ color }) => color};
  border: ${({ color, theme }) =>
    `1px solid ${
      color === theme.colors.gray1 ? theme.colors.transcendNavy4 : color
    }`};

  &:hover,
  &:active {
    background: ${({ color }) => color};
    border: ${({ color, theme }) =>
      `1px solid ${
        color === theme.colors.gray1 ? theme.colors.transcendNavy4 : color
      }`};
  }

  &:not(:last-child) {
    margin-right: 10px;
  }
`;

export const StyledSelectedColorMark = styled.div`
  height: 12px;
  width: 12px;
  border-radius: 50%;
  background: ${({ theme }) => theme.colors.white};
`;

export const StyledColorLabel = styled.div`
  font-size: 12px;
  color: ${({ theme }) => theme.colors.transcendNavy2};
  font-weight: 600;
  align-self: center;
`;

export const StyledMainSelectedColor = styled.div<{
  /** The selected color */
  color: string;
}>`
  height: 16px;
  width: 16px;
  border-radius: 50%;
  background: ${({ color }) => color};
  border: ${({ color, theme }) =>
    `1px solid ${
      color === theme.colors.gray1 ? theme.colors.transcendNavy4 : color
    }`};
`;
