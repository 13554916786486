import React from 'react';

import { ErrorText, ErrorTextProps } from '../ErrorText';
import {
  GenericMessageDescriptor,
  useFormatMessageGeneric,
} from '../GenericFormattedMessage';
import { StyleUtils } from '../StyledWrappers';
import { Alert, AlertProps } from './Alert';

export interface ErrorAlertProps extends Pick<AlertProps, 'variant' | 'href'> {
  /** the error to display */
  error?: ErrorTextProps['error'];
  /** the errors to display */
  errors?: ErrorTextProps['error'][];
  /** the title of the error alert */
  title?: GenericMessageDescriptor;
  /** Maximum length to show error message before truncating */
  maxErrorLengthTruncated?: number;
}

/**
 * ErrorAlert
 */
export const ErrorAlert: React.FC<ErrorAlertProps> = ({
  error,
  errors,
  children,
  variant = 'danger',
  title,
  href,
  maxErrorLengthTruncated,
}) => {
  const { formatMessageGeneric } = useFormatMessageGeneric();

  return (
    <Alert
      variant={variant}
      style={StyleUtils.Flex.Row.CenterVertical}
      icon="exclamation-circle"
      href={href}
    >
      {title && (
        <div style={{ color: 'inherit', fontWeight: 600 }}>
          {formatMessageGeneric(title)}
        </div>
      )}
      {errors ? (
        <ul>
          {errors.map((err, i) => (
            <li key={i}>
              <ErrorText
                error={err}
                hasMargin={false}
                isBold={false}
                inheritColor
                shouldTruncate
              />
            </li>
          ))}
        </ul>
      ) : (
        <ErrorText
          error={error}
          hasMargin={false}
          isBold={false}
          inheritColor
          maxErrorLengthTruncated={maxErrorLengthTruncated}
          shouldTruncate
        >
          {children}
        </ErrorText>
      )}
    </Alert>
  );
};
