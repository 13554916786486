import { mkInput, mkType, SchemaToType } from '@main/schema-utils';

export const ConsentPartition = mkType({
  name: 'ConsentPartition',
  comment: 'The partition (namespace) for storing consent data',
  fields: {
    id: {
      type: 'id',
      modelName: 'airgapPartition',
      comment: 'ID of the consent partition',
    },
    name: {
      type: 'string',
      comment: 'Human-readable name of this consent partition',
    },
    partition: {
      type: 'string',
      comment:
        'Unique random string that will be used to partition consent data by airgap.js and consent-backend',
    },
  },
});

/** Override type */
export type ConsentPartition = SchemaToType<typeof ConsentPartition>;

export const ConsentPartitionFiltersInput = mkInput({
  name: 'ConsentPartitionFiltersInput',
  comment: 'Input for filter consent partition',
  fields: {
    ids: {
      type: 'id',
      modelName: 'airgapPartition',
      comment: 'ID of the consent partition',
      list: true,
      optional: true,
    },
    name: {
      type: 'string',
      comment: 'Human-readable name of this consent partition',
      optional: true,
    },
    partition: {
      type: 'string',
      comment: 'Unique partition value',
      optional: true,
    },
  },
});

/** Override type */
export type ConsentPartitionFiltersInput = SchemaToType<
  typeof ConsentPartitionFiltersInput
>;

export const CreateConsentPartitionInput = mkInput({
  name: 'CreateConsentPartitionInput',
  comment: 'Input for creating a new consent partition',
  fields: {
    id: {
      comment: 'The ID of the airgap bundle',
      type: 'id',
      modelName: 'airgapBundle',
    },
    name: ConsentPartition.fields.name,
  },
});

/** Override type */
export type CreateConsentPartitionInput = SchemaToType<
  typeof CreateConsentPartitionInput
>;
