import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { LanguageKey } from '@transcend-io/internationalization';

import { LocalizedMessage, Message } from '@main/internationalization';
import { Policy } from '@main/pc-types';
import type { ID } from '@main/schema-utils';

import { deployedPolicyToModifiedPolicy } from './helpers';
import { ModifiedPolicy } from './types';

/**
 * the policies state
 */
export interface PoliciesState {
  /** The policies that exist in the database and are deployed. */
  deployedPolicies?: Policy[];
  /** The currently edited policies */
  policies?: ModifiedPolicy[];
}

export const policiesSlice = createSlice({
  name: 'PrivacyCenter.Policies',
  initialState: {} as PoliciesState,
  reducers: {
    resetPolicies: (state) => {
      state.policies = (state.deployedPolicies || []).map(
        deployedPolicyToModifiedPolicy,
      );
    },
    resetPolicy: (
      state,
      {
        payload: { id },
      }: PayloadAction<{
        /** The id of the policy to reset */ id: ID<'policy'>;
      }>,
    ) => {
      if (state.policies) {
        state.policies = state.policies.map((policy) => {
          if (policy.id === id) {
            const deployedPolicy = state.deployedPolicies?.find(
              (p) => p.id === id,
            );
            if (deployedPolicy) {
              return deployedPolicyToModifiedPolicy(deployedPolicy);
            }
          }
          return policy;
        });
      }
    },
    removePolicy: (
      state,
      {
        payload: { id },
      }: PayloadAction<{
        /** The id of the policy to be deleted */
        id: ID<'policy'>;
      }>,
    ) => {
      if (state.policies) {
        state.policies = state.policies.filter((policy) => policy.id !== id);
      }
    },
    setPolicies: (
      state,
      {
        payload: { policies },
      }: PayloadAction<{
        /** Polices to be set */
        policies: Policy[];
      }>,
    ) => {
      state.policies = policies.map(deployedPolicyToModifiedPolicy);
      state.deployedPolicies = policies;
    },
    changeContent: (
      state,
      {
        payload: { content, id, locale, privacyCenterDefaultLocale },
      }: PayloadAction<{
        /** The updated content */
        content: string;
        /** The id of the updated policy */
        id: ID<'policy'>;
        /** The locale the content is for; use undefined for default */
        locale: LanguageKey;
        /** The default locale for the privacy center */
        privacyCenterDefaultLocale: LanguageKey;
      }>,
    ) => {
      (state.policies || []).forEach((policy) => {
        if (policy.id === id) {
          const translations = policy.versions[0].content?.translations || [];
          // If we are modifying a specific translation, find it in the translations array
          const foundIndex = translations.findIndex(
            ({ locale: tLocale }) => locale === tLocale,
          );
          // If found, replace it
          if (foundIndex > -1) {
            translations[foundIndex] = { locale, value: content };
          }
          // If not found, add it
          else {
            translations.push({ locale, value: content });
          }
          policy.versions[0].content = {
            // If there is no defaultMessage already, we need to specify one.
            ...(policy.versions[0].content ?? { defaultMessage: content }),
            // If no locale is set or the locale is the
            // same as the default locale, update the defaultMessage
            ...(privacyCenterDefaultLocale === locale
              ? { defaultMessage: content }
              : {}),
            translations,
          };
        }
      });
    },
    changeAllContentTranslations: (
      state,
      {
        payload: { content, id },
      }: PayloadAction<{
        /** The updated content */
        content: Pick<Message, 'defaultMessage' | 'translations'>;
        /** The id of the updated policy */
        id: ID<'policy'>;
      }>,
    ) => {
      (state.policies || []).forEach((policy) => {
        if (policy.id === id) {
          policy.versions[0].content = content;
        }
      });
    },
    // It seems like the policies are being kept in array in the store because
    // that's the most convenient shape for another layer, not the store.
    // Considering the length of the array perf concerns is not the issue here,
    // the issue is that it's an awkward shape for no benefit within this layer.
    changeTitle: (
      state,
      {
        payload: { title, id },
      }: PayloadAction<{
        /** The updated title */
        title: LocalizedMessage;
        /** The id of the updated policy */
        id: ID<'policy'>;
      }>,
    ) => {
      if (state.policies) {
        state.policies.forEach((policy) => {
          if (policy.id === id) {
            policy.title = title;
          }
        });
      }
    },
    // change disabled locales
    changeDisabledLocales: (
      state,
      {
        payload: { disabledLocales, policyId },
      }: PayloadAction<{
        /** The locale being update */
        disabledLocales: LanguageKey[];
        /** The id of the updated policy */
        policyId: ID<'policy'>;
      }>,
    ) => {
      if (state.policies) {
        state.policies.forEach((policy) => {
          if (policy.id === policyId) {
            policy.disabledLocales = disabledLocales;
          }
        });
      }
    },
    changeDisableEffectiveOn: (
      state,
      {
        payload: { id, disableEffectiveOn },
      }: PayloadAction<{
        /** The id of the updated policy */
        id: ID<'policy'>;
        /** Whether to disable the effectiveOn date */
        disableEffectiveOn: boolean;
      }>,
    ) => {
      if (state.policies) {
        state.policies.forEach((policy) => {
          if (policy.id === id) {
            policy.disableEffectiveOn = disableEffectiveOn;
          }
        });
      }
    },
    changeEffectiveOn: (
      state,
      {
        payload: { date, id },
      }: PayloadAction<{
        /** The updated date */
        date: string;
        /** The id of the updated policy */
        id: ID<'policy'>;
      }>,
    ) => {
      if (state.policies) {
        state.policies.forEach((policy) => {
          if (policy.id === id) {
            policy.versions[0].effectiveOn = date;
          }
        });
      }
    },
    changeDisplayOrder: (
      state,
      {
        payload: { policyId, displayOrder },
      }: PayloadAction<{
        /** The policy to be updated */
        policyId: ID<'policy'>;
        /** Display order to be set */
        displayOrder: number;
      }>,
    ) => {
      if (state.policies) {
        const policyToMove = state.policies.find(
          (policy) => policy.id === policyId,
        );
        if (!policyToMove) {
          throw new Error(`Failed to find policy with ID: ${policyId}`);
        }

        const newPolicies = state.policies.filter(
          (policy) => policy.id !== policyId,
        );
        newPolicies.splice(displayOrder, 0, policyToMove);

        state.policies = newPolicies;
      }
    },
    createPolicy: (
      state,
      {
        payload: { policy },
      }: PayloadAction<{
        /** The policy to be created */
        policy: ModifiedPolicy;
      }>,
    ) => {
      if (state.policies) {
        state.policies.push(policy);
      } else {
        state.policies = [policy];
      }
    },
  },
});

export const {
  resetPolicies,
  resetPolicy,
  removePolicy,
  setPolicies,
  changeContent,
  changeAllContentTranslations,
  changeDisabledLocales,
  changeTitle,
  changeDisableEffectiveOn,
  changeDisplayOrder,
  changeEffectiveOn,
  createPolicy,
} = policiesSlice.actions;
