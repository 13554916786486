import { createSelector } from 'reselect';

import { RoutesState } from './slice';

export const getRoutesView = (state: any): RoutesState =>
  state.AdminDashboardRoutes;

/**
 * If the user is currently being refetched
 */
export const selectIsLoadingUser = createSelector(
  getRoutesView,
  ({ isLoadingUser }) => isLoadingUser,
);

/**
 * The page title
 */
export const selectDocumentTitle = createSelector(
  getRoutesView,
  ({ title }) => title,
);
