import {
  FlexRowCenterVertical,
  GenericFormattedMessage,
  GenericMessageDescriptor,
  Icon,
} from '@main/core-ui';
import React, { isValidElement, ReactElement } from 'react';
import { useIntl } from 'react-intl';

import { pageTitleMessages } from './messages';
import {
  StyledButton,
  StyledIcon,
  StyledText,
  StyledTitleBlock,
} from './wrappers';

export interface PageTitleProps {
  /** The main title of the page */
  title: GenericMessageDescriptor | ReactElement;
  /** Optional icon for page title, i.e. integrations logo */
  icon?: React.ReactNode;
  /** Name of the page to go back to */
  prevPageTitle?: GenericMessageDescriptor;
  /** Path of the page to go back to */
  prevPagePath?: string;
  /** whether it should override the default page title (move up 75px to the same position) */
  overrideDefaultPageTitle?: boolean;
}

/**
 * The common page title block component for ADv2 views, with a small back
 * button that returns to the previous "main" page during navigation flow.
 * Includes some standardized styling.
 */
export const PageTitle: React.FC<PageTitleProps> = ({
  title,
  icon,
  prevPageTitle,
  prevPagePath,
  overrideDefaultPageTitle,
}) => {
  const { formatMessage } = useIntl();

  return (
    <StyledTitleBlock
      style={
        overrideDefaultPageTitle
          ? // the default page title has a padding of 24px
            { marginTop: '-55px', paddingBottom: '24px' }
          : undefined
      }
    >
      {prevPagePath && prevPageTitle && (
        <StyledButton
          style={!overrideDefaultPageTitle ? { marginTop: '-31px' } : undefined}
          size="sm"
          variant="link"
          href={prevPagePath}
          icon={<Icon type="arrow-left" size={14} />}
        >
          <GenericFormattedMessage message={prevPageTitle} />
        </StyledButton>
      )}
      <FlexRowCenterVertical>
        {icon && typeof icon === 'string' ? (
          <StyledIcon
            src={icon}
            alt={formatMessage(pageTitleMessages.iconAltText)}
          />
        ) : (
          icon && icon
        )}
        <StyledText>
          {isValidElement(title) ? (
            title
          ) : (
            <GenericFormattedMessage
              message={title as GenericMessageDescriptor}
            />
          )}
        </StyledText>
      </FlexRowCenterVertical>
    </StyledTitleBlock>
  );
};
