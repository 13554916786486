import { defineMessages } from '@transcend-io/internationalization';

import { DataApplicationMethod } from '@main/datamap-types';

export const dataApplicationHeaderMessages =
  defineMessages<DataApplicationMethod>(
    'admin-dashboard.PrivacyCenter.DataPractices.dataApplicationHeader',
    {
      USAGE: {
        defaultMessage: 'How we use it',
      },
      SHARING: {
        defaultMessage: 'Who we share it with',
      },
    },
  );

/**
 * Message definitions for the data application popover
 */
export const dataApplicationPopoverMessages = defineMessages(
  'admin-dashboard.PrivacyCenter.DataPractices.dataApplicationPopover',
  {
    titlePlaceholder: {
      defaultMessage: 'Title',
    },
    descriptionPlaceholder: {
      defaultMessage: 'Description',
    },
  },
);

export const dataApplicationCountMessages =
  defineMessages<DataApplicationMethod>(
    'admin-dashboard.PrivacyCenter.DataPractices.dataApplicationCount',
    {
      USAGE: {
        defaultMessage: '{n} {n, plural, one {usage} other {usages}}',
      },
      SHARING: {
        defaultMessage: '{n} shared',
      },
    },
  );

export const dataPracticesMessages = defineMessages(
  'admin-dashboard.PrivacyCenter.DataPractices.dataPractices',
  {
    header: {
      defaultMessage: 'Data Practices',
    },
    noApplications: {
      defaultMessage: 'No data applications',
    },
    cancel: {
      defaultMessage: 'Cancel',
    },
    noDataApplications: {
      defaultMessage: 'No data applications',
    },
    connectionPlaceholder: {
      defaultMessage: 'Add another...',
    },
    minChars: {
      defaultMessage: 'Title must be less than 255 characters',
    },
    uncategorized: {
      defaultMessage: 'These data collections need to be categorized.',
    },
    noChildren: {
      defaultMessage: 'This category has no data collections.',
    },
    categoryUncategorized: {
      defaultMessage: 'Drag to any one of the sections below.',
    },
    deleted: {
      defaultMessage: 'removed',
    },
    description: {
      defaultMessage: 'Description',
    },
    noDataApplicationDescription: {
      defaultMessage: 'One of the data applications has no description!',
    },
    noDescription: {
      defaultMessage: 'Missing Description!',
    },
    title: {
      defaultMessage: 'Title',
    },
    missingTitle: {
      defaultMessage: 'Title is required.',
    },
    icon: {
      defaultMessage: 'Icon',
    },
    discard: {
      defaultMessage: 'Are you sure? You will lose your changes.',
    },
    method: {
      defaultMessage: 'This is...',
    },
    editDataCollection: {
      defaultMessage: 'Edit Data Collection',
    },
    editDataCategory: {
      defaultMessage: 'Edit Category',
    },
    delete: {
      defaultMessage: 'Remove',
    },
  },
);
