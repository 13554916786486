import type { RequestStatus } from '@transcend-io/privacy-types';
import { createEnum, filterEnum } from '@transcend-io/type-utils';

import { REQUEST_STATUSES } from './statuses';

/**
 * The statuses of a request that successfully returned a report to the data subject. Either indicating
 * changes were made, or they had no data found.
 */
export const REQUEST_STATUSES_SUCCESSFUL = filterEnum(
  REQUEST_STATUSES,
  ({ isSuccess }) => !!isSuccess,
);

/**
 * The successful request statuses, keyed by status
 */
export const REQUEST_STATUS_SUCCESSFUL = createEnum(
  REQUEST_STATUSES_SUCCESSFUL,
);

/**
 * Check if a request is in a state of success
 *
 * @param status - The status of the request to check
 * @returns True if the request is in a successfully sent state
 */
export function isSuccessful(status: RequestStatus): boolean {
  return status in REQUEST_STATUS_SUCCESSFUL;
}
