import { DoubleTag } from '@main/core-ui';
import React from 'react';

import { SelectedTcfPurpose } from './SelectTcfPurpose';
import { SelectedTcfSpecialFeature } from './SelectTcfSpecialFeature';

/** Props for Tcf Double Tag component */
interface TcfDoubleTagProps {
  /** the option name and id */
  option: SelectedTcfPurpose | SelectedTcfSpecialFeature;
}

export const TcfDoubleTag: React.FC<TcfDoubleTagProps> = ({ option }) => {
  const { id, name } = option;
  return (
    <DoubleTag
      variant="filled"
      leftLabel={`${id}`}
      rightLabel={name}
      color="transcendNavy2"
    />
  );
};
