/* eslint-disable max-lines */

import * as t from 'io-ts';
import { regexp } from 'io-ts-types/lib/regexp';

import {
  AirgapAuth,
  BooleanString,
  ConsentOptions,
  DismissedViewState,
  Logger,
  LogLevel,
  RegimePurposeScopesConfig,
  Removable,
  Stringifiable,
  TCFConfigInput,
  TrackingConsent,
  TrackingConsentDetails,
  TrackingPurpose,
  TrackingPurposeDetails,
  TrackingPurposes,
  TrackingPurposesConfig,
  TrackingPurposesTypes,
} from '@transcend-io/airgap.js-types';
import {
  FixedLengthArray,
  Optionalize,
  valuesOf,
} from '@transcend-io/type-utils';

import {
  ConsentExpiryBehavior,
  IABSignal,
  ModuleKind,
  UserPrivacySignalEnum,
} from './enums';
import { ParentNode } from './fix-default-types';
import { AirgapMetadata } from './metadata';
import { ConsentManagerConfigInput } from './ui';
import { XDISyncConsentManagerOptions } from './xdi';

/**
 * airgap.js event type
 */
export type AirgapEventType =
  | 'consent-resolution'
  | 'consent-change'
  | 'sync'
  | 'policy-update';

export {
  AirgapAuth,
  AirgapAuthMap,
  AutoOptOut,
  AutoOptOutForDNT,
  AutoOptOutForGDPR,
  AutoOptOutForGPC,
  // TODO: https://transcend.height.app/T-24018
  // sync AirgapEventType & ConsentChangeEventDetails changes upstream to airgap.js-types
  // AirgapEventType,
  // ConsentChangeEventDetails,
  ConsentChange,
  ConsentOptions,
  ConsentPreferencesBody,
  ConsoleSafeLogLevel,
  DefaultConsentConfig,
  DefaultConsentConfigValue,
  FullTrackingConsentDetails,
  LogEmitter,
  LogEntry,
  LogEntryType,
  Logger,
  LogLevel,
  Removable,
  Stringifiable,
  TrackingConsent,
  TrackingConsentDetails,
  TrackingPurpose,
  TrackingPurposeDetails,
  TrackingPurposes,
  TrackingPurposesConfig,
  TrackingPurposesTypes,
  ViewState,
} from '@transcend-io/airgap.js-types';

/** Realm interface */
// eslint-disable-next-line no-restricted-globals
export type Realm = typeof self;

/** Consent resolution event details */
export interface ConsentResolutionEventDetails {
  /** The old tracking consent */
  oldConsent: TrackingConsentDetails | null;
  /** The new tracking consent */
  consent: TrackingConsentDetails | null;
  /** The tracking consent diff (what's changed in the new consent) */
  changes: Diff | null;
  /** Applicable privacy signals contributing to this consent change event */
  signals?: Set<UserPrivacySignal> | null;
}

/** Consent change event details */
export type ConsentChangeEventDetails = ConsentResolutionEventDetails;

/** Sync completion event details */
export type SyncEventDetails = ConsentChangeEventDetails;

/** Policy update event details */
export interface PolicyUpdateEventDetails {
  /** Policy rules */
  rules: PrivacyGovernancePolicies;
  /** Policy URL (null for inline/embedded policies) */
  url: string | null;
}

/** Data privacy legal regime */
export type PrivacyRegime = string;

/** Region geodata used for regime inference */
export interface RegimeGeoHints {
  /** Country code (e.g. US) */
  country?: string;
  /** Country region code (e.g. CA) */
  countryRegion?: string;
}

/** TrackingPurposeTypes without TrackingPurposeDetails.showInConsentManager */
export type TrackingPurposeTypesInput = {
  [purpose in string]: Omit<TrackingPurposeDetails, 'showInConsentManager'>;
};

/** TrackingPurposesConfig with .types as TrackingPurposeTypesInput */
export type TrackingPurposesConfigInput = Omit<
  TrackingPurposesConfig,
  'types'
> & {
  /**
   * TrackingPurposesConfig.types input
   */
  types: TrackingPurposeTypesInput;
};

/**
 * Request override config
 */
export interface RequestOverride {
  /** Optional tracking purposes to gate the override with lack of consent */
  unconsented?: TrackingPurpose[];
  /**
   * Optional request matcher. This is a RegExp object (or string input
   * for the RegExp constructor). Overrides apply to all requests
   * when this is undefined.
   */
  matcher?: RegExp | string;
  /** Override executor function or replacement string */
  override: string | ((request: IPendingEvent, matcher?: RegExp) => void);
  /** Name */
  name?: string;
  /** Note */
  note?: string;
  /** Description */
  description?: string;
}

/**
 * Request override config (resolved for internal use)
 */
export type ResolvedRequestOverride = Omit<RequestOverride, 'unconsented'> & {
  /** Optional tracking purposes to gate the override with lack of consent (resolved to a set) */
  unconsented?: Set<TrackingPurpose>;
};

/** Request watcher */
export type AirgapWatcher = (request: IPendingEvent) => void;

/** Realm creation and detection watcher */
export type RealmWatcher = (realm: Realm) => void;

/**
 * Cookie override handler. This function can modify attempted cookie mutations.
 */
export type CookieOverride = (event: IPendingCookieMutation) => void;

/**
 * Passive cookie watcher. This function can view attempted cookie mutations in a read-only state.
 */
export type CookieWatcher = (event: IPendingCookieMutation) => void;

/** Airgap CSP policy directive */
export type CSPPolicyDirective =
  | 'allow-known-hosts'
  | 'allow-subdomains'
  | 'strict'
  | 'off';

/** Unknown request policies */
export const UnknownRequestPolicy = t.union([
  t.literal('allow'),
  t.literal('block'),
  t.literal('require-full-consent'),
]);

/** Type override */
export type UnknownRequestPolicy = t.TypeOf<typeof UnknownRequestPolicy>;

/** Unknown cookie policy */
export const UnknownCookiePolicy = UnknownRequestPolicy;

/** Type override */
export type UnknownCookiePolicy = t.TypeOf<typeof UnknownCookiePolicy>;

/**
 * Telemetry type options
 * (used for data-telemetry and data-prioritize-telemetry)
 */
export const TelemetryConfig = t.union([t.literal('usage'), BooleanString]);

/**
 * Telemetry page stats partition key strategy
 */
export const TelemetryPartitioningStrategy = t.union([
  t.literal('origin'),
  t.literal('path'),
  t.literal('url'),
]);

/** Telemetry page stats partition key strategy type override */
export type TelemetryPartitioningStrategy = t.TypeOf<
  typeof TelemetryPartitioningStrategy
>;

/**
 * Realm regulation hooking strategies
 */
export const RealmProtectionHookStrategy = t.union([
  t.literal('nav'),
  t.literal('worker'),
]);

/** Type override */
export type RealmProtectionHookStrategy = t.TypeOf<
  typeof RealmProtectionHookStrategy
>;

/**
 * Realm regulation navigation hook mode (default: async)
 */
export const RealmProtectionNavHookMode = t.union([
  t.literal('sync'),
  t.literal('async'),
]);

/** Type override */
export type RealmProtectionNavHookMode = t.TypeOf<
  typeof RealmProtectionNavHookMode
>;

/**
 * Backend config type options
 */
export const BackendSyncConfig = t.union([t.literal('usage'), BooleanString]);
/**
 * airgap.js settings or load options
 *
 * These can be configured by either defining airgap.loadOptions.* before loading airgap,
 * or by specifying data-* attributes on the airgap script element.
 */
export const AirgapSettings = t.partial({
  /**
   * baseURI propagation setting (default: on)
   */
  bup: BooleanString,
  /**
   * Inline CSS options for consent management UI
   */
  inlineCss: t.union([
    t.literal('off'),
    t.literal('on'),
    // using 'data:' instead of 'external' to afford flexibility
    // for 'blob:' etc in the future to support varying CSPs
    t.literal('data:'),
  ]),
  /**
   * CSP nonce
   */
  nonce: t.string,
  /**
   * Disable airgap.js. Default: "false"
   *
   * Set this to "true" to disable airgap.js entirely.
   */
  disabled: t.union([t.literal('true'), t.literal('false')]),
  /**
   * Load policies bundles synchronously (default: off)
   *
   * Warning: This option is horrible for performance. It should only be used
   * when customers want to load external policy bundles and don't have replay enabled.
   */
  loadPoliciesSync: BooleanString,
  /**
   * Consent partition. If this is set, then consent
   * records are keyed by this partition identifier.
   */
  partition: t.string,
  /**
   * Event quarantine maximum size in bytes.
   *
   * Default: 2.5 MiB
   */
  quarantineSize: t.number,
  /**
   * Regulation cache size limit (# of entries). Set to -1 for unbounded cache
   * or set to 0 to disable regulation cache.
   *
   * Defaults to DEFAULT_REGULATION_CACHE_SIZE (see ag-core/constants).
   */
  regulationCacheSize: t.number,
  /**
   * Regulation cache key size limit (in UTF-16 string.length units).
   *
   * Defaults to DEFAULT_REGULATION_CACHE_KEY_SIZE (see ag-core/constants).
   */
  regulationCacheKeySize: t.number,
  /**
   * Regulation cache garbage collection interval. Set to 0 to disable garbage collection.
   *
   * Defaults to DEFAULT_REGULATION_CACHE_GC_INTERVAL (see ag-core/constants).
   */
  regulationCacheGcInterval: t.number,
  /**
   * Require authorization for privileged APIs (default: on)
   *
   * Set this to 'strict' to require load event auth for all privileged APIs.
   */
  requireAuth: t.union([BooleanString, t.literal('strict')]),
  /**
   * Regime precedence list
   *
   * This is a semicolon-separated ordered list of privacy regimes. When a user has multiple regimes,
   * the regime with the highest precedence will be used for auto-determining initial consent UI
   * view states.
   *
   * Note: When a user is detected to have multiple regimes,
   * we only report the data for the regime with the highest precedence.
   */
  regimePrecedence: t.string,
  /**
   * Consent resolution precedence strategy
   *
   * If this is set to 'user' (default), then confirmed consent is persisted, even
   * if the consent opts the user into tracking purposes opted out by the user's
   * detected privacy signals.
   *
   * If this is set to 'signals', then detected privacy signals always take precedence
   * over confirmed consent.
   */
  consentPrecedence: t.union([t.literal('user'), t.literal('signals')]),
  /**
   * Regime detection configuration.
   *
   * If this is set to 'ip-only', then regimes will only be inferred using
   * IP geolocation data.
   *
   * If this is set to 'heuristics-only', then regimes will only be inferred using
   * language and timezone-based regime detection heuristics.
   *
   * If this is not set, or is set to any other value, then regimes will be
   * inferred using both IP geolocation data and our language and timezone-based
   * regime detection heuristics.
   */
  regimeDetection: t.union([
    t.literal('ip-only'),
    t.literal('heuristics-only'),
    t.literal('off'),
  ]),
  /**
   * Tracking purpose regime scoping configuration.
   *
   * If this is set to 'auto', then first-order tracking purposes
   * are limited by default by regimePurposeScopes (see ag-core/init).
   *
   * If this is set to 'off', then all configurable tracking purposes
   * are classified as first-order tracking purposes.
   *
   * Default value: 'auto'
   */
  regimePurposeScopes: t.union([t.literal('auto'), t.literal('off')]),
  /**
   * Consent manager UI configuration
   *
   * Can be a sparse ConsentManagerConfig object
   * or JSON string.
   */
  consentManagerConfig: t.union([ConsentManagerConfigInput, t.string]),
  /** What state the consent manager should go to when dismissed */
  dismissedViewState: valuesOf(DismissedViewState),
  /**
   * Automatically prompt user with consent manager UI at
   * n pageviews (default off). Set to `0` to disable.
   *
   * Setting this to 1 will prompt the user on their first pageview.
   */
  prompt: t.string,
  /**
   * Privacy Center URL
   *
   * @deprecated - Use `loadOptions.privacyPolicy`
   */
  privacyCenter: t.string,
  /** Privacy policy URL */
  privacyPolicy: t.string,
  /** core bundle location */
  airgap: t.string,
  /** current page base location for relative URL resolution */
  baseUrl: t.string,
  /**
   * CSP protection configuration. This is a space-(and optionally comma)-separated
   * list of `CSPPolicyDirective`s.
   *
   * If any entry is `"strict"` or this setting is undefined, then a CSP is generated
   * with the minimum number of CSP rules. This directive does not stack.
   *
   * If any entry is `"allow-subdomains"`, then the CSP is generated with additional
   * wildcard-allowlisted *.{domain} entries wherever needed to allow all
   * subdomains for allowed domains.
   *
   * If any entry is `"allow-known-hosts"`, then the CSP is generated with entries
   * which allow all known domains on the purpose map. This directive can stack with
   * `"allow-subdomains"`.
   *
   * If any entry is `"off"`, then a CSP is not generated and all CSP functionality
   * is disabled at runtime.
   *
   * @example ```ts
   * allow-subdomains allow-known-hosts x-allow-trusted-neuralink-thoughtworks-plugins
   * ```
   *
   * Default: `"allow-known-hosts allow-subdomains"` (lax) if undefined
   */
  csp: t.string,
  /**
   * Default privacy legal regime. airgap.getRegimes() will return this value
   * if no applicable privacy legal regimes are detected for the user.
   *
   * Default: `"Unknown"`
   */
  defaultRegime: t.string,
  /**
   * Privacy legal regimes override. airgap.getRegimes() will return this value
   * if defined. Multiple regimes can be specified by separating them with semicolons.
   */
  regime: t.string,
  /**
   * Quarantine replay configuration (default on)
   *
   * airgap.js can be configured to only replay certain types of events with
   * `replay: "[type1] [type2] [etc]"` (e.g. `replay: "requests mutations cookies"`) in your loadOptions.
   *
   * To enable replay of all events, set `replay: "*"` or `replay: "on"`.
   * To disable replay entirely, set `replay: "off"`. The default setting is to replay all events.
   *
   * Default: `"on"`
   */
  replay: t.string,
  /**
   * Report-only mode
   *
   * Whether an airgap bundle should allow all requests, regardless of
   * purpose map matches. This is used by our site scanner and should
   * always be `"off"` for customer site production bundles.
   *
   * Default: `"off"`
   */
  reportOnly: BooleanString,
  /**
   * Monitoring mode
   *
   * If this is set to 'export', airgap records all sent requests to
   * `airgap.export().sentRequests` and all set cookies to `airgap.export().setCookies`.
   *
   * If this is set to 'on' or 'export', airgap streams these requests to the `airgap.watch()`
   * and `airgap.watchCookies()` APIs.
   *
   * Default: `"off"`
   */
  monitoring: t.union([BooleanString, t.literal('export')]),
  /**
   * Whether to fully disable airgap patcher protections when fully
   * consented to all tracking purposes
   *
   * Default: `"on"`
   */
  disableWhenConsented: BooleanString,
  /**
   * Should airgap.js regulate requests?
   *
   * If this is 'on' (default), requests are regulated and potentially
   * quarantined for replay (see replay option to disable replay).
   *
   * If this is 'off' or report-only mode is enabled, requests are not regulated at all.
   *
   * Default: `"on"`
   */
  regulateRequests: BooleanString,
  /**
   * Should airgap.js regulate cookies?
   *
   * If this is 'on' (default) or '2', cookies are regulated and potentially
   * quarantined for replay (see replay option to disable replay).
   *
   * If this is '2', regular expression matchers are evaluated against full
   * cookie name-value pairs.
   *
   * If this is 'off' or report-only mode is enabled, cookies are not regulated at all.
   *
   * Default: `"on"`
   */
  regulateCookies: t.union([BooleanString, t.literal('2')]),
  /**
   * Should airgap.js regulate transitive top-level navigation?
   *
   * If if this 'on', transitive (i.e. non-reload) top-level navigation is regulated.
   *
   * If this is 'off', transitive top-level navigation is not regulated.
   *
   * Default: `"off"`
   */
  regulateNavigation: BooleanString,
  /**
   * Should airgap.js auto-protect all accessible same-origin realms?
   *
   * If this is set to '*' (default), all accessible same-origin realms are auto-regulated.
   * If this is set to 'self', the current realm is auto-regulated.
   * If this is set to 'descendants', the current realm and its descendants are auto-regulated.
   */
  protectRealms: t.union([
    t.literal('self'),
    t.literal('descendants'),
    t.literal('*'),
  ]),
  /**
   * Realm protection same-origin request hooks
   *
   * If this is set to 'on' (default), the following hooks are used: `embed worker`
   *
   * Supported hooks:
   * - nav: Sub-navigation (optional async mode enabled with
   * `data-realm-protection-hooks="nav:async worker"`)
   * - worker: Worker creation
   */
  realmProtectionHooks: t.string,
  /**
   * Specify how to regulate cookies with unknown purposes.
   *
   * If this is 'block', cookies with unknown tracking purposes
   * are always blocked.
   *
   * If this is 'allow', cookies with unknown tracking purposes
   * are always allowed. This is the default behavior.
   *
   * If this is 'require-full-consent', fully consenting to all tracking
   * purposes allows cookies with unknown tracking purposes.
   *
   * Default: `"allow"`
   */
  unknownCookiePolicy: UnknownRequestPolicy,
  /**
   * Specify how to regulate network requests with unknown purposes.
   *
   * If this is 'block', requests with unknown tracking purposes
   * are always blocked. This is the default setting whenever CSP is
   * enabled.
   *
   * If this is 'allow', requests with unknown tracking purposes
   * are always allowed through. This is the default setting whenever
   * CSP is disabled.
   *
   * If this is 'require-full-consent', fully consenting to all tracking
   * purposes allows requests with unknown tracking purposes.
   *
   * Default: `"block"` if CSP is enabled, otherwise "allow"
   */
  unknownRequestPolicy: UnknownRequestPolicy,
  /**
   * DOM protection configuration.
   *
   * If this is `"on"`, the entire document is protected by airgap.
   * If this is `"on"` or `"document"`, the entire document is protected by airgap.
   *
   * If this is `"off"`, airgap.js DOM patchers are not initialized.
   *
   * Otherwise, this is treated as a CSS selector, which is used to
   * target specific nodes for airgap.js protections.
   *
   * Default: `"on"`
   */
  protect: t.union([BooleanString, t.literal('document'), t.string]),
  /**
   * DOM protection caches configuration. Currently we only support caching checked nodes,
   * although we may add other caches in the future.
   *
   * If this is set to `"on"` (default), airgap.js will keep track of checked nodes to optimize
   * DOM regulation performance at the cost of increased memory usage.
   *
   * If this is set to `"off"`, airgap.js will not keep track of previously checked nodes.
   */
  domProtectionCache: BooleanString,
  /**
   * Whether to automatically reload the viewport on consent changes that result
   * in request dispatches that require a modified CSP.
   *
   * If this is set to `false` and a consent change triggers an undispatchable
   * (due to CSP) request, the request is postponed until the next page load.
   *
   * Default: `"on"`
   */
  autoReload: BooleanString,
  /**
   * Allow scripts to make synchronous XMLHttpRequests.
   *
   * Setting this to "off" prevents any content scripts from making
   * synchronous XMLHttpRequests.
   *
   * Default: `"on"`
   *
   * @deprecated - This is no longer supported by airgap.js
   */
  syncXhr: BooleanString,
  /**
   * Should airgap regulate all scripts
   *
   * If this is "on", then unknown scripts and scripts that map to
   * unconsented tracking purposes are blocked until consented.
   *
   * Default: `"on"`
   */
  regulateAllScripts: BooleanString,
  /**
   * Airgap telemetry
   *
   * This is used to inform a metrics collection endpoint of encountered
   * domains, errors, and navigated pages on the customer site.
   *
   * Set this to 'usage' to only include usage data that is necessary
   * for billing.
   *
   * Default: "on"
   */
  telemetry: TelemetryConfig,
  /**
   * Airgap telemetry page stats partition key strategy
   *
   * This is used to control the collection depth of page-level stats.
   *
   * Options:
   * - "origin" - current page origin (default; results in a single partition key)
   * - "path" - current page origin + pathname
   * - "url" - current page full URL including query string and hash
   *
   * Default: "origin"
   */
  telemetryPartitioning: TelemetryPartitioningStrategy,
  /**
   * Airgap telemetry network request prioritization
   *
   * Set this to 'off' to use low-priority network requests for
   * all telemetry.
   *
   * Set this to 'usage' to prioritize telemetry requests with billing
   * usage data.
   *
   * Set this to 'on' to prioritize all telemetry requests.
   *
   * Default: "usage"
   */
  prioritizeTelemetry: TelemetryConfig,
  /**
   * Remote sync configuration
   *
   * Default: "on"
   */
  backendSync: BackendSyncConfig,
  /** Remote sync endpoint URL */
  backendSyncEndpoint: t.string,
  /**
   * Bounce debouncer interaction threshold (in milliseconds)
   *
   * If the user has been inactive for this many milliseconds,
   * airgap will report 'pagehide' events in new sessions as bounces.
   *
   * Default: 600 ms
   */
  bdInteractionThreshold: t.string,
  /**
   * Consent sync hostname list (space and/or comma-separated list).
   * These hostnames are allowed to connect over XDI to both push and pull
   * airgap consent data.
   *
   * Example: `google.com youtube.com`
   *
   * @deprecated - Use `xdiAllowed` instead
   */
  firstParty: t.string,
  /**
   * Sync configuration (default on)
   *
   * airgap.js can be configured to only sync certain types of events with
   * `sync: "[type1] [type2] [etc]"` (e.g. `sync: "consent quarantine"`) in your loadOptions.
   *
   * To enable sync of all data, set `sync: "*"` or `sync: "on"`.
   * To disable sync entirely, set `sync: "off"`. The default setting is to sync all data.
   *
   * Default: `"on"`
   */
  sync: t.string,
  /**
   * Whether or not to run cross-domain sync
   *
   * Options:
   *  - 'private' | 'on' -- use private consent sync only
   *  - 'allow-network-observable' -- allow network-observable sync when private sync is unavailable
   *  - 'off' -- disable XDI sync
   *
   * Default: `"on"`
   */
  localSync: t.union([
    BooleanString,
    t.literal('private'),
    t.literal('allow-network-observable'),
  ]),
  /**
   * Sync domain scope. Used for cookie-based consent sync.
   *
   * The site owner should set this to the highest level domain within a site that they wish to sync across.
   */
  site: t.string,
  /**
   * Expected cookie domains list. Used to help determine the cookie domain to use for storing consent.
   * Space-separated list of preferably eTLD+1s. `data-site` takes precedence over this setting.
   *
   * @example
   * ```
   * example.com example.net
   * ```
   */
  sites: t.string,
  /**
   * Deferred sync. Enabling this setting while sync is also enabled causes sync
   * to not be automatically enqueued. This is useful for sites where the customer
   * wants more control over sync timing and to avoid creating extra requests.
   *
   * Default: `"off"`
   */
  deferSync: BooleanString,
  /**
   * Sync endpoint URL
   */
  syncEndpoint: t.string,
  /**
   * Multi-site sync endpoint mapping (JSON format). This takes precedence over `data-sync-endpoint`.
   *
   * @example
   * ```
   * {"example.com": "https://sync.example.com/", "example.net": "https://sync.example.net/"}
   * ```
   */
  syncEndpointMap: t.string,
  /**
   * Airgap consent sync period (in seconds).
   * Consent sync is immediately queued during idle on first visit.
   *
   * Default: 30 minutes
   */
  syncPeriod: t.string,
  /**
   * Quarantine sync budget (in bytes). 0 = disable quarantine sync, -1 = unlimited.
   *
   * Default: 0 (quarantine sync is off)
   */
  quarantineSyncBudget: t.string,
  /**
   * Transcend Privacy-Enhancing Tracker Overrides configuration
   */
  trackerOverrides: t.string,
  /** Enable active anti-tamper interventions (default: on) */
  tamperResist: BooleanString,
  /** Telemetry sync period (in seconds), defaults to 5 minutes */
  telemetrySyncPeriod: t.string,
  /** Telemetry processing period (in milliseconds), defaults to 5 seconds */
  telemetryProcessingPeriod: t.string,
  /**
   * Initial telemetry logging sync period (in seconds). The first telemetry
   * flush to happen during each visit will use this initial sync period.
   *
   * Defaults to 30 seconds
   */
  initialTelemetrySyncPeriod: t.string,
  /** Airgap telemetry endpoint URL */
  telemetryEndpoint: t.string,
  /**
   * Tracking purpose to assign to the telemetry endpoint
   *
   * Default: 'Functional'
   */
  telemetryTrackingPurpose: t.string,
  /**
   * Telemetry sampling rate
   * percentage of sessions to send telemetry for
   */
  telemetrySampleRate: t.string,
  /**
   * Client-side telemetry event sampling rate
   * percentage of outgoing requests and cookie mutations to send telemetry for
   *
   * Default 1
   */
  eventSampleRate: t.string,
  /**
   * @deprecated - This feature has been replaced with eventSampleRate
   *
   * Client-side telemetry event sampling rate
   * percentage of outgoing requests and cookie mutations to send telemetry for
   *
   * Default 1
   */
  reqCookieSampleRate: t.string,
  /**
   * Tracking purpose to assign to the Privacy-Enhancing Tracker Overrides trigger
   *
   * Default: 'SaleOfInfo'
   */
  trackerOverridesUnconsentedPurpose: t.string,
  /**
   * Lazy-load consent manager UI
   *
   * Default: `"off"`
   */
  lazyLoadUi: BooleanString,
  /**
   * Default output log level for logger.log.
   * Can be info, warn, debug, error, or trace.
   *
   * Default: `"info"`
   */
  defaultLogLevel: LogLevel,
  /** Enabled log level(s) */
  log: t.string,
  /**
   * XDI host allowed hosts (space and/or comma-separated list)
   *
   * Example: `xdi-allowed-host.example xdi-allowed-host-2.example`
   */
  xdiAllowed: t.string,
  /**
   * Only allow XDI connections from secure (HTTPS) origins.
   *
   * Default: "on"
   */
  xdiRequireHttps: BooleanString,
  /** Enabled XDI commands (comma/space-separated) */
  xdiCommands: t.string,
  /**
   * XDI first-party-set consensus sync timeout in milliseconds (0 for no timeout, default is 5 seconds)
   *
   * @deprecated - This feature has been removed
   */
  xdiFpsSyncTimeout: t.string,
  /** XDI connection timeout in milliseconds (0 for no timeout, default is 30 seconds) */
  xdiConnectTimeout: t.string,
  /**
   * Shared XDI host sync groups config (JSON)
   * Mappings of bundle IDs to first party sets
   *
   * @example
   * {"airgap-playground":["localhost"],"rfc6761":["example.com","example.net","example.org"]}
   */
  syncGroups: t.string,
  /**
   * Consent expiry time limit in minutes.
   * Set this to 0 for no expiry (default is 0)
   */
  consentExpiry: t.string,
  /**
   * Consent expiry behavior (default is "prompt").
   * Set this to "reset" to reset consent entirely on consent expiry.
   */
  onConsentExpiry: valuesOf(ConsentExpiryBehavior),
  /**
   * Quarantine pending event auto-expiry time limit in minutes.
   * Set this to 0 for no expiry (default is 0)
   */
  autoExpire: t.string,
  /**
   * Custom UI module load option (overrides airgap.ui)
   *
   * Set to 'off' to disable the UI entirely.
   */
  ui: t.string,
  /**
   * Custom path to translation files used in the default UI (overrides consentManagerConfig.messages)
   *
   * Set to undefined to take default translations
   */
  messages: t.string,
  /**
   * Custom path to css file containing stylesheet
   * for the default consent manager UI.
   */
  css: t.string,
  /**
   * The default language selection used in the default UI
   *
   * Set to undefined to use the auto-select language option
   *
   * Note: this should be `ConsentManagerLanguageKey`
   * but we want to avoid loading in that enum into memory here,
   * so we avoid the extra typing to save bundle size
   */
  locale: t.string,
  /**
   * Custom XDI module load option (overrides airgap.xdi)
   */
  xdi: t.string,
  /** TCF Configuration including paths to vendor-list.json, css and messages */
  tcfConfig: t.union([TCFConfigInput, t.string]),
  /** Whether the site owner has signed the IAB LSPA agreement */
  signedIabAgreement: t.union([
    t.literal('yes'),
    t.literal('no'),
    t.literal('unknown'),
  ]),
  /** Whether to enable the IAB US Privacy API (default: off) */
  uspapi: BooleanString,
  /** Whether to enable the IAB TCF API (default: off) */
  tcf: BooleanString,
  /** Use MutationObserver regulation fallback while document is in 'open' state (default: off) */
  mutationObserver: BooleanString,
  /** Whether to use telemetry false positive filtering (default: on) */
  tfpf: BooleanString,
  /** Should support for telemetry false positive filtering be required for event telemetry (default: off) */
  requireTfpf: BooleanString,
  /** Frames to collect for telemetry false positive filtering in chromium environments, should be int or 'Infinity' (default: 10) */
  tfpfStackLimit: t.string,
  /** Whether or not to use cookie setter listeners to reactively clear server-originated cookies */
  cookieListenerRegulation: BooleanString,
});
/** Type override */
export type AirgapSettings = t.TypeOf<typeof AirgapSettings>;

/** Event types (for purpose resolution) */
export type TrackingEventType = 'request' | 'cookie';

/** Event types (for replay) */
export type ReplayType = 'requests' | 'mutations' | 'cookies';

/** Make all properties mutable */
export type Mutable<T> = {
  -readonly [P in keyof T]: T[P];
};

/** Region regimes configuration */
export const RegionRegimesConfig = t.array(
  FixedLengthArray(2, Infinity, t.array(t.string)),
);

/** Region descriptor */
export type Region = [
  /** Country or macroregion code */
  country?: string,
  /** Country sub-region code */
  region?: string,
];

/** JS module descriptor */
export interface ModuleDescriptor {
  /** Module URL */
  url: string;
  /** Module ID (defaults to URI if not provided) */
  id?: string;
  /** Module name */
  name?: string;
  /** Module kind (default: plugin) */
  kind?: ModuleKind;
  /** Module media type (default: application/ecmascript) */
  type?:
    | 'module' // ES module
    | 'application/ecmascript' // default for kind={plugin, ui}
    | 'application/vnd.transcend.governance-policies+json'; // default for kind=policies
  /** Should module be loaded synchronously? (default: false) */
  sync?: boolean;
}

/** Region regimes configuration options */
export type RegionRegimesConfigOptions = [
  operator?: 'in' | 'out',
  useBrowserLanguages?: boolean | string[],
  useTimezones?: string[] | null,
  ...effects: RegimeEffects,
];

/** Regime effects */
export type RegimeEffects = [
  useModules?: ModuleDescriptor[] | null,
  trackerOverridesConfig?: string | null,
  settingsOverrides?: AirgapSettings | null,
];

/** Regime effects map */
export type RegimeEffectsMap = [regimes: string[], effects: RegimeEffects];

/** Entry in region regimes configuration */
export type RegionRegimesConfigEntry = [
  regions: Region[],
  regimes: PrivacyRegime[],
  ...options: RegionRegimesConfigOptions,
];

/** Region regimes configuration */
export type RegionRegimesConfig = RegionRegimesConfigEntry[];

/** airgap.sync() options */
export type SyncOptions = Pick<XDISyncConsentManagerOptions, 'reset' | 'auth'> &
  Optionalize<XDISyncConsentManagerOptions, 'sync' | 'local'>;

/** Macroregion codes to space-separated country codes list */
export const MacroregionMapInput = t.array(
  FixedLengthArray(2, 2, t.array(t.string)),
);

/** Type override */
export type MacroregionMapInput = [macroregion: string, countries: string][];

/** Macroregion codes to country codes list */
export type MacroregionMap = [macroregion: string, countries: string[]][];

/** airgap.reset() options */
export interface ResetOptions {
  /** Should page auto-reload when consent changes and the CSP is too restrictive? */
  autoReload?: boolean;
  /** Should  */
  autoSync?: boolean;
}

/** airgap.toggle() options */
export type AirgapToggleOptions =
  /** Protection state */
  | boolean
  | {
      /** Protection state */
      protection?: boolean;
    };

/** airgap.status() output format */
export interface AirgapSystemStatus {
  /** Protection system active state */
  protection: boolean;
  /** Have any CSPs been activated? */
  csp: boolean;
  /** Monitoring system active state */
  monitoring: boolean;
  /** Telemetry system active state */
  telemetry: boolean;
}

/** airgap.js API */
export type AirgapAPI = Readonly<{
  /** Hosts that a build is allowed to run on */
  hosts?: string[];
  /** Smart Quarantine API endpoint origin */
  endpoint?: string;
  /** Macroregion map */
  macroregions?: MacroregionMapInput;
  /** Embedded purpose map */
  purposeMap?: IPurposeMapJSON;
  /** Embedded CSP */
  csp?: IPurposeMapJSON;
  /** Embedded plugins */
  plugins?: AirgapPlugin[];
  /** Embedded cookie purposes */
  cookies?: ICookiePurposeMapInputJSON;
  /** Embedded request watchers */
  watchers?: AirgapWatcher[];
  /** Embedded realm watchers */
  realmWatchers?: RealmWatcher[];
  /** Embedded request overrides */
  overrides?: RequestOverride[];
  /** Embedded request overrides */
  cookieOverrides?: CookieOverride[];
  /** Airgap load options */
  loadOptions?: AirgapSettings;
  /** Tracking purposes configuration */
  purposes?: TrackingPurposesConfigInput;
  /** Default tracking consent */
  defaultTrackingConsent?: TrackingConsent;
  /** Omit credentials from requests to these locations */
  omitCredentials?: RegulatedPaths;
  /** Regulated locations */
  regulatedPaths?: RegulatedPaths;
  /** Regulated scripts */
  regulatedScripts?: RegulatedPaths;
  /** Region to regimes mappings */
  regionRegimesMap?: RegionRegimesConfig;
  /** Regime purpose scopes */
  regimePurposeScopes?: RegimePurposeScopesConfig;
  /** Regime purpose default opt outs */
  regimePurposeOptOuts?: RegimePurposeScopesConfig;
  /** Telemetry suppression options */
  suppressTelemetry?: TelemetrySuppressionOptions;
  /** Consent manager bundle ID */
  id?: string;
  /** Consent manager UI script location */
  ui?: string | false;
  /** XDI script location */
  xdi?: string | false;
  /** Metadata JSON location */
  metadata?: string | false;
  /** Regime hints JSON location */
  regimeHints?: string;
  /** Queue of callbacks to dispatch once airgap is ready */
  readyQueue?: ((airgap: AirgapAPI) => void)[];
  /** Airgap ready event subscriber */
  ready(callback: (airgap: AirgapAPI) => void): void;
  /** Enqueue cross-domain data sync across all airgap bundle domains */
  sync(options?: SyncOptions): Promise<void>;
  /**
   * Resolve URL input reserialization post-regulation.
   *
   * @param url - URL input to resolve
   * @param resolveOverrides - Resolve overrides. Defaults to true.
   */
  resolve(url: Stringifiable, resolveOverrides?: boolean): Stringifiable;
  /**
   * Resolve consent status for given tracking purposes. Essential purposes override opted out unessential purposes.
   *
   * If `use` is not provided, consent is resolved for both request and cookie tracking event types.
   *
   * @param trackingPurposes - Tracking purposes to resolve
   * @param use - Optional event type to use for tracking purpose resolution
   * @returns `true` if the applicable tracking purposes are consented.
   */
  isConsented(
    trackingPurposes: TrackingPurposes,
    use?: TrackingEventType,
  ): boolean;
  /**
   * Resolve consent status for given tracking purposes. Essential purposes override opted out unessential purposes.
   *
   * If `use` is not provided, consent is resolved for both request and cookie tracking event types.
   *
   * @param trackingPurposes - Tracking purposes to resolve
   * @param use - Optional event type to use for tracking purpose resolution
   * @returns `true` if the applicable tracking purposes are consented.
   */
  /** Fetches JSON metadata about services and cookies regulated by airgap */
  getMetadata(): Promise<AirgapMetadata>;
  /**
   * Enforce airgap.js DOM protection mechanisms for a specific realm.
   * If a node is provided as input, the node's associated realm is protected.
   * (defaults to `self`)
   */
  protect(input?: Realm | ParentNode): Removable | void;
  /** Toggle all airgap.js protections. Auth must be a pre-airgap.js or airgap.js script 'load' event. Returns success status */
  toggle(auth: AirgapAuth, options?: AirgapToggleOptions): boolean;
  /** Start regulating all scripts */
  regulateAllScripts(): void;
  /** Returns whether all scripts (that are not explicitly regulated) are currently allowed by airgap.js */
  areAllScriptsRegulated(): boolean;
  /** Get tracking consent */
  getConsent(): TrackingConsentDetails;
  /** Set tracking consent */
  setConsent(
    /** Airgap auth proof */
    auth: AirgapAuth,
    /** The tracking consent options. */
    consent: TrackingConsent,
    /** Consent options */
    options?: ConsentOptions & {
      /** Consent timestamp (optional; ISO 8601-format) */
      timestamp?: string;
      /**
       * Metadata timestamp (optional; ISO 8601-format)
       *
       * This is instantiated automatically if not provided
       * whenever metadata is defined.
       */
      metadataTimestamp?: string;
      /** Whether or not to return a Promise so that the caller can wait for sync to complete. By default, we do not wait for sync */
      waitForSync?: boolean;
    },
  ): Promise<boolean> | boolean;
  /** Set metadata associated with the user's consent */
  setConsentMetadata(
    /** Airgap auth proof */
    auth: AirgapAuth,
    /** Consent options */
    metadata: ConsentOptions['metadata'],
  ): Promise<boolean>;
  /** Sets whether or not the Consent UI has been shown to the user */
  setPrompted(state: boolean): Promise<void>;
  /** Consents the user to all tracking purposes (requires recent UI interaction) */
  optIn(
    /** Airgap auth proof */
    auth: AirgapAuth,
  ): boolean;
  /** Revokes consent for all tracking purposes (requires recent UI interaction) */
  optOut(
    /** Airgap auth proof */
    auth: AirgapAuth,
  ): boolean;
  /** Returns true if the user is fully-opted in to all first-order tracking purposes */
  isOptedIn(): boolean;
  /** Returns true if the user is fully-opted out to all first-order tracking purposes */
  isOptedOut(): boolean;
  /** Resolve regime tracking purposes. If no regimes are provided, then the user's detected regimes are used */
  getRegimePurposes(regimes?: Set<PrivacyRegime>): Set<TrackingPurpose>;
  /** Get initialized tracking purposes config */
  getPurposeTypes(): TrackingPurposesTypes;
  /** Override pending requests */
  override(auth: AirgapAuth, ...overrides: RequestOverride[]): Removable;
  /** Override cookies */
  overrideCookies(
    auth: AirgapAuth,
    handler: (event: IPendingCookieMutation) => void,
  ): Removable;
  /** Listen to pending requests passively */
  watch(watcher: AirgapWatcher): Removable;
  /** Listen to realm creation or detections passively */
  watchRealms(watcher: RealmWatcher): Removable;
  /** Listen to cookies passively */
  watchCookies(
    watcher: (event: Readonly<IPendingCookieMutation>) => void,
  ): Removable;
  /** Insert CSP into document if not already present */
  activateCSP(policyDirectives?: CSPPolicyDirective[]): void | Promise<void>;
  /** Clear airgap queue & caches. Returns `true` on success. */
  clear(auth: AirgapAuth): Promise<boolean>;
  /** Reset airgap queue and consent. Returns `true` on success. */
  reset(
    /** An airgap auth proof */
    auth: AirgapAuth,
    /** Automatically reload the page if needed to remove CSP. */
    autoReload?: boolean,
  ): Promise<boolean>;
  /** Check whether a URL is allowed to be loaded */
  isAllowed(
    /** URL to evaluate */
    url: Stringifiable,
    /** Should overrides be resolved? true by default */
    resolveOverrides?: boolean,
  ): Promise<boolean>;
  /** Check whether a cookie is allowed to be set */
  isCookieAllowed(
    /** IPendingCookieMutation-like object to evaluate */
    cookie: string | IPendingCookieMutation | PendingCookieMutationInit,
    /** Should overrides be resolved? true by default */
    resolveOverrides?: boolean,
  ): Promise<boolean>;
  /** Check whether a IPendingRequest is allowed to be loaded */
  isRequestAllowed(
    /** IPendingEvent to inspect */
    request: IPendingEvent,
    /** Should overrides be resolved? true by default */
    resolveOverrides?: boolean,
  ): Promise<boolean>;
  /** Get purposes of URL */
  getPurposes(
    /** URL to evaluate */
    url: Stringifiable,
    /** Should overrides be resolved? true by default */
    resolveOverrides?: boolean,
  ): Promise<TrackingPurposes>;
  /** Get purposes of IPendingRequest */
  getRequestPurposes(
    /** IPendingEvent-like object to inspect */
    request: string | IPendingEvent | PendingRequestInit,
    /** Should overrides be resolved? true by default */
    resolveOverrides?: boolean,
  ): Promise<TrackingPurposes>;
  /** Get purposes of a cookie */
  getCookiePurposes(
    /** IPendingCookieMutation-like object to evaluate */
    cookie: string | IPendingCookieMutation | PendingCookieMutationInit,
    /** Should overrides be resolved? true by default */
    resolveOverrides?: boolean,
  ): Promise<TrackingPurposes>;
  /** Export queues */
  export(options?: AirgapExportOptions): AirgapQueues;
  /** Get a list of legal regimes that are potentially applicable to the user */
  getRegimes(): Set<PrivacyRegime>;
  /** Get a list of detected active user agent privacy signals */
  getPrivacySignals(): Set<UserPrivacySignal>;
  /** airgap.js version number */
  version: string;
  /** Is airgap.js initialized? */
  initialized?: boolean;
  /** Enumerate current airgap.js system flags */
  status: AirgapSystemStatus;
  /** Clear any disallowed cookies based on the current user consent state and their matching regime */
  clearDisallowedCookies(quarantineDisallowedCookies?: boolean): Promise<void>;
  /** Clear cookies based on a custom filter provided by the user */
  clearCookies: (
    filter: (
      cookie: IPendingCookieMutation,
      quarantineDisallowedCookies?: boolean,
    ) => boolean,
  ) => Promise<void>;
  /** Iterator */
  [Symbol.iterator](): Generator<string>;
}> &
  EventTarget;

/** airgap.export() options */
export interface AirgapExportOptions {
  /** Send output to web endpoint */
  endpoint?: string;
  /** JSON pretty-print indentation (default: 0) */
  space?: number;
  /** Save output to disk (default: off) */
  save?: boolean;
  /** Filename for saving to disk */
  filename?: string;
}

/** Exported airgap queues & consent */
export type AirgapQueues = Readonly<{
  /** airgap.js version number */
  version: string;
  /** Current user consent details */
  consent: TrackingConsentDetails;
  /** Navigation page URL */
  url: Stringifiable;
  /** Pending requests */
  requests: PendingRequestDescriptor[];
  /** Pending mutations (same-session-only replay) */
  mutations: PendingMutationDescriptor[];
  /** Pending cookies */
  cookies: PendingCookieMutationDescriptor[];
  /** Pending cookies (same-session-only replay) */
  cookieMutations: PendingCookieMutationDescriptor[];
  /** Sent requests */
  sentRequests?: PendingRequestDescriptor[];
  /** Set cookies */
  setCookies?: PendingCookieMutationDescriptor[];
}>;

/** Exported airgap quarantine queues (localStorage.tcmQuarantine JSON format) */
export type QuarantineJSON = Readonly<{
  /** Requests */
  requests?: PendingEventQueue;
  /** Cookies */
  cookies?: PendingCookieQueue;
}>;

/** DOM <script> element types */
export type Script = HTMLScriptElement | SVGScriptElement;

/** DOM:view-creating element types */
export type DOMViewElement =
  | HTMLIFrameElement
  | HTMLEmbedElement
  | HTMLFrameElement;

/** XHR metadata for airgap.js */
export type XMLHttpRequestDetails = {
  /** XHR request URL */
  url: Stringifiable;
  /** XHR request method */
  method: string;
  /** Is the XHR asynchronous? */
  async?: boolean;
  /** XHR quarantine/block status */
  blocked?: boolean;
} | null;

/** Airgap request source types */
export type AirgapRequestSource =
  | 'unknown'
  | 'airgap.js' // emitted by airgap.isAllowed()
  | 'fetch'
  | 'xhr'
  | 'websocket'
  | 'webtransport'
  | 'service-worker'
  | 'shared-worker'
  | 'worker'
  | 'module-worker'
  | 'shared-module-worker'
  | 'worklet'
  | 'eventsource'
  | 'beacon'
  | 'CSPV'
  | 'navigation'
  | 'open'
  | 'script'
  | AirgapDOMRequestSource;

/** Airgap DOM-initiated request source types */
export type AirgapDOMRequestSource =
  | 'DOM:style'
  | 'DOM:image'
  | 'DOM:media'
  | 'DOM:video'
  | 'DOM:audio'
  | 'DOM:track'
  | 'DOM:link'
  | 'DOM:form'
  | 'DOM:form-action'
  | 'DOM:view' // iframe {srcdoc, src}, object, embed
  | 'DOM:ping'
  | 'DOM:unknown';

/** Airgap sync types */
export type AirgapSyncType = 'consent' | 'quarantine';

/**
 * Airgap pending request descriptor init dictionary
 */
export interface PendingRequestInit {
  /** Request initiator type */
  type: AirgapRequestSource;
  /** Request URL */
  url: Stringifiable;
  /** Persist request for cross-session replay (false by default) */
  persist?: boolean;
  /** Request initialization data */
  requestInit?: RequestInit;
  /** Request timestamp (ISO 8601) */
  timestamp?: Stringifiable;
  /** Request DOM target */
  target?: Node | IDynamicNodeReference | null;
  /** Mutator to apply changes associated with the request */
  mutator?(): void;
  /** Serialize request or mutation state back to DOM patcher parser input */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  serialize?(): any;
  /** Prevent credentials from being included in request */
  omitCredentials?(): boolean;
}

/** Properties that are added to instantiated PendingRequests */
export interface InstantiatedPendingRequestProps {
  /** Primary or first associated request URL input */
  url: Stringifiable;
  /** All associated request URL inputs */
  urls: Stringifiable[];
  /** Request timestamp (ISO 8601) */
  timestamp: Stringifiable;
  /** Serialize request or mutation state back to DOM patcher parser input */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  serialize(): any;

  /** The following IPendingEvent APIs are only available to overrides: */

  /** All associated resolved request URLs. null = invalid URL */
  URLs: (URL | null)[];
  /** Prevent credentials from being included in request */
  omitCredentials(): boolean;
  /** Whether or not request is currently allowed */
  allowed: boolean;
  /** Bypass our consent logic and force-allow request */
  allow(): void;
  /** Bypass our consent logic and force-deny request */
  deny(): void;
  /** Resolved tracking purposes associated with this pending mutation */
  purposes: Set<TrackingPurpose>;
}

/**
 * Airgap pending request descriptor with script annotation
 */
export type PendingRequestDescriptor = PendingRequestInit &
  InstantiatedPendingRequestProps;

/** Airgap pending request descriptor init with multiple URLs */
export type PendingMutationInit = Omit<PendingRequestInit, 'url'> & {
  /** Request URLs */
  urls: Stringifiable[];
};

/** PendingEvent init input */
export type PendingEventInit = PendingRequestInit | PendingMutationInit;

/** Airgap pending mutation descriptor */
export type PendingMutationDescriptor = PendingMutationInit &
  InstantiatedPendingRequestProps;

/** Airgap pending event props that can't or won't be serialized to JSON */
type PendingEventUnserializableProps =
  | 'persist'
  | 'target'
  | 'mutator'
  | 'serialize'
  | 'URLs'
  | 'omitCredentials'
  | 'allow'
  | 'deny'
  | 'purposes'
  | 'allowed';

/** JSON-safe representation of pending event tracking purposes */
interface PendingEventPurposesJSON {
  /** Tracking purposes list */
  purposes?: TrackingPurpose[];
}

/** Pending request subset to JSON-safe properties */
export type PendingRequestJSON = Omit<
  PendingRequestDescriptor,
  PendingEventUnserializableProps | 'urls'
> &
  PendingEventPurposesJSON;

/** Pending mutation subset to JSON-safe properties */
export type PendingMutationJSON = Omit<
  PendingMutationDescriptor,
  PendingEventUnserializableProps | 'url'
> &
  PendingEventPurposesJSON;

/** Airgap pending request interface */
export interface IPendingRequest extends PendingRequestDescriptor {
  /** Convert PendingRequest to JSON-safe representation */
  toJSON(): PendingRequestJSON;
}

/** Airgap pending mutation interface */
export interface IPendingMutation extends PendingMutationDescriptor {
  /** Convert PendingRequest to JSON-safe representation */
  toJSON(): PendingMutationJSON;
}

/** Pending event descriptor */
export type PendingEventDescriptor =
  | PendingRequestDescriptor
  | PendingMutationDescriptor;

/** Pending request or mutation */
export type IPendingEvent = IPendingMutation | IPendingRequest;

/** Unapproved request queue */
export type PendingRequestQueue = IPendingRequest[];

/** Unapproved mutation queue */
export type PendingMutationQueue = IPendingEvent[];

/** Unapproved event queue */
export type PendingEventQueue = IPendingEvent[];

/** Cookie descriptor */
export interface Cookie {
  /** Cookie name */
  name: Stringifiable;
  /** Cookie value */
  value?: Stringifiable;
  /** Cookie change event timestamp (ISO 8601) */
  timestamp?: string;
  /** Expiry date (UTC date string or DOMTimeStamp) */
  expires?: number | Stringifiable;
  /** Max cookie age (seconds) */
  maxAge?: number;
  /** Optional cookie host scope */
  domain?: Stringifiable;
  /** Optional cookie path scope */
  path?: Stringifiable;
  /** Should cookie only be sent in secure contexts? */
  secure?: boolean;
  /**
   * Should cookie be restricted to the same site?
   * Values: https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie/SameSite
   */
  sameSite?: Stringifiable;
  /**
   * Is the cookie partitioned (e.g. by CHIPS)?
   * See https://developer.mozilla.org/en-US/docs/Web/Privacy/Partitioned_cookies
   * and https://developer.mozilla.org/en-US/docs/Web/API/CookieStore/get#:~:text=of%20the%20cookie.-,partitioned,-A%20boolean%20indicating
   */
  partitioned?: boolean;
  /** Target document / node */
  target?: Node | null;
}

/**
 * PendingCookieMutation constructor input
 */
export interface PendingCookieMutationInit extends Cookie {
  /** Persist cookie for cross-session replay if quarantined (true by default) */
  persist?: boolean;
  /** Mutator to apply cookie mutation */
  mutator?(): void | Promise<void>;
}

/** Properties that are added to instantiated PendingCookieMutations */
export interface InstantiatedPendingCookieMutationProps {
  /** Expiry date (DOMTimeStamp) */
  expires?: number;
  /** Cookie change event timestamp (Date.now() format) */
  timestamp: string;
  /** Whether or not cookie is currently allowed */
  allowed: boolean;
  /** Bypass our consent logic and force-allow cookie */
  allow(): void;
  /** Bypass our consent logic and force-deny cookie */
  deny(): void;
  /** Resolved tracking purposes associated with this pending mutation */
  purposes: Set<TrackingPurpose>;
  /** Mutator to apply cookie mutation */
  mutator(): void | Promise<void>;
}

/** Pending cookie mutation descriptor */
export type PendingCookieMutationDescriptor = Omit<
  PendingCookieMutationInit,
  'expires'
> &
  InstantiatedPendingCookieMutationProps;

/** Pending cookie mutation props that can't or won't be serialized to JSON */
type PendingCookieMutationUnserializableProps =
  | 'persist'
  | 'mutator'
  | 'allow'
  | 'deny'
  | 'purposes'
  | 'allowed';

/** JSON-safe representation of pending cookie mutation tracking purposes */
interface PendingCookieMutationPurposesJSON {
  /** Tracking purposes list */
  purposes?: TrackingPurpose[];
}

/** Pending request subset to JSON-safe properties */
export type PendingCookieMutationJSON = Omit<
  PendingCookieMutationDescriptor,
  PendingCookieMutationUnserializableProps
> &
  PendingCookieMutationPurposesJSON;

/** Airgap pending cookie mutation interface */
export interface IPendingCookieMutation
  extends PendingCookieMutationDescriptor {
  /** Convert PendingCookieMutation to JSON-safe representation */
  toJSON(): PendingCookieMutationJSON;
}

/** Pending cookie mutation queue */
export type PendingCookieQueue = IPendingCookieMutation[];

/** Airgap purpose map JSON */
export const IPurposeMapJSON = t.record(t.string, t.array(t.string));

/** Type overrides */
export type IPurposeMapJSON = t.TypeOf<typeof IPurposeMapJSON>;

/** Purpose map iterator entry */
export type PurposeMapEntry = [string, TrackingPurposes];

/** Pending DOM insertion */
export interface PendingDOMInsertion {
  /** Complete insertion */
  mutator: (
    /** The insertion target */
    target: Node,
    /** The node being written or inserted */
    insertion: Node,
    /** Was the pending insertion modified by airgap patchers? */
    modified: boolean,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ) => any;
  /** Insertion target element */
  target: Node;
  /** Insertion node */
  insertion: Node;
  /** Was the pending insertion modified by airgap patchers? */
  modified?: boolean;
  /**
   * Should insertion nodes be kept static or automatically update after replay?
   */
  static?: boolean;
}

// can't import actual DOMPatcher type from ag-core
/**
 * Instantiated DOM patcher
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type DOMPatcher = any;

/**
 * DOM patcher config input
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type DOMPatcherConfig = any;

/** Plugin utils */
export type PluginUtils = [
  synchronousPendingEventInspectionAPIs: [
    isRequestAllowedSync: (
      request: IPendingEvent,
      shouldLog?: boolean,
    ) => boolean,
    getRequestPurposesSync: (
      request: IPendingEvent,
      shouldLog?: boolean,
    ) => TrackingPurposes,
    isCookieAllowedSync: (
      cookie: IPendingCookieMutation,
      shouldLog?: boolean,
    ) => boolean,
    getCookiePurposesSync: (
      cookie: IPendingCookieMutation,
      shouldLog?: boolean,
    ) => TrackingPurposes,
  ],
  pendingEventConstructors: [
    PendingRequest: new (
      init: PendingRequestInit,
      resolveOverrides?: boolean,
      shouldLog?: boolean,
    ) => IPendingRequest,
    PendingMutation: new (
      init: PendingMutationInit,
      resolveOverrides?: boolean,
      shouldLog?: boolean,
    ) => IPendingMutation,
    PendingCookieMutation: new (
      init: PendingCookieMutationInit,
      resolveOverrides?: boolean,
      shouldLog?: boolean,
    ) => IPendingCookieMutation,
  ],
  lifecycleHooks: [
    isProtectionInactive: () => boolean,
    quarantine: (request: IPendingEvent) => void,
  ],
  consentAPIs: [
    airgap: AirgapAPI,
    toggleWithAuth: (options?: AirgapToggleOptions) => boolean,
    setConsentWithAuth: (
      trackingConsent: TrackingConsent,
      confirmed?: boolean,
      prompted?: boolean,
      metadata?: ConsentOptions['metadata'],
      autoSync?: boolean,
      timestamp?: string,
      consentUpdated?: boolean,
      metadataTimestamp?: string,
    ) => Promise<void>,
    resetWithAuth: (options?: ResetOptions | boolean) => Promise<boolean>,
    updatePolicies: (
      rules: PrivacyGovernancePolicies,
      url?: string | null,
    ) => void,
    loadModules: (...modules: ModuleDescriptor[]) => Promise<void[]>,
  ],
  domProtectionUtils: [
    activatePatchers: (realm: Realm, configs: DOMPatcherConfig[]) => void,
    setupPatchers: (realm: Realm, configs: DOMPatcherConfig[]) => DOMPatcher[],
    regulateDOMTree: (
      realm: Realm,
      pendingInsertion: PendingDOMInsertion,
      document: Document,
      parentNode: ParentNode,
    ) => void,
    regulateInsertion: (
      realm: Realm,
      pendingInsertion: PendingDOMInsertion,
    ) => ReturnType<typeof pendingInsertion.mutator>,
    constructorToTagNamesMap: Map<string, string[]>,
  ],
  misc: [logger: Logger, logLevels: Set<LogLevel>],
];

/** Plugin API */
export interface AirgapPlugin {
  /** Init hook */
  init?(utils: PluginUtils, contextRealm: Realm): void;
  /** Protection hook */
  protect?(realm: Realm, utils: PluginUtils, contextRealm: Realm): void;
  // unused; might be used in the future
  // /** Consent resolution hook */
  // getConsent?(realm: Realm, utils: PluginUtils, contextRealm: Realm): void;
  // /** Monitoring hook */
  // monitor?(realm: Realm, utils: PluginUtils, contextRealm: Realm): void;
  // /** Consent sync hook */
  // sync?(realm: Realm, utils: PluginUtils, contextRealm: Realm): void;
}

/** Privacy governance policies bundle */
export interface PrivacyGovernancePolicies {
  /** Network data flow purpose maps */
  dataflows?: {
    /** Host purpose map entries */
    hosts?: IPurposeMap;
    /** Path purpose map entries */
    paths?: RegulatedPaths;
    /** CSP inclusion map entries */
    csp?: IPurposeMap;
  };
  /** Cookie purposes map */
  cookies?: ICookiePurposeMap;
}

/** Privacy governance policies bundle JSON representation */

export interface PrivacyGovernancePoliciesJSON {
  /** Network data flow purpose maps */
  dataflows?: {
    /** Host purpose map entries */
    hosts?: IPurposeMapJSON;
    /** Path purpose map entries */
    paths?: RegulatedPaths;
    /** CSP inclusion map entries */
    csp?: IPurposeMapJSON;
  };
  /** Cookie purposes map */
  cookies?: ICookiePurposeMapInputJSON;
}

/** Request telemetry suppression condition (return true = suppress telemetry) */
export type RequestTelemetrySuppressionCondition = (
  event: IPendingEvent,
) => boolean;

/** Telemetry suppression options */
export interface TelemetrySuppressionOptions {
  /** Request URL regex matchers */
  requests?: RegExp[];
  /** Cookie name regex matchers */
  cookies?: RegExp[];
  /** Error message regex matchers */
  errors?: RegExp[];
  /** Request suppression conditions */
  requestConditions?: RequestTelemetrySuppressionCondition[];
}

/** Interface representing the common stack frame subset between JS engines. Order is: [methodName, fileName, lineNum, colNum] */
export type CommonStackFrame = [
  /** Method call this frame originated from */
  methodName: string | null,
  /** Name of the file the method is instantiated in */
  fileName: string | undefined,
  /** Line number of the method call */
  lineNum: number | null,
  /** Column number of the method call */
  colNum: number | null,
];

/** Airgap telemetry API */
export interface TelemetryAPI {
  /**
   * Log stats for a given PendingEvent.
   *
   * @param event - Pending event
   * @param flush - Enqueue telemetry queue flush (defaults to true)
   */
  logRequestStats(
    event: IPendingEvent,
    gatherTrace?: boolean,
    flush?: boolean,
  ): void;
  /**
   * Log stats for a given PendingCookieMutation.
   *
   * @param cookie - Pending cookie mutation
   * @param flush - Enqueue telemetry queue flush (defaults to true)
   */
  logCookieStats(
    cookie: IPendingCookieMutation,
    stackFrames?: Promise<CommonStackFrame[]>,
    gatherTrace?: boolean,
    flush?: boolean,
  ): void;
  /**
   * Log an encountered error for airgap telemetry.
   * This method also emits errors through logger.error when appropriate.
   *
   * @param error - Error message to log
   * @param flush - Enqueue telemetry queue flush (defaults to true)
   */
  logError(message: Stringifiable, flush?: boolean): void;
}

/**
 * Airgap telemetry host queue
 *
 * This is a map of hosts to their encounter counts
 */
export type TelemetryHostQueue = Map<string, number>;

/**
 * Cookie telemetry stats (cookie+host => encounter count)
 */
export type CookieTelemetryStats = Map<string, number>;

/**
 * Airgap telemetry cookie queue
 *
 * This is a map of cookie names to their stats metadata
 */
export type TelemetryCookieQueue = Map<string, CookieTelemetryStats>;

/**
 * Cookie details sent to telemetry
 */
export type TelemetryCookieDetails = Omit<Cookie, 'value'>;

/**
 * Airgap telemetry cookie queue
 *
 * This is a map of parsed cookies to their stats metadata
 */
export type ProcessedTelemetryCookieQueue = Map<
  TelemetryCookieDetails,
  CookieTelemetryStats
>;

/**
 * Airgap telemetry path queue
 *
 * This is a map of path matcher => encounter count + associated hosts
 */
export type TelemetryPathQueue = Map<
  string,
  /** Hosts that matched this path matcher (and their encounter counts) */
  TelemetryHostQueue
>;

/**
 * Airgap telemetry path matchers (regexp) queue
 *
 * This is a map of path matcher => encounter count + associated hosts
 */
export type TelemetryPathMatchersQueue = Map<
  string,
  /** Hosts that matched this path matcher (and their encounter counts) */
  TelemetryHostQueue
>;

/**
 * Airgap telemetry path matchers (regexp) queue
 *
 * This is a map of path matcher => encounter count + associated hosts
 */
export interface TelemetryPathMatchersQueueJSON {
  [matcher: string]: string;
}

/**
 * Airgap telemetry cookie matcher queue
 *
 * This is a map of cookie matcher => encounter count + associated hosts
 */
export type TelemetryCookieMatcherQueue = Map<
  string,
  /**
   * Hosts of cookies that matched this cookie matcher (and their encounter counts)
   *
   * Cookie names are intentionally absent to prevent accidental PII collection
   */
  TelemetryHostQueue
>;

/** User-configurable user agent privacy signal */
export const UserPrivacySignal = valuesOf(UserPrivacySignalEnum);

/** type overload */
export type UserPrivacySignal = t.TypeOf<typeof UserPrivacySignal>;

/** User-configurable user agent privacy signal array */
export const RespectedUserPrivacySignals = t.array(
  valuesOf(UserPrivacySignalEnum),
);

/** type overload */
export type RespectedUserPrivacySignals = t.TypeOf<
  typeof RespectedUserPrivacySignals
>;

/** Tracking object change diffs */
export const Diff = t.record(t.string, t.boolean);
/** type overload */
export type Diff = t.TypeOf<typeof Diff>;

/** Request allowed/blocked stats on a per-tracking-purpose level */
export type TrackingPurposeStats = Map<
  /** Tracking purpose */
  TrackingPurpose,
  {
    /** Number of requests with this tracking purpose that were allowed */
    allowed: number;
    /** Number of requests with this tracking purpose that were blocked (includes blocked & subsequently allowed requests) */
    blocked: number;
  }
>;

/**
 * Request allowed/blocked stats on a per-tracking-purpose level
 * Should be an array of length 2 [number-of-requests-allowed, number-of-requests-blocked]
 * - First element: Number of requests with this tracking purpose that were allowed
 * - Second element: Number of requests with this tracking purpose that were blocked (includes blocked & subsequently allowed requests)
 */
export const TrackingPurposeStatsJSON = t.record(
  TrackingPurpose,
  FixedLengthArray(2, 2, t.number),
);

/** type overload */
export type TrackingPurposeStatsJSON = t.TypeOf<
  typeof TrackingPurposeStatsJSON
>;

/**
 * The consent status per purpose
 */
export const TrackingPurposeConsent = t.record(
  TrackingPurpose,
  t.union([t.boolean, t.string, t.undefined]),
);

/** type overload */
export type TrackingPurposeConsent = t.TypeOf<typeof TrackingPurposeConsent>;

/** Airgap telemetry request body */
export const TelemetryRequest = t.intersection([
  // required fields
  t.type({
    /** Originating site hostname (customer site) */
    site: t.string,
    /** Airgap version */
    version: t.string,
  }),
  // optional fields
  t.partial({
    /** Originating airgap.js bundle ID */
    id: t.string,
    /**
     * Encountered hosts list
     * Newline-separated list of hosts annotated with optional encounter counts
     *
     * @example
     * example.com 5
     * example.net
     * example.org 2
     * wss:example.com 4
     *
     * // (1 is implied for example.net)
     */
    hosts: t.string,
    /**
     * Encountered path matchers matches list
     * Newline-separated list of path matchers annotated with host encounter counts
     *
     * @example
     * example.com;//example.com
     * example.com 3,example.net 2;///favicon.ico
     * example.com 9;?query-param=blah
     * example.com;///paths can contain spaces
     * example.com 4;wss://example.com/sync
     *
     * // (1 is implied for ///favicon.ico)
     */
    paths: t.string,
    /**
     * Encountered cookies list
     *
     * Newline-separated list of encountered cookie names with optional encounter counts
     *
     * @example
     * foo 3
     * bar example.com
     * baz example.com example.org 10
     */
    cookies: t.string,
    /**
     * Map of encountered CSP violation URIs and their block counts. This is in the same format
     * as TelemetryRequest.hosts, but with URIs instead of hosts
     */
    cspv: t.string,
    /** List of (PII-sanitized) error messages emitted by airgap */
    errors: t.array(t.string),
    /** Total pageviews since the last telemetry payload */
    views: t.number,
    /** How many ‘sessions’ (unique sessionStorage contexts) has the user had (flushed) */
    sessions: t.number,
    /** Detected 'bounces' (flushed) */
    bounces: t.number,
    /** Detected user privacy legal regimes */
    regimes: t.array(t.string),
    /** Detected user privacy signals (e.g. GPC, DNT, etc.) */
    signals: t.array(UserPrivacySignal),
    /**
     * Allowed/blocked counts for all encountered requests, broken
     * down by tracking purposes for all encountered requests (flushed)
     */
    stats: TrackingPurposeStatsJSON,
    /** List of consent change events (flushed) */
    consent: t.array(Diff),
  }),
]);

/** type overload */
export type TelemetryRequest = t.TypeOf<typeof TelemetryRequest>;

/** Telemetry stats entry */
export interface TelemetryStatsEntry {
  /** Encountered hosts */
  hosts: TelemetryHostQueue;
  /** Matched path matchers (ag-core native) */
  paths: TelemetryPathQueue;
  /** Matched path matchers (RegExp) */
  pathMatchers: TelemetryPathMatchersQueue;
  /** Encountered cookies */
  cookies: TelemetryCookieQueue;
  /** Matched cookie matchers (RegExp) */
  cookieMatchers: TelemetryCookieMatcherQueue;
  /** Encountered CSP violations */
  cspv: TelemetryHostQueue;
  /** Consent changes */
  consent: Diff[];
}

/** Telemetry partitioned URL stats */
export type TelemetryStats = Map<string, TelemetryStatsEntry>;

export const TelemetryStatsEntryJSON = t.partial({
  /**
   * Encountered hosts list
   * Newline-separated list of hosts annotated with optional encounter counts
   *
   * @example
   * example.com 5
   * example.net
   * example.org 2
   * wss:example.com 4
   *
   * // (1 is implied for example.net)
   */
  hosts: t.string,
  /**
   * Encountered path matchers (ag-core native) matches list
   * Newline-separated list of path matchers annotated with host encounter counts
   *
   * @example
   * example.com;//example.com
   * example.com 3,example.net 2;///favicon.ico
   * example.com 9;?query-param=blah
   * example.com;///paths can contain spaces
   * example.com 4;wss://example.com/sync
   *
   * // (1 is implied for ///favicon.ico)
   */
  paths: t.string,
  /**
   * Encountered path matchers (regexp) matches list
   *
   * This is a JSON-compatible object with path matcher source as keys and host encounter counts as values
   */
  pathMatchers: t.record(t.string, t.string),
  /**
   * Encountered cookies list (v2)
   *
   * Newline-separated list of encountered cookie names with optional encounter counts
   *
   * @example
   * foo;Path=/;Expires=Thu, 14 Jul 2022 17:58:50 GMT;SameSite=Lax
   * 3
   * bar;Max-Age=100
   * example.com
   * baz
   * example.com example.org 10
   */
  cookies: t.string,
  /**
   * Encountered cookie matchers matches list
   *
   * Newline-separated list of cookie matchers annotated with host counts
   * Each pair of lines represents a cookie matcher and its associated hosts
   *
   * @example
   * foo.*bar
   * ^ 3 example.org 3
   * ^_+ga[\b-]
   * ^ 5 example.net
   * \bfb\b
   * ^ 2
   */
  cookieMatchers: t.string,
  /**
   * Map of encountered CSP violation URIs and their block counts. This is in the same format
   * as TelemetryRequest.hosts, but with URIs instead of hosts
   */
  cspv: t.string,
  /** List of consent change events (flushed) */
  consent: t.array(Diff),
});

/** type overload */
export type TelemetryStatsEntryJSON = t.TypeOf<typeof TelemetryStatsEntryJSON>;

/** Record with the key being the site itself, and the value as the telemetry stats for said site. */
export const TelemetryStatsJSON = t.record(t.string, TelemetryStatsEntryJSON);

/** type overload */
export type TelemetryStatsJSON = t.TypeOf<typeof TelemetryStatsJSON>;

/** Airgap telemetry request v2 body */
export const TelemetryRequestV2 = t.intersection([
  // required fields
  t.type({
    /** Airgap version */
    version: t.string,
    /** Originating site hostname (customer site) */
    site: t.string,
  }),
  // optional fields
  t.partial({
    /** Originating airgap.js bundle ID */
    id: t.string,
    /** Stats per URL partition */
    entries: TelemetryStatsJSON,
    /** List of (PII-sanitized) error messages emitted by airgap */
    errors: t.array(t.string),
    /** Total pageviews since the last telemetry payload */
    views: t.number,
    /** How many 'sessions' (unique sessionStorage contexts) has the user had (flushed) */
    sessions: t.number,
    /** Detected 'bounces' (flushed) */
    bounces: t.number,
    /** Detected user privacy legal regimes */
    regimes: t.array(t.string),
    /** Detected user privacy signals (e.g. GPC, DNT, etc.) */
    signals: t.array(UserPrivacySignal),
    /**
     * Allowed/blocked counts for all encountered requests, broken
     * down by tracking purposes for all encountered requests (flushed)
     */
    purposes: TrackingPurposeStatsJSON,
    /** The default consent values based on the detected regime */
    defaultConsent: TrackingPurposeConsent,
    /** The current consent values */
    currentConsent: TrackingPurposeConsent,
    /**
     * sampling rate (defaults to 1) - percentage of sessions to send telemetry for
     *
     * @deprecated - TODO: https://transcend.height.app/T-28974 - remove
     */
    samplingRate: t.number,
    /** client sampling rate (defaults to 1) - percentage of cookie mutations and outgoing requests to send telemetry for */
    clientSamplingRate: t.number,
    /** true or undefined if included, false if excluded from processing */
    sampled: t.boolean,
    /** Mobile Application Id */
    mobileAppId: t.string,
  }),
]);

/** type overload */
export type TelemetryRequestV2 = t.TypeOf<typeof TelemetryRequestV2>;

/** get() property descriptor accessor */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type Getter = (this: any) => any;
/** set() property descriptor accessor */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type Setter = (this: any, value: any) => any;

/** Partial property descriptor (accessor properties only) */
export interface PropertyDescriptorAccessor {
  /** Getter */
  get: Getter;
  /** Setter */
  set: Setter;
}
/** Full property descriptor */
export type PropertyDescriptorWithAccessor = PropertyDescriptor &
  PropertyDescriptorAccessor;
/** A list of property descriptors */
export interface PropertyDescriptorsWithAccessors {
  [property: string]: PropertyDescriptorWithAccessor;
}

/**
 * Internal implementation for regulated query parameters;
 */
export interface RegulatedQueryParamConfig {
  /** Query param name */
  param: string;
  /** Optional query param value substring matcher */
  value: string | null;
  /** Associated tracking purposes */
  purposes: Set<TrackingPurpose>;
  /** Original query param matcher source input */
  source: string;
}

/** Regulated path configuration */
export const RegulatedPathConfig = t.intersection([
  t.type({
    /**
     * Regulated path matcher.
     *
     * Potential formats:
     * - Relative path (same-site): `/path/to/resource`
     * - Relative path (any-site): `///path/to/resource`
     * - Protocol-relative path: `//site//path/to/resource`
     * - Absolute path: `https://site/path/to/resource`
     * - Query parameter: `?param=value`
     * - RegExp
     */
    matcher: t.union([t.string, regexp]),
    /** Tracking purposes of the request itself */
    purposes: t.array(TrackingPurpose),
  }),
  t.partial({
    /** Is this a RegExp string? (optional; default: false) */
    regex: t.boolean,
    /** Name */
    name: t.string,
    /** Note */
    note: t.string,
    /** Description */
    description: t.string,
  }),
]);
/** Type override */
export type RegulatedPathConfig = t.TypeOf<typeof RegulatedPathConfig>;

/**
 * Regulated path configuration (resolved for internal use)
 */
export type ResolvedRegulatedPathConfig = Omit<
  RegulatedPathConfig,
  'purposes'
> & {
  /** Tracking purposes of the request itself (resolved to a set) */
  purposes: Set<TrackingPurpose>;
};

/** Regulated paths configuration */
export const RegulatedPaths = t.array(RegulatedPathConfig);
/** Type override */
export type RegulatedPaths = t.TypeOf<typeof RegulatedPaths>;
/** Regulated paths configuration (resolved for internal use) */
export type ResolvedRegulatedPaths = ResolvedRegulatedPathConfig[];

/** Interface for airgap data flow purpose maps */
export type IPurposeMap = Map<string, TrackingPurposes>;

/** Cookie purpose config (purpose map entry internal representation) */
export interface ICookiePurposeConfig {
  /** Cookie name exact string or regexp matcher */
  cookie: string | RegExp;
  /** Tracking purposes */
  purposes: TrackingPurposes;
  /** Optional cookie host scope(s) */
  hosts?: Set<string> | null;
}

/** Cookie purpose config input */
export const ICookiePurposesConfigInput = t.intersection([
  t.type({
    /** Cookie name */
    cookie: t.union([t.string, regexp]),
    /** Tracking purposes */
    purposes: t.array(t.string),
  }),
  t.partial({
    /** Is cookie name a RegExp string matcher (optional; default: false) */
    regex: t.boolean,
    /** Optional cookie host scope(s) */
    hosts: t.union([t.array(t.string), t.null]),
  }),
]);
/** Type override */
export type ICookiePurposesConfigInput = t.TypeOf<
  typeof ICookiePurposesConfigInput
>;

/** Cookie purpose map input format */
export const ICookiePurposeMapInputJSON = t.array(ICookiePurposesConfigInput);
/** Type override */
export type ICookiePurposeMapInputJSON = t.TypeOf<
  typeof ICookiePurposeMapInputJSON
>;

/** Interface for airgap cookie purpose maps */
export type ICookiePurposeMap = ICookiePurposeConfig[];

/** Interface for dynamic node references */
export interface IDynamicNodeReference {
  /**
   * Current node getter. This should always be used in `handleLiveMutation()`.
   *
   * @returns current node
   */
  getNode(): Element;
  /**
   * Live node getter. Use this to apply mutations in `quarantine()`
   * and `quarantineMutation()` handlers.
   *
   * `release()` must always be called after completing
   * mutations using `getLiveNode()`.
   *
   * @returns live node
   */
  getLiveNode(): Element;
  /** Release & garbage-collect internal node reference */
  release(): void;
}

/** mapping of what experiences communicate which signals */
export const IABExperienceMap = t.record(
  t.string,
  t.array(valuesOf(IABSignal)),
);
/** Type override */
export type IABExperienceMap = t.TypeOf<typeof IABExperienceMap>;

/** GPP module config to be compiled into the module code */
export const GPPBundledModuleData = t.type({
  /** mapping of what experiences communicate which signals */
  experienceMap: IABExperienceMap,
});
/** Type override */
export type GPPBundledModuleData = t.TypeOf<typeof GPPBundledModuleData>;

/** Mobile bridge module config to be compiled into the module code */
export const MobileBridgeBundledModuleData = t.type({
  /** List of consent Applications */
  consentApplications: t.array(t.string),
  /** Regime purpose opt outs */
  regimePurposeOptOuts: t.array(
    t.tuple([t.array(t.string), t.array(t.string)]),
  ),
  /** Telemetry Endpoint */
  telemetryEndpoint: t.string,
  /** Partition key for localStorage */
  partition: t.string,
});
/** Type override */
export type MobileBridgeBundledModuleData = t.TypeOf<
  typeof MobileBridgeBundledModuleData
>;

/* eslint-enable max-lines */
