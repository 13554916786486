import { defineMessages } from '@transcend-io/internationalization';

export const selectAssigneesMessages = defineMessages(
  'admin-dashboard.Assessments.components.SelectAssignees.selectAssignees',
  {
    assignViaEmail: {
      defaultMessage: 'Assign to external user: {email}',
    },
  },
);
