import { mkType, SchemaToType } from '@main/schema-utils';

import { ProfileDataPoint } from './ProfileDataPoint';
import { ProfilePreview } from './profilePreview';
import { RequestIdentifier } from './RequestIdentifier';

export const Profile = mkType({
  name: 'Profile',
  comment:
    'A unique identifier for a data subject in the context of a data silo. From this profile is where all data is attached.',
  fields: {
    ...ProfilePreview.fields,
    requestIdentifiers: {
      comment: 'The request identifiers that mapped to this profile',
      list: true,
      type: RequestIdentifier,
    },
    profileDataPoints: {
      comment:
        'The statuses of the datapoints to fire in respect to this profile',
      list: true,
      type: ProfileDataPoint,
    },
  },
});

/** Override type */
export type Profile = SchemaToType<typeof Profile>;
