import { transparentize } from 'polished';

import { FIXED_COLORS } from '@main/theme';

// Shared colors between Highlight.js and Prism.js, for semantically equivalent tokens
const COLOR_MAIN_TEXT = FIXED_COLORS.transcendNavy;
const COLOR_COMMENT = FIXED_COLORS.transcendNavy3;
const COLOR_STRING = FIXED_COLORS.red1;
const COLOR_KEYWORD = FIXED_COLORS.indigo1;
const COLOR_NUMBER = FIXED_COLORS.mint1;
const COLOR_FUNCTION = FIXED_COLORS.blue1;
const COLOR_VARIABLE = FIXED_COLORS.mint1;
const COLOR_PROPERTY = FIXED_COLORS.blue1;
const COLOR_BOOLEAN = FIXED_COLORS.indigo1;
const COLOR_CLASS_NAME = FIXED_COLORS.purple1;
const COLOR_TAG = FIXED_COLORS.indigo1;
const COLOR_ATTR_NAME = FIXED_COLORS.blue1;
const COLOR_ATTR_VALUE = FIXED_COLORS.red1;
const COLOR_REGEXP = FIXED_COLORS.mint1;
const COLOR_BUILTIN = FIXED_COLORS.mint1;
const COLOR_SYMBOL = FIXED_COLORS.pink1;
const COLOR_OPERATOR = FIXED_COLORS.transcendNavy2;
const COLOR_PUNCTUATION = FIXED_COLORS.transcendNavy2;
const COLOR_INSERTED = transparentize(0.65, FIXED_COLORS.positiveHighlight);
const COLOR_DELETED = transparentize(0.65, FIXED_COLORS.negativeHighlight);
const COLOR_SECTION = FIXED_COLORS.indigo1; // Assuming an appropriate color for sections
const COLOR_LINK = FIXED_COLORS.lime1; // Assuming an appropriate color for links
const COLOR_CONSTANT = FIXED_COLORS.mint1;
const COLOR_IMPORTANT = FIXED_COLORS.red1;
const STYLE_BOLD: React.CSSProperties = { fontWeight: 'bold' };
const STYLE_ITALIC: React.CSSProperties = { fontStyle: 'italic' };

/**
 * Custom code highlighting theme
 *
 * @see https://github.com/react-syntax-highlighter/react-syntax-highlighter/blob/master/src/styles/hljs/github.js
 */
export const hljsCodeTheme: { [key: string]: React.CSSProperties } = {
  hljs: {
    overflowX: 'auto',
    color: COLOR_MAIN_TEXT,
  },
  'hljs-comment': { color: COLOR_COMMENT },
  'hljs-string': { color: COLOR_STRING },
  'hljs-keyword': { color: COLOR_KEYWORD },
  'hljs-number': { color: COLOR_NUMBER },
  'hljs-regexp': { color: COLOR_REGEXP },
  'hljs-symbol': { color: COLOR_SYMBOL },
  'hljs-variable': { color: COLOR_VARIABLE },
  'hljs-template-variable': { color: COLOR_VARIABLE },
  'hljs-link': { color: COLOR_LINK },
  'hljs-literal': { color: COLOR_BOOLEAN },
  'hljs-meta': { color: COLOR_OPERATOR },
  'hljs-operator': { color: COLOR_OPERATOR },
  'hljs-bullet': { color: COLOR_BUILTIN },
  'hljs-tag': { color: COLOR_PUNCTUATION },
  'hljs-selector': { color: COLOR_SYMBOL },
  'hljs-selector-tag': { color: COLOR_MAIN_TEXT },
  'hljs-selector-class': { color: COLOR_MAIN_TEXT },
  'hljs-selector-pseudo': { color: FIXED_COLORS.red1 },
  'hljs-name': { color: COLOR_TAG },
  'hljs-attr': { color: COLOR_ATTR_NAME },
  'hljs-attribute': { color: COLOR_ATTR_VALUE },
  'hljs-addition': { background: COLOR_INSERTED },
  'hljs-deletion': { background: COLOR_DELETED },
  'hljs-section': { color: COLOR_SECTION },
  'hljs-quote': { color: COLOR_STRING },
  'hljs-title': { color: COLOR_CLASS_NAME },
  'hljs-function .hljs-title': { color: COLOR_FUNCTION },
  'hljs-built_in': { color: COLOR_BUILTIN },
  'hljs-type': { color: COLOR_CLASS_NAME },
  'hljs-emphasis': STYLE_ITALIC,
  'hljs-strong': STYLE_BOLD,
};

// Prism.js theme
export const prismCodeTheme: { [key: string]: React.CSSProperties } = {
  'pre[class*="language-"]': {
    overflowX: 'auto',
    color: COLOR_MAIN_TEXT,
  },
  'code[class*="language-"]': {
    color: COLOR_MAIN_TEXT,
  },
  keyword: { color: COLOR_KEYWORD },
  builtin: { color: COLOR_BUILTIN },
  'class-name': { color: COLOR_CLASS_NAME },
  function: { color: COLOR_FUNCTION },
  boolean: { color: COLOR_BOOLEAN },
  number: { color: COLOR_NUMBER },
  string: { color: COLOR_STRING },
  char: { color: COLOR_STRING },
  symbol: { color: COLOR_SYMBOL },
  regex: { color: COLOR_REGEXP },
  url: { color: COLOR_LINK },
  operator: { color: COLOR_OPERATOR },
  variable: { color: COLOR_VARIABLE },
  constant: { color: COLOR_CONSTANT },
  property: { color: COLOR_PROPERTY },
  punctuation: { color: COLOR_PUNCTUATION },
  'script-punctuation': { color: COLOR_PUNCTUATION },
  comment: { color: COLOR_COMMENT },
  tag: { color: COLOR_TAG },
  'attr-name': { color: COLOR_ATTR_NAME },
  'attr-value': { color: COLOR_ATTR_VALUE },
  inserted: { background: COLOR_INSERTED },
  deleted: { background: COLOR_DELETED },
  namespace: { color: FIXED_COLORS.indigo1 },
  selector: { color: FIXED_COLORS.indigo1 },
  doctype: { color: COLOR_COMMENT },
  cdata: { color: COLOR_COMMENT },
  important: { color: COLOR_IMPORTANT },
  bold: STYLE_BOLD,
  italic: STYLE_ITALIC,
};
