import styled from 'styled-components';

import { Button } from '@main/ad-core-components';
import { A } from '@main/core-ui';

export const MenuItemLink = styled(A)`
  font-size: 12px;
  border-radius: 6px;
  padding: 4px;
  color: ${({ theme }) => theme.colors.transcendNavy2};
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition:
    background 0.2s,
    opacity 0.3s ease-in-out;

  &:hover,
  &:focus {
    background-color: ${({ theme }) => theme.colors.gray1};
  }
`;

export const StyledButton = styled(Button)`
  border: none;
  border-top: 1px solid ${({ theme }) => theme.colors.gray3};
  justify-content: flex-start;
  padding: 12px 1.25em;
  border-radius: 0;

  transition:
    flex-grow 0.3s ease-in-out 0.15s,
    padding 0.3s ease-in-out 0.15s;
`;

export const OrganizationAdminSection = styled.div`
  display: flex;
  align-items: center;
  flex-grow: 1;
`;

interface IOrganizationLogoIconProps {
  /** The size (width & height) of the icon */
  size?: number;
}

export const OrganizationLogoIcon = styled.img<IOrganizationLogoIconProps>`
  height: ${({ size }) => size ?? 32}px;
  max-width: ${({ size }) => size ?? 32}px;
  border-radius: 6px;
  // when no image is present
  color: transparent;
  overflow: hidden;
`;

export interface IOrganizationIconPlaceholderProps {
  /** The background color of the placeholder */
  bgColor?: string;
  /** The size (width & height) of the placeholder */
  size?: number;
}

export const OrganizationIconPlaceholder = styled.div<IOrganizationIconPlaceholderProps>`
  width: ${({ size }) => size ?? 32}px;
  height: ${({ size }) => size ?? 32}px;
  border-radius: 6px;
  font-size: ${({ size }) => Math.round((size ?? 32) / 2)}px;
  background-color: ${({ bgColor, theme }) =>
    bgColor ?? theme.colors.transcendNavy3};
  color: ${({ theme }) => theme.colors.white};
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const OrganizationNameLabel = styled.div`
  color: ${({ theme }) => theme.colors.transcendNavy};
  font-size: 14px;
  font-weight: 600;
  margin-right: 12px;
  flex-grow: 1;
`;

export const EmailWrapper = styled.div`
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
