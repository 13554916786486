import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { Button, ButtonProps } from '@main/ad-core-components';
import { FlexColumn } from '@main/core-ui';

interface CollapsibleElementProps {
  /** is the element at full size */
  $isExpanded?: boolean;
}

export const NavItemCollapsibleHeightWrapper = styled(FlexColumn)`
  overflow: hidden;

  transition:
    max-height 0.3s ease-in-out,
    opacity 0.3s ease-in-out;
  transition-delay: 0.15s;
`;

export const NavItemLabelWrapper = styled(FlexColumn)<CollapsibleElementProps>`
  font-weight: 600;
  flex-grow: 1;
  color: inherit;
  overflow: hidden;
  padding: 0.3em;

  transition: opacity 0.3s ease-in-out 0.15s;

  ${({ $isExpanded }) =>
    $isExpanded
      ? `
  opacity: 1;
`
      : `
  opacity: 0;
  `}
`;

export const NavItemStatusBadgeWrapper = styled(
  FlexColumn,
)<CollapsibleElementProps>`
  overflow: hidden;
  padding: 0.3em;

  transition: opacity 0.3s ease-in-out 0.15s;

  ${({ $isExpanded }) =>
    $isExpanded
      ? `
  opacity: 1;
`
      : `
  opacity: 0;
  `}
`;

export const StyledNavItemCollapseButton = styled(Button)<
  ButtonProps & CollapsibleElementProps
>`
  overflow: hidden;
  // need to eat into the right padding to not crowd the items
  //margin-right: -0.75em;
  padding: 0.25em 0.5em;

  transition: opacity 0.3s ease-in-out 0.15s;

  ${({ $isExpanded }) =>
    $isExpanded
      ? `
  opacity: 1;
`
      : `
  opacity: 0;
  `}
`;

export const StyledNavLinkRaw = styled(Link)`
  display: flex;
  align-items: center;
  padding-right: 0.5em;

  > ${StyledNavItemCollapseButton} {
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
  }

  &:hover > ${StyledNavItemCollapseButton} {
    opacity: 1;
  }
`;
