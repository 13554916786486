import {
  extractFormItemWrapperProps,
  FlexRow,
  FormItemWrapper,
  IFormFieldProps,
  IFormItemWrapperProps,
  multipleValidators,
  TValidator,
} from '@main/core-ui';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { Input, InputProps } from '../Input';

export interface IFormInputNumberProps extends IFormFieldProps {
  /** the validation rules for the input */
  rules?: TValidator[];
}

/**
 * A raw number form input for use cases where formatting is not desired
 */
export const FormInputNumberRaw: React.FC<
  IFormInputNumberProps & Omit<InputProps, 'value' | 'onChange' | 'onBlur'>
> = ({ name, defaultValue = '', rules, ...inputProps }) => {
  const { control } = useFormContext();
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      rules={rules ? { validate: multipleValidators(rules) } : undefined}
      render={({ field }) => (
        <FlexRow style={{ width: '100%', flexGrow: 1 }}>
          <Input
            {...inputProps}
            {...field}
            type="number"
            onChange={(event) =>
              field.onChange(
                event.target.value?.trim() !== ''
                  ? +event.target.value
                  : undefined,
              )
            }
          />
        </FlexRow>
      )}
    />
  );
};

/**
 * A number form input with formatting and error display
 */
export const FormInputNumber: React.FC<
  IFormInputNumberProps & InputProps & Omit<IFormItemWrapperProps, 'errors'>
> = (props) => {
  const {
    formState: { errors },
  } = useFormContext();
  const { passthroughProps, wrapperProps } = extractFormItemWrapperProps(props);

  return (
    <FormItemWrapper errors={errors} {...wrapperProps}>
      <FormInputNumberRaw {...passthroughProps} />
    </FormItemWrapper>
  );
};
