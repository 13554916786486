import { FilterConfigItem } from '@main/ad-core-components';
import {
  SelectedPromptGroup,
  SelectPromptGroups,
} from '@main/admin-dashboard/src/Prompts/components/SelectPromptGroups';
import { buildUseLazyQuery } from '@main/core-ui';
import { endpoints } from '@main/prompt-types';
import { ID } from '@main/schema-utils';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { MessageDescriptor } from 'react-intl';

import { filterManagerHookMessages } from './messages';

/**
 * Filters definition with property for promptGroup values specified
 */
type PromptGroupEnrichedFilters = Record<string, unknown> &
  Record<
    /**
     * PromptGroup values that are currently filtering the table
     */
    string,
    SelectedPromptGroup[] | undefined
  >;

const useLazyPromptGroups = buildUseLazyQuery(endpoints.promptGroups);

export const usePromptGroupFilters = <
  T extends Record<string, unknown>,
  E extends PromptGroupEnrichedFilters,
>({
  promptGroupIdsFilterKey = 'promptGroupIds',
  filters,
  setFilters,
  label,
  enrichedPromptGroupKey = 'promptGroups',
}: {
  /** The property name of the filter for promptGroup ids */
  promptGroupIdsFilterKey?: keyof T;
  /** The currently applied filters */
  filters?: T;
  /** Callback when the filters are changed */
  setFilters: (filters: T) => void;
  /** Label for the filter menu */
  label: string | MessageDescriptor;
  /** The key to use if more than one usePromptGroupFilters is used in a FilterManager */
  enrichedPromptGroupKey?: keyof E;
}): {
  /**  The promptGroup keys enabled on this table */
  selectedPromptGroups: SelectedPromptGroup[];
  /** The filter configuration to be passed to the filter manager */
  promptGroupFiltersConfig: FilterConfigItem<E>;
  /** Callback for when the filter is cleared in the filter manager */
  clearPromptGroupFilters: (key: Extract<keyof E, string>) => void;
} => {
  const getSelectedPromptGroups = useLazyPromptGroups();
  const [selectedPromptGroups, setSelectedPromptGroups] = useState<
    SelectedPromptGroup[]
  >([]);
  const [fetchingPromptGroups, setFetchingPromptGroups] = useState(false);

  // Populate initial complex filter values
  useEffect(() => {
    if (filters) {
      const promptGroupIds = (filters[promptGroupIdsFilterKey] ??
        []) as ID<'promptGroup'>[];

      const filtersAndEnrichedFiltersMatch =
        promptGroupIds.sort().join() ===
        selectedPromptGroups
          .map(({ id }) => id)
          .sort()
          .join();

      if (promptGroupIds.length === 0 && selectedPromptGroups.length > 0) {
        setSelectedPromptGroups([]);
      } else if (!filtersAndEnrichedFiltersMatch && !fetchingPromptGroups) {
        // Prevent over-firing this setter while data is still being fetched
        setFetchingPromptGroups(true);
        getSelectedPromptGroups({ filterBy: { ids: promptGroupIds } })
          .then(({ data }) => {
            setSelectedPromptGroups(data.nodes);
          })
          .finally(() => {
            setFetchingPromptGroups(false);
          });
      }
    }
  }, [
    filters,
    fetchingPromptGroups,
    getSelectedPromptGroups,
    selectedPromptGroups.length,
    promptGroupIdsFilterKey,
    selectedPromptGroups,
  ]);

  const promptGroupFiltersConfig = useMemo(
    () =>
      ({
        filterKey: enrichedPromptGroupKey,
        label,
        pillOptions: {
          label: ({
            filterValues: { [enrichedPromptGroupKey]: promptGroups = [] },
            index = 0,
          }) => promptGroups[index]?.title,
        },
        filter: (
          <SelectPromptGroups
            isMulti
            placeholderDescriptor={
              filterManagerHookMessages.promptGroupsPlaceholder
            }
            menuPosition="absolute"
            value={selectedPromptGroups}
            showOutline={false}
            onChange={(promptGroups) => {
              setSelectedPromptGroups([...promptGroups]);
              setFilters({
                ...filters,
                [promptGroupIdsFilterKey]: promptGroups.map(({ id }) => id),
              } as T);
            }}
          />
        ),
      }) as FilterConfigItem<PromptGroupEnrichedFilters>,
    [
      enrichedPromptGroupKey,
      label,
      selectedPromptGroups,
      filters,
      promptGroupIdsFilterKey,
    ],
  );

  const clearPromptGroupFilters = useCallback(
    (key) => {
      if (key === enrichedPromptGroupKey) {
        setFilters({
          ...filters,
          [promptGroupIdsFilterKey]: [],
        } as T);
        setSelectedPromptGroups([]);
      }
    },
    [enrichedPromptGroupKey, filters, promptGroupIdsFilterKey],
  );

  return {
    selectedPromptGroups,
    promptGroupFiltersConfig,
    clearPromptGroupFilters,
  };
};
