import { datadogLogs } from '@datadog/browser-logs';

import { DATADOG_LOGS_CLIENT_TOKEN } from '@main/core-ui';

import { DATADOG_RUM_CLIENT_TOKEN } from './args';

declare global {
  interface Window {
    /** DataDog RUM */
    DD_RUM?: {
      /** Global context for RUM events */
      setGlobalContextProperty: (key: string, value: any) => void;
      /** Set user */
      setUser: (newUser: {
        /** User ID */
        id?: string | undefined;
        /** User Email */
        email?: string | undefined;
        /** User Name */
        name?: string | undefined;
        [key: string]: unknown;
      }) => void;
      /** Begin session replay recording. ONLY used for `organization.tier === 'INTERNAL'` */
      startSessionReplayRecording: (options?: {
        /** Force the Session Replay recording even on sampled-out sessions. Sets `sessionReplaySampleRate: 100` if it is different at init. */
        force: boolean;
      }) => void;
    };
  }
}

const datadogRum = window.DD_RUM;

/**
 * Set context on DataDog logs and RUM
 *
 * @param key - the key to set on DataDog logs/RUM
 * @param value - the value to set on DataDog logs/RUM
 */
export function setDataDogContext(key: string, value: any): void {
  // Logs
  if (DATADOG_LOGS_CLIENT_TOKEN) {
    datadogLogs.addLoggerGlobalContext(key, value);
  }

  // Real User Monitoring (RUM)
  if (DATADOG_RUM_CLIENT_TOKEN && datadogRum) {
    datadogRum.setGlobalContextProperty(key, value);
  }
}

/**
 * Set the user on DataDog RUM
 *
 * @param userId - the user id to set on DataDog RUM
 */
export function setDataDogUser(userId: string): void {
  datadogRum?.setUser({ id: userId });
}
