import { defineMessages } from '@transcend-io/internationalization';

import { TemplateType } from '@main/datamap-types';

import { TemplateVariableUseCase } from './constants';

export const templatesFormItemMessages = defineMessages(
  'admin-dashboard.PrivacyRequests.EmailSettings.Templates.templatesFormItem',
  {
    type: {
      defaultMessage: 'Template Type',
    },
    typeInfo: {
      defaultMessage:
        'Whether this template will be used to send an email or a text',
    },
    subject: {
      defaultMessage: 'Message Subject',
    },
    subjectPlaceholder: {
      defaultMessage: 'This will be the email subject line.',
    },
    template: {
      defaultMessage: 'Message Template',
    },
    templatePlaceholder: {
      defaultMessage: 'We need some additional information...',
    },
    title: {
      defaultMessage: 'Internal Title',
    },
    titlePlaceholder: {
      defaultMessage: 'Custom Template',
    },
    locale: {
      defaultMessage: 'Language',
    },
    localeInfo: {
      defaultMessage: 'Choose which translation of this email template to use',
    },
  },
);

export const templatesMessages = defineMessages(
  'admin-dashboard.PrivacyRequests.EmailSettings.Templates.templates',
  {
    header: {
      defaultMessage: 'Email Templates',
    },
    templateId: {
      defaultMessage: 'Template ID:',
    },
    noMatches: {
      defaultMessage: 'No matching templates',
    },
    none: {
      defaultMessage: 'No templates created',
    },
    // create
    create: {
      defaultMessage: 'New Template',
    },
    // update
    updateHeader: {
      defaultMessage: 'Edit Template - {title}',
    },
    submit: {
      defaultMessage: 'Update template',
    },
    success: {
      defaultMessage: 'Successfully updated template!',
    },
    // select
    placeholder: {
      defaultMessage: 'Select Template',
    },
    existingTemplates: {
      defaultMessage: 'Existing Templates',
    },
    templateCreatedSuccessfully: {
      defaultMessage: 'Template created successfully',
    },
    defaultTemplate: {
      defaultMessage: 'Default Template',
    },
    templateVariables: {
      defaultMessage: 'Available template variables',
    },
    templateCannotBeDeleted: {
      defaultMessage:
        'Template cannot be deleted because it is assigned to a Data Action or Integration',
    },
    templateIsDefault: {
      defaultMessage:
        'Template cannot be deleted because it is a default template',
    },
    copiedtoClipboard: {
      defaultMessage: 'Copied to clipboard!',
    },
    useCase: {
      defaultMessage: 'Use Case:',
    },
  },
);

export const templateVariableGroupMessages =
  defineMessages<TemplateVariableUseCase>(
    'admin-dashboard.PrivacyRequests.EmailSettings.Templates.templateVariableGroup',
    {
      [TemplateVariableUseCase.Request]: {
        defaultMessage: 'Request',
      },
      [TemplateVariableUseCase.AutomatedVendorCoordination]: {
        defaultMessage: 'Automated Vendor Coordination',
      },
      [TemplateVariableUseCase.Assessment]: {
        defaultMessage: 'Assessment',
      },
      [TemplateVariableUseCase.PrivacyCenter]: {
        defaultMessage: 'Privacy Center',
      },
    },
  );
export const templateVariableGroupDescriptionMessages =
  defineMessages<TemplateVariableUseCase>(
    'admin-dashboard.PrivacyRequests.EmailSettings.Templates.templateVariableGroupDescription',
    {
      [TemplateVariableUseCase.Request]: {
        defaultMessage:
          'Emails sent to the requestor of a DSR or DSR status emails',
      },
      [TemplateVariableUseCase.AutomatedVendorCoordination]: {
        defaultMessage:
          'Emails sent by Automated Vendor Coordination data silos',
      },
      [TemplateVariableUseCase.Assessment]: {
        defaultMessage: 'Emails sent to Assessment assignees',
      },
      [TemplateVariableUseCase.PrivacyCenter]: {
        defaultMessage: 'Privacy Center login and session related emails',
      },
    },
  );

export const templatesTypeMessages = defineMessages<TemplateType>(
  'admin-dashboard.PrivacyRequests.EmailSettings.Templates.templatesType',
  {
    [TemplateType.Email]: { defaultMessage: 'Email' },
    [TemplateType.Text]: { defaultMessage: 'Text' },
  },
);
