import React from 'react';

import { InlineInputNumberWrapperProps, StyledInputNumber } from './wrappers';

/**
 * A number input to be used for inline changes where you want the callback to be fired onBlur
 */
export const InlineNumberInput = React.forwardRef<
  HTMLInputElement,
  InlineInputNumberWrapperProps
>(({ ...inputProps }, ref) => <StyledInputNumber {...inputProps} ref={ref} />);
