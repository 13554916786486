/**
 * Fields that a list of RequestIdentifiers can be ordered by (in ASC or DESC order)
 */
export enum RequestIdentifierOrderField {
  /** Order by name */
  Name = 'name',
  /** Order in direction of RequestIdentifier creation (submission time) */
  CreatedAt = 'createdAt',
  /** Order in direction of last modification of the RequestIdentifier */
  UpdatedAt = 'updatedAt',
}

/**
 * The source of the RequestIdentifier
 */
export enum RequestIdentifierSource {
  Input = 'INPUT',
  Enricher = 'ENRICHER',
  DataSilo = 'DATA_SILO',
}
