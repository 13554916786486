import { mkInput, mkType, SchemaToType } from '@main/schema-utils';

export const ConsentManagerStyleItem = mkType({
  name: 'ConsentManagerStyleItem',
  comment: 'Style sheet encoding for consent manger ui',
  fields: {
    header: {
      comment: 'Section header for style, used to group similar selectors',
      type: 'string',
    },
    comment: {
      comment: 'Comment for style selector',
      type: 'string',
    },
    selector: {
      comment: 'The style selector',
      type: 'string',
    },
    styles: {
      comment: 'The CSS string',
      type: 'string',
    },
    mediaStyles: {
      comment: 'The media styles string',
      type: 'string',
      optional: true,
    },
  },
});

/** Override type */
export type ConsentManagerStyleItem = SchemaToType<
  typeof ConsentManagerStyleItem
>;

export const ConsentManagerStyleItemInput = mkInput({
  name: 'ConsentManagerStyleItemInput',
  comment: 'Input for updating consent-manager-ui stylesheet',
  fields: {
    header: ConsentManagerStyleItem.fields.header,
    comment: ConsentManagerStyleItem.fields.comment,
    selector: ConsentManagerStyleItem.fields.selector,
    styles: ConsentManagerStyleItem.fields.styles,
    mediaStyles: ConsentManagerStyleItem.fields.mediaStyles,
  },
});

/** Override type */
export type ConsentManagerStyleItemInput = SchemaToType<
  typeof ConsentManagerStyleItemInput
>;

export const ConsentManagerTheme = mkType({
  name: 'ConsentManagerTheme',
  comment:
    'The UI theme configuration associated with a consent manager bundle',
  fields: {
    airgapBundleId: {
      comment: 'The ID of the associated Consent Manager bundle.',
      type: 'id',
      modelName: 'airgapBundle',
    },
    primaryColor: {
      comment: 'The button color',
      type: 'string',
    },
    fontColor: {
      comment: 'The type (text) color',
      type: 'string',
    },
    styles: {
      comment: 'Custom styles for the consent manager UI',
      type: ConsentManagerStyleItem,
      list: true,
    },
    privacyPolicy: {
      comment: 'The privacy policy URL',
      type: 'string',
      optional: true,
    },
    prompt: {
      comment:
        'The number of page views at which to prompt the default consent banner. ' +
        'To turn off auto-prompt, set this to 0.',
      type: 'int',
      optional: false,
    },
  },
});

/** Override type */
export type ConsentManagerTheme = SchemaToType<typeof ConsentManagerTheme>;

export const UpdateConsentManagerThemeInput = mkInput({
  name: 'UpdateConsentManagerThemeInput',
  comment: "The input for updating an existing Consent Manager's theme",
  fields: {
    airgapBundleId: {
      comment: 'The ID of the associated Consent Manager bundle.',
      type: 'id',
      modelName: 'airgapBundle',
    },
    primaryColor: {
      comment: 'The button color',
      type: 'string',
      optional: true,
    },
    fontColor: {
      comment: 'The type (text) color',
      type: 'string',
      optional: true,
    },
    privacyPolicy: {
      comment: 'The privacy policy link',
      type: 'string',
      optional: true,
    },
    prompt: {
      comment:
        'The number of page views at which to prompt the default consent banner. ' +
        'To turn off auto-prompt, set this to 0.',
      type: 'int',
      optional: true,
    },
    styles: {
      comment: 'The custom styles to update',
      type: ConsentManagerStyleItemInput,
      list: true,
      optional: true,
    },
  },
});

/** Override type */
export type UpdateConsentManagerThemeInput = SchemaToType<
  typeof UpdateConsentManagerThemeInput
>;
