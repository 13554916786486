import { Badge } from '@main/core-ui';
import React, { isValidElement, ReactNode } from 'react';

import { FilterConfigItem, PillRenderProps } from './types';

const isReactNode = (value: any): value is ReactNode =>
  isValidElement(value) ||
  typeof value === 'string' ||
  typeof value === 'number' ||
  typeof value === 'boolean' ||
  (Array.isArray(value) && value.every((item) => isReactNode(item)));

interface FilterPillProps<TFilterInput> extends PillRenderProps<TFilterInput> {
  /** Single item from the filter config */
  filterItem: FilterConfigItem<TFilterInput>;
}

export const FilterPillValues = <T extends Record<string, unknown>>({
  filterItem,
  ...props
}: FilterPillProps<T>): JSX.Element => {
  const { renderPill, pillOptions, renderEmptyPill } = filterItem;

  const values = props.filterValues[filterItem.filterKey];

  if (!props.filterValues) {
    return <>{renderEmptyPill && renderEmptyPill(props)}</>;
  }
  if (renderPill) {
    return <>{renderPill(props)}</>;
  }

  const { color, label, ...badgeProps } = pillOptions || {};

  return (
    <Badge
      {...badgeProps}
      color={
        !color ? 'gray1' : typeof color === 'string' ? color : color(props)
      }
    >
      {label
        ? label(props)
        : isReactNode(values as any)
          ? (values as ReactNode) // the check above should assert this, but it doesn't
          : null}
    </Badge>
  );
};
