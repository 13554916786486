import { indexBy } from '@main/utils';

import { DataSubject } from './schema';

/**
 * Derive allow list of data subjects from a blocklist
 *
 * @param blockSubjects - Block list
 * @param allSubjects - All subjects in org
 * @returns Allow list
 */
export function deriveDataSubjectAllowlist(
  blockSubjects: DataSubject[],
  allSubjects: DataSubject[] = [],
): DataSubject[] {
  const blockListLookup = indexBy(blockSubjects, 'id');
  return allSubjects.filter((subject) => !blockListLookup[subject.id]);
}
