import * as t from 'io-ts';

import { dbModelId } from '@main/schema-utils';

import { AuditEventCode } from '../schema/enums';
import {
  mkAuditEventCodec,
  mkAuditEventJoinCodec,
  mkAuditJoinStateCodec,
} from '../types';

export const AuditEventExperience = mkAuditEventCodec(
  AuditEventCode.Experience,
  t.type({
    /** ID of the experience */
    experienceId: dbModelId('experience'),
  }),
);

/** Override types. */
export type AuditEventExperience = t.TypeOf<typeof AuditEventExperience>;

export const AuditEventExperiencePurpose = mkAuditEventJoinCodec(
  AuditEventCode.ExperiencePurpose,
  mkAuditJoinStateCodec('purpose'),
  t.type({
    /** ID of the experience */
    experienceId: dbModelId('experience'),
  }),
);

/** Override types. */
export type AuditEventExperiencePurpose = t.TypeOf<
  typeof AuditEventExperiencePurpose
>;
