import 'antd/lib/table/style/index';

import Table, { TableProps } from 'antd/lib/table';
import React, { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';

import { dnsRecordTableMessages } from './messages';

export { dnsRecordTableMessages };

const DNS_RECORD_TABLE_COLUMNS = [
  {
    title: <FormattedMessage {...dnsRecordTableMessages.type} />,
    dataIndex: 'type',
    width: '10%',
  },
  {
    title: <FormattedMessage {...dnsRecordTableMessages.name} />,
    dataIndex: 'name',
    width: '45%',
  },
  {
    title: <FormattedMessage {...dnsRecordTableMessages.value} />,
    dataIndex: 'value',
    width: '45%',
  },
];

/**
 * props for the DnsRecordTable component
 */
export type DnsRecordTableProps = Omit<
  TableProps<{
    /** The type of DNS record */
    type: string;
    /** The record name */
    name?: string;
    /** The record value */
    value?: string | ReactNode;
  }>,
  'columns' | 'rowKey'
>;

export const DnsRecordTable: React.FC<DnsRecordTableProps> = (props) => (
  // @deprecated TODO: https://transcend.height.app/T-19886 - remove ant
  <Table columns={DNS_RECORD_TABLE_COLUMNS} rowKey="value" {...props} />
);
