import { defineMessages } from '@transcend-io/internationalization';

export const columnConfigurationMessages = defineMessages(
  'admin-dashboard.components.ColumnsConfigurationButton.columnConfiguration',
  {
    button: {
      defaultMessage: 'Columns',
    },
    buttonPersonal: {
      defaultMessage: 'Columns: Personal',
    },
    checkboxLabel: {
      defaultMessage: 'Pick columns to show',
    },
    dynamicCheckboxLabel: {
      defaultMessage: 'Pick questions to show',
    },
    sortableListLabel: {
      defaultMessage: 'Drag items to change their order',
    },
    linkToAttributes: {
      defaultMessage: 'Add New Column Custom Field',
    },
    organizationTab: {
      defaultMessage: 'Organization',
    },
    personalTab: {
      defaultMessage: 'Personal',
    },
    successMessage: {
      defaultMessage: 'Preferences updated',
    },
    changeTabError: {
      defaultMessage: 'An error occurred while changing tabs',
    },
    permissionAlertHeader: {
      defaultMessage:
        "You don't have permission to edit organization-level preferences",
    },
  },
);
