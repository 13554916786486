import { QueryResult } from '@apollo/client';

import { endpoints, Preference } from '@main/access-control-types';
import { ResponseToType } from '@main/schema-utils';

export const transformRawPreference = (
  data: QueryResult<
    ResponseToType<typeof endpoints.preference.response>
  >['data'],
): QueryResult<{
  /** The transformed preference */
  preference: Preference;
}>['data'] => ({
  ...data,
  preference: {
    ...(data?.preference || {}),
    tables: data?.preference?.tables ? JSON.parse(data.preference.tables) : {},
  } as Preference,
});
