import {
  ApolloQueryResult,
  gql,
  QueryHookOptions,
  QueryResult,
  useQuery as useApolloQuery,
} from '@apollo/client';

import { Gql } from '@transcend-io/type-utils';

import { logger } from '@main/core-ui/src/logger';
import {
  AnySchema,
  EndpointParams,
  GqlObject,
  GraphQLResponse,
  mkQueryString,
  ParamsToType,
  QueryEndpoint,
  ResponseToType,
} from '@main/schema-utils';

/** The response from a `buildUseQuery` invocation. */
export type BuildUseQueryReturn<
  P extends EndpointParams,
  R extends GraphQLResponse | AnySchema,
> = (
  options?: QueryHookOptions<ResponseToType<R>, ParamsToType<P>>,
) => QueryResult<ResponseToType<R>>;

/**
 * Function to run a graphQL query
 */
export type QueryFunction<TParams extends EndpointParams, TResult> = (
  variables: ParamsToType<TParams>,
) => Promise<ApolloQueryResult<TResult>>;

/** The response from a `buildUseLazyQuery` invocation. */
export type BuildUseLazyQueryReturn<
  P extends EndpointParams,
  R extends GraphQLResponse | AnySchema,
> = (
  options?: QueryHookOptions<ResponseToType<R>, ParamsToType<P>>,
) => QueryFunction<P, ResponseToType<R>>;

/**
 * Create a GraphQL query hook from an endpoint definition
 * This is NOT lazy and will execute immediately on load
 *
 * @param endpoint - The GraphQL query endpoint definition
 * @param operationName - The GraphQL operation name
 * @param responseFields - Return a partial response
 * @returns A function that returns an Apollo query hook. The
 * returned hook is documented here:
 * https://www.apollographql.com/docs/react/api/react/hooks/#options
 */
export function buildUseQuery<
  TName extends string,
  TParams extends EndpointParams,
  TResult extends GraphQLResponse | AnySchema,
>(
  endpoint: QueryEndpoint<TName, TParams, TResult>,
  operationName?: string,
  responseFields?: GqlObject<TResult> | Gql<ResponseToType<TResult>>,
): BuildUseQueryReturn<TParams, TResult> {
  const gqlTagInput = gql`
    ${mkQueryString(endpoint, operationName, responseFields as any)}
  `;

  return (options) => {
    const { data, refetch, fetchMore, ...response } = useApolloQuery(
      gqlTagInput,
      options,
    );
    return {
      ...response,
      refetch: (options?: Partial<ParamsToType<TParams>>) =>
        refetch(options).then((res: any) => ({
          ...res,
          data: (res.data as any)?.[endpoint.name],
        })),
      // TODO: https://transcend.height.app/T-13291 - use default `fetchMore` function, migrate to use field policies
      // See https://stackoverflow.com/questions/62742379/apollo-3-pagination-with-field-policies
      fetchMore: (fetchMoreOptions: any) =>
        fetchMore({
          ...fetchMoreOptions,
          updateQuery: !fetchMoreOptions.updateQuery
            ? undefined
            : (prev, { fetchMoreResult, ...rest }) => {
                const response = fetchMoreOptions.updateQuery(
                  prev ? (prev as any)[endpoint.name] : prev,
                  {
                    ...rest,
                    fetchMoreResult: fetchMoreResult
                      ? (fetchMoreResult as any)[endpoint.name]
                      : fetchMoreResult,
                  },
                );
                if (!response) {
                  return response;
                }
                return { [endpoint.name]: response };
              },
        }) as any,
      data: (data as any)?.[endpoint.name],
    };
  };
}

/**
 * Create a GraphQL query hook from an endpoint definition
 * This is lazy and will not execute immediately on load
 *
 * @param endpoint - The GraphQL query endpoint definition
 * @param operationName - The GraphQL operation name
 * @param responseFields - Partial response
 * @returns A hook that calls apollo's useQuery but with type enforcements
 */
export function buildUseLazyQuery<
  TName extends string,
  TParams extends EndpointParams,
  TResult extends GraphQLResponse | AnySchema,
>(
  endpoint: QueryEndpoint<TName, TParams, TResult>,
  operationName?: string,
  responseFields?: GqlObject<TResult> | Gql<ResponseToType<TResult>>,
): BuildUseLazyQueryReturn<TParams, TResult> {
  const gqlTagInput = gql`
    ${mkQueryString(endpoint, operationName, responseFields as any)}
  `;

  return (options) => {
    const { refetch } = useApolloQuery<
      ResponseToType<TResult>,
      ParamsToType<TParams>
    >(gqlTagInput, { ...options, skip: true });
    return (params: ParamsToType<TParams>) =>
      refetch(params)
        .then((result: any) => {
          if (result.errors) {
            logger.error(result.errors);
          }
          const { data: dataInner, ...responseInner } = result;
          return {
            ...responseInner,
            data: (dataInner as any)?.[endpoint.name],
          };
        })
        .catch((err) => {
          logger.error(err);
          throw err;
        });
  };
}
