import { defineMessages } from '@transcend-io/internationalization';

export const filterManagerMessages = defineMessages(
  'ad-core-components.FilterManager.filterManager',
  {
    defaultPlaceholder: {
      defaultMessage: 'Click or type to search',
    },
    and: {
      defaultMessage: 'AND',
    },
    searchLabel: {
      defaultMessage: 'Search',
    },
  },
);
