import { defineMessages } from '@transcend-io/internationalization';

export const downloadZipButtonMessages = defineMessages(
  'ad-core-components.DownloadZipButton.downloadZipButton',
  {
    download: {
      defaultMessage: 'Download Report',
    },
    saving: {
      defaultMessage: 'Downloading files to disk...',
    },
    downloaded: {
      defaultMessage: 'Downloaded to disk!',
    },
    downloadAllFiles: {
      defaultMessage: 'Download All Files',
    },
  },
);
