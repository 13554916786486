import { TeamPreview, UserPreview } from '@main/access-control-types';
import { AttributeValue, PromptPreview } from '@main/attribute-types';
import { mkInput, mkOrder, mkType, SchemaToType } from '@main/schema-utils';

import { PromptGroupOrderField } from './enums';

export const PromptGroupTemplated = mkType({
  name: 'PromptGroupTemplated',
  comment:
    'Information about a prompt partial necessary for templating a prompt',
  fields: {
    id: {
      comment: 'The id of the prompt group',
      modelName: 'promptGroup',
      type: 'id',
    },
    title: {
      comment: 'The title of the prompt group',
      type: 'string',
    },
    description: {
      comment: 'The description of the prompt group',
      type: 'string',
    },
    createdAt: {
      comment: 'The date the prompt was created',
      type: 'Date',
    },
    updatedAt: {
      comment: 'The date the prompt was last updated',
      type: 'Date',
    },
    prompts: {
      comment: 'The list of prompts in the group',
      type: PromptPreview,
      list: true,
    },
  },
});

/** Override type */
export type PromptGroupTemplated = SchemaToType<typeof PromptGroupTemplated>;

export const PromptGroup = mkType({
  name: 'PromptGroup',
  comment: 'Group for a template that can be composed into a prompt',
  fields: {
    id: {
      comment: 'The id of the prompt group',
      modelName: 'promptGroup',
      type: 'id',
    },
    title: {
      comment: 'The title of the prompt group',
      type: 'string',
    },
    description: {
      comment: 'The description of the prompt group',
      type: 'string',
    },
    creator: {
      comment: 'The user who created this prompt someone',
      type: UserPreview,
      optional: true,
    },
    lastEditor: {
      comment: 'The user who last edited the prompt',
      type: UserPreview,
      optional: true,
    },
    createdAt: {
      comment: 'The date the prompt was created',
      type: 'Date',
    },
    updatedAt: {
      comment: 'The date the prompt was last updated',
      type: 'Date',
    },
    prompts: {
      comment: 'The list of prompts in the group',
      type: PromptPreview,
      list: true,
    },
    teams: {
      comment: 'The team/s that the prompt is assigned to',
      type: TeamPreview,
      list: true,
    },
    owners: {
      comment: 'The user/s that the prompt is assigned to',
      type: UserPreview,
      list: true,
    },
    attributeValues: {
      comment: 'The attribute values used to label this assessment',
      type: AttributeValue,
      list: true,
    },
  },
});

/** Override type */
export type PromptGroup = SchemaToType<typeof PromptGroup>;

export const PromptGroupFiltersInput = mkInput({
  name: 'PromptGroupFiltersInput',
  comment: 'Inputs for filtering prompt groups',
  fields: {
    ids: {
      comment: 'The ids of the prompt groups',
      type: 'id',
      modelName: 'promptGroup',
      optional: true,
      list: true,
    },
    lastEditorIds: {
      comment: 'The ids of last editors',
      type: 'id',
      modelName: 'user',
      optional: true,
      list: true,
    },
    creatorIds: {
      comment: 'The ids of the users who created a prompt',
      type: 'id',
      modelName: 'user',
      optional: true,
      list: true,
    },
    promptIds: {
      comment: 'The ids of the prompts that are in the group',
      type: 'id',
      modelName: 'prompt',
      optional: true,
      list: true,
    },
    text: {
      comment: 'Filter by text (title of the prompt)',
      optional: true,
      type: 'string',
    },
    ownerIds: {
      comment: 'Filter by the assigned owner IDs',
      optional: true,
      type: 'id',
      modelName: 'user',
      list: true,
    },
    teamIds: {
      comment: 'Filter by the assigned team IDs',
      optional: true,
      type: 'id',
      modelName: 'team',
      list: true,
    },
  },
});

/** Override type */
export type PromptGroupFiltersInput = SchemaToType<
  typeof PromptGroupFiltersInput
>;

export const DeletePromptGroupsInput = mkInput({
  name: 'DeletePromptGroupsInput',
  comment: 'Input for deleting a group of group prompts',
  fields: {
    ids: {
      comment: 'The id of the group prompts to delete',
      type: 'id',
      modelName: 'promptGroup',
      list: true,
    },
  },
});

/** Override type */
export type DeletePromptGroupsInput = SchemaToType<
  typeof DeletePromptGroupsInput
>;

export const CreatePromptGroupInput = mkInput({
  name: 'CreatePromptGroupInput',
  comment: 'Input for creating a prompt group',
  fields: {
    title: {
      comment: 'The new title of the prompt group',
      type: 'string',
      optional: true,
    },
    description: {
      comment: 'The description of the prompt group ',
      type: 'string',
      optional: true,
    },
    promptIds: {
      comment: 'The ids of the prompt in the group',
      type: 'id',
      modelName: 'prompt',
      list: true,
      optional: true,
    },
    promptTitles: {
      comment: 'The titles of the prompts in the group',
      type: 'string',
      list: true,
      optional: true,
    },
    ownerIds: {
      comment:
        'The unique ids of the users to assign as owners of this prompt group',
      modelName: 'user',
      type: 'id',
      optional: true,
      list: true,
    },
    ownerEmails: {
      comment:
        'The unique emails of the users to assign as owners of this prompt group',
      type: 'string',
      optional: true,
      list: true,
    },
    teamIds: {
      comment:
        'The ids of the teams that should be responsible for this prompt group',
      type: 'id',
      modelName: 'team',
      list: true,
      optional: true,
    },
    teamNames: {
      comment:
        'The names of the teams that should be responsible for this prompt group',
      type: 'string',
      list: true,
      optional: true,
    },
  },
});

/** Override type */
export type CreatePromptGroupInput = SchemaToType<
  typeof CreatePromptGroupInput
>;

export const UpdatePromptGroupInput = mkInput({
  name: 'UpdatePromptGroupInput',
  comment: 'Input for updating a Prompt group',
  fields: {
    id: {
      comment: 'The id of the prompt group',
      modelName: 'promptGroup',
      type: 'id',
    },
    title: {
      comment: 'The new title of the prompt group',
      type: 'string',
      optional: true,
    },
    description: {
      comment: 'The description of the prompt group ',
      type: 'string',
      optional: true,
    },
    promptIds: CreatePromptGroupInput.fields.promptIds,
    promptTitles: CreatePromptGroupInput.fields.promptTitles,
    ownerIds: CreatePromptGroupInput.fields.ownerIds,
    ownerEmails: CreatePromptGroupInput.fields.ownerEmails,
    teamIds: CreatePromptGroupInput.fields.teamIds,
    teamNames: CreatePromptGroupInput.fields.teamNames,
  },
});

/** Override type */
export type UpdatePromptGroupInput = SchemaToType<
  typeof UpdatePromptGroupInput
>;

export const UpdatePromptGroupsInput = mkInput({
  name: 'UpdatePromptGroupsInput',
  comment: 'Input for bulk updating one or more prompt groups',
  fields: {
    promptGroups: {
      comment:
        'List of prompt groups with the properties that should be updated for each',
      type: UpdatePromptGroupInput,
      list: true,
    },
  },
});

/** Override type */
export type UpdatePromptGroupsInput = SchemaToType<
  typeof UpdatePromptGroupsInput
>;

/** Order for a promptGroups query */
export const PromptGroupOrder = mkOrder(
  PromptGroup.name,
  PromptGroupOrderField,
);
