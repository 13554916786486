import { statusDescriptionMessages, statusTitleMessages } from './messages';
import type { RequestStatusConfig } from './types';

/**
 * ## View Categories
 *
 * The data subject request is in a state where the download zip is not exposed
 * to the Privacy Center and the user cannot access the files, but only what data
 * collection categories are affiliated.
 */
export const VIEW_CATEGORIES_STATUS: RequestStatusConfig = {
  color: 'success',
  icon: 'file-folder-stack',
  canDownload: false,
  canViewByDataSubject: true,
  isClosed: true,
  isSuccess: true,
  messages: {
    description: statusDescriptionMessages.VIEW_CATEGORIES,
    title: statusTitleMessages.VIEW_CATEGORIES,
  },
  primaryOnly: true,
  transitions: [],
};
