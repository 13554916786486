import {
  MenuTab,
  ReactComponent,
  Row,
  SideMenu,
  SideMenuProps,
} from '@main/core-ui';
import { Optionalize } from '@transcend-io/type-utils';
import React, { useEffect, useState } from 'react';
import { MessageDescriptor } from 'react-intl';
import { useLocation } from 'react-router-dom';

import {
  DefaultContentWrapper,
  HorizontalContentWrapper,
  HorizontalMenuWrapper,
} from './wrappers';

/**
 * Component mapper
 */
export interface MappedComponent {
  /** Header message of the component */
  header: MessageDescriptor;
  /** Title of component (used as page title or tab title) */
  title?: MessageDescriptor;
  /** Descriptor message */
  info?: MessageDescriptor;
  /** Expose a logo */
  logo?: string;
  /** Provide the visual id for the tab manually, else it is computed from `header` */
  visualId?: string;
  /** Child pages */
  children?: (MessageDescriptor & {
    /** Specify visual id */
    visualId?: string;
  })[];
}

/**
 * The mapping from tab to component
 */
export type ComponentMap = { [k in string]: MappedComponent };

/**
 * Props for SideMenuPage
 */
export interface SideMenuPageProps<
  TTabProps extends { [k in string]: any } = { [k in string]: any },
> extends Omit<SideMenuProps, 'tabs' | 'selected' | 'onSelectChange'> {
  /** Mapping from tab name to display component */
  tabMappings: ComponentMap;
  /** The default menu */
  defaultMenu?: string;
  /** The contents to display on every page */
  children?: React.ReactNode;
  /** The header to display for all nodes */
  header?: React.ReactNode;
  /** The max height of the menu */
  maxHeight?: string;
  /** The menu style */
  menuStyle?: React.CSSProperties;
  /** The props to pass to all tab components */
  tabProps?: TTabProps;
  /** When true, menu is displayed across the top */
  top?: boolean;
  /** being passed through when should not */
  dispatch?: any;
  /** The react component that wraps around the content children */
  ContentWrapper?: ReactComponent;
}

/**
 * Extend the side menu page
 */
export type ExtendSideMenuPage = Optionalize<SideMenuPageProps, 'tabMappings'>;

/**
 * Extract the first menu
 *
 * @param tabs -The tabs to determine selected from
 */
const getDefaultSelected = (tabs: MenuTab[]): string =>
  tabs
    .map((tab) =>
      tab.children
        ? tab.children.map(({ visualId }) => visualId)
        : [tab.visualId],
    )
    .flat()[0] || '';

/**
 * Convert a tabMappings to side menu tab
 *
 * @param lookup - The lookup to convert
 * @returns The side menu tabs
 */
const constructTabs = (
  lookup: ComponentMap,
): {
  /** The tab mappings to use (include children lookup) */
  lookupComponent: { [k in string]: MappedComponent };
  /** The menu tab */
  tabs: MenuTab[];
} => {
  // Make a copy
  const lookupComponent = { ...lookup };

  // Iterate over tabs
  const tabs = Object.entries(lookup).map(([tabKey, Comp]) => {
    // Determine the id
    const visualId = Comp.visualId || tabKey;
    lookupComponent[visualId] = Comp;

    // Add children to lookup
    if (Comp.children && Comp.children.length > 0) {
      const children = Comp.children.map((child) => {
        const childVisualId =
          child.visualId || (String(child.id).split('.').pop() as string);
        const fullChildVisualId = `${visualId}.${childVisualId}`;
        lookupComponent[fullChildVisualId] = Comp;
        return { ...child, id: String(child.id), visualId: fullChildVisualId };
      });
      return {
        id: String(Comp.header.id),
        title: Comp.header,
        children,
        logo: Comp.logo,
        visualId,
      };
    }

    // No children
    return {
      id: String(Comp.header.id),
      title: Comp.header,
      logo: Comp.logo,
      visualId,
    };
  });

  return { tabs, lookupComponent };
};

/**
 * A page that has a side menu
 */
export function SideMenuPage({
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  dispatch,
  tabMappings,
  defaultMenu,
  minHeight = '600px',
  maxHeight = '1400px',
  tabProps = {},
  menuStyle = {},
  header,
  top,
  children,
  ContentWrapper = DefaultContentWrapper,
  ...sideMenuProps
}: SideMenuPageProps): JSX.Element {
  const [isDesktop, setIsDesktop] = useState(!top);
  const location = useLocation();
  const [tabs, setTabs] = useState<MenuTab[]>([]);
  const [selectedMenu, setSelectedMenu] = useState('');
  const [lookupComponent, setLookupComponent] = useState<{
    [k in string]: MappedComponent;
  }>({});

  // The component to use to display the content
  const DisplayComponent: any = lookupComponent[selectedMenu.replace('#', '')];

  useEffect(() => {
    // Construct the tabs
    const createdTabs = constructTabs(tabMappings);

    // Determine the selected menu
    const newSelectedMenu =
      location.hash.slice(1) ||
      defaultMenu ||
      getDefaultSelected(createdTabs.tabs);

    // Construct the state
    setTabs(createdTabs.tabs);
    setLookupComponent(createdTabs.lookupComponent);
    setSelectedMenu(newSelectedMenu);
  }, [tabMappings, location]);

  useEffect(() => {
    if (top) {
      setIsDesktop(false);
      return undefined;
    }

    /**
     * Handler to call on window resize
     */
    function handleResize(): void {
      // Set window width/height to state
      setIsDesktop(window.innerWidth > 870);
    }

    // Add event listener
    window.addEventListener('resize', handleResize);

    // Call handler right away so state gets updated with initial window size
    handleResize();

    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleResize);
  }, [top]); // Empty array ensures that effect is only run on mount

  // The content
  const content = (
    <ContentWrapper info={DisplayComponent?.info}>
      {children}
      {!children && DisplayComponent && <DisplayComponent {...tabProps} />}
    </ContentWrapper>
  );

  // Props for vertical menu
  const menuProps: React.ComponentProps<typeof SideMenu> = {
    onSelectChange: ({ newMenu, newTab }) => {
      if (!newTab) {
        setSelectedMenu(newMenu);
      }
    },
    selected: selectedMenu,
    tabs,
    style: menuStyle,
    maxHeight: maxHeight || undefined,
    minHeight: top ? '' : minHeight,
    values: tabProps,
    noHash: false,
    ...sideMenuProps,
  };

  // If it is desktop display the vertical content wrapper
  // else display the horizontal content wrapper
  return isDesktop ? (
    <React.Fragment>
      <div>
        {header && (
          <Row padding="40px 0" full>
            {header}
          </Row>
        )}
        <HorizontalMenuWrapper>
          <SideMenu
            {...menuProps}
            mode="horizontal"
            minHeight=""
            alwaysDropdown={false}
          />
        </HorizontalMenuWrapper>
        {content}
      </div>
    </React.Fragment>
  ) : (
    <React.Fragment>
      <div>
        <Row align="middle">
          <HorizontalMenuWrapper>
            <SideMenu
              {...menuProps}
              mode="horizontal"
              minHeight=""
              alwaysDropdown={false}
            />
          </HorizontalMenuWrapper>
        </Row>
        {header && (
          <Row padding="40px 0" full>
            {header}
          </Row>
        )}
        <HorizontalContentWrapper>{content}</HorizontalContentWrapper>
      </div>
    </React.Fragment>
  );
}

export default SideMenuPage;
