/**
 * Date ranges for the analytics. This represents a range of days (i.e.
 * start time to end time will be 30 days if you use DateRange.ThirtyDays.)
 *
 * Using these values, you can later map to a bin interval that seems
 * reasonable for the amount of days in the range. For instance:
 *    DateRange.SevenDays  => '1d'
 *    DateRange.ThirtyDays => '1d'
 *    DateRange.OneYear    => '30d'
 */
export enum DateRange {
  SevenDays = 'SevenDays',
  ThirtyDays = 'ThirtyDays',
  OneYear = 'OneYear',
}
