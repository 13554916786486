import { defineMessages } from '@transcend-io/internationalization';

export const attributeManagementMessages = defineMessages(
  'admin-dashboard.Infrastructure.AttributeManagement.attributeManagement',
  {
    header: {
      defaultMessage: 'Custom Fields',
    },
    pageDescription: {
      defaultMessage: `
        Use custom fields to categorize and filter tables any way you like.
        Default custom fields can be extended to suit your needs,
        and you can also define custom custom fields specific to your organization's needs.
      `,
    },
  },
);
