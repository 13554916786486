import * as t from 'io-ts';

import { valuesOf } from '@transcend-io/type-utils';

import { dbModelId } from '@main/schema-utils';

import { ActionItemSubscriptionNotificationMethod } from './schema/enums';

/**
 * The expected query parameters from RelayState
 */
export const RelayState = t.intersection([
  t.type({
    /** The email of the user */
    email: t.string,
    /** The diffie-hellman public key to forward to sombra */
    publicKey: t.string,
    /** Redirect to this page */
    redirectTo: t.string,
  }),
  t.partial({
    /** The id of the user logging in */
    userId: dbModelId('user'),
  }),
]);

/** Type override */
export type RelayState = t.TypeOf<typeof RelayState>;

/**
 * A mapping of action item subscription methods to the whitelisted destinations
 */
export const ActionItemSubscriptionWhitelist = t.union([
  t.undefined,
  t.array(
    t.type({
      /** The display name */
      name: t.string,
      /** The whitelisted id/email */
      identifier: t.string,
      /** The notification method */
      notificationMethod: valuesOf(ActionItemSubscriptionNotificationMethod),
    }),
  ),
]);

/** Type override */
export type ActionItemSubscriptionWhitelist = t.TypeOf<
  typeof ActionItemSubscriptionWhitelist
>;
