import qs from 'query-string';
import { useLocation } from 'react-router-dom';

import { AdminDashboardPrivateMiscPath } from '@main/access-control-types';
import { useQueryParams, useRedirect } from '@main/core-ui';

/**
 * A helper function for re-directing to login page
 * with bounce back to current page
 *
 * @returns A function that will initiate re-login
 */
export function useRedirectToReLogin(): () => void {
  const location = useLocation();
  const redirect = useRedirect();
  const queryParams = useQueryParams();

  return () =>
    redirect(
      `${AdminDashboardPrivateMiscPath.ReLogin}?${qs.stringify({
        redirectTo: queryParams.redirectTo || JSON.stringify(location),
      })}`,
    );
}
