/**
 * The fields that an pathfinder can be ordered on
 */
export enum PathfinderOrderField {
  /** The name of the pathfinder */
  Name = 'name',
  /** The URL of the pathfinder */
  Url = 'url',
  /** The age of the pathfinder */
  CreatedAt = 'createdAt',
  /** The last updated time */
  UpdatedAt = 'updatedAt',
}
