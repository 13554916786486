import { ConditionalContainer } from '@main/ad-core-components';
import {
  FlexRowCenterVertical,
  ItemsWithOverflow,
  ItemsWithOverflowProps,
  StyleUtils,
  Tooltip,
} from '@main/core-ui';
import type { Requirize } from '@transcend-io/type-utils';
import React from 'react';

import { StyledOverflowIndicator, StyledTitle } from './wrappers';

/** Type of props for LogoIconList */
export type LogoIconListProps<T> = Requirize<
  Omit<
    ItemsWithOverflowProps<T>,
    'renderOverflowIndicator' | 'countAndMoreAsSegment' | 'includeAllInOverflow'
  >,
  'renderOverflowElement'
>;

export const LogoIconList = <
  T extends {
    /** Title to display in the popover and in the overflow list */
    title: string;
  },
>({
  items,
  limit = 3,
  popoverOnAll = true,
  renderElement,
  renderOverflowElement,
  ...props
}: LogoIconListProps<T>): JSX.Element => (
  <ItemsWithOverflow
    items={items.slice(0, 6)}
    limit={limit}
    popoverOnAll
    includeAllInOverflow
    countAndMoreAsSegment
    renderElement={(item, index, items) => (
      <ConditionalContainer
        condition={popoverOnAll}
        container={(children) => (
          <Tooltip title={item.title}>{children}</Tooltip>
        )}
      >
        {renderElement(item, index, items)}
      </ConditionalContainer>
    )}
    renderOverflowElement={(item, index, items) => (
      <FlexRowCenterVertical style={{ gap: StyleUtils.Spacing.sm }}>
        {renderOverflowElement(item, index, items)}
        <StyledTitle>{item.title}</StyledTitle>
      </FlexRowCenterVertical>
    )}
    renderOverflowIndicator={() => (
      <StyledOverflowIndicator>
        {/* Set fontSize on the inner span because the fontSize on the overflow indicator affects the left margin size */}
        <span style={{ fontSize: 12 }}>
          {/* add one to the limit because we are using countAndMoreAsSegment */}
          +{items.length - limit + 1}
        </span>
      </StyledOverflowIndicator>
    )}
    {...props}
  />
);
