import styled from 'styled-components';

import { BootstrapButton } from './BootstrapButton';

export const ButtonIcon = styled.div`
  display: flex;
  color: inherit;
`;

export interface StyledButtonProps {
  /** Whether the button is icon+text so we can tweak padding */
  $iconWithText?: boolean;
}

export const StyledButton = styled(BootstrapButton)<StyledButtonProps>`
  position: relative;
  font-family: neue-haas-grotesk-display, sans-serif;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1em;
  white-space: nowrap;
  border-radius: 6px;
  border-width: 1px;
  text-decoration: none !important;
  padding-left: ${({ $iconWithText }) => $iconWithText && '6px'};
  ${({ $noPadding }) => ($noPadding ? 'padding: 0px;' : '')}
  ${({ $noBorder }) => ($noBorder ? 'border: none;' : '')}
  color: ${({ variant, theme }) =>
    variant === 'danger'
      ? theme.colors.white
      : variant?.includes('secondary') && theme.colors.transcendNavy2};

  &.btn-sm {
    min-height: 0px;
  }

  &.disabled,
  &:disabled {
    ${({ theme, variant }) =>
      variant === 'outline-secondary'
        ? `color: ${theme.colors.transcendNavy2}`
        : ''};
  }

  /*
   * For best practices, do not customize pseudo-classes' background color styles here and
   * instead add a new variant and theme-color in @core-ui/bootstrap-theme.scss so that it
   * has an explicit base color for Bootstrap to calculate off of.
   */

  :hover,
  :active {
    color: ${({ variant, theme }) =>
      variant?.includes('danger') && theme.colors.white};
  }
  :focus {
    color: ${({ variant, theme }) =>
      // background color for 'danger-secondary' on focus is white so we don't want white text there
      variant === 'danger' && theme.colors.white};
  }
`;

export const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px;
  margin-bottom: 6px;

  p {
    font-weight: 600;
    font-size: 16px;
    margin: 0;
  }
`;

export const StyledVerticalBar = styled.div`
  width: 2px;
  height: 17px;
  background-color: ${({ theme }) => theme.colors.transcend3};
  border-radius: 4px;
  margin-right: 7px;
`;

export const LoadingSpinnerWrapper = styled.span`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;
  color: inherit;
`;

export const HiddenChild = styled.span`
  visibility: hidden;
`;

export const ButtonWithDropdownMenuWrapper = styled.span`
  display: flex;

  .dropstart {
    display: flex;

    ${ButtonIcon} {
      padding: 0 2px;
    }
  }
`;
