import { defineMessages } from '@transcend-io/internationalization';

export const promptBadgeMessages = defineMessages(
  'admin-dashboard.Prompts.components.PromptBadge.promptBadge',
  {
    promptRunCount: {
      defaultMessage: '{count} {count, plural, one {run} other {runs}}',
    },
  },
);
