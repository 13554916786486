/**
 * Types of data flows
 */
export enum DataFlowType {
  URL = 'URL',
  QueryParam = 'QUERY_PARAM',
  RegExp = 'REGEX',
}

/**
 * Fields by which you can use to order data flows
 */
export enum DataFlowOrderField {
  /** The value of this data flow */
  Value = 'value',
  /** When the data flow was created */
  CreatedAt = 'createdAt',
  /** The time the data flow was updated */
  UpdatedAt = 'updatedAt',
  /** The number of occurrences of this data flow */
  Occurrences = 'occurrences',
  /** The SaaS tool associated with these data flows */
  Service = 'service',
}
