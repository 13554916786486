import React from 'react';
import { useFormContext } from 'react-hook-form';

import { Button, ButtonProps } from './Button';

interface IFormSubmitButtonProps extends ButtonProps {
  /** should the form require changes in order to submit? */
  requireChange?: boolean;
}

export const FormSubmitButton = ({
  variant = 'primary',
  type = 'submit',
  loading = false,
  requireChange,
  disabled,
  ...props
}: IFormSubmitButtonProps): JSX.Element => {
  const { formState } = useFormContext();
  return (
    <Button
      variant={variant}
      type={type}
      disabled={
        !formState.isValid ||
        (requireChange && !formState.isDirty) ||
        !!loading ||
        disabled
      }
      aria-busy={loading ? 'true' : 'false'}
      loading={loading}
      {...props}
    />
  );
};
