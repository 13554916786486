/**
 * the list of feature flags
 */
export enum FeatureFlagKey {
  MnBased = 'mn-based',
  DisableIntegrationStatusMessages = 'disable-integration-status-messages',
  DataSiloRateLimiting = 'data-silo-rate-limiting',
  ActionItemSlackNotifications = 'action-item-slack-notifications',
  ManagedConsentDatabase = 'managed-consent-database',
  OccurrencesInDataFlowsAndCookies = 'occurrences-in-data-flows-and-cookies',
  S3ConsolidatedRequests = 's3-consolidated-requests',
  AssessmentsV2 = 'AssessmentsV2',
  CustomTcfMessages = 'custom-tcf-messages',
  ConsentServices = 'consent-services',
  CrossReferenceIdentifierMappingUI = 'enricher-form-cross-reference-identifier-ui',
  ShowPCRequestsProcessedStatsSettings = 'show-pc-requests-processed-stats-settings',
  EnableWorkflowsV2Ui = 'enable-workflows-v2-ui',
  EnableMultipleRulesOnConsentWorkflow = 'enable-multiple-rules-on-consent-workflow',
  SaleOfInfoToggle = 'sale-of-info-toggle',
  IdentifiersMigrationAllowedOrgIds = 'identifiers-migration-allowed-org-ids',
  ShowNewUserPreferencesView = 'user-preferences-table-v-2',
  EnableExperiencesNotIn = 'enable-experiences-not-in',
  CreatePikoSombra = 'create-piko-sombra',
}

/* eslint-disable jsdoc/require-jsdoc */
/**
 * The type of the returned feature flags
 */
export interface FeatureFlags {
  [FeatureFlagKey.MnBased]: boolean;
  [FeatureFlagKey.DisableIntegrationStatusMessages]: boolean;
  [FeatureFlagKey.DataSiloRateLimiting]: boolean;
  [FeatureFlagKey.ActionItemSlackNotifications]: boolean;
  [FeatureFlagKey.ManagedConsentDatabase]: boolean;
  [FeatureFlagKey.OccurrencesInDataFlowsAndCookies]: boolean;
  [FeatureFlagKey.S3ConsolidatedRequests]: boolean;
  [FeatureFlagKey.AssessmentsV2]: boolean;
  [FeatureFlagKey.CustomTcfMessages]: boolean;
  [FeatureFlagKey.ConsentServices]: boolean;
  [FeatureFlagKey.CrossReferenceIdentifierMappingUI]: boolean;
  [FeatureFlagKey.ShowPCRequestsProcessedStatsSettings]: boolean;
  [FeatureFlagKey.EnableWorkflowsV2Ui]: boolean;
  [FeatureFlagKey.EnableMultipleRulesOnConsentWorkflow]: boolean;
  [FeatureFlagKey.SaleOfInfoToggle]: boolean;
  [FeatureFlagKey.IdentifiersMigrationAllowedOrgIds]: string[];
  [FeatureFlagKey.ShowNewUserPreferencesView]: boolean;
  [FeatureFlagKey.EnableExperiencesNotIn]: boolean;
  [FeatureFlagKey.CreatePikoSombra]: boolean;
}
/* eslint-enable jsdoc/require-jsdoc */
