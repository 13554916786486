import { Bool } from './Bool';
import { Collapse } from './Collapse';
import { DataReady } from './DataReady';
import { Download } from './Download';
import { Dropdown } from './Dropdown';
import { Erased } from './Erased';
import { Erasing } from './Erasing';
import { Expand } from './Expand';
import { Failure } from './Failure';
import { LongText } from './LongText';
import { MultipleChoice } from './MultipleChoice';
import { Num } from './Num';
import { RequestCanceled } from './RequestCanceled';
import { RequestProcessing } from './RequestProcessing';
import { RequestSent } from './RequestSent';
import { ShortText } from './ShortText';

/**
 * Custom icons
 */
export const CUSTOM_ICONS = {
  'custom-boolean': Bool,
  'custom-shortText': ShortText,
  'custom-longText': LongText,
  'custom-num': Num,
  'custom-dropdown': Dropdown,
  'custom-multipleChoice': MultipleChoice,
  'custom-dataReady': DataReady,
  'custom-requestCanceled': RequestCanceled,
  'custom-requestProcessing': RequestProcessing,
  'custom-requestSent': RequestSent,
  'custom-erased': Erased,
  'custom-erasing': Erasing,
  'custom-failure': Failure,
  'custom-collapse': Collapse,
  'custom-expand': Expand,
  'custom-download': Download,
};

/**
 * Lookup icon
 */
export type CustomIcon = keyof typeof CUSTOM_ICONS;
