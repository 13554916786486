import { AgentPreview } from '@main/attribute-types';
import {
  buildUseQuery,
  formatErrorMessage,
  Icon,
  ReactSelect,
  ReactSelectExtendedProps,
} from '@main/core-ui';
import { endpoints } from '@main/prompt-types';
import { ID } from '@main/schema-utils';
import React, { useCallback, useEffect, useState } from 'react';
import { useTheme } from 'styled-components';

const NODES = {
  id: null,
  name: null,
} as const;

/**
 * Selected prompt
 */
export type SelectedAgent = Pick<AgentPreview, keyof typeof NODES> & {
  /** Whether prompt is newly created prompt or existing prompt */
  isNew?: boolean;
};

/** An option for the select component */
export interface SelectAgentOption {
  /** The value for the option */
  value: SelectedAgent;
  /** The label for the option */
  label: string;
  /** The logo for the option */
  logo: JSX.Element;
}

const useAgents = buildUseQuery(endpoints.agents, 'SelectAgents', {
  nodes: NODES,
});

const isId = (value: SelectedAgent | ID<'agent'>): value is ID<'agent'> =>
  typeof value === 'string';

/** Props for the `SelectAgent` component */
export interface SelectAgentProps
  extends Omit<ReactSelectExtendedProps<boolean, SelectedAgent>, 'onChange'> {
  /** The currently selected values */
  value?: SelectedAgent[] | ID<'agent'>[];
  /** On change handler */
  onChange?: (agents: SelectedAgent[]) => void;
}

const isSelectedAgentOption = (
  value: SelectAgentOption | undefined,
): value is SelectAgentOption => !!value;

export const SelectAgents: React.FC<SelectAgentProps> = ({
  isLoading,
  isMulti = true,
  disabled = false,
  value: initialValues,
  onChange,
  ...props
}) => {
  const { data, loading, error } = useAgents({
    fetchPolicy: 'cache-and-network',
  });
  const theme = useTheme();

  const toOption = useCallback(
    (value?: SelectedAgent): SelectAgentOption | undefined =>
      !value
        ? undefined
        : {
            value,
            label: value.name,
            logo: (
              <Icon type="assessment-template" color={theme.colors.transcend} />
            ),
          },
    [theme],
  );
  const options = (data?.nodes ?? []).map(toOption);

  const [selected, setSelected] = useState<SelectAgentOption[]>();

  useEffect(() => {
    const newSelected = (
      initialValues
        ? initialValues.map((value) =>
            isId(value)
              ? toOption((data?.nodes || []).find(({ id }) => id === value))
              : toOption(value),
          )
        : []
    ).filter(isSelectedAgentOption);

    setSelected(newSelected);
  }, [data, initialValues, toOption]);

  return (
    <ReactSelect<boolean, SelectAgentOption>
      options={options.filter(isSelectedAgentOption)}
      value={selected}
      isMulti={isMulti}
      isLoading={isLoading || loading}
      getOptionLogo={({ logo }) => logo}
      noOptionsMessage={
        error ? () => formatErrorMessage(error.message) : undefined
      }
      getOptionLabel={({ label }) => label}
      getOptionValue={({ value }) => value.id}
      isClearable={false}
      closeMenuOnSelect
      isDisabled={disabled}
      onChange={
        onChange
          ? (option) =>
              !option
                ? onChange([])
                : Array.isArray(option)
                  ? onChange(option.map(({ value }) => value))
                  : onChange([(option as SelectAgentOption).value as any])
          : undefined
      }
      {...props}
    />
  );
};

/**
 * Select a single prompt agent
 */
export const SelectAgent: React.FC<
  Omit<SelectAgentProps, 'value' | 'onChange'> & {
    /** Set the newly selected agents */
    onChange?: (agent: SelectedAgent) => void;
    /** Selected value */
    value?: SelectedAgent | ID<'agent'>;
  }
> = ({ onChange, value, ...props }) => (
  <SelectAgents
    {...props}
    isMulti={false}
    value={
      // default type is mixed array rather than array of one type or the other
      value ? ([value] as SelectedAgent[] | ID<'agent'>[]) : []
    }
    onChange={onChange ? ([value]) => onChange(value) : undefined}
  />
);
