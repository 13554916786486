import { Change } from 'diff';
import { transparentize } from 'polished';
import React from 'react';
import { useTheme } from 'styled-components';

import { CODE_FONT_STYLE } from '../CodeBlock';

const MAX_UNCHANGED_BLOCK_SIZE = 300;

export interface ChangeBlockProps {
  /** The changes */
  changes?: Change[];
  /** Whether this is the original value or the value being updated */
  isBefore: boolean;
  /** The other value we're comparing to */
  otherValue?: unknown;
}

export const ChangeBlock: React.FC<ChangeBlockProps> = ({
  changes,
  isBefore,
  otherValue,
}) => {
  const theme = useTheme();

  return (
    <>
      {changes
        ? changes.map((row, i) => {
            const isLastRow = i === changes.length - 1;
            const isFirstRow = i === 0;
            const isUnchanged = !row.added && !row.removed;
            return (
              <div
                key={i}
                style={{
                  margin: 0,
                  // Typescript thinks that color is always added below, but
                  // I respectfully disagree, which is why we use the weird
                  // object expansion syntax here
                  ...{ color: theme.colors.black },
                  ...CODE_FONT_STYLE,
                  overflow: 'none',
                  whiteSpace: 'pre-wrap',
                  wordBreak: 'break-word',
                  ...(otherValue !== undefined &&
                  ((isBefore && row.added) || (!isBefore && row.removed))
                    ? {
                        // add spacer to keep alignment
                        color: 'transparent',
                        userSelect: 'none',
                        backgroundColor: 'rgba(0,0,0,.05)',
                        ...(isBefore &&
                          row.added && { borderRadius: '0.3em 0 0 0.3em' }),
                        ...(!isBefore &&
                          row.removed && { borderRadius: '0 0.3em 0.3em 0' }),
                      }
                    : isUnchanged
                      ? {}
                      : isBefore
                        ? {
                            backgroundColor: transparentize(
                              0.65,
                              theme.colors.negativeHighlight,
                            ),
                            borderRadius: '0.3em 0 0 0.3em',
                          }
                        : {
                            backgroundColor: transparentize(
                              0.65,
                              theme.colors.positiveHighlight,
                            ),
                            borderRadius: '0 0.3em 0.3em 0',
                          }),
                }}
              >
                {isUnchanged &&
                // too long
                row.value.length > MAX_UNCHANGED_BLOCK_SIZE &&
                // not the only change (only when isCreated)
                changes.length > 1
                  ? `${
                      !isFirstRow
                        ? row.value.substring(0, MAX_UNCHANGED_BLOCK_SIZE / 2)
                        : ''
                    }\n...\n${
                      !isLastRow
                        ? row.value.substring(
                            row.value.length - MAX_UNCHANGED_BLOCK_SIZE / 2,
                          )
                        : ''
                    }`.trim()
                  : // otherwise show everything
                    row.value}
              </div>
            );
          })
        : '--'}
    </>
  );
};
