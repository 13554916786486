import { RequestEnricherStatus } from '@transcend-io/privacy-types';

import { Enricher } from '@main/datamap-types';
import { mkInput, mkOrder, mkType, SchemaToType } from '@main/schema-utils';

import {
  RequestEnricherOrderField,
  RequestEnricherSortAssociation,
} from './enums';
import { RequestIdentifier } from './RequestIdentifier';

export const RequestEnricher = mkType({
  name: 'RequestEnricher',
  comment: ' A request enrichment that needs to be responded to',
  fields: {
    id: {
      comment: 'The unique id of the RequestEnricher',
      modelName: 'RequestEnricher',
      type: 'id',
    },
    requestId: {
      comment: 'The id of the request in process',
      modelName: 'request',
      type: 'id',
    },
    enricher: {
      comment: 'The enricher configuration',
      type: Enricher,
    },
    error: {
      comment: 'The error message if one exists',
      optional: true,
      type: 'string',
    },
    status: {
      comment: 'The status of the request enricher',
      type: { RequestEnricherStatus },
    },
    inputRequestIdentifier: {
      comment: 'The request identifier that is being responded to',
      optional: true,
      type: RequestIdentifier,
    },
    isVerified: {
      comment:
        'Indicator as to whether the request enricher verified the identifier or not. ' +
        'Only provided when input.requestIdentifierId provided.',
      optional: true,
      type: 'boolean',
    },
    requestIdentifierCount: {
      comment:
        'The number of RequestIdentifiers found by this RequestEnrichers',
      type: 'int',
    },
    initialAuthMethod: {
      comment: `When sombra signs an identifier, there is an underlying authentication method that did the verification
      (Usually one of "SombraDataSubjectAuthMethod"). Only provided when input.requestIdentifierId provided.`,
      optional: true,
      type: 'string',
    },
    scheduledAt: {
      comment: 'When the next action is scheduled',
      type: 'Date',
    },
    pollId: {
      comment: 'Poll ID for polling results',
      type: 'string',
      optional: true,
    },
    verificationUrl: {
      comment: 'URL used for verification',
      type: 'string',
      optional: true,
    },
  },
});

/** Override type */
export type RequestEnricher = SchemaToType<typeof RequestEnricher>;

/** Order for a requestEnrichers query */
export const RequestEnrichersOrder = mkOrder(
  RequestEnricher.name,
  RequestEnricherOrderField,
  RequestEnricherSortAssociation,
);

/** Override type */
export type RequestEnrichersOrder = SchemaToType<typeof RequestEnrichersOrder>;

export const RequestEnrichersFiltersInput = mkInput({
  name: 'RequestEnrichersFiltersInput',
  comment: 'Input for searching for Enrichers',
  fields: {
    text: {
      comment: 'Filter by arbitrary text (search on: title)',
      type: 'string',
      optional: true,
    },
    statuses: {
      comment: 'Filter by status',
      type: { RequestEnricherStatus },
      optional: true,
      list: true,
    },
  },
});

/** Override type */
export type RequestEnrichersFiltersInput = SchemaToType<
  typeof RequestEnrichersFiltersInput
>;

export const RequestEnrichersInput = mkInput({
  name: 'RequestEnrichersInput',
  comment: 'Input for RequestEnrichers',
  fields: {
    requestId: {
      comment: 'The unique ID of the data subject request',
      modelName: 'request',
      type: 'id',
    },
    requestIdentifierId: {
      comment:
        'The unique ID of the RequestIdentifier to filter RequestEnrichers for',
      modelName: 'RequestIdentifier',
      type: 'id',
      optional: true,
    },
  },
});

/** Override type */
export type RequestEnrichersInput = SchemaToType<typeof RequestEnrichersInput>;
