import api from "!../../../../.yarn/__virtual__/style-loader-virtual-c514109e2f/0/cache/style-loader-npm-2.0.0-b9a5c4a2aa-129f8d7124.zip/node_modules/style-loader/dist/runtime/injectStylesIntoStyleTag.js";
            import content from "!!../../../../.yarn/__virtual__/css-loader-virtual-1089cffea8/0/cache/css-loader-npm-5.2.6-118c6d409e-592df1dcc7.zip/node_modules/css-loader/dist/cjs.js!../../../../.yarn/__virtual__/postcss-loader-virtual-ca7522143c/0/cache/postcss-loader-npm-7.0.0-e0a0c61fcd-a1d3de713e.zip/node_modules/postcss-loader/dist/cjs.js!./font-awesome.css";

var options = {};

options.insert = "head";
options.singleton = false;

var update = api(content, options);



export default content.locals || {};