import { RequestStatus } from '@transcend-io/privacy-types';
import { createEnum, filterEnum } from '@transcend-io/type-utils';

import { REQUEST_STATUSES } from './statuses';

/**
 * The statuses where a request can be placed on hold by an organization member
 *
 * This is true if request statuses that can transition to placed on hold state
 */
export const REQUEST_STATUSES_CAN_PLACE_ON_HOLD = filterEnum(
  REQUEST_STATUSES,
  ({ transitions }) => transitions.includes(RequestStatus.OnHold),
);

/**
 * The statuses that can be placed on hold key by status
 */
export const REQUEST_STATUS_CAN_PLACE_ON_HOLD = createEnum(
  REQUEST_STATUSES_CAN_PLACE_ON_HOLD,
);

/**
 * Check if a request can be placed on hold from its current state
 *
 * @param status - The status of the request to check
 * @returns True if the request can be placed on hold from its current state
 */
export function canPlaceOnHold(status: RequestStatus): boolean {
  return status in REQUEST_STATUS_CAN_PLACE_ON_HOLD;
}
