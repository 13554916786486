import {
  FormInput,
  FormStatus,
  FormSubmitButton,
  FormTextArea,
  mutationToFormStatusProps,
} from '@main/ad-core-components';
import {
  CreateSoftwareDevelopmentKitInput,
  endpoints,
  SoftwareDevelopmentKit,
} from '@main/code-scanning-types';
import {
  buildUseMutation,
  FlexColumnCenterHorizontal,
  Form,
  useForm,
  Validators,
} from '@main/core-ui';
import React from 'react';
import { useIntl } from 'react-intl';

import { FormSelectCodePackageType } from '../SelectCodePackageType';
import { newSoftwareDevelopmentKitFormMessages } from './messages';

const useCreateSoftwareDevelopmentKit = buildUseMutation(
  endpoints.createSoftwareDevelopmentKit,
);

export const NewSoftwareDevelopmentKitForm: React.FC<{
  /** On success callback */
  onSuccess?: (softwareDevelopmentKit: SoftwareDevelopmentKit) => void;
}> = ({ onSuccess }) => {
  const { formatMessage } = useIntl();
  const formMethods = useForm<CreateSoftwareDevelopmentKitInput>({});
  const [createSoftwareDevelopmentKit, createSoftwareDevelopmentKitResult] =
    useCreateSoftwareDevelopmentKit({
      refetchQueries: ['SoftwareDevelopmentKits'],
      awaitRefetchQueries: true,
    });

  return (
    <Form
      useFormMethods={formMethods}
      onSubmit={async (input) => {
        const { data } = await createSoftwareDevelopmentKit({
          variables: {
            input,
          },
        });
        if (onSuccess && data) {
          onSuccess(data.softwareDevelopmentKit);
        }
      }}
    >
      <FormInput
        id="name"
        name="name"
        label={newSoftwareDevelopmentKitFormMessages.nameLabel}
        rules={[Validators.REQUIRED]}
        required
      />
      <FormInput
        id="repositoryUrl"
        name="repositoryUrl"
        label={newSoftwareDevelopmentKitFormMessages.urlLabel}
        rules={[Validators.REQUIRED, Validators.URL]}
        required
      />
      <FormSelectCodePackageType
        id="codePackageType"
        name="codePackageType"
        label={newSoftwareDevelopmentKitFormMessages.codePackageTypeLabel}
        rules={[Validators.REQUIRED]}
        required
      />
      <FormTextArea
        id="description"
        name="description"
        label={newSoftwareDevelopmentKitFormMessages.descriptionLabel}
      />
      <FlexColumnCenterHorizontal>
        <FormSubmitButton loading={createSoftwareDevelopmentKitResult.loading}>
          {formatMessage(newSoftwareDevelopmentKitFormMessages.submitButton)}
        </FormSubmitButton>
        <FormStatus
          {...mutationToFormStatusProps(createSoftwareDevelopmentKitResult)}
          successMessage={newSoftwareDevelopmentKitFormMessages.submitSuccess}
        />
      </FlexColumnCenterHorizontal>
    </Form>
  );
};
