import { StyleUtils } from '@main/core-ui';
import React, { FC } from 'react';
import {
  BaseEdge,
  EdgeLabelRenderer,
  EdgeProps,
  getBezierPath,
  getSmoothStepPath,
  getStraightPath,
} from 'reactflow';
import { useTheme } from 'styled-components';

interface EdgeBuilderProps {
  /** the type of edge to use. Defaults to bezier */
  edgeType?: 'default' | 'smoothstep' | 'step' | 'straight';
  /** whether to hide the default background color on the label */
  hideBackgroundColor?: boolean;
}

export const buildEdgeWithReactLabel: (
  props?: EdgeBuilderProps,
) => FC<EdgeProps> =
  ({ edgeType = 'default', hideBackgroundColor } = {}) =>
  ({
    id,
    sourceX,
    sourceY,
    targetX,
    targetY,
    sourcePosition,
    targetPosition,
    data,
    ...rawProps
  }) => {
    const theme = useTheme();
    // pick the right edge type based on the built in path types
    const [edgePath, labelX, labelY] = {
      default: getBezierPath,
      smoothstep: getSmoothStepPath,
      step: getSmoothStepPath,
      straight: getStraightPath,
    }[edgeType]({
      sourceX,
      sourceY,
      sourcePosition,
      targetX,
      targetY,
      targetPosition,
    });

    return (
      <>
        <BaseEdge id={id} path={edgePath} {...rawProps} />
        <EdgeLabelRenderer>
          <div
            style={{
              position: 'absolute',
              transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`,
              pointerEvents: 'all',
              backgroundColor: hideBackgroundColor
                ? undefined
                : theme.colors.gray1,
              padding: StyleUtils.Spacing.xs,
            }}
            className="nodrag nopan"
          >
            {data?.label}
          </div>
        </EdgeLabelRenderer>
      </>
    );
  };
