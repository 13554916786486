import { PaginatedSelect } from '@main/ad-core-components';
import { ropaSelectCategoryMessages } from '@main/admin-dashboard/src/DataMap/components/SelectCategory/messages';
import { buildUseInfiniteScroll, SelectTagWrapper } from '@main/core-ui';
import { endpoints } from '@main/datamap-types';
import { DataCategoryType } from '@transcend-io/privacy-types';
import { gql } from '@transcend-io/type-utils';
import React, { useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { MenuPosition } from 'react-select';
import type { DefaultTheme } from 'styled-components';

import { DATA_CATEGORY_TYPE_COLORS } from '../../constants';
import { DataCategoriesItem, isSubCategory } from '../../DataCategories/types';
import { DataCategoryTitle } from '../DataCategoryTitle';

const UNIQ_BY_FIELDS = ['category', 'id'];

const useDataCategories = buildUseInfiniteScroll(
  endpoints.dataCategories,
  'DataCategories',
  gql`
    totalCount
    nodes {
      category
      description
      ... on DataSubCategory {
        id
        name
        isDefault
      }
    }
  `,
);

/** Props for the `SelectCategoryFilter` component */
export interface SelectCategoryFilterProps {
  /** The selected data subcategories for this subdatapoint */
  value?: DataCategoriesItem[];
  /** Whether the input is being updated/loading */
  loading?: boolean;
  /** Callback to fire when the category is updated */
  onChange: (selected?: DataCategoriesItem[]) => void;
  /** How to position the menu (see docs for React Select) */
  menuPosition?: MenuPosition;
  /** Whether or not to show the outline of this select component */
  showOutline?: boolean;
  /** When true, omit Unspecified filter option */
  omitUnspecified?: boolean;
}

const COLOR_MAP_WRAPPER =
  (theme: DefaultTheme) => (option: DataCategoriesItem) =>
    DATA_CATEGORY_TYPE_COLORS(theme)({ value: option.category });

export const SelectCategoryFilter: React.FC<SelectCategoryFilterProps> = ({
  value,
  loading,
  onChange,
  menuPosition = 'fixed',
  showOutline = false,
  omitUnspecified = false,
}) => {
  const { formatMessage } = useIntl();
  const [searchText, setSearchText] = useState('');

  const {
    data,
    loading: dataSubCategoriesLoading,
    fetchMore,
    error: dataSubCategoriesError,
  } = useDataCategories(
    {
      variables: {
        first: 100,
        filterBy: {
          ...(searchText ? { text: searchText } : {}),
        },
      },
    },
    { uniqByFields: UNIQ_BY_FIELDS },
  );

  const options = useMemo(() => {
    const newOptions = (data?.nodes as DataCategoriesItem[]) ?? [];
    return omitUnspecified
      ? newOptions.filter(
          (option) => option.category !== DataCategoryType.Unspecified,
        )
      : newOptions;
  }, [data?.nodes, omitUnspecified]);

  return (
    <SelectTagWrapper>
      <PaginatedSelect
        isMulti
        showOutline={showOutline}
        menuPosition={menuPosition}
        options={options}
        fetchMore={fetchMore}
        isQueryLoading={loading || dataSubCategoriesLoading}
        queryError={dataSubCategoriesError}
        onChange={(values) => onChange(values as DataCategoriesItem[])}
        getOptionValue={(value) =>
          isSubCategory(value) ? value.id : value.category
        }
        formatOptionPill={(option: DataCategoriesItem) => (
          <DataCategoryTitle
            name={isSubCategory(option) ? option.name : undefined}
            category={option.category}
          />
        )}
        colorMap={COLOR_MAP_WRAPPER}
        placeholder={formatMessage(ropaSelectCategoryMessages.placeholder)}
        value={value}
        onEndsTyping={(searchText) => setSearchText(searchText)}
      />
    </SelectTagWrapper>
  );
};
