import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { useFeatureFlags, useRedirect } from '@main/core-ui';

import { selectOrganization, selectUser } from '../../../Auth/App/selectors';
import { AD_NAV_ITEMS } from './constants';
import {
  getCtaPageHref,
  getNavItemHrefOrEvaluatedHref,
  isNavGateAllowed,
  isNavItemActive,
} from './helpers';

export const useNavGateAutoRedirect = (): void => {
  const user = useSelector(selectUser);
  const organization = useSelector(selectOrganization);
  const featureSet = organization?.featureSet;
  const featureFlags = useFeatureFlags();
  const { pathname: currentPath } = useLocation();
  const redirect = useRedirect();

  useEffect(() => {
    if (!user || !organization || !featureSet) {
      // don't do anything when no users or org present yet
      return;
    }

    const gateStatuses = AD_NAV_ITEMS.map((section) => {
      const sectionAllowed = isNavGateAllowed(
        section.gate,
        featureFlags,
        featureSet,
        organization.tier,
      );

      return section.items.map((item) => {
        const itemAllowed = isNavGateAllowed(
          item.gate,
          featureFlags,
          featureSet,
          organization.tier,
        );
        const hrefOrEvaluated = getNavItemHrefOrEvaluatedHref(
          item.to,
          user,
          organization,
        );

        return [
          {
            active: isNavItemActive(item, hrefOrEvaluated, currentPath),
            allowed: sectionAllowed && itemAllowed,
            ctaPage: item.gate?.ctaPage,
          },
          ...(item.childItems?.map((child) => {
            const childAllowed = isNavGateAllowed(
              child.gate,
              featureFlags,
              featureSet,
              organization.tier,
            );
            const childHrefOrEvaluated = getNavItemHrefOrEvaluatedHref(
              child.to,
              user,
              organization,
            );

            return {
              active: isNavItemActive(child, childHrefOrEvaluated, currentPath),
              allowed: sectionAllowed && itemAllowed && childAllowed,
              ctaPage: item.gate?.ctaPage ?? child.gate?.ctaPage,
            };
          }) ?? []),
        ];
      });
    }).flat(3);

    // check the first matching nav item gate status
    const [currentGateStatus] = gateStatuses.filter((status) => status.active);

    if (
      currentGateStatus &&
      !currentGateStatus.allowed &&
      currentGateStatus.ctaPage
    ) {
      const redirectHref = getCtaPageHref(currentGateStatus.ctaPage.id);

      // if not already on the page
      if (redirectHref && redirectHref !== currentPath) {
        redirect(redirectHref);
      }
    }
    // do nothing when no active page is matched
  }, [user, organization, currentPath, featureSet, featureFlags]);
};
