import React from 'react';
import { useTheme } from 'styled-components';

/**
 * Multiple choice icon
 */
export const MultipleChoice: React.FC = (props) => {
  const theme = useTheme();
  return (
    <svg
      width="42px"
      height="32px"
      viewBox="0 0 42 32"
      version="1.1"
      {...props}
    >
      <defs />
      <g
        id="Symbols"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Button/dashboardsquare"
          transform="translate(-43.000000, -37.000000)"
          fill={theme.colors.primary}
          fillRule="nonzero"
        >
          <g
            id="inputicon/multiple-choice"
            transform="translate(40.000000, 28.000000)"
          >
            <polygon
              id="Shape"
              points="8.121 21.879 3.879 26.121 18 40.242 44.121 14.121 39.879 9.879 18 31.758"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};
