import {
  buildUseQuery,
  formatErrorMessage,
  Icon,
  ReactSelect,
  ReactSelectExtendedProps,
} from '@main/core-ui';
import { endpoints, PathfinderPolicyPreview } from '@main/prompt-types';
import { ID } from '@main/schema-utils';
import React, { useCallback, useEffect, useState } from 'react';
import { useTheme } from 'styled-components';

const NODES = {
  id: null,
  name: null,
} as const;

/**
 * Selected pathfinder policy
 */
export type SelectedPathfinderPolicy = Pick<
  PathfinderPolicyPreview,
  keyof typeof NODES
> & {
  /** Whether pathfinder policy is newly created or existing */
  isNew?: boolean;
};

/** An option for the select component */
export interface SelectPathfinderPolicyOption {
  /** The value for the option */
  value: SelectedPathfinderPolicy;
  /** The label for the option */
  label: string;
  /** The logo for the option */
  logo: JSX.Element;
}

const usePathfinderPolicies = buildUseQuery(
  endpoints.pathfinderPolicies,
  'SelectPathfinderPolicies',
  {
    nodes: NODES,
  },
);

const isId = (
  value: SelectedPathfinderPolicy | ID<'pathfinderPolicy'>,
): value is ID<'pathfinderPolicy'> => typeof value === 'string';

/** Props for the `SelectPathfinderPolicy` component */
export interface SelectPathfinderPolicyProps
  extends Omit<
    ReactSelectExtendedProps<boolean, SelectedPathfinderPolicy>,
    'onChange'
  > {
  /** The currently selected values */
  value?: SelectedPathfinderPolicy[] | ID<'pathfinderPolicy'>[];
  /** On change handler */
  onChange: (policies: SelectedPathfinderPolicy[]) => void;
}

const isSelectedPathfinderPolicyOption = (
  value: SelectPathfinderPolicyOption | undefined,
): value is SelectPathfinderPolicyOption => !!value;

export const SelectPathfinderPolicies: React.FC<
  SelectPathfinderPolicyProps
> = ({
  isLoading,
  isMulti = true,
  disabled = false,
  value: initialValues,
  onChange,
  ...props
}) => {
  const { data, loading, error } = usePathfinderPolicies({
    fetchPolicy: 'cache-and-network',
  });
  const theme = useTheme();

  const toOption = useCallback(
    (
      value?: SelectedPathfinderPolicy,
    ): SelectPathfinderPolicyOption | undefined =>
      !value
        ? undefined
        : {
            value,
            label: value.name,
            logo: (
              <Icon type="assessment-template" color={theme.colors.transcend} />
            ),
          },
    [theme],
  );
  const options = (data?.nodes ?? []).map(toOption);

  const [selected, setSelected] = useState<SelectPathfinderPolicyOption[]>();

  useEffect(() => {
    const newSelected = (
      initialValues
        ? initialValues.map((value) =>
            isId(value)
              ? toOption((data?.nodes || []).find(({ id }) => id === value))
              : toOption(value),
          )
        : []
    ).filter(isSelectedPathfinderPolicyOption);

    setSelected(newSelected);
  }, [data, initialValues, toOption]);

  return (
    <ReactSelect<boolean, SelectPathfinderPolicyOption>
      options={options.filter(isSelectedPathfinderPolicyOption)}
      value={selected}
      isMulti={isMulti}
      isLoading={isLoading || loading}
      getOptionLogo={({ logo }) => logo}
      noOptionsMessage={
        error ? () => formatErrorMessage(error.message) : undefined
      }
      getOptionLabel={({ label }) => label}
      getOptionValue={({ value }) => value.id}
      isClearable={false}
      closeMenuOnSelect
      isDisabled={disabled}
      onChange={(option) =>
        !option
          ? onChange([])
          : Array.isArray(option)
            ? onChange(option.map(({ value }) => value))
            : onChange([(option as SelectPathfinderPolicyOption).value as any])
      }
      {...props}
    />
  );
};

/**
 * Select a single pathfinder policy
 */
export const SelectPathfinderPolicy: React.FC<
  Omit<SelectPathfinderPolicyProps, 'value' | 'onChange'> & {
    /** Set the newly selected policies */
    onChange: (policy: SelectedPathfinderPolicy) => void;
    /** Selected value */
    value?: SelectedPathfinderPolicy | ID<'pathfinderPolicy'>;
  }
> = ({ onChange, value, ...props }) => (
  <SelectPathfinderPolicies
    {...props}
    isMulti={false}
    value={
      // default type is mixed array rather than array of one type or the other
      value
        ? ([value] as SelectedPathfinderPolicy[] | ID<'pathfinderPolicy'>[])
        : []
    }
    onChange={([value]) => onChange(value)}
  />
);
