import { defineMessages } from '@transcend-io/internationalization';

export const forgotPasswordMessages = defineMessages(
  'admin-dashboard.Auth.ForgotPassword.forgotPassword',
  {
    checkInbox: {
      defaultMessage:
        'If you have already created an account with us, please check your inbox for an email to change your password. ' +
        'The link sent to you will be valid for 15 minutes. ' +
        'If you do not see the link in your inbox, try checking your spam folder.',
    },
    header: {
      defaultMessage: 'Reset Password',
    },
    submit: {
      defaultMessage: 'Send Reset Email',
    },
  },
);
