import { defineMessages } from '@transcend-io/internationalization';

export const pathfinderTabMessages = defineMessages(
  'admin-dashboard.Pathfinder.PathfinderTabs.pathfinderTab',
  {
    pathfinders: { defaultMessage: 'Pathfinders' },
    applications: { defaultMessage: 'Applications' },
    appUsers: { defaultMessage: 'Users' },
    agents: { defaultMessage: 'Assistants' },
    agentFunctions: { defaultMessage: 'Assistant Functions' },
    agentFiles: { defaultMessage: 'Assistant Files' },
    pathfinderPolicies: { defaultMessage: 'Policies' },
  },
);
