import { buildUseMutation } from '@main/core-ui';
import {
  EndpointParams,
  GqlObject,
  GraphQLResponse,
  MutationEndpoint,
  ParamsToType,
  ResponseToType,
} from '@main/schema-utils';
import React from 'react';

import { useInlineInput } from '../../hooks';
import { InlineTextInput, InlineTextInputProps } from '../InlineTextInput';

/** Props for the returned update component */
export interface InlineUpdateProps<
  TParams extends EndpointParams,
  TResult extends GraphQLResponse,
> extends InlineTextInputProps {
  /** ID for the component */
  id?: string;
  /** The value for the cell */
  value: string;
  /** Callback to pass the updated value to the endpoint */
  updateItemWithValue: (updatedValue: string) => {
    /** input variables */
    variables: ParamsToType<TParams>;
  };
  /** Callback to get the updated value from the data object returned by the update endpoint */
  getValueFromReturnedData: (
    data: ResponseToType<TResult> | null | undefined,
  ) => string | null | undefined;
}

/**
 *
 * Builds an inline text input component that includes a mutation endpoint
 *
 * @param endpoint - the mutation endpoint that will be updated with this text
 * @returns An inline text input component
 */
export function buildInlineTextInputWithUpdate<
  TName extends string,
  TParams extends EndpointParams,
  TResult extends GraphQLResponse,
>(
  endpoint: MutationEndpoint<TName, TParams, TResult>,
  operationName?: string,
  responseFields?: GqlObject<TResult>,
): React.FC<InlineUpdateProps<TParams, TResult>> {
  const useUpdateItem = buildUseMutation(
    endpoint,
    operationName,
    responseFields,
  );

  return ({
    id,
    value: initialValue,
    updateItemWithValue,
    getValueFromReturnedData,
    ...props
  }) => {
    const [updateItem] = useUpdateItem();
    const { value, setValue, onBlur } = useInlineInput({
      initialValue,
      onUpdate: (updatedValue) =>
        updateItem(updateItemWithValue(updatedValue)).then(
          // Cast to any to avoid excessively deep type instantiation
          ({ data, errors }: any) => ({
            data: getValueFromReturnedData(data),
            errors,
          }),
        ),
    });

    return (
      <InlineTextInput
        id={id}
        // null/undefined are invalid values for text inputs
        value={value ?? ''}
        showPlaceholder={false}
        onChange={(e: any) => {
          setValue(e.target.value);
        }}
        onBlur={onBlur}
        {...props}
      />
    );
  };
}
