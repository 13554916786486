import React from 'react';
import { useTheme } from 'styled-components';

import { StyledFlexRowCenterVertical } from './wrappers';

/**
 * The contents of a cell in the request upload view
 */
export const RowCell: React.FC<{
  /** Value */
  value: string | boolean;
  /** Whether valid */
  isValid: boolean | undefined;
  /** Max row height */
  maxRowHeight?: number;
}> = ({ isValid, value, maxRowHeight }) => {
  const theme = useTheme();
  return (
    <StyledFlexRowCenterVertical
      style={{
        ...(isValid === false
          ? { color: theme.colors.error }
          : { color: theme.colors.black }),
        ...(maxRowHeight
          ? {
              maxHeight: maxRowHeight,
              alignItems: 'flex-start',
              overflowY: 'auto',
            }
          : {}),
      }}
    >
      {typeof value === 'boolean' ? value.toString() : value}
    </StyledFlexRowCenterVertical>
  );
};
