import { PaginatedSelect } from '@main/ad-core-components';
import { buildUseInfiniteScroll, SelectTagWrapper } from '@main/core-ui';
import { endpoints } from '@main/datamap-types';
import { ProcessingPurpose } from '@transcend-io/privacy-types';
import { gql } from '@transcend-io/type-utils';
import React, { useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { MenuPosition } from 'react-select';
import type { DefaultTheme } from 'styled-components';

import { PurposeTitle } from '../../../components';
import { ropaSelectPurposeMessages } from '../../../components/SelectPurpose/messages';
import { PURPOSE_OF_PROCESSING_COLORS } from '../../../constants';
import {
  isPurposeSubCategory,
  PurposesItem,
} from '../../../Purposes/typeGuards';

const UNIQ_BY_FIELDS = ['purpose', 'id'];

const usePurposes = buildUseInfiniteScroll(
  endpoints.processingPurposes,
  endpoints.processingPurposes.name,
  gql`
    totalCount
    nodes {
      purpose
      description
      ... on PurposeSubCategory {
        id
        name
        isDefault
      }
    }
  `,
);

/** Props for the `SelectPurposeFilter` component */
export interface SelectPurposeFilterProps {
  /** The selected subcategories of processing purposes for this subdatapoint */
  value?: PurposesItem[];
  /** Whether the input is being updated/loading */
  loading?: boolean;
  /** Callback to fire when the purpose is updated */
  onChange: (selected?: PurposesItem[]) => void;
  /** How to position the menu (see docs for React Select) */
  menuPosition?: MenuPosition;
  /** Whether or not to show the outline of this select component */
  showOutline?: boolean;
  /** When true, omit Unspecified filter option */
  omitUnspecified?: boolean;
}

const COLOR_MAP_WRAPPER =
  (theme: DefaultTheme) =>
  ({ purpose }: PurposesItem) =>
    PURPOSE_OF_PROCESSING_COLORS(theme)({ purpose });

export const SelectPurposeFilter: React.FC<SelectPurposeFilterProps> = ({
  value,
  loading,
  onChange,
  menuPosition = 'fixed',
  showOutline = false,
  omitUnspecified = false,
}) => {
  const { formatMessage } = useIntl();
  const [searchText, setSearchText] = useState('');

  const {
    data,
    loading: dataPurposesLoading,
    fetchMore,
    error: dataPurposesError,
  } = usePurposes(
    {
      variables: {
        first: 100,
        filterBy: {
          ...(searchText ? { text: searchText } : {}),
        },
      },
    },
    { uniqByFields: UNIQ_BY_FIELDS },
  );

  const options = useMemo(() => {
    const newOptions = (data?.nodes as PurposesItem[]) ?? [];
    return omitUnspecified
      ? newOptions.filter(
          (option) => option.purpose !== ProcessingPurpose.Unspecified,
        )
      : newOptions;
  }, [data?.nodes, omitUnspecified]);

  return (
    <SelectTagWrapper>
      <PaginatedSelect
        isMulti
        showOutline={showOutline}
        menuPosition={menuPosition}
        options={options}
        fetchMore={fetchMore}
        isQueryLoading={loading || dataPurposesLoading}
        queryError={dataPurposesError}
        onChange={(values) => onChange(values as PurposesItem[])}
        getOptionValue={(value) =>
          isPurposeSubCategory(value) ? value.id : value.purpose
        }
        formatOptionPill={(option: PurposesItem) => (
          <PurposeTitle
            name={isPurposeSubCategory(option) ? option.name : undefined}
            purpose={option.purpose}
          />
        )}
        colorMap={COLOR_MAP_WRAPPER}
        placeholder={formatMessage(ropaSelectPurposeMessages.placeholder)}
        value={value}
        onEndsTyping={(searchText) => setSearchText(searchText)}
      />
    </SelectTagWrapper>
  );
};
