import { AssessmentFormStatus } from '@transcend-io/privacy-types';

import { UserPreview } from '@main/access-control-types';
import { mkInput, mkType, SchemaToType } from '@main/schema-utils';

export const AssessmentFormComment = mkType({
  name: 'AssessmentFormComment',
  comment: 'A comment to an assessment form.',
  fields: {
    id: {
      comment: 'The id of the assessment form comment',
      type: 'id',
      modelName: 'assessmentFormComment',
    },
    content: {
      comment: 'The content of the assessment form comment',
      type: 'string',
    },
    author: {
      comment: 'The user who wrote this comment',
      type: UserPreview,
      optional: true,
    },
    updatedAt: {
      comment: 'The date the comment was last updated',
      type: 'Date',
      optional: true,
    },
    createdAt: {
      comment: 'The date the comment was created',
      type: 'Date',
      optional: true,
    },
    formStatus: {
      comment: 'The change in status associated with the comment',
      type: { AssessmentFormStatus },
      optional: true,
    },
    assessmentEventId: {
      comment: 'The ID of the event that the comment refers to',
      type: 'id',
      modelName: 'assessmentEvent',
      optional: true,
    },
  },
});

/** Override type */
export type AssessmentFormComment = SchemaToType<typeof AssessmentFormComment>;

export const AssessmentFormCommentFiltersInput = mkInput({
  name: 'AssessmentFormCommentFiltersInput',
  comment: 'Inputs for filtering a list of assessment form comments',
  fields: {
    ids: {
      comment: 'The ids of the assessment form comments',
      type: 'id',
      modelName: 'assessmentFormComment',
      optional: true,
      list: true,
    },
    text: {
      type: 'string',
      comment: 'Filter the comments by the text present in its content',
      optional: true,
    },
    authorIds: {
      comment: 'The ids of users wrote the comments',
      type: 'id',
      modelName: 'user',
      optional: true,
      list: true,
    },
    assessmentFormIds: {
      comment: 'The IDs of forms that the comments refer to',
      type: 'id',
      modelName: 'assessmentForm',
      optional: true,
      list: true,
    },
    assessmentEventIds: {
      comment: 'The IDs of events that the comments refer to',
      type: 'id',
      modelName: 'assessmentEvent',
      optional: true,
      list: true,
    },
  },
});

/** Override type */
export type AssessmentFormCommentFiltersInput = SchemaToType<
  typeof AssessmentFormCommentFiltersInput
>;

export const CreateAssessmentFormCommentInput = mkInput({
  name: 'CreateAssessmentFormCommentInput',
  comment: 'Input for creating an assessment form comment',
  fields: {
    content: AssessmentFormComment.fields.content,
    assessmentFormId: {
      comment: 'The ID of the form that the comment refers to',
      type: 'id',
      modelName: 'assessmentForm',
    },
    formStatus: {
      comment: 'Update the status while adding the comment',
      type: { AssessmentFormStatus },
      optional: true,
    },
    assessmentEventId: {
      comment: 'The ID of the event that the comment refers to',
      type: 'id',
      modelName: 'assessmentEvent',
      optional: true,
    },
  },
});

/** Override type */
export type CreateAssessmentFormCommentInput = SchemaToType<
  typeof CreateAssessmentFormCommentInput
>;

export const CreateAssessmentFormCommentsInput = mkInput({
  name: 'CreateAssessmentFormCommentsInput',
  comment: 'Input for bulk creating assessment form comments',
  fields: {
    assessmentFormComments: {
      comment: 'The list of assessment form comments to create',
      type: CreateAssessmentFormCommentInput,
      list: true,
    },
  },
});

/** Override type */
export type CreateAssessmentFormCommentsInput = SchemaToType<
  typeof CreateAssessmentFormCommentsInput
>;

export const UpdateAssessmentFormCommentInput = mkInput({
  name: 'UpdateAssessmentFormCommentInput',
  comment: 'Input for updating assessment form comments',
  fields: {
    id: AssessmentFormComment.fields.id,
    content: CreateAssessmentFormCommentInput.fields.content,
  },
});

/** Override type */
export type UpdateAssessmentFormCommentInput = SchemaToType<
  typeof UpdateAssessmentFormCommentInput
>;

export const UpdateAssessmentFormCommentsInput = mkInput({
  name: 'UpdateAssessmentFormCommentsInput',
  comment: 'Input for bulk updating assessment form comments',
  fields: {
    assessmentFormComments: {
      comment: 'The list of assessment form comments to update',
      type: UpdateAssessmentFormCommentInput,
      list: true,
    },
  },
});

/** Override type */
export type UpdateAssessmentFormCommentsInput = SchemaToType<
  typeof UpdateAssessmentFormCommentsInput
>;

export const DeleteAssessmentFormCommentsInput = mkInput({
  name: 'DeleteAssessmentFormCommentsInput',
  comment: 'Input for deleting multiple assessment form comments',
  fields: {
    ids: {
      comment: 'The ids of the assessment form comments to delete',
      type: 'id',
      modelName: 'assessmentFormComment',
      list: true,
    },
  },
});

/** Override type */
export type DeleteAssessmentFormCommentsInput = SchemaToType<
  typeof DeleteAssessmentFormCommentsInput
>;
