import React from 'react';

export interface IndeterminateCheckboxProps {
  /** The indeterminate state of this checkbox */
  indeterminate?: boolean | undefined;
}

/**
 * An indeterminate checkbox
 */
export const IndeterminateCheckbox = React.forwardRef<
  HTMLInputElement,
  IndeterminateCheckboxProps
>(({ indeterminate, ...rest }, ref) => {
  const defaultRef = React.useRef<HTMLInputElement>(null);
  const resolvedRef = ref || defaultRef;

  React.useEffect(() => {
    if (typeof resolvedRef === 'function') {
      resolvedRef(null);
    } else if (resolvedRef.current) {
      resolvedRef.current.indeterminate = !!indeterminate;
    }
  }, [resolvedRef, indeterminate]);

  // Could not get react checkbox to work with parent container overflow: scroll
  // nor could I figure out how to do the indeterminate checkbox logic
  // so opting to use the native input element here
  return (
    <>
      <input type="checkbox" ref={resolvedRef} {...rest} />
    </>
  );
});
