import { CodePackage } from '@main/code-scanning-types';
import React from 'react';
import { CellProps, Column } from 'react-table';

import { ActionsCellWrapper } from '../wrappers';
import { ApproveAppButton } from './components';

export const ACTIONS_COLUMN: Column<CodePackage> = {
  id: 'actions',
  Cell: ({ row }: CellProps<CodePackage>) => {
    const { id, name, description } = row.original;

    return (
      <ActionsCellWrapper>
        <ApproveAppButton
          name={name}
          description={description}
          codePackageId={id}
        />
      </ActionsCellWrapper>
    );
  },
  minWidth: 232,
};
