import { RequestAction, RequestStatus } from '@transcend-io/privacy-types';

import {
  DataPointExportMode,
  DataPointMetadata,
  DataPointPreviewWithDataSilo,
} from '@main/datamap-types';
import { LocalizedMessage } from '@main/internationalization';
import { mkInput, mkOrder, mkType, SchemaToType } from '@main/schema-utils';
import { TableEncryptionType } from '@main/sombra-types';

import { ProfileDataPointOrderField, ProfileDataPointStatus } from './enums';
import { ProfilePreview } from './profilePreview';

export const ProfileDataPoint = mkType({
  name: 'ProfileDataPoint',
  comment: 'An edge between a datapoint and profile, indicating child profile',
  fields: {
    id: {
      comment: 'The unique database id of the ProfileDataPoint edge',
      modelName: 'ProfileDataPoint',
      type: 'id',
    },
    dataPointId: {
      comment: 'The id of the datapoint',
      modelName: 'dataPoint',
      type: 'id',
    },
    profileId: {
      comment: 'The id of the profile',
      modelName: 'profile',
      type: 'id',
    },
    dataPoint: {
      comment: 'The datapoint that the edge refers to',
      type: DataPointPreviewWithDataSilo,
    },
    status: {
      comment: 'The status of the profile firing the datapoint',
      type: { ProfileDataPointStatus },
    },
    error: {
      comment: 'The error message if an error ocurred',
      optional: true,
      type: 'string',
    },
    fileCount: {
      comment:
        'The number of request files associated with the profile datapoint',
      type: 'int',
    },
    scheduledAt: {
      comment: 'When the next action is scheduled',
      type: 'Date',
    },
    profile: {
      comment: 'The profile identifier being looked up',
      type: ProfilePreview,
    },
    pollId: {
      comment:
        'The ID used as reference when implemented as notify -> save nonce -> poll nonce',
      type: 'string',
      optional: true,
    },
    link: {
      comment: 'A link to the profile datapoint in question',
      type: 'string',
    },
  },
});

/** Override type */
export type ProfileDataPoint = SchemaToType<typeof ProfileDataPoint>;

export const ProfileDataPointFiltersInput = mkInput({
  name: 'ProfileDataPointFiltersInput',
  comment: 'Input for filter ProfileDataPoints',
  fields: {
    text: {
      comment: 'Search datapoints by name or data silos by title',
      type: 'string',
      optional: true,
    },
    dataSiloId: {
      comment: 'The ID of the data silo to filter for',
      modelName: 'dataSilo',
      type: 'id',
      optional: true,
    },
    profileId: {
      comment: 'The ID of the profile to filter for',
      modelName: 'profile',
      type: 'id',
      optional: true,
    },
    dataPointId: {
      comment:
        'The ID of the datapoint to filter for. When provided, dataSiloId is redundant',
      modelName: 'dataPoint',
      type: 'id',
      optional: true,
    },
    status: {
      comment: 'The status of the ProfileDataPoint to filter',
      type: { ProfileDataPointStatus },
      optional: true,
      list: true,
    },
    requestIds: {
      comment: 'The IDs of the requests to filter for',
      modelName: 'request',
      type: 'id',
      optional: true,
      list: true,
    },
    showActiveJobs: {
      comment: 'Show only active profile datapoint jobs',
      type: 'boolean',
      optional: true,
    },
    requestType: {
      comment: 'The type of data subject requests to filter for',
      type: { RequestAction },
      optional: true,
      list: true,
    },
    requestCreatedAt: {
      comment: 'Filter for requests that have been made before this date',
      type: 'Date',
      optional: true,
    },
    requestStatus: {
      comment: 'The status of data subject requests to filter for',
      type: { RequestStatus },
      optional: true,
      list: true,
    },
  },
});

/** Override type */
export type ProfileDataPointFiltersInput = SchemaToType<
  typeof ProfileDataPointFiltersInput
>;

/** Order for a profileDataPoints query */
export const ProfileDataPointOrder = mkOrder(
  ProfileDataPoint.name,
  ProfileDataPointOrderField,
);

/** Override type */
export type ProfileDataPointOrder = SchemaToType<typeof ProfileDataPointOrder>;

export const DataPointSummary = mkType({
  name: 'DataPointSummary',
  comment:
    'The summarization for a datapoint against a specific RequestDataSilo',
  fields: {
    id: {
      comment: 'The unique database id of the datapoint',
      modelName: 'dataPoint',
      type: 'id',
    },
    name: {
      comment: 'Name of the datapoint',
      type: 'string',
    },
    slug: {
      comment: 'The slug of the datapoint for templating',
      type: 'string',
    },
    path: {
      comment:
        'Usually only relevant for database silos, ' +
        'where datapoints can be uniquely identified by listing out the schemas that they belong to. ' +
        'The list of schema names MUST appear in order e.g., ' +
        '["ANALYTICS","public"] is different from ["public","ANALYTICS"]',
      type: 'string',
      list: true,
    },
    title: {
      comment: 'The title of the datapoint',
      type: LocalizedMessage,
      optional: true,
    },
    description: {
      comment: 'The description of the datapoint',
      type: LocalizedMessage,
      optional: true,
    },
    metadata: {
      comment: 'Additional metadata about the datapoint',
      type: DataPointMetadata,
    },
    status: {
      comment:
        'The accumulated status for the datapoint in the context of the RequestDataSilo',
      type: { ProfileDataPointStatus },
      optional: true,
    },
    isRedactDataPoint: {
      comment: 'True if the datapoint supports redaction',
      type: 'boolean',
    },
    fileCount: {
      comment:
        'The number of request files associated with the RequestDataSilo.',
      type: 'int',
    },
    profileDataPoint: {
      comment:
        'Associated Profile Data Point, if a profile Id is specified in the original query',
      optional: true,
      type: ProfileDataPoint,
    },
    exportMode: {
      comment: 'The export type of the datapoint',
      type: { DataPointExportMode },
    },
    encryption: {
      comment: 'The encryption type for the datapoint',
      type: { TableEncryptionType },
      optional: true,
    },
  },
});

/** Override type */
export type DataPointSummary = SchemaToType<typeof DataPointSummary>;
