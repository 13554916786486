/**
 * The statuses of contract scans
 */
export enum ContractScanStatus {
  /** An error occurred mid-run */
  Error = 'ERROR',
  /** Contract scan is in progress */
  InProgress = 'IN_PROGRESS',
  /** Scan is completed successfully */
  Done = 'DONE',
}

/**
 * The fields that a contract scan can be ordered on
 */
export enum ContractScanOrderField {
  /** The name of the contract scan */
  Name = 'name',
  /** The age of the contract */
  CreatedAt = 'createdAt',
  /** The last updated time */
  UpdatedAt = 'updatedAt',
}
