import { AttributeKey, AttributeValue } from '@main/attribute-types';

/**
 * pivots a list of attribute values into a column-oriented object where the
 * columns are attribute keys
 *
 * @param attributeKeys - the list of potential keys
 * @param attributeValues - the list of potential values
 * @returns the formatted and flat CSV object containing the attributes
 */
export function pivotAttributesToCsvColumns(
  attributeKeys: AttributeKey[],
  attributeValues: AttributeValue[],
): Record<string, any> {
  return Object.fromEntries(
    attributeKeys.map(({ name, id }) => [
      name,
      attributeValues
        .filter((value) => value.attributeKey.id === id)
        .map(({ name }) => name),
    ]),
  );
}
