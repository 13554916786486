import { useMemo } from 'react';

import { AnyFunction } from '@transcend-io/type-utils';

import { throttle, ThrottleSettings } from '@main/utils';

/**
 * Throttles a callback.
 * If dependencies change too often, the throttle function will be recreated and will not throttle as expected.
 * Consider memoizing options (if used) and wrapping callback in useCallback, then including the callback in dependencies here.
 *
 * @param callback - The function to throttle execution of
 * @param time - Time in seconds to between invocations
 * @param options - Additional options; should memoize/use constant if including in dependencies
 * @param dependencies - Callback dependencies; can be an array containing the callback itself if it has been wrapped in useCallback
 * @returns debounced callback
 */
export const useThrottle = <TFunc extends AnyFunction>(
  callback: TFunc,
  time = 400,
  options: ThrottleSettings = { leading: true },
  dependencies: any[] = [],
): TFunc | (() => void) =>
  useMemo(
    () => throttle(callback, time, options),
    // Callback is not listed as a dependency as it is not always necessary.
    // If it is necessary, include it OR its dependencies in the dependencies array.
    [time, ...dependencies],
  );
