import {
  PaginatedSelect,
  PaginatedSelectProps,
} from '@main/ad-core-components';
import { buildUseInfiniteScroll } from '@main/core-ui';
import {
  BusinessEntityOrderField,
  BusinessEntityPreview,
  endpoints,
} from '@main/datamap-types';
import { OrderDirection } from '@main/schema-utils';
import React, { useState } from 'react';

import {
  SELECT_BUSINESS_ENTITY_NODES,
  SELECT_BUSINESS_ENTITY_QUERY_NAME,
} from './constants';
import { selectVendorBusinessEntityMessages } from './messages';

/**
 * Selected businessEntity
 */
export type SelectedBusinessEntity = Pick<
  BusinessEntityPreview,
  keyof typeof SELECT_BUSINESS_ENTITY_NODES
>;

const useSelectBusinessEntity = buildUseInfiniteScroll(
  endpoints.businessEntities,
  SELECT_BUSINESS_ENTITY_QUERY_NAME,
  {
    nodes: SELECT_BUSINESS_ENTITY_NODES,
  },
);

export interface SelectBusinessEntitiesProps
  extends Omit<
    PaginatedSelectProps<SelectedBusinessEntity, false>,
    'isQueryLoading' | 'queryError' | 'fetchMore' | 'onEndsTyping' | 'onChange'
  > {
  /** Typed onChange handler */
  onChange?: (businessEntities: SelectedBusinessEntity[]) => void;
}

/**
 * Select a specific businessEntity
 */
export const SelectBusinessEntities: React.FC<SelectBusinessEntitiesProps> = ({
  onChange,
  ...paginatedSelectProps
}) => {
  const [searchText, setSearchText] = useState('');
  const { data, loading, error, fetchMore } = useSelectBusinessEntity({
    variables: {
      filterBy: {
        text: searchText,
      },
      orderBy: [
        {
          field: BusinessEntityOrderField.Title,
          direction: OrderDirection.ASC,
        },
      ],
    },
  });

  return (
    <PaginatedSelect
      showOutline
      placeholderDescriptor={selectVendorBusinessEntityMessages.placeholder}
      options={data?.nodes ?? []}
      isQueryLoading={loading}
      getOptionValue={({ id }: SelectedBusinessEntity) => id}
      getOptionLabel={({ title }: SelectedBusinessEntity) => title}
      queryError={error}
      fetchMore={fetchMore}
      onEndsTyping={setSearchText}
      onChange={
        !onChange
          ? undefined
          : (entities) =>
              !entities
                ? onChange([])
                : Array.isArray(entities)
                  ? onChange(entities)
                  : onChange([entities as SelectedBusinessEntity])
      }
      {...paginatedSelectProps}
    />
  );
};
