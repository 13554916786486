import { makeEnum } from '@transcend-io/type-utils';

/**
 * An event for when a user consents (or removes consent) to select purposes
 *
 * When this event is reported, there will be Dimension for which purpose changed its consent preferences and what it changed to.
 */
export const ConsentEvent = makeEnum({
  ConsentChanged: 'CONSENT_CHANGED',
});
/** Overrides type */
export type ConsentEvent = (typeof ConsentEvent)[keyof typeof ConsentEvent];

/** Metrics related to page traffic */
export const TrafficEvent = makeEnum({
  /** Number of outgoing requests to external hosts from a site */
  OutgoingRequest: 'OUTGOING_REQUEST',
  /** Number of cookie mutations */
  CookieMutation: 'COOKIE_MUTATION',
  /** Number of cookie matchers */
  CookieMatcherMutation: 'COOKIE_MATCHER_MUTATION',
  /** Number of outgoing requests to external hosts from a site collected via Path Matchers/REGEX */
  PathMatcher: 'PATH_MATCHER_REQUEST',
  /** Number of outgoing requests to external hosts from a site collected via Paths */
  Path: 'PATH_REQUEST',
  /** Number of pages loaded with the consent manager active */
  PageViews: 'PAGE_VIEWS',
  /** Number of user browser sessions using the consent manager */
  SiteSessions: 'SITE_SESSIONS',
  /** Number of bounces */
  Bounces: 'BOUNCES',
  /** Number of processed telemetry requests */
  TelemetryRequests: 'TELEMETRY_REQUESTS',
  /** The default consent values based on the detected regime */
  InitialSessionConsent: 'INITIAL_SITE_SESSION_CONSENT',
});
/** Overrides type */
export type TrafficEvent = (typeof TrafficEvent)[keyof typeof TrafficEvent];

/**
 * Metrics related to Regimes found for users.
 *
 * When this event is reported, there will be Dimension for which regime was detected.
 */
export const RegimeEvent = makeEnum({
  RegimeDetected: 'REGIME_DETECTED',
});
/** Overrides type */
export type RegimeEvent = (typeof RegimeEvent)[keyof typeof RegimeEvent];

/**
 * Metrics related to Signals found in  our users' browser settings.
 *
 * When this event is reported, there will be Dimension for which signal was detected.
 */
export const SignalEvent = makeEnum({
  SignalDetected: 'SIGNAL_DETECTED',
  InitialSessionSignal: 'INITIAL_SESSION_SIGNAL',
});
/** Overrides type */
export type SignalEvent = (typeof SignalEvent)[keyof typeof SignalEvent];

/**
 * Metrics related to requests and if they were allowed of blocked.
 *
 * When this event is reported, there will be Dimension for which purpose was allowed/disallowed.
 */
export const TrackingPurposeStatsEvent = makeEnum({
  TrackingPurposeStats: 'TRACKING_PURPOSE_STATS',
});
/** Overrides type */
export type TrackingPurposeStatsEvent =
  (typeof TrackingPurposeStatsEvent)[keyof typeof TrackingPurposeStatsEvent];

/** All analytics events */
export const AnalyticsEvent = makeEnum({
  ...ConsentEvent,
  ...TrafficEvent,
  ...RegimeEvent,
  ...SignalEvent,
  ...TrackingPurposeStatsEvent,
});
/** Overrides type */
export type AnalyticsEvent =
  (typeof AnalyticsEvent)[keyof typeof AnalyticsEvent];

/** The names of supplementary fields that add context to AnalyticsEvent events. */
export const AnalyticsDimension = makeEnum({
  Regime: 'REGIME',
  Signal: 'SIGNAL',
  SignalGpc: 'SIGNAL_GPC',
  SignalDnt: 'SIGNAL_DNT',
  Cookie: 'COOKIE',
  Host: 'HOST',
  Path: 'PATH',
  Purpose: 'PURPOSE',
  PurposeDefaultConsentValue: 'PURPOSE_DEFAULT_CONSENT_VALUE',
  PurposeCurrentConsentValue: 'PURPOSE_CURRENT_CONSENT_VALUE',
  NewValue: 'NEW_VALUE',
  WasAllowed: 'WAS_ALLOWED',
  ConsentManagerId: 'CONSENT_MANAGER_ID',
  EncounteredAt: 'ENCOUNTERED_AT',
  MobileAppId: 'MOBILE_APP_ID',
  Regex: 'REGEX',
  /** @deprecated */
  SamplingRate: 'SAMPLING_RATE',
  ClientSamplingRate: 'CLIENT_SAMPLING_RATE',
  BackendSamplingMultiplier: 'BACKEND_SAMPLING_MULTIPLIER',
});
