import { createLoadable } from '@main/core-ui';

import { failureMessages, oAuthMessages, successMessages } from './messages';

/**
 * Callback when successful
 */
export const OAuthSuccess = createLoadable({
  loader: () => import('./Success'),
  messages: successMessages,
});

/**
 * Callback when fails
 */
export const OAuthFailure = createLoadable({
  loader: () => import('./Failure'),
  messages: failureMessages,
});

// Load the component dynamically
export default createLoadable({
  loader: () => import('./index'),
  messages: oAuthMessages,
});
