import type { ExtendableRoute } from '@main/admin-dashboard/src/routes';
import {
  AdminDashboardPathfinderPath,
  PATHFINDERS_PATH_PREFIX,
} from '@main/prompt-types';

import { AgentLoadable, NewAgentLoadable } from './Agent/Loadable';
import { AgentFileLoadable, NewAgentFileLoadable } from './AgentFile/Loadable';
import { AgentFilesLoadable } from './AgentFiles/Loadable';
import {
  AgentFunctionLoadable,
  NewAgentFunctionLoadable,
} from './AgentFunction/Loadable';
import { AgentFunctionsLoadable } from './AgentFunctions/Loadable';
import { AgentsLoadable } from './Agents/Loadable';
import { ApplicationLoadable } from './Application/Loadable';
import { ApplicationsLoadable } from './Applications/Loadable';
import { AppUserLoadable } from './AppUser/Loadable';
import { AppUsersLoadable } from './AppUsers/Loadable';
import { pathfinderPageTitleMessages } from './messages';
import { PathfinderLoadable } from './Pathfinder/Loadable';
import { PathfinderPoliciesLoadable } from './PathfinderPolicies/Loadable';
import { PathfinderPolicyLoadable } from './PathfinderPolicy/Loadable';
import { PathfindersLoadable } from './Pathfinders/Loadable';
import { pathfinderTabMessages } from './PathfinderTabs/messages';

const pathfindersBreadcrumb = {
  id: PATHFINDERS_PATH_PREFIX,
  displayText: pathfinderPageTitleMessages.pathfinders,
  href: AdminDashboardPathfinderPath.Pathfinders,
};

const pathfinderPolicieBreadcrumbs = [
  pathfindersBreadcrumb,
  {
    id: AdminDashboardPathfinderPath.Pathfinders,
    displayText: pathfinderTabMessages.pathfinderPolicies,
    href: AdminDashboardPathfinderPath.PathfinderPolicies,
  },
];

const pathfindersAppUsersBreadcrumbs = [
  pathfindersBreadcrumb,
  {
    id: AdminDashboardPathfinderPath.Pathfinders,
    displayText: pathfinderTabMessages.appUsers,
    href: AdminDashboardPathfinderPath.AppUsers,
  },
];

const pathfindersApplicationsBreadcrumbs = [
  pathfindersBreadcrumb,
  {
    id: AdminDashboardPathfinderPath.Pathfinders,
    displayText: pathfinderTabMessages.applications,
    href: AdminDashboardPathfinderPath.Applications,
  },
];

const agentsBreadcrumbs = [
  pathfindersBreadcrumb,
  {
    id: AdminDashboardPathfinderPath.Pathfinders,
    displayText: pathfinderTabMessages.agents,
    href: AdminDashboardPathfinderPath.Agents,
  },
];

const agentFunctionsBreadcrumbs = [
  pathfindersBreadcrumb,
  {
    id: AdminDashboardPathfinderPath.Pathfinders,
    displayText: pathfinderTabMessages.agentFunctions,
    href: AdminDashboardPathfinderPath.AgentFunctions,
  },
];

const agentFilesBreadcrumbs = [
  pathfindersBreadcrumb,
  {
    id: AdminDashboardPathfinderPath.Pathfinders,
    displayText: pathfinderTabMessages.agentFiles,
    href: AdminDashboardPathfinderPath.AgentFiles,
  },
];

export const pathfinderRoutes: Record<
  AdminDashboardPathfinderPath,
  ExtendableRoute
> = {
  [AdminDashboardPathfinderPath.Pathfinders]: {
    path: AdminDashboardPathfinderPath.Pathfinders,
    title: pathfinderPageTitleMessages.pathfinders,
    component: PathfindersLoadable,
    breadcrumbs: [pathfindersBreadcrumb],
  },
  [AdminDashboardPathfinderPath.ViewPathfinder]: {
    path: AdminDashboardPathfinderPath.ViewPathfinder,
    component: PathfinderLoadable,
    breadcrumbs: [pathfindersBreadcrumb],
  },
  [AdminDashboardPathfinderPath.EditPathfinder]: {
    path: AdminDashboardPathfinderPath.EditPathfinder,
    component: PathfinderLoadable,
    breadcrumbs: [pathfindersBreadcrumb],
  },
  [AdminDashboardPathfinderPath.Applications]: {
    path: AdminDashboardPathfinderPath.Applications,
    title: pathfinderPageTitleMessages.applications,
    component: ApplicationsLoadable,
    breadcrumbs: pathfindersApplicationsBreadcrumbs,
  },
  [AdminDashboardPathfinderPath.ViewApplication]: {
    path: AdminDashboardPathfinderPath.ViewApplication,
    component: ApplicationLoadable,
    breadcrumbs: pathfindersApplicationsBreadcrumbs,
  },
  [AdminDashboardPathfinderPath.EditApplication]: {
    path: AdminDashboardPathfinderPath.EditApplication,
    component: ApplicationLoadable,
    breadcrumbs: pathfindersApplicationsBreadcrumbs,
  },
  [AdminDashboardPathfinderPath.AppUsers]: {
    path: AdminDashboardPathfinderPath.AppUsers,
    title: pathfinderPageTitleMessages.appUsers,
    component: AppUsersLoadable,
    breadcrumbs: pathfindersAppUsersBreadcrumbs,
  },
  [AdminDashboardPathfinderPath.ViewAppUser]: {
    path: AdminDashboardPathfinderPath.ViewAppUser,
    component: AppUserLoadable,
    breadcrumbs: pathfindersAppUsersBreadcrumbs,
  },
  [AdminDashboardPathfinderPath.EditAppUser]: {
    path: AdminDashboardPathfinderPath.EditAppUser,
    component: AppUserLoadable,
    breadcrumbs: pathfindersAppUsersBreadcrumbs,
  },
  [AdminDashboardPathfinderPath.PathfinderPolicies]: {
    path: AdminDashboardPathfinderPath.PathfinderPolicies,
    title: pathfinderPageTitleMessages.pathfinderPolicies,
    component: PathfinderPoliciesLoadable,
    breadcrumbs: pathfinderPolicieBreadcrumbs,
  },
  [AdminDashboardPathfinderPath.ViewPathfinderPolicy]: {
    path: AdminDashboardPathfinderPath.ViewPathfinderPolicy,
    component: PathfinderPolicyLoadable,
    breadcrumbs: pathfinderPolicieBreadcrumbs,
  },
  [AdminDashboardPathfinderPath.EditPathfinderPolicy]: {
    path: AdminDashboardPathfinderPath.EditPathfinderPolicy,
    component: PathfinderPolicyLoadable,
    breadcrumbs: pathfinderPolicieBreadcrumbs,
  },
  [AdminDashboardPathfinderPath.Agents]: {
    path: AdminDashboardPathfinderPath.Agents,
    title: pathfinderPageTitleMessages.agents,
    component: AgentsLoadable,
    breadcrumbs: agentsBreadcrumbs,
  },
  [AdminDashboardPathfinderPath.NewAgent]: {
    path: AdminDashboardPathfinderPath.NewAgent,
    component: NewAgentLoadable,
    breadcrumbs: agentsBreadcrumbs,
  },
  [AdminDashboardPathfinderPath.ViewAgent]: {
    path: AdminDashboardPathfinderPath.ViewAgent,
    component: AgentLoadable,
    breadcrumbs: agentsBreadcrumbs,
  },
  [AdminDashboardPathfinderPath.EditAgent]: {
    path: AdminDashboardPathfinderPath.EditAgent,
    component: AgentLoadable,
    breadcrumbs: agentsBreadcrumbs,
  },
  [AdminDashboardPathfinderPath.AgentFiles]: {
    path: AdminDashboardPathfinderPath.AgentFiles,
    title: pathfinderPageTitleMessages.agentFiles,
    component: AgentFilesLoadable,
    breadcrumbs: agentFilesBreadcrumbs,
  },
  [AdminDashboardPathfinderPath.NewAgentFile]: {
    path: AdminDashboardPathfinderPath.NewAgentFile,
    component: NewAgentFileLoadable,
    breadcrumbs: agentFilesBreadcrumbs,
  },
  [AdminDashboardPathfinderPath.ViewAgentFile]: {
    path: AdminDashboardPathfinderPath.ViewAgentFile,
    component: AgentFileLoadable,
    breadcrumbs: agentFilesBreadcrumbs,
  },
  [AdminDashboardPathfinderPath.EditAgentFile]: {
    path: AdminDashboardPathfinderPath.EditAgentFile,
    component: AgentFileLoadable,
    breadcrumbs: agentFilesBreadcrumbs,
  },
  [AdminDashboardPathfinderPath.AgentFunctions]: {
    path: AdminDashboardPathfinderPath.AgentFunctions,
    title: pathfinderPageTitleMessages.agentFunctions,
    component: AgentFunctionsLoadable,
    breadcrumbs: agentFunctionsBreadcrumbs,
  },
  [AdminDashboardPathfinderPath.NewAgentFunction]: {
    path: AdminDashboardPathfinderPath.NewAgentFunction,
    component: NewAgentFunctionLoadable,
    breadcrumbs: agentFunctionsBreadcrumbs,
  },
  [AdminDashboardPathfinderPath.ViewAgentFunction]: {
    path: AdminDashboardPathfinderPath.ViewAgentFunction,
    component: AgentFunctionLoadable,
    breadcrumbs: agentFunctionsBreadcrumbs,
  },
  [AdminDashboardPathfinderPath.EditAgentFunction]: {
    path: AdminDashboardPathfinderPath.EditAgentFunction,
    component: AgentFunctionLoadable,
    breadcrumbs: agentFunctionsBreadcrumbs,
  },
};
