import type { RequestStatus } from '@transcend-io/privacy-types';
import { createEnum, filterEnum } from '@transcend-io/type-utils';

import { REQUEST_STATUSES } from './statuses';

/**
 * The statuses where a request can be revoked by a data subject
 *
 * This is true if request statuses that can transition to revoked state
 */
export const REQUEST_STATUSES_REVOKABLE = filterEnum(
  REQUEST_STATUSES,
  ({ transitions }) => transitions.includes('REVOKED'),
);

/**
 * The statuses that can be revoked key by status
 */
export const REQUEST_STATUS_REVOKABLE = createEnum(REQUEST_STATUSES_REVOKABLE);

/**
 * Check if a request can be revoked from its current state
 *
 * @param status - The status of the request to check
 * @returns True if the request can be revoked from its current state
 */
export function canRevoke(status: RequestStatus): boolean {
  return status in REQUEST_STATUS_REVOKABLE;
}
