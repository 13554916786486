import { defineMessages } from '@transcend-io/internationalization';

export const inlineUpdateMessages = defineMessages(
  'ad-core-components.hooks.useInlineInput.inlineUpdate',
  {
    updateSuccess: {
      defaultMessage: 'Successfully saved changes.',
    },
    unsaved: {
      defaultMessage:
        "Oops, too fast! We didn't have time to save your changes. Are you sure you want to leave?",
    },
  },
);
