/**
 * The fields that an legalHolds can be ordered on
 */
export enum LegalHoldOrderField {
  /** Sort alphabetically */
  Value = 'value',
  /** The time the legal hold was made */
  CreatedAt = 'createdAt',
  /** The time the legal hold was updated */
  UpdatedAt = 'updatedAt',
}
