import 'antd/lib/avatar/style/index';

import { getColorFromString } from '@main/utils';
import React from 'react';
import { useIntl } from 'react-intl';

import { AntIcon } from '../AntIcon';
import { Icon, IconType } from '../Icon';
import { IWithClassName } from '../types';
import { avatarMessages } from './messages';
import { IconPlaceholder, LogoIcon } from './wrappers';

export const BACKGROUND_COLORS = [
  '#75BDFF',
  '#81DBC6',
  '#F5D871',
  '#FFAF75',
  '#FE9F96',
  '#B275FF',
];

export interface AvatarProps extends IWithClassName {
  /** The avatar's icon src */
  src?: string;
  /** The avatar's name */
  name?: string;
  /** alt text to use */
  alt?: string;
  /** The size of the icon */
  size?: number;
  /** Styles for this icon */
  style?: React.CSSProperties;
  /** should the icon be displayed as a circle */
  circle?: boolean;
  // TODO: https://transcend.height.app/T-29409 - remove this when removing ant icon
  /** Optionally use an icon instead of text or image */
  icon?: IconType | string;
}

export const Avatar: React.FC<AvatarProps> = ({
  src,
  name,
  alt,
  size,
  style,
  circle = true,
  icon,
  className,
}) => {
  const { formatMessage } = useIntl();

  return src &&
    (!name ||
      // use the default instead if we are just using the default picture
      !src.match(/\/profile-pictures\/[a-z].svg$/)) ? (
    <LogoIcon
      className={className}
      size={size}
      src={src}
      alt={
        alt ?? name
          ? formatMessage(avatarMessages.iconAlt, {
              name,
            })
          : formatMessage(avatarMessages.defaultIconAlt)
      }
      style={{ borderRadius: circle ? '10em' : undefined, ...style }}
    />
  ) : (
    <IconPlaceholder
      className={className}
      bgColor={getColorFromString(BACKGROUND_COLORS, name)}
      size={size}
      style={{ borderRadius: circle ? size ?? '10em' : undefined, ...style }}
    >
      {icon ? (
        Object.values(IconType).includes(icon as IconType) ? (
          <Icon type={icon as IconType} />
        ) : (
          // TODO: https://transcend.height.app/T-29409 - remove this support for AntIcon
          <AntIcon type={icon} />
        )
      ) : (
        name?.[0].toUpperCase()
      )}
    </IconPlaceholder>
  );
};
