import {
  OrganizationIcon,
  SelectedUser,
  SelectUser,
  SelectUserProps,
} from '@main/ad-core-components';
import React from 'react';
import { useIntl } from 'react-intl';

import { selectAssigneesMessages } from './messages';

/**
 * Component to select one or more internal or external assignees
 */
export function SelectAssignees<
  TIsMulti extends boolean,
  T extends SelectedUser = SelectedUser,
>(props: SelectUserProps<TIsMulti, T>): JSX.Element {
  const { formatMessage } = useIntl();

  return (
    <SelectUser<TIsMulti, T>
      isMulti
      variant="light"
      allowInvite
      formatCreateLabel={(email) =>
        formatMessage(selectAssigneesMessages.assignViaEmail, {
          email,
        })
      }
      getOptionLabel={({ name, isNew, email }: SelectedUser) =>
        isNew ? email : name
      }
      getOptionLogo={({ profilePicture }: SelectedUser) =>
        profilePicture ? (
          <OrganizationIcon
            organizationIconSrc={profilePicture}
            size={20}
            style={{ fontSize: '12px' }}
            circle
          />
        ) : (
          // Add a placeholder span to keep the height of the icon consistent
          <span style={{ height: 20 }} />
        )
      }
      {...props}
    />
  );
}
