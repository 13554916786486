import { defineMessages } from '@transcend-io/internationalization';

export const formItemMessages = defineMessages('dsr-types.src.formItem', {
  email: {
    defaultMessage: 'Email',
  },
  details: {
    defaultMessage: 'Additional Details',
  },
});
