import { SelectedUser, useInlineInput } from '@main/ad-core-components';
import { buildUseMutation } from '@main/core-ui';
import { endpoints } from '@main/datamap-types';
import type { ID } from '@main/schema-utils';
import partition from 'lodash/partition';
import React from 'react';

import { SelectAssignees } from './SelectAssignees';

const useUpdateAssessmentForm = buildUseMutation(
  endpoints.updateAssessmentForm,
);

// Ideally, we would omit `id` here and override with ID<'user'> | ID<'assessmentFormExternalAssignee'>,
// but then we wouldn't be extending the SelectedUser type, and we'd need a lot more casting to use <SelectUser />
interface SelectedAssignee extends SelectedUser {
  /** Whether this is an external user */
  isExternal: boolean;
}

/** Props for the `InlineSelectAssignees` component */
interface InlineSelectFormAssigneesProps {
  /** The ID of the parent assessment for this cell */
  id: ID<'assessmentForm'>;
  /** The value for the cell */
  value: SelectedAssignee[];
}

export const InlineSelectFormAssignees: React.FC<
  InlineSelectFormAssigneesProps
> = ({ id, value: initialValue }) => {
  const [updateAssessmentForm, { loading }] = useUpdateAssessmentForm({
    refetchQueries: ['AssessmentFormsList', 'AssessmentForms'],
  });
  const { value, setValue, onBlur } = useInlineInput<SelectedAssignee[]>({
    initialValue,
    optionsComparator: (existing, updated) =>
      JSON.stringify(existing.map(({ id }) => id).sort()) ===
      JSON.stringify(updated.map(({ id }) => id).sort()),
    onUpdate: (values) => {
      const [externalAssignees, internalAssignees] = partition(
        values,
        (option) => option.isNew || option.isExternal,
      );
      return updateAssessmentForm({
        variables: {
          input: {
            id,
            assigneeIds: internalAssignees.map((option) => (option as any).id),
            externalAssigneeEmails: externalAssignees.map(
              (option) => (option as any).email,
            ),
          },
        },
      }).then(({ data, errors }) => ({
        data: [
          ...(data?.assessmentForm?.assignees || []).map((a) => ({
            ...a,
            isExternal: false,
          })),
          ...(data?.assessmentForm?.externalAssignees || []).map((e) => ({
            ...e,
            // This cast is not great, but allows us to use `SelectedAssignee` as
            // the option type in SelectedUser with minimal casting overall
            id: e.id as unknown as ID<'user'>,
            name: e.email,
            profilePicture: '',
            isExternal: true,
          })),
        ],
        errors,
      }));
    },
  });

  return (
    <SelectAssignees<true, SelectedAssignee>
      isMulti
      variant="light"
      value={value}
      showOutline={false}
      onChange={(newValues) => setValue(newValues)}
      isLoading={loading}
      overflow="badge"
      onBlur={onBlur}
      extraOptions={value.filter((option) => option.isNew || option.isExternal)}
      // keeps the menu from going below the frozen cell in the next row
      menuPortalTarget={
        document.getElementsByTagName('table')[0] || document.body
      }
    />
  );
};
