import React, { Dispatch, SetStateAction } from 'react';
import {
  DragDropContext,
  Draggable,
  DraggableChildrenFn,
  DraggableProps,
  DraggingStyle,
  Droppable,
  NotDraggingStyle,
  OnDragEndResponder,
} from 'react-beautiful-dnd';

import { DragHandle } from './DragHandle';
import { SortableListItem } from './wrappers';

// helper for reordering the result
const reorder = <T,>(
  list: ISortableListItem<T>[],
  startIndex: number,
  endIndex: number,
): ISortableListItem<T>[] => {
  const result = [...list];
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const getItemStyle = (
  isDragging: boolean,
  draggableStyle: DraggingStyle | NotDraggingStyle | undefined,
): object => ({
  // some basic styles to make the items look a bit nicer
  ...(isDragging
    ? {
        boxShadow: '0px 10px 20px rgba(0, 0, 0, 0.05)',
      }
    : {}),
  // styles we need to apply on draggables
  ...draggableStyle,
});

const getRenderItem =
  <T,>(items: ISortableListItem<T>[]): DraggableChildrenFn =>
  (provided, snapshot, rubric) => (
    <SortableListItem
      ref={provided.innerRef}
      {...provided.draggableProps}
      {...provided.dragHandleProps}
      style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
    >
      <DragHandle />

      {items[rubric.source.index].content}
    </SortableListItem>
  );

export interface ISortableListItem<T> {
  /** An id/key to keep track of the item in the list as it moves. MUST be unique for this list. */
  id: string;
  /** The raw data that is rendered into the content element */
  rawItem: T;
  /** The content to render for the list item */
  content: JSX.Element;
}

export interface SortableListProps<T> {
  /** The items to sort */
  items: ISortableListItem<T>[];
  /** Setter for the items when they are reordered */
  setItems: Dispatch<SetStateAction<ISortableListItem<T>[]>>;
  /** Props to pass to the draggable wrapping each item in the list */
  draggableProps?: Partial<DraggableProps>;
}

export const SortableList = <T,>({
  items,
  setItems,
  draggableProps, // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
}: SortableListProps<T>) => {
  const onDragEnd: OnDragEndResponder = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const sourceIndex = result.source.index;
    const destIndex = result.destination.index;

    if (result.type === 'DEFAULT') {
      const newItems = reorder(items, sourceIndex, destIndex);
      setItems(newItems);
    }
  };

  const renderItem = getRenderItem(items);

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable
        droppableId="droppable"
        // renderClone is necessary for showing an item being dragged when its parent has `transform` set on it
        renderClone={renderItem}
      >
        {(provided) => (
          <div
            ref={provided.innerRef}
            {...provided.droppableProps}
            style={{ marginLeft: -10 }}
          >
            {items.map((item, index) => (
              <Draggable
                key={item.id}
                draggableId={item.id}
                index={index}
                {...draggableProps}
              >
                {renderItem}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};
