import { Message, MessageTranslationInput } from '@main/internationalization';
import { mkInput, mkType, SchemaToType } from '@main/schema-utils';

export const PolicyVersion = mkType({
  name: 'PolicyVersion',
  comment: 'A version of a policy on the privacy center',
  fields: {
    id: {
      comment: 'The unique ID of the policy version',
      modelName: 'policyVersion',
      type: 'id',
    },
    effectiveOn: {
      comment: 'The date the policy version was effective',
      type: 'Date',
    },
    createdAt: {
      comment: 'The time this policy version was created',
      type: 'Date',
    },
    content: {
      comment: 'The HTML content of the policy version',
      type: Message,
      optional: true,
    },
  },
});

/** Override type */
export type PolicyVersion = SchemaToType<typeof PolicyVersion>;

export const PolicyContentInput = mkInput({
  name: 'PolicyContentInput',
  comment: 'Input for policy content for multiple locales',
  fields: {
    defaultMessage: {
      comment: 'The default content',
      type: 'string',
    },
    translations: {
      type: MessageTranslationInput,
      optional: true,
      comment: 'Translations of the content',
      list: true,
    },
  },
});

/** Override type */
export type PolicyContentInput = SchemaToType<typeof PolicyContentInput>;

export const PolicyVersionInput = mkInput({
  name: 'PolicyVersionInput',
  comment: 'Make changes to a policy version',
  fields: {
    effectiveOn: {
      comment: 'The date the policy version was effective',
      optional: true,
      type: 'string',
    },
    content: {
      comment: 'The HTML content of the policy version',
      type: PolicyContentInput,
      optional: true,
    },
  },
});

/** Override type */
export type PolicyVersionInput = SchemaToType<typeof PolicyVersionInput>;
