import React from 'react';

import { TipTapMarkType } from './enums';
import { extensionIsEnabled } from './helpers';
import { toolbarMessages } from './messages';
import { ToolbarToggle } from './ToolbarToggle';
import { ToolbarButtonGroupProps } from './types';

export const TextStyleButtonGroup: React.FC<ToolbarButtonGroupProps> = ({
  editor,
}) => (
  <>
    {extensionIsEnabled(editor, TipTapMarkType.Bold) && (
      <ToolbarToggle
        title={toolbarMessages.bold}
        pressed={editor.isActive(TipTapMarkType.Bold)}
        onPressedChange={() => editor.chain().focus().toggleBold().run()}
        disabled={!editor.can().chain().focus().toggleBold().run()}
        icon="bold"
      />
    )}

    {extensionIsEnabled(editor, TipTapMarkType.Italic) && (
      <ToolbarToggle
        title={toolbarMessages.italic}
        pressed={editor.isActive(TipTapMarkType.Italic)}
        onPressedChange={() => editor.chain().focus().toggleItalic().run()}
        disabled={!editor.can().chain().focus().toggleItalic().run()}
        icon="italic"
      />
    )}

    {extensionIsEnabled(editor, TipTapMarkType.Underline) && (
      <ToolbarToggle
        title={toolbarMessages.underline}
        pressed={editor.isActive(TipTapMarkType.Underline)}
        onPressedChange={() => editor.chain().focus().toggleUnderline().run()}
        disabled={!editor.can().chain().focus().toggleUnderline().run()}
        icon="underline"
      />
    )}

    {extensionIsEnabled(editor, TipTapMarkType.Strike) && (
      <ToolbarToggle
        title={toolbarMessages.strikethrough}
        pressed={editor.isActive(TipTapMarkType.Strike)}
        onPressedChange={() => editor.chain().focus().toggleStrike().run()}
        disabled={!editor.can().chain().focus().toggleStrike().run()}
        icon="strikethrough"
      />
    )}
  </>
);
