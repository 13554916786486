import { defineMessages } from '@transcend-io/internationalization';

export const jsonDiffMessages = defineMessages(
  'ad-core-components.JsonDiff.jsonDiff',
  {
    loading: {
      defaultMessage: 'Processing large diff, please wait...',
    },
  },
);
