import React, { ReactNode, useState } from 'react';

import { Search } from '../Search';
import { RightWrapper, ToolbarWrapper } from './wrappers';

/**
 * Props for TableToolbar component
 */
export interface TableToolbarProps {
  /** The current search value */
  searchValue?: string;
  /** Callback to fire when searchValue changes */
  onSearch?: (text: string) => void;
  /** The table filter button */
  tableFilterButton?: JSX.Element;
  /** Additional components to render in the toolbar (left side) */
  centerAlignedContent?: JSX.Element[];
  /** Additional components to render in the toolbar (right side) */
  rightAlignedContent?: JSX.Element[];
}

export const TableToolbar: React.FC<TableToolbarProps> = ({
  searchValue,
  onSearch,
  tableFilterButton,
  centerAlignedContent = [],
  rightAlignedContent = [],
}) => {
  const [localSearchValue, setLocalSearchValue] = useState(searchValue);

  return (
    <RawTableToolbar
      leftAlignedContent={[
        <Search
          key="search"
          style={{
            ...(!onSearch ? { visibility: 'hidden' } : {}),
          }}
          name="search"
          onChange={(newVal) => {
            setLocalSearchValue(newVal);
            onSearch?.(newVal);
          }}
          value={localSearchValue}
          loading={false}
        />,
      ]}
      centerAlignedContent={centerAlignedContent}
      rightAlignedContent={[
        ...(tableFilterButton ? [tableFilterButton] : []),
        ...rightAlignedContent,
      ]}
    />
  );
};

/**
 * Props for RawTableToolbar component
 */
export interface RawTableToolbarProps {
  /** Additional components to render in the toolbar (left side) */
  leftAlignedContent?: ReactNode;
  /** Additional components to render in the toolbar (center) */
  centerAlignedContent?: ReactNode;
  /** Additional components to render in the toolbar (right side) */
  rightAlignedContent?: ReactNode;
  /** Keep margin */
  withMargin?: boolean;
}

export const RawTableToolbar: React.FC<RawTableToolbarProps> = ({
  leftAlignedContent = null,
  centerAlignedContent = null,
  rightAlignedContent = null,
  withMargin = true,
}) => (
  <ToolbarWrapper style={withMargin ? {} : { margin: '0' }}>
    <div>{leftAlignedContent}</div>
    <div>{centerAlignedContent}</div>
    <RightWrapper>{rightAlignedContent}</RightWrapper>
  </ToolbarWrapper>
);
