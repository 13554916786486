import { indexBy } from '@main/utils';
import uniqBy from 'lodash/uniqBy';
import React, { useMemo } from 'react';
import { GroupTypeBase, OptionTypeBase, ValueType } from 'react-select';

import { ReactSelect } from './ReactSelect';
import { DefaultOptionType, ReactSelectExtendedProps } from './types';

export interface ReactSelectByValueProps<
  IsMulti extends boolean,
  T extends OptionTypeBase = DefaultOptionType,
  G extends GroupTypeBase<T> = GroupTypeBase<T>,
  V extends string = string,
> extends Omit<
    ReactSelectExtendedProps<IsMulti, T, G>,
    'value' | 'onChange' | 'getOptionValue'
  > {
  /** the value of the select */
  value?: V;
  /** onChange handler */
  onChange?: (selection: IsMulti extends true ? V[] : V) => void;
  /**
   * the current selected option(s) can be undefined if its containing page has not yet been fetched,
   * so this prop allows preloading it and fetching it manually to ensure an option is always visible
   *
   * Example: the options are a paginated list of integrations but the current selection is on the second page.
   * It's not technically in the list of options yet, so it'll appear as an empty badge (like an invalid value,
   * looks subtly different than a cleared value). This allows you to prefetch that selected integration so it
   * will display correctly as the current selection. On second thought this could even be an array to support
   * multiselect too
   */
  preloadedCurrentOptions?: T[];
}

/**
 * A react select component wrapper that automatically unwraps values
 * from options so you don't need to deal with translating the option types to values and back
 */
export function ReactSelectByValue<
  IsMulti extends boolean,
  T extends OptionTypeBase = DefaultOptionType,
  G extends GroupTypeBase<T> = GroupTypeBase<T>,
  V extends string = string,
>({
  preloadedCurrentOptions,
  options,
  ...reactSelectProps
}: ReactSelectByValueProps<IsMulti, T, G, V>): JSX.Element {
  const getOptionValueOrValue = useMemo(
    () =>
      reactSelectProps.getOptionValue ??
      ((option: T | ValueType<T, IsMulti>): V => (option as any).value),
    [reactSelectProps.getOptionValue],
  );

  const optionsWithPreloadedValue = useMemo(
    () =>
      uniqBy(
        [...(options ?? []), ...(preloadedCurrentOptions ?? [])] as T[],
        getOptionValueOrValue,
      ),
    [getOptionValueOrValue, options, preloadedCurrentOptions],
  );

  const optionsByValue = useMemo(
    () =>
      indexBy(optionsWithPreloadedValue, (option: T) =>
        getOptionValueOrValue(option),
      ),
    [optionsWithPreloadedValue, getOptionValueOrValue],
  );

  return (
    <ReactSelect<IsMulti, T, G>
      {...reactSelectProps}
      options={optionsWithPreloadedValue}
      value={
        (Array.isArray(reactSelectProps.value)
          ? reactSelectProps.value
          : [reactSelectProps.value]
        ).map((value) => optionsByValue[value ?? '']) as T[] | undefined
      }
      onChange={(selection) =>
        reactSelectProps.onChange?.(
          (Array.isArray(selection)
            ? selection.map(getOptionValueOrValue)
            : getOptionValueOrValue(selection)) as IsMulti extends true
            ? V[]
            : V,
        )
      }
    />
  );
}
