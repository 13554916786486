import styled from 'styled-components';

import { FlexRowCenterVertical } from '@main/core-ui';

export const ActionsHeaderWrapper = styled(FlexRowCenterVertical)`
  height: 100%;
  color: ${({ theme }) => theme.colors.transcendNavy};
  justify-content: end;
  margin-right: 12px;
`;

export const ActionsCellWrapper = styled(FlexRowCenterVertical)`
  height: 100%;
  color: ${({ theme }) => theme.colors.transcendNavy};
  justify-content: end;
  gap: 8px;
`;
