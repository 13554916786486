import { defineMessages } from '@transcend-io/internationalization';

export const selectDataSiloOwnerMessages = defineMessages(
  'ad-core-components.SelectDataSiloOwner.selectDataSiloOwner',
  {
    label: {
      defaultMessage: 'Choose an assignee',
    },
    info: {
      defaultMessage: 'Type a name or invite to Transcend via email',
    },
    assignSelfButton: {
      defaultMessage: "I'll do it",
    },
    assignButton: {
      defaultMessage: 'Update Assignees',
    },
    inviteAndAssignButton: {
      defaultMessage: 'Invite & Assign',
    },
  },
);
