import { endpoints } from '@main/datamap-types';
import { GqlObject } from '@main/schema-utils';

export const DATA_MAP_DATA_SUBJECT_FIELDS: GqlObject<
  typeof endpoints.internalSubjects.response
> = {
  id: null,
  title: {
    id: null,
    defaultMessage: null,
    description: null,
  },
};
