import {
  DescriptionWrapper,
  LabelWrapper,
  SettingsItem,
  SettingsWrapper,
  Switch,
  TitleWrapper,
} from '@main/ad-core-components';
import React from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import { requestsProcessedDisclosureStatsSettingsSlice } from '../slice';
import { requestsProcessedStatsMessages } from './messages';
import { selectRequestsProcessedDisclosureStatsInput } from './selectors';

/**
 * Change whether requests processed stats table shows in the privacy center
 */
export const ToggleShowRequestsProcessedStats: React.FC = () => {
  const { formatMessage } = useIntl();
  const showRequestsProcessedDisclosureStats = useSelector(
    selectRequestsProcessedDisclosureStatsInput,
  );
  const dispatch = useDispatch();
  return (
    <SettingsWrapper>
      <SettingsItem>
        <LabelWrapper>
          <TitleWrapper>
            {formatMessage(
              requestsProcessedStatsMessages.changeShowRequestsProcessedStats,
            )}
          </TitleWrapper>
          <DescriptionWrapper>
            {formatMessage(
              requestsProcessedStatsMessages.changeShowRequestsProcessedStatsDescription,
            )}
          </DescriptionWrapper>
        </LabelWrapper>
        <Switch
          id="showRequestsProcessedStats"
          onChange={(newShowRequestsProcessedStats) => {
            dispatch(
              requestsProcessedDisclosureStatsSettingsSlice.actions.setRequestsProcessedDisclosureStatsSettings(
                { tableEnabled: newShowRequestsProcessedStats },
              ),
            );
          }}
          checked={!!showRequestsProcessedDisclosureStats.tableEnabled}
        />
      </SettingsItem>
    </SettingsWrapper>
  );
};
