import * as t from 'io-ts';

import { dbModelId } from '@main/schema-utils';

import { AuditEventCode } from '../schema/enums';
import { mkAuditEventCodec } from '../types';

export const AuditEventActionItem = mkAuditEventCodec(
  AuditEventCode.ActionItem,
  t.type({
    /** ID of the actionItem */
    actionItemId: dbModelId('actionItem'),
  }),
);

/** Override types. */
export type AuditEventActionItem = t.TypeOf<typeof AuditEventActionItem>;
