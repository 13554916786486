import { endpoints } from '@main/access-control-types';
import { useInlineInput } from '@main/ad-core-components';
import { buildUseMutation } from '@main/core-ui';
import type { ID } from '@main/schema-utils';
import isEqual from 'lodash/isEqual';
import moment from 'moment';
import React, { useEffect } from 'react';
import { useTheme } from 'styled-components';

import { RowTableContext } from '../types';
import { StyledDueDatePicker } from '../wrappers';

const useUpdateActionItems = buildUseMutation(endpoints.updateActionItems);

interface InlineSelectDueDateProps {
  /** ids of the action items for this cell */
  ids: ID<'actionItem'>[];
  /** The value for the cell */
  value: string;
  /** Queries to refetch on success */
  refetchQueries?: string[];
  /** The table context */
  context: RowTableContext;
}

export const InlineSelectDueDate: React.FC<InlineSelectDueDateProps> = ({
  ids,
  value: initialValue,
  refetchQueries = ['GlobalActionItems', 'GlobalActionItemsSubTable'],
  context,
}) => {
  const theme = useTheme();
  const [updateActionItems, { loading }] = useUpdateActionItems(
    refetchQueries
      ? {
          refetchQueries,
          awaitRefetchQueries: true,
        }
      : {},
  );
  const { value, setValue, onBlur } = useInlineInput<string>({
    initialValue,
    optionsComparator: (value, previousValue) => isEqual(value, previousValue),
    onUpdate: (dueDate) =>
      updateActionItems({
        variables: {
          input: {
            dueDate: (dueDate === '' ? null : dueDate) as any,
            ...(context.count > 1
              ? {
                  queryFilters: context.filters,
                  rollupFilters: [context.rollupFilters],
                }
              : {
                  ids,
                }),
          },
        },
      }).then(({ data, errors }) => ({
        data: dueDate,
        errors,
        success: data?.success,
      })),
  });

  // Hitting the 'x' button on the date picker does not trigger onBlur
  // so we have to use this useEffect to do that manually, so that the
  // updateActionItems method will fire
  useEffect(() => {
    if (value === '') {
      onBlur();
    }
  }, [value]);

  return (
    <StyledDueDatePicker
      onBlur={() => onBlur()}
      value={value ? moment(value).toDate() : undefined}
      onChange={(date) => {
        setValue(date ? date.toISOString() : '');
      }}
      disabled={loading}
      color={
        value && new Date(value) < new Date() ? theme.colors.red1 : undefined
      }
    />
  );
};
