import { RequestStatus } from '@transcend-io/privacy-types';
import { createEnum } from '@transcend-io/type-utils';

import { REQUEST_STATUS_FSM_MATRIX } from './constants';

/**
 * Lookup request status
 */
export const LOOKUP_REQUEST_STATUS = createEnum(Object.values(RequestStatus));

/**
 * Asserts that a string is a valid request status, and that the string is also a valid transition from the current state
 *
 * @param status - The response status from the hook request
 * @param currentStatus - The type of this hook (pre/postflight)
 * @returns True if the resulting status is a valid transition
 */
export function isValidStatus(
  status: string,
  currentStatus: RequestStatus,
): status is RequestStatus {
  return LOOKUP_REQUEST_STATUS[status]
    ? REQUEST_STATUS_FSM_MATRIX[currentStatus][status]
    : false;
}
