import {
  ErrorText,
  GenericMessageDescriptor,
  useFormatMessageGeneric,
} from '@main/core-ui';
import React from 'react';

import { commonFormStatusMessages } from './messages';
import type { SimpleMutationResult } from './types';

interface IFormStatusProps {
  /** override for is success */
  isSuccessful?: boolean;
  /** should we hide the success message (e.g. for use with modal success screen) */
  hideSuccess?: boolean;
  /** override for the error to show */
  error?: GenericMessageDescriptor;
  /** override for is loading */
  isLoading?: boolean;
  /** the message to show upon success */
  successMessage?: GenericMessageDescriptor;
  /** css styles */
  style?: React.CSSProperties;
}
/**
 * convert a mutation result into props for a FormStatus
 */
export function mutationToFormStatusProps({
  data,
  error,
  loading,
}: SimpleMutationResult): Partial<IFormStatusProps> {
  return { isSuccessful: !!data, error, isLoading: loading };
}

/**
 * Displays the status of a form, either success, error, or nothing
 */
export const FormStatus: React.FC<IFormStatusProps> = ({
  isSuccessful,
  hideSuccess,
  error,
  isLoading,
  successMessage,
  style,
}) => {
  const { formatMessageGeneric } = useFormatMessageGeneric();

  return (
    <>
      {!hideSuccess && isSuccessful && !error && !isLoading && (
        <i style={{ marginLeft: '1em', marginRight: '1em', ...(style ?? {}) }}>
          {formatMessageGeneric(
            successMessage ?? commonFormStatusMessages.updateSuccess,
          )}
        </i>
      )}
      {error && <ErrorText error={error} />}
    </>
  );
};
