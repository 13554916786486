import {
  extractFormItemWrapperProps,
  FormItemWrapper,
  IFormItemWrapperProps,
  multipleValidators,
} from '@main/core-ui';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { IFormInputProps } from './FormInput';
import { Input, InputProps } from './Input';

/**
 * A raw password form input for use cases where formatting is not desired
 */
export const FormInputPasswordRaw: React.FC<
  IFormInputProps & Omit<InputProps, 'value' | 'onChange' | 'onBlur'>
> = ({ name, defaultValue = '', rules, ...antInputProps }) => {
  const { control } = useFormContext();
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      rules={rules ? { validate: multipleValidators(rules) } : undefined}
      render={({ field }) => (
        <Input type="password" {...antInputProps} {...field} />
      )}
    />
  );
};

/**
 * A password form input with formatting and error display
 */
export const FormInputPassword: React.FC<
  IFormInputProps & InputProps & Omit<IFormItemWrapperProps, 'errors'>
> = (props) => {
  const {
    formState: { errors },
  } = useFormContext();
  const { passthroughProps, wrapperProps } = extractFormItemWrapperProps(props);

  return (
    <FormItemWrapper errors={errors} {...wrapperProps}>
      <FormInputPasswordRaw {...passthroughProps} />
    </FormItemWrapper>
  );
};
