import * as t from 'io-ts';

import { dbModelId } from '@main/schema-utils';

import { AuditEventCode } from '../schema/enums';
import { mkAuditEventCodec } from '../types';

export const AuditEventAssessmentEmailSet = mkAuditEventCodec(
  AuditEventCode.AssessmentEmailSet,
  t.type({
    /** ID of the assessment email set */
    assessmentFormTemplateId: dbModelId('assessmentEmailSet'),
  }),
);

/** Override types. */
export type AuditEventAssessmentEmailSet = t.TypeOf<
  typeof AuditEventAssessmentEmailSet
>;
