import { IntlShape } from 'react-intl';

import { ActionItemCode } from '@transcend-io/privacy-types';

import { GlobalActionItemContext } from '@main/access-control-types';

import { ACTION_ITEM_FRONTEND_MAPPING } from '../constants';

/**
 * Gets a translated example message for an action item code
 *
 * @param formatMessage - useIntl
 * @param code - the code to fetch
 * @returns the formatted example message
 */
export function getActionItemExampleMessage(
  formatMessage: IntlShape['formatMessage'],
  code: ActionItemCode,
): string {
  // mock context to pass to the message
  const exampleContext: Required<GlobalActionItemContext> & {
    /** raw count */
    count: string | number;
    /** formatted count */
    prettyCount: string | number;
    /** the model title */
    title: string;
  } = {
    count: 15,
    prettyCount: 15,
    // row metadata
    title: 'Example',
    parentTitle: 'Parent',
    iconOverride: '',
    requestId: 'request-id' as any,
    dataSiloId: 'datasilo-id' as any,
    requestType: 'ACCESS',
    latestAirgapVersion: '7.19.20',
  };
  return formatMessage(
    ACTION_ITEM_FRONTEND_MAPPING[code].richMessage,
    exampleContext,
  );
}
