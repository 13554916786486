/* eslint-disable max-lines */
import {
  ConsentTrackerSource,
  ConsentTrackerStatus,
} from '@transcend-io/privacy-types';

import { TeamPreview, UserPreview } from '@main/access-control-types';
import { AttributeInput, AttributeValue } from '@main/attribute-types';
import { Catalog } from '@main/datamap-types';
import { mkInput, mkOrder, mkType, SchemaToType } from '@main/schema-utils';

import { CookieOrderField } from './enums';
import { TrackingPurpose } from './purpose';

export const CookieDomain = mkType({
  name: 'CookieDomain',
  comment: 'A domain associated with an Airgap cookie',
  fields: {
    id: {
      comment: 'The ID of this cookie domain',
      type: 'id',
      modelName: 'airgapCookieDomain',
    },
    domain: {
      comment: 'The domain value',
      type: 'string',
    },
    lastDiscoveredAt: {
      comment: 'The last time this particular cookie was encountered',
      type: 'Date',
      optional: true,
    },
    occurrences: {
      comment: 'The number of occurrences for this cookie-host pair',
      type: 'int',
    },
  },
});

/** Override type */
export type CookieDomain = SchemaToType<typeof CookieDomain>;

export const Cookie = mkType({
  name: 'Cookie',
  comment: 'An Airgap cookie config',
  fields: {
    id: {
      comment: 'The ID of this cookie',
      type: 'id',
      modelName: 'airgapCookie',
    },
    description: {
      comment: 'The description for this cookie',
      type: 'string',
      optional: true,
    },
    name: {
      comment: 'Name or regex matching the name of this cookie',
      type: 'string',
    },
    trackingPurposes: {
      comment: 'The tracking purposes that apply to this cookie',
      type: 'string',
      list: true,
    },
    purposes: {
      comment: 'A list of tracking purposes associated with this cookie',
      type: TrackingPurpose,
      list: true,
    },
    frequency: {
      comment: 'The aggregated relative frequency of this cookie',
      type: 'float',
    },
    service: {
      comment: 'The SaaS tool associated with these cookies',
      type: Catalog,
      optional: true,
    },
    isJunk: {
      comment: 'Whether Airgap should ignore this cookie',
      type: 'boolean',
    },
    isRegex: {
      comment: 'Whether this cookie name is a regex',
      type: 'boolean',
    },
    source: {
      comment: 'The source of this cookie, (scanned or manually entered)',
      type: { ConsentTrackerSource },
    },
    status: {
      comment: 'The status of this cookie',
      type: { ConsentTrackerStatus },
    },
    createdAt: {
      comment: 'The time the cookie was created',
      type: 'Date',
    },
    updatedAt: {
      comment: 'The time the cookie was last updated',
      type: 'Date',
    },
    encounters: {
      comment: 'List of places this cookie was encountered',
      optional: true,
      list: true,
      type: 'string',
    },
    lastDiscoveredAt: {
      comment: 'The last time this particular cookie was encountered',
      type: 'Date',
      optional: true,
    },
    domains: {
      comment: 'List of domains associated with this cookie',
      list: true,
      type: CookieDomain,
    },
    attributeValues: {
      comment: 'The attribute values used to label this cookie',
      type: AttributeValue,
      list: true,
    },
    owners: {
      comment:
        'The list of individual users who are responsible for managing this cookie',
      list: true,
      type: UserPreview,
    },
    teams: {
      comment: 'The list of teams who are responsible for managing this cookie',
      list: true,
      type: TeamPreview,
    },
    pendingTelemetryMetadataSync: {
      comment:
        'Whether or not this cookie has a pending telemetry metadata sync',
      type: 'boolean',
    },
    occurrences: {
      comment: 'The number of times this cookie has been seen',
      type: 'float',
    },
  },
});

/** Override type */
export type Cookie = SchemaToType<typeof Cookie>;

export const CookiesFiltersInput = mkInput({
  name: 'CookiesFiltersInput',
  comment: 'Filters that can be applied when querying for cookies',
  fields: {
    trackingPurposes: {
      comment: 'Filter for cookies that have a certain tracking purposes',
      type: 'string',
      list: true,
      optional: true,
    },
    purposeIds: {
      comment: 'Filter for cookies of a certain purpose',
      optional: true,
      type: 'id',
      modelName: 'purpose',
      list: true,
    },
    text: {
      comment: 'Filter cookies by text',
      type: 'string',
      optional: true,
    },
    isJunk: {
      comment: 'Filter for cookies marked as "junk"',
      type: 'boolean',
      optional: true,
    },
    source: {
      comment: 'Filter for cookies by their source',
      type: { ConsentTrackerSource },
      optional: true,
    },
    status: {
      comment: "Filter for a cookie's status",
      type: { ConsentTrackerStatus },
      optional: true,
    },
    ownerIds: {
      comment: 'Filter by the assigned owner IDs',
      optional: true,
      type: 'id',
      modelName: 'user',
      list: true,
    },
    teamIds: {
      comment: 'Filter by the assigned team IDs',
      optional: true,
      type: 'id',
      modelName: 'team',
      list: true,
    },
    attributeValueIds: {
      comment: 'Filter by the attribute values used to label the cookies',
      type: 'id',
      modelName: 'attributeValue',
      list: true,
      optional: true,
    },
    service: {
      comment: 'Filter for cookies by their associated service',
      type: 'string',
      optional: true,
    },
    isRegex: {
      comment: 'Filter for regex cookie matchers (or an exact name match)',
      type: 'boolean',
      optional: true,
    },
    showZeroActivity: {
      comment:
        'Whether or not to show cookies with zero activity in the past 1 day. Defaults to false.',
      type: 'boolean',
      optional: true,
    },
    createdAt: {
      comment: 'Filter for cookies by when they were created',
      type: 'string',
      optional: true,
    },
    lastDiscoveredAt: {
      comment: 'Filter for cookies by when they were last encountered',
      type: 'string',
      optional: true,
    },
    encounters: {
      comment: 'Filter for cookies by the urls they have been encountered at',
      optional: true,
      type: 'string',
    },
    domains: {
      comment: 'Filter for cookies by the domains associated with a cookie',
      optional: true,
      type: 'string',
    },
    minOccurrences: {
      comment:
        'Filter for cookies with a minimum number of times the cookie has been seen',
      optional: true,
      type: 'int',
    },
    matchesCookie: {
      comment: 'Filter for cookies that match a real cookie name',
      optional: true,
      type: 'string',
    },
  },
});

/** Override type */
export type CookiesFiltersInput = SchemaToType<typeof CookiesFiltersInput>;

/*
 * Order for a dataFlows query
 */
export const CookieOrder = mkOrder(Cookie.name, CookieOrderField);

/** Override type */
export type CookieOrder = SchemaToType<typeof CookieOrder>;

export const UpdateOrCreateCookieInput = mkInput({
  name: 'UpdateOrCreateCookieInput',
  comment: 'Input to update or create many Airgap cookies',
  fields: {
    name: {
      comment: 'The name or regex matching the name of the cookie',
      type: 'string',
    },
    trackingPurposes: {
      comment: 'The tracking purposes that apply to this cookie',
      type: 'string',
      list: true,
      optional: true,
    },
    purposeIds: {
      comment: 'The purposes that apply to this cookie',
      modelName: 'purpose',
      type: 'id',
      list: true,
      optional: true,
    },
    description: {
      comment: 'The description for this cookie',
      type: 'string',
      optional: true,
    },
    service: {
      comment: 'The name of the SaaS tool associated with this cookie',
      type: 'string',
      optional: true,
    },
    integrationName: {
      comment:
        'The name of the integration associated with this cookie. Often the same as service but not always',
      type: 'string',
      optional: true,
    },
    ownerIds: {
      comment: 'The unique ids of the users to assign as owners of this cookie',
      modelName: 'user',
      type: 'id',
      optional: true,
      list: true,
    },
    teamIds: {
      comment:
        'The ids of the teams that should be responsible for this cookie',
      type: 'id',
      modelName: 'team',
      list: true,
      optional: true,
    },
    isJunk: {
      comment: 'Whether or not Airgap should ignore this cookie',
      type: 'boolean',
      optional: true,
    },
    status: {
      comment: 'The status of this cookie',
      type: { ConsentTrackerStatus },
      optional: true,
    },
    isRegex: {
      comment: 'Whether the cookie name is a regex',
      type: 'boolean',
      optional: true,
    },
    attributes: {
      comment: 'The attribute values used to label this cookie',
      type: AttributeInput,
      list: true,
      optional: true,
    },
    source: {
      comment: 'The source of this cookie',
      type: { ConsentTrackerSource },
      optional: true,
    },
  },
});

/** Override type */
export type UpdateOrCreateCookieInput = SchemaToType<
  typeof UpdateOrCreateCookieInput
>;

export const UpdateOrCreateCookiesInput = mkInput({
  name: 'UpdateOrCreateCookiesInput',
  comment: 'Input to bulk update-or-create cookies',
  fields: {
    airgapBundleId: {
      comment: 'The airgap bundle for which we want to bulk upsert cookies',
      type: 'id',
      modelName: 'airgapBundle',
    },
    cookies: {
      comment: 'The cookies to bulk upsert',
      type: UpdateOrCreateCookieInput,
      list: true,
    },
  },
});

/** Override type */
export type UpdateOrCreateCookiesInput = SchemaToType<
  typeof UpdateOrCreateCookiesInput
>;

export const DeleteCookiesInput = mkInput({
  name: 'DeleteCookiesInput',
  comment: 'Input to delete cookies',
  fields: {
    airgapBundleId: {
      comment: 'The airgap bundle to delete the cookies for',
      type: 'id',
      modelName: 'airgapBundle',
    },
    ids: {
      comment: 'Delete cookies with ID in this list',
      type: 'id',
      modelName: 'airgapCookie',
      list: true,
      optional: true,
    },
    status: {
      comment: 'The status of this cookie',
      type: { ConsentTrackerStatus },
      optional: true,
    },
  },
});

/** Override type */
export type DeleteCookiesInput = SchemaToType<typeof DeleteCookiesInput>;

export const ClassifyCookiesInput = mkInput({
  name: 'ClassifyCookiesInput',
  comment: 'Input to classify cookies to be created',
  fields: {
    cookies: {
      comment: 'The cookies to classify',
      type: UpdateOrCreateCookieInput,
      list: true,
    },
  },
});

/** Override type */
export type ClassifyCookiesInput = SchemaToType<typeof ClassifyCookiesInput>;

export const CookieSuggestion = mkType({
  name: 'CookieSuggestion',
  comment: 'Suggestions for certain cookie fields based on user inputted name',
  fields: {
    name: {
      comment: 'Name or regex matching the name of this cookie',
      type: 'string',
    },
    isRegex: {
      ...Cookie.fields.isRegex,
      comment: 'The suggestion of whether this cookie name is a regex',
    },
    purposes: {
      ...Cookie.fields.purposes,
      comment:
        'The suggested tracking purposes for this cookie based on name classification',
    },
    service: {
      ...Cookie.fields.service,
      comment:
        'The suggested SaaS tool for this cookie based on name classification',
    },
    description: {
      ...Cookie.fields.description,
      comment: 'The suggested description for this cookie',
    },
  },
});

/** Override type */
export type CookieSuggestion = SchemaToType<typeof CookieSuggestion>;
/* eslint-enable max-lines */
