import { DataFlowScope } from '@transcend-io/privacy-types';

import { isValidCSPEntryHost, isValidHost, view } from '@main/utils';

import {
  isValidAbsoluteCSPEntry,
  isValidAbsoluteURL,
} from './isValidAbsoluteURL';
import type { DataFlowDescriptor } from './types';
import { usesNonStandardProtocol } from './usesNonStandardProtocol';

const getHost = (url: string): string => {
  // TODO: https://transcend.height.app/T-33858 - remove isRelativeProtocol when CSP is updated
  const isRelativeProtocol = url.startsWith('//');
  try {
    return isRelativeProtocol
      ? new view.URL(url, 'https://-').host
      : new view.URL(url).host;
  } catch (ex) {
    return '';
  }
};

/**
 * Get data flow descriptor from data flow modal input
 *
 * TODO: https://transcend.height.app/T-24099 - add support for RegExp
 *
 * @param input - Entered URL
 * @returns Data flow UI hints
 */
export const parseDataFlowInput = (input: string): DataFlowDescriptor[] => {
  const dataFlows: DataFlowDescriptor[] = [];
  if (input.length === 0) {
    return dataFlows;
  }
  // Only CSP entries can start with *.
  if (input.startsWith('*.')) {
    const host = input.slice(2);
    if (isValidHost(host)) {
      dataFlows.push({
        scope: DataFlowScope.Host,
        value: host,
      });
    }
    dataFlows.push({
      scope: DataFlowScope.CSP,
      value: input,
    });
    return dataFlows;
  }
  // Query params
  if (input.startsWith('?')) {
    dataFlows.push({
      scope: DataFlowScope.QueryParam,
      value: input,
    });
    return dataFlows;
  }
  // User pasted a full absolute URL; they likely want host-only scope
  if (isValidAbsoluteURL(input)) {
    if (usesNonStandardProtocol(input)) {
      dataFlows.push({
        scope: DataFlowScope.CSP,
        value: input,
      });
    }
    const host = getHost(input);
    if (!['-', ''].includes(host)) {
      dataFlows.push({
        scope: DataFlowScope.Host,
        value: host,
      });
    }
    return dataFlows;
  }
  // Absolute URL with CSP wildcard syntax
  if (isValidAbsoluteCSPEntry(input)) {
    dataFlows.push({
      scope: DataFlowScope.CSP,
      value: input,
    });
    return dataFlows;
  }
  // Bare host
  if (isValidHost(input) && !isValidAbsoluteURL(input)) {
    dataFlows.push({
      scope: DataFlowScope.Host,
      value: getHost(`https://${input}`),
    });
    return dataFlows;
  }
  // Bare host with CSP wildcard syntax
  if (isValidCSPEntryHost(input) && !isValidAbsoluteURL(input)) {
    dataFlows.push({
      scope: DataFlowScope.CSP,
      value: input,
    });
  }
  // Any-site path matcher syntax (///)
  if (input.startsWith('///')) {
    dataFlows.push({
      scope: DataFlowScope.Path,
      value: input,
    });
    return dataFlows;
  }
  // Relative protocol URL syntax (//)
  if (input.startsWith('//')) {
    dataFlows.push({
      scope: DataFlowScope.Host,
      value: getHost(input),
    });
    dataFlows.push({
      scope: DataFlowScope.Path,
      value: input,
    });
    return dataFlows;
  }
  // Relative path URL syntax (/)
  if (input.startsWith('/')) {
    dataFlows.push({
      scope: DataFlowScope.Path,
      value: input,
    });
    return dataFlows;
  }
  // Pass all unhandled input to path matchers by default
  dataFlows.push({
    scope: DataFlowScope.Path,
    value: input,
  });
  return dataFlows;
};

/**
 * Get data flow descriptor from telemetry host data
 *
 * @param input - Entered URL
 * @returns Data flow UI hints
 */
export const parseTelemetryHost = (input: string): DataFlowDescriptor[] => {
  const dataFlows: DataFlowDescriptor[] = [];
  if (isValidAbsoluteURL(input) && usesNonStandardProtocol(input)) {
    const { host, origin } = new view.URL(input);
    dataFlows.push(
      {
        scope: DataFlowScope.Host,
        value: host,
      },
      {
        scope: DataFlowScope.CSP,
        value: origin,
      },
    );
    return dataFlows;
  }
  if (isValidHost(input)) {
    dataFlows.push({
      scope: DataFlowScope.Host,
      value: input,
    });
    return dataFlows;
  }
  throw new Error(`Unexpected telemetry host format: ${input}`);
};
