/* eslint-disable max-lines */
import {
  DataPointExportMode,
  RequestFileDataPointPreview,
} from '@main/datamap-types';
import { DefinedMessage } from '@main/internationalization';
import {
  mkInput,
  mkInterface,
  mkOrder,
  mkType,
  SchemaToType,
} from '@main/schema-utils';

import {
  DownloadFileOrderField,
  RequestFileOrderField,
  RequestFileQueueType,
  RequestFileStatus,
} from './enums';

export const RequestFileFiltersInput = mkInput({
  name: 'RequestFileFiltersInput',
  comment: 'The input for filtering requestFiles',
  fields: {
    profileDataPointIds: {
      comment: `Provide a list of ProfileDataPoints to filter by.
    Cannot be provided with "requestId", "profileId" or "dataSiloId"`,
      modelName: 'ProfileDataPoint',
      optional: true,
      list: true,
      type: 'id',
    },
    profileId: {
      comment: `The id of the profile to get the files for.
    Cannot be provided with "requestId", "profileDataPointId" or "dataSiloId`,
      modelName: 'profile',
      optional: true,
      type: 'id',
    },
    requestId: {
      comment:
        'The id of the request to get files for. Cannot be provided with "profileDataPointId" or "profileId"',
      modelName: 'request',
      optional: true,
      type: 'id',
    },
    dataSiloId: {
      comment:
        'The id of the data silo to get files for. Must be provided with "requestId"',
      modelName: 'dataSilo',
      optional: true,
      type: 'id',
    },
    exportMode: {
      comment: 'The export mode on the dataPoint',
      optional: true,
      list: true,
      type: { DataPointExportMode },
    },
  },
});

/** Override type */
export type RequestFileFiltersInput = SchemaToType<
  typeof RequestFileFiltersInput
>;

export const RequestFileTokenFiltersInput = mkInput({
  name: 'RequestFileTokenFiltersInput',
  comment: 'The input for filtering requestFiles using token based auth',
  fields: {
    profileDataPointIds: {
      comment: `Provide a list of ProfileDataPoints to filter by.`,
      modelName: 'ProfileDataPoint',
      list: true,
      type: 'id',
    },
  },
});

/** Override type */
export type RequestFileTokenFiltersInput = SchemaToType<
  typeof RequestFileTokenFiltersInput
>;

export const DownloadFileFiltersInput = mkInput({
  name: 'DownloadFileFiltersInput',
  comment: 'The input for filtering downloadFiles',
  fields: {
    requestId: {
      comment: 'The id of the request to get files for.',
      modelName: 'request',
      type: 'id',
    },
    dataCollectionId: {
      comment: 'The id of the data collection to get files for',
      modelName: 'dataCollection',
      optional: true,
      type: 'id',
    },
  },
});

/** Override type */
export type DownloadFileFiltersInput = SchemaToType<
  typeof DownloadFileFiltersInput
>;

export const SelectionInput = mkInput({
  name: 'SelectionInput',
  comment: 'Input for a redaction selection',
  fields: {
    start: {
      comment: 'Start of redaction (inclusive)',
      type: 'int',
    },
    end: {
      comment: 'End of redaction (exclusive)',
      type: 'int',
    },
  },
});

/** Override type */
export type SelectionInput = SchemaToType<typeof SelectionInput>;

export const RedactPathInput = mkInput({
  name: 'RedactPathInput',
  comment: 'The JSON path to redact',
  fields: {
    path: {
      comment: 'The JSON path itself',
      type: 'string',
    },
    selections: {
      comment: 'The selections to redact',
      list: true,
      type: SelectionInput,
    },
  },
});

/** Override type */
export type RedactPathInput = SchemaToType<typeof RedactPathInput>;

export const RedactRequestFileInput = mkInput({
  name: 'RedactRequestFileInput',
  comment: 'Input to update the redact indices for a request file',
  fields: {
    requestFileId: {
      comment: 'The id of the request file to redact',
      modelName: 'requestFile',
      type: 'id',
    },
    redactionIndices: {
      comment: 'The JSON paths and indices to redact',
      list: true,
      type: RedactPathInput,
    },
  },
});

/** Override type */
export type RedactRequestFileInput = SchemaToType<
  typeof RedactRequestFileInput
>;

export const RedactRequestFilesInput = mkInput({
  name: 'RedactRequestFilesInput',
  comment: 'Input to update the redact indices for a set of request files',
  fields: {
    redactions: {
      comment: 'The set of redactions to make',
      list: true,
      type: RedactRequestFileInput,
    },
  },
});

/** Override type */
export type RedactRequestFilesInput = SchemaToType<
  typeof RedactRequestFilesInput
>;

export const RequestFileInterface = mkInterface({
  name: 'RequestFileInterface',
  comment: 'A downloadable encrypted file associated with a request',
  fields: {
    size: {
      comment:
        'The file size in bytes as a string because graphql does not play well with larger numbers',
      type: 'string',
    },
    mimetype: {
      comment: 'The mimetype of the file',
      type: 'string',
    },
    id: {
      comment: 'The unique id of the request file',
      modelName: 'requestFile',
      type: 'id',
    },
    downloadUrl: {
      comment: 'The URL to download the file',
      type: 'string',
    },
    fileName: {
      comment: 'The name of the file once downloaded',
      type: 'string',
    },
    authTag: {
      comment: 'The authTag of the encrypted file',
      type: 'string',
    },
    iv: {
      comment: 'The iv randomness of the encrypted file',
      type: 'string',
    },
    redactIndices: {
      comment: 'Stringified redaction indices',
      type: 'string',
    },
  },
});

/** Override type */
export type RequestFileInterface = SchemaToType<typeof RequestFileInterface>;

export const DownloadFile = mkType({
  name: 'DownloadFile',
  comment: 'A downloadable encrypted file associated with a request',
  interfaces: [RequestFileInterface],
  fields: {
    ...RequestFileInterface.fields,
    title: {
      comment: 'The title of the file',
      type: DefinedMessage,
      optional: true,
    },
    description: {
      comment: 'The description of the file',
      type: DefinedMessage,
      optional: true,
    },
  },
});

/** Override type */
export type DownloadFile = SchemaToType<typeof DownloadFile>;

export const DownloadFileCounts = mkType({
  name: 'DownloadFileCounts',
  comment: 'A file count associated with a request',
  fields: {
    dataPointId: {
      comment: 'The id of the dataPoint',
      type: 'string',
    },
    name: {
      comment: 'The name of the datapoint',
      type: 'string',
    },
    title: {
      comment: 'The title of the file',
      type: DefinedMessage,
      optional: true,
    },
    fileCount: {
      comment: 'The number of files associated with the datapoint',
      type: 'int',
    },
  },
});

/** Override type */
export type DownloadFileCounts = SchemaToType<typeof DownloadFileCounts>;

export const RequestFile = mkType({
  name: 'RequestFile',
  comment: 'A download file viewed from the administrator perspective',
  interfaces: [RequestFileInterface],
  fields: {
    ...RequestFileInterface.fields,
    ProfileDataPointId: {
      comment: 'ID of the profile datapoint',
      modelName: 'ProfileDataPoint',
      type: 'id',
    },
    error: {
      comment: 'The error that occurred when processing the request file',
      optional: true,
      type: 'string',
    },
    createdAt: {
      comment: 'The time that the file was created',
      type: 'Date',
    },
    remoteId: {
      comment: 'The id of the file in the remote system',
      optional: true,
      type: 'string',
    },
    link: {
      comment: 'A link to the file in the remote system',
      optional: true,
      type: 'string',
    },
    status: {
      comment:
        'The status of that request file in respect to being processed in the remote system',
      type: { RequestFileStatus },
    },
    queueType: {
      comment:
        'The type of action that will be taken when secondary action happens',
      type: { RequestFileQueueType },
    },
    peekUrl: {
      comment:
        'The URL of the file to use if need to peek at the file contents.',
      type: 'string',
    },
    context: {
      comment: 'Stringified context',
      type: 'string',
    },
    redactIndices: {
      comment: 'Stringified redaction indices',
      type: 'string',
    },
    // TODO: https://github.com/transcend-io/main/pull/5254/files - only reveal to sombra?
    downloadKey: {
      comment:
        'The base64 encoded key containing information needed to download the file',
      optional: true,
      type: 'string',
    },
    scheduledAt: {
      comment: 'When the next action is scheduled',
      type: 'Date',
    },
    dataPoint: {
      comment: 'The data point that this file is associated with',
      type: () => RequestFileDataPointPreview,
    },
  },
});

/** Override type */
export type RequestFile = SchemaToType<typeof RequestFile>;

/**
 * Order for a requestFile query
 */
export const RequestFileOrder = mkOrder(
  RequestFile.name,
  RequestFileOrderField,
);

/** Override type */
export type RequestFileOrder = SchemaToType<typeof RequestFileOrder>;

/**
 * Order for a downloadFile query
 */
export const DownloadFileOrder = mkOrder(
  DownloadFile.name,
  DownloadFileOrderField,
);

/** Override type */
export type DownloadFileOrder = SchemaToType<typeof DownloadFileOrder>;

export const UpdateRequestFileStatusInput = mkInput({
  name: 'UpdateRequestFileStatusInput',
  comment: 'Input to update the Request File status.',
  fields: {
    id: {
      comment: 'Request File Id',
      type: 'id',
      modelName: 'requestFile',
    },
    status: {
      comment: 'Request File status',
      type: { RequestFileStatus },
    },
  },
});

/** Override type */
export type UpdateRequestFileStatusInput = SchemaToType<
  typeof UpdateRequestFileStatusInput
>;

export const MarkLinkOnlyRequestFileAsReadyInput = mkInput({
  name: 'MarkLinkOnlyRequestFileAsReadyInput',
  comment:
    'Input to mark all link-only Request Files as Ready, associated with a given Request Data Silo',
  fields: {
    id: {
      comment: 'Request Data Silo Id',
      type: 'id',
      modelName: 'RequestDataSilo',
    },
    status: {
      comment: 'Request File status',
      type: { RequestFileStatus },
    },
  },
});

/** Override type */
export type MarkLinkOnlyRequestFileAsReadyInput = SchemaToType<
  typeof MarkLinkOnlyRequestFileAsReadyInput
>;
/* eslint-enable max-lines */
