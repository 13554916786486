/* eslint-disable max-lines */
import { FeatureSet } from '@main/access-control-types/src/schema';
import { mkInput, mkType, SchemaToType } from '@main/schema-utils';
import { Sombra } from '@main/sombra-types';

import {
  ActionItemSubscriptionNotificationMethod,
  ConsentManagerOnboardingStatus,
  OrganizationTier,
  TestEmailFailurePoint,
} from './enums';
import { AssetFile } from './TODO';

export const SelfServeSignupInput = mkInput({
  name: 'SelfServeSignupInput',
  comment: 'Input for onboarding user and organization via onboarding wizard',
  fields: {
    name: {
      comment: 'The name of the user',
      type: 'string',
    },
    email: {
      comment: 'The email of the user',
      type: 'string',
    },
    organizationName: {
      comment: 'The name of the organization',
      type: 'string',
    },
    companyWebsite: {
      comment: 'The website of the organization',
      type: 'string',
    },
    uri: {
      comment: 'The uri of the organization',
      optional: true,
      type: 'string',
    },
    domain: {
      comment: 'The domain of the organization',
      type: 'string',
    },
    code: {
      comment: 'Signup code to prevent random signups',
      type: 'string',
      optional: true,
    },
  },
});

/** Override type */
export type SelfServeSignupInput = SchemaToType<typeof SelfServeSignupInput>;

export const UpdateOrganizationInput = mkInput({
  name: 'UpdateOrganizationInput',
  comment: "Input for updating the organization's info",
  fields: {
    name: {
      comment: 'The name of the organization',
      type: 'string',
      optional: true,
    },
    description: {
      comment: 'The description of the organization',
      type: 'string',
      optional: true,
    },
    defaultEmailAlias: {
      comment: 'The default pretty alias used for emails',
      type: 'string',
      optional: true,
    },
  },
});

/** Override type */
export type UpdateOrganizationInput = SchemaToType<
  typeof UpdateOrganizationInput
>;

export const OrganizationNotificationWhitelistItem = mkType({
  name: 'OrganizationNotificationWhitelistItem',
  comment: 'A whitelisted org-level notification channel',
  fields: {
    name: {
      comment: 'The name of an org-level notification channel',
      type: 'string',
    },
    identifier: {
      comment:
        'The destination identifier of an org-level notification channel. E.g. slack channel ID or email address',
      type: 'string',
    },
    notificationMethod: {
      comment: 'Method of notification for this channel',
      type: { ActionItemSubscriptionNotificationMethod },
    },
  },
});

/** Override type */
export type OrganizationNotificationWhitelistItem = SchemaToType<
  typeof OrganizationNotificationWhitelistItem
>;

export const Organization = mkType({
  name: 'Organization',
  comment: 'An organization on transcend',
  fields: {
    id: {
      comment: 'The unique UUID of the organization',
      modelName: 'organization',
      type: 'id',
    },
    parentOrganizationId: {
      comment: 'The unique UUID of the parent organization',
      modelName: 'organization',
      type: 'id',
      optional: true,
    },
    name: {
      comment: 'The display name of the organization',
      type: 'string',
    },
    description: {
      comment: 'The description of the organization',
      type: 'string',
    },
    createdAt: {
      comment: 'The time when the organization was created',
      type: 'Date',
    },
    uri: {
      comment:
        'The unique uri slug to identify the organization in a human-readable way',
      type: 'string',
    },
    domain: {
      comment: "The domain the organization's main website resides on",
      type: 'string',
    },
    transcendHostedDomain: {
      comment: "The domain the organization's privacy center resides on",
      type: 'string',
    },
    logo: {
      comment: 'Logo taken from privacy center',
      optional: true,
      type: AssetFile,
    },
    favicon: {
      comment: 'Logo taken from privacy center',
      optional: true,
      type: AssetFile,
    },
    tier: {
      comment: "The organization's tier",
      type: { OrganizationTier },
    },
    deployedPrivacyCenterUrl: {
      comment: 'The deployed privacy center configuration.',
      type: 'string',
    },
    sombra: {
      comment: 'The associated Sombra gateway configuration',
      type: Sombra,
    },
    sombras: {
      comment: 'The associated Sombra gateway configuration',
      type: Sombra,
      list: true,
    },
    featureSet: {
      comment: 'Set of features that an organization has access to',
      type: FeatureSet,
    },
    defaultEmailAlias: {
      comment: 'The default pretty alias used for emails',
      type: 'string',
      optional: true,
    },
    notificationsWhitelist: {
      comment: 'The whitelist of allowed org-level notification channels',
      type: OrganizationNotificationWhitelistItem,
      optional: true,
      list: true,
    },
    awsExternalId: {
      comment: 'The external id used for AWS cross-account access',
      type: 'string',
      optional: true,
    },
  },
});

/** Override type */
export type Organization = SchemaToType<typeof Organization>;

// TODO interface
export const OrganizationPreview = mkType({
  name: 'OrganizationPreview',
  comment: `The preview of an organization when connected to some other context
that does not have access to the full organization model`,
  fields: {
    id: Organization.fields.id,
    name: Organization.fields.name,
    uri: Organization.fields.uri,
    parentOrganizationId: Organization.fields.parentOrganizationId,
    sombraPublicKeyECDH: Sombra.fields.publicKeyECDH,
    favicon: Organization.fields.favicon,
    featureSet: Organization.fields.featureSet,
    ssoDomains: {
      comment:
        'If the company has SSO login, the domains of emails that can authenticate with SSO.',
      type: 'string',
      optional: true,
      list: true,
    },
  },
});

/** Override type */
export type OrganizationPreview = SchemaToType<typeof OrganizationPreview>;

export const UpdateConsentManagerOnboardingStatusInput = mkInput({
  name: 'UpdateConsentManagerOnboardingStatusInput',
  comment: 'Input for updating a consent manager status',
  fields: {
    id: {
      comment: 'The ID of the Consent Manager bundle to update.',
      modelName: 'airgapBundle',
      type: 'id',
    },
    onboardingStatus: {
      comment: 'The onboarding status of this consent manager',
      type: { ConsentManagerOnboardingStatus },
    },
  },
});

/** Override type */
export type UpdateConsentManagerOnboardingStatusInput = SchemaToType<
  typeof UpdateConsentManagerOnboardingStatusInput
>;

export const OrgNotificationDestinationAllowListEntry = mkType({
  name: 'OrgNotificationDestinationAllowListEntry',
  comment: 'An AllowListed notification destination',
  fields: {
    name: {
      comment: 'How this identifier will be displayed',
      type: 'string',
    },
    identifier: {
      comment:
        'Raw identifier that will be user when sending notifications e.g. email address',
      type: 'string',
    },
    notificationMethod: {
      comment:
        'Raw identifier that will be user when sending notifications e.g. email address',
      type: { ActionItemSubscriptionNotificationMethod },
    },
  },
});

/** Override type */
export type OrgNotificationDestinationAllowListEntry = SchemaToType<
  typeof OrgNotificationDestinationAllowListEntry
>;

export const OrgNotificationDestinationAllowListEntryInput = mkInput({
  name: 'OrgNotificationDestinationAllowListEntryInput',
  comment: 'An AllowListed notification destination',
  fields: {
    ...OrgNotificationDestinationAllowListEntry.fields,
  },
});

/** Override type */
export type OrgNotificationDestinationAllowListEntryInput = SchemaToType<
  typeof OrgNotificationDestinationAllowListEntryInput
>;

export const SetOrgNotificationDestinationsInput = mkInput({
  name: 'AllowNotificationDestinationsInput',
  comment: 'Update notifications AllowList, slack channel ids, emails, etc',
  fields: {
    orgNotificationDestinationAllowList: {
      comment: 'The onboarding status of this consent manager',
      type: OrgNotificationDestinationAllowListEntryInput,
      list: true,
    },
  },
});

/** Override type */
export type SetOrgNotificationDestinationsInput = SchemaToType<
  typeof SetOrgNotificationDestinationsInput
>;

export const ToggleAccountMoveSettingsInput = mkInput({
  name: 'ToggleAccountMoveSettingsInput',
  comment: 'Toggle regional account move settings for an organisation',
  fields: {
    organizationId: {
      type: 'id',
      modelName: 'organization',
      comment: 'Organisation ID to put this hex on',
    },
    lockoutNonTranscendUsers: {
      type: 'boolean',
      comment: 'Lock out non-Transcend users',
    },
    setPendingMigration: {
      type: 'boolean',
      comment: 'Set the pendingMigration flag on the organization row',
    },
  },
});

/** Override type */
export type ToggleAccountMoveSettingsInput = SchemaToType<
  typeof ToggleAccountMoveSettingsInput
>;

export const OrgNotificationDestinationAllowList = mkType({
  name: 'OrgNotificationDestinationAllowList',
  comment: 'An AllowList of notification destinations',
  fields: {
    entries: {
      comment: 'allow list entries',
      type: OrgNotificationDestinationAllowListEntry,
      list: true,
    },
  },
});

/** Override type */
export type OrgNotificationDestinationAllowList = SchemaToType<
  typeof OrgNotificationDestinationAllowList
>;

export const SendTestEmailInput = mkInput({
  name: 'SendTestEmailInput',
  comment: 'Send a test email through sombra',
  fields: {
    sombraId: {
      type: 'id',
      modelName: 'sombra',
      comment:
        'The unique ID for your self-hosted sombra instance (useful if there are multiple instances)',
      optional: true,
    },
    destinationAddress: {
      type: 'string',
      comment: 'The email address to which to send the test email',
    },
    useCustomDomain: {
      type: 'boolean',
      comment: 'Whether or not to use a custom email domain',
      optional: true,
    },
  },
});

/** Override type */
export type SendTestEmailInput = SchemaToType<typeof SendTestEmailInput>;

export const SendTestEmailResponse = mkType({
  name: 'SendTestEmailResponse',
  comment: 'Response for sending a test email',
  fields: {
    success: {
      comment: 'Was the email sent successfully',
      type: 'boolean',
    },
    errorMessage: {
      comment: 'The error message returned',
      type: 'string',
      optional: true,
    },
    failurePoint: {
      comment: 'Where did the error occur',
      type: { TestEmailFailurePoint },
      optional: true,
    },
  },
});

/** Override type */
export type SendTestEmailResponse = SchemaToType<typeof SendTestEmailResponse>;
/* eslint-enable max-lines */
