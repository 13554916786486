import * as t from 'io-ts';

import { dbModelId } from '@main/schema-utils';

import { AuditEventCode } from '../schema/enums';
import {
  mkAuditEventCodec,
  mkAuditEventJoinCodec,
  mkAuditJoinStateCodec,
} from '../types';

export const AuditEventAirgapCookie = mkAuditEventCodec(
  AuditEventCode.AirgapCookie,
  t.type({
    /** ID of the airgap cookie */
    airgapCookieId: dbModelId('airgapCookie'),
  }),
);

/** Override types. */
export type AuditEventAirgapCookie = t.TypeOf<typeof AuditEventAirgapCookie>;

export const AuditEventAirgapCookieOwner = mkAuditEventJoinCodec(
  AuditEventCode.AirgapCookieOwner,
  mkAuditJoinStateCodec('user'),
  t.type({
    /** ID of the airgap cookie */
    airgapCookieId: dbModelId('airgapCookie'),
  }),
);

/** Override types. */
export type AuditEventAirgapCookieOwner = t.TypeOf<
  typeof AuditEventAirgapCookieOwner
>;

export const AuditEventAirgapCookieTeam = mkAuditEventJoinCodec(
  AuditEventCode.AirgapCookieTeam,
  mkAuditJoinStateCodec('team'),
  t.type({
    /** ID of the airgap cookie */
    airgapCookieId: dbModelId('airgapCookie'),
  }),
);

/** Override types. */
export type AuditEventAirgapCookieTeam = t.TypeOf<
  typeof AuditEventAirgapCookieTeam
>;

export const AuditEventAirgapCookieAttribute = mkAuditEventJoinCodec(
  AuditEventCode.AirgapCookieAttribute,
  mkAuditJoinStateCodec(
    'attributeValue',
    t.type({
      /** the display title for the attribute key */
      attributeKeyTitle: t.string,
    }),
  ),
  t.type({
    /** ID of the airgap cookie */
    airgapCookieId: dbModelId('airgapCookie'),
  }),
);

/** Override types. */
export type AuditEventAirgapCookieAttribute = t.TypeOf<
  typeof AuditEventAirgapCookieAttribute
>;

export const AuditEventAirgapCookiePurpose = mkAuditEventJoinCodec(
  AuditEventCode.AirgapCookiePurpose,
  mkAuditJoinStateCodec('purpose'),
  t.type({
    /** ID of the airgap cookie */
    airgapCookieId: dbModelId('airgapCookie'),
  }),
);

/** Override types. */
export type AuditEventAirgapCookiePurpose = t.TypeOf<
  typeof AuditEventAirgapCookiePurpose
>;
