import { AttributeValue } from '@main/attribute-types';
import { WiredIdentifier } from '@main/datamap-types';
import { mkInput, mkOrder, mkType, SchemaToType } from '@main/schema-utils';

import { LegalHoldOrderField } from './enums';

export const LegalHoldInput = mkInput({
  name: 'LegalHoldInput',
  comment: 'Filters for legal holds',
  fields: {
    value: {
      comment: 'The identifier to place the legal hold on',
      type: 'string',
    },
    identifierName: {
      comment: 'The name of the identifier to create the hold for',
      type: 'string',
    },
  },
});

/** Override type */
export type LegalHoldInput = SchemaToType<typeof LegalHoldInput>;

export const CreateLegalHoldInput = mkInput({
  name: 'CreateLegalHoldInput',
  comment: 'Create a new legal hold',
  fields: {
    legalHolds: {
      comment: 'The legal holds to create',
      list: true,
      type: LegalHoldInput,
    },
  },
});

/** Override type */
export type CreateLegalHoldInput = SchemaToType<typeof CreateLegalHoldInput>;

export const DeleteLegalHoldsInput = mkInput({
  name: 'DeleteLegalHoldsInput',
  comment: 'Input for deleting legal holds',
  fields: {
    ids: {
      comment: 'The list of IDs to delete',
      type: 'id',
      modelName: 'legalHold',
      list: true,
    },
  },
});

/** Override type */
export type DeleteLegalHoldsInput = SchemaToType<typeof DeleteLegalHoldsInput>;

export const LegalHoldsFiltersInput = mkInput({
  name: 'LegalHoldsFiltersInput',
  comment: 'Filters for legal holds',
  fields: {
    text: {
      comment: 'Find any legal holds that are iLike a text string',
      optional: true,
      type: 'string',
    },
    identifierIds: {
      comment: 'Filter by identifier types',
      modelName: 'identifier',
      optional: true,
      list: true,
      type: 'id',
    },
  },
});

/** Override type */
export type LegalHoldsFiltersInput = SchemaToType<
  typeof LegalHoldsFiltersInput
>;

export const LegalHold = mkType({
  name: 'LegalHold',
  comment: 'An individual on a legal hold',
  fields: {
    id: {
      comment: 'The unique ID of the legal hold',
      modelName: 'legalHold',
      type: 'id',
    },
    createdAt: {
      comment: 'The time the legal hold was created',
      type: 'Date',
    },
    value: {
      comment: 'The value of the legal hold identifier',
      type: 'string',
    },
    requestCount: {
      comment: 'The number of requests made by the individual',
      type: 'int',
    },
    identifier: {
      comment: 'The identifier that the legal hold relates to',
      type: WiredIdentifier,
    },
    attributeValues: {
      comment: 'The attribute values used to label this assessment',
      type: AttributeValue,
      list: true,
    },
  },
});

/** Override type */
export type LegalHold = SchemaToType<typeof LegalHold>;

/** Order for a legalHolds query */
export const LegalHoldOrder = mkOrder(LegalHold.name, LegalHoldOrderField);

/** Override type */
export type LegalHoldOrder = SchemaToType<typeof LegalHoldOrder>;
