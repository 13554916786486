import {
  mkMutation,
  mkQuery,
  PAGINATION_INPUT_PARAMS,
} from '@main/schema-utils';

import {
  AuditorFileInput,
  AuditorFinding,
  AuditorFindingClassCountsFiltersInput,
  AuditorFindingFiltersInput,
  AuditorFindingsByClassResults,
  AuditorRunCookie,
  AuditorRunCookiesFiltersInput,
  AuditorRunDataFlow,
  AuditorRunDataFlowsFiltersInput,
  AuditorRunFiltersInput,
  AuditorRunInput,
  AuditorRunResponse,
  AuditorRunResults,
  AuditorSchedule,
  AuditorScheduleInput,
  AuditorSchedulesFiltersInput,
  DeleteAuditorSchedulesInput,
  UpdateAuditorScheduleInput,
} from './schema';

export const auditorRun = mkQuery({
  name: 'auditorRun',
  comment: 'A single auditor run',
  params: {
    id: {
      comment: 'The ID of the run',
      type: 'id',
      modelName: 'auditorRun',
    },
  },
  response: {
    run: AuditorRunResults,
  },
});

export const auditorRuns = mkQuery({
  name: 'auditorRuns',
  comment: 'All the historic auditor runs',
  params: {
    ...PAGINATION_INPUT_PARAMS,
    filterBy: AuditorRunFiltersInput.optional(),
  },
  response: {
    nodes: AuditorRunResults.list(),
    totalCount: 'int',
  },
});

export const auditorRunDataFlows = mkQuery({
  name: 'auditorRunDataFlows',
  comment: 'The data flows for a given auditor run',
  params: {
    ...PAGINATION_INPUT_PARAMS,
    filterBy: AuditorRunDataFlowsFiltersInput,
  },
  response: {
    nodes: AuditorRunDataFlow.list(),
    totalCount: 'int',
  },
});

export const auditorRunCookies = mkQuery({
  name: 'auditorRunCookies',
  comment: 'The cookies for a given auditor run',
  params: {
    ...PAGINATION_INPUT_PARAMS,
    filterBy: AuditorRunCookiesFiltersInput,
  },
  response: {
    nodes: AuditorRunCookie.list(),
    totalCount: 'int',
  },
});

export const auditorFindings = mkQuery({
  name: 'auditorFindings',
  comment: 'The findings for a given auditor run',
  params: {
    ...PAGINATION_INPUT_PARAMS,
    filterBy: AuditorFindingFiltersInput,
  },
  response: {
    nodes: AuditorFinding.list(),
    totalCount: 'int',
  },
});

export const auditorFindingClassCounts = mkQuery({
  name: 'auditorFindingClassCounts',
  comment: 'Number of findings by finding class type for a given auditor run',
  params: {
    id: {
      comment: 'The ID of the run',
      type: 'id',
      modelName: 'auditorRun',
    },
    filterBy: AuditorFindingClassCountsFiltersInput.optional(),
  },
  response: {
    findingCounts: AuditorFindingsByClassResults,
  },
});

export const auditorFileLink = mkQuery({
  name: 'auditorFileLink',
  comment: 'Retrieves a temporary link to download an auditor file',
  params: {
    input: AuditorFileInput,
  },
  response: {
    temporaryLink: 'string',
  },
});

export const auditorSchedules = mkQuery({
  name: 'auditorSchedules',
  comment: 'All the active auditor schedules',
  params: {
    ...PAGINATION_INPUT_PARAMS,
    filterBy: AuditorSchedulesFiltersInput.optional(),
  },
  response: {
    nodes: AuditorSchedule.list(),
    totalCount: 'int',
  },
});

export const runAuditor = mkMutation({
  name: 'runAuditor',
  comment: 'Executes the Web Auditor on a given URL',
  params: {
    input: AuditorRunInput,
  },
  response: {
    results: AuditorRunResponse,
  },
});

export const createAuditorSchedule = mkMutation({
  name: 'createAuditorSchedule',
  comment: 'Creates a schedule to run auditor',
  params: {
    input: AuditorScheduleInput,
  },
  response: {
    results: AuditorSchedule,
  },
});

export const deleteAuditorSchedules = mkMutation({
  name: 'deleteAuditorSchedules',
  comment: 'Deletes auditor schedules, leaving the auditor runs intact',
  params: {
    input: DeleteAuditorSchedulesInput,
  },
  response: {
    success: 'boolean',
  },
});

export const updateAuditorSchedule = mkMutation({
  name: 'updateAuditorSchedule',
  comment: 'Updates auditor schedules',
  params: {
    input: UpdateAuditorScheduleInput,
  },
  response: {
    schedule: AuditorSchedule,
  },
});
