import { defineMessages } from '@transcend-io/internationalization';

export const accountPageTitleMessages = defineMessages(
  'admin-dashboard.Auth.AccountProfile.accountPageTitle',
  {
    account: {
      defaultMessage: 'Account',
    },
    changeLanguage: {
      defaultMessage: 'Change Language',
    },
  },
);
