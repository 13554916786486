import { IntlFormatters } from 'react-intl';

import {
  IsoCountryCode,
  IsoCountrySubdivisionCode,
} from '@transcend-io/privacy-types';

import { DefinedMessage } from '@main/internationalization';

import { iso31662Messages, isoCountryCodeMessages } from './isoMessages';
import { regionMessages } from './messages';

export const countryToMessage = (
  country: IsoCountryCode | string,
): DefinedMessage | null =>
  country in isoCountryCodeMessages
    ? isoCountryCodeMessages[country as IsoCountryCode]
    : null;

export const countrySubDivisionToMessage = (
  countrySubDivision: IsoCountrySubdivisionCode | string,
): DefinedMessage | null =>
  countrySubDivision && countrySubDivision in iso31662Messages
    ? iso31662Messages[countrySubDivision as IsoCountrySubdivisionCode]
    : null;

export const regionCodeToFormattedString = (
  code: IsoCountryCode | IsoCountrySubdivisionCode | string,
  formatMessage: IntlFormatters['formatMessage'],
): string => {
  // Get the messages from the codes
  const countryMessage =
    code.length > 2
      ? countryToMessage(code.split('-')[0])
      : countryToMessage(code);
  const countrySubDivisionMessage =
    code.length > 2 ? countrySubDivisionToMessage(code) : null;

  // Convert the messages to strings, if they were found
  const formattedCountryMessage = countryMessage
    ? formatMessage(countryMessage)
    : '';
  const formattedCountrySubDivisionMessage = countrySubDivisionMessage
    ? formatMessage(countrySubDivisionMessage)
    : '';

  // Return a single string from the messages
  return formatMessage(regionMessages.countryAndSubDivision, {
    country: formattedCountryMessage,
    countrySubDivision: formattedCountrySubDivisionMessage,
    hasBoth: !!(formattedCountryMessage && formattedCountrySubDivisionMessage),
  });
};
