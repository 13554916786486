import { BarRounded } from '@visx/shape';
import styled, { css } from 'styled-components';

interface StyledBarRoundedProps {
  /** color of bar r */
  r: string;
  /** color of bar g */
  g: string;
  /** color of bar b */
  b: string;
  /** bar is manually expanded */
  expanded: 'true' | 'false';
}

const expansionStyles = ({
  r,
  g,
  b,
}: Omit<StyledBarRoundedProps, 'expanded'>): ReturnType<typeof css> => css`
  filter: ${`drop-shadow(0px 0px 4px rgb(${r} ${g} ${b} / 0.6))`};
`;

export const StyledBarRounded = styled(BarRounded)<StyledBarRoundedProps>`
  :hover {
    ${expansionStyles}
  }

  ${({ expanded, r, g, b }) =>
    expanded === 'true' ? expansionStyles({ r, g, b }) : ''}
  cursor: pointer;
  transition: all 200ms ease-out;
`;

export const VisxTooltipGridWrapper = styled.div`
  display: grid;
  grid-template-columns: min-content 1fr min-content;
  gap: 0.5em;
  align-items: center;
`;

export const VisxTooltipKeyWrapper = styled.span`
  margin-right: 1.5em;
  flex-grow: 1;
`;

export const VisxTooltipValueWrapper = styled.span`
  text-align: right;
`;

export const StyledVisxTooltipColorCircle = styled.div<{
  /** is the circle an outline circle or a filled circle */
  isOutline?: boolean;
  /** the color of the circle */
  color: string;
}>`
  height: 8px;
  width: 8px;
  border-radius: 8px;

  ${({ isOutline, color }) =>
    isOutline ? `border: 2px solid ${color}` : `background-color: ${color}`}
`;
