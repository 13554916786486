import { defineMessages } from '@transcend-io/internationalization';

export const generalSettingsMessages = defineMessages(
  'admin-dashboard.PrivacyCenter.GeneralSettings.generalSettings',
  {
    header: {
      defaultMessage: 'General Settings',
    },
    homeTitle: {
      defaultMessage: 'Homepage URL',
    },
    homeDescription: {
      defaultMessage: "The URL of your organization's homepage.",
    },
    localesTitle: {
      defaultMessage: 'Supported Languages',
    },
    localesDescription: {
      defaultMessage:
        'The languages that you want to display your Privacy Center in.',
    },
    defaultLocaleTitle: {
      defaultMessage: 'Default Language',
    },
    defaultLocaleDescription: {
      defaultMessage:
        'The language code that should be used when on the base domain of the Privacy Center.',
    },
    preferBrowserDefaultLocaleTitle: {
      defaultMessage: 'Prefer Browser Default Language?',
    },
    preferBrowserDefaultLocaleDescription: {
      defaultMessage: `When turned on, the language specified by the user’s browser will be used instead of the default
      language configured on the Privacy Center (the default language will still be used if the browser language cannot
      be determined). When turned off, on first load the Privacy Center will always default to the specified language.`,
    },
    hideDataPracticesTitle: {
      defaultMessage: 'Show data practices tab?',
    },
    hideDataPracticesDescription: {
      defaultMessage: `When turned on, the data practices page will be shown on your Privacy Center.
Without this page, the only way to visualize the data in an Access request is to download the full zip archive.`,
    },
    showTrackingTechnologiesTitle: {
      defaultMessage: `Show Tracking Technologies tab? (requires {consentManager})`,
    },
    showPrivacySettingsTitle: {
      defaultMessage: `Show Privacy Settings tab? (requires {consentManager})`,
    },
    showSaleOfInfoTitle: {
      defaultMessage: `Show Sale of Info tab? (requires {consentManager})`,
    },
    unauthenticatedDoNotSellRegionsTitle: {
      defaultMessage:
        'Regions that support unauthenticated Do Not Sell requests (requires {dsrAutomation})',
    },
    showMarketingPreferencesTitle: {
      defaultMessage: `Show Communication Preferences?`,
    },
    showPoliciesTitle: {
      defaultMessage: `Show "Policies" tab?`,
    },
    showPrivacyCenterTitle: {
      defaultMessage: `Show Privacy Center?`,
    },
    showPrivacyCenterDescription: {
      defaultMessage: `When turned off, the Privacy Center will no longer be visible.`,
    },
    consentManager: {
      defaultMessage: 'Transcend Consent',
    },
    dsrAutomation: {
      defaultMessage: 'Transcend DSR Automation',
    },
    showPoliciesDescription: {
      defaultMessage: 'When turned on, show the "Our Policies" tab.',
    },
    showTrackingTechnologiesDescription: {
      defaultMessage:
        'When turned on, the data flows and cookies you approve in Transcend Consent will automatically be displayed in ' +
        'another tab on the privacy center, with the heading "Tracking Technologies".',
    },
    showPrivacySettingsDescription: {
      defaultMessage:
        'When turned on, the "Privacy Settings" tab will be shown on the Privacy Center allowing ' +
        'the data subject to opt in and out of your consent purposes.',
    },
    showSaleOfInfoDescription: {
      defaultMessage:
        'When turned on, the "Sale of Info" tab will be shown on the Privacy Center allowing ' +
        'the data subject to opt-in or opt-out of selling and sharing their personal information.',
    },
    unauthenticatedDoNotSellRegionsDescription: {
      defaultMessage:
        'The set or regions that do not require data subjects to authenticate to submit Do Not Sell or Share requests.',
    },
    showMarketingPreferencesDescription: {
      defaultMessage:
        'When turned on, the "Communication Preferences" tab will be shown on the Privacy Center allowing ' +
        'the data subject to opt in and out of their communication preferences.',
    },
    showCookies: {
      defaultMessage: `Show cookies in Tracking Technologies table? (requires {consentManager})`,
    },
    showCookiesDescription: {
      defaultMessage:
        'When turned on, the cookies will be included in the Tracking Technologies table.',
    },
    showConsentManager: {
      defaultMessage: `Show button to change consent preferences? (requires {consentManager})`,
    },
    showConsentManagerDescription: {
      defaultMessage:
        'When turned on, it will be possible for a customer to see and change their consent preferences from this page.',
    },
    showDataFlows: {
      defaultMessage: `Show data flows in Tracking Technologies table? (requires {consentManager})`,
    },
    showDataFlowsDescription: {
      defaultMessage:
        'When turned on, the data flows will be included in the Tracking Technologies table.',
    },
    showPrivacyRequestButtonTitle: {
      defaultMessage: `Show "Make a Privacy Request" button? (requires {dsrAutomation})`,
    },
    showPrivacyRequestButtonDescription: {
      defaultMessage:
        // eslint-disable-next-line max-len
        'When turned on, the "Make a Privacy Request"  will be shown to allow users to authenticate and submit privacy requests.',
    },
    transformAccessReportJsonToCsvTitle: {
      defaultMessage: 'Transform ACCESS report JSONs to CSVs',
    },
    transformAccessReportJsonToCsvDescription: {
      defaultMessage:
        'When turned on, convert JSON files in the ACCESS report to CSV files',
    },
    allowPartitionChangeTitle: {
      defaultMessage: 'Allow Partition Selection',
    },
    allowPartitionChangeDescription: {
      defaultMessage:
        'When turned on, show the partition select component in the privacy center. ' +
        'This allows for users to switch between each of your partitions and make ' +
        'separate DSR or consent changes on a per-partition basis.',
    },
    showPartitionTitle: {
      defaultMessage: 'Show Partition Information',
    },
    showPartitionDescription: {
      defaultMessage:
        'When turned on, display the currently selected partition. ' +
        'This allows for users to switch between each of your partitions and make ' +
        'separate DSR or consent changes on a per-partition basis.',
    },
    changeDefaultMessageWarning: {
      defaultMessage:
        'Changing the default locale will change the displayed message for all translatable ' +
        'Privacy Center messages in the Admin Dashboard',
    },
  },
);
