import { FilterConfigItem } from '@main/ad-core-components';
import { buildUseLazyQuery } from '@main/core-ui';
import { DataCategoryStaticType, endpoints } from '@main/datamap-types';
import { ID } from '@main/schema-utils';
import { DataCategoryType } from '@transcend-io/privacy-types';
import partition from 'lodash/partition';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { MessageDescriptor } from 'react-intl';

import {
  DataCategoryTitle,
  SelectCategoryFilter,
} from '../../DataMap/components';
import {
  DataCategoriesItem,
  isSubCategory,
} from '../../DataMap/DataCategories/types';

/**
 * Filters definition with property for categories specified
 */
type DataCategoryEnrichedFilters = Record<string, unknown> & {
  /** Combined category and subcategory filter selection */
  categories?: DataCategoriesItem[];
};

/**
 * Hook to get the filter configuration for data categories and subcategories
 *
 * @returns the config for the filters and a function to clear the filters
 */
type DataCategoryFiltersBuilder<
  T extends Record<string, unknown>,
  E extends DataCategoryEnrichedFilters,
> = (filterParams: {
  /** name for the property that filters on the name of the parent data category */
  dataCategoryFilterKey?: keyof T;
  /** name for the property that filters on the ids of the data subcategory */
  dataSubCategoryFilterKey?: keyof T;
  /** The currently applied filters */
  filters?: T;
  /** Callback when the filters are changed */
  setFilters: (filters: T) => void;
  /** Label for the filter menu */
  label: string | MessageDescriptor;
  /** Whether to hide the option to filter on unspecified purposes */
  omitUnspecified?: boolean;
}) => {
  /** The categories currently filtered on */
  selectedDataCategories: DataCategoriesItem[];
  /** The filter configuration to be passed to the filter manager */
  dataCategoryFiltersConfig: FilterConfigItem<E>;
  /** Callback for when the filter is cleared in the filter manager */
  clearDataCategoryFilters: (key: Extract<keyof E, string>) => void;
};

const DATA_SUB_CATEGORY_PREVIEW_FIELDS = {
  id: null,
  name: null,
  category: null,
  slug: null,
  isDefault: null,
};

const useLazySubCategories = buildUseLazyQuery(
  endpoints.dataSubCategories,
  'DataCategoryFilters',
  {
    nodes: DATA_SUB_CATEGORY_PREVIEW_FIELDS,
    totalCount: null,
  },
);

/**
 *
 * Builds elements of the filters config needed for filtering by data categories and subcategories
 *
 * @param filterParams - the currently applied filters and a function to set the filters
 * @returns Elements of the filters config needed for filtering by data categories and subcategories
 */
export const useDataCategoryFilters: DataCategoryFiltersBuilder<
  Record<string, unknown>,
  DataCategoryEnrichedFilters
> = ({
  dataCategoryFilterKey = 'dataCategories',
  dataSubCategoryFilterKey = 'dataSubCategoryIds',
  label,
  filters,
  setFilters,
  omitUnspecified = false,
}) => {
  const getSelectedSubCategories = useLazySubCategories();

  const [selectedDataCategoriesItems, setSelectedDataCategoriesItems] =
    useState<DataCategoriesItem[]>([]);

  // Populate initial complex filter values
  useEffect(() => {
    if (filters) {
      if (selectedDataCategoriesItems.length === 0) {
        const parentCategoryFilter = filters[dataCategoryFilterKey];
        const subCategoryFilter = filters[dataSubCategoryFilterKey];

        const selectedParentCategory = (
          parentCategoryFilter
            ? Array.isArray(parentCategoryFilter)
              ? parentCategoryFilter
              : [parentCategoryFilter]
            : []
        ).map(
          (category: DataCategoryStaticType) =>
            ({
              category,
              isDefault: true,
            }) as unknown as DataCategoriesItem,
        );
        if (Array.isArray(subCategoryFilter) && subCategoryFilter.length > 0) {
          getSelectedSubCategories({
            filterBy: { ids: subCategoryFilter },
          }).then(({ data }) => {
            setSelectedDataCategoriesItems([
              ...(data.nodes || []),
              ...selectedParentCategory,
            ]);
          });
        } else if (
          Array.isArray(parentCategoryFilter) &&
          parentCategoryFilter.length > 0
        ) {
          setSelectedDataCategoriesItems(selectedParentCategory);
        }
      }
    }
  }, []);

  const dataCategoryFiltersConfig: FilterConfigItem<DataCategoryEnrichedFilters> =
    useMemo(
      () => ({
        filterKey: 'categories',
        label,
        renderPill: ({ filterValues: { categories = [] }, index = 0 }) => {
          const category = categories[index];
          return (
            <DataCategoryTitle
              key={`category-option-${index}`}
              name={isSubCategory(category) ? category.name : undefined}
              category={category.category}
            />
          );
        },
        filter: (
          <SelectCategoryFilter
            menuPosition="absolute"
            omitUnspecified={omitUnspecified}
            value={selectedDataCategoriesItems}
            onChange={(selections = []) => {
              setSelectedDataCategoriesItems(selections);
              const [subCategories, categories] = partition(
                selections,
                isSubCategory,
              );
              setFilters({
                ...filters,
                [dataCategoryFilterKey]: categories.reduce<DataCategoryType[]>(
                  (categories, category) => [...categories, category.category],
                  [],
                ),
                [dataSubCategoryFilterKey]: subCategories.reduce<
                  ID<'dataSubCategory'>[]
                >((ids, category) => [...ids, category.id], []),
              });
            }}
          />
        ),
      }),
      [filters, label, selectedDataCategoriesItems],
    );

  const clearDataCategoryFilters = useCallback(
    (key) => {
      if (key === 'categories') {
        setFilters({
          ...filters,
          [dataCategoryFilterKey]: [],
          [dataSubCategoryFilterKey]: [],
        });
        setSelectedDataCategoriesItems([]);
      }
    },
    [dataCategoryFilterKey, dataSubCategoryFilterKey, filters],
  );

  return {
    selectedDataCategories: selectedDataCategoriesItems,
    dataCategoryFiltersConfig,
    clearDataCategoryFilters,
  };
};
