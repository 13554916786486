import { Button } from '@main/ad-core-components';
import {
  CenteredDiv,
  Container,
  ErrorText,
  logger,
  RouteComponentProps,
  Skeleton,
  withRouter,
} from '@main/core-ui';
import React from 'react';
import { injectIntl, IntlShape } from 'react-intl';

import { errorBoundaryMessages } from './messages';

/**
 * Props for ErrorBoundary
 */
export interface ErrorBoundaryProps extends RouteComponentProps {
  /** Intl */
  intl: IntlShape;
  /** The child contents */
  children?: React.ReactNode;
}

interface ErrorBoundaryState {
  /** Error if ocurred */
  error: null | Error;
  /** Loading state */
  loading: boolean;
}

/**
 * Bound the ui with an error boundary and catch any unexpected errors
 */
export class ErrorBoundary extends React.Component<ErrorBoundaryProps> {
  /** Error boundary state */
  public state: ErrorBoundaryState = { error: null, loading: false };

  /**
   * If the pathname changes, allow the error to be forgotten
   *
   * @param newProps - The new ErrorBoundary props
   */
  public UNSAFE_componentWillReceiveProps(newProps: ErrorBoundaryProps): void {
    const { location } = this.props;
    if (newProps.location.pathname !== location.pathname) {
      this.setState({ loading: true });
      setTimeout(() => this.setState({ error: null, loading: false }), 1000);
    }
  }

  /**
   * Go back to the previous page
   */
  public back = (): void => {
    this.props.navigate(-1);
    this.setState({ loading: true });
    setTimeout(() => this.setState({ null: null, loading: false }), 1000);
  };

  /**
   * Catch the unexpected error and set the state to hasError:true
   *
   * @param error - The error that occurred
   * @param info - The information associated with
   */
  public componentDidCatch(error: Error, info: React.ErrorInfo): void {
    this.setState({ error });
    logger.error(error, info);
  }

  /**
   * Render the ErrorBoundary container
   */
  public render(): JSX.Element | React.ReactNode {
    const { error, loading } = this.state;
    const {
      children,
      intl: { formatMessage },
    } = this.props;
    if (error) {
      // You can render any custom fall-back UI
      return (
        <Container>
          <CenteredDiv>
            <h2>
              <ErrorText>
                {formatMessage(errorBoundaryMessages.header)}
                {error && error?.message}
              </ErrorText>
            </h2>
            {loading && <Skeleton />}
            <Button
              variant="secondary"
              onClick={this.back}
              style={{ margin: '0 auto' }}
            >
              {formatMessage(errorBoundaryMessages.back)}
            </Button>
          </CenteredDiv>
        </Container>
      );
    }
    return children;
  }
}

export const ErrorBoundaryWithIntl = injectIntl(ErrorBoundary);

export default withRouter(ErrorBoundaryWithIntl);
