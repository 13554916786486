/* eslint-disable max-lines */
import { OrganizationPreview } from '@main/access-control-types';
import { ConsentPartition, SyncedConsentManager } from '@main/cm-types';
import {
  DataApplication,
  DataCategoryMethodMenu,
  DataCategoryMethodMenuInput,
  Region,
  RegionInput,
  StepStatus,
  UpdateDataApplicationInput,
} from '@main/datamap-types';
import { MessageInput } from '@main/internationalization';
import { DefinedMessage } from '@main/internationalization/src/schema';
import {
  applyFields,
  applyFieldsEnum,
  mkInput,
  mkType,
  SchemaToType,
} from '@main/schema-utils';
import {
  DEFAULT_CUSTOMIZABLE_COLORS,
  OPTIONAL_COLOR_DEFAULTS,
} from '@main/theme';

import { AssetFile } from './assetFile';
import { Certificate } from './certificate';
import { CloudFront } from './cloudfront';
import { PrivacyCenterAssetName, PrivacyCenterType } from './enums';
import { Page } from './page';
import { PolicyInput } from './policy';
import { ComponentStylesInput, TextStylesInput } from './theme';

export const UpdatePrivacyCenterColorPaletteInput = mkInput({
  name: 'UpdatePrivacyCenterColorPaletteInput',
  comment: 'The updated color palette colors',
  fields: applyFields(
    {
      ...DEFAULT_CUSTOMIZABLE_COLORS,
      ...OPTIONAL_COLOR_DEFAULTS,
    },
    (_, colorName) => ({
      comment: `The color customization for  ${colorName}`,
      optional: true,
      type: 'string',
    }),
  ),
});

/** Override type */
export type UpdatePrivacyCenterColorPaletteInput = SchemaToType<
  typeof UpdatePrivacyCenterColorPaletteInput
>;

export const UpdatePrivacyCenterAssetFileInput = mkInput({
  name: 'UpdatePrivacyCenterAssetFileInput',
  comment:
    'The ids and alt text of the asset files that should be globally updated',
  fields: {
    id: {
      comment: 'The id of the asset file',
      modelName: 'assetFile',
      type: 'id',
    },
    alt: {
      comment: 'The alt text for this asset file',
      optional: true,
      type: 'string',
    },
  },
});

/** Override type */
export type UpdatePrivacyCenterAssetFileInput = SchemaToType<
  typeof UpdatePrivacyCenterAssetFileInput
>;

export const UpdatePrivacyCenterAssetsInput = mkInput({
  name: 'UpdatePrivacyCenterAssetsInput',
  comment: 'The asset files that should be globally updated',
  // TODO: https://github.com/transcend-io/main/issues/2535 - instead of using this weird nested object structure, convert to list
  fields: applyFieldsEnum(PrivacyCenterAssetName, (assetFileName) => ({
    comment: `The asset file ${assetFileName}`,
    optional: true,
    type: UpdatePrivacyCenterAssetFileInput,
  })),
});

/** Override type */
export type UpdatePrivacyCenterAssetsInput = SchemaToType<
  typeof UpdatePrivacyCenterAssetsInput
>;

export const UpdatePoliciesInput = mkInput({
  name: 'UpdatePoliciesInput',
  comment: 'Make changes to the global Privacy Center configuration',
  fields: {
    privacyCenterId: {
      comment: 'The ID of the Privacy Center to update',
      modelName: 'privacyCenter',
      type: 'id',
    },
    policies: {
      comment: 'The updated policy definitions',
      list: true,
      type: PolicyInput,
    },
    skipPublish: {
      comment: 'Should we skip publishing the module to the CDN?',
      type: 'boolean',
      optional: true,
    },
  },
});

/** Override type */
export type UpdatePoliciesInput = SchemaToType<typeof UpdatePoliciesInput>;

export const DeletePoliciesInput = mkInput({
  name: 'DeletePoliciesInput',
  comment: 'Make changes to the global Privacy Center configuration',
  fields: {
    privacyCenterId: {
      comment: 'The ID of the Privacy Center to update',
      modelName: 'privacyCenter',
      type: 'id',
    },
    ids: {
      comment: 'The IDs of the policies to delete',
      modelName: 'policy',
      type: 'id',
      list: true,
    },
    skipPublish: {
      comment: 'Should we skip publishing the module to the CDN?',
      type: 'boolean',
      optional: true,
    },
  },
});

/** Override type */
export type DeletePoliciesInput = SchemaToType<typeof DeletePoliciesInput>;

export const UpdatePrivacyCenterInput = mkInput({
  name: 'UpdatePrivacyCenterInput',
  comment: 'Make changes to the global Privacy Center configuration',
  fields: {
    privacyCenterId: {
      comment: 'The ID of the Privacy Center to update',
      modelName: 'privacyCenter',
      type: 'id',
    },
    assets: {
      comment: 'The updated asset files',
      optional: true,
      type: UpdatePrivacyCenterAssetsInput,
    },
    colorPalette: {
      comment: 'The updated color palette',
      optional: true,
      type: UpdatePrivacyCenterColorPaletteInput,
    },
    componentStyles: {
      comment: 'CSS overrides for components on the Privacy Center',
      optional: true,
      type: ComponentStylesInput,
    },
    textStyles: {
      comment: 'Text overrides on the Privacy Center',
      optional: true,
      type: TextStylesInput,
    },
    isDisabled: {
      comment:
        'When true, do not show the Privacy Center, instead show a disabled message',
      type: 'boolean',
      optional: true,
    },
    locales: {
      comment: 'The languages that the Privacy Center supports',
      optional: true,
      list: true,
      type: 'string',
    },
    defaultLocale: {
      comment: 'The default language of the Privacy Center',
      optional: true,
      type: 'string',
    },
    preferBrowserDefaultLocale: {
      comment: 'Update whether to use the browser default locale if available',
      optional: true,
      type: 'boolean',
    },
    dataApplications: {
      comment: 'The data applications to update',
      list: true,
      optional: true,
      type: UpdateDataApplicationInput,
    },
    dataPractices: {
      comment: 'The updated nested data practices menu',
      list: true,
      optional: true,
      type: DataCategoryMethodMenuInput,
    },
    supportEmail: {
      comment: 'The support email that should be linked to in the footer',
      optional: true,
      type: 'string',
    },
    replyToEmail: {
      comment:
        'The support email that should be used when the user hits reply-to on an email',
      optional: true,
      type: 'string',
    },
    emailPrefix: {
      comment:
        'The email prefix that should be used when sending emails to the data subject',
      optional: true,
      type: 'string',
    },
    home: {
      comment: 'The homepage url of the Privacy Center',
      optional: true,
      type: 'string',
    },
    hideDataPractices: {
      comment: 'Update whether data practices page is hidden',
      optional: true,
      type: 'boolean',
    },
    showTrackingTechnologies: {
      comment: 'Update whether tracking technologies page is shown',
      optional: true,
      type: 'boolean',
    },
    showManageYourPrivacy: {
      comment: 'Update whether "Privacy Settings" page is shown',
      optional: true,
      type: 'boolean',
    },
    showSaleOfInfo: {
      comment: 'Update whether sale of info page is shown',
      optional: true,
      type: 'boolean',
    },
    unauthenticatedDoNotSellRegions: {
      comment:
        'Update the regions that support unauthenticated Do Not Sell requests',
      optional: true,
      list: true,
      type: RegionInput,
    },
    showMarketingPreferences: {
      comment: 'Update whether marketing preferences page is shown',
      optional: true,
      type: 'boolean',
    },
    showPolicies: {
      comment: 'Update whether policies page is shown',
      optional: true,
      type: 'boolean',
    },
    showCookies: {
      comment: 'Show cookies in tracking technologies table',
      optional: true,
      type: 'boolean',
    },
    showConsentManager: {
      comment:
        'Enable the ability to set/change consent preferences via the consent manager',
      optional: true,
      type: 'boolean',
    },
    showDataFlows: {
      comment: 'Show data flows in tracking technologies table',
      optional: true,
      type: 'boolean',
    },
    showPrivacyRequestButton: {
      comment: 'Show the Make a Privacy Request button',
      type: 'boolean',
      optional: true,
    },
    useNoReplyEmailAddress: {
      comment:
        'Indicates whether to use a no-reply email address for data subject communications',
      optional: true,
      type: 'boolean',
    },
    useCustomEmailDomain: {
      comment:
        'Whether to use the configured email domain for data subject communications',
      type: 'boolean',
      optional: true,
    },
    transformAccessReportJsonToCsv: {
      comment:
        'Should we attempt to transform any JSON objects as part of the ACCESS report download into CSV?',
      type: 'boolean',
      optional: true,
    },
    allowPartitionChange: {
      comment:
        'When true, show the partition select component in the privacy center',
      type: 'boolean',
      optional: true,
    },
    showPartition: {
      comment:
        'When true, show the partition information on the privacy center',
      type: 'boolean',
      optional: true,
    },
    customSubdomain: {
      comment:
        'The custom subdomain to use, instead of the default "privacy" subdomain',
      type: 'string',
      optional: true,
    },
    skipPublish: {
      comment: 'Should we skip publishing the module to the CDN?',
      type: 'boolean',
      optional: true,
    },
    displayOrder: {
      comment: 'The order in which the policies should be displayed',
      type: 'int',
      optional: true,
    },
  },
});

/** Override type */
export type UpdatePrivacyCenterInput = SchemaToType<
  typeof UpdatePrivacyCenterInput
>;

export const PrivacyCenterAssets = mkType({
  name: 'PrivacyCenterAssets',
  comment: 'The asset files to render on the Privacy Center',
  fields: applyFieldsEnum(PrivacyCenterAssetName, (assetFileName) => ({
    comment: `The asset file ${assetFileName}`,
    optional: true,
    type: AssetFile,
  })),
});

/** Override type */
export type PrivacyCenterAssets = SchemaToType<typeof PrivacyCenterAssets>;

export const PrivacyCenterTranslation = mkType({
  name: 'PrivacyCenterTranslation',
  comment: 'An ID of a message and its associated translation',
  fields: {
    id: {
      comment: 'The unique identifier for that message',
      type: 'string',
    },
    value: {
      comment: 'The translation for that message',
      type: 'string',
    },
  },
});

/** Override type */
export type PrivacyCenterTranslation = SchemaToType<
  typeof PrivacyCenterTranslation
>;

export const UpdateMessagesInput = mkInput({
  name: 'UpdateMessagesInput',
  comment: 'Update internationalized messages for the Privacy Center',
  fields: {
    messages: {
      comment:
        'Updated messages. ID must be provided or a customizable targetReactIntlId',
      list: true,
      type: MessageInput,
    },
    skipPublish: {
      comment: 'Should we skip publishing the module to the CDN?',
      type: 'boolean',
      optional: true,
    },
  },
});

/** Override type */
export type UpdateMessagesInput = SchemaToType<typeof UpdateMessagesInput>;

export const PrivacyCenterDeploymentStep = mkType({
  name: 'PrivacyCenterDeploymentStep',
  comment: 'A step in the privacy deployment process',
  fields: {
    current: {
      comment: 'The index of the step currently being run',
      type: 'int',
    },
    description: {
      comment: 'A description of what is happening at this step',
      type: 'string',
    },
    status: {
      comment:
        'The status of the step as it relates to the ant.design component',
      type: { StepStatus },
    },
  },
});

/** Override type */
export type PrivacyCenterDeploymentStep = SchemaToType<
  typeof PrivacyCenterDeploymentStep
>;

export const PrivacyCenterStringified = mkType({
  name: 'PrivacyCenterStringified',
  comment: 'The Privacy Center configuration',
  fields: {
    id: {
      comment: 'The id of the Privacy Center',
      modelName: 'privacyCenter',
      type: 'id',
    },
    type: {
      comment: 'The type of Privacy Center',
      type: { PrivacyCenterType },
    },
    showPrivacyRequestButton: {
      comment: 'Show the Make a Privacy Request button',
      type: 'boolean',
    },
    hideDataPractices: {
      comment:
        'When true, do not show data practices and instead redirect to privacy policy',
      type: 'boolean',
    },
    isDisabled: {
      comment:
        'When true, do not show the Privacy Center, instead show a disabled message',
      type: 'boolean',
    },
    showPolicies: {
      comment: 'When true, show the policies tab',
      type: 'boolean',
    },
    showTrackingTechnologies: {
      comment: 'When true, show the tracking technologies tab',
      type: 'boolean',
    },
    showManageYourPrivacy: {
      comment: 'When true, show the "Privacy Settings" tab',
      type: 'boolean',
    },
    showSaleOfInfo: {
      comment: 'When true, show the sale of info tab',
      type: 'boolean',
    },
    unauthenticatedDoNotSellRegions: {
      comment: 'The regions that support unauthenticated Do Not Sell requests',
      list: true,
      type: Region,
    },
    showMarketingPreferences: {
      comment: 'When true, show the marketing preferences tab',
      type: 'boolean',
    },
    showCookies: {
      comment: 'Show cookies in tracking technologies table',
      type: 'boolean',
    },
    showConsentManager: {
      comment:
        'Enable the ability to set/change consent preferences via the consent manager',
      type: 'boolean',
    },
    showDataFlows: {
      comment: 'Show data flows in tracking technologies table',
      type: 'boolean',
    },
    showRequestsProcessedStats: {
      comment: 'When true, show the Requests Processed tab',
      type: 'boolean',
    },
    url: {
      comment: 'The url that the Privacy Center is hosted on',
      type: 'string',
    },
    home: {
      comment: `
      When the logo is clicked, this is the URL it should redirect to.
      The default is to the home page of the Privacy Center (data practices), but this could also be the full privacy policy
      if hosted individually from the Privacy Center.
`,
      type: DefinedMessage,
    },
    locales: {
      comment: 'The languages that the Privacy Center supports',
      list: true,
      type: 'string',
    },
    defaultLocale: {
      comment: 'The default locale to use for this Privacy Center',
      type: 'string', // Should be LanguageKey enum but GraphQL does not allow hyphens in enums
    },
    preferBrowserDefaultLocale: {
      comment: 'When true, use the browser default locale if available',
      type: 'boolean',
    },
    supportEmail: {
      comment: 'The support email that should be linked to in the footer',
      optional: true,
      type: 'string',
    },
    replyToEmail: {
      comment:
        'The support email that should be used when the user hits reply-to on an email',
      optional: true,
      type: 'string',
    },
    emailPrefix: {
      comment:
        'The email prefix that should be used when sending emails to the data subject',
      optional: true,
      type: 'string',
    },
    organization: {
      comment: 'The preview of the organization',
      type: OrganizationPreview,
    },
    assets: {
      comment: 'The globally defined asset files',
      type: PrivacyCenterAssets,
    },
    consentManagers: {
      comment:
        'The consent manager bundles associated with this Privacy Center',
      type: SyncedConsentManager,
      list: true,
    },
    pages: {
      comment: 'The pages associated with the Privacy Center',
      list: true,
      type: Page,
    },
    themeStr: {
      comment: 'The theme to style the Privacy Center with',
      type: 'string',
    },
    dataApplications: {
      comment: 'The list of all data applications',
      list: true,
      type: DataApplication,
    },
    dataPractices: {
      comment: 'The data practices, in nested format',
      list: true,
      type: DataCategoryMethodMenu,
    },
    useNoReplyEmailAddress: {
      comment:
        'Whether to use a no-reply email address for data subject communications',
      type: 'boolean',
    },
    useCustomEmailDomain: {
      comment:
        'Whether to use the configured email domain for data subject communications',
      type: 'boolean',
    },
    transformAccessReportJsonToCsv: {
      comment:
        'Should we attempt to transform any JSON objects as part of the ACCESS report download into CSV?',
      type: 'boolean',
    },
    allowPartitionChange: {
      comment:
        'When true, show the partition select component in the privacy center',
      type: 'boolean',
    },
    showPartition: {
      comment:
        'When true, show the partition information on the privacy center',
      type: 'boolean',
    },
    customSubdomain: {
      comment:
        'Custom subdomain for the Transcend-hosted privacy center (when fully launched)',
      type: 'string',
    },
    transcendHostedDomain: {
      comment:
        'Domain for the Transcend-hosted privacy center (when fully launched).',
      type: 'string',
    },
    consentPartitions: {
      comment:
        'The consent partitions available for display on the Privacy Center',
      list: true,
      type: ConsentPartition,
    },
  },
});

/** Override type */
export type PrivacyCenterStringified = SchemaToType<
  typeof PrivacyCenterStringified
>;

export const PrivacyCenterDeployment = mkType({
  name: 'PrivacyCenterDeployment',
  comment: 'The deployment information for a Privacy Center',
  fields: {
    id: {
      comment: 'The id of the Privacy Center',
      modelName: 'privacyCenter',
      type: 'id',
    },
    cloudfront: {
      comment: 'The cloudfront instance deployed for the Privacy Center',
      type: CloudFront,
      optional: true,
    },
    certificate: {
      comment: 'The SSL certificate used to host the cloudfront',
      type: Certificate,
      optional: true,
    },
    step: {
      comment:
        'The current step in the deployment process for this Privacy Center',
      type: PrivacyCenterDeploymentStep,
    },
  },
});

/** Override type */
export type PrivacyCenterDeployment = SchemaToType<
  typeof PrivacyCenterDeployment
>;

export const DataCollectionsRequestFileCountInput = mkInput({
  name: 'DataCollectionsRequestFileCountInput',
  comment: 'Input for a DataCollectionsRequestFileCount query',
  fields: {
    requestId: {
      comment: 'The id of the request to find request files for',
      type: 'id',
      modelName: 'request',
    },
  },
});

/** Override type */
export type DataCollectionsRequestFileCountInput = SchemaToType<
  typeof DataCollectionsRequestFileCountInput
>;

export const DataCollectionRequestFileCount = mkType({
  name: 'DataCollectionRequestFileCount',
  comment: 'The number of request files for a data collection',
  fields: {
    id: {
      comment: 'The data collection id',
      type: 'id',
      modelName: 'dataCollection',
    },
    count: {
      comment: 'The number of request files for the data collection',
      type: 'int',
      optional: true,
    },
    hasRequestFiles: {
      comment: 'Whether the data collection has request files',
      type: 'boolean',
    },
  },
});

/** Override type */
export type DataCollectionRequestFileCount = SchemaToType<
  typeof DataCollectionRequestFileCount
>;
/* eslint-enable max-lines */
