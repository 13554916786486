import { defineMessages } from '@transcend-io/internationalization';

import { AuditEventBaseModelCode } from '@main/audit-types';

export const auditTrailFilterMessages = defineMessages(
  'ad-core-components.AuditTrail.AuditTrailFilter.auditTrailFilter',
  {
    placeholder: { defaultMessage: 'Click to filter events' },
    codesLabel: { defaultMessage: 'Event Type' },
    actorUsersLabel: { defaultMessage: 'User' },
    isAutomatedLabel: { defaultMessage: 'Automated Event' },
    isAutomatedTrueLabel: { defaultMessage: 'Yes' },
    isAutomatedFalseLabel: { defaultMessage: 'No' },
    createdAt: {
      defaultMessage: 'Created At',
    },
  },
);

// Labels for the items in the filter for AuditEventCode
export const auditEventCodeFilterLabelMessages =
  defineMessages<AuditEventBaseModelCode>(
    'ad-core-components.AuditTrail.AuditTrailFilter.auditEventCodeFilterLabel',
    {
      [AuditEventBaseModelCode.ActionItem]: {
        defaultMessage: 'Action Item',
      },
      [AuditEventBaseModelCode.DataSilo]: {
        defaultMessage: 'Data Silo',
      },
      [AuditEventBaseModelCode.DataSiloPlugin]: {
        defaultMessage: 'Data Silo Plugin',
      },
      [AuditEventBaseModelCode.DataReport]: {
        defaultMessage: 'Data Report',
      },
      [AuditEventBaseModelCode.DataPointLevel]: {
        defaultMessage: 'Data Point Level/Schema',
      },
      [AuditEventBaseModelCode.AirgapBundle]: {
        defaultMessage: 'Airgap Bundle',
      },
      [AuditEventBaseModelCode.AirgapCookie]: {
        defaultMessage: 'Airgap Cookie',
      },
      [AuditEventBaseModelCode.AirgapDataFlow]: {
        defaultMessage: 'Airgap Data Flow',
      },
      [AuditEventBaseModelCode.AirgapTheme]: {
        defaultMessage: 'Airgap Theme',
      },
      [AuditEventBaseModelCode.Vendor]: {
        defaultMessage: 'Vendor',
      },
      [AuditEventBaseModelCode.BusinessEntity]: {
        defaultMessage: 'Business Entity',
      },
      [AuditEventBaseModelCode.DataSubCategory]: {
        defaultMessage: 'Data Category',
      },
      [AuditEventBaseModelCode.ProcessingPurposeSubCategory]: {
        defaultMessage: 'Processing Purpose',
      },
      [AuditEventBaseModelCode.SubDataPoint]: {
        defaultMessage: 'Datapoint Property',
      },
      [AuditEventBaseModelCode.DataPoint]: {
        defaultMessage: 'Datapoint Object',
      },
      [AuditEventBaseModelCode.Identifier]: {
        defaultMessage: 'Identifier',
      },
      [AuditEventBaseModelCode.Message]: {
        defaultMessage: 'Message',
      },
      [AuditEventBaseModelCode.PrivacyCenter]: {
        defaultMessage: 'Privacy Center',
      },
      [AuditEventBaseModelCode.Theme]: {
        defaultMessage: 'Theme',
      },
      [AuditEventBaseModelCode.DataCollection]: {
        defaultMessage: 'Data Collection',
      },
      [AuditEventBaseModelCode.AssetFile]: {
        defaultMessage: 'Asset File',
      },
      [AuditEventBaseModelCode.Policy]: {
        defaultMessage: 'Policy',
      },
      [AuditEventBaseModelCode.DataApplication]: {
        defaultMessage: 'Data Application',
      },
      [AuditEventBaseModelCode.DataCategory]: {
        defaultMessage: 'Data Category',
      },
      [AuditEventBaseModelCode.Experience]: {
        defaultMessage: 'Experience',
      },
      [AuditEventBaseModelCode.TrackingPurpose]: {
        defaultMessage: 'Purpose',
      },
      [AuditEventBaseModelCode.AssessmentEmailSet]: {
        defaultMessage: 'Assessment Email Set',
      },
      [AuditEventBaseModelCode.AssessmentAnswer]: {
        defaultMessage: 'Assessment Answer',
      },
      [AuditEventBaseModelCode.AssessmentQuestionComment]: {
        defaultMessage: 'Assessment Question Comment',
      },
      [AuditEventBaseModelCode.AssessmentSectionComment]: {
        defaultMessage: 'Assessment Section Comment',
      },
      [AuditEventBaseModelCode.AssessmentFormTemplate]: {
        defaultMessage: 'Assessment Form Template',
      },
      [AuditEventBaseModelCode.AssessmentForm]: {
        defaultMessage: 'Assessment Form',
      },
      [AuditEventBaseModelCode.AssessmentGroup]: {
        defaultMessage: 'Assessment Group',
      },
      [AuditEventBaseModelCode.AssessmentFormComment]: {
        defaultMessage: 'Assessment Form Comment',
      },
      [AuditEventBaseModelCode.AssessmentQuestion]: {
        defaultMessage: 'Assessment Question',
      },
      [AuditEventBaseModelCode.AssessmentSection]: {
        defaultMessage: 'Assessment section',
      },
      [AuditEventBaseModelCode.EmailSenderAddress]: {
        defaultMessage: 'Conditional Email Address',
      },
      [AuditEventBaseModelCode.Preference]: {
        defaultMessage: 'Preference',
      },
      [AuditEventBaseModelCode.AirgapBundleDeployment]: {
        defaultMessage: 'Airgap Bundle Deployment',
      },
      [AuditEventBaseModelCode.ScannedObject]: {
        defaultMessage: 'Unstructured Data Discovery Objects',
      },
      [AuditEventBaseModelCode.UnstructuredSubDataPointRecommendation]: {
        defaultMessage: 'Unstructured Data Discovery Objects',
      },
      [AuditEventBaseModelCode.Sombra]: {
        defaultMessage: 'Sombra',
      },
      [AuditEventBaseModelCode.OrgTcfPurpose]: {
        defaultMessage: 'TCF Purpose',
      },
      [AuditEventBaseModelCode.OrgTcfSpecialFeature]: {
        defaultMessage: 'TCF Special Feature',
      },
      [AuditEventBaseModelCode.ConsentService]: {
        defaultMessage: 'Consent Service',
      },
      [AuditEventBaseModelCode.AirgapBundleTcfStack]: {
        defaultMessage: 'TCF Stack',
      },
      [AuditEventBaseModelCode.AirgapPartition]: {
        defaultMessage: 'Airgap Partition',
      },
      [AuditEventBaseModelCode.RiskFramework]: {
        defaultMessage: 'Risk Framework',
      },
      [AuditEventBaseModelCode.RiskLevel]: {
        defaultMessage: 'Risk Level',
      },
      [AuditEventBaseModelCode.RiskCategory]: {
        defaultMessage: 'Risk Category',
      },
      [AuditEventBaseModelCode.RiskMatrixColumn]: {
        defaultMessage: 'Risk Matrix Column',
      },
      [AuditEventBaseModelCode.RiskMatrixRow]: {
        defaultMessage: 'Risk Matrix Row',
      },
    },
  );
