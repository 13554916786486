import { UserPreview } from '@main/access-control-types';
import {
  Communication,
  DataPointPreview,
  DataSiloPreview,
} from '@main/datamap-types';
import { mkInput, mkOrder, mkType, SchemaToType } from '@main/schema-utils';

import { ActivityCode, ActivityOrderField } from './enums';
import { ProfilePreview } from './profilePreview';
import { RequestDetails } from './request';
import { RequestEnricher } from './RequestEnricher';
import { RequestEnricherRequestIdentifier } from './RequestEnricherRequestIdentifier';
import { RequestFile } from './requestFile';
import { RequestIdentifier } from './RequestIdentifier';

export const ActivityFiltersInput = mkInput({
  name: 'ActivityFiltersInput',
  comment: 'Input for filtering activities',
  fields: {
    requestId: {
      comment: 'Request to filter by',
      optional: true,
      modelName: 'request',
      type: 'id',
    },
    dataSiloId: {
      comment: 'Data silo to filter by',
      optional: true,
      modelName: 'dataSilo',
      type: 'id',
    },
    // TODO: https://transcend.height.app/T-4422 - add back search functionality
    // text: {
    //   comment: 'Filter by any text on associated models',
    //   optional: true,
    //   type: 'string',
    // },
    code: {
      comment: 'The code of the activity',
      list: true,
      optional: true,
      type: { ActivityCode },
    },
  },
});

/** Override type */
export type ActivityFiltersInput = SchemaToType<typeof ActivityFiltersInput>;

export const Activity = mkType({
  name: 'Activity',
  comment:
    'An activity related to a request. This is the audit trail of everything that occurs during the request lifecycle.',
  fields: {
    id: {
      comment: 'The id of the activity',
      modelName: 'activity',
      type: 'id',
    },
    code: {
      comment: 'The code of the activity',
      type: { ActivityCode },
    },
    createdAt: {
      comment: 'The time the activity occurred',
      type: 'Date',
    },
    request: {
      comment: 'The request that the activity was created for',
      type: RequestDetails,
    },
    user: {
      comment: 'The user that performed the activity',
      optional: true,
      type: UserPreview,
    },
    requestFile: {
      comment: 'The requestFile that the activity is about',
      optional: true,
      type: RequestFile,
    },
    profile: {
      comment: 'The profile that the request file belongs to',
      optional: true,
      type: ProfilePreview,
    },
    dataSilo: {
      comment: 'The data silo that the profile belongs to',
      optional: true,
      type: DataSiloPreview,
    },
    dataPoint: {
      comment: 'The datapoint that is being resolved',
      optional: true,
      type: DataPointPreview,
    },
    requestEnricher: {
      comment: 'The request enricher that the activity is about',
      optional: true,
      type: RequestEnricher,
    },
    requestIdentifier: {
      comment: 'The request enricher that the activity is about',
      optional: true,
      type: RequestIdentifier,
    },
    requestEnricherRequestIdentifier: {
      comment: 'The edge between the request enricher and request identifier',
      optional: true,
      type: RequestEnricherRequestIdentifier,
    },
    communication: {
      comment: 'The communication message that was sent',
      optional: true,
      type: Communication,
    },
    error: {
      comment: 'The error message if on occurred',
      optional: true,
      type: 'string',
    },
  },
});

/** Override type */
export type Activity = SchemaToType<typeof Activity>;

/** Order for the activities query */
export const ActivityOrder = mkOrder(Activity.name, ActivityOrderField);

/** Override type */
export type ActivityOrder = SchemaToType<typeof ActivityOrder>;
