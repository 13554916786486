import { mkType, SchemaToType } from '@main/schema-utils';

export const RequestEnricherRequestIdentifier = mkType({
  name: 'RequestEnricherRequestIdentifier',
  comment:
    'The edge indicating that a request enricher is attesting to a request identifier',
  fields: {
    id: {
      comment: 'The ID of the RequestEnricherRequestIdentifier',
      modelName: 'RequestEnricherRequestIdentifier',
      type: 'id',
    },
    RequestEnricherId: {
      comment: 'The ID of the request enricher',
      modelName: 'RequestEnricher',
      type: 'id',
    },
    RequestIdentifierId: {
      comment: 'The ID of the request identifier',
      modelName: 'RequestIdentifier',
      type: 'id',
    },
    jwt: {
      comment: 'The JWT signing the identifier values',
      optional: true,
      type: 'string',
    },
    isVerified: {
      comment:
        'Indicator that the request enricher is verifying the request identifier',
      type: 'boolean',
    },
  },
});

/** Override type */
export type RequestEnricherRequestIdentifier = SchemaToType<
  typeof RequestEnricherRequestIdentifier
>;
