import { defineMessages } from '@transcend-io/internationalization';
import { RequestAction } from '@transcend-io/privacy-types';
import { apply } from '@transcend-io/type-utils';

import { ACTIONS } from '@main/datamap-types';

/**
 * Message definitions for workflow cards
 */
export const workflowCardMessages = defineMessages(
  'ad-core-components.WorkflowCard.workflowCard',
  {
    accessTitle: {
      defaultMessage: 'Access',
    },
    integrationsCount: {
      defaultMessage: '{count, plural, one {Integration} other {Integrations}}',
    },
    datapointsCount: {
      defaultMessage: '{count, plural, one {Datapoint} other {Datapoints}}',
    },
    requestsProcessedCount: {
      defaultMessage:
        '{count, plural, one {Request} other {Requests}} Processed',
    },
    isLive: {
      defaultMessage: 'Live',
    },
    isNotLive: {
      defaultMessage: 'Not Live',
    },
  },
);

/**
 * Message definitions for RequestAction
 */
export const requestActionMessages = defineMessages<RequestAction>(
  'ad-core-components.WorkflowCard.requestAction',
  apply(ACTIONS, ({ messages }) => messages.adminTitle),
);
