import { makeEnum } from '@transcend-io/type-utils';

/** The broad category that a finding belongs to */
export const FindingClass = makeEnum({
  // If Airgap is not on the page at all
  AirgapNotFound: 'AirgapNotFound',

  // Categories where a network request / cookie is seen by Auditor that Airgap should have regulated, but did not
  UnexpectedCookieStored: 'UnexpectedCookieStored',
  UnexpectedDataFlow: 'UnexpectedDataFlow',

  // Categories where a network request / cookie is seen by Auditor, where the consent manager settings did not have a relevant
  // data flow / cookie for it
  UnregulatedCookieStored: 'UnregulatedCookieStored',
  UnregulatedNetworkRequestMade: 'UnregulatedNetworkRequestMade',

  // Categories where our source of truth suggests that a data flow / cookie could be classified differently
  CookieMisclassified: 'CookieMisclassified',
  DataFlowMisclassified: 'DataFlowMisclassified',

  // Specific rules that we have custom logic / descriptions for
  FacebookLDU: 'FacebookLDU',
  GoogleRDP: 'GoogleRDP',
  MicrosoftAdvertising: 'MicrosoftAdvertising',
  PinterestAds: 'PinterestAds',
  TikTokPixel: 'TikTokPixel',
  TwitterAds: 'TwitterAds',

  // A finding that just has a description attached
  Other: 'Other',
});

/** Override type for FindingClass */
export type FindingClass = (typeof FindingClass)[keyof typeof FindingClass];

/** Severity level of a finding */
export const FindingSeverity = makeEnum({
  Informational: 'Informational',
  Low: 'Low',
  Medium: 'Medium',
  High: 'High',
  Critical: 'Critical',
});

/** Override type for FindingSeverity */
export type FindingSeverity =
  (typeof FindingSeverity)[keyof typeof FindingSeverity];
