import {
  mkMutation,
  mkQuery,
  PAGINATION_INPUT_PARAMS,
} from '@main/schema-utils';

import {
  CodePackage,
  CodePackageFiltersInput,
  CodePackageOrder,
  CreateCodePackageInput,
  CreatePullRequestInput,
  CreateRepositoryInput,
  CreateSoftwareDevelopmentKitInput,
  DeleteCodePackagesInput,
  DeletePullRequestsInput,
  DeleteRepositoriesInput,
  DeleteSoftwareDevelopmentKitsInput,
  PullRequest,
  PullRequestFiltersInput,
  PullRequestOrder,
  Repository,
  RepositoryFiltersInput,
  RepositoryOrder,
  SoftwareDevelopmentKit,
  SoftwareDevelopmentKitFiltersInput,
  SoftwareDevelopmentKitOrder,
  UpdateCodePackagesInput,
  UpdatePullRequestsInput,
  UpdateRepositoriesInput,
  UpdateSoftwareDevelopmentKitsInput,
} from './schema';

export const updateRepositories = mkMutation({
  name: 'updateRepositories',
  comment: 'Updates a set of repository',
  params: {
    input: UpdateRepositoriesInput,
  },
  response: { repositories: Repository.list() },
});

export const createRepository = mkMutation({
  name: 'createRepository',
  comment: 'Creates a new repository',
  params: {
    input: CreateRepositoryInput,
  },
  response: { repository: Repository },
});

export const repositories = mkQuery({
  name: 'repositories',
  comment: 'List the repositories',
  params: {
    ...PAGINATION_INPUT_PARAMS,
    filterBy: RepositoryFiltersInput.optional(),
    orderBy: RepositoryOrder,
  },
  response: {
    nodes: Repository.list(),
    totalCount: 'int',
  },
});

export const deleteRepositories = mkMutation({
  name: 'deleteRepositories',
  comment: 'Delete multiple repositories from an organization',
  params: {
    input: DeleteRepositoriesInput,
  },
  response: { success: 'boolean' },
});

export const pullRequests = mkQuery({
  name: 'pullRequests',
  comment: 'List of pull requests registered in the instance',
  params: {
    ...PAGINATION_INPUT_PARAMS,
    filterBy: PullRequestFiltersInput.optional(),
    orderBy: PullRequestOrder,
  },
  response: {
    nodes: PullRequest.list(),
    totalCount: 'int',
  },
});

export const updatePullRequests = mkMutation({
  name: 'updatePullRequests',
  comment: 'Updates a set of pull requests',
  params: {
    input: UpdatePullRequestsInput,
  },
  response: { pullRequests: PullRequest.list() },
});

export const createPullRequest = mkMutation({
  name: 'createPullRequest',
  comment: 'Creates a new pull request',
  params: {
    input: CreatePullRequestInput,
  },
  response: { pullRequest: PullRequest },
});

export const deletePullRequests = mkMutation({
  name: 'deletePullRequests',
  comment: 'Delete multiple pull requests from an organization',
  params: {
    input: DeletePullRequestsInput,
  },
  response: { success: 'boolean' },
});

export const codePackages = mkQuery({
  name: 'codePackages',
  comment: 'List of code packages registered in the instance',
  params: {
    ...PAGINATION_INPUT_PARAMS,
    filterBy: CodePackageFiltersInput.optional(),
    orderBy: CodePackageOrder,
  },
  response: {
    nodes: CodePackage.list(),
    totalCount: 'int',
  },
});

export const updateCodePackages = mkMutation({
  name: 'updateCodePackages',
  comment: 'Updates a set of code packages',
  params: {
    input: UpdateCodePackagesInput,
  },
  response: { codePackages: CodePackage.list() },
});

export const createCodePackage = mkMutation({
  name: 'createCodePackage',
  comment: 'Creates a new code package',
  params: {
    input: CreateCodePackageInput,
  },
  response: { codePackage: CodePackage },
});

export const deleteCodePackages = mkMutation({
  name: 'deleteCodePackages',
  comment: 'Delete multiple code packages from an organization',
  params: {
    input: DeleteCodePackagesInput,
  },
  response: { success: 'boolean' },
});

export const softwareDevelopmentKits = mkQuery({
  name: 'softwareDevelopmentKits',
  comment: 'List of software development kits in the instance',
  params: {
    ...PAGINATION_INPUT_PARAMS,
    filterBy: SoftwareDevelopmentKitFiltersInput.optional(),
    orderBy: SoftwareDevelopmentKitOrder,
  },
  response: {
    nodes: SoftwareDevelopmentKit.list(),
    totalCount: 'int',
  },
});

export const updateSoftwareDevelopmentKits = mkMutation({
  name: 'updateSoftwareDevelopmentKits',
  comment: 'Updates a set of software development kits',
  params: {
    input: UpdateSoftwareDevelopmentKitsInput,
  },
  response: { softwareDevelopmentKits: SoftwareDevelopmentKit.list() },
});

export const createSoftwareDevelopmentKit = mkMutation({
  name: 'createSoftwareDevelopmentKit',
  comment: 'Creates a new software development kit',
  params: {
    input: CreateSoftwareDevelopmentKitInput,
  },
  response: { softwareDevelopmentKit: SoftwareDevelopmentKit },
});

export const deleteSoftwareDevelopmentKits = mkMutation({
  name: 'deleteSoftwareDevelopmentKits',
  comment: 'Delete multiple software development kits from an organization',
  params: {
    input: DeleteSoftwareDevelopmentKitsInput,
  },
  response: { success: 'boolean' },
});
