import { defineMessages } from '@transcend-io/internationalization';

export const approveAppButtonMessages = defineMessages(
  'admin-dashboard.ConsentManager.NativeApps.TriageApplications.components.ApproveAppButton.approveAppButton',
  {
    buttonText: {
      defaultMessage: 'Add to Consent Applications',
    },
    approveSuccess: {
      defaultMessage: 'Successfully added Code Package to Consent Applications',
    },
  },
);
