/**
 * A type of privacy center
 */
export enum PrivacyCenterType {
  /** A deployed production ready privacy center */
  Deployed = 'DEPLOYED',
  /** A privacy center that is in preview mode for the organization */
  Preview = 'PREVIEW',
}

/**
 * The names of the asset files globally defined on the privacy center
 */
export enum PrivacyCenterAssetName {
  /** The logo asset */
  Logo = 'logo',
  /** The favicon asset */
  Favicon = 'favicon',
  /** The logoOnSolid asset */
  LogoOnSolid = 'logoOnSolid',
  /** The hero asset */
  Hero = 'hero',
  /** The widgetBg asset */
  WidgetBg = 'widgetBg',
}
