import {
  buildUseQuery,
  formatErrorMessage,
  Icon,
  ReactSelect,
  ReactSelectExtendedProps,
} from '@main/core-ui';
import { endpoints, PathfinderPreview } from '@main/prompt-types';
import { ID } from '@main/schema-utils';
import React, { useCallback, useEffect, useState } from 'react';
import { useTheme } from 'styled-components';

const NODES = {
  id: null,
  name: null,
} as const;

/**
 * Selected pathfinder
 */
export type SelectedPathfinder = Pick<PathfinderPreview, keyof typeof NODES> & {
  /** Whether pathfinder is newly created or existing */
  isNew?: boolean;
};

/** An option for the select component */
export interface SelectPathfinderOption {
  /** The value for the option */
  value: SelectedPathfinder;
  /** The label for the option */
  label: string;
  /** The logo for the option */
  logo: JSX.Element;
}

const usePathfinders = buildUseQuery(
  endpoints.pathfinders,
  'SelectPathfinders',
  {
    nodes: NODES,
  },
);

const isId = (
  value: SelectedPathfinder | ID<'pathfinder'>,
): value is ID<'pathfinder'> => typeof value === 'string';

/** Props for the `SelectPathfinder` component */
export interface SelectPathfinderProps
  extends Omit<
    ReactSelectExtendedProps<boolean, SelectedPathfinder>,
    'onChange'
  > {
  /** The currently selected values */
  value?: SelectedPathfinder[] | ID<'pathfinder'>[];
  /** On change handler */
  onChange?: (pathfinders: SelectedPathfinder[]) => void;
}

const isSelectedPathfinderOption = (
  value: SelectPathfinderOption | undefined,
): value is SelectPathfinderOption => !!value;

export const SelectPathfinders: React.FC<SelectPathfinderProps> = ({
  isLoading,
  isMulti = true,
  disabled = false,
  value: initialValues,
  onChange,
  ...props
}) => {
  const { data, loading, error } = usePathfinders({
    fetchPolicy: 'cache-and-network',
  });
  const theme = useTheme();

  const toOption = useCallback(
    (value?: SelectedPathfinder): SelectPathfinderOption | undefined =>
      !value
        ? undefined
        : {
            value,
            label: value.name,
            logo: (
              <Icon type="assessment-template" color={theme.colors.transcend} />
            ),
          },
    [theme],
  );
  const options = (data?.nodes ?? []).map(toOption);

  const [selected, setSelected] = useState<SelectPathfinderOption[]>();

  useEffect(() => {
    const newSelected = (
      initialValues
        ? initialValues.map((value) =>
            isId(value)
              ? toOption((data?.nodes || []).find(({ id }) => id === value))
              : toOption(value),
          )
        : []
    ).filter(isSelectedPathfinderOption);

    setSelected(newSelected);
  }, [data, initialValues, toOption]);

  return (
    <ReactSelect<boolean, SelectPathfinderOption>
      options={options.filter(isSelectedPathfinderOption)}
      value={selected}
      isMulti={isMulti}
      isLoading={isLoading || loading}
      getOptionLogo={({ logo }) => logo}
      noOptionsMessage={
        error ? () => formatErrorMessage(error.message) : undefined
      }
      getOptionLabel={({ label }) => label}
      getOptionValue={({ value }) => value.id}
      isClearable={false}
      closeMenuOnSelect
      isDisabled={disabled}
      onChange={
        !onChange
          ? undefined
          : (option) =>
              !option
                ? onChange([])
                : Array.isArray(option)
                  ? onChange(option.map(({ value }) => value))
                  : onChange([(option as SelectPathfinderOption).value as any])
      }
      {...props}
    />
  );
};

/**
 * Select a single pathfinder
 */
export const SelectPathfinder: React.FC<
  Omit<SelectPathfinderProps, 'value' | 'onChange'> & {
    /** Set the newly selected pathfinders */
    onChange?: (pathfinder: SelectedPathfinder) => void;
    /** Selected value */
    value?: SelectedPathfinder | ID<'pathfinder'>;
  }
> = ({ onChange, value, ...props }) => (
  <SelectPathfinders
    {...props}
    isMulti={false}
    value={
      // default type is mixed array rather than array of one type or the other
      value ? ([value] as SelectedPathfinder[] | ID<'pathfinder'>[]) : []
    }
    onChange={onChange ? ([value]) => onChange(value) : undefined}
  />
);
