/* eslint-disable max-lines */
import type { GenericMessageDescriptor } from '@main/core-ui/src/GenericFormattedMessage';
import { GenericFormattedMessage } from '@main/core-ui/src/GenericFormattedMessage';
import {
  checkPasswordStrength,
  getInvalidEmailCharacters,
  isEmptyStr,
  isUuid,
  isValidHost,
  TELEPHONE_REGEX,
  TLS_URL_REGEX,
  URI_REGEX,
  URL_REGEX,
} from '@main/utils';
import React from 'react';
import { Validate } from 'react-hook-form';
import { MessageDescriptor } from 'react-intl';

import { ValidatorKey } from './enums';
import { validatorMessages } from './messages';
import {
  DATE_REGEX,
  DOMAIN_REGEX,
  DOMAIN_WITHOUT_PORT_REGEX,
  SOCIAL_SECURITY_NUMBER_REGEX,
} from './regexes';
import type { TValidator } from './types';

/**
 * Options to construct the lookup str
 */
export interface LookupOptions {
  /** The raw value */
  value: string;
  /** Convert to upper case */
  upper?: boolean;
  /** Return the identity */
  exact?: boolean;
}

/**
 * Enum for Compare types
 */
export enum CompareType {
  /** Less Than */
  LessThan = 'less_than',
  /** Less Or Equal to */
  LessOrEqualTo = 'less_or_equal_to',
  /** Greater than */
  GreaterThan = 'greater_than',
  /** Greater or equal */
  GreaterOrEqualTo = 'greater_or_equal_to',
}

/**
 * Compare the value against the number using the operator
 *
 * @param value - The value to compare
 * @param op - The operator to use
 * @param nm - The number we are comparing against
 * @returns The comparison result
 */
function comparison(value: string, op: CompareType, nm: number): boolean {
  const val = parseInt(value, 10);
  switch (op) {
    case CompareType.LessThan:
      return val < nm;
    case CompareType.LessOrEqualTo:
      return val <= nm;
    case CompareType.GreaterThan:
      return val > nm;
    case CompareType.GreaterOrEqualTo:
      return val >= nm;
    default:
      return false;
  }
}

/**
 * The message to display when the comparison is not met
 *
 */
const CompareMessageByType: Record<CompareType, MessageDescriptor> = {
  [CompareType.LessThan]: validatorMessages.lessThan,
  [CompareType.LessOrEqualTo]: validatorMessages.lessOrEqualTo,
  [CompareType.GreaterThan]: validatorMessages.greaterThan,
  [CompareType.GreaterOrEqualTo]: validatorMessages.greaterOrEqualTo,
};

/**
 * Construct a string to lookup against (default is to convert to lower case)
 *
 * @returns The value converted to proper casing
 */
export function getLookupValue({ upper, exact, value }: LookupOptions): string {
  if (exact) {
    return value;
  }
  return upper ? value.toUpperCase() : value.toLowerCase();
}

/**
 * create a validator from a regex
 *
 * @param name - the name of the
 * @param isValidRegex - regex that corresponds with all valid values
 * @param message - the error message to show
 */
export const createRegexValidator = (
  name: ValidatorKey | string,
  isValidRegex: RegExp,
  message: GenericMessageDescriptor,
): TValidator => ({
  [name]: (value: string) =>
    value && !isValidRegex.test(value) ? (
      <GenericFormattedMessage message={message} />
    ) : undefined,
});

/**
 * String is date
 */
const DATE_STR: TValidator = createRegexValidator(
  ValidatorKey.DateStr,
  DATE_REGEX,
  validatorMessages.dateStr,
);

export const isValidEmail = (
  email: string,
): {
  /** is the email valid */ isValid: boolean;
  /** the list of extra weird invalid characters, if any */
  invalidChars: string[];
} => {
  const input = document.createElementNS(
    'http://www.w3.org/1999/xhtml',
    'input',
  ) as HTMLInputElement;
  input.required = true;
  input.type = 'email';
  input.value = email;
  let isValid = input.checkValidity();

  // check for extra weird characters
  const invalidChars = getInvalidEmailCharacters(email);
  if (invalidChars.length > 0) {
    isValid = false;
  }

  return { isValid, invalidChars };
};

/**
 * string is an email (but can be empty)
 * If you want the input to be required, use Validators.REQUIRED
 */
const EMAIL: TValidator = {
  [ValidatorKey.Email]: (email) => {
    if (email.trim() !== email) {
      return (
        <GenericFormattedMessage
          message={validatorMessages.invalidEmailTrailing}
        />
      );
    }
    const validation = isValidEmail(email);
    return email !== '' && !validation.isValid ? (
      <GenericFormattedMessage
        message={
          validation.invalidChars.length > 0
            ? validatorMessages.invalidEmailChars
            : validatorMessages.invalidEmail
        }
        args={{ chars: validation.invalidChars.join(',') }}
      />
    ) : undefined;
  },
};

/**
 * string is a list of emails
 */
const MULTI_EMAIL: TValidator = {
  [ValidatorKey.MultiEmail]: (emails) =>
    emails &&
    emails
      // split by common email separators & trim
      ?.split(/[,;|]\s*/)
      // are any of the multiple emails invalid
      .some((email) => !isValidEmail(email).isValid) ? (
      <GenericFormattedMessage message={validatorMessages.invalidEmailMulti} />
    ) : undefined,
};

/**
 * Ensure the length of the string is less than `sz`
 *
 * @param sz - The max len exclusive (<)
 * @param name - The name of the type
 * @returns The len validator
 */
const MAX_LENGTH = (sz: number, name = 'Input'): TValidator => ({
  [ValidatorKey.MaxLength]: (value) =>
    value && value.length >= sz ? (
      <GenericFormattedMessage
        message={validatorMessages.maxLength}
        args={{ sz, name }}
      />
    ) : undefined,
});

/**
 * Ensure the length of the string is greater than `sz
 *
 * @param sz - The min len
 * @param name - The name of the type
 * @returns The len validator
 */
export const MIN_LENGTH = (sz: number, name = 'Input'): TValidator => ({
  [ValidatorKey.MinLength]: (value) =>
    value && value.length < sz ? (
      <GenericFormattedMessage
        message={validatorMessages.minLength}
        args={{ sz, name }}
      />
    ) : undefined,
});

/**
 * Ensure value compared (using operator) to number is valid
 *
 * @param op - The operator to use
 * @param nm - The number we are comparing against
 * @returns The less or equal validator
 */
const COMPARE = (op: CompareType, nm: number): TValidator => ({
  [ValidatorKey.Compare]: (value) =>
    comparison(value, op, nm) ? undefined : (
      <GenericFormattedMessage
        message={CompareMessageByType[op]}
        args={{ nm }}
      />
    ),
});

/**
 * Ensure the input is not one of some values
 *
 * @param options - Lookup option
 * @param upper - Convert to upper case before looking, else lower
 * @param exact - Must match exactly
 * @returns The len validator
 */
export const NOT_ONE_OF = (
  options: Record<string, any>,
  upper = false,
  exact = false,
): TValidator => ({
  [ValidatorKey.NotOneOf]: (value) => {
    const validateSingleValue = (val: any): boolean =>
      options[getLookupValue({ upper, exact, value: val })];

    return value &&
      (Array.isArray(value)
        ? value.every(validateSingleValue)
        : validateSingleValue(value)) ? (
      <GenericFormattedMessage message={validatorMessages.notOneOf} />
    ) : undefined;
  },
});

/**
 * Ensure the input is one of some values
 *
 * @param options - Lookup option
 * @param upper - Convert to upper case before looking, else lower
 * @param exact - Must match exactly
 * @param allowNull - When true, allow the empty string
 * @returns The len validator
 */
export const ONE_OF = (
  options: { [k in string]: string },
  upper = false,
  exact = false,
  allowNull = false,
): TValidator => ({
  [ValidatorKey.OneOf]: (value) =>
    (allowNull && isEmptyStr(value)) ||
    (value &&
      value.length > 0 &&
      options[getLookupValue({ upper, exact, value })]) ? undefined : (
      <GenericFormattedMessage message={validatorMessages.oneOf} />
    ),
});

/**
 * String is a valid password
 */
export const PASSWORD: TValidator = {
  [ValidatorKey.Password]: (value) =>
    !checkPasswordStrength(value).strong ? (
      <GenericFormattedMessage message={validatorMessages.password} />
    ) : undefined,
};

/**
 * String is a positive integer
 */
export const POSITIVE_INTEGER: TValidator = {
  [ValidatorKey.PositiveInteger]: (value) => {
    const message = (
      <GenericFormattedMessage message={validatorMessages.positiveInteger} />
    );
    try {
      const valNum = +value;
      return valNum > 0 && Math.round(valNum) === valNum ? undefined : message;
    } catch (e) {
      return message;
    }
  },
};

/**
 * Input is required
 */
export const REQUIRED: TValidator = {
  [ValidatorKey.Required]: (value: string | string[] | number) =>
    (!value && value !== 0) ||
    isEmptyStr(value) ||
    ((Array.isArray(value) || typeof value === 'string') &&
      value.length === 0) ? (
      <GenericFormattedMessage message={validatorMessages.required} />
    ) : undefined,
};

/**
 * Input is required
 */
export const INTEGER: TValidator = {
  [ValidatorKey.Integer]: (value) => {
    const message = (
      <GenericFormattedMessage message={validatorMessages.integer} />
    );
    try {
      const valNum = Number(value);
      return `${Math.round(valNum)}` === `${value}` ? undefined : message;
    } catch (e) {
      return message;
    }
  },
};

/**
 * String is a valid SSN
 */
export const SOCIAL_SECURITY: TValidator = createRegexValidator(
  ValidatorKey.SocialSecurity,
  SOCIAL_SECURITY_NUMBER_REGEX,
  validatorMessages.socialSecurity,
);

/**
 * String is an international telephone number
 */
export const TELEPHONE: TValidator = createRegexValidator(
  ValidatorKey.Telephone,
  TELEPHONE_REGEX,
  validatorMessages.telephone,
);

/**
 * String is a valid uri
 */
export const URI: TValidator = createRegexValidator(
  ValidatorKey.Uri,
  URI_REGEX,
  validatorMessages.uri,
);

/**
 * String is a valid url
 */
export const URL: TValidator = createRegexValidator(
  ValidatorKey.Url,
  TLS_URL_REGEX,
  validatorMessages.url,
);

/**
 * String is a valid url
 */
export const ANY_URL: TValidator = createRegexValidator(
  ValidatorKey.AnyUrl,
  URL_REGEX,
  validatorMessages.anyUrl,
);

/**
 * String is a valid domain (with an optional port)
 */
export const DOMAIN: TValidator = createRegexValidator(
  ValidatorKey.Domain,
  DOMAIN_REGEX,
  validatorMessages.domain,
);

/**
 * String is a valid domain (without an optional port)
 */
export const DOMAIN_WITHOUT_PORT: TValidator = createRegexValidator(
  ValidatorKey.DomainWithoutPort,
  DOMAIN_WITHOUT_PORT_REGEX,
  validatorMessages.domainWithoutPort,
);

/**
 * String is a valid host
 */
export const HOST: TValidator = {
  [ValidatorKey.Host]: (value) =>
    isValidHost(value) ? undefined : (
      <GenericFormattedMessage message={validatorMessages.host} />
    ),
};

/**
 * String is a UUID v4.
 */
export const UUIDv4: TValidator = {
  [ValidatorKey.UUIDv4]: (value) =>
    isUuid(value) ? undefined : (
      <GenericFormattedMessage message={validatorMessages.uuidv4} />
    ),
};

/**
 * IF THE STRING IS NONEMPTY, check if string is valid according to some custom regex
 * To enforce that the string is non-empty, use the validator 'REQUIRED'
 */
export const CUSTOM_REGEX: (
  customRegex: string | RegExp,
  customMessage?: GenericMessageDescriptor,
) => TValidator = (customRegex, customMessage) => ({
  [ValidatorKey.CustomRegex]: (value) =>
    !value || !customRegex || !!value.match(customRegex) ? undefined : (
      <GenericFormattedMessage
        message={customMessage ?? validatorMessages.customRegex}
        args={{ customRegex: customRegex.toString() }}
      />
    ),
});

/**
 * String is HTML and has displayed contents
 */
export const HTML_STRING_HAS_CONTENTS: TValidator = {
  [ValidatorKey.HtmlStringHasContents]: (value) => {
    const span = document.createElement('span');
    span.innerHTML = value;
    const hasText = !!span.textContent?.trim();

    // textContent doesn't require a render
    return !hasText ? (
      <GenericFormattedMessage
        message={validatorMessages.htmlStringHasContents}
      />
    ) : undefined;
  },
};

/**
 * Namespaced rules for easier discovery
 */
export const Validators = {
  CUSTOM_REGEX,
  DATE_STR,
  EMAIL,
  MULTI_EMAIL,
  MAX_LENGTH,
  MIN_LENGTH,
  COMPARE,
  NOT_ONE_OF,
  ONE_OF,
  PASSWORD,
  POSITIVE_INTEGER,
  INTEGER,
  REQUIRED,
  SOCIAL_SECURITY,
  TELEPHONE,
  URI,
  URL,
  ANY_URL,
  DOMAIN,
  DOMAIN_WITHOUT_PORT,
  HOST,
  HTML_STRING_HAS_CONTENTS,
  UUIDv4,
} as const;

export const multipleValidators = (
  validators: TValidator | TValidator[],
): Validate<any> =>
  Array.isArray(validators) ? Object.assign({}, ...validators) : validators;
/* eslint-enable max-lines */
