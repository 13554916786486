import { DoubleTag, SingleTag } from '@main/core-ui';
import React from 'react';

import type { SelectedVendor } from './types';

export const VendorTitle: React.FC<SelectedVendor> = ({
  businessEntity,
  title,
}) =>
  businessEntity ? (
    <DoubleTag
      variant="filled"
      leftLabel={businessEntity.title}
      rightLabel={title}
      color="transcendNavy2"
    />
  ) : (
    <SingleTag label={title} color="transcendNavy2" />
  );
