import { makeEnum } from '@transcend-io/type-utils';

export const PROMPTS_PATH_PREFIX = '/prompts';

export const AdminDashboardPromptsPath = makeEnum({
  Prompts: PROMPTS_PATH_PREFIX,
  NewPrompt: `${PROMPTS_PATH_PREFIX}/new`,
  Partials: `${PROMPTS_PATH_PREFIX}/partials`,
  NewPartial: `${PROMPTS_PATH_PREFIX}/partials/new`,
  ViewPartial: `${PROMPTS_PATH_PREFIX}/partials/view/:promptPartialId`,
  EditPartial: `${PROMPTS_PATH_PREFIX}/partials/edit/:promptPartialId`,
  Groups: `${PROMPTS_PATH_PREFIX}/groups`,
  Browse: `${PROMPTS_PATH_PREFIX}/browse`,
  ViewPrompt: `${PROMPTS_PATH_PREFIX}/view/:promptId`,
  EditPrompt: `${PROMPTS_PATH_PREFIX}/edit/:promptId`,
  Models: `${PROMPTS_PATH_PREFIX}/models`,
  PromptRuns: `${PROMPTS_PATH_PREFIX}/runs`,
  ViewPromptRun: `${PROMPTS_PATH_PREFIX}/runs/:promptRunId`,
});

/** Override type */
export type AdminDashboardPromptsPath =
  (typeof AdminDashboardPromptsPath)[keyof typeof AdminDashboardPromptsPath];

export const PATHFINDERS_PATH_PREFIX = '/pathfinder';

export const AdminDashboardPathfinderPath = makeEnum({
  Pathfinders: PATHFINDERS_PATH_PREFIX,
  EditPathfinder: `${PATHFINDERS_PATH_PREFIX}/edit/:pathfinderId`,
  ViewPathfinder: `${PATHFINDERS_PATH_PREFIX}/view/:pathfinderId`,
  Applications: `${PATHFINDERS_PATH_PREFIX}/applications`,
  ViewApplication: `${PATHFINDERS_PATH_PREFIX}/applications/view/:applicationId`,
  EditApplication: `${PATHFINDERS_PATH_PREFIX}/applications/edit/:applicationId`,
  PathfinderPolicies: `${PATHFINDERS_PATH_PREFIX}/policies`,
  ViewPathfinderPolicy: `${PATHFINDERS_PATH_PREFIX}/policies/view/:pathfinderPolicyId`,
  EditPathfinderPolicy: `${PATHFINDERS_PATH_PREFIX}/policies/edit/:pathfinderPolicyId`,
  AppUsers: `${PATHFINDERS_PATH_PREFIX}/application-users`,
  ViewAppUser: `${PATHFINDERS_PATH_PREFIX}/application-users/view/:appUserId`,
  EditAppUser: `${PATHFINDERS_PATH_PREFIX}/application-users/edit/:appUserId`,
  // agents
  EditAgent: `${PATHFINDERS_PATH_PREFIX}/agents/edit/:agentId`,
  Agents: `${PATHFINDERS_PATH_PREFIX}/agents`,
  NewAgent: `${PATHFINDERS_PATH_PREFIX}/agents/new`,
  ViewAgent: `${PATHFINDERS_PATH_PREFIX}/agents/view/:agentId`,
  AgentFunctions: `${PATHFINDERS_PATH_PREFIX}/agent-functions`,
  NewAgentFunction: `${PATHFINDERS_PATH_PREFIX}/agent-functions/new`,
  ViewAgentFunction: `${PATHFINDERS_PATH_PREFIX}/agent-functions/view/:agentFunctionId`,
  EditAgentFunction: `${PATHFINDERS_PATH_PREFIX}/agent-functions/edit/:agentFunctionId`,
  AgentFiles: `${PATHFINDERS_PATH_PREFIX}/agent-files`,
  NewAgentFile: `${PATHFINDERS_PATH_PREFIX}/agent-files/new`,
  ViewAgentFile: `${PATHFINDERS_PATH_PREFIX}/agent-files/view/:agentFileId`,
  EditAgentFile: `${PATHFINDERS_PATH_PREFIX}/agent-files/edit/:agentFileId`,
});

/** Override type */
export type AdminDashboardPathfinderPath =
  (typeof AdminDashboardPathfinderPath)[keyof typeof AdminDashboardPathfinderPath];
