/**
 * Fields that a list of requests can be ordered by (in ASC or DESC order)
 */
export enum RequestOrderField {
  /** Order in direction of request creation (submission time) */
  CreatedAt = 'createdAt',
  /** Order in direction of last modification of the request */
  UpdatedAt = 'updatedAt',
  /** Order by request ID */
  Id = 'id',
  /** Order in direction of most urgent (past 30 days due date or nearing) to least urgent -- last item would be first DSR submit */
  TimeRemaining = 'timeRemaining',
}

/**
 * The fields that an request history can be ordered on
 */
export enum RequestHistoryOrderField {
  /** The time the request was made */
  CreatedAt = 'createdAt',
  /** The time the request was updated */
  UpdatedAt = 'updatedAt',
}
