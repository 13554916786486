import {
  FlexColumnCenterHorizontal,
  Icon,
  StyleUtils,
  useFormatMessageGeneric,
} from '@main/core-ui';
import { FIXED_COLORS } from '@main/theme';
import { Group } from '@visx/group';
import { ParentSize } from '@visx/responsive';
import { Pie } from '@visx/shape';
import React, { useMemo } from 'react';

import { ChartContainer } from '../DonutChart/wrappers';
import {
  SPEEDOMETER_ARC_WIDTH,
  SPEEDOMETER_END_ANGLE,
  SPEEDOMETER_ICON_SIZE,
  SPEEDOMETER_PAD_ANGLE,
  SPEEDOMETER_START_ANGLE,
  SPEEDOMETER_SVG_TEXT_OFFSET,
  SPEEDOMETER_SVG_TEXT_SIZE,
} from './constants';
import { SpeedometerChartProps, SpeedometerThreshold } from './types';
import { StyledPath } from './wrappers';

export const SpeedometerChart: React.FC<SpeedometerChartProps> = ({
  data: { currentValue, maxValue },
  thresholds,
  headingLabel,
  iconType,
  iconSize = SPEEDOMETER_ICON_SIZE,
  width = '100%',
  height = '100%',
}) => {
  const { formatMessageGeneric } = useFormatMessageGeneric();

  const currentThreshold: SpeedometerThreshold = useMemo(
    () =>
      thresholds.find((t) => currentValue <= t.threshold) ?? {
        color: 'blue1',
        label: '',
        threshold: maxValue,
      },
    [thresholds, currentValue, maxValue],
  );

  return (
    <FlexColumnCenterHorizontal
      style={{ height, width, gap: StyleUtils.Spacing.md }}
    >
      <h4 style={{ fontWeight: 600, marginBottom: 0 }}>
        {formatMessageGeneric(headingLabel)}
      </h4>
      <ParentSize>
        {({ height: containerHeight, width: containerWidth }) => {
          const size = Math.min(containerHeight, containerWidth);
          const centerPos = {
            x: containerWidth / 2,
            y: (containerHeight + SPEEDOMETER_SVG_TEXT_OFFSET) / 2,
          };

          return (
            <ChartContainer
              style={{ height: containerHeight, width: containerWidth }}
            >
              <svg
                height={containerHeight}
                width={containerWidth}
                style={{ top: 0, left: 0 }}
              >
                <Group top={centerPos.y} left={centerPos.x}>
                  <Pie
                    data={
                      currentValue && maxValue
                        ? [currentValue, maxValue - currentValue]
                        : [0, 1]
                    }
                    outerRadius={size / 2}
                    innerRadius={size / 2 - SPEEDOMETER_ARC_WIDTH}
                    cornerRadius={SPEEDOMETER_ARC_WIDTH}
                    startAngle={SPEEDOMETER_START_ANGLE}
                    endAngle={SPEEDOMETER_END_ANGLE}
                    pieSort={null}
                    pieSortValues={null}
                    padAngle={SPEEDOMETER_PAD_ANGLE}
                  >
                    {(pie) =>
                      pie.arcs.map((arc, idx) => (
                        <g key={`acc-${idx}`}>
                          <StyledPath
                            d={pie.path(arc) ?? undefined}
                            fill={
                              idx === 0
                                ? FIXED_COLORS[currentThreshold.color]
                                : FIXED_COLORS.gray3
                            }
                          />
                        </g>
                      ))
                    }
                  </Pie>
                  <text
                    y={size / 2 - SPEEDOMETER_SVG_TEXT_OFFSET}
                    fontSize={SPEEDOMETER_SVG_TEXT_SIZE}
                    textAnchor="middle"
                  >
                    <tspan fill={FIXED_COLORS[currentThreshold.color]}>
                      {currentValue}
                    </tspan>{' '}
                    / {maxValue}
                  </text>
                </Group>
              </svg>
              <div
                style={{
                  position: 'absolute',
                  width: iconSize,
                  height: iconSize,
                  top: centerPos.y - iconSize / 2,
                  left: centerPos.x - iconSize / 2,
                }}
              >
                <Icon type={iconType} size={iconSize} />
              </div>
            </ChartContainer>
          );
        }}
      </ParentSize>
      <div
        style={{
          color: FIXED_COLORS[currentThreshold.color],
          fontSize: 16,
        }}
      >
        {formatMessageGeneric(currentThreshold.label)}
      </div>
    </FlexColumnCenterHorizontal>
  );
};
