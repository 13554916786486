import { defineMessages } from '@transcend-io/internationalization';

export const miscPrivatePageTitleMessages = defineMessages(
  'admin-dashboard.Auth.MiscellaneousPrivate.miscPrivatePageTitle',
  {
    actionItems: {
      defaultMessage: 'Action Items',
    },
  },
);
