/**
 * The fields that an agent function can be ordered on
 */
export enum AgentFunctionOrderField {
  /** The name of the agent function */
  Name = 'name',
  /** The age of the agent function */
  CreatedAt = 'createdAt',
  /** The last updated time */
  UpdatedAt = 'updatedAt',
}
