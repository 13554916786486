import {
  PaginatedSelect,
  PaginatedSelectProps,
} from '@main/ad-core-components';
import { buildUseInfiniteScroll } from '@main/core-ui';
import {
  endpoints,
  LargeLanguageModel,
  LargeLanguageModelOrderField,
} from '@main/prompt-types';
import { ID, OrderDirection } from '@main/schema-utils';
import React, { useState } from 'react';
import { MenuPosition } from 'react-select';

import { selectLargeLanguageModelsMessages } from './messages';

const NODES = {
  id: null,
  name: null,
  client: null,
} as const;

/**
 * Selected prompt
 */
export type SelectedLargeLanguageModel = Pick<
  LargeLanguageModel,
  keyof typeof NODES
> & {
  /** Whether prompt is newly created prompt or existing prompt */
  isNew?: boolean;
};

const useSelectLargeLanguageModels = buildUseInfiniteScroll(
  endpoints.largeLanguageModels,
  'SelectLargeLanguageModels',
  {
    nodes: NODES,
  },
);

export interface SelectLargeLanguageModelProps
  extends Omit<
    PaginatedSelectProps<SelectedLargeLanguageModel, true>,
    'isQueryLoading' | 'queryError' | 'fetchMore' | 'onEndsTyping' | 'onChange'
  > {
  /** On change handler */
  onChange?: (largeLanguageModels: SelectedLargeLanguageModel[]) => void;

  /** How to position the menu (see docs for React Select) */
  menuPosition?: MenuPosition;
}

/**
 * Select a specific llm
 */
export const SelectLargeLanguageModels: React.FC<
  SelectLargeLanguageModelProps
> = ({
  placeholderDescriptor = selectLargeLanguageModelsMessages.placeholder,
  showOutline = true,
  onChange,
  menuPosition = 'fixed',
  ...paginatedSelectProps
}) => {
  const [searchText, setSearchText] = useState('');
  const { data, loading, error, fetchMore } = useSelectLargeLanguageModels({
    variables: {
      filterBy: {
        text: searchText,
      },
      orderBy: [
        {
          field: LargeLanguageModelOrderField.Client,
          direction: OrderDirection.ASC,
        },
        {
          field: LargeLanguageModelOrderField.Name,
          direction: OrderDirection.ASC,
        },
      ],
    },
    fetchPolicy: 'cache-and-network',
  });

  return (
    <PaginatedSelect
      id="select-large-language-models"
      showOutline={showOutline}
      placeholderDescriptor={placeholderDescriptor}
      options={data?.nodes ?? []}
      onChange={onChange as any}
      isQueryLoading={loading}
      isCreatable={false}
      isMulti
      menuPosition={menuPosition}
      getOptionValue={({ id }: SelectedLargeLanguageModel) => id}
      getOptionLabel={({ name, client }: SelectedLargeLanguageModel) =>
        `${client}: ${name}`
      }
      queryError={error}
      fetchMore={fetchMore}
      onEndsTyping={setSearchText}
      {...paginatedSelectProps}
    />
  );
};

/**
 * Select a single largeLanguageModel
 */
export const SelectLargeLanguageModel: React.FC<
  Omit<SelectLargeLanguageModelProps, 'value' | 'onChange'> & {
    /** Set the newly selected largeLanguageModel */
    onChange?: (largeLanguageModel: SelectedLargeLanguageModel) => void;
    /** Selected value */
    value?: SelectedLargeLanguageModel | ID<'largeLanguageModel'>;
  }
> = ({ onChange, value, ...props }) => (
  <SelectLargeLanguageModels
    {...props}
    isMulti={false}
    value={
      // default type is mixed array rather than array of one type or the other
      value
        ? ([value] as SelectedLargeLanguageModel[] | ID<'largeLanguageModel'>[])
        : []
    }
    onChange={onChange ? ([value]) => onChange(value) : undefined}
  />
);
