import 'reactflow/dist/style.css';

import ELK, { ElkExtendedEdge, ElkNode } from 'elkjs/lib/elk.bundled.js';
import { Edge, Node } from 'reactflow';

const reactFlowEdgeToElkEdge = (edge: Edge): ElkExtendedEdge => ({
  ...edge,
  sources: [edge.source],
  targets: [edge.target],
});

const elkEdgeToReactFlowEdge = (edge: ElkExtendedEdge): Edge => ({
  ...edge,
  source: edge.sources[0],
  target: edge.targets[0],
});

/**
 * Use ELK to get positioning of nodes and edges.
 */

const elk = new ELK();
export const getTreeLayout = (
  nodes: Node[],
  edges: Edge[],
  options: Record<string, string> = {},
): Promise<{
  /** Nodes in the tree */
  nodes: Node[];
  /** Edges between nodes */
  edges: Edge[];
}> => {
  const isHorizontal = options?.['elk.direction'] === 'RIGHT';
  const graph = {
    id: 'root',
    layoutOptions: options,
    children: nodes.map((node) => ({
      ...node,
      // Sets positions of handles on the node.
      targetPosition: isHorizontal ? 'left' : 'top',
      sourcePosition: isHorizontal ? 'right' : 'bottom',

      // Hardcode a width and height for elk to use when making layout.
      width: 150,
      height: 50,
    })),
    edges: edges.map(reactFlowEdgeToElkEdge),
  };

  // Use elk to get positions of nodes and edges and convert back to ReactFlow format.
  return elk.layout(graph).then((treeLayout) => ({
    nodes:
      treeLayout.children?.map((elkNode: ElkNode) => {
        // elkNode is generated from graph.children, so we can assume node is always going to be found
        const node = graph.children.find(({ id }) => id === elkNode.id) as Node;
        return {
          ...node,
          ...(elkNode.x && elkNode.y
            ? { position: { x: elkNode.x, y: elkNode.y } }
            : {}),
        };
      }) ?? [],
    edges: treeLayout.edges?.map(elkEdgeToReactFlowEdge) ?? [],
  }));
};
