import type { ExtendableRoute } from '@main/admin-dashboard/src/routes';
import {
  AdminDashboardContractScanningPath,
  CONTRACT_SCANNING_PATH_PREFIX,
} from '@main/contract-scanning-types';

import { ContractLoadable } from './Contract/Loadable';
import { ContractsLoadable } from './Contracts/Loadable';
import { ContractScanLoadable } from './ContractScan/Loadable';
import { contractScanningTabMessages } from './ContractScanningTabs/messages';
import { ContractScansLoadable } from './ContractScans/Loadable';

const contractsBreadcrumb = {
  id: CONTRACT_SCANNING_PATH_PREFIX,
  displayText: contractScanningTabMessages.contracts,
  href: AdminDashboardContractScanningPath.Contracts,
};

const contractScansBreadcrumbs = [
  contractsBreadcrumb,
  {
    id: AdminDashboardContractScanningPath.ContractScans,
    displayText: contractScanningTabMessages.contractScans,
    href: AdminDashboardContractScanningPath.ContractScans,
  },
];

export const contractScanningRoutes: Record<
  AdminDashboardContractScanningPath,
  ExtendableRoute
> = {
  [AdminDashboardContractScanningPath.Contracts]: {
    path: AdminDashboardContractScanningPath.Contracts,
    title: contractScanningTabMessages.contracts,
    component: ContractsLoadable,
    breadcrumbs: [contractsBreadcrumb],
  },
  [AdminDashboardContractScanningPath.NewContract]: {
    path: AdminDashboardContractScanningPath.NewContract,
    component: ContractLoadable,
    breadcrumbs: [contractsBreadcrumb],
  },
  [AdminDashboardContractScanningPath.ViewContract]: {
    path: AdminDashboardContractScanningPath.ViewContract,
    component: ContractLoadable,
    breadcrumbs: [contractsBreadcrumb],
  },
  [AdminDashboardContractScanningPath.EditContract]: {
    path: AdminDashboardContractScanningPath.EditContract,
    component: ContractLoadable,
    breadcrumbs: [contractsBreadcrumb],
  },
  [AdminDashboardContractScanningPath.ContractScans]: {
    path: AdminDashboardContractScanningPath.ContractScans,
    title: contractScanningTabMessages.contractScans,
    component: ContractScansLoadable,
    breadcrumbs: contractScansBreadcrumbs,
  },
  [AdminDashboardContractScanningPath.ContractScan]: {
    path: AdminDashboardContractScanningPath.ContractScan,
    component: ContractScanLoadable,
    breadcrumbs: contractScansBreadcrumbs,
  },
};
