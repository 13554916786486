import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { endpoints } from '@main/access-control-types';
import {
  buildUseTransformQuery,
  transformRawPreference,
} from '@main/ad-core-components';

import { selectUser } from './selectors';
import { setPreferences } from './slice';

const usePreference = buildUseTransformQuery(
  endpoints.preference,
  transformRawPreference,
);

export const usePreferences = (): void => {
  const user = useSelector(selectUser);
  const dispatch = useDispatch();

  const { data: { preference: userPreference } = {} } = usePreference({
    variables: {
      filterBy: { userId: user?.id },
    },
    skip: !user,
  });

  const { data: { preference: organizationPreference } = {} } = usePreference({
    variables: {
      filterBy: {},
    },
    fetchPolicy: 'cache-and-network',
    skip: !user,
  });

  useEffect(() => {
    if (user) {
      dispatch(
        setPreferences({
          organizationPreference,
          userPreference,
        }),
      );
    } else {
      dispatch(
        setPreferences({
          organizationPreference: null,
          userPreference: null,
        }),
      );
    }
  }, [dispatch, organizationPreference, user, userPreference]);
};
