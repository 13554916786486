import AntIcon, { IconProps as AntIconProps } from 'antd/lib/icon';
import styled from 'styled-components';

import {
  PrivacyCenterTheme,
  resolveColor,
  ResolveColorOptions,
} from '@main/theme';

/**
 * Styled I props
 */
export interface StyledIProps extends ResolveColorOptions {
  /** Make opaque on hover */
  hoverable?: string;
}

/**
 * The italic wrapper
 */
export const StyledI = styled.i<StyledIProps>`
  color: ${resolveColor()};
  ${({ hoverable }) =>
    hoverable &&
    `
    cursor: pointer;
    &:hover {
      opacity: .7;
    }
  `}
`;

/**
 * Icon props
 */
export interface IconWrapperProps {
  /** The color to display in */
  color?: string;
  /** The font size of the icon */
  fontSize?: string;
  /** Make opaque on hover */
  hoverable?: string;
  /** Pass styleTheme so theme can go to ant */
  styletheme: PrivacyCenterTheme;
  /** Icon theme goes through */
  theme: AntIconProps['theme'];
}

/**
 * The Icon wrapper
 *
 * @deprecated TODO: https://transcend.height.app/T-19886 - remove ant
 */
export const IconWrapper = styled(AntIcon)<IconWrapperProps>`
  color: ${({ color, styletheme }) =>
    resolveColor()({ color, theme: styletheme })};
  ${({ fontSize }) => fontSize && `font-size: ${fontSize};`}
  ${({ hoverable }) =>
    hoverable &&
    `
    cursor: pointer;
    &:hover {
      opacity: .7;
    }
  `}
`;
