/* eslint-disable max-lines */
import { AddTable } from './assets/add-table';
import { Approved } from './assets/approved';
import { ArrowCurvedDownLeft } from './assets/arrow-curved-down-left';
import { ArrowDown } from './assets/arrow-down';
import { ArrowLeft } from './assets/arrow-left';
import { ArrowLeftBackSquare } from './assets/arrow-left-back-square';
import { ArrowRight } from './assets/arrow-right';
import { ArrowRightBackSquare } from './assets/arrow-right-back-square';
import { ArrowRotate } from './assets/arrow-rotate';
import { ArrowUp } from './assets/arrow-up';
import { ArrowsMaximize } from './assets/arrows-maximize';
import { AssessmentTemplate } from './assets/assessment-template';
import { Binary } from './assets/binary';
import { Bold } from './assets/bold';
import { Book } from './assets/book';
import { BrowserWebEye } from './assets/browser-web-eye';
import { Bucket } from './assets/bucket';
import { BuildingHomeHouseRealEstate } from './assets/building-home-house-real-estate';
import { BulletedList } from './assets/bulleted-list';
import { Calendar } from './assets/calendar';
import { CaretDouble } from './assets/caret-double';
import { CaretDown } from './assets/caret-down';
import { CaretLeft } from './assets/caret-left';
import { CaretRight } from './assets/caret-right';
import { CaretUp } from './assets/caret-up';
import { ChangesRequested } from './assets/changes-requested';
import { ChatConversationQuestion } from './assets/chat-conversation-question';
import { Checkmark } from './assets/checkmark';
import { CheckmarkBubble } from './assets/checkmark-bubble';
import { CheckmarkCircle } from './assets/checkmark-circle';
import { CheckmarkGear } from './assets/checkmark-gear';
import { CircleAdd } from './assets/circle-add';
import { CircleDottedLine } from './assets/circle-dotted-line';
import { CircleQuestion } from './assets/circle-question';
import { ClearFormatting } from './assets/clear-formatting';
import { ClipboardCheck } from './assets/clipboard-check';
import { Clock } from './assets/clock';
import { ClockAndDataSilo } from './assets/clock-and-data-silo';
import { ClockArrowBack } from './assets/clock-arrow-back';
import { Close } from './assets/close';
import { CloseThick } from './assets/close-thick';
import { CodeMonitor } from './assets/code-monitor';
import { Columns } from './assets/columns';
import { Comment } from './assets/comment';
import { Compass } from './assets/compass';
import { Copy } from './assets/copy';
import { CopyAddPlus } from './assets/copy-add-plus';
import { CopyItemCheckmark } from './assets/copy-item-checkmark';
import { CopySimple } from './assets/copy-simple';
import { CreateFile } from './assets/create-file';
import { Cube } from './assets/cube';
import { CubeGrid } from './assets/cube-grid';
import { DataSiloAdd } from './assets/data-silo-add';
import { Database } from './assets/database';
import { DatabaseX } from './assets/database-x';
import { DocumentCheckmark } from './assets/document-checkmark';
import { DocumentContractEditPen } from './assets/document-contract-edit-pen';
import { DocumentCross } from './assets/document-cross';
import { DocumentLegalUsers } from './assets/document-legal-users';
import { DocumentQuestion } from './assets/document-question';
import { DocumentSearch } from './assets/document-search';
import { DotsVertical } from './assets/dots-vertical';
import { Download } from './assets/download';
import { Draft } from './assets/draft';
import { DsrTypeAccess } from './assets/dsr-type-access';
import { DsrTypeAutomatedDecisionMakingOptOut } from './assets/dsr-type-automated-decision-making-opt-out';
import { DsrTypeBusinessPurpose } from './assets/dsr-type-business-purpose';
import { DsrTypeContactOptOut } from './assets/dsr-type-contact-opt-out';
import { DsrTypeErasure } from './assets/dsr-type-erasure';
import { DsrTypeRectification } from './assets/dsr-type-rectification';
import { DsrTypeRestriction } from './assets/dsr-type-restriction';
import { DsrTypeSaleOptOut } from './assets/dsr-type-sale-opt-out';
import { DsrTypeTrackingOptOut } from './assets/dsr-type-tracking-opt-out';
import { EditFile } from './assets/edit-file';
import { Ellipsis } from './assets/ellipsis';
import { EmailLetterMailNotification } from './assets/email-letter-mail-notification';
import { EmailLetterMore } from './assets/email-letter-more';
import { EmployeeBadge } from './assets/employee-badge';
import { Envelope } from './assets/envelope';
import { ExclamationCircle } from './assets/exclamation-circle';
import { ExclamationTriangle } from './assets/exclamation-triangle';
import { ExternalLink } from './assets/external-link';
import { Eye } from './assets/eye';
import { EyeCheckmark } from './assets/eye-checkmark';
import { EyeSlash } from './assets/eye-slash';
import { File } from './assets/file';
import { FileCheckmark } from './assets/file-checkmark';
import { FileExclamation } from './assets/file-exclamation';
import { FileExport } from './assets/file-export';
import { FileFolderStack } from './assets/file-folder-stack';
import { FileImport } from './assets/file-import';
import { FileLoad } from './assets/file-load';
import { Filter } from './assets/filter';
import { Fire } from './assets/fire';
import { FlagAD } from './assets/flag-AD';
import { FlagAE } from './assets/flag-AE';
import { FlagAF } from './assets/flag-AF';
import { FlagAG } from './assets/flag-AG';
import { FlagAI } from './assets/flag-AI';
import { FlagAL } from './assets/flag-AL';
import { FlagAM } from './assets/flag-AM';
import { FlagAO } from './assets/flag-AO';
import { FlagAQ } from './assets/flag-AQ';
import { FlagAR } from './assets/flag-AR';
import { FlagAS } from './assets/flag-AS';
import { FlagAT } from './assets/flag-AT';
import { FlagAU } from './assets/flag-AU';
import { FlagAW } from './assets/flag-AW';
import { FlagAX } from './assets/flag-AX';
import { FlagAZ } from './assets/flag-AZ';
import { FlagBA } from './assets/flag-BA';
import { FlagBB } from './assets/flag-BB';
import { FlagBD } from './assets/flag-BD';
import { FlagBE } from './assets/flag-BE';
import { FlagBF } from './assets/flag-BF';
import { FlagBG } from './assets/flag-BG';
import { FlagBH } from './assets/flag-BH';
import { FlagBI } from './assets/flag-BI';
import { FlagBJ } from './assets/flag-BJ';
import { FlagBL } from './assets/flag-BL';
import { FlagBM } from './assets/flag-BM';
import { FlagBN } from './assets/flag-BN';
import { FlagBO } from './assets/flag-BO';
import { FlagBQ } from './assets/flag-BQ';
import { FlagBR } from './assets/flag-BR';
import { FlagBS } from './assets/flag-BS';
import { FlagBT } from './assets/flag-BT';
import { FlagBV } from './assets/flag-BV';
import { FlagBW } from './assets/flag-BW';
import { FlagBY } from './assets/flag-BY';
import { FlagBZ } from './assets/flag-BZ';
import { FlagCA } from './assets/flag-CA';
import { FlagCC } from './assets/flag-CC';
import { FlagCD } from './assets/flag-CD';
import { FlagCF } from './assets/flag-CF';
import { FlagCG } from './assets/flag-CG';
import { FlagCH } from './assets/flag-CH';
import { FlagCheckmark } from './assets/flag-checkmark';
import { FlagCI } from './assets/flag-CI';
import { FlagCK } from './assets/flag-CK';
import { FlagCL } from './assets/flag-CL';
import { FlagCM } from './assets/flag-CM';
import { FlagCN } from './assets/flag-CN';
import { FlagCO } from './assets/flag-CO';
import { FlagCR } from './assets/flag-CR';
import { FlagCU } from './assets/flag-CU';
import { FlagCV } from './assets/flag-CV';
import { FlagCW } from './assets/flag-CW';
import { FlagCX } from './assets/flag-CX';
import { FlagCY } from './assets/flag-CY';
import { FlagCZ } from './assets/flag-CZ';
import { FlagDE } from './assets/flag-DE';
import { FlagDJ } from './assets/flag-DJ';
import { FlagDK } from './assets/flag-DK';
import { FlagDM } from './assets/flag-DM';
import { FlagDO } from './assets/flag-DO';
import { FlagDZ } from './assets/flag-DZ';
import { FlagEC } from './assets/flag-EC';
import { FlagEE } from './assets/flag-EE';
import { FlagEG } from './assets/flag-EG';
import { FlagEH } from './assets/flag-EH';
import { FlagER } from './assets/flag-ER';
import { FlagES } from './assets/flag-ES';
import { FlagET } from './assets/flag-ET';
import { FlagEU } from './assets/flag-EU';
import { FlagFI } from './assets/flag-FI';
import { FlagFJ } from './assets/flag-FJ';
import { FlagFK } from './assets/flag-FK';
import { FlagFM } from './assets/flag-FM';
import { FlagFO } from './assets/flag-FO';
import { FlagFR } from './assets/flag-FR';
import { FlagGA } from './assets/flag-GA';
import { FlagGB } from './assets/flag-GB';
import { FlagGD } from './assets/flag-GD';
import { FlagGE } from './assets/flag-GE';
import { FlagGF } from './assets/flag-GF';
import { FlagGG } from './assets/flag-GG';
import { FlagGH } from './assets/flag-GH';
import { FlagGI } from './assets/flag-GI';
import { FlagGL } from './assets/flag-GL';
import { FlagGM } from './assets/flag-GM';
import { FlagGN } from './assets/flag-GN';
import { FlagGP } from './assets/flag-GP';
import { FlagGQ } from './assets/flag-GQ';
import { FlagGR } from './assets/flag-GR';
import { FlagGS } from './assets/flag-GS';
import { FlagGT } from './assets/flag-GT';
import { FlagGU } from './assets/flag-GU';
import { FlagGW } from './assets/flag-GW';
import { FlagGY } from './assets/flag-GY';
import { FlagHK } from './assets/flag-HK';
import { FlagHM } from './assets/flag-HM';
import { FlagHN } from './assets/flag-HN';
import { FlagHR } from './assets/flag-HR';
import { FlagHT } from './assets/flag-HT';
import { FlagHU } from './assets/flag-HU';
import { FlagID } from './assets/flag-ID';
import { FlagIE } from './assets/flag-IE';
import { FlagIL } from './assets/flag-IL';
import { FlagIM } from './assets/flag-IM';
import { FlagIN } from './assets/flag-IN';
import { FlagIO } from './assets/flag-IO';
import { FlagIQ } from './assets/flag-IQ';
import { FlagIR } from './assets/flag-IR';
import { FlagIS } from './assets/flag-IS';
import { FlagIT } from './assets/flag-IT';
import { FlagJE } from './assets/flag-JE';
import { FlagJM } from './assets/flag-JM';
import { FlagJO } from './assets/flag-JO';
import { FlagJP } from './assets/flag-JP';
import { FlagKE } from './assets/flag-KE';
import { FlagKG } from './assets/flag-KG';
import { FlagKH } from './assets/flag-KH';
import { FlagKI } from './assets/flag-KI';
import { FlagKM } from './assets/flag-KM';
import { FlagKN } from './assets/flag-KN';
import { FlagKP } from './assets/flag-KP';
import { FlagKR } from './assets/flag-KR';
import { FlagKW } from './assets/flag-KW';
import { FlagKY } from './assets/flag-KY';
import { FlagKZ } from './assets/flag-KZ';
import { FlagLA } from './assets/flag-LA';
import { FlagLB } from './assets/flag-LB';
import { FlagLC } from './assets/flag-LC';
import { FlagLI } from './assets/flag-LI';
import { FlagLK } from './assets/flag-LK';
import { FlagLR } from './assets/flag-LR';
import { FlagLS } from './assets/flag-LS';
import { FlagLT } from './assets/flag-LT';
import { FlagLU } from './assets/flag-LU';
import { FlagLV } from './assets/flag-LV';
import { FlagLY } from './assets/flag-LY';
import { FlagMA } from './assets/flag-MA';
import { FlagMC } from './assets/flag-MC';
import { FlagMD } from './assets/flag-MD';
import { FlagME } from './assets/flag-ME';
import { FlagMF } from './assets/flag-MF';
import { FlagMG } from './assets/flag-MG';
import { FlagMH } from './assets/flag-MH';
import { FlagMK } from './assets/flag-MK';
import { FlagML } from './assets/flag-ML';
import { FlagMM } from './assets/flag-MM';
import { FlagMN } from './assets/flag-MN';
import { FlagMO } from './assets/flag-MO';
import { FlagMP } from './assets/flag-MP';
import { FlagMQ } from './assets/flag-MQ';
import { FlagMR } from './assets/flag-MR';
import { FlagMS } from './assets/flag-MS';
import { FlagMT } from './assets/flag-MT';
import { FlagMU } from './assets/flag-MU';
import { FlagMV } from './assets/flag-MV';
import { FlagMW } from './assets/flag-MW';
import { FlagMX } from './assets/flag-MX';
import { FlagMY } from './assets/flag-MY';
import { FlagMZ } from './assets/flag-MZ';
import { FlagNA } from './assets/flag-NA';
import { FlagNC } from './assets/flag-NC';
import { FlagNE } from './assets/flag-NE';
import { FlagNF } from './assets/flag-NF';
import { FlagNG } from './assets/flag-NG';
import { FlagNI } from './assets/flag-NI';
import { FlagNL } from './assets/flag-NL';
import { FlagNO } from './assets/flag-NO';
import { FlagNP } from './assets/flag-NP';
import { FlagNR } from './assets/flag-NR';
import { FlagNU } from './assets/flag-NU';
import { FlagNZ } from './assets/flag-NZ';
import { FlagOM } from './assets/flag-OM';
import { FlagPA } from './assets/flag-PA';
import { FlagPE } from './assets/flag-PE';
import { FlagPF } from './assets/flag-PF';
import { FlagPG } from './assets/flag-PG';
import { FlagPH } from './assets/flag-PH';
import { FlagPK } from './assets/flag-PK';
import { FlagPL } from './assets/flag-PL';
import { FlagPM } from './assets/flag-PM';
import { FlagPN } from './assets/flag-PN';
import { FlagPR } from './assets/flag-PR';
import { FlagPS } from './assets/flag-PS';
import { FlagPT } from './assets/flag-PT';
import { FlagPW } from './assets/flag-PW';
import { FlagPY } from './assets/flag-PY';
import { FlagQA } from './assets/flag-QA';
import { FlagRE } from './assets/flag-RE';
import { FlagRO } from './assets/flag-RO';
import { FlagRS } from './assets/flag-RS';
import { FlagRU } from './assets/flag-RU';
import { FlagRW } from './assets/flag-RW';
import { FlagSA } from './assets/flag-SA';
import { FlagSB } from './assets/flag-SB';
import { FlagSC } from './assets/flag-SC';
import { FlagSD } from './assets/flag-SD';
import { FlagSE } from './assets/flag-SE';
import { FlagSG } from './assets/flag-SG';
import { FlagSH } from './assets/flag-SH';
import { FlagSI } from './assets/flag-SI';
import { FlagSJ } from './assets/flag-SJ';
import { FlagSK } from './assets/flag-SK';
import { FlagSL } from './assets/flag-SL';
import { FlagSM } from './assets/flag-SM';
import { FlagSN } from './assets/flag-SN';
import { FlagSO } from './assets/flag-SO';
import { FlagSR } from './assets/flag-SR';
import { FlagSS } from './assets/flag-SS';
import { FlagST } from './assets/flag-ST';
import { FlagSV } from './assets/flag-SV';
import { FlagSX } from './assets/flag-SX';
import { FlagSY } from './assets/flag-SY';
import { FlagSZ } from './assets/flag-SZ';
import { FlagTC } from './assets/flag-TC';
import { FlagTD } from './assets/flag-TD';
import { FlagTF } from './assets/flag-TF';
import { FlagTG } from './assets/flag-TG';
import { FlagTH } from './assets/flag-TH';
import { FlagTJ } from './assets/flag-TJ';
import { FlagTK } from './assets/flag-TK';
import { FlagTL } from './assets/flag-TL';
import { FlagTM } from './assets/flag-TM';
import { FlagTN } from './assets/flag-TN';
import { FlagTO } from './assets/flag-TO';
import { FlagTR } from './assets/flag-TR';
import { FlagTT } from './assets/flag-TT';
import { FlagTV } from './assets/flag-TV';
import { FlagTW } from './assets/flag-TW';
import { FlagTZ } from './assets/flag-TZ';
import { FlagUA } from './assets/flag-UA';
import { FlagUG } from './assets/flag-UG';
import { FlagUM } from './assets/flag-UM';
import { FlagUS } from './assets/flag-US';
import { FlagUY } from './assets/flag-UY';
import { FlagUZ } from './assets/flag-UZ';
import { FlagVA } from './assets/flag-VA';
import { FlagVC } from './assets/flag-VC';
import { FlagVE } from './assets/flag-VE';
import { FlagVG } from './assets/flag-VG';
import { FlagVI } from './assets/flag-VI';
import { FlagVN } from './assets/flag-VN';
import { FlagVU } from './assets/flag-VU';
import { FlagWF } from './assets/flag-WF';
import { FlagWS } from './assets/flag-WS';
import { FlagYE } from './assets/flag-YE';
import { FlagYT } from './assets/flag-YT';
import { FlagZA } from './assets/flag-ZA';
import { FlagZM } from './assets/flag-ZM';
import { FlagZW } from './assets/flag-ZW';
import { FloppyDisk } from './assets/floppy-disk';
import { Folder } from './assets/folder';
import { FolderPlus } from './assets/folder-plus';
import { Gear } from './assets/gear';
import { Glasses } from './assets/glasses';
import { GlobeCube } from './assets/globe-cube';
import { Grid } from './assets/grid';
import { HamburgerMenu } from './assets/hamburger-menu';
import { HandShapes } from './assets/hand-shapes';
import { HighRiseBuilding } from './assets/high-rise-building';
import { ICircle } from './assets/i-circle';
import { Image } from './assets/image';
import { InReview } from './assets/in-review';
import { InsertTable } from './assets/insert-table';
import { InsertTableColumnLeft } from './assets/insert-table-column-left';
import { InsertTableColumnRight } from './assets/insert-table-column-right';
import { InsertTableRowAbove } from './assets/insert-table-row-above';
import { InsertTableRowBelow } from './assets/insert-table-row-below';
import { Italic } from './assets/italic';
import { Junk } from './assets/junk';
import { Key } from './assets/key';
import { LanguageTranslate } from './assets/language-translate';
import { Lightning } from './assets/lightning';
import { LightningBox } from './assets/lightning-box';
import { LightningMovingFast } from './assets/lightning-moving-fast';
import { LinesTriple } from './assets/lines-triple';
import { Link } from './assets/link';
import { LinkAssessment } from './assets/link-assessment';
import { LinkUnlink } from './assets/link-unlink';
import { ListTree } from './assets/list-tree';
import { Lock } from './assets/lock';
import { LockHexagon } from './assets/lock-hexagon';
import { LogOut } from './assets/log-out';
import { MagnifyingGlassDocument } from './assets/magnifying-glass-document';
import { MagnifyingGlassPlusBox } from './assets/magnifying-glass-plus-box';
import { MenuBlockCheckmark } from './assets/menu-block-checkmark';
import { MenubarArrowDown } from './assets/menubar-arrow-down';
import { MergeTableCells } from './assets/merge-table-cells';
import { MinusSmall } from './assets/minus-small';
import { MonitorCableConnectionAddPlus } from './assets/monitor-cable-connection-add-plus';
import { MovingMail } from './assets/moving-mail';
import { NodeTriangle } from './assets/node-triangle';
import { NotJunk } from './assets/not-junk';
import { Notifications } from './assets/notifications';
import { NotificationsMuted } from './assets/notifications-muted';
import { OrderedList } from './assets/ordered-list';
import { Paperclip } from './assets/paperclip';
import { PasswordKey } from './assets/password-key';
import { Pencil } from './assets/pencil';
import { PencilGrid } from './assets/pencil-grid';
import { Phone } from './assets/phone';
import { PlaneFlightCanceled } from './assets/plane-flight-canceled';
import { Plugin } from './assets/plugin';
import { PluginAdd } from './assets/plugin-add';
import { Plus } from './assets/plus';
import { Power } from './assets/power';
import { PrivacyCenter } from './assets/privacy-center';
import { Profile } from './assets/profile';
import { QuestionBubble } from './assets/question-bubble';
import { QuestionMark } from './assets/question-mark';
import { Radar } from './assets/radar';
import { Refresh } from './assets/refresh';
import { Rejected } from './assets/rejected';
import { RemoveTable } from './assets/remove-table';
import { RemoveTableColumn } from './assets/remove-table-column';
import { RemoveTableRow } from './assets/remove-table-row';
import { ReScan } from './assets/reScan';
import { Retry } from './assets/retry';
import { RowNodes } from './assets/row-nodes';
import { Save } from './assets/save';
import { Search } from './assets/search';
import { SearchRadarLocation } from './assets/search-radar-location';
import { ServerDatabaseCheckmark } from './assets/server-database-checkmark';
import { ServerDatabasesCheckmark } from './assets/server-databases-checkmark';
import { ServerDatabasesKeyProtection } from './assets/server-databases-key-protection';
import { Shapes } from './assets/shapes';
import { Share } from './assets/share';
import { ShieldMagnifyingGlass } from './assets/shield-magnifying-glass';
import { SidebarAssessments } from './assets/sidebar-assessments';
import { SidebarConsent } from './assets/sidebar-consent';
import { SidebarConsentManager } from './assets/sidebar-consent-manager';
import { SidebarDataMap } from './assets/sidebar-data-map';
import { Skip } from './assets/skip';
import { Slack } from './assets/slack';
import { SortAscending } from './assets/sort-ascending';
import { SortDescending } from './assets/sort-descending';
import { Sparkles } from './assets/sparkles';
import { SpeechBubbleSlash } from './assets/speech-bubble-slash';
import { Sphere } from './assets/sphere';
import { SplitTableCell } from './assets/split-table-cell';
import { Spreadsheet } from './assets/spreadsheet';
import { StopHand } from './assets/stop-hand';
import { Stopwatch } from './assets/stopwatch';
import { StoreModernStyle } from './assets/store-modern-style';
import { StreetSigns } from './assets/street-signs';
import { Strikethrough } from './assets/strikethrough';
import { Submitted } from './assets/submitted';
import { Switch } from './assets/switch';
import { SystemStatus } from './assets/system-status';
import { Table } from './assets/table';
import { Team } from './assets/team';
import { Terminal } from './assets/terminal';
import { TextFile } from './assets/text-file';
import { TextSparkle } from './assets/text-sparkle';
import { ThumbsUp } from './assets/thumbs-up';
import { ThumbsUpCheck } from './assets/thumbs-up-check';
import { ToggleTableHeaderCell } from './assets/toggle-table-header-cell';
import { ToggleTableHeaderColumn } from './assets/toggle-table-header-column';
import { ToggleTableHeaderRow } from './assets/toggle-table-header-row';
import { Trash } from './assets/trash';
import { TrashDeleteRecycle } from './assets/trash-delete-recycle';
import { Underline } from './assets/underline';
import { Upload } from './assets/upload';
import { User } from './assets/user';
import { UserAdd } from './assets/user-add';
import { UserCard } from './assets/user-card';
import { UserExclamation } from './assets/user-exclamation';
import { ViewDetails } from './assets/view-details';
import { ViewFile } from './assets/view-file';
import { XBubble } from './assets/x-bubble';
import { XNoPadding } from './assets/x-no-padding';
import { ZipFile } from './assets/zip-file';
import { IconType, SvgProps } from './enums';

export { IconType };

/**
 * The types of icons and their respective SVGs
 * !!! Add to this whenever a new icon is added !!!
 */
export const ICON_TYPES: {
  [k in IconType]: React.FC<SvgProps>;
} = {
  [IconType.AddTable]: AddTable,
  [IconType.Approved]: Approved,
  [IconType.ArrowCurvedDownLeft]: ArrowCurvedDownLeft,
  [IconType.ArrowDown]: ArrowDown,
  [IconType.ArrowLeft]: ArrowLeft,
  [IconType.ArrowLeftBackSquare]: ArrowLeftBackSquare,
  [IconType.ArrowRight]: ArrowRight,
  [IconType.ArrowRightBackSquare]: ArrowRightBackSquare,
  [IconType.ArrowRotate]: ArrowRotate,
  [IconType.ArrowsMaximize]: ArrowsMaximize,
  [IconType.ArrowUp]: ArrowUp,
  [IconType.AssessmentTemplate]: AssessmentTemplate,
  [IconType.Binary]: Binary,
  [IconType.Bold]: Bold,
  [IconType.Book]: Book,
  [IconType.BrowserWebEye]: BrowserWebEye,
  [IconType.Bucket]: Bucket,
  [IconType.BuildingHomeHouseRealEstate]: BuildingHomeHouseRealEstate,
  [IconType.BulletedList]: BulletedList,
  [IconType.Calendar]: Calendar,
  [IconType.CaretDouble]: CaretDouble,
  [IconType.CaretDown]: CaretDown,
  [IconType.CaretLeft]: CaretLeft,
  [IconType.CaretRight]: CaretRight,
  [IconType.CaretUp]: CaretUp,
  [IconType.ChangesRequested]: ChangesRequested,
  [IconType.ChatConversationQuestion]: ChatConversationQuestion,
  [IconType.CheckmarkBubble]: CheckmarkBubble,
  [IconType.CheckmarkCircle]: CheckmarkCircle,
  [IconType.CheckmarkGear]: CheckmarkGear,
  [IconType.Checkmark]: Checkmark,
  [IconType.CircleAdd]: CircleAdd,
  [IconType.CircleDottedLine]: CircleDottedLine,
  [IconType.CircleQuestion]: CircleQuestion,
  [IconType.ClearFormatting]: ClearFormatting,
  [IconType.ClipboardCheck]: ClipboardCheck,
  [IconType.Clock]: Clock,
  [IconType.ClockAndDataSilo]: ClockAndDataSilo,
  [IconType.ClockArrowBack]: ClockArrowBack,
  [IconType.Close]: Close,
  [IconType.CloseThick]: CloseThick,
  [IconType.CodeMonitor]: CodeMonitor,
  [IconType.Columns]: Columns,
  [IconType.Comment]: Comment,
  [IconType.Compass]: Compass,
  [IconType.Copy]: Copy,
  [IconType.CopyAddPlus]: CopyAddPlus,
  [IconType.CopyItemCheckmark]: CopyItemCheckmark,
  [IconType.CopySimple]: CopySimple,
  [IconType.CreateFile]: CreateFile,
  [IconType.Cube]: Cube,
  [IconType.CubeGrid]: CubeGrid,
  [IconType.Database]: Database,
  [IconType.DatabaseX]: DatabaseX,
  [IconType.DataSiloAdd]: DataSiloAdd,
  [IconType.DocumentCheckmark]: DocumentCheckmark,
  [IconType.DocumentContractEditPen]: DocumentContractEditPen,
  [IconType.DocumentCross]: DocumentCross,
  [IconType.DocumentLegalUsers]: DocumentLegalUsers,
  [IconType.DocumentQuestion]: DocumentQuestion,
  [IconType.DocumentSearch]: DocumentSearch,
  [IconType.DotsVertical]: DotsVertical,
  [IconType.Download]: Download,
  [IconType.Draft]: Draft,
  [IconType.DsrTypeAccess]: DsrTypeAccess,
  [IconType.DsrTypeAutomatedDecisionMakingOptOut]:
    DsrTypeAutomatedDecisionMakingOptOut,
  [IconType.DsrTypeBusinessPurpose]: DsrTypeBusinessPurpose,
  [IconType.DsrTypeContactOptOut]: DsrTypeContactOptOut,
  [IconType.DsrTypeErasure]: DsrTypeErasure,
  [IconType.DsrTypeRectification]: DsrTypeRectification,
  [IconType.DsrTypeRestriction]: DsrTypeRestriction,
  [IconType.DsrTypeSaleOptOut]: DsrTypeSaleOptOut,
  [IconType.DsrTypeTrackingOptOut]: DsrTypeTrackingOptOut,
  [IconType.EditFile]: EditFile,
  [IconType.Ellipsis]: Ellipsis,
  [IconType.EmailLetterMailNotification]: EmailLetterMailNotification,
  [IconType.EmailLetterMore]: EmailLetterMore,
  [IconType.EmployeeBadge]: EmployeeBadge,
  [IconType.Envelope]: Envelope,
  [IconType.ExclamationCircle]: ExclamationCircle,
  [IconType.ExclamationTriangle]: ExclamationTriangle,
  [IconType.ExternalLink]: ExternalLink,
  [IconType.Eye]: Eye,
  [IconType.EyeCheckmark]: EyeCheckmark,
  [IconType.EyeSlash]: EyeSlash,
  [IconType.File]: File,
  [IconType.FileCheckmark]: FileCheckmark,
  [IconType.FileExclamation]: FileExclamation,
  [IconType.FileExport]: FileExport,
  [IconType.FileFolderStack]: FileFolderStack,
  [IconType.FileImport]: FileImport,
  [IconType.FileLoad]: FileLoad,
  [IconType.Filter]: Filter,
  [IconType.Fire]: Fire,
  [IconType.FloppyDisk]: FloppyDisk,
  [IconType.FlagCheckmark]: FlagCheckmark,
  [IconType.Folder]: Folder,
  [IconType.FolderPlus]: FolderPlus,
  [IconType.Gear]: Gear,
  [IconType.Glasses]: Glasses,
  [IconType.GlobeCube]: GlobeCube,
  [IconType.Grid]: Grid,
  [IconType.HamburgerMenu]: HamburgerMenu,
  [IconType.HandShapes]: HandShapes,
  [IconType.HighRiseBuilding]: HighRiseBuilding,
  [IconType.ICircle]: ICircle,
  [IconType.Image]: Image,
  [IconType.InReview]: InReview,
  [IconType.InsertTable]: InsertTable,
  [IconType.InsertTableColumnLeft]: InsertTableColumnLeft,
  [IconType.InsertTableColumnRight]: InsertTableColumnRight,
  [IconType.InsertTableRowAbove]: InsertTableRowAbove,
  [IconType.InsertTableRowBelow]: InsertTableRowBelow,
  [IconType.Italic]: Italic,
  [IconType.Junk]: Junk,
  [IconType.Key]: Key,
  [IconType.LanguageTranslate]: LanguageTranslate,
  [IconType.Lightning]: Lightning,
  [IconType.LightningBox]: LightningBox,
  [IconType.LightningMovingFast]: LightningMovingFast,
  [IconType.LinesTriple]: LinesTriple,
  [IconType.Link]: Link,
  [IconType.LinkAssessment]: LinkAssessment,
  [IconType.LinkUnlink]: LinkUnlink,
  [IconType.ListTree]: ListTree,
  [IconType.Lock]: Lock,
  [IconType.LockHexagon]: LockHexagon,
  [IconType.LogOut]: LogOut,
  [IconType.MagnifyingGlassDocument]: MagnifyingGlassDocument,
  [IconType.MagnifyingGlassPlusBox]: MagnifyingGlassPlusBox,
  [IconType.MenubarArrowDown]: MenubarArrowDown,
  [IconType.MenuBlockCheckmark]: MenuBlockCheckmark,
  [IconType.MergeTableCells]: MergeTableCells,
  [IconType.MinusSmall]: MinusSmall,
  [IconType.MonitorCableConnectionAddPlus]: MonitorCableConnectionAddPlus,
  [IconType.MovingMail]: MovingMail,
  [IconType.NodeTriangle]: NodeTriangle,
  [IconType.Notifications]: Notifications,
  [IconType.NotificationsMuted]: NotificationsMuted,
  [IconType.NotJunk]: NotJunk,
  [IconType.OrderedList]: OrderedList,
  [IconType.PasswordKey]: PasswordKey,
  [IconType.Paperclip]: Paperclip,
  [IconType.Pencil]: Pencil,
  [IconType.PencilGrid]: PencilGrid,
  [IconType.Phone]: Phone,
  [IconType.PlaneFlightCanceled]: PlaneFlightCanceled,
  [IconType.Plugin]: Plugin,
  [IconType.PluginAdd]: PluginAdd,
  [IconType.Plus]: Plus,
  [IconType.Power]: Power,
  [IconType.PrivacyCenter]: PrivacyCenter,
  [IconType.Profile]: Profile,
  [IconType.QuestionBubble]: QuestionBubble,
  [IconType.QuestionMark]: QuestionMark,
  [IconType.Radar]: Radar,
  [IconType.Refresh]: Refresh,
  [IconType.Rejected]: Rejected,
  [IconType.RemoveTable]: RemoveTable,
  [IconType.RemoveTableColumn]: RemoveTableColumn,
  [IconType.RemoveTableRow]: RemoveTableRow,
  [IconType.ReScan]: ReScan,
  [IconType.Retry]: Retry,
  [IconType.RowNodes]: RowNodes,
  [IconType.Save]: Save,
  [IconType.Search]: Search,
  [IconType.SearchRadarLocation]: SearchRadarLocation,
  [IconType.ServerDatabaseCheckmark]: ServerDatabaseCheckmark,
  [IconType.ServerDatabasesCheckmark]: ServerDatabasesCheckmark,
  [IconType.ServerDatabasesKeyProtection]: ServerDatabasesKeyProtection,
  [IconType.Shapes]: Shapes,
  [IconType.Share]: Share,
  [IconType.ShieldMagnifyingGlass]: ShieldMagnifyingGlass,
  [IconType.SidebarAssessments]: SidebarAssessments,
  [IconType.SidebarConsent]: SidebarConsent,
  [IconType.SidebarConsentManager]: SidebarConsentManager,
  [IconType.SidebarDataMap]: SidebarDataMap,
  [IconType.Skip]: Skip,
  [IconType.Slack]: Slack,
  [IconType.SortAscending]: SortAscending,
  [IconType.SortDescending]: SortDescending,
  [IconType.Sparkles]: Sparkles,
  [IconType.SpeechBubbleSlash]: SpeechBubbleSlash,
  [IconType.Spreadsheet]: Spreadsheet,
  [IconType.Sphere]: Sphere,
  [IconType.SplitTableCell]: SplitTableCell,
  [IconType.Stopwatch]: Stopwatch,
  [IconType.StopHand]: StopHand,
  [IconType.StoreModernStyle]: StoreModernStyle,
  [IconType.StreetSigns]: StreetSigns,
  [IconType.Strikethrough]: Strikethrough,
  [IconType.Submitted]: Submitted,
  [IconType.Switch]: Switch,
  [IconType.SystemStatus]: SystemStatus,
  [IconType.Table]: Table,
  [IconType.Team]: Team,
  [IconType.Terminal]: Terminal,
  [IconType.TextFile]: TextFile,
  [IconType.TextSparkle]: TextSparkle,
  [IconType.ThumbsUp]: ThumbsUp,
  [IconType.ThumbsUpCheck]: ThumbsUpCheck,
  [IconType.ToggleTableHeaderCell]: ToggleTableHeaderCell,
  [IconType.ToggleTableHeaderColumn]: ToggleTableHeaderColumn,
  [IconType.ToggleTableHeaderRow]: ToggleTableHeaderRow,
  [IconType.Trash]: Trash,
  [IconType.TrashDeleteRecycle]: TrashDeleteRecycle,
  [IconType.Underline]: Underline,
  [IconType.Upload]: Upload,
  [IconType.User]: User,
  [IconType.UserAdd]: UserAdd,
  [IconType.UserCard]: UserCard,
  [IconType.UserExclamation]: UserExclamation,
  [IconType.ViewDetails]: ViewDetails,
  [IconType.ViewFile]: ViewFile,
  [IconType.XBubble]: XBubble,
  [IconType.XNoPadding]: XNoPadding,
  [IconType.ZipFile]: ZipFile,
  [IconType.FlagUS]: FlagUS,
  [IconType.FlagEU]: FlagEU,
  [IconType.FlagAF]: FlagAF,
  [IconType.FlagAX]: FlagAX,
  [IconType.FlagAL]: FlagAL,
  [IconType.FlagDZ]: FlagDZ,
  [IconType.FlagAS]: FlagAS,
  [IconType.FlagAD]: FlagAD,
  [IconType.FlagAO]: FlagAO,
  [IconType.FlagAI]: FlagAI,
  [IconType.FlagAQ]: FlagAQ,
  [IconType.FlagAG]: FlagAG,
  [IconType.FlagAR]: FlagAR,
  [IconType.FlagAM]: FlagAM,
  [IconType.FlagAW]: FlagAW,
  [IconType.FlagAU]: FlagAU,
  [IconType.FlagAT]: FlagAT,
  [IconType.FlagAZ]: FlagAZ,
  [IconType.FlagBS]: FlagBS,
  [IconType.FlagBH]: FlagBH,
  [IconType.FlagBD]: FlagBD,
  [IconType.FlagBB]: FlagBB,
  [IconType.FlagBY]: FlagBY,
  [IconType.FlagBE]: FlagBE,
  [IconType.FlagBZ]: FlagBZ,
  [IconType.FlagBJ]: FlagBJ,
  [IconType.FlagBM]: FlagBM,
  [IconType.FlagBT]: FlagBT,
  [IconType.FlagBO]: FlagBO,
  [IconType.FlagBA]: FlagBA,
  [IconType.FlagBW]: FlagBW,
  [IconType.FlagBV]: FlagBV,
  [IconType.FlagBR]: FlagBR,
  [IconType.FlagIO]: FlagIO,
  [IconType.FlagVG]: FlagVG,
  [IconType.FlagBN]: FlagBN,
  [IconType.FlagBG]: FlagBG,
  [IconType.FlagBF]: FlagBF,
  [IconType.FlagBI]: FlagBI,
  [IconType.FlagKH]: FlagKH,
  [IconType.FlagCM]: FlagCM,
  [IconType.FlagCA]: FlagCA,
  [IconType.FlagCV]: FlagCV,
  [IconType.FlagBQ]: FlagBQ,
  [IconType.FlagKY]: FlagKY,
  [IconType.FlagCF]: FlagCF,
  [IconType.FlagTD]: FlagTD,
  [IconType.FlagCL]: FlagCL,
  [IconType.FlagCN]: FlagCN,
  [IconType.FlagCX]: FlagCX,
  [IconType.FlagCC]: FlagCC,
  [IconType.FlagCO]: FlagCO,
  [IconType.FlagKM]: FlagKM,
  [IconType.FlagCG]: FlagCG,
  [IconType.FlagCD]: FlagCD,
  [IconType.FlagCK]: FlagCK,
  [IconType.FlagCR]: FlagCR,
  [IconType.FlagCI]: FlagCI,
  [IconType.FlagHR]: FlagHR,
  [IconType.FlagCU]: FlagCU,
  [IconType.FlagCW]: FlagCW,
  [IconType.FlagCY]: FlagCY,
  [IconType.FlagCZ]: FlagCZ,
  [IconType.FlagDK]: FlagDK,
  [IconType.FlagDJ]: FlagDJ,
  [IconType.FlagDM]: FlagDM,
  [IconType.FlagDO]: FlagDO,
  [IconType.FlagEC]: FlagEC,
  [IconType.FlagEG]: FlagEG,
  [IconType.FlagSV]: FlagSV,
  [IconType.FlagGQ]: FlagGQ,
  [IconType.FlagER]: FlagER,
  [IconType.FlagEE]: FlagEE,
  [IconType.FlagSZ]: FlagSZ,
  [IconType.FlagET]: FlagET,
  [IconType.FlagFK]: FlagFK,
  [IconType.FlagFO]: FlagFO,
  [IconType.FlagFJ]: FlagFJ,
  [IconType.FlagFI]: FlagFI,
  [IconType.FlagFR]: FlagFR,
  [IconType.FlagGF]: FlagGF,
  [IconType.FlagPF]: FlagPF,
  [IconType.FlagTF]: FlagTF,
  [IconType.FlagGA]: FlagGA,
  [IconType.FlagGM]: FlagGM,
  [IconType.FlagGE]: FlagGE,
  [IconType.FlagDE]: FlagDE,
  [IconType.FlagGH]: FlagGH,
  [IconType.FlagGI]: FlagGI,
  [IconType.FlagGR]: FlagGR,
  [IconType.FlagGL]: FlagGL,
  [IconType.FlagGD]: FlagGD,
  [IconType.FlagGP]: FlagGP,
  [IconType.FlagGU]: FlagGU,
  [IconType.FlagGT]: FlagGT,
  [IconType.FlagGG]: FlagGG,
  [IconType.FlagGN]: FlagGN,
  [IconType.FlagGW]: FlagGW,
  [IconType.FlagGY]: FlagGY,
  [IconType.FlagHT]: FlagHT,
  [IconType.FlagHM]: FlagHM,
  [IconType.FlagHN]: FlagHN,
  [IconType.FlagHK]: FlagHK,
  [IconType.FlagHU]: FlagHU,
  [IconType.FlagIS]: FlagIS,
  [IconType.FlagIN]: FlagIN,
  [IconType.FlagID]: FlagID,
  [IconType.FlagIR]: FlagIR,
  [IconType.FlagIQ]: FlagIQ,
  [IconType.FlagIE]: FlagIE,
  [IconType.FlagIM]: FlagIM,
  [IconType.FlagIL]: FlagIL,
  [IconType.FlagIT]: FlagIT,
  [IconType.FlagJM]: FlagJM,
  [IconType.FlagJP]: FlagJP,
  [IconType.FlagJE]: FlagJE,
  [IconType.FlagJO]: FlagJO,
  [IconType.FlagKZ]: FlagKZ,
  [IconType.FlagKE]: FlagKE,
  [IconType.FlagKI]: FlagKI,
  [IconType.FlagKW]: FlagKW,
  [IconType.FlagKG]: FlagKG,
  [IconType.FlagLA]: FlagLA,
  [IconType.FlagLV]: FlagLV,
  [IconType.FlagLB]: FlagLB,
  [IconType.FlagLS]: FlagLS,
  [IconType.FlagLR]: FlagLR,
  [IconType.FlagLY]: FlagLY,
  [IconType.FlagLI]: FlagLI,
  [IconType.FlagLT]: FlagLT,
  [IconType.FlagLU]: FlagLU,
  [IconType.FlagMO]: FlagMO,
  [IconType.FlagMG]: FlagMG,
  [IconType.FlagMW]: FlagMW,
  [IconType.FlagMY]: FlagMY,
  [IconType.FlagMV]: FlagMV,
  [IconType.FlagML]: FlagML,
  [IconType.FlagMT]: FlagMT,
  [IconType.FlagMH]: FlagMH,
  [IconType.FlagMQ]: FlagMQ,
  [IconType.FlagMR]: FlagMR,
  [IconType.FlagMU]: FlagMU,
  [IconType.FlagYT]: FlagYT,
  [IconType.FlagMX]: FlagMX,
  [IconType.FlagFM]: FlagFM,
  [IconType.FlagMD]: FlagMD,
  [IconType.FlagMC]: FlagMC,
  [IconType.FlagMN]: FlagMN,
  [IconType.FlagME]: FlagME,
  [IconType.FlagMS]: FlagMS,
  [IconType.FlagMA]: FlagMA,
  [IconType.FlagMZ]: FlagMZ,
  [IconType.FlagMM]: FlagMM,
  [IconType.FlagNA]: FlagNA,
  [IconType.FlagNR]: FlagNR,
  [IconType.FlagNP]: FlagNP,
  [IconType.FlagNL]: FlagNL,
  [IconType.FlagNC]: FlagNC,
  [IconType.FlagNZ]: FlagNZ,
  [IconType.FlagNI]: FlagNI,
  [IconType.FlagNE]: FlagNE,
  [IconType.FlagNG]: FlagNG,
  [IconType.FlagNU]: FlagNU,
  [IconType.FlagNF]: FlagNF,
  [IconType.FlagKP]: FlagKP,
  [IconType.FlagMK]: FlagMK,
  [IconType.FlagMP]: FlagMP,
  [IconType.FlagNO]: FlagNO,
  [IconType.FlagOM]: FlagOM,
  [IconType.FlagPK]: FlagPK,
  [IconType.FlagPW]: FlagPW,
  [IconType.FlagPS]: FlagPS,
  [IconType.FlagPA]: FlagPA,
  [IconType.FlagPG]: FlagPG,
  [IconType.FlagPY]: FlagPY,
  [IconType.FlagPE]: FlagPE,
  [IconType.FlagPH]: FlagPH,
  [IconType.FlagPN]: FlagPN,
  [IconType.FlagPL]: FlagPL,
  [IconType.FlagPT]: FlagPT,
  [IconType.FlagPR]: FlagPR,
  [IconType.FlagQA]: FlagQA,
  [IconType.FlagRE]: FlagRE,
  [IconType.FlagRO]: FlagRO,
  [IconType.FlagRU]: FlagRU,
  [IconType.FlagRW]: FlagRW,
  [IconType.FlagWS]: FlagWS,
  [IconType.FlagSM]: FlagSM,
  [IconType.FlagST]: FlagST,
  [IconType.FlagSA]: FlagSA,
  [IconType.FlagSN]: FlagSN,
  [IconType.FlagRS]: FlagRS,
  [IconType.FlagSC]: FlagSC,
  [IconType.FlagSL]: FlagSL,
  [IconType.FlagSG]: FlagSG,
  [IconType.FlagSX]: FlagSX,
  [IconType.FlagSK]: FlagSK,
  [IconType.FlagSI]: FlagSI,
  [IconType.FlagSB]: FlagSB,
  [IconType.FlagSO]: FlagSO,
  [IconType.FlagZA]: FlagZA,
  [IconType.FlagGS]: FlagGS,
  [IconType.FlagKR]: FlagKR,
  [IconType.FlagSS]: FlagSS,
  [IconType.FlagES]: FlagES,
  [IconType.FlagLK]: FlagLK,
  [IconType.FlagBL]: FlagBL,
  [IconType.FlagSH]: FlagSH,
  [IconType.FlagKN]: FlagKN,
  [IconType.FlagLC]: FlagLC,
  [IconType.FlagMF]: FlagMF,
  [IconType.FlagPM]: FlagPM,
  [IconType.FlagVC]: FlagVC,
  [IconType.FlagSD]: FlagSD,
  [IconType.FlagSR]: FlagSR,
  [IconType.FlagSJ]: FlagSJ,
  [IconType.FlagSE]: FlagSE,
  [IconType.FlagCH]: FlagCH,
  [IconType.FlagSY]: FlagSY,
  [IconType.FlagTW]: FlagTW,
  [IconType.FlagTJ]: FlagTJ,
  [IconType.FlagTZ]: FlagTZ,
  [IconType.FlagTH]: FlagTH,
  [IconType.FlagTL]: FlagTL,
  [IconType.FlagTG]: FlagTG,
  [IconType.FlagTK]: FlagTK,
  [IconType.FlagTO]: FlagTO,
  [IconType.FlagTT]: FlagTT,
  [IconType.FlagTN]: FlagTN,
  [IconType.FlagTR]: FlagTR,
  [IconType.FlagTM]: FlagTM,
  [IconType.FlagTC]: FlagTC,
  [IconType.FlagTV]: FlagTV,
  [IconType.FlagUM]: FlagUM,
  [IconType.FlagVI]: FlagVI,
  [IconType.FlagUG]: FlagUG,
  [IconType.FlagUA]: FlagUA,
  [IconType.FlagAE]: FlagAE,
  [IconType.FlagGB]: FlagGB,
  [IconType.FlagUY]: FlagUY,
  [IconType.FlagUZ]: FlagUZ,
  [IconType.FlagVU]: FlagVU,
  [IconType.FlagVA]: FlagVA,
  [IconType.FlagVE]: FlagVE,
  [IconType.FlagVN]: FlagVN,
  [IconType.FlagWF]: FlagWF,
  [IconType.FlagEH]: FlagEH,
  [IconType.FlagYE]: FlagYE,
  [IconType.FlagZM]: FlagZM,
  [IconType.FlagZW]: FlagZW,
};
/* eslint-enable max-lines */
