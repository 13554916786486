import styled from 'styled-components';

// TODO: https://transcend.height.app/T-15309 - break out into separate components
export const NumberInput = styled.input`
  // hide arrows
  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  // hide arrows
  -moz-appearance: textfield;
  height: 40px;
  width: 42px;
  padding: 8px;
  border: 1px solid ${({ theme }) => theme.colors.gray3};
  border-radius: 6px;
  font-size: 12px;
  color: ${({ theme }) => theme.colors.transcendNavy3};
  margin-left: 8px;
  outline: none;
  transition: all 200ms ease-out;

  :focus :focus-visible {
    border-color: ${({ theme }) => theme.colors.transcend3};
  }

  :hover {
    border-color: ${({ theme }) => theme.colors.transcendNavy3};
  }

  + span {
    padding-left: 2px;
    font-size: 12px;
    color: ${({ theme }) => theme.colors.transcendNavy3};
  }
`;
