import * as t from 'io-ts';

import { dbModelId } from '@main/schema-utils';

import { AuditEventCode } from '../schema/enums';
import {
  mkAuditEventCodec,
  mkAuditEventJoinCodec,
  mkAuditJoinStateCodec,
} from '../types';

export const AuditEventOrgTcfSpecialFeature = mkAuditEventCodec(
  AuditEventCode.OrgTcfSpecialFeature,
  t.type({
    /** ID of the tcf SpecialFeature for an organization */
    orgTcfSpecialFeatureId: dbModelId('orgTcfSpecialFeature'),
  }),
);

/** Override types. */
export type AuditEventOrgTcfSpecialFeature = t.TypeOf<
  typeof AuditEventOrgTcfSpecialFeature
>;

export const AuditEventOrgTcfSpecialFeaturePurpose = mkAuditEventJoinCodec(
  AuditEventCode.OrgTcfSpecialFeaturePurpose,
  mkAuditJoinStateCodec('purpose'),
  t.type({
    /** ID of the tcf SpecialFeature for an organization */
    orgTcfSpecialFeatureId: dbModelId('orgTcfSpecialFeature'),
  }),
);

/** Override types. */
export type AuditEventOrgTcfSpecialFeaturePurpose = t.TypeOf<
  typeof AuditEventOrgTcfSpecialFeaturePurpose
>;
