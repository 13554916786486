import {
  ActionPreview,
  DataPointPreviewWithDataSilo,
  DataSiloPreview,
  DataSubject,
  DataSubjectInternal,
} from '@main/datamap-types';
import { mkInput, mkType, SchemaToType } from '@main/schema-utils';

export const ConsentWorkflowActionPreview = mkType({
  name: 'ConsentWorkflowActionPreview',
  comment: 'Preview of action with subset of fields',
  fields: {
    id: ActionPreview.fields.id,
    subjectTitle: {
      ...ActionPreview.fields.title,
      comment:
        'The display title of the request action with associated translations',
    },
    type: ActionPreview.fields.type,
  },
});

/** Override type */
export type ConsentWorkflowActionPreview = SchemaToType<
  typeof ConsentWorkflowActionPreview
>;

export const ConsentWorkflowSubjectPreview = mkType({
  name: 'ConsentWorkflowSubjectPreview',
  comment: 'Preview of data subject with subset of fields',
  fields: {
    id: DataSubject.fields.id,
    title: DataSubject.fields.title,
    type: DataSubject.fields.type,
    active: DataSubjectInternal.fields.active,
    icon: { ...DataSubject.fields.icon, optional: true },
  },
});

/** Override type */
export type ConsentWorkflowSubjectPreview = SchemaToType<
  typeof ConsentWorkflowSubjectPreview
>;

export const ConsentWorkflowTrigger = mkType({
  name: 'ConsentWorkflowTrigger',
  comment: 'The Consent Workflow Trigger',
  fields: {
    id: {
      type: 'id',
      modelName: 'consentWorkflowTrigger',
      comment: 'ID of the consent Workflow Trigger',
    },
    name: {
      type: 'string',
      comment: 'The name of the consent workflow trigger',
    },
    triggerCondition: {
      comment: 'The condition to trigger the consent workflow',
      type: 'string',
    },
    action: {
      comment: 'The action associated to the consent workflow trigger',
      type: ConsentWorkflowActionPreview,
    },
    subject: {
      comment: 'The data-subject associated to the consent workflow trigger',
      type: ConsentWorkflowSubjectPreview,
    },
    isSilent: {
      type: 'boolean',
      comment: 'If a DSR is triggered, is it a silent request',
    },
    allowUnauthenticated: {
      type: 'boolean',
      comment:
        'If workflow is allowed to be triggered for unauthenticated users',
    },
    isActive: {
      type: 'boolean',
      comment: 'If the trigger is active',
    },
    dataSilos: {
      type: DataSiloPreview,
      comment: 'The set of data silos that are configured for this workflow',
      list: true,
    },
    dataPoints: {
      type: DataPointPreviewWithDataSilo,
      comment: 'The set of datapoints that are configured for this workflow',
      list: true,
    },
  },
});

/** Override type */
export type ConsentWorkflowTrigger = SchemaToType<
  typeof ConsentWorkflowTrigger
>;

export const ConsentWorkflowTriggerPurposeInput = mkInput({
  name: 'ConsentWorkflowTriggerPurposeInput',
  comment: 'Marketing purposes id with matching state',
  fields: {
    purposeId: {
      type: 'id',
      modelName: 'purpose',
      comment: 'ID of the purpose',
      optional: true,
    },
    matchingState: {
      type: 'boolean',
      comment: 'The state of the purpose to trigger the workflow',
    },
  },
});

/** Override type */
export type ConsentWorkflowTriggerPurposeInput = SchemaToType<
  typeof ConsentWorkflowTriggerPurposeInput
>;

export const CreateOrUpdateConsentWorkflowTriggerInput = mkInput({
  name: 'CreateOrUpdateConsentWorkflowTriggerInput',
  comment: 'Input for updating a Consent Workflow Trigger',
  fields: {
    id: {
      type: 'id',
      modelName: 'consentWorkflowTrigger',
      comment: 'ID of the consent workflow trigger to update',
      optional: true,
    },
    name: {
      type: 'string',
      comment: 'The name of the consent workflow trigger',
      optional: true,
    },
    triggerCondition: {
      comment: 'The condition to trigger the consent workflow',
      type: 'string',
      optional: true,
    },
    actionId: {
      type: 'id',
      modelName: 'action',
      comment: 'The action associated to the consent workflow trigger',
      optional: true,
    },
    dataSubjectId: {
      type: 'id',
      modelName: 'subject',
      comment: 'The data-subject associated to the consent workflow trigger',
      optional: true,
    },
    dataSiloIds: {
      type: 'id',
      modelName: 'dataSilo',
      comment: 'The set of data silos that are configured for this workflow',
      list: true,
      optional: true,
    },
    consentWorkflowTriggerPurposes: {
      type: ConsentWorkflowTriggerPurposeInput,
      comment:
        'The list of purposes(Tracking and Marketing purposes) associated with this Consent Workflow Trigger',
      list: true,
      optional: true,
    },
    isSilent: {
      type: 'boolean',
      comment: 'If a DSR is triggered, is it a silent request',
      optional: true,
    },
    allowUnauthenticated: {
      type: 'boolean',
      comment:
        'If workflow is allowed to be triggered for unauthenticated users',
      optional: true,
    },
    isActive: {
      type: 'boolean',
      comment: 'If the trigger is active',
      optional: true,
    },
  },
});

/** Override type */
export type CreateOrUpdateConsentWorkflowTriggerInput = SchemaToType<
  typeof CreateOrUpdateConsentWorkflowTriggerInput
>;

export const DeleteConsentWorkflowTriggersInput = mkInput({
  name: 'DeleteConsentWorkflowTriggersInput',
  comment: 'Input for deleting Consent Workflow Triggers',
  fields: {
    ids: {
      type: 'id',
      modelName: 'consentWorkflowTrigger',
      comment: 'ID of the consent workflow trigger to delete',
      list: true,
    },
  },
});

/** Override type */
export type DeleteConsentWorkflowTriggersInput = SchemaToType<
  typeof DeleteConsentWorkflowTriggersInput
>;

export const ConsentWorkflowTriggersFilterInput = mkInput({
  name: 'ConsentWorkflowTriggersFilterInput',
  comment: 'The filter options to fetch consent workflow triggers',
  fields: {
    purposeIds: {
      type: 'id',
      modelName: 'purpose',
      comment: 'Filter the consent workflow triggers by purpose IDs',
      list: true,
      optional: true,
    },
    trackingTypes: {
      type: 'string',
      comment: 'Filter the consent workflow triggers by tracking types',
      list: true,
      optional: true,
    },
    text: {
      type: 'string',
      comment: 'Filter the consent workflow triggers by text',
      optional: true,
    },
    actionIds: {
      type: 'id',
      modelName: 'action',
      comment: 'Filter the consent workflow triggers by action ID',
      optional: true,
      list: true,
    },
    dataSubjectIds: {
      type: 'id',
      modelName: 'subject',
      comment: 'Filter the consent workflow triggers by subject ID',
      optional: true,
      list: true,
    },
    isActive: {
      type: 'boolean',
      comment: 'Filter the consent workflow triggers that are active',
      optional: true,
    },
    isSilent: {
      type: 'boolean',
      comment:
        'Filter the consent workflow triggers that trigger DSR in silent mode',
      optional: true,
    },
    allowUnauthenticated: {
      type: 'boolean',
      comment:
        'Filter the consent workflow triggers that are allowed for unauthenticated users',
      optional: true,
    },
  },
});

/** Override type */
export type ConsentWorkflowTriggersFilterInput = SchemaToType<
  typeof ConsentWorkflowTriggersFilterInput
>;
