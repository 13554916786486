import { OrganizationIcon } from '@main/ad-core-components';
import { CatalogPreview, DataSilo } from '@main/datamap-types';
import React from 'react';

import { LogoIconList, LogoIconListProps } from './LogoIconList';
import { StyledOrganizationIcon } from './wrappers';

export const DataSiloIconList: React.FC<
  Omit<
    LogoIconListProps<
      Pick<DataSilo, 'title'> & {
        /** Catalog preview with necessary fields to display the icon */
        catalog: CatalogPreview;
      }
    >,
    'renderElement' | 'renderOverflowElement'
  >
> = (props) => (
  <LogoIconList
    renderElement={({ title, catalog: { logoSquare } }, index) => (
      <StyledOrganizationIcon
        organizationIconSrc={logoSquare}
        organizationName={title}
        size={24}
        style={index === 0 ? { marginLeft: 0 } : undefined}
      />
    )}
    renderOverflowElement={({ title, catalog: { logoSquare } }) => (
      <OrganizationIcon
        organizationIconSrc={logoSquare}
        organizationName={title}
        size={20}
      />
    )}
    {...props}
  />
);
