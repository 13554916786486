import { easings, PickAnimated, SpringValues, useSpring } from 'react-spring';

/**
 * return type of animation hook
 */
type UseChartGrowUpwardsSVGAnimation = SpringValues<
  PickAnimated<{
    /** delay value */
    delay: number;
    /** from styles */
    from: {
      /** initial transform */
      transform: string;
    };
    /** to styles */
    to: {
      /** final transform */
      transform: string;
    };
    /** animation config */
    config: {
      /** animation duration */
      duration: number;
      /** easing fn */
      easing: (t: number) => number;
    };
  }>
>;
// this animation makes the chart contents appear to grow out of the x-axis
export const useChartGrowUpwardsSVGAnimation = (
  chartInteriorHeight: number,
): UseChartGrowUpwardsSVGAnimation =>
  useSpring({
    delay: 450,
    config: {
      duration: 600,
      easing: easings.easeOutQuart,
    },
    from: {
      transform: `translateY(${chartInteriorHeight / 2}px) scaleY(0%)`,
    },
    to: {
      transform: 'translateY(0px) scaleY(100%)',
    },
  });
