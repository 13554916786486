import {
  FlexColumnCenterBoth,
  FlexRow,
  FlexRowCenterHorizontal,
  GenericMessageDescriptor,
  Icon,
  IconType,
  StyleUtils,
  useFormatMessageGeneric,
} from '@main/core-ui';
import React, { ReactElement } from 'react';

import { Button, ButtonProps } from '../Button';
import { emptyMessages } from './messages';
import { StyledDescription, StyledTitle } from './wrappers';

export interface EmptyProps {
  /** The icon to use */
  icon?: IconType;
  /** should we hide the icon? */
  hideIcon?: boolean;
  /** Title text */
  title?: GenericMessageDescriptor;
  /** Description text */
  description?: GenericMessageDescriptor;
  /** Primary button */
  primaryButton?: ButtonProps;
  /** Secondary button */
  secondaryButton?: ButtonProps;
  /** should we hide the buttons? */
  hideButtons?: boolean;
  /** prop to specify a fully custom primary button */
  primaryButtonOverride?: ReactElement;
  /** prop to specify a fully custom secondary button */
  secondaryButtonOverride?: ReactElement;
}

/**
 * First time user experience (FTUX) component for empty views, tables, lists
 */
export const Empty: React.FC<EmptyProps> = ({
  icon = IconType.ViewFile,
  title,
  description,
  primaryButton,
  secondaryButton,
  hideButtons,
  primaryButtonOverride,
  secondaryButtonOverride,
}) => {
  const { formatMessageGeneric } = useFormatMessageGeneric();

  return (
    <FlexColumnCenterBoth
      style={{
        ...StyleUtils.Flex.Column.CenterBoth,
        gap: StyleUtils.Spacing.md,
        flexGrow: 1,
      }}
    >
      {icon && (
        <FlexRowCenterHorizontal>
          <Icon type={icon} size={64} />
        </FlexRowCenterHorizontal>
      )}
      {title && <StyledTitle>{formatMessageGeneric(title)}</StyledTitle>}
      <StyledDescription>
        {formatMessageGeneric(description ?? emptyMessages.defaultTitle)}
      </StyledDescription>
      {!hideButtons && (
        <FlexRow
          style={{
            gap: StyleUtils.Spacing.sm,
          }}
        >
          {secondaryButtonOverride ??
            (secondaryButton && (
              <Button variant="secondary" {...secondaryButton} />
            ))}
          {primaryButtonOverride ??
            (primaryButton && <Button variant="primary" {...primaryButton} />)}
        </FlexRow>
      )}
    </FlexColumnCenterBoth>
  );
};
