import { defineMessages } from '@transcend-io/internationalization';

export const ropaSelectCategoryMessages = defineMessages(
  'admin-dashboard.DataMap.components.SelectCategory.ropaSelectCategory',
  {
    placeholder: {
      defaultMessage: 'Click to select category',
    },
  },
);
