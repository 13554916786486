import type { RequestStatus } from '@transcend-io/privacy-types';
import { createEnum, filterEnum } from '@transcend-io/type-utils';

import { REQUEST_STATUSES } from './statuses';

/**
 * The statuses where a request can be canceled by an organization member
 *
 * This is true if request statuses that can transition to canceled state
 */
export const REQUEST_STATUSES_VIEWABLE = filterEnum(
  REQUEST_STATUSES,
  ({ canViewByDataSubject }) => !!canViewByDataSubject,
);

/**
 * The statuses that can be canceled key by status
 */
export const REQUEST_STATUS_VIEWABLE = createEnum(REQUEST_STATUSES_VIEWABLE);

/**
 * Check if a request can be viewed by the data subject
 *
 * @param status - The status of the request to check
 * @returns True if the request can be viewed by the data subject
 */
export function canViewByDataSubject(status: RequestStatus): boolean {
  return status in REQUEST_STATUS_VIEWABLE;
}
