import {
  PaginatedSelect,
  PaginatedSelectProps,
} from '@main/ad-core-components';
import { buildUseInfiniteScroll, Icon, SelectTagWrapper } from '@main/core-ui';
import { endpoints, VendorOrderField } from '@main/datamap-types';
import { createNewId, OrderDirection } from '@main/schema-utils';
import React, { useState } from 'react';
import { MenuPosition } from 'react-select';

import { INLINE_SELECT_VENDOR_FIELDS } from './constants';
import { selectDataSiloVendorMessages } from './messages';
import { SelectedVendor } from './types';
import { VendorTitle } from './VendorTitle';

const useSelectVendors = buildUseInfiniteScroll(
  endpoints.vendors,
  'SelectVendors',
  {
    nodes: INLINE_SELECT_VENDOR_FIELDS,
  },
);

export interface SelectVendorProps
  extends Omit<
    PaginatedSelectProps<SelectedVendor, false>,
    'isQueryLoading' | 'queryError' | 'fetchMore' | 'onEndsTyping'
  > {
  /** On change handler */
  onChange?: (vendor: SelectedVendor | null) => void;
  /** Show option to create vendor */
  allowCreate?: boolean;
  /** How to position the menu (see docs for React Select) */
  menuPosition?: MenuPosition;
}

/**
 * Select a specific vendor
 */
export const SelectVendor: React.FC<SelectVendorProps> = ({
  allowCreate = false,
  placeholderDescriptor = selectDataSiloVendorMessages.placeholder,
  showOutline = true,
  menuPosition = 'fixed',
  ...paginatedSelectProps
}) => {
  const [searchText, setSearchText] = useState('');
  const { data, loading, error, fetchMore } = useSelectVendors({
    variables: {
      filterBy: {
        text: searchText,
      },
      orderBy: [
        { field: VendorOrderField.Title, direction: OrderDirection.ASC },
      ],
    },
  });

  return (
    <SelectTagWrapper>
      <PaginatedSelect
        id="select-vendors"
        showOutline={showOutline}
        placeholderDescriptor={placeholderDescriptor}
        options={data?.nodes ?? []}
        isQueryLoading={loading}
        isCreatable={allowCreate}
        isMulti={false}
        formatOptionPill={(option: SelectedVendor) => (
          <VendorTitle {...option} />
        )}
        menuPosition={menuPosition}
        getOptionValue={({ id }: SelectedVendor) => id}
        getOptionLabel={({ title }: SelectedVendor) => title}
        queryError={error}
        getOptionLogo={({ isNew }: SelectedVendor) =>
          isNew ? <Icon type="circle-add" /> : ''
        }
        getNewOptionData={(inputValue) => ({
          isNew: true as const,
          title: inputValue,
          id: createNewId<'vendor'>(),
        })}
        fetchMore={fetchMore}
        onEndsTyping={setSearchText}
        {...paginatedSelectProps}
      />
    </SelectTagWrapper>
  );
};
