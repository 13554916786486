import { makeEnum } from '@transcend-io/type-utils';

/**
 * The types of nodes that can exist in a datamap
 */
export const DataMapNode = makeEnum({
  /** A grouping of data -- a SaaS tool, a database, a filing cabinet... */
  DataSilo: 'dataSilo',
  /** A granular piece of data that lives within a dataSilo node */
  DataPoint: 'dataPoint',
  /** A property of a datapoint, aka a database column name, or field */
  SubDataPoint: 'subDataPoint',
  /**
   * Unstructured Data Discovery Recommendations for SubDataPoints.
   */
  UnstructuredSubDataPointRecommendation:
    'unstructuredSubDataPointRecommendation',
  /** A granular piece of unstructured data that lives within a dataSilo node */
  ScannedObject: 'scannedObject',
});

/**
 * Overload type for datamap nodes
 */
export type DataMapNode = (typeof DataMapNode)[keyof typeof DataMapNode];

/**
 * A node that is a practice of applied data from the datamap
 */
export const DataPracticeNode = makeEnum({
  /** A category of data collections */
  DataCategory: 'dataCategory',
  /** A category of data collected by the organization */
  DataCollection: 'dataCollection',
  /** A use case for a dataCollection */
  DataApplication: 'dataApplication',
});

/**
 * Overload type for data practice nodes
 */
export type DataPracticeNode =
  (typeof DataPracticeNode)[keyof typeof DataPracticeNode];

/**
 * A node in the data map
 */
export const DatamapModelName = makeEnum({
  ...DataMapNode,
  ...DataPracticeNode,
});

/**
 * Overload all datamap nodes
 */
export type DatamapModelName =
  (typeof DatamapModelName)[keyof typeof DatamapModelName];
