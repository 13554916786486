import React, { ChangeEvent, useState } from 'react';

import { useDebounce } from '../hooks';
import { InlineTextInput, InlineTextInputProps } from './InlineTextInput';

export const DebouncedInlineTextInput: React.FC<InlineTextInputProps> = ({
  value,
  onChange,
  ...props
}) => {
  const [localValue, setLocalValue] = useState(value);
  const [busy, setBusy] = useState(false);

  const debouncedOnChange = useDebounce(
    (e) => {
      onChange?.(e);
      setBusy(false);
    },
    400,
    { trailing: true },
    [onChange],
  );

  const onChangeWithDebounce = (
    e: ChangeEvent<{
      /** The new value */
      value: string;
    }>,
  ): void => {
    // set locally
    setLocalValue(e.target.value);
    setBusy(true);
    debouncedOnChange(e);
  };

  return (
    <InlineTextInput
      value={localValue}
      aria-busy={busy ? 'true' : 'false'}
      onChange={onChangeWithDebounce}
      {...props}
    />
  );
};
