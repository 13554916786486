import { endpoints as accessControlEndpoints } from '@main/access-control-types';
import { buildUseMutation, useApolloClient } from '@main/core-ui';
import type { ID } from '@main/schema-utils';
import React from 'react';

import { InlineUpdateResponse, useInlineInput } from '../hooks/useInlineInput';
import { SelectedTeam, SelectTeamProps, SelectTeams } from './SelectTeams';

const useCreateTeam = buildUseMutation(accessControlEndpoints.createTeam);

/** Props for the `InlineSelectTeams` component */
interface InlineSelectTeamsProps extends SelectTeamProps {
  /** The value for the cell */
  value: SelectedTeam[];
  /** Loading */
  loading: boolean;
  /** Callback to update teams */
  onUpdate: (
    teamIds: ID<'team'>[],
  ) => Promise<InlineUpdateResponse<SelectedTeam[]>>;
}

export const InlineSelectTeams: React.FC<InlineSelectTeamsProps> = ({
  value: initialValue,
  onUpdate,
  loading,
  showOutline = false,
  ...props
}) => {
  const client = useApolloClient();
  const [createTeam, createTeamResult] = useCreateTeam();

  const { value, setValue, onBlur } = useInlineInput<SelectedTeam[]>({
    initialValue,
    onUpdate: async (values) => {
      const newTeams = values.filter(({ isNew }) => isNew);
      const existingTeamIds = values
        .filter(({ isNew }) => !isNew)
        .map(({ id }) => id);
      if (newTeams.length > 0) {
        // eslint-disable-next-line @typescript-eslint/prefer-for-of
        for (let i = 0; i < newTeams.length; i += 1) {
          const { name } = newTeams[i];
          // eslint-disable-next-line no-await-in-loop
          const { data } = await createTeam({ variables: { input: { name } } });
          if (!data) {
            throw new Error(`Failed to create team with name: "${name}"`);
          }
          existingTeamIds.push(data.team.id);
        }
      }

      client.refetchQueries({ include: ['Teams', 'SelectTeams'] });

      return onUpdate(existingTeamIds);
    },
  });

  return (
    <SelectTeams
      variant="light"
      value={value}
      allowCreate
      showOutline={showOutline}
      onChange={(newValues) => {
        setValue([...newValues]);
      }}
      isLoading={loading || createTeamResult.loading}
      overflow="badge"
      onBlur={onBlur}
      {...props}
    />
  );
};
