import {
  extractFormItemWrapperProps,
  FormItemWrapper,
  IFormFieldProps,
  IFormItemWrapperProps,
  multipleValidators,
  ReactSelect,
  ReactSelectExtendedProps,
  TValidator,
} from '@main/core-ui';
import React, { ReactElement } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { OptionTypeBase } from 'react-select';

export interface IFormSelectProps<
  T extends OptionTypeBase,
  IsMulti extends boolean,
> extends IFormFieldProps<T>,
    Omit<ReactSelectExtendedProps<IsMulti, T>, 'name'> {
  /** the options for the select */
  options: (T | string)[];
  /** the validation rules for the input */
  rules?: TValidator[];
  /** Display options as objects */
  displayObjects?: boolean;
  /** Whether multi-select values are initially in string */
  multiValuesAsString?: boolean;
  /** Delimiter if multi-values are string */
  multiValuesDelimiter?: string;
}

/**
 * FormSelectRaw
 */
export function FormSelectRaw<
  T extends OptionTypeBase,
  IsMulti extends boolean,
>({
  name,
  defaultValue,
  options = [],
  rules,
  multiValuesAsString,
  multiValuesDelimiter = ',',
  ...props
}: IFormSelectProps<T, IsMulti>): ReactElement {
  const { control } = useFormContext();

  const getItemValue = (value: any): unknown =>
    props?.displayObjects || typeof value === 'string' ? value : value?.value;

  const toObject = (singleVal: string | T): any =>
    typeof singleVal === 'string'
      ? { value: singleVal, label: singleVal }
      : singleVal;

  const optionsAsObjects =
    typeof options[0] === 'string'
      ? options.map((val) => toObject(val))
      : options;

  return (
    <Controller
      name={name}
      control={control}
      rules={rules ? { validate: multipleValidators(rules) } : undefined}
      defaultValue={getItemValue(defaultValue)}
      // Cannot pass ref to React-Select without errors so we just don't bother passing it
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      render={({ field: { ref, value, ...field } }) => {
        const valueAsArray =
          multiValuesAsString && typeof value === 'string'
            ? value
                .split(multiValuesDelimiter)
                .filter((val) => val.trim().length > 0)
            : value;

        const valuesAsObject = Array.isArray(valueAsArray)
          ? valueAsArray.map((val) => toObject(val))
          : toObject(valueAsArray);
        return (
          <ReactSelect
            {...props}
            {...field}
            onChange={(value: any) =>
              field.onChange(
                Array.isArray(value) // Multi-select value
                  ? value.map(getItemValue)
                  : getItemValue(value),
              )
            }
            options={optionsAsObjects}
            value={valuesAsObject}
          />
        );
      }}
    />
  );
}

/**
 * FormSelect
 */
export function FormSelect<T extends OptionTypeBase, IsMulti extends boolean>(
  props: IFormSelectProps<T, IsMulti> & IFormItemWrapperProps,
): ReactElement {
  const {
    formState: { errors },
  } = useFormContext();
  const { passthroughProps, wrapperProps } = extractFormItemWrapperProps(props);

  return (
    <FormItemWrapper errors={errors} {...wrapperProps}>
      <FormSelectRaw {...passthroughProps} />
    </FormItemWrapper>
  );
}
