import { endpoints } from '@main/access-control-types';
import {
  FilterConfigItem,
  NONE_USER,
  SelectedUser,
  SelectUser,
  UserTag,
} from '@main/ad-core-components';
import { buildUseLazyQuery } from '@main/core-ui';
import { DataSiloNullableFilters } from '@main/datamap-types';
import { ID } from '@main/schema-utils';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { MessageDescriptor } from 'react-intl';

/**
 * Filters definition with property for user values specified
 */
type UserEnrichedFilters = Record<string, unknown> & {
  /**
   * Properties to filter on null values
   */
  includeNulls?: (typeof DataSiloNullableFilters)[];
} & Record<
    /**
     * User values that are currently filtering the table
     */
    string,
    SelectedUser[] | undefined
  >;

const useLazyUsers = buildUseLazyQuery(endpoints.users);

export const useUserFilters = <
  T extends Record<string, unknown>,
  E extends UserEnrichedFilters,
>({
  userIdsFilterKey = 'userIds',
  filters,
  setFilters,
  label,
  includeNone = false,
  enrichedUserKey = 'owners',
}: {
  /** The property name of the filter for user ids */
  userIdsFilterKey?: keyof T;
  /** The currently applied filters */
  filters?: T;
  /** Callback when the filters are changed */
  setFilters: (filters: T) => void;
  /** Label for the filter menu */
  label: string | MessageDescriptor;
  /** Whether this filter supports filtering on "no user". Right now this is only supported for data silos. */
  includeNone?: boolean;
  /** The key to use if more than one useUserFilters is used in a FilterManager */
  enrichedUserKey?: keyof E;
}): {
  /**  The user keys enabled on this table */
  selectedUsers: SelectedUser[];
  /** The filter configuration to be passed to the filter manager */
  userFiltersConfig: FilterConfigItem<E>;
  /** Callback for when the filter is cleared in the filter manager */
  clearUserFilters: (key: Extract<keyof E, string>) => void;
} => {
  const getSelectedUsers = useLazyUsers();
  const [selectedUsers, setSelectedUsers] = useState<SelectedUser[]>([]);

  // Populate initial complex filter values
  useEffect(() => {
    if (filters) {
      // Whether the current filters include one for filtering on "no user"
      const includeNullUsers = (
        Array.isArray(filters.includeNulls) ? filters.includeNulls : []
      ).includes(DataSiloNullableFilters.Owners);

      const userIds = (filters[userIdsFilterKey] ?? []) as ID<'user'>[];

      if (userIds.length > 0 && selectedUsers.length === 0) {
        getSelectedUsers({ filterBy: { ids: userIds } }).then(({ data }) => {
          setSelectedUsers(
            includeNone
              ? [...(includeNullUsers ? [NONE_USER] : []), ...data.nodes]
              : data.nodes,
          );
        });
      } else if (includeNullUsers) {
        setSelectedUsers([NONE_USER]);
      }
    }
  }, []);

  const userFiltersConfig = useMemo(
    () =>
      ({
        filterKey: enrichedUserKey,
        label,
        renderPill: ({
          filterValues: { [enrichedUserKey]: users = [] },
          index = 0,
        }) => {
          const user = users[index];
          return <UserTag user={user} />;
        },
        filter: (
          <SelectUser
            includeNone={includeNone}
            isMulti
            menuPosition="absolute"
            value={selectedUsers}
            showOutline={false}
            onChange={(users) => {
              // Add or remove the users enum from the `includeNulls` parameter, depending on the
              // presence of the NONE_USER (with id:'') in the list of users
              const includeNulls =
                filters && 'includeNulls' in filters
                  ? new Set(filters.includeNulls as string)
                  : new Set();
              if (users.some(({ id }) => id === '')) {
                includeNulls.add(DataSiloNullableFilters.Owners);
              } else {
                includeNulls.delete(DataSiloNullableFilters.Owners);
              }

              setSelectedUsers(users);
              setFilters({
                ...filters,
                ...(includeNone ? { includeNulls: [...includeNulls] } : {}),
                [userIdsFilterKey]: users
                  // filter because `users` could include the NONE_USER with id: ''
                  .filter(({ id }) => id)
                  .map(({ id }) => id),
              } as T);
            }}
          />
        ),
      }) as FilterConfigItem<UserEnrichedFilters>,
    [label, selectedUsers, filters, userIdsFilterKey],
  );

  const clearUserFilters = useCallback(
    (key) => {
      if (key === enrichedUserKey) {
        const includeNulls =
          filters && 'includeNulls' in filters
            ? new Set(filters.includeNulls as string)
            : new Set();
        includeNulls.delete(DataSiloNullableFilters.Owners);
        setFilters({
          ...filters,
          [userIdsFilterKey]: [],
          ...(includeNone ? { includeNulls: [...includeNulls] } : {}),
        } as T);
        setSelectedUsers([]);
      }
    },
    [selectedUsers, filters, userIdsFilterKey],
  );

  return {
    selectedUsers,
    userFiltersConfig,
    clearUserFilters,
  };
};
