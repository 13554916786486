import { hexToRgb } from '@main/theme-types';
import { BarStack } from '@visx/shape';
import orderBy from 'lodash/orderBy';
import sumBy from 'lodash/sumBy';
import React, { useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useTheme } from 'styled-components';

import {
  BAR_MIN_HEIGHT,
  BAR_MIN_SPACING,
  BAR_MIN_WIDTH,
  BAR_STACK_SPACING,
  BAR_WIDTH,
  DEFAULT_COLORS,
  HOVER_SCALE,
} from '../constants';
import { InternalChartData, InternalChartDataPoint } from '../types';
import { getValueData } from '../utils';
import { BarStacksProps } from './types';
import { StyledBarRounded } from './wrappers';

export const BarStacks: React.FC<BarStacksProps> = ({
  data,
  keys,
  x,
  xScale,
  yScale,
  color,
  colors = DEFAULT_COLORS,
  groupHeight,
  groupWidth,
  highlightedBar,
  stacked = false,
  yScaleType = 'linear',
  noFillSeriesNames = [],
  barWidth,
  updateTooltip,
  hideTooltip,
  useBarColorForTooltipLegend,
  hasNegativeY,
  yUnitTransform,
  oneColorPerSeries,
  hideMissingData,
  renderExtraTooltipMetadata,
  prettySeriesNameTransform,
  groupName,
  setPreferredTooltipPosition,
  boundingRectLeft,
  boundingRectTop,
  hiddenKeys,
}) => {
  const theme = useTheme();
  const { formatNumber } = useIntl();
  const [highlightedX, setHighlightedX] = useState<string | undefined>();
  const yUnitTransformOrDefault = yUnitTransform ?? ((s) => formatNumber(s));
  const filteredData = useMemo(
    () =>
      (groupName
        ? data.map((series) =>
            !hiddenKeys.has(series.x) ? series : { x: series.x },
          )
        : data.map((point) => ({
            ...point,
            // overwrite to size zero instead of removing to not mess up the colorings
            ...Object.fromEntries([...hiddenKeys].map((key) => [key, 0])),
          }))) as InternalChartDataPoint[],
    [data, hiddenKeys],
  );

  return (
    <>
      <BarStack<InternalChartData[number], string>
        data={filteredData}
        keys={keys}
        x={x}
        xScale={xScale}
        yScale={yScale}
        color={color}
        offset={hasNegativeY ? 'diverging' : 'none'}
        value={(d, key) => {
          const v = d[key];
          return hasNegativeY
            ? v === 0
              ? 1
              : v
            : Math.max(hiddenKeys.has(key) ? 0 : 1, d[key]);
        }}
      >
        {(horizontalSlices) => {
          const scaledBarWidth =
            barWidth ||
            Math.min(
              BAR_WIDTH,
              Math.max(
                groupWidth / horizontalSlices[0].bars.length - BAR_MIN_SPACING,
                BAR_MIN_WIDTH,
              ),
            );
          return horizontalSlices.map((horizontalSlice) =>
            // the same vertical slice of a bar across multiple x values
            horizontalSlice.bars.map((childBar) => {
              const val = sumBy(
                getValueData(data, childBar.bar.data.x),
                ([, val]) => Math.abs(val),
              );
              if (hideMissingData && val === 0) {
                return null;
              }
              const strokeOnly = noFillSeriesNames.includes(childBar.key);
              const strokeOffset = strokeOnly ? 4 : 0;
              const lastBarIndex = keys.length - 1;
              const childBarX =
                childBar.x +
                childBar.width / 2 -
                (scaledBarWidth - strokeOffset / 2) / 2;
              let childBarY =
                childBar.y +
                (horizontalSlice.index !== lastBarIndex && stacked
                  ? BAR_STACK_SPACING
                  : 0);
              let childBarHeight =
                yScaleType === 'logarithmic'
                  ? groupHeight - childBar.y - strokeOffset
                  : childBar.height -
                    (horizontalSlice.index !== lastBarIndex && stacked
                      ? BAR_STACK_SPACING + strokeOffset / 2
                      : 0);

              // must show a minimum bar height to still be usable
              if (childBarHeight < BAR_MIN_HEIGHT) {
                childBarY += childBarHeight - BAR_MIN_HEIGHT;
                childBarHeight = BAR_MIN_HEIGHT;
              }

              const hexColor =
                stacked || oneColorPerSeries
                  ? childBar.color
                  : theme.colors[colors[childBar.index % colors.length]];
              const rgbColor = hexToRgb(hexColor);
              const lightFillColor = `rgba(${rgbColor.r.toString()},${rgbColor.g.toString()},${rgbColor.b.toString()},.1)`;
              const onMouseEnter = (isBar: boolean): void => {
                const barName = childBar.bar.data.x;
                if (highlightedX !== barName) {
                  setHighlightedX?.(barName);
                }
                const legendDomain = getValueData(
                  data,
                  childBar.bar.data.x,
                ).map(([key, value]) => ({
                  key:
                    prettySeriesNameTransform?.(groupName, barName, key) ?? key,
                  value: yUnitTransformOrDefault(value),
                  numberValue: value,
                }));
                const legendItems = orderBy(
                  legendDomain,
                  ['numberValue', 'key'],
                  ['desc', 'asc'],
                )
                  .map((item) => ({
                    ...item,
                    hexColor: useBarColorForTooltipLegend
                      ? // use the bar color override (single series)
                        hexColor
                      : // get the color from the legend (multiple series)
                        color(item.key, 0),
                  }))
                  .filter(({ key }) => !hiddenKeys.has(key));
                // Get the x position closest to the cursor
                const tooltipClientY = boundingRectTop + childBar.y;
                const tooltipClientX =
                  boundingRectLeft + childBar.x + childBar.width / 2;
                setPreferredTooltipPosition(undefined);
                updateTooltip({
                  tooltipOpen: true,
                  tooltipLeft: tooltipClientX,
                  tooltipTop: tooltipClientY,
                  tooltipData: {
                    title: barName,
                    noFillSeriesNames,
                    legendItems,
                    extraMetadata: renderExtraTooltipMetadata?.(legendItems),
                    hoveredKey: isBar ? childBar.key : undefined,
                  },
                });
              };

              return (
                // hide the entire column when grouped
                (!groupName || !hiddenKeys.has(childBar.bar.data.x)) && (
                  <g
                    key={`bar-stack-${horizontalSlice.index}-${childBar.index}`}
                    onMouseLeave={(e) => {
                      hideTooltip();
                      const containerLeft = boundingRectLeft + childBar.x;
                      const containerTop = boundingRectTop;
                      // if leaving chart or highlighted section, clear the highlight
                      if (
                        // left of bounds
                        e.nativeEvent.x < containerLeft ||
                        // right of bounds
                        e.nativeEvent.x >
                          containerLeft + groupWidth / data.length ||
                        // above bounds
                        e.nativeEvent.y < containerTop ||
                        // below bounds
                        e.nativeEvent.y > containerTop + groupHeight
                      ) {
                        setHighlightedX(undefined);
                      }
                    }}
                  >
                    {horizontalSlice.index === 0 && (
                      <rect
                        x={childBar.x}
                        y="0"
                        width={groupWidth / data.length}
                        height={groupHeight}
                        opacity={
                          childBar.bar.data.x === highlightedX ? '10%' : '0%'
                        }
                        onMouseEnter={() => onMouseEnter(false)}
                      />
                    )}
                    <StyledBarRounded
                      expanded={
                        childBar.index === highlightedBar ? 'true' : 'false'
                      }
                      onMouseEnter={() => onMouseEnter(true)}
                      r={rgbColor.r.toString()}
                      g={rgbColor.g.toString()}
                      b={rgbColor.b.toString()}
                      x={childBarX}
                      y={childBarY}
                      height={childBarHeight}
                      fill={strokeOnly ? lightFillColor : hexColor}
                      stroke={hexColor}
                      strokeWidth={strokeOnly ? 1 : 0}
                      width={scaledBarWidth - strokeOffset / 2}
                      radius={
                        !stacked || horizontalSlice.index === lastBarIndex
                          ? 6
                          : 0
                      }
                      top={childBar.bar.data[childBar.key] >= 0}
                      bottom={childBar.bar.data[childBar.key] < 0}
                      style={{
                        transform: `translateX(-${
                          HOVER_SCALE * childBarX -
                          childBarX +
                          (HOVER_SCALE * BAR_WIDTH - BAR_WIDTH) / 2
                        }px) scaleX(${HOVER_SCALE})`,
                        opacity: hiddenKeys.has(horizontalSlice.key) ? 0 : 1,
                      }}
                    />
                  </g>
                )
              );
            }),
          );
        }}
      </BarStack>
    </>
  );
};
