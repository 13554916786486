import styled, { css, keyframes } from 'styled-components';

const toolTipExpand = keyframes`
  0% {
    opacity: 0;
    transform: scale(60%, 60%) translate(calc(-35% + 3px), calc(-25% + 24px));

  }
  100% {
    opacity: 100%;
    transform: scaleX(100%, 100%) translate(0, 0);
  }
`;

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  30% {
    opacity: 0;
  }
  100% {
    opacity: 100%;
  }
`;

export const MapSVG = styled.svg`
  touch-action: none;
  overflow: visible;
  animation: ${css`
    ${fadeIn} 1750ms ease-out
  `};
`;

export const StyledFeature = styled.path`
  stroke: ${({ theme }) => theme.colors.white};
  stroke-width: 1px;
  cursor: pointer;
  transition: all 150ms ease-out;

  :hover {
    fill: ${({ theme }) => theme.colors.yellow1};
  }
`;

export const TooltipContainer = styled.div`
  box-sizing: border-box;
  box-shadow: 1px 4px 9px 0 rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 8px 16px;
  background: ${({ theme }) => theme.colors.white};
  border: ${({ theme }) => `1px solid ${theme.colors.gray3}`};
  animation: ${css`
    ${toolTipExpand} 50ms ease-out
  `};
`;

export const TooltipContent = styled.div`
  animation: ${css`
    ${fadeIn} 200ms ease-out;
  `};
`;

export const Caret = styled.div`
  position: absolute;
  height: 12px;
  width: 12px;
  top: 24px;
  left: -6px;
  transform: rotate(45deg);
  background: ${({ theme }) => theme.colors.white};
  border-left: ${({ theme }) => `1px solid ${theme.colors.gray3}`};
  border-bottom: ${({ theme }) => `1px solid ${theme.colors.gray3}`};
`;
