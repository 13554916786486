import type { QueryResult } from '@apollo/client';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { ConsentManagerOnboardingStatus } from '@main/access-control-types';
import {
  AdminDashboardCmOnboardingPath,
  AdminDashboardCmPostOnboardingPath,
  ConsentManager,
  endpoints,
} from '@main/cm-types';
import { buildUseQuery, useRedirect } from '@main/core-ui';
import { GqlObject, GraphQLResponse, ResponseToType } from '@main/schema-utils';

import { selectConsentManager } from './selectors';
import { setConsentManager, setSelectedConsentManagerId } from './slice';

/**
 * Hook to help fetch the consent manager
 *
 * @param queryName - name of the query
 * @param responseFields - fields to return
 * @returns The consent manager and `consentManager` query response
 */
export function useGetConsentManager<TResult extends GraphQLResponse>(
  queryName?: string,
  responseFields?: GqlObject<TResult>,
): [
  ConsentManager | undefined,
  QueryResult<ResponseToType<typeof endpoints.consentManager.response>>,
] {
  const dispatch = useDispatch();
  const redirect = useRedirect({ push: false });
  const { pathname } = useLocation();

  const useConsentManager = buildUseQuery(
    endpoints.consentManager,
    queryName ?? 'ConsentManager',
    responseFields ? { consentManager: responseFields } : undefined,
  );

  // Fetch from redux
  const consentManager = useSelector(selectConsentManager);

  // Call backend
  const { data, error, called, loading, ...rest } = useConsentManager({
    // note, this still makes a call to this query on every page load
    // when the consent manager does not exist for an organization
    // this is not ideal
    skip: !!consentManager,
  });

  const mostUpToDateConsentManager = data?.consentManager || consentManager;

  useEffect(() => {
    if (data?.consentManager) {
      dispatch(setSelectedConsentManagerId(data.consentManager.id));
      dispatch(setConsentManager(data.consentManager));
    }

    // If we have no consent manager, or if it's not completed onboarding, redirect to the onboarding steps.
    // (But make sure we don't end up in a redirect loop by checking the current location)
    const isCmPath = Object.values(AdminDashboardCmPostOnboardingPath).some(
      (path) => pathname === path,
    );

    if (
      isCmPath &&
      // Either:
      // 1) We are done loading and the error is 'AirgapBundle not found'
      // 2) There is a consent manager and the onboarding status is "Started"
      // Then we redirect to the onboarding steps
      ((!loading && error?.message.includes('AirgapBundle not found')) ||
        mostUpToDateConsentManager?.onboardingStatus ===
          ConsentManagerOnboardingStatus.Started)
    ) {
      redirect(AdminDashboardCmOnboardingPath.OnboardConsentManager);
    }
  }, [loading, error, data, redirect, pathname, mostUpToDateConsentManager]);

  return [
    consentManager || data?.consentManager,
    { ...rest, loading, called, data, error },
  ];
}
