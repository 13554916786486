import { PaginatedSelect } from '@main/ad-core-components';
import {
  buildUseInfiniteScroll,
  type ReactSelectExtendedProps,
  SelectTagWrapper,
  useQueryParamJson,
} from '@main/core-ui';
import {
  dataCategoryTypeMessages,
  type DataSubCategoryPreview,
  endpoints,
  type SubDataPointFiltersInput,
} from '@main/datamap-types';
import type { DefinedMessage } from '@main/internationalization';
import {
  DataCategoryType,
  SubDataPointDataSubCategoryGuessStatus,
} from '@transcend-io/privacy-types';
import groupBy from 'lodash/groupBy';
import isEmpty from 'lodash/isEmpty';
import React, { useMemo, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import type Select from 'react-select';
import type { ActionMeta } from 'react-select';

import { DATA_CATEGORY_TYPE_COLORS } from '../../constants';
import { ropaSelectCategoryMessages } from '../SelectCategory/messages';
import { type BestGuessSortCriteria, sortByBestGuess } from './helpers';
import { selectSubCategoryMessages } from './messages';
import { SelectCategoryPillDisplay } from './SelectCategoryPillDisplay';
import type { SelectSubCategoryOption } from './types';

export const SELECT_SUB_CATEGORY_NODES = {
  id: null,
  name: null,
  category: null,
};

const useDataSubCategories = buildUseInfiniteScroll(
  endpoints.dataSubCategories,
  endpoints.dataSubCategories.name,
  { nodes: SELECT_SUB_CATEGORY_NODES },
);

/** Props for the `SelectSubCategory` component */
export interface SelectSubCategoryProps
  extends Omit<
    ReactSelectExtendedProps<true, SelectSubCategoryOption>,
    'onChange' | 'isMulti' | 'value'
  > {
  /** The selected data subcategories for this subdatapoint */
  value: SelectSubCategoryOption[];
  /** Callback to fire when the category is updated */
  onUpdate: (
    selected: SelectSubCategoryOption[],
    meta: ActionMeta<SelectSubCategoryOption>,
  ) => void;
  /** Groups of options to display before the main list of options */
  optionGroups?: {
    /** The label of the group */
    label: string;
    /** The options within this group */
    options: SelectSubCategoryOption[];
  }[];
  /** The label for the main list of options, used if other option groups are provided */
  defaultOptionGroupLabel?: DefinedMessage;
  /** Whether "Unspecified" should be included in the list of data subcategory options */
  includeUnspecified?: boolean;
}

/**
 * Transform a subcategory into an option
 */
export function toSelectSubCategoryOption({
  category,
  ...rest
}: Omit<DataSubCategoryPreview, 'slug'> &
  Partial<
    Pick<
      SelectSubCategoryOption,
      'guessId' | 'classifierVersion' | 'subCategoryId'
    >
  >): SelectSubCategoryOption {
  return {
    value: category,
    ...rest,
    name: rest.name || '',
    id: rest.guessId ? rest.guessId : rest.id,
  };
}

const UNSPECIFIED_OPTION: SelectSubCategoryOption = {
  value: DataCategoryType.Unspecified,
  name: '',
};

/**
 * Transform a subcategory into an option
 */
export function toSelectSubCategoryOptionFromSelected({
  category,
  ...rest
}: DataSubCategoryPreview): SelectSubCategoryOption {
  return {
    value: category,
    ...rest,
    name: rest.name || '',
  };
}

export const SelectSubCategory: React.FC<SelectSubCategoryProps> = ({
  value: values,
  loading,
  onUpdate,
  showOutline = false,
  optionGroups,
  defaultOptionGroupLabel,
  includeUnspecified = false,
  ...props
}) => {
  const { formatMessage } = useIntl();

  // TODO: https://transcend.height.app/T-15626 - confidence filters should be applied in resolver, not UI
  const { value: inventoryFilters } =
    useQueryParamJson<SubDataPointFiltersInput>({
      name: 'filters',
    });
  const { value: dataReportFilters } =
    useQueryParamJson<SubDataPointFiltersInput>({
      name: 'dataReportFilters',
    });

  const filters = isEmpty(inventoryFilters)
    ? dataReportFilters
    : inventoryFilters;

  const [searchText, setSearchText] = useState('');
  const selectRef = useRef<Select>(null);

  // boolean to determine if we filter for guesses
  const shouldFilterGuesses =
    ((filters?.category &&
      filters?.category.filter((c) => c !== DataCategoryType.Unspecified)
        .length > 0) ||
      (filters?.subCategories && filters?.subCategories.length > 0) ||
      (filters?.status &&
        filters?.status === SubDataPointDataSubCategoryGuessStatus.Pending)) ??
    false;

  // split the current values into approved categories and guesses
  const { filteredForGuesses, guesses, approved } = useMemo(() => {
    const grouped = groupBy(
      values,
      ({ guessId, pendingApproval }) => !guessId || !!pendingApproval,
    );

    const approved = grouped.true ?? [];

    // filter for guesses that match the filter (filteredForGuesses)
    const filteredForGuesses = (grouped.false ?? []).filter(
      ({ value, subCategoryId }) =>
        shouldFilterGuesses &&
        (!filters?.category ||
          filters.category.includes(value) ||
          !filters?.subCategories ||
          !subCategoryId ||
          filters.subCategories.includes(subCategoryId)) &&
        (!filters?.status ||
          filters?.status === SubDataPointDataSubCategoryGuessStatus.Pending),
    );

    // Sort ALL guesses by confidence so we show the highest score first if no filters
    const guesses = ((grouped.false as BestGuessSortCriteria[]) ?? []).sort(
      sortByBestGuess,
    ) as SelectSubCategoryOption[];

    return { filteredForGuesses, approved, guesses };
  }, [
    values,
    shouldFilterGuesses,
    filters?.subCategories,
    filters?.category,
    filters?.status,
  ]);

  // determine the best guess, by confidence
  const bestGuess = useMemo(() => {
    if (guesses.length > 0) {
      return guesses[0];
    }
    return null;
  }, [guesses]);
  const hasApproved = approved.length > 0;

  const {
    data,
    loading: dataSubCategoriesLoading,
    fetchMore,
    error: dataSubCategoriesError,
  } = useDataSubCategories({
    variables: {
      filterBy: {
        ...(searchText ? { text: searchText } : {}),
      },
      first: 100,
    },
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
  });

  // Transform data
  const allCategoriesOptions = useMemo(
    () => [
      ...(data?.nodes.map((subcategory) =>
        toSelectSubCategoryOption(subcategory),
      ) || []),
      ...(includeUnspecified ? [UNSPECIFIED_OPTION] : []),
    ],
    [data?.nodes, includeUnspecified],
  );

  const options = useMemo(() => {
    // get the option displays for all guesses
    const recommendedOptions = guesses.map((guess) =>
      toSelectSubCategoryOption(guess as any),
    );

    return (optionGroups || guesses.length > 0) && !searchText
      ? // the grouped version of the options
        [
          ...(optionGroups || []),
          {
            label: formatMessage(
              selectSubCategoryMessages.recommendedCategories,
            ),
            options: recommendedOptions,
          },
          {
            label: defaultOptionGroupLabel
              ? formatMessage(defaultOptionGroupLabel)
              : formatMessage(selectSubCategoryMessages.allCategories),
            options: allCategoriesOptions,
          },
        ]
      : allCategoriesOptions;
  }, [
    allCategoriesOptions,
    defaultOptionGroupLabel,
    formatMessage,
    guesses,
    optionGroups,
    searchText,
  ]);

  const value = useMemo(
    () =>
      hasApproved
        ? shouldFilterGuesses
          ? [...approved, ...filteredForGuesses]
          : approved
        : shouldFilterGuesses
          ? filteredForGuesses
          : bestGuess,
    [approved, bestGuess, filteredForGuesses, hasApproved, shouldFilterGuesses],
  );

  return (
    <SelectTagWrapper>
      <PaginatedSelect<SelectSubCategoryOption, true>
        selectRef={selectRef}
        isMulti
        showOutline={showOutline}
        mostRecentlyFetchedChildren={allCategoriesOptions}
        options={options}
        fetchMore={fetchMore}
        isQueryLoading={loading || dataSubCategoriesLoading}
        queryError={dataSubCategoriesError}
        onChange={(selections, meta) => {
          const valueHasUnspecified = includeUnspecified
            ? (value ? (Array.isArray(value) ? value : [value]) : []).some(
                ({ value }) => value === DataCategoryType.Unspecified,
              )
            : false;

          const selectionsHasUnspecified = includeUnspecified
            ? selections.some(
                ({ value }) => value === DataCategoryType.Unspecified,
              )
            : false;

          const newSelections =
            selectionsHasUnspecified && !valueHasUnspecified
              ? [UNSPECIFIED_OPTION]
              : [
                  ...(selections.filter(
                    ({ value }) => value !== DataCategoryType.Unspecified,
                  ) as SelectSubCategoryOption[]),
                ];
          // The UI is a bit confusing right now where selecting the best guess
          // actually dismisses the guess because that guess was previously selected
          // this then persists to the backend and the user has no way to see what that
          // prior guess was. this change reverses the operation to act like an approval
          if (
            meta.action === 'deselect-option' &&
            meta.option?.id === bestGuess?.id
          ) {
            onUpdate?.(newSelections, {
              ...meta,
              option: meta.option,
              action: 'select-option',
            });
          } else {
            onUpdate?.(newSelections, meta);
          }
        }}
        getOptionLabel={({ value }) =>
          formatMessage(dataCategoryTypeMessages[value])
        }
        formatOptionPill={(option, { context }) => (
          <SelectCategoryPillDisplay
            selectRef={selectRef}
            option={option}
            values={values}
            onUpdate={onUpdate}
            isInMenu={context === 'menu'}
          />
        )}
        getVariant={({ guessId, pendingApproval }) =>
          guessId && !pendingApproval ? 'secondary' : 'primary'
        }
        placeholder={formatMessage(ropaSelectCategoryMessages.placeholder)}
        colorMap={DATA_CATEGORY_TYPE_COLORS}
        value={value}
        onEndsTyping={(searchText) => setSearchText(searchText)}
        {...props}
      />
    </SelectTagWrapper>
  );
};
