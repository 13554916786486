import { Icon } from '@main/core-ui';
import type { Requirize } from '@transcend-io/type-utils';
import React, { ChangeEvent, useEffect, useState } from 'react';

import { useDebounce } from '../hooks';
import { Input, InputProps } from '../Input';
import { searchMessages } from './messages';

/**
 * Props
 */
export interface SearchProps
  // loading is made required in order to make this compatible
  // with the cypress searchList function
  // a common cypress flake outlined in https://www.cypress.io/blog/2020/07/22/do-not-get-too-detached/
  // is prevented by waiting on searches to complete
  // we use the aria-busy field to present loading state
  extends Requirize<Omit<InputProps, 'onChange' | 'value'>, 'loading'> {
  /** The delay in ms before calling onChange */
  delay?: number;
  /** Callback that occurs on search change */
  onChange?: (newValue: string) => void;
  /** The current search value */
  value?: string;
}

/**
 * An input with a search icon
 */
export const Search = React.forwardRef<HTMLInputElement, SearchProps>(
  (
    { delay = 350, value = '', onChange, placeholder, loading, ...searchProps },
    ref,
  ) => {
    // store locally
    const [localValue, setLocalValue] = useState(value);
    const [searching, setIsSearching] = useState(false);
    const [originalValue] = useState(value);

    // use a large debounce to avoid overlapping api calls
    const debouncedOnChange = useDebounce(
      (newValue: string) => {
        onChange?.(newValue);
        setIsSearching(false);
      },
      delay,
      { trailing: true },
      [onChange],
    );

    // update the local value if the passed value changes
    useEffect(() => {
      // only update if the local value hasn't changed since first mount
      if (value !== localValue && localValue === originalValue) {
        setLocalValue(value);
      }
    }, [value]);

    const onChangeWithDebounce = ({
      target,
    }: ChangeEvent<{
      /** The new value */
      value: string;
    }>): void => {
      // set locally
      setLocalValue(target.value);
      setIsSearching(true);
      debouncedOnChange(target.value);
    };

    return (
      <Input
        icon={<Icon type="search" />}
        className="search"
        placeholder={placeholder || searchMessages.placeholder}
        {...searchProps}
        loading={loading || searching}
        aria-busy={loading ? 'true' : 'false'}
        onChange={onChangeWithDebounce}
        value={localValue}
        ref={ref}
      />
    );
  },
);
