/* eslint-disable max-lines */
import {
  GlobalActionItem,
  GlobalActionItemContext,
} from '@main/access-control-types';
import {
  Button,
  ColumnHeaderWithTooltip,
  HeaderCheckbox,
  HideableCheckbox,
} from '@main/ad-core-components';
import {
  StyledFlexRowCenterBoth,
  StyledFlexRowCenterVertical,
} from '@main/admin-dashboard/src/DataMap/components';
import { AttributeKey } from '@main/attribute-types';
import {
  FlexRowCenterVertical,
  Icon,
  StyleUtils,
  Tooltip,
} from '@main/core-ui';
import { ID } from '@main/schema-utils';
import {
  ActionItemCode,
  ActionItemPriorityOverride,
} from '@transcend-io/privacy-types';
import moment from 'moment';
import React, { ChangeEvent } from 'react';
import { useIntl } from 'react-intl';
import { CellProps, Column, ToCsvCellParams } from 'react-table';
import { useTheme } from 'styled-components';

import { ActionItemCodeInfoPopover } from './ActionItemSubscriptionsList/ActionItemCodeInfoPopover';
import { InlineActionItemAttributeInput } from './components/InlineActionItemAttribute';
import { InlineActionItemLink } from './components/InlineActionItemLink';
import { InlineActionItemNotes } from './components/InlineActionItemNotes';
import { InlineSelectAssigneeTeams } from './components/InlineSelectAssigneeTeams';
import { InlineSelectAssigneeUsers } from './components/InlineSelectAssigneeUsers';
import { InlineSelectDueDate } from './components/InlineSelectDueDate';
import { InlineSelectPriority } from './components/InlineSelectPriority';
import { ACTION_ITEM_FRONTEND_MAPPING } from './constants';
import { ellipsizeAndJoinCsvValues } from './helpers/ellipsizeAndJoinCsvValues';
import {
  actionItemDocumentationMessages,
  actionItemsPriorityMessages,
  actionItemsTableMessages,
} from './messages';
import { ActionItemRow, ColumnTableContext } from './types';

export const EXPAND_COLUMN: Column<ActionItemRow> = {
  id: 'expander',
  disableResizing: true,
  width: 25,
  Cell: ({ row }: CellProps<ActionItemRow>) => {
    const theme = useTheme();
    return (
      row.original.count > 1 && (
        <FlexRowCenterVertical style={{ height: '100%' }}>
          <span {...row.getToggleRowExpandedProps?.()}>
            <Icon
              type={row.isExpanded ? 'caret-down' : 'caret-right'}
              color={theme.colors.transcend}
            />
          </span>
        </FlexRowCenterVertical>
      )
    );
  },
  SubCell: () => null, // No expander on an expanded row
};

export const CHECKBOX_COLUMN: (
  selectedActionItems: Set<ID<'actionItem'>>,
  setSelectedActionItems: (selectedActionItems: Set<ID<'actionItem'>>) => void,
) => Column<ActionItemRow> = (selectedActionItems, setSelectedActionItems) => ({
  accessor: 'ids',
  disableResizing: true,
  width: 40,
  minWidth: 40,
  Header: ({ data }) => (
    <StyledFlexRowCenterBoth>
      <HeaderCheckbox
        checked={
          selectedActionItems.size > 0 &&
          selectedActionItems.size === data.length
        }
        onChange={() => {
          if (selectedActionItems.size === data.length) {
            setSelectedActionItems(new Set());
          } else {
            setSelectedActionItems(
              new Set(data.map(({ ids }: GlobalActionItem) => ids)),
            );
          }
        }}
      />
    </StyledFlexRowCenterBoth>
  ),
  Cell: ({ value }: CellProps<ActionItemRow>) => (
    <StyledFlexRowCenterBoth>
      <HideableCheckbox
        checked={value.every((val: ID<'actionItem'>) =>
          selectedActionItems.has(val),
        )}
        onChange={(e: ChangeEvent<HTMLInputElement>) => {
          const newSelectedActionItems = new Set(selectedActionItems);
          value.forEach((val: ID<'actionItem'>) => {
            if (e.target.checked) {
              newSelectedActionItems.add(val);
            } else {
              newSelectedActionItems.delete(val);
            }
          });
          setSelectedActionItems(newSelectedActionItems);
        }}
      />
    </StyledFlexRowCenterBoth>
  ),
});

export const TASK_COLUMN: (showIcon: boolean) => Column<ActionItemRow> = (
  showIcon,
) => ({
  accessor: 'type',
  id: 'task',
  Cell: ({
    row: {
      original: { count, titles, additionalContexts, resolved, type, dueDate },
    },
  }: CellProps<ActionItemRow>) => {
    const { formatMessage, formatNumber } = useIntl();
    const theme = useTheme();

    // This stuff is needed because react-intl likes to barf out the unformatted message if
    // any value is undefined or key is missing. So we have to manually go through and set them.
    const defaultAdditionalContexts: Required<GlobalActionItemContext> = {
      iconOverride: '',
      requestId: '' as any,
      dataSiloId: '' as any,
      requestType: '',
      latestAirgapVersion: '',
      parentTitle: '',
    };
    const safeAdditionalContexts: Required<GlobalActionItemContext> = {
      ...defaultAdditionalContexts,
      ...(additionalContexts?.[0] ?? {}),
    };
    return (
      <StyledFlexRowCenterVertical
        style={{
          display: 'inline-flex',
          alignItems: 'center',
          gap: StyleUtils.Spacing.sm,
        }}
      >
        {(showIcon || type !== ActionItemCode.Onboarding) && (
          <FlexRowCenterVertical>
            <ActionItemCodeInfoPopover
              code={type}
              icon={
                <FlexRowCenterVertical>
                  {count === 1 && additionalContexts?.[0]?.iconOverride ? (
                    <img
                      src={additionalContexts?.[0]?.iconOverride}
                      alt={titles?.[0] ?? ''}
                      // 22 instead of 24 here because the icons have a small gap
                      // that the images don't, so it looks nicer this way
                      style={{
                        minHeight: 22,
                        minWidth: 22,
                        maxWidth: 22,
                      }}
                    />
                  ) : (
                    <Icon
                      type={ACTION_ITEM_FRONTEND_MAPPING[type].icon}
                      size={24}
                      color={
                        resolved
                          ? theme.colors.transcendNavy3
                          : theme.colors.transcend
                      }
                    />
                  )}
                </FlexRowCenterVertical>
              }
            />
          </FlexRowCenterVertical>
        )}
        <span
          style={{
            fontWeight: 500,
            color: theme.colors.transcendNavy,
            ...(resolved
              ? {
                  textDecoration: 'line-through',
                  color: theme.colors.transcendNavy3,
                  textDecorationThickness: 2,
                }
              : {}),
          }}
        >
          {formatMessage(ACTION_ITEM_FRONTEND_MAPPING[type].richMessage, {
            count,
            prettyCount: (
              <Tooltip
                title={
                  // only show tooltip when count is abbreviated
                  formatNumber(count) !==
                  formatNumber(count, { notation: 'compact' })
                    ? formatNumber(count)
                    : undefined
                }
              >
                <span>{formatNumber(count, { notation: 'compact' })}</span>
              </Tooltip>
            ),
            title: <span style={{ fontWeight: 600 }}>{titles?.[0] ?? ''}</span>,
            ...safeAdditionalContexts,
            parentTitle: (
              <span style={{ fontWeight: 600 }}>
                {additionalContexts?.[0]?.parentTitle ?? ''}
              </span>
            ),
          })}
        </span>
        {dueDate && new Date(dueDate) < new Date() && (
          <Tooltip title={formatMessage(actionItemsTableMessages.itemOverdue)}>
            <FlexRowCenterVertical>
              <Icon type="stopwatch" color={theme.colors.red1} />
            </FlexRowCenterVertical>
          </Tooltip>
        )}
      </StyledFlexRowCenterVertical>
    );
  },
  minWidth: 300,
  csvHeader: [
    actionItemsTableMessages.count,
    actionItemsTableMessages.task,
    actionItemsTableMessages.title,
    actionItemsTableMessages.parentType,
    actionItemsTableMessages.parentTitle,
  ],
  toCsvCells: ({
    value,
    data,
  }: ToCsvCellParams<ActionItemRow['count'], ActionItemRow>) => [
    data.count,
    value,
    ellipsizeAndJoinCsvValues(data.titles, data.count),
    ACTION_ITEM_FRONTEND_MAPPING[data.type].parentType,
    ellipsizeAndJoinCsvValues(
      data.additionalContexts?.map((context) => context.parentTitle ?? ''),
      data.count,
    ),
  ],
});

export const ASSIGNEE_TEAM_COLUMN: (
  context: ColumnTableContext,
) => Column<ActionItemRow> = (context) => ({
  accessor: 'teams',
  Cell: ({
    value,
    row: {
      original: { ids, type, users, teams, count, resolved },
    },
  }: CellProps<ActionItemRow>) => (
    <StyledFlexRowCenterVertical>
      <InlineSelectAssigneeTeams
        ids={ids}
        value={value}
        context={{
          ...context,
          rollupFilters: {
            type,
            assigneesUserIds: users.map((user) => user.id),
            assigneesTeamIds: teams.map((team) => team.id),
            resolved,
          },
          count,
        }}
      />
    </StyledFlexRowCenterVertical>
  ),
  minWidth: 250,
  toCsvCell: ({ value }) =>
    (value || []).map(
      ({
        name,
      }: {
        /** The team's name */
        name: string;
      }) => name,
    ),
});

export const ASSIGNEE_USER_COLUMN: (
  context: ColumnTableContext,
) => Column<ActionItemRow> = (context) => ({
  accessor: 'users',
  Cell: ({
    value,
    row: {
      original: { ids, type, users, teams, resolved, count },
    },
  }: CellProps<ActionItemRow>) => (
    <StyledFlexRowCenterVertical>
      <InlineSelectAssigneeUsers
        ids={ids}
        value={value}
        context={{
          ...context,
          rollupFilters: {
            type,
            assigneesUserIds: users.map((user) => user.id),
            assigneesTeamIds: teams.map((team) => team.id),
            resolved,
          },
          count,
        }}
      />
    </StyledFlexRowCenterVertical>
  ),
  toCsvCell: ({ value }) =>
    (value || []).map(
      ({
        email,
      }: {
        /** The user's name */
        email: string;
      }) => email,
    ),
  minWidth: 250,
});

export const DUE_DATE_COLUMN: (
  context: ColumnTableContext,
) => Column<ActionItemRow> = (context) => ({
  accessor: 'dueDate',
  Cell: ({
    value,
    row: {
      original: { ids, type, users, teams, resolved, count },
    },
  }: CellProps<ActionItemRow>) => (
    <StyledFlexRowCenterVertical>
      <InlineSelectDueDate
        value={value}
        ids={ids}
        context={{
          ...context,
          rollupFilters: {
            type,
            assigneesUserIds: users.map((user) => user.id),
            assigneesTeamIds: teams.map((team) => team.id),
            resolved,
          },
          count,
        }}
      />
    </StyledFlexRowCenterVertical>
  ),
  toCsvCell: ({ value }) => moment(value).toISOString(),
});

export const PRIORITY_COLUMN: (
  context: ColumnTableContext,
) => Column<ActionItemRow> = (context) => ({
  accessor: 'priority',
  Cell: ({
    value,
    row: {
      original: { ids, type, users, teams, resolved, count },
    },
  }: CellProps<ActionItemRow>) => {
    const { formatMessage } = useIntl();
    return (
      <StyledFlexRowCenterVertical>
        <InlineSelectPriority
          ids={ids}
          value={
            value && {
              value,
              label: formatMessage(
                actionItemsPriorityMessages[
                  value as ActionItemPriorityOverride
                ],
              ),
            }
          }
          context={{
            ...context,
            rollupFilters: {
              type,
              assigneesUserIds: users.map((user) => user.id),
              assigneesTeamIds: teams.map((team) => team.id),
              resolved,
            },
            count,
          }}
        />
      </StyledFlexRowCenterVertical>
    );
  },
  toCsvCell: ({ value }) => value,
  minWidth: 180,
});

export const DOCUMENTATION_COLUMN: Column<ActionItemRow> = {
  accessor: 'documentationLink',
  Cell: ({
    row: {
      original: { type },
    },
  }: CellProps<ActionItemRow>) => {
    const { formatMessage } = useIntl();
    return ACTION_ITEM_FRONTEND_MAPPING[type as ActionItemCode]
      .documentationLink ? (
      <StyledFlexRowCenterVertical>
        <Button
          variant="link"
          href={
            ACTION_ITEM_FRONTEND_MAPPING[type as ActionItemCode]
              .documentationLink
          }
          target="_blank"
          icon={<Icon type="book" size={24} />}
        >
          {formatMessage(
            ACTION_ITEM_FRONTEND_MAPPING[type as ActionItemCode]
              .documentationMessage ?? actionItemDocumentationMessages.default,
          )}
        </Button>
      </StyledFlexRowCenterVertical>
    ) : (
      <></>
    );
  },
  toCsvCell: ({ value }) => value,
  minWidth: 275,
};

export const RESOLVED_COLUMN: Column<ActionItemRow> = {
  accessor: 'resolved',
  width: 30,
  Cell: ({
    row: {
      original: { resolved },
    },
  }: CellProps<ActionItemRow>) => {
    const theme = useTheme();
    return (
      <StyledFlexRowCenterVertical>
        {resolved && <Icon color={theme.colors.mint1} type="checkmark" />}
      </StyledFlexRowCenterVertical>
    );
  },
  toCsvCell: ({ value }) => (value ? 'true' : 'false'),
};

export const NOTES_COLUMN: (
  context: ColumnTableContext,
) => Column<ActionItemRow> = (context) => ({
  accessor: 'notes',
  minWidth: 200,
  Cell: ({
    value,
    row: {
      original: { ids, type, users, teams, resolved, count },
    },
  }: CellProps<ActionItemRow>) => (
    <StyledFlexRowCenterVertical>
      <InlineActionItemNotes
        values={value}
        ids={ids}
        context={{
          ...context,
          rollupFilters: {
            type,
            assigneesUserIds: users.map((user) => user.id),
            assigneesTeamIds: teams.map((team) => team.id),
            resolved,
          },
          count,
        }}
        hasMultipleValues={
          value.length > 1 && value.some((note: string) => note !== value[0])
        }
      />
    </StyledFlexRowCenterVertical>
  ),
  csvHeader: [actionItemsTableMessages.notes],
  toCsvCell: ({ value, data }) => ellipsizeAndJoinCsvValues(value, data.count),
});

export const LINKS_COLUMN: (
  context: ColumnTableContext,
) => Column<ActionItemRow> = (context) => ({
  accessor: 'links',
  Cell: ({
    value,
    row: {
      original: { ids, type, users, teams, resolved, count },
    },
  }: CellProps<ActionItemRow>) => (
    <StyledFlexRowCenterVertical>
      <InlineActionItemLink
        values={value}
        ids={ids}
        context={{
          ...context,
          rollupFilters: {
            type,
            assigneesUserIds: users.map((user) => user.id),
            assigneesTeamIds: teams.map((team) => team.id),
            resolved,
          },
          count,
        }}
        hasMultipleValues={
          value.length > 1 && value.some((link: string) => link !== value[0])
        }
      />
    </StyledFlexRowCenterVertical>
  ),
  csvHeader: [actionItemsTableMessages.links],
  toCsvCell: ({ value, data }) => ellipsizeAndJoinCsvValues(value, data.count),
});

export const defineAttributeColumn: (
  key: AttributeKey,
  context: ColumnTableContext,
) => Column<ActionItemRow> = (key, context) => ({
  id: key.id,
  accessor: 'attributeValues',
  Header: () => (
    <ColumnHeaderWithTooltip message={key.name} info={key.description} />
  ),
  Cell: ({
    value: attributeValues,
    row: {
      original: { ids, type, users, teams, resolved, count },
    },
  }: CellProps<ActionItemRow, ActionItemRow['attributeValues']>) => (
    <StyledFlexRowCenterVertical>
      <InlineActionItemAttributeInput
        values={attributeValues.flatMap((values) =>
          values.filter(({ attributeKey }) => attributeKey.id === key.id),
        )}
        attributeKey={key}
        ids={ids}
        context={{
          ...context,
          rollupFilters: {
            type,
            assigneesUserIds: users.map((user) => user.id),
            assigneesTeamIds: teams.map((team) => team.id),
            resolved,
          },
          count,
        }}
      />
    </StyledFlexRowCenterVertical>
  ),
  width: 270,
  minWidth: 150,
  csvHeader: key.name,
  toCsvCell: ({
    value: attributeValues,
  }: ToCsvCellParams<ActionItemRow['attributeValues']>) =>
    attributeValues
      .flatMap((values) =>
        values.filter(({ attributeKey }) => attributeKey.id === key.id),
      )
      .map(({ name }) => name),
});

/* eslint-enable max-lines */
