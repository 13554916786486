/* eslint-disable max-lines */
import {
  AdminDashboardInfrastructurePath,
  endpoints,
  TableId,
} from '@main/access-control-types';
import {
  Button,
  CheckboxInputGroup,
  commonButtonMessages,
  ISortableListItem,
  SortableList,
  transformRawPreference,
} from '@main/ad-core-components';
import {
  Alert,
  buildUseMutation,
  buildUseQuery,
  FlexRow,
  FlexRowCenterVertical,
  formatErrorMessage,
  Icon,
  message,
  Popover,
  useFormatMessageGeneric,
} from '@main/core-ui';
import { ID } from '@main/schema-utils';
import { indexBy } from '@main/utils';
import {
  AttributeSupportedResourceType,
  ScopeName,
} from '@transcend-io/privacy-types';
import difference from 'lodash/difference';
import React, { Dispatch, SetStateAction, useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Column } from 'react-table';
import { useTheme } from 'styled-components';

import {
  selectPreferences,
  selectRequiredUser,
} from '../../Auth/App/selectors';
import { setPreferences } from '../../Auth/App/slice';
import { ColumnConfigurationTab } from './constants';
import { ColumnIdWithMessage } from './helpers';
import { columnConfigurationMessages } from './messages';
import { useIsPersonalTab } from './useIsPersonalTab';
import { ColumnConfigSection, Divider, StyledTabBar } from './wrappers';

export interface ColumnsConfigurationButtonProps<D extends object> {
  /** All the native columns that can be hidden */
  columns: Column<D>[];
  /** All the dynamic columns that can be hidden */
  dynamicColumns?: Column<D>[];
  /** The key for this table's stored column configuration */
  tableId: TableId;
  /** The ID of the assessmentGroup if the preference refers to Assessments */
  assessmentGroupId?: ID<'assessmentGroup'>;
  /** Columns to show */
  hiddenColumns: string[];
  /** Callback to update the shown columns */
  setHiddenColumns: (columns: string[]) => void;
  /** Ids/accessors of columns whose visibility and order is not controllable */
  uneditableColumns: string[];
  /** The columns transformed to be the id/accessor and the header message for each */
  columnMessages: ColumnIdWithMessage[];
  /** The dynamic columns transformed to be the id/accessor and the header message for each */
  dynamicColumnMessages?: ColumnIdWithMessage[];
  /** Which database tables the attributes for this table have been enabled on  */
  attributesEnabledOn: AttributeSupportedResourceType;
  /** Whether to show the button that links to the Attributes page */
  showLinkToAttributes?: boolean;
  /** Whether to show tabs for the hidden columns */
  showTabs?: boolean;
  /** State for the ordered columns */
  orderedColumnsState: [string[], Dispatch<SetStateAction<string[]>>];
  // TODO: https://transcend.height.app/T-25072 - not supported on data reports yet
  /** Whether to hide the section for reordering columns */
  hideOrderableColumns: boolean;
  /** Whether to show the button that saves column configuration */
  showSaveButton: boolean;
}

const useCreateOrUpdateOrganizationPreference = buildUseMutation(
  endpoints.createOrUpdateOrganizationPreference,
);
const useCreateOrUpdateUserPreference = buildUseMutation(
  endpoints.createOrUpdateUserPreference,
);

const useUser = buildUseQuery(endpoints.users, 'currentUserScopes', {
  nodes: { allScopes: { name: null } },
});

export const ColumnsConfigurationButton = <D extends object>({
  uneditableColumns,
  attributesEnabledOn,
  columns,
  dynamicColumns = [],
  columnMessages,
  dynamicColumnMessages = [],
  tableId,
  assessmentGroupId,
  hiddenColumns,
  setHiddenColumns,
  showLinkToAttributes = true,
  showSaveButton,
  showTabs = true,
  orderedColumnsState: [orderedColumns, setOrderedColumns],
  hideOrderableColumns,
}: ColumnsConfigurationButtonProps<D>): JSX.Element => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const { formatMessage } = useIntl();
  const { formatMessageGeneric } = useFormatMessageGeneric();

  const [isPersonalTab, setIsPersonalTab] = useIsPersonalTab(
    tableId,
    assessmentGroupId,
  );

  const currentUser = useSelector(selectRequiredUser);
  const { userPreference, organizationPreference } =
    useSelector(selectPreferences);

  const { data: users } = useUser({
    variables: {
      filterBy: { ids: [currentUser.id] },
      first: 1,
    },
  });

  const columnIds = useMemo(
    () => columns.map(({ id, accessor }) => (id || accessor) as string),
    [columns],
  );
  const dynamicColumnIds = useMemo(
    () => dynamicColumns.map(({ id, accessor }) => (id || accessor) as string),
    [dynamicColumns],
  );

  const indexedColumnMessages = useMemo(
    () => indexBy([...columnMessages, ...dynamicColumnMessages], 'id'),
    [columnMessages, dynamicColumnMessages],
  );

  const sortableColumns = useMemo(
    () =>
      orderedColumns.map((id) => ({
        id,
        rawItem: id,
        content: (
          <>{formatMessageGeneric(indexedColumnMessages[id]?.message)}</>
        ),
      })),
    [indexedColumnMessages, formatMessageGeneric, orderedColumns],
  );

  const setItems = useCallback(
    (orderedColumnsAction: SetStateAction<ISortableListItem<string>[]>) => {
      if (Array.isArray(orderedColumnsAction)) {
        setOrderedColumns(orderedColumnsAction.map(({ rawItem }) => rawItem));
      }
    },
    [setOrderedColumns],
  );

  const options = useMemo(
    () =>
      columnMessages.map(({ id, message }) => ({
        value: id,
        label: message,
      })),
    [columnMessages],
  );
  const dynamicOptions = useMemo(
    () =>
      dynamicColumnMessages.map(({ id, message }) => ({
        value: id,
        label: message,
      })),
    [dynamicColumnMessages],
  );

  const [createOrUpdateOrganizationPreference] =
    useCreateOrUpdateOrganizationPreference();
  const [createOrUpdateUserPreference] = useCreateOrUpdateUserPreference();

  const savePreferredTab = useCallback(
    (isPersonalTab) => {
      const existingTables = userPreference?.tables || {};
      const targetTable = assessmentGroupId
        ? (existingTables.RISK_INTELLIGENCE_ASSESSMENTS ?? []).find(
            (t) => t.assessmentGroupId === assessmentGroupId,
          )
        : existingTables[tableId];
      const existingHiddenColumns = targetTable?.hiddenColumns ?? [];
      const existingOrderedColumns = targetTable?.orderedColumns ?? [];

      const newTableConfiguration = {
        isPreferred: isPersonalTab,
        ...(existingHiddenColumns
          ? {
              hiddenColumns: existingHiddenColumns,
            }
          : {}),
        ...(existingOrderedColumns
          ? {
              orderedColumns: existingOrderedColumns,
            }
          : {}),
      };

      // Only save which tab is preferred for the user (otherwise it would persist to the other users if set on org)
      return createOrUpdateUserPreference({
        variables: {
          input: {
            tables: {
              ...existingTables,
              [tableId]: assessmentGroupId
                ? [
                    ...(
                      existingTables.RISK_INTELLIGENCE_ASSESSMENTS ?? []
                    ).filter((t) => t.assessmentGroupId !== assessmentGroupId),
                    {
                      ...newTableConfiguration,
                      ...(assessmentGroupId && { assessmentGroupId }),
                    },
                  ]
                : newTableConfiguration,
            },
          },
        },
      })
        .then(({ data, errors }) => {
          if (data?.preference) {
            const parsedData = transformRawPreference(data);
            dispatch(
              setPreferences({
                userPreference: parsedData?.preference || null,
              }),
            );
          } else if (errors) {
            errors.forEach((error) =>
              message.error(formatErrorMessage(error.message)),
            );
          }
        })
        .catch(() => {
          setIsPersonalTab(!isPersonalTab);
          message.error(
            formatMessage(columnConfigurationMessages.changeTabError),
          );
        });
    },
    [
      createOrUpdateUserPreference,
      dispatch,
      formatMessage,
      tableId,
      setIsPersonalTab,
      userPreference?.tables,
      assessmentGroupId,
    ],
  );

  const savePreference = useCallback(() => {
    const tables =
      (isPersonalTab ? userPreference : organizationPreference)?.tables || {};
    const otherAssessmentsTables = (
      tables.RISK_INTELLIGENCE_ASSESSMENTS ?? []
    ).filter((t) => t.assessmentGroupId !== assessmentGroupId);
    const targetTable =
      (assessmentGroupId
        ? (tables.RISK_INTELLIGENCE_ASSESSMENTS ?? []).find(
            (t) => t.assessmentGroupId === assessmentGroupId,
          )
        : tables[tableId]) ?? [];

    const newTableConfiguration = {
      ...targetTable,
      hiddenColumns,
      orderedColumns,
    };

    return (
      isPersonalTab
        ? createOrUpdateUserPreference
        : createOrUpdateOrganizationPreference
    )({
      variables: {
        input: {
          tables: {
            ...tables,
            [tableId]: assessmentGroupId
              ? [
                  ...otherAssessmentsTables,
                  {
                    ...newTableConfiguration,
                    ...(assessmentGroupId && { assessmentGroupId }),
                  },
                ]
              : newTableConfiguration,
          },
        },
      },
    })
      .then(({ data, errors }) => {
        if (data?.preference) {
          const parsedData = transformRawPreference(data);
          dispatch(
            setPreferences({
              [isPersonalTab ? 'userPreference' : 'organizationPreference']:
                parsedData?.preference || null,
            }),
          );

          message.success(
            formatMessage(columnConfigurationMessages.successMessage),
          );
        } else if (errors) {
          errors.forEach((error) =>
            message.error(formatErrorMessage(error.message)),
          );
        }
      })
      .catch((error) => {
        if (error?.message) {
          message.error(formatErrorMessage(error.message));
        }
      });
  }, [
    isPersonalTab,
    userPreference,
    organizationPreference,
    hiddenColumns,
    orderedColumns,
    createOrUpdateUserPreference,
    createOrUpdateOrganizationPreference,
    tableId,
    assessmentGroupId,
    dispatch,
    formatMessage,
  ]);

  const disableEditingForOrg =
    showTabs &&
    !isPersonalTab &&
    !(users?.nodes[0]?.allScopes || [])
      .map(({ name }) => name)
      .includes(ScopeName.ManageOrganizationInfo);

  return (
    <Popover
      noPadding
      contents={
        <>
          <div>
            {showTabs && (
              <StyledTabBar
                unlinked
                activePath={
                  isPersonalTab
                    ? ColumnConfigurationTab.Personal
                    : ColumnConfigurationTab.Organization
                }
                onSelect={(selected) => {
                  const isPersonal =
                    selected === ColumnConfigurationTab.Personal;
                  setIsPersonalTab(isPersonal);
                  savePreferredTab(isPersonal);
                }}
                tabs={[
                  {
                    path: ColumnConfigurationTab.Organization,
                    title: formatMessage(
                      columnConfigurationMessages.organizationTab,
                    ),
                  },
                  {
                    path: ColumnConfigurationTab.Personal,
                    title: formatMessage(
                      columnConfigurationMessages.personalTab,
                    ),
                  },
                ]}
              />
            )}
            {disableEditingForOrg && (
              <div style={{ padding: '0 18px' }}>
                <Alert variant="warning">
                  {formatMessage(
                    columnConfigurationMessages.permissionAlertHeader,
                  )}
                </Alert>
              </div>
            )}
            <FlexRow style={{ alignItems: 'flex-start', height: '200px' }}>
              <ColumnConfigSection showTabs={showTabs}>
                <CheckboxInputGroup
                  value={difference(
                    columnIds,
                    uneditableColumns,
                    hiddenColumns,
                  )}
                  onChange={(shownColumnIds) => {
                    // the currently hidden dynamic columns should remain hidden
                    const dynamicHiddenColumns = hiddenColumns.filter((col) =>
                      dynamicColumnIds.includes(col),
                    );
                    setHiddenColumns(
                      difference(
                        [...columnIds, ...dynamicHiddenColumns],
                        shownColumnIds,
                        uneditableColumns,
                      ),
                    );
                  }}
                  onToggleAll={(isSelectAll) => {
                    // the currently hidden dynamic columns should remain hidden
                    const dynamicHiddenColumns = hiddenColumns.filter((col) =>
                      dynamicColumnIds.includes(col),
                    );
                    setHiddenColumns(
                      isSelectAll
                        ? dynamicHiddenColumns
                        : difference(
                            [...columnIds, ...dynamicHiddenColumns],
                            uneditableColumns,
                          ),
                    );
                  }}
                  title={columnConfigurationMessages.checkboxLabel}
                  options={options}
                  disabled={disableEditingForOrg}
                />
              </ColumnConfigSection>
              {/* display dynamic question columns for Assessments */}
              {tableId === TableId.RiskIntelligenceAssessments &&
                dynamicOptions.length > 0 && (
                  <>
                    <Divider />
                    <ColumnConfigSection showTabs={showTabs}>
                      <CheckboxInputGroup
                        value={difference(
                          dynamicColumnIds,
                          uneditableColumns,
                          hiddenColumns,
                        )}
                        onChange={(shownColumnIds) => {
                          // the currently hidden regular columns should remain hidden
                          const regularHiddenColumns = hiddenColumns.filter(
                            (col) => columnIds.includes(col),
                          );
                          setHiddenColumns(
                            difference(
                              [...dynamicColumnIds, ...regularHiddenColumns],
                              shownColumnIds,
                              uneditableColumns,
                            ),
                          );
                        }}
                        onToggleAll={(isSelectAll) => {
                          // the currently hidden regular columns should remain hidden
                          const regularHiddenColumns = hiddenColumns.filter(
                            (col) => columnIds.includes(col),
                          );
                          setHiddenColumns(
                            isSelectAll
                              ? regularHiddenColumns
                              : difference(
                                  [
                                    ...dynamicColumnIds,
                                    ...regularHiddenColumns,
                                  ],
                                  uneditableColumns,
                                ),
                          );
                        }}
                        title={columnConfigurationMessages.dynamicCheckboxLabel}
                        options={dynamicOptions}
                        disabled={disableEditingForOrg}
                      />
                    </ColumnConfigSection>
                  </>
                )}
              {/*
               * TODO: https://transcend.height.app/T-25072 - temporary wrapper to hide the column
               * reordering section as it is not supported on data reports yet
               * */}
              {!hideOrderableColumns && (
                <>
                  <Divider />

                  <ColumnConfigSection showTabs={showTabs}>
                    <div
                      id="sort-columns-label"
                      style={{ color: theme.colors.transcendNavy }}
                    >
                      {formatMessage(
                        columnConfigurationMessages.sortableListLabel,
                      )}
                    </div>
                    <div aria-describedby="sort-columns-label">
                      <SortableList
                        items={sortableColumns}
                        setItems={setItems}
                        draggableProps={{
                          isDragDisabled: disableEditingForOrg,
                        }}
                      />
                    </div>
                  </ColumnConfigSection>
                </>
              )}
            </FlexRow>
          </div>
          {showSaveButton && (
            <FlexRowCenterVertical
              style={{
                padding: '10px 18px',
                borderTop: `${theme.colors.transcendNavy4} 1px solid`,
                justifyContent: showLinkToAttributes
                  ? 'space-between'
                  : 'flex-end',
                gap: '2em',
              }}
            >
              {showLinkToAttributes && (
                <Link
                  to={`${AdminDashboardInfrastructurePath.CreateNewAttribute}?attributeKeyEnabledOn=${attributesEnabledOn}`}
                  style={{ color: theme.colors.transcend, fontWeight: 600 }}
                >
                  {formatMessage(columnConfigurationMessages.linkToAttributes)}
                </Link>
              )}
              <Button onClick={savePreference}>
                {formatMessage(commonButtonMessages.save)}
              </Button>
            </FlexRowCenterVertical>
          )}
        </>
      }
    >
      <Button icon={<Icon type="columns" />} variant="secondary">
        {formatMessage(
          isPersonalTab && showTabs
            ? columnConfigurationMessages.buttonPersonal
            : columnConfigurationMessages.button,
        )}
      </Button>
    </Popover>
  );
};
/* eslint-enable max-lines */
