import { mkInput, mkType, SchemaToType } from '@main/schema-utils';

export const DataSilosForSingleCountryInput = mkInput({
  name: 'DataSilosForSingleCountryInput',
  comment: 'Input for DataSilosForSingleCountry endpoint',
  fields: {
    countryCode: {
      comment: 'country code for which you are fetching data silos',
      type: 'string',
    },
  },
});

/** Override type */
export type DataSilosForSingleCountryInput = SchemaToType<
  typeof DataSilosForSingleCountryInput
>;

export const DataSiloCountForSingleCountry = mkType({
  name: 'DataSilosCountForSingleCountry',
  comment: 'number of data silos in each country',
  fields: {
    countryCode: {
      comment: 'country code for corresponding data silos',
      type: 'string',
    },
    count: {
      comment: 'number of silos',
      type: 'int',
    },
  },
});

/** Override type */
export type DataSiloCountForSingleCountry = SchemaToType<
  typeof DataSiloCountForSingleCountry
>;

export const DataSiloCountsByCountry = mkType({
  name: 'DataSilosByCountry',
  comment: 'all countries and their corresponding data silos',
  fields: {
    dataSiloCountsByCountry: {
      comment: 'list of countries and their data silos',
      type: DataSiloCountForSingleCountry,
      list: true,
    },
    cachedAt: {
      comment: 'The last time the data was fetched',
      type: 'Date',
      optional: true,
    },
  },
});

/** Override type */
export type DataSiloCountsByCountry = SchemaToType<
  typeof DataSiloCountsByCountry
>;

export const DataSiloCountsByCountryInput = mkInput({
  name: 'DataSiloCountsByCountryInput',
  comment: 'Input for dataSiloCountsByCountry endpoint',
  fields: {
    forceRefetch: {
      comment: 'Should ignore the cache and force a fetch?',
      type: 'boolean',
      optional: true,
    },
  },
});

/** Override type */
export type DataSiloCountsByCountryInput = SchemaToType<
  typeof DataSiloCountsByCountryInput
>;
