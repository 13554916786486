import { makeEnum } from '@transcend-io/type-utils';

import {
  FACEBOOK_LDU_MODULE,
  GOOGLE_ADS_RDP_MODULE,
  GOOGLE_CONSENT_MODE_MODULE,
  VIMEO_DNT_MODULE,
  WISTIA_DNT_MODULE,
  YOUTUBE_PRIVACY_ENHANCED_MODE_MODULE,
} from '@main/ag-types/src/constants';

/**
 * Privacy-enhancing tracker override modules
 */
export const TrackerOverrideModule = makeEnum({
  FACEBOOK_LDU_MODULE,
  GOOGLE_ADS_RDP_MODULE,
  GOOGLE_CONSENT_MODE_MODULE,
  VIMEO_DNT_MODULE,
  WISTIA_DNT_MODULE,
  YOUTUBE_PRIVACY_ENHANCED_MODE_MODULE,
});

/** Privacy-enhancing tracker override modules type override */
export type TrackerOverrideModule =
  (typeof TrackerOverrideModule)[keyof typeof TrackerOverrideModule];
