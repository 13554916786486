import { useFormatMessageGeneric } from '@main/core-ui';
import { ID } from '@main/schema-utils';
import { DefinedMessages } from '@transcend-io/internationalization';
import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { MessageDescriptor } from 'react-intl';
import { useSelector } from 'react-redux';
import { Column } from 'react-table';

import { selectPreferences } from '../../Auth/App/selectors';
import {
  ColumnsConfigurationButton,
  ColumnsConfigurationButtonProps,
} from './ColumnsConfigurationButton';
import { DYNAMIC_COLUMN_LENGTH } from './constants';
import { ColumnIdWithMessage, columnsToMessages } from './helpers';
import { useHiddenColumns } from './useHiddenColumns';
import { useVisibleColumns } from './useVisibleColumns';

export const useColumnConfiguration = <D extends object>({
  uneditableColumns,
  attributeColumnHeaders,
  attributesEnabledOn,
  columns,
  dynamicColumns = [],
  tableId,
  assessmentGroupId,
  hiddenColumnsState,
  nativeColumnHeaders,
  readOnly = false,
  hasCustomFields = !readOnly,
}: Pick<
  ColumnsConfigurationButtonProps<D>,
  | 'columns'
  | 'dynamicColumns'
  | 'tableId'
  | 'attributesEnabledOn'
  | 'uneditableColumns'
> & {
  /** Optional getter/setter for the names of the columns that are hidden, if this is to be controlled by a parent component */
  hiddenColumnsState?: [string[], Dispatch<SetStateAction<string[]>>];
  /** Mapping of table column ids (or accessors) to header messages */
  nativeColumnHeaders: DefinedMessages;
  /** Mapping of table column ids for attributes to their header labels */
  attributeColumnHeaders: Record<string, MessageDescriptor | string>;
  /** Whether column configuration is displayed in a context where the table is */
  readOnly?: boolean;
  /** Whether to show a link to the attributes tab */
  hasCustomFields?: boolean;
  /** The ID of the assessment group needed for Assessment preferences */
  assessmentGroupId?: ID<'assessmentGroup'>;
}): {
  /** Names of the columns that are hidden */
  hiddenColumns: string[];
  /** Columns in the order they should be displayed */
  orderedColumns: string[];
  /** Column definition of the currently visible columns */
  visibleColumns: Column<D>[];
  /** The columns transformed to be the id/accessor and the header message for each */
  columnMessages: ColumnIdWithMessage[];
  /** The button and popover */
  button: JSX.Element;
} => {
  const { formatMessageGeneric } = useFormatMessageGeneric();

  // Get preferences
  const { userPreference, organizationPreference } =
    useSelector(selectPreferences);

  const tableConfiguration = assessmentGroupId
    ? (userPreference?.tables?.RISK_INTELLIGENCE_ASSESSMENTS ?? []).find(
        (t) => t.assessmentGroupId === assessmentGroupId,
      )
    : userPreference?.tables[tableId];
  const isPersonalTab = !!tableConfiguration?.isPreferred;

  const previousIsPersonalTab = useRef(isPersonalTab);

  const columnMessages: ColumnIdWithMessage[] = useMemo(
    () =>
      columnsToMessages({
        columns,
        uneditableColumns,
        nativeColumnHeaders,
        attributeColumnHeaders,
      }),
    [columns, uneditableColumns, nativeColumnHeaders, attributeColumnHeaders],
  );
  const dynamicColumnMessages = useMemo(
    () =>
      dynamicColumns.map((c) => ({
        id: c.id ?? '',
        // trim some questions whose title is too long so it doesn't overflow
        message:
          ((c.Header ?? '') as string).length > DYNAMIC_COLUMN_LENGTH
            ? `${(c.Header as string).substring(0, DYNAMIC_COLUMN_LENGTH)}...`
            : c.Header,
      })),
    [dynamicColumns],
  );
  const allColumnMessages = useMemo(
    () => [...columnMessages, ...dynamicColumnMessages],
    [columnMessages, dynamicColumnMessages],
  );

  // Set up hidden columns
  const [hiddenColumns, setHiddenColumns] = useHiddenColumns(
    tableId,
    hiddenColumnsState,
    assessmentGroupId,
  );

  // Set up ordered columns
  const [orderedColumns, setOrderedColumns] = useState<string[]>([]);

  const visibleColumns = useVisibleColumns(hiddenColumns, orderedColumns, [
    ...columns,
    ...dynamicColumns,
  ]);

  useEffect(() => {
    const tables =
      (isPersonalTab
        ? userPreference?.tables
        : organizationPreference?.tables) ?? {};
    const tableConfiguration = assessmentGroupId
      ? (tables.RISK_INTELLIGENCE_ASSESSMENTS ?? []).find(
          (t) => t.assessmentGroupId === assessmentGroupId,
        )
      : tables[tableId];
    const preferenceOrderedColumns = tableConfiguration?.orderedColumns;

    if (
      // initial load or more columns (i.e. attribute columns) loaded
      allColumnMessages.length !== orderedColumns.length ||
      // tab changed
      isPersonalTab !== previousIsPersonalTab.current
    ) {
      previousIsPersonalTab.current = isPersonalTab;
      if (preferenceOrderedColumns) {
        // establish the order saved in preferences
        setOrderedColumns(
          // use allColumnMessages to make sure columns added or deleted since last save are
          // also added and deleted from the UI here.
          allColumnMessages
            .map(({ id }) => id)
            .sort((a, b) => {
              const indexA = preferenceOrderedColumns.indexOf(a);
              const indexB = preferenceOrderedColumns.indexOf(b);

              // keep item in place if it is not in the preferences array
              if (indexA === -1 || indexB === -1) {
                return 0;
              }

              return indexA - indexB;
            }),
        );
      } else {
        setOrderedColumns(allColumnMessages.map(({ id }) => id));
      }
    }
  }, [
    uneditableColumns,
    attributeColumnHeaders,
    allColumnMessages,
    columns,
    formatMessageGeneric,
    tableId,
    assessmentGroupId,
    isPersonalTab,
    nativeColumnHeaders,
    orderedColumns,
    organizationPreference,
    userPreference,
  ]);

  const orderedColumnsWithAlwaysShown = useMemo(
    () => [...uneditableColumns, ...orderedColumns],
    [uneditableColumns, orderedColumns],
  );

  return {
    hiddenColumns,
    orderedColumns: orderedColumnsWithAlwaysShown,
    visibleColumns,
    columnMessages,
    button: (
      <ColumnsConfigurationButton
        // This button is most likely injected into a list in a table toolbar, where it needs a key.
        // If put somewhere else, the key doesn't hurt anything.
        key="column-configuration"
        columns={columns}
        dynamicColumns={dynamicColumns}
        columnMessages={columnMessages}
        dynamicColumnMessages={dynamicColumnMessages}
        uneditableColumns={uneditableColumns}
        hiddenColumns={hiddenColumns}
        setHiddenColumns={setHiddenColumns}
        attributesEnabledOn={attributesEnabledOn}
        showLinkToAttributes={hasCustomFields}
        showTabs={!readOnly}
        showSaveButton={!readOnly}
        tableId={tableId}
        assessmentGroupId={assessmentGroupId}
        orderedColumnsState={[orderedColumns, setOrderedColumns]}
        // TODO: https://transcend.height.app/T-25072 - not supported on data reports yet
        hideOrderableColumns={readOnly}
      />
    ),
  };
};
