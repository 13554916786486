import isEqual from 'lodash/isEqual';
import { createSelector } from 'reselect';

import { RequestsProcessedDisclosureStatsSettingsState } from '../slice';

export const selectRequestsProcessedDisclosureStatsSettingsView = (
  state: any,
): RequestsProcessedDisclosureStatsSettingsState =>
  state.RequestsProcessedDisclosureStatsSettings;

export const selectRequestsProcessedDisclosureStatsSettings = createSelector(
  selectRequestsProcessedDisclosureStatsSettingsView,
  ({ requestsProcessedDisclosureStatsSettings }) =>
    requestsProcessedDisclosureStatsSettings,
);

export const selectRequestsProcessedDisclosureStatsInput = createSelector(
  selectRequestsProcessedDisclosureStatsSettingsView,
  ({ newRequestsProcessedDisclosureStatsSettings }) =>
    newRequestsProcessedDisclosureStatsSettings,
);

export const selectIsRequestsProcessedDisclosureStatsModified = createSelector(
  selectRequestsProcessedDisclosureStatsSettings,
  selectRequestsProcessedDisclosureStatsInput,
  (settings, input) => !!settings && !!input && !isEqual(settings, input),
);
