/* eslint-disable max-lines */
import { useRouter } from '@main/core-ui/src/hooks/useRouter';
import { addQueryParams } from '@main/core-ui/src/utils/location';
import { indexTree } from '@main/utils';
import type { MessageValues } from '@transcend-io/internationalization';
import difference from 'lodash/difference';
import isEqual from 'lodash/isEqual';
import uniq from 'lodash/uniq';
import React, { useEffect, useState } from 'react';

import { Tooltip } from '../Tooltip';
import { MenuItem, MenuProps } from './Menu';
import { MenuTab, SideMenuTab, SideMenuTabProps } from './SideMenuTab';
import { SideMenuWrapper, SubMenu } from './wrappers';

/**
 * The default menu tab
 */
export interface DefaultMenuTab {
  /** Child tabs must be a list */
  children?: DefaultMenuTab[];
  /** Most have an id attribute */
  id: string;
}

/** Index from child id to all of its parent ids */
export type ParentLookup = { [k in string]: string[] };

/** Lookup menu tab by visual id */
export type MenuTabLookup = { [visualId in string]: MenuTab };

export type { MenuTab, SideMenuTabProps };

/**
 * Props
 */
export interface SideMenuProps extends Omit<MenuProps, 'theme'> {
  /** The tabs to display in the menu */
  tabs: MenuTab[];
  /** The id of the currently selected menu item */
  selected?: string;
  /** The max height of the menu */
  maxHeight?: string;
  /** The minimum height of the menu */
  minHeight?: string;
  /** The mode to display the menu */
  mode?: MenuProps['mode'];
  /**
   * When true, don't use the page location hash.
   * When a string, this query param should hold the current page
   * TODO rename prop
   */
  noHash?: boolean | string;
  /** Collapse other side menus when current is de-selected */
  accordion?: boolean;
  /** Callback that occurs when selected item changes */
  onSelectChange?: (options: {
    /** Menu name */
    newMenu: string;
    /** The tab selected */
    menuTab: MenuTab;
    /** All parent tabs */
    parentTabs: MenuTab[];
    /** When true, user wants to open selection in a new tab */
    newTab: boolean;
    /** The parent tab */
    parentTab?: MenuTab;
  }) => void;
  /** When true, always drop down the sub-menus  */
  alwaysDropdown?: boolean;
  /** The menu style */
  style?: React.CSSProperties;
  /** Styles to apply to each tab */
  tabStyles?: (level: number) => React.CSSProperties;
  /** Values to pass to menu message */
  values?: MessageValues;
  /**
   * Provide the base url that the hash should live in.
   * This is only needed if you intend to display the side menu page on a different page
   * (i.e. the request menu page when on request/data-silo)
   */
  fullUrl?: string;
  /** The ant.design menu component, styled */
  MenuWrapper?: typeof SideMenuWrapper;
  /** The class name of the sub menu */
  popupClassName?: string;
  /** When true and no selected menu provided, the first menu should be selected */
  setFirstAsSelected?: boolean;
  /** Whether to include the gradient fade or not for menu scrolling */
  haveGradient?: boolean;
  /** TODO */
  staticContext?: undefined;
  /** The counts to display beside the children tabs */
  counts?: Record<
    string,
    {
      /** The number of request files for this child */
      count?: number;
      /** Whether this child has request files */
      hasRequestFiles: boolean;
    }
  >;
  /** Whether the children tabs should show a count */
  showCount?: boolean;
  /** whether to use ant icons or not */
  antIcons?: boolean;
}

/**
 * Display a side menu that can switch which components are being viewed
 */
export const SideMenu: React.FC<SideMenuProps> = ({
  /* eslint-disable @typescript-eslint/no-unused-vars */
  staticContext,
  /* eslint-enable @typescript-eslint/no-unused-vars */
  noHash = false,
  onSelectChange,
  tabStyles,
  values,
  fullUrl,
  setFirstAsSelected,
  popupClassName,
  accordion,
  alwaysDropdown,
  maxHeight = '800px',
  minHeight = '',
  selected,
  tabs,
  style = { overflowY: 'auto' },
  haveGradient = false,
  mode = 'inline',
  MenuWrapper = SideMenuWrapper,
  antIcons = true,
  ...rest
}) => {
  const { location, queryParams, redirect } = useRouter();
  const { hash } = location;
  // TODO move state defaults to props
  // Index from child ids to all of its parents
  const [parentLookup, setParentLookup] = useState<ParentLookup>({});

  // Needed in order to allow mobile side menu to re-expand URG
  const [resetInd, setResetInd] = useState(0);

  // Lookup menu item by visual id. Only indexing the lead nodes
  const [lookupMenuTab, setLookupMenuTab] = useState<MenuTabLookup>({});

  // The keys of all of all menu items in the tree
  const [allMenuKeys, setAllMenuKeys] = useState<string[]>([]);

  // The open menus by default
  const [defaultOpenKeys, setDefaultOpenKeys] = useState<string[]>([]);

  // Can set the default selected item when nothing is selected
  const [defaultSelectedItem, setDefaultSelectedItem] = useState<string>();

  // Determine the currently selected tab
  const displayTab = selected || defaultSelectedItem;

  // Keys open
  const openKeys = alwaysDropdown ? allMenuKeys : defaultOpenKeys;

  // Callback to alert parent of change
  const onChange = (newSelected: string, newTab: boolean): void => {
    if (!onSelectChange) {
      return;
    }
    // Grab the expected node
    const selectedNode = lookupMenuTab[newSelected];
    // Determine the parent nodes for the selected value
    const selectedParents = (parentLookup[newSelected] || []).map(
      (parentVisualId) => lookupMenuTab[parentVisualId],
    );
    onSelectChange({
      newMenu: newSelected,
      menuTab: selectedNode,
      parentTabs: selectedParents,
      newTab,
    });
  };

  // Let the parent component know that the default item is being set
  useEffect(() => {
    if (displayTab && displayTab !== selected) {
      onChange(displayTab, false);
    }
  }, [displayTab]);

  // Whenever the tabs change, we create an index of the tree to display the tree
  useEffect(() => {
    const indexedTabs = indexTree(tabs, 'visualId');
    setParentLookup(indexedTabs.parentLookup);
    setLookupMenuTab(indexedTabs.lookupTreeTab);

    // New menu keys
    const newMenuKeys = indexedTabs.allMenuKeys;
    setAllMenuKeys(newMenuKeys);
    if (!isEqual(newMenuKeys, allMenuKeys)) {
      setResetInd(resetInd + 1);
    }

    // Set the open keys
    if (selected && mode !== 'horizontal') {
      setDefaultOpenKeys(parentLookup[selected] || [selected]);
    } else if (mode !== 'horizontal') {
      // Grab the first item
      let [first] = tabs.filter(({ children }) => children);

      // Get inner most
      while (first && first.children && first.children.length > 0) {
        [first] = first.children;
      }

      // Set to default open key
      if (first) {
        setDefaultOpenKeys(parentLookup[first.visualId] || [first.visualId]);

        // Set default selected
        if (setFirstAsSelected) {
          setDefaultSelectedItem(first.visualId);
        }
      }
    }
  }, [tabs, selected, mode]);

  // Ensure that the page hash or query param matches the expected selected menu item
  useEffect(() => {
    // Nothing to do if no onSelectChange provided
    if (!onSelectChange) {
      return;
    }

    // We don't have to do anything when there is no hashbang or query param support
    if (!noHash) {
      return;
    }

    // determine expected new selected menu item id by pulling from hash or queryParams
    const newSelected =
      noHash === true
        ? (hash.split('#').pop() as string)
        : queryParams[noHash] || '';

    // If the hashes
    if (selected !== newSelected) {
      onChange(newSelected, false);
    }
  }, [hash, queryParams, noHash, onSelectChange]);

  // Render the tab menu item
  const renderTab = (tab: MenuTab, level: number): React.ReactNode => {
    const lineHeightHorizontalMenu =
      mode === 'horizontal' ? { lineHeight: '40px' } : undefined;

    const tabStylesCalculated = tabStyles
      ? { ...tabStyles(level), ...lineHeightHorizontalMenu }
      : {};
    const tabId = `${tab.visualId}-side-menu-item`;
    return tab.children ? (
      <SubMenu
        key={tab.visualId}
        id={tabId}
        popupClassName={popupClassName}
        style={tabStylesCalculated}
        title={
          <SideMenuTab
            tab={{ ...tab, selected: selected === tab.id }}
            values={values}
            style={tabStylesCalculated}
            level={level}
            antIcons={antIcons}
          />
        }
        onTitleClick={(e) => {
          const findFirstChild = (t: MenuTab): MenuTab => {
            if (t.children && t.children[0]) {
              return findFirstChild(t.children[0]);
            }
            return t;
          };
          const firstChild = findFirstChild(tab);
          const firstChildId = firstChild.visualId;
          onChange(firstChildId, (e.domEvent as any).metaKey);
        }}
      >
        {tab.children.map((childTab) => renderTab(childTab, level + 1))}
      </SubMenu>
    ) : (
      <MenuItem key={tab.visualId} id={tabId} style={tabStylesCalculated}>
        {tab.tooltip ? (
          <Tooltip title={tab.tooltip}>
            <SideMenuTab
              tab={{ ...tab, selected: selected === tab.id }}
              values={values}
              level={level}
              antIcons={antIcons}
              style={lineHeightHorizontalMenu}
            />
          </Tooltip>
        ) : (
          <SideMenuTab
            tab={{ ...tab, selected: selected === tab.id }}
            values={values}
            level={level}
            antIcons={antIcons}
            style={lineHeightHorizontalMenu}
          />
        )}
      </MenuItem>
    );
  };
  return (
    <MenuWrapper
      key={resetInd}
      maxheight={maxHeight}
      minheight={minHeight}
      havegradient={haveGradient.toString()}
      mode={mode}
      onOpenChange={(newDefaultOpenKeys) => {
        if (accordion) {
          const newKeys = difference(newDefaultOpenKeys, defaultOpenKeys).map(
            (key) => [key, ...(parentLookup[key] || [])],
          );
          const newOpenKeys = uniq(newKeys.flat());
          if (newOpenKeys.length > 0) {
            setDefaultOpenKeys(newOpenKeys);
          }
        } else {
          setDefaultOpenKeys(newDefaultOpenKeys);
        }
      }}
      onSelect={(e): void => {
        const { key, domEvent } = e;
        const keyboardEvent = domEvent as any as React.KeyboardEvent;

        // Get the full node
        if (key.includes('overflowed-indicator')) {
          return;
        }

        // Change the hashbang
        if (!noHash) {
          redirect(
            {
              ...location,
              hash: key,
              pathname: fullUrl || location.pathname,
            },
            keyboardEvent.metaKey,
          );
        }

        // Change a query param
        if (typeof noHash === 'string') {
          redirect(
            addQueryParams(location, { [noHash]: key }),
            keyboardEvent.metaKey,
          );
        }

        // Call onChange
        onChange(key, keyboardEvent.metaKey);
      }}
      openKeys={openKeys}
      selectedKeys={
        selected ? [selected] : defaultSelectedItem ? [defaultSelectedItem] : []
      }
      style={style}
      {...rest}
    >
      {tabs.map((tab) => renderTab(tab, 0))}
    </MenuWrapper>
  );
};
/* eslint-enable max-lines */
