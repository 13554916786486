import { endpoints, PullRequestPreview } from '@main/code-scanning-types';
import {
  buildUseQuery,
  formatErrorMessage,
  Icon,
  ReactSelect,
  ReactSelectExtendedProps,
} from '@main/core-ui';
import { ID } from '@main/schema-utils';
import React, { useCallback, useEffect, useState } from 'react';
import { useTheme } from 'styled-components';

const NODES = {
  id: null,
  title: null,
} as const;

/**
 * Selected pullRequest
 */
export type SelectedPullRequest = Pick<
  PullRequestPreview,
  keyof typeof NODES
> & {
  /** Whether pullRequest is newly created or existing */
  isNew?: boolean;
};

/** An option for the select component */
export interface SelectPullRequestOption {
  /** The value for the option */
  value: SelectedPullRequest;
  /** The label for the option */
  label: string;
  /** The logo for the option */
  logo: JSX.Element;
}

const usePullRequests = buildUseQuery(
  endpoints.pullRequests,
  'SelectPullRequests',
  {
    nodes: NODES,
  },
);

const isId = (
  value: SelectedPullRequest | ID<'pullRequest'>,
): value is ID<'pullRequest'> => typeof value === 'string';

/** Props for the `SelectPullRequest` component */
export interface SelectPullRequestProps
  extends Omit<
    ReactSelectExtendedProps<boolean, SelectedPullRequest>,
    'onChange'
  > {
  /** The currently selected values */
  value?: SelectedPullRequest[] | ID<'pullRequest'>[];
  /** On change handler */
  onChange: (pullRequests: SelectedPullRequest[]) => void;
}

const isSelectedPullRequestOption = (
  value: SelectPullRequestOption | undefined,
): value is SelectPullRequestOption => !!value;

export const SelectPullRequests: React.FC<SelectPullRequestProps> = ({
  isLoading,
  isMulti = true,
  disabled = false,
  value: initialValues,
  onChange,
  ...props
}) => {
  const { data, loading, error } = usePullRequests({
    fetchPolicy: 'cache-and-network',
  });
  const theme = useTheme();

  const toOption = useCallback(
    (value?: SelectedPullRequest): SelectPullRequestOption | undefined =>
      !value
        ? undefined
        : {
            value,
            label: value.title,
            logo: (
              <Icon type="assessment-template" color={theme.colors.transcend} />
            ),
          },
    [theme],
  );
  const options = (data?.nodes ?? []).map(toOption);

  const [selected, setSelected] = useState<SelectPullRequestOption[]>();

  useEffect(() => {
    const newSelected = (
      initialValues
        ? initialValues.map((value) =>
            isId(value)
              ? toOption((data?.nodes || []).find(({ id }) => id === value))
              : toOption(value),
          )
        : []
    ).filter(isSelectedPullRequestOption);

    setSelected(newSelected);
  }, [data, initialValues, toOption]);

  return (
    <ReactSelect<boolean, SelectPullRequestOption>
      options={options.filter(isSelectedPullRequestOption)}
      value={selected}
      isMulti={isMulti}
      isLoading={isLoading || loading}
      getOptionLogo={({ logo }) => logo}
      noOptionsMessage={
        error ? () => formatErrorMessage(error.message) : undefined
      }
      getOptionLabel={({ label }) => label}
      getOptionValue={({ value }) => value.id}
      isClearable={false}
      closeMenuOnSelect
      isDisabled={disabled}
      onChange={(option) =>
        !option
          ? onChange([])
          : Array.isArray(option)
            ? onChange(option.map(({ value }) => value))
            : onChange([(option as SelectPullRequestOption).value as any])
      }
      {...props}
    />
  );
};

/**
 * Select a single pullRequest
 */
export const SelectPullRequest: React.FC<
  Omit<SelectPullRequestProps, 'value' | 'onChange'> & {
    /** Set the newly selected pullRequests */
    onChange: (pullRequest: SelectedPullRequest) => void;
    /** Selected value */
    value?: SelectedPullRequest | ID<'pullRequest'>;
  }
> = ({ onChange, value, ...props }) => (
  <SelectPullRequests
    {...props}
    isMulti={false}
    value={
      // default type is mixed array rather than array of one type or the other
      value ? ([value] as SelectedPullRequest[] | ID<'pullRequest'>[]) : []
    }
    onChange={([value]) => onChange(value)}
  />
);
