import { makeEnum } from '@transcend-io/type-utils';

/** User-configurable user agent privacy signal */
export const UserPrivacySignalEnum = makeEnum({
  /** Global Privacy Control */
  GPC: 'GPC',
  /** Do Not Track */
  DNT: 'DNT',
});

/**
 * Type override
 */
export type UserPrivacySignalEnum =
  (typeof UserPrivacySignalEnum)[keyof typeof UserPrivacySignalEnum];
