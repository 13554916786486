import { SingleTag } from '@main/core-ui';
import { ActionItemPriorityOverride } from '@transcend-io/privacy-types';
import React from 'react';
import { useIntl } from 'react-intl';

import { ACTION_ITEM_PRIORITY_COLOR_NAMES } from '../constants';
import { actionItemsPriorityMessages } from '../messages';

interface ActionItemPriorityLabelProps {
  /** the priority to display */
  priority: ActionItemPriorityOverride;
}

/**
 * ActionItemPriorityLabel
 */
export const ActionItemPriorityLabel: React.FC<
  ActionItemPriorityLabelProps
> = ({ priority }) => {
  const { formatMessage } = useIntl();
  return (
    <SingleTag
      label={formatMessage(actionItemsPriorityMessages[priority])}
      color={ACTION_ITEM_PRIORITY_COLOR_NAMES[priority] ?? 'transcendNavy2'}
      variant="filled"
    />
  );
};
