import { invert } from '@transcend-io/type-utils';

import { IntlMessageType } from '@main/internationalization';

import * as endpoints from './endpoints';
import { PrivacyCenterCustomizableMessageName } from './enums';
import { PageType } from './schema/enums';
import { PrivacyCenterModule, PrivacyCenterModuleContext } from './types';

/**
 * Lookup from page type to pathname
 */
export const PAGE_PATHNAMES: { [k in PageType]: string } = {
  [PageType.ManageYourPrivacy]: '/my-privacy-choices',
  [PageType.DataPractices]: '/',
  [PageType.Policies]: '/policies',
  [PageType.TrackingTechnologies]: '/trackers',
  [PageType.RequestsProcessedReporting]: '/requests-processed-reporting',
  [PageType.CommunicationsPreferences]: '/communications-preferences',
  [PageType.SaleOfInfo]: '/sale-of-info',
};

/**
 * Lookup from pathname on privacy center to page type
 */
export const PATHNAME_TO_PAGE_TYPE = invert(PAGE_PATHNAMES);

/** Name of file when exporting internationalized messages */
export const EXPORT_MESSAGES_PC_FILE_NAME = 'privacy-center-translations.csv';

/**
 * Override the default validation type for loose messages
 */
export const LOOSE_MESSAGE_SUPPORTS_HTML: {
  [k in PrivacyCenterCustomizableMessageName]?: boolean;
} = {
  [PrivacyCenterCustomizableMessageName.AboutTranscendDescription]: true,
  [PrivacyCenterCustomizableMessageName.TakeControlDescription]: true,
  [PrivacyCenterCustomizableMessageName.TrackingTechnologiesInfo]: true,
};
export const MESSAGE_TYPE_SUPPORTS_HTML: IntlMessageType[] = [
  IntlMessageType.TemplateTemplate,
];

export const PRIVACY_CENTER_MODULE_CONTEXT_MAP: Record<
  PrivacyCenterModule,
  PrivacyCenterModuleContext
> = {
  [PrivacyCenterModule.PrivacyCenter]: {
    operationName: 'PrivacyCenter',
    endpoint: endpoints.privacyCenter,
    moduleFilename: 'privacyCenter',
    versionColumnName: 'privacyCenterModule',
    localized: false,
  },
  [PrivacyCenterModule.Policies]: {
    operationName: 'PrivacyCenterPolicies',
    endpoint: endpoints.privacyCenterPolicies,
    moduleFilename: 'privacyCenterPolicies',
    versionColumnName: 'policiesModule',
    localized: true,
  },
  [PrivacyCenterModule.Messages]: {
    operationName: 'PrivacyCenterTranslatedMessages',
    endpoint: endpoints.privacyCenterTranslatedMessages,
    moduleFilename: 'privacyCenterMessages',
    versionColumnName: 'messagesModule',
    localized: true,
  },
  [PrivacyCenterModule.Subjects]: {
    operationName: 'PrivacyCenterSubjects',
    endpoint: endpoints.privacyCenterSubjects,
    moduleFilename: 'privacyCenterSubjects',
    versionColumnName: 'subjectsModule',
    localized: false,
  },
  [PrivacyCenterModule.RequestsProcessedStats]: {
    operationName: 'RequestsProcessedDisclosureStats',
    endpoint: endpoints.requestsProcessedDisclosureStats,
    moduleFilename: 'requestsProcessedDisclosureStats',
    versionColumnName: 'privacyCenterRequestsProcessedStatsModule',
    localized: false,
  },
  [PrivacyCenterModule.Purposes]: {
    operationName: 'PrivacyCenterPurposes',
    endpoint: endpoints.privacyCenterPurposes,
    moduleFilename: 'privacyCenterPurposes',
    versionColumnName: 'purposesModule',
    localized: false,
  },
};
