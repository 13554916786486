import { getEntries } from '@transcend-io/type-utils';
import React from 'react';
import {
  Light as HljsHighlighter,
  PrismLight as PrismHighlighter,
} from 'react-syntax-highlighter';
// Highlight.js languages. Prefer Highlight.js these where possible, since it can work with TipTap editor.
import css from 'react-syntax-highlighter/dist/cjs/languages/hljs/css';
import json from 'react-syntax-highlighter/dist/cjs/languages/hljs/json';
import sql from 'react-syntax-highlighter/dist/cjs/languages/hljs/sql';
import xml from 'react-syntax-highlighter/dist/cjs/languages/hljs/xml';
// Prism languages. Only use these when Highlight.js doesn't do well.
import regex from 'react-syntax-highlighter/dist/cjs/languages/prism/regex';
import tsx from 'react-syntax-highlighter/dist/cjs/languages/prism/tsx';

import { hljsCodeTheme, prismCodeTheme } from './codeTheme';

const hljsLanguages = {
  json,
  sql,
  xml,
  css,
} as const;

const prismLanguages = {
  regex,
  tsx,
} as const;

// Register languages
getEntries(hljsLanguages).forEach(([languageKey, languageImport]) => {
  HljsHighlighter.registerLanguage(languageKey, languageImport);
});
getEntries(prismLanguages).forEach(([languageKey, languageImport]) => {
  PrismHighlighter.registerLanguage(languageKey, languageImport);
});

const languages = { ...hljsLanguages, ...prismLanguages } as const;
const languageAliases = {
  html: 'xml',
  typescript: 'tsx',
  javascript: 'tsx',
  js: 'tsx',
  ts: 'tsx',
} as const;

/**
 * Supported Languages
 */
export type CodeBlockSupportedLanguage =
  | keyof typeof languages
  // Aliases
  | keyof typeof languageAliases
  // No highlight
  | 'text';

/**
 * CodeBlock props
 */
export interface CodeBlockProps {
  /** The snippet of code */
  snippet: string;
  /** The language of the code */
  language: CodeBlockSupportedLanguage;
  /** Whether to wrap lines (default = false) */
  wrap?: boolean;
  /** Custom styles */
  style?: React.CSSProperties;
}

/**
 * Monospaced font styles for code
 */
export const CODE_FONT_STYLE: React.CSSProperties = {
  fontFamily: '"Menlo", monospace',
  fontSize: '0.9em',
};

/**
 * Low-level CodeBlock syntax highlighter.
 * You are responsible for the container and padding
 * ... but you can import `CodeBlockContainer` for a standard container.
 */
export const CodeBlock: React.FC<CodeBlockProps> = ({
  snippet: inputSnippet,
  language: inputLanguage,
  wrap = false,
  style = {
    ...CODE_FONT_STYLE,
    margin: 0,
    padding: 0,
  },
}) => {
  const language = languageAliases[inputLanguage] || inputLanguage;
  const isHljs = Object.hasOwn(hljsLanguages, language);

  const snippet = inputSnippet.trim();

  return isHljs ? (
    <HljsHighlighter
      language={language}
      style={hljsCodeTheme}
      wrapLongLines={wrap}
      customStyle={style}
    >
      {snippet}
    </HljsHighlighter>
  ) : (
    <PrismHighlighter
      language={language}
      style={prismCodeTheme}
      wrapLines={wrap}
      customStyle={style}
    >
      {snippet}
    </PrismHighlighter>
  );
};
