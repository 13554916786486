import { useInlineInput } from '@main/ad-core-components';
import { endpoints } from '@main/code-scanning-types';
import { buildUseMutation } from '@main/core-ui';
import type { ID } from '@main/schema-utils';
import { CodePackageType } from '@transcend-io/privacy-types';
import React from 'react';

import {
  SelectCodePackageType,
  SelectCodePackageTypeProps,
} from '../components';

/** Props for the `InlineCodePackageTypeUpdate` component */
interface InlineCodePackageTypeUpdateProps
  extends Omit<SelectCodePackageTypeProps, 'onChange' | 'onBlur'> {
  /** The ID of the parent data silo for this cell */
  id: ID<'codePackage'>;
  /** The value for the cell */
  value: CodePackageType;
}

const useUpdateCodePackages = buildUseMutation(endpoints.updateCodePackages);

export const InlineCodePackageTypeUpdate: React.FC<
  InlineCodePackageTypeUpdateProps
> = ({ id, value: initialValue, isLoading, ...props }) => {
  const [updateCodePackages, { loading }] = useUpdateCodePackages();
  const { value, setValue, onBlur } = useInlineInput<CodePackageType>({
    initialValue,
    onUpdate: (value) =>
      updateCodePackages({
        variables: {
          input: {
            codePackages: [
              {
                id,
                type: value,
              },
            ],
          },
        },
      }).then(({ data, errors }) => ({
        data: data?.codePackages[0].type,
        errors,
      })),
  });

  return (
    <SelectCodePackageType
      showOutline={false}
      isLoading={loading || isLoading}
      value={value}
      onChange={(value) => setValue(value)}
      onBlur={onBlur}
      {...props}
    />
  );
};
