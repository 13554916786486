/**
 * Tiptap converts > to &gt; in the prompt content. This function converts it back
 * so that the html becomes valid handlebars
 *
 * TODO: https://transcend.height.app/T-30350 - try doing this on save in tap tap
 *
 * @param content - Tiptap html output
 * @returns Handlebars input
 */
export function promptContentToTemplate(content: string): string {
  return content.split('{{&gt;').join('{{>');
}
