import type { ExtendableRoute } from '@main/admin-dashboard/src/routes';
import {
  AdminDashboardAssessmentsPath,
  ASSESSMENTS_PATH_PREFIX,
} from '@main/datamap-types';

import { AssessmentEmailSetLoadable } from './AssessmentEmailSet/Loadable';
import { AssessmentEmailSetsLoadable } from './AssessmentEmailSets/Loadable';
import { AssessmentFormLoadable } from './AssessmentForm/Loadable';
import { AssessmentFormResponseLoadable } from './AssessmentFormResponse/Loadable';
import { AssessmentFormTemplateLoadable } from './AssessmentFormTemplate/Loadable';
import { AssessmentFormTemplatesLoadable } from './AssessmentFormTemplates/Loadable';
import { AssessmentGroupLoadable } from './AssessmentGroup/Loadable';
import { AssessmentGroupsLoadable } from './AssessmentGroups/Loadable';
import { assessmentsPageTitleMessages } from './messages';
import { RiskCategoriesLoadable } from './RiskCategories/Loadable';
import { RiskFrameworksLoadable } from './RiskFrameworks/Loadable';

export const assessmentsBreadcrumb = {
  id: ASSESSMENTS_PATH_PREFIX,
  displayText: assessmentsPageTitleMessages.assessments,
  href: AdminDashboardAssessmentsPath.AssessmentGroups,
};

export const assessmentFormTemplatesBreadcrumbs = [
  assessmentsBreadcrumb,
  {
    id: AdminDashboardAssessmentsPath.AssessmentFormTemplates,
    displayText: assessmentsPageTitleMessages.assessmentFormTemplates,
    href: AdminDashboardAssessmentsPath.AssessmentFormTemplates,
  },
];

export const riskFrameworksBreadcrumbs = [
  assessmentsBreadcrumb,
  {
    id: AdminDashboardAssessmentsPath.RiskFrameworks,
    displayText: assessmentsPageTitleMessages.riskFrameworks,
    href: AdminDashboardAssessmentsPath.RiskFrameworks,
  },
];

export const assessmentRoutes: Record<
  AdminDashboardAssessmentsPath,
  ExtendableRoute
> = {
  [AdminDashboardAssessmentsPath.AssessmentGroups]: {
    path: AdminDashboardAssessmentsPath.AssessmentGroups,
    title: assessmentsPageTitleMessages.assessments,
    component: AssessmentGroupsLoadable,
    breadcrumbs: [assessmentsBreadcrumb],
  },
  [AdminDashboardAssessmentsPath.AssessmentGroup]: {
    path: AdminDashboardAssessmentsPath.AssessmentGroup,
    title: assessmentsPageTitleMessages.respondents,
    component: AssessmentGroupLoadable,
    breadcrumbs: [assessmentsBreadcrumb],
  },
  [AdminDashboardAssessmentsPath.AssessmentForm]: {
    path: AdminDashboardAssessmentsPath.AssessmentForm,
    title: assessmentsPageTitleMessages.assessmentForms,
    component: AssessmentFormLoadable,
    breadcrumbs: [assessmentsBreadcrumb],
  },
  [AdminDashboardAssessmentsPath.AssessmentEmailSets]: {
    path: AdminDashboardAssessmentsPath.AssessmentEmailSets,
    title: assessmentsPageTitleMessages.assessmentEmailSets,
    component: AssessmentEmailSetsLoadable,
    breadcrumbs: [assessmentsBreadcrumb],
  },
  [AdminDashboardAssessmentsPath.AssessmentEmailSet]: {
    path: AdminDashboardAssessmentsPath.AssessmentEmailSet,
    title: assessmentsPageTitleMessages.templates,
    component: AssessmentEmailSetLoadable,
    breadcrumbs: [assessmentsBreadcrumb],
  },
  [AdminDashboardAssessmentsPath.AssessmentFormResponse]: {
    path: AdminDashboardAssessmentsPath.AssessmentFormResponse,
    title: assessmentsPageTitleMessages.assessmentForm,
    component: AssessmentFormResponseLoadable,
    breadcrumbs: [assessmentsBreadcrumb],
  },
  [AdminDashboardAssessmentsPath.AssessmentFormTemplates]: {
    path: AdminDashboardAssessmentsPath.AssessmentFormTemplates,
    title: assessmentsPageTitleMessages.assessmentFormTemplates,
    component: AssessmentFormTemplatesLoadable,
    breadcrumbs: [assessmentsBreadcrumb],
  },
  [AdminDashboardAssessmentsPath.AssessmentFormTemplate]: {
    path: AdminDashboardAssessmentsPath.AssessmentFormTemplate,
    title: assessmentsPageTitleMessages.assessmentFormTemplate,
    component: AssessmentFormTemplateLoadable,
    breadcrumbs: assessmentFormTemplatesBreadcrumbs,
  },
  [AdminDashboardAssessmentsPath.NewAssessmentFormTemplate]: {
    path: AdminDashboardAssessmentsPath.NewAssessmentFormTemplate,
    title: assessmentsPageTitleMessages.newAssessmentFormTemplate,
    component: AssessmentFormTemplateLoadable,
    breadcrumbs: assessmentFormTemplatesBreadcrumbs,
  },
  [AdminDashboardAssessmentsPath.RiskFrameworks]: {
    path: AdminDashboardAssessmentsPath.RiskFrameworks,
    title: assessmentsPageTitleMessages.riskFrameworks,
    component: RiskFrameworksLoadable,
    breadcrumbs: riskFrameworksBreadcrumbs,
  },
  [AdminDashboardAssessmentsPath.RiskCategories]: {
    path: AdminDashboardAssessmentsPath.RiskCategories,
    title: assessmentsPageTitleMessages.riskCategories,
    component: RiskCategoriesLoadable,
    // Fall back values. Should be set on the page dynamically.
    breadcrumbs: riskFrameworksBreadcrumbs,
  },
};
