/* eslint-disable max-lines */
import { DataCategoryType } from '@transcend-io/privacy-types';

import {
  mkInput,
  mkOrder,
  mkType,
  type SchemaToType,
} from '@main/schema-utils';
import {
  DecryptionContext,
  UnstructuredClassificationMethod,
} from '@main/sombra-types';

import { DataSubCategoryPreview } from './dataSubCategory';
import {
  UnstructuredDataSamplingStatus,
  UnstructuredSubDataPointRecommendationOrderField,
  UnstructuredSubDataPointRecommendationStatus,
} from './enums';
import { ScanRunId } from './pluginScan';

export const RecommendationPreview = mkType({
  name: 'RecommendationPreview',
  comment: 'Recommendations of different categories discovered in a given file',
  fields: {
    id: {
      type: 'id',
      modelName: 'unstructuredSubDataPointRecommendation',
      comment: 'Recommendation id',
    },
    name: {
      type: 'string',
      comment: 'Name of the subdatapoint recommendations',
    },
    contextSnippet: {
      type: 'string',
      comment: 'Context Snippet',
      optional: true,
    },
    dataSubCategory: {
      comment:
        'The set of data categories assigned to the sub-datapoints of this datapoint',
      type: DataSubCategoryPreview,
      optional: true,
    },
    confidence: {
      type: 'float',
      comment: 'The confidence score for this recommendation',
      optional: true,
    },
    classificationMethod: {
      type: { UnstructuredClassificationMethod },
      comment:
        'The method that identified this unstructured subDataPoint entity',
      optional: true,
    },
    status: {
      type: { UnstructuredSubDataPointRecommendationStatus },
      comment: 'The status of this recommendation',
      optional: true,
    },
  },
});

/** Override type */
export type RecommendationPreview = SchemaToType<typeof RecommendationPreview>;

export const UnstructuredSubDataPointRecommendation = mkType({
  name: 'UnstructuredSubDataPointRecommendation',
  comment: 'Unstructured Subdatapoint recommendations raw',
  fields: {
    ...RecommendationPreview.fields,
    dataSiloId: {
      type: 'id',
      modelName: 'dataSilo',
      comment: 'Data Silo Id',
    },
    scannedObjectPathId: {
      type: 'id',
      modelName: 'scannedObjectPath',
      comment: 'Scanned Object Path ID',
      optional: true,
    },
    scannedObjectId: {
      type: 'id',
      modelName: 'scannedObject',
      comment: 'Scanned Object ID',
      optional: true,
    },
    scanRunId: {
      type: ScanRunId,
      comment: 'Scan Run Id',
    },
    classifierVersion: {
      type: 'int',
      comment:
        'The version of the classifier that generated the recommendation',
      optional: true,
    },
  },
});

/** Override type */
export type UnstructuredSubDataPointRecommendation = SchemaToType<
  typeof UnstructuredSubDataPointRecommendation
>;

export const UnstructuredSubDataPointRecommendationsFilterInput = mkInput({
  name: 'UnstructuredSubDataPointRecommendationsFilterInput',
  comment: 'Unstructured Subdatapoint recommendations filter input',
  fields: {
    name: {
      type: 'string',
      comment: 'Name of the subdatapoint recommendations',
      optional: true,
    },
    dataSiloId: {
      type: 'id',
      modelName: 'dataSilo',
      comment: 'Data Silo Id',
      optional: true,
    },
    scannedObjectPathId: {
      type: 'id',
      modelName: 'scannedObjectPath',
      comment: 'Scanned Object Path ID',
      optional: true,
    },
    scannedObjectId: {
      type: 'id',
      modelName: 'scannedObject',
      comment: 'Scanned Object ID',
      optional: true,
    },
    dataSubCategoryId: {
      type: 'id',
      modelName: 'dataSubCategory',
      comment: 'Data Sub-Category',
      optional: true,
    },
    status: {
      type: { UnstructuredSubDataPointRecommendationStatus },
      comment: 'The status of this recommendation',
      optional: true,
    },
  },
});

/** Override type */
export type UnstructuredSubDataPointRecommendationsFilterInput = SchemaToType<
  typeof UnstructuredSubDataPointRecommendationsFilterInput
>;

export const UpdateUnstructuredSubDataPointRecommendationInput = mkInput({
  name: 'UpdateUnstructuredSubDataPointRecommendationInput',
  comment:
    'The validated or corrected recommendation for an unstructured subdatapoint',
  fields: {
    unstructuredSubDataPointRecommendationId: {
      comment:
        'The ID of the recommendation being corrected or validated ' +
        '(if corrected, the existing recommendation will be rejected and a new one generated)',
      type: 'id',
      modelName: 'unstructuredSubDataPointRecommendation',
    },
    dataSubCategory: {
      comment: 'The ID of the subcategory to associate with this subdatapoint',
      type: 'id',
      modelName: 'dataSubCategory',
      optional: true,
    },
  },
});

/** Override type */
export type UpdateUnstructuredSubDataPointRecommendationInput = SchemaToType<
  typeof UpdateUnstructuredSubDataPointRecommendationInput
>;

export const UpdateUnstructuredSubDataPointRecommendationsInput = mkInput({
  name: 'UpdateScannedObjectsInput',
  comment: 'Input for bulk updating scanned objects',
  fields: {
    unstructuredSubDataPointRecommendations: {
      comment: 'List of recommendations to validate or correct',
      type: UpdateUnstructuredSubDataPointRecommendationInput,
      list: true,
    },
  },
});

/** Override type */
export type UpdateUnstructuredSubDataPointRecommendationsInput = SchemaToType<
  typeof UpdateUnstructuredSubDataPointRecommendationsInput
>;

/** Order for a vendors query */
export const UnstructuredSubDataPointRecommendationOrder = mkOrder(
  UnstructuredSubDataPointRecommendation.name,
  UnstructuredSubDataPointRecommendationOrderField,
);

/** Override type */
export type UnstructuredSubDataPointRecommendationOrder = SchemaToType<
  typeof UnstructuredSubDataPointRecommendationOrder
>;

export const UnstructuredDataDiscoveryFilesFilterInput = mkInput({
  name: 'UnstructuredDataDiscoveryFilesFilterInput',
  comment: 'Unstructured Subdatapoint recommendations filter input',
  fields: {
    name: {
      type: 'string',
      comment: 'Name of the datapoint',
      optional: true,
    },
    dataSiloId: {
      type: 'id',
      modelName: 'dataSilo',
      comment: 'Data Silo Id',
      optional: true,
    },
    scannedObjectPathId: {
      type: 'id',
      modelName: 'scannedObjectPath',
      comment: 'Scanned Object Path ID',
      optional: true,
    },
    scannedObjectId: {
      type: 'id',
      modelName: 'scannedObject',
      comment: 'Scanned Object ID',
      optional: true,
    },
    samplingStatus: {
      comment: 'The sampling status of the file',
      type: { UnstructuredDataSamplingStatus },
      optional: true,
      list: true,
    },
    dataCategories: {
      comment: 'Filter by categories of personal data',
      type: { DataCategoryType },
      list: true,
      optional: true,
    },
    dataSubCategoryIds: {
      comment: 'Filter by specific subcategories of personal data',
      type: 'id',
      modelName: 'dataSubCategory',
      optional: true,
      list: true,
    },
    status: {
      type: { UnstructuredSubDataPointRecommendationStatus },
      comment: 'The status of this recommendation',
      optional: true,
    },
  },
});

/** Override type */
export type UnstructuredDataDiscoveryFilesFilterInput = SchemaToType<
  typeof UnstructuredDataDiscoveryFilesFilterInput
>;

export const UnstructuredDataDiscoveryFile = mkType({
  name: 'UnstructuredDataDiscoveryFile',
  comment: 'Files found during the unstructured data discovery process',
  interfaces: [],
  fields: {
    id: {
      comment: 'The unique ID of the scanned object',
      modelName: 'scannedObject',
      type: 'id',
    },
    name: {
      comment: 'The identifying name/key of the datapoint',
      type: 'string',
    },
    metadataAsJSONString: {
      comment: 'File related metadata in JSON string format',
      type: 'string',
      optional: true,
    },
    fileType: {
      comment: 'The file type if discovered datapoint is file',
      type: 'string',
      optional: true,
    },
    samplingStatus: {
      comment: 'The sampling status of the file',
      type: { UnstructuredDataSamplingStatus },
      optional: true,
    },
    recommendations: {
      comment: 'The recommendations associated with this file',
      type: RecommendationPreview,
      list: true,
      optional: true,
      deprecated: 'Use the categories instead',
    },
    categories: {
      comment: 'The categories associated with this file',
      type: DataSubCategoryPreview,
      list: true,
    },
    error: {
      comment: 'Error message if the sampling status is error',
      type: 'string',
      optional: true,
    },
  },
});

/** Override type */
export type UnstructuredDataDiscoveryFile = SchemaToType<
  typeof UnstructuredDataDiscoveryFile
>;

export const UnwrapFileSnippetFiltersInput = mkInput({
  name: 'UnwrapFileSnippetFiltersInput',
  comment:
    'Input for unwrapping a set of file snippets that resulted in a recommendation',
  fields: {
    recommendationIds: {
      comment: 'The list of recommendations to fetch file snippets for',
      type: 'id',
      modelName: 'unstructuredSubDataPointRecommendation',
      list: true,
    },
  },
});

/** Override type */
export type UnwrapFileSnippetFiltersInput = SchemaToType<
  typeof UnwrapFileSnippetFiltersInput
>;

export const FileSnippetDecryptionContext = mkType({
  name: 'FileSnippetDecryptionContext',
  comment: 'Response after unwrapping a set of file snippets',
  fields: {
    id: {
      comment: 'The recommendation ID',
      type: 'id',
      modelName: 'unstructuredSubDataPointRecommendation',
    },
    decryptionContext: {
      comment: 'The context needed to decrypt the sample in the browser',
      type: DecryptionContext,
      optional: true,
    },
  },
});

/** Override type */
export type FileSnippetDecryptionContext = SchemaToType<
  typeof FileSnippetDecryptionContext
>;
/* eslint-enable max-lines */
