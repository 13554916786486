import {
  LargeLanguageModelClient,
  PROMPT_FILE_PURPOSE_TO_OPEN_AI,
} from '@transcend-io/privacy-types';
import { invert } from '@transcend-io/type-utils';

import {
  CLAUDE_MODELS,
  LargeLanguageModelInput,
  LLAMA_MODELS,
  OPENAI_MODELS,
} from '@main/sombra-types';

/**
 * The default large language models
 */
export const DEFAULT_LARGE_LANGUAGE_MODELS: LargeLanguageModelInput[] = [
  ...Object.entries(OPENAI_MODELS).map(
    ([
      name,
      { description, contextLength, inputTokenPrice, outputTokenPrice },
    ]) => ({
      client: LargeLanguageModelClient.OpenAi,
      name,
      description,
      contextLength,
      inputTokenPrice,
      outputTokenPrice,
      isTranscendHosted: false,
    }),
  ),
  ...Object.entries(CLAUDE_MODELS).map(
    ([
      name,
      { description, contextLength, inputTokenPrice, outputTokenPrice },
    ]) => ({
      client: LargeLanguageModelClient.Claude,
      name,
      description,
      contextLength,
      inputTokenPrice,
      outputTokenPrice,
      isTranscendHosted: true,
    }),
  ),
  ...Object.entries(CLAUDE_MODELS).map(
    ([
      name,
      { description, contextLength, inputTokenPrice, outputTokenPrice },
    ]) => ({
      client: LargeLanguageModelClient.Claude,
      name,
      description,
      contextLength,
      inputTokenPrice,
      outputTokenPrice,
      isTranscendHosted: false,
    }),
  ),
  ...Object.entries(LLAMA_MODELS).map(
    ([
      name,
      { description, contextLength, inputTokenPrice, outputTokenPrice },
    ]) => ({
      client: LargeLanguageModelClient.Llama,
      name,
      description,
      contextLength,
      inputTokenPrice,
      outputTokenPrice,
      isTranscendHosted: true,
    }),
  ),
  ...Object.entries(LLAMA_MODELS).map(
    ([
      name,
      { description, contextLength, inputTokenPrice, outputTokenPrice },
    ]) => ({
      client: LargeLanguageModelClient.Llama,
      name,
      description,
      contextLength,
      inputTokenPrice,
      outputTokenPrice,
      isTranscendHosted: false,
    }),
  ),
];

export const OPEN_AI_PURPOSE_TO_PROMPT_FILE_PURPOSE = invert(
  PROMPT_FILE_PURPOSE_TO_OPEN_AI,
);
