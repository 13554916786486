import {
  FilterManager,
  FiltersConfig,
  InlineTextInput,
  SelectedTeam,
  SelectedUser,
} from '@main/ad-core-components';
import {
  useRepositoryFilters,
  useSoftwareDevelopmentKitFilters,
  useTeamFilters,
  useUserFilters,
} from '@main/admin-dashboard/src/hooks';
import { CodePackageFiltersInput } from '@main/code-scanning-types';
import pickBy from 'lodash/pickBy';
import React, { useMemo, useRef } from 'react';
import { useIntl } from 'react-intl';

import { codePackagesColumnHeaderMessages } from '../../CodePackages/messages';
import { SelectedRepository } from '../SelectRepositories';
import { SelectedSoftwareDevelopmentKit } from '../SelectSoftwareDevelopmentKits';
import { codePackageFilterMessages } from './messages';

/**
 * Filters we support for code packages
 */
// eslint-disable-next-line @typescript-eslint/consistent-type-definitions
type SupportedFilters = {
  /** Text to search the code packages by */
  text?: string;
  /** Text to search on name only */
  name?: string;
  /** Assigned owner */
  owners?: SelectedUser[];
  /** Assigned teams */
  teams?: SelectedTeam[];
  /** Repositories */
  codePackage?: SelectedRepository[];
  /** SDKs in code package */
  softwareDevelopmentKits?: SelectedSoftwareDevelopmentKit[];
};

interface CodePackageFilterProps {
  /** The currently applied filters */
  filters?: CodePackageFiltersInput;
  /** Callback when the filters are changed */
  setFilters: (filters: CodePackageFiltersInput) => void;
}

export const CodePackageFilter: React.FC<CodePackageFilterProps> = ({
  filters,
  setFilters,
}) => {
  const { formatMessage } = useIntl();

  const {
    selectedUsers: selectedOwners,
    userFiltersConfig: ownerFiltersConfig,
    clearUserFilters: clearOwnerFilters,
  } = useUserFilters({
    userIdsFilterKey: 'ownerIds',
    filters,
    setFilters,
    label: codePackagesColumnHeaderMessages.owners,
    enrichedUserKey: 'owners',
  });

  const { selectedTeams, teamFiltersConfig, clearTeamFilters } = useTeamFilters(
    {
      filters,
      setFilters,
      label: codePackagesColumnHeaderMessages.teams,
    },
  );

  const {
    selectedSoftwareDevelopmentKits,
    softwareDevelopmentKitFiltersConfig,
    clearSoftwareDevelopmentKitFilters,
  } = useSoftwareDevelopmentKitFilters({
    filters,
    setFilters,
    label: codePackagesColumnHeaderMessages.softwareDevelopmentKits,
  });

  const {
    selectedRepositories,
    repositoryFiltersConfig,
    clearRepositoryFilters,
  } = useRepositoryFilters({
    filters,
    setFilters,
    label: codePackagesColumnHeaderMessages.repository,
  });

  const nameInputRef = useRef<HTMLInputElement>(null);

  const filterConfig: FiltersConfig<SupportedFilters> = [
    teamFiltersConfig as any,
    ownerFiltersConfig as any,
    softwareDevelopmentKitFiltersConfig as any,
    repositoryFiltersConfig as any,
    {
      filterKey: 'name',
      label: codePackagesColumnHeaderMessages.name,
      pillOptions: {
        label: ({ filterValues: { name } }) => name,
      },
      filter: (
        <InlineTextInput
          ref={nameInputRef}
          name="search"
          style={{
            background: 'none',
            border: 0,
            boxShadow: 'none',
            minWidth: 20,
            width: 'auto',
          }}
          placeholder={formatMessage(codePackagesColumnHeaderMessages.name)}
          aria-label={formatMessage(codePackagesColumnHeaderMessages.name)}
          onChange={({ target: { value: newValue } }) => {
            setFilters({
              ...filters,
              name: newValue,
            });
          }}
        />
      ),
    },
  ];

  // Map the basic filters from the query to the enriched values for the filter manager
  const enrichedFilters = useMemo<SupportedFilters>(
    () =>
      pickBy(
        {
          ...filters,
          teams: selectedTeams,
          softwareDevelopmentKits: selectedSoftwareDevelopmentKits,
          owners: selectedOwners,
          codePackage: selectedRepositories,
        },
        (v) => (Array.isArray(v) ? v.length > 0 : v !== undefined),
      ),
    [
      filters,
      selectedTeams,
      selectedOwners,
      selectedSoftwareDevelopmentKits,
      selectedRepositories,
    ],
  );

  return (
    <FilterManager
      id="code-package-filter"
      defaultSearchKey="text"
      filtersValues={enrichedFilters}
      filtersConfig={filterConfig}
      placeholder={codePackageFilterMessages.textLabel}
      onChange={(key, value) =>
        setFilters({
          ...filters,
          [key]: value,
        })
      }
      clearFilter={(key) => {
        clearRepositoryFilters(key);
        clearSoftwareDevelopmentKitFilters(key);
        clearOwnerFilters(key);
        clearTeamFilters(key);
        setFilters({
          ...filters,
          [key]: undefined,
        });
      }}
    />
  );
};
