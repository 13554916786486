import {
  mkMutation,
  mkQuery,
  PAGINATION_INPUT_PARAMS,
} from '@main/schema-utils';

import {
  Contract,
  ContractFiltersInput,
  ContractOrder,
  ContractScan,
  ContractScanFiltersInput,
  ContractScanOrder,
  CreateContractInput,
  CreateContractScanInput,
  DeleteContractScansInput,
  DeleteContractsInput,
  UpdateContractScansInput,
  UpdateContractsInput,
} from './schema';

export const contracts = mkQuery({
  name: 'contracts',
  comment: 'List of contracts registered in the instance',
  params: {
    ...PAGINATION_INPUT_PARAMS,
    filterBy: ContractFiltersInput.optional(),
    orderBy: ContractOrder,
  },
  response: {
    nodes: Contract.list(),
    totalCount: 'int',
  },
});

export const updateContracts = mkMutation({
  name: 'updateContracts',
  comment: 'Updates a set of contracts',
  params: {
    input: UpdateContractsInput,
  },
  response: { contracts: Contract.list() },
});

export const createContract = mkMutation({
  name: 'createContract',
  comment: 'Creates a new contract',
  params: {
    input: CreateContractInput,
  },
  response: { contract: Contract },
});

export const deleteContracts = mkMutation({
  name: 'deleteContracts',
  comment: 'Delete multiple contracts from an organization',
  params: {
    input: DeleteContractsInput,
  },
  response: { success: 'boolean' },
});

export const createContractScan = mkMutation({
  name: 'createContractScan',
  comment: 'Creates a new contract scan',
  params: {
    input: CreateContractScanInput,
  },
  response: { contractScan: ContractScan },
});

export const contractScans = mkQuery({
  name: 'contractScans',
  comment: 'The instances of scanning contracts',
  params: {
    ...PAGINATION_INPUT_PARAMS,
    filterBy: ContractScanFiltersInput.optional(),
    orderBy: ContractScanOrder,
  },
  response: {
    nodes: ContractScan.list(),
    totalCount: 'int',
  },
});

export const deleteContractScans = mkMutation({
  name: 'deleteContractScans',
  comment: 'Delete multiple contract scans from an organization',
  params: {
    input: DeleteContractScansInput,
  },
  response: { success: 'boolean' },
});

export const updateContractScans = mkMutation({
  name: 'updateContractScans',
  comment: 'Updates a set of contract scans',
  params: {
    input: UpdateContractScansInput,
  },
  response: { contractScans: ContractScan.list() },
});
