import { defineMessages } from '@transcend-io/internationalization';

export const ropaSelectPurposeMessages = defineMessages(
  'admin-dashboard.DataMap.components.SelectPurpose.ropaSelectPurpose',
  {
    placeholder: {
      defaultMessage: 'Click to select purpose',
    },
  },
);
