import { RequestAction } from '@transcend-io/privacy-types';

import { RegionInput } from '@main/datamap-types';
import { mkInput, SchemaToType } from '@main/schema-utils';

export const EmailLoginInput = mkInput({
  name: 'EmailLoginInput',
  comment: 'Input to make a login attempt into a privacy center',
  fields: {
    email: {
      comment: 'The email of the person requesting the login',
      type: 'string',
    },
    action: {
      comment: 'The action the user clicked into prior to email login flow',
      optional: true,
      type: { RequestAction },
    },
    dataSubjectId: {
      comment: 'The data subject ID that is being logged in as',
      modelName: 'subject',
      type: 'id',
    },
    path: {
      comment: 'The path the user was on when they clicked the login button',
      type: 'string',
      optional: true,
    },
    region: {
      comment: 'The region that the request was submitted in',
      type: RegionInput,
      optional: true,
    },
  },
});

/** Override type */
export type EmailLoginInput = SchemaToType<typeof EmailLoginInput>;
