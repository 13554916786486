import styled from 'styled-components';

import { Button } from '../Button';

export const StyledTitleBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const StyledButton = styled(Button)`
  color: ${({ theme }) => theme.colors.transcend2};

  &:hover,
  &:focus {
    text-decoration: none;
  }
`;

export const StyledIcon = styled.img`
  height: 36px;
  margin-right: 8px;
`;

export const StyledText = styled.h1`
  color: ${({ theme }) => theme.colors.transcendNavy};
  font-weight: 700;
  margin: 0;
`;
