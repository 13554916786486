import { makeEnum } from '@transcend-io/type-utils';

/** The approval status of the category recommendation for a scanned object */
export const UnstructuredSubDataPointRecommendationStatus = makeEnum({
  /** The category was manually applied */
  ManuallyAdded: 'MANUALLY_ADDED',
  /** The recommendation has been corrected */
  Corrected: 'CORRECTED',
  /** The recommendation has been approved as valid */
  Validated: 'VALIDATED',
  /** The recommendation is has been made but not validated */
  Classified: 'CLASSIFIED',
  /** The recommendation has been marked as wrong */
  Rejected: 'REJECTED',
});

/** Type override */
export type UnstructuredSubDataPointRecommendationStatus =
  (typeof UnstructuredSubDataPointRecommendationStatus)[keyof typeof UnstructuredSubDataPointRecommendationStatus];

/**
 * Order-by for Unstructured Subdatapoint recommendations.
 */
export enum UnstructuredSubDataPointRecommendationOrderField {
  /** Name. */
  Name = 'name',
}
