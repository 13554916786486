import { SelectedUser, useInlineInput } from '@main/ad-core-components';
import { buildUseMutation } from '@main/core-ui';
import { endpoints } from '@main/datamap-types';
import { ID } from '@main/schema-utils';
import React from 'react';

import { buildSelectUser } from './buildSelectUser';

const useUpdateDataPointLevel = buildUseMutation(
  endpoints.updateDataPointLevel,
);

/** Props for the `InlineSelectDataPointLevelOwners` component */
interface InlineSelectDataPointLevelOwnersProps {
  /** The ID of the parent data point for this cell */
  id: ID<'dataPointLevel'>;
  /** The value for the cell */
  value: SelectedUser[];
}

export const InlineSelectDataPointLevelOwners: React.FC<
  InlineSelectDataPointLevelOwnersProps
> = ({ id, value: initialValue }) => {
  const [updateDataPointLevel, { loading }] = useUpdateDataPointLevel();

  return buildSelectUser(
    loading,
    useInlineInput<SelectedUser[]>({
      initialValue,
      optionsComparator: (existing, updated) =>
        JSON.stringify(existing.map(({ id }) => id).sort()) ===
        JSON.stringify(updated.map(({ id }) => id).sort()),
      onUpdate: (values) =>
        updateDataPointLevel({
          variables: {
            input: {
              id,
              ownerIds: values
                .filter((option) => !option.isNew)
                .map((option) => (option as any).id),
            },
          },
        }).then(({ data, errors }) => ({
          data: data?.dataPointHierarchy?.owners,
          errors,
        })),
    }),
  );
};
