export const objectToGqlString = (obj: Record<string, unknown>): string =>
  Object.entries(obj).reduce((acc, [key, value]) => {
    if (value === null) {
      return `
      ${acc}
      ${key}
      `;
    }
    if (typeof value === 'object') {
      return `
      ${acc}
      ${key} { ${objectToGqlString(value as Record<string, unknown>)} }`;
    }
    return `${acc} ${key}`;
  }, '');
