import { DefinedMessage } from '@main/internationalization';
import { mkOrder, mkType, SchemaToType } from '@main/schema-utils';

import { AssetFileOrderField } from './enums';

export const AssetFile = mkType({
  name: 'AssetFile',
  comment:
    'An asset file uploaded by an organization to be displayed on their privacy center',
  fields: {
    key: {
      comment: 'The key where the file is stored',
      type: 'string',
    },
    size: {
      comment: 'The file size in bytes',
      type: 'int',
    },
    mimetype: {
      comment: 'The mimetype of the file',
      type: 'string',
    },
    id: {
      comment: 'The unique id of the asset file',
      modelName: 'assetFile',
      type: 'id',
    },
    src: {
      comment: 'The public url where the asset file can be downloaded',
      type: 'string',
    },
    alt: {
      comment: 'The alternate text for this asset file',
      optional: true,
      type: DefinedMessage,
    },
  },
});

/** Override type */
export type AssetFile = SchemaToType<typeof AssetFile>;

/**
 * Order for a assetFile query
 */
export const AssetFileOrder = mkOrder(AssetFile.name, AssetFileOrderField);

/** Override type */
export type AssetFileOrder = SchemaToType<typeof AssetFileOrder>;
