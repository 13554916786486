import { FilterConfigItem } from '@main/ad-core-components';
import { endpoints } from '@main/cm-types';
import { buildUseLazyQuery } from '@main/core-ui';
import { ID } from '@main/schema-utils';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { MessageDescriptor } from 'react-intl';

import {
  SelectedTcfPurpose,
  SelectTcfPurposes,
} from '../../ConsentManager/Tcf/TcfStacks/components/SelectTcfPurpose';
import { TcfDoubleTag } from '../../ConsentManager/Tcf/TcfStacks/components/TcfDoubleTag';

/**
 * Filters definition with property for TCF purposes specified
 */
type TcfPurposeEnrichedFilters = Record<string, unknown> &
  Record<
    /**
     * TCF Purpose that are currently filtering the data
     */
    string,
    SelectedTcfPurpose[] | undefined
  >;

const useLazyTcfPurposes = buildUseLazyQuery(
  endpoints.orgTcfPurposes,
  endpoints.orgTcfPurposes.name,
  { nodes: { tcfPurpose: { id: null, name: null } } },
);

export const useTcfPurposeFilters = <
  T extends Record<string, unknown>,
  E extends TcfPurposeEnrichedFilters,
>({
  tcfPurposeIdsFilterKey = 'purposeIds',
  filters,
  setFilters,
  label,
  enrichedTcfPurposeKey = 'purposes',
}: {
  /** The property name of the filter for TCF purpose ids */
  tcfPurposeIdsFilterKey?: keyof T;
  /** The currently applied filters */
  filters?: T;
  /** Callback when the filters are changed */
  setFilters: (filters: T) => void;
  /** Label for the filter menu */
  label: string | MessageDescriptor;
  /** The key to use if more than one useTcfPurposeFilters is used in a FilterManager */
  enrichedTcfPurposeKey?: keyof E;
}): {
  /**  The tcf purpose keys enabled on this table */
  selectedTcfPurposes: SelectedTcfPurpose[];
  /** The filter configuration to be passed to the filter manager */
  tcfPurposeFiltersConfig: FilterConfigItem<E>;
  /** Callback for when the filter is cleared in the filter manager */
  clearTcfPurposeFilters: (key: Extract<keyof E, string>) => void;
} => {
  const getSelectedTcfPurposes = useLazyTcfPurposes();
  const [selectedTcfPurposes, setSelectedTcfPurposes] = useState<
    SelectedTcfPurpose[]
  >([]);

  // Populate initial complex filter values
  useEffect(() => {
    if (filters) {
      const tcfPurposeIds = (filters[tcfPurposeIdsFilterKey] ??
        []) as ID<'tcfPurpose'>[];

      if (tcfPurposeIds.length > 0 && selectedTcfPurposes.length === 0) {
        getSelectedTcfPurposes({
          input: {},
          filterBy: {
            tcfPurposeIds,
          },
        }).then(({ data }) => {
          setSelectedTcfPurposes(
            data.nodes.map(({ tcfPurpose }) => tcfPurpose),
          );
        });
      } else {
        setSelectedTcfPurposes([]);
      }
    }
  }, []);

  const tcfPurposeFiltersConfig = useMemo(
    () =>
      ({
        filterKey: enrichedTcfPurposeKey,
        label,
        renderPill: ({
          filterValues: { [enrichedTcfPurposeKey]: purposes = [] },
          index = 0,
        }) => purposes[index] && <TcfDoubleTag option={purposes[index]} />,
        filter: (
          <SelectTcfPurposes
            value={selectedTcfPurposes}
            menuPosition="absolute"
            onChange={(tcfPurposes) => {
              setSelectedTcfPurposes(tcfPurposes);
              setFilters({
                ...filters,
                [tcfPurposeIdsFilterKey]: tcfPurposes.map(({ id }) => id),
              } as T);
            }}
          />
        ),
      }) as FilterConfigItem<TcfPurposeEnrichedFilters>,
    [
      enrichedTcfPurposeKey,
      label,
      selectedTcfPurposes,
      filters,
      tcfPurposeIdsFilterKey,
    ],
  );

  const clearTcfPurposeFilters = useCallback(
    (key) => {
      if (key === enrichedTcfPurposeKey) {
        setFilters({
          ...filters,
          [tcfPurposeIdsFilterKey]: [],
        } as T);
        setSelectedTcfPurposes([]);
      }
    },
    [enrichedTcfPurposeKey, filters, tcfPurposeIdsFilterKey],
  );

  return {
    selectedTcfPurposes,
    tcfPurposeFiltersConfig,
    clearTcfPurposeFilters,
  };
};
