import {
  FormItemWrapper,
  IFormInputValidatedProps,
  IFormItemWrapperProps,
  multipleValidators,
} from '@main/core-ui';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { SelectAssignees } from './SelectAssignees';

export const FormSelectAssignees: React.FC<
  IFormInputValidatedProps &
    Omit<IFormItemWrapperProps, 'errors'> & {
      /** Override is=false to select 1 user */
      isMulti?: boolean;
    }
> = ({
  name,
  label,
  rules,
  info,
  isMulti = true,
  errorDisplay,
  required,
  ...rawInputProps
}) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <FormItemWrapper
      name={name}
      label={label}
      info={info}
      errors={errors}
      errorDisplay={errorDisplay}
      required={required}
    >
      <Controller
        name={name}
        control={control}
        rules={
          rules
            ? { validate: multipleValidators(rules) }
            : required
              ? { required: true }
              : undefined
        }
        render={({ field: { ref: {} = {}, ...props } }) => (
          <SelectAssignees isMulti={isMulti} {...rawInputProps} {...props} />
        )}
      />
    </FormItemWrapper>
  );
};
