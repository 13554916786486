import { FilterConfigItem } from '@main/ad-core-components';
import { buildUseLazyQuery, message } from '@main/core-ui';
import { endpoints } from '@main/datamap-types';
import { ID } from '@main/schema-utils';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { MessageDescriptor } from 'react-intl';

import {
  SelectDataPointLevelFilter,
  SelectedDataPointLevel,
} from '../../DataMap/components';

/**
 * Filters definition with property for data point levels specified
 */
type DataPointLevelEnrichedFilters = Record<
  string,
  SelectedDataPointLevel[] | undefined
>;

/**
 * Hook to get the filter configuration for data point levels
 */
type DataPointLevelFiltersBuilder<
  T extends Record<string, unknown>,
  E extends DataPointLevelEnrichedFilters,
> = (filterParams: {
  /** Name for the property that filters on the name of the data point level */
  dataPointLevelIdsFilterKey?: keyof T;
  /** The currently applied filters */
  filters?: T;
  /** Callback when the filters are changed */
  setFilters: (filters: T) => void;
  /** Label for the filter menu */
  label: string | MessageDescriptor;
  /** The key to use if more than one useDataPointLevelFilters is used in a FilterManager */
  enrichedDataPointLevelKey?: keyof E;
  /** Data Silo ID for query */
  dataSiloId: ID<'dataSilo'>;
}) => {
  /** The data point level enabled on this table */
  selectedDataPointLevels: SelectedDataPointLevel[];
  /** The filter configuration to be passed to the filter manager */
  dataPointLevelFiltersConfig: FilterConfigItem<E>;
  /** Callback for when the filter is cleared in the filter manager */
  clearDataPointLevelFilters: (key: Extract<keyof E, string>) => void;
};

const useLazyDataPointHierarchy = buildUseLazyQuery(
  endpoints.dataPointHierarchy,
  'LazyDataPointHierarchyFilter',
  {
    nodes: {
      id: null,
      path: null,
      name: null,
    },
    totalCount: null,
  },
);

export const useDataPointLevelFilters: DataPointLevelFiltersBuilder<
  Record<string, unknown>,
  DataPointLevelEnrichedFilters
> = ({
  dataPointLevelIdsFilterKey = 'dataPointLevelIds',
  filters,
  dataSiloId,
  setFilters,
  label,
}) => {
  const getSelectedDataPointHierarchy = useLazyDataPointHierarchy();
  const [selectedDataPointLevels, setSelectedDataPointLevels] = useState<
    SelectedDataPointLevel[]
  >([]);
  const [fetching, setFetching] = useState(false);
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    if (filters) {
      const dataPointLevelIds = (filters[dataPointLevelIdsFilterKey] ||
        []) as ID<'dataPointLevel'>[];
      const filtersAndEnricherdFiltersMatch =
        dataPointLevelIds.sort().join() ===
        selectedDataPointLevels
          .map(({ id }) => id)
          .sort()
          .join();

      if (
        dataPointLevelIds.length === 0 &&
        selectedDataPointLevels.length > 0
      ) {
        setSelectedDataPointLevels([]);
      } else if (!filtersAndEnricherdFiltersMatch && !fetching && !hasError) {
        setFetching(true);
        getSelectedDataPointHierarchy({
          filterBy: {
            dataPointLevelIds,
            dataSiloId,
            attributeValueIds: [],
          },
        })
          .then(({ data }) => {
            setSelectedDataPointLevels([
              ...(data.nodes || []),
              ...selectedDataPointLevels,
            ]);
          })
          .catch((err) => {
            setHasError(true);
            message.error(err.message);
          })
          .finally(() => {
            setFetching(false);
          });
      }
    }
  }, [
    dataPointLevelIdsFilterKey,
    dataSiloId,
    fetching,
    filters,
    getSelectedDataPointHierarchy,
    hasError,
    selectedDataPointLevels,
  ]);

  const dataPointLevelFiltersConfig: FilterConfigItem<DataPointLevelEnrichedFilters> =
    useMemo(
      () => ({
        filterKey: 'dataPointLevels',
        label,
        pillOptions: {
          label: ({ filterValues: { dataPointLevels = [] }, index = 0 }) =>
            dataPointLevels[index] && dataPointLevels[index].path
              ? [
                  ...dataPointLevels[index].path!,
                  dataPointLevels[index].name,
                ].join('.')
              : dataPointLevels[index]?.name,
        },
        filter: (
          <SelectDataPointLevelFilter
            value={selectedDataPointLevels}
            onChange={(selections = []) => {
              setSelectedDataPointLevels(selections);
              setFilters({
                ...filters,
                [dataPointLevelIdsFilterKey]: selections.map(({ id }) => id),
              });
            }}
            dataSiloId={dataSiloId}
          />
        ),
      }),
      [
        dataPointLevelIdsFilterKey,
        dataSiloId,
        filters,
        label,
        selectedDataPointLevels,
        setFilters,
      ],
    );

  const clearDataPointLevelFilters = useCallback(() => {
    setSelectedDataPointLevels([]);
    setFilters({
      ...filters,
      [dataPointLevelIdsFilterKey]: [],
    });
  }, [dataPointLevelIdsFilterKey, filters, setFilters]);

  return {
    selectedDataPointLevels,
    dataPointLevelFiltersConfig,
    clearDataPointLevelFilters,
  };
};
