import { mkType, optionalizeFields, SchemaToType } from '@main/schema-utils';

import { ConsentService } from './consentService';
import { TCFVendor } from './tcfVendor';

const optionalizedTcfVendorFields = optionalizeFields<
  (typeof TCFVendor)['fields']
>(TCFVendor.fields);
// Cut out fields that are added/transformed in the resolver
const resolvedFields = [
  'purposes',
  'specialPurposes',
  'legIntPurposes',
  'flexiblePurposes',
  'features',
  'specialFeatures',
  'dataDeclaration',
  'urls',
  'overflow',
  'dataRetention',
];
resolvedFields.forEach((rf) => delete optionalizedTcfVendorFields[rf]);

export const BundleTcfVendor = mkType({
  name: 'BundleTcfVendor',
  comment:
    // eslint-disable-next-line max-len
    'A rolled up record consisting of all consent services corresponding a given bundle and TCF vendor id, union all non-TCF vendor consent services.',
  fields: {
    ...optionalizedTcfVendorFields,
    mergedName: {
      comment:
        'The TCF vendor name or catalog name of the associated consent services',
      type: 'string',
    },
    isIab: {
      comment:
        'Whether or not this is a record of one or more rolled up TCF vendor consent services.',
      type: 'boolean',
    },
    consentServices: {
      comment: "The bundle's consent services this vendor is associated with",
      type: ConsentService,
      list: true,
    },
  },
});

/** Override type */
export type BundleTcfVendor = SchemaToType<typeof BundleTcfVendor>;
