import { useFormatMessageGeneric } from '@main/core-ui';
import { DefinedMessage } from '@transcend-io/internationalization';
import React, { useCallback, useMemo } from 'react';

import { FilterPopoverButton } from '../Button';
import { CheckboxUnit, FiltersSection, StyledParagraph } from './wrappers';

export interface FilterOption {
  /** The label for this option */
  label: string;
  /** The underlying value for this option */
  value: string;
  /** Whether it is selected */
  selected: boolean;
}

export interface FilterSection {
  /** The title of this filter section */
  title: DefinedMessage | string;
  /** The options for this filter */
  options: FilterOption[];
}

export interface TableFilterProps {
  /** The state of the filters */
  filters: FilterSection[];
  /** Callback when the filter selections have changed */
  onChange: (filters: FilterSection[]) => void;
}

export const TableFilter: React.FC<TableFilterProps> = ({
  filters,
  onChange,
}) => {
  const { formatMessageGeneric } = useFormatMessageGeneric();
  const handleOptionSelect = useCallback(
    (filterSectionIdx: number, optionValue: string, selected: boolean) => {
      const updatedFilters = filters.map((filterSection, idx) => ({
        ...filterSection,
        options:
          idx === filterSectionIdx
            ? filterSection.options.map((option) => ({
                ...option,
                selected:
                  option.value === optionValue ? selected : option.selected,
              }))
            : filterSection.options,
      }));
      return onChange(updatedFilters);
    },
    [filters, onChange],
  );
  const filtersCount = useMemo(
    () =>
      filters.reduce((acc, filterSection) => {
        const optionsSelected = filterSection.options.reduce(
          (acc2, option) => (option.selected ? acc2 + 1 : acc2),
          0,
        );
        return optionsSelected + acc;
      }, 0),
    [filters],
  );
  const filterSections = filters.map((filterSection, idx) => (
    <FiltersSection key={idx}>
      <StyledParagraph>
        {formatMessageGeneric(filterSection.title)}
      </StyledParagraph>
      {filterSection.options.map((option) => (
        <CheckboxUnit key={option.value}>
          <input
            type="checkbox"
            name={option.value}
            checked={option.selected}
            onChange={(e) => {
              handleOptionSelect(idx, option.value, e.target.checked);
            }}
          />
          {option.label}
        </CheckboxUnit>
      ))}
    </FiltersSection>
  ));

  return (
    <FilterPopoverButton
      placement="bottom-end"
      activeFilterCount={filtersCount}
      showFilterText
      onClear={() => {
        onChange(
          filters.map((filter) => ({
            ...filter,
            options: filter.options.map((option) => ({
              ...option,
              selected: false,
            })),
          })),
        );
      }}
    >
      {filterSections}
    </FilterPopoverButton>
  );
};
