import { defineMessages } from '@transcend-io/internationalization';

export const errorBoundaryMessages = defineMessages(
  'admin-dashboard.Auth.ErrorBoundary.errorBoundary',
  {
    back: {
      defaultMessage: 'Go back',
    },
    header: {
      defaultMessage: 'Something went wrong! Try refreshing the page...',
    },
  },
);
