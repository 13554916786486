import { DefaultTheme } from 'styled-components';

import { DarkColorOption } from '@main/core-ui';
import { Controllership } from '@main/datamap-types';

import { controllershipMessages } from './messages';
import { SelectControllershipOption } from './types';

export const CONTROLLERSHIP_COLOR_NAMES: {
  [key in Controllership]: keyof DarkColorOption;
} = {
  [Controllership.Controller]: 'orange1',
  [Controllership.JointController]: 'blue1',
  [Controllership.Processor]: 'purple1',
};

export const CONTROLLERSHIP_COLORS: (theme: DefaultTheme) => (option: {
  /** The value being selected */
  value: Controllership;
}) => string =
  (theme) =>
  ({ value }) =>
    theme.colors[CONTROLLERSHIP_COLOR_NAMES[value] ?? 'gray1'];

export const controllershipToOption = (
  value: Controllership,
): SelectControllershipOption => ({
  value,
  id: value,
  label: controllershipMessages[value],
});

export const CONTROLLERSHIP_OPTIONS = Object.values(Controllership).map(
  controllershipToOption,
);
