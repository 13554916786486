import { defineMessages } from '@transcend-io/internationalization';

export const rangePercentMessages = defineMessages(
  'ad-core-components.RangePercent.rangePercent',
  {
    below: {
      defaultMessage: '{title} is at most {value} percent',
    },
    above: {
      defaultMessage: '{title} is at least {value} percent',
    },
    is: {
      defaultMessage: '{title} is {value} percent',
    },
    belowOptionLabel: {
      defaultMessage: 'is at most',
    },
    aboveOptionLabel: {
      defaultMessage: 'is at least',
    },
    isOptionLabel: {
      defaultMessage: 'is',
    },
  },
);
