import { FlexColumn, StyleUtils, Tooltip } from '@main/core-ui';
import { Duration } from 'luxon';
import React, { useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';

import {
  DAYS_HOURS_MINUTES_SECOND_FORMAT,
  DEFAULT_REFRESH_INTERVAL,
} from './constants';
import { countUpMessages } from './messages';

/**
 * Props for count up
 */
export interface CountUpProps {
  /** ISO string for the start time */
  startTime?: string;
  /** ISO string for the end time */
  endTime?: string;
  /** Format for counter */
  format?: string;
  /** the interval to update the count */
  updateInterval?: number;
}

export const CountUp: React.FC<CountUpProps> = ({
  format,
  startTime,
  endTime,
  updateInterval,
}) => {
  const [currentDuration, setCurrentDuration] = useState(0);
  const [mountTime] = useState(new Date());
  const timer = useRef<number>();
  const { formatMessage } = useIntl();

  const refreshDuration = (): void => {
    const startTimeOrDefault = startTime ? new Date(startTime) : mountTime;
    const endTimeOrDefault = endTime ? new Date(endTime) : new Date();

    setCurrentDuration(
      endTimeOrDefault.getTime() - startTimeOrDefault.getTime(),
    );
  };

  useEffect(() => {
    if (timer.current) {
      clearInterval(timer.current);
    }
    if (!endTime) {
      timer.current = window.setInterval(
        refreshDuration,
        Math.max(30, updateInterval ?? DEFAULT_REFRESH_INTERVAL),
      );
    } else {
      // only run once
      refreshDuration();
    }
    return () => clearInterval(timer.current);
  }, [updateInterval]);

  return (
    <Tooltip
      title={
        <FlexColumn style={{ gap: StyleUtils.Spacing.md, color: 'inherit' }}>
          <span>
            {formatMessage(countUpMessages.startedAt, {
              date: (startTime
                ? new Date(startTime)
                : mountTime
              ).toLocaleString(),
            })}
          </span>
          {endTime && (
            <span>
              {formatMessage(countUpMessages.endedAt, {
                date: new Date(endTime).toLocaleString(),
              })}
            </span>
          )}
        </FlexColumn>
      }
    >
      {Duration.fromMillis(currentDuration).toFormat(
        format ?? DAYS_HOURS_MINUTES_SECOND_FORMAT,
      )}
    </Tooltip>
  );
};
