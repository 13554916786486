import { FlexColumn, formatErrorMessage, StyleUtils } from '@main/core-ui';
import { ColorPalette } from '@main/theme';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useTheme } from 'styled-components';

import { rowUploadStatusMessages, uploadCsvMessages } from './messages';
import { RowErrorDetails, RowUploadStatus } from './types';
import { StyledFlexRowCenterVertical } from './wrappers';

const CsvRowUploadStatusColors: Record<RowUploadStatus, keyof ColorPalette> = {
  [RowUploadStatus.Error]: 'error',
  [RowUploadStatus.Success]: 'success',
  [RowUploadStatus.Pending]: 'lightPrimary',
};

/**
 * Display the status of the row pending upload
 */
export function PendingRowUploadStatus({
  errorMessage,
  errorDetails,
  uploadStatus,
}: {
  /** name of column */
  uploadStatus: RowUploadStatus;
  /** error message */
  errorMessage?: string;
  /** object containing specific errors for row */
  errorDetails?: RowErrorDetails;
}): JSX.Element {
  const theme = useTheme();

  const { formatMessage } = useIntl();

  const duplicateRowEntries = useMemo(
    () => Object.entries(errorDetails?.duplicateRows ?? {}),
    [errorDetails?.duplicateRows],
  );

  return (
    <StyledFlexRowCenterVertical
      style={{
        color: theme.colors[CsvRowUploadStatusColors[uploadStatus]],
      }}
    >
      {uploadStatus !== RowUploadStatus.Error ? (
        formatMessage(rowUploadStatusMessages[uploadStatus])
      ) : (
        <FlexColumn
          style={{ gap: StyleUtils.Spacing.sm, color: 'inherit' }}
          className="upload-status-errors"
        >
          {(errorDetails?.missingColumns ?? []).length > 0 && (
            <FlexColumn style={{ color: 'inherit' }}>
              <span>{formatMessage(uploadCsvMessages.missingColumns)}</span>
              <ul style={{ margin: 0 }}>
                {errorDetails?.missingColumns.map((col) => (
                  <li key={col.toString()}>{col}</li>
                ))}
              </ul>
            </FlexColumn>
          )}
          {(errorDetails?.invalidInputColumns ?? []).length > 0 && (
            <FlexColumn style={{ color: 'inherit' }}>
              <span>
                {formatMessage(uploadCsvMessages.invalidInputColumns)}
              </span>
              <ul style={{ margin: 0 }}>
                {errorDetails?.invalidInputColumns.map((col) => (
                  <li key={col}>{col}</li>
                ))}
              </ul>
            </FlexColumn>
          )}
          {(errorDetails?.invalidInputColumns ?? []).length === 0 &&
            (errorDetails?.missingColumns ?? []).length === 0 &&
            duplicateRowEntries.length === 0 && (
              <span>
                {errorMessage
                  ? formatErrorMessage(errorMessage)
                  : formatMessage(uploadCsvMessages.failedToUpload)}
              </span>
            )}
          {duplicateRowEntries.length > 0 && (
            <FlexColumn style={{ color: 'inherit' }}>
              <span>
                {formatMessage(uploadCsvMessages.nonUniqueValuesFound)}
              </span>
              <ul style={{ margin: 0 }}>
                {duplicateRowEntries.map(([key, value]) => (
                  <li key={key}>
                    {formatMessage(uploadCsvMessages.nonUniqueRow, {
                      columnName: `'${key}'`,
                      rows: value.join(', '),
                    })}
                  </li>
                ))}
              </ul>
            </FlexColumn>
          )}
        </FlexColumn>
      )}
    </StyledFlexRowCenterVertical>
  );
}
