import {
  CreateActionItemCollectionInput,
  endpoints,
  GlobalActionItemCollection,
} from '@main/access-control-types';
import {
  commonButtonMessages,
  commonFormStatusMessages,
  FormInput,
  FormStatus,
  FormSubmitButton,
  Modal,
  mutationToFormStatusProps,
} from '@main/ad-core-components';
import {
  AUTOCOMPLETE_OFF_PROPS,
  buildUseMutation,
  Form,
  formatErrorMessage,
  message,
  useForm,
  Validators,
} from '@main/core-ui';
import { TranscendProduct } from '@transcend-io/privacy-types';
import React from 'react';
import { useIntl } from 'react-intl';

import { onboardingItemCollectionsMessages } from './messages';

const useCreateActionItemCollection = buildUseMutation(
  endpoints.createActionItemCollection,
);
const useUpdateActionItemCollection = buildUseMutation(
  endpoints.updateActionItemCollection,
);

interface CreateOnboardingCollectionModalProps {
  /** handler on hiding of the modal */
  onHide: () => void;
  /** the product line being rendered */
  productLine: TranscendProduct;
  /** the collection to update, null if create */
  collection?: GlobalActionItemCollection;
}

/**
 * CreateOnboardingCollectionModal
 */
export const CreateOnboardingCollectionModal: React.FC<
  CreateOnboardingCollectionModalProps
> = ({ onHide, productLine, collection }) => {
  const { formatMessage } = useIntl();
  const [createActionItemCollection, createActionItemCollectionResult] =
    useCreateActionItemCollection({
      notifyOnNetworkStatusChange: true,
      refetchQueries: ['GlobalActionItemCollections'],
    });
  const [updateActionItemCollection, updateActionItemCollectionResult] =
    useUpdateActionItemCollection({
      notifyOnNetworkStatusChange: true,
      refetchQueries: ['GlobalActionItemCollections'],
    });
  const formMethods = useForm<Omit<CreateActionItemCollectionInput, 'hidden'>>({
    defaultValues: {
      ...(collection ?? {}),
    },
  });
  return (
    <Modal
      show
      onHide={() => {
        onHide();
        formMethods.reset();
      }}
      header={onboardingItemCollectionsMessages.createCollection}
    >
      <Form
        useFormMethods={formMethods}
        onSubmit={async (values) => {
          try {
            if (collection?.id) {
              await updateActionItemCollection({
                variables: {
                  input: {
                    ...values,
                    hidden: false,
                    productLine,
                    id: collection.id,
                  },
                },
              });
            } else {
              await createActionItemCollection({
                variables: { input: { ...values, productLine, hidden: false } },
              });
            }
            message.success(
              formatMessage(commonFormStatusMessages.createSuccess),
            );
            onHide();
            formMethods.reset();
          } catch (err) {
            message.error(formatErrorMessage(err));
          }
        }}
      >
        <FormInput
          name="title"
          label={onboardingItemCollectionsMessages.titleLabel}
          info={onboardingItemCollectionsMessages.titleDescription}
          required
          {...AUTOCOMPLETE_OFF_PROPS}
          rules={[Validators.REQUIRED]}
        />
        <FormInput
          name="description"
          label={onboardingItemCollectionsMessages.descriptionLabel}
          info={onboardingItemCollectionsMessages.descriptionDescription}
          required
          {...AUTOCOMPLETE_OFF_PROPS}
          rules={[Validators.REQUIRED]}
        />
        <FormSubmitButton
          loading={
            createActionItemCollectionResult.loading ||
            updateActionItemCollectionResult.loading
          }
        >
          {formatMessage(commonButtonMessages.save)}
        </FormSubmitButton>
        <FormStatus
          {...mutationToFormStatusProps(
            collection
              ? updateActionItemCollectionResult
              : createActionItemCollectionResult,
          )}
        />
      </Form>
    </Modal>
  );
};
