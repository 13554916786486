import { defineMessages } from '@transcend-io/internationalization';

export const bulkUpdateModalMessages = defineMessages(
  'admin-dashboard.DataMap.components.BulkUpdateModal.bulkUpdateModal',
  {
    modalButton: {
      defaultMessage: 'Bulk Edits',
    },
    modalHeader: {
      defaultMessage: 'Bulk Edit',
    },
    modalSubHeader: {
      defaultMessage:
        'Any changes you make here will be applied to all currently selected rows.',
    },
    /* TODO: https://transcend.height.app/T-22322 - use confirmNButton */
    confirmButton: {
      defaultMessage: 'Apply changes',
    },
    confirmNButton: {
      defaultMessage:
        'Apply {count} {count, plural, one {change} other {changes}}',
    },
    confirmAll: {
      defaultMessage:
        'Apply updates to all rows matching current filter ({count})',
    },
    success: {
      defaultMessage: 'Updates successfully applied',
    },
  },
);
