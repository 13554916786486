import type { RequestStatus } from '@transcend-io/privacy-types';

import { APPROVING_STATUS } from './approving';
import { CANCELED_STATUS } from './canceled';
import { COMPILING_STATUS } from './compiling';
import { COMPLETED_STATUS } from './completed';
import { DELAYED_STATUS } from './delayed';
import { DOWNLOADABLE_STATUS } from './downloadable';
import { ENRICHING_STATUS } from './enriching';
import { FAILED_VERIFICATION_STATUS } from './failedVerification';
import { ON_HOLD_STATUS } from './onHold';
import { REQUEST_MADE_STATUS } from './requestMade';
import { REVOKED_STATUS } from './revoked';
import { SECONDARY_STATUS } from './secondary';
import { SECONDARY_APPROVING_STATUS } from './secondaryApproving';
import { SECONDARY_COMPLETED_STATUS } from './secondaryCompleted';
import type { RequestStatusConfig } from './types';
import { VIEW_CATEGORIES_STATUS } from './viewCategories';
import { WAITING_STATUS } from './waiting';

/**
 * Configurations for the statuses that a request can take on.
 */
export const REQUEST_STATUSES: {
  [status in RequestStatus]: RequestStatusConfig;
} = {
  REQUEST_MADE: REQUEST_MADE_STATUS,
  FAILED_VERIFICATION: FAILED_VERIFICATION_STATUS,
  ENRICHING: ENRICHING_STATUS,
  ON_HOLD: ON_HOLD_STATUS,
  WAITING: WAITING_STATUS,
  COMPILING: COMPILING_STATUS,
  APPROVING: APPROVING_STATUS,
  DELAYED: DELAYED_STATUS,
  COMPLETED: COMPLETED_STATUS,
  DOWNLOADABLE: DOWNLOADABLE_STATUS,
  VIEW_CATEGORIES: VIEW_CATEGORIES_STATUS,
  CANCELED: CANCELED_STATUS,
  SECONDARY: SECONDARY_STATUS,
  SECONDARY_APPROVING: SECONDARY_APPROVING_STATUS,
  SECONDARY_COMPLETED: SECONDARY_COMPLETED_STATUS,
  REVOKED: REVOKED_STATUS,
};
