import { Purpose } from '@transcend-io/airgap.js-types';
import { defineMessages } from '@transcend-io/internationalization';

import {
  AuditorGQLRegion,
  type AuditorGQLRegionValues,
} from '@main/auditor-types';

export const auditorMessages = defineMessages(
  'admin-dashboard.Auditor.auditor',
  {
    auditor: {
      defaultMessage: 'Web Auditor',
    },
    runs: {
      defaultMessage: 'Runs',
    },
    schedules: {
      defaultMessage: 'Schedules',
    },
    schedule: {
      defaultMessage: 'Schedule',
    },
    run: {
      defaultMessage: 'Run',
    },
    newAuditorRun: {
      defaultMessage: 'New Web Auditor Run',
    },
    noPdfFileKey: {
      defaultMessage: 'Result files are not available for failed runs',
    },
    actions: {
      defaultMessage: 'Actions',
    },
    details: {
      defaultMessage: 'Details',
    },
    browserSteps: {
      defaultMessage: 'Browser Steps',
    },
    recordingOfScan: {
      defaultMessage: 'Recording of scan',
    },
    errorMessage: {
      defaultMessage: 'Error Message',
    },
    noRecording: {
      defaultMessage: 'No recording available',
    },
    newSchedule: {
      defaultMessage: 'New Schedule',
    },
    scanInProgress: {
      defaultMessage: 'Starting an Auditor Run...',
    },
    creatingScheduleInProgress: {
      defaultMessage: 'Creating schedule..',
    },
    runSuccessMessage: {
      defaultMessage: 'Scan successfully completed',
    },
    runErrorMessage: {
      defaultMessage: 'Failed to run Web Auditor',
    },
    createdScheduleSuccessMessage: {
      defaultMessage: 'Schedule successfully created',
    },
    linkFetchFailure: {
      defaultMessage: 'Failed to get link for Web Auditor report',
    },
    tableEmpty: {
      defaultMessage:
        "You haven't ran the Web Auditor yet. Click on the button above to run Web Auditor.",
    },
    scheduleTableEmpty: {
      defaultMessage:
        "You haven't created any Web Auditor schedules yet. Click on the button above to create a schedule..",
    },
    runCount: {
      defaultMessage: '{count} {count, plural, one {Run} other {Runs}}',
    },
    scheduleCount: {
      defaultMessage:
        '{count} {count, plural, one {Schedule} other {Schedules}}',
    },
    runWebAuditor: {
      defaultMessage: 'Run Web Auditor',
    },
    runAuditor: {
      defaultMessage: 'Run Web Auditor',
    },
    rerunAuditor: {
      defaultMessage: 'Create Run with the same settings',
    },
    rerunAuditorButton: {
      defaultMessage: 'Duplicate Run',
    },
    viewRun: {
      defaultMessage: 'View Run',
    },
    runSubmitButton: {
      defaultMessage: 'Run',
    },
    createScheduleButton: {
      defaultMessage: 'Create',
    },
    urlToScanLabel: {
      defaultMessage: 'URL to Scan',
    },
    urlToScanInfo: {
      defaultMessage: 'The URL should start with https://',
    },
    urlToScanPlaceholder: {
      defaultMessage: 'https://www.example.com',
    },
    browserAutomationSteps: {
      defaultMessage: 'Browser Automation Steps',
    },
    addBrowserStep: {
      defaultMessage: 'Add Browser Step',
    },
    useGPCLabel: {
      defaultMessage: 'Use GPC?',
    },
    yes: {
      defaultMessage: 'Yes',
    },
    no: {
      defaultMessage: 'No',
    },
    notApplicable: {
      defaultMessage: 'N/A',
    },
    scheduledInitiator: {
      defaultMessage: 'Scheduled',
    },
    apiKeyInitiator: {
      defaultMessage: 'API Key',
    },
    screenshotAltText: {
      defaultMessage: `Screenshot preview of the {UrlScanned} scan`,
    },
    runScan: {
      defaultMessage: 'Run Scan:',
    },
    filterPlaceholder: {
      defaultMessage: 'Filter Web Auditor runs',
    },
    cookieFilterPlaceholder: {
      defaultMessage: 'Filter cookies',
    },
    findingsFilterPlaceholder: {
      defaultMessage: 'Filter findings',
    },
    dataFlowFilterPlaceholder: {
      defaultMessage: 'Filter data flows',
    },
    deleteSuccess: {
      defaultMessage: 'Deleted successfully',
    },
    confirmDeletePrompt: {
      defaultMessage:
        'Are you sure you want to delete {count, plural, one {this schedule} other {{count} schedules}}?',
    },
    confirmDeleteButton: {
      defaultMessage:
        'Delete {count, plural, one {Schedule} other {{count} Schedules}}',
    },
    schedulesFilterPlaceholder: {
      defaultMessage: 'Filter Web Auditor schedules',
    },
    viewResults: {
      defaultMessage: 'View Results',
    },
    initiator: {
      defaultMessage: 'Created By',
    },
    url: {
      defaultMessage: 'URL',
    },
    colorScheme: {
      defaultMessage: 'Color Scheme',
    },
    region: {
      defaultMessage: 'Region',
    },
    deviceType: {
      defaultMessage: 'Device Type',
    },
    summary: {
      defaultMessage: 'Summary',
    },
    findings: {
      defaultMessage: 'Findings',
    },
    networkRequests: {
      defaultMessage: 'Network Requests',
    },
    cookies: {
      defaultMessage: 'Cookies',
    },
    exportCsv: {
      defaultMessage: 'Export CSV',
    },
    exportHar: {
      defaultMessage: 'Export HAR',
    },
    viewSchedule: {
      defaultMessage: 'View Schedule',
    },
    linkToFindingsDocs: {
      defaultMessage: 'Learn more about findings in our {link}.',
    },
    findingsDocs: {
      defaultMessage: 'documentation',
    },
    duplicateSchedule: {
      defaultMessage: 'Duplicate Schedule',
    },
    failedToLoadSchedule: {
      defaultMessage: 'Failed to load schedule',
    },
    useGPCDescription: {
      defaultMessage: 'Have the auditor use the GPC signal during its scan?',
    },
    regionLabel: {
      defaultMessage: 'Scan Region',
    },
    regionDescription: {
      defaultMessage: 'Region that the auditor conducts its scan from',
    },
  },
);

export const auditorButtonMessages = defineMessages(
  'admin-dashboard.Auditor.auditorButton',
  {},
);

export const purposeGuessesMessages = defineMessages<Purpose>(
  'admin-dashboard.Auditor.purposeGuesses',
  {
    [Purpose.Advertising]: { defaultMessage: 'Advertising' },
    [Purpose.Analytics]: { defaultMessage: 'Analytics' },
    [Purpose.Essential]: { defaultMessage: 'Essential' },
    [Purpose.Functional]: { defaultMessage: 'Functional' },
    [Purpose.Unknown]: { defaultMessage: 'Unknown' },
    [Purpose.SaleOfInfo]: { defaultMessage: 'Sale of Info' },
  },
);

export const regionSelectMessages = defineMessages<AuditorGQLRegionValues>(
  'admin-dashboard.Auditor.regionSelect',
  {
    [AuditorGQLRegion.California]: { defaultMessage: 'US (California)' },
    [AuditorGQLRegion.Ireland]: { defaultMessage: 'EU (Ireland)' },
  },
);
