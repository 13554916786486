import { statusDescriptionMessages, statusTitleMessages } from './messages';
import type { RequestStatusConfig } from './types';

/**
 * ## Secondary Action
 * Some request action types const of a "primary" action type that runs in the `COMPILING` status.
 * In those cases, the unique action runs in the `SECONDARY` portion of the request lifecycle, after the primary action is completed and some
 * specified delay period took place.
 *
 * i.e. an `ERASURE` request runs an `ACCESS` request as the primary action, and then runs an `ERASURE` at some time in the future.
 *
 * ### ON CHANGE TO
 *  - The request data silos queue up and fire the secondary action
 *  - The request waits in this state for some arbitrary time to allow for reversal
 *  - After the second wait period the request transitions to `DELETED` phase
 */
export const SECONDARY_STATUS: RequestStatusConfig = {
  color: 'darkPrimary',
  icon: 'view-file',
  secondaryOnly: true,
  messages: {
    description: statusDescriptionMessages.SECONDARY,
    title: statusTitleMessages.SECONDARY,
  },
  transitions: [
    /** The request can be canceled by an administrator. This is not recommended, since partial erasure may happen, but can be done if needed. */
    'CANCELED',
    /** The secondary action was executed successfully across the datamap */
    'SECONDARY_COMPLETED',
    /** Request needs to be approved before sending */
    'SECONDARY_APPROVING',
  ],
};
