import { getEntries } from '@transcend-io/type-utils';

import { ExternalDataSeries, InternalChartData } from './types';

// returns sum of child bars for a given x value
// needed to create Y scale described below
// this is mostly useful for stacked bar charts who's y axis values are all positive
export const getXSum = (
  xValue: string | number,
  data: ReturnType<typeof getInternalChartData>,
): number => {
  const datum =
    data.find((d) => d.x === xValue) || ({} as Record<string, number>);
  const values = Object.entries(datum)
    .filter(([key]) => key !== 'x')
    .map(([, val]) => val);
  return values.reduce((sum, curr) => sum + curr, 0);
};

// helper function to determine the max Y value for a given X input
// this value is used to create the scale that maps bars to a y value in
// the parent svg coordinate system
export const getYValues = (data: InternalChartData): Record<string, number> =>
  data.reduce(
    (values, currPoint) => ({
      ...values,
      [currPoint.x]: getXSum(currPoint.x, data),
    }),
    {},
  );

// helper function to determine the max Y value for a given X input
// where it finds the maximum instead of the sum
export const getFlatMaxYValues = (
  data: InternalChartData,
): Record<string, number> =>
  data.reduce(
    (values, currPoint) => ({
      ...values,
      [currPoint.x]: Math.max(
        ...Object.keys(currPoint).map((key) =>
          key === 'x' ? 0 : currPoint[key],
        ),
      ),
    }),
    {},
  );

// helper function to determine the min Y value for a given X input
// where it finds the minimum instead of the sum
export const getFlatMinYValues = (
  data: InternalChartData,
): Record<string, number> =>
  data.reduce(
    (values, currPoint) => ({
      ...values,
      [currPoint.x]: Math.min(
        ...Object.keys(currPoint).map((key) =>
          key === 'x' ? 0 : currPoint[key],
        ),
      ),
    }),
    {},
  );

/**
 * calculates the maximum Y value for all possible X inputs, which is used
 * for creating mapping function that takes x values and returns
 * y coordinate within parent svg
 *
 * @param data - the internal chart data
 * @param stacked - is the chart stacked
 * @param extraScale - pad the chart scale by increasing it's size by a percentage
 * @returns maximum Y value for all possible X inputs
 */
export const getMinMaxY = (
  data: InternalChartData,
  stacked: boolean,
  extraScale = 0.05,
): { /** min value */ min: number; /** max value */ max: number } => {
  const min = Math.min(
    ...Object.values(stacked ? getYValues(data) : getFlatMinYValues(data)),
  );
  return {
    // if the min is positive, then move min down by extraScale
    min: min * (1 + (min > 0 ? -extraScale : extraScale)),
    max:
      Math.max(
        ...Object.values(stacked ? getYValues(data) : getFlatMaxYValues(data)),
      ) *
      (1 + extraScale),
  };
};

// helper in converting external series data to internal format
export const getSeriesData: (
  data: ExternalDataSeries,
) => Record<string, Array<Record<string, number>>> = (data) => {
  const seriesData: Record<string, Array<Record<string, number>>> = {};
  data.series.forEach((s) => {
    s.points.forEach((p) => {
      const newPoint = { [s.name]: p.value };
      const existingPoints = seriesData[p.key];
      seriesData[p.key] =
        existingPoints === undefined
          ? (seriesData[p.key] = [newPoint])
          : [...existingPoints, newPoint];
    });
  });
  return seriesData;
};

// converts external series data format to a shape that's easier to use internally as it
// lines up with what BarChart expends as its data prop
export const getInternalChartData: (
  data: ExternalDataSeries,
) => InternalChartData = (data) =>
  Object.entries(getSeriesData(data))
    .map(([x, yValues]) => ({
      x,
      ...yValues.reduce((soFar, curr) => ({ ...soFar, ...curr }), {}),
    }))
    .flat() as InternalChartData;

// Gets just the values out of a given point of chart data
// such that the 'x' value is not included
export const getValueData = (
  data: InternalChartData,
  xValue: string,
): [string, number][] =>
  getEntries(data.find((d) => d.x === xValue) || {}).filter(
    ([key]) => key !== 'x',
  );
