import { FlexRowCenterVertical, Tooltip } from '@main/core-ui';
import React, { ReactElement } from 'react';
import { Handle, NodeProps, Position } from 'reactflow';

import { StyledDataSiloCard, StyledDataSiloCardTitle } from './wrappers';

interface DataSiloNodeData {
  /** Node label */
  label: string;
  /** Node logo image */
  logo: string;
}

// https://reactflow.dev/docs/api/nodes/custom-nodes/
export const DataSiloNode = ({
  data,
  isConnectable,
  targetPosition,
  sourcePosition,
}: NodeProps<DataSiloNodeData>): ReactElement => (
  <StyledDataSiloCard>
    <Handle
      type="target"
      position={targetPosition ?? Position.Top}
      isConnectable={isConnectable}
    />
    {data.logo && (
      <FlexRowCenterVertical>
        <img
          src={data.logo}
          alt={data.label}
          style={{ minWidth: 16, maxWidth: 16, margin: '12px 0' }}
        />
      </FlexRowCenterVertical>
    )}
    <Tooltip title={data.label}>
      <StyledDataSiloCardTitle>{data.label}</StyledDataSiloCardTitle>
    </Tooltip>
    <Handle
      type="source"
      position={sourcePosition ?? Position.Bottom}
      isConnectable={isConnectable}
    />
  </StyledDataSiloCard>
);
