import { A } from '@main/core-ui';
import React from 'react';
import { MessageDescriptor, useIntl } from 'react-intl';
import { useTheme } from 'styled-components';

export const LegalLink: React.FC<{
  /** The link to the document */
  href: string;
  /** message that will show as link text, if not passed, children will be rendered */
  linkText: MessageDescriptor | string;
}> = ({ href, linkText }) => {
  const theme = useTheme();
  const { formatMessage } = useIntl();
  return (
    <A
      href={href}
      style={{
        fontWeight: 600,
        fontSize: 'inherit',
        color: theme.colors.transcend,
      }}
    >
      {typeof linkText === 'string' ? linkText : formatMessage(linkText)}
    </A>
  );
};
