import { defineMessages } from '@transcend-io/internationalization';
import {
  RequestActionObjectResolver,
  RequestStatus,
} from '@transcend-io/privacy-types';
import { apply } from '@transcend-io/type-utils';

import { requestActionMessages } from '@main/ad-core-components/src/WorkflowCard/messages';
import { REQUEST_STATUSES } from '@main/dsr-types';

/**
 * Message definitions for RequestActionObjectResolver
 */
export const requestActionObjectResolverMessages =
  defineMessages<RequestActionObjectResolver>(
    'admin-dashboard.PrivacyRequests.Request.requestActionObjectResolver',
    {
      [RequestActionObjectResolver.Access]: requestActionMessages.ACCESS,
      [RequestActionObjectResolver.Erasure]: requestActionMessages.ERASURE,
      [RequestActionObjectResolver.ContactOptOut]:
        requestActionMessages.CONTACT_OPT_OUT,
      [RequestActionObjectResolver.ContactOptIn]:
        requestActionMessages.CONTACT_OPT_IN,
      [RequestActionObjectResolver.AutomatedDecisionMakingOptOut]:
        requestActionMessages.AUTOMATED_DECISION_MAKING_OPT_OUT,
      [RequestActionObjectResolver.SaleOptOut]:
        requestActionMessages.SALE_OPT_OUT,
      [RequestActionObjectResolver.SaleOptIn]:
        requestActionMessages.SALE_OPT_IN,
      [RequestActionObjectResolver.AutomatedDecisionMakingOptIn]:
        requestActionMessages.AUTOMATED_DECISION_MAKING_OPT_IN,
      [RequestActionObjectResolver.TrackingOptOut]:
        requestActionMessages.TRACKING_OPT_OUT,
      [RequestActionObjectResolver.TrackingOptIn]:
        requestActionMessages.TRACKING_OPT_IN,
      [RequestActionObjectResolver.CustomOptOut]:
        requestActionMessages.CUSTOM_OPT_OUT,
      [RequestActionObjectResolver.CustomOptIn]:
        requestActionMessages.CUSTOM_OPT_IN,
      [RequestActionObjectResolver.UseOfSensitiveInformationOptOut]:
        requestActionMessages.USE_OF_SENSITIVE_INFORMATION_OPT_OUT,
      [RequestActionObjectResolver.UseOfSensitiveInformationOptIn]:
        requestActionMessages.USE_OF_SENSITIVE_INFORMATION_OPT_IN,
      [RequestActionObjectResolver.Restriction]:
        requestActionMessages.RESTRICTION,
      [RequestActionObjectResolver.Rectification]:
        requestActionMessages.RECTIFICATION,
      [RequestActionObjectResolver.BusinessPurpose]:
        requestActionMessages.BUSINESS_PURPOSE,
      [RequestActionObjectResolver.PlaceOnLegalHold]:
        requestActionMessages.PLACE_ON_LEGAL_HOLD,
      [RequestActionObjectResolver.RemoveFromLegalHold]:
        requestActionMessages.REMOVE_FROM_LEGAL_HOLD,
    },
  );

/**
 * Message definitions for RequestStatus
 */
export const requestStatusMessages = defineMessages<RequestStatus>(
  'admin-dashboard.PrivacyRequests.Request.requestStatus',
  apply(REQUEST_STATUSES, ({ messages }) => messages.title),
);

export const requestMessages = defineMessages(
  'admin-dashboard.PrivacyRequests.Request.request',
  {
    header: {
      defaultMessage: '{pascalType} Request',
    },
    info: {
      defaultMessage: '{pascalType} request from {requestEmail}',
    },
    bounces: {
      defaultMessage: 'Emails to the data subject are bouncing!',
    },
    back: {
      defaultMessage: 'Back',
    },
    dataSubject: {
      defaultMessage: 'Data Subject',
    },
    isArchived: {
      defaultMessage:
        'The request has been processed and archived. Some data may be unavailable.',
    },
  },
);
