import styled from 'styled-components';

import { responsive } from '@main/theme';

/**
 * Allow override of content wrapper applied to all rendered components
 */
export const DefaultContentWrapper = styled.div``;

/**
 * Wrapper for horizontal menu
 */
export const HorizontalMenuWrapper = styled.div`
  padding-bottom: 35px;
  max-width: 600px;
  ${responsive.atLeastTablet} {
    max-width: 100%;
    text-align: center;
  }
`;

/**
 * The content wrapper when horizontal menu
 */
export const HorizontalContentWrapper = styled.div`
  padding: 0 10px;
`;
