import { createLoadable } from '@main/core-ui';

// Load the component dynamically
export const PartialLoadable = createLoadable({
  loader: () => import('./Partial'),
});

export const NewPartialLoadable = createLoadable({
  loader: () => import('./NewPartial'),
});
