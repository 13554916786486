import { useLocation, useParams } from 'react-router-dom';

import { getRoutePath } from '../utils/location';

/**
 * Get the current route path
 *
 * @returns The current route path
 */
export const useRoutePath = <TRouteName extends string>(): TRouteName => {
  const location = useLocation();
  const params = useParams();
  return getRoutePath(location, params) as TRouteName;
};
