import { mkInput, mkType, SchemaToType } from '@main/schema-utils';

export const ScannedObjectHierarchyPathFiltersInput = mkInput({
  name: 'ScannedObjectHierarchyPathFiltersInput',
  comment: 'Input for filtering a hierarchy of scanned objects',
  fields: {
    dataSiloId: {
      comment: 'Filter by data silos the scanned objects belong to',
      type: 'id',
      modelName: 'dataSilo',
    },
    parentId: {
      comment: 'Filter by parent scanned object level ID',
      type: 'id',
      modelName: 'scannedObjectPath',
      optional: true,
    },
    scannedObjectId: {
      comment: 'Filter by the current scanned object',
      type: 'id',
      modelName: 'scannedObject',
      optional: true,
    },
  },
});

/** Override type */
export type ScannedObjectHierarchyPathFiltersInput = SchemaToType<
  typeof ScannedObjectHierarchyPathFiltersInput
>;

export const ScannedObjectHierarchyItemMetadata = mkType({
  name: 'ScannedObjectHierarchyItemMetadata',
  comment: 'Metadata for a hierarchy item',
  fields: {
    rowCount: {
      comment: 'The number of rows in the object',
      type: 'int',
    },
  },
});

/** Override type */
export type ScannedObjectHierarchyItemMetadata = SchemaToType<
  typeof ScannedObjectHierarchyItemMetadata
>;

export const ScannedObjectHierarchyItem = mkType({
  name: 'ScannedObjectHierarchyItem',
  comment:
    'A hierarchy item in the scanned object hierarchy, which can be a scanned object or a folder',
  fields: {
    id: {
      comment:
        'The unique ID of the scanned object or the scanned object level',
      type: 'string',
    },
    name: {
      comment: 'The identifying name/key of the scanned object',
      type: 'string',
    },
    metadata: {
      comment: 'The metadata for the object',
      type: ScannedObjectHierarchyItemMetadata,
      optional: true,
    },
    path: {
      comment: 'The path to this hierarchy item',
      type: 'string',
      list: true,
      optional: true,
    },
    isFolder: {
      comment: 'Is this item a scanned object or a folder?',
      type: 'boolean',
    },
    dataSiloId: {
      comment: 'The ID of the data silo that contains the item',
      type: 'id',
      modelName: 'dataSilo',
      optional: true,
    },
    description: {
      comment: 'The description of the data point',
      type: 'string',
      optional: true,
    },
  },
});

/** Override type */
export type ScannedObjectHierarchyItem = SchemaToType<
  typeof ScannedObjectHierarchyItem
>;
