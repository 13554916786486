/**
 * The fields that an appUser can be ordered on
 */
export enum ApplicationUserOrderField {
  /** The name of the appUser */
  Name = 'name',
  /** The coreIdentifier of the appUser */
  CoreIdentifier = 'coreIdentifier',
  /** The age of the appUser */
  CreatedAt = 'createdAt',
  /** The last updated time */
  UpdatedAt = 'updatedAt',
}
