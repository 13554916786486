import styled from 'styled-components';

import {
  FlexColumnCenterHorizontal,
  ModalRaw,
  ModalRawProps,
  StyleUtils,
} from '@main/core-ui';

export const StyledModalRaw = styled(ModalRaw)<
  ModalRawProps & {
    /** is success screen showing */
    $showSuccess?: boolean;
  }
>`
  .modal-body {
    ${({ $showSuccess }) => ($showSuccess ? `display: none;` : '')}
  }
`;

export const SuccessContentWrapper = styled(FlexColumnCenterHorizontal)`
  transition: opacity 0.5s ease-in-out;
  gap: ${StyleUtils.Spacing.md};
  padding: 3em;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 0.7em;
  overflow-y: auto;
`;
