import * as t from 'io-ts';

import { dbModelId } from '@main/schema-utils';

import { AuditEventCode } from '../schema/enums';
import {
  mkAuditEventCodec,
  mkAuditEventJoinCodec,
  mkAuditJoinStateCodec,
} from '../types';

export const AuditEventDataSubCategory = mkAuditEventCodec(
  AuditEventCode.DataSubCategory,
  t.type({
    /** ID of the sub-category */
    dataSubCategoryId: dbModelId('dataSubCategory'),
  }),
);

/** Override types. */
export type AuditEventDataSubCategory = t.TypeOf<
  typeof AuditEventDataSubCategory
>;

export const AuditEventDataSubCategoryOwner = mkAuditEventJoinCodec(
  AuditEventCode.DataSubCategoryOwner,
  mkAuditJoinStateCodec('user'),
  t.type({
    /** ID of the sub-category */
    dataSubCategoryId: dbModelId('dataSubCategory'),
  }),
);

/** Override types. */
export type AuditEventDataSubCategoryOwner = t.TypeOf<
  typeof AuditEventDataSubCategoryOwner
>;

export const AuditEventDataSubCategoryTeam = mkAuditEventJoinCodec(
  AuditEventCode.DataSubCategoryTeam,
  mkAuditJoinStateCodec('team'),
  t.type({
    /** ID of the sub-category */
    dataSubCategoryId: dbModelId('dataSubCategory'),
  }),
);

/** Override types. */
export type AuditEventDataSubCategoryTeam = t.TypeOf<
  typeof AuditEventDataSubCategoryTeam
>;
export const AuditEventDataSubCategoryAttribute = mkAuditEventJoinCodec(
  AuditEventCode.DataSubCategoryAttribute,
  mkAuditJoinStateCodec(
    'attributeValue',
    t.type({
      /** the display title for the attribute key */
      attributeKeyTitle: t.string,
    }),
  ),
  t.type({
    /** ID of the dataSubCategory */
    dataSubCategoryId: dbModelId('dataSubCategory'),
  }),
);

/** Override types. */
export type AuditEventDataSubCategoryAttribute = t.TypeOf<
  typeof AuditEventDataSubCategoryAttribute
>;
