import type { RequestStatus } from '@transcend-io/privacy-types';
import { createEnum, filterEnum } from '@transcend-io/type-utils';

import { REQUEST_STATUSES } from './statuses';

/**
 * The statuses where a request can be archived from
 */
export const REQUEST_STATUSES_ARCHIVEABLE = filterEnum(
  REQUEST_STATUSES,
  ({ canArchive }) => !!canArchive,
);

/**
 * The statuses that can be archived from
 */
export const REQUEST_STATUS_ARCHIVEABLE = createEnum(
  REQUEST_STATUSES_ARCHIVEABLE,
);

/**
 * Check if a request can be archived from its current status
 *
 * @param status - The status of the request to check
 * @returns True if the request can be archived from its current state
 */
export function canArchive(status: RequestStatus): boolean {
  return status in REQUEST_STATUS_ARCHIVEABLE;
}
