import { datadogLogs } from '@datadog/browser-logs';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';

import { logger } from '@main/core-ui';
import { decrypt, toBuffer } from '@main/sm-browser';
import { DecryptionContext, DhEncryptionKeys } from '@main/sombra-types';

import { selectBackendUrl, selectUser } from '../Auth/App/selectors';

/**
 * Decrypts the sombra session secret using the sombra public key and the KEK
 *
 * @param dhEncryptionKeys - Diffie hellman encryption keys
 * @param decryptionContext - Response form sombra that needs to be decrypted to reveal the session secret
 * @returns The decrypted sombra session secret
 */
export type DecryptSombraSessionSecretFn = (
  dhEncryptionKeys: DhEncryptionKeys,
  decryptionContext: DecryptionContext,
) => string | undefined;

/**
 * Returns a function that can decrypts the sombra session secret using the sombra public key and the KEK
 *
 * This could be a function, but because we want to include some additional redux context in the datadog
 * logs upon error, we need to use a hook.
 *
 *  @returns The function
 */
export function useDecryptSombraSessionSecret(): DecryptSombraSessionSecretFn {
  const backendUrl = useSelector(selectBackendUrl);
  const user = useSelector(selectUser);
  const func = useCallback<DecryptSombraSessionSecretFn>(
    (dhEncryptionKeys, decryptionContext) => {
      try {
        return decrypt(decryptionContext.wrappedKey, {
          key: toBuffer(dhEncryptionKeys.kek),
          iv: decryptionContext.iv,
          authTag: decryptionContext.authTag,
        }).toString('utf-8');
      } catch (err) {
        datadogLogs.logger.warn(
          // eslint-disable-next-line max-len
          `Failed to select decrypted sombra session secret. The decryption keys and context were both present, but were not in sync`,
          {
            sombraPublicKey: dhEncryptionKeys.sombraPublicKey,
            backendUrl,
            userOrgUri: user?.organization?.uri ?? 'unknown',
            userEmailDomain: user?.email?.replace(/[^@]*/, '') ?? 'unknown',
          },
        );
        logger.error(err);
        return undefined;
      }
    },
    [],
  );
  return func;
}
