/**
 * Create a regex to extract data from a tag
 *
 * Input:
 * "here is some data <tag>Data</tag>..."
 *
 * @param tagName - Name of tag
 * @returns Data within the tag
 */
export function createRegexForTag(tagName: string): RegExp {
  return new RegExp(`<${tagName}>([\\s\\S]+?)<\\/${tagName}>`);
}
