import { createLoadable } from '@main/core-ui';

import { failureMessages } from './messages';

// Load the component dynamically
export default createLoadable({
  loader: () => import('./index'),
});

/**
 * Callback when fails
 */
export const LoginFailure = createLoadable({
  loader: () => import('./Failure'),
  messages: failureMessages,
});
