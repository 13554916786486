import { withBoundingRects, WithBoundingRectsProps } from '@visx/bounds';
import { Portal, TooltipWithBounds, useTooltip } from '@visx/tooltip';
import { UseTooltipParams } from '@visx/tooltip/lib/hooks/useTooltip';
import isEqual from 'lodash/isEqual';
import React, { CSSProperties, useCallback, useState } from 'react';

import { TooltipPosition } from './types';
import { Caret, TooltipContainer } from './wrappers';

export interface VisxTooltipProps {
  /** offset from top of document */
  top: number;
  /** offset from left of document */
  left: number;
  /** positionOverride */
  preferredPosition?: TooltipPosition;
  /** style overrides passed to tooltip container */
  styleOverrides?: CSSProperties;
}

/**
 * props for internal tooltip component
 */
type TooltipProps = Pick<VisxTooltipProps, 'preferredPosition'> &
  WithBoundingRectsProps & {
    /** overrides bounding rect props ref to be more specifically a div ref */
    nodeRef?: React.Ref<HTMLDivElement>;
  } & {
    /** offset from top of document */
    top: number;
    /** offset from left of document */
    left: number;
  };

const getTooltipPosition = ({
  fitsAbove,
  fitsBelow,
  fitsLeft,
  fitsRight,
  preferredPosition,
}: {
  /** if there's room for the tooltip above */
  fitsAbove: boolean;
  /** if there's room for the tooltip below */
  fitsBelow: boolean;
  /** if there's room for the tooltip to the left */
  fitsLeft: boolean;
  /** if there's room for the tooltip to the right */
  fitsRight: boolean;
  /** chart author's preferred tooltip position */
  preferredPosition: TooltipPosition | undefined;
}): TooltipPosition => {
  // TODO: https://transcend.height.app/T-22393
  // only fleshing out above-center right now as that's
  // the only one we're using, but can be expanded
  // for all
  if (preferredPosition === 'above-center' && fitsAbove) {
    return preferredPosition;
  }
  if (fitsAbove && fitsBelow) {
    if (fitsRight) {
      return 'right-center';
    }
    if (!fitsRight) {
      return 'left-center';
    }
  }
  if (!fitsBelow) {
    if (fitsRight && fitsLeft) {
      return 'above-center';
    }
    if (!fitsLeft) {
      return 'above-right';
    }
    if (!fitsRight) {
      return 'above-left';
    }
  }
  if (!fitsAbove) {
    if (fitsRight) {
      return 'right-down';
    }
    if (!fitsRight) {
      return 'left-down';
    }
  }
  return 'right-center';
};

// child component needed to pull state from parent context using useTooltipPosition
const BaseVisxTooltipWithoutBounds: React.FC<TooltipProps> = ({
  preferredPosition,
  children,
  rect: ownBounds,
  top,
  left,
  nodeRef,
}) => {
  const ownHeight = ownBounds?.height || 0;
  const ownWidth = ownBounds?.width || 0;

  const clipAboveAmount = Math.abs(Math.min(top - ownHeight, 0));
  const clipBelowAmount = Math.abs(
    Math.min(window.innerHeight - top - ownHeight - ownHeight, 0),
  );
  const clipLeftAmount = Math.abs(Math.min(left - ownWidth, 0));
  const clipRightAmount = Math.abs(
    Math.min(window.innerWidth - left - ownWidth - ownWidth, 0),
  );

  const fitsAbove = clipAboveAmount <= clipBelowAmount;
  const fitsBelow = clipBelowAmount <= clipAboveAmount;
  const fitsRight = clipRightAmount <= clipLeftAmount;
  const fitsLeft = clipLeftAmount <= clipRightAmount;
  const tooltipPosition = getTooltipPosition({
    fitsAbove,
    fitsBelow,
    fitsLeft,
    fitsRight,
    preferredPosition,
  });
  return (
    <TooltipContainer tooltipPosition={tooltipPosition} ref={nodeRef}>
      <Caret tooltipPosition={tooltipPosition} />
      {children}
    </TooltipContainer>
  );
};

const BaseVisxTooltipWithBounds = withBoundingRects(
  BaseVisxTooltipWithoutBounds,
);

export const VisxTooltip: React.FC<VisxTooltipProps> = ({
  children,
  top,
  left,
  preferredPosition,
}) => (
  <Portal>
    <TooltipWithBounds
      style={{
        transition: 'transform 200ms ease-out',
        width: 'max-content',
        position: 'fixed',
        zIndex: 999,
        /**
         * can't interact with the tooltip anyways, so disabling pointer events
         * prevents mouse leave events from being fired when it moves around
         */
        pointerEvents: 'none',
        /**
         * override additional layout styles as we apply our own in BaseVisxTooltip.
         * 10px is our cursor offset so the tooltip doesn't obscure
         * tip of the cursor
         */
        transform: `translate(${left + 10}px, ${top + 10}px)`,
      }}
      top={top}
      left={left}
    >
      <BaseVisxTooltipWithBounds
        top={top}
        left={left}
        preferredPosition={preferredPosition}
      >
        {children}
      </BaseVisxTooltipWithBounds>
    </TooltipWithBounds>
  </Portal>
);

export const useVisxTooltip = <
  TooltipData extends {},
>(): UseTooltipParams<TooltipData> & {
  /** preferred TooltipPosition to be default when no bounds are being hit */
  preferredPosition?: TooltipPosition;
  /** setter function for preferredPosition */
  setPreferredPosition: (position: TooltipPosition | undefined) => void;
} => {
  const tooltipControls = useTooltip<TooltipData>();
  const [preferredPosition, setPreferredPosition] = useState<
    TooltipPosition | undefined
  >();
  const updateTooltip = useCallback<
    UseTooltipParams<TooltipData>['updateTooltip']
  >(
    (data) => {
      // only trigger updates if the contents have changed
      if (!isEqual(data, tooltipControls.tooltipData)) {
        tooltipControls.updateTooltip(data);
      }
    },
    [tooltipControls.updateTooltip, tooltipControls.tooltipData],
  );

  return {
    ...tooltipControls,
    updateTooltip,
    preferredPosition,
    setPreferredPosition,
  };
};
