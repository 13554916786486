import { findAllWithRegex } from '@transcend-io/type-utils';

import type { Intervals } from '@main/dsr-types';

import { mergeIntervals } from './mergeIntervals';

/**
 * Given a content string, search that content string for specific search terms and return the indices that match
 *
 * @param content - The content to search
 * @param searchTerms - The terms to search
 * @returns The indices within the content that include the search terms
 */
export function findDefaultSelections(
  content: string,
  searchTerms: string[],
): Intervals {
  const selections: Intervals = [];
  searchTerms.forEach((searchTerm) => {
    const searchTermRegex = new RegExp(
      // Escape the search term
      searchTerm.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'),
      'gi',
    );
    const matches = findAllWithRegex(
      { value: searchTermRegex, matches: [] },
      content,
    );
    matches.forEach(({ matchIndex }) =>
      selections.push([matchIndex, matchIndex + searchTerm.length]),
    );
  });
  return mergeIntervals(selections);
}
