import {
  AUDIT_EVENT_BASE_MODEL_CODES,
  AuditEventPreview,
} from '@main/audit-types';
import { FlexColumn, StyleUtils } from '@main/core-ui';
import groupBy from 'lodash/groupBy';
import React, { useMemo } from 'react';

import { AuditTrailJoinListItem } from './AuditTrailJoinListItem';
import { AuditTrailStateListItem } from './AuditTrailStateListItem';

interface AuditTrailListProps {
  /** the events to render */
  events: AuditEventPreview[];
}

const stateCodeSet = new Set(AUDIT_EVENT_BASE_MODEL_CODES);
/**
 * AuditTrailList
 */
export const AuditTrailList: React.FC<AuditTrailListProps> = ({ events }) => {
  const groupedEvents = useMemo(
    () => Object.entries(groupBy(events, 'transactionUuid')),
    [events],
  );
  return (
    <FlexColumn style={{ gap: StyleUtils.Spacing.md }}>
      {groupedEvents.map(([transaction, group]) =>
        stateCodeSet.has(group[0].code) ? (
          group.map((event) => (
            <AuditTrailStateListItem key={event.id} event={event} />
          ))
        ) : (
          <AuditTrailJoinListItem events={group} key={transaction} />
        ),
      )}
    </FlexColumn>
  );
};
