import {
  extractFormItemWrapperProps,
  FormItemWrapper,
  IFormFieldProps,
  IFormItemWrapperProps,
  multipleValidators,
  TValidator,
} from '@main/core-ui';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { TextArea, TextAreaProps } from '../Input';

export interface IFormTextAreaProps extends IFormFieldProps {
  /** the validation rules for the input */
  rules?: TValidator[];
}

/**
 * A raw form text area for use cases where formatting is not desired
 */
export const FormTextAreaRaw: React.FC<
  IFormTextAreaProps & Omit<TextAreaProps, 'value' | 'onChange' | 'onBlur'>
> = ({ name, defaultValue = '', rules, ...antInputProps }) => {
  const { control } = useFormContext();
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      rules={rules ? { validate: multipleValidators(rules) } : undefined}
      render={({ field }) => <TextArea {...antInputProps} {...field} />}
    />
  );
};

/**
 * A form text area with formatting and error display
 */
export const FormTextArea: React.FC<
  IFormTextAreaProps & TextAreaProps & Omit<IFormItemWrapperProps, 'errors'>
> = (props) => {
  const {
    formState: { errors },
  } = useFormContext();
  const { passthroughProps, wrapperProps } = extractFormItemWrapperProps(props);

  return (
    <FormItemWrapper errors={errors} {...wrapperProps}>
      <FormTextAreaRaw {...passthroughProps} />
    </FormItemWrapper>
  );
};
