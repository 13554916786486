import { Button } from '@main/ad-core-components';
import { Badge, buildUseMutation, Icon, message } from '@main/core-ui';
import { endpoints } from '@main/pc-types';
import type { ID } from '@main/schema-utils';
import React, { useCallback, useState } from 'react';
import { useIntl } from 'react-intl';
import { CSSProperties } from 'styled-components';

import { reviewChangesAndPublishMessages } from './messages';
import { ReviewChangesAndPublishModal } from './ReviewChangesAndPublishModal';

const useDeployPrivacyCenterVersion = buildUseMutation(
  endpoints.deployPrivacyCenterVersion,
);

export const ReviewChangesAndPublishButton: React.FC<{
  /** function to save draft for current privacy center (PC) page */
  savePCDraft?: () => Promise<void>;
  /** is the draft of PC page currently in the process of being saved? */
  savePCDraftSaving?: boolean;
  /** styling for the button */
  styles?: CSSProperties;
}> = ({ savePCDraft, savePCDraftSaving, styles }) => {
  const { formatMessage } = useIntl();

  const [isVisible, setIsVisible] = useState(false);
  const handleClick = useCallback(async () => {
    setIsVisible(true);
    if (savePCDraft) {
      await savePCDraft();
    }
  }, [savePCDraft, setIsVisible]);
  const handleCancel = useCallback(() => {
    setIsVisible(false);
  }, [setIsVisible]);

  // Indicates whether there are saved changes that can be set live
  const [changesMade, setChangesMade] = useState(false);

  const [deployPrivacyCenterVersion, { loading: deployLoading }] =
    useDeployPrivacyCenterVersion({
      refetchQueries: ['PrivacyCenterVersionModules'],
    });

  // callback to update saved (not published) privacy center version draft as ready to deploy
  const handleDeploy = useCallback(
    (privacyCenterVersionId?: ID<'privacyCenterVersion'>) => {
      if (privacyCenterVersionId) {
        deployPrivacyCenterVersion({
          variables: {
            input: { privacyCenterVersionId },
          },
        })
          .then(() =>
            message.success(
              formatMessage(
                reviewChangesAndPublishMessages.reviewChangesAndPublishSuccess,
              ),
            ),
          )
          .catch(() =>
            message.error(
              formatMessage(
                reviewChangesAndPublishMessages.reviewChangesAndPublishError,
              ),
            ),
          )
          .finally(() => setIsVisible(false));
      } else {
        // closes modal if no privacyCenterVersionId provided
        setIsVisible(false);
      }
    },
    [],
  );

  return (
    <>
      <Button
        data-cy="review-changes-and-publish-button"
        variant="primary"
        icon={<Icon type="lightning" />}
        style={{ ...styles }}
        loading={savePCDraftSaving}
        onClick={handleClick}
      >
        {formatMessage(reviewChangesAndPublishMessages.reviewChangesAndPublish)}
        {changesMade && (
          <Badge
            color="positiveHighlight"
            round
            style={{
              padding: '3px',
              marginLeft: '6px',
              minWidth: 0,
            }}
          />
        )}
      </Button>
      {isVisible && (
        <ReviewChangesAndPublishModal
          onHide={handleCancel}
          onPublish={handleDeploy}
          publishLoading={deployLoading}
          onModified={setChangesMade}
          saveDraftLoading={!!savePCDraftSaving}
        />
      )}
    </>
  );
};
