import { DefinedMessage, MenuTabInterface } from '@main/internationalization';
import { mkInput, mkType, SchemaToType } from '@main/schema-utils';

import { DataApplication } from './dataApplication';
import { DataCollectionStaticType } from './enums';

export const NewDataCollectionInput = mkInput({
  name: 'NewDataCollectionInput',
  comment: 'Input for creating a data collection',
  fields: {
    title: {
      comment: 'The title of the data collection',
      type: 'string',
    },
    description: {
      comment: 'A description for the data collection',
      optional: true,
      type: 'string',
    },
  },
});

/** Override type */
export type NewDataCollectionInput = SchemaToType<
  typeof NewDataCollectionInput
>;

export const DataCollectionInput = mkInput({
  name: 'DataCollectionInput',
  comment: 'Input for updating or creating a data collection',
  fields: {
    id: {
      comment: 'The id of the data collection to update, else create new',
      optional: true,
      modelName: 'dataCollection',
      type: 'id',
    },
    title: {
      comment: 'The title of the data collection',
      type: 'string',
    },
    description: {
      comment: 'A description for the data collection',
      optional: true,
      type: 'string',
    },
    dataApplicationIds: {
      comment: 'The ids of the data applications for this data collection',
      list: true,
      optional: true,
      modelName: 'dataApplication',
      type: 'id',
    },
    icon: {
      comment: 'The icon used to display the data practice data collection',
      optional: true,
      type: 'string',
    },
    staticType: {
      comment: 'The underlying static type of the data collection',
      optional: true,
      type: { DataCollectionStaticType },
    },
  },
});

/** Override type */
export type DataCollectionInput = SchemaToType<typeof DataCollectionInput>;

export const DataCollection = mkType({
  name: 'DataCollection',
  comment: 'A collection of data that the organization holds',
  interfaces: [MenuTabInterface],
  fields: {
    ...MenuTabInterface.fields,
    id: {
      comment: 'The id of the data collection',
      modelName: 'dataCollection',
      type: 'id',
    },
    staticType: {
      comment: 'The underlying static type of the data collection',
      optional: true,
      type: { DataCollectionStaticType },
    },
    dataApplications: {
      comment: 'The data application associated with the data collection',
      list: true,
      type: DataApplication,
    },
    dataCategoryId: {
      comment: 'The category that the data collection belongs under',
      modelName: 'dataCategory',
      type: 'id',
    },
    description: {
      comment: 'A brief overview of the data collection',
      type: DefinedMessage,
      optional: true,
    },
  },
});

/** Override type */
export type DataCollection = SchemaToType<typeof DataCollection>;
