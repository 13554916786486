import { defineMessages } from '@transcend-io/internationalization';

export const triageSDKsMessages = defineMessages(
  'admin-dashboard.ConsentManager.NativeApps.TriageSDKs.triageSDKs',
  {
    actionsColumnHeader: { defaultMessage: 'Actions' },
  },
);

export const sdkMessages = defineMessages(
  'admin-dashboard.ConsentManager.NativeApps.TriageSDKs.sdk',
  {
    sdkCount: {
      defaultMessage: '{count} {count, plural, one {SDK} other {SDKs}}',
    },
    noDataMessage: {
      defaultMessage: 'No SDKs to display.',
    },
  },
);

export const sdkColumnHeaderMessages = defineMessages(
  'admin-dashboard.ConsentManager.NativeApps.TriageSDKs.sdkColumnHeader',
  {
    name: {
      defaultMessage: 'Name',
    },
    description: {
      defaultMessage: 'Description',
    },
    codePackageType: {
      defaultMessage: 'Package Type',
    },
    repositoryUrl: {
      defaultMessage: 'Repository Link',
    },
    documentationLinks: {
      defaultMessage: 'Documentation',
    },
    sdk: {
      defaultMessage: 'Linked SDK',
    },
    catalog: {
      defaultMessage: 'Integration',
    },
    guessedCatalog: {
      defaultMessage: 'Guessed Integration',
    },
    repositories: {
      defaultMessage: 'Repositories',
    },
    registryUrl: {
      defaultMessage: 'Registry URL',
    },
    codePackages: {
      defaultMessage: 'Packages',
    },
    actions: { defaultMessage: 'Actions' },
  },
);
