import { Icon } from '@main/core-ui';
import React from 'react';

import { WarningMessageWrapper, WarningMessageWrapperProps } from './wrappers';

export interface WarningMessageWrapper extends WarningMessageWrapperProps {
  /** Style */
  style?: React.CSSProperties;
}

/**
 * A warning message and icon
 */
export const WarningMessage: React.FC<WarningMessageWrapper> = ({
  children,
  ...props
}) => (
  <WarningMessageWrapper {...props}>
    <Icon type="exclamation-circle" />
    <span>{children}</span>
  </WarningMessageWrapper>
);
