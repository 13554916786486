import { endpoints, TCFPurpose } from '@main/cm-types';
import {
  buildUseQuery,
  formatErrorMessage,
  ReactSelect,
  ReactSelectOverflowOptions,
} from '@main/core-ui';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { MessageDescriptor, useIntl } from 'react-intl';
import { MenuPosition } from 'react-select';

import { TcfDoubleTag } from '../TcfDoubleTag';
import { selectTcfPurposeMessages } from './messages';

const NODES = {
  id: null,
  name: null,
} as const;

const useTcfPurposes = buildUseQuery(
  endpoints.orgTcfPurposes,
  'SelectTcfPurposes',
  { nodes: { tcfPurpose: NODES } },
);

/**
 * Selected TCF Purpose
 */
export type SelectedTcfPurpose = Pick<TCFPurpose, keyof typeof NODES>;

/** Props for the SelectTcfPurposes component */
export interface SelectTcfPurposesProps {
  /** The value for the cell */
  value?: SelectedTcfPurpose[];
  /** Whether the input is disabled (e.g. due to the data being derived) */
  disabled?: boolean;
  /** Whether the input is being updated/loading */
  isLoading?: boolean;
  /** Whether to show the select input outlines */
  showSelectOutline?: boolean;
  /** How to position the menu (see docs for React Select) */
  menuPosition?: MenuPosition;
  /** How to display overflow if multi-select */
  overflow?: ReactSelectOverflowOptions<true>;
  /** Callback to fire when the values is updated */
  onChange: (values: SelectedTcfPurpose[]) => void;
  /** Placeholder Message */
  placeholder?: MessageDescriptor;
}

/** An option for the select component */
export interface SelectTcfPurposesOption {
  /** The value for the option */
  value: SelectedTcfPurpose;
  /** The label for the option */
  label: string;
}

const isSelectedTcfPurposesOption = (
  value: SelectTcfPurposesOption | undefined,
): value is SelectTcfPurposesOption => !!value;

/** SelectTcfPurposes: select TCF purposes */
export const SelectTcfPurposes: React.FC<SelectTcfPurposesProps> = ({
  value: initialValues,
  disabled = false,
  isLoading,
  showSelectOutline = false,
  onChange,
  placeholder,
  ...props
}) => {
  const { formatMessage } = useIntl();
  const [searchText, setSearchText] = useState('');

  const { data, loading, error } = useTcfPurposes({
    variables: {
      input: {},
      filterBy: {
        ...(searchText ? { text: searchText } : {}),
      },
    },
    fetchPolicy: 'cache-and-network',
  });

  const toOption = useCallback(
    (value?: SelectedTcfPurpose): SelectTcfPurposesOption | undefined =>
      !value
        ? undefined
        : {
            value,
            label: value.name,
          },
    [],
  );

  const options = useMemo(
    () => (data?.nodes.map(({ tcfPurpose }) => tcfPurpose) ?? []).map(toOption),
    [data],
  );

  const [selected, setSelected] = useState<SelectTcfPurposesOption[]>();

  useEffect(() => {
    const newSelected = (
      initialValues ? initialValues.map((value) => toOption(value)) : []
    ).filter(isSelectedTcfPurposesOption);

    setSelected(newSelected);
  }, [data, initialValues, toOption]);

  return (
    <ReactSelect<true, SelectTcfPurposesOption>
      options={options.filter(isSelectedTcfPurposesOption)}
      isMulti
      value={selected}
      onChange={(options) =>
        onChange(options.map(({ value }) => value) as SelectedTcfPurpose[])
      }
      showOutline={showSelectOutline}
      isDisabled={disabled}
      isClearable={false}
      isLoading={isLoading || loading}
      getOptionLabel={({ label }) => label}
      placeholder={formatMessage(
        placeholder ?? selectTcfPurposeMessages.selectPlaceholder,
      )}
      onEndsTyping={setSearchText}
      noOptionsMessage={
        error ? () => formatErrorMessage(error.message) : undefined
      }
      formatOptionPill={(option) => <TcfDoubleTag option={option.value} />}
      {...props}
    />
  );
};
