import { defineMessages } from '@transcend-io/internationalization';

export const administrationPageTitleMessages = defineMessages(
  'admin-dashboard.Administration.administrationPageTitle',
  {
    account: {
      defaultMessage: 'Account',
    },
    administration: {
      defaultMessage: 'Administration',
    },
  },
);
