import {
  AttributeInput,
  endpoints,
  MAX_ATTRIBUTE_KEYS,
} from '@main/attribute-types';
import { buildUseQuery, FormItemWrapper } from '@main/core-ui';
import { ID } from '@main/schema-utils';
import {
  AttributeKeyType,
  AttributeSupportedResourceType,
} from '@transcend-io/privacy-types';
import React, { useCallback, useMemo } from 'react';
import { Controller } from 'react-hook-form';

import {
  AttributeInput as AttributeInputComponent,
  AttributeInputProps,
} from '../AttributeInput';

const useAttributeKeys = buildUseQuery(
  endpoints.attributeKeys,
  'BulkUpdateModalAttributeKeys',
);

interface BulkUpdateAttributeResult {
  /** The Select components for the attributes */
  attributeSelectors: React.ReactNode[];
  /** Helper to convert the form values for attributes to the values used in updating the attributes in the mutation */
  convertAttributeFormValuesToInput: (input: any) =>
    | {
        /** The attribute values to update, along with their keys */
        attributes: AttributeInput[];
      }
    | undefined;
  /** The default form values for each attribute key */
  defaultAttributeFormValues: {
    /** The attribute key ids with their default value type */
    [k in ID<'attributeKey'>]?: AttributeInputProps['values'];
  };
}

export const useBulkUpdateAttribute = (
  enabledOn: AttributeSupportedResourceType,
): BulkUpdateAttributeResult => {
  // To prevent frontend from crashing, only fetch a max of 25 attributes
  const { data: { nodes: attributeKeys = [] } = {} } = useAttributeKeys({
    variables: {
      first: MAX_ATTRIBUTE_KEYS,
      filterBy: {
        enabledOn: [enabledOn],
      },
    },
    fetchPolicy: 'cache-first',
  });

  const defaultAttributeFormValues = useMemo(() => {
    const formValues = {};
    attributeKeys.forEach(({ id, type }) => {
      Object.assign(formValues, {
        [id]: [
          AttributeKeyType.URL,
          AttributeKeyType.EMAIL,
          AttributeKeyType.Text,
        ].includes(type)
          ? ''
          : [],
      });
    });
    return formValues;
  }, [attributeKeys]);

  const convertAttributeFormValuesToInput = useCallback(
    (input: any) =>
      attributeKeys.length > 0
        ? {
            attributes: attributeKeys.reduce((previousValues, { id, name }) => {
              const values = (input[id] as AttributeInputProps['values']) || [];
              return [
                ...previousValues,
                // whether it's a string or an array, the length should be >0 to avoid wiping populated values
                ...(values.length > 0
                  ? [
                      {
                        key: name,
                        values:
                          typeof values === 'string'
                            ? [values]
                            : values.map(({ name }) => name),
                      },
                    ]
                  : []),
              ];
            }, [] as AttributeInput[]),
          }
        : undefined,
    [attributeKeys],
  );

  const attributeSelectors = useMemo(
    () =>
      attributeKeys.map((attributeKey) => (
        <FormItemWrapper
          key={attributeKey.id}
          name={attributeKey.id}
          label={attributeKey.name}
        >
          <Controller
            name={attributeKey.id}
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            render={({ field: { ref, value, onChange, ...field } }) => (
              <AttributeInputComponent
                showOutline
                attributeKey={attributeKey}
                values={value || []}
                setValue={onChange}
                {...field}
              />
            )}
          />
        </FormItemWrapper>
      )),
    [attributeKeys],
  );

  const attributeFormElements = useMemo(
    () => ({
      attributeSelectors,
      convertAttributeFormValuesToInput,
      defaultAttributeFormValues,
    }),
    [
      attributeSelectors,
      convertAttributeFormValuesToInput,
      defaultAttributeFormValues,
    ],
  );

  return attributeFormElements;
};
