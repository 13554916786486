import { mkType, SchemaToType } from '@main/schema-utils';

import { CertificateStatus } from './enums';

export const Certificate = mkType({
  name: 'Certificate',
  comment: 'An SSL certificate issued for the privacy center',
  fields: {
    id: {
      comment: 'The unique ID of the SSL certificate',
      modelName: 'certificate',
      type: 'id',
    },
    recordName: {
      comment:
        'The record name of the certificate that the organization needs to verify',
      type: 'string',
    },
    recordValue: {
      comment: 'The record value that the organization needs to verify',
      type: 'string',
    },
    arn: {
      comment: 'The unique infrastructure arn of the certificate',
      type: 'string',
    },
    status: {
      comment: 'The status of the certificate',
      type: { CertificateStatus },
    },
  },
});

/** Override type */
export type Certificate = SchemaToType<typeof Certificate>;
