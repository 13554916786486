import { defineMessages } from '@transcend-io/internationalization';

export const dataFlowsMessages = defineMessages(
  'admin-dashboard.Auditor.Run.DataFlows.dataFlows',
  {
    header: {
      defaultMessage: 'Network Requests',
    },
  },
);
