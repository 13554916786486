import { defineMessages } from '@transcend-io/internationalization';

export const inlineUrlMessages = defineMessages(
  'ad-core-components.InlineUrlInput.inlineUrl',
  {
    followLinkButtonLabel: { defaultMessage: 'Follow link' },
    copyButtonLabel: { defaultMessage: 'Copy URL' },
    placeholder: { defaultMessage: 'Enter URL' },
  },
);
