import styled, { css } from 'styled-components';

import {
  CONTENT_PADDING,
  PAGE_BREADCRUMB_HEIGHT,
  PAGE_BREADCRUMB_MARGIN_BOTTOM,
} from '@main/ad-core-components';
import { StyledButton } from '@main/ad-core-components/src/Button/wrappers';
import { FlexColumn } from '@main/core-ui';
import { SIDEBAR_TRANSITION, styleObjectToCss } from '@main/theme';

const SIDEBAR_WIDTH = 300;
const SIDEBAR_COLLAPSED_WIDTH = 75;
const CONTENT_WIDTH = 2000;
const ICON_WIDTH = 24;

export const PageContainerWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
`;

export const PageWrapper = styled.div`
  display: flex;
  width: 100%;
  max-width: 100%;
`;

export const PageBreadcrumbWrapper = styled.div`
  height: ${PAGE_BREADCRUMB_HEIGHT}px;
  margin-bottom: ${PAGE_BREADCRUMB_MARGIN_BOTTOM}px;
  display: flex;
  align-items: center;

  ${StyledButton} {
    padding-top: 0;
    padding-bottom: 0;
  }
`;

const sidebarTransition = css`
  ${styleObjectToCss(SIDEBAR_TRANSITION)}
`;

export interface SidebarWrapperProps {
  /** Is the sidebar expanded? */
  isExpanded?: boolean;
}

export const SidebarWrapper = styled.div<SidebarWrapperProps>`
  width: ${({ isExpanded }) =>
    isExpanded ? SIDEBAR_WIDTH : SIDEBAR_COLLAPSED_WIDTH}px;
  transition-property: width;
  ${sidebarTransition}
  height: 100vh;
  //overflow-y: auto;
  overflow-x: hidden;
  background-color: ${({ theme }) => theme.colors.gray2};
  border-right: 1px solid ${({ theme }) => theme.colors.gray3};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const NavWrapper = styled.nav`
  padding: 0 1.25em;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  min-height: 0;
`;

export const MenuItemWrapper = styled(FlexColumn)`
  flex-grow: 1;
  padding: 0.5em;

  &.hidden {
    pointer-events: none;
    opacity: 0.42;
  }
`;

export const SubMenuWrapper = styled(FlexColumn)<SidebarWrapperProps>`
  margin: 0 0 0 ${ICON_WIDTH + 15}px;
  padding-top: 0.5em;
  overflow: hidden;
  transition-property: max-height;
  ${sidebarTransition};
  max-height: 500px;
  ${({ isExpanded }) => !isExpanded && 'max-height: 0'}
`;

export interface MenuItemContentWrapperProps extends SidebarWrapperProps {
  /** Is submenu of menu item open? */
  submenuIsOpen?: boolean;
}

export const MenuItemContentWrapper = styled.span<MenuItemContentWrapperProps>`
  display: flex;
  justify-content: flex-start;
  align-items: center;

  svg {
    margin-right: 15px;
    flex-shrink: 0;
  }

  a,
  ${StyledButton} {
    display: block;
    outline: none;
    position: relative;
    font-size: 14px;
    font-weight: 600;
    color: ${({ theme }) => theme.colors.transcendNavy2};
    text-decoration: none;
    will-change: color;
    transition: color 0.1s ease-in-out;

    svg:last-child {
      margin: 0;
      opacity: 0;
    }

    :hover {
      svg:last-child {
        opacity: 1;
      }
    }

    :hover,
    :focus {
      color: ${({ theme }) => theme.colors.transcendNavy2};
    }

    &.active {
      color: ${({ theme }) => theme.colors.primary};
    }

    span {
      transition-property: opacity, max-height;
      ${sidebarTransition}
      max-height: 50px;
      ${({ isExpanded }) => !isExpanded && 'max-height: 0'}
    }
  }

  ${StyledButton} {
    border: none;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    text-align: left;
    padding: 0;

    :focus {
      box-shadow: none;
    }
  }
`;

export const LogoWrapper = styled.div`
  margin-bottom: 1em;
  padding-top: 1em;
`;

export const ContentWrapper = styled.div`
  height: 100vh;
  width: 100%;
  overflow-y: auto;
  background-color: ${({ theme }) => theme.colors.white};

  @media print {
    overflow-y: visible;
  }
`;

export interface ContentWrapperInnerProps {
  /** Whether to hide padding */
  hidePadding?: boolean;
}

export const ContentWrapperInner = styled.div<ContentWrapperInnerProps>`
  height: auto;
  width: 100%;
  min-width: 700px;
  /** For now let's not cap a max-width so that it's not mistaken as a padding error on large screens */
  /* max-width: ${CONTENT_WIDTH}px; */
  ${({ hidePadding }) => !hidePadding && `padding: ${CONTENT_PADDING}px`}
`;

export const Navigation = styled.div`
  display: flex;

  a {
    z-index: 1;
  }

  .btn {
    border: none;
    padding: 3px 6px;

    :not(:last-child) {
      margin-right: 8px;
    }
  }
`;

export const ContentBody = styled.div`
  position: relative;
`;
