import styled from 'styled-components';

/**
 * Props
 */
export interface LogoWrappersProps {
  /** Shift the logo in vertical direction */
  shiftY?: string;
}

/**
 * The Logo wrapper
 */
export const LogoWrapper = styled.span<LogoWrappersProps>`
  margin: auto;
  display: inline-block;
  vertical-align: middle;
  transform: translateY(${({ shiftY }) => shiftY});
`;
