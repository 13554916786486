import { DatabaseDriver } from '@transcend-io/privacy-types';

const DRIVER_SUPPORTS_TRANSACTION: { [k in DatabaseDriver]: boolean } = {
  [DatabaseDriver.bigquery]: false,
  [DatabaseDriver.mongodb]: false,
  [DatabaseDriver.amazondynamodb]: false,
  [DatabaseDriver.amazons3]: false,
  [DatabaseDriver.amazonredshift]: true,
  [DatabaseDriver.mysql]: true,
  [DatabaseDriver.maria]: true,
  [DatabaseDriver.postgresql]: true,
  [DatabaseDriver.ibmdb2]: true,
  [DatabaseDriver.snowflake]: true,
  [DatabaseDriver.microsoftsql]: true,
  [DatabaseDriver.databrickslakehouse]: false,
  [DatabaseDriver.oracle]: true,
};

/**
 * Test if the database driver supports transaction
 *
 * @param driver - the drive to test
 * @param transactionEnabled - Allow for enabling/disabling of transaction usage from a header
 * @returns true if driver supports transaction, false otherwise
 */
export function databaseDriverSupportsTransaction(
  driver: string,
  transactionEnabled: boolean,
): boolean {
  return (
    transactionEnabled &&
    !!driver &&
    !!DRIVER_SUPPORTS_TRANSACTION[driver as DatabaseDriver]
  );
}
