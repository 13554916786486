import { defineMessages } from '@transcend-io/internationalization';

import { TestEmailFailurePoint } from '@main/access-control-types';

export const testEmailSettingsDescriptionMessages =
  defineMessages<TestEmailFailurePoint>(
    'admin-dashboard.PrivacyRequests.EmailSettings.Testing.testEmailSettingsDescription',
    {
      [TestEmailFailurePoint.InitialBackend]: {
        defaultMessage:
          'Custom domains have not been verified yet, so the email cannot be sent.',
      },
      [TestEmailFailurePoint.Sombra]: {
        defaultMessage:
          'A connection could not be established from our backend to Sombra. This is commonly due to ' +
          'firewall issues or blocking connections from external IP addresses.',
      },
      [TestEmailFailurePoint.SombraInternal]: {
        defaultMessage: 'An error occurred within sombra',
      },
      [TestEmailFailurePoint.EmailSender]: {
        defaultMessage:
          'A connection could not be established from Sombra to our Email Sender service. This is commonly due to ' +
          'firewall issues or blocking connections to external IP addresses.',
      },
      [TestEmailFailurePoint.FinalBackend]: {
        defaultMessage:
          'A connection could not be established from Sombra to our backend. This is commonly due to ' +
          'firewall issues or blocking connections to external IP addresses.',
      },
    },
  );
export const testEmailSettingsNodeLabelMessages =
  defineMessages<TestEmailFailurePoint>(
    'admin-dashboard.PrivacyRequests.EmailSettings.Testing.testEmailSettingsNodeLabel',
    {
      [TestEmailFailurePoint.InitialBackend]: {
        defaultMessage: 'Transcend Backend',
      },
      [TestEmailFailurePoint.Sombra]: { defaultMessage: 'Sombra' },
      [TestEmailFailurePoint.SombraInternal]: { defaultMessage: 'Sombra' },
      [TestEmailFailurePoint.EmailSender]: { defaultMessage: 'Email Sender' },
      [TestEmailFailurePoint.FinalBackend]: {
        defaultMessage: 'Transcend Backend',
      },
    },
  );

export const testEmailSettingsMessages = defineMessages(
  'admin-dashboard.PrivacyRequests.EmailSettings.Testing.testEmailSettings',
  {
    header: {
      defaultMessage: 'Testing',
    },
    primarySombra: {
      defaultMessage: 'Primary Sombra (default)',
    },
    sendEmail: {
      defaultMessage: 'Send Email',
    },
    sombra: {
      defaultMessage: 'Sombra',
    },
    emailSender: {
      defaultMessage: 'Email Sender',
    },
    transcendBackend: {
      defaultMessage: 'Transcend Backend',
    },
    connectionFailed: {
      defaultMessage: 'Connection Failed',
    },
    configurationError: {
      defaultMessage: 'Configuration Error',
    },
    notReached: {
      defaultMessage:
        'Step was not reached. Failure occurred before this point',
    },
    errorOccurred: {
      defaultMessage: 'An error occurred while trying to send the test email',
    },
    success: {
      defaultMessage: 'The test email was sent successfully to {email}',
    },
  },
);

export const testEmailSettingsFormMessages = defineMessages(
  'admin-dashboard.PrivacyRequests.EmailSettings.Testing.testEmailSettingsForm',
  {
    sombraId: {
      defaultMessage: 'Sombra',
    },
    sombraIdDescription: {
      defaultMessage: 'Choose which sombra to use to send the test email',
    },
    useCustomDomain: {
      defaultMessage: 'Use Custom Email Domain',
    },
    useCustomDomainDescription: {
      defaultMessage:
        'Use the configured Custom Email Domain when trying to send the email. This will reveal if your custom ' +
        'domains are configured correctly and verified.',
    },
    destinationAddress: {
      defaultMessage: 'Destination Address',
    },
    destinationAddressDescription: {
      defaultMessage:
        'Specify an email address to which to send the test email',
    },
  },
);
