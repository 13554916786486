import { FormAutoComplete } from '@main/utils';

export const INPUT_ICON_SIZE = '1.3em';

/**
 * The valid autocomplete parameters
 *
 * Should stay up to date with Mozilla: https://developer.mozilla.org/en-US/docs/Web/HTML/Attributes/autocomplete
 */
export const FORM_AUTO_COMPLETES: { [name in FormAutoComplete]: string } = {
  [FormAutoComplete.Name]: 'name',
  [FormAutoComplete.HonorificPrefix]: 'honorific-prefix',
  [FormAutoComplete.GivenName]: 'given-name',
  [FormAutoComplete.AdditionalName]: 'additional-name',
  [FormAutoComplete.AdditionalNameInitial]: 'additional-name-initial',
  [FormAutoComplete.FamilyName]: 'family-name',
  [FormAutoComplete.HonorificSuffix]: 'honorific-suffix',
  [FormAutoComplete.Nickname]: 'nickname',
  [FormAutoComplete.StreetAddress]: 'street-address',
  [FormAutoComplete.AddressLine1]: 'address-line1',
  [FormAutoComplete.AddressLine2]: 'address-line2',
  [FormAutoComplete.AddressLine3]: 'address-line3',
  [FormAutoComplete.Locality]: 'locality',
  [FormAutoComplete.City]: 'city',
  [FormAutoComplete.AdministrativeArea]: 'administrative-area',
  [FormAutoComplete.State]: 'state',
  [FormAutoComplete.Province]: 'province',
  [FormAutoComplete.Region]: 'region',
  [FormAutoComplete.PostalCode]: 'postal-code',
  [FormAutoComplete.CountryName]: 'country-name',
  [FormAutoComplete.Email]: 'email',
  [FormAutoComplete.Tel]: 'tel',
  [FormAutoComplete.TelCountryCode]: 'tel-country-code',
  [FormAutoComplete.TelNational]: 'tel-national',
  [FormAutoComplete.TelAreaCode]: 'tel-area-code',
  [FormAutoComplete.TelLocal]: 'tel-local',
  [FormAutoComplete.TelLocalPrefix]: 'tel-local-prefix',
  [FormAutoComplete.TelLocalSuffix]: 'tel-local-suffix',
  [FormAutoComplete.TelExtension]: 'tel-extension',
  [FormAutoComplete.Fax]: 'fax',
  [FormAutoComplete.FaxCountryCode]: 'fax-country-code',
  [FormAutoComplete.FaxNational]: 'fax-national',
  [FormAutoComplete.FaxAreaCode]: 'fax-area-code',
  [FormAutoComplete.FaxLocal]: 'fax-local',
  [FormAutoComplete.FaxLocalPrefix]: 'fax-local-prefix',
  [FormAutoComplete.FaxLocalSuffix]: 'fax-local-suffix',
  [FormAutoComplete.FaxExtension]: 'fax-extension',
  [FormAutoComplete.CcName]: 'cc-name',
  [FormAutoComplete.CcGivenName]: 'cc-given-name',
  [FormAutoComplete.CcAdditionalName]: 'cc-additional-name',
  [FormAutoComplete.CcFamilyName]: 'cc-family-name',
  [FormAutoComplete.CcNumber]: 'cc-number',
  [FormAutoComplete.CcExpMonth]: 'cc-exp-month',
  [FormAutoComplete.CcExpYear]: 'cc-exp-year',
  [FormAutoComplete.CcExp]: 'cc-exp',
  [FormAutoComplete.CcCsc]: 'cc-csc',
  [FormAutoComplete.Bday]: 'bday',
  [FormAutoComplete.BdayYear]: 'bday-year',
  [FormAutoComplete.BdayMonth]: 'bday-month',
  [FormAutoComplete.BdayDay]: 'bday-day',
  [FormAutoComplete.Org]: 'org',
  [FormAutoComplete.OrganizationTitle]: 'organization-title',
  [FormAutoComplete.Sex]: 'sex',
  [FormAutoComplete.GenderIdentity]: 'gender-identity',
  [FormAutoComplete.Url]: 'url',
  [FormAutoComplete.Photo]: 'photo',
  [FormAutoComplete.NewPassword]: 'new-password',
  [FormAutoComplete.CurrentPassword]: 'current-password',
};
