import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { logger, useApolloClient } from '@main/core-ui';
import { ImmutableUrl } from '@main/immutable-url';

import { setSelectedBackend } from '../Auth/App/slice';
import { createApolloBackendLink } from '../graphql';

/**
 * Returns a function that can be used to change which backend is currently in use
 *
 * @returns The function
 */
export function useChangeSelectedBackend(): (backendUrl: string) => void {
  const dispatch = useDispatch();
  const client = useApolloClient();
  const changeBackendUrl = useCallback(
    (backendUrl: string) => {
      logger.info(`Changing selected backend to ${backendUrl}`);
      client.setLink(createApolloBackendLink(new ImmutableUrl(backendUrl)));
      dispatch(setSelectedBackend(backendUrl));
    },
    [client, dispatch],
  );
  return changeBackendUrl;
}
