/* eslint-disable max-lines */
import {
  ConsentTrackerStatus,
  DataCategoryType,
  IsoCountryCode,
  ProcessingPurpose,
  PromptAVendorEmailCompletionLinkType,
  PromptAVendorEmailSendType,
  RequestAction,
  RequestActionObjectResolver,
} from '@transcend-io/privacy-types';
import { applyEnum } from '@transcend-io/type-utils';

import { ApiKeyPreview, UserPreview } from '@main/access-control-types';
import { AttributeInput, AttributeValue } from '@main/attribute-types';
import {
  mkInput,
  mkInterface,
  mkOrder,
  mkType,
  SchemaToType,
} from '@main/schema-utils';
import { PluginType, SombraPreview } from '@main/sombra-types';

import { BusinessEntityPreview } from './businessEntityPreview';
import { CustomHeaderInput } from './customHeader';
import { DataSiloDiscoPluginRecommendationKey } from './DataSiloPlugin';
import {
  DataSiloInterface,
  DataSiloPreview,
  DiscoveredByDataSiloPreview,
  SilosDiscoveredPreview,
} from './dataSiloPreview';
import { DataSubCategoryPreview } from './dataSubCategory';
import { DataSubject } from './dataSubject';
import { SignedIdentifierPathSchema } from './enricher';
import {
  ConnectorType,
  ConsentManagerConnectionStatus,
  Controllership,
  DataProcessingAgreementStatus,
  DataSiloBulkPreviewOrderField,
  DataSiloConnectionState,
  DataSiloDeprecationState,
  DataSiloNullableFilters,
  HasPersonalDataStatus,
  PrivacyRequestConnectionStatus,
} from './enums';
import { WiredIdentifier } from './identifier';
import { PlaintextContext, PlaintextContextInput } from './plaintextContext';
import { Plugin } from './plugin';
import { PurposeSubCategoryPreview } from './processingPurpose';
import { SaaSCategoryBase } from './saaSCategoryBase';
import { Template } from './template';
import { VendorPreview } from './vendorPreview';

export const Region = mkType({
  name: 'Region',
  comment: 'ISO 3166 defined country and country subdivision',
  fields: {
    country: {
      comment: "A country's ISO code",
      optional: false,
      type: { IsoCountryCode },
    },
    countrySubDivision: {
      comment: 'A country subdivision ISO code',
      type: 'string',
      optional: true,
    },
  },
});

/** Override type */
export type Region = SchemaToType<typeof Region>;

/**
 * List of regions
 */
export type RegionsList = Region[];

export const RegionInput = mkInput({
  name: 'RegionInput',
  comment: 'A country and country subdivision nested in that country',
  fields: {
    country: {
      comment: "A country's ISO code",
      optional: true,
      type: { IsoCountryCode },
    },
    countrySubDivision: {
      comment: 'A country subdivision ISO code',
      type: 'string',
      optional: true,
    },
  },
});

/** Override type */
export type RegionInput = SchemaToType<typeof RegionInput>;

export const AddDataSiloToOpenRequestsInput = mkInput({
  name: 'AddDataSiloToOpenRequestsInput',
  comment: 'Add this data silo to a set of open requests',
  fields: {
    dataSiloId: {
      comment: 'The id of the data silo to add to requests',
      modelName: 'dataSilo',
      type: 'id',
    },
    requestIds: {
      comment:
        'The list of request IDs to attach to (if none, will attach all possible requests)',
      modelName: 'request',
      list: true,
      optional: true,
      type: 'id',
    },
  },
});

/** Override type */
export type AddDataSiloToOpenRequestsInput = SchemaToType<
  typeof AddDataSiloToOpenRequestsInput
>;

export const ConsentManagerCount = mkType({
  name: 'ConsentManagerCount',
  comment: 'The count of relevant consent manager labels',
  fields: applyEnum(ConsentTrackerStatus, (value) => ({
    comment: `Number of items in state: ${value}`,
    type: 'int',
  })),
});

/** Override type */
export type ConsentManagerCount = SchemaToType<typeof ConsentManagerCount>;

export const DataSiloInput = mkInput({
  name: 'DataSiloInput',
  comment: 'Inputs for connecting server/skip/saas/promptAPerson data silos',
  fields: {
    name: {
      comment: 'The name of the integration connecting',
      type: 'string',
    },
    outerType: {
      comment: `
        The catalog name responsible for the cosmetics of the integration (name, description, logo, email fields).
        The datapoints and everything else are still captured by the catalog enshrined in the "name" field.
      `,
      type: 'string',
      optional: true,
    },
    title: {
      comment: 'The title of the data silo',
      type: 'string',
      optional: true,
    },
    description: {
      comment: 'The description of the data silo',
      type: 'string',
      optional: true,
    },
    url: {
      comment: 'The URL to send POST request notifications to',
      type: 'string',
      optional: true,
    },
    defaultAccessRequestVisibility: {
      comment:
        'The default policy for visibility setting when creating subdatapoints',
      type: 'boolean',
      optional: true,
    },
    headers: {
      comment: 'Custom headers to include in outbound webhook',
      list: true,
      optional: true,
      type: CustomHeaderInput,
    },
    apiKeyId: {
      comment: 'The id of the existing api key to attach to',
      modelName: 'apiKey',
      // TODO: https://github.com/transcend-io/main/issues/1554 - make required
      optional: true,
      type: 'id',
    },
    promptEmailTemplateId: {
      comment: 'The id of template to use when prompting via email',
      modelName: 'template',
      optional: true,
      type: 'id',
    },
    notifyEmailAddress: {
      comment:
        'The email of the person that should be notified (an account will be created)',
      type: 'string',
      optional: true,
    },
    promptAVendorEmailIncludeIdentifiersAttachment: {
      comment:
        // eslint-disable-next-line max-len
        'Indicates whether prompt-a-vendor emails should include a list of identifiers in addition to a link to the bulk processing UI.',
      type: 'boolean',
      optional: true,
    },
    notifyWebhookUrl: {
      comment: 'The webhook URL to notify for manual actions',
      type: 'string',
      optional: true,
    },
    identifiers: {
      comment:
        'The names of the identifiers that the data silo should be connected to',
      optional: true,
      list: true,
      type: 'string',
    },
    isLive: {
      comment: 'Whether the data silo should be live',
      optional: true,
      type: 'boolean',
    },
    dependedOnDataSiloIds: {
      comment:
        'The IDs of the data silo that this data silo depends on during a deletion request.',
      modelName: 'dataSilo',
      optional: true,
      list: true,
      type: 'id',
    },
    dependedOnDataSiloTitles: {
      comment:
        'The titles of the data silo that this data silo depends on during a deletion request.',
      optional: true,
      list: true,
      type: 'string',
    },
    dataSubjectBlockListIds: {
      optional: true,
      comment: 'the list of subject IDs to block list from this data silo',
      type: 'id',
      modelName: 'subject',
      list: true,
    },
    ownerIds: {
      comment:
        'The unique ids of the users to assign as owners of this data silo',
      modelName: 'user',
      type: 'id',
      optional: true,
      list: true,
    },
    vendorId: {
      comment: 'The vendor that the data silo is assigned to',
      modelName: 'vendor',
      type: 'id',
      optional: true,
    },
    ownerEmails: {
      comment: 'The emails of the users to assign as owners of this data silo.',
      type: 'string',
      optional: true,
      list: true,
    },
    teams: {
      comment:
        'The ids of the teams that should be responsible for this data silo',
      type: 'id',
      modelName: 'team',
      list: true,
      optional: true,
    },
    teamNames: {
      comment:
        'The names of the teams that should be responsible for this data silo',
      type: 'string',
      list: true,
      optional: true,
    },
    receiverDataSiloIds: {
      comment:
        'The ids of the data silos that receive data from this data silo',
      type: 'id',
      modelName: 'dataSilo',
      list: true,
      optional: true,
    },
    plaintextContext: {
      comment: 'The form input plaintext',
      list: true,
      optional: true,
      type: PlaintextContextInput,
    },
    country: {
      comment: 'ISO country where data silo has data',
      type: { IsoCountryCode },
      optional: true,
    },
    countrySubDivision: {
      comment: 'ISO country where data silo has data',
      type: 'string',
      optional: true,
    },
    attributes: {
      comment:
        'User-defined key-value pairs that can be used to track additional metadata about your silo',
      optional: true,
      list: true,
      type: AttributeInput,
    },
  },
});

/** Override type */
export type DataSiloInput = SchemaToType<typeof DataSiloInput>;

export const DataSiloReducedQueryInput = mkInput({
  name: 'DataSiloReducedQueryInput',
  comment: 'Inputs for fetching a specific data silo with reduced fields',
  fields: {
    id: {
      comment: 'data silo id',
      optional: true,
      type: 'string',
    },
    token: {
      comment: 'data silo token',
      optional: true,
      type: 'string',
    },
  },
});

/** Override type */
export type DataSiloReducedQueryInput = SchemaToType<
  typeof DataSiloReducedQueryInput
>;

export const ReconnectDataSiloInput = mkInput({
  name: 'ReconnectDataSiloInput',
  comment: 'Input to reconnect a SaaS integration',
  fields: {
    // With wizard flow, PAP can be reconnected
    teams: DataSiloInput.fields.teams,
    ownerIds: DataSiloInput.fields.ownerIds,
    title: DataSiloInput.fields.title,
    promptEmailTemplateId: DataSiloInput.fields.promptEmailTemplateId,
    notifyEmailAddress: DataSiloInput.fields.notifyEmailAddress,
    description: DataSiloInput.fields.description,
    url: DataSiloInput.fields.url,
    dataSiloId: {
      comment: 'The id of the associated data silo',
      modelName: 'dataSilo',
      type: 'id',
    },
    presignedSaasContext: {
      comment:
        'A presigned JWT containing the SaaS Context for an integration.',
      optional: true,
      type: 'string',
    },
    plaintextContext: DataSiloInput.fields.plaintextContext,
  },
});

/** Override type */
export type ReconnectDataSiloInput = SchemaToType<
  typeof ReconnectDataSiloInput
>;

export const DataSiloFiltersInput = mkInput({
  name: 'DataSiloFiltersInput',
  comment: 'Inputs for determining what data silos to filter',
  fields: {
    text: {
      comment: 'Filter by text (title or description of data silo)',
      optional: true,
      type: 'string',
    },
    titles: {
      comment: 'Filter on direct title matches. Data silo titles are unique',
      optional: true,
      list: true,
      type: 'string',
    },
    type: {
      comment: 'Filter by the data silo type',
      optional: true,
      list: true,
      type: 'string',
    },
    actions: {
      comment: 'Filter for data silos that support a specific request action',
      optional: true,
      list: true,
      type: { RequestAction },
    },
    ids: {
      comment: 'Filter for sender data silo IDs',
      optional: true,
      list: true,
      type: 'id',
      modelName: 'dataSilo',
    },
    isLive: {
      comment: 'Filter for data silos that are live',
      optional: true,
      type: 'boolean',
    },
    isPaidIntegration: {
      comment:
        'Filter for data silos that count against the contract integration count',
      optional: true,
      type: 'boolean',
    },
    canCustomizeDataPoints: {
      comment: 'Filter for data silos where datapoints are customizable',
      optional: true,
      type: 'boolean',
    },
    isDeleted: {
      comment: 'Filter for data silos that are deleted',
      optional: true,
      type: 'boolean',
    },
    connectionState: {
      comment: 'Filter by the connection state',
      list: true,
      optional: true,
      type: { DataSiloConnectionState },
    },
    plugins: {
      comment: 'Filter by plugin type',
      optional: true,
      list: true,
      type: { PluginType },
    },
    pluginsEnabled: {
      comment: 'Filter by whether plugin(s) are enabled',
      optional: true,
      type: 'boolean',
    },
    owners: {
      comment: 'Filter by the assigned owner IDs',
      optional: true,
      type: 'id',
      modelName: 'user',
      list: true,
    },
    vendors: {
      comment: 'Filter by the assigned vendor IDs',
      optional: true,
      type: 'id',
      modelName: 'vendor',
      list: true,
    },
    teams: {
      comment: 'Filter by the assigned team IDs',
      optional: true,
      type: 'id',
      modelName: 'team',
      list: true,
    },
    saaSCategoryIds: {
      comment: 'Filter by the SaaS Category/recipient category IDs',
      optional: true,
      type: 'id',
      modelName: 'saaSCategory',
      list: true,
    },
    purposes: {
      comment: 'Filter by purpose of processing',
      type: { ProcessingPurpose },
      list: true,
      optional: true,
    },
    processingPurposeSubCategoryIds: {
      comment: 'Filter by specific subcategory of processing purpose',
      optional: true,
      type: 'id',
      modelName: 'processingPurposeSubCategory',
      list: true,
    },
    receiverDataSiloIds: {
      comment:
        'Filter by the IDs of the data silos that receive data from this silo',
      optional: true,
      type: 'id',
      modelName: 'dataSilo',
      list: true,
    },
    dataProcessingAgreementStatus: {
      comment: 'Filter by the status of a DPA with that vendor',
      optional: true,
      type: { DataProcessingAgreementStatus },
    },
    recommendedForConsent: {
      comment:
        'Filter by whether the data silo is recommended for consent manager purposes',
      optional: true,
      type: { ConsentManagerConnectionStatus },
    },
    recommendedForPrivacy: {
      comment:
        'Filter by whether the data silo is recommended for privacy request purposes',
      optional: true,
      type: { PrivacyRequestConnectionStatus },
    },
    hasPersonalData: {
      comment: 'Filter by whether the data silo has personal data in it',
      optional: true,
      type: { HasPersonalDataStatus },
    },
    deprecationState: {
      comment: 'Filter by whether the data silo has been deprecated',
      optional: true,
      type: { DataSiloDeprecationState },
    },
    dataSubjectIds: {
      optional: true,
      comment: 'Filter by ids of data subjects included in this data silo',
      type: 'id',
      modelName: 'subject',
      list: true,
    },
    attributeValueIds: {
      comment: 'Filter by the attribute values used to label the data silos',
      type: 'id',
      modelName: 'attributeValue',
      list: true,
      optional: true,
    },
    includeNulls: {
      comment: 'A list of fields for which to include null',
      type: { DataSiloNullableFilters },
      list: true,
      optional: true,
    },
    country: {
      comment: 'Filter by ISO country',
      type: { IsoCountryCode },
      optional: true,
      list: true,
    },
    countrySubDivision: {
      comment: 'Filter by ISO country subdivision',
      type: 'string',
      optional: true,
      list: true,
    },
    transferRegions: {
      comment: 'Filter by transfer regions',
      optional: true,
      type: RegionInput,
      list: true,
    },
    connectorType: {
      comment: 'Filter by connector type',
      type: { ConnectorType },
      optional: true,
    },
    discoveredBy: {
      comment: 'Filter for data silo discovered by IDs',
      optional: true,
      list: true,
      type: 'id',
      modelName: 'dataSilo',
    },
    businessEntityIds: {
      comment: 'Filter by the associated business entities',
      optional: true,
      type: 'id',
      modelName: 'businessEntity',
      list: true,
    },
    vendorBusinessEntityIds: {
      comment: 'Filter by the associated vendor business entities',
      optional: true,
      type: 'id',
      modelName: 'businessEntity',
      list: true,
    },
    dataCategories: {
      comment: 'Filter by categories of personal data',
      type: { DataCategoryType },
      list: true,
      optional: true,
    },
    dataSubCategoryIds: {
      comment: 'Filter by specific subcategories of personal data',
      type: 'id',
      modelName: 'dataSubCategory',
      optional: true,
      list: true,
    },
    controllerships: {
      comment:
        'Filter by controllerships assigned to subdatapoints of this data silo',
      type: { Controllership },
      list: true,
      optional: true,
    },
    workflowConfigId: {
      comment: 'Filter by the associated workflow config',
      optional: true,
      type: 'id',
      modelName: 'workflowConfig',
    },
    notAssociatedWithWorkflowConfigId: {
      comment: 'Filter by data silos not associated with a workflow config',
      optional: true,
      type: 'id',
      modelName: 'workflowConfig',
    },
  },
});

/** Override type */
export type DataSiloFiltersInput = SchemaToType<typeof DataSiloFiltersInput>;

export const ContentClassificationDataSiloFiltersInput = mkInput({
  name: 'ContentClassificationDataSiloFiltersInput',
  comment:
    'Inputs for determining what content classification data silos to filter',
  fields: {
    ids: {
      comment: 'Filter by data silo ids',
      type: 'id',
      modelName: 'dataSilo',
      optional: true,
      list: true,
    },
    text: {
      comment: 'Filter by text (title of data silo)',
      optional: true,
      type: 'string',
    },
    type: {
      comment: 'Filter by the data silo type',
      optional: true,
      list: true,
      type: 'string',
    },
    isDeleted: {
      comment: 'Filter for data silos that are deleted',
      optional: true,
      type: 'boolean',
    },
    pluginsEnabled: {
      comment: 'Filter by whether plugin(s) are enabled',
      optional: true,
      type: 'boolean',
    },
    owners: {
      comment: 'Filter by the assigned owner IDs',
      optional: true,
      type: 'id',
      modelName: 'user',
      list: true,
    },
    teams: {
      comment: 'Filter by the assigned team IDs',
      optional: true,
      type: 'id',
      modelName: 'team',
      list: true,
    },
  },
});

/** Override type */
export type ContentClassificationDataSiloFiltersInput = SchemaToType<
  typeof ContentClassificationDataSiloFiltersInput
>;

export const UpdateDataSiloInput = mkInput({
  name: 'UpdateDataSiloInput',
  comment: "Input for updating a data silo's attributes",
  fields: {
    id: {
      comment: 'The id of the data silo to update',
      modelName: 'dataSilo',
      type: 'id',
    },
    title: {
      comment: 'The title of the data silo',
      optional: true,
      type: 'string',
    },
    description: {
      comment: 'The description of the data silo',
      optional: true,
      type: 'string',
    },
    notifyEmailAddress: {
      comment: 'The email address to notify when a prompt a person silo',
      optional: true,
      type: 'string',
    },
    notifyWebhookUrl: {
      comment: 'The URL of the webhook to notify on manual action',
      type: 'string',
      optional: true,
    },
    promptAVendorEmailIncludeIdentifiersAttachment:
      DataSiloInput.fields.promptAVendorEmailIncludeIdentifiersAttachment,
    promptEmailTemplateId: {
      comment: 'The id of template to use when prompting via email',
      modelName: 'template',
      optional: true,
      type: 'id',
    },
    promptAVendorEmailSendType: {
      comment:
        // eslint-disable-next-line max-len
        'The updated type of emails to send for this data silo, i.e. send an email for each DSR, across all open DSRs, or per profile in a DSR.',
      type: { PromptAVendorEmailSendType },
      optional: true,
    },
    promptAVendorEmailCompletionLinkType: {
      comment:
        'Indicates what kind of link to generate as part of the emails sent out for this PaV silo.',
      type: { PromptAVendorEmailCompletionLinkType },
      optional: true,
    },
    promptAVendorEmailSendFrequency: {
      comment:
        'The updated frequency with which we should be sending emails for this data silo, in milliseconds.',
      type: 'int',
      optional: true,
    },
    manualWorkRetryFrequency: {
      comment:
        'The updated frequency with which we should retry sending emails for this data silo, in milliseconds. ' +
        'Needs to be a string because the number can be larger than the MAX_INT',
      type: 'string',
      optional: true,
    },
    promptAVendorEmailStartAt: {
      comment:
        "The updated time this data silo's prompt-a-vendor email should start being sent",
      type: 'Date',
      optional: true,
    },
    manualWorkRetryStartAt: {
      comment:
        'The updated start time when we should start retrying emails for this data silo, in ISO format.',
      type: 'Date',
      optional: true,
    },
    identifiers: {
      comment:
        'The names of the identifiers that the data silo should be connected to',
      optional: true,
      list: true,
      type: 'string',
    },
    dataSubjectBlockListIds: {
      optional: true,
      comment: 'the list of subject IDs to block list from this data silo',
      type: 'id',
      modelName: 'subject',
      list: true,
    },
    url: {
      comment: 'The URL of the server to post to if a server silo',
      optional: true,
      type: 'string',
    },
    defaultAccessRequestVisibility: {
      comment:
        'The default policy for visibility setting when creating subdatapoints',
      optional: true,
      type: 'boolean',
    },
    isLive: {
      comment: 'Whether the data silo should be live',
      optional: true,
      type: 'boolean',
    },
    headers: {
      comment: 'The custom server headers',
      optional: true,
      list: true,
      type: CustomHeaderInput,
    },
    attributes: DataSiloInput.fields.attributes,
    dependedOnDataSiloIds: DataSiloInput.fields.dependedOnDataSiloIds,
    dependedOnDataSiloTitles: DataSiloInput.fields.dependedOnDataSiloTitles,
    ownerIds: DataSiloInput.fields.ownerIds,
    apiKeyId: DataSiloInput.fields.apiKeyId,
    ownerEmails: DataSiloInput.fields.ownerEmails,
    teams: DataSiloInput.fields.teams,
    vendorId: DataSiloInput.fields.vendorId,
    teamNames: DataSiloInput.fields.teamNames,
    receiverDataSiloIds: DataSiloInput.fields.receiverDataSiloIds,
    plaintextContext: DataSiloInput.fields.plaintextContext,
    controllerships: {
      comment: "The organization's controllership of the data silo",
      type: { Controllership },
      list: true,
      optional: true,
    },
    // data inventory fields below
    notes: {
      comment: 'Freeform notes about the data silo',
      type: 'string',
      optional: true,
    },
    dataRetentionNote: {
      comment:
        'A freeform note talking about data retention policy at high level',
      type: 'string',
      optional: true,
    },
    dataProcessingAgreementLink: {
      comment:
        'A link to the DPA for this data silo or a note about the status',
      optional: true,
      type: 'string',
    },
    contactName: {
      comment: 'Contact name of person related to email',
      optional: true,
      type: 'string',
    },
    contactEmail: {
      comment:
        'Contact email to use for this data silo (often a third party contact)',
      optional: true,
      type: 'string',
    },
    websiteUrl: {
      comment: 'A link to the website of the data silo, if applicable.',
      optional: true,
      type: 'string',
    },
    dataProcessingAgreementStatus: {
      comment: 'The status of a DPA with that vendor',
      optional: true,
      type: { DataProcessingAgreementStatus },
    },
    recommendedForConsent: {
      comment:
        'Whether the integration is recommended for consent manager purposes',
      optional: true,
      type: { ConsentManagerConnectionStatus },
    },
    recommendedForPrivacy: {
      comment:
        'Whether the integration is recommended for privacy request purposes',
      optional: true,
      type: { PrivacyRequestConnectionStatus },
    },
    hasPersonalData: {
      comment: 'Whether the data silo has personal data in it',
      optional: true,
      type: { HasPersonalDataStatus },
    },
    deprecationState: {
      comment: 'Whether the data silo has been deprecated',
      optional: true,
      type: { DataSiloDeprecationState },
    },
    country: {
      comment: 'ISO country where data silo has data in',
      type: { IsoCountryCode },
      optional: true,
    },
    countrySubDivision: {
      comment: 'ISO country where data silo has data in',
      type: 'string',
      optional: true,
    },
    transferRegions: {
      comment:
        'Countries and country subdivisions to which this data silo transfers data',
      type: RegionInput,
      optional: true,
      list: true,
    },
    sombraId: {
      comment:
        'The ID of the sombra to use for communication with this data silo',
      type: 'id',
      modelName: 'sombra',
      optional: true,
    },
    dataSubCategoryIds: {
      comment: 'The IDs of the data subcategories for this data silo',
      type: 'id',
      modelName: 'dataSubCategory',
      list: true,
      optional: true,
    },
    processingPurposeSubCategoryIds: {
      comment:
        'The IDs of the processing purpose subcategories for this data silo',
      type: 'id',
      modelName: 'processingPurposeSubCategory',
      list: true,
      optional: true,
    },
    saaSCategoryIds: {
      comment: 'The IDs of SaaS categories to assign to this data silo',
      type: 'id',
      modelName: 'saaSCategory',
      list: true,
      optional: true,
    },
    businessEntityIds: {
      comment: 'Update the associated business entities for the data silo',
      optional: true,
      type: 'id',
      modelName: 'businessEntity',
      list: true,
    },
    businessEntityTitles: {
      comment: 'Update the associated business entity titles for the data silo',
      optional: true,
      type: 'string',
      list: true,
    },
  },
});

/** Override type */
export type UpdateDataSiloInput = SchemaToType<typeof UpdateDataSiloInput>;

export const UpdateDataSilosInput = mkInput({
  name: 'UpdateDataSilosInput',
  comment: 'Input for bulk updating one or more data silos',
  fields: {
    dataSilos: {
      comment:
        'List of data silos with the properties that should be updated for each',
      type: UpdateDataSiloInput,
      list: true,
    },
  },
});

/** Override type */
export type UpdateDataSilosInput = SchemaToType<typeof UpdateDataSilosInput>;

export const FireIntegrationsInput = mkInput({
  name: 'FireIntegrationsInput',
  comment: `The input to fire an integration for a single request or for all requests associated with a data silo,
  at least one of these must be provided`,
  fields: {
    requestId: {
      comment: 'The request id to re-trigger for',
      modelName: 'request',
      optional: true,
      type: 'id',
    },
    dataSiloId: {
      comment: 'The data silo id to re-trigger for',
      modelName: 'dataSilo',
      optional: true,
      type: 'id',
    },
  },
});

/** Override type */
export type FireIntegrationsInput = SchemaToType<typeof FireIntegrationsInput>;

export const CreateDataSilosInput = mkInput({
  name: 'CreateDataSilosInput',
  comment: 'Input for creating data silos without any connection details',
  fields: {
    name: {
      comment:
        'The integration name (has to match the integrationName as it appears in our catalog)',
      type: 'string',
    },
    pluginId: {
      ...DataSiloDiscoPluginRecommendationKey.fields.pluginId,
      optional: true,
    },
    resourceId: {
      ...DataSiloDiscoPluginRecommendationKey.fields.resourceId,
      optional: true,
    },
    region: {
      ...DataSiloDiscoPluginRecommendationKey.fields.region,
      optional: true,
    },
    country: {
      ...DataSiloDiscoPluginRecommendationKey.fields.country,
      optional: true,
    },
    countrySubDivision: {
      ...DataSiloDiscoPluginRecommendationKey.fields.countrySubDivision,
      optional: true,
    },
    transferRegions: UpdateDataSiloInput.fields.transferRegions,
    saaSCategoryIds: UpdateDataSiloInput.fields.saaSCategoryIds,
    title: {
      ...DataSiloDiscoPluginRecommendationKey.fields.title,
      optional: true,
    },
    description: {
      comment: 'The custom description of the data silo.',
      type: 'string',
      optional: true,
    },
    plaintextContext: {
      ...DataSiloDiscoPluginRecommendationKey.fields.plaintextContext,
      optional: true,
    },
    businessEntityIds: UpdateDataSiloInput.fields.businessEntityIds,
    businessEntityTitles: UpdateDataSiloInput.fields.businessEntityTitles,
    sombraId: {
      comment:
        'The ID of the sombra to use for communication with this data silo',
      type: 'id',
      modelName: 'sombra',
      optional: true,
    },
    controllerships: UpdateDataSiloInput.fields.controllerships,
    pluginConfigurations: {
      comment: 'The plugin configurations for the recommendation',
      type: 'string',
      optional: true,
    },
  },
});

/** Override type */
export type CreateDataSilosInput = SchemaToType<typeof CreateDataSilosInput>;

export const DatamapDataSiloInterface = mkInterface({
  name: 'DatamapDataSiloInterface',
  comment:
    'The interface for a data silo in the datamap, viewed on the datamap page',
  fields: {
    ...DataSiloInterface.fields,
    saaSCategories: {
      comment: 'The SaaS categories for this data silo',
      type: SaaSCategoryBase,
      list: true,
    },
    defaultAccessRequestVisibility: {
      comment:
        'The default policy for visibility setting when creating subdatapoints',
      type: 'boolean',
    },
    hasEmailEnabled: {
      comment:
        'When true, the data silo is configured to communicate with an email address to manually fulfil requests',
      type: 'boolean',
    },
    errorCount: {
      comment:
        'The number of requests that are active and have encountered an error processing',
      type: 'int',
    },
    subDataPointCount: {
      comment: 'The number of subdatapoints this data silo has',
      type: 'int',
    },
    dataFlowCount: {
      comment:
        'The number of data flows found associated to the same catalog entry as this data silo',
      type: ConsentManagerCount,
    },
    cookieCount: {
      comment:
        'The number of cookies found associated to the same catalog entry as this data silo',
      type: ConsentManagerCount,
    },
    notifyEmailAddress: {
      comment:
        'The email address that should be notified whenever new requests are made',
      optional: true,
      type: 'string',
    },
    notifyWebhookUrl: {
      comment: 'The URL of the webhook to notify on manual action',
      type: 'string',
      optional: true,
    },
    subjectBlocklist: {
      comment: 'The list of data subjects that are blocked for this data silo',
      type: DataSubject,
      list: true,
    },
    subjects: {
      comment:
        'The list of data subjects that are not blocked for this data silo',
      type: DataSubject,
      list: true,
    },
    attributeValues: {
      comment: 'The attribute values used to label this data silo',
      type: AttributeValue,
      list: true,
    },
    notes: {
      comment: 'Freeform notes about the data silo',
      type: 'string',
    },
    dataRetentionNote: {
      comment:
        'A freeform note talking about data retention policy at high level',
      type: 'string',
    },
    dataProcessingAgreementLink: {
      comment:
        'A link to the DPA for this data silo or a note about the status',
      optional: true,
      type: 'string',
    },
    websiteUrl: {
      comment: 'A link to the website of the data silo, if applicable.',
      optional: true,
      type: 'string',
    },
    contactName: {
      comment: 'Contact name of person related to email',
      optional: true,
      type: 'string',
    },
    contactEmail: {
      comment:
        'Contact email to use for this data silo (often a third party contact)',
      optional: true,
      type: 'string',
    },
    dataProcessingAgreementStatus: {
      comment: 'The status of a DPA with that vendor',
      type: { DataProcessingAgreementStatus },
    },
    recommendedForConsent: {
      comment:
        'Whether the integration is recommended for consent manager purposes',
      type: { ConsentManagerConnectionStatus },
    },
    recommendedForPrivacy: {
      comment:
        'Whether the integration is recommended for privacy request purposes',
      type: { PrivacyRequestConnectionStatus },
    },
    hasPersonalData: {
      comment: 'Whether the data silo has personal data in it',
      type: { HasPersonalDataStatus },
    },
    deprecationState: {
      comment: 'Whether the data silo has been deprecated',
      type: { DataSiloDeprecationState },
    },
    country: {
      comment: 'ISO country where data silo has data in',
      type: { IsoCountryCode },
      optional: true,
    },
    countrySubDivision: {
      comment: 'ISO country where data silo has data in',
      type: 'string',
      optional: true,
    },
    vendor: {
      comment: 'The vendor that the data silo is for',
      optional: true,
      type: VendorPreview,
    },
    identifiers: {
      comment: 'The identifiers that can key the data silo',
      type: () => WiredIdentifier,
      list: true,
    },
    receiverDataSilos: {
      comment: 'The list of data silos that receive data from this data silo',
      list: true,
      type: DataSiloPreview,
    },
    controllerships: {
      comment: 'The controllership values assigned to this data silo',
      type: { Controllership },
      list: true,
    },
    subDataPointControllerships: {
      comment:
        'The controllership values assigned to the sub-datapoints of this data silo',
      type: { Controllership },
      list: true,
    },
    promptEmailTemplate: {
      comment: 'the template to use for the prompt-a-vendor email',
      type: Template,
      optional: true,
    },
    promptAVendorEmailSendFrequency: {
      comment:
        'The frequency with which we should be sending emails for this data silo, in milliseconds.',
      type: 'int',
    },
    promptAVendorEmailSendType: {
      comment:
        // eslint-disable-next-line max-len
        'The type of emails to send for this data silo, i.e. send an email for each DSR, across all open DSRs, or per profile in a DSR.',
      type: { PromptAVendorEmailSendType },
    },
    promptAVendorEmailIncludeIdentifiersAttachment: {
      comment:
        // eslint-disable-next-line max-len
        'Indicates whether prompt-a-vendor emails should include a list of identifiers in addition to a link to the bulk processing UI.',
      type: 'boolean',
    },
    promptAVendorEmailCompletionLinkType: {
      comment:
        'Indicates what kind of link to generate as part of the emails sent out for this PaV silo.',
      type: { PromptAVendorEmailCompletionLinkType },
    },
    manualWorkRetryFrequency: {
      comment:
        'The updated frequency with which we should retry sending emails for this data silo, in milliseconds. ' +
        'Needs to be a string because the number can be larger than the MAX_INT',
      type: 'string',
    },
    connectedActions: {
      comment: 'The data actions configured for this data silo',
      list: true,
      type: { RequestActionObjectResolver },
    },
    dependentDataSilos: {
      comment:
        'A list of data silos that the parent data silo depends on completing before it runs',
      type: DataSiloPreview,
      list: true,
    },
    apiKeys: {
      comment: 'The API keys that have access to this data silo',
      type: () => ApiKeyPreview,
      list: true,
    },
    businessEntities: {
      comment: 'The business entities assigned directly to the data silo',
      list: true,
      type: BusinessEntityPreview,
    },
  },
});

/** Override type */
export type DatamapDataSiloInterface = SchemaToType<
  typeof DatamapDataSiloInterface
>;

export const DataSiloBulkPreview = mkType({
  name: 'DataSiloBulkPreview',
  comment: 'Preview of a data silo in a list',
  interfaces: [DatamapDataSiloInterface],
  fields: {
    ...DatamapDataSiloInterface.fields,
    dataSubCategories: {
      comment: 'The set of data categories assigned to this data silo',
      type: DataSubCategoryPreview,
      list: true,
    },
    processingPurposeSubCategories: {
      comment: 'The set of processing purposes assigned to this data silo',
      type: PurposeSubCategoryPreview,
      list: true,
    },
    subDataPointDataSubCategories: {
      comment:
        'The set of data categories assigned to the sub-datapoints of this data silo',
      type: DataSubCategoryPreview,
      list: true,
    },
    subDataPointProcessingPurposeSubCategories: {
      comment:
        'The set of processing purposes assigned to the sub-datapoints of this data silo',
      type: PurposeSubCategoryPreview,
      list: true,
    },
    silosDiscovered: {
      comment: 'Data silos discovered when scanning this data silo',
      list: true,
      type: SilosDiscoveredPreview,
      optional: true,
    },
    transferRegions: {
      comment: 'The regions where data is transferred to',
      type: Region,
      list: true,
    },
    dataSiloSuperCategories: {
      comment:
        'Super Categories associated with data subcategories assigned to this data silo',
      type: AttributeValue,
      list: true,
    },
    subDataPointSuperCategories: {
      comment:
        "Super Categories associated with data subcategories assigned to this data silo's subdatapoints",
      type: AttributeValue,
      list: true,
    },
    workflowConfigId: {
      comment: 'Workflow configs that are associated with this data silo',
      type: 'id',
      modelName: 'workflowConfig',
      optional: true,
    },
  },
});

/** Override type */
export type DataSiloBulkPreview = SchemaToType<typeof DataSiloBulkPreview>;

export const ContentClassificationDataSilo = mkType({
  name: 'ContentClassificationDataSilo',
  comment: 'A data silo configured for content classification',
  interfaces: [DataSiloInterface],
  fields: {
    ...DataSiloInterface.fields,
    subDataPointCount: {
      comment: 'The number of subdatapoints this data silo has',
      type: 'int',
    },
    plugins: {
      comment: 'All plugins associated with this data silo',
      type: Plugin,
      list: true,
    },
  },
});

/** Override type */
export type ContentClassificationDataSilo = SchemaToType<
  typeof ContentClassificationDataSilo
>;

export const UnstructuredDiscoveryDataSilo = mkType({
  name: 'UnstructuredDiscoveryDataSilo',
  comment: 'A data silo configured for unstructured discovery',
  interfaces: [DataSiloInterface],
  fields: {
    ...DataSiloInterface.fields,
    plugins: {
      comment: 'All plugins associated with this data silo',
      type: Plugin,
      list: true,
    },
    fileCount: {
      comment: 'Count of files discovered by this plugin',
      type: 'int',
    },
  },
});

/** Override type */
export type UnstructuredDiscoveryDataSilo = SchemaToType<
  typeof UnstructuredDiscoveryDataSilo
>;

export const DataSilo = mkType({
  name: 'DataSilo',
  comment:
    'A place in the organization where data sits. This could be a database, SaaS tool, filing cabinet, etc.',
  interfaces: [DatamapDataSiloInterface],
  fields: {
    ...DatamapDataSiloInterface.fields,
    isPaidIntegration: {
      comment:
        'Whether the integration counts towards the allotment of integrations in the contract',
      type: 'boolean',
    },
    privacyRequestSubdatapointEnabled: {
      comment: 'When true, the subdatapoint settings are enabled',
      type: 'boolean',
    },
    users: {
      comment: 'The user that is being notified',
      type: () => UserPreview,
      list: true,
    },
    plaintextContext: {
      comment: 'The plaintext context parameters',
      type: PlaintextContext,
      list: true,
    },
    reconnectFormItems: {
      comment: 'The form items that must be reconnected, if applicable',
      optional: true,
      list: true,
      type: 'string',
    },
    allowedPlaintextPaths: {
      comment: 'The plaintext paths that have been approved by the client',
      list: true,
      type: 'string',
    },
    businessEntity: {
      comment: 'The business entity that is inherited from the vendor',
      optional: true,
      type: BusinessEntityPreview,
    },
    openRequestCount: {
      comment: 'The number of open requests pertaining to this silo',
      type: 'int',
    },
    promptAVendorEmailStartAt: {
      comment:
        "Time this data silo's prompt-a-vendor email should start being sent",
      type: 'Date',
    },
    promptEmailTemplateId: {
      comment: 'The template ID of the email template used for notifications.',
      type: 'id',
      modelName: 'template',
      optional: true,
    },
    promptAVendorEmailScheduledAt: {
      comment: 'The datetime to send the next email for this data silo.',
      type: 'Date',
      optional: true,
    },
    databaseQuerySuggestionCount: {
      comment:
        'True if the data silo has outstanding database query suggestions',
      type: 'int',
    },
    manualWorkRetryStartAt: {
      comment:
        'The updated start time when we should start retrying emails for this data silo, in ISO format.',
      type: 'Date',
      optional: true,
    },
    dataSubCategories: DataSiloBulkPreview.fields.dataSubCategories,
    processingPurposeSubCategories:
      DataSiloBulkPreview.fields.processingPurposeSubCategories,
    subDataPointDataSubCategories:
      DataSiloBulkPreview.fields.subDataPointDataSubCategories,
    subDataPointProcessingPurposeSubCategories:
      DataSiloBulkPreview.fields.subDataPointProcessingPurposeSubCategories,
    transferRegions: DataSiloBulkPreview.fields.transferRegions,
    silosDiscovered: {
      comment: 'Data silos discovered when scanning this data silo',
      list: true,
      type: SilosDiscoveredPreview,
      optional: true,
    },
    sombraId: {
      comment: 'sombra associated with this data silo',
      type: 'id',
      modelName: 'sombra',
      optional: true,
    },
    sombra: {
      comment:
        'The associated sombra to this integration - when not specified, the default sombra is used',
      type: SombraPreview,
      optional: true,
    },
    enricherIdentifierMappings: {
      comment:
        'The identifier mappings for this data silo, if it has a DB or looker enricher attached to it',
      type: SignedIdentifierPathSchema,
      list: true,
    },
    workflowConfigId: DataSiloBulkPreview.fields.workflowConfigId,
  },
});

/** Override type */
export type DataSilo = SchemaToType<typeof DataSilo>;

export const DataSiloReduced = mkType({
  name: 'DataSiloReduced',
  comment: 'A data silo with a reduced set of fields',
  fields: {
    id: DiscoveredByDataSiloPreview.fields.id,
    title: DiscoveredByDataSiloPreview.fields.title,
    catalog: DataSiloInterface.fields.catalog,
    sombra: {
      comment: 'Sombra metadata',
      type: SombraPreview,
    },
  },
});

/** Override type */
export type DataSiloReduced = SchemaToType<typeof DataSiloReduced>;

export const DataLineageDataSilo = mkType({
  name: 'DataLineageDataSilo',
  comment: 'A data silo used in building a data lineage graph',
  fields: {
    id: DiscoveredByDataSiloPreview.fields.id,
    title: DiscoveredByDataSiloPreview.fields.title,
    catalog: DiscoveredByDataSiloPreview.fields.catalog,
    receiverDataSiloId: {
      comment: 'The id of the data silo that receives data from this data silo',
      type: 'id',
      modelName: 'dataSilo',
      optional: true,
    },
  },
});

/** Override type */
export type DataLineageDataSilo = SchemaToType<typeof DataLineageDataSilo>;

export const DataSiloLineageFiltersInput = mkInput({
  name: 'DataSiloLineageFiltersInput',
  comment: 'Inputs for determining what data silos to filter',
  fields: {
    ids: {
      comment: 'Filter for data silo IDs',
      optional: true,
      list: true,
      type: 'id',
      modelName: 'dataSilo',
    },
    purposes: {
      comment: 'Filter by purpose of processing',
      type: { ProcessingPurpose },
      list: true,
      optional: true,
    },
    processingPurposeSubCategoryIds: {
      comment: 'Filter by specific subcategory of processing purpose',
      optional: true,
      type: 'id',
      modelName: 'processingPurposeSubCategory',
      list: true,
    },
    dataCategories: {
      comment: 'Filter by categories of personal data',
      type: { DataCategoryType },
      list: true,
      optional: true,
    },
    dataSubCategoryIds: {
      comment: 'Filter by specific subcategories of personal data',
      type: 'id',
      modelName: 'dataSubCategory',
      optional: true,
      list: true,
    },
    includeUnconnected: {
      comment:
        'Include in the results data silos that have no sender or receiver relationships',
      type: 'boolean',
      optional: true,
    },
  },
});

/** Override type */
export type DataSiloLineageFiltersInput = SchemaToType<
  typeof DataSiloLineageFiltersInput
>;

/**
 * Order for a assetFile query
 */
export const DataSiloBulkPreviewOrder = mkOrder(
  DataSiloBulkPreview.name,
  DataSiloBulkPreviewOrderField,
);

/** Override type */
export type DataSiloBulkPreviewOrder = SchemaToType<
  typeof DataSiloBulkPreviewOrder
>;

export const DeleteDataSilosInput = mkInput({
  name: 'DeleteDataSilosInput',
  comment: 'Input for deleting data silos for an organization',
  fields: {
    ids: {
      comment: 'The list of IDs to delete',
      type: 'id',
      modelName: 'dataSilo',
      list: true,
    },
  },
});

/** Override type */
export type DeleteDataSilosInput = SchemaToType<typeof DeleteDataSilosInput>;

export const RateLimitConfigInput = mkInput({
  name: 'RateLimitConfigInput',
  comment: 'Rate limit configuration for a data silo',
  fields: {
    rateLimitType: {
      comment: 'Rate limit type',
      type: 'string',
    },
    rateLimitQuantity: {
      comment: 'Rate limit quantity',
      type: 'int',
    },
    rateLimitWaitTimeMs: {
      comment: 'Milliseconds to wait upon exhaustion of this rate limit',
      type: 'int',
    },
  },
});

/** Override type */
export type RateLimitConfigInput = SchemaToType<typeof RateLimitConfigInput>;

export const RateLimitConfig = mkType({
  name: 'RateLimitConfig',
  comment: 'Rate limit configuration',
  fields: {
    ...RateLimitConfigInput.fields,
    rateLimitResetAt: {
      type: 'Date',
      comment: 'Unix epoch in milliseconds to when the rate limit will reset',
    },
    counter: {
      type: 'int',
      comment: 'Number of calls made against this rate limit',
    },
    isUserModified: {
      type: 'boolean',
      comment: 'If this rate limit has been modified/added by a user',
    },
  },
});

/** Override type */
export type RateLimitConfig = SchemaToType<typeof RateLimitConfig>;

export const UpdateDataSiloRateLimitsInput = mkInput({
  name: 'UpdateDataSiloRateLimitsInput',
  comment: 'Update data silo rate limits input',
  fields: {
    id: {
      type: 'id',
      modelName: 'dataSilo',
      comment: 'The data silo id',
    },
    rateLimitConfigs: {
      comment: 'Rate limit configurations',
      type: RateLimitConfigInput,
      list: true,
    },
  },
});

/** Override type */
export type UpdateDataSiloRateLimitsInput = SchemaToType<
  typeof UpdateDataSiloRateLimitsInput
>;

export const RestartSiloObjectClassificationsInput = mkInput({
  name: 'RestartSiloObjectClassificationsInput',
  comment:
    'Input for restarting the classification of the scanned objects of a data silo',
  fields: {
    dataSiloId: {
      comment: 'The id of the data silo',
      type: 'id',
      modelName: 'dataSilo',
    },
    clearExistingClassifications: {
      comment:
        'Whether to remove all existing classifications for scanned objects.',
      type: 'boolean',
      optional: true,
    },
  },
});

/** Override type */
export type RestartSiloObjectClassificationsInput = SchemaToType<
  typeof RestartSiloObjectClassificationsInput
>;

export const RestartSiloClassificationInput = mkInput({
  name: 'RestartSiloClassificationInput',
  comment:
    'Input for restarting the classification of the contents of a data silo',
  fields: {
    dataSiloId: {
      comment: 'The id of the data silo',
      type: 'id',
      modelName: 'dataSilo',
    },
    clearPendingGuesses: {
      comment:
        'Whether to remove unconfirmed guesses in addition to triggering a rescan. Defaults to true.',
      type: 'boolean',
      optional: true,
    },
  },
});

/** Override type */
export type RestartSiloClassificationInput = SchemaToType<
  typeof RestartSiloClassificationInput
>;

/* eslint-enable max-lines */
