/**
 * Save a string to a file
 *
 * @param filename - Name of the file to save to
 * @param text - The text to write
 */
export function downloadToDisk(filename: string, text: string): void {
  const pom = document.createElement('a');
  const objectURI = URL.createObjectURL(
    new Blob([text], { type: 'text/plain;charset=utf-8' }),
  );
  pom.setAttribute('href', objectURI);
  pom.setAttribute('download', filename);

  if (pom.click) {
    pom.click();
  } else {
    const event = document.createEvent('MouseEvents');
    event.initEvent('click', true, true);
    pom.dispatchEvent(event);
  }
  // Auto-revoke object URI in 30 seconds
  setTimeout(() => {
    URL.revokeObjectURL(objectURI);
  }, 30000);
}
