import { defineMessages } from '@transcend-io/internationalization';

export const requestSettingsMessages = defineMessages(
  'admin-dashboard.PrivacyRequests.RequestSettings.requestSettings',
  {
    header: {
      defaultMessage: 'Data Subject Requests',
    },
  },
);
