import {
  ErrorText,
  FlexColumn,
  FlexRowCenterVertical,
  Icon,
  message as msg,
  Popover,
  Progress,
  StyleUtils,
} from '@main/core-ui';
import { ONE_SECOND } from '@main/utils';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import { Button } from '../Button';
import { downloadZipButtonMessages } from './messages';
import type { DownloadZipStatus } from './types';

/**
 * DownloadRequest props
 */
export interface DownloadRequestProps extends DownloadZipStatus {
  /** Handler to perform zip */
  onClick: () => Promise<void>;
  /** Default behavior is to warn when someone is downloading a zip and then navigates away from the page  */
  warnOnNavigationAway?: boolean;
  /** Download all files instead of filtered report */
  downloadAll?: boolean;
}

/**
 * A button for downloading the file
 */
export const DownloadZipButton: React.FC<DownloadRequestProps> = ({
  onClick,
  progress,
  isDownloading,
  message,
  downloadAll = false,
  warnOnNavigationAway = true,
}) => {
  const { formatMessage } = useIntl();
  const [delayedIsDownloading, setDelayedIsDownloading] = useState(false);

  // Holds the error message
  const [errorMessage, setErrorMessage] = useState('');

  // Warn before navigating away if mid-download
  useEffect(() => {
    window.onbeforeunload = () => {
      if (isDownloading && warnOnNavigationAway) {
        return true;
      }
      return undefined;
    };
  }, [isDownloading, warnOnNavigationAway]);
  useEffect(() => {
    if (isDownloading) {
      setDelayedIsDownloading(isDownloading);
      return () => {
        // do nothing
      };
    } else {
      // show the popover for an extra few seconds after it completes
      const timer = setTimeout(
        () => setDelayedIsDownloading(isDownloading),
        4 * ONE_SECOND,
      );
      return () => clearTimeout(timer);
    }
  }, [isDownloading]);

  // Render error
  if (errorMessage) {
    return <ErrorText>{errorMessage}</ErrorText>;
  }

  return (
    <Popover
      show={delayedIsDownloading || isDownloading ? true : undefined}
      width="150px"
      contents={
        <FlexColumn style={{ gap: StyleUtils.Spacing.sm }}>
          <Progress value={progress} />
          <div>
            {formatMessage(
              message ||
                (progress === 100
                  ? downloadZipButtonMessages.downloaded
                  : downloadZipButtonMessages.saving),
            )}
          </div>
        </FlexColumn>
      }
      trigger={
        isDownloading || progress === 100 ? ['hover', 'focus'] : undefined
      }
      delay={{ show: 300, hide: 500 }}
    >
      <Button
        icon={<Icon type="download" />}
        onClick={async () => {
          try {
            await onClick();
            msg.success(formatMessage(downloadZipButtonMessages.downloaded));
          } catch (err) {
            setErrorMessage(err.message);
          }
        }}
        disabled={isDownloading}
        loading={isDownloading}
      >
        <FlexRowCenterVertical
          style={{
            gap: StyleUtils.Spacing.sm,
            color: 'inherit',
            fontWeight: 'inherit',
          }}
        >
          <span>
            {formatMessage(
              downloadAll
                ? downloadZipButtonMessages.downloadAllFiles
                : downloadZipButtonMessages.download,
            )}
          </span>
        </FlexRowCenterVertical>
      </Button>
    </Popover>
  );
};
