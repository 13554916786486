import {
  AUTOCOMPLETE_OFF_PROPS,
  GenericMessageDescriptor,
  useFormatMessageGeneric,
} from '@main/core-ui';
import React from 'react';
import type { FormControlProps } from 'react-bootstrap/FormControl';

import { inlineTextMessages } from './messages';
import { StyledFormControl } from './wrappers';

/** Props for the `InlineTextInput` component */
export type InlineTextInputProps = FormControlProps & {
  /** Show place holder */
  showPlaceholder?: boolean;
  /** Placeholder */
  placeholder?: GenericMessageDescriptor;
  /** HTML element name attribute */
  name?: string;
  /** Whether to show an outline on the input */
  showOutline?: boolean;
  /** Hide the input and render text instead */
  readOnly?: boolean;
};

/**
 * Unlike FormInput, which is meant to be used in a form,
 * this component is meant to be used for in-line changes where
 * you want the callback (to update/save the value) to be fired onBlur
 * instead of onChange.
 *
 * This is usually used for in-line table edits.
 */
export const InlineTextInput = React.forwardRef<
  HTMLInputElement,
  InlineTextInputProps
>(
  (
    {
      showPlaceholder = true,
      placeholder = inlineTextMessages.placeholder,
      showOutline,
      readOnly,
      ...props
    },
    ref,
  ) => {
    const { formatMessageGeneric } = useFormatMessageGeneric();

    if (readOnly) {
      return <span>{props.value || ''}</span>;
    }
    return (
      <StyledFormControl
        ref={ref}
        placeholder={
          showPlaceholder ? formatMessageGeneric(placeholder) : undefined
        }
        $showOutline={showOutline}
        onClick={(e: any) => {
          e.stopPropagation();
        }}
        {...AUTOCOMPLETE_OFF_PROPS}
        {...props}
      />
    );
  },
);
