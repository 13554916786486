import { endpoints as analyticsEndpoints } from '@main/analytics-types';
import {
  buildUseQuery,
  FlexColumn,
  Icon,
  useFormatMessageGeneric,
} from '@main/core-ui';
import { ColorPalette } from '@main/theme';
import React, { useMemo, useState } from 'react';
import { useIntl } from 'react-intl';

import { DashboardTile, DashboardTileProps } from '../../DashboardTile';
import { BarChart } from '../BarChart';
import { remapAnalyticsPrettyNames } from './helpers';
import { dashboardChartMessages } from './messages';
import { SimpleDashboardXYChartProps } from './types';

interface SimpleDashboardBarChartProps extends SimpleDashboardXYChartProps {
  /** should we sort by values in desc order */
  sortByValueDesc?: boolean;
  /** whether to use a single color for each series, useful for time series data */
  oneColorPerSeries?: boolean;
  /** mapping of the data keys to colors that should be applied for each key */
  colorMap?: Record<string, keyof ColorPalette>;
}

const useAnalytics = buildUseQuery(analyticsEndpoints.analyticsData);

/**
 * SimpleDashboardPieChart - a component to reuse the common pie chart configuration
 * for dashboards
 */
export const SimpleDashboardBarChart: React.FC<
  SimpleDashboardBarChartProps & DashboardTileProps
> = ({
  dataSource,
  prettyLabelMap,
  startDate,
  endDate,
  isTestRequest,
  defaultLabel,
  sortByValueDesc,
  scale,
  dateKeys,
  prettySeriesMap,
  extraProps,
  oneColorPerSeries,
  skipIfNoStartDate,
  runId,
  colorMap,
  ...other
}) => {
  const { formatMessage } = useIntl();
  const { formatMessageGeneric } = useFormatMessageGeneric();
  const [userScale, setUserScale] = useState(scale ?? 'linear');
  const input = {
    dataSource,
    startDate: startDate?.toISOString(),
    endDate: endDate?.toISOString(),
    isTestRequest,
    ...(runId ? { runId } : {}),
    ...extraProps,
  };
  const { data, loading, error, refetch } = useAnalytics({
    variables: {
      input,
    },
    fetchPolicy: 'cache-and-network',
    skip: skipIfNoStartDate && !startDate,
  });
  const relabeledData = useMemo(
    () =>
      data
        ? {
            series: remapAnalyticsPrettyNames({
              data,
              prettyLabelMap,
              prettySeriesMap,
              defaultLabel,
              dateKeys,
              sortByValueDesc,
              formatMessageGeneric,
            }),
          }
        : undefined,
    [data],
  );

  const hasDataPoints =
    relabeledData &&
    relabeledData.series.length > 0 &&
    relabeledData.series.some(({ points }) => points.length > 0);
  const stacked = relabeledData && relabeledData.series.length > 1;

  const keys = useMemo(() => {
    if (!data) {
      return [];
    }
    const { points } = data.series[0];
    return points.map(({ key }) => key || '');
  }, [data]);

  const colors =
    colorMap && keys
      ? Object.entries(colorMap)
          .filter(([key]) => keys.includes(key))
          .sort(([a], [b]) => keys.indexOf(a) - keys.indexOf(b))
          .map(([, color]) => color)
      : undefined;

  return (
    <DashboardTile
      {...other}
      header={formatMessageGeneric(other.header, {
        summaryValue:
          data?.metadata.summaryValue !== undefined
            ? Math.round(data.metadata.summaryValue * 10) / 10
            : undefined,
      })}
      percentChange={other.percentChange ?? data?.metadata.percentChange}
      cachedAt={data?.cachedAt}
      loading={loading}
      error={error}
      menuOptions={
        !stacked
          ? [
              {
                displayTitle: dashboardChartMessages.logScale,
                icon:
                  userScale === 'logarithmic' ? (
                    <Icon type="checkmark" />
                  ) : undefined,
                onClick: () =>
                  setUserScale((current) =>
                    current === 'linear' ? 'logarithmic' : 'linear',
                  ),
              },
            ]
          : undefined
      }
      actions={other.actions}
      onClickForceRefetch={() =>
        refetch({ input: { ...input, forceRefetch: true } })
      }
    >
      {hasDataPoints && (
        <FlexColumn style={{ height: '300px', flexGrow: 1 }}>
          <div style={{ paddingTop: '32px', height: '100%', width: '100%' }}>
            <BarChart
              scale={userScale}
              data={relabeledData!}
              oneColorPerSeries={oneColorPerSeries}
              maxBottomLabels={dateKeys ? 7 : undefined}
              colors={colors}
            />
          </div>
        </FlexColumn>
      )}
      {!hasDataPoints &&
        !error &&
        !loading &&
        formatMessage(dashboardChartMessages.noData)}
    </DashboardTile>
  );
};
