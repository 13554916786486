import { defineMessages } from '@transcend-io/internationalization';

export const previewSdkMetadataMessages = defineMessages(
  'admin-dashboard.CodeScanning.components.PreviewSdkMetadata.previewSdkMetadata',
  {
    title: { defaultMessage: 'SDK Metadata' },
    integration: { defaultMessage: 'Integration' },
    description: { defaultMessage: 'Description' },
    name: { defaultMessage: 'SDK Name' },
    repositoryUrl: { defaultMessage: 'Git Repository' },
    documentationLinks: { defaultMessage: 'Documentation' },
    type: { defaultMessage: 'Package Type' },
  },
);
