import { makeEnum } from '@transcend-io/type-utils';

import { OptionalAirgapModuleName } from '../../airgapBundleName';

/**
 * Fields to order an experience by when paginating
 */
export enum ExperienceOrderField {
  /** Whether the experience is active */
  Active = 'isActive',
  /** The name of the experience */
  Name = 'name',
  /** The created at date of the experience */
  CreatedAt = 'createdAt',
  /** The time the experience was updated */
  UpdatedAt = 'updatedAt',
  /** Priority */
  DisplayPriority = 'displayPriority',
}

/**
 * Transcend's Default UI Modules
 */
export const TranscendConsentManagerUI = makeEnum({
  [OptionalAirgapModuleName.ui]: 'ui',
  [OptionalAirgapModuleName.tcfUi]: 'tcfUi',
});

/** Override type */
export type TranscendConsentManagerUI =
  (typeof TranscendConsentManagerUI)[keyof typeof TranscendConsentManagerUI];

/**
 * Transcend's conditionally loaded scripts
 */
export const TranscendConsentManagerScript = makeEnum({
  ...TranscendConsentManagerUI,
  [OptionalAirgapModuleName.gpp]: 'gpp',
});

/** Override type */
export type TranscendConsentManagerScript =
  (typeof TranscendConsentManagerScript)[keyof typeof TranscendConsentManagerScript];
