import { datadogLogs } from '@datadog/browser-logs';
import { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { endpoints } from '@main/access-control-types';
import { useRedirectToReLogin } from '@main/ad-core-components';
import {
  buildUseMutation,
  FetchResult,
  logger,
  message,
  MutationHookOptions,
  MutationResult,
} from '@main/core-ui';
import {
  GqlObject,
  MutationEndpoint,
  ParamsToType,
  ResponseToType,
} from '@main/schema-utils';
import {
  createDhEncryptionKeys,
  createDhPayloadHelpers,
  DhOptions,
  DiffieHellmanMutate,
} from '@main/sm-browser';
import {
  DiffieHellmanParams,
  DiffieHellmanResponse,
  isDiffieHellmanError,
  SombraEmployeeAuthMethod,
} from '@main/sombra-types';

import {
  selectEmployeeAuthenticationMethods,
  selectNoSombraAuth,
  selectSombraPublicKeyECDH,
  selectSombraSessionSecret,
  selectUser,
} from '../Auth/App/selectors';
import { setSombraSessionSecret, userLoggedIn } from '../Auth/App/slice';
import { useDecryptSombraSessionSecret } from './useDecryptSombraSessionSecret';

const useAssumeRole = buildUseMutation(
  endpoints.assumeRole,
  'AssumeRoleForBuildUseDhMutation',
);

/**
 * A GraphQL mutation with diffie hellman support built in
 *
 * @param endpoint - The GraphQL mutation endpoint definition (requires dhEncrypted as input)
 * @param operationName - The GraphQL operation name
 * @param responseFields - Return a partial response
 * @returns A hook that calls useApolloMutation but adds type enforcements
 */
export function buildUseDhMutation<
  TName extends string,
  TParams extends DiffieHellmanParams,
  TResult extends DiffieHellmanResponse,
>(
  endpoint: MutationEndpoint<TName, TParams, TResult>,
  operationName?: string,
  responseFields?: GqlObject<TResult>,
  // TODO: https://github.com/transcend-io/main/issues/6322 - this response type should be partial if responseFields is provided
): (
  options?: MutationHookOptions<
    ResponseToType<TResult>,
    Omit<ParamsToType<TParams>, 'dhEncrypted'>
  > & {
    /** When true, pass in the publicKey to the variables.publicKey parameter based on generating diffie hellman keys */
    includePublicKey?: boolean;
  },
) => [
  undefined | DiffieHellmanMutate<TParams, TResult>,
  MutationResult<ResponseToType<TResult>> & {
    /** The unwrapped response from the diffie hellman channel */
    unwrappedResponse?: Buffer;
  },
] {
  // Create the raw hook using the core `buildUseMutation`
  const useMutation = buildUseMutation(endpoint, operationName, responseFields);

  // Wrap the hook with diffie hellman support
  return (options) => {
    const dispatch = useDispatch();

    /** Store the decrypted response sent back after result */
    const [unwrappedResponse, setUnwrappedResponse] = useState<Buffer>();

    // keys to create diffie hellman channel
    const authSecret = useSelector(selectSombraSessionSecret);
    const sombraPublicKeyEcdh = useSelector(selectSombraPublicKeyECDH);
    const user = useSelector(selectUser);

    /** The raw mutation */
    const [mutate, mutateResponse] = useMutation(options as any); // dhEncryption-less options are hard to type
    const [isReLogging, setIsReLogging] = useState(false);
    const error = useMemo(
      () => (isReLogging ? undefined : mutateResponse.error),
      [isReLogging, mutateResponse.error],
    );

    /** Diffie hellman support */
    const noSombraAuth = useSelector(selectNoSombraAuth);
    const reLogin = useRedirectToReLogin();
    const employeeAuthenticationMethods = useSelector(
      selectEmployeeAuthenticationMethods,
    );
    const decryptSombraSessionSecret = useDecryptSombraSessionSecret();
    const canAutoReLogin = useMemo(
      () =>
        !!employeeAuthenticationMethods &&
        !!user &&
        employeeAuthenticationMethods.includes(
          SombraEmployeeAuthMethod.Transcend,
        ),
      [employeeAuthenticationMethods, user],
    );
    const [assumeRole] = useAssumeRole();

    /** Define mutation execution function */
    const executeMutation = useCallback(
      async (
        {
          dhEncryptedInput,
          variables = {},
          ...mutateOptions
        } = {} as DhOptions<TParams, TResult>,
      ) => {
        // this shouldn't happen unless local storage is cleared
        if (!sombraPublicKeyEcdh || !authSecret) {
          datadogLogs.logger.error(
            `Missing sombraPublicKeyEcdh and/or authSecret in buildUseDhMutation`,
          );

          // if user is logged in - do a fresh re-login
          if (user) {
            reLogin();
            return {} as any;
          }

          // if user is unauthenticated - they'll need to reload the page, this should be very rare
          message.error(
            `Your session has expired - try reloading the page and re-authenticating`,
          );
          throw new Error(`Session expired for user during mutation`);
        }

        let dhEncryptionKeys = createDhEncryptionKeys(sombraPublicKeyEcdh);

        // create the dh payload
        let [createDhPayload, decryptPayload] = createDhPayloadHelpers<true>({
          authMethod: SombraEmployeeAuthMethod.Session,
          authSecret,
          dhEncryptionKeys,
        });

        // Make the mutation
        let rawResponse: FetchResult<
          ResponseToType<TResult>,
          Record<string, any>,
          Record<string, any>
        >;
        try {
          setIsReLogging(true);
          rawResponse = await mutate({
            ...mutateOptions,
            variables: {
              ...variables,
              dhEncrypted: createDhPayload(dhEncryptedInput),
              ...(options?.includePublicKey
                ? { publicKey: dhEncryptionKeys.publicKey }
                : {}),
            } as any, // difficult to type
          });
          setIsReLogging(false);
        } catch (err) {
          // attempt to re-login if diffie hellman error occurs
          if (err.message && isDiffieHellmanError(err.message)) {
            try {
              // user is not authenticated - they need to reload the page
              // to re-establish a new session
              if (!user?.id) {
                message.error(
                  `Your session has expired - try reloading the page and re-authenticating`,
                );
                datadogLogs.logger.error(
                  `Session expired for user during mutation`,
                );
                setIsReLogging(false);
                throw err;
              }

              // If the user can only authenticate via SSO login - redirect the user
              if (!canAutoReLogin) {
                reLogin();
                setIsReLogging(false);
                return {} as any;
              }

              // At this point - the user can be automatically re-authenticated
              // because transcend employee authentication method is supported
              logger.warn(
                `Attempting to re-login user to sombra using transcend authentication`,
              );

              // Create new dh keys
              const newDhEncryptionKeys =
                createDhEncryptionKeys(sombraPublicKeyEcdh);

              // Assume the role
              const result = await assumeRole({
                variables: {
                  id: user.id,
                  publicKey: newDhEncryptionKeys.publicKey,
                },
              });

              // If the user can authenticate with SSO,
              // attempt to re-login
              // this should not happen because of the check above
              if (!result?.data?.decryptionContext) {
                datadogLogs.logger.error(
                  `Failed to re-login using assumeRole when expected to, attempting re-login`,
                );
                reLogin();

                // return false to indicate re-login
                setIsReLogging(false);
                return {} as any;
              }

              // transcend auth was successful so no need to re-login
              const newSombraSessionSecret = decryptSombraSessionSecret(
                newDhEncryptionKeys,
                result.data.decryptionContext,
              );
              dispatch(
                userLoggedIn({
                  user: result.data.user,
                  sombraSessionSecret: newSombraSessionSecret,
                }),
              );

              // if authentication is successful - immediately re-execute the mutation
              if (newSombraSessionSecret) {
                dispatch(setSombraSessionSecret(newSombraSessionSecret));

                dhEncryptionKeys = createDhEncryptionKeys(sombraPublicKeyEcdh);

                // create the dh payload
                [createDhPayload, decryptPayload] =
                  createDhPayloadHelpers<true>({
                    authMethod: SombraEmployeeAuthMethod.Session,
                    authSecret: newSombraSessionSecret,
                    dhEncryptionKeys,
                  });

                rawResponse = await mutate({
                  ...mutateOptions,
                  variables: {
                    ...variables,
                    dhEncrypted: createDhPayload(dhEncryptedInput),
                    ...(options?.includePublicKey
                      ? { publicKey: dhEncryptionKeys.publicKey }
                      : {}),
                  } as any, // difficult to type
                });
              } else {
                datadogLogs.logger.error(
                  `Failed to decrypt sombra session from assumeRole in buildUseDhMutation`,
                );
                reLogin();
                setIsReLogging(false);
                return {} as any;
              }
              setIsReLogging(false);
            } catch (innerErr) {
              logger.warn(`Failed to check sombra session`);
              setIsReLogging(false);
              throw innerErr;
            }
          } else {
            logger.warn(`Failed to check sombra session`);
            setIsReLogging(false);
            throw err;
          }
        }

        // pass decryptDhResponse to child in case fields other than decryptionContext need to be unwrapped
        const response = {
          ...rawResponse!,
          decryptDhResponse: decryptPayload,
        };

        // Return response if no data
        const { data } = response;
        if (!data) {
          return response;
        }

        // Attempt to unpack the diffie hellman response
        // generic is difficult to get working with
        const decryptionContext = data?.decryptionContext as any; // hard to type

        if (decryptionContext) {
          const newUnwrappedResponse = decryptPayload(decryptionContext);
          setUnwrappedResponse(newUnwrappedResponse);
          return { ...response, unwrappedResponse: newUnwrappedResponse };
        }
        return response;
      },
      [
        assumeRole,
        authSecret,
        canAutoReLogin,
        dispatch,
        mutate,
        options?.includePublicKey,
        reLogin,
        sombraPublicKeyEcdh,
        user?.id,
        decryptSombraSessionSecret,
      ],
    );

    return [
      noSombraAuth ? undefined : executeMutation,
      {
        unwrappedResponse,
        ...mutateResponse,
        error,
      },
    ];
  };
}
