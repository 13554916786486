import { FilterConfigItem } from '@main/ad-core-components';
import {
  SelectedPrompt,
  SelectPrompts,
} from '@main/admin-dashboard/src/Prompts/components/SelectPrompts';
import { buildUseLazyQuery } from '@main/core-ui';
import { endpoints } from '@main/prompt-types';
import { ID } from '@main/schema-utils';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { MessageDescriptor } from 'react-intl';

import { filterManagerHookMessages } from './messages';

/**
 * Filters definition with property for prompt values specified
 */
type PromptEnrichedFilters = Record<string, unknown> &
  Record<
    /**
     * Prompt values that are currently filtering the table
     */
    string,
    SelectedPrompt[] | undefined
  >;

const useLazyPrompts = buildUseLazyQuery(endpoints.prompts);

export const usePromptFilters = <
  T extends Record<string, unknown>,
  E extends PromptEnrichedFilters,
>({
  promptIdsFilterKey = 'promptIds',
  filters,
  setFilters,
  label,
  enrichedPromptKey = 'prompts',
}: {
  /** The property name of the filter for prompt ids */
  promptIdsFilterKey?: keyof T;
  /** The currently applied filters */
  filters?: T;
  /** Callback when the filters are changed */
  setFilters: (filters: T) => void;
  /** Label for the filter menu */
  label: string | MessageDescriptor;
  /** The key to use if more than one usePromptFilters is used in a FilterManager */
  enrichedPromptKey?: keyof E;
}): {
  /**  The prompt keys enabled on this table */
  selectedPrompts: SelectedPrompt[];
  /** The filter configuration to be passed to the filter manager */
  promptFiltersConfig: FilterConfigItem<E>;
  /** Callback for when the filter is cleared in the filter manager */
  clearPromptFilters: (key: Extract<keyof E, string>) => void;
} => {
  const getSelectedPrompts = useLazyPrompts();
  const [selectedPrompts, setSelectedPrompts] = useState<SelectedPrompt[]>([]);
  const [fetchingPrompts, setFetchingPrompts] = useState(false);

  // Populate initial complex filter values
  useEffect(() => {
    if (filters) {
      const promptIds = (filters[promptIdsFilterKey] ?? []) as ID<'prompt'>[];

      const filtersAndEnrichedFiltersMatch =
        promptIds.sort().join() ===
        selectedPrompts
          .map(({ id }) => id)
          .sort()
          .join();

      if (promptIds.length === 0 && selectedPrompts.length > 0) {
        setSelectedPrompts([]);
      } else if (!filtersAndEnrichedFiltersMatch && !fetchingPrompts) {
        // Prevent over-firing this setter while data is still being fetched
        setFetchingPrompts(true);
        getSelectedPrompts({ filterBy: { ids: promptIds } })
          .then(({ data }) => {
            setSelectedPrompts(data.nodes);
          })
          .finally(() => {
            setFetchingPrompts(false);
          });
      }
    }
  }, [
    filters,
    fetchingPrompts,
    getSelectedPrompts,
    selectedPrompts.length,
    promptIdsFilterKey,
    selectedPrompts,
  ]);

  const promptFiltersConfig = useMemo(
    () =>
      ({
        filterKey: enrichedPromptKey,
        label,
        pillOptions: {
          label: ({
            filterValues: { [enrichedPromptKey]: prompts = [] },
            index = 0,
          }) => prompts[index]?.title,
        },
        filter: (
          <SelectPrompts
            isMulti
            placeholderDescriptor={filterManagerHookMessages.promptsPlaceholder}
            menuPosition="absolute"
            value={selectedPrompts}
            showOutline={false}
            onChange={(prompts) => {
              setSelectedPrompts([...prompts]);
              setFilters({
                ...filters,
                [promptIdsFilterKey]: prompts.map(({ id }) => id),
              } as T);
            }}
          />
        ),
      }) as FilterConfigItem<PromptEnrichedFilters>,
    [enrichedPromptKey, label, selectedPrompts, filters, promptIdsFilterKey],
  );

  const clearPromptFilters = useCallback(
    (key) => {
      if (key === enrichedPromptKey) {
        setFilters({
          ...filters,
          [promptIdsFilterKey]: [],
        } as T);
        setSelectedPrompts([]);
      }
    },
    [enrichedPromptKey, filters, promptIdsFilterKey],
  );

  return {
    selectedPrompts,
    promptFiltersConfig,
    clearPromptFilters,
  };
};
