import { administrationRoutes } from '@main/admin-dashboard/src/Administration/routes';
import { assessmentRoutes } from '@main/admin-dashboard/src/Assessments/routes';
import { auditorRoutes } from '@main/admin-dashboard/src/Auditor/routes';
import { App } from '@main/admin-dashboard/src/Auth/App';
import { miscBasicRoutes } from '@main/admin-dashboard/src/Auth/MiscellaneousBasic/routes';
import { miscPrivateRoutes } from '@main/admin-dashboard/src/Auth/MiscellaneousPrivate/routes';
import { miscPublicRoutes } from '@main/admin-dashboard/src/Auth/MiscellaneousPublic/routes';
import { miscRedirectRoutes } from '@main/admin-dashboard/src/Auth/MiscellaneousRedirect/routes';
import { consentManagerRoutes } from '@main/admin-dashboard/src/ConsentManager/routes';
import { contractScanningRoutes } from '@main/admin-dashboard/src/ContractScanning/routes';
import { dataMapRoutes } from '@main/admin-dashboard/src/DataMap/routes';
import { infrastructureRoutes } from '@main/admin-dashboard/src/Infrastructure/routes';
import { preferenceStoreRoutes } from '@main/admin-dashboard/src/PreferenceStore/routes';
import { privacyRequestsRoutes } from '@main/admin-dashboard/src/PrivacyRequests/routes';
import { promptRoutes } from '@main/admin-dashboard/src/Prompts/routes';
import { getValues } from '@transcend-io/type-utils';
import React, { ReactNode } from 'react';
import { Route, Routes as ReactRouterRoutes } from 'react-router-dom';

import { codeScanningRoutes } from '../CodeScanning/routes';
import { pathfinderRoutes } from '../Pathfinder/routes';
import { privacyCenterRoutes } from '../PrivacyCenter/routes';
import NotFound from './NotFound/Loadable';
import {
  OrganizationPage,
  OrganizationPageProps,
} from './OrganizationRoute/OrganizationPage';
import { RedirectRoute, RedirectRouteProps } from './RedirectRoute';

export interface ExtendableRoute extends OrganizationPageProps {
  /** Route path */
  path: string;
  /** Route component */
  component: React.ComponentType<any>;
  /** The wrapper around the route */
  wrapper?: React.ComponentType<any>;
}

export const renderRoutes = (
  routes: Record<string, ExtendableRoute>,
): ReactNode[] =>
  getValues(routes).map(
    ({
      path,
      wrapper: Wrapper = OrganizationPage,
      component: Component,
      hideInnerPadding,
      title,
      prevPagePath,
      prevPageTitle,
      breadcrumbs,
      ...routeProps
    }) => (
      <Route
        key={path}
        path={path}
        {...routeProps}
        element={
          <Wrapper
            prevPagePath={prevPagePath}
            prevPageTitle={prevPageTitle}
            hideInnerPadding={hideInnerPadding}
            title={title}
            breadcrumbs={breadcrumbs}
          >
            <Component />
          </Wrapper>
        }
      />
    ),
  );

export const renderRedirectRoutes = (
  routes: Record<
    string,
    RedirectRouteProps & {
      /** Path to redirect from */
      path: string;
    }
  >,
): ReactNode[] =>
  getValues(routes).map(({ path, ...routeProps }) => (
    <Route key={path} path={path} element={<RedirectRoute {...routeProps} />} />
  ));

/**
 * Manages all routes for the application
 */
export default function Routes(): JSX.Element {
  return (
    <App>
      <ReactRouterRoutes>
        {renderRoutes(administrationRoutes)}
        {renderRoutes(consentManagerRoutes)}
        {renderRoutes(preferenceStoreRoutes)}
        {renderRoutes(dataMapRoutes)}
        {renderRoutes(assessmentRoutes)}
        {renderRoutes(infrastructureRoutes)}
        {renderRoutes(privacyRequestsRoutes)}
        {renderRoutes(privacyCenterRoutes)}
        {renderRoutes(miscBasicRoutes)}
        {renderRoutes(miscPrivateRoutes)}
        {renderRoutes(miscPublicRoutes)}
        {renderRoutes(promptRoutes)}
        {renderRoutes(contractScanningRoutes)}
        {renderRoutes(pathfinderRoutes)}
        {renderRoutes(auditorRoutes)}
        {renderRoutes(codeScanningRoutes)}
        {renderRedirectRoutes(miscRedirectRoutes)}
        {/** Not Found */}
        <Route element={<NotFound />} path="*" />
      </ReactRouterRoutes>
    </App>
  );
}
