import { NA } from '@main/ad-core-components/src/NA';
import React from 'react';
import Moment from 'react-moment';

/**
 * Display a time
 *
 * @param timeStr - The time as ISO string
 */
export function DisplayTime(timeStr?: string): JSX.Element {
  if (!timeStr) {
    return <NA />;
  }
  return (
    <Moment format="MM/DD/YYYY hh:mma">
      {new Date(timeStr).toISOString()}
    </Moment>
  );
}
