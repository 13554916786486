import { AdminDashboardAdministrationPath } from '@main/access-control-types';
import type { ExtendableRoute } from '@main/admin-dashboard/src/routes';

import { Billing } from './Billing/Loadable';
import { administrationPageTitleMessages } from './messages';
import { Organization } from './Organization/Loadable';
import { ScopesLoadable } from './Scopes/Loadable';
import { SingleSignOn } from './SingleSignOn/Loadable';
import { TeamsLoadable } from './Teams/Loadable';
import { UsersLoadable } from './Users/Loadable';

const administrationBreadcrumb = {
  id: AdminDashboardAdministrationPath.Organization,
  displayText: administrationPageTitleMessages.administration,
  href: AdminDashboardAdministrationPath.Organization,
};

export const administrationRoutes: {
  [k in AdminDashboardAdministrationPath]: ExtendableRoute;
} = {
  [AdminDashboardAdministrationPath.Organization]: {
    path: AdminDashboardAdministrationPath.Organization,
    title: administrationPageTitleMessages.administration,
    component: Organization,
    breadcrumbs: [administrationBreadcrumb],
  },
  [AdminDashboardAdministrationPath.Users]: {
    path: AdminDashboardAdministrationPath.Users,
    title: administrationPageTitleMessages.administration,
    component: UsersLoadable,
    breadcrumbs: [administrationBreadcrumb],
  },
  [AdminDashboardAdministrationPath.Teams]: {
    path: AdminDashboardAdministrationPath.Teams,
    title: administrationPageTitleMessages.administration,
    component: TeamsLoadable,
    breadcrumbs: [administrationBreadcrumb],
  },
  [AdminDashboardAdministrationPath.Scopes]: {
    path: AdminDashboardAdministrationPath.Scopes,
    title: administrationPageTitleMessages.administration,
    component: ScopesLoadable,
    breadcrumbs: [administrationBreadcrumb],
  },
  [AdminDashboardAdministrationPath.Sso]: {
    path: AdminDashboardAdministrationPath.Sso,
    title: administrationPageTitleMessages.administration,
    component: SingleSignOn,
    breadcrumbs: [administrationBreadcrumb],
  },
  [AdminDashboardAdministrationPath.Billing]: {
    path: AdminDashboardAdministrationPath.Billing,
    title: administrationPageTitleMessages.administration,
    component: Billing,
    breadcrumbs: [administrationBreadcrumb],
  },
};
