import React from 'react';
import { useTheme } from 'styled-components';

/**
 * Boolean icon
 */
export const Bool: React.FC = (props) => {
  const theme = useTheme();
  return (
    <svg
      width="42px"
      height="42px"
      viewBox="0 0 42 42"
      version="1.1"
      {...props}
    >
      <defs />
      <g
        id="Symbols"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Button/dashboardsquare"
          transform="translate(-43.000000, -31.000000)"
          fill={theme.colors.primary}
        >
          <g id="inputicon/yes-no" transform="translate(40.000000, 28.000000)">
            <path
              // eslint-disable-next-line max-len
              d="M24,45 C12.4020203,45 3,35.5979797 3,24 C3,12.4020203 12.4020203,3 24,3 C35.5979797,3 45,12.4020203 45,24 C45,35.5979797 35.5979797,45 24,45 Z M24,9 C15.7157288,9 9,15.7157288 9,24 C9,32.2842712 15.7157288,39 24,39 L24,9 Z"
              id="Shape"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};
