import { defineMessages } from '@transcend-io/internationalization';

import { DataSiloDetailsTab } from './enums';

export const dataSiloV2Messages = defineMessages(
  'admin-dashboard.Infrastructure.Integrations.DataSiloDetails.dataSiloV2',
  {
    header: {
      defaultMessage: 'Integration Configuration',
    },
    back: {
      defaultMessage: 'Back to Integrations',
    },
    backDataInventory: {
      defaultMessage: 'Back to Data Inventory',
    },
  },
);

export const dataSiloV2TabMessages = defineMessages<DataSiloDetailsTab>(
  'admin-dashboard.Infrastructure.Integrations.DataSiloDetails.dataSiloV2Tab',
  {
    [DataSiloDetailsTab.Activities]: { defaultMessage: 'Activities' },
    [DataSiloDetailsTab.Connection]: { defaultMessage: 'Connection' },
    [DataSiloDetailsTab.SiloDiscovery]: {
      defaultMessage: 'Silo Discovery',
    },
    [DataSiloDetailsTab.StructuredDiscovery]: {
      defaultMessage: 'Structured Discovery',
    },
    [DataSiloDetailsTab.UnstructuredDiscovery]: {
      defaultMessage: 'Unstructured Discovery',
    },
    [DataSiloDetailsTab.DsrAutomation]: {
      defaultMessage: 'DSR Automation',
    },
    [DataSiloDetailsTab.Lookups]: { defaultMessage: 'Lookups' },
    [DataSiloDetailsTab.AuditTrail]: { defaultMessage: 'Audit Trail' },
    [DataSiloDetailsTab.ManageDatapoints]: {
      defaultMessage: 'Manage Datapoints',
    },
    [DataSiloDetailsTab.ProcessedRequests]: {
      defaultMessage: 'Processed Requests',
    },
    [DataSiloDetailsTab.ActionItems]: { defaultMessage: 'Action Items' },
  },
);
