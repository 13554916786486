import React from 'react';

import {
  MultipleChoice,
  MultipleChoiceBaseProps,
  MultipleChoiceOption,
} from './MultipleChoice';

/**
 * option representing a valid value and label pair
 */
export type RadioInputGroupOption<T extends string> = MultipleChoiceOption<T>;

/**
 * props for RadioInputGroup wrapper around MultipleChoice
 */
export interface RadioInputGroupProps<
  T extends string,
  K extends Array<RadioInputGroupOption<T>>,
  V = K[number]['value'],
> extends Omit<MultipleChoiceBaseProps, 'type'> {
  /** current checked value of group */
  value?: V;
  /** choices */
  options: K;
  /** callback for when a choice is selected */
  onChange?: (value: V | undefined) => void;
}

export const RadioInputGroup: <
  T extends string,
  K extends Array<RadioInputGroupOption<T>>,
>(
  props: RadioInputGroupProps<T, K>,
) => React.ReactElement = ({ onChange, value, ...props }) => (
  <MultipleChoice
    {...props}
    type="radio"
    onChange={(values) => onChange?.(values[0] || undefined)}
    value={value === undefined ? [] : [value]}
  />
);
