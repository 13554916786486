import styled from 'styled-components';

import { Card } from '../Card';
import { WizardPanelImagePosition } from './enums';

const IMAGE_OVERLAP = 30;

export const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export interface StyledImageProps {
  /** Is this image overlapping the top border? */
  $overlap: boolean;
}

export const StyledImage = styled.img<StyledImageProps>`
  width: 130px;

  ${(props) =>
    props.$overlap
      ? `position: absolute; top: -${IMAGE_OVERLAP}px;`
      : 'margin-bottom: 1rem;'}
`;

export interface StyledWizardCardProps {
  /** Does this card have an image? Controls top padding. */
  $hasImage?: boolean;
  /** Where is the image positioned on the card? */
  $imagePosition?: WizardPanelImagePosition;
  /** Does this card have a subtitle */
  $hasSubtitle?: boolean;
}

export const StyledWizardCard = styled(Card)<StyledWizardCardProps>`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 600px;
  padding: 80px 120px;
  ${({ $hasImage, $imagePosition }) =>
    $hasImage &&
    $imagePosition === WizardPanelImagePosition.TOP_OVERLAP &&
    `
      margin-top: ${IMAGE_OVERLAP}px;
      padding-top: 115px;
    `}

  h1 {
    font-size: 34px;
    color: ${({ theme }) => theme.colors.transcendNavy};
    font-weight: 700;
    ${({ $hasSubtitle }) => $hasSubtitle && `margin-bottom: 0.25em;`}
  }
  h3 {
    font-size: 16px;
    color: ${({ theme }) => theme.colors.transcendNavy3};
    font-weight: 600;
    margin-bottom: 1.5em;
  }
  p {
    font-size: 16px;
    font-weight: 400;
    color: ${({ theme }) => theme.colors.transcendNavy2};
    letter-spacing: 0.4px;
  }
`;
