import { TeamPreview, UserPreview } from '@main/access-control-types';
import { mkInput, mkOrder, mkType, SchemaToType } from '@main/schema-utils';

import { PromptPartialOrderField } from './enums';

export const PromptPartial = mkType({
  name: 'PromptPartial',
  comment: 'Partial for a template that can be composed into a prompt',
  fields: {
    id: {
      comment: 'The id of the prompt partial',
      modelName: 'promptPartial',
      type: 'id',
    },
    title: {
      comment: 'The title of the partial prompt',
      type: 'string',
    },
    slug: {
      comment: 'The slug of the partial prompt',
      type: 'string',
    },
    content: {
      comment: 'The content of the partial prompt',
      type: 'string',
    },
    creator: {
      comment: 'The user who created this partial prompt',
      type: UserPreview,
      optional: true,
    },
    lastEditor: {
      comment: 'The user who last edited the partial prompt',
      type: UserPreview,
      optional: true,
    },
    createdAt: {
      comment: 'The date the partial prompt was created',
      type: 'Date',
    },
    updatedAt: {
      comment: 'The date the partial prompt was last updated',
      type: 'Date',
    },
    teams: {
      comment: 'The team/s that the partial prompt is assigned to',
      type: TeamPreview,
      list: true,
    },
    owners: {
      comment: 'The user/s that the partial prompt is assigned to',
      type: UserPreview,
      list: true,
    },
  },
});

/** Override type */
export type PromptPartial = SchemaToType<typeof PromptPartial>;

export const PromptPartialTemplated = mkType({
  name: 'PromptPartialTemplated',
  comment:
    'Information about a prompt partial necessary for templating a prompt',
  fields: {
    id: {
      comment: 'The id of the prompt partial',
      modelName: 'promptPartial',
      type: 'id',
    },
    title: {
      comment: 'The title of the prompt',
      type: 'string',
    },
    slug: {
      comment: 'The slug of the partial prompt',
      type: 'string',
    },
    content: {
      comment: 'The content of the partial prompt',
      type: 'string',
    },
    createdAt: {
      comment: 'The date the partial prompt was created',
      type: 'Date',
    },
    updatedAt: {
      comment: 'The date the partial prompt was last updated',
      type: 'Date',
    },
  },
});

/** Override type */
export type PromptPartialTemplated = SchemaToType<
  typeof PromptPartialTemplated
>;

export const PromptPartialFiltersInput = mkInput({
  name: 'PromptPartialFiltersInput',
  comment: 'Inputs for filtering prompt partials',
  fields: {
    ids: {
      comment: 'The ids of the prompt partials',
      type: 'id',
      modelName: 'promptPartial',
      optional: true,
      list: true,
    },
    lastEditorIds: {
      comment: 'The ids of last editors',
      type: 'id',
      modelName: 'user',
      optional: true,
      list: true,
    },
    creatorIds: {
      comment: 'The ids of the users who created a prompt',
      type: 'id',
      modelName: 'user',
      optional: true,
      list: true,
    },
    text: {
      comment: 'Filter by text (title of the prompt)',
      optional: true,
      type: 'string',
    },
    ownerIds: {
      comment: 'Filter by the assigned owner IDs',
      optional: true,
      type: 'id',
      modelName: 'user',
      list: true,
    },
    teamIds: {
      comment: 'Filter by the assigned team IDs',
      optional: true,
      type: 'id',
      modelName: 'team',
      list: true,
    },
  },
});

/** Override type */
export type PromptPartialFiltersInput = SchemaToType<
  typeof PromptPartialFiltersInput
>;

export const DeletePromptPartialsInput = mkInput({
  name: 'DeletePromptPartialsInput',
  comment: 'Input for deleting a group of partial prompts',
  fields: {
    ids: {
      comment: 'The id of the partial prompts to delete',
      type: 'id',
      modelName: 'promptPartial',
      list: true,
    },
  },
});

/** Override type */
export type DeletePromptPartialsInput = SchemaToType<
  typeof DeletePromptPartialsInput
>;

export const CreatePromptPartialInput = mkInput({
  name: 'CreatePromptPartialInput',
  comment: 'Input for creating a prompt partial',
  fields: {
    title: {
      comment: 'The title of the prompt partial',
      type: 'string',
    },
    content: {
      comment: 'The content of the prompt partial',
      type: 'string',
    },
    ownerIds: {
      comment:
        'The unique ids of the users to assign as owners of this partial prompt',
      modelName: 'user',
      type: 'id',
      optional: true,
      list: true,
    },
    ownerEmails: {
      comment:
        'The emails of the users to assign as owners of this partial prompt',
      type: 'string',
      optional: true,
      list: true,
    },
    teamIds: {
      comment:
        'The ids of the teams that should be responsible for this partial prompt',
      type: 'id',
      modelName: 'team',
      list: true,
      optional: true,
    },
    teamNames: {
      comment:
        'The names of the teams that should be responsible for this partial prompt',
      type: 'string',
      list: true,
      optional: true,
    },
  },
});

/** Override type */
export type CreatePromptPartialInput = SchemaToType<
  typeof CreatePromptPartialInput
>;

export const UpdatePromptPartialInput = mkInput({
  name: 'UpdatePromptPartialInput',
  comment: 'Input for updating a Prompt partial',
  fields: {
    id: {
      comment: 'The id of the Prompt',
      modelName: 'promptPartial',
      type: 'id',
    },
    title: {
      comment: 'The new title of the Prompt',
      type: 'string',
      optional: true,
    },
    content: {
      comment: 'The new content of the Prompt',
      type: 'string',
      optional: true,
    },
    ownerIds: CreatePromptPartialInput.fields.ownerIds,
    ownerEmails: CreatePromptPartialInput.fields.ownerEmails,
    teamIds: CreatePromptPartialInput.fields.teamIds,
    teamNames: CreatePromptPartialInput.fields.teamNames,
  },
});

/** Override type */
export type UpdatePromptPartialInput = SchemaToType<
  typeof UpdatePromptPartialInput
>;

export const UpdatePromptPartialsInput = mkInput({
  name: 'UpdatePromptPartialsInput',
  comment: 'Input for bulk updating one or more prompt partials',
  fields: {
    promptPartials: {
      comment:
        'List of prompt partials with the properties that should be updated for each',
      type: UpdatePromptPartialInput,
      list: true,
    },
  },
});

/** Override type */
export type UpdatePromptPartialsInput = SchemaToType<
  typeof UpdatePromptPartialsInput
>;

/** Order for a prompt partials query */
export const PromptPartialOrder = mkOrder(
  PromptPartial.name,
  PromptPartialOrderField,
);
