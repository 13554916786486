import { defineMessages } from '@transcend-io/internationalization';

/**
 * Message definitions for generic forms
 */
export const inputMessages = defineMessages('ad-core-components.Input.input', {
  password: {
    defaultMessage: 'Password',
  },
  email: {
    defaultMessage: 'Email',
  },
  emailPlaceholder: {
    defaultMessage: 'email@my.company.com',
  },
});
