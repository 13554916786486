import { FlexRow, Prompt, PromptMessage, StyleUtils } from '@main/core-ui';
import React, { useEffect } from 'react';
import { MessageDescriptor, useIntl } from 'react-intl';

import { Button, ButtonProps } from '../Button';
import { saveChangesMessages } from './messages';

/**
 * Props for SaveChangesWithPreview
 */
export interface SaveChangesProps {
  /** Indicator as to whether the page has been modified */
  isModified: boolean;
  /** Indicator that changes are currently being saved */
  saving: boolean;
  /** Callback that occurs when save button is clicked (state should be handled outside this component) */
  onSave: () => void;
  /** Callback that occurs when discard button is clicked */
  onClear: () => void;
  /** Extra props for the save button */
  saveButtonProps?: Partial<ButtonProps>;
  /** Message for save button */
  saveMessage?: MessageDescriptor;
  /** Extra props for the discard button */
  discardButtonProps?: Partial<ButtonProps>;
  /** Message to display when navigation is blocked */
  promptMessage?: PromptMessage;
  /** Callback when the user confirms the decision to navigate away  */
  onConfirmPrompt?: () => void;
}

/**
 * Split the page into two columns where the right column has a sticky save button and preview of changes
 */
export const SaveChanges: React.FC<SaveChangesProps> = ({
  isModified,
  onSave,
  onClear,
  saving,
  saveMessage = saveChangesMessages.save,
  saveButtonProps,
  discardButtonProps,
  promptMessage,
  onConfirmPrompt,
}) => {
  const { formatMessage } = useIntl();
  // Warn before navigating away
  useEffect(() => {
    window.onbeforeunload = () => {
      if (isModified) {
        return true;
      }
      return undefined;
    };
  }, [isModified]);

  return (
    <>
      <Prompt
        when={isModified}
        message={promptMessage ?? formatMessage(saveChangesMessages.unsaved)}
        onConfirm={onConfirmPrompt}
      />
      <FlexRow
        style={{
          gap: StyleUtils.Spacing.sm,
        }}
      >
        <Button
          loading={saving}
          disabled={!isModified || saving}
          onClick={onSave}
          {...saveButtonProps}
        >
          {formatMessage(saveMessage)}
        </Button>
        {isModified && (
          <Button
            disabled={saving}
            onClick={onClear}
            variant="secondary"
            {...discardButtonProps}
          >
            {formatMessage(saveChangesMessages.discard)}
          </Button>
        )}
      </FlexRow>
    </>
  );
};
