import { defineMessages } from '@transcend-io/internationalization';

export const summaryMessages = defineMessages(
  'admin-dashboard.Auditor.Run.Summary.summary',
  {
    header: {
      defaultMessage: 'Summary',
    },
    dashboardDescription: {
      defaultMessage:
        'For a detailed explanation of these visualizations and our reporting methodology' +
        ' please visit our {dashboardDocsLink}.',
    },
    runMetadata: {
      defaultMessage: 'Run Metadata',
    },
    durationMinutes: {
      defaultMessage: '{count} {count, plural, one {minute} other {minutes}} ',
    },
    durationSeconds: {
      defaultMessage: '{count} {count, plural, one {second} other {seconds}}',
    },
    startedBy: {
      defaultMessage: 'Started By',
    },
    status: {
      defaultMessage: 'Status',
    },
    startedAt: {
      defaultMessage: 'Started At',
    },
    duration: {
      defaultMessage: 'Duration',
    },
    errorMessage: {
      defaultMessage: 'Error Message',
    },
    failedStep: {
      defaultMessage: 'Failed Step',
    },
    dashboardDocs: {
      defaultMessage: 'dashboard documentation',
    },
    findingsBySeverity: {
      defaultMessage: 'Findings by Severity',
    },
    findingsBySeverityDescription: {
      defaultMessage:
        'We assign a severity to each finding based on the potential impact of the finding.' +
        ' You can find more information about our classes of findings and their possible severities' +
        ' in our {dashboardDocsLink}.',
    },
    videoHeader: {
      defaultMessage: 'Recording of the run',
    },
    requestsRegulatedByAirgap: {
      defaultMessage: 'Requests Regulated by Airgap',
    },
    requestsRegulatedByAirgapDescription: {
      defaultMessage:
        'Shows how many requests coming out of your website had Airgap listed as the initiator.' +
        ' Airgap can only regulate requests that it initiates, an in general you will want Airgap to regulate as much' +
        ' as possible. However, you may choose to {asyncLoadingLink} for performance reasons, which can cause some' +
        ' requests to load before Airgap is ready.',
    },
    requestsRegulatedByAirgapTableLink: {
      defaultMessage:
        'You can see the full list of requests that Airgap did not regulate by filtering for ' +
        'Regulated by Airgap in the Requests tab.',
    },
    asyncLoadingLink: {
      defaultMessage: 'load Airgap asynchronously',
    },
    cookiesRegulatedByAirgap: {
      defaultMessage: 'Cookies Regulated by Airgap',
    },
    cookiesRegulatedByAirgapDescription: {
      defaultMessage:
        'Shows how many cookies are being set by your website that Airgap is regulating.' +
        ' Airgap can only regulate cookies that are set after it initializes and that are visible to the browser, and' +
        ' in general you will want Airgap to regulate as many cookies as possible. However, you may choose to' +
        ' {asyncLoadingLink} for performance reasons, which can cause some cookies to be set before Airgap is ready.',
    },
    cookiesRegulatedByAirgapTableLink: {
      defaultMessage:
        'You can see the full list of cookies that Airgap did not regulate by filtering for ' +
        'Regulated by Airgap in the Cookies tab.',
    },
    timeToLoadAirgap: {
      defaultMessage: 'Time to Load Airgap',
    },
    networkRequestsWithKnownPurposes: {
      defaultMessage: 'Network Requests with Known Purposes',
    },
    networkRequestsWithKnownPurposesInfo: {
      defaultMessage:
        'Shows the number of requests by their predicted purpose.',
    },
    cookiesWithKnownPurposes: {
      defaultMessage: 'Cookies with Known Purposes',
    },
    cookiesWithKnownPurposesInfo: {
      defaultMessage: 'Shows the number of cookies by their predicted purpose.',
    },
    requests: {
      defaultMessage: 'Requests',
    },
    cookies: {
      defaultMessage: 'Cookies',
    },
    findings: {
      defaultMessage: 'Findings',
    },
    regulated: {
      defaultMessage: 'Regulated by Airgap',
    },
    unregulated: {
      defaultMessage: 'Unregulated by Airgap',
    },
    queuedRun: {
      defaultMessage: 'Run is queued, please check back later.',
    },
  },
);

export const regionMessages = defineMessages(
  'admin-dashboard.Auditor.Run.Summary.region',
  {
    'us-west-1': {
      defaultMessage: 'California',
    },
    unknown: {
      defaultMessage: 'Unknown',
    },
  },
);

export const regulatedByAirgapMessages = defineMessages(
  'admin-dashboard.Auditor.Run.Summary.regulatedByAirgap',
  {
    Regulated: {
      defaultMessage: 'Regulated by Airgap',
    },
    Unregulated: {
      defaultMessage: 'Not Regulated by Airgap',
    },
  },
);
