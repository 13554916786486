import styled from 'styled-components';

/**
 * Upload wrapper props
 */
export interface WrapperProps {
  /** The amount of padding for the wrapper */
  padding: string;
}

/**
 * The Upload wrapper
 */
export const UploadWrapper = styled.div<WrapperProps>`
  padding-left: ${({ padding }) => padding};
  padding-right: ${({ padding }) => padding};
`;
