import { defineMessages } from '@transcend-io/internationalization';

/**
 * Form item messages
 */
export const connectSiloOauthFormItemMessages = defineMessages(
  'admin-dashboard.Infrastructure.ConnectSilo.OAuth.connectSiloOauthFormItem',
  {
    subdomain: {
      defaultMessage: 'Subdomain',
    },
    subdomainPlaceholder: {
      defaultMessage: 'my-subdomain',
    },
    optionalSubdomainPlaceholder: {
      defaultMessage: 'my-subdomain (if applicable)',
    },
  },
);

export const successMessages = defineMessages(
  'admin-dashboard.Infrastructure.ConnectSilo.OAuth.success',
  {
    info: {
      defaultMessage: 'You can close this page.',
    },
    header: {
      defaultMessage: 'Successfully Connected',
    },
  },
);

export const failureMessages = defineMessages(
  'admin-dashboard.Infrastructure.ConnectSilo.OAuth.failure',
  {
    info: {
      defaultMessage: 'You can close this page.',
    },
    header: {
      defaultMessage: 'Failed to connect!',
    },
    failed: {
      defaultMessage: 'Failed to connect!',
    },
    oauthError: {
      defaultMessage: 'Error occurred during OAuth: {msg}',
    },
  },
);

export const oAuthMessages = defineMessages(
  'admin-dashboard.Infrastructure.ConnectSilo.OAuth.oAuth',
  {
    button: {
      defaultMessage: 'Connect',
    },
    header: {
      defaultMessage: 'Connect with OAuth',
    },
    title: {
      defaultMessage: 'Connect with OAuth',
    },
  },
);
