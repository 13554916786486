import {
  FlexRowCenterVertical,
  FormatMessageArgs,
  GenericMessageDescriptor,
  Icon,
  Popover,
  useFormatMessageGeneric,
} from '@main/core-ui';
import React from 'react';
import { OverlayTriggerProps } from 'react-bootstrap';
import { CSSProperties } from 'styled-components';

export interface InfoTooltipProps {
  /** the contents of the tooltip */
  contents: GenericMessageDescriptor;
  /** formatting for the contents of the tooltip */
  messageArgs?: FormatMessageArgs;
  /** the trigger event, either click or hover */
  trigger?: 'click' | 'hover';
  /** style overrides */
  style?: CSSProperties;
  /** optionally show arrow or not */
  hideArrow?: boolean;
  /** optionally limit the popover width */
  popoverWidth?: string;
  /** Placement (defaults to auto) */
  placement?: OverlayTriggerProps['placement'];
}

/**
 * InfoTooltip
 */
export const InfoTooltip: React.FC<InfoTooltipProps> = ({
  contents,
  messageArgs,
  trigger,
  style,
  hideArrow = true,
  popoverWidth,
  placement = 'auto',
}) => {
  const { formatMessageGeneric } = useFormatMessageGeneric();
  return (
    <Popover
      contents={formatMessageGeneric(contents, messageArgs)}
      trigger={[trigger ?? 'hover', 'focus']}
      placement={placement}
      hideArrow={hideArrow}
      width={popoverWidth}
    >
      <FlexRowCenterVertical
        style={{ color: 'inherit', cursor: 'pointer', ...style }}
      >
        <Icon type="i-circle" />
      </FlexRowCenterVertical>
    </Popover>
  );
};
