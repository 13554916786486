import type { RequestStatus } from '@transcend-io/privacy-types';
import { createEnum, filterEnum } from '@transcend-io/type-utils';

import { REQUEST_STATUSES } from './statuses';

/**
 * The statuses that request report is being built in, and data silos should be shown
 */
export const REQUEST_STATUSES_BUILDING = filterEnum(
  REQUEST_STATUSES,
  (status) => !!status.isBuilding,
);

/**
 * The statuses building statuses keyed by status
 */
export const REQUEST_STATUS_BUILDING = createEnum(REQUEST_STATUSES_BUILDING);

/**
 * Determine whether the request is in a state of being built.
 *
 * "Built" means that any unknown identifiers have been verified, and the request is being constructed and has not been sent to the user.
 * If the request is building, its data silos and report can be modified.
 *
 * @param status - The status of the request to check
 * @returns True if the request currently being built
 */
export function isBuilding(status: RequestStatus): boolean {
  return status in REQUEST_STATUS_BUILDING;
}
