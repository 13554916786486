import * as t from 'io-ts';

import { dbModelId } from '@main/schema-utils';

import { AuditEventCode } from '../schema/enums';
import {
  mkAuditEventCodec,
  mkAuditEventJoinCodec,
  mkAuditJoinStateCodec,
} from '../types';

const baseFields = {
  /** ID of the sub data point */
  subDataPointId: dbModelId('subDataPoint'),
  /** ID of the data silo */
  dataSiloId: dbModelId('dataSilo'),
};

export const AuditEventSubDataPoint = mkAuditEventCodec(
  AuditEventCode.SubDataPoint,
  t.type(baseFields),
);

/** Override types. */
export type AuditEventSubDataPoint = t.TypeOf<typeof AuditEventSubDataPoint>;

export const AuditEventSubDataPointDataSubCategory = mkAuditEventJoinCodec(
  AuditEventCode.SubDataPointDataSubCategory,
  mkAuditJoinStateCodec(
    'dataSubCategory',
    t.type({ category: t.string, source: t.string }),
  ),
  t.type(baseFields),
);

/** Override types. */
export type AuditEventSubDataPointDataSubCategory = t.TypeOf<
  typeof AuditEventSubDataPointDataSubCategory
>;

export const AuditEventSubDataPointProcessingPurposeSubCategory =
  mkAuditEventJoinCodec(
    AuditEventCode.SubDataPointProcessingPurposeSubCategory,
    mkAuditJoinStateCodec(
      'processingPurposeSubCategory',
      t.type({ purpose: t.string }),
    ),
    t.type(baseFields),
  );

/** Override types. */
export type AuditEventSubDataPointProcessingPurposeSubCategory = t.TypeOf<
  typeof AuditEventSubDataPointProcessingPurposeSubCategory
>;

export const AuditEventSubDataPointSubject = mkAuditEventJoinCodec(
  AuditEventCode.SubDataPointSubject,
  mkAuditJoinStateCodec('subject'),
  t.type({
    /** ID of the sub-datapoint */
    subDataPointId: dbModelId('subDataPoint'),
  }),
);

/** Override types. */
export type AuditEventSubDataPointSubject = t.TypeOf<
  typeof AuditEventSubDataPointSubject
>;

export const AuditEventSubDataPointAttribute = mkAuditEventJoinCodec(
  AuditEventCode.SubDataPointAttribute,
  mkAuditJoinStateCodec(
    'attributeValue',
    t.type({
      /** the display title for the attribute key */
      attributeKeyTitle: t.string,
    }),
  ),
  t.type(baseFields),
);

/** Override types. */
export type AuditEventSubDataPointAttribute = t.TypeOf<
  typeof AuditEventSubDataPointAttribute
>;
