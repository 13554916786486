/* eslint-disable max-lines */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import difference from 'lodash/difference';
import uniq from 'lodash/uniq';
import uniqBy from 'lodash/uniqBy';

import { ConsentManager, DefaultConsentOption } from '@main/cm-types';
import type { ID } from '@main/schema-utils';

export interface DataFlowsFilters {
  /** Text filter for data flows */
  text?: string;
  /** Purposes filter for data flows */
  purposes: string[];
}

export interface DataFlowsTabState {
  /** The selected data flows */
  selectedDataFlows: {
    /** ID of data flow */
    id: ID<'airgapDataFlow'>;
    /** Whether the data flow can be approved or not */
    canApprove: boolean;
  }[];
}

export interface DataFlowsTabs {
  /** Tab showing data flows which need to be reviewed */
  triage: DataFlowsTabState;
  /** Tab showing live, approved data flows */
  approved: DataFlowsTabState;
  /** Tab showing junk data flows */
  junk: DataFlowsTabState;
}

export interface CookiesFilters {
  /** Text filter for data flows */
  text?: string;
  /** Purposes filter for data flows */
  trackingPurposes: string[];
}

export interface CookiesTabState {
  /** The selected cookies */
  selectedCookies: {
    /** ID of cookie */
    id: ID<'airgapCookie'>;
    /** Name of cookie */
    name: string;
    /** Whether the data flow can be approved or not */
    canApprove: boolean;
  }[];
}

export interface CookiesTabs {
  /** Tab showing data flows which need to be reviewed */
  triage: CookiesTabState;
  /** Tab showing live, approved data flows */
  approved: CookiesTabState;
  /** Tab showing junk data flows */
  junk: CookiesTabState;
}

/**
 * the consent manager v2 state
 */
export interface ConsentManagerV2State {
  /** The consent managers for this org */
  consentManagers: {
    [k: string]: ConsentManager;
  };
  /** The (global) default consent -- applies to all bundles and all tracking purposes */
  defaultConsent?: DefaultConsentOption;
  /** Consent manager - the currently selected consent manager */
  selectedConsentManagerId?: ID<'airgapBundle'>;
  /** The data flows state */
  dataFlows: DataFlowsTabs;
  /** The cookies state */
  cookies: CookiesTabs;
  /** The experiences state */
  experiences: ID<'experience'>[];
  /** The purposes state */
  purposes: ID<'purpose'>[];
  /** The selected consent services state */
  consentServices: ID<'consentService'>[];
  /** The selected consent applications */
  consentApplications: ID<'consentApplication'>[];
  /** The selected consent SDKs */
  consentSdks: ID<'consentSdk'>[];
  /** The selected TCF stacks */
  tcfStacks: ID<'tcfStack'>[];
}

const INITIAL_TAB_STATE = {
  selectedDataFlows: [],
};

const INIT_DATA_FLOWS = {
  triage: INITIAL_TAB_STATE,
  approved: INITIAL_TAB_STATE,
  junk: INITIAL_TAB_STATE,
};

const INITIAL_COOKIES_FILTERS = {
  selectedCookies: [],
};

const INIT_COOKIES = {
  triage: INITIAL_COOKIES_FILTERS,
  approved: INITIAL_COOKIES_FILTERS,
  junk: INITIAL_COOKIES_FILTERS,
};

export const consentManagerV2Slice = createSlice({
  name: 'ConsentManagerV2',
  initialState: {
    consentManagers: {},
    dataFlows: INIT_DATA_FLOWS,
    cookies: INIT_COOKIES,
    experiences: [],
    purposes: [],
    consentServices: [],
    consentApplications: [],
    consentSdks: [],
    tcfStacks: [],
  } as ConsentManagerV2State,
  reducers: {
    // data flows
    selectDataFlow: (
      state,
      {
        payload: { tab, id, canApprove },
      }: PayloadAction<{
        /** The tab this is for */
        tab: keyof DataFlowsTabs;
        /** The id to select */
        id: ID<'airgapDataFlow'>;
        /** Whether or not can approve */
        canApprove: boolean;
      }>,
    ) => {
      state.dataFlows[tab].selectedDataFlows.push({ id, canApprove });
    },
    unselectDataFlow: (
      state,
      {
        payload: { tab, id },
      }: PayloadAction<{
        /** The tab this is for */
        tab: keyof DataFlowsTabs;
        /** The id to select */
        id: ID<'airgapDataFlow'>;
      }>,
    ) => ({
      ...state,
      dataFlows: {
        ...state.dataFlows,
        [tab]: {
          ...state.dataFlows[tab],
          selectedDataFlows: state.dataFlows[tab].selectedDataFlows.filter(
            (selected) => selected.id !== id,
          ),
        },
      },
    }),
    selectDataFlows: (
      state,
      {
        payload: { tab, dataFlows },
      }: PayloadAction<{
        /** The tab this is for */
        tab: keyof DataFlowsTabs;
        /** The ids to select */
        dataFlows: {
          /** ID of data flow */
          id: ID<'airgapDataFlow'>;
          /** Whether the data flow can be approved or not */
          canApprove: boolean;
        }[];
      }>,
    ) => {
      state.dataFlows[tab].selectedDataFlows = uniqBy(
        state.dataFlows[tab].selectedDataFlows.concat(dataFlows),
        'id',
      );
    },
    unselectDataFlows: (
      state,
      {
        payload: { tab, ids },
      }: PayloadAction<{
        /** The tab this is for */
        tab: keyof DataFlowsTabs;
        /** The ids to unselect */
        ids: ID<'airgapDataFlow'>[];
      }>,
    ) => {
      state.dataFlows[tab].selectedDataFlows = state.dataFlows[
        tab
      ].selectedDataFlows.filter(({ id }) => !ids.includes(id));
    },
    resetSelectedDataFlows: (state) => ({
      ...state,
      dataFlows: INIT_DATA_FLOWS,
    }),
    // cookies
    selectCookie: (
      state,
      {
        payload: { tab, id, name, canApprove },
      }: PayloadAction<{
        /** The tab this is for */
        tab: keyof CookiesTabs;
        /** The id to select */
        id: ID<'airgapCookie'>;
        /** The name */
        name: string;
        /** Whether or not can approve */
        canApprove: boolean;
      }>,
    ) => {
      state.cookies[tab].selectedCookies.push({
        id,
        name,
        canApprove,
      });
    },
    unselectCookie: (
      state,
      {
        payload: { tab, id },
      }: PayloadAction<{
        /** The tab this is for */
        tab: keyof CookiesTabs;
        /** The id to select */
        id: ID<'airgapCookie'>;
      }>,
    ) => ({
      ...state,
      cookies: {
        ...state.cookies,
        [tab]: {
          ...state.cookies[tab],
          selectedCookies: state.cookies[tab].selectedCookies.filter(
            (selected) => selected.id !== id,
          ),
        },
      },
    }),
    selectCookies: (
      state,
      {
        payload: { tab, cookies },
      }: PayloadAction<{
        /** The tab this is for */
        tab: keyof CookiesTabs;
        /** The ids to select */
        cookies: {
          /** ID of cookie */
          id: ID<'airgapCookie'>;
          /** Name of the cookie */
          name: string;
          /** Whether the data flow can be approved or not */
          canApprove: boolean;
        }[];
      }>,
    ) => {
      state.cookies[tab].selectedCookies = uniqBy(
        state.cookies[tab].selectedCookies.concat(cookies),
        'id',
      );
    },
    unselectCookies: (
      state,
      {
        payload: { tab, ids },
      }: PayloadAction<{
        /** The tab this is for */
        tab: keyof CookiesTabs;
        /** The ids to unselect */
        ids: ID<'airgapCookie'>[];
      }>,
    ) => {
      state.cookies[tab].selectedCookies = state.cookies[
        tab
      ].selectedCookies.filter(({ id }) => !ids.includes(id));
    },
    resetSelectedCookies: (state) => ({
      ...state,
      cookies: INIT_COOKIES,
    }),
    // rest
    setConsentManager: (state, { payload }: PayloadAction<ConsentManager>) => {
      state.consentManagers[payload.id] = payload;
    },
    setSelectedConsentManagerId: (
      state,
      { payload }: PayloadAction<ID<'airgapBundle'>>,
    ) => {
      state.selectedConsentManagerId = payload;
    },
    selectExperience: (state, { payload }: PayloadAction<ID<'experience'>>) => {
      state.experiences.push(payload);
    },
    unselectExperience: (
      { experiences, ...state },
      { payload }: PayloadAction<ID<'experience'>>,
    ) => ({
      ...state,
      experiences: experiences.filter((id) => id !== payload),
    }),
    selectExperiences: (
      state,
      { payload }: PayloadAction<ID<'experience'>[]>,
    ) => {
      state.experiences = uniq(state.experiences.concat(payload));
    },
    unselectExperiences: (
      state,
      { payload }: PayloadAction<ID<'experience'>[]>,
    ) => {
      state.experiences = difference(state.experiences, payload);
    },
    resetSelectedExperiences: (state) => ({
      ...state,
      experiences: [],
    }),
    selectPurpose: (state, { payload }: PayloadAction<ID<'purpose'>>) => {
      state.purposes.push(payload);
    },
    unselectPurpose: (
      { purposes, ...state },
      { payload }: PayloadAction<ID<'purpose'>>,
    ) => ({
      ...state,
      purposes: purposes.filter((id) => id !== payload),
    }),
    selectPurposes: (state, { payload }: PayloadAction<ID<'purpose'>[]>) => {
      state.purposes = uniq(state.purposes.concat(payload));
    },
    unselectPurposes: (state, { payload }: PayloadAction<ID<'purpose'>[]>) => {
      state.purposes = difference(state.purposes, payload);
    },
    resetSelectedPurposes: (state) => ({
      ...state,
      purposes: [],
    }),
    selectConsentService: (
      state,
      { payload }: PayloadAction<ID<'consentService'>>,
    ) => {
      state.consentServices.push(payload);
    },
    unselectConsentService: (
      { consentServices, ...state },
      { payload }: PayloadAction<ID<'consentService'>>,
    ) => ({
      ...state,
      consentServices: consentServices.filter((id) => id !== payload),
    }),
    selectConsentServices: (
      state,
      { payload }: PayloadAction<ID<'consentService'>[]>,
    ) => {
      state.consentServices = uniq(state.consentServices.concat(payload));
    },
    unselectConsentServices: (
      state,
      { payload }: PayloadAction<ID<'consentService'>[]>,
    ) => {
      state.consentServices = difference(state.consentServices, payload);
    },
    resetSelectedConsentServices: (state) => ({
      ...state,
      consentServices: [],
    }),
    selectConsentApplication: (
      state,
      { payload }: PayloadAction<ID<'consentApplication'>>,
    ) => {
      state.consentApplications.push(payload);
    },
    unselectConsentApplication: (
      { consentApplications, ...state },
      { payload }: PayloadAction<ID<'consentApplication'>>,
    ) => ({
      ...state,
      consentApplications: consentApplications.filter((id) => id !== payload),
    }),
    selectConsentApplications: (
      state,
      { payload }: PayloadAction<ID<'consentApplication'>[]>,
    ) => {
      state.consentApplications = uniq(
        state.consentApplications.concat(payload),
      );
    },
    unselectConsentApplications: (
      state,
      { payload }: PayloadAction<ID<'consentApplication'>[]>,
    ) => {
      state.consentApplications = difference(
        state.consentApplications,
        payload,
      );
    },
    resetSelectedConsentApplications: (state) => ({
      ...state,
      consentApplications: [],
    }),
    selectConsentSdk: (state, { payload }: PayloadAction<ID<'consentSdk'>>) => {
      state.consentSdks.push(payload);
    },
    unselectConsentSdk: (
      { consentSdks, ...state },
      { payload }: PayloadAction<ID<'consentSdk'>>,
    ) => ({
      ...state,
      consentSdks: consentSdks.filter((id) => id !== payload),
    }),
    selectConsentSdks: (
      state,
      { payload }: PayloadAction<ID<'consentSdk'>[]>,
    ) => {
      state.consentSdks = uniq(state.consentSdks.concat(payload));
    },
    unselectConsentSdks: (
      state,
      { payload }: PayloadAction<ID<'consentSdk'>[]>,
    ) => {
      state.consentSdks = difference(state.consentSdks, payload);
    },
    resetSelectedConsentSdks: (state) => ({
      ...state,
      consentSdks: [],
    }),
    selectTcfStack: (state, { payload }: PayloadAction<ID<'tcfStack'>>) => {
      state.tcfStacks.push(payload);
    },
    unselectTcfStack: (
      { tcfStacks, ...state },
      { payload }: PayloadAction<ID<'tcfStack'>>,
    ) => ({
      ...state,
      tcfStacks: tcfStacks.filter((id) => id !== payload),
    }),
    selectTcfStacks: (state, { payload }: PayloadAction<ID<'tcfStack'>[]>) => {
      state.tcfStacks = uniq(state.tcfStacks.concat(payload));
    },
    unselectTcfStacks: (
      state,
      { payload }: PayloadAction<ID<'tcfStack'>[]>,
    ) => {
      state.tcfStacks = difference(state.tcfStacks, payload);
    },
    resetSelectedTcfStacks: (state) => ({
      ...state,
      tcfStacks: [],
    }),
  },
});

export const {
  selectDataFlow,
  unselectDataFlow,
  resetSelectedDataFlows,
  selectDataFlows,
  unselectDataFlows,
  selectCookie,
  unselectCookie,
  resetSelectedCookies,
  selectCookies,
  unselectCookies,
  setConsentManager,
  setSelectedConsentManagerId,
  selectExperience,
  unselectExperience,
  selectExperiences,
  unselectExperiences,
  resetSelectedExperiences,
  selectPurpose,
  unselectPurpose,
  selectPurposes,
  unselectPurposes,
  resetSelectedPurposes,
  selectConsentService,
  unselectConsentService,
  selectConsentServices,
  unselectConsentServices,
  resetSelectedConsentServices,
  selectConsentApplication,
  unselectConsentApplication,
  selectConsentApplications,
  unselectConsentApplications,
  resetSelectedConsentApplications,
  selectConsentSdk,
  unselectConsentSdk,
  selectConsentSdks,
  unselectConsentSdks,
  resetSelectedConsentSdks,
  selectTcfStack,
  unselectTcfStack,
  selectTcfStacks,
  unselectTcfStacks,
  resetSelectedTcfStacks,
} = consentManagerV2Slice.actions;
/* eslint-enable max-lines */
