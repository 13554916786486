import { MultiItemOverflowDisplay } from '@main/ad-core-components';
import { FlexRow } from '@main/core-ui';
import { Sdk } from '@main/datamap-types';
import React, { useState } from 'react';

import { InlineSdkModal } from './InlineSdkModal';

/** Props for the `InlineDiscoveredBy` component */
interface InlineDiscoveredByProps {
  /** The SDK to preview */
  sdk: Sdk;
}

export const PreviewSdkMetadata: React.FC<InlineDiscoveredByProps> = ({
  sdk,
}) => {
  const [visible, setVisible] = useState(false);

  return (
    <>
      <InlineSdkModal visible={visible} setVisible={setVisible} sdk={sdk} />
      <FlexRow style={{ height: '100%' }}>
        <MultiItemOverflowDisplay
          isCircle={false}
          isInline
          items={[
            {
              logo: sdk.catalog.logoSquare,
              id: sdk.id,
              displayName: sdk.name,
            },
          ]}
          onClick={() => {
            setVisible(true);
          }}
        />
      </FlexRow>
    </>
  );
};
