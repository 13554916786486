import { FilterConfigItem } from '@main/ad-core-components';
import { SelectCatalogs } from '@main/admin-dashboard/src/DataMap/TriageSiloDiscovery/components/SelectCatalog/SelectCatalogs';
import {
  CATALOG_SELECTION_NODES,
  SelectedCatalog,
} from '@main/admin-dashboard/src/DataMap/TriageSiloDiscovery/types';
import { buildUseLazyQuery, Logo, message } from '@main/core-ui';
import { endpoints } from '@main/datamap-types';
import { ID } from '@main/schema-utils';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { MessageDescriptor } from 'react-intl';

/**
 * Filters definition with property for data silo values specified
 */
type CatalogEnrichedFilters = Record<string, unknown> &
  Record<
    /**
     * Data silo values that are currently filtering the table
     */
    string,
    SelectedCatalog[] | undefined
  >;

/**
 * Hook to get the filter configuration for data silos
 *
 * @returns the data silo keys along with the config for the filter and a function to clear the filters
 */
type BuildCatalogFilters<
  T extends Record<string, unknown>,
  E extends CatalogEnrichedFilters,
> = ({
  catalogIntegrationNamesFilterKey,
  filters,
  setFilters,
  enrichedCatalogKey,
}: {
  /** The property name of the filter for data silo ids */
  catalogIntegrationNamesFilterKey?: keyof T;
  /** The currently applied filters */
  filters?: T;
  /** Callback when the filters are changed */
  setFilters: (filters: T) => void;
  /** Label for the filter menu */
  label: string | MessageDescriptor;
  /** The key to use if more than one useCatalogFilters is used in a FilterManager */
  enrichedCatalogKey?: keyof E;
}) => {
  /**  The data silo keys enabled on this table */
  selectedCatalogs: SelectedCatalog[];
  /** The filter configuration to be passed to the filter manager */
  catalogFiltersConfig: FilterConfigItem<E>;
  /** Callback for when the filter is cleared in the filter manager */
  clearCatalogFilters: (key: Extract<keyof E, string>) => void;
};

const useLazyCatalogs = buildUseLazyQuery(
  endpoints.catalogs,
  'useCatalogFilters',
  {
    nodes: CATALOG_SELECTION_NODES,
    totalCount: null,
  },
);

export const useCatalogFilters: BuildCatalogFilters<
  Record<string, unknown>,
  CatalogEnrichedFilters
> = ({
  catalogIntegrationNamesFilterKey = 'integrationNames',
  filters,
  setFilters,
  label,
  enrichedCatalogKey = 'catalogs',
}) => {
  const getSelectedCatalogs = useLazyCatalogs();
  const [selectedCatalogs, setSelectedCatalogs] = useState<SelectedCatalog[]>(
    [],
  );
  const [fetching, setFetching] = useState(false);
  const [hasError, setHasError] = useState(false);

  // Populate initial complex filter values
  useEffect(() => {
    if (filters) {
      const catalogIntegrationNames = (filters[
        catalogIntegrationNamesFilterKey
      ] || []) as ID<'catalog'>[];

      const filtersAndEnrichedFiltersMatch =
        catalogIntegrationNames.sort().join() ===
        selectedCatalogs
          .map(({ id }) => id)
          .sort()
          .join();

      if (catalogIntegrationNames.length === 0 && selectedCatalogs.length > 0) {
        setSelectedCatalogs([]);
      } else if (!filtersAndEnrichedFiltersMatch && !fetching && !hasError) {
        // Prevent over-firing this setter while data is still being fetched
        setFetching(true);
        getSelectedCatalogs({ filterBy: { type: catalogIntegrationNames } })
          .then(({ data }) => {
            setSelectedCatalogs(data.nodes);
          })
          .catch((err) => {
            setHasError(true);
            message.error(err.message);
          })
          .finally(() => {
            setFetching(false);
          });
      }
    }
  }, [
    catalogIntegrationNamesFilterKey,
    fetching,
    filters,
    getSelectedCatalogs,
    selectedCatalogs,
  ]);

  const catalogFiltersConfig = useMemo(
    () =>
      ({
        filterKey: enrichedCatalogKey,
        label,
        pillOptions: {
          label: ({
            filterValues: { [enrichedCatalogKey]: catalogs = [] },
            index = 0,
          }) => (
            <>
              <Logo
                logo={catalogs[index].logoSquare}
                height="18px"
                style={{ marginRight: 3, marginTop: -2 }}
              />
              {catalogs[index]?.title}
            </>
          ),
        },
        filter: (
          <SelectCatalogs
            menuPosition="absolute"
            value={selectedCatalogs}
            onChange={(catalogs) => {
              setSelectedCatalogs(catalogs);
              setFilters({
                ...filters,
                [catalogIntegrationNamesFilterKey]: catalogs.map(
                  ({ integrationName }) => integrationName,
                ),
              });
            }}
          />
        ),
      }) as FilterConfigItem<CatalogEnrichedFilters>,
    [catalogIntegrationNamesFilterKey, selectedCatalogs, filters, label],
  );

  const clearCatalogFilters = useCallback(
    (key) => {
      if (key === enrichedCatalogKey) {
        setFilters({
          ...filters,
          [catalogIntegrationNamesFilterKey]: [],
        });
        setSelectedCatalogs([]);
      }
    },
    [selectedCatalogs, filters, catalogIntegrationNamesFilterKey],
  );

  return {
    selectedCatalogs,
    catalogFiltersConfig,
    clearCatalogFilters,
  };
};
