import { useDispatch } from 'react-redux';

import { LanguageKey } from '@transcend-io/internationalization';

import { NODE_ENV } from '@main/core-ui';
import { moment } from '@main/utils';

import { ADMIN_DASHBOARD_TRANSLATIONS } from '../../translations';
import { setTranslations } from './slice';

/**
 * Callback to change translations and selected locale
 *
 * @returns Callback
 */
export function useChangeTranslations(): (locale: LanguageKey) => void {
  const dispatch = useDispatch();
  return (locale) => {
    const getTranslations = ADMIN_DASHBOARD_TRANSLATIONS[locale];
    if (getTranslations) {
      moment.locale(locale);
      getTranslations().then((loaded) => {
        dispatch(
          setTranslations({
            locale,
            translations:
              NODE_ENV === 'development' && locale === 'en'
                ? // use translations directly from messages.ts instead in local dev
                  {}
                : loaded.default,
          }),
        );
      });
    }
  };
}
