import { defineMessages } from '@transcend-io/internationalization';

export const dashboardChartMessages = defineMessages(
  'ad-core-components.DataVisualizations.SimpleDashboardCharts.dashboardChart',
  {
    noData: {
      defaultMessage: 'No data!',
    },
    logScale: {
      defaultMessage: 'Log scale',
    },
    total: {
      defaultMessage: 'Total',
    },
    noDataEffectiveDateWarning: {
      defaultMessage:
        'Features supporting this chart are not enabled or have not been enabled for long enough to receive data.',
    },
    reducedDataEffectiveDateWarning: {
      defaultMessage:
        'Features supporting this chart were initially enabled on {date}. Time periods before ' +
        'this date do not have data, so the values displayed are limited to after that date.',
    },
  },
);
