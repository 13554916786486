import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Requirize } from '@transcend-io/type-utils';

import type { Request } from '@main/dsr-types';
import { ID } from '@main/schema-utils';

/**
 * Minimum subset of fields required to render the page
 */
export type PartialRequest = Requirize<
  Partial<Request>,
  'id' | 'status' | 'isSilent' | 'origin' | 'type' | 'subjectType'
>;

/**
 * the request state
 */
export interface RequestState {
  /** The ID of the request currently being requested (different from request.id when in flight)  */
  requestId?: ID<'request'>;
  /** The request being viewed  */
  request?: Request | PartialRequest;
}

export const requestSlice = createSlice({
  name: 'Request',
  initialState: {} as RequestState,
  reducers: {
    setRequest: (
      state,
      {
        payload: { request },
      }: PayloadAction<{
        /** The request being viewed  */
        request?: Request | PartialRequest;
      }>,
    ) => ({
      ...state,
      request,
    }),
    setRequestId: (
      state,
      {
        payload: { requestId },
      }: PayloadAction<{
        /** The ID of the request currently being requested (different from request.id when in flight)  */
        requestId?: ID<'request'>;
      }>,
    ) => ({
      ...state,
      requestId,
    }),
  },
});

export const { setRequest, setRequestId } = requestSlice.actions;
