import { QueueStatus } from '@transcend-io/privacy-types';
import { makeEnum } from '@transcend-io/type-utils';

/**
 * Status of profile/datapoint processing
 */
export const ProfileDataPointStatus = makeEnum({
  ...QueueStatus,
  /** The datapoint expected data to be uploaded to it or marked as completed manually */
  Manual: 'MANUAL',
  /** The datapoint expected data to be uploaded to it or marked as completed manually */
  WaitingOnDeletionDependencies: 'WAITING_ON_DELETION_DEPENDENCIES',
  /**
   * The datapoint has been fired and the action was initialized successfully, however not complete.
   * This if often useful when we are adding to some sort of queue, and we then have to poll that queue to verify successful completion.
   */
  Polling: 'POLLING',
  /**
   *  The datapoint is waiting on other requests to complete before it can be processed.
   */
  WaitingOnOtherRequests: 'WAITING_ON_OTHER_REQUESTS',
  /** The request not processed due to some exception */
  SkippedDueToException: 'SKIPPED_DUE_TO_EXCEPTION',
  /** The secondary action ran and completed */
  Finished: 'FINISHED',
  /**
   * Note: this request status should never be used.
   *
   * - If a specific file requires manual review, then the requestFile.status should be set to ACTION_REQUIRED
   * - If a request data silo requires manual review then RequestDataSilo.status should be set to ACTION_REQUIRED
   *
   * This enum value is needed because:
   * 1. the resolver for ProfileDataPointStatus returns ActionRequired if any request files have this marked
   * 2. the datapoints.ts file expects a ProfileDataPointStatus even though this updates the requestFile status
   */
  ActionRequired: 'ACTION_REQUIRED',
});

/**
 * Overload type
 */
export type ProfileDataPointStatus =
  (typeof ProfileDataPointStatus)[keyof typeof ProfileDataPointStatus];

/**
 * The statuses that indicate a successful completion
 */
export const ProfileDataPointStatusSuccess = makeEnum({
  /** Successfully resolved */
  Resolved: 'RESOLVED',
  /** The secondary action ran and completed */
  Finished: 'FINISHED',
  /** Skipped processing because already completed */
  Skipped: 'SKIPPED',
  /** The request not processed due to some exception */
  SkippedDueToException: 'SKIPPED_DUE_TO_EXCEPTION',
});

/**
 * Overload type
 */
export type ProfileDataPointStatusSuccess =
  (typeof ProfileDataPointStatusSuccess)[keyof typeof ProfileDataPointStatusSuccess];

/**
 * The type of profile datapoint queues
 */
export enum ProfileDataPointQueueType {
  /**
   * The primary action for the DSR. Typically this is just the resolver for the action, but in the case where the action supports a delay,
   * it is the action that is run before the delay occurs. i.e. an erasure -> access, access -> access, custom -> custom
   */
  Primary = 'PRIMARY',
  /** Run the secondary action for the request */
  Secondary = 'SECONDARY',
}

/**
 * Fields that a ProfileDataPoint can be ordered by
 */
export enum ProfileDataPointOrderField {
  /** When the ProfileDataPoint was created */
  CreatedAt = 'createdAt',
  /** The time the profile datapoint was updated */
  UpdatedAt = 'updatedAt',
}
