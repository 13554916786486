import * as t from 'io-ts';

import { dbModelId } from '@main/schema-utils';

import { AuditEventCode } from '../schema/enums';
import { mkAuditEventCodec } from '../types';

export const AuditEventAssessmentAnswer = mkAuditEventCodec(
  AuditEventCode.AssessmentAnswer,
  t.intersection([
    t.type({
      /** ID of the assessment question */
      assessmentAnswerId: dbModelId('assessmentAnswer'),
    }),
    t.partial({
      /** ID of the assessment question that owns the answer */
      assessmentQuestionId: dbModelId('assessmentQuestion'),
      /** ID of the assessment section that owns the answer */
      assessmentSectionId: dbModelId('assessmentSection'),
      /** ID of the assessment template that owns the answer */
      assessmentFormTemplateId: dbModelId('assessmentFormTemplate'),
      /** ID of the assessment form that owns the answer */
      assessmentFormId: dbModelId('assessmentForm'),
    }),
  ]),
);

/** Override types. */
export type AuditEventAssessmentAnswer = t.TypeOf<
  typeof AuditEventAssessmentAnswer
>;
