import { endpoints } from '@main/access-control-types';
import {
  FilterConfigItem,
  SelectedTeam,
  SelectTeams,
} from '@main/ad-core-components';
import { buildUseLazyQuery } from '@main/core-ui';
import { ID } from '@main/schema-utils';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { MessageDescriptor } from 'react-intl';

import { filterManagerHookMessages } from './messages';

/**
 * Filters definition with property for team values specified
 */
type TeamEnrichedFilters = Record<string, unknown> &
  Record<
    /**
     * Team values that are currently filtering the table
     */
    string,
    SelectedTeam[] | undefined
  >;

const useLazyTeams = buildUseLazyQuery(endpoints.teams);

export const useTeamFilters = <
  T extends Record<string, unknown>,
  E extends TeamEnrichedFilters,
>({
  teamIdsFilterKey = 'teamIds',
  filters,
  setFilters,
  label,
  enrichedTeamKey = 'teams',
}: {
  /** The property name of the filter for team ids */
  teamIdsFilterKey?: keyof T;
  /** The currently applied filters */
  filters?: T;
  /** Callback when the filters are changed */
  setFilters: (filters: T) => void;
  /** Label for the filter menu */
  label: string | MessageDescriptor;
  /** The key to use if more than one useTeamFilters is used in a FilterManager */
  enrichedTeamKey?: keyof E;
}): {
  /**  The team keys enabled on this table */
  selectedTeams: SelectedTeam[];
  /** The filter configuration to be passed to the filter manager */
  teamFiltersConfig: FilterConfigItem<E>;
  /** Callback for when the filter is cleared in the filter manager */
  clearTeamFilters: (key: Extract<keyof E, string>) => void;
} => {
  const getSelectedTeams = useLazyTeams();
  const [selectedTeams, setSelectedTeams] = useState<SelectedTeam[]>([]);
  const [fetchingTeams, setFetchingTeams] = useState(false);

  // Populate initial complex filter values
  useEffect(() => {
    if (filters) {
      const teamIds = (filters[teamIdsFilterKey] ?? []) as ID<'team'>[];

      const filtersAndEnrichedFiltersMatch =
        teamIds.sort().join() ===
        selectedTeams
          .map(({ id }) => id)
          .sort()
          .join();

      if (teamIds.length === 0 && selectedTeams.length > 0) {
        setSelectedTeams([]);
      } else if (!filtersAndEnrichedFiltersMatch && !fetchingTeams) {
        // Prevent over-firing this setter while data is still being fetched
        setFetchingTeams(true);
        getSelectedTeams({ filterBy: { ids: teamIds } })
          .then(({ data }) => {
            setSelectedTeams(data.nodes);
          })
          .finally(() => {
            setFetchingTeams(false);
          });
      }
    }
  }, [
    filters,
    fetchingTeams,
    getSelectedTeams,
    selectedTeams.length,
    teamIdsFilterKey,
    selectedTeams,
  ]);

  const teamFiltersConfig = useMemo(
    () =>
      ({
        filterKey: enrichedTeamKey,
        label,
        pillOptions: {
          label: ({
            filterValues: { [enrichedTeamKey]: teams = [] },
            index = 0,
          }) => teams[index]?.name,
        },
        filter: (
          <SelectTeams
            isMulti
            placeholderDescriptor={filterManagerHookMessages.teamsPlaceholder}
            menuPosition="absolute"
            value={selectedTeams}
            showOutline={false}
            onChange={(teams) => {
              setSelectedTeams([...teams]);
              setFilters({
                ...filters,
                [teamIdsFilterKey]: teams.map(({ id }) => id),
              } as T);
            }}
          />
        ),
      }) as FilterConfigItem<TeamEnrichedFilters>,
    [enrichedTeamKey, label, selectedTeams, filters, teamIdsFilterKey],
  );

  const clearTeamFilters = useCallback(
    (key) => {
      if (key === enrichedTeamKey) {
        setFilters({
          ...filters,
          [teamIdsFilterKey]: [],
        } as T);
        setSelectedTeams([]);
      }
    },
    [enrichedTeamKey, filters, teamIdsFilterKey],
  );

  return {
    selectedTeams,
    teamFiltersConfig,
    clearTeamFilters,
  };
};
