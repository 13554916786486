import { Avatar, IWithClassName } from '@main/core-ui';
import React from 'react';

export const BACKGROUND_COLORS = [
  '#75BDFF',
  '#81DBC6',
  '#F5D871',
  '#FFAF75',
  '#FE9F96',
  '#B275FF',
];

export interface IOrganizationIconProps extends IWithClassName {
  /** The organization's icon src */
  organizationIconSrc?: string;
  /** The organization's name */
  organizationName?: string;
  /** The size of the icon */
  size?: number;
  /** Styles for this icon */
  style?: React.CSSProperties;
  /** should the icon be displayed as a circle */
  circle?: boolean;
}

export const OrganizationIcon = ({
  organizationIconSrc,
  organizationName,
  ...props
}: IOrganizationIconProps): JSX.Element => (
  <Avatar
    circle={false}
    src={organizationIconSrc}
    name={organizationName}
    {...props}
  />
);
