import * as t from 'io-ts';

import { dbModelId } from '@main/schema-utils';

import { AuditEventCode } from '../schema/enums';
import {
  mkAuditEventCodec,
  mkAuditEventJoinCodec,
  mkAuditJoinStateCodec,
} from '../types';

export const AuditEventAirgapDataFlow = mkAuditEventCodec(
  AuditEventCode.AirgapDataFlow,
  t.type({
    /** ID of the airgap data flow */
    airgapDataFlowId: dbModelId('airgapDataFlow'),
    /** ID of the airgap bundle */
    airgapBundleId: dbModelId('airgapBundle'),
  }),
);

/** Override types. */
export type AuditEventAirgapDataFlow = t.TypeOf<
  typeof AuditEventAirgapDataFlow
>;

export const AuditEventAirgapDataFlowOwner = mkAuditEventJoinCodec(
  AuditEventCode.AirgapDataFlowOwner,
  mkAuditJoinStateCodec('user'),
  t.type({
    /** ID of the airgap data flow */
    airgapDataFlowId: dbModelId('airgapDataFlow'),
    /** ID of the airgap bundle */
    airgapBundleId: dbModelId('airgapBundle'),
  }),
);

/** Override types. */
export type AuditEventAirgapDataFlowOwner = t.TypeOf<
  typeof AuditEventAirgapDataFlowOwner
>;

export const AuditEventAirgapDataFlowTeam = mkAuditEventJoinCodec(
  AuditEventCode.AirgapDataFlowTeam,
  mkAuditJoinStateCodec('team'),
  t.type({
    /** ID of the airgap data flow */
    airgapDataFlowId: dbModelId('airgapDataFlow'),
    /** ID of the airgap bundle */
    airgapBundleId: dbModelId('airgapBundle'),
  }),
);

/** Override types. */
export type AuditEventAirgapDataFlowTeam = t.TypeOf<
  typeof AuditEventAirgapDataFlowTeam
>;

export const AuditEventAirgapDataFlowAttribute = mkAuditEventJoinCodec(
  AuditEventCode.AirgapDataFlowAttribute,
  mkAuditJoinStateCodec(
    'attributeValue',
    t.type({
      /** the display title for the attribute key */
      attributeKeyTitle: t.string,
    }),
  ),
  t.type({
    /** ID of the airgap data flow */
    airgapDataFlowId: dbModelId('airgapDataFlow'),
    /** ID of the airgap bundle */
    airgapBundleId: dbModelId('airgapBundle'),
  }),
);

/** Override types. */
export type AuditEventAirgapDataFlowAttribute = t.TypeOf<
  typeof AuditEventAirgapDataFlowAttribute
>;

export const AuditEventAirgapDataFlowPurpose = mkAuditEventJoinCodec(
  AuditEventCode.AirgapDataFlowPurpose,
  mkAuditJoinStateCodec('purpose'),
  t.type({
    /** ID of the airgap data flow */
    airgapDataFlowId: dbModelId('airgapDataFlow'),
    /** ID of the airgap bundle */
    airgapBundleId: dbModelId('airgapBundle'),
  }),
);

/** Override types. */
export type AuditEventAirgapDataFlowPurpose = t.TypeOf<
  typeof AuditEventAirgapDataFlowPurpose
>;
