import {
  PaginatedSelect,
  PaginatedSelectProps,
} from '@main/ad-core-components';
import { buildUseInfiniteScroll } from '@main/core-ui';
import { Catalog, endpoints } from '@main/datamap-types';
import React, { useState } from 'react';
import type { MenuPosition } from 'react-select';

import { selectIntegrationsMessages } from '../../../SelectIntegrations/messages';

const CATALOG_SELECTION_NODES = {
  id: null,
  title: null,
  description: null,
  integrationName: null,
  logoSquare: null,
  logo: null,
} as const;

/**
 * the type of the selected catalog
 */
export type SelectedCatalog = Pick<
  Catalog,
  keyof typeof CATALOG_SELECTION_NODES
>;

export interface UpdatedSiloGuessParams {
  /** the updated catalog selection */
  updatedCatalog?: SelectedCatalog;
  /** the updated custom title */
  updatedTitle?: string;
}

export interface SelectCatalogProps
  extends Partial<PaginatedSelectProps<SelectedCatalog, false>> {
  /** the current catalog selection  */
  value?: SelectedCatalog | null;
  /** handler for when the selection changes */
  onChange: (selection?: SelectedCatalog | null) => void;
  /** The menu position */
  menuPosition?: MenuPosition;
  /** Whether to show outline of input */
  showOutline?: boolean;
  /** Whether this is clearable */
  isClearable?: boolean;
  /** Whether loading */
  isLoading?: boolean;
  /** Element to attach the dropdown menu to */
  menuPortalTarget?: HTMLElement;
}

const useCatalogs = buildUseInfiniteScroll(
  endpoints.catalogs,
  'SelectIntegrations',
  {
    nodes: CATALOG_SELECTION_NODES,
    totalCount: null,
  },
);

/**
 * SelectCatalog
 */
export const SelectCatalog: React.FC<SelectCatalogProps> = ({
  value,
  onChange,
  menuPosition,
  showOutline,
  isLoading,
  isClearable,
  menuPortalTarget,
  ...rest
}) => {
  const [searchText, setSearchText] = useState('');
  const { data, loading, error, fetchMore } = useCatalogs({
    variables: { filterBy: { text: searchText } },
  });
  return (
    <PaginatedSelect<SelectedCatalog, false>
      id="select-catalogs"
      isQueryLoading={isLoading || loading}
      captureMenuScroll
      queryError={error}
      onEndsTyping={setSearchText}
      options={data?.nodes || []}
      getOptionValue={({ id }) => id}
      getOptionLabel={({ title }) => title}
      getOptionLogo={({ logoSquare }) => logoSquare}
      fetchMore={fetchMore}
      value={value}
      placeholderDescriptor={selectIntegrationsMessages.placeholderSingle}
      onChange={(selected) =>
        onChange(selected ?? (isClearable ? undefined : value))
      }
      variant="light"
      showOutline={showOutline}
      menuPosition={menuPosition}
      isClearable={isClearable}
      menuPortalTarget={menuPortalTarget}
      {...rest}
    />
  );
};
