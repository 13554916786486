import type {
  Cookie,
  ProcessedTelemetryCookieQueue,
  TelemetryCookieQueue,
  TelemetryStatsEntryJSON,
} from '@main/ag-types';
import { isValidHost } from '@main/utils';

import { isNumeric } from './isNumeric';
import { stripKnownCookieJunk } from './stripKnownCookieJunk';

const parseCookieChunk = (chunk: string): string[] => {
  const parts = chunk.split(/=(.*)/s, 2); // Split into at most two parts
  if (parts.length < 2) {
    // If there's no '=' in the input, treat the whole chunk as the key
    return [chunk.trim(), ''];
  }
  // Otherwise, process key and value
  return parts.map((subChunk, index) =>
    index === 1 ? decodeURIComponent(subChunk.trim()) : subChunk.trim(),
  );
};

const cookieParameters: (keyof Cookie)[] = [
  'timestamp',
  'expires',
  'maxAge',
  'domain',
  'path',
  'sameSite',
  'secure',
];
const cookieParametersLowercase = cookieParameters.map((param) =>
  param.toLowerCase(),
);
const booleanParameters = cookieParametersLowercase.slice(-1);

/**
 * Parse a cookie string into a `Cookie` object
 *
 * @param input - Cookie string
 * @param init - Initial cookie attributes
 * @returns a `Cookie` object or null if no cookie detected
 */
export function parseCookie(
  input: string,
  init?: Partial<Cookie>,
): Cookie | null {
  const chunks = input.split(';');
  const [name, value = ''] = parseCookieChunk(chunks[0]);

  if (!name && !value) {
    return null;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const options: { [key in keyof Cookie]?: any } = Object.create(null);

  chunks.slice(1).forEach((chunk) => {
    const [key, value] = chunk.split('=').map((subChunk) => subChunk.trim());
    // Remove dashes etc for Max-Age -> MaxAge
    const searchKey = key.replace(/[^a-z]/i, '').toLowerCase();
    const foundKey = cookieParametersLowercase.indexOf(searchKey);
    if (foundKey !== -1) {
      options[cookieParameters[foundKey]] = booleanParameters.includes(
        searchKey,
      )
        ? !!value
        : value;
    }
  });

  return {
    name,
    value,
    ...init,
    ...options,
  };
}

/**
 *  Check if cookie host is an ancestor domain of site.
 *
 * @param site - Source site hostname
 * @param host - cookie host
 * @returns true if host is an ancestor domain of site
 */
function isAncestorDomain(site: string, host: string): boolean {
  try {
    const siteUrl =
      site.includes('http://') || site.includes('https://')
        ? new URL(site)
        : new URL(`https://${site}`);
    const siteDomain = siteUrl.hostname;
    const hostUrl = host.startsWith('.')
      ? new URL(`https://${host.slice(1)}`)
      : new URL(`https://${host}`);
    const hostDomain = hostUrl.hostname;
    if (hostDomain.length > siteDomain.length) {
      return false;
    }
    const splitSite = siteDomain.split('.').reverse();
    return hostDomain
      .split('.')
      .reverse()
      .every((part, index) => part === splitSite[index]);
  } catch (e) {
    // invalid site or cookie host
    return false;
  }
}

/**
 * Parses `TelemetryRequest.cookies` (v2 format) into a `TelemetryCookieQueue`.
 *
 * @param cookies - Encountered cookie list
 * @param site - Source site hostname
 * @returns Map of cookies to cookie stats metadata (hosts & encounter count)
 */
export function parseTelemetryCookiesV2(
  cookies: TelemetryStatsEntryJSON['cookies'],
  site: string,
): TelemetryCookieQueue {
  const stats: TelemetryCookieQueue = new Map();
  if (typeof cookies === 'string') {
    const lines = cookies.split('\n');
    let line = 0;
    while (line < lines.length) {
      // eslint-disable-next-line no-plusplus
      const cookie = stripKnownCookieJunk(lines[line++]);
      // eslint-disable-next-line no-plusplus
      const chunks = lines[line++]?.split(' ') || [];
      const encounters = stats.get(cookie) || new Map<string, number>();
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < chunks.length; i++) {
        const host = chunks[i];
        if (
          (host === '^' && site) ||
          (!isNumeric(host) &&
            isAncestorDomain(site, host) &&
            isValidHost(host))
        ) {
          const nextChunk = chunks[i + 1];
          const nextChunkIsCount = isNumeric(nextChunk);
          const count = nextChunkIsCount ? parseInt(nextChunk, 10) : 1;
          if (nextChunkIsCount) {
            if (Number.isNaN(count) || count < 1) {
              throw Error(
                `Invalid count for cookie: ${cookie} w/ host: ${host}`,
              );
            }
            // eslint-disable-next-line no-plusplus
            i++;
          }
          encounters.set(
            host === '^' && site ? site : host,
            (encounters.get(host) || 0) + count,
          );
        } else {
          throw Error(
            `Invalid payload.\n Site: ${site}\n Cookie domain: ${host}\n Cookie: ${cookie}\n`,
          );
        }
      }
      if (encounters.size > 0 && !stats.has(cookie)) {
        stats.set(cookie, encounters);
      }
    }
  }
  return stats;
}

/**
 * Parses encountered cookie list into `ProcessedTelemetryCookieQueue` with Cookie object keys.
 *
 * @param input - Encountered cookie list
 * @param site - Site hostname
 * @returns Map of rich cookie objects to cookie stats metadata (hosts & encounter count)
 */
export function parseAndProcessTelemetryCookiesV2(
  input: string,
  site: string,
): ProcessedTelemetryCookieQueue {
  const cookies = parseTelemetryCookiesV2(input, site);
  const processedStats: ProcessedTelemetryCookieQueue = new Map();
  // eslint-disable-next-line no-restricted-syntax
  for (const [cookie, encounters] of cookies.entries()) {
    const parsedCookie = parseCookie(cookie);
    if (parsedCookie) {
      const {
        // drop value
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        value,
        ...cookie
      } = parsedCookie;
      processedStats.set(cookie, encounters);
    }
  }
  return processedStats;
}

/**
 * Flatten v2 `TelemetryCookieQueue` into v1 `TelemetryCookieQueue` without attributes.
 *
 * @param cookies - TelemetryCookieQueue
 * @returns Map of cookies to cookie stats metadata (hosts & encounter count)
 */
export function flattenTelemetryCookiesV2ToV1(
  cookies: TelemetryCookieQueue,
): TelemetryCookieQueue {
  const stats: TelemetryCookieQueue = new Map();
  // eslint-disable-next-line no-restricted-syntax
  for (const [cookie, encounters] of cookies.entries()) {
    const cookieName = parseCookie(cookie)?.name;
    if (cookieName) {
      stats.set(cookieName, encounters || new Map());
    }
  }
  return stats;
}
