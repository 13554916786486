import * as t from 'io-ts';

import { dbModelId } from '@main/schema-utils';

import { AuditEventCode } from '../schema/enums';
import { mkAuditEventCodec } from '../types';

export const AuditEventAssessmentQuestionComment = mkAuditEventCodec(
  AuditEventCode.AssessmentQuestionComment,
  t.intersection([
    t.type({
      /** ID of the assessment question comment */
      assessmentQuestionCommentId: dbModelId('assessmentQuestionComment'),
    }),
    t.partial({
      /** ID of the assessment question where the comment was made */
      assessmentQuestionId: dbModelId('assessmentQuestion'),
      /** ID of the assessment section that owns the comment */
      assessmentSectionId: dbModelId('assessmentSection'),
      /** ID of the assessment template that owns the comment */
      assessmentFormTemplateId: dbModelId('assessmentFormTemplate'),
      /** ID of the assessment form that owns the comment */
      assessmentFormId: dbModelId('assessmentForm'),
    }),
  ]),
);

/** Override types. */
export type AuditEventAssessmentQuestionComment = t.TypeOf<
  typeof AuditEventAssessmentQuestionComment
>;
