import 'antd/lib/icon/style/index';

import { isDoubleByte } from '@main/utils';
import { IconProps as AntdIconProps, ThemeType } from 'antd/lib/icon';
import React from 'react';
import { useTheme } from 'styled-components';

import { CUSTOM_ICONS, CustomIcon } from './custom';
import { IconWrapper, StyledI } from './wrappers';

export type { CustomIcon };

/**
 * Prop types for an icon
 */
export interface AntIconProps extends Omit<AntdIconProps, 'theme'> {
  /** The type of icon */
  type: string;
  /** The id to add to the icon HTML element */
  id?: string;
  /** Loading indicator for icon (switches icon to loading nd uses aria-busy) */
  loading?: boolean;
  /** Set the color of the icon */
  color?: string;
  /** Indicates if the icon should change opacity on hover */
  hoverable?: boolean;
  /** Font awesome size multiplier */
  x?: number;
  /** Rename theme -> icon theme */
  iconTheme?: ThemeType; // Handle weird namespace collision
}

/**
 * Old ant icon
 *
 * @deprecated - TODO: https://transcend.height.app/T-8204
 */
export const AntIcon = React.forwardRef<HTMLSpanElement, AntIconProps>(
  (
    {
      hoverable = false,
      type: typeRaw,
      loading,
      x = 1,
      color,
      iconTheme,
      ...passProps
    },
    ref,
  ): JSX.Element => {
    const theme = useTheme();

    const iconProps = {
      ...passProps,
      'aria-busy': loading ? 'true' : 'false',
    } as typeof passProps; // aria-busy is not in props but it will pass through

    // Determine icon logo
    const type = loading ? 'loading' : typeRaw;
    const useHoverable = hoverable ? 'true' : undefined;
    if (isDoubleByte(type)) {
      return (
        <StyledI className="fa" hoverable={useHoverable} {...iconProps}>
          {type}
        </StyledI>
      );
    }
    if (type && type.indexOf('fa-') === 0) {
      return (
        <StyledI
          color={color}
          className={`fa fa-${x}x ${type}`}
          hoverable={useHoverable}
          {...iconProps}
        />
      );
    }
    if (type in CUSTOM_ICONS) {
      const CustomIcon = CUSTOM_ICONS[type as keyof typeof CUSTOM_ICONS];
      const { style } = iconProps;
      const extraProps =
        style && style.fontSize
          ? {
              height: style.fontSize,
              width: style.fontSize,
            }
          : {};
      return (
        <CustomIcon color={color} {...(iconProps as any)} {...extraProps} />
      );
    }
    return (
      <span ref={ref}>
        <IconWrapper
          color={color}
          hoverable={useHoverable}
          type={type}
          theme={iconTheme as any} // eslint-disable-line @typescript-eslint/no-explicit-any
          styletheme={theme}
          {...iconProps}
        />
      </span>
    );
  },
);
