import { createLoadable } from '@main/core-ui';

import { colorPaletteMessages } from './messages';

// Load the component dynamically
export default createLoadable({
  loader: () => import('./index'),
  messages: colorPaletteMessages,
  logo: 'bg-colors',
});
