import {
  FlexColumn,
  FlexRowCenterVertical,
  Popover,
  StyleUtils,
  useFormatMessageGeneric,
  useListOverflow,
} from '@main/core-ui';
import React from 'react';
import { useTheme } from 'styled-components';

import { Button } from '../Button';
import { ConditionalContainer } from '../ConditionalContainer';
import { OrganizationIcon } from '../OrganizationIcon';
import { MultiItemOverflowDisplayProps } from './types';
import { StyledImageIcon, StyledMultiImageOverflowIndicator } from './wrappers';

/**
 * MultiItemOverflowDisplay
 */
export const MultiItemOverflowDisplay: React.FC<
  MultiItemOverflowDisplayProps
> = ({
  items,
  isCircle = true,
  isInline,
  borderless = false,
  limit,
  totalCount,
  onClick,
  textOnly,
  popoverTitle,
  countAndMoreAsSegment,
}) => {
  const theme = useTheme();
  const { formatMessageGeneric } = useFormatMessageGeneric();
  const { isOverflowing, listBeforeOverflow, listAfterOverflow } =
    useListOverflow({
      list: items,
      countAndMoreAsSegment: countAndMoreAsSegment ?? isInline,
      limit,
    });

  return (
    <FlexRowCenterVertical
      style={{
        ...(isInline ? {} : { marginBottom: StyleUtils.Spacing.md }),
        ...(textOnly ? { gap: StyleUtils.Spacing.xs } : {}),
      }}
    >
      {listBeforeOverflow.map(({ displayName, logo, id }, i) => (
        <ConditionalContainer
          key={id}
          condition={!textOnly}
          container={(children) => (
            <Popover
              contents={
                <FlexColumn style={{ gap: StyleUtils.Spacing.sm }}>
                  {popoverTitle && (
                    <div
                      style={{
                        fontSize: '12px',
                        fontWeight: 600,
                        textTransform: 'uppercase',
                      }}
                    >
                      {formatMessageGeneric(popoverTitle)}
                    </div>
                  )}

                  <div
                    style={{
                      fontSize: '12px',
                      fontWeight: 600,
                      color: theme.colors.transcendNavy,
                    }}
                  >
                    {displayName}
                  </div>
                </FlexColumn>
              }
              trigger={['hover', 'focus']}
              placement="top"
            >
              <StyledImageIcon
                $isInline={isInline}
                style={{
                  zIndex: isInline ? i : listBeforeOverflow.length - i,
                }}
              >
                {children}
              </StyledImageIcon>
            </Popover>
          )}
        >
          <ConditionalContainer
            condition={!!onClick}
            container={(children) => (
              <Button
                onClick={() => {
                  onClick?.(id);
                }}
                key={id}
                variant="link"
                style={{ visibility: 'visible' }}
              >
                {children}
              </Button>
            )}
          >
            {textOnly ? (
              <span>
                {formatMessageGeneric(displayName) +
                  (i < listBeforeOverflow.length - 1 ? ', ' : '')}
              </span>
            ) : (
              <OrganizationIcon
                key={id}
                organizationIconSrc={logo}
                organizationName={
                  typeof displayName === 'string' ? displayName : undefined
                }
                size={isInline ? 24 : items.length === 1 ? 96 : 68}
                circle={isCircle}
                style={{
                  ...(isInline ? { padding: '3px' } : {}),
                  border: borderless
                    ? `1px solid ${theme.colors.white}`
                    : isInline
                      ? `1px solid ${theme.colors.transcendNavy4}`
                      : `4px solid ${theme.colors.white}`,
                }}
              />
            )}
          </ConditionalContainer>
        </ConditionalContainer>
      ))}
      {(isOverflowing || (totalCount && limit && totalCount > limit)) && (
        <Popover
          height="280px"
          scrollable
          contents={
            <FlexColumn style={{ gap: StyleUtils.Spacing.sm }}>
              {popoverTitle && (
                <div style={{ fontSize: '10px', textTransform: 'uppercase' }}>
                  {formatMessageGeneric(popoverTitle)}
                </div>
              )}
              {listAfterOverflow.map(({ displayName, logo, id }) => (
                <FlexRowCenterVertical
                  key={id}
                  style={{
                    gap: StyleUtils.Spacing.sm,
                  }}
                >
                  <ConditionalContainer
                    condition={!!onClick}
                    container={(children) => (
                      <Button
                        onClick={() => {
                          onClick?.(id);
                        }}
                        key={id}
                        variant="link"
                        style={{ visibility: 'visible' }}
                      >
                        {children}
                      </Button>
                    )}
                  >
                    <>
                      {!textOnly && (
                        <OrganizationIcon
                          organizationIconSrc={logo}
                          organizationName={
                            typeof displayName === 'string'
                              ? displayName
                              : undefined
                          }
                          style={{
                            borderRadius: isCircle ? '10em' : undefined,
                            minWidth: '24px',
                          }}
                          size={24}
                        />
                      )}
                      <div style={{ wordBreak: 'break-all', fontSize: '12px' }}>
                        {formatMessageGeneric(displayName)}
                      </div>
                    </>
                  </ConditionalContainer>
                </FlexRowCenterVertical>
              ))}
            </FlexColumn>
          }
        >
          <StyledMultiImageOverflowIndicator
            variant={isInline ? 'secondary' : 'secondary-filled'}
            $isCircle={isCircle}
            $isInline={isInline}
            $borderless={borderless}
            style={{
              zIndex: isInline ? listBeforeOverflow.length : undefined,
              ...(textOnly ? { marginLeft: 0 } : {}),
            }}
          >
            +
            {totalCount && limit
              ? totalCount - limit
              : listAfterOverflow.length}
          </StyledMultiImageOverflowIndicator>
        </Popover>
      )}
    </FlexRowCenterVertical>
  );
};
