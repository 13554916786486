import { FilterConfigItem } from '@main/ad-core-components';
import { buildUseLazyQuery, Logo, message } from '@main/core-ui';
import { endpoints } from '@main/datamap-types';
import { ID } from '@main/schema-utils';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { MessageDescriptor } from 'react-intl';

import {
  DATA_SILO_SELECTION_NODES,
  DataSiloSelection,
  SelectIntegrations,
} from '../../DataMap/SelectIntegrations';

/**
 * Filters definition with property for data silo values specified
 */
type DataSiloEnrichedFilters = Record<string, unknown> &
  Record<
    /**
     * Data silo values that are currently filtering the table
     */
    string,
    DataSiloSelection[] | undefined
  >;

/**
 * Hook to get the filter configuration for data silos
 *
 * @returns the data silo keys along with the config for the filter and a function to clear the filters
 */
type BuildDataSiloFilters<
  T extends Record<string, unknown>,
  E extends DataSiloEnrichedFilters,
> = ({
  dataSiloIdsFilterKey,
  filters,
  setFilters,
  enrichedDataSiloKey,
}: {
  /** The property name of the filter for data silo ids */
  dataSiloIdsFilterKey?: keyof T;
  /** The currently applied filters */
  filters?: T;
  /** Callback when the filters are changed */
  setFilters: (filters: T) => void;
  /** Label for the filter menu */
  label: string | MessageDescriptor;
  /** The key to use if more than one useDataSiloFilters is used in a FilterManager */
  enrichedDataSiloKey?: keyof E;
}) => {
  /**  The data silo keys enabled on this table */
  selectedDataSilos: DataSiloSelection[];
  /** The filter configuration to be passed to the filter manager */
  dataSiloFiltersConfig: FilterConfigItem<E>;
  /** Callback for when the filter is cleared in the filter manager */
  clearDataSiloFilters: (key: Extract<keyof E, string>) => void;
};

const useLazyDataSilos = buildUseLazyQuery(
  endpoints.dataSilos,
  'useDataSiloFilters',
  {
    nodes: DATA_SILO_SELECTION_NODES,
    totalCount: null,
  },
);

export const useDataSiloFilters: BuildDataSiloFilters<
  Record<string, unknown>,
  DataSiloEnrichedFilters
> = ({
  dataSiloIdsFilterKey = 'dataSiloIds',
  filters,
  setFilters,
  label,
  enrichedDataSiloKey = 'dataSilos',
}) => {
  const getSelectedDataSilos = useLazyDataSilos();
  const [selectedDataSilos, setSelectedDataSilos] = useState<
    DataSiloSelection[]
  >([]);
  const [fetching, setFetching] = useState(false);
  const [hasError, setHasError] = useState(false);

  // Populate initial complex filter values
  useEffect(() => {
    if (filters) {
      const dataSiloIds = (filters[dataSiloIdsFilterKey] ||
        []) as ID<'dataSilo'>[];

      const filtersAndEnrichedFiltersMatch =
        dataSiloIds.sort().join() ===
        selectedDataSilos
          .map(({ id }) => id)
          .sort()
          .join();

      if (dataSiloIds.length === 0 && selectedDataSilos.length > 0) {
        setSelectedDataSilos([]);
      } else if (!filtersAndEnrichedFiltersMatch && !fetching && !hasError) {
        // Prevent over-firing this setter while data is still being fetched
        setFetching(true);
        getSelectedDataSilos({ filterBy: { ids: dataSiloIds } })
          .then(({ data }) => {
            setSelectedDataSilos(data.nodes);
          })
          .catch((err) => {
            setHasError(true);
            message.error(err.message);
          })
          .finally(() => {
            setFetching(false);
          });
      }
    }
  }, [
    dataSiloIdsFilterKey,
    fetching,
    filters,
    getSelectedDataSilos,
    selectedDataSilos,
  ]);

  const dataSiloFiltersConfig = useMemo(
    () =>
      ({
        filterKey: enrichedDataSiloKey,
        label,
        pillOptions: {
          label: ({
            filterValues: { [enrichedDataSiloKey]: dataSilos = [] },
            index = 0,
          }) => (
            <>
              <Logo
                logo={dataSilos[index].catalog.logoSquare}
                height="18px"
                style={{ marginRight: 3, marginTop: -2 }}
              />
              {dataSilos[index]?.title}
            </>
          ),
        },
        filter: (
          <SelectIntegrations
            menuPosition="absolute"
            value={selectedDataSilos}
            onChange={(dataSilos) => {
              setSelectedDataSilos(dataSilos);
              setFilters({
                ...filters,
                [dataSiloIdsFilterKey]: dataSilos.map(({ id }) => id),
              });
            }}
          />
        ),
      }) as FilterConfigItem<DataSiloEnrichedFilters>,
    [dataSiloIdsFilterKey, selectedDataSilos, filters, label],
  );

  const clearDataSiloFilters = useCallback(
    (key) => {
      if (key === enrichedDataSiloKey) {
        setFilters({
          ...filters,
          [dataSiloIdsFilterKey]: [],
        });
        setSelectedDataSilos([]);
      }
    },
    [selectedDataSilos, filters, dataSiloIdsFilterKey],
  );

  return {
    selectedDataSilos,
    dataSiloFiltersConfig,
    clearDataSiloFilters,
  };
};
