import { compareVersions } from '@main/utils';

import { MINIMUM_AIRGAP_CONSENT_EXPIRY_VERSION } from '../consentExpiry';

const belowMinimumVersion = (version: string): boolean =>
  compareVersions(version, MINIMUM_AIRGAP_CONSENT_EXPIRY_VERSION) === -1;

/**
 * Function that returns whether or not a version can use the consent expiry configuration
 *
 * @param version - deployed prod bundle version
 * @param testVersion - deployed test bundle version
 * @returns boolean describing whether consent expiry is supported
 */
export function consentExpirySupported(
  version: string | null,
  testVersion: string | null,
): boolean {
  // xor
  if (Boolean(version) !== Boolean(testVersion)) {
    return !belowMinimumVersion((version || testVersion)!);
  }
  if (version && testVersion) {
    const higherVersion =
      compareVersions(version, testVersion) === 1 ? version : testVersion;
    return !belowMinimumVersion(higherVersion);
  }
  return false;
}
