import {
  CreateActionItemsInput,
  endpoints,
  GlobalActionItemCollection,
  GlobalActionItemFiltersInput,
} from '@main/access-control-types';
import {
  commonButtonMessages,
  DropdownItem,
  Upload,
  useExportGqlToCsv,
} from '@main/ad-core-components';
import { buildUseLazyQuery, buildUseMutation } from '@main/core-ui';
import { ONE_SECOND } from '@main/utils';
import { ObjByString } from '@transcend-io/type-utils';
import Papa from 'papaparse';
import React from 'react';
import { useIntl } from 'react-intl';
import { Column } from 'react-table';
import { useTheme } from 'styled-components';

import { TASK_COLUMN } from '../ActionItemsTable/columns';
import { actionItemsTableCSVMessages } from '../ActionItemsTable/messages';
import { ActionItemRow } from '../ActionItemsTable/types';
import { onboardingItemCollectionTableMessages } from './messages';

const useGlobalActionItemsLazy = buildUseLazyQuery(
  endpoints.globalActionItems,
  'OnboardingActionItems',
);
const useCreateActionItems = buildUseMutation(endpoints.createActionItems);

interface UseImportOnboardingItemsCsvProps {
  /** the collection to display */
  collection: GlobalActionItemCollection;
  /** function to refetch the current action item table */
  refetch: () => void;
}

interface UseExportOnboardingItemsCsvProps {
  /** the table columns */
  columns: Column<ActionItemRow>[];
  /** the filters to use when fetching the csv contents */
  filterBy: GlobalActionItemFiltersInput;
}

export const useImportOnboardingItemsCsv = ({
  collection,
  refetch,
}: UseImportOnboardingItemsCsvProps): DropdownItem => {
  const [createActionItems] = useCreateActionItems();
  const { formatMessage } = useIntl();
  const theme = useTheme();

  return {
    displayTitle: (
      <Upload
        multiple={false}
        skipSuccessFlash
        action={(file) => {
          // Reset the content being read
          file.text().then((csvContents) => {
            // On success parse the csv
            Papa.parse(csvContents, {
              header: true,
              skipEmptyLines: true,
              complete: ({ data }) => {
                const rawData = data as any as ObjByString[];
                // remap formatted column names to input fields
                const createInputs: CreateActionItemsInput[] = rawData.map(
                  (row) => ({
                    type: row[formatMessage(TASK_COLUMN(true).csvHeader[1])],
                    title: row[formatMessage(TASK_COLUMN(true).csvHeader[2])],
                    resolved: row.resolved === 'true',
                    assigneesUserEmails: row.users?.split(','),
                    assigneesTeamNames: row.teams?.split(','),
                    priorityOverride: row.priority,
                    dueDate: row.dueDate || undefined,
                    collectionIds: [collection.id],
                  }),
                );
                createActionItems({
                  variables: { input: createInputs },
                }).then(() => refetch());
              },
            });
          });

          // Prevent upload
          return '';
        }}
        noHint
        customRequest={({ onSuccess, onProgress, file }) => {
          setTimeout(() => onProgress({ percent: 80 }, file), ONE_SECOND / 4);

          // Need to return first
          setTimeout(
            () => onSuccess({ content: 'Success' }, file),
            ONE_SECOND / 2,
          );

          return {};
        }}
        accept=".csv"
        unique={false}
        headerMessage={null}
        padding="0px"
      >
        <span
          style={{
            color: theme.colors.transcendNavy,
            fontSize: '16px',
            fontWeight: 400,
          }}
        >
          {formatMessage(onboardingItemCollectionTableMessages.importFromCsv)}
        </span>
      </Upload>
    ),
    icon: 'insert-table',
  };
};

export const useExportActionItemsCsv = ({
  columns,
  filterBy,
}: UseExportOnboardingItemsCsvProps): DropdownItem => {
  const executeQuery = useGlobalActionItemsLazy({ fetchPolicy: 'no-cache' });

  const [downloadCsv] = useExportGqlToCsv<
    (typeof endpoints.globalActionItems)['params'],
    (typeof endpoints.globalActionItems)['response']
  >({
    fileNamePrefix: 'action-items',
    executeQuery,
    getVariables: () => ({ filterBy }),
    columnDefinition: {
      columns,
      headerMessages: actionItemsTableCSVMessages,
    },
  });

  return {
    displayTitle: commonButtonMessages.exportToCsv,
    icon: 'download',
    onClick: () => downloadCsv(),
  };
};
