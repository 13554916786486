import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { LanguageKey } from '@transcend-io/internationalization';

import { DEFAULT_LOCALE } from '@main/internationalization';

/**
 * the language provider state
 */
export interface LanguageProviderState {
  /** The updated locale */
  locale: LanguageKey;
  /** The translations for the current language */
  translations: { [k in string]: string };
}

/**
 * Reducer slice the `LanguageProvider` state store
 */
export const languageProviderSlice = createSlice({
  name: 'LanguageProvider',
  initialState: {
    locale: DEFAULT_LOCALE,
    translations: {},
  },
  reducers: {
    setLocale: (
      state,
      {
        payload,
      }: PayloadAction<{
        /** The language being translated */
        locale: LanguageKey;
      }>,
    ) => ({
      ...state,
      translations: {},
      ...payload,
    }),
    setTranslations: (
      state,
      {
        payload,
      }: PayloadAction<{
        /** The translations */
        translations: { [k in string]: string };
        /** The language being translated */
        locale: LanguageKey;
      }>,
    ) => ({
      ...state,
      ...payload,
    }),
  },
});

export const { setLocale, setTranslations } = languageProviderSlice.actions;
