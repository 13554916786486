import 'reactflow/dist/style.css';

import {
  FlexRowCenterBoth,
  GenericMessageDescriptor,
  Icon,
  IconType,
  StyleUtils,
  useFormatMessageGeneric,
} from '@main/core-ui';
import { ColorPalette } from '@main/theme';
import { Optionalize } from '@transcend-io/type-utils';
import sortBy from 'lodash/sortBy';
import React, { ReactNode, useCallback, useLayoutEffect, useMemo } from 'react';
import {
  addEdge,
  Connection,
  Controls,
  Edge,
  EdgeTypes,
  MarkerType,
  MiniMap,
  Node,
  NodeTypes,
  OnSelectionChangeFunc,
  ReactFlowProvider,
  useEdgesState,
  useNodesState,
  useReactFlow,
} from 'reactflow';
import { useTheme } from 'styled-components';

import {
  DEFAULT_EDGE_THICKNESS,
  TREE_GRAPH_AUTO_LAYOUT_POSITION,
} from './constants';
import { getTreeLayout } from './helpers';
import { StyledReactFlow } from './wrappers';

/**
 *
 * https://reactflow.dev/docs/examples/layout/elkjs/
 * https://www.eclipse.org/elk/reference/algorithms.html
 * https://www.eclipse.org/elk/reference/options.html
 * 'layered' creates a tree that prioritizes putting as many nodes on the same layer as possible.
 *
 */
const defaultElkOptions = {
  // Best for tree layouts. mrtree is also good, but doesn't seem to work properly
  // with horizontal layouts.
  'elk.algorithm': 'layered',

  // Reduces the number of edge crossings.
  'elk.layered.crossingMinimization.semiInteractive': 'true',
  'elk.layered.crossingMinimization.strategy': 'INTERACTIVE',

  // Add spacing between nodes and edges.
  'elk.layered.spacing.baseValue': '20',
  'elk.layered.spacing.nodeNodeBetweenLayers': '80',
  'elk.layered.spacing.edgeNodeBetweenLayers': '80',
  // This is a bit too much space in most cases, but if we don't set it this high, feedback edges are more likely to hit the nodes.
  'elk.spacing.nodeNode': '80',

  // Supposedly better for angled edge layouts
  'elk.edgeRouting': 'ORTHOGONAL',

  // Make loops/reversed edges go around nodes instead of through/behind them
  'elk.layered.feedbackEdges': 'true',

  'elk.layered.nodePlacement.strategy': 'BRANDES_KOEPF',
  // Helps keep branching edges/nodes in a symmetrical layout.
  // Only valid if 'elk.layered.nodePlacement.strategy' is 'BRANDES_KOEPF;
  'elk.layered.nodePlacement.favorStraightEdges': 'false',

  // Helps keep nodes on the same layout layer, rather than grouping them or spreading them
  // randomly across the layout.
  'elk.hierarchyHandling': 'INCLUDE_CHILDREN',
};

interface LabelAndStatusColor {
  /** the label for the node */
  label: GenericMessageDescriptor;
  /** the color of the node */
  statusColor?: keyof ColorPalette | string;
}

export interface TreeGraphNode
  extends Omit<Optionalize<Node, 'position'>, 'data'> {
  /** the extra node data */
  data: LabelAndStatusColor & any;
}

export interface TreeGraphEdge
  extends Omit<Edge, 'data' | 'markerStart' | 'markerEnd'> {
  /** the extra edge data */
  data?: Partial<LabelAndStatusColor> & {
    /** should we use the default arrow facing the start of the edge */
    arrowStart?: boolean;
    /** should we use the default arrow facing the end of the edge */
    arrowEnd?: boolean;
    /** the icon name or a logo url */
    icon?: IconType | ReactNode;
  } & any;
  /** marker partial override */
  markerStart?: Partial<Edge['markerStart']>;
  /** marker partial override */
  markerEnd?: Partial<Edge['markerEnd']>;
}

export interface TreeGraphProps {
  /** Nodes in the tree */
  nodes: TreeGraphNode[];
  /** Edges between nodes */
  edges: TreeGraphEdge[];
  /** Custom node types */
  nodeTypes?: NodeTypes;
  /** Custom edge types */
  edgeTypes?: EdgeTypes;
  /** Set graph to be horizontal or vertical */
  isHorizontal?: boolean;
  /** Options for configuring ELK */
  elkOptions?: Record<string, string>;
  /** Whether to show the minimap */
  showMiniMap?: boolean;
  /** Callback for when a node is connected to another node */
  onConnect?: (edgeParams: Edge | Connection) => void;
  /** Callback for when an edge is deleted */
  onDeleteEdge?: (edgeParams: Edge | Connection) => void;
  /** Type of edge to set when connecting new edge */
  connectEdgeType?: string;
  /** is the graph read-only? */
  readOnly?: boolean;
}

/**
 * A tree graph that uses the ELK algorithm to layout the nodes.
 *
 */
const TreeGraphFlow: React.FC<TreeGraphProps> = ({
  nodes: initialNodes,
  edges: initialEdges,
  nodeTypes,
  isHorizontal = true,
  elkOptions = defaultElkOptions,
  showMiniMap,
  onConnect: handleConnect,
  onDeleteEdge: handleDeleteEdge,
  connectEdgeType,
  edgeTypes,
  readOnly,
}) => {
  const opts = useMemo(
    () => ({
      'elk.direction': isHorizontal ? 'RIGHT' : 'DOWN',
      ...elkOptions,
    }),
    [isHorizontal, elkOptions],
  );
  const theme = useTheme();
  const { formatMessageGeneric } = useFormatMessageGeneric();

  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);

  const { fitView } = useReactFlow();

  // Calculate the initial layout on mount.
  useLayoutEffect(() => {
    getTreeLayout(
      initialNodes.map((node) => ({
        ...node,
        position: node.position ?? TREE_GRAPH_AUTO_LAYOUT_POSITION,
      })),
      initialEdges as Edge[],
      opts,
    ).then(({ nodes: treeLayoutNodes, edges: treeLayoutEdges }) => {
      setEdges(treeLayoutEdges ?? []);
      setNodes(treeLayoutNodes ?? []);

      window.requestAnimationFrame(() => fitView());
    });
  }, [initialNodes, initialEdges, opts, setEdges, setNodes, fitView]);

  const onConnect = useCallback(
    (params) => {
      setEdges((edges) => addEdge({ ...params, type: connectEdgeType }, edges));
      handleConnect?.(params);
    },
    [setEdges, handleConnect],
  );

  const onDeleteEdge = useCallback(
    (params) => {
      handleDeleteEdge?.(params[0]);
    },
    [handleDeleteEdge],
  );

  const onSelectionChange: OnSelectionChangeFunc = useCallback(
    ({ edges: selectedEdges }) => {
      setEdges((edges) =>
        sortBy(edges, ({ id }) =>
          // The first edges in the array are displayed on top of the others in the SVG.
          // This sort achieves the same effect as increasing the z-index of the selected edge.
          selectedEdges[0]?.id === id ? 1 : 0,
        ),
      );
    },
    [setEdges],
  );

  // add default style overrides to nodes and edges
  const nodesWithDefaultStyling = useMemo(
    () =>
      nodes.map((node) => ({
        ...node,
        data: {
          ...(node.data ?? {}),
          label: (
            <FlexRowCenterBoth
              style={{ gap: StyleUtils.Spacing.sm, color: 'inherit' }}
            >
              {node.data.icon &&
                (typeof node.data.icon === 'string' ? (
                  <Icon type={node.data.icon} />
                ) : (
                  node.data.icon
                ))}
              <span>{formatMessageGeneric(node.data?.label)}</span>
            </FlexRowCenterBoth>
          ),
        },
        position: node.position ?? TREE_GRAPH_AUTO_LAYOUT_POSITION,
        style: {
          borderColor:
            theme.colors[node.data?.statusColor ?? ''] ??
            theme.colors.transcendNavy4,
          borderRadius: '.5em',
          // clickable/hoverable by default
          pointerEvents: 'all',
          ...(node.style ?? {}),
        },
      })),
    [nodes],
  );
  const edgesWithDefaultStyling = useMemo(
    () =>
      edges.map((edge) => {
        const defaultMarkerProps = {
          type: MarkerType.Arrow,
          color:
            theme.colors[edge.data?.statusColor ?? ''] ??
            theme.colors.transcendNavy4,
          strokeWidth: DEFAULT_EDGE_THICKNESS,
          width: 20,
          height: 20,
        };
        return {
          ...edge,
          data: {
            ...(edge.data ?? {}),
            label: formatMessageGeneric(edge.data?.label),
          },
          style: {
            stroke:
              theme.colors[edge.data?.statusColor ?? ''] ??
              theme.colors.transcendNavy4,
            strokeWidth: DEFAULT_EDGE_THICKNESS,
            ...(edge.style ?? {}),
          },
          ...(edge.markerStart || edge.data?.arrowStart
            ? {
                markerStart: {
                  ...defaultMarkerProps,
                  ...(typeof edge.markerStart === 'string'
                    ? { type: edge.markerStart }
                    : edge.markerStart ?? {}),
                },
              }
            : {}),
          ...(edge.markerEnd || edge.data?.arrowEnd
            ? {
                markerEnd: {
                  ...defaultMarkerProps,
                  ...(typeof edge.markerEnd === 'string'
                    ? { type: edge.markerEnd }
                    : edge.markerEnd ?? {}),
                },
              }
            : {}),
        };
      }),
    [edges],
  );

  return (
    <StyledReactFlow
      nodes={nodesWithDefaultStyling as Node[]}
      edges={edgesWithDefaultStyling as Edge[]}
      onConnect={onConnect}
      onNodesChange={onNodesChange}
      onEdgesChange={onEdgesChange}
      onEdgesDelete={onDeleteEdge}
      nodeTypes={nodeTypes}
      edgeTypes={edgeTypes}
      fitView
      proOptions={{ hideAttribution: true }}
      onSelectionChange={onSelectionChange}
      elementsSelectable={!readOnly}
      nodesConnectable={!readOnly}
      nodesDraggable={!readOnly}
      $readOnly={readOnly}
    >
      <Controls showInteractive={!readOnly} />
      {showMiniMap && <MiniMap />}
    </StyledReactFlow>
  );
};

export const TreeGraph: React.FC<TreeGraphProps> = (props) => {
  const theme = useTheme();
  return (
    <ReactFlowProvider>
      <div
        style={{
          width: '100%',
          height: '100%',
          background: theme.colors.gray1,
          borderRadius: '16px',
        }}
      >
        <TreeGraphFlow {...props} />
      </div>
    </ReactFlowProvider>
  );
};
