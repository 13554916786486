import { useFormatMessageGeneric } from '@main/core-ui/src/GenericFormattedMessage';
import { Logo, LogoProps } from '@main/core-ui/src/Logo';
import { LocalizedMessage, MenuTabInterface } from '@main/internationalization';
import { ColorPalette } from '@main/theme';
import type { MessageValues } from '@transcend-io/internationalization';
import React from 'react';
import { MessageDescriptor } from 'react-intl';
import { useTheme } from 'styled-components';

import { Badge } from '../Badge';
import { Icon, IconType } from '../Icon';
import { LoadingSpinner } from '../LoadingSpinner';
import { MaxLengthWrapper, TabWrapper } from './wrappers';

/**
 * A menu tab is a merge of formatted message
 */
export interface MenuTab extends Omit<MenuTabInterface, 'logo' | 'title'> {
  /** The tab ID */
  id: string;
  /** Selected flag */
  selected?: boolean;
  /** The child menu items */
  children?: MenuTab[];
  /** The logo to display with the menu item */
  logo?: string | LogoProps;
  /** Indicates if the contents are loading */
  loading?: boolean;
  /** Title can be localized message or a message descriptor */
  title?: LocalizedMessage | MessageDescriptor | string;
  /** The menu tab for policies can have a slug */
  slug?: string;
  /** A number to display beside the tab */
  count?: number;
  /** A flag for whether a data collection tab category has files */
  hasRequestFiles?: boolean;
  /** Whether to show the count or not for data categories */
  showCount?: boolean;
  /** The tooltip title for the menu item */
  tooltip?: string;
}

/**
 * Props
 */
export interface SideMenuTabProps {
  /** The menu tab to render */
  tab: MenuTab;
  /** Level at which the menu exists */
  level: number;
  /** Message values */
  values?: MessageValues;
  /** Styles */
  style?: React.CSSProperties;
  /** whether to use ant icons or not */
  antIcons?: boolean;
}

/**
 * Render a side menu tab
 */
export const SideMenuTab: React.FC<SideMenuTabProps> = ({
  tab: { logo, loading, count, title },
  values,
  style,
  antIcons = true,
}) => {
  const { formatMessageGeneric } = useFormatMessageGeneric();
  const theme = useTheme();
  return (
    <TabWrapper style={style}>
      {logo &&
        (antIcons ? (
          typeof logo === 'object' ? (
            <Logo {...logo} />
          ) : (
            <Logo logo={logo} height="15px" />
          )
        ) : typeof logo === 'object' ? (
          <Icon
            type={logo.logo as IconType}
            color={
              logo.color
                ? theme.colors[logo.color as keyof ColorPalette]
                : undefined
            }
            size={logo.height}
          />
        ) : (
          <Icon type={logo as IconType} size={20} />
        ))}
      <MaxLengthWrapper>
        {title ? formatMessageGeneric(title, values) : undefined}
      </MaxLengthWrapper>
      {loading && <LoadingSpinner small />}
      {count && count > 0 ? (
        <Badge color="transcendNavy4">{count}</Badge>
      ) : undefined}
    </TabWrapper>
  );
};
