import {
  A,
  extractFormItemWrapperProps,
  FormItemWrapper,
  GenericMessageDescriptor,
  IFormFieldProps,
  IFormItemWrapperProps,
  IWithClassName,
  multipleValidators,
  TValidator,
  useFormatMessageGeneric,
} from '@main/core-ui';
import React, { useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';

import {
  BootstrapFormCheck,
  BootstrapFormCheckType,
} from './BootstrapFormCheck';
import { checkboxGroupMessages } from './messages';
import { CheckboxGroupWrapper } from './wrapper';

export interface IFormCheckboxGroupOption {
  /** the option's label */
  label: GenericMessageDescriptor | string;
  /** the option's value */
  value: string | number;
  /** should this option be hidden? */
  isHidden?: boolean;
}

export interface IFormCheckboxGroupProps
  extends IWithClassName,
    IFormFieldProps<string | ReadonlyArray<string> | number> {
  /** options to choose from */
  options: IFormCheckboxGroupOption[];
  /** the validation rules for the group */
  rules?: TValidator[];
  /** type of the inputs, checkbox (multi-select) or radio (single-select) */
  type?: BootstrapFormCheckType;
  /** column count */
  columnCount?: number;
  /** initialDisplayedCount */
  initialDisplayedCount?: number;
}

const valueMatches = (
  arrOrValue: string | ReadonlyArray<string> | number | undefined,
  value: string | number,
): boolean =>
  Array.isArray(arrOrValue) ? arrOrValue.includes(value) : arrOrValue === value;

export const FormCheckboxGroupRaw = ({
  name,
  options,
  type = 'checkbox',
  defaultValue = type === 'radio' ? '' : [],
  rules,
  columnCount = 1,
  initialDisplayedCount,
}: IFormCheckboxGroupProps): JSX.Element => {
  const { control } = useFormContext();
  const { formatMessageGeneric } = useFormatMessageGeneric();
  const [showAll, setShowAll] = useState(initialDisplayedCount === undefined);
  const { formatMessage } = useIntl();
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      rules={rules ? { validate: multipleValidators(rules) } : undefined}
      render={({ field: { value: selected, onChange, ...controllerRest } }) => (
        <>
          <CheckboxGroupWrapper columnCount={columnCount}>
            {options
              .filter(
                (option, i) =>
                  showAll ||
                  (initialDisplayedCount ? i < initialDisplayedCount : true),
              )
              .map(({ label, value, isHidden, ...optionRest }) => (
                <BootstrapFormCheck
                  key={`${name}-${value}`}
                  id={`${name}-${value}`}
                  type={type}
                  label={
                    typeof label === 'string'
                      ? label
                      : formatMessageGeneric(label)
                  }
                  {...controllerRest}
                  {...optionRest}
                  checked={valueMatches(selected, value)}
                  onChange={({ target: { checked } }) =>
                    onChange(
                      Array.isArray(selected)
                        ? checked
                          ? [...selected, value]
                          : [...selected.filter((v: unknown) => v !== value)]
                        : value,
                    )
                  }
                  {...(isHidden ? { style: { display: 'none' } } : {})}
                />
              ))}
          </CheckboxGroupWrapper>
          {initialDisplayedCount !== undefined &&
            options.length > initialDisplayedCount && (
              <A onClick={() => setShowAll((showAll) => !showAll)}>
                {showAll
                  ? formatMessage(checkboxGroupMessages.showLess)
                  : formatMessage(checkboxGroupMessages.showMore, {
                      count: options.length - initialDisplayedCount,
                    })}
              </A>
            )}
        </>
      )}
    />
  );
};

export const FormCheckboxGroup = (
  props: IFormCheckboxGroupProps & Omit<IFormItemWrapperProps, 'errors'>,
): JSX.Element => {
  const {
    formState: { errors },
  } = useFormContext();
  const { passthroughProps, wrapperProps } = extractFormItemWrapperProps(props);

  return (
    <FormItemWrapper errors={errors} {...wrapperProps}>
      <FormCheckboxGroupRaw {...passthroughProps} />
    </FormItemWrapper>
  );
};
