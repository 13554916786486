import { Icon, IconType, StyleUtils } from '@main/core-ui';
import { switchCase } from '@main/utils';
import React from 'react';

import { ByteSize } from './ByteSize';

/**
 * Get the icon to display with a file
 */
const getFileIcon = switchCase<IconType, [], string>({
  'text/plain': IconType.File,
  'application/pdf': IconType.CreateFile,
})(IconType.File);

/**
 * Props for Mimetype
 */
export interface MimetypeProps {
  /** The mimetype to display */
  mimetype: string;
  /** The size of the file */
  size?: number;
}

/**
 * Display a mimetype
 */
export function Mimetype({ mimetype, size = 0 }: MimetypeProps): JSX.Element {
  const icon =
    mimetype && mimetype.indexOf('image') === 0
      ? IconType.Image
      : getFileIcon(mimetype);
  return (
    <small
      style={{
        ...StyleUtils.Flex.Row.CenterVertical,
        display: 'inline-flex',
        gap: StyleUtils.Spacing.sm,
      }}
    >
      {icon && <Icon type={icon} />}
      {mimetype}
      <span>
        (<ByteSize>{size}</ByteSize>)
      </span>
    </small>
  );
}
