import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { FlexRowCenterBoth, FlexRowCenterVertical } from '@main/core-ui';

export const CellContainer = styled.span`
  padding-left: 5px;
`;

export const StyledFlexRowCenterBoth = styled(FlexRowCenterBoth)`
  // Need to expand to full height to center cell contents after using useBlockLayout
  height: 100%;
`;

export const StyledFlexRowCenterVertical = styled(FlexRowCenterVertical)`
  // Need to expand to full height to center cell contents after using useBlockLayout
  height: 100%;
  color: ${({ theme }) => theme.colors.transcendNavy};
`;

export const CellWithHiddenButton = styled(StyledFlexRowCenterVertical)`
  :hover button {
    opacity: 1;
  }
  button {
    opacity: 0;
    transition: 0.5s opacity;
    margin-left: -0.5em;

    :hover,
    :focus,
    :active {
      opacity: 1;
    }
  }
`;

export const StyledLink = styled(Link)`
  color: ${({ theme }) => theme.colors.primary};
  font-size: 14px;
  font-weight: 600;
`;

export const TitleWrapper = styled.span`
  margin-left: 8px;

  color: ${({ theme }) => theme.colors.black};
  font-weight: 600;
`;

export const StyledBoldText = styled.span`
  color: ${({ theme }) => theme.colors.black};
  font-weight: 600;
`;
