/**
 * Check if any properties from a CSS string are malformed or unrecognized when applied to an element
 *
 * @example
 * // Unrecognized properties example:
 * checkStyles(';  ;;; blah : foo; bar  : foo;;color:red;;;;;;; ;;;  ')
 * // returns ["blah", "bar"]
 *
 * // Malformed property value example:
 * checkStyles('color: r e d')
 * // returns ["color"]
 * @param css - CSS string to check
 * @param testElt - (Optional) Test element to apply CSS onto
 * @returns A list of all unrecognized style properties
 */
export function checkStyles(css: string, testElt?: HTMLElement): string[] {
  const tempTestElt = !testElt;
  const elt = tempTestElt
    ? document.body.appendChild(document.createElement('_'))
    : (testElt as HTMLElement);
  const allProps: Set<string> = new Set<string>(
    css
      .replace(/^[;\s]*/, '') // remove leading semicolons & whitespace
      .replace(/[;\s]*$/, '') // remove ending semicolons & whitespace
      .split(/\s*[;\s]*;[;\s]*\s*/)
      .map((prop: string) => prop.split(/\s*:\s*/)[0])
      .filter((prop) => prop),
  );
  const recognizedProps: Set<string> = new Set<string>();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (elt as any).style = css;
  const { style } = elt;
  let item = style.length;
  // eslint-disable-next-line no-plusplus
  while (item--) {
    recognizedProps.add(style[item]);
  }
  if (tempTestElt) {
    elt.remove();
  }
  const unrecognized = [...allProps].filter(
    (prop) => !recognizedProps.has(prop),
  );
  return unrecognized;
}

/**
 * Calculate the detected supported css from a string input
 *
 * @example
 * getSupportedStyles(`
 *  button { color: orange; thisWillBe: dropped; }
 *  button:hover { color: red }
 * `)
 * // output:
 *  `button { color: orange; }
 *   button:hover { color: red; }`
 *
 * getSupportedStyles(`* { color: red }
 *  unrecognized/selector { color: green; }`)
 * // output:
 *  `* { color: red; }`
 * @param css - The input css string
 * @param joiner - For joining the different detected css properties
 * @returns a string of the support css properties and values from the input
 */
export function getSupportedStyles(css: string, joiner = '\n'): string {
  const style = document.documentElement.appendChild(
    document.createElement('style'),
  );
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (style as any).disabled = true;
  style.append(css);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return [...(style.sheet as any).cssRules]
    .map((rule: CSSRule) => rule.cssText)
    .join(joiner);
}

/**
 * Calculate the detected supported css from a string input
 * and sanitize response
 *
 * @param css - The input css string
 * @param joiner - For joining the different detected css properties
 * @returns a string of the support css properties and values from the input
 */
export function getSupportedStylesFromCss(
  css: string,
  joiner?: string,
): string[] {
  // Parse the supported styles to display from the input
  const currentStyles = getSupportedStyles(`_{${css}}`, joiner)
    .replace(/^\s*_\s*\{/, '')
    .replace(/\s*\}\s*$/, '')
    .split(';')
    .map((field) => field.slice(1));
  currentStyles.pop();
  return currentStyles;
}
