import {
  FormInput,
  FormStatus,
  FormSubmitButton,
  FormTextArea,
  mutationToFormStatusProps,
} from '@main/ad-core-components';
import {
  CodePackage,
  CreateCodePackageInput,
  endpoints,
  RepositoryPreview,
} from '@main/code-scanning-types';
import {
  buildUseMutation,
  FlexColumnCenterHorizontal,
  Form,
  useForm,
  Validators,
} from '@main/core-ui';
import React from 'react';
import { useIntl } from 'react-intl';

import { FormSelectCodePackageType } from '../SelectCodePackageType';
import { FormSelectRepository } from '../SelectRepositories';
import { newCodePackageFormMessages } from './messages';

const useCreateCodePackage = buildUseMutation(endpoints.createCodePackage);

interface NewCodePackageFormValues
  extends Omit<CreateCodePackageInput, 'repositoryId'> {
  /** The selected repository */
  repository: RepositoryPreview;
}

export const NewCodePackageForm: React.FC<{
  /** On success callback */
  onSuccess?: (codePackage: CodePackage) => void;
}> = ({ onSuccess }) => {
  const { formatMessage } = useIntl();
  const formMethods = useForm<NewCodePackageFormValues>({});
  const [createCodePackage, createCodePackageResult] = useCreateCodePackage({
    refetchQueries: ['CodePackages'],
    awaitRefetchQueries: true,
  });

  return (
    <Form
      useFormMethods={formMethods}
      onSubmit={async ({ repository, ...input }) => {
        const { data } = await createCodePackage({
          variables: {
            input: {
              ...input,
              repositoryId: repository.id,
            },
          },
        });
        if (onSuccess && data) {
          onSuccess(data.codePackage);
        }
      }}
    >
      <FormInput
        id="name"
        name="name"
        label={newCodePackageFormMessages.nameLabel}
        rules={[Validators.REQUIRED]}
        required
      />
      <FormInput
        id="relativePath"
        name="relativePath"
        label={newCodePackageFormMessages.relativePathLabel}
        rules={[Validators.REQUIRED]}
        required
      />
      <FormTextArea
        id="description"
        name="description"
        label={newCodePackageFormMessages.descriptionLabel}
      />
      <FormSelectCodePackageType
        id="type"
        name="type"
        label={newCodePackageFormMessages.typeLabel}
        rules={[Validators.REQUIRED]}
        required
      />
      <FormSelectRepository
        id="repository"
        name="repository"
        label={newCodePackageFormMessages.repositoryLabel}
        rules={[Validators.REQUIRED]}
        required
      />
      <FlexColumnCenterHorizontal>
        <FormSubmitButton loading={createCodePackageResult.loading}>
          {formatMessage(newCodePackageFormMessages.submitButton)}
        </FormSubmitButton>
        <FormStatus
          {...mutationToFormStatusProps(createCodePackageResult)}
          successMessage={newCodePackageFormMessages.submitSuccess}
        />
      </FlexColumnCenterHorizontal>
    </Form>
  );
};
