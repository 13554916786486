import { ProcessingPurpose } from '@transcend-io/privacy-types';

import type { PurposeSubCategory } from '@main/datamap-types';

export const UNSPECIFIED_PURPOSE = {
  name: 'Unspecified',
  purpose: ProcessingPurpose.Unspecified,
} as PurposeSubCategory;

export const SELECT_SUB_PURPOSE_NODES = {
  id: null,
  name: null,
  purpose: null,
};
