import { Popover } from '@main/core-ui';
import React from 'react';
import { MessageDescriptor, useIntl } from 'react-intl';
import { useTheme } from 'styled-components';

import { joinAssociationPreviewMessages } from './messages';

/**
 * Props for JoinAssociationPreview
 */
export interface JoinAssociationPreviewProps<TJoin> {
  /** The preview header message */
  headerMessage: MessageDescriptor;
  /** The association items to display */
  items: TJoin[];
  /** Render a single item */
  renderItem: (item: TJoin) => React.ReactNode;
  /** When true, add a comma between render of each item */
  joinComma?: boolean;
  /** Provide the maximum number of items to display as `All` instead of a number */
  maxCount?: number;
  /** The maximum height for the popover */
  maxHeight?: string;
  /** The maximum width for the popover */
  maxWidth?: string;
}

/**
 * Preview join associations for an instance
 */
export function JoinAssociationPreview<TJoin>({
  maxCount,
  items,
  headerMessage,
  renderItem,
  joinComma = false,
  maxHeight = '250px',
  maxWidth = '500px',
}: JoinAssociationPreviewProps<TJoin>): JSX.Element {
  const theme = useTheme();
  const { formatMessage } = useIntl();
  // When to add a comma between listed items
  const addCommaMax = !joinComma || items.length < 2 ? -1 : items.length - 1;
  return (
    <Popover
      contents={
        <div
          style={{
            maxWidth,
            maxHeight,
            overflowY: 'scroll',
          }}
        >
          {items.length === 0 ? (
            <i>
              <small>
                {formatMessage(joinAssociationPreviewMessages.none)}
              </small>
            </i>
          ) : (
            items.map((item, ind) => {
              // Whether to add a comma
              const withComma = ind < addCommaMax;

              // The rendered content
              const content = renderItem(item);

              if (!withComma) {
                return content;
              }
              return (
                <React.Fragment key={(item as any)?.id || item}>
                  {content},&nbsp;
                </React.Fragment>
              );
            })
          )}
        </div>
      }
    >
      <b
        style={{
          cursor: 'pointer',
          color: theme.colors.transcend,
          fontWeight: 'bolder',
        }}
      >
        {formatMessage(headerMessage, {
          count:
            maxCount === items.length
              ? formatMessage(joinAssociationPreviewMessages.all)
              : items.length,
        })}
      </b>
    </Popover>
  );
}
