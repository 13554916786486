import { mkType, SchemaToType } from '@main/schema-utils';

export const TCFFeature = mkType({
  name: 'TCFFeature',
  comment: 'TCF Feature for a particular consent bundle',
  fields: {
    id: {
      type: 'id',
      comment: 'The id of the TCF Feature',
      modelName: 'tcfFeature',
    },
    name: {
      type: 'string',
      comment: 'The name of the TCF Feature',
    },
    isApplicable: {
      type: 'boolean',
      comment: 'Whether this feature is applicable to the organization',
    },
    vendorsDeclaring: {
      type: 'int',
      comment: 'total number of vendors declaring this feature',
    },
  },
});

/** Override type */
export type TCFFeature = SchemaToType<typeof TCFFeature>;
