import { AUTOCOMPLETE_OFF_PROPS, Form, useForm } from '@main/core-ui';
import { Editor } from '@tiptap/core';
import React from 'react';
import { useIntl } from 'react-intl';

import { FormSubmitButton } from '../Button';
import { FormInput } from '../Input';
import { Modal, ModalProps } from '../Modal';
import { richEditorMessages } from './messages';

export interface AddLinkModalProps extends ModalProps {
  /** The editor instance  */
  editor: Editor | null;
  /** Callback to fire when form is submitted */
  onSubmit: (values: AddLinkFormData) => void;
}

export interface AddLinkFormData {
  /** The URL to link to */
  destination: string;
}

export const AddLinkModal = ({
  editor,
  onSubmit,
  ...props
}: AddLinkModalProps): JSX.Element | null => {
  const { formatMessage } = useIntl();

  const formMethods = useForm<AddLinkFormData>({
    defaultValues: {
      destination: editor?.getAttributes('link').href ?? '',
    },
  });

  return (
    <Modal header={richEditorMessages.addLinkHeading} {...props}>
      <Form
        useFormMethods={formMethods}
        onSubmit={onSubmit}
        style={{ display: 'flex', flexDirection: 'column' }}
      >
        <FormInput
          name="destination"
          label={richEditorMessages.linkDestination}
          {...AUTOCOMPLETE_OFF_PROPS}
          // disabling this because we are focusing a newly opened modal, which is the correct behavior.
          // eslint-disable-next-line jsx-a11y/no-autofocus
          autoFocus
        />
        <FormSubmitButton>
          {formatMessage(richEditorMessages.submitLabel)}
        </FormSubmitButton>
      </Form>
    </Modal>
  );
};
