import { DefinedMessage } from '@main/internationalization';
import { mkType, SchemaToType } from '@main/schema-utils';

export const PreferenceTopicPreview = mkType({
  name: 'PreferenceTopicPreview',
  comment: 'Preference topic preview configuration on the Admin Dashboard.',
  fields: {
    id: {
      comment: 'The id of the preference topic',
      type: 'id',
      modelName: 'preferenceTopic',
    },
    slug: {
      comment: 'The slug of the preference topic',
      type: 'string',
    },
    title: {
      comment: 'The display title of the preference topic',
      type: DefinedMessage,
    },
    color: {
      comment: 'The color of the preference topic',
      type: 'string',
    },
  },
});

/** Override type */
export type PreferenceTopicPreview = SchemaToType<
  typeof PreferenceTopicPreview
>;
