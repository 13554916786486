import { Optionalize } from '@transcend-io/type-utils';
import React, { useState } from 'react';
import styled from 'styled-components';

import { DebouncedInput, DebouncedInputProps } from '../Input';

export interface InputNumberProps
  extends Optionalize<
    Omit<DebouncedInputProps, 'initialValue' | 'onChange' | 'type'>,
    'debounceTime'
  > {
  /** value */
  initialValue?: number;
  /** on change handler */
  onChange?: (newValue: number) => void;
}

const InputWithError = styled(DebouncedInput)<{
  /** does the input have a parse error */
  hasError: boolean;
}>`
  input,
  input:focus {
    ${({ hasError, theme }) =>
      hasError
        ? `
      border: 1px solid ${theme.colors.red2};
      outline-color: ${theme.colors.red1};
      `
        : ''}
  }
`;

/**
 * InputNumber
 */
export const InputNumber = React.forwardRef<HTMLInputElement, InputNumberProps>(
  ({ initialValue, onChange, debounceTime, ...inputProps }, ref) => {
    const [hasError, setHasError] = useState(false);

    return (
      <InputWithError
        {...inputProps}
        ref={ref}
        debounceTime={debounceTime ?? 0}
        initialValue={`${initialValue ?? 0}`}
        onChange={(event) => {
          const newVal = +event.target.value;
          if (!Number.isNaN(newVal)) {
            onChange?.(newVal);
            setHasError(false);
          } else {
            setHasError(true);
          }
        }}
        hasError={hasError}
        type="number"
      />
    );
  },
);
