import {
  PaginatedSelect,
  PaginatedSelectProps,
} from '@main/ad-core-components';
import { PromptPreview } from '@main/attribute-types';
import { buildUseInfiniteScroll, Icon } from '@main/core-ui';
import { endpoints, PromptOrderField } from '@main/prompt-types';
import { createNewId, ID, OrderDirection } from '@main/schema-utils';
import React, { useState } from 'react';
import { MenuPosition } from 'react-select';

import { selectPromptsMessages } from './messages';

const NODES = {
  id: null,
  title: null,
} as const;

/**
 * Selected prompt
 */
export type SelectedPrompt = Pick<PromptPreview, keyof typeof NODES> & {
  /** Whether prompt is newly created prompt or existing prompt */
  isNew?: boolean;
};

const useSelectPrompts = buildUseInfiniteScroll(
  endpoints.prompts,
  'SelectPrompts',
  {
    nodes: NODES,
  },
);

export interface SelectPromptProps
  extends Omit<
    PaginatedSelectProps<SelectedPrompt, true>,
    'isQueryLoading' | 'queryError' | 'fetchMore' | 'onEndsTyping' | 'onChange'
  > {
  /** On change handler */
  onChange?: (prompts: SelectedPrompt[]) => void;
  /** Show option to create prompt */
  allowCreate?: boolean;
  /** How to position the menu (see docs for React Select) */
  menuPosition?: MenuPosition;
}

/**
 * Select a specific prompt
 */
export const SelectPrompts: React.FC<SelectPromptProps> = ({
  allowCreate = false,
  placeholderDescriptor = selectPromptsMessages.placeholder,
  showOutline = true,
  onChange,
  menuPosition = 'fixed',
  ...paginatedSelectProps
}) => {
  const [searchText, setSearchText] = useState('');
  const { data, loading, error, fetchMore } = useSelectPrompts({
    variables: {
      filterBy: {
        text: searchText,
      },
      orderBy: [
        { field: PromptOrderField.Title, direction: OrderDirection.ASC },
      ],
    },
    fetchPolicy: 'cache-and-network',
  });

  return (
    <PaginatedSelect
      id="select-prompts"
      showOutline={showOutline}
      placeholderDescriptor={placeholderDescriptor}
      options={data?.nodes ?? []}
      onChange={onChange as any}
      isQueryLoading={loading}
      isCreatable={allowCreate}
      isMulti
      isClearable={false}
      menuPosition={menuPosition}
      getOptionValue={({ id }: SelectedPrompt) => id}
      getOptionLabel={({ title }: SelectedPrompt) => title}
      queryError={error}
      getOptionLogo={({ isNew }: SelectedPrompt) =>
        isNew ? <Icon type="circle-add" /> : ''
      }
      getNewOptionData={(inputValue) => ({
        isNew: true as const,
        title: inputValue,
        id: createNewId<'prompt'>(),
      })}
      fetchMore={fetchMore}
      onEndsTyping={setSearchText}
      {...paginatedSelectProps}
    />
  );
};

/**
 * Select a single prompt
 */
export const SelectPrompt: React.FC<
  Omit<SelectPromptProps, 'value' | 'onChange'> & {
    /** Set the newly selected prompts */
    onChange?: (prompt: SelectedPrompt) => void;
    /** Selected value */
    value?: SelectedPrompt | ID<'prompt'>;
  }
> = ({ onChange, value, ...props }) => (
  <SelectPrompts
    {...props}
    isMulti={false}
    value={
      // default type is mixed array rather than array of one type or the other
      value ? ([value] as SelectedPrompt[] | ID<'prompt'>[]) : []
    }
    onChange={onChange ? ([value]) => onChange(value) : undefined}
  />
);
