import styled from 'styled-components';

export const ToggleAllButton = styled.button`
  border: none;
  color: ${({ theme }) => theme.colors.transcend};
  background: ${({ theme }) => theme.colors.white};
  font-size: 14px;
  padding: 0 16px;
  flex-shrink: 0;
`;

export const Legend = styled.legend`
  font-size: 14px;
  color: ${({ theme }) => theme.colors.transcendNavy};
  margin-bottom: 0;
`;
