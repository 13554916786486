import { makeEnum } from '@transcend-io/type-utils';

export const DATA_MAP_PATH_PREFIX = '/data-map';
export const DATA_INVENTORY_PATH_PREFIX = `${DATA_MAP_PATH_PREFIX}/data-inventory`;
export const SILO_DISCOVERY_PATH_PREFIX = `${DATA_INVENTORY_PATH_PREFIX}/silo-discovery`;
export const CONTENT_CLASSIFICATION_PATH_PREFIX = `${DATA_MAP_PATH_PREFIX}/content-classification`;
export const UNSTRUCTURED_DISCOVERY_PATH_PREFIX = `${DATA_MAP_PATH_PREFIX}/unstructured-discovery`;

export const AdminDashboardDataMapPath = makeEnum({
  ContentClassificationActive: `${CONTENT_CLASSIFICATION_PATH_PREFIX}/active`,
  ContentClassificationInactive: `${CONTENT_CLASSIFICATION_PATH_PREFIX}/inactive`,
  ContentClassificationDashboard: `${CONTENT_CLASSIFICATION_PATH_PREFIX}/:dataSiloId`,
  ContentClassificationDetailsTrain: `${CONTENT_CLASSIFICATION_PATH_PREFIX}/:dataSiloId/train`,
  ContentClassificationDetailsBrowse: `${CONTENT_CLASSIFICATION_PATH_PREFIX}/:dataSiloId/browse`,
  ContentClassificationDetailsDatapoints: `${CONTENT_CLASSIFICATION_PATH_PREFIX}/:dataSiloId/datapoints`,
  ContentClassificationDetailsScanDiagnostics: `${CONTENT_CLASSIFICATION_PATH_PREFIX}/:dataSiloId/scan-diagnostics`,
  DataMappingDashboard: DATA_MAP_PATH_PREFIX,
  DataSilos: `${DATA_INVENTORY_PATH_PREFIX}/data-silos`,
  DataLineage: `${DATA_MAP_PATH_PREFIX}/data-lineage`,
  DataPoints: `${DATA_INVENTORY_PATH_PREFIX}/data-points`,
  Purposes: `${DATA_INVENTORY_PATH_PREFIX}/purposes`,
  BusinessEntities: `${DATA_INVENTORY_PATH_PREFIX}/business-entities`,
  Vendors: `${DATA_INVENTORY_PATH_PREFIX}/vendors`,
  DataInventoryAudit: `${DATA_MAP_PATH_PREFIX}/audit`,
  ActionItems: `${DATA_INVENTORY_PATH_PREFIX}/action-items`,
  DataCategories: `${DATA_INVENTORY_PATH_PREFIX}/data-categories`,
  DataReports: `${DATA_MAP_PATH_PREFIX}/data-reports`,
  TriageSiloDiscovery: `${SILO_DISCOVERY_PATH_PREFIX}/triage`,
  JunkSiloDiscovery: `${SILO_DISCOVERY_PATH_PREFIX}/junk`,
  UnstructuredDiscoveryActive: `${UNSTRUCTURED_DISCOVERY_PATH_PREFIX}/active`,
  UnstructuredDiscoveryInactive: `${UNSTRUCTURED_DISCOVERY_PATH_PREFIX}/inactive`,
  UnstructuredDiscoveryScanResults: `${UNSTRUCTURED_DISCOVERY_PATH_PREFIX}/:dataSiloId/scan-results`,
  UnstructuredDiscoveryRecommendations: `${UNSTRUCTURED_DISCOVERY_PATH_PREFIX}/:dataSiloId/:fileId`,
});

/** Override type */
export type AdminDashboardDataMapPath =
  (typeof AdminDashboardDataMapPath)[keyof typeof AdminDashboardDataMapPath];

export const ASSESSMENTS_PATH_PREFIX = '/assessments';

export const AdminDashboardAssessmentsPath = makeEnum({
  AssessmentGroups: `${ASSESSMENTS_PATH_PREFIX}/groups`,
  AssessmentGroup: `${ASSESSMENTS_PATH_PREFIX}/groups/:assessmentGroupId`,
  AssessmentForm: `${ASSESSMENTS_PATH_PREFIX}/forms/:assessmentFormId/view`,
  AssessmentFormResponse: `${ASSESSMENTS_PATH_PREFIX}/forms/:assessmentFormId/response`,
  AssessmentFormTemplates: `${ASSESSMENTS_PATH_PREFIX}/form-templates`,
  NewAssessmentFormTemplate: `${ASSESSMENTS_PATH_PREFIX}/form-templates/new`,
  AssessmentFormTemplate: `${ASSESSMENTS_PATH_PREFIX}/form-templates/:assessmentFormTemplateId`,
  AssessmentEmailSets: `${ASSESSMENTS_PATH_PREFIX}/email-sets`,
  AssessmentEmailSet: `${ASSESSMENTS_PATH_PREFIX}/email-sets/:assessmentEmailSetId`,
  RiskFrameworks: `${ASSESSMENTS_PATH_PREFIX}/risk-frameworks`,
  RiskCategories: `${ASSESSMENTS_PATH_PREFIX}/risk-frameworks/:riskFrameworkId/riskCategories`,
});

/** Override type */
export type AdminDashboardAssessmentsPath =
  (typeof AdminDashboardAssessmentsPath)[keyof typeof AdminDashboardAssessmentsPath];
