import { union } from 'interval-operations';

import type { Intervals } from '@main/dsr-types';

/**
 * Type safe merge of selections
 *
 * @param selections - The list of selections to merge
 * @returns Union all intervals
 */
export function mergeIntervals(selections: Intervals): Intervals {
  // Merge together
  const merged = union(...selections) as Intervals;

  // Ensure all intervals are positive
  return merged.filter(([x, y]) => y > x);
}
