import { DoubleTag, SingleTag } from '@main/core-ui';
import { processingPurposeMessages } from '@main/datamap-types';
import { ProcessingPurpose } from '@transcend-io/privacy-types';
import React from 'react';
import { useIntl } from 'react-intl';

import { PURPOSE_OF_PROCESSING_COLOR_NAMES } from '../../constants';

/** Props for Purpose Title component */
interface PurposeTitleProps {
  /** The parent purpose */
  purpose: ProcessingPurpose;
  /** The sub category of this purpose */
  name?: string;
}

export const PurposeTitle: React.FC<PurposeTitleProps> = ({
  name,
  purpose,
}) => {
  const { formatMessage } = useIntl();
  const purposeLabel = formatMessage(processingPurposeMessages[purpose]);
  return name ? (
    <DoubleTag
      style={{ margin: '0.1em', display: '-webkit-inline-box' }}
      variant="filled"
      leftLabel={purposeLabel}
      rightLabel={name}
      color={PURPOSE_OF_PROCESSING_COLOR_NAMES[purpose]}
    />
  ) : (
    <SingleTag
      style={{ margin: '0.1em', display: '-webkit-inline-box' }}
      label={purposeLabel}
      color={PURPOSE_OF_PROCESSING_COLOR_NAMES[purpose]}
    />
  );
};
