import { defineMessages } from '@transcend-io/internationalization';

export const detailsMessages = defineMessages(
  'admin-dashboard.Auditor.Run.Details.details',
  {
    header: {
      defaultMessage: 'Details',
    },
  },
);

export const regionMessages = defineMessages(
  'admin-dashboard.Auditor.Run.Details.region',
  {
    'us-west-1': {
      defaultMessage: 'California',
    },
    unknown: {
      defaultMessage: 'Unknown',
    },
  },
);
