import React from 'react';
import { useTheme } from 'styled-components';

/**
 * Number icon
 */
export const Num: React.FC = (props) => {
  const theme = useTheme();
  return (
    <svg
      width="42px"
      height="42px"
      viewBox="0 0 42 42"
      version="1.1"
      {...props}
    >
      <defs />
      <g
        id="Symbols"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Button/dashboardsquare"
          transform="translate(-43.000000, -31.000000)"
          fill={theme.colors.primary}
        >
          <g id="inputicon/number" transform="translate(40.000000, 28.000000)">
            <path
              // eslint-disable-next-line max-len
              d="M18,30 L30,30 L30,18 L18,18 L18,30 Z M18,36 L18,45 L12,45 L12,36 L3,36 L3,30 L12,30 L12,18 L3,18 L3,12 L12,12 L12,3 L18,3 L18,12 L30,12 L30,3 L36,3 L36,12 L45,12 L45,18 L36,18 L36,30 L45,30 L45,36 L36,36 L36,45 L30,45 L30,36 L18,36 Z"
              id="Shape"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};
