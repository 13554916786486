/* eslint-disable max-lines */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import difference from 'lodash/difference';
import differenceWith from 'lodash/differenceWith';
import uniq from 'lodash/uniq';
import uniqBy from 'lodash/uniqBy';

import type { ID } from '@main/schema-utils';

import { getIdForGuess } from './TriageSiloDiscovery/helpers';
import type {
  SiloDiscoveryRecommendationKey,
  SiloDiscoveryRecommendationWithContext,
} from './types';

export interface DataMapV2State {
  /** The selected data silos */
  selectedDataSilos: ID<'dataSilo'>[];
  /** The selected vendors */
  selectedVendors: ID<'vendor'>[];
  /** The selected business entities */
  selectedBusinessEntities: ID<'businessEntity'>[];
  /** The selected datapoints */
  selectedSubDataPoints: ID<'subDataPoint'>[];
  /** The selected pending silo recommendations */
  selectedPendingSiloRecommendations: SiloDiscoveryRecommendationWithContext[];
  /** The selected junk silo recommendations */
  selectedJunkSiloRecommendations: SiloDiscoveryRecommendationWithContext[];
  /** The selected data subcategories */
  selectedDataSubCategories: ID<'dataSubCategory'>[];
  /** The selected purpose subcategories */
  selectedPurposeSubCategories: ID<'processingPurposeSubCategory'>[];
  /** Has a demo been requested? */
  isDemoRequested: boolean;
}

export const dataMapV2Slice = createSlice({
  name: 'DataMapV2',
  initialState: {
    isDemoRequested: false,
    selectedDataSilos: [],
    selectedVendors: [],
    selectedBusinessEntities: [],
    selectedSubDataPoints: [],
    selectedPendingSiloRecommendations: [],
    selectedJunkSiloRecommendations: [],
    selectedDataSubCategories: [],
    selectedPurposeSubCategories: [],
  } as DataMapV2State,
  reducers: {
    hideDemoButton: (state) => ({
      ...state,
      isDemoRequested: true,
    }),
    selectDataSilo: (state, { payload }: PayloadAction<ID<'dataSilo'>>) => {
      state.selectedDataSilos.push(payload);
    },
    unselectDataSilo: (
      { selectedDataSilos, ...state },
      { payload }: PayloadAction<ID<'dataSilo'>>,
    ) => ({
      ...state,
      selectedDataSilos: selectedDataSilos.filter((id) => id !== payload),
    }),
    selectDataSilos: (state, { payload }: PayloadAction<ID<'dataSilo'>[]>) => {
      state.selectedDataSilos = uniq(state.selectedDataSilos.concat(payload));
    },
    unselectDataSilos: (
      state,
      { payload }: PayloadAction<ID<'dataSilo'>[]>,
    ) => {
      state.selectedDataSilos = difference(state.selectedDataSilos, payload);
    },
    resetSelectedDataSilos: (state) => ({
      ...state,
      selectedDataSilos: [],
    }),
    selectSubDataPoint: (
      state,
      { payload }: PayloadAction<ID<'subDataPoint'>>,
    ) => {
      state.selectedSubDataPoints.push(payload);
    },
    unselectSubDataPoint: (
      { selectedSubDataPoints, ...state },
      { payload }: PayloadAction<ID<'subDataPoint'>>,
    ) => ({
      ...state,
      selectedSubDataPoints: selectedSubDataPoints.filter(
        (id) => id !== payload,
      ),
    }),
    selectSubDataPoints: (
      state,
      { payload }: PayloadAction<ID<'subDataPoint'>[]>,
    ) => {
      state.selectedSubDataPoints = uniq(
        state.selectedSubDataPoints.concat(payload),
      );
    },
    unselectSubDataPoints: (
      state,
      { payload }: PayloadAction<ID<'subDataPoint'>[]>,
    ) => {
      state.selectedSubDataPoints = difference(
        state.selectedSubDataPoints,
        payload,
      );
    },
    resetSelectedDataPoints: (state) => ({
      ...state,
      selectedSubDataPoints: [],
    }),
    selectBusinessEntity: (
      state,
      { payload }: PayloadAction<ID<'businessEntity'>>,
    ) => {
      state.selectedBusinessEntities.push(payload);
    },
    unselectBusinessEntity: (
      { selectedBusinessEntities, ...state },
      { payload }: PayloadAction<ID<'businessEntity'>>,
    ) => ({
      ...state,
      selectedBusinessEntities: selectedBusinessEntities.filter(
        (id) => id !== payload,
      ),
    }),
    selectBusinessEntities: (
      state,
      { payload }: PayloadAction<ID<'businessEntity'>[]>,
    ) => {
      state.selectedBusinessEntities = uniq(
        state.selectedBusinessEntities.concat(payload),
      );
    },
    unselectBusinessEntities: (
      state,
      { payload }: PayloadAction<ID<'businessEntity'>[]>,
    ) => {
      state.selectedBusinessEntities = difference(
        state.selectedBusinessEntities,
        payload,
      );
    },
    resetSelectedBusinessEntities: (state) => ({
      ...state,
      selectedBusinessEntities: [],
    }),
    selectVendor: (state, { payload }: PayloadAction<ID<'vendor'>>) => {
      state.selectedVendors.push(payload);
    },
    unselectVendor: (
      { selectedVendors, ...state },
      { payload }: PayloadAction<ID<'vendor'>>,
    ) => ({
      ...state,
      selectedVendors: selectedVendors.filter((id) => id !== payload),
    }),
    selectVendors: (state, { payload }: PayloadAction<ID<'vendor'>[]>) => {
      state.selectedVendors = uniq(state.selectedVendors.concat(payload));
    },
    unselectVendors: (state, { payload }: PayloadAction<ID<'vendor'>[]>) => {
      state.selectedVendors = difference(state.selectedVendors, payload);
    },
    resetSelectedVendors: (state) => ({
      ...state,
      selectedVendors: [],
    }),
    selectPendingSiloRecommendation: (
      state,
      { payload }: PayloadAction<SiloDiscoveryRecommendationWithContext>,
    ) => {
      state.selectedPendingSiloRecommendations.push(payload);
      state.selectedPendingSiloRecommendations = uniqBy(
        [...state.selectedPendingSiloRecommendations],
        getIdForGuess,
      );
    },
    selectPendingSiloRecommendations: (
      state,
      { payload }: PayloadAction<SiloDiscoveryRecommendationWithContext[]>,
    ) => {
      state.selectedPendingSiloRecommendations.push(...payload);
      state.selectedPendingSiloRecommendations = uniqBy(
        [...state.selectedPendingSiloRecommendations],
        getIdForGuess,
      );
    },
    unselectPendingSiloRecommendation: (
      { selectedPendingSiloRecommendations, ...state },
      { payload }: PayloadAction<SiloDiscoveryRecommendationKey>,
    ) => ({
      ...state,
      selectedPendingSiloRecommendations:
        selectedPendingSiloRecommendations.filter(
          ({ pluginId, resourceId }) =>
            pluginId !== payload.pluginId || resourceId !== payload.resourceId,
        ),
    }),
    unselectPendingSiloRecommendations: (
      state,
      { payload }: PayloadAction<SiloDiscoveryRecommendationKey[]>,
    ) => {
      state.selectedPendingSiloRecommendations = differenceWith(
        state.selectedPendingSiloRecommendations,
        payload,
        (selected, key) =>
          selected.pluginId === key.pluginId &&
          selected.resourceId === key.resourceId,
      );
    },
    selectJunkSiloRecommendation: (
      state,
      { payload }: PayloadAction<SiloDiscoveryRecommendationWithContext>,
    ) => {
      state.selectedJunkSiloRecommendations.push(payload);
    },
    unselectJunkSiloRecommendation: (
      { selectedJunkSiloRecommendations, ...state },
      { payload }: PayloadAction<SiloDiscoveryRecommendationKey>,
    ) => ({
      ...state,
      selectedJunkSiloRecommendations: selectedJunkSiloRecommendations.filter(
        ({ pluginId, resourceId }) =>
          pluginId !== payload.pluginId || resourceId !== payload.resourceId,
      ),
    }),
    unselectJunkSiloRecommendations: (
      state,
      { payload }: PayloadAction<SiloDiscoveryRecommendationKey[]>,
    ) => {
      state.selectedJunkSiloRecommendations = differenceWith(
        state.selectedJunkSiloRecommendations,
        payload,
        (selected, key) =>
          selected.pluginId === key.pluginId &&
          selected.resourceId === key.resourceId,
      );
    },
    selectDataSubCategory: (
      state,
      { payload }: PayloadAction<ID<'dataSubCategory'>>,
    ) => {
      state.selectedDataSubCategories.push(payload);
    },
    unselectDataSubCategory: (
      { selectedDataSubCategories, ...state },
      { payload }: PayloadAction<ID<'dataSubCategory'>>,
    ) => ({
      ...state,
      selectedDataSubCategories: selectedDataSubCategories.filter(
        (id) => id !== payload,
      ),
    }),
    selectDataSubCategories: (
      state,
      { payload }: PayloadAction<ID<'dataSubCategory'>[]>,
    ) => {
      state.selectedDataSubCategories = uniq(
        state.selectedDataSubCategories.concat(payload),
      );
    },
    unselectDataSubCategories: (
      state,
      { payload }: PayloadAction<ID<'dataSubCategory'>[]>,
    ) => {
      state.selectedDataSubCategories = difference(
        state.selectedDataSubCategories,
        payload,
      );
    },
    resetSelectedDataSubCategories: (state) => ({
      ...state,
      selectedDataSubCategories: [],
    }),
    selectPurposeSubCategory: (
      state,
      { payload }: PayloadAction<ID<'processingPurposeSubCategory'>>,
    ) => {
      state.selectedPurposeSubCategories.push(payload);
    },
    unselectPurposeSubCategory: (
      { selectedPurposeSubCategories, ...state },
      { payload }: PayloadAction<ID<'processingPurposeSubCategory'>>,
    ) => ({
      ...state,
      selectedPurposeSubCategories: selectedPurposeSubCategories.filter(
        (id) => id !== payload,
      ),
    }),
    selectPurposeSubCategories: (
      state,
      { payload }: PayloadAction<ID<'processingPurposeSubCategory'>[]>,
    ) => {
      state.selectedPurposeSubCategories = uniq(
        state.selectedPurposeSubCategories.concat(payload),
      );
    },
    unselectPurposeSubCategories: (
      state,
      { payload }: PayloadAction<ID<'processingPurposeSubCategory'>[]>,
    ) => {
      state.selectedPurposeSubCategories = difference(
        state.selectedPurposeSubCategories,
        payload,
      );
    },
    resetSelectedPurposeSubCategories: (state) => ({
      ...state,
      selectedPurposeSubCategories: [],
    }),
  },
});

export const {
  selectDataSilo,
  unselectDataSilo,
  selectDataSilos,
  unselectDataSilos,
  resetSelectedDataSilos,
  selectSubDataPoint,
  unselectSubDataPoint,
  selectSubDataPoints,
  unselectSubDataPoints,
  resetSelectedDataPoints,
  selectPendingSiloRecommendation,
  selectPendingSiloRecommendations,
  unselectPendingSiloRecommendation,
  unselectPendingSiloRecommendations,
  selectJunkSiloRecommendation,
  unselectJunkSiloRecommendation,
  unselectJunkSiloRecommendations,
  selectDataSubCategory,
  unselectDataSubCategory,
  selectDataSubCategories,
  unselectDataSubCategories,
  resetSelectedDataSubCategories,
  selectPurposeSubCategory,
  unselectPurposeSubCategory,
  selectPurposeSubCategories,
  unselectPurposeSubCategories,
  resetSelectedPurposeSubCategories,
  hideDemoButton,
  selectBusinessEntity,
  unselectBusinessEntity,
  selectBusinessEntities,
  unselectBusinessEntities,
  resetSelectedBusinessEntities,
  selectVendor,
  unselectVendor,
  selectVendors,
  unselectVendors,
  resetSelectedVendors,
} = dataMapV2Slice.actions;
/* eslint-enable max-lines */
