import type { ExtendableRoute } from '@main/admin-dashboard/src/routes';
import {
  AdminDashboardCodeScanningPath,
  CODE_SCANNING_PATH_PREFIX,
} from '@main/code-scanning-types';

import { dataInventoryBreadcrumbs } from '../DataMap/routes';
import { CodePackagesLoadable } from './CodePackages/Loadable';
import { codeScanningPageTitleMessages } from './messages';
import { PullRequestsLoadable } from './PullRequests/Loadable';
import { RepositoriesLoadable } from './Repositories/Loadable';
import { SoftwareDevelopmentKitsLoadable } from './SoftwareDevelopmentKits/Loadable';

const repositoriesBreadcrumb = [
  dataInventoryBreadcrumbs[0],
  {
    id: CODE_SCANNING_PATH_PREFIX,
    displayText: codeScanningPageTitleMessages.repositories,
    href: AdminDashboardCodeScanningPath.Repositories,
  },
];

export const codeScanningRoutes: Record<
  AdminDashboardCodeScanningPath,
  ExtendableRoute
> = {
  [AdminDashboardCodeScanningPath.Repositories]: {
    path: AdminDashboardCodeScanningPath.Repositories,
    title: codeScanningPageTitleMessages.repositories,
    component: RepositoriesLoadable,
    breadcrumbs: repositoriesBreadcrumb,
  },
  [AdminDashboardCodeScanningPath.CodePackages]: {
    path: AdminDashboardCodeScanningPath.CodePackages,
    title: codeScanningPageTitleMessages.codePackages,
    component: CodePackagesLoadable,
    breadcrumbs: repositoriesBreadcrumb,
  },
  [AdminDashboardCodeScanningPath.PullRequests]: {
    path: AdminDashboardCodeScanningPath.PullRequests,
    title: codeScanningPageTitleMessages.pullRequests,
    component: PullRequestsLoadable,
    breadcrumbs: repositoriesBreadcrumb,
  },
  [AdminDashboardCodeScanningPath.SoftwareDevelopmentKits]: {
    path: AdminDashboardCodeScanningPath.SoftwareDevelopmentKits,
    title: codeScanningPageTitleMessages.softwareDevelopmentKits,
    component: SoftwareDevelopmentKitsLoadable,
    breadcrumbs: repositoriesBreadcrumb,
  },
};
