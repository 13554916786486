import { Modifier, Modifiers } from '@apollo/client/cache/core/types/common';
import { useInlineInput } from '@main/ad-core-components';
import { AttributeValue, endpoints } from '@main/attribute-types';
import { ApolloCache, buildUseMutation } from '@main/core-ui';
import type { ID } from '@main/schema-utils';
import { AttributeSupportedResourceType } from '@transcend-io/privacy-types';
import React from 'react';

import { ATTRIBUTE_KEY_TEXT_TYPES } from '../../constants';
import { AttributeInput, AttributeInputProps } from '../AttributeInput';
import { AttributeValuePreview } from '../SelectAttribute';

/** Props for the `InlineAttributeInput` component */
export interface InlineAttributeInputProps<
  T extends AttributeSupportedResourceType,
> {
  /** Attribute key to edit */
  attributeKey: AttributeInputProps['attributeKey'];
  /** Values */
  values: AttributeValuePreview[];
  /** ID of the resource */
  id: ID<T>;
  /** Attribute type */
  resourceType: T;
  /** Queries to refetch on success */
  refetchQueries?: string[];
  /** Whether the input should be displayed as readonly */
  readOnly?: boolean;
  /** Function to update the cache instead of refetching */
  updateCacheFields?: (
    cache: ApolloCache<any>,
    data: AttributeValue[],
    rowId: ID<T>,
    key: AttributeInputProps['attributeKey'],
  ) => Modifiers | Modifier<any>;
}

const useSetResourceAttributes = buildUseMutation(
  endpoints.setResourceAttributes,
);

/**
 * Component to conditional display/edit attributes based off of attribute key type
 */
export function InlineAttributeInput<T extends AttributeSupportedResourceType>({
  attributeKey,
  values,
  id,
  resourceType,
  refetchQueries,
  readOnly,
  updateCacheFields,
}: InlineAttributeInputProps<T>): React.ReactElement {
  const [setResourceAttributes] = useSetResourceAttributes({
    refetchQueries,
    ...(refetchQueries ? { awaitRefetchQueries: true } : {}),
    ...(updateCacheFields
      ? {
          update: (cache, { data }) => {
            const { attributeValues } = (data as any).setResourceAttributes;

            cache.modify({
              fields: updateCacheFields(
                cache,
                attributeValues,
                id,
                attributeKey,
              ),
            });
          },
        }
      : {}),
  });
  const textTypes = ATTRIBUTE_KEY_TEXT_TYPES;

  const { value, setValue, onBlur } = useInlineInput({
    initialValue: textTypes.includes(attributeKey.type)
      ? values.length > 0
        ? values[0].name
        : ''
      : values,
    onUpdate: (updatedValue) => {
      if (textTypes.includes(attributeKey.type)) {
        return setResourceAttributes({
          variables: {
            input: {
              resourceIds: [id],
              resourceType,
              attributeKeyId: attributeKey.id,
              attributeValueNames: [updatedValue as string],
              attributeValueIds: [],
            },
          },
        }).then(({ data, errors }) => ({
          data: data?.attributeValues[0]?.name,
          errors,
        }));
      }
      return setResourceAttributes({
        variables: {
          input: {
            resourceIds: [id],
            resourceType,
            attributeKeyId: attributeKey.id,
            attributeValueNames: [
              ...(updatedValue as AttributeValuePreview[]),
            ].map(({ name }) => name),
            attributeValueIds: [],
          },
        },
      }).then(({ data, errors }) => ({
        data: data?.attributeValues,
        errors,
      }));
    },
  });

  return (
    <AttributeInput
      values={value}
      attributeKey={attributeKey}
      setValue={setValue}
      onBlur={onBlur}
      readOnly={readOnly}
    />
  );
}
