import { defineMessages } from '@transcend-io/internationalization';

export const notAuthorizedMessages = defineMessages(
  'ad-core-components.NotAuthorized.notAuthorized',
  {
    notAuthorized: {
      defaultMessage:
        'You do not have permissions for this operation. Please ask your administrator.',
    },
  },
);
