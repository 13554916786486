import { endpoints } from '@main/access-control-types';
import {
  SelectedUser,
  SelectUser,
  SelectUserProps,
  useInlineInput,
} from '@main/ad-core-components';
import { buildUseMutation } from '@main/core-ui';
import type { ID } from '@main/schema-utils';
import React from 'react';

import { RowTableContext } from '../types';

const useUpdateActionItems = buildUseMutation(endpoints.updateActionItems);

interface InlineSelectAssigneeUsersProps extends SelectUserProps<true> {
  /** ids of the action items for this cell */
  ids: ID<'actionItem'>[];
  /** The value for the cell */
  value: SelectedUser[];
  /** Queries to refetch on success */
  refetchQueries?: string[];
  /** The table context */
  context: RowTableContext;
}

export const InlineSelectAssigneeUsers: React.FC<
  InlineSelectAssigneeUsersProps
> = ({
  ids,
  value: initialValue,
  refetchQueries = ['GlobalActionItems', 'GlobalActionItemsSubTable'],
  context,
  ...props
}) => {
  const [updateActionItems, { loading }] = useUpdateActionItems(
    refetchQueries
      ? {
          refetchQueries,
          awaitRefetchQueries: true,
        }
      : {},
  );
  const { value, setValue, onBlur } = useInlineInput<SelectedUser[]>({
    initialValue,
    optionsComparator: (existing, updated) =>
      JSON.stringify(existing?.map(({ id }) => id).sort()) ===
      JSON.stringify(updated?.map(({ id }) => id).sort()),
    onUpdate: (values) =>
      updateActionItems({
        variables: {
          input: {
            assigneesUserIds: values.map((option) => (option as any).id),
            ...(context.count > 1
              ? {
                  queryFilters: context.filters,
                  rollupFilters: [context.rollupFilters],
                }
              : {
                  ids,
                }),
          },
        },
      }).then(({ data, errors }) => ({
        data: values,
        errors,
        success: data?.success,
      })),
  });

  return (
    <SelectUser
      isMulti
      variant="light"
      value={value}
      showOutline={false}
      onChange={(newValues: SelectedUser[]) => {
        setValue(newValues);
      }}
      isLoading={loading}
      onBlur={onBlur}
      overflow="badge"
      {...props}
    />
  );
};
