import {
  AdminDashboardInfrastructurePath,
  INFRASTRUCTURE_PATH_PREFIX,
} from '@main/access-control-types';
import type { ExtendableRoute } from '@main/admin-dashboard/src/routes';

import { navMenuMessages } from '../routes/OrganizationRoute/NavMenu/messages';
import { ApiKeys } from './ApiKeys/Loadable';
import { AttributeManagementLoadable } from './AttributeManagement/Loadable';
import { OAuthFailure, OAuthSuccess } from './ConnectSilo/OAuth/Loadable';
import { EmailDomainsLoadable } from './EmailDomains/Loadable';
import { AddDataSiloLoadable } from './Integrations/AddDataSilo/Loadable';
import { DataSiloLoadable } from './Integrations/DataSiloDetails/Loadable';
import { DataSilosLoadable } from './Integrations/DataSilos/Loadable';
import { infrastructurePageTitleMessages } from './messages';
import { OAuthLanding } from './OAuthLanding/Loadable';
import { sombraSettingsMessages } from './SombraSettings/messages';
import { SombraApiKeysLoadable } from './SombraSettings/SombraApiKeys/Loadable';
import { SombraChangelogLoadable } from './SombraSettings/SombraChangelog/Loadable';
import { SombraRotateKeysLoadable } from './SombraSettings/SombraRotateKeys/Loadable';
import { SombrasLoadable } from './SombraSettings/Sombras/Loadable';
import { SombraSecuritySettingsLoadable } from './SombraSettings/SombraSecuritySettings/Loadable';

const infrastructureBreadcrumb = {
  id: INFRASTRUCTURE_PATH_PREFIX,
  displayText: navMenuMessages.linkDeveloperTools,
  href: AdminDashboardInfrastructurePath.EmailDomains,
};

export const integrationsBreadcrumbs = [
  {
    id: AdminDashboardInfrastructurePath.Integrations,
    displayText: infrastructurePageTitleMessages.integrations,
    href: AdminDashboardInfrastructurePath.Integrations,
  },
] as const;

const sombrasBreadcrumbs = [
  {
    id: AdminDashboardInfrastructurePath.Sombras,
    displayText: infrastructurePageTitleMessages.sombra,
    href: AdminDashboardInfrastructurePath.Sombras,
  },
];

const attributesBreadcrumbs = [
  {
    id: AdminDashboardInfrastructurePath.AttributesManagement,
    displayText: infrastructurePageTitleMessages.attributeManagement,
    href: AdminDashboardInfrastructurePath.AttributesManagement,
  },
];

export const infrastructureRoutes: {
  [k in AdminDashboardInfrastructurePath]: ExtendableRoute;
} = {
  [AdminDashboardInfrastructurePath.Sombras]: {
    path: AdminDashboardInfrastructurePath.Sombras,
    title: sombraSettingsMessages.sombras,
    component: SombrasLoadable,
    breadcrumbs: sombrasBreadcrumbs,
  },
  [AdminDashboardInfrastructurePath.SecuritySettings]: {
    path: AdminDashboardInfrastructurePath.SecuritySettings,
    title: sombraSettingsMessages.security,
    component: SombraSecuritySettingsLoadable,
    breadcrumbs: sombrasBreadcrumbs,
  },
  [AdminDashboardInfrastructurePath.SombraRotateKeys]: {
    path: AdminDashboardInfrastructurePath.SombraRotateKeys,
    title: sombraSettingsMessages.rotate,
    component: SombraRotateKeysLoadable,
    breadcrumbs: sombrasBreadcrumbs,
  },
  [AdminDashboardInfrastructurePath.SombraChangelog]: {
    path: AdminDashboardInfrastructurePath.SombraChangelog,
    title: sombraSettingsMessages.changelog,
    component: SombraChangelogLoadable,
    breadcrumbs: sombrasBreadcrumbs,
  },
  [AdminDashboardInfrastructurePath.SombraApiKeys]: {
    path: AdminDashboardInfrastructurePath.SombraApiKeys,
    title: sombraSettingsMessages.apiKeys,
    component: SombraApiKeysLoadable,
    breadcrumbs: sombrasBreadcrumbs,
  },
  [AdminDashboardInfrastructurePath.EmailDomains]: {
    path: AdminDashboardInfrastructurePath.EmailDomains,
    title: infrastructurePageTitleMessages.emailDomains,
    component: EmailDomainsLoadable,
    breadcrumbs: [
      infrastructureBreadcrumb,
      {
        id: AdminDashboardInfrastructurePath.EmailDomains,
        displayText: infrastructurePageTitleMessages.emailDomains,
        href: AdminDashboardInfrastructurePath.EmailDomains,
      },
    ],
  },
  [AdminDashboardInfrastructurePath.ApiKeys]: {
    path: AdminDashboardInfrastructurePath.ApiKeys,
    title: infrastructurePageTitleMessages.apiKeys,
    component: ApiKeys,
    breadcrumbs: [
      infrastructureBreadcrumb,
      {
        id: AdminDashboardInfrastructurePath.ApiKeys,
        displayText: infrastructurePageTitleMessages.apiKeys,
        href: AdminDashboardInfrastructurePath.ApiKeys,
      },
    ],
  },
  [AdminDashboardInfrastructurePath.Integrations]: {
    path: AdminDashboardInfrastructurePath.Integrations,
    title: infrastructurePageTitleMessages.integrations,
    component: DataSilosLoadable,
    breadcrumbs: integrationsBreadcrumbs,
  },
  [AdminDashboardInfrastructurePath.ConnectNewService]: {
    path: AdminDashboardInfrastructurePath.ConnectNewService,
    component: AddDataSiloLoadable,
    title: infrastructurePageTitleMessages.connectNewService,
    breadcrumbs: [
      ...integrationsBreadcrumbs,
      {
        id: AdminDashboardInfrastructurePath.ConnectNewService,
        displayText: infrastructurePageTitleMessages.connectNewService,
        href: AdminDashboardInfrastructurePath.ConnectNewService,
      },
    ],
  },
  [AdminDashboardInfrastructurePath.ServiceConfiguration]: {
    path: AdminDashboardInfrastructurePath.ServiceConfiguration,
    component: DataSiloLoadable,
    // Fallback value. This is set on the page dynamically.
    breadcrumbs: integrationsBreadcrumbs,
  },
  [AdminDashboardInfrastructurePath.DataSiloActivities]: {
    path: AdminDashboardInfrastructurePath.DataSiloActivities,
    component: DataSiloLoadable,
    // Fallback value. This is set on the page dynamically.
    breadcrumbs: integrationsBreadcrumbs,
  },
  [AdminDashboardInfrastructurePath.DataSiloConnection]: {
    path: AdminDashboardInfrastructurePath.DataSiloConnection,
    component: DataSiloLoadable,
    // Fallback value. This is set on the page dynamically.
    breadcrumbs: integrationsBreadcrumbs,
  },
  [AdminDashboardInfrastructurePath.DataSiloSiloDiscovery]: {
    path: AdminDashboardInfrastructurePath.DataSiloSiloDiscovery,
    component: DataSiloLoadable,
    // Fallback value. This is set on the page dynamically.
    breadcrumbs: integrationsBreadcrumbs,
  },
  [AdminDashboardInfrastructurePath.DataSiloStructuredDiscovery]: {
    path: AdminDashboardInfrastructurePath.DataSiloStructuredDiscovery,
    component: DataSiloLoadable,
    // Fallback value. This is set on the page dynamically.
    breadcrumbs: integrationsBreadcrumbs,
  },
  [AdminDashboardInfrastructurePath.DataSiloUnstructuredDiscovery]: {
    path: AdminDashboardInfrastructurePath.DataSiloUnstructuredDiscovery,
    component: DataSiloLoadable,
    // Fallback value. This is set on the page dynamically.
    breadcrumbs: integrationsBreadcrumbs,
  },
  [AdminDashboardInfrastructurePath.DataSiloDsrAutomation]: {
    path: AdminDashboardInfrastructurePath.DataSiloDsrAutomation,
    component: DataSiloLoadable,
    // Fallback value. This is set on the page dynamically.
    breadcrumbs: integrationsBreadcrumbs,
  },
  [AdminDashboardInfrastructurePath.DataSiloManageDatapoints]: {
    path: AdminDashboardInfrastructurePath.DataSiloManageDatapoints,
    component: DataSiloLoadable,
    // Fallback value. This is set on the page dynamically.
    breadcrumbs: integrationsBreadcrumbs,
  },
  [AdminDashboardInfrastructurePath.DataSiloProcessedRequests]: {
    path: AdminDashboardInfrastructurePath.DataSiloProcessedRequests,
    component: DataSiloLoadable,
    // Fallback value. This is set on the page dynamically.
    breadcrumbs: integrationsBreadcrumbs,
  },
  [AdminDashboardInfrastructurePath.DataSiloLookups]: {
    path: AdminDashboardInfrastructurePath.DataSiloLookups,
    component: DataSiloLoadable,
    // Fallback value. This is set on the page dynamically.
    breadcrumbs: integrationsBreadcrumbs,
  },
  [AdminDashboardInfrastructurePath.DataSiloAuditTrail]: {
    path: AdminDashboardInfrastructurePath.DataSiloAuditTrail,
    component: DataSiloLoadable,
    // Fallback value. This is set on the page dynamically.
    breadcrumbs: integrationsBreadcrumbs,
  },
  [AdminDashboardInfrastructurePath.DataSiloActionItems]: {
    path: AdminDashboardInfrastructurePath.DataSiloActionItems,
    component: DataSiloLoadable,
    // Fallback value. This is set on the page dynamically.
    breadcrumbs: integrationsBreadcrumbs,
  },
  [AdminDashboardInfrastructurePath.OAuthSuccess]: {
    path: AdminDashboardInfrastructurePath.OAuthSuccess,
    component: OAuthSuccess,
  },
  [AdminDashboardInfrastructurePath.OAuthFailure]: {
    path: AdminDashboardInfrastructurePath.OAuthFailure,
    component: OAuthFailure,
  },
  [AdminDashboardInfrastructurePath.OAuthLanding]: {
    path: AdminDashboardInfrastructurePath.OAuthLanding,
    component: OAuthLanding,
  },
  [AdminDashboardInfrastructurePath.AttributesManagement]: {
    path: AdminDashboardInfrastructurePath.AttributesManagement,
    title: infrastructurePageTitleMessages.attributeManagement,
    component: AttributeManagementLoadable,
    breadcrumbs: attributesBreadcrumbs,
  },
  [AdminDashboardInfrastructurePath.AttributeManagement]: {
    path: AdminDashboardInfrastructurePath.AttributeManagement,
    title: infrastructurePageTitleMessages.attributeManagement,
    component: AttributeManagementLoadable,
    breadcrumbs: attributesBreadcrumbs,
  },
  [AdminDashboardInfrastructurePath.CreateNewAttribute]: {
    path: AdminDashboardInfrastructurePath.CreateNewAttribute,
    title: infrastructurePageTitleMessages.createNewAttribute,
    component: AttributeManagementLoadable,
    breadcrumbs: [
      ...attributesBreadcrumbs,
      {
        id: AdminDashboardInfrastructurePath.CreateNewAttribute,
        displayText: infrastructurePageTitleMessages.createNewAttribute,
        href: AdminDashboardInfrastructurePath.CreateNewAttribute,
      },
    ],
  },
};
