import { Icon, StyleUtils, useFormatMessageGeneric } from '@main/core-ui';
import { IndexResult } from '@main/utils';
import React, { useMemo } from 'react';
import { useTheme } from 'styled-components';

import { Button } from '../Button';
import { FilterConfigItem, FiltersConfig } from './types';
import { StyledCategoryTitle, StyledMenuItem } from './wrappers';

interface FilterPopoverProps<TFilterInput extends Record<string, unknown>> {
  /** Function to set menu to closed */
  closeMenu: () => void;
  /** The key currently being filtered */
  currentSelectorKey: Extract<keyof TFilterInput, string> | '';
  /** The configuration of raw and enriched filters */
  filtersConfig: FiltersConfig<TFilterInput>;
  /** hash table for filters config array  */
  filtersConfigLookup: IndexResult<
    Extract<keyof TFilterInput, string>,
    FilterConfigItem<TFilterInput>
  >;
  /** Values */
  filtersValues: TFilterInput;
  /** Id of filter component */
  id?: string;
  /** global onChange to be passed to children as default */
  onChange?: (
    filterKey: keyof TFilterInput,
    value: TFilterInput[keyof TFilterInput],
  ) => void;
  /** Sets the key currently being filtered */
  setCurrentSelectorKey: (
    filterKey: Extract<keyof TFilterInput, string> | '',
  ) => void;
}
export const FilterPopover = <T extends Record<string, unknown>>({
  closeMenu,
  currentSelectorKey,
  filtersConfig,
  filtersConfigLookup,
  filtersValues,
  id,
  setCurrentSelectorKey,
  onChange,
}: FilterPopoverProps<T>): JSX.Element => {
  const theme = useTheme();
  const { formatMessageGeneric } = useFormatMessageGeneric();

  const menuItems = useMemo(
    () =>
      filtersConfig
        .filter(({ filter }) => !!filter)
        .map(({ filterKey, label }) => (
          <StyledMenuItem
            key={String(filterKey)}
            variant="link"
            onClick={() => {
              setCurrentSelectorKey(filterKey);
            }}
          >
            {formatMessageGeneric(label)}
          </StyledMenuItem>
        )),
    [filtersConfig, formatMessageGeneric, setCurrentSelectorKey],
  );

  const currentConfigItem = currentSelectorKey
    ? filtersConfigLookup[currentSelectorKey]
    : null;

  const closeButtonProps = {
    variant: 'link',
    size: 'sm' as const,
    icon: <Icon type="close" color={theme.colors.transcendNavy2} size={10} />,
  };

  return (
    <div id={`${id ? `${id}-` : ''}popover-contents`}>
      {currentConfigItem && currentConfigItem.filter ? (
        <div
          style={{
            ...StyleUtils.Flex.Column.base,
            padding: '0.5rem',
            gap: '0.5rem',
          }}
        >
          <div
            style={{
              ...StyleUtils.Flex.Row.CenterVertical,
              ...StyleUtils.Flex.SpaceBetween,
              padding: '0 6px 4px 6px',
            }}
          >
            <StyledCategoryTitle>
              {formatMessageGeneric(currentConfigItem.label)}
            </StyledCategoryTitle>
            <Button
              {...closeButtonProps}
              id={`${id ? `${id}-` : ''}close-filter`}
              onClick={() => {
                setCurrentSelectorKey('');
              }}
            />
          </div>
          {React.cloneElement(currentConfigItem.filter, {
            value: filtersValues[currentConfigItem.filterKey],
            ...(onChange
              ? {
                  onChange: (value: T[keyof T]) =>
                    onChange(currentSelectorKey, value),
                }
              : {}),
            // override onChange and value with props set on component
            ...currentConfigItem.filter.props,
          })}
        </div>
      ) : (
        <div
          style={{
            ...StyleUtils.Flex.Column.base,
            padding: '4px 28px 4px 0',
            position: 'relative',
            maxHeight: '70vh',
            overflowY: 'auto',
            overflowX: 'hidden',
          }}
        >
          <Button
            {...closeButtonProps}
            onClick={closeMenu}
            id={`${id ? `${id}-` : ''}close-filters`}
            style={{ position: 'absolute', right: 5, top: 5, zIndex: 1 }}
          />
          {menuItems}
        </div>
      )}
    </div>
  );
};
