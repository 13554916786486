/* eslint-disable max-lines */
import { defineMessages } from '@transcend-io/internationalization';

export const navMenuMessages = defineMessages(
  'admin-dashboard.routes.OrganizationRoute.NavMenu.navMenu',
  {
    linkActionItems: {
      defaultMessage: 'Action Items',
    },
    repositories: {
      defaultMessage: 'Repositories',
    },
    prompts: {
      defaultMessage: 'Prompts',
    },
    agents: {
      defaultMessage: 'Assistants',
    },
    sectionDataAssetIntelligence: {
      defaultMessage: 'Data Asset Intelligence',
    },
    linkDataInventory: {
      defaultMessage: 'Data Inventory',
    },
    linkDataInventoryDashboard: {
      defaultMessage: 'Dashboard',
    },
    linkDataInventoryBase: {
      defaultMessage: 'Inventory',
    },
    linkDataInventoryDataReports: {
      defaultMessage: 'Data Reports',
    },
    linkDataLineage: {
      defaultMessage: 'Data Lineage',
    },
    sectionDataDiscovery: {
      defaultMessage: 'Data Discovery & Classification',
    },
    linkSiloDiscovery: {
      defaultMessage: 'Silo Discovery',
    },
    linkStructuredDiscovery: {
      defaultMessage: 'Structured Discovery',
    },
    linkUnstructuredDiscovery: {
      defaultMessage: 'Unstructured Discovery',
    },
    sectionPrivacyOperations: {
      defaultMessage: 'Autonomous Privacy Operations',
    },
    linkDsrAutomation: {
      defaultMessage: 'DSR Automation',
    },
    linkDsrDashboard: {
      defaultMessage: 'Dashboard',
    },
    linkDsrWorkflows: {
      defaultMessage: 'Workflows',
    },
    linkIncomingRequests: {
      defaultMessage: 'Incoming Requests',
    },
    linkBulkRespond: {
      defaultMessage: 'Bulk Respond',
    },
    linkRequestSettings: {
      defaultMessage: 'Request Settings',
    },
    linkIdentifiers: {
      defaultMessage: 'Identifiers',
    },
    linkEmailTemplates: {
      defaultMessage: 'Email Templates',
    },
    linkEmailSettings: {
      defaultMessage: 'Email Settings',
    },
    linkLegalHolds: {
      defaultMessage: 'Legal Holds',
    },
    linkDataInventoryAuditTrail: { defaultMessage: 'Audit Trail' },
    linkOnboardingItems: {
      defaultMessage: 'Action Item Checklists',
    },
    linkConsentManagement: {
      defaultMessage: 'Consent Management',
    },
    linkConsentDashboard: {
      defaultMessage: 'Dashboard',
    },
    linkAuditorRuns: {
      defaultMessage: 'Runs',
    },
    linkAuditorSchedules: {
      defaultMessage: 'Schedules',
    },
    linkDataFlows: {
      defaultMessage: 'Data Flows',
    },
    linkCookies: {
      defaultMessage: 'Cookies',
    },
    linkRegionalExperiences: {
      defaultMessage: 'Regional Experiences',
    },
    linkDeveloperSettings: {
      defaultMessage: 'Developer Settings',
    },
    linkDisplaySettings: {
      defaultMessage: 'Display Settings',
    },
    linkConsentAuditTrail: {
      defaultMessage: 'Audit Trail',
    },
    linkPreferenceManagement: {
      defaultMessage: 'Preference Management',
    },
    linkPrivacyCenter: {
      defaultMessage: 'Privacy Center',
    },
    linkGeneralSettings: {
      defaultMessage: 'General Settings',
    },
    linkPolicies: {
      defaultMessage: 'Policies',
    },
    linkAssetFiles: {
      defaultMessage: 'Asset Files',
    },
    linkMessages: {
      defaultMessage: 'Messages & Internationalization',
    },
    linkRequestsProcessedStats: {
      defaultMessage: 'Requests Processed Stats',
    },
    linkColors: {
      defaultMessage: 'Colors & Styles',
    },
    linkAuthenticationMethods: {
      defaultMessage: 'Authentication Methods',
    },
    linkDataPractices: {
      defaultMessage: 'Data Practices',
    },
    linkPrivacyCenterAuditTrail: {
      defaultMessage: 'Audit Trail',
    },
    sectionRiskIntelligence: {
      defaultMessage: 'Risk Intelligence',
    },
    linkWebAuditor: {
      defaultMessage: 'Web Auditor',
    },
    linkContractScanning: {
      defaultMessage: 'Contract Scanning',
    },
    linkAssessments: {
      defaultMessage: 'Assessments',
    },
    sectionAiGovernance: {
      defaultMessage: 'AI Governance',
    },
    linkPathfinder: {
      defaultMessage: 'Pathfinder',
    },
    linkPromptManagement: {
      defaultMessage: 'Prompt Manager',
    },
    sectionInfrastructure: {
      defaultMessage: 'Infrastructure',
    },
    linkIntegrations: {
      defaultMessage: 'Integrations',
    },
    linkSombra: {
      defaultMessage: 'Sombra',
    },
    linkDeveloperTools: {
      defaultMessage: 'Developer Tools',
    },
    linkEmailDomains: {
      defaultMessage: 'Email Domains',
    },
    linkApiKeys: {
      defaultMessage: 'API Keys',
    },
    linkCliTerraform: {
      defaultMessage: 'CLI & Terraform',
    },
    linkCustomFields: {
      defaultMessage: 'Custom Fields',
    },
    linkConsentServices: {
      defaultMessage: 'Services',
    },
    linkTcfSettings: {
      defaultMessage: 'TCF Settings',
    },
    linkNativeConsent: {
      defaultMessage: 'Native Apps',
    },
    expandNavSection: {
      defaultMessage: 'Expand nav section',
    },
    linkConsentWorkflows: {
      defaultMessage: 'Consent Workflows',
    },
    linkPreferenceTopics: {
      defaultMessage: 'Preference Topics',
    },
    linkUserPreferences: {
      defaultMessage: 'User Preferences',
    },
  },
);

export const ctaPageMessages = defineMessages(
  'admin-dashboard.routes.OrganizationRoute.NavMenu.ctaPage',
  {
    contactSales: {
      defaultMessage: 'Contact Sales',
    },
    errorOccurred: {
      defaultMessage:
        'An unexpected error occurred. Try reloading the page. If the issue persists, reach out to support.',
    },
    webAuditorTitle: {
      defaultMessage: 'Your web compliance watchdog',
    },
    webAuditorCtaDescription: {
      defaultMessage:
        'See how effective your site actually is in complying with specific privacy regulations.',
    },
    webAuditorCtaFeature1: {
      defaultMessage: 'Identify AdTech violations on any web page',
    },
    webAuditorCtaFeature2: {
      defaultMessage: 'Immediate visibility for immediate action',
    },
    webAuditorCtaFeature3: {
      defaultMessage:
        'Run scheduled scans to see if your website is putting your organization at risk',
    },
    webAuditorPrimaryLinkText: {
      defaultMessage: 'See it in action',
    },
    dataLineageTitle: {
      defaultMessage: 'Better data intelligence, sharper data governance',
    },
    dataLineageDescription: {
      defaultMessage:
        'With {productName}, have confidence with how personal information is collected, ' +
        'processed and shared across your organization.',
    },
    dataLineageBullet1: {
      defaultMessage: 'Visualize where data flows',
    },
    dataLineageBullet2: {
      defaultMessage: 'Filter by processing purpose or data category',
    },
    dataLineageBullet3: {
      defaultMessage:
        'Enable proactive data governance and compliance assurance',
    },
    dataLineagePrimaryLinkText: {
      defaultMessage: 'Talk to Sales',
    },
    siloDiscoTitle: {
      defaultMessage: '360 visibility starts here',
    },
    siloDiscoDescription: {
      defaultMessage:
        'Know all of your data silos: automatically scan, discover, and catalog.',
    },
    siloDiscoBullet1: {
      defaultMessage:
        'From shadow IT to full visibility with automated scanning',
    },
    siloDiscoBullet2: {
      defaultMessage:
        'Uncover more with scans across client interfaces, codebases, SaaS tools, and more',
    },
    siloDiscoBullet3: {
      defaultMessage:
        'Automatically populates your Data Inventory for up-to-date visibility',
    },
    siloDiscoPrimaryLinkText: {
      defaultMessage: 'See it today',
    },
    structuredDiscoTitle: {
      defaultMessage: 'Know where the PII is hiding',
    },
    structuredDiscoDescription: {
      defaultMessage:
        "Easily understand what kind of information is being collected, where it's stored, and who has access to it.",
    },
    structuredDiscoBullet1: {
      defaultMessage:
        'Comprehensive support for DBs including Snowflake, MongoDB, Netsuite, Salesforce, S3 data lakes, and more',
    },
    structuredDiscoBullet2: {
      defaultMessage: 'Quick to set up, thanks to our agentless deployment',
    },
    structuredDiscoBullet3: {
      defaultMessage:
        'Cost-effective, thanks to our proprietary sampling approach',
    },
    structuredDiscoPrimaryLinkText: {
      defaultMessage: 'Get a demo',
    },
    unstructuredDiscoTitle: {
      defaultMessage:
        'Stop struggling with personal data in docs, chat apps, and text files',
    },
    unstructuredDiscoDescription: {
      defaultMessage:
        // eslint-disable-next-line max-len
        '{productName} uncovers and classifies personal data living in non-conventional data models, for comprehensive governance.',
    },
    unstructuredDiscoBullet1: {
      defaultMessage:
        // eslint-disable-next-line max-len
        'Continuously discovers and classifies data inside non-formalized data stores, eg. Slack, Asana, S3, Azure, O365, and Google Suite',
    },
    unstructuredDiscoBullet2: {
      defaultMessage: 'Quick to set up, thanks to our agentless deployment',
    },
    unstructuredDiscoBullet3: {
      defaultMessage:
        'Cost-effective, thanks to our proprietary sampling approach',
    },
    unstructuredDiscoPrimaryLinkText: {
      defaultMessage: 'Talk to an expert today',
    },
    dsrAutomationTitle: {
      defaultMessage:
        'Automate with pre-built workflows and zero code configuration',
    },
    dsrAutomationDescription: {
      defaultMessage:
        // eslint-disable-next-line max-len
        'Say goodbye to operational resource drains. {productName} is your all-in-one solution for deleting and returning any kind of personal data, across every system, at any scale.',
    },
    dsrAutomationBullet1: {
      defaultMessage:
        'Complete data coverage, with the largest ecosystem of integrations',
    },
    dsrAutomationBullet2: {
      defaultMessage:
        'Legal holds and pre-flight checks turn your edge cases into an automated routine',
    },
    dsrAutomationBullet3: {
      defaultMessage:
        'Simple KPI and ROI reporting demonstrate your processing efficiency',
    },
    dsrAutomationPrimaryLinkText: {
      defaultMessage: 'Try it today',
    },
    consentTitle: {
      defaultMessage: 'A new standard for consent management',
    },
    consentDescription: {
      defaultMessage:
        // eslint-disable-next-line max-len
        'The old way of consent is broken. Experience the easiest, most comprehensive way to collect and govern consent across every interface, from websites to mobile apps.',
    },
    consentBullet1: {
      defaultMessage:
        'Continuous detection of 200+ kinds of trackers across every inch of your site',
    },
    consentBullet2: {
      defaultMessage:
        'Automatic network-level enforcement–no manual tag manager configuration',
    },
    consentBullet3: {
      defaultMessage:
        'Out of the box compliance for Do Not Sell/Share, Google Consent Mode, IAB TCF, and more',
    },
    consentPrimaryLinkText: {
      defaultMessage: 'Learn more',
    },
    assessmentTitle: {
      defaultMessage: 'Centralized risk management',
    },
    assessmentDescription: {
      defaultMessage:
        // eslint-disable-next-line max-len
        'Centralize DPIAs, TIAs, and PRD reviews in {productName} with pre-built templates which directly tie into your Data Inventory.',
    },
    assessmentBullet1: {
      defaultMessage:
        'A centralized engine for easy and thorough assessment management',
    },
    assessmentBullet2: {
      defaultMessage:
        'Auto suggestions turn your insights into efficient privacy operations',
    },
    assessmentBullet3: {
      defaultMessage: 'AI-powered reviews of PRDs, pull requests, and more',
    },
    assessmentPrimaryLinkText: {
      defaultMessage: 'Get started now',
    },
    sombraTitle: {
      defaultMessage: 'Keep privacy from becoming a security problem',
    },
    sombraDescription: {
      defaultMessage:
        'Data discovery and governance without infrastructure exposure.',
    },
    sombraBullet1: { defaultMessage: 'End to end encryption with AES-256' },
    sombraBullet2: { defaultMessage: 'You keep your API keys' },
    sombraBullet3: {
      defaultMessage: 'Cryptographic proof required for all operations',
    },
    sombraPrimaryLinkText: { defaultMessage: 'See a demo' },
    promptsTitle: {
      defaultMessage: 'Data governance, supercharged with AI',
    },
    promptsDescription: {
      defaultMessage:
        'Power governance prompts to identify risk gaps, extract metadata, and more.',
    },
    promptsBullet1: {
      defaultMessage:
        'A powerful tool to save, edit, and repeat governance prompts',
    },
    promptsBullet2: {
      defaultMessage:
        "Pull from Transcend's solutions library or create your own",
    },
    promptsBullet3: {
      defaultMessage:
        'Jumpstart privacy operations and increase risk intelligence',
    },
    promptsPrimaryLinkText: { defaultMessage: 'Talk to an expert' },
    pathfinderTitle: {
      defaultMessage: 'Scalable AI governance',
    },
    pathfinderDescription: {
      defaultMessage: 'Ensure your company has technical guardrails for LLMs.',
    },
    pathfinderBullet1: {
      defaultMessage: 'Middleware for data going in and out of LLMs',
    },
    pathfinderBullet2: {
      defaultMessage: 'A single audit view of all AI use',
    },
    pathfinderBullet3: {
      defaultMessage: 'Continuous monitoring and alerts',
    },
    pathfinderPrimaryLinkText: {
      defaultMessage: 'Talk to an expert',
    },
    contractScanningTitle: {
      defaultMessage: 'Free your information from hundreds of contracts',
    },
    contractScanningDescription: {
      defaultMessage:
        'Leverage valuable metadata from documents for faster, consolidated intelligence.',
    },
    contractScanningBullet1: {
      defaultMessage:
        // eslint-disable-next-line max-len
        'Easily extract contract metadata–data categories, processing purposes, data sub processors–to get to the base truth',
    },
    contractScanningBullet2: {
      defaultMessage: 'Close intelligence gaps with efficient visibility',
    },
    contractScanningBullet3: {
      defaultMessage:
        'Speed up privacy operations by putting valuable information at your fingertips',
    },
    contractScanningPrimaryLinkText: {
      defaultMessage: 'See how it works',
    },
    privacyCenterTitle: {
      defaultMessage: 'When your privacy@ email does not scale',
    },
    privacyCenterDescription: {
      defaultMessage:
        'The most user-friendly, scalable, and secure way to handle privacy requests.',
    },
    privacyCenterBullet1: {
      defaultMessage:
        'Give your users buttons to self-serve access, deletion, and other DSRs through an intuitive interface',
    },
    privacyCenterBullet2: {
      defaultMessage:
        "Customize to your company's look and feel. Localize to your users’ language",
    },
    privacyCenterBullet3: {
      defaultMessage:
        "The industry's most secure and scalable privacy center–from authentication to user communications",
    },
    privacyCenterPrimaryLinkText: {
      defaultMessage: 'Try it today',
    },
    prefStoreTitle: {
      defaultMessage:
        'User consent honored consistently throughout your organization',
    },
    prefStoreDescription: {
      defaultMessage:
        '{productName} syncs user consent across any device, with a singular log of all user preference events.',
    },
    prefStoreBullet1: {
      defaultMessage:
        'Get and set consent from any frontend interface or backend system',
    },
    prefStoreBullet2: {
      defaultMessage:
        'Comply with CPRA by persisting opt-outs for known users across sessions and devices',
    },
    prefStoreBullet3: {
      defaultMessage:
        'High availability infra that stores a central, audit-friendly Record of Consent',
    },
    prefStorePrimaryLinkText: {
      defaultMessage: 'See a demo today',
    },
    nativeAppTitle: {
      defaultMessage: 'User consent honored across all your applications',
    },
    nativeAppDescription: {
      defaultMessage:
        'Collect and honor user consent across all your native applications',
    },
    nativeAppBullet1: {
      defaultMessage: 'Quick to set up with our out-of-the-box Consent UIs',
    },
    nativeAppBullet2: {
      defaultMessage:
        'Comply with CPRA by persisting opt-outs for known users across sessions and devices',
    },
    nativeAppBullet3: {
      defaultMessage:
        'Easily integrate with your existing mobile app infrastructure',
    },
    nativeAppLinkText: {
      defaultMessage: 'Talk to us',
    },
  },
);
/* eslint-enable max-lines */
