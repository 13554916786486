import styled from 'styled-components';

import { InputNumber, InputNumberProps } from './InputNumber';

export interface InlineInputNumberWrapperProps extends InputNumberProps {
  /** whether to display outline */
  showOutline?: boolean;
}
export const StyledInputNumber = styled(
  InputNumber,
)<InlineInputNumberWrapperProps>`
  background-color: transparent;
  ${({ theme, showOutline }) =>
    showOutline
      ? `border: 1px solid ${theme.colors.gray3}`
      : 'border: 1px solid transparent'};
  transition: border-color 0.3s ease-in-out;
  font-size: 14px;

  &:hover {
    border: ${({ theme }) => `1px solid ${theme.colors.gray3}`};
    background: ${({ theme }) => theme.colors.white};
  }

  &:disabled {
    background: ${({ theme }) => theme.colors.white};
  }
`;
