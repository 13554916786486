import { FeatureSet, ProductArea } from '@main/access-control-types';
import { FixedColorPalette } from '@main/theme';

export const PRODUCT_AREA_COLORS: Record<ProductArea, keyof FixedColorPalette> =
  {
    [ProductArea.Consent]: 'red1',
    [ProductArea.DataMapping]: 'yellow1',
    [ProductArea.DsrAutomation]: 'blue1',
    [ProductArea.PrivacyCenter]: 'mint1',
    [ProductArea.Assessments]: 'purple1',
    [ProductArea.Administration]: 'orange1',
  };

export const PRODUCT_AREA_FEATURE_SET_MAP: Record<
  ProductArea,
  (keyof FeatureSet)[]
> = {
  [ProductArea.Consent]: ['webConsent'],
  [ProductArea.DataMapping]: ['structuredDataDiscovery', 'siloDiscovery'],
  [ProductArea.DsrAutomation]: ['dsrAutomation'],
  [ProductArea.PrivacyCenter]: ['privacyCenter'],
  [ProductArea.Assessments]: ['assessments'],
  // no feature set for this, always allowed
  [ProductArea.Administration]: [],
};
