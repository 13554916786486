import * as TogglePrimitive from '@radix-ui/react-toggle';
import * as ToolbarPrimitive from '@radix-ui/react-toolbar';
import styled, { css } from 'styled-components';

/**
 * TOOLBAR STYLES
 */
const itemStyles = css`
  all: unset;
  flex: 0 0 auto;
  height: 25px;
  padding: 0 5px;
  border-radius: 4px;
  display: inline-flex;
  font-size: 13px;
  line-height: 1;
  align-items: center;
  justify-content: center;
  margin-left: 2px;
`;

const itemHoverStyles = css`
  transition:
    color 0.1s ease-in-out,
    background-color 0.1s ease-in-out;

  &:first-child {
    margin-left: 0;
  }

  &:hover {
    background-color: ${({ theme }) => theme.colors.gray3};
    color: ${({ theme }) => theme.colors.black};
  }

  &:focus {
    position: relative;
    box-shadow: 0 0 0 2px rgb(0 0 0 / 20%);
  }
`;

export const StyledToolbar = styled(ToolbarPrimitive.Root)`
  display: flex;
  padding: 7px 10px;
  align-items: center;
  border-bottom: solid 1px ${({ theme }) => theme.colors.gray3};
  background-color: rgba(255, 255, 255, 0.98);
  // must have z-index to be sticky, but needs to be below z-index of react selects
  z-index: 1;
  position: sticky;
  top: 0;
  flex-wrap: wrap;
`;

export const StyledButton = styled(ToolbarPrimitive.Button)`
  ${itemStyles};
  ${itemHoverStyles};
  padding-left: 10px;
  padding-right: 10px;
  background-color: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.transcendNavy2};
`;

export const StyledSeparator = styled(ToolbarPrimitive.Separator)`
  width: 1px;
  background-color: ${({ theme }) => theme.colors.gray3};
  margin: 0 10px;
  height: 30px;
`;

export const StyledToggleGroup = styled(ToolbarPrimitive.ToggleGroup)`
  display: inline-flex;
  border-radius: 4px;
`;

export const StyledToggle = styled(TogglePrimitive.Root)`
  ${itemStyles};
  ${itemHoverStyles}
  box-shadow: 0;
  background-color: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.transcendNavy2};

  &[data-state='on'] {
    background-color: ${({ theme }) => theme.colors.gray3};
    color: ${({ theme }) => theme.colors.black};
  }
`;
