import { makeEnum } from '@transcend-io/type-utils';

/**
 * The set of valid activity codes related to the audit trail of a request.
 */
export const ActivityCode = makeEnum({
  // RequestDataSilo
  RequestDataSiloError: 'REQUEST_DATA_SILO_ERROR',
  RequestDataSiloActionRequired: 'REQUEST_DATA_SILO_ACTION_REQUIRED',
  RequestDataSiloWaiting: 'REQUEST_DATA_SILO_WAITING',
  RequestDataSiloQueued: 'REQUEST_DATA_SILO_QUEUED',
  RequestDataSiloRemoteProcessing: 'REQUEST_DATA_SILO_REMOTE_PROCESSING',
  RequestDataSiloResolved: 'REQUEST_DATA_SILO_RESOLVED',
  RequestDataSiloSkipped: 'REQUEST_DATA_SILO_SKIPPED',
  RequestDataSiloSkippedDueToException:
    'REQUEST_DATA_SILO_SKIPPED_DUE_TO_EXCEPTION',
  // RequestFile
  RequestFileArchived: 'REQUEST_FILE_ARCHIVED',
  RequestFileEncryptedModified: 'REQUEST_FILE_ENCRYPTED_MODIFIED',
  RequestFileEncryptedCopy: 'REQUEST_FILE_ENCRYPTED_COPY',
  RequestFileQueued: 'REQUEST_FILE_QUEUED',
  RequestFileRemoteProcessing: 'REQUEST_FILE_REMOTE_PROCESSING',
  RequestFileWaiting: 'REQUEST_FILE_WAITING',
  RequestFileError: 'REQUEST_FILE_ERROR',
  RequestFileResolved: 'REQUEST_FILE_RESOLVED',
  RequestFileSkipped: 'REQUEST_FILE_SKIPPED',
  RequestFilePolling: 'REQUEST_FILE_POLLING',
  RequestFileActionRequired: 'REQUEST_FILE_ACTION_REQUIRED',
  // ProfileDataPoint
  ProfileDataPointQueued: 'PROFILE_DATA_POINT_QUEUED',
  ProfileDataPointActionRequired: 'PROFILE_DATA_POINT_ACTION_REQUIRED',
  ProfileDataPointRemoteProcessing: 'PROFILE_DATA_POINT_REMOTE_PROCESSING',
  ProfileDataPointWaiting: 'PROFILE_DATA_POINT_WAITING',
  ProfileDataPointWaitingOnDeletionDependencies:
    'PROFILE_DATA_POINT_WAITING_ON_DELETION_DEPENDENCIES',
  ProfileDataPointWaitingOnOtherRequests:
    'PROFILE_DATA_POINT_WAITING_ON_OTHER_REQUESTS',
  ProfileDataPointError: 'PROFILE_DATA_POINT_ERROR',
  ProfileDataPointResolved: 'PROFILE_DATA_POINT_RESOLVED',
  ProfileDataPointPolling: 'PROFILE_DATA_POINT_POLLING',
  ProfileDataPointFinished: 'PROFILE_DATA_POINT_FINISHED',
  ProfileDataPointSkipped: 'PROFILE_DATA_POINT_SKIPPED',
  ProfileDataPointManual: 'PROFILE_DATA_POINT_MANUAL',
  ProfileDataPointSkippedDueToException:
    'PROFILE_DATA_POINT_SKIPPED_DUE_TO_EXCEPTION',
  // RequestEnricher
  RequestEnricherCreated: 'REQUEST_ENRICHER_CREATED',
  RequestEnricherPolling: 'REQUEST_ENRICHER_POLLING',
  RequestEnricherQueued: 'REQUEST_ENRICHER_QUEUED',
  RequestEnricherActionRequired: 'REQUEST_ENRICHER_ACTION_REQUIRED',
  RequestEnricherWaiting: 'REQUEST_ENRICHER_WAITING',
  RequestEnricherWaitingOnDependencies:
    'REQUEST_ENRICHER_WAITING_ON_DEPENDENCIES',
  RequestEnricherError: 'REQUEST_ENRICHER_ERROR',
  RequestEnricherResolved: 'REQUEST_ENRICHER_RESOLVED',
  RequestEnricherSkipped: 'REQUEST_ENRICHER_SKIPPED',
  RequestEnricherRemoteProcessing: 'REQUEST_ENRICHER_REMOTE_PROCESSING',

  // RequestIdentifier
  RequestIdentifierCreated: 'REQUEST_IDENTIFIER_CREATED',
  RequestIdentifierVerified: 'REQUEST_IDENTIFIER_VERIFIED',
  // this is an old ambiguous state that is either
  // RequestIdentifierExpired or RequestIdentifierExplicitlyInvalidated
  RequestIdentifierInvalidated: 'REQUEST_IDENTIFIER_INVALIDATED',
  RequestIdentifierExpired: 'REQUEST_IDENTIFIER_EXPIRED',
  RequestIdentifierExplicitlyInvalidated:
    'REQUEST_IDENTIFIER_EXPLICITLY_INVALIDATED',
  // RequestEnricherRequestIdentifier
  RequestEnricherResolvedRequestIdentifier:
    'REQUEST_ENRICHER_RESOLVED_REQUEST_IDENTIFIER',
  // Communication
  CommunicationMessageSent: 'COMMUNICATION_MESSAGE_SENT',
  CommunicationMessageReceived: 'COMMUNICATION_MESSAGE_RECEIVED',
  // LookupProcess
  LookupProcessQueued: 'LOOKUP_PROCESS_QUEUED',
  LookupProcessActionRequired: 'LOOKUP_PROCESS_ACTION_REQUIRED',
  LookupProcessWaiting: 'LOOKUP_PROCESS_WAITING',
  LookupProcessError: 'LOOKUP_PROCESS_ERROR',
  LookupProcessResolved: 'LOOKUP_PROCESS_RESOLVED',
  LookupProcessSkipped: 'LOOKUP_PROCESS_SKIPPED',
  LookupProcessInactive: 'LOOKUP_PROCESS_INACTIVE',
  LookupProcessRemoteProcessing: 'LOOKUP_PROCESS_REMOTE_PROCESSING',
  // Profile
  ProfileCreated: 'PROFILE_CREATED',
  ProfilesDestroyed: 'PROFILES_DESTROYED',
  // Request
  RequestRequestMade: 'REQUEST_REQUEST_MADE',
  RequestFailedVerification: 'REQUEST_FAILED_VERIFICATION',
  RequestEnriching: 'REQUEST_ENRICHING',
  RequestOnHold: 'REQUEST_ON_HOLD',
  RequestWaiting: 'REQUEST_WAITING',
  RequestCompiling: 'REQUEST_COMPILING',
  RequestApproving: 'REQUEST_APPROVING',
  RequestDelayed: 'REQUEST_DELAYED',
  RequestDownloadable: 'REQUEST_DOWNLOADABLE',
  RequestViewCategories: 'REQUEST_VIEW_CATEGORIES',
  RequestCompleted: 'REQUEST_COMPLETED',
  RequestCanceled: 'REQUEST_CANCELED',
  RequestSecondary: 'REQUEST_SECONDARY',
  RequestSecondaryApproving: 'REQUEST_SECONDARY_APPROVING',
  RequestSecondaryCompleted: 'REQUEST_SECONDARY_COMPLETED',
  RequestRevoked: 'REQUEST_REVOKED',
  RequestArchived: 'REQUEST_ARCHIVED',
  ReportOpened: 'REPORT_OPENED',
  ReportDownloaded: 'REPORT_DOWNLOADED',
});

/**
 * Overload the type
 */
export type ActivityCode = (typeof ActivityCode)[keyof typeof ActivityCode];

/**
 * Fields activities can be ordered by
 */
export enum ActivityOrderField {
  /** Time the activity occurred */
  CreatedAt = 'createdAt',
  /** The time the activity was updated */
  UpdatedAt = 'updatedAt',
}
