import * as t from 'io-ts';

import { dbModelId } from '@main/schema-utils';

import { AuditEventCode } from '../schema/enums';
import { mkAuditEventCodec } from '../types';

export const AuditEventMessage = mkAuditEventCodec(
  AuditEventCode.Message,
  t.intersection([
    t.type({
      /** ID of the message */
      messageId: dbModelId('message'),
    }),
    t.partial({
      /** ID of the dataCategory */
      dataCategoryId: dbModelId('dataCategory'),
      /** ID of the dataCollection */
      dataCollectionId: dbModelId('dataCollection'),
      /** ID of the dataPoint */
      dataPointId: dbModelId('dataPoint'),
      /** ID of the dataSilo */
      dataSiloId: dbModelId('dataSilo'),
      /** ID of the privacyCenter */
      privacyCenterId: dbModelId('privacyCenter'),
      /** ID of the dataApplication */
      dataApplicationId: dbModelId('dataApplication'),
      /** ID of the identifier */
      identifierId: dbModelId('identifier'),
      /** ID of the subject */
      subjectId: dbModelId('subject'),
      /** ID of the policy */
      policyId: dbModelId('policy'),
    }),
  ]),
);

/** Override types. */
export type AuditEventMessage = t.TypeOf<typeof AuditEventMessage>;
