/* eslint-disable max-lines */
import { CodePackageType } from '@transcend-io/privacy-types';

import { TeamPreview, UserPreview } from '@main/access-control-types';
import { Sdk, SelectableCatalog } from '@main/datamap-types';
import { mkInput, mkOrder, mkType, SchemaToType } from '@main/schema-utils';

import { CodePackagePreview } from './codePackage';
import { SoftwareDevelopmentKitOrderField } from './enums';
import { RepositoryPreview } from './repository';

export const SoftwareDevelopmentKitPreview = mkType({
  name: 'SoftwareDevelopmentKitPreview',
  comment: 'A preview of a software development kit instance',
  fields: {
    id: {
      comment: 'The id of the software development kit',
      modelName: 'softwareDevelopmentKit',
      type: 'id',
    },
    name: {
      comment: 'The name of the software development kit',
      type: 'string',
    },
    description: {
      comment: 'The description of the software development kit',
      type: 'string',
    },
    codePackageType: {
      comment: 'The type of dependency',
      type: { CodePackageType },
    },
    repositoryUrl: {
      comment: 'The repository URL of the software development kit',
      type: 'string',
      optional: true,
    },
    registryUrl: {
      comment: 'The registry URL of the software development kit',
      type: 'string',
    },
    documentationLinks: {
      comment: 'The documentation links of the software development kit',
      type: 'string',
      list: true,
    },
    createdAt: {
      comment: 'The date the software development kit was created',
      type: 'Date',
    },
    updatedAt: {
      comment: 'The date the software development kit was last updated',
      type: 'Date',
    },
  },
});

/** Override type */
export type SoftwareDevelopmentKitPreview = SchemaToType<
  typeof SoftwareDevelopmentKitPreview
>;

export const SoftwareDevelopmentKit = mkType({
  name: 'SoftwareDevelopmentKit',
  comment:
    'A software development kit that contains code submitted to a particular repository',
  fields: {
    ...SoftwareDevelopmentKitPreview.fields,
    sdk: {
      comment:
        'The software development kit metadata that Transcend holds on this tool',
      type: Sdk,
      optional: true,
    },
    catalog: {
      comment:
        'The integration catalog metadata that Transcend holds on this tool',
      type: SelectableCatalog,
      optional: true,
    },
    guessedCatalog: {
      comment:
        'The guessed integration catalog metadata that Transcend holds on this tool',
      type: SelectableCatalog,
      optional: true,
    },
    repositories: {
      comment: 'The repositories where this software development kit is used',
      type: () => RepositoryPreview,
      list: true,
    },
    codePackages: {
      comment: 'The code packages where this software development kit is used',
      type: () => CodePackagePreview,
      list: true,
    },
    owners: {
      comment: 'The users that are in charge of this software development kit',
      type: () => UserPreview,
      list: true,
    },
    teams: {
      comment: 'The teams that are in charge of this software development kit',
      type: () => TeamPreview,
      list: true,
    },
  },
});

/** Override type */
export type SoftwareDevelopmentKit = SchemaToType<
  typeof SoftwareDevelopmentKit
>;

export const SoftwareDevelopmentKitForPackage = mkType({
  name: 'SoftwareDevelopmentKitForPackage',
  comment:
    'An instance of a software development kit that is used in a code package',
  fields: {
    softwareDevelopmentKit: {
      comment: 'The software development kit instance',
      type: SoftwareDevelopmentKitPreview,
    },
    lastSeenAt: {
      comment: 'The date the software development kit was last reported on',
      type: 'Date',
      optional: true,
    },
    version: {
      comment: 'The version of the SDK in the code package',
      type: 'string',
      optional: true,
    },
  },
});

/** Override type */
export type SoftwareDevelopmentKitForPackage = SchemaToType<
  typeof SoftwareDevelopmentKitForPackage
>;

export const SoftwareDevelopmentKitFiltersInput = mkInput({
  name: 'SoftwareDevelopmentKitFiltersInput',
  comment: 'Inputs for filtering a list of software development kits',
  fields: {
    ids: {
      comment: 'The ids of the software development kits',
      type: 'id',
      modelName: 'softwareDevelopmentKit',
      optional: true,
      list: true,
    },
    ownerIds: {
      comment:
        'The ids of the users that created the software development kits',
      type: 'id',
      modelName: 'user',
      optional: true,
      list: true,
    },
    teamIds: {
      comment:
        'The ids of the teams that created the software development kits',
      type: 'id',
      modelName: 'team',
      optional: true,
      list: true,
    },
    repositoryIds: {
      comment:
        'The ids of the repositories that the software development kits are found in',
      type: 'id',
      modelName: 'repository',
      optional: true,
      list: true,
    },
    codePackageIds: {
      comment:
        'The ids of the code packages that the software development kits are found in',
      type: 'id',
      modelName: 'codePackage',
      optional: true,
      list: true,
    },
    confirmedIntegrationNames: {
      comment:
        'The names of the confirmed integrations that the software development kits relate to',
      type: 'string',
      optional: true,
      list: true,
    },
    guessedIntegrationNames: {
      comment:
        'The names of the guessed integrations that the software development kits relate to',
      type: 'string',
      optional: true,
      list: true,
    },
    text: {
      comment: 'Filter by text (name, description, repositoryUrl)',
      optional: true,
      type: 'string',
    },
    name: {
      comment: 'Filter by software development kit name',
      optional: true,
      type: 'string',
    },
    names: {
      comment: 'Filter on exact software development kit names',
      optional: true,
      type: 'string',
      list: true,
    },
    linkedToConsentSdk: {
      comment: 'Filter by whether or not the entry is linked to a consent SDK',
      optional: true,
      type: 'boolean',
    },
    linkedToConsentApplication: {
      comment:
        'Filter by whether or not the entry is part of a code package that is a consent application',
      optional: true,
      type: 'boolean',
    },
    codePackageTypes: {
      comment: 'The types of code package to include',
      type: { CodePackageType },
      optional: true,
      list: true,
    },
  },
});

/** Override type */
export type SoftwareDevelopmentKitFiltersInput = SchemaToType<
  typeof SoftwareDevelopmentKitFiltersInput
>;

export const DeleteSoftwareDevelopmentKitsInput = mkInput({
  name: 'DeleteSoftwareDevelopmentKitsInput',
  comment: 'Input for deleting a set of software development kits',
  fields: {
    ids: {
      comment: 'The id of the software development kits to delete',
      type: 'id',
      modelName: 'softwareDevelopmentKit',
      list: true,
    },
  },
});

/** Override type */
export type DeleteSoftwareDevelopmentKitsInput = SchemaToType<
  typeof DeleteSoftwareDevelopmentKitsInput
>;

export const CreateSoftwareDevelopmentKitInput = mkInput({
  name: 'CreateSoftwareDevelopmentKitInput',
  comment: 'Input for creating a software development kit',
  fields: {
    name: {
      comment: 'The name of the software development kit',
      type: 'string',
    },
    description: {
      comment: 'The description of the software development kit',
      type: 'string',
      optional: true,
    },
    codePackageType: {
      comment: 'The type of dependency',
      type: { CodePackageType },
    },
    repositoryUrl: {
      comment: 'The URL of the software development kit',
      type: 'string',
      optional: true,
    },
    documentationLinks: {
      comment: 'The documentation links of the software development kit',
      type: 'string',
      list: true,
      optional: true,
    },
    codePackageIds: {
      comment:
        'The IDs of the code packages that the software development kit is used on',
      modelName: 'codePackage',
      type: 'id',
      list: true,
      optional: true,
    },
    catalogIntegrationName: {
      comment:
        'The name of the integration in the catalog that the software development kit relates to',
      type: 'string',
      optional: true,
    },
    codePackageNames: {
      comment:
        'The names of the code packages that the software development kit is used on',
      type: 'string',
      list: true,
      optional: true,
    },
    ownerIds: {
      comment: 'The IDs of users that manage the software development kit',
      modelName: 'user',
      type: 'id',
      list: true,
      optional: true,
    },
    ownerEmails: {
      comment: 'The emails of users that manage the software development kit',
      type: 'string',
      list: true,
      optional: true,
    },
    teamIds: {
      comment: 'The IDs of teams that manage the software development kit',
      modelName: 'team',
      type: 'id',
      list: true,
      optional: true,
    },
    teamNames: {
      comment: 'The names of teams that manage the software development kit',
      type: 'string',
      list: true,
      optional: true,
    },
  },
});

/** Override type */
export type CreateSoftwareDevelopmentKitInput = SchemaToType<
  typeof CreateSoftwareDevelopmentKitInput
>;

export const UpdateSoftwareDevelopmentKitInput = mkInput({
  name: 'UpdateSoftwareDevelopmentKitInput',
  comment: 'Input for updating a software development kit',
  fields: {
    id: {
      comment: 'The id of the software development kit',
      modelName: 'softwareDevelopmentKit',
      type: 'id',
    },
    name: {
      comment: 'The name of the software development kit',
      type: 'string',
      optional: true,
    },
    description: CreateSoftwareDevelopmentKitInput.fields.description,
    repositoryUrl: CreateSoftwareDevelopmentKitInput.fields.repositoryUrl,
    catalogIntegrationName:
      CreateSoftwareDevelopmentKitInput.fields.catalogIntegrationName,
    documentationLinks:
      CreateSoftwareDevelopmentKitInput.fields.documentationLinks,
    codePackageIds: CreateSoftwareDevelopmentKitInput.fields.codePackageIds,
    codePackageNames: CreateSoftwareDevelopmentKitInput.fields.codePackageNames,
    ownerIds: CreateSoftwareDevelopmentKitInput.fields.ownerIds,
    ownerEmails: CreateSoftwareDevelopmentKitInput.fields.ownerEmails,
    teamIds: CreateSoftwareDevelopmentKitInput.fields.teamIds,
    teamNames: CreateSoftwareDevelopmentKitInput.fields.teamNames,
  },
});

/** Override type */
export type UpdateSoftwareDevelopmentKitInput = SchemaToType<
  typeof UpdateSoftwareDevelopmentKitInput
>;

export const UpdateSoftwareDevelopmentKitsInput = mkInput({
  name: 'UpdateSoftwareDevelopmentKitsInput',
  comment: 'Input for bulk updating one or more software development kits',
  fields: {
    softwareDevelopmentKits: {
      comment:
        'List of software development kits with the properties that should be updated for each',
      type: UpdateSoftwareDevelopmentKitInput,
      list: true,
    },
  },
});

/** Override type */
export type UpdateSoftwareDevelopmentKitsInput = SchemaToType<
  typeof UpdateSoftwareDevelopmentKitsInput
>;

/** Order for a software development kits query */
export const SoftwareDevelopmentKitOrder = mkOrder(
  SoftwareDevelopmentKit.name,
  SoftwareDevelopmentKitOrderField,
);
/* eslint-enable max-lines */
