import { FilterConfigItem } from '@main/ad-core-components';
import {
  SelectCodePackages,
  SelectedCodePackage,
} from '@main/admin-dashboard/src/CodeScanning/components/SelectCodePackages';
import { endpoints } from '@main/code-scanning-types';
import { buildUseLazyQuery } from '@main/core-ui';
import { ID } from '@main/schema-utils';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { MessageDescriptor } from 'react-intl';

import { filterManagerHookMessages } from './messages';

/**
 * Filters definition with property for codePackage values specified
 */
type CodePackageEnrichedFilters = Record<string, unknown> &
  Record<
    /**
     * CodePackage values that are currently filtering the table
     */
    string,
    SelectedCodePackage[] | undefined
  >;

const useLazyCodePackages = buildUseLazyQuery(endpoints.codePackages);

export const useCodePackageFilters = <
  T extends Record<string, unknown>,
  E extends CodePackageEnrichedFilters,
>({
  codePackageIdsFilterKey = 'codePackageIds',
  filters,
  setFilters,
  label,
  enrichedCodePackageKey = 'codePackages',
}: {
  /** The property name of the filter for codePackage ids */
  codePackageIdsFilterKey?: keyof T;
  /** The currently applied filters */
  filters?: T;
  /** Callback when the filters are changed */
  setFilters: (filters: T) => void;
  /** Label for the filter menu */
  label: string | MessageDescriptor;
  /** The key to use if more than one useCodePackageFilters is used in a FilterManager */
  enrichedCodePackageKey?: keyof E;
}): {
  /**  The codePackage keys enabled on this table */
  selectedCodePackages: SelectedCodePackage[];
  /** The filter configuration to be passed to the filter manager */
  codePackageFiltersConfig: FilterConfigItem<E>;
  /** Callback for when the filter is cleared in the filter manager */
  clearCodePackageFilters: (key: Extract<keyof E, string>) => void;
} => {
  const getSelectedCodePackages = useLazyCodePackages();
  const [selectedCodePackages, setSelectedCodePackages] = useState<
    SelectedCodePackage[]
  >([]);
  const [fetchingCodePackages, setFetchingCodePackages] = useState(false);

  // Populate initial complex filter values
  useEffect(() => {
    if (filters) {
      const codePackageIds = (filters[codePackageIdsFilterKey] ??
        []) as ID<'codePackage'>[];

      const filtersAndEnrichedFiltersMatch =
        codePackageIds.sort().join() ===
        selectedCodePackages
          .map(({ id }) => id)
          .sort()
          .join();

      if (codePackageIds.length === 0 && selectedCodePackages.length > 0) {
        setSelectedCodePackages([]);
      } else if (!filtersAndEnrichedFiltersMatch && !fetchingCodePackages) {
        // Prevent over-firing this setter while data is still being fetched
        setFetchingCodePackages(true);
        getSelectedCodePackages({ filterBy: { ids: codePackageIds } })
          .then(({ data }) => {
            setSelectedCodePackages(data.nodes);
          })
          .finally(() => {
            setFetchingCodePackages(false);
          });
      }
    }
  }, [
    filters,
    fetchingCodePackages,
    getSelectedCodePackages,
    selectedCodePackages.length,
    codePackageIdsFilterKey,
    selectedCodePackages,
  ]);

  const codePackageFiltersConfig = useMemo(
    () =>
      ({
        filterKey: enrichedCodePackageKey,
        label,
        pillOptions: {
          label: ({
            filterValues: { [enrichedCodePackageKey]: codePackages = [] },
            index = 0,
          }) => codePackages[index]?.name,
        },
        filter: (
          <SelectCodePackages
            isMulti
            placeholderDescriptor={
              filterManagerHookMessages.codePackagesPlaceholder
            }
            menuPosition="absolute"
            value={selectedCodePackages}
            showOutline={false}
            onChange={(codePackages) => {
              setSelectedCodePackages([...codePackages]);
              setFilters({
                ...filters,
                [codePackageIdsFilterKey]: codePackages.map(({ id }) => id),
              } as T);
            }}
          />
        ),
      }) as FilterConfigItem<CodePackageEnrichedFilters>,
    [
      enrichedCodePackageKey,
      label,
      selectedCodePackages,
      filters,
      codePackageIdsFilterKey,
    ],
  );

  const clearCodePackageFilters = useCallback(
    (key) => {
      if (key === enrichedCodePackageKey) {
        setFilters({
          ...filters,
          [codePackageIdsFilterKey]: [],
        } as T);
        setSelectedCodePackages([]);
      }
    },
    [enrichedCodePackageKey, filters, codePackageIdsFilterKey],
  );

  return {
    selectedCodePackages,
    codePackageFiltersConfig,
    clearCodePackageFilters,
  };
};
