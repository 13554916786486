import { IWithStyle } from '@main/core-ui';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { StyledLink } from '../wrappers';

/** Props for the `FilteredTableLink` component */
interface FilteredTableLinkProps extends IWithStyle {
  /** The filters to add as query params */
  filters: object;
  /** The base route to link to */
  route: string;
  /** Message to format */
  formattedMessage?: FormattedMessage['props'];
}

export const FilteredTableLink: React.FC<FilteredTableLinkProps> = ({
  filters,
  route,
  formattedMessage,
  style,
  children,
}) => {
  const searchParams = new URLSearchParams();
  searchParams.set('filters', JSON.stringify(filters));
  return (
    <StyledLink
      style={style}
      to={{
        pathname: `${route}?${searchParams.toString()}`,
      }}
    >
      {formattedMessage ? <FormattedMessage {...formattedMessage} /> : children}
    </StyledLink>
  );
};
