import { defineMessages } from '@transcend-io/internationalization';

export const modalMessages = defineMessages('ad-core-components.Modal.modal', {
  successHeaderDefault: {
    defaultMessage: 'Success!',
  },
  startOver: {
    defaultMessage: 'Start Over',
  },
});
