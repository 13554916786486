import { Button, Modal } from '@main/ad-core-components';
import { Icon, StyleUtils } from '@main/core-ui';
import { DefinedMessage } from '@main/internationalization';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';

import { bulkUpdateModalMessages } from './messages';

export interface BulkUpdateModalProps {
  /** Message to override the default modal header */
  modalHeader?: DefinedMessage;
  /** Whether to display the button as in a loading state */
  buttonLoading?: boolean;
}

export const BulkUpdateModal: React.FC<BulkUpdateModalProps> = ({
  modalHeader,
  buttonLoading,
  children,
}) => {
  const { formatMessage } = useIntl();

  const [visible, setVisible] = useState(false);

  return (
    <>
      <Button
        variant="secondary"
        loading={buttonLoading}
        onClick={() => setVisible(true)}
        icon={<Icon type="pencil" />}
      >
        {formatMessage(bulkUpdateModalMessages.modalButton)}
      </Button>
      <Modal
        show={visible}
        onHide={() => setVisible(false)}
        header={modalHeader || bulkUpdateModalMessages.modalHeader}
      >
        <div style={{ marginBottom: StyleUtils.Spacing.xl }}>
          <p>{formatMessage(bulkUpdateModalMessages.modalSubHeader)}</p>
        </div>

        {children}
      </Modal>
    </>
  );
};
