import { Icon, IconType } from '@main/core-ui';
import React from 'react';
import { useTheme } from 'styled-components';

import { ViewToggleButton, ViewToggleContainer } from './wrappers';

/**
 * View switcher
 */
export function ViewSwitcher<T extends string>({
  currentView,
  buttons,
  setView,
}: {
  /** current view */
  currentView: T;
  /** view setter  */
  setView: (view: T) => void;
  /** buttons */
  buttons: {
    /** view */
    view: T;
    /** icon */
    icon: IconType;
  }[];
}): JSX.Element {
  const theme = useTheme();

  return (
    <ViewToggleContainer>
      {buttons.map(({ view, icon }) => {
        const isActive = currentView === view;
        return (
          <ViewToggleButton
            key={view}
            active={isActive}
            onClick={() => setView(view)}
          >
            <Icon
              type={icon}
              color={isActive ? theme.colors.transcend2 : undefined}
            />
          </ViewToggleButton>
        );
      })}
    </ViewToggleContainer>
  );
}
