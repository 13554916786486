import { ApolloProvider } from '@main/core-ui';
import { ImmutableUrl } from '@main/immutable-url';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { createApolloClient } from '../../graphql';
import { selectSelectedBackendOrDefault } from './selectors';

/**
 * Provider for connecting to backend GraphQL API.
 * Ability to switch between different backends in various regions.
 */
export const BackendGraphQLProvider: React.FC<{}> = ({ children }) => {
  const backendUrl = useSelector(selectSelectedBackendOrDefault);
  const adminDashboardApolloClient = useMemo(
    () => createApolloClient(new ImmutableUrl(backendUrl)),
    [backendUrl],
  );

  return (
    <ApolloProvider client={adminDashboardApolloClient}>
      {children}
    </ApolloProvider>
  );
};
