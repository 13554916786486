import { FilterConfigItem } from '@main/ad-core-components';
import {
  SelectedPathfinderPolicy,
  SelectPathfinderPolicies,
} from '@main/admin-dashboard/src/Pathfinder/components/SelectPathfinderPolicies';
import { buildUseLazyQuery } from '@main/core-ui';
import { endpoints } from '@main/prompt-types';
import { ID } from '@main/schema-utils';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { MessageDescriptor } from 'react-intl';

import { filterManagerHookMessages } from './messages';

/**
 * Filters definition with property for pathfinderPolicy values specified
 */
type PathfinderPolicyEnrichedFilters = Record<string, unknown> &
  Record<
    /**
     * PathfinderPolicy values that are currently filtering the table
     */
    string,
    SelectedPathfinderPolicy[] | undefined
  >;

const useLazyPathfinderPolicies = buildUseLazyQuery(
  endpoints.pathfinderPolicies,
);

export const usePathfinderPolicyFilters = <
  T extends Record<string, unknown>,
  E extends PathfinderPolicyEnrichedFilters,
>({
  pathfinderPolicyIdsFilterKey = 'pathfinderPolicyIds',
  filters,
  setFilters,
  label,
  enrichedPathfinderPolicyKey = 'pathfinderPolicies',
}: {
  /** The property name of the filter for pathfinderPolicy ids */
  pathfinderPolicyIdsFilterKey?: keyof T;
  /** The currently applied filters */
  filters?: T;
  /** Callback when the filters are changed */
  setFilters: (filters: T) => void;
  /** Label for the filter menu */
  label: string | MessageDescriptor;
  /** The key to use if more than one usePathfinderPolicyFilters is used in a FilterManager */
  enrichedPathfinderPolicyKey?: keyof E;
}): {
  /**  The pathfinderPolicy keys enabled on this table */
  selectedPathfinderPolicies: SelectedPathfinderPolicy[];
  /** The filter configuration to be passed to the filter manager */
  pathfinderPolicyFiltersConfig: FilterConfigItem<E>;
  /** Callback for when the filter is cleared in the filter manager */
  clearPathfinderPolicyFilters: (key: Extract<keyof E, string>) => void;
} => {
  const getSelectedPathfinderPolicies = useLazyPathfinderPolicies();
  const [selectedPathfinderPolicies, setSelectedPathfinderPolicies] = useState<
    SelectedPathfinderPolicy[]
  >([]);
  const [fetchingPathfinderPolicies, setFetchingPathfinderPolicies] =
    useState(false);

  // Populate initial complex filter values
  useEffect(() => {
    if (filters) {
      const pathfinderPolicyIds = (filters[pathfinderPolicyIdsFilterKey] ??
        []) as ID<'pathfinderPolicy'>[];

      const filtersAndEnrichedFiltersMatch =
        pathfinderPolicyIds.sort().join() ===
        selectedPathfinderPolicies
          .map(({ id }) => id)
          .sort()
          .join();

      if (
        pathfinderPolicyIds.length === 0 &&
        selectedPathfinderPolicies.length > 0
      ) {
        setSelectedPathfinderPolicies([]);
      } else if (
        !filtersAndEnrichedFiltersMatch &&
        !fetchingPathfinderPolicies
      ) {
        // Prevent over-firing this setter while data is still being fetched
        setFetchingPathfinderPolicies(true);
        getSelectedPathfinderPolicies({
          filterBy: { ids: pathfinderPolicyIds },
        })
          .then(({ data }) => {
            setSelectedPathfinderPolicies(data.nodes);
          })
          .finally(() => {
            setFetchingPathfinderPolicies(false);
          });
      }
    }
  }, [
    filters,
    fetchingPathfinderPolicies,
    getSelectedPathfinderPolicies,
    selectedPathfinderPolicies.length,
    pathfinderPolicyIdsFilterKey,
    selectedPathfinderPolicies,
  ]);

  const pathfinderPolicyFiltersConfig = useMemo(
    () =>
      ({
        filterKey: enrichedPathfinderPolicyKey,
        label,
        pillOptions: {
          label: ({
            filterValues: {
              [enrichedPathfinderPolicyKey]: pathfinderPolicies = [],
            },
            index = 0,
          }) => {
            const pathfinderPolicy = pathfinderPolicies[index];
            return pathfinderPolicy?.name || '';
          },
        },
        filter: (
          <SelectPathfinderPolicies
            isMulti
            placeholderDescriptor={
              filterManagerHookMessages.applicationsPlaceholder
            }
            menuPosition="absolute"
            value={selectedPathfinderPolicies}
            showOutline={false}
            onChange={(pathfinderPolicies) => {
              setSelectedPathfinderPolicies([...pathfinderPolicies]);
              setFilters({
                ...filters,
                [pathfinderPolicyIdsFilterKey]: pathfinderPolicies.map(
                  ({ id }) => id,
                ),
              } as T);
            }}
          />
        ),
      }) as FilterConfigItem<PathfinderPolicyEnrichedFilters>,
    [
      enrichedPathfinderPolicyKey,
      label,
      selectedPathfinderPolicies,
      filters,
      pathfinderPolicyIdsFilterKey,
    ],
  );

  const clearPathfinderPolicyFilters = useCallback(
    (key) => {
      if (key === enrichedPathfinderPolicyKey) {
        setFilters({
          ...filters,
          [pathfinderPolicyIdsFilterKey]: [],
        } as T);
        setSelectedPathfinderPolicies([]);
      }
    },
    [enrichedPathfinderPolicyKey, filters, pathfinderPolicyIdsFilterKey],
  );

  return {
    selectedPathfinderPolicies,
    pathfinderPolicyFiltersConfig,
    clearPathfinderPolicyFilters,
  };
};
