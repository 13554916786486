import React from 'react';
import { animated, easings, useSpring } from 'react-spring';

import { RadarIconProps } from './types';
import { RadarIconContainer } from './wrappers';

export const RadarIcon: React.FC<RadarIconProps> = ({
  isAnimated,
  color = 'currentColor',
}) => {
  const baseProps = { r: 0.75, opacity: 1 };
  const radiationStyles = useSpring({
    delay: 500,
    config: {
      duration: 1750,
      easing: easings.linear,
    },
    loop: true,
    to: [{ r: 6.25, opacity: 0 }],
    from: baseProps,
  });

  return (
    <RadarIconContainer isAnimated={isAnimated}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1.1em"
        height="1.1em"
        fill="none"
        viewBox="0 0 14 14"
      >
        <mask
          id="mask0_1942_159184"
          style={{ maskType: 'alpha' }}
          width="14"
          height="14"
          x="0"
          y="0"
          maskUnits="userSpaceOnUse"
        >
          <circle cx="7" cy="7" r="6.75" fill="#c4c4c4" />
        </mask>
        <g>
          <animated.circle
            style={isAnimated ? radiationStyles : baseProps}
            cx="7"
            cy="7"
            stroke={color}
          />
          <circle cx="7" cy="7" r="6.25" stroke={color} opacity={0.25} />
          <circle cx="7" cy="7" r="4.25" stroke={color} opacity={0.5} />
          <circle cx="7" cy="7" r="2.25" stroke={color} opacity={0.75} />
          <circle cx="7" cy="7" r="0.75" fill={color} />
          <path mask="url(#mask0_1942_159184)" stroke={color} d="M7 7l5-5" />
        </g>
      </svg>
    </RadarIconContainer>
  );
};
