import { defineMessages } from '@transcend-io/internationalization';

export const codeScanningPageTitleMessages = defineMessages(
  'admin-dashboard.CodeScanning.codeScanningPageTitle',
  {
    codePackages: {
      defaultMessage: 'Packages',
    },
    repositories: {
      defaultMessage: 'Repositories',
    },
    pullRequests: {
      defaultMessage: 'Pull Requests',
    },
    softwareDevelopmentKits: {
      defaultMessage: 'SDKs',
    },
  },
);
