import * as t from 'io-ts';

import { dbModelId } from '@main/schema-utils';

import { AuditEventCode } from '../schema/enums';
import {
  mkAuditEventCodec,
  mkAuditEventJoinCodec,
  mkAuditJoinStateCodec,
} from '../types';

export const AuditEventDataCollection = mkAuditEventCodec(
  AuditEventCode.DataCollection,
  t.type({
    /** ID of the data collection */
    dataCollectionId: dbModelId('dataCollection'),
  }),
);

/** Override types. */
export type AuditEventDataCollection = t.TypeOf<
  typeof AuditEventDataCollection
>;

export const AuditEventDataCollectionDataApplication = mkAuditEventJoinCodec(
  AuditEventCode.DataCollectionDataApplication,
  mkAuditJoinStateCodec('dataApplication', t.type({ displayOrder: t.number })),
  t.type({
    /** ID of the data collection */
    dataCollectionId: dbModelId('dataCollection'),
    /** ID of the data application */
    dataApplicationId: dbModelId('dataApplication'),
  }),
);

/** Override types. */
export type AuditEventDataCollectionDataApplication = t.TypeOf<
  typeof AuditEventDataCollectionDataApplication
>;
