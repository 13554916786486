import { TeamPreview, UserPreview } from '@main/access-control-types';
import { mkInput, mkOrder, mkType, SchemaToType } from '@main/schema-utils';

import { RepositoryOrderField } from './enums';

export const RepositoryPreview = mkType({
  name: 'RepositoryPreview',
  comment: 'A preview of a code repository',
  fields: {
    id: {
      comment: 'The id of the repository',
      modelName: 'repository',
      type: 'id',
    },
    name: {
      comment: 'The name of the repository instance',
      type: 'string',
    },
    description: {
      comment: 'The description of the repository instance',
      type: 'string',
    },
    url: {
      comment: 'The URL of the repository service',
      type: 'string',
    },
    createdAt: {
      comment: 'The date the repository was created',
      type: 'Date',
    },
    updatedAt: {
      comment: 'The date the repository was last updated',
      type: 'Date',
    },
  },
});

/** Override type */
export type RepositoryPreview = SchemaToType<typeof RepositoryPreview>;

export const Repository = mkType({
  name: 'Repository',
  comment: 'A repository where code is stored',
  fields: {
    ...RepositoryPreview.fields,
    codePackageCount: {
      comment: 'The number of code packages in this repository',
      type: 'int',
    },
    softwareDevelopmentKitCount: {
      comment: 'The number of SDKs in this repository',
      type: 'int',
    },
    pullRequestCount: {
      comment:
        'The number of pull requests that are made against this repository',
      type: 'int',
    },
    promptRunCount: {
      comment: 'The number of prompt runs that this repository has ran',
      type: 'int',
    },
    owners: {
      comment:
        'The list of individual users who are responsible for managing this repository',
      list: true,
      type: UserPreview,
    },
    teams: {
      comment:
        'The list of teams who are responsible for managing this repository',
      list: true,
      type: TeamPreview,
    },
  },
});

/** Override type */
export type Repository = SchemaToType<typeof Repository>;

export const RepositoryFiltersInput = mkInput({
  name: 'RepositoryFiltersInput',
  comment: 'Inputs for filtering a list of repositories',
  fields: {
    ids: {
      comment: 'The ids of the repositories',
      type: 'id',
      modelName: 'repository',
      optional: true,
      list: true,
    },
    ownerIds: {
      comment: 'The ids of the owners that are mapped to this repository',
      type: 'id',
      modelName: 'user',
      optional: true,
      list: true,
    },
    teamIds: {
      comment: 'The ids of the teams that are mapped to this repository',
      type: 'id',
      modelName: 'team',
      optional: true,
      list: true,
    },
    softwareDevelopmentKitIds: {
      comment: 'The ids of the SDKs in this repository',
      type: 'id',
      modelName: 'softwareDevelopmentKit',
      optional: true,
      list: true,
    },
    text: {
      comment: 'Filter by text (title, description, url)',
      optional: true,
      type: 'string',
    },
    name: {
      comment: 'Fuzzy search by repository name',
      optional: true,
      type: 'string',
    },
    names: {
      comment: 'Filter on exact repository names',
      optional: true,
      type: 'string',
      list: true,
    },
  },
});

/** Override type */
export type RepositoryFiltersInput = SchemaToType<
  typeof RepositoryFiltersInput
>;

export const DeleteRepositoriesInput = mkInput({
  name: 'DeleteRepositoriesInput',
  comment: 'Input for deleting a set of repositories',
  fields: {
    ids: {
      comment: 'The id of the repositories to delete',
      type: 'id',
      modelName: 'repository',
      list: true,
    },
  },
});

/** Override type */
export type DeleteRepositoriesInput = SchemaToType<
  typeof DeleteRepositoriesInput
>;

export const CreateRepositoryInput = mkInput({
  name: 'CreateRepositoryInput',
  comment: 'Input for creating a repository',
  fields: {
    name: {
      comment: 'The name of the repository',
      type: 'string',
    },
    description: {
      comment: 'The description of the repository',
      type: 'string',
      optional: true,
    },
    url: {
      comment: 'The URL of the repository',
      type: 'string',
    },
    ownerIds: {
      comment:
        'The unique ids of the users to assign as owners of this repository',
      modelName: 'user',
      type: 'id',
      optional: true,
      list: true,
    },
    ownerEmails: {
      comment:
        'The emails of the users to assign as owners of this repository.',
      type: 'string',
      optional: true,
      list: true,
    },
    teamIds: {
      comment:
        'The ids of the teams that should be responsible for this repository',
      type: 'id',
      modelName: 'team',
      list: true,
      optional: true,
    },
    teamNames: {
      comment:
        'The names of the teams that should be responsible for this repository',
      type: 'string',
      list: true,
      optional: true,
    },
  },
});

/** Override type */
export type CreateRepositoryInput = SchemaToType<typeof CreateRepositoryInput>;

export const UpdateRepositoryInput = mkInput({
  name: 'UpdateRepositoryInput',
  comment: 'Input for updating a repository',
  fields: {
    id: {
      comment: 'The id of the repository',
      modelName: 'repository',
      type: 'id',
    },
    name: {
      comment: 'The new name of the repository',
      type: 'string',
      optional: true,
    },
    description: CreateRepositoryInput.fields.description,
    url: {
      ...CreateRepositoryInput.fields.url,
      optional: true,
    },
    ownerIds: CreateRepositoryInput.fields.ownerIds,
    ownerEmails: CreateRepositoryInput.fields.ownerEmails,
    teamIds: CreateRepositoryInput.fields.teamIds,
    teamNames: CreateRepositoryInput.fields.teamNames,
  },
});

/** Override type */
export type UpdateRepositoryInput = SchemaToType<typeof UpdateRepositoryInput>;

export const UpdateRepositoriesInput = mkInput({
  name: 'UpdateRepositoriesInput',
  comment: 'Input for bulk updating one or more repositories',
  fields: {
    repositories: {
      comment:
        'List of repositories with the properties that should be updated for each',
      type: UpdateRepositoryInput,
      list: true,
    },
  },
});

/** Override type */
export type UpdateRepositoriesInput = SchemaToType<
  typeof UpdateRepositoriesInput
>;

/** Order for a repositories query */
export const RepositoryOrder = mkOrder(Repository.name, RepositoryOrderField);
