import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { endpoints } from '@main/access-control-types';
import { buildUseQuery, logger } from '@main/core-ui';

import { selectUser } from '../../Auth/App/selectors';

const PAGE_SIZE = 20;
const useGlobalActionItems = buildUseQuery(
  endpoints.globalActionItems,
  'GlobalActionItemsCount',
  {
    totalCount: null,
    unrolledCount: null,
  },
);

export const useActionItemStatusCount = (): {
  /** the returned count */
  count?: number;
  /** gql loading state */
  loading: boolean;
  /** gql error state */
  error: any;
} => {
  const user = useSelector(selectUser);
  const { data, loading, error } = useGlobalActionItems({
    variables: {
      first: PAGE_SIZE,
      filterBy: {
        resolved: false,
        ...(user?.isAdmin ? {} : { assigneeUserIds: [user!.id] }),
      },
    },
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
    skip: !user,
  });

  // log error so we can debug. no need to show on screen, there will just be no badge
  useEffect(() => {
    if (error) {
      logger.error(error);
    }
  }, [error]);

  // number of action items to notify about
  return {
    count: data && data.totalCount > 0 ? data.totalCount : undefined,
    loading,
    error,
  };
};
