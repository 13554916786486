import { defineMessages } from '@transcend-io/internationalization';

export const organizationPageMessages = defineMessages(
  'admin-dashboard.routes.OrganizationRoute.organizationPage',
  {
    breadcrumbsAriaLabel: {
      defaultMessage: 'Page breadcrumbs',
    },
  },
);

/**
 * CTABanners
 */
export const ctaBannerMessages = defineMessages(
  'admin-dashboard.routes.OrganizationRoute.ctaBanner',
  {
    trialExpiring: {
      defaultMessage: 'Your trial of Consent Management is ending soon.',
    },
    blocked: {
      defaultMessage:
        "Consent Management is not available under your organization's subscriptions.",
    },
  },
);
