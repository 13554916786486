import { makeEnum } from '@transcend-io/type-utils';

/**
 * Ways that a data collection can be applied.
 */
export enum DataApplicationMethod {
  /** Usage node */
  Usage = 'USAGE',
  /** Sharing node */
  Sharing = 'SHARING',
}

/**
 * Usage applications
 */
export enum UsageDataApplicationStaticType {
  /** Show you ads */
  Ads = 'ADS',
  /** Analytics */
  Analytics = 'ANALYTICS',
  /** Acquisition */
  Acquisition = 'ACQUISITION',
  /** Communicate with you */
  Communication = 'COMMUNICATION',
  /** Comply with the law */
  Comply = 'COMPLY',
  /** Provide Core Features */
  CoreFeatures = 'CORE_FEATURES',
  /** Improve Our Service */
  Improve = 'IMPROVE',
  /** Securely identify you */
  Identify = 'IDENTIFY',
  /** Detect malicious activity */
  Malicious = 'MALICIOUS',
  /** Market to you */
  Marketing = 'MARKETING',
  /** Service operations Security */
  Security = 'SECURITY',
  /** Optional features */
  OptionalFeatures = 'OPTIONAL_FEATURES',
  /** Personalize your experience */
  Personalization = 'PERSONALIZATION',
}

/**
 * Sharing applications
 */
export enum SharingDataApplicationStaticType {
  /** Companies we own */
  OwnedCompanies = 'OWNED_COMPANIES',
  /** Partners */
  Partners = 'PARTNERS',
}

/**
 * Data application static defaults
 */
export const DataApplicationStaticType = makeEnum({
  ...UsageDataApplicationStaticType,
  ...SharingDataApplicationStaticType,
});

/**
 * Overload all data applications
 */
export type DataApplicationStaticType =
  (typeof DataApplicationStaticType)[keyof typeof DataApplicationStaticType];
