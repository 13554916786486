import { FlexRowCenterBoth, StyleUtils, useBlocker } from '@main/core-ui';
import React, { useCallback, useEffect, useState } from 'react';
import { MessageDescriptor, useIntl } from 'react-intl';
import { useLocation, useNavigate } from 'react-router-dom';

import { Button } from '../Button';
import { Modal } from '../Modal';
import { confirmNavigationModalMessages } from './messages';

interface ConfirmNavigationModalProps {
  /** The condition for which the confirm prompt should be displayed */
  when: boolean;
  /** Modal title */
  title: MessageDescriptor;
  /** Modal main text */
  text: MessageDescriptor;
  /** Text for the confirm button */
  confirm?: MessageDescriptor;
  /** Text for the cancel button */
  cancel?: MessageDescriptor;
}

// Dialog that displays when user navigates away from this component
export const ConfirmNavigationModal: React.FC<ConfirmNavigationModalProps> = ({
  when,
  title,
  text,
  confirm,
  cancel,
}) => {
  const { formatMessage } = useIntl();

  const navigate = useNavigate();
  const location = useLocation();
  const [showPrompt, setShowPrompt] = useState(false);
  const [lastLocation, setLastLocation] = useState<any>(null);
  const [confirmedNavigation, setConfirmedNavigation] = useState(false);

  const cancelNavigation = useCallback(() => {
    setShowPrompt(false);
  }, []);

  const handleBlockedNavigation = useCallback(
    (nextLocation) => {
      if (
        !confirmedNavigation &&
        nextLocation.location.pathname !== location.pathname
      ) {
        setShowPrompt(true);
        setLastLocation(nextLocation);
        return false;
      }
      return true;
    },
    [confirmedNavigation, location.pathname],
  );

  const confirmNavigationModal = useCallback(() => {
    setShowPrompt(false);
    setConfirmedNavigation(true);
  }, []);

  useEffect(() => {
    if (confirmedNavigation && lastLocation) {
      navigate(lastLocation.location.pathname);
    }
  }, [confirmedNavigation, lastLocation, navigate]);

  useBlocker(handleBlockedNavigation, when);

  return (
    <Modal
      show={showPrompt}
      onHide={cancelNavigation}
      header={title}
      aria-describedby="confirm-nav-description"
    >
      <div style={{ marginBottom: StyleUtils.Spacing.xl }}>
        <p id="confirm-nav-description">{formatMessage(text)}</p>
      </div>

      <FlexRowCenterBoth style={{ gap: StyleUtils.Spacing.md }}>
        <Button onClick={confirmNavigationModal} variant="outline-danger">
          {formatMessage(confirm ?? confirmNavigationModalMessages.confirm)}
        </Button>
        <Button onClick={cancelNavigation}>
          {formatMessage(cancel ?? confirmNavigationModalMessages.cancel)}
        </Button>
      </FlexRowCenterBoth>
    </Modal>
  );
};
