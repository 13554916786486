import { QueryHookOptions, QueryResult } from '@apollo/client';
import { useMemo } from 'react';

import { Gql, Requirize } from '@transcend-io/type-utils';

import {
  buildUseInfiniteScroll,
  buildUseLazyQuery,
  buildUseQuery,
  QueryFunction,
} from '@main/core-ui';
import {
  AnySchema,
  EndpointParams,
  GqlObject,
  GraphQLResponse,
  ParamsToType,
  QueryEndpoint,
  ResponseToType,
} from '@main/schema-utils';

/**
 * wrapper on `buildUseQuery` that transforms the data returned,
 * e.g. when the data has stringified JSON
 *
 * @param endpoint - The GraphQL query endpoint definition
 * @param transform - the function that transforms the data.nodes returned from GQL to the new form
 * @param operationName - The GraphQL operation name
 * @param responseFields - Return a partial response

 * @returns the wrapped hook function
 */
export function buildUseTransformQuery<
  TName extends string,
  TParams extends EndpointParams,
  TResult extends GraphQLResponse | AnySchema,
  TTransformedResult,
>(
  endpoint: QueryEndpoint<TName, TParams, TResult>,
  transform: (
    data: QueryResult<ResponseToType<TResult>>['data'],
  ) => QueryResult<TTransformedResult>['data'],
  operationName?: string,
  responseFields?: GqlObject<TResult> | Gql<ResponseToType<TResult>>,
): (
  options?: QueryHookOptions<ResponseToType<TResult>, ParamsToType<TParams>>,
) => QueryResult<TTransformedResult> {
  const executeQuery = buildUseQuery<TName, TParams, TResult>(
    endpoint,
    operationName,
    responseFields,
  );

  return (options) => {
    const response = executeQuery(options);

    const data = useMemo(() => transform(response.data), [response.data]);

    return {
      ...response,
      data: response.data ? data : undefined,
    } as any;
  };
}

/**
 * wrapper on `buildUseLazyQuery` that transforms the data returned,
 * e.g. when the data has stringified JSON
 *
 * @param endpoint - The GraphQL query endpoint definition
 * @param transform - the function that transforms the data.nodes returned from GQL to the new form
 * @param operationName - The GraphQL operation name
 * @param responseFields - Return a partial response

 * @returns the wrapped hook function
 */
export function buildUseLazyTransformQuery<
  TName extends string,
  TParams extends EndpointParams,
  TResult extends GraphQLResponse | AnySchema,
  TTransformedResult,
>(
  endpoint: QueryEndpoint<TName, TParams, TResult>,
  transform: (
    data: QueryResult<ResponseToType<TResult>>['data'],
  ) => QueryResult<TTransformedResult>['data'],
  operationName?: string,
  responseFields?: GqlObject<TResult> | Gql<ResponseToType<TResult>>,
): (
  options?: QueryHookOptions<ResponseToType<TResult>, ParamsToType<TParams>>,
) => QueryFunction<TParams, TTransformedResult> {
  const useLazyQuery = buildUseLazyQuery<TName, TParams, TResult>(
    endpoint,
    operationName,
    responseFields,
  );

  return (options) => {
    const lazyQuery = useLazyQuery(options);

    return (params: ParamsToType<TParams>) =>
      lazyQuery(params).then(
        // Cast because type instantiation is excessively deep, possibly infinite
        (response: any) =>
          ({
            ...response,
            data: transform(response.data),
          }) as any,
      );
  };
}

/**
 * wrapper on `buildUseInfiniteScroll` that transforms the data returned,
 * e.g. when the data has stringified JSON
 *
 * @param endpoint - The GraphQL query endpoint definition
 * @param transform - the function that transforms the data.nodes returned from GQL to the new form
 * @param operationName - The GraphQL operation name
 * @param responseFields - Return a partial response

 * @returns the wrapped hook function
 */
export function buildUseTransformInfiniteScroll<
  TName extends string,
  TParams extends Requirize<EndpointParams, 'first'>,
  TResult extends GraphQLResponse | AnySchema,
  TTransformedResult,
>(
  endpoint: QueryEndpoint<TName, TParams, TResult>,
  transform: (
    data: QueryResult<ResponseToType<TResult>>['data'],
  ) => QueryResult<TTransformedResult>['data'],
  operationName?: string,
  responseFields?: GqlObject<TResult> | Gql<ResponseToType<TResult>>,
): (
  options?: QueryHookOptions<ResponseToType<TResult>, ParamsToType<TParams>>,
) => QueryResult<TTransformedResult> {
  const executeInfiniteScroll = buildUseInfiniteScroll<TName, TParams, TResult>(
    endpoint,
    operationName,
    responseFields,
  );

  return (options) => {
    const response = executeInfiniteScroll(options);

    const data = useMemo(() => transform(response.data), [response.data]);

    return {
      ...response,
      data: response.data ? data : undefined,
    } as any;
  };
}
