import { mergeAttributes, type Node } from '@tiptap/core';
import Placeholder from '@tiptap/extension-placeholder';
import Table, { TableOptions } from '@tiptap/extension-table';
import TableCell, { TableCellOptions } from '@tiptap/extension-table-cell';
import TableHeader from '@tiptap/extension-table-header';
import TableRow from '@tiptap/extension-table-row';
import type { Extensions } from '@tiptap/react';

import {
  ALL_TEXT_NO_HEADERS_RICH_EDITOR_EXTENSIONS,
  ALL_TEXT_RICH_EDITOR_EXTENSIONS,
  CustomImageOptions,
  DEFAULT_RICH_EDITOR_EXTENSIONS,
  IMAGE_RICH_EDITOR_EXTENSIONS,
  TEXT_STYLING_RICH_EDITOR_EXTENSIONS,
} from './constants';
import { RichEditorExtension } from './enums';

export interface BaseExtensionConfig {
  /** The placeholder text for this document, when empty */
  placeholder: string;
  /** Options for configuring the image extension */
  imageOptions?: CustomImageOptions;
}

/**
 * Get tiptap extension configuration for tables
 *
 * @see https://tiptap.dev/extensions
 * @returns extension configuration for tables
 */
export function getTableExtensions(): Extensions {
  return [
    getCustomTable(),
    TableRow,
    getCustomTableHeader(),
    getCustomTableCell(),
  ];
}

/**
 *  Get tiptap Table extension configuration
 *
 * @see https://tiptap.dev/api/nodes/table
 * @returns custom Table extension with overwritten keyboard shortcuts
 */
function getCustomTable(): Node<TableOptions> {
  return Table.extend({
    /**
     *
     * @returns configuration that overrides the Table keyboard shortcuts
     */
    addKeyboardShortcuts() {
      return {
        // keep existing shortcuts
        ...this.parent?.(),
        // Ctrl + Alt + right adds column after
        'Control-Alt-ArrowRight': () => this.editor.commands.addColumnAfter(),
        // Ctrl + Alt + left adds column before
        'Control-Alt-ArrowLeft': () => this.editor.commands.addColumnBefore(),
        // Ctrl + Alt + down adds row after
        'Control-Alt-ArrowDown': () => this.editor.commands.addRowAfter(),
        // Ctrl + Alt + up adds row before
        'Control-Alt-ArrowUp': () => this.editor.commands.addRowBefore(),
      };
    },
    // TODO: https://transcend.height.app/T-18970
    // implement a plugin that, when the user hovers over a column or row divider,
    // displays a tooltip that explains the available command shortcuts
    // for inspiration, see the columnResizing plugin, implemented on
    // https://github.com/ProseMirror/prosemirror-tables/blob/master/src/columnresizing.ts
    // addProseMirrorPlugins() {
    //   return [...(this.parent?.() ?? [])]
    // }
  }).configure({
    resizable: true,
  });
}

export const getCustomTableCell = (): Node<TableCellOptions> =>
  TableCell.extend({
    /**
     *
     * @param opts - the node and html attributes
     * @returns configuration that overrides the TableCell renderHTML method
     */
    renderHTML({ HTMLAttributes }) {
      // Set the width as a style instead of using `colwidth` attribute, which has a bug
      // https://github.com/ueberdosis/tiptap/issues/2041#issuecomment-1772081904
      const attrs = mergeAttributes(
        this.options.HTMLAttributes,
        HTMLAttributes,
      );

      if (attrs.colwidth) {
        attrs.style = `width: ${attrs.colwidth}px`;
      }

      return ['td', attrs, 0];
    },
  });

export const getCustomTableHeader = (): Node<TableCellOptions> =>
  TableHeader.extend({
    /**
     *
     * @param opts - the node and html attributes
     * @returns configuration that overrides the TableHeader renderHTML method
     */
    renderHTML({ HTMLAttributes }) {
      // Set the width as a style instead of using `colwidth` attribute, which has a bug
      // https://github.com/ueberdosis/tiptap/issues/2041#issuecomment-1772081904
      const attrs = mergeAttributes(
        this.options.HTMLAttributes,
        HTMLAttributes,
      );

      if (attrs.colwidth) {
        attrs.style = `width: ${attrs.colwidth}px`;
      }

      return ['th', attrs, 0];
    },
  });

/**
 * Get the extensions enabled for this RichEditor instance
 *
 * @param enabledExtensions - extensions to enable
 * @param baseExtensionConfig - configuration for the enabled extensions
 * @see https://tiptap.dev/extensions
 * @returns extension configuration based on selection
 */
export function getEnabledExtensions(
  enabledExtensions: RichEditorExtension[],
  { placeholder, imageOptions }: BaseExtensionConfig,
): Extensions {
  let extensions: Extensions = [];
  const RICH_EDITOR_EXTENSION_MAP: Record<RichEditorExtension, Extensions> = {
    [RichEditorExtension.Default]: DEFAULT_RICH_EDITOR_EXTENSIONS,
    [RichEditorExtension.TextStyling]: TEXT_STYLING_RICH_EDITOR_EXTENSIONS,
    [RichEditorExtension.AllText]: ALL_TEXT_RICH_EDITOR_EXTENSIONS,
    [RichEditorExtension.AllTextNoHeaders]:
      ALL_TEXT_NO_HEADERS_RICH_EDITOR_EXTENSIONS,
    [RichEditorExtension.Image]: IMAGE_RICH_EDITOR_EXTENSIONS(imageOptions),
    [RichEditorExtension.Table]: getTableExtensions(),
  };
  enabledExtensions.forEach((extension) => {
    extensions = extensions.concat(RICH_EDITOR_EXTENSION_MAP[extension]);
    if (extension === RichEditorExtension.Default) {
      extensions.push(Placeholder.configure({ placeholder }));
    }
  });

  return extensions.filter(
    (extension, index, self) =>
      // Remove duplicates
      index === self.findIndex(({ name }) => name === extension.name),
  );
}
/**
 * Get the base tiptap extension configuration, used in every RichEditor instance
 *
 * @see https://tiptap.dev/extensions
 * @returns default extension configuration for plaintext support
 */
export function getDefaultExtensions(): Extensions {
  return DEFAULT_RICH_EDITOR_EXTENSIONS;
}
