import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { INLINES } from '@contentful/rich-text-types';
import { compile } from '@main/core-ui';
import type { ObjByString } from '@transcend-io/type-utils';
import React from 'react';

/**
 * Render contentful rich text.
 */
export const ContentfulRichText: React.FC<{
  /** JSON stringified rich text */
  richText: string;
  /** Values to file in compile statement */
  values?: ObjByString;
}> = ({ richText, values = {} }) => (
  <>
    {documentToReactComponents(JSON.parse(compile(richText, values)), {
      renderNode: {
        [INLINES.HYPERLINK]: (
          {
            data: { uri },
          }: {
            /** The metadata for links */
            data: {
              /** Data uri */
              uri?: string;
            };
          },
          linkText,
        ) => (
          <a href={uri} target="_blank" rel="noopener noreferrer">
            {linkText}
          </a>
        ),
      },
    })}
  </>
);
