/* eslint-disable max-lines */
import * as t from 'io-ts';

import { UpdateConsentManagerOnboardingStatusInput } from '@main/access-control-types';
import {
  DATABASE_PREFERENCE_PARAMS,
  mkMutation,
  mkQuery,
  PAGINATION_INPUT_PARAMS,
} from '@main/schema-utils';
import {
  ConsentLastKeySchema,
  ConsentRecordSchema,
  DecryptionContext,
  RecordOfConsentNextToken,
} from '@main/sombra-types';

import {
  AirgapBundleAggregateAnalyticsInput,
  AirgapBundleAggregateAnalyticsResult,
  AirgapBundleInput,
  AirgapBundleTimeseriesAnalyticsInput,
  AirgapBundleTimeseriesAnalyticsResult,
  AirgapBundleVersion,
  AirgapBundleVersionsInput,
  AirgapVersion,
  AirgapVersionsInput,
  BundleTCFStack,
  BundleTcfVendor,
  BundleTCFVendorInput,
  ClassifyCookiesInput,
  ClassifyDataFlowsInput,
  ConsentApplication,
  ConsentApplicationFiltersInput,
  ConsentManager,
  ConsentManagerConfigurationJson,
  ConsentManagerTheme,
  ConsentManagerUI,
  ConsentPartition,
  ConsentPartitionFiltersInput,
  ConsentPreferencesInput,
  ConsentSdk,
  ConsentSdkFiltersInput,
  ConsentService,
  ConsentWorkflowTrigger,
  ConsentWorkflowTriggersFilterInput,
  Cookie,
  CookieOrder,
  CookiesFiltersInput,
  CookieSuggestion,
  CreateConsentManagerInput,
  CreateConsentManagerUIInput,
  CreateConsentPartitionInput,
  CreateDataFlowsInput,
  CreateExperienceInput,
  CreateOrUpdateConsentApplicationInput,
  CreateOrUpdateConsentSdkInput,
  CreateOrUpdateConsentServicesInput,
  CreateOrUpdateConsentWorkflowTriggerInput,
  CreateOrUpdatePreferenceOptionValuesInput,
  CreateOrUpdatePreferenceTopicInput,
  CreatePurposeInput,
  DataFlow,
  DataFlowOrder,
  DataFlowService,
  DataFlowServicesFiltersInput,
  DataFlowsFiltersInput,
  DataFlowSuggestion,
  DeleteConsentApplicationInput,
  DeleteConsentSdkInput,
  DeleteConsentServicesInput,
  DeleteConsentWorkflowTriggersInput,
  DeleteCookiesInput,
  DeleteDataFlowsInput,
  DeleteExperiencesInput,
  DeletePreferenceOptionValuesInput,
  DeletePreferenceTopicsInput,
  DeletePurposesInput,
  DeployConsentManagerInput,
  DisableExperiencesInput,
  EnrichedConsentRecordSchema,
  Experience,
  ExperienceFiltersInput,
  ExperienceOrder,
  FilterConsentServicesInput,
  MakeDefaultSyncEndpointInput,
  OptionalAirgapBundleInput,
  OrganizationTCFSpecialPurpose,
  OrgTcfPurposeFilterInput,
  OrgTcfSpecialFeatureFilterInput,
  PreferenceOptionValue,
  PreferenceOptionValueFilterInput,
  PreferenceTopic,
  PreferenceTopicFilterInput,
  PurposeOrder,
  RecordsOfConsentInput,
  RollbackAirgapBundleInput,
  SetSyncEndpointInput,
  SetTCFStacksInput,
  ShowTCFVendorInput,
  SyncEndpointCustomDomain,
  TCFFeature,
  TCFPurposeTrackingPurposes,
  TCFSpecialFeatureTrackingPurpose,
  TCFStack,
  TCFStackFilterInput,
  TCFVendor,
  TCFVendorDetailsInput,
  TCFVendorFilterInput,
  ToggleConsentManagerEnforcementInput,
  ToggleConsentPrecedenceInput,
  TogglePurposeActiveInput,
  ToggleTelemetryPartitionStrategyInput,
  ToggleUnknownCookiePolicyInput,
  ToggleUnknownRequestPolicyInput,
  ToggleUseDefaultTcfSettingsInput,
  ToggleUspapiInput,
  TokenSecretInput,
  TokenSecrets,
  TrackingPurpose,
  TrackingPurposeFiltersInput,
  TrackingPurposeInput,
  UpdateConsentManagerInput,
  UpdateConsentManagerPartitionInput,
  UpdateConsentManagerThemeInput,
  UpdateConsentPreferenceInput,
  UpdateDataFlowsInput,
  UpdateDomainListInput,
  UpdateExperienceInput,
  UpdateLoadOptionsInput,
  UpdateOrCreateCookiesInput,
  UpdateOrgTCFPurposesInput,
  UpdatePurposeInput,
  UpdateSyncEndpointInput,
  UpdateSyncGroupsInput,
  UpdateTCFSpecialFeatureTrackingPurposesInput,
} from './schema';

export const purposes = mkQuery({
  name: 'purposes',
  comment:
    'Return the list of consent tracking purposes for this organization.',
  params: {
    ...PAGINATION_INPUT_PARAMS,
    filterBy: TrackingPurposeFiltersInput.optional(),
    orderBy: PurposeOrder,
    input: TrackingPurposeInput.optional(),
  },
  response: {
    nodes: TrackingPurpose.list(),
    totalCount: 'int',
  },
});

export const airgapBundleTimeseriesAnalytics = mkQuery({
  name: 'airgapBundleTimeseriesAnalytics',
  comment: 'The timeseries analytics data for an airgap bundle',
  params: {
    id: {
      type: 'id',
      modelName: 'airgapBundle',
    },
    input: AirgapBundleTimeseriesAnalyticsInput,
  },
  response: AirgapBundleTimeseriesAnalyticsResult,
});

export const airgapBundleAggregateAnalytics = mkQuery({
  name: 'airgapBundleAggregateAnalytics',
  comment: 'The aggregate analytics data for an airgap bundle',
  params: {
    id: {
      type: 'id',
      modelName: 'airgapBundle',
    },
    input: AirgapBundleAggregateAnalyticsInput,
  },
  response: AirgapBundleAggregateAnalyticsResult,
});

export const dataFlowServices = mkQuery({
  name: 'dataFlowServices',
  comment: 'The services for which data flows are configured',
  params: {
    ...PAGINATION_INPUT_PARAMS,
    input: AirgapBundleInput,
    filterBy: DataFlowServicesFiltersInput.optional(),
  },
  response: {
    nodes: DataFlowService.list(),
    totalCount: 'int',
  },
});

export const dataFlows = mkQuery({
  name: 'dataFlows',
  comment: 'The data flows for an organization',
  params: {
    ...PAGINATION_INPUT_PARAMS,
    input: AirgapBundleInput,
    filterBy: DataFlowsFiltersInput.optional(),
    orderBy: DataFlowOrder,
    ...DATABASE_PREFERENCE_PARAMS,
  },
  response: {
    nodes: DataFlow.list(),
    totalCount: 'int',
  },
});

export const dataFlowStats = mkQuery({
  name: 'dataFlowStats',
  comment: 'Aggregate statistics about managed data flows for an org',
  params: {
    input: AirgapBundleInput,
  },
  response: {
    liveCount: 'int',
    needReviewCount: 'int',
    junkCount: 'int',
    liveActivity: 'float',
    needReviewActivity: 'float',
    junkActivity: 'float',
  },
});

export const cookieStats = mkQuery({
  name: 'cookieStats',
  comment: 'Aggregate statistics about managed cookies for an org',
  params: { input: AirgapBundleInput },
  response: {
    liveCount: 'int',
    needReviewCount: 'int',
    junkCount: 'int',
    liveActivity: 'float',
    needReviewActivity: 'float',
    junkActivity: 'float',
  },
});

export const createDataFlows = mkMutation({
  name: 'createDataFlows',
  comment: 'Create new data flows',
  params: {
    input: CreateDataFlowsInput,
  },
  response: {
    dataFlows: DataFlow.list(),
  },
});

export const updateDataFlows = mkMutation({
  name: 'updateDataFlows',
  comment: 'Update a data flow',
  params: {
    input: UpdateDataFlowsInput,
  },
  response: {
    dataFlows: DataFlow.list(),
  },
});

export const deleteDataFlow = mkMutation({
  name: 'deleteDataFlow',
  comment: 'Delete a data flow',
  params: {
    id: {
      comment: 'The id of the data flow to delete',
      type: 'id',
      modelName: 'airgapDataFlow',
    },
  },
  response: {
    success: 'boolean',
  },
});

export const airgapBundleVersions = mkQuery({
  name: 'airgapBundleVersions',
  comment:
    'List the versions of an airgap bundle, ordered by most recent first',
  params: {
    ...PAGINATION_INPUT_PARAMS,
    id: {
      comment: 'The ID of the airgap bundle',
      type: 'id',
      modelName: 'airgapBundle',
    },
    input: AirgapBundleVersionsInput,
  },
  response: {
    nodes: AirgapBundleVersion.list(),
    totalCount: 'int',
  },
});

export const rollbackAirgapBundle = mkMutation({
  name: 'rollbackAirgapBundle',
  comment: 'Rollback to a previous version of an airgap bundle',
  params: {
    id: {
      comment: 'The ID of the airgap bundle',
      type: 'id',
      modelName: 'airgapBundle',
    },
    input: RollbackAirgapBundleInput,
  },
  response: {
    success: 'boolean',
  },
});

export const consentManager = mkQuery({
  name: 'consentManager',
  comment:
    'The configuration and metadata associated with a given consent manager.',
  params: {},
  response: {
    consentManager: ConsentManager,
  },
});

export const createConsentManager = mkMutation({
  name: 'createConsentManager',
  comment: 'Create a new consent manager',
  params: {
    input: CreateConsentManagerInput,
  },
  response: {
    consentManager: ConsentManager,
  },
});

export const updateConsentManagerOnboardingStatus = mkMutation({
  name: 'updateConsentManagerOnboardingStatus',
  comment: 'Update the onboarding status of a consent manager',
  params: {
    input: UpdateConsentManagerOnboardingStatusInput,
  },
  response: {
    consentManager: ConsentManager,
  },
});

export const consentManagerTheme = mkQuery({
  name: 'consentManagerTheme',
  comment: 'Get the UI configuration associated with a given consent manager',
  params: {
    input: AirgapBundleInput,
  },
  response: {
    theme: ConsentManagerTheme,
  },
});

export const updateConsentManagerTheme = mkMutation({
  name: 'updateConsentManagerTheme',
  comment: "Update the consent manager's theme settings",
  params: {
    input: UpdateConsentManagerThemeInput,
  },
  response: {
    theme: ConsentManagerTheme,
  },
});

export const deployConsentManagerBundle = mkMutation({
  name: 'deployConsentManagerBundle',
  comment: 'Deploy a new consent manager with the current configuration.',
  params: {
    id: {
      modelName: 'airgapBundle',
      type: 'id',
    },
    input: DeployConsentManagerInput,
  },
  response: {
    consentManager: ConsentManager,
  },
});

export const toggleUnknownRequestPolicy = mkMutation({
  name: 'toggleUnknownRequestPolicy',
  comment: 'Toggle the unknown request policy for the Consent Manager.',
  params: {
    input: ToggleUnknownRequestPolicyInput,
  },
  response: {
    consentManager: ConsentManager,
  },
});

export const toggleUnknownCookiePolicy = mkMutation({
  name: 'toggleUnknownCookiePolicy',
  comment: 'Toggle the unknown cookie policy for the Consent Manager.',
  params: {
    input: ToggleUnknownCookiePolicyInput,
  },
  response: {
    consentManager: ConsentManager,
  },
});

export const toggleConsentManagerEnforcement = mkMutation({
  name: 'toggleConsentManagerEnforcement',
  comment: 'Toggle the consent manager enforcement on/off',
  params: {
    input: ToggleConsentManagerEnforcementInput,
  },
  response: {
    consentManager: ConsentManager,
  },
});

export const toggleTelemetryPartitioning = mkMutation({
  name: 'toggleTelemetryPartitioning',
  comment:
    'Toggle the telemetry partitioning strategy for the Consent Manager.',
  params: {
    input: ToggleTelemetryPartitionStrategyInput,
  },
  response: {
    consentManager: ConsentManager,
  },
});

export const updateConsentManagerDomains = mkMutation({
  name: 'updateConsentManagerDomains',
  comment:
    'Modifies the domain list that the Consent Manager is allowed to run on.',
  params: {
    input: UpdateDomainListInput,
  },
  response: {
    consentManager: ConsentManager,
  },
});

export const cookies = mkQuery({
  name: 'cookies',
  comment: 'The cookies for an organization',
  params: {
    ...PAGINATION_INPUT_PARAMS,
    input: AirgapBundleInput,
    filterBy: CookiesFiltersInput.optional(),
    orderBy: CookieOrder,
    ...DATABASE_PREFERENCE_PARAMS,
  },
  response: {
    nodes: Cookie.list(),
    totalCount: 'int',
  },
});

export const updateOrCreateCookies = mkMutation({
  name: 'updateOrCreateCookies',
  comment: 'Update or create airgap cookies',
  params: {
    input: UpdateOrCreateCookiesInput,
  },
  response: {
    nodes: Cookie.list(),
    totalCount: 'int',
  },
});

export const reClassifyCookies = mkMutation({
  name: 'reClassifyCookies',
  comment: 'Enqueues cookies in NEEDS_REVIEW status to be re-classified',
  params: {
    id: {
      type: 'id',
      modelName: 'airgapBundle',
    },
  },
  response: {
    success: 'boolean',
  },
});

export const reClassifyDataFlows = mkMutation({
  name: 'reClassifyDataFlows',
  comment: 'Enqueues data flows in NEEDS_REVIEW status to be re-classified',
  params: {
    id: {
      type: 'id',
      modelName: 'airgapBundle',
    },
  },
  response: {
    success: 'boolean',
  },
});

export const deleteCookies = mkMutation({
  name: 'deleteCookies',
  comment: 'Deletes cookies by some criteria',
  params: {
    input: DeleteCookiesInput,
  },
  response: {
    success: 'boolean',
  },
});

export const deleteDataFlows = mkMutation({
  name: 'deleteDataFlows',
  comment: 'Deletes data flows by some criteria',
  params: {
    input: DeleteDataFlowsInput,
  },
  response: {
    success: 'boolean',
  },
});

export const updateConsentManagerToLatestVersion = mkMutation({
  name: 'updateConsentManagerToLatestVersion',
  comment:
    'Updates consent manager to the latest airgap.js version and deploys the change immediately',
  params: {
    id: {
      comment: 'The ID of this Consent Manager bundle.',
      modelName: 'airgapBundle',
      type: 'id',
    },
    input: DeployConsentManagerInput,
  },
  response: {
    consentManager: ConsentManager,
  },
});

export const experiences = mkQuery({
  name: 'experiences',
  comment:
    'Transcend configurations for subjects in organization defined regions',
  params: {
    ...PAGINATION_INPUT_PARAMS,
    ...DATABASE_PREFERENCE_PARAMS,
    filterBy: ExperienceFiltersInput.optional(),
    orderBy: ExperienceOrder,
  },
  response: {
    nodes: Experience.list(),
    totalCount: 'int',
  },
});

export const createExperience = mkMutation({
  name: 'createExperience',
  comment: 'Create a new experience for an organization',
  params: {
    input: CreateExperienceInput,
  },
  response: {
    experience: Experience,
  },
});

export const deleteExperiences = mkMutation({
  name: 'deleteExperiences',
  comment: 'Delete a list of experiences by id',
  params: {
    input: DeleteExperiencesInput,
  },
  response: {
    success: 'boolean',
  },
});

export const updateExperience = mkMutation({
  name: 'updateExperience',
  comment: 'Update an existing experience for an organization',
  params: {
    input: UpdateExperienceInput,
  },
  response: {
    experience: Experience,
  },
});

export const disableExperiences = mkMutation({
  name: 'disableExperiences',
  comment: 'Disable or enable a list of experiences by id',
  params: {
    input: DisableExperiencesInput,
  },
  response: {
    success: 'boolean',
  },
});

export const consentManagerUIs = mkQuery({
  name: 'consentManagerUIs',
  comment: 'Consent Manager UIs for Airgap Bundle',
  params: {
    ...PAGINATION_INPUT_PARAMS,
  },
  response: {
    nodes: ConsentManagerUI.list(),
    totalCount: 'int',
  },
});

export const createConsentManagerUI = mkMutation({
  name: 'createConsentManagerUI',
  comment: 'Create a record for a custom UI module for Airgap',
  params: {
    input: CreateConsentManagerUIInput,
  },
  response: {
    ui: ConsentManagerUI,
  },
});

export const createPurpose = mkMutation({
  name: 'createPurpose',
  comment: 'Create a consent tracking purpose for an organization',
  params: {
    input: CreatePurposeInput,
  },
  response: {
    purpose: TrackingPurpose,
  },
});

export const updatePurpose = mkMutation({
  name: 'updatePurpose',
  comment: 'Update an existing consent tracking purpose for an organization',
  params: {
    input: UpdatePurposeInput,
  },
  response: {
    purpose: TrackingPurpose,
  },
});

export const deletePurposes = mkMutation({
  name: 'deletePurposes',
  comment: 'Delete an existing consent tracking purposes for an organization',
  params: {
    input: DeletePurposesInput,
  },
  response: {
    success: 'boolean',
  },
});

export const togglePurposeActive = mkMutation({
  name: 'togglePurposeActive',
  comment:
    'Enable/disable existing consent tracking purposes for an organization',
  params: {
    input: TogglePurposeActiveInput,
  },
  response: {
    success: 'boolean',
  },
});

export const toggleUseDefaultTcfSettings = mkMutation({
  name: 'toggleUseDefaultTcfSettings',
  comment: 'Opt-in to using default TCF Settings',
  params: {
    input: ToggleUseDefaultTcfSettingsInput,
  },
  response: {
    consentManager: ConsentManager,
  },
});

export const toggleUspapi = mkMutation({
  name: 'toggleUspapi',
  comment: 'Toggling the use of the Consent US Privacy API.',
  params: {
    input: ToggleUspapiInput,
  },
  response: {
    consentManager: ConsentManager,
  },
});

export const updateSyncGroups = mkMutation({
  name: 'updateSyncGroups',
  comment: 'Update sync groups.',
  params: {
    input: UpdateSyncGroupsInput,
  },
  response: {
    consentManager: ConsentManager,
  },
});

export const updateConsentManagerPartition = mkMutation({
  name: 'updateConsentManagerPartition',
  comment: 'Update the key used to partition consent records.',
  params: {
    input: UpdateConsentManagerPartitionInput,
  },
  response: {
    consentManager: ConsentManager,
  },
});

export const toggleConsentPrecedence = mkMutation({
  name: 'toggleConsentPrecedence',
  comment: 'Toggle the Consent Resolution Precedence for the Consent Manager.',
  params: {
    input: ToggleConsentPrecedenceInput,
  },
  response: {
    consentManager: ConsentManager,
  },
});

export const updateSyncEndpoint = mkMutation({
  name: 'updateSyncEndpoint',
  comment: 'Update the airgap XDI consent sync endpoint URL.',
  params: {
    input: UpdateSyncEndpointInput,
  },
  response: {
    consentManager: ConsentManager,
  },
});

export const updateLoadOptions = mkMutation({
  name: 'updateLoadOptions',
  comment: 'Update an airgap bundle loadOptions.',
  params: {
    input: UpdateLoadOptionsInput,
  },
  response: {
    consentManager: ConsentManager,
  },
});

export const getTokenSecrets = mkQuery({
  name: 'getTokenSecrets',
  comment: 'Get the secrets for signing tokens (current and former)',
  params: {
    id: {
      comment: 'The ID of the airgap bundle',
      type: 'id',
      modelName: 'airgapBundle',
    },
  },
  response: { tokenSecrets: TokenSecrets },
});

export const setTokenSecret = mkMutation({
  name: 'setTokenSecret',
  comment: 'Update secret for signing and verifying JWTs',
  params: {
    input: TokenSecretInput,
  },
  response: { tokenSecrets: TokenSecrets },
});

export const consentPartitions = mkQuery({
  name: 'consentPartitions',
  comment:
    'List the consent partitions defined for this organization (parent orgs only)',
  params: {
    ...PAGINATION_INPUT_PARAMS,
    filterBy: ConsentPartitionFiltersInput.optional(),
  },
  response: {
    nodes: ConsentPartition.list(),
    totalCount: 'int',
  },
});

export const createConsentPartition = mkMutation({
  name: 'createConsentPartition',
  comment: 'Create a new consent partition (only for parent orgs)',
  params: {
    input: CreateConsentPartitionInput,
  },
  response: {
    consentPartition: ConsentPartition,
  },
});

export const deleteConsentPartition = mkMutation({
  name: 'deleteConsentPartition',
  comment: 'Delete a consent partition (only for parent orgs)',
  params: {
    id: {
      type: 'id',
      modelName: 'airgapPartition',
    },
  },
  response: {
    success: 'boolean',
  },
});

export const updateConsentManager = mkMutation({
  name: 'updateConsentManager',
  comment: 'Update the consent manager settings',
  params: {
    id: {
      type: 'id',
      modelName: 'airgapBundle',
    },
    input: UpdateConsentManagerInput,
  },
  response: {
    consentManager: ConsentManager,
  },
});

export const setSyncEndpoints = mkMutation({
  name: 'setSyncEndpoints',
  comment: 'Specify a list of custom domains hosting the sync endpoint',
  params: {
    id: {
      type: 'id',
      modelName: 'airgapBundle',
    },
    input: SetSyncEndpointInput,
  },
  response: {
    syncEndpoints: SyncEndpointCustomDomain.list(),
  },
});

export const makeDefaultSyncEndpoint = mkMutation({
  name: 'makeDefaultSyncEndpoint',
  comment:
    'Mark a given sync endpoint as the default one, to be included in the generated airgap bundle configuration',
  params: {
    id: {
      type: 'id',
      modelName: 'airgapBundle',
    },
    input: MakeDefaultSyncEndpointInput,
  },
  response: {
    syncEndpoints: SyncEndpointCustomDomain.list(),
  },
});

export const consentPreferences = mkMutation({
  name: 'consentPreferences',
  comment: 'Paginated query for fetching employee consent preferences data',
  params: {
    first: PAGINATION_INPUT_PARAMS.first,
    input: ConsentPreferencesInput,
    dhEncrypted: {
      type: 'string',
      underlyingType: t.type({}),
    },
  },
  response: {
    nodes: EnrichedConsentRecordSchema.list(),
    lastKey: ConsentLastKeySchema.optional(),
    decryptionContext: DecryptionContext,
  },
});

export const employeeUpdateConsentPreferences = mkMutation({
  name: 'employeeUpdateConsentPreferences',
  comment: 'Update consent preferences for a list of identifiers',
  params: {
    input: UpdateConsentPreferenceInput,
    dhEncrypted: {
      type: 'string',
      underlyingType: t.type({}),
    },
  },
  response: {
    data: EnrichedConsentRecordSchema.list(),
    /** Optional in case the data is empty */
    decryptionContext: DecryptionContext,
  },
});

export const customSyncEndpoints = mkQuery({
  name: 'customSyncEndpoints',
  comment: 'List all the configured sync endpoints for a given consent manager',
  params: {
    id: {
      type: 'id',
      modelName: 'airgapBundle',
    },
  },
  response: {
    nodes: SyncEndpointCustomDomain.list(),
    totalCount: 'int',
  },
});

export const airgapVersions = mkQuery({
  name: 'airgapVersions',
  comment: 'List all the available airgap versions (max 100)',
  params: {
    input: AirgapVersionsInput,
  },
  response: {
    versions: AirgapVersion.list(),
  },
});

/** @deprecated use setAirgapBundleTcfStacks */
export const addTCFStacksToBundle = mkMutation({
  name: 'addTCFStacksToBundle',
  comment: 'Add TCF stacks to an airgap bundle',
  params: {
    input: SetTCFStacksInput,
  },
  response: {
    tcfStacks: TCFStack.list(),
  },
});

/** @deprecated use setAirgapBundleTcfStacks */
export const removeTCFStacksFromBundle = mkMutation({
  name: 'removeTCFStacksFromBundle',
  comment: 'Remove TCF stacks from an airgap bundle',
  params: {
    input: SetTCFStacksInput,
  },
  response: {
    success: 'boolean',
  },
});

// TODO: remove unused input from the query https://transcend.height.app/T-33586
export const orgTcfPurposes = mkQuery({
  name: 'orgTcfPurposes',
  comment: 'List the mapping from TCF Purposes to Airgap Tracking Purposes',
  params: {
    input: OptionalAirgapBundleInput,
    filterBy: OrgTcfPurposeFilterInput.optional(),
  },
  response: {
    nodes: TCFPurposeTrackingPurposes.list(),
  },
});

export const orgTcfSpecialPurposes = mkQuery({
  name: 'orgTcfSpecialPurposes',
  comment: 'TCF Special Purposes applicable for an organization',
  params: { input: AirgapBundleInput },
  response: {
    nodes: OrganizationTCFSpecialPurpose.list(),
  },
});

export const updateOrgTCFPurposes = mkMutation({
  name: 'updateOrgTCFPurposes',
  comment: 'Update the mapping from TCF Purposes to Airgap tracking purposes',
  params: {
    input: UpdateOrgTCFPurposesInput,
  },
  response: {
    orgTcfPurposes: TCFPurposeTrackingPurposes,
  },
});

export const consentServices = mkQuery({
  name: 'consentServices',
  comment: 'Query for all consent services for a given Consent bundle',
  params: {
    ...PAGINATION_INPUT_PARAMS,
    input: AirgapBundleInput,
    filterBy: FilterConsentServicesInput.optional(),
  },
  response: {
    nodes: ConsentService.list(),
    totalCount: 'int',
  },
});

export const createOrUpdateConsentServices = mkMutation({
  name: 'createOrUpdateConsentServices',
  comment: 'Create or update consent services',
  params: {
    input: CreateOrUpdateConsentServicesInput,
  },
  response: {
    nodes: ConsentService.list(),
  },
});

export const tcfFeatures = mkQuery({
  name: 'tcfFeatures',
  comment: 'Query for all TCF Features for a given Consent bundle',
  params: {
    ...PAGINATION_INPUT_PARAMS,
    input: AirgapBundleInput,
  },
  response: {
    nodes: TCFFeature.list(),
    totalCount: 'int',
  },
});

export const bundleTcfVendors = mkQuery({
  name: 'bundleTcfVendors',
  comment: 'Query for all TCF vendors for a given Consent bundle',
  params: {
    ...PAGINATION_INPUT_PARAMS,
    input: BundleTCFVendorInput,
  },
  response: {
    nodes: BundleTcfVendor.list(),
    totalCount: 'int',
  },
});

export const orgTcfSpecialFeatures = mkQuery({
  name: 'orgTcfSpecialFeatures',
  comment:
    'Query for the mapping from TCF Special Features to Airgap Tracking Purposes',
  params: {
    // TODO: remove unused input from the query https://transcend.height.app/T-33586
    input: OptionalAirgapBundleInput,
    filterBy: OrgTcfSpecialFeatureFilterInput.optional(),
  },
  response: {
    nodes: TCFSpecialFeatureTrackingPurpose.list(),
    totalCount: 'int',
  },
});

export const updateOrgTcfSpecialFeature = mkMutation({
  name: 'updateOrgTcfSpecialFeature',
  comment:
    'Update the mapping from TCF Special Features to Airgap tracking purposes',
  params: {
    input: UpdateTCFSpecialFeatureTrackingPurposesInput,
  },
  response: {
    tcfSpecialFeatureTrackingPurposes: TCFSpecialFeatureTrackingPurpose,
  },
});

export const consentApplications = mkQuery({
  name: 'consentApplications',
  comment:
    'Query for all native applications being governed by Transcend Consent',
  params: {
    ...PAGINATION_INPUT_PARAMS,
    filterBy: ConsentApplicationFiltersInput.optional(),
  },
  response: {
    nodes: ConsentApplication.list(),
    totalCount: 'int',
  },
});

export const createOrUpdateConsentApplications = mkMutation({
  name: 'createOrUpdateConsentApplications',
  comment: 'Mutation for bulk upserting consent applications',
  params: {
    input: CreateOrUpdateConsentApplicationInput,
  },
  response: {
    nodes: ConsentApplication.list(),
  },
});

export const deleteConsentApplications = mkMutation({
  name: 'deleteConsentApplications',
  comment: 'Mutation for deleting consent applications',
  params: {
    input: DeleteConsentApplicationInput,
  },
  response: {
    success: 'boolean',
  },
});

export const consentSdks = mkQuery({
  name: 'consentSdks',
  comment: 'Query for all consent SDKs being governed by Transcend Consent',
  params: {
    ...PAGINATION_INPUT_PARAMS,
    input: AirgapBundleInput,
    filterBy: ConsentSdkFiltersInput.optional(),
  },
  response: {
    nodes: ConsentSdk.list(),
    totalCount: 'int',
  },
});

export const createOrUpdateConsentSdks = mkMutation({
  name: 'createOrUpdateConsentSdks',
  comment: 'Mutation for bulk upserting consent SDKs',
  params: {
    input: CreateOrUpdateConsentSdkInput,
  },
  response: {
    nodes: ConsentSdk.list(),
  },
});

export const deleteConsentSdks = mkMutation({
  name: 'deleteConsentSdks',
  comment: 'Mutation for deleting consent SDKs',
  params: {
    input: DeleteConsentSdkInput,
  },
  response: {
    success: 'boolean',
  },
});

export const deleteConsentServices = mkMutation({
  name: 'deleteConsentServices',
  comment: 'Mutation for deleting consent Services',
  params: {
    input: DeleteConsentServicesInput,
  },
  response: {
    success: 'boolean',
  },
});

export const tcfVendors = mkQuery({
  name: 'tcfVendors',
  comment: 'Query for all TCF vendors',
  params: {
    ...PAGINATION_INPUT_PARAMS,
    input: AirgapBundleInput,
    filterBy: TCFVendorFilterInput.optional(),
  },
  response: {
    nodes: TCFVendor.list(),
    totalCount: 'int',
  },
});

export const tcfVendorDetails = mkQuery({
  name: 'tcfVendorDetails',
  comment: 'Query to retrieve details for a specific TCF vendors',
  params: {
    ...PAGINATION_INPUT_PARAMS,
    input: TCFVendorDetailsInput,
  },
  response: {
    tcfVendor: TCFVendor,
  },
});

export const showTcfVendorInUI = mkMutation({
  name: 'showTcfVendorInUI',
  comment: 'Mutation for showing or hiding a given vendor in the TCF UI',
  params: {
    input: ShowTCFVendorInput,
  },
  response: {
    success: 'boolean',
  },
});

export const bundleTcfStacks = mkQuery({
  name: 'bundleTcfStacks',
  comment:
    'Query for all IAB TCF stacks with metadata for a given Consent bundle',
  params: {
    ...PAGINATION_INPUT_PARAMS,
    input: AirgapBundleInput,
    filterBy: TCFStackFilterInput.optional(),
  },
  response: {
    nodes: BundleTCFStack.list(),
    totalCount: 'int',
  },
});

export const setAirgapBundleTcfStacks = mkMutation({
  name: 'setAirgapBundleTcfStacks',
  comment: 'set TCF stacks for an airgap bundle',
  params: {
    input: SetTCFStacksInput,
  },
  response: {
    nodes: TCFStack.list(),
  },
});

export const consentManagerConfigurationJson = mkQuery({
  name: 'consentManagerConfigurationJson',
  comment: 'The configuration associated with a given consent manager.',
  params: {
    id: {
      modelName: 'airgapBundle',
      type: 'id',
    },
  },
  response: {
    consentManagerConfigurationJson: ConsentManagerConfigurationJson,
  },
});

export const classifyDataFlows = mkMutation({
  name: 'classifyDataFlows',
  comment:
    'Retrieve suggestions for tracking type and service based on data flow values',
  params: {
    input: ClassifyDataFlowsInput,
  },
  response: {
    dataFlowSuggestions: DataFlowSuggestion.list(),
  },
});

export const classifyCookies = mkMutation({
  name: 'classifyCookies',
  comment:
    'Retrieve suggestions for purpose and service based on cookie values',
  params: {
    input: ClassifyCookiesInput,
  },
  response: {
    cookieSuggestions: CookieSuggestion.list(),
  },
});

export const consentWorkflowTriggers = mkQuery({
  name: 'consentWorkflowTriggers',
  comment:
    'Query for all consent workflow triggers setup for a given Organization',
  params: {
    ...PAGINATION_INPUT_PARAMS,
    filterBy: ConsentWorkflowTriggersFilterInput.optional(),
  },
  response: {
    nodes: ConsentWorkflowTrigger.list(),
    totalCount: 'int',
  },
});

export const createOrUpdateConsentWorkflowTrigger = mkMutation({
  name: 'createOrUpdateConsentWorkflowTrigger',
  comment: 'create or update a consent workflow trigger',
  params: {
    input: CreateOrUpdateConsentWorkflowTriggerInput,
  },
  response: {
    consentWorkflowTrigger: ConsentWorkflowTrigger,
  },
});

export const deleteConsentWorkflowTriggers = mkMutation({
  name: 'deleteConsentWorkflowTriggers',
  comment: 'deletes list of consent workflow triggers by id',
  params: {
    input: DeleteConsentWorkflowTriggersInput,
  },
  response: {
    count: 'int',
  },
});

export const preferenceTopics = mkQuery({
  name: 'preferenceTopics',
  comment: 'Query for all preference topics',
  params: {
    ...PAGINATION_INPUT_PARAMS,
    filterBy: PreferenceTopicFilterInput.optional(),
  },
  response: {
    nodes: PreferenceTopic.list(),
    totalCount: 'int',
  },
});

export const createOrUpdatePreferenceTopic = mkMutation({
  name: 'createOrUpdatePreferenceTopic',
  comment: 'create or update a preference topic',
  params: {
    input: CreateOrUpdatePreferenceTopicInput,
  },
  response: {
    preferenceTopic: PreferenceTopic,
  },
});

export const deletePreferenceTopics = mkMutation({
  name: 'deletePreferenceTopics',
  comment: 'deletes list of preference topics by id',
  params: {
    input: DeletePreferenceTopicsInput,
  },
  response: {
    count: 'int',
  },
});

export const preferenceOptionValues = mkQuery({
  name: 'preferenceOptionValues',
  comment: 'Query for all preference option values',
  params: {
    ...PAGINATION_INPUT_PARAMS,
    filterBy: PreferenceOptionValueFilterInput.optional(),
  },
  response: {
    nodes: PreferenceOptionValue.list(),
    totalCount: 'int',
  },
});

export const createOrUpdatePreferenceOptionValues = mkMutation({
  name: 'createOrUpdatePreferenceOptionValues',
  comment: 'create or update preference option values',
  params: {
    input: CreateOrUpdatePreferenceOptionValuesInput,
  },
  response: {
    preferenceOptionValues: PreferenceOptionValue.list(),
  },
});

export const deletePreferenceOptionValues = mkMutation({
  name: 'deletePreferenceOptionValues',
  comment: 'deletes list of preference option values by id',
  params: {
    input: DeletePreferenceOptionValuesInput,
  },
  response: {
    count: 'int',
  },
});

export const recordsOfConsent = mkQuery({
  name: 'recordsOfConsent',
  comment: 'Query for fetching historical records of consent',
  params: {
    input: RecordsOfConsentInput,
    dhEncrypted: {
      type: 'string',
      underlyingType: t.type({}),
    },
  },
  response: {
    nodes: ConsentRecordSchema.list(),
    nextToken: RecordOfConsentNextToken.optional(),
    decryptionContext: DecryptionContext,
  },
});
/* eslint-enable max-lines */
