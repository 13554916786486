import styled, { css, Keyframes, keyframes } from 'styled-components';

export const LabelsSection = styled.div<{
  /** add padding to either end */
  padExtremes: boolean;
  /** colors for gradient */
  colorStops: Array<string>;
}>`
  text-align: justify;
  padding: ${({ padExtremes }) =>
    padExtremes ? '8px calc(12% - 8px)' : '8px'};
  width: 100%;

  :after {
    content: "";
    display: inline-block;
    width: 100%;
  }

  font-size: 14px;
  font-weight: 600;
  color: transparent;
  -webkit-background-clip: text;
  background-image: ${({ colorStops }) =>
    `linear-gradient(0.25turn, ${colorStops.join(', ')});`};
}
`;

const getKeyframe = (color: string, percent: number): string =>
  `${percent}% {color: ${color}}`;

const getKeyframes = (colorStops: Array<string>): Keyframes =>
  keyframes`{
    ${colorStops
      .map((cs, idx) => getKeyframe(cs, (idx / (colorStops.length - 1)) * 100))
      .join(' ')}
  }`;

export const Input = styled.input<{
  /** stops in color gradient */
  colorStops: Array<string>;
  /** current value of slider as a percent of the max */
  valueAsPercent: number;
  /** if we need to add padding on both ends of the slider */
  padExtremes: boolean;
}>`
  position: relative;
  width: 100%;
  padding: ${({ padExtremes }) => (padExtremes ? '0 12%' : '')};
  height: 0;
  z-index: 2;
  outline: none;
  -webkit-appearance: none;

  :focus :focus-visible ::-webkit-slider-thumb {
    border-color: ${({ theme }) => theme.colors.transcend3};
    background: currentColor;
    border: 3px solid ${({ theme }) => theme.colors.gray2};
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
  }

  :focus :focus-visible + div {
    border-color: ${({ theme }) => theme.colors.transcend3};
  }

  animation: ${({ colorStops }) => css`
    ${getKeyframes(colorStops)} 1s linear forwards paused;
  `};
  animation-delay: ${({ valueAsPercent }) => -(valueAsPercent / 100)}s;

  ::-webkit-slider-thumb {
    -webkit-appearance: none;
    height: 17px;
    width: 17px;
    border: 0.5px solid ${({ theme }) => theme.colors.transcendNavy4};
    background: ${({ theme }) => theme.colors.gray2};
    border-radius: 50%;
    cursor: pointer;
    transition: all 200ms ease-out;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);

    :hover {
      // the webkit-slider-thumb pseudo element doesn't support animation
      // so we inherit the parent color set by the animation
      background: currentColor;
      border: 4px solid ${({ theme }) => theme.colors.gray2};
      box-shadow: 0 1px 2px 1px rgba(0, 0, 0, 0.2);
    }

    :active {
      transition: 0ms all;
      transform: scale(92%);
      border: 4px solid ${({ theme }) => theme.colors.gray2};
      box-shadow: 0 1px 2px 1px rgba(0, 0, 0, 0.2);
      // the webkit-slider-thumb pseudo element doesn't support animation
      // so we inherit the parent color set by the animation
      background: currentColor;
    }
  }
}
`;

export const FocusRing = styled.div`
  z-index: 1;
  background: transparent;
  border: 1px solid transparent;
  margin-top: -9px;
  position: absolute;
  height: 5px;
  width: 100%;
  border-radius: 3px;
  transition: all 200ms ease-out;
`;

export const Track = styled.div`
  margin-top: -9px;
  position: relative;
  height: 5px;
  width: 100%;
`;

export const TrackBase = styled.div<{
  /** stops of the color gradient */
  colorStops: Array<string>;
}>`
  left: 0;
  width: 100%;
  border-radius: 3px;
  position: absolute;
  top: 0;
  height: 100%;
  max-width: 100%;
  background: ${({ colorStops }) =>
    /** color stops for track gradient */
    `linear-gradient(0.25turn, ${colorStops.join(', ')});`};
`;

export const TrackMaskDisabled = styled.div`
  left: 0;
  width: 100%;
  border-radius: 3px;
  position: absolute;
  top: 0;
  height: 100%;
  max-width: 100%;
  background: ${({ theme }) => theme.colors.transcend4};
`;

export interface TrackMaskProps {
  /** number of steps in the range input */
  totalTrackIncrements: number;
  /** normalized value */
  valueAsSteps: number;
  /** if we need to add padding on both ends of the slider */
  padExtremes: boolean;
}

export const TrackMaskLeft = styled.div<TrackMaskProps>`
  left: 0;
  border-radius: 3px 0 0 3px;
  background: ${({ theme }) => theme.colors.transcend4};
  position: absolute;
  top: 0;
  height: 100%;
  max-width: 100%;
  width: ${({ valueAsSteps, padExtremes, totalTrackIncrements }) =>
    `calc((((100% - ${
      padExtremes ? '24%' : '0px'
    }) / ${totalTrackIncrements}) * ${valueAsSteps}) + ${
      padExtremes
        ? `12% ${
            valueAsSteps === 0
              ? '+ 8px'
              : valueAsSteps === totalTrackIncrements
                ? '- 8px'
                : ''
          }`
        : '0px'
    })`};
`;

export const TrackMaskRight = styled.div<TrackMaskProps>`
  right: 0;
  border-radius: 0 3px 3px 0;
  background: ${({ theme }) => theme.colors.transcend4};
  position: absolute;
  top: 0;
  height: 100%;
  width: ${({ valueAsSteps, padExtremes, totalTrackIncrements }) =>
    `calc(100% - ((((100% - ${
      padExtremes ? '24%' : '0px'
    }) / ${totalTrackIncrements}) * ${valueAsSteps}) + ${
      padExtremes
        ? `12% ${
            valueAsSteps === 0
              ? '+ 8px'
              : valueAsSteps === totalTrackIncrements
                ? '- 8px'
                : ''
          }`
        : '0px'
    }))`};
  max-width: 100%;
`;
