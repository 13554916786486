import { useInlineInput } from '@main/ad-core-components';
import {
  buildUseMutation,
  buildUseQuery,
  formatErrorMessage,
  message,
} from '@main/core-ui';
import {
  DataSubject,
  deriveDataSubjectAllowlist,
  endpoints,
} from '@main/datamap-types';
import type { ID } from '@main/schema-utils';
import React, { useEffect, useMemo } from 'react';
import type { OptionsType } from 'react-select';

import { SelectSubjects } from '../SelectSubjects';
import { DATA_MAP_DATA_SUBJECT_FIELDS } from '../SelectSubjects/constants';

const useUpdateDataSilos = buildUseMutation(endpoints.updateDataSilos);
const useInternalSubjects = buildUseQuery(
  endpoints.internalSubjects,
  'DataMapSiloSubjectSelect',
  {
    ...DATA_MAP_DATA_SUBJECT_FIELDS,
  },
);

/** Props for the `SiloSubjectsSelect` component */
interface SiloSubjectsSelectProps {
  /** The ID of the parent data silo for this cell */
  id: ID<'dataSilo'>;
  /** The BLOCK LIST of data subjects */
  value: DataSubject[];
  /** Whether to show the subject as inline */
  inline: boolean;
  /** Whether to disable the select input */
  isDisabled?: boolean;
}

export const SiloSubjectsSelect: React.FC<SiloSubjectsSelectProps> = ({
  id,
  value: blockList,
  inline,
  ...props
}) => {
  const {
    data: subjects,
    loading: subjectsLoading,
    error: subjectsError,
  } = useInternalSubjects();
  const [updateDataSilos, { loading }] = useUpdateDataSilos();

  // Flash error if we can't load the options
  useEffect(() => {
    if (subjectsError) {
      message.error(formatErrorMessage(subjectsError.message));
    }
  }, [subjectsError]);

  const deriveBlockList = (values: DataSubject[]): ID<'subject'>[] =>
    (subjects || [])
      .filter(
        (subject) =>
          !values.some((allowedSubject) => allowedSubject.id === subject.id),
      )
      .map(({ id }) => id);

  const initialOptions = useMemo(
    () => deriveDataSubjectAllowlist(blockList, subjects),
    [subjects, blockList],
  );
  const { value, setValue, onBlur } = useInlineInput<DataSubject[]>({
    initialValue: initialOptions,
    optionsComparator: (existing, updated) =>
      JSON.stringify(existing.map(({ id }) => id).sort()) ===
      JSON.stringify(updated.map(({ id }) => id).sort()),
    onUpdate: (values) =>
      updateDataSilos({
        variables: {
          input: {
            dataSilos: [
              {
                id,
                dataSubjectBlockListIds: deriveBlockList(values),
              },
            ],
          },
        },
      }).then(({ data, errors }) => {
        const blockList = data?.dataSilos[0]?.subjectBlocklist;
        return {
          data: blockList
            ? deriveDataSubjectAllowlist(blockList, subjects)
            : undefined,
          errors,
        };
      }),
  });

  return (
    <SelectSubjects
      {...(inline
        ? {
            overflow: 'badge',
            showOutline: false,
          }
        : {
            showOutline: true,
          })}
      value={value}
      onChange={(updated: OptionsType<DataSubject>) => setValue([...updated])}
      onBlur={onBlur}
      isLoading={loading || subjectsLoading}
      {...props}
    />
  );
};
