import { UserPreview } from '@main/access-control-types';
import { Icon } from '@main/core-ui';
import type { RequestAction } from '@transcend-io/privacy-types';
import React from 'react';
import { useIntl } from 'react-intl';
import { useTheme } from 'styled-components';

import { MultiItemOverflowDisplay } from '../MultiItemOverflowDisplay';
import { REQUEST_ACTION_TO_COMPONENT_MAP } from './constants';
import { requestActionMessages, workflowCardMessages } from './messages';
import {
  StyledCardDetails,
  StyledCardTitle,
  StyledCountDetails,
  StyledCountLabels,
  StyledCountNumbers,
  StyledWorkflowCard,
} from './wrappers';

export interface WorkflowCardProps {
  /** The request type */
  requestType: RequestAction;
  /** List of respective integrations */
  integrations: {
    /** Integration display name */
    displayName: string;
    /** Logo */
    logo: string;
    /** ID */
    id: string;
  }[];
  /** Number of total integrations */
  integrationCount: number;
  /** Number of datapoints */
  datapointsCount: number;
  /** Number of requests processed */
  requestsProcessedCount: number;
  /** Whether or not the workflow is live (processing requests) */
  isLive: boolean;
  /** Owners of this workflow */
  owners: UserPreview[];
  /** Link to the corresponding request type details */
  href: string;
}

/**
 * The workflow card component that will display for each request type and its respective details
 */
export const WorkflowCard: React.FC<WorkflowCardProps> = ({
  requestType,
  integrations,
  integrationCount,
  datapointsCount,
  requestsProcessedCount,
  isLive,
  owners,
  href,
}) => {
  const { formatMessage } = useIntl();
  const theme = useTheme();
  const requestActionComponents = REQUEST_ACTION_TO_COMPONENT_MAP[requestType];

  return (
    <StyledWorkflowCard href={href} $isLive={isLive}>
      <div style={{ display: 'flex' }}>
        <Icon
          type={requestActionComponents.icon}
          size={40}
          color={theme.colors[requestActionComponents.color]}
          color2={
            requestActionComponents.color2 &&
            theme.colors[requestActionComponents.color2]
          }
        />
        <MultiItemOverflowDisplay
          isInline
          isCircle
          borderless
          items={owners.map((owner) => ({
            displayName: owner.name,
            logo: owner.profilePicture,
            id: owner.id,
          }))}
          limit={2}
        />
      </div>
      <StyledCardDetails>
        <StyledCardTitle>
          {formatMessage(requestActionMessages[requestType])}
        </StyledCardTitle>
        <StyledCountDetails>
          <div>
            <StyledCountNumbers>{integrationCount}</StyledCountNumbers>
            <StyledCountLabels>
              {formatMessage(workflowCardMessages.integrationsCount, {
                count: integrations.length,
              })}
            </StyledCountLabels>
          </div>
          <div>
            <StyledCountNumbers>{datapointsCount}</StyledCountNumbers>
            <StyledCountLabels>
              {formatMessage(workflowCardMessages.datapointsCount, {
                count: datapointsCount,
              })}
            </StyledCountLabels>
          </div>
          <div>
            <StyledCountNumbers>{requestsProcessedCount}</StyledCountNumbers>
            <StyledCountLabels>
              {formatMessage(workflowCardMessages.requestsProcessedCount, {
                count: requestsProcessedCount,
              })}
            </StyledCountLabels>
          </div>
        </StyledCountDetails>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <MultiItemOverflowDisplay
            isInline
            isCircle={false}
            items={integrations}
            limit={6}
            totalCount={integrationCount}
          />
          {formatMessage(
            isLive
              ? workflowCardMessages.isLive
              : workflowCardMessages.isNotLive,
          )}
        </div>
      </StyledCardDetails>
    </StyledWorkflowCard>
  );
};
