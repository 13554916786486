import isEqual from 'lodash/isEqual';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';

import {
  FetchResult,
  formatErrorMessage,
  message,
  usePrompt,
} from '@main/core-ui';
import { isEmptyStr, isNull } from '@main/utils';

import { inlineUpdateMessages } from './messages';

/**
 * Response type for an inline update callback
 */
export type InlineUpdateResponse<ValueType> = Pick<
  FetchResult<ValueType>,
  'data' | 'errors'
>;
export interface InlineInputProps<ValueType> {
  /** Initial value */
  initialValue: ValueType;
  /** Function to call when the options have changed */
  onUpdate: (
    valueOptions: ValueType,
  ) => Promise<InlineUpdateResponse<ValueType>>;
  /** Function to compare the currently value options to the previous set to see if they have changed */
  optionsComparator?: (value1: ValueType, value2: ValueType) => boolean;
}

/**
 * Hook to DRY repetitive logic around state management of inline edit components
 *
 * @param options - InlineInputProps
 * @returns The current values and hooks to manage the value,
 * as well as an onBlur callback that will optionally apply the update if the value has changed
 */
export function useInlineInput<ValueType>({
  initialValue,
  onUpdate,
  optionsComparator = isEqual,
}: InlineInputProps<ValueType>): {
  /** The (internally updated) values */
  value: ValueType;
  /** Change the internal value */
  setValue: (values: ValueType) => void;
  /** Callback when the input component blurs */
  onBlur: () => void;
} {
  const { formatMessage } = useIntl();
  const [previousValue, setPreviousValue] = useState<ValueType>(initialValue);
  const [value, setValue] = useState<ValueType>(previousValue);

  // keep track of whether the component is mounted so that we don't try to update the component
  // after it has unmounted, e.g. when the update causes a refetch, which removes the row from the table
  const mountedRef = useRef(true);
  useEffect(
    () => () => {
      mountedRef.current = false;
    },
    [],
  );

  useEffect(() => {
    if (!isEqual(previousValue, initialValue)) {
      setPreviousValue(initialValue);
      setValue(initialValue);
    }
  }, [initialValue]);

  usePrompt(
    formatMessage(inlineUpdateMessages.unsaved),
    !optionsComparator(value, previousValue),
  );

  // We only want to fire the onUpdate IF the values have actually changed
  const onBlur = useCallback(() => {
    const hasChanged = !optionsComparator(value, previousValue);
    if (hasChanged) {
      onUpdate(value)
        .then(({ data, errors }) => {
          if (!mountedRef.current) return;
          if (
            typeof data !== 'undefined' &&
            ((!isNull(value) && data !== null) ||
              // if clearing, show success indicator
              ((isNull(value) || isEmptyStr(value)) && data === null))
          ) {
            setPreviousValue(data!);
            setValue(data!);
            message.success(formatMessage(inlineUpdateMessages.updateSuccess));
          } else if (errors && errors.length > 0) {
            message.error(formatErrorMessage(errors[0].message));
            // reset values on error
            setValue(previousValue);
          }
        })
        .catch((error) => {
          if (error) {
            message.error(formatErrorMessage(error.message));
            if (!mountedRef.current) return;
            // reset values on error
            setValue(previousValue);
          }
        });
    }
  }, [optionsComparator, value, previousValue, onUpdate, formatMessage]);

  return {
    value,
    setValue,
    onBlur,
  };
}
