import { DataFlowScope } from '@transcend-io/privacy-types';

import type { DataFlowDescriptor } from './types';

const FQDN = /\.+$/g;
// Normalizes FQDNs by removing trailing periods.
export const normalizeFQDN = (host: string): string => host.replace(FQDN, '');

/**
 * Determine whether an input host matches a target host
 *
 * @param searchHost - Target host to match
 * @param url - Input URL to match against
 * @returns True if input host matches target host, otherwise false
 */
export const matchHost = (searchHost: string, { hostname }: URL): boolean => {
  const parts = normalizeFQDN(hostname).split('.');
  const { length } = parts;
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < length; i++) {
    // iterate from most specific domain down to least-specific
    const host = parts.slice(i).join('.');
    if (host === searchHost) {
      return true;
    }
  }
  return false;
};

/**
 * Determine whether an input URL matches a target query param
 *
 * @param queryParam - Target query param to match
 * @param url - Input URL to match against
 * @returns True if input URL matches target query param, otherwise false
 */
export const matchQueryParam = (
  queryParam: string,
  { searchParams }: URL,
): boolean => {
  // only parse first detected query param from data flow
  const [param, value] = (
    (new URLSearchParams(queryParam) as any).entries() as Iterator<
      [string, string]
    >
  ).next().value;
  return (
    searchParams.has(param) &&
    (value === '' || searchParams.get(param)!.startsWith(value))
  );
};

/**
 * Get the part of a URL that a regulator will need to
 * search for our matcher comparisons in doesRegulatorMatchURL()
 *
 * @param url - URL to process
 * @param after - Part to slice after
 * @returns URL without a protocol
 */
const getSearchPart = <TPart extends 'protocol' | 'origin'>(
  url: URL | Pick<URL, TPart | 'href'>,
  after: TPart,
): string => url.href.slice(url[after].length);

/**
 * Determine if a URL matches a path data flow.
 *
 * @param matcher - Path data flow matcher string
 * @param url - A URL to check
 * @param context - Context page URL for same-site relative matchers
 * @returns True if the URL matches the data flow, otherwise false
 */
export const matchPath = (matcher: string, url: URL, context: URL): boolean => {
  const isRelative = matcher[0] === '/';
  // Is this an any-site relative path matcher? (///...)
  const anySite = isRelative && matcher[1] === '/' && matcher[2] === '/';
  const searchPart = anySite
    ? // slice after origin for any-site relative path matchers
      'origin'
    : // slice after protocol for relative protocol matchers
      'protocol';
  return isRelative
    ? getSearchPart(url, searchPart).startsWith(
        getSearchPart(
          // Resolve matcher
          new URL(
            anySite // Convert any-site matchers from ///... to /...
              ? matcher.slice(2)
              : matcher,
            context,
          ),
          searchPart,
        ),
      )
    : // absolute matcher
      url.href.startsWith(matcher);
};

/**
 * Determine if a URL matches a RegExp data flow.
 *
 * @param matcher - RegExp data flow matcher
 * @param url - A URL to check
 * @returns True if the URL matches the data flow, otherwise false
 */
export const matchRegExp = (matcher: string | RegExp, url: URL): boolean =>
  (typeof matcher === 'string' ? new RegExp(matcher) : matcher).test(url.href);

/**
 * Determine if a URL matches a CSP entry data flow, using native browser CSP
 * capabilities.
 *
 * @param cspEntry - CSP entry data flow string
 * @param url - A URL to check
 * @returns True if the URL matches the data flow, otherwise false
 */
export const matchCSPEntry = (cspEntry: string, url: URL): Promise<boolean> =>
  new Promise((resolve) => {
    if (typeof document === 'undefined') {
      throw new Error('CSP entry data flow matching requires the DOM API');
    }
    const HTML = 'http://www.w3.org/1999/xhtml';
    const frame = (document.documentElement || document).appendChild(
      document.createElementNS(HTML, 'iframe') as HTMLIFrameElement,
    );
    frame.style.display = 'none';
    // eslint-disable-next-line no-multi-assign
    frame.width = frame.height = '0';

    const doc = frame.contentDocument!;
    let resolved = false;
    const resolveTest = (status: boolean): void => {
      if (!resolved) {
        resolved = true;
        img.removeAttribute('src');
        img.remove();
        frame.remove();
        resolve(status);
      }
    };
    const isFirefox =
      typeof navigator !== 'undefined' &&
      navigator.userAgent.includes('Firefox/');
    // Detect CSP violation
    (isFirefox // Firefox doesn't send CSP violation events to iframe doc
      ? document
      : doc
    ).addEventListener(
      'securitypolicyviolation',
      () => {
        resolveTest(false);
      },
      { once: true },
    );
    const meta = doc.createElementNS(HTML, 'meta') as HTMLMetaElement;
    meta.httpEquiv = 'Content-Security-Policy';
    meta.content = `default-src ${cspEntry};`;
    doc.head.appendChild(meta).remove();
    const img = doc.createElementNS(HTML, 'img') as HTMLImageElement;
    doc.body.appendChild(img);
    img.src = url.href;
    // Resolve successfully if no CSP violations in ≥20ms
    setTimeout(() => {
      resolveTest(true);
    }, 20);
  });

/**
 * Determine if data flow descriptor matches URL
 *
 * @param dataFlow - Data flow descriptor to use
 * @param url - URL to match against
 * @param context - Context URL to use for 'same-site' relative path matchers
 * @returns True if url matches data flow, false otherwise
 */
export const matchDataFlow = async (
  dataFlow: DataFlowDescriptor,
  url: URL,
  context = url,
  // eslint-disable-next-line require-await
): Promise<boolean> => {
  const { scope, value } = dataFlow;

  switch (scope) {
    case DataFlowScope.Host:
      return matchHost(value, url);
    case DataFlowScope.Path:
      return matchPath(value, url, context);
    case DataFlowScope.QueryParam:
      return matchQueryParam(value, url);
    case DataFlowScope.RegExp:
      return matchRegExp(value, url);
    case DataFlowScope.CSP:
      return matchCSPEntry(value, url);
    default:
      throw new Error(`Unrecognized data flow scope: ${scope}`);
  }
};
