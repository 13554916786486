import {
  PaginatedSelect,
  PaginatedSelectProps,
} from '@main/ad-core-components';
import { PromptGroupPreview } from '@main/attribute-types';
import { buildUseInfiniteScroll, Icon } from '@main/core-ui';
import { endpoints, PromptGroupOrderField } from '@main/prompt-types';
import { createNewId, ID, OrderDirection } from '@main/schema-utils';
import React, { useState } from 'react';
import { MenuPosition } from 'react-select';

import { selectPromptGroupsMessages } from './messages';

const NODES = {
  id: null,
  title: null,
} as const;

/**
 * Selected prompt
 */
export type SelectedPromptGroup = Pick<
  PromptGroupPreview,
  keyof typeof NODES
> & {
  /** Whether prompt is newly created prompt or existing prompt */
  isNew?: boolean;
};

const useSelectPromptGroups = buildUseInfiniteScroll(
  endpoints.promptGroups,
  'SelectPromptGroups',
  {
    nodes: NODES,
  },
);

export interface SelectPromptGroupProps
  extends Omit<
    PaginatedSelectProps<SelectedPromptGroup, true>,
    'isQueryLoading' | 'queryError' | 'fetchMore' | 'onEndsTyping' | 'onChange'
  > {
  /** On change handler */
  onChange?: (promptGroups: SelectedPromptGroup[]) => void;
  /** Show option to create prompt */
  allowCreate?: boolean;
  /** How to position the menu (see docs for React Select) */
  menuPosition?: MenuPosition;
}

/**
 * Select a specific prompt
 */
export const SelectPromptGroups: React.FC<SelectPromptGroupProps> = ({
  allowCreate = false,
  placeholderDescriptor = selectPromptGroupsMessages.placeholder,
  showOutline = true,
  onChange,
  menuPosition = 'fixed',
  ...paginatedSelectProps
}) => {
  const [searchText, setSearchText] = useState('');
  const { data, loading, error, fetchMore } = useSelectPromptGroups({
    variables: {
      filterBy: {
        text: searchText,
      },
      orderBy: [
        { field: PromptGroupOrderField.Title, direction: OrderDirection.ASC },
      ],
    },
    fetchPolicy: 'cache-and-network',
  });

  return (
    <PaginatedSelect
      id="select-prompt-groups"
      showOutline={showOutline}
      placeholderDescriptor={placeholderDescriptor}
      options={data?.nodes ?? []}
      onChange={onChange as any}
      isQueryLoading={loading}
      isCreatable={allowCreate}
      isMulti
      menuPosition={menuPosition}
      getOptionValue={({ id }: SelectedPromptGroup) => id}
      getOptionLabel={({ title }: SelectedPromptGroup) => title}
      queryError={error}
      getOptionLogo={({ isNew }: SelectedPromptGroup) =>
        isNew ? <Icon type="circle-add" /> : ''
      }
      getNewOptionData={(inputValue): SelectedPromptGroup => ({
        isNew: true as const,
        title: inputValue,
        id: createNewId<'promptGroup'>(),
      })}
      fetchMore={fetchMore}
      onEndsTyping={setSearchText}
      {...paginatedSelectProps}
    />
  );
};

/**
 * Select a single prompt group
 */
export const SelectPromptGroup: React.FC<
  Omit<SelectPromptGroupProps, 'value' | 'onChange'> & {
    /** Set the newly selected prompt groups */
    onChange?: (promptGroup: SelectedPromptGroup) => void;
    /** Selected value */
    value?: SelectedPromptGroup | ID<'promptGroup'>;
  }
> = ({ onChange, value, ...props }) => (
  <SelectPromptGroups
    {...props}
    isMulti={false}
    value={
      // default type is mixed array rather than array of one type or the other
      value ? ([value] as SelectedPromptGroup[] | ID<'promptGroup'>[]) : []
    }
    onChange={onChange ? ([value]) => onChange(value) : undefined}
  />
);
