/**
 * This is the entry file for the application, only setup and boilerplate code.
 */

/* eslint-disable import/no-import-module-exports */
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import './assets/favicon/favicon.png';
import './assets/font-awesome.css';
import '@main/core-ui/bootstrap-theme.scss';

import { datadogLogs } from '@datadog/browser-logs';
import { LanguageProvider } from '@main/admin-dashboard/src/Auth/LanguageProvider';
import {
  BUILD_VERSION,
  DATADOG_LOGS_CLIENT_TOKEN,
  DATADOG_SAMPLE_RATE,
  DEPLOY_ENV,
  FeatureFlagProvider,
} from '@main/core-ui';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import * as streamSaver from 'streamsaver';

import { DATADOG_CONTAINER_NAME, DATADOG_SERVICE } from './args';
import { BackendGraphQLProvider } from './Auth/App/BackendGraphQLProvider';
import { initAmplitude } from './Auth/App/tracking';
import { setDataDogContext } from './datadog';
import Routes from './routes';
import { adminDashboardStore } from './store';

// Load the favicon, the manifest.json file and the .htaccess file
// TODO: https://github.com/transcend-io/main/issues/2945 - (pc as well) import './manifest.notjson';

// Create redux store with history
const MOUNT_NODE = document.getElementById('app');

// Module with hot reload
const persistor = persistStore(adminDashboardStore);

// polyfill IE11 & Safari 12-
if (!Intl.PluralRules) {
  require('intl-pluralrules'); // eslint-disable-line global-require
}

// We host our own streamSaver files to appease Firefox Enhanced Tracking Protection
// eslint-disable-next-line @typescript-eslint/no-explicit-any
(streamSaver as any).mitm = '/penumbra/streamsaver/endpoint.html';

const render = (): void => {
  ReactDOM.render(
    <Provider store={adminDashboardStore}>
      <PersistGate loading={null} persistor={persistor}>
        <BackendGraphQLProvider>
          <LanguageProvider>
            <FeatureFlagProvider>
              <BrowserRouter>
                <Routes />
              </BrowserRouter>
            </FeatureFlagProvider>
          </LanguageProvider>
        </BackendGraphQLProvider>
      </PersistGate>
    </Provider>,
    MOUNT_NODE,
  );
};

// Enable log forwarding
if (DATADOG_LOGS_CLIENT_TOKEN) {
  datadogLogs.init({
    site: 'datadoghq.com',
    sampleRate: DATADOG_SAMPLE_RATE,
    sessionSampleRate: DATADOG_SAMPLE_RATE,
    env: DEPLOY_ENV,
    service: DATADOG_SERVICE,
    clientToken: DATADOG_LOGS_CLIENT_TOKEN,
    forwardErrorsToLogs: true,
    version: BUILD_VERSION,
  });
  setDataDogContext('container', DATADOG_CONTAINER_NAME);
  setDataDogContext('DEPLOY_ENV', DEPLOY_ENV);
  setDataDogContext('BUILD_VERSION', BUILD_VERSION);
}

// Initialize Amplitude
initAmplitude();

if (module.hot) {
  // Hot reloadable React components and translation json files
  // Modules.hot.accept does not accept dynamic dependencies,
  // have to be constants at compile-time
  module.hot.accept(['./Auth/App'], () => {
    ReactDOM.unmountComponentAtNode(MOUNT_NODE as any);
    render();
  });
}

// Chunked polyfill for browsers without Intl support
if (!window.Intl) {
  new Promise((resolve) => {
    resolve(import('intl'));
  })
    .then(() => Promise.all([import('intl/locale-data/jsonp/en.js' as any)])) // eslint-disable-line @typescript-eslint/no-explicit-any,max-len
    .then(() => render())
    .catch((err) => {
      throw err;
    });
} else {
  render();
}
/* eslint-enable import/no-import-module-exports */
