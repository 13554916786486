import {
  FormInput,
  FormStatus,
  FormSubmitButton,
  FormTextArea,
  mutationToFormStatusProps,
} from '@main/ad-core-components';
import {
  CreateRepositoryInput,
  endpoints,
  Repository,
} from '@main/code-scanning-types';
import {
  buildUseMutation,
  FlexColumnCenterHorizontal,
  Form,
  useForm,
  Validators,
} from '@main/core-ui';
import React from 'react';
import { useIntl } from 'react-intl';

import { newRepositoryFormMessages } from './messages';

const useCreateRepository = buildUseMutation(endpoints.createRepository);

export const NewRepositoryForm: React.FC<{
  /** On success callback */
  onSuccess?: (repository: Repository) => void;
}> = ({ onSuccess }) => {
  const { formatMessage } = useIntl();
  const formMethods = useForm<CreateRepositoryInput>({});
  const [createRepository, createRepositoryResult] = useCreateRepository({
    refetchQueries: ['Repositories'],
    awaitRefetchQueries: true,
  });

  return (
    <Form
      useFormMethods={formMethods}
      onSubmit={async (input) => {
        const { data } = await createRepository({
          variables: {
            input,
          },
        });
        if (onSuccess && data) {
          onSuccess(data.repository);
        }
      }}
    >
      <FormInput
        id="name"
        name="name"
        label={newRepositoryFormMessages.nameLabel}
        rules={[Validators.REQUIRED]}
        required
      />
      <FormInput
        id="url"
        name="url"
        label={newRepositoryFormMessages.urlLabel}
        rules={[Validators.REQUIRED, Validators.URL]}
        required
      />
      <FormTextArea
        id="description"
        name="description"
        label={newRepositoryFormMessages.descriptionLabel}
      />
      <FlexColumnCenterHorizontal>
        <FormSubmitButton loading={createRepositoryResult.loading}>
          {formatMessage(newRepositoryFormMessages.submitButton)}
        </FormSubmitButton>
        <FormStatus
          {...mutationToFormStatusProps(createRepositoryResult)}
          successMessage={newRepositoryFormMessages.submitSuccess}
        />
      </FlexColumnCenterHorizontal>
    </Form>
  );
};
