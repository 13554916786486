/**
 * There are two types of controls in TipTap:
 * - Nodes (https://tiptap.dev/api/nodes) - (paragraph, ordered list, heading, ...)
 * - Marks (https://tiptap.dev/api/marks) - (bold, italic, ...)
 */

/**
 * TipTap NodeTypes
 * String values are the same as the TipTap Node's `name`
 *
 * @example https://github.com/ueberdosis/tiptap/blob/e2f8747e2b10f98d49798b15d64db5f0a36a3427/packages/extension-bullet-list/src/bullet-list.ts#L22
 */
export enum TipTapNodeType {
  Paragraph = 'paragraph',
  Heading = 'heading',
  OrderedList = 'orderedList',
  BulletList = 'bulletList',
  Table = 'table',
  Image = 'image',
}

/**
 * TipTap MarkTypes
 * String values are the same as the TipTap Mark's `name`
 *
 * @example https://github.com/ueberdosis/tiptap/blob/e2f8747e2b10f98d49798b15d64db5f0a36a3427/packages/extension-bold/src/bold.ts#L37
 */
export enum TipTapMarkType {
  Bold = 'bold',
  Italic = 'italic',
  Underline = 'underline',
  Strike = 'strike',
  Link = 'link',
}
