import { AttributeValue } from '@main/attribute-types';
import { mkInput, mkOrder, mkType, SchemaToType } from '@main/schema-utils';

import { LegalMatterOrderField } from './enums';

export const LegalMatterInput = mkInput({
  name: 'LegalMatterInput',
  comment: 'Filters for legal matters',
  fields: {
    identifierName: {
      comment: 'The name of the identifier to search for matters matching',
      type: 'string',
    },
  },
});

/** Override type */
export type LegalMatterInput = SchemaToType<typeof LegalMatterInput>;

export const CreateLegalMatterInput = mkInput({
  name: 'CreateLegalMatterInput',
  comment: 'Create a new legal matter',
  fields: {
    title: {
      comment: 'The title of the legal matter',
      type: 'string',
    },
    description: {
      comment: 'The description of the legal matter',
      type: 'string',
    },
  },
});

/** Override type */
export type CreateLegalMatterInput = SchemaToType<
  typeof CreateLegalMatterInput
>;

export const UpdateLegalMatterInput = mkInput({
  name: 'UpdateLegalMatterInput',
  comment: 'Update a new legal matter',
  fields: {
    id: {
      comment: 'The ID of the legal matter',
      type: 'id',
      modelName: 'legalMatter',
    },
    title: {
      comment: 'The title of the legal matter',
      type: 'string',
      optional: true,
    },
    description: {
      comment: 'The description of the legal matter',
      type: 'string',
      optional: true,
    },
  },
});

/** Override type */
export type UpdateLegalMatterInput = SchemaToType<
  typeof UpdateLegalMatterInput
>;

export const DeleteLegalMattersInput = mkInput({
  name: 'DeleteLegalMattersInput',
  comment: 'Input for deleting legal matters',
  fields: {
    ids: {
      comment: 'The list of IDs to delete',
      type: 'id',
      modelName: 'legalMatter',
      list: true,
    },
  },
});

/** Override type */
export type DeleteLegalMattersInput = SchemaToType<
  typeof DeleteLegalMattersInput
>;

export const LegalMattersFiltersInput = mkInput({
  name: 'LegalMattersFiltersInput',
  comment: 'Filters for legal matters',
  fields: {
    text: {
      comment: 'Find any legal matters are iLike a text string',
      optional: true,
      type: 'string',
    },
  },
});

/** Override type */
export type LegalMattersFiltersInput = SchemaToType<
  typeof LegalMattersFiltersInput
>;

export const LegalMatter = mkType({
  name: 'LegalMatter',
  comment: 'An individual on a legal matter',
  fields: {
    id: {
      comment: 'The unique ID of the legal matter',
      modelName: 'legalMatter',
      type: 'id',
    },
    createdAt: {
      comment: 'The time the legal matter was created',
      type: 'Date',
    },
    title: {
      comment: 'The title of the legal matter',
      type: 'string',
    },
    description: {
      comment: 'The description of the legal matter',
      type: 'string',
    },
    legalHoldCount: {
      comment: 'The number of individuals on the legal matter',
      type: 'int',
    },
    attributeValues: {
      comment: 'The attribute values used to label this assessment',
      type: AttributeValue,
      list: true,
    },
  },
});

/** Override type */
export type LegalMatter = SchemaToType<typeof LegalMatter>;

/** Order for a legalMatters query */
export const LegalMatterOrder = mkOrder(
  LegalMatter.name,
  LegalMatterOrderField,
);

/** Override type */
export type LegalMatterOrder = SchemaToType<typeof LegalMatterOrder>;
