import { ReactNode } from 'react';

import { ObjByString } from '@transcend-io/type-utils';

/**
 * State for pending rows
 */
export enum RowUploadStatus {
  Error = 'ERROR',
  Success = 'SUCCESS',
  Pending = 'PENDING',
}

export interface RowUploadResult {
  /** id of request */
  id: string;
  /** status of row upload */
  uploadStatus: RowUploadStatus;
  /** the message for the error if it exists */
  errorMessage?: string;
}

/**
 * Stronger typing for the interal column names for each row
 */
export enum CsvUploadDefaultColumnName {
  Key = 'key',
  Id = 'id',
  UploadStatus = 'uploadStatus',
  ErrorMessage = 'errorMessage',
  ErrorDetails = 'errorDetails',
  ColumnIsValidMap = 'columnIsValidMap',
}

/**
 * Row with upload status and generic type to allow for stronger typings for each column
 */
export type RowWithUploadStatus<T extends {}> = T &
  RowWithUploadStatusMetadata<T>;

export interface RowErrorDetails {
  /** column names with invalid input */
  invalidInputColumns: string[];
  /** column names with missing */
  missingColumns: string[];
  /** key is the column name, the value is the indexes of the duplicate values */
  duplicateRows: Record<string, number[]>;
}

export interface RowWithUploadStatusMetadata<T extends {}> {
  /** Unique key */
  [CsvUploadDefaultColumnName.Key]: string;
  /** Unique ID */
  [CsvUploadDefaultColumnName.Id]: string;
  /** Upload status */
  [CsvUploadDefaultColumnName.UploadStatus]: RowUploadStatus;
  /** Error */
  [CsvUploadDefaultColumnName.ErrorMessage]?: string;
  /** error details */
  [CsvUploadDefaultColumnName.ErrorDetails]?: RowErrorDetails;
  /** Mapping from column -> isValid */
  [CsvUploadDefaultColumnName.ColumnIsValidMap]: { [k in keyof T]: boolean };
}

export interface CsvColumn<T = ObjByString> {
  /** name of column */
  columnName: keyof T;
  /** list of alternate column names to access */
  alternateAccessor?: string[];
  /** validate */
  validate?: (value: string, row: object) => boolean;
  /** render contents */
  renderContents?: (value: string, row: object) => ReactNode;
  /** is required */
  required?: boolean;
  /** enforce unique values within the whole column */
  enforceUnique?: boolean;
}
