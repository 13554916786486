/**
 * The fields that the promptThreads query can be ordered on
 */
export enum PromptThreadOrderField {
  /** The ID of the prompt thread */
  ThreadId = 'threadId',
  /** The created date of the prompt thread */
  CreatedAt = 'createdAt',
  /** The updated date of the prompt thread */
  UpdatedAt = 'updatedAt',
}
