import * as t from 'io-ts';

import { dbModelId } from '@main/schema-utils';

import { AuditEventCode } from '../schema/enums';
import { mkAuditEventCodec } from '../types';

export const AuditEventSombra = mkAuditEventCodec(
  AuditEventCode.Sombra,
  t.type({
    sombraId: dbModelId('sombra'),
  }),
);

/** Override types. */
export type AuditEventSombra = t.TypeOf<typeof AuditEventSombra>;

export const AuditEventSombraApiKey = mkAuditEventCodec(
  AuditEventCode.SombraApiKey,
  t.type({
    sombraId: dbModelId('sombra'),
    sombraApiKeyId: dbModelId('sombraApiKey'),
  }),
);

/** Override types. */
export type AuditEventSombraApiKey = t.TypeOf<typeof AuditEventSombraApiKey>;
