/**
 * The fields that a repository can be ordered on
 */
export enum RepositoryOrderField {
  /** The name of the repository */
  Name = 'name',
  /** The age of the repository */
  CreatedAt = 'createdAt',
  /** The last updated time */
  UpdatedAt = 'updatedAt',
}
