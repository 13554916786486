import { DefaultOptionType, ReactSelect, StyleUtils } from '@main/core-ui';
import { indexBy, ONE_SECOND } from '@main/utils';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';

import { clampToEndOfDay, clampToStartOfDay } from '../DataVisualizations';
import { DatePicker } from '../DatePicker';
import { useDebounce } from '../hooks';
import { lastTimePeriodSelectMessages } from './messages';
import { AnalyticsStartDate, LastTimePeriodSelectProps } from './types';

export const getAnalyticsStartDate = (
  range: Omit<AnalyticsStartDate, 'custom'>,
  orgCreatedAt: Date,
): Date => {
  switch (range) {
    case AnalyticsStartDate.LastWeek:
      return clampToStartOfDay(moment().subtract(1, 'week').toDate());
    case AnalyticsStartDate.LastMonth:
      return clampToStartOfDay(moment().subtract(1, 'month').toDate());
    case AnalyticsStartDate.LastSixMonths:
      return clampToStartOfDay(moment().subtract(6, 'month').toDate());
    case AnalyticsStartDate.LastYear:
      return clampToStartOfDay(moment().subtract(1, 'year').toDate());
    case AnalyticsStartDate.AllTime:
      return orgCreatedAt;
    // unreachable
    default:
      return new Date();
  }
};

/**
 * LastTimePeriodSelect
 */
export const LastTimePeriodSelect: React.FC<LastTimePeriodSelectProps> = ({
  value: providedValue,
  onChange,
  orgCreatedAt,
  defaultStartDate = AnalyticsStartDate.LastSixMonths,
}) => {
  const { formatMessage } = useIntl();
  const [dropdownValue, setDropdownValue] = useState(defaultStartDate);
  const isCustomRange = dropdownValue === AnalyticsStartDate.Custom;
  const endOfToday = clampToEndOfDay(new Date(Date.now()));
  const startOfToday = clampToStartOfDay(new Date(Date.now()));
  const allTimeStartDate = clampToStartOfDay(new Date(orgCreatedAt));

  const defaultValue = {
    startDate: getAnalyticsStartDate(defaultStartDate, allTimeStartDate),
    endDate: endOfToday,
  };
  useEffect(() => {
    if (!providedValue) {
      onChange(defaultValue);
    }
  }, [providedValue]);

  const value = providedValue || defaultValue;

  const options = useMemo(
    () => [
      {
        value: AnalyticsStartDate.LastWeek,
        label: formatMessage(lastTimePeriodSelectMessages.lastOneWeek),
      },
      {
        value: AnalyticsStartDate.LastMonth,
        label: formatMessage(lastTimePeriodSelectMessages.lastOneMonth),
      },
      {
        value: AnalyticsStartDate.LastSixMonths,
        label: formatMessage(lastTimePeriodSelectMessages.lastSixMonths),
      },
      {
        value: AnalyticsStartDate.LastYear,
        label: formatMessage(lastTimePeriodSelectMessages.lastYear),
      },
      {
        value: AnalyticsStartDate.AllTime,
        label: formatMessage(lastTimePeriodSelectMessages.allTime),
      },
      {
        value: AnalyticsStartDate.Custom,
        label: formatMessage(lastTimePeriodSelectMessages.custom),
      },
    ],
    [],
  );
  const optionsByValue = useMemo(
    () => indexBy(options, (option) => option.value),
    [options],
  );

  // Debounce the onChange function to prevent too many calls
  const debouncedOnChange = useDebounce(onChange, ONE_SECOND / 2, {
    trailing: true,
  });

  return (
    <div
      style={{
        ...StyleUtils.Flex.Row.AlignRight,
        alignItems: 'flex-end',
        gap: '1em',
      }}
    >
      <div style={{ width: '15em' }}>
        <ReactSelect<false, DefaultOptionType<AnalyticsStartDate>>
          value={optionsByValue[dropdownValue]}
          onChange={(option) => {
            if (option) {
              setDropdownValue(option?.value);
            }
            if (option && option.value !== AnalyticsStartDate.Custom) {
              onChange({
                startDate: getAnalyticsStartDate(
                  option.value,
                  allTimeStartDate,
                ),
                endDate: endOfToday,
              });
            }
          }}
          isClearable={false}
          options={options}
          variant="light"
        />
      </div>
      {isCustomRange && (
        <>
          <div>
            <span style={{ display: 'block' }}>
              {formatMessage(lastTimePeriodSelectMessages.startDate)}
            </span>
            <DatePicker
              value={value.startDate}
              onChange={(pickerValue) => {
                if (pickerValue !== undefined) {
                  debouncedOnChange({
                    startDate: clampToStartOfDay(pickerValue),
                    endDate: value.endDate,
                  });
                }
              }}
              dayPickerProps={{
                disabled: {
                  after:
                    clampToStartOfDay(value.endDate) < startOfToday
                      ? clampToStartOfDay(value.endDate)
                      : startOfToday,
                },
              }}
            />
          </div>
          <div>
            <span style={{ display: 'block' }}>
              {formatMessage(lastTimePeriodSelectMessages.endDate)}
            </span>
            <DatePicker
              value={value.endDate}
              onChange={(pickerValue) => {
                if (pickerValue) {
                  debouncedOnChange({
                    startDate: value.startDate,
                    endDate: clampToEndOfDay(pickerValue),
                  });
                }
              }}
              dayPickerProps={{
                disabled: {
                  before: clampToEndOfDay(value.startDate),
                  after: endOfToday,
                },
              }}
            />
          </div>
        </>
      )}
    </div>
  );
};
