import { Icon, LoadingSpinner, Skeleton } from '@main/core-ui';
import React, {
  Fragment,
  HTMLAttributes,
  ReactNode,
  UIEvent,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { HeaderGroup, Row, TableInstance, TableRowProps } from 'react-table';
import { useTheme } from 'styled-components';

import { getFrozenCellsLeftPosition } from './helpers';
import {
  FreezableCell,
  StyledColumnResizeButton,
  StyledLoadingSpinnerWrapper,
  StyledTable,
  TableActionWrapper,
  TableCellOverflowWrapper,
  TableScrollContainer,
} from './wrappers'; // Table docs: https://react-table.tanstack.com/docs/api/overview

// Table docs: https://react-table.tanstack.com/docs/api/overview
export interface TableViewProps<T extends object> {
  /** The table instance, which is defined with the `useTable` hook */
  table: TableInstance<T>;

  /** True when loading data */
  loading?: boolean;

  /** Additional row props */
  getRowProps?: (row: Row<T>) => Partial<TableRowProps>;

  /** Function to fire on row click */
  onRowClick?: (
    row: Row<T>,
    event: React.MouseEvent<HTMLTableRowElement>,
  ) => void;

  /** max the table horizontally scrollable */
  scrollHorizontally?: boolean;

  /**
   * whether or not to freeze the leftmost column while scrolling
   * (by default, we freeze just 1 but sometimes we freeze more than 1 columns).
   * For styling purposes, frozen columns other than the last one should have `disableResizing: true`
   * and have width and/or minWidth set as numbers
   */
  freezeLeftmostColumns?: number;

  /** Function to render the contents of the row action tray */
  renderRowActions?: (row: Row<T>) => ReactNode;
  /** the space available for the floating actions tray. Defaults to 300px */
  rowActionsWidth?: string;
  /** A sub component that's rendered when the row is expanded */
  subComponent?: (row: Row<T>) => ReactNode;
  /** Should show the table header */
  showTableHead?: boolean;
}

export const TableView = <
  T extends {
    /**
     * It is strongly recommended that every row have a unique id so that updated rows
     * rerender correctly, especially when updates cause rows to be removed from the table
     */
    id?: string;
  },
>({
  table: {
    getRowId,
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    rows,
    columns,
  },
  loading = false,
  getRowProps,
  onRowClick,
  scrollHorizontally,
  freezeLeftmostColumns = 0,
  renderRowActions,
  rowActionsWidth = '300px',
  subComponent,
  showTableHead = true,
}: TableViewProps<T>): JSX.Element => {
  const theme = useTheme();

  const rowsToRender = page || rows;

  const [isScrolledFromLeft, setIsScrolledFromLeft] = useState(false);

  const frozenCellsLeftDistance = useMemo(
    () =>
      freezeLeftmostColumns > 1 ? getFrozenCellsLeftPosition(columns) : [],
    [columns, freezeLeftmostColumns],
  );
  const getWidthPlusRowActionsWidth = useCallback(
    (width: number | string | undefined): string | number | undefined =>
      renderRowActions && width !== undefined
        ? `calc(${width} + ${rowActionsWidth})`
        : width,
    [!!renderRowActions, rowActionsWidth],
  );

  const styledTable = (
    <StyledTable
      {...(getTableProps() as HTMLAttributes<HTMLTableElement>)}
      style={{
        ...getTableProps().style,
        width: getWidthPlusRowActionsWidth(getTableProps().style?.width),
      }}
    >
      {showTableHead && (
        <thead>
          {headerGroups.map((headerGroup: HeaderGroup<T>, i) => (
            <tr
              {...(headerGroup.getHeaderGroupProps() as HTMLAttributes<HTMLTableRowElement>)}
              key={i}
              style={{
                ...headerGroup.getHeaderGroupProps().style,
                width: getWidthPlusRowActionsWidth(
                  headerGroup.getHeaderGroupProps().style?.width,
                ),
              }}
            >
              {headerGroup.headers.map((column, j) => (
                <FreezableCell
                  as="th"
                  frozen={j < freezeLeftmostColumns}
                  isScrolledFromLeft={isScrolledFromLeft}
                  left={frozenCellsLeftDistance[j]}
                  isRightmostFrozenCell={j === freezeLeftmostColumns - 1}
                  {...(column.getHeaderProps({
                    ...(column.getSortByToggleProps
                      ? column.getSortByToggleProps()
                      : {}),
                    // The mix of styles on FreezableCell and inline styling is
                    // to handle overrides from own styling and react-table's
                    style: {
                      width: column.width,
                      minWidth: column.minWidth,
                      maxWidth: column.maxWidth,
                      fontWeight: column.isSorted ? 700 : 600,
                      ...(j < freezeLeftmostColumns
                        ? { position: 'sticky' }
                        : {}),
                    },
                  }) as HTMLAttributes<HTMLTableHeaderCellElement>)}
                  key={j}
                >
                  <TableCellOverflowWrapper>
                    {column.render('Header')}
                    {column.canSort ? (
                      <span>
                        {column.isSorted
                          ? column.isSortedDesc
                            ? ' ↑'
                            : ' ↓'
                          : ' '}
                      </span>
                    ) : null}
                    {column.canResize && (
                      <StyledColumnResizeButton
                        size="sm"
                        variant="secondary"
                        icon={
                          <Icon
                            type="lines-triple"
                            color={theme.colors.transcendNavy4}
                            size={10}
                          />
                        }
                        {...(column.getResizerProps
                          ? column.getResizerProps()
                          : {})}
                      />
                    )}
                  </TableCellOverflowWrapper>
                </FreezableCell>
              ))}
            </tr>
          ))}
        </thead>
      )}
      {loading && (
        <StyledLoadingSpinnerWrapper>
          <LoadingSpinner />
        </StyledLoadingSpinnerWrapper>
      )}
      <tbody
        id="table-body-wrapper"
        {...(getTableBodyProps
          ? (getTableBodyProps() as HTMLAttributes<HTMLTableSectionElement>)
          : {})}
        aria-busy={loading ? 'true' : 'false'}
      >
        {/* Create 10 fake rows with Skeletons when loading and there are no cached rows */}
        {loading &&
          headerGroups.length > 0 &&
          rowsToRender.length === 0 &&
          Array.from({ length: 10 }, (_, i) => (
            <tr key={i} style={{ display: 'flex' }}>
              {/* Take the last headerGroup, as it's most likely to correlate to actual columns in table */}
              {headerGroups[headerGroups.length - 1].headers.map(
                (column, j) => (
                  <td
                    key={`${column.id}-${j}`}
                    style={{
                      width: column.width,
                      minWidth: column.minWidth,
                      maxWidth: column.maxWidth,
                    }}
                  >
                    <Skeleton active paragraph={false} />
                  </td>
                ),
              )}
            </tr>
          ))}

        {rowsToRender.map((row, i) => {
          prepareRow(row);
          const rowProps = row.getRowProps(
            getRowProps ? getRowProps(row) : undefined,
          );
          const rowActions = renderRowActions?.(row);
          let className = 'loaded-row';
          // Add any custom class name provided in rowProps
          if (rowProps.className) {
            className += ` ${rowProps.className}`;
          }
          return (
            <Fragment
              key={
                getRowId?.(row.original, i, undefined) ??
                (row?.original && 'id' in row.original ? row.original.id : i)
              }
            >
              <tr
                {...(rowProps as HTMLAttributes<HTMLTableRowElement>)}
                onClick={(event) => onRowClick?.(row, event) ?? undefined}
                className={
                  onRowClick ? `row-clickable ${className}` : className
                }
                style={{
                  ...rowProps.style,
                  width: getWidthPlusRowActionsWidth(rowProps.style?.width),
                }}
              >
                {row.cells.map((cell, j) => (
                  <FreezableCell
                    isScrolledFromLeft={isScrolledFromLeft}
                    frozen={j < freezeLeftmostColumns}
                    left={frozenCellsLeftDistance[j]}
                    isRightmostFrozenCell={j === freezeLeftmostColumns - 1}
                    backgroundColor={rowProps.style?.backgroundColor}
                    {...(cell.getCellProps({
                      // The mix of styles on FreezableCell and inline styling is
                      // to handle overrides from own styling and react-table's
                      style: {
                        width: cell.column.width,
                        minWidth: cell.column.minWidth,
                        maxWidth: cell.column.maxWidth,
                        ...(j < freezeLeftmostColumns ? { zIndex: 3 } : {}),
                      },
                    }) as HTMLAttributes<HTMLTableDataCellElement>)}
                    key={j}
                  >
                    <TableCellOverflowWrapper>
                      {cell.render('Cell')}
                    </TableCellOverflowWrapper>
                  </FreezableCell>
                ))}
                {rowActions && (
                  <TableActionWrapper>{rowActions}</TableActionWrapper>
                )}
              </tr>
              {row.isExpanded && !!subComponent && <>{subComponent(row)}</>}
            </Fragment>
          );
        })}
      </tbody>
    </StyledTable>
  );
  return scrollHorizontally ? (
    <TableScrollContainer
      tableIsEmpty={rows.length === 0}
      onScroll={(e: UIEvent<HTMLDivElement>) => {
        setIsScrolledFromLeft(
          ((e.target as HTMLDivElement).scrollLeft || 0) > 0,
        );
      }}
    >
      {styledTable}
    </TableScrollContainer>
  ) : (
    styledTable
  );
};
