import { createLoadable } from '@main/core-ui';

import { templatesMessages } from './messages';

// Load the component dynamically
export const TemplatesLoadable = createLoadable({
  loader: () => import('./index'),
  messages: templatesMessages,
  logo: 'form',
});
