import { mkType, SchemaToType } from '@main/schema-utils';

import { PageType } from './enums';

export const Page = mkType({
  name: 'Page',
  comment: 'A page on the privacy center',
  fields: {
    type: {
      comment: 'The type of page (dictates what component to use)',
      type: { PageType },
    },
  },
});

/** Override type */
export type Page = SchemaToType<typeof Page>;
