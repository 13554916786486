import { PaginatedSelect } from '@main/ad-core-components';
import {
  buildUseInfiniteScroll,
  formatErrorMessage,
  message,
  ReactSelectExtendedProps,
  SelectTagWrapper,
} from '@main/core-ui';
import {
  endpoints,
  processingPurposeMessages,
  PurposeSubCategory,
} from '@main/datamap-types';
import { DefinedMessage } from '@main/internationalization';
import React, { useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';

import { PURPOSE_OF_PROCESSING_COLORS } from '../../constants';
import { PurposeTitle } from '../PurposeTitle';
import { ropaSelectPurposeMessages } from '../SelectPurpose/messages';
import { SELECT_SUB_PURPOSE_NODES } from './constants';

const useProcessingPurposeSubCategories = buildUseInfiniteScroll(
  endpoints.processingPurposeSubCategories,
  endpoints.processingPurposeSubCategories.name,
  {
    nodes: SELECT_SUB_PURPOSE_NODES,
  },
);

export interface SubPurposeOption
  extends Pick<PurposeSubCategory, 'id' | 'name' | 'purpose'> {
  /** Whether the option is disabled (in the dropdown) */
  isDisabled?: boolean;
  /** Whether the option is fixed (in the select menu - works like disabled) */
  isFixed?: boolean;
}

/** Props for the `SelectSubPurpose` component */
export interface SelectSubPurposeProps
  extends Omit<
    ReactSelectExtendedProps<true, SubPurposeOption>,
    'onChange' | 'isMulti'
  > {
  /** Callback to fire when the purpose is updated */
  onChange: (selected: SubPurposeOption[]) => void;
  /** Groups of options to display before the main list of options */
  optionGroups?: {
    /** The label of the group */
    label: string;
    /** The options within this group */
    options: SubPurposeOption[];
  }[];
  /** The label for the main list of options, used if other option groups are provided */
  defaultOptionGroupLabel?: DefinedMessage;
}

export const SelectSubPurpose: React.FC<SelectSubPurposeProps> = ({
  value,
  loading,
  onChange,
  optionGroups,
  defaultOptionGroupLabel,
  ...rest
}) => {
  const { formatMessage } = useIntl();
  const [searchText, setSearchText] = useState('');

  const {
    data,
    loading: processingPurposeSubCategoriesLoading,
    fetchMore,
    error,
  } = useProcessingPurposeSubCategories({
    variables: {
      filterBy: {
        ...(searchText ? { text: searchText } : {}),
      },
      first: 100,
    },
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
  });

  // Flash error for query errors
  useEffect(() => {
    if (error) {
      message.error(formatErrorMessage(error.message));
    }
  }, [error]);

  const options = useMemo(
    () =>
      (optionGroups && !searchText
        ? [
            ...optionGroups,
            {
              ...(defaultOptionGroupLabel
                ? { label: formatMessage(defaultOptionGroupLabel) }
                : {}),
              options: data?.nodes || [],
            },
          ]
        : data?.nodes || []) as PurposeSubCategory[],
    [
      data?.nodes,
      defaultOptionGroupLabel,
      formatMessage,
      optionGroups,
      searchText,
    ],
  );

  return (
    <SelectTagWrapper>
      <PaginatedSelect<SubPurposeOption, true>
        isMulti
        showOutline={false}
        options={options}
        getOptionValue={({ id }: SubPurposeOption) => id}
        fetchMore={fetchMore}
        isQueryLoading={loading || processingPurposeSubCategoriesLoading}
        queryError={error}
        formatOptionPill={(option: SubPurposeOption) => (
          <PurposeTitle purpose={option.purpose} name={option.name} />
        )}
        onEndsTyping={(searchText) => setSearchText(searchText)}
        getOptionLabel={({ purpose }: SubPurposeOption) =>
          formatMessage(processingPurposeMessages[purpose])
        }
        placeholder={formatMessage(ropaSelectPurposeMessages.placeholder)}
        colorMap={PURPOSE_OF_PROCESSING_COLORS}
        value={value}
        onChange={(values) => {
          onChange([...values]);
        }}
        {...rest}
      />
    </SelectTagWrapper>
  );
};
