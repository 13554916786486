import { Icon, ReactSelect, SelectTagWrapper, SingleTag } from '@main/core-ui';
import { processingPurposeMessages } from '@main/datamap-types';
import { ProcessingPurpose } from '@transcend-io/privacy-types';
import React, { useCallback } from 'react';
import { useIntl } from 'react-intl';
import type { MenuPosition } from 'react-select';

import {
  PURPOSE_OF_PROCESSING_COLOR_NAMES,
  PURPOSE_OF_PROCESSING_COLORS,
} from '../../constants';
import { ropaSelectPurposeMessages } from './messages';

/** Props for the `SelectPurpose` component */
interface SelectPurposeProps {
  /** The value for the cell */
  value?: ProcessingPurpose;
  /** Whether the input is disabled (e.g. due to the data being derived) */
  disabled?: boolean;
  /** Whether the input is being updated/loading */
  loading?: boolean;
  /** Whether to show the select input outlines */
  showSelectOutline: boolean;
  /** Callback to fire when component is blurred */
  onBlur?: () => void;
  /** Callback to fire when the purpose is updated */
  onChange?: (category?: ProcessingPurpose) => void;
  /** How to position the menu (see docs for React Select) */
  menuPosition?: MenuPosition;
  /** Whether to hide the `UNSPECIFIED` option */
  hideUnspecified?: boolean;
}

/** An option for the select component */
interface SelectPurposeOption {
  /** The value for the option */
  purpose: ProcessingPurpose;
  /** The label for the option */
  label: string;
}

export const SelectPurpose: React.FC<SelectPurposeProps> = ({
  value,
  disabled = false,
  loading = false,
  onBlur,
  onChange,
  showSelectOutline,
  menuPosition,
  hideUnspecified = false,
}) => {
  const { formatMessage } = useIntl();
  const toOption: (purpose: ProcessingPurpose) => SelectPurposeOption =
    useCallback(
      (purpose) => ({
        purpose,
        label: formatMessage(processingPurposeMessages[purpose]),
        ...(disabled ? { logo: <Icon type="lock" /> } : {}),
      }),
      [disabled],
    );
  const options = Object.values(ProcessingPurpose)
    .filter(
      (purpose) =>
        !hideUnspecified || purpose !== ProcessingPurpose.Unspecified,
    )
    .map(toOption);

  return (
    <SelectTagWrapper>
      <ReactSelect<false, SelectPurposeOption>
        options={options}
        value={value ? toOption(value) : null}
        menuPosition={menuPosition}
        placeholder={formatMessage(ropaSelectPurposeMessages.placeholder)}
        onChange={(option) => {
          onChange?.(option?.purpose);
        }}
        formatOptionPill={(option) => (
          <SingleTag
            label={option.label}
            color={PURPOSE_OF_PROCESSING_COLOR_NAMES[option.purpose]}
          />
        )}
        onBlur={onBlur}
        showOutline={showSelectOutline}
        isDisabled={disabled}
        isLoading={loading}
        getOptionValue={({ purpose }: SelectPurposeOption) => purpose}
        colorMap={PURPOSE_OF_PROCESSING_COLORS}
      />
    </SelectTagWrapper>
  );
};
