import { QueryResult } from '@apollo/client';

import { endpoints } from '@main/datamap-types';
import { AssessmentQuestionSelectOption } from '@main/datamap-types/src/schema/assessmentQuestion';
import { ResponseToType } from '@main/schema-utils';

export const transformRawAssessmentQuestionSelectOptions = (
  data: QueryResult<
    ResponseToType<typeof endpoints.assessmentQuestionSelectOptions.response>
  >['data'],
): QueryResult<{
  /** The total count of the options */
  totalCount?: number;
  /** The transformed assessment question select option */
  nodes: AssessmentQuestionSelectOption[];
}>['data'] => ({
  ...data,
  nodes: (data?.nodes || []).map(
    ({ additionalContext, ...option }) =>
      ({
        ...option,
        additionalContext: additionalContext
          ? JSON.parse(additionalContext)
          : {},
      }) as AssessmentQuestionSelectOption,
  ),
});
