import styled from 'styled-components';

export const StyledParagraph = styled.p`
  font-weight: 600;
  margin-bottom: 12px;
`;

export const FiltersSection = styled.div`
  &:not(:last-child) {
    margin-bottom: 18px;
  }
`;

export const CheckboxUnit = styled.label`
  font-weight: 600;
  font-size: 14px;
  color: ${({ theme }) => theme.colors.black};
  display: flex;
  align-items: center;
  margin-bottom: 0.65em;
  cursor: pointer;

  input[type='checkbox'] {
    height: 1.25em;
    width: 1.25em;
    margin-right: 8px;
  }
`;
