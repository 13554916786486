import { mkType, SchemaToType } from '@main/schema-utils';

import { Cookie } from './cookie';
import { DataFlow } from './dataFlow';

export const CookieSummary = mkType({
  name: 'CookieSummary',
  comment: 'Information about a cookie for the public Tracking Technology page',
  fields: {
    name: Cookie.fields.name,
    trackingPurposes: Cookie.fields.trackingPurposes,
  },
});

/** Override type */
export type CookieSummary = SchemaToType<typeof CookieSummary>;

export const DataFlowSummary = mkType({
  name: 'DataFlowSummary',
  comment:
    'Information about a data flow for the public Tracking Technology page',
  fields: {
    value: DataFlow.fields.value,
    trackingPurposes: DataFlow.fields.trackingType,
  },
});

/** Override type */
export type DataFlowSummary = SchemaToType<typeof DataFlowSummary>;

export const TrackingTechnology = mkType({
  name: 'TrackingTechnology',
  comment: 'A cookie or data flow from the Consent Manager configuration',
  fields: {
    name: {
      comment: 'The name of the service that the technology is used for',
      type: 'string',
    },
    description: {
      comment: 'A description for the service',
      type: 'string',
      optional: true,
    },
    logoUrl: {
      comment: 'A logo URL to display alongside the service',
      type: 'string',
    },
    cookies: {
      comment: 'The list of cookies associated with the service',
      list: true,
      type: CookieSummary,
    },
    dataFlows: {
      comment: 'The list of data flows associated with the service',
      list: true,
      type: DataFlowSummary,
    },
  },
});

/** Override type */
export type TrackingTechnology = SchemaToType<typeof TrackingTechnology>;
