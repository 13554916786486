import { animated } from 'react-spring';
import styled, { css, keyframes } from 'styled-components';

import { FlexRowCenterBoth, StyleUtils } from '@main/core-ui';

import { Button } from '../../Button';

const fadeIn = keyframes`
  0% {
    opacity: 0
  }
  30% {
    opacity: 0
  }
  100% {
    opacity: 100%
  }
`;

export const ChartContainer = styled.div`
  overflow: visible;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  animation: ${css`
    ${fadeIn} 700ms ease-out
  `};
`;
export const ChartSVGGrid = styled(animated.svg)`
  position: absolute;
  stroke-dasharray: 4, 4;
`;

export const MainStyledLegend = styled(FlexRowCenterBoth)`
  position: absolute;
  left: 0;
  right: 0;
  overflow-y: auto;
  flex-wrap: wrap;
`;

export const LegendItem = styled(Button)`
  gap: ${StyleUtils.Spacing.xs};
  display: flex;
  align-items: center;
  font-weight: unset;
  padding: 0.2em ${StyleUtils.Spacing.sm};
  border-width: 0;
`;
