import * as t from 'io-ts';

import { dbModelId } from '@main/schema-utils';

import { AuditEventCode } from '../schema/enums';
import { mkAuditEventCodec } from '../types';

export const AuditEventAssetFile = mkAuditEventCodec(
  AuditEventCode.AssetFile,
  t.intersection([
    t.type({
      /** ID of the asset file */
      assetFileId: dbModelId('assetFile'),
    }),
    // Optional fields; assets may be for multiple purposes
    t.partial({
      /** ID of the privacy center */
      privacyCenterId: dbModelId('privacyCenter'),
    }),
  ]),
);

/** Override types. */
export type AuditEventAssetFile = t.TypeOf<typeof AuditEventAssetFile>;
