/* eslint-disable max-lines */
import mapValues from 'lodash/mapValues';
import uniq from 'lodash/uniq';

import {
  ScopeName,
  ScopeType,
  TRANSCEND_SCOPES,
} from '@transcend-io/privacy-types';
import { getEntries } from '@transcend-io/type-utils';

/**
 * Mapping from scope definition to list of routes that scope grants access to
 */
const SCOPE_TO_ROUTES_MINUS_SMART: {
  [name in Exclude<
    ScopeName,
    ScopeName.FullAdmin | ScopeName.ReadOnly
  >]: string[];
} = {
  [ScopeName.ViewAllActionItems]: [
    // scope is checked at runtime
  ],
  [ScopeName.ManageAllActionItems]: [
    // scope is checked at runtime
  ],
  [ScopeName.ManageAssessments]: [
    // GraphQL routes
    'createAssessmentFormTemplate',
    'updateAssessmentFormTemplate',
    'deleteAssessmentFormTemplates',
    'deleteAssessmentAnswerFiles',
    'createAssessmentForms',
    'importOneTrustAssessmentForms',
    'updateAssessmentForm',
    'deleteAssessmentForms',
    'createAssessmentGroup',
    'updateAssessmentGroup',
    'deleteAssessmentGroups',
    'createAssessmentQuestions',
    'updateAssessmentFormQuestion',
    'updateAssessmentTemplateQuestion',
    'deleteAssessmentQuestions',
    'createAssessmentSection',
    'updateAssessmentFormSections',
    'updateAssessmentTemplateSection',
    'deleteAssessmentSections',
    'selectAssessmentQuestionAnswers',
    'createAssessmentQuestionComments',
    'updateAssessmentQuestionComments',
    'deleteAssessmentQuestionComments',
    'createAssessmentSectionComments',
    'updateAssessmentSectionComments',
    'deleteAssessmentSectionComments',
    'createAssessmentFormComments',
    'updateAssessmentFormComments',
    'deleteAssessmentFormComments',
    'createAssessmentEmailSet',
    'updateAssessmentEmailSet',
    'deleteAssessmentEmailSets',
    'createRiskFrameworks',
    'updateRiskFrameworks',
    'deleteRiskFrameworks',
    'createRiskCategories',
    'updateRiskCategories',
    'deleteRiskCategories',
    // REST routes
    'filesAssessmentFileUploadPost',
    'filesAssessmentAnswerUploadPost',
  ],
  [ScopeName.ViewAssessments]: [
    'assessmentGroups',
    'assessmentForms',
    'assessmentFormComments',
    'assessmentFormTemplates',
    'assessmentEmailSets',
    'assessmentFiles',
    'assessmentQuestionComments',
    'assessmentSectionComments',
    'assessmentQuestions',
    'assessmentQuestionSelectOptions',
    'riskCategories',
    'riskFrameworks',
    'riskLevels',
  ],
  [ScopeName.ViewAssignedAssessments]: [],
  [ScopeName.ManageAssignedAssessments]: [],
  [ScopeName.ApproveAssessments]: [
    // TODO: https://transcend.height.app/T-18557
  ],
  [ScopeName.ViewCustomerDataDataMapping]: ['unwrapSubDataPointSamples'],
  [ScopeName.ViewCustomerDataPrivacyRequests]: [
    'filesRequestFilePeekRequestFileIdGet',
  ],
  [ScopeName.ViewLegalHold]: ['legalHolds', 'legalMatters'],
  [ScopeName.ManageLegalHold]: [
    'createLegalHolds',
    'deleteLegalHolds',
    'createLegalMatter',
    'updateLegalMatter',
    'deleteLegalMatters',
  ],
  [ScopeName.ViewDataFlow]: [
    'scanCookiePurposes',
    'scanDomainPurposes',
    'cookies',
    'purposes',
    'dataFlowServices',
    'dataFlows',
    'dataFlowStats',
    'cookieStats',
  ],
  [ScopeName.ViewAssignedConsentManager]: [
    'scanCookiePurposes',
    'scanDomainPurposes',
    'purposes',
    'dataFlowServices',
    'dataFlowStats',
    'cookieStats',
  ],
  [ScopeName.ManageDataFlow]: [
    'createDataFlows',
    'updateDataFlows',
    'deleteDataFlows',
    'updateOrCreateCookies',
    'deleteCookies',
    'reClassifyCookies',
    'reClassifyDataFlows',
    'classifyDataFlows',
    'classifyCookies',
  ],
  [ScopeName.ManageAssignedConsentManager]: [
    'createDataFlows',
    'updateOrCreateCookies',
    'reClassifyCookies',
    'reClassifyDataFlows',
    'classifyDataFlows',
    'classifyCookies',
  ],
  [ScopeName.ManageRequestSecurity]: [
    'reSignSaaSContexts',
    'employeeReSignEncryptedCEKContexts',
  ],
  [ScopeName.ManageSombraRootKeys]: [
    'rotateSombraRootKeys',
    'testSombraEmployeeAuthentication',
    'testSombraGatewayConnection',
    'testIntegrationsSombraConnectivity',
    'updateSombra',
    'createOnPremiseSombra',
    'revealRootSombraSecret',
    'removeSombra',
    'changePrimarySombra',
    'issueSombraApiKey',
    'expireSombraApiKey',
    'sombras',
    'generateConsentIdentifierEncryptionKey',
    'revealConsentIdentifierEncryptionKey',
  ],
  [ScopeName.ViewConsentManager]: [
    'bundleTcfVendors',
    'bundleTcfStacks',
    'customSyncEndpoints',
    'consentManager',
    'consentManagerTheme',
    'airgapBundleVersions',
    'airgapBundleTimeseriesAnalytics',
    'airgapBundleAggregateAnalytics',
    'consentPartitions',
    'experiences',
    'analyticsData',
    'purposes',
    'airgapVersions',
    'consentManagerUIs',
    'consentServices',
    'tcfFeatures',
    'tcfVendors',
    'consentApplications',
    'consentSdks',
    'orgTcfSpecialFeatures',
    'tcfVendorDetails',
    'consentManagerConfigurationJson',
  ],
  [ScopeName.ManageConsentManager]: [
    'createConsentPartition',
    'deleteConsentPartition',
    'deployConsentManagerBundle',
    'rollbackAirgapBundle',
    'createConsentManager',
    'toggleConsentManagerEnforcement',
    'toggleTelemetryPartitioning',
    'toggleUseDefaultTcfSettings',
    'toggleUnknownRequestPolicy',
    'toggleUnknownCookiePolicy',
    'updateConsentManagerTheme',
    'updateConsentManagerDomains',
    'updateConsentManagerOnboardingStatus',
    'updateConsentManagerToLatestVersion',
    'createExperience',
    'deleteExperiences',
    'updateExperience',
    'createPurpose',
    'updatePurpose',
    'deletePurposes',
    'togglePurposeActive',
    'createConsentManagerUI',
    'toggleUspapi',
    'updateSyncGroups',
    'updateConsentManagerPartition',
    'toggleConsentPrecedence',
    'updateSyncEndpoint',
    'updateLoadOptions',
    'updateOrgTCFPurposes',
    'updateOrgTcfSpecialFeature',
    'addTCFStacksToBundle',
    'removeTCFStacksFromBundle',
    'createOrUpdateConsentServices',
    'createOrUpdateConsentApplications',
    'deleteConsentApplications',
    'createOrUpdateConsentSdks',
    'deleteConsentSdks',
    'deleteConsentServices',
    'showTcfVendorInUI',
    'setAirgapBundleTcfStacks',
    'disableExperiences',
  ],
  [ScopeName.DeployConsentManager]: [
    'deployConsentManagerBundle',
    'rollbackAirgapBundle',
    'createConsentManager',
    'toggleConsentManagerEnforcement',
    'toggleTelemetryPartitioning',
    'toggleUseDefaultTcfSettings',
    'toggleUnknownRequestPolicy',
    'toggleUnknownCookiePolicy',
    'updateConsentManagerDomains',
    'updateConsentManagerOnboardingStatus',
    'updateConsentManagerToLatestVersion',
    'toggleUspapi',
    'updateLoadOptions',
    'getTokenSecrets',
    'setTokenSecret',
  ],
  [ScopeName.DeployTestConsentManager]: [
    // this is restricted in memory
    'deployConsentManagerBundle',
    'toggleConsentManagerEnforcement',
    'toggleTelemetryPartitioning',
    'toggleUseDefaultTcfSettings',
    'toggleUnknownRequestPolicy',
    'toggleUnknownCookiePolicy',
    'updateConsentManagerDomains',
    'updateConsentManagerOnboardingStatus',
    'updateConsentManagerToLatestVersion',
    'toggleUspapi',
    'updateLoadOptions',
    'getTokenSecrets',
    'setTokenSecret',
  ],
  [ScopeName.ManageConsentManagerDeveloperSettings]: [
    'toggleConsentManagerEnforcement',
    'toggleTelemetryPartitioning',
    'toggleUseDefaultTcfSettings',
    'toggleUnknownRequestPolicy',
    'toggleUnknownCookiePolicy',
    'updateConsentManagerDomains',
    'updateConsentManagerOnboardingStatus',
    'updateConsentManagerToLatestVersion',
    'toggleUspapi',
    'updateSyncGroups',
    'updateConsentManagerPartition',
    'toggleConsentPrecedence',
    'updateSyncEndpoint',
    'updateLoadOptions',
    'createExperience',
    'updateExperience',
    'setSyncEndpoints',
    'makeDefaultSyncEndpoint',
    'updateConsentManager',
  ],
  [ScopeName.ManageConsentManagerDisplaySettings]: [
    'updateConsentManagerTheme',
    'updateConsentManagerDomains',
    'createExperience',
    'deleteExperiences',
    'updateExperience',
    'createPurpose',
    'updatePurpose',
    'deletePurposes',
  ],
  [ScopeName.MakeDataSubjectRequest]: [
    // GraphQL routes
    'employeeMakeDataSubjectRequest',
    'employeeDownloadCEK',
    'employeeReSignEncryptedCEKContexts',
    'employeeUpdateRequestIdentifiers',
    // REST routes
    'apiDataSubjectRequestPost',
  ],
  [ScopeName.ConnectDataSilos]: [
    // Connecting integrations
    'addDataSiloToOpenRequests',
    'reconnectDataSilo',
    // OAuth integrations
    'connectIntegration',
    'sombras',
  ],
  [ScopeName.DeployPrivacyCenter]: [
    'privacyCenterDeployment',
    'requestCertificate',
    'deployCloudfront',
    'launchPrivacyCenter',
  ],
  [ScopeName.ManageAccessControl]: [
    'createTeam',
    'deleteTeam',
    'updateTeam',
    'updateUser',
    'parentOrganizationTeams',
    'addUser',
    'sendUserInviteEmail',
    'removeUser',
    'updateScope',
    'updateSombraTenantConfig',
    'sombras',
    'disableUserAccount',
    'enableUserAccount',
  ],
  [ScopeName.ManageBilling]: [],
  [ScopeName.ManageSSO]: ['updateSsoProvider'],
  [ScopeName.ManageApiKeys]: [
    'createApiKey',
    'deleteApiKey',
    'duplicateApiKey',
    'updateApiKey',
  ],
  [ScopeName.ManageDataMap]: [
    'deleteDataSilos',
    'updateDataSilos',
    'retryDataSiloErrors',
    'manuallyTestAndRefreshDataSilo',
    'createDataCollection',
    'createDataSilos',
    'possibleDataSiloDependencies',
    'updateLookupProcess',
    'updateOrCreateDataPoint',
    'updateOrCreateWorkflowDataPoint',
    'deleteDataPoints',
    'generateDataSiloSqlStatements',
    'deleteSubDataPoints',
    'toggleDataPointActions',
    'updateDataSiloPlugin',
    'updateDataSiloRateLimits',
    'reScanPluginPermissions',
    'restartSiloClassification',
    'restartSiloObjectClassifications',
    'resetDataSiloRateLimitsToDefault',
    'setResourceAttributes',
    'createDataReport',
    'updateDataReports',
  ],
  [ScopeName.ManageAssignedIntegrations]: [
    'retryEnricherErrors',
    'createDataCollection',
    'createDataSilos',
    'possibleDataSiloDependencies',
  ],
  [ScopeName.ManageDataSubjectRequestSettings]: [
    'updateAction',
    'toggleSubject',
    'updateSubject',
    'createSubject',
    'deleteSubject',
    'updateSombraOAuthConfig',
    'updateSombraJWTConfig',
    'retentionSchedules',
    'dataRetentionSchedulePolicy',
    'updateDataRetentionSchedulePolicy',
  ],
  [ScopeName.ManageEmailTemplates]: [
    'createTemplate',
    'deleteTemplate',
    'updateTemplate',
  ],
  [ScopeName.ManageOrganizationInfo]: [
    'slackWorkspaceMetadata',
    'updateOrganizationInfo',
    'setOrgNotificationDestinationAllowList',
    'updateSavingsConfiguration',
    'createOrUpdateOrganizationPreference',
  ],
  [ScopeName.ViewPrivacyCenter]: [
    // GraphQL routes
    'assetFiles',
    'translatedMessages',
    'currentEmailSenderAddresses',
    'privacyCenterVersionModules',
  ],
  [ScopeName.ViewAuditEvents]: ['auditEvents'],
  [ScopeName.ManagePrivacyCenter]: [
    // GraphQL routes
    'updateIntlMessages',
    'updatePrivacyCenter',
    'setRequestsProcessedDisclosureStatsSettings',
    'updatePolicies',
    'deletePolicies',
    'createDataApplication',
    'createEmailSenderAddress',
    'updateEmailSenderAddress',
    'deleteEmailSenderAddress',
    'deployPrivacyCenterVersion',
    'sendTestEmail',
    // REST routes
    'filesAssetFileUploadPost',
  ],
  [ScopeName.ManageRequestCompilation]: [
    // GraphQL routes
    'changeRequestDataSiloStatus',
    'changeRequestDataSiloStatuses',
    'deleteRequestFile',
    'addDataSiloToOpenRequests',
    'markLinkOnlyRequestFilesAsReady',
    'profileDataPoints',
    'redactRequestFiles',
    'retryProfileDataPoint',
    'retryProfileDataPointSecondary',
    'retryRequestDataSilo',
    'retryRequestEnricher',
    'removeRequestIdentifiers',
    'skipRequestEnricher',
    'updateRequest',
    'updateRequestFileStatus',
    'setResourceAttributes',

    // TODO - https://transcend.height.app/T-37606 make workflows scope
    'workflows',
    'workflowIdentifiers',
    'workflowEnrichers',
    'workflowCommunicationsSettings',
    'workflowConfig',
    'createWorkflow',
    'addEnrichersToWorkflowConfig',
    'updateEnrichersInWorkflowConfig',
    'removeEnrichersFromWorkflowConfig',

    // REST routes
    'filesRequestFilePeekRequestFileIdGet', // TODO: https://transcend.height.app/T-18552 - remove
    'filesRequestFileUploadPost',
    'apiRequestIdentifiersPost',
  ],
  [ScopeName.ManageAssignedRequests]: [],
  [ScopeName.ManageRequestIdentities]: [
    'createEnricher',
    'deleteEnricher',
    'deleteIdentifiers',
    'updateEnricher',
    'retryEnricherErrors',
    'fetchEnrichmentMetadataForDataSilo',
    'createIdentifier',
    'updateIdentifier',
    'newIdentifierTypes',
    'addSignedIdentifiers',
  ],
  [ScopeName.RequestApproval]: [
    'approveRequest',
    'approveRequestSecondary',
    'cancelRequest',
    'fireSecondaryAction',
    'resumeRequest',
    'toggleCommunicationRead',
    'notifyAdditionalTime',
    'placeRequestOnHold',
    'sendCommunication',
    'compileRequest',
  ],
  [ScopeName.ViewApiKeys]: ['apiKey', 'apiKeys', 'scopes'],
  [ScopeName.ViewDataMap]: [
    'dataSilo',
    'dataSilos',
    'dataSiloLineage',
    'vendors',
    'teams',
    'users',
    'businessEntities',
    'contentClassificationSilos',
    'subDataPoints',
    'subDataPointErrorRollup',
    'pluginScans',
    'dataCollections',
    'lookupProcesses',
    'inspectLookupProcesses',
    'dataPoints',
    'dataReports',
    'activities',
    'plugins',
    'dataSiloPluginRecommendation',
    'analyticsData',
    'savingsConfiguration',
    'dataSiloCountsByCountry',
    'dataSiloRateLimits',
    'latestScan',
    'latestSchemaDiscoveryScanProgress',
    // TODO: https://transcend.height.app/T-31072 - Move these out into their own separate scopes.
    'unstructuredDataDiscoveryFiles',
    'unstructuredSubDataPointRecommendations',
    'unstructuredDiscoverySilos',
    'unwrapFileSnippets',
  ],
  [ScopeName.ViewAssignedIntegrations]: [
    'teams',
    'users',
    'dataCollections',
    'analyticsData',
    'savingsConfiguration',
    'dataSiloCountsByCountry',
  ],
  [ScopeName.ViewDataSubjectRequestSettings]: ['actions', 'internalSubjects'],
  [ScopeName.ViewEmailTemplates]: ['defaultTemplates', 'templates'],
  [ScopeName.ViewRequestCompilation]: [
    'activities',
    'requestDataSilo',
    'requestDataSiloDataPoints',
    'requestDataSilos',
    'requestEnrichers',
    'requestFiles',
    'requestIdentifiers',
    'unwrapEmailContents',
    'unwrapProfileIdentifier',
    'unwrapRequestIdentifier',
    // REST routes
    'apiDataSubjectRequestIdDownloadKeysGet',
  ],
  [ScopeName.ViewRequestIdentitySettings]: [
    'enricher',
    'enrichers',
    'initializer',
    'identifiers',
  ],
  [ScopeName.ViewSSO]: ['ssoProvider'],
  [ScopeName.ViewRequests]: [
    // GraphQL routes
    'request',
    'dataSubjectCommunications',
    'dataSiloCommunications',
    'requests',
    'savingsConfiguration',
    'analyticsData',
    // REST routes
    'apiDataSubjectRequestIdGet',
    'requestsForDataSilo',
  ],
  [ScopeName.ViewAssignedRequests]: ['savingsConfiguration', 'analyticsData'],
  [ScopeName.ViewScopes]: ['scopes'],
  [ScopeName.ViewEmployees]: ['users', 'teams'],
  [ScopeName.ViewEmailDomains]: ['currentEmailDomains'],
  [ScopeName.ManageEmailDomains]: [
    'addEmailDomain',
    'deleteEmailDomain',
    'sendTestEmail',
  ],
  [ScopeName.ViewDataInventory]: [
    'teams',
    'users',
    'subDataPoints',
    'dataCategories',
    'dataSubCategories',
    'processingPurposes',
    'processingPurposeSubCategories',
    'scannedObjectHierarchyPath',
    'siloDiscoveryRecommendations',
    'siloDiscoveryPendingRecommendationCount',
    'dataPointHierarchy',
    'contentClassificationSilos',
    'dataPointHierarchyPath',
    'contentClassificationDataPoints',
    'unstructuredDiscoverySilos',
  ],
  [ScopeName.ViewAssignedDataInventory]: [
    'dataCollections',
    'siloDiscoveryPendingRecommendationCount',
    'dataSiloCountsByCountry',
    'analyticsData',
    'teams',
    'users',
  ],
  [ScopeName.ManageDataInventory]: [
    'updateDataPointLevel',
    'createVendor',
    'updateVendors',
    'deleteVendors',
    'deleteBusinessEntities',
    'createBusinessEntity',
    'updateBusinessEntities',
    'updateDataSilos',
    'deleteDataReports',
    'deleteDataSilos',
    'initiateSitescan',
    'createDataSubCategory',
    'updateDataSubCategories',
    'deleteDataSubCategories',
    'createProcessingPurposeSubCategory',
    'deleteProcessingPurposeSubCategories',
    'updateProcessingPurposeSubCategories',
    'markSiloRecommendationsJunk',
    'markSiloRecommendationsDuplicate',
    'addSiloDiscoveryResults',
    'updateSubDataPoints',
    'createSubDataPoint',
    'unwrapSubDataPointSamples', // TODO: https://transcend.height.app/T-18552 - remove
    'refetchSubDataPointSample',
    'setResourceAttributes',
    'updateUnstructuredSubDataPointRecommendations',
  ],
  [ScopeName.ManageAssignedDataInventory]: [
    'createVendor',
    'createBusinessEntity',
    'initiateSitescan',
    'createDataSubCategory',
    'createProcessingPurposeSubCategory',
    'addSiloDiscoveryResults',
    'createSubDataPoint',
  ],
  [ScopeName.ManageActionItemCollections]: [
    'createActionItemCollection',
    'updateActionItemCollection',
    'deleteActionItemCollections',
  ],
  [ScopeName.ViewGlobalAttributes]: [
    'attributeKeys',
    'attributeValues',
    'attributeKey',
  ],
  [ScopeName.ManageGlobalAttributes]: [
    'createAttributeKey',
    'deleteAttributeKey',
    'updateAttributeKey',
    'deleteAttributeValue',
    'updateAttributeValues',
    'createAttributeValues',
  ],
  [ScopeName.ViewManagedConsentDatabaseAdminApi]: [
    'queryConsentPreferences',
    'consentPreferences',
    'purposes',
    'recordsOfConsent',
  ],
  [ScopeName.ManageStoredPreferences]: [
    'adminUpsertPreferences',
    'employeeUpdateConsentPreferences',
  ],
  [ScopeName.ManagePreferenceStoreSettings]: [
    'createOrUpdateConsentWorkflowTrigger',
    'deleteConsentWorkflowTriggers',
    'deletePreferenceTopics',
    'createOrUpdatePreferenceTopic',
    'createOrUpdatePreferenceOptionValues',
    'deletePreferenceOptionValues',
  ],
  [ScopeName.ViewPreferenceStoreSettings]: [
    'consentWorkflowTriggers',
    'preferenceTopics',
    'preferenceOptionValues',
  ],
  [ScopeName.ViewPrompts]: [
    'largeLanguageModels',
    'prompts',
    'promptsWithVariables',
    'promptPartials',
    'promptGroups',
  ],
  [ScopeName.ManagePrompts]: [
    'deletePrompts',
    'deletePromptPartials',
    'deletePromptGroups',
    'createPrompt',
    'createPromptGroup',
    'createPromptPartial',
    'updatePrompts',
    'updatePromptPartials',
    'updatePromptGroups',
    'promptThreads',
  ],
  [ScopeName.ApprovePrompts]: [],
  [ScopeName.ManagePromptRuns]: [
    'reportPromptRun',
    'addMessagesToPromptRun',
    'createPromptThread',
    'updatePromptThreads',
    'deletePromptThreads',
  ],
  [ScopeName.ExecutePrompt]: ['executePromptRun'],
  [ScopeName.ViewPromptRuns]: ['promptRuns', 'promptThreads'],
  [ScopeName.ManageAuditor]: [
    'createAuditorSchedule',
    'deleteAuditorSchedules',
    'updateAuditorSchedule',
  ],
  [ScopeName.ExecuteAuditor]: ['runAuditor'],
  [ScopeName.ViewAuditorRuns]: [
    'auditorRun',
    'auditorRunCookies',
    'auditorRunDataFlows',
    'auditorRuns',
    'auditorFileLink',
    'auditorSchedules',
    'auditorFindings',
    'auditorFindingClassCounts',
  ],
  [ScopeName.ManageContractScanning]: [
    'createContract',
    'updateContracts',
    'deleteContracts',
    'createContractScan',
    'updateContractScans',
    'deleteContractScans',
  ],
  [ScopeName.ViewContractScanning]: ['contracts', 'contractScans'],
  [ScopeName.ManagePathfinder]: [
    'deleteApplications',
    'deletePathfinders',
    'deleteApplicationUsers',
    'deletePathfinderPolicies',
    'updateApplications',
    'updatePathfinders',
    'updateOrCreateApplicationUsers',
    'updatePathfinderPolicies',
    'createApplication',
    'createPathfinder',
    'createPathfinderPolicy',
    'createAgent',
    'updateAgents',
    'deleteAgents',
    'createAgentFunction',
    'deleteAgentFunctions',
    'updateAgentFunctions',
    'createAgentFile',
    'deleteAgentFiles',
    'updateAgentFiles',
  ],
  [ScopeName.ViewPathfinder]: [
    'applications',
    'pathfinders',
    'applicationUsers',
    'pathfinderPolicies',
    'agents',
    'agentFiles',
    'agentFunctions',
  ],
  [ScopeName.ManageCodeScanning]: [
    'deleteRepositories',
    'deleteCodePackages',
    'deletePullRequests',
    'updateRepositories',
    'updateCodePackages',
    'updatePullRequests',
    'createRepository',
    'createCodePackage',
    'createPullRequest',
    'deleteSoftwareDevelopmentKits',
    'updateSoftwareDevelopmentKits',
    'createSoftwareDevelopmentKit',
  ],
  [ScopeName.ViewCodeScanning]: [
    'repositories',
    'codePackages',
    'pullRequests',
    'softwareDevelopmentKits',
  ],
  [ScopeName.ManagePolicies]: ['updatePolicies'],
  [ScopeName.ViewPolicies]: [],
  [ScopeName.ManageIntlMessages]: ['updateIntlMessages'],
  [ScopeName.ViewIntlMessages]: ['translatedMessages'],
  [ScopeName.LLMLogTransfer]: [],
};

/**
 * Mapping from scope definition to list of routes that scope grants access to
 */
export const SCOPE_TO_ROUTES: { [name in ScopeName]: string[] } = {
  ...SCOPE_TO_ROUTES_MINUS_SMART,
  [ScopeName.ReadOnly]: uniq(
    getEntries(SCOPE_TO_ROUTES_MINUS_SMART)
      .filter(
        ([scopeName]) => TRANSCEND_SCOPES[scopeName].type === ScopeType.View,
      )
      .flatMap(([, values]) => values),
  ),
  [ScopeName.FullAdmin]: uniq(
    Object.values(SCOPE_TO_ROUTES_MINUS_SMART).flat(),
  ),
};

export const ALL_SCOPED_ROUTES = uniq(Object.values(SCOPE_TO_ROUTES).flat());

/**
 * This is the mapping from name of scope to other scopes that are accessible when that scope is applied.
 *
 * i.e. if i can modify access controls, i can view access controls even if that scope is not specifically applied
 */
export const DEPENDENT_SCOPES: { [k in ScopeName]: ScopeName[] } = mapValues(
  TRANSCEND_SCOPES,
  ({ dependencies }) =>
    uniq([
      ...dependencies,
      ...dependencies.flatMap((dep) => TRANSCEND_SCOPES[dep].dependencies),
    ]),
);

/* eslint-enable max-lines */
