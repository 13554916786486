/**
 * Hash a string
 *
 * @param str - The string
 * @returns The hashed string
 */
export function hashCode(str: string): number {
  // java String#hashCode
  let hash = 0;
  for (let i = 0; i < str.length; i += 1) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash); // eslint-disable-line no-bitwise
  }
  return hash;
}

/**
 * Convert an int to RGB
 *
 * @param i - The int
 * @returns The RGB
 */
export function intToRGB(i: number): string {
  const c = (i & 0x00ffffff) // eslint-disable-line no-bitwise
    .toString(16)
    .toUpperCase();

  return '00000'.substring(0, 6 - c.length) + c;
}

/**
 * Convert a string to a random hex color with a direct mapping
 *
 * @param str - The str to convert
 * @returns The hex color code
 */
export function stringToColor(str: string): string {
  let res = intToRGB(hashCode(str));
  res = res.replace('A', '4');
  res = res.replace('B', '5');
  res = res.replace('C', '6');
  res = res.replace('D', '7');
  res = res.replace('E', '8');
  res = res.replace('F', '9');
  return `#${res}`;
}
