import { mkType, SchemaToType } from '@main/schema-utils';

export const CloudFront = mkType({
  name: 'CloudFront',
  comment:
    'Metadata about the cloudfront instance that the privacy center is deployed on',
  fields: {
    id: {
      comment: 'The unique ID of the cloudfront',
      modelName: 'cloudfront',
      type: 'id',
    },
    hostedDomainName: {
      comment:
        'The domain name for the hosted site on a Cloudfront CDN. Where the organization should point their domain',
      type: 'string',
    },
    deployDomainName: {
      comment: 'The expected domain of the deployed privacy center',
      type: 'string',
    },
    isDeployed: {
      comment: 'Indicates if the cloudfront is deployed',
      type: 'boolean',
    },
    isValidated: {
      comment: 'True if cloudfront DNS record is set',
      type: 'boolean',
    },
  },
});

/** Override type */
export type CloudFront = SchemaToType<typeof CloudFront>;
