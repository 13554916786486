import * as t from 'io-ts';

import { dbModelId } from '@main/schema-utils';

import { AuditEventCode } from '../schema/enums';
import {
  mkAuditEventCodec,
  mkAuditEventJoinCodec,
  mkAuditJoinStateCodec,
} from '../types';

export const AuditEventOrgTcfPurpose = mkAuditEventCodec(
  AuditEventCode.OrgTcfPurpose,
  t.type({
    /** ID of the tcf purpose for an organization */
    orgTcfPurposeId: dbModelId('orgTcfPurpose'),
  }),
);

/** Override types. */
export type AuditEventOrgTcfPurpose = t.TypeOf<typeof AuditEventOrgTcfPurpose>;

export const AuditEventOrgTcfPurposePurpose = mkAuditEventJoinCodec(
  AuditEventCode.OrgTcfPurposePurpose,
  mkAuditJoinStateCodec('purpose'),
  t.type({
    /** ID of the tcf purpose for an organization */
    orgTcfPurposeId: dbModelId('orgTcfPurpose'),
  }),
);

/** Override types. */
export type AuditEventOrgTcfPurposePurpose = t.TypeOf<
  typeof AuditEventOrgTcfPurposePurpose
>;
