/**
 * workflows statuses
 */
export enum WorkflowConfigStatus {
  /** live */
  Live = 'ENABLED',
  /** offline */
  Offline = 'DISABLED',
}

/**
 * Workflow instance statuses
 */
export enum WorkflowInstanceStatus {
  /** Workflow has been created and is waiting execution */
  Initialized = 'INITIALIZED',
  /** If the workflow is running normally */
  InProgress = 'IN_PROGRESS',
  /** If the workflow has been canceled */
  Canceled = 'CANCELED',
  /** Awaiting human interaction, approval, manual phase transition, etc */
  AwaitingInteraction = 'AWAITING_INTERACTION',
  /** Execution has been paused */
  Paused = 'PAUSED',
  /** If the workflow has thrown any sort of error while processing but is not stopped */
  InProgressWithErrors = 'IN_PROGRESS_WITH_ERRORS',
  /** Completed with no outstanding errors */
  CompletedSuccessfully = 'COMPLETED_SUCCESSFULLY',
  /** Managed to complete, but encountered errors in doing so */
  CompletedWIthErrors = 'COMPLETED_WITH_ERRORS',
}

/**
 * Workflow job statuses
 */
export enum WorkflowJobStatus {
  /** Job has been queued by scheduler and is waiting to be picked up by processor */
  Queued = 'QUEUED',
  /** Job has completed work successfully */
  Success = 'SUCCESS',
  /** Job has failed with error */
  Error = 'ERROR',
  /** Job has been sent to a secondary processor queue like SQS for integrations */
  SentToSecondaryQueue = 'SENT_TO_SECONDARY_QUEUE',
  /** Job is waiting for response from external service */
  AwaitingExternalResponse = 'AWAITING_EXTERNAL_RESPONSE',
  /** Awaiting human interaction such as verification via sent email or text, etc */
  AwaitingInteraction = 'AWAITING_INTERACTION',
}

/**
 * All phases that a workflow can be in, which determine which jobs are allowed to be scheduled
 */
export enum WorkflowPhase {
  /** before the workflow has been picked up by the scheduler daemon */
  Initialization = 'INITIALIZATION',
  /** verifying identifiers in pre-flight checks */
  Verification = 'VERIFICATION',
  /** enriching identifiers and files */
  Enrichment = 'ENRICHMENT',
  /** acting on collected data from specific integration datapoints */
  DataPointAction = 'DATAPOINT_ACTION',
  /** No more jobs to be queued */
  JobsCompleted = 'JOBS_COMPLETED',
}

/**
 * phases that a DSR workflow can be in and their execution order
 */
export const DSRWorkflowPhases = [
  WorkflowPhase.Initialization,
  WorkflowPhase.Verification,
  WorkflowPhase.Enrichment,
  WorkflowPhase.DataPointAction,
  WorkflowPhase.JobsCompleted,
];

/**
 * types of things that can trigger a workflow
 */
export enum WorkflowTrigger {
  /** for workflows that have been initiated because of a DSR */
  Request = 'REQUEST',
}

/**
 * execution order of phases for a given trigger
 */
export const WorkflowPhaseMapping: Record<
  WorkflowTrigger,
  Array<WorkflowPhase>
> = {
  [WorkflowTrigger.Request]: [
    WorkflowPhase.Initialization,
    WorkflowPhase.Verification,
    WorkflowPhase.Enrichment,
    WorkflowPhase.DataPointAction,
    WorkflowPhase.JobsCompleted,
  ],
};

/** data subject region detection */
export enum CollectDataSubjectRegions {
  DoNotCollect = 'DO_NOT_COLLECT',
  AutoDetect = 'AUTO_DETECT',
  Collect = 'COLLECT',
}

/** The action to take when an id is missing from an enricher */
export enum MissingIdBehavior {
  Skip = 'SKIP',
  Cancel = 'CANCEL',
  Notify = 'NOTIFY',
}

/** The types of communication a WorkflowConfigCommunication row will handle */
export enum WorkflowCommunicationMethod {
  Email = 'EMAIL',
}
