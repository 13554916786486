import type { ApolloError } from '@apollo/client';
import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { endpoints } from '@main/access-control-types';
import { logger } from '@main/core-ui';
import { ResponseToType } from '@main/schema-utils';

import { selectNoSombraAuth } from '../Auth/App/selectors';
import { setSombraSessionSecret } from '../Auth/App/slice';
import { buildUseDhMutation } from './buildUseDhMutation';

const useIsUserAuthenticated = buildUseDhMutation(
  endpoints.isUserAuthenticated,
);

/**
 * Returns a function that checks the current sombra session status
 * If transcend authentication is enabled, re-login can happen automatically
 *
 * @param options - Options
 * @returns A function to check the session, plus loading, error
 */
export const useCheckSombraSession = ({
  checkOnMount,
}: {
  /** When true, check on mount */
  checkOnMount?: boolean;
} = {}): [
  // function to check session, undefined if no permissions
  (
    | undefined
    | (() => Promise<
        | ResponseToType<typeof endpoints.isUserAuthenticated.response>
        | undefined
        | null
      >)
  ),
  {
    /** Loading */
    loading: boolean;
    /** Error if happened */
    error?: ApolloError;
  },
] => {
  const dispatch = useDispatch();
  const noSombraAuth = useSelector(selectNoSombraAuth);

  // mutation to check if users is logged in
  const [isUserAuthenticated, { loading, error }] = useIsUserAuthenticated({
    includePublicKey: true,
  });

  // wrap function to write back to redux
  const checkLogin = useCallback(() => {
    if (!isUserAuthenticated) {
      throw new Error('User is not authorized to connect to sombra');
    }
    return isUserAuthenticated({
      variables: {
        publicKey: '', // this is overwritten in the hook because `includePublicKey: true`
      },
      dhEncryptedInput: {},
    }).then(
      (
        check,
      ):
        | ResponseToType<typeof endpoints.isUserAuthenticated.response>
        | undefined
        | null => {
        // update session when re-login happens automatically
        if (check.unwrappedResponse) {
          logger.warn(
            `Updating sombra session after isUserAuthenticated check`,
          );
          dispatch(
            setSombraSessionSecret(check.unwrappedResponse.toString('utf-8')),
          );
        }
        return check.data;
      },
    );
  }, [isUserAuthenticated, dispatch]);

  // run check on mount
  useEffect(() => {
    if (checkOnMount && isUserAuthenticated && !noSombraAuth) {
      checkLogin();
    }
    // only want to do once on mount no matter what
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // return function to call
  return [
    isUserAuthenticated && !noSombraAuth ? checkLogin : undefined,
    {
      loading,
      error,
    },
  ];
};
