import { gql } from '@apollo/client';
import { useInlineInput } from '@main/ad-core-components';
import { buildUseMutation } from '@main/core-ui';
import { endpoints, SubDataPoint } from '@main/datamap-types';
import type { ID } from '@main/schema-utils';
import React from 'react';

import { objectToGqlString } from '../../helpers/objectToGqlString';
import { updateSubDataPointsGqlCache } from '../../helpers/updateSubDataPointsGqlCache';
import { INLINE_SELECT_VENDOR_FIELDS } from './constants';
import { SelectVendor, SelectVendorProps } from './SelectVendor';
import { SelectedVendor } from './types';

const useUpdateDataSilos = buildUseMutation(
  endpoints.updateDataSilos,
  endpoints.updateDataSilos.name,
  {
    dataSilos: {
      id: null,
      vendor: INLINE_SELECT_VENDOR_FIELDS,
    },
  },
);
const useCreateTeam = buildUseMutation(endpoints.createVendor);

/** Props for the `InlineSelectVendor` component */
interface InlineSelectVendorProps
  extends Omit<SelectVendorProps, 'onChange' | 'onBlur'> {
  /** The ID of the parent data silo for this cell */
  id: ID<'dataSilo'>;
  /** The value for the cell */
  value: SelectedVendor;
  /** The queries to be refetched when updating the vendors */
  refetchQueries?: string[];
  /** Other subdatapoints whose data silo's vendor should potentially be updated after a change */
  subDataPointsToUpdate?: SubDataPoint[];
}

export const InlineSelectVendor: React.FC<InlineSelectVendorProps> = ({
  id,
  value: initialValue,
  refetchQueries,
  isLoading,
  subDataPointsToUpdate,
  ...props
}) => {
  const [createVendor, createVendorResult] = useCreateTeam({
    refetchQueries: ['Vendors', 'SelectVendors'],
  });

  const [updateDataSilos, { loading }] = useUpdateDataSilos({
    ...(refetchQueries ? { refetchQueries } : {}),
    ...(subDataPointsToUpdate
      ? {
          update: (cache, { data }) => {
            const [dataSilo] = (data as any).updateDataSilos.dataSilos;

            // Manually update the cache so that we don't have to refetch the entire (slow) subdatapoints query
            updateSubDataPointsGqlCache({
              subDataPointsToUpdate,
              cache,
              data: {
                dataSiloVendor: dataSilo.vendor,
              },
              fragment: gql`
                fragment DataSiloVendor on SubDataPoint {
                  dataSiloVendor {
                    ${objectToGqlString(INLINE_SELECT_VENDOR_FIELDS)}
                  }
                }
              `,
              // dataPoint should always be defined, but could get forgotten in the GQL response fields
              shouldUpdate: (sdp) => sdp.dataPoint.dataSilo.id === dataSilo.id,
            });
          },
        }
      : {}),
  });
  const { value, setValue, onBlur } = useInlineInput<SelectedVendor | null>({
    initialValue,
    onUpdate: async (value) => {
      // clear
      if (!value) {
        return updateDataSilos({
          variables: {
            input: {
              dataSilos: [
                {
                  id,
                  vendorId: null as any,
                },
              ],
            },
          },
        }).then(({ data, errors }) => ({
          data: data?.dataSilos[0]?.vendor,
          errors,
        }));
      }

      let vendorId = value.id;
      if (value.isNew) {
        const { data } = await createVendor({
          variables: { input: { title: value.title, description: '' } },
        });
        if (!data) {
          throw new Error(
            `Failed to create vendor with title: "${value.title}"`,
          );
        }
        vendorId = data.vendor.id;
      }

      return updateDataSilos({
        variables: {
          input: {
            dataSilos: [
              {
                id,
                vendorId,
              },
            ],
          },
        },
      }).then(({ data, errors }) => ({
        data: data?.dataSilos[0]?.vendor,
        errors,
      }));
    },
  });

  return (
    <SelectVendor
      variant="light"
      value={value}
      allowCreate
      showOutline={false}
      onChange={setValue}
      isLoading={loading || createVendorResult.loading || isLoading}
      onBlur={onBlur}
      {...props}
    />
  );
};
