import type {
  PurposeOfProcessing,
  PurposeSubCategory,
} from '@main/datamap-types';

/** An item corresponding to a row on the `DataCategories` tab */
export type PurposesItem =
  | (PurposeOfProcessing & {
      /** Attribute values */
      attributeValues: never;
      /** Owners */
      owners: never;
      /** Teams */
      teams: never;
    })
  | PurposeSubCategory;

/**
 * Narrow type of processing purpose to a purpose subcategory
 *
 * @param purpose - The processing purpose item
 * @returns Whether the processing purpose item is a subcategory
 */
export function isPurposeSubCategory(
  purpose: PurposesItem,
): purpose is PurposeSubCategory {
  return 'id' in purpose && 'isDefault' in purpose;
}
