import { defineMessages } from '@transcend-io/internationalization';

export const dashboardTileMessages = defineMessages(
  'ad-core-components.DashboardTile.dashboardTile',
  {
    refetchNow: {
      defaultMessage: 'Refresh',
    },
    lastUpdated: {
      defaultMessage: 'Last Updated',
    },
    percentChangeTooltip: {
      defaultMessage:
        'Percent change from previous time period to current time period. This is ' +
        'calculated by taking the sum total value from the previous time period ' +
        '(of the same duration as the current selection) and comparing it to the ' +
        'sum total from the current time period.',
    },
  },
);
