import { AdminDashboardPublicOrPrivatePath } from '@main/access-control-types';
import {
  A,
  ContentErrorBoundary,
  GenericMessageDescriptor,
  ReactSelect,
  TranscendLogo,
  useFormatMessageGeneric,
} from '@main/core-ui';
import React, { CSSProperties, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useIntl } from 'react-intl';

import { WizardPanelImagePosition } from './enums';
import { wizardPanelMessages } from './messages';
import { StyledImage, StyledWizardCard, StyledWrapper } from './wrappers';

export interface WizardPanelProps {
  /** The card styles */
  cardStyle?: CSSProperties;
  /** The image url to display at the top of the panel, width 130px */
  imageSrc?: string;
  /** Where to display the image */
  imagePosition?: WizardPanelImagePosition;
  /** Show the Transcend logo above the card? */
  showTranscendLogo?: boolean;
  /** Currently selected backend URL */
  selectedBackendUrl?: string;
  /** The title, displayed as heading and used in page meta title */
  title?: GenericMessageDescriptor;
  /** The subtitle, displayed right below the heading */
  subtitle?: GenericMessageDescriptor;
  /** Show the Region select dropdown? */
  showRegionSelect?: boolean;
  /** The selectable region URLs if using a region dropdown */
  regionBackendUrls?: string[];
  /** Action to take when a URL is selected from the region dropdown */
  onRegionSelect?: (url: string) => void;
}

export const WizardPanel: React.FC<WizardPanelProps> = ({
  children,
  cardStyle = {},
  selectedBackendUrl,
  imageSrc,
  imagePosition = WizardPanelImagePosition.TOP,
  showTranscendLogo = false,
  title,
  subtitle,
  showRegionSelect = false,
  regionBackendUrls = [],
  onRegionSelect,
}) => {
  const { formatMessage } = useIntl();
  const { formatMessageGeneric } = useFormatMessageGeneric();
  const [selectedRegion, setSelectedRegion] = useState<string>();

  return (
    <StyledWrapper>
      {showTranscendLogo && (
        <div style={{ marginTop: '30px', marginBottom: '30px' }}>
          <A href={AdminDashboardPublicOrPrivatePath.Login}>
            <TranscendLogo transcendSolid id="transcend-logo" />
          </A>
        </div>
      )}
      <StyledWizardCard
        $hasImage={!!imageSrc}
        $imagePosition={imagePosition}
        $hasSubtitle={!!subtitle}
        borderRadius="18px"
        style={cardStyle}
      >
        {imageSrc && (
          <StyledImage
            src={imageSrc}
            $overlap={imagePosition === WizardPanelImagePosition.TOP_OVERLAP}
          />
        )}
        {title && (
          <>
            {/*
             * If this h1 tag is changed or Helmet is removed, consider adding titles
             * to the routes of pages that were using this component to ensure h1s
             * and document titles exist on every page
             * */}
            <Helmet>
              <title>{formatMessageGeneric(title)}</title>
            </Helmet>
            <h1>{formatMessageGeneric(title)}</h1>
          </>
        )}
        {subtitle && <h3>{formatMessageGeneric(subtitle)}</h3>}
        <ContentErrorBoundary>{children}</ContentErrorBoundary>
        {showRegionSelect && (
          <>
            <br />
            <h2>{formatMessage(wizardPanelMessages.selectBackend)}</h2>
            <ReactSelect
              options={regionBackendUrls.map((url) => ({
                value: url,
              }))}
              isMulti={false}
              isClearable={false}
              isCreatable={false}
              showOutline
              isQueryLoading={false}
              getOptionLabel={(option) => option.value}
              onChange={(option) => {
                if (option?.value && onRegionSelect) {
                  setSelectedRegion(option.value);
                  onRegionSelect(option.value);
                }
              }}
              value={
                selectedRegion
                  ? { value: selectedRegion }
                  : selectedBackendUrl
                    ? { value: selectedBackendUrl }
                    : undefined
              }
            />
          </>
        )}
      </StyledWizardCard>
    </StyledWrapper>
  );
};
