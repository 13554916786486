import { ConsentTrackerStatus } from '@transcend-io/privacy-types';

import { SelectableCatalog } from '@main/datamap-types';
import { mkInput, mkInterface, mkType, SchemaToType } from '@main/schema-utils';

export const ConsentServicePreviewInterface = mkInterface({
  name: 'ConsentServicePreviewInterface',
  comment: 'Metadata relating to a service regulated by our Consent Manager',
  fields: {
    id: {
      type: 'id',
      modelName: 'consentService',
      comment: 'ID of the consent service',
    },
    service: {
      comment: 'Metadata about the service',
      type: SelectableCatalog,
    },
    name: {
      type: 'string',
      comment: 'Unique name for this service',
    },
  },
});

/** Override type */
export type ConsentServicePreviewInterface = SchemaToType<
  typeof ConsentServicePreviewInterface
>;

export const ConsentServicePreview = mkType({
  name: 'ConsentServicePreview',
  interfaces: [ConsentServicePreviewInterface],
  comment: 'A small set of fields required for previewing the consent service',
  fields: {
    ...ConsentServicePreviewInterface.fields,
  },
});

/** Override type */
export type ConsentServicePreview = SchemaToType<typeof ConsentServicePreview>;

export const ConsentService = mkType({
  name: 'ConsentService',
  comment: 'Metadata relating to a service regulated by our Consent Manager',
  interfaces: [ConsentServicePreviewInterface],
  fields: {
    ...ConsentServicePreviewInterface.fields,
    description: {
      type: 'string',
      comment: 'Description of this service',
      optional: true,
    },
    status: {
      type: { ConsentTrackerStatus },
      comment:
        'Status of this service -- only live and not-junk services are included in the live airgap bundle',
    },
    isJunk: {
      type: 'boolean',
      comment:
        'Whether or not this consent service should be included in the live airgap bundle',
    },
    dataFlowCount: {
      comment:
        'The number of live, not junk data flows found associated to the same catalog entry as this consent service',
      type: 'int',
    },
    cookieCount: {
      comment:
        'The number of live, not junk cookies found associated to the same catalog entry as this consent service',
      type: 'int',
    },
    sdkCount: {
      comment: 'The number of SDKs found associated to the same catalog entry',
      type: 'int',
    },
    showInTcfModal: {
      type: 'boolean',
      comment:
        'Whether or not to show this consent service in the TCF UI modal',
    },
  },
});

/** Override type */
export type ConsentService = SchemaToType<typeof ConsentService>;

export const UpsertConsentServiceInput = mkInput({
  name: 'UpsertConsentServiceInput',
  comment: 'Input for creating or updating a single consent service',
  fields: {
    id: {
      ...ConsentService.fields.id,
      comment:
        'ID of the consent service -- if not specified, we will create a new entry',
      optional: true,
    },
    name: {
      ...ConsentService.fields.name,
      optional: true,
    },
    catalogIntegrationName: {
      comment:
        'The integration name of the catalog entry for this consent service',
      type: 'string',
      optional: true,
    },
    description: ConsentService.fields.description,
    status: {
      ...ConsentService.fields.status,
      optional: true,
    },
    isJunk: {
      ...ConsentService.fields.isJunk,
      optional: true,
    },
    showInTcfModal: {
      ...ConsentService.fields.showInTcfModal,
      optional: true,
    },
  },
});

/** Override type */
export type UpsertConsentServiceInput = SchemaToType<
  typeof UpsertConsentServiceInput
>;

export const CreateOrUpdateConsentServicesInput = mkInput({
  name: 'CreateOrUpdateConsentServicesInput',
  comment: 'Input for creating or updating a list of consent services',
  fields: {
    airgapBundleId: {
      type: 'id',
      modelName: 'airgapBundle',
      comment: 'ID of the airgap bundle that this consent service belongs to',
    },
    consentServices: {
      comment: 'The list of consent services to create or update',
      type: UpsertConsentServiceInput,
      list: true,
    },
  },
});

/** Override type */
export type CreateOrUpdateConsentServicesInput = SchemaToType<
  typeof CreateOrUpdateConsentServicesInput
>;

export const FilterConsentServicesInput = mkInput({
  name: 'FilterConsentServicesInput',
  comment: 'Input for filtering consent services',
  fields: {
    ids: {
      type: 'id',
      modelName: 'consentService',
      comment: 'Filter by exact match on ID',
      optional: true,
      list: true,
    },
    text: {
      type: 'string',
      comment: 'Filter by a fuzzy match on the slug or service name',
      optional: true,
    },
    status: {
      comment: 'Filter by the status of this consent service',
      type: { ConsentTrackerStatus },
      optional: true,
    },
    isJunk: {
      comment:
        'Filter by whether or not this consent service is considered "junk"',
      type: 'boolean',
      optional: true,
    },
    showInTcfModal: {
      comment: 'Filter for services that are shown in the TCF UI modal',
      type: 'boolean',
      optional: true,
    },
    isIab: {
      comment: 'Filter for services that are IAB registered vendors',
      type: 'boolean',
      optional: true,
    },
  },
});

/** Override type */
export type FilterConsentServicesInput = SchemaToType<
  typeof FilterConsentServicesInput
>;

export const DeleteConsentServicesInput = mkInput({
  name: 'DeleteConsentServicesInput',
  comment: 'Input for deleting consent services by ID',
  fields: {
    airgapBundleId: {
      type: 'id',
      modelName: 'airgapBundle',
      comment: 'ID of the airgap bundle that this consent service belongs to',
    },
    ids: {
      type: 'id',
      modelName: 'consentService',
      comment: 'IDs of the consent services to delete',
      list: true,
    },
  },
});

/** Override type */
export type DeleteConsentServicesInput = SchemaToType<
  typeof DeleteConsentServicesInput
>;
