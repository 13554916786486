import {
  DataSubCategoryInput,
  DataSubCategoryPreview,
} from '@main/datamap-types';
import { mkInput, mkOrder, mkType, SchemaToType } from '@main/schema-utils';

import { PathfinderPolicyOrderField, PathfinderPolicyType } from './enums';
import { PathfinderPreview } from './pathfinder';

export const PathfinderPolicyPreview = mkType({
  name: 'PathfinderPolicyPreview',
  comment: 'A preview of an pathfinder policy instance',
  fields: {
    id: {
      comment: 'The id of the pathfinder policy',
      modelName: 'pathfinderPolicy',
      type: 'id',
    },
    name: {
      comment: 'The name of the pathfinder policy',
      type: 'string',
    },
    description: {
      comment: 'The description of the pathfinder policy',
      type: 'string',
    },
    createdAt: {
      comment: 'The date the pathfinder policy was created',
      type: 'Date',
    },
    updatedAt: {
      comment: 'The date the pathfinder policy was last updated',
      type: 'Date',
    },
    type: {
      comment: 'The type of pathfinder policy',
      type: { PathfinderPolicyType },
    },
    response: {
      comment:
        'When the policy is meant to reject input, this is the default response message',
      type: 'string',
      optional: true,
    },
    dataSubCategories: {
      comment: 'The data sub  categories that this policy applies to',
      type: DataSubCategoryPreview,
      list: true,
    },
  },
});

/** Override type */
export type PathfinderPolicyPreview = SchemaToType<
  typeof PathfinderPolicyPreview
>;

export const PathfinderPolicy = mkType({
  name: 'PathfinderPolicy',
  comment: 'A policy that is applied to a pathfinder service',
  fields: {
    ...PathfinderPolicyPreview.fields,
    pathfinders: {
      comment: 'The list of pathfinder instances that this policy applies to',
      type: () => PathfinderPreview,
      list: true,
    },
  },
});

/** Override type */
export type PathfinderPolicy = SchemaToType<typeof PathfinderPolicy>;

export const PathfinderPolicyFiltersInput = mkInput({
  name: 'PathfinderPolicyFiltersInput',
  comment: 'Inputs for filtering a list of pathfinder policies',
  fields: {
    ids: {
      comment: 'The ids of the pathfinder policies',
      type: 'id',
      modelName: 'pathfinderPolicy',
      optional: true,
      list: true,
    },
    pathfinderIds: {
      comment: 'The ids of the pathfinders that the policy applies to',
      type: 'id',
      modelName: 'pathfinder',
      optional: true,
      list: true,
    },
    dataSubCategoryIds: {
      comment: 'The ids of the data sub categories that the policy applies to',
      type: 'id',
      modelName: 'dataSubCategory',
      optional: true,
      list: true,
    },
    text: {
      comment:
        'Filter by text (name, description, response of the pathfinder policy)',
      optional: true,
      type: 'string',
    },
    name: {
      comment: 'Filter by fuzzy name',
      optional: true,
      type: 'string',
    },
    names: {
      comment: 'Filter on exact names',
      optional: true,
      list: true,
      type: 'string',
    },
  },
});

/** Override type */
export type PathfinderPolicyFiltersInput = SchemaToType<
  typeof PathfinderPolicyFiltersInput
>;

export const DeletePathfinderPoliciesInput = mkInput({
  name: 'DeletePathfinderPoliciesInput',
  comment: 'Input for deleting a group of pathfinder policies',
  fields: {
    ids: {
      comment: 'The id of the pathfinder policies to delete',
      type: 'id',
      modelName: 'pathfinderPolicy',
      list: true,
    },
  },
});

/** Override type */
export type DeletePathfinderPoliciesInput = SchemaToType<
  typeof DeletePathfinderPoliciesInput
>;

export const CreatePathfinderPolicyInput = mkInput({
  name: 'CreatePathfinderPolicyInput',
  comment: 'Input for creating a pathfinder policy',
  fields: {
    name: {
      comment: 'The name of the pathfinder policy',
      type: 'string',
    },
    type: {
      comment: 'The type of pathfinder policy',
      type: { PathfinderPolicyType },
    },
    description: {
      comment: 'The description of the pathfinder policy',
      type: 'string',
      optional: true,
    },
    dataSubCategoryIds: {
      comment: 'The ids of the data sub categories that the policy applies to',
      type: 'id',
      modelName: 'dataSubCategory',
      list: true,
      optional: true,
    },
    dataSubCategoryInputs: {
      comment:
        'The data sub categories that the policy applies to, with the id and name',
      type: DataSubCategoryInput,
      list: true,
      optional: true,
    },
    response: {
      comment: 'The response text of the pathfinder policy',
      type: 'string',
      optional: true,
    },
    pathfinderIds: {
      comment: 'The ids of the pathfinders that the policy applies to',
      type: 'id',
      modelName: 'pathfinder',
      optional: true,
      list: true,
    },
    pathfinderNames: {
      comment: 'The names of the pathfinders that the policy applies to',
      type: 'string',
      optional: true,
      list: true,
    },
  },
});

/** Override type */
export type CreatePathfinderPolicyInput = SchemaToType<
  typeof CreatePathfinderPolicyInput
>;

export const UpdatePathfinderPolicyInput = mkInput({
  name: 'UpdatePathfinderPolicyInput',
  comment: 'Input for updating a pathfinder policy',
  fields: {
    id: {
      comment: 'The id of the pathfinder policy',
      modelName: 'pathfinderPolicy',
      type: 'id',
    },
    name: {
      comment: 'The new name of the pathfinder policy',
      type: 'string',
      optional: true,
    },
    type: {
      comment: 'The type of pathfinder policy',
      type: { PathfinderPolicyType },
      optional: true,
    },
    description: {
      comment: 'The description of the pathfinder policy',
      type: 'string',
      optional: true,
    },
    response: {
      comment: 'The response text of the pathfinder policy',
      type: 'string',
      optional: true,
    },
    dataSubCategoryIds: CreatePathfinderPolicyInput.fields.dataSubCategoryIds,
    dataSubCategoryInputs:
      CreatePathfinderPolicyInput.fields.dataSubCategoryInputs,
    pathfinderIds: CreatePathfinderPolicyInput.fields.pathfinderIds,
    pathfinderNames: CreatePathfinderPolicyInput.fields.pathfinderNames,
  },
});

/** Override type */
export type UpdatePathfinderPolicyInput = SchemaToType<
  typeof UpdatePathfinderPolicyInput
>;

export const UpdatePathfinderPoliciesInput = mkInput({
  name: 'UpdatePathfinderPoliciesInput',
  comment: 'Input for bulk updating one or more pathfinderPolicies',
  fields: {
    pathfinderPolicies: {
      comment:
        'List of pathfinder policies with the properties that should be updated for each',
      type: UpdatePathfinderPolicyInput,
      list: true,
    },
  },
});

/** Override type */
export type UpdatePathfinderPoliciesInput = SchemaToType<
  typeof UpdatePathfinderPoliciesInput
>;

/** Order for a pathfinderPolicies query */
export const PathfinderPolicyOrder = mkOrder(
  PathfinderPolicy.name,
  PathfinderPolicyOrderField,
);
