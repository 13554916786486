import { makeEnum } from '@transcend-io/type-utils';

export const PRIVACY_REQUESTS_PATH_PREFIX = '/privacy-requests';
export const EMAIL_SETTINGS_PATH_PREFIX = `${PRIVACY_REQUESTS_PATH_PREFIX}/email-settings`;
export const PRIVACY_REQUESTS_SETTINGS_PATH_PREFIX = `${PRIVACY_REQUESTS_PATH_PREFIX}/settings`;

export const AdminDashboardPrivacyRequestOnboardingPath = makeEnum({
  GetStarted: `${PRIVACY_REQUESTS_PATH_PREFIX}/get-started`,
});

/** Overrides type */
export type AdminDashboardPrivacyRequestOnboardingPath =
  (typeof AdminDashboardPrivacyRequestOnboardingPath)[keyof typeof AdminDashboardPrivacyRequestOnboardingPath];

export const AdminDashboardPrivacyRequestPostOnboardingPath = makeEnum({
  IncomingRequests: `${PRIVACY_REQUESTS_PATH_PREFIX}/incoming-requests`,
  ActionItems: `${PRIVACY_REQUESTS_PATH_PREFIX}/action-items`,
  Request: `${PRIVACY_REQUESTS_PATH_PREFIX}/incoming-requests/:requestId`,
  RequestDataSilo: `${PRIVACY_REQUESTS_PATH_PREFIX}/incoming-requests/:requestId/:dataSiloId`,
  BulkRequests: `${PRIVACY_REQUESTS_PATH_PREFIX}/bulk-requests`,
  Settings: PRIVACY_REQUESTS_SETTINGS_PATH_PREFIX,
  SettingsDataActions: `${PRIVACY_REQUESTS_SETTINGS_PATH_PREFIX}/data-actions`,
  SettingsDataSubjects: `${PRIVACY_REQUESTS_SETTINGS_PATH_PREFIX}/data-subjects`,
  SettingsDataRetention: `${PRIVACY_REQUESTS_SETTINGS_PATH_PREFIX}/data-retention`,
  Identifiers: `${PRIVACY_REQUESTS_PATH_PREFIX}/identifiers`,
  LegalHolds: `${PRIVACY_REQUESTS_PATH_PREFIX}/legal-holds`,
  LegalMatters: `${PRIVACY_REQUESTS_PATH_PREFIX}/legal-holds/legal-matters`,
  Workflows: `${PRIVACY_REQUESTS_PATH_PREFIX}/workflows`,
  WorkflowsConfiguration: `${PRIVACY_REQUESTS_PATH_PREFIX}/workflows/:workflowId/configuration`,
  PrivacyRequestDashboard: PRIVACY_REQUESTS_PATH_PREFIX,
  EmailSettingsConfiguration: `${EMAIL_SETTINGS_PATH_PREFIX}/configuration`,
  EmailSettingsTemplates: `${EMAIL_SETTINGS_PATH_PREFIX}/templates`,
  EmailSettingsTesting: `${EMAIL_SETTINGS_PATH_PREFIX}/testing`,
});

/** Overrides type */
export type AdminDashboardPrivacyRequestPostOnboardingPath =
  (typeof AdminDashboardPrivacyRequestPostOnboardingPath)[keyof typeof AdminDashboardPrivacyRequestPostOnboardingPath];

export const AdminDashboardPrivacyRequestPath = makeEnum({
  ...AdminDashboardPrivacyRequestOnboardingPath,
  ...AdminDashboardPrivacyRequestPostOnboardingPath,
});

/** Override type */
export type AdminDashboardPrivacyRequestPath =
  (typeof AdminDashboardPrivacyRequestPath)[keyof typeof AdminDashboardPrivacyRequestPath];
