import { Button } from '@main/ad-core-components';
import {
  ErrorAlert,
  FlexColumn,
  FlexRow,
  FlexRowCenterBoth,
  GridTwoEvenColumns,
  StyleUtils,
  useFormatMessageGeneric,
} from '@main/core-ui';
import React from 'react';
import { useParams } from 'react-router-dom';

import defaultImage from '../../../ConsentManager/GetStarted/AirgapOnboardingImage.svg';
import { CTA_PAGES_BY_ID } from '../NavMenu/constants';
import { ctaPageMessages } from '../NavMenu/messages';

/**
 * CtaPage
 */
export const CtaPage: React.FC = () => {
  const { formatMessageGeneric } = useFormatMessageGeneric();
  const params = useParams<{
    /** The id of the Cta page to show */
    ctaId: string;
  }>();
  const ctaProps = CTA_PAGES_BY_ID[params.ctaId ?? ''];

  return !ctaProps ? (
    <ErrorAlert>
      {formatMessageGeneric(ctaPageMessages.errorOccurred)}
    </ErrorAlert>
  ) : (
    <FlexRowCenterBoth
      style={{
        height: '100vh',
        padding: '3em',
      }}
    >
      <GridTwoEvenColumns
        style={{
          gap: StyleUtils.Spacing.xl,
          width: '90%',
          height: '60%',
        }}
      >
        <FlexColumn
          style={{
            gap: StyleUtils.Spacing.md,
            justifyContent: 'center',
          }}
        >
          <h1 style={{ margin: 0 }}>{formatMessageGeneric(ctaProps.title)}</h1>
          <div>
            {formatMessageGeneric(ctaProps.description, {
              productName: ctaProps.productName ? (
                <b style={{ fontWeight: 600 }}>
                  {formatMessageGeneric(ctaProps.productName)}
                </b>
              ) : (
                ''
              ),
            })}
          </div>
          {ctaProps.bulletPoints && ctaProps.bulletPoints.length > 0 && (
            <ul
              style={{
                margin: 0,
                ...StyleUtils.Flex.Column.base,
                gap: StyleUtils.Spacing.sm,
              }}
            >
              {ctaProps.bulletPoints.map((bullet, i) => (
                <li key={i}>{formatMessageGeneric(bullet)}</li>
              ))}
            </ul>
          )}
          <FlexRow style={{ marginTop: '2em' }}>
            <Button variant="primary" href={ctaProps.primaryLink}>
              {formatMessageGeneric(ctaProps.primaryLinkLabel)}
            </Button>
          </FlexRow>
          {ctaProps.secondaryLink && ctaProps.secondaryLinkLabel && (
            <FlexRow>
              <Button variant="secondary" href={ctaProps.secondaryLink}>
                {formatMessageGeneric(ctaProps.secondaryLinkLabel)}
              </Button>
            </FlexRow>
          )}
        </FlexColumn>
        <FlexRowCenterBoth>
          <div
            style={{
              backgroundImage: `url("${ctaProps.imageSrc || defaultImage}")`,
              backgroundSize: 'contain',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
              flexGrow: 1,
              height: '100%',
            }}
          />
        </FlexRowCenterBoth>
      </GridTwoEvenColumns>
    </FlexRowCenterBoth>
  );
};
