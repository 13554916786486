import styled from 'styled-components';

// NOTE: this is an alternative to frontend-support/admin-dashboard/src/components/Icon/index.tsx
// for when we'd like to render a generic svg icon without any interactivity
// and don't need anything the ANT dependency offers.
const SvgIcon = styled.svg.attrs({
  version: '1.1',
  xmlns: 'http://www.w3.org/2000/svg',
  xmlnsXlink: 'http://www.w3.org/1999/xlink',
  viewbox: '0 0 24 24',
})``;

export const SvgFormIcon = styled(SvgIcon)`
  width: 24px;
  height: 24px;
  transform: scale(0.8);
`;
