import styled from 'styled-components';

import { FlexRowCenterVertical } from '@main/core-ui';

/**
 * Props for wrapper
 */
export interface WarningMessageWrapperProps {
  /** Left padding */
  paddingLeft?: string;
}

/**
 * The WarningMessage wrapper
 */
export const WarningMessageWrapper = styled(
  FlexRowCenterVertical,
)<WarningMessageWrapperProps>`
  display: inline-flex;
  gap: 0.5em;
  padding-left: ${({ paddingLeft = '8px' }) => paddingLeft};
  color: ${({ theme }) => theme.colors.error};
`;
