import { defineMessages } from '@transcend-io/internationalization';

export const popConfirmMessages = defineMessages(
  'ad-core-components.Popconfirm.popConfirm',
  {
    cancel: {
      defaultMessage: 'Cancel',
    },
    ok: {
      defaultMessage: 'Yes',
    },
    sure: {
      defaultMessage: 'Are you sure?',
    },
  },
);
