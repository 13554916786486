import React from 'react';
import { useTheme } from 'styled-components';

/**
 * Expand icon
 */
export const Expand: React.FC = (props) => {
  const theme = useTheme();
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <g
        id="ExpandIcon"
        stroke={theme.colors.accentOnSidebar}
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <path
          d="M12 14.071L7.429 9.5 5.929 11 12 17.071 18.071 11 16.571 9.5z"
          fill={theme.colors.accentOnSidebar}
          fillRule="nonzero"
        />
      </g>
    </svg>
  );
};
