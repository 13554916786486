import * as t from 'io-ts';

import { dbModelId } from '@main/schema-utils';

import { AuditEventCode } from '../schema/enums';
import {
  mkAuditEventCodec,
  mkAuditEventJoinCodec,
  mkAuditJoinStateCodec,
} from '../types';

export const AuditEventDataPoint = mkAuditEventCodec(
  AuditEventCode.DataPoint,
  t.type({
    /** ID of the data silo */
    dataSiloId: dbModelId('dataSilo'),
    /** ID of the data point */
    dataPointId: dbModelId('dataPoint'),
  }),
);

/** Override types. */
export type AuditEventDataPoint = t.TypeOf<typeof AuditEventDataPoint>;

export const AuditEventDataPointAction = mkAuditEventJoinCodec(
  AuditEventCode.DataPointAction,
  mkAuditJoinStateCodec('action'),
  t.type({
    /** ID of the data silo */
    dataSiloId: dbModelId('dataSilo'),
    /** ID of the data point */
    dataPointId: dbModelId('dataPoint'),
  }),
);

/** Override types. */
export type AuditEventDataPointAction = t.TypeOf<
  typeof AuditEventDataPointAction
>;

export const AuditEventDataPointOwner = mkAuditEventJoinCodec(
  AuditEventCode.DataPointOwner,
  mkAuditJoinStateCodec('user'),
  t.type({
    /** ID of the data point */
    dataPointId: dbModelId('dataPoint'),
  }),
);

/** Override types. */
export type AuditEventDataPointOwner = t.TypeOf<
  typeof AuditEventDataPointOwner
>;

export const AuditEventDataPointTeam = mkAuditEventJoinCodec(
  AuditEventCode.DataPointTeam,
  mkAuditJoinStateCodec('team'),
  t.type({
    /** ID of the data point */
    dataPointId: dbModelId('dataPoint'),
  }),
);

/** Override types. */
export type AuditEventDataPointTeam = t.TypeOf<typeof AuditEventDataPointTeam>;

export const AuditEventDataPointLevelDescription = mkAuditEventCodec(
  AuditEventCode.DataPointLevelDescription,
  t.type({
    /** ID of the data point level */
    dataPointLevelId: dbModelId('dataPointLevel'),
  }),
);

/** Override types. */
export type AuditEventDataPointLevelDescription = t.TypeOf<
  typeof AuditEventDataPointLevelDescription
>;

export const AuditEventDataPointLevelOwner = mkAuditEventJoinCodec(
  AuditEventCode.DataPointLevelOwner,
  mkAuditJoinStateCodec('user'),
  t.type({
    /** ID of the data point level */
    dataPointLevelId: dbModelId('dataPointLevel'),
  }),
);

/** Override types. */
export type AuditEventDataPointLevelOwner = t.TypeOf<
  typeof AuditEventDataPointLevelOwner
>;

export const AuditEventDataPointLevelTeam = mkAuditEventJoinCodec(
  AuditEventCode.DataPointLevelTeam,
  mkAuditJoinStateCodec('team'),
  t.type({
    /** ID of the data point level */
    dataPointLevelId: dbModelId('dataPointLevel'),
  }),
);

/** Override types. */
export type AuditEventDataPointLevelTeam = t.TypeOf<
  typeof AuditEventDataPointLevelTeam
>;
