import { statusDescriptionMessages, statusTitleMessages } from './messages';
import type { RequestStatusConfig } from './types';

/**
 * ## Approved and Report Sent/Completed Primary
 * The request has been compiled, approved, and the report can now be sent to the Data Subject.
 * This status is only used when a request has no secondary action,
 *
 * Each action will have a custom success email template that the Data Subject will receive in their inbox.
 * If the request includes data that can be downloaded, the Data Subject will also have a link to the download the report.
 *
 * Each action can also configure an email template to use when no records of the Data Subject were found. A global not found
 * email template can also be specified.
 *
 * ### ON CHANGE TO
 *  - An email is sent to the data subject with their report.
 */
export const COMPLETED_STATUS: RequestStatusConfig = {
  color: 'success',
  icon: 'checkmark',
  isClosed: true,
  isSuccess: true,
  canArchive: true,
  messages: {
    description: statusDescriptionMessages.COMPLETED,
    title: statusTitleMessages.COMPLETED,
  },
  transitions: [],
};
