/* eslint-disable max-lines */
import {
  PromptResponseFormat,
  PromptStatus,
} from '@transcend-io/privacy-types';

import { TeamPreview, UserPreview } from '@main/access-control-types';
import {
  AgentPreview,
  AttributeValue,
  PromptGroupPreview,
  PromptPreview,
} from '@main/attribute-types';
import { mkInput, mkOrder, mkType, SchemaToType } from '@main/schema-utils';

import { PromptOrderField } from './enums';
import {
  LargeLanguageModelInput,
  LargeLanguageModelPreview,
} from './largeLanguageModel';

export const PromptTemplated = mkType({
  name: 'PromptTemplated',
  comment: 'Prompt with all information necessary to render it',
  fields: {
    ...PromptPreview.fields,
    responseFormat: {
      comment:
        'The expected mode in which response text is returned - commonly set to json_object to return JSON data',
      type: { PromptResponseFormat },
    },
    content: {
      comment: 'The content of the prompt',
      type: 'string',
    },
    createdAt: {
      comment: 'The date the prompt was created',
      type: 'Date',
    },
    updatedAt: {
      comment: 'The date the prompt was last updated',
      type: 'Date',
    },
  },
});

/** Override type */
export type PromptTemplated = SchemaToType<typeof PromptTemplated>;

export const Prompt = mkType({
  name: 'Prompt',
  comment: 'An HTML-based prompt for editable prompts with HTML content',
  fields: {
    ...PromptPreview.fields,
    responseFormat: {
      comment:
        'The expected mode in which response text is returned - commonly set to json_object to return JSON data',
      type: { PromptResponseFormat },
    },
    content: {
      comment: 'The content of the prompt',
      type: 'string',
    },
    creator: {
      comment: 'The user who created this prompt someone',
      type: UserPreview,
      optional: true,
    },
    lastEditor: {
      comment: 'The user who last edited the prompt',
      type: UserPreview,
      optional: true,
    },
    reviewer: {
      comment: 'The user who is asked to review the prompt',
      type: UserPreview,
      optional: true,
    },
    createdAt: {
      comment: 'The date the prompt was created',
      type: 'Date',
    },
    updatedAt: {
      comment: 'The date the prompt was last updated',
      type: 'Date',
    },
    promptRunCount: {
      comment: 'The number of recorded prompt runs',
      type: 'int',
    },
    teams: {
      comment: 'The team/s that the prompt is assigned to',
      type: TeamPreview,
      list: true,
    },
    owners: {
      comment: 'The user/s that the prompt is assigned to',
      type: UserPreview,
      list: true,
    },
    promptGroups: {
      comment: 'The prompt groups that the prompt belongs to',
      type: PromptGroupPreview,
      list: true,
    },
    agents: {
      comment: 'The agents that the prompt belongs to',
      type: () => AgentPreview,
      list: true,
    },
    largeLanguageModels: {
      comment: 'The large language models that this prompt is best used with',
      type: () => LargeLanguageModelPreview,
      list: true,
    },
    attributeValues: {
      comment: 'The attribute values used to label this assessment',
      type: AttributeValue,
      list: true,
    },
  },
});

/** Override type */
export type Prompt = SchemaToType<typeof Prompt>;

export const PromptFiltersInput = mkInput({
  name: 'PromptFiltersInput',
  comment: 'Inputs for filtering a list of prompts',
  fields: {
    ids: {
      comment: 'The ids of the prompts',
      type: 'id',
      modelName: 'prompt',
      optional: true,
      list: true,
    },
    responseFormats: {
      comment: 'The response formats of the prompts',
      type: { PromptResponseFormat },
      optional: true,
      list: true,
    },
    titles: {
      comment: 'The titles of the prompts',
      type: 'string',
      optional: true,
      list: true,
    },
    lastEditorIds: {
      comment: 'The ids of last editors',
      type: 'id',
      modelName: 'user',
      optional: true,
      list: true,
    },
    creatorIds: {
      comment: 'The ids of the users who created a prompt',
      type: 'id',
      modelName: 'user',
      optional: true,
      list: true,
    },
    promptGroupIds: {
      comment: 'The ids of the prompt groups for the prompt',
      type: 'id',
      modelName: 'promptGroup',
      optional: true,
      list: true,
    },
    largeLanguageModelIds: {
      comment: 'The ids of the large language models assigned to the prompts',
      type: 'id',
      modelName: 'largeLanguageModel',
      optional: true,
      list: true,
    },
    reviewerIds: {
      comment: 'The ids of the reviewers for the prompt',
      type: 'id',
      modelName: 'user',
      optional: true,
      list: true,
    },
    text: {
      comment: 'Filter by text (title of the prompt)',
      optional: true,
      type: 'string',
    },
    ownerIds: {
      comment: 'Filter by the assigned owner IDs',
      optional: true,
      type: 'id',
      modelName: 'user',
      list: true,
    },
    teamIds: {
      comment: 'Filter by the assigned team IDs',
      optional: true,
      type: 'id',
      modelName: 'team',
      list: true,
    },
  },
});

/** Override type */
export type PromptFiltersInput = SchemaToType<typeof PromptFiltersInput>;

export const DeletePromptsInput = mkInput({
  name: 'DeletePromptsInput',
  comment: 'Input for deleting a group of prompts',
  fields: {
    ids: {
      comment: 'The id of the prompts to delete',
      type: 'id',
      modelName: 'prompt',
      list: true,
    },
  },
});

/** Override type */
export type DeletePromptsInput = SchemaToType<typeof DeletePromptsInput>;

export const CreatePromptInput = mkInput({
  name: 'CreatePromptInput',
  comment: 'Input for creating an Prompt',
  fields: {
    title: {
      comment: 'The title of the prompt',
      type: 'string',
    },
    content: {
      comment: 'The content of the prompt',
      type: 'string',
    },
    responseFormat: {
      comment:
        'The expected mode in which response text is returned - commonly set to json_object to return JSON data',
      type: { PromptResponseFormat },
      optional: true,
    },
    temperature: {
      comment:
        'Amount of randomness injected into the response. ' +
        'Defaults to 1. Ranges from 0 to 1. ' +
        'Use temp closer to 0 for analytical / multiple choice, and closer to 1 for creative and generative tasks.',
      type: 'float',
      optional: true,
    },
    topP: {
      comment:
        'An alternative to sampling with temperature, called nucleus sampling, where the ' +
        'model considers the results of the tokens with top_p probability mass. So 0.1 ' +
        'means only the tokens comprising the top 10% probability mass are considered. ' +
        'We generally recommend altering this or `temperature` but not both.',
      type: 'float',
      optional: true,
    },
    maxTokensToSample: {
      comment: 'The maximum number of tokens to sample in the response',
      type: 'int',
      optional: true,
    },
    status: {
      comment: 'The status of the prompt',
      type: { PromptStatus },
      optional: true,
    },
    reviewerId: {
      comment: 'The ID of the user requesting this prompt',
      type: 'id',
      modelName: 'user',
      optional: true,
    },
    reviewerEmail: {
      comment: 'The email of the user requesting this prompt',
      type: 'string',
      optional: true,
    },
    ownerIds: {
      comment: 'The unique ids of the users to assign as owners of this prompt',
      modelName: 'user',
      type: 'id',
      optional: true,
      list: true,
    },
    ownerEmails: {
      comment: 'The emails of the users to assign as owners of this prompt',
      type: 'string',
      optional: true,
      list: true,
    },
    teamIds: {
      comment:
        'The ids of the teams that should be responsible for this prompt',
      type: 'id',
      modelName: 'team',
      list: true,
      optional: true,
    },
    teamNames: {
      comment:
        'The names of the teams that should be responsible for this prompt',
      type: 'string',
      list: true,
      optional: true,
    },
    largeLanguageModelIds: {
      comment:
        'The ids of the large language models that work well with this prompt',
      type: 'id',
      modelName: 'largeLanguageModel',
      list: true,
      optional: true,
    },
    largeLanguageModelInputs: {
      comment:
        'The inputs of the large language models that work well with this prompt',
      type: LargeLanguageModelInput,
      list: true,
      optional: true,
    },
    promptGroupIds: {
      comment:
        'The ids of the prompt groups that this prompt should be a part of',
      type: 'id',
      modelName: 'promptGroup',
      list: true,
      optional: true,
    },
    promptGroupTitles: {
      comment:
        'The titles of the prompt groups that this prompt should be a part of',
      type: 'string',
      list: true,
      optional: true,
    },
  },
});

/** Override type */
export type CreatePromptInput = SchemaToType<typeof CreatePromptInput>;

export const UpdatePromptInput = mkInput({
  name: 'UpdatePromptInput',
  comment: 'Input for updating an Prompt',
  fields: {
    id: {
      comment: 'The id of the Prompt',
      modelName: 'prompt',
      type: 'id',
    },
    responseFormat: {
      comment:
        'The expected mode in which response text is returned - commonly set to json_object to return JSON data',
      type: { PromptResponseFormat },
      optional: true,
    },
    temperature: {
      comment:
        'Amount of randomness injected into the response. ' +
        'Defaults to 1. Ranges from 0 to 1. ' +
        'Use temp closer to 0 for analytical / multiple choice, and closer to 1 for creative and generative tasks.',
      type: 'float',
      optional: true,
    },
    topP: {
      comment:
        'An alternative to sampling with temperature, called nucleus sampling, where the ' +
        'model considers the results of the tokens with top_p probability mass. So 0.1 ' +
        'means only the tokens comprising the top 10% probability mass are considered. ' +
        'We generally recommend altering this or `temperature` but not both.',
      type: 'float',
      optional: true,
    },
    maxTokensToSample: {
      comment: 'The maximum number of tokens to sample in the response',
      type: 'int',
      optional: true,
    },
    title: {
      comment: 'The new title of the Prompt',
      type: 'string',
      optional: true,
    },
    content: {
      comment: 'The new content of the Prompt',
      type: 'string',
      optional: true,
    },
    status: {
      comment: 'The new status of the prompt',
      type: { PromptStatus },
      optional: true,
    },
    reviewerId: CreatePromptInput.fields.reviewerId,
    reviewerEmail: CreatePromptInput.fields.reviewerEmail,
    ownerIds: CreatePromptInput.fields.ownerIds,
    ownerEmails: CreatePromptInput.fields.ownerEmails,
    teamIds: CreatePromptInput.fields.teamIds,
    teamNames: CreatePromptInput.fields.teamNames,
    largeLanguageModelIds: CreatePromptInput.fields.largeLanguageModelIds,
    largeLanguageModelInputs: CreatePromptInput.fields.largeLanguageModelInputs,
    promptGroupIds: CreatePromptInput.fields.promptGroupIds,
    promptGroupTitles: CreatePromptInput.fields.promptGroupTitles,
  },
});

/** Override type */
export type UpdatePromptInput = SchemaToType<typeof UpdatePromptInput>;

export const UpdatePromptsInput = mkInput({
  name: 'UpdatePromptsInput',
  comment: 'Input for bulk updating one or more prompts',
  fields: {
    prompts: {
      comment:
        'List of prompts with the properties that should be updated for each',
      type: UpdatePromptInput,
      list: true,
    },
  },
});

/** Override type */
export type UpdatePromptsInput = SchemaToType<typeof UpdatePromptsInput>;

/** Order for a prompts query */
export const PromptOrder = mkOrder(Prompt.name, PromptOrderField);

export const PromptsWithVariablesInput = mkInput({
  name: 'PromptsWithVariablesInput',
  comment: 'Input for fetching prompts and their associated variables',
  fields: {
    promptIds: {
      comment: 'The ids of the prompts to get variables for',
      type: 'id',
      modelName: 'prompt',
      list: true,
      optional: true,
    },
    promptTitles: {
      comment: 'The titles of the prompts to get variables for',
      type: 'string',
      list: true,
      optional: true,
    },
    promptGroupIds: {
      comment: 'The ids of the prompt groups to get variables for',
      type: 'id',
      modelName: 'promptGroup',
      list: true,
      optional: true,
    },
    promptGroupTitles: {
      comment: 'The titles of the prompt groups to get variables for',
      type: 'string',
      list: true,
      optional: true,
    },
    promptPartialIds: {
      comment: 'The ids of the prompt partials to get variables for',
      type: 'id',
      modelName: 'promptPartial',
      list: true,
      optional: true,
    },
    promptPartialTitles: {
      comment: 'The titles of the prompt partials to get variables for',
      type: 'string',
      list: true,
      optional: true,
    },
  },
});

/** Override type */
export type PromptsWithVariablesInput = SchemaToType<
  typeof PromptsWithVariablesInput
>;

export const PromptVariableData = mkType({
  name: 'PromptVariableData',
  comment:
    'When templating prompts, the set of data that is necessary to fill in said template',
  fields: {
    name: {
      comment: 'Name of the variable',
      type: 'string',
    },
    data: {
      comment: 'JSON stringified data to be used for that variable',
      type: 'string',
    },
  },
});

/** Override type */
export type PromptVariableData = SchemaToType<typeof PromptVariableData>;

/**
 * A form item for a prompt
 */
export const PromptVariableFormItem = mkType({
  name: 'PromptVariableFormItem',
  comment: 'A form item for a prompt',
  fields: {
    name: {
      comment: 'The name of the form item value',
      type: 'string',
    },
  },
});

/** Override type */
export type PromptVariableFormItem = SchemaToType<
  typeof PromptVariableFormItem
>;

/* eslint-enable max-lines */
