import { defineMessages } from '@transcend-io/internationalization';

export const failureMessages = defineMessages(
  'admin-dashboard.Auth.Login.failure',
  {
    title: {
      defaultMessage: 'Login Failure',
    },
    header: {
      defaultMessage: 'Login Failure',
    },
    failure: {
      defaultMessage:
        'Client error: An error occurred while attempting to log in',
    },
  },
);

export const loginMessages = defineMessages(
  'admin-dashboard.Auth.Login.login',
  {
    emailHeader: {
      defaultMessage: 'Email',
    },
    forgotPassword: {
      defaultMessage: 'Forgot your password?',
    },
    login: {
      defaultMessage: 'Log in',
    },
    passwordHeader: {
      defaultMessage: 'Password',
    },
    retry: {
      defaultMessage: 'Go back to login page',
    },
    ssoRedirecting: {
      defaultMessage: 'Logging you in with your identity provider...',
    },
  },
);
