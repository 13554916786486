import {
  SelectedUser,
  SelectUser,
  SelectUserProps,
} from '@main/ad-core-components';
import React from 'react';

/**
 * takes the return value of userInlineInput to build a SelectUser component
 *
 * @param loading - wether the fetched content is still loading
 * @param object - the value returned by userInlineInput
 * @return - SelectUser component
 */
export const buildSelectUser = (
  loading: boolean,
  {
    value,
    setValue,
    onBlur,
  }: {
    /** the value returned by useInlineInput */
    value: SelectedUser[];
    /** the setValue returned by useInlineInput */
    setValue: (values: SelectedUser[]) => void;
    /** the onBlue returned by useInlineInput */
    onBlur: () => void;
  },
  props?: SelectUserProps<true>,
): JSX.Element => (
  <SelectUser
    // TODO: https://transcend.height.app/T-10849 - Support in-line invite of new users
    // allowInvite
    isMulti
    variant="light"
    value={value}
    showOutline={false}
    onChange={(newValues: SelectedUser[]) => {
      setValue(newValues);
    }}
    isLoading={loading}
    overflow="badge"
    onBlur={onBlur}
    {...props}
  />
);
