import { endpoints as analyticsEndpoints } from '@main/analytics-types';
import {
  buildUseQuery,
  FlexColumnCenterVertical,
  GenericMessageDescriptor,
  useFormatMessageGeneric,
} from '@main/core-ui';
import { ColorPalette } from '@main/theme';
import { createFakeUuidv4 } from '@main/utils';
import orderBy from 'lodash/orderBy';
import sum from 'lodash/sum';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';

import { DashboardTile, DashboardTileProps } from '../../DashboardTile';
import { DonutChart } from '../DonutChart';
import { DashboardEffectiveDateWarning } from './DashboardEffectiveDateWarning';
import { dashboardChartMessages } from './messages';
import { SimpleDashboardXYChartProps } from './types';

interface SimpleDashboardPieChartProps
  extends Omit<
    SimpleDashboardXYChartProps,
    'scale' | 'dateKeys' | 'prettySeriesMap'
  > {
  /** the label used in center of chart */
  unitLabel?: GenericMessageDescriptor;
  /** onClick legend entry */
  onClickLegendEntry?: (key: string) => void;
  /** mapping of the data keys to colors that should be applied for each key */
  colorMap?: Record<string, keyof ColorPalette>;
}

const useAnalytics = buildUseQuery(analyticsEndpoints.analyticsData);

/**
 * SimpleDashboardPieChart - a component to reuse the common pie chart configuration
 * for dashboards
 */
export const SimpleDashboardPieChart: React.FC<
  SimpleDashboardPieChartProps & DashboardTileProps
> = ({
  dataSource,
  prettyLabelMap,
  startDate,
  endDate,
  isTestRequest,
  defaultLabel,
  unitLabel: unitLabelMessage,
  onClickLegendEntry,
  extraProps,
  skipIfNoStartDate,
  colorMap,
  uniqueId = '',
  appId,
  runId,
  ...other
}) => {
  const { formatMessage } = useIntl();
  const { formatMessageGeneric } = useFormatMessageGeneric();
  const input = {
    dataSource,
    startDate: startDate?.toISOString(),
    endDate: endDate?.toISOString(),
    isTestRequest,
    ...(uniqueId ? { uniqueId } : {}),
    ...(appId ? { appId } : {}),
    ...(runId ? { runId } : {}),
    ...extraProps,
  };
  const { data, loading, error, refetch } = useAnalytics({
    variables: {
      input,
    },
    fetchPolicy: 'cache-and-network',
    skip: skipIfNoStartDate && !startDate,
  });

  const { values, labels, keys } = useMemo(() => {
    if (!data) {
      return {};
    }
    // sort by descending value
    const points = orderBy(
      data.series[0].points,
      ['value', 'key'],
      ['desc', 'asc'],
    );
    return {
      keys: points.map(({ key }) => key || ''),
      values: points.map(({ value }) => value),
      labels: points.map(({ key }) =>
        key
          ? prettyLabelMap?.[key]
            ? formatMessageGeneric(prettyLabelMap[key])
            : key
          : formatMessageGeneric(defaultLabel),
      ) as string[],
    };
  }, [data]);
  const colors =
    colorMap && keys
      ? Object.entries(colorMap)
          .filter(([key]) => keys.includes(key))
          .sort(([a], [b]) => keys.indexOf(a) - keys.indexOf(b))
          .map(([, color]) => color)
      : undefined;
  const unitLabel = formatMessageGeneric(unitLabelMessage) as string;
  const hasDataPoints = data && labels && values && keys && sum(values) > 0;

  return (
    <DashboardTile
      {...other}
      cachedAt={data?.cachedAt}
      loading={loading}
      error={error}
      onClickForceRefetch={() =>
        refetch({
          input: {
            ...input,
            forceRefetch: true,
            ...(uniqueId ? { uniqueId: createFakeUuidv4() } : {}),
          },
        })
      }
    >
      <DashboardEffectiveDateWarning
        startDate={startDate}
        effectiveDate={data?.metadata.effectiveDate}
      />
      {hasDataPoints && (
        <FlexColumnCenterVertical
          style={{
            alignItems: 'center',
            flexGrow: 1,
            minHeight: '15em',
            minWidth: '300px',
          }}
        >
          <DonutChart
            maxDonutSize={225}
            data={{
              labels: labels!,
              values: values!,
              keys: keys!,
            }}
            unitLabel={unitLabel || ''}
            onClickLegendEntry={onClickLegendEntry}
            colors={colors}
          />
        </FlexColumnCenterVertical>
      )}
      {!hasDataPoints &&
        !error &&
        !loading &&
        formatMessage(dashboardChartMessages.noData)}
    </DashboardTile>
  );
};
