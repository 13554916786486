import {
  extractFormItemWrapperProps,
  FormItemWrapper,
  IFormFieldProps,
  IFormItemWrapperProps,
  multipleValidators,
  TValidator,
} from '@main/core-ui';
import { Optionalize } from '@transcend-io/type-utils';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { DebouncedInput, DebouncedInputProps } from './DebouncedInput';
import { Input, InputProps } from './Input';

export interface IFormInputProps extends IFormFieldProps {
  /** the validation rules for the input */
  rules?: TValidator[];
}

/**
 * A raw form input for use cases where formatting is not desired
 */
export const FormInputRaw: React.FC<
  IFormInputProps &
    Omit<InputProps, 'value' | 'onChange' | 'onBlur'> &
    Optionalize<Pick<DebouncedInputProps, 'debounceTime'>, 'debounceTime'>
> = ({ name, defaultValue = '', rules, debounceTime, ...inputProps }) => {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      rules={rules ? { validate: multipleValidators(rules) } : undefined}
      render={({ field }) => {
        const sharedProps = {
          id: name,
          ...inputProps,
          ...field,
        };

        return debounceTime ? (
          <DebouncedInput
            {...sharedProps}
            debounceTime={debounceTime}
            initialValue={sharedProps.value}
          />
        ) : (
          <Input {...sharedProps} />
        );
      }}
    />
  );
};

/**
 * A form input with formatting and error display
 */
export const FormInput: React.FC<
  IFormInputProps &
    InputProps &
    Omit<IFormItemWrapperProps, 'errors'> &
    Optionalize<Pick<DebouncedInputProps, 'debounceTime'>, 'debounceTime'>
> = (props) => {
  const {
    formState: { errors },
  } = useFormContext();
  const { passthroughProps, wrapperProps } = extractFormItemWrapperProps(props);
  return (
    <FormItemWrapper errors={errors} {...wrapperProps}>
      <FormInputRaw {...passthroughProps} />
    </FormItemWrapper>
  );
};
