import { makeEnum } from '@transcend-io/type-utils';

export const CONTRACT_SCANNING_PATH_PREFIX = '/contracts';

export const AdminDashboardContractScanningPath = makeEnum({
  Contracts: CONTRACT_SCANNING_PATH_PREFIX,
  NewContract: `${CONTRACT_SCANNING_PATH_PREFIX}/new`,
  ViewContract: `${CONTRACT_SCANNING_PATH_PREFIX}/view/:contractId`,
  EditContract: `${CONTRACT_SCANNING_PATH_PREFIX}/edit/:contractId`,
  ContractScans: `${CONTRACT_SCANNING_PATH_PREFIX}/scans`,
  ContractScan: `${CONTRACT_SCANNING_PATH_PREFIX}/scans/view/:contractScanId`,
});

/** Override type */
export type AdminDashboardContractScanningPath =
  (typeof AdminDashboardContractScanningPath)[keyof typeof AdminDashboardContractScanningPath];
