import styled from 'styled-components';

import { Card } from '../Card';

export const StyledWorkflowCard = styled(Card)<{
  /** Whether workflow is set live */
  $isLive: boolean;
}>`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  position: relative;
  padding: 20px 24px;
  ${({ $isLive, theme }) =>
    !$isLive ? `background-color: ${theme.colors.bgAccent};` : ''}
`;

export const StyledCardTitle = styled.div`
  font-size: 20px;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.transcendNavy};
`;

export const StyledCardDetails = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const StyledCountDetails = styled.div`
  display: flex;
  gap: 10px;
`;

export const StyledCountNumbers = styled.div`
  font-size: 16px;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.transcendNavy};
`;

export const StyledCountLabels = styled.div`
  font-size: 12px;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.transcendNavy2};
`;
