import { cases } from '@transcend-io/handlebars-utils';

/**
 * Converts a React style object
 *
 * @param styleObject - React style object, e.g., `{ backgroundColor: '#010101', fontSize: '12px' }`
 * @returns the CSS string, e.g., `background-color: #010101; font-size: 12px;`
 */
export function styleObjectToCss(styleObject: Record<string, string>): string {
  return Object.entries(styleObject)
    .map(
      ([styleObjectProperty, styleObjectValue]) =>
        `${[cases.kebabCase(styleObjectProperty), styleObjectValue].join(
          ':',
        )};`,
    )
    .join(';');
}
