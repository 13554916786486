import { createSelector } from 'reselect';

import { LanguageProviderState } from './slice';

/**
 * The selector for the `LanguageProvider` state store slice
 */
export const getLanguageProviderSlice = (state: any): LanguageProviderState =>
  state.LanguageProvider;

/**
 * The currently selected language key
 */
export const selectLocale = createSelector(
  getLanguageProviderSlice,
  ({ locale }) => locale,
);

/**
 * Translations for the current locale
 */
export const selectTranslations = createSelector(
  getLanguageProviderSlice,
  (slice) => slice.translations,
);
